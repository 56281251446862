import * as d3 from 'rockerbox_d3_legacy_clone';

import { TIERS } from '../../constants/tiers';
import { isJsonString } from '../../utils/valueFormatter';

export const createSelectedTiers = selected => {
  const noneSelected = TIERS.reduce((accu, curr) => { accu[curr] = []; return accu; }, {});
  const initialFilter = ['any', 'first', 'last'];
  if (!selected || initialFilter.includes(selected)) {
    return noneSelected;
  }
  const parseSelectedTiers = isJsonString(selected) ? isJsonString(selected) : {};
  return Object.assign(noneSelected, parseSelectedTiers);
};

export const updateSelectedTiersforURL = (tiers, resetUrl) => {
  const updateSelected = Object.keys(tiers).reduce((accu, curr) => {
    if (tiers[curr].length > 0) accu[curr] = tiers[curr];
    return accu;
  }, {});
  return Object.keys(updateSelected).length > 0 ? JSON.stringify(updateSelected) : resetUrl;
};

const initializeTiers = data => {
  const tiers = data.reduce((accu, curr) => {
    TIERS.forEach((t, i) => {
      if (curr.path[0][i]) {
        accu[t] = (accu[t] || {});
        accu[t][curr.path[0][i]] = (accu[t][curr.path[0][i]] || []);
        const parentPath = i ? curr.path[0][i - 1] : [];
        accu[t][curr.path[0][i]].push(parentPath);
      }
    });
    return accu;
  }, {});

  const t = Object.keys(tiers).reduce((accu, curr) => {
    accu[curr] = Object.keys(tiers[curr]).map(key => ({ key, value: key, text: key, parentValues: new Set(tiers[curr][key]) }));
    return accu;
  }, {});

  return t;
};

export const buildTiers = (tiers, nestedObj, resetUrl) => {
  const selectedTiers = createSelectedTiers(tiers);
  const { originalTiers } = nestedObj;

  const build = TIERS.reduce((accu, curr, i) => {
    if (originalTiers[curr] === undefined) return accu;
    if (i === 0) {
      accu[curr] = originalTiers[curr];
      return accu;
    }

    accu[curr] = originalTiers[curr].filter(tier => selectedTiers[TIERS[i - 1]]
      .filter(x => tier.parentValues.has(x)).length);

    return accu;
  }, {});

  const tierValues = Object.keys(build).reduce((accu, curr) => {
    accu[curr] = build[curr].map(v => v.value);
    return accu;
  }, {});

  const updateSelected = Object.keys(selectedTiers)
    .reduce((accu, tier) => {
      const selected = selectedTiers[tier].filter(v => tierValues[tier].includes(v));
      accu[tier] = selected;
      return accu;
    }, {});

  return [build, updateSelectedTiersforURL(updateSelected, resetUrl)];
};

const filterTiers = (originalTiers, selectedTiers) => TIERS.reduce((accu, curr, i) => {
  if (originalTiers[curr] === undefined) return accu;
  if (i === 0) {
    accu[curr] = originalTiers[curr];
    return accu;
  }
  accu[curr] = originalTiers[curr].filter(tier => (selectedTiers[TIERS[i - 1]] || []).filter(x => tier.parentValues.has(x)).length);
  return accu;
}, {});

export const buildNestedData = (touchpoints, data, keys) => {
  const { count, revenue, avg_seconds_til_conversion } = keys;
  const selectedTiers = createSelectedTiers(touchpoints);

  const nestedData = d3.nest()
    .key(x => JSON.stringify(x.path))
    .rollup(values => ({
      path: values[0].path,
      count: d3.sum(values, x => x[count]),
      revenue: d3.sum(values, x => x[revenue]),
      avg_revenue: d3.sum(values, x => x[revenue]) / d3.sum(values, x => x[count]),
      avg_seconds: d3.sum(values, x => x[avg_seconds_til_conversion]) / values.length,
      total_seconds: d3.sum(values, x => x[avg_seconds_til_conversion] * x[count]),
    }))
    .map(data);

  const originalCustomerPath = Object.values(nestedData);
  const originalTiers = initializeTiers(originalCustomerPath);

  const subsetSelectedTiers = Object.keys(originalTiers).reduce((accu, curr) => {
    accu[curr] = selectedTiers[curr];
    return accu;
  }, {});

  const tiers = filterTiers(originalTiers, subsetSelectedTiers);
  return { tiers, originalCustomerPath, originalTiers, subsetSelectedTiers };
};

/**
 * Determines if a given path contains all the specified tiers in the filter using the 'and' or 'or' operator.
 *
 * @param {Array} path - The path to check.
 * @param {Object} filter - The filter object containing tiers and their corresponding values.
 * @param {Object} andOr - The object specifying whether to use 'and' or 'or' operator for each tier.
 * @returns {boolean} - True if the path contains all the specified tiers using the 'and' operator, false otherwise.
 */
export const containsTouchAndOrOperator = (path, filter, andOr) => (
  TIERS.reduce((p, tier, i) => {
    const tierList = filter[tier] || [];
    if (tierList.length === 0 || !p) return p;
    const mappedPath = path.map(visit => visit[i]);
    const isAnd = andOr[tier] === 'and';
    const inTierList = isAnd ? tierList.every(item => mappedPath.includes(item)) : tierList.some(item => mappedPath.includes(item));
    return inTierList;
  }, true)
);

/**
 * Determines if a given path contains at least one visit that matches the specified filter.
 *
 * @param {Array} path - The path to check for matching visits.
 * @param {Object} filter - The filter object containing tiers and their corresponding values to match against.
 * @returns {boolean} - True if the path contains a matching visit, false otherwise.
 */
export const containsTouch = (path, filter) => path.filter(visit => TIERS.reduce((p, c, i) => {
  const tierList = filter[c] || [];
  const inTierList = tierList.length === 0 || tierList.indexOf(visit[i]) > -1;
  return p && inTierList;
}, true)).length > 0;

/**
 * Filters the customer path based on specified criteria.
 *
 * @param {boolean} anywhere - Flag indicating whether to filter by anywhere touch.
 * @param {boolean} firstTouch - Flag indicating whether to filter by first touch.
 * @param {boolean} lastTouch - Flag indicating whether to filter by last touch.
 * @param {object} andOr - Object specifying the operator for each tier.
 * @param {number} minPathLength - Minimum length of the customer path.
 * @param {object} nestedObj - Optional nested object containing the original customer path.
 * @returns {array} - Array of filtered customer paths sorted by count in descending order.
 */
// might want to break out into separate functions, otherwise all filters run when either any, first, or last is changed
export const filterCustomerPath = (anywhere, firstTouch, lastTouch, andOr, minPathLength, nestedObj) => {
  const { originalCustomerPath } = nestedObj || [];
  const any = createSelectedTiers(anywhere);
  const first = createSelectedTiers(firstTouch);
  const last = createSelectedTiers(lastTouch);
  const minPathLen = minPathLength || 1;

  const paths = originalCustomerPath
    .map(row => {
      const updatedRow = { ...row };
      updatedRow.total_revenue = updatedRow.avg_revenue * updatedRow.count;
      return updatedRow;
    })
    .filter(row => {
      const containsAnyTouch = Object.keys(andOr).length ? containsTouchAndOrOperator(row.path, any, andOr) : containsTouch(row.path, any);
      const containsFirstTouch = containsTouch(row.path.slice(0, 1), first);
      const containsLastTouch = containsTouch(row.path.slice(-1), last);

      const isMinPathLen = row.path.length >= minPathLen;

      return containsAnyTouch && containsFirstTouch && containsLastTouch && isMinPathLen && row.count > 0;
    });
  return paths.sort((p, c) => c.count - p.count);
};

// Filters
export const summarizePaths = paths => {
/*  {
    avg_revenue: 86.6129411764706
    avg_seconds: 1787.5
    count: 204
    path: [Array(3)]
    revenue: 17669.04
    searchField: ""
    total_seconds: 357947
  }

  avg touchpoints -- need delta?
  avg revenue

  */

  const conversions = paths.reduce((p, c) => p + c.count, 0);
  const totalPaths = paths.length;
  const totalLengths = paths.reduce((p, c) => p + c.path.length * c.count, 0);
  const totalTimes = paths.reduce((p, c) => p + c.total_seconds, 0);
  const totalRevenue = paths.reduce((p, c) => p + c.revenue, 0);
  const averageRevenue = totalRevenue / conversions;

  const averageLength = totalLengths / conversions;
  const averageTime = totalTimes / conversions;

  return { conversions, totalPaths, averageLength, averageTime, averageRevenue };
};

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

export const Row = styled.div.attrs({"className":"rowContainer"})`
  margin-top:${props => props.marginTop || '15px' };
  margin-bottom:${props => props.marginBottom || '20px' };
  display: flex;
  flex-direction: ${props => props.direction || 'row' };
  flex: 1;
`
export const RowItem = styled.div`
  display: flex;
  flex-direction: column;
  padding-right:15px;
  padding-top: ${props => props.paddingTop || 0 };
  flex: ${props => props.flex || 1 };;
  justify-content: ${props => props.justify|| 'inherit' };
  text-align: ${props => props.justify|| 'inherit' };
`
export const CenteredRowItem = (props) => (
  <Row marginTop="0px" marginBottom="0px" >
    <RowItem justify="center">
       {props.children}
    </RowItem>
  </Row>
)

class WidthWrapper extends Component {

  constructor() {
    super()
    this.state = {
      width: false,
      id: parseInt(Math.random()*1000)
    }
  }

  componentDidMount() {
    this.getDimensions();
    window.addEventListener("resize", this.getDimensions.bind(this));
  }
  componentDidUpdate(prevProps) {
    this.getDimensions();
  }

  getDimensions() {
    const { width, id } = this.state;
    const { data = {} } = this.props;
    const { length } = data
    const shouldRender = (data && length)

    if (!shouldRender) return false

    const attributionBar = this.refs[`attributionBar-${id}`];
    const target = ReactDOM.findDOMNode(attributionBar);

    if (target) {
      const parent = target.parentNode.parentNode
      const shouldSetWidth = width !== parent.offsetWidth
      shouldSetWidth && this.setState({"width": parent.offsetWidth});
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.getDimensions.bind(this));
  }

  render() {
    const { width, id } = this.state;
    const Component = this.props.component;

    const body = width ?  <Component {...{ width }} {...this.props} /> : null 

    return (
      <CenteredRowItem>
        <div className="stacked-bar-wrapper" ref={`attributionBar-${id}`} >
          { body }
        </div>
      </CenteredRowItem>
    )
  }
}

export default WidthWrapper;


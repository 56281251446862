import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ContainerStyles = styled.div`
  display: ${props => props.display || 'flex'};
  width: ${props => props.width ? props.width + 'px' : props.fill ? '100%' : 'auto'};
  height: ${props => props.height ? props.height + 'px' : props.fill ? '100%' : 'auto'};
  text-align: ${props => props.center ? 'center' : props.textAlign || 'left' };
  flex-direction: ${props => props.direction || props.column ? 'column' : 'row'};
  align-items: ${props => props.center ? 'center' : props.align || 'flex-start'};
  justify-content: ${props => props.center ? 'center' : props.justify || 'flex-start'};
  align-self: ${props => props.self || 'inherit'};
`;

const Container = (props) => <ContainerStyles {...props}> { props.children } </ContainerStyles>

Container.propTypes = {
  children: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(['row', 'column']),
  align: PropTypes.oneOf(['space-between', 'space-around', 'center']),
  width: PropTypes.string,
  height: PropTypes.string,
  center: PropTypes.bool, //shorthand for center all (veritical/hortizonal);
  column: PropTypes.bool, //shorthand for flex-direction: column;
  fill: PropTypes.bool, //shorthand for width/height 100%;
}

export default Container;

import { useEffect, useState, useMemo } from 'react';

import { getPlatformPerformanceMeta } from '../../../../api/platformPerformanceApi';
import { useParamsWithDefaults } from '../urlParamHooks';
import { generateDateRange } from '../../../../utils/channelPerformance/helpers';

export const usePerformanceMetaData = () => { // eslint-disable-line import/prefer-default-export
  const { startDate, endDate, platform } = useParamsWithDefaults();

  const [meta, setMeta] = useState(undefined);
  const [current, setCurrent] = useState({});

  // establish date range
  const dates = useMemo(
    () => generateDateRange(startDate, endDate),
    [startDate, endDate],
  );

  // if current is updated, use the start, end, platform to get the meta data
  useEffect(() => {
    setCurrent({});
    setMeta(undefined);
    getPlatformPerformanceMeta(platform)
      .then(metaData => {
        // HACK for Facebook 7D Click + 1D View
        /* eslint-disable */
        if (platform === 'facebook') {
          metaData.columns.push('7d_click_1d_view', '7d_click_1d_view_value');
          metaData.conversion_columns.push('7d_click_1d_view', '7d_click_1d_view_value');
          metaData.nested_columns['7d_click_1d_view'] = metaData.nested_columns['7d_click'];
          metaData.nested_columns['7d_click_1d_view_value'] = metaData.nested_columns['7d_click_value'];
        }
        /* eslint-enable */
        setMeta(metaData);
        setCurrent({ platform });
      });
  }, [platform]);

  // if current != params, dont return meta and derived objects
  if (platform !== current.platform) {
    return {
      current,
      dates,
      platformPerformanceMeta: [],
    };
  }

  return {
    current,
    dates,
    platformPerformanceMeta: meta,
  };
};

import React, { useCallback } from "react";
import { Form, Button, Table } from "semantic-ui-react";
import {
  datasetAccountingStore,
  warehouseStore,
  syncCardStore
} from "./zustandStore";
import { tableTypeOptions } from "./constants";

const datasetAccountingSelector = state => [
  state.createNewDataset,
  state.deleteDataset
];
const warehouseSelector = state => [
  state.activeWarehouseId,
  state.fetchWarehouse
];
const syncSelector = state => [
  state.setCardLoading,
  state.updateCardStatus,
  state.removeNewDataset
];

const SyncDatasetManageTable = props => {
  const {
    datasets,
    action,
    segments,
    segment,
    newDataset,
    setNewDataset,
    onAddNew
  } = props;

  const [createNewDataset, deleteDataset] = datasetAccountingStore(
    datasetAccountingSelector
  );
  const [activeWarehouseId, fetchWarehouse] = warehouseStore(warehouseSelector);
  const [setCardLoading, updateCardStatus, removeNewDataset] = syncCardStore(
    syncSelector
  );

  const onDatasetChange = useCallback((e, data) => {
    setNewDataset("dataset", data.value);
  });
  const onDatasetNameChange = useCallback((e, data) => {
    setNewDataset("warehouse_table_name", data.value);
  });
  const onFilterIdChange = useCallback((e, data) => {
    setNewDataset("filter_id", data.value);
  });
  const onDatasetCreation = useCallback((e, data) => {
    setCardLoading(true);
    const requestBody = {
      table_name: newDataset["warehouse_table_name"],
      dataset: newDataset["dataset"],
      advertiser_warehouse_id: activeWarehouseId
    };
    requestBody["filter_id"] = newDataset["filter_id"] || segment;
    requestBody["advertiser"] = segments[0]["pixel_source_name"];
    createNewDataset(requestBody)
      .then(() => {
        removeNewDataset();
        return fetchWarehouse();
      })
      .finally(() => {
        updateCardStatus("show");
        setCardLoading(false);
      });
  });

  const segmentOptions = segments.map(item => {
    return {
      key: item.id,
      value: item.filter_id,
      text: item.action_name
    };
  });

  const validateTableName = React.useMemo(() => {
    const reg = new RegExp("^[a-zA-Z0-9_]+$");
    const tableName = newDataset && newDataset["warehouse_table_name"];

    return {
      error: !reg.test(tableName),
      content: "We only allow a-z, A-Z, 0-9 and _ in table name"
    };
  }, [newDataset && newDataset["warehouse_table_name"]]);

  return (
    <Form>
      {!!newDataset && (
        <React.Fragment>
          <Form.Group>
            <Form.Field width={8}>
              <Form.Dropdown
                selection
                onChange={onFilterIdChange}
                options={segmentOptions}
                value={newDataset && newDataset["filter_id"]}
                label="Segment Name"
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field width={6}>
              <Form.Dropdown
                selection
                onChange={onDatasetChange}
                options={tableTypeOptions}
                value={newDataset["dataset"]}
                label="Table Type"></Form.Dropdown>
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field width={10}>
              <Form.Input
                fluid
                label="Table Name"
                error={validateTableName["error"] && validateTableName}
                value={newDataset["warehouse_table_name"]}
                placeholder="Table Name (Note: we will add _tiers or _mta to your table name based on the Dataset chosen above.)"
                onChange={onDatasetNameChange}></Form.Input>
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width={10}>
              {newDataset["dataset"] && newDataset["warehouse_table_name"] ? (
                <Table celled>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={5} active={true}>
                        <b>Table Name Preview</b>
                      </Table.Cell>
                      <Table.Cell>
                        {` ${newDataset["warehouse_table_name"]}_${
                          tableTypeOptions.filter(
                            item => item.value === newDataset["dataset"]
                          )[0]["key"]
                        }_live`}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              ) : null}
            </Form.Field>
          </Form.Group>
        </React.Fragment>
      )}
      <Button
        primary
        onClick={!!newDataset ? onDatasetCreation : onAddNew}
        disabled={validateTableName["error"]}>
        {!!newDataset ? "Confirm" : "Add"}
      </Button>
    </Form>
  );
};

export default SyncDatasetManageTable;

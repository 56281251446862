import React from "react";
import DnDReportTable from "./DnDReportTable";
import AppliedSchemaTable from "./AppliedSchemaTable";
import { ContentCard } from "@rockerbox/styleguide";
import { DataAppliedFailure, ValidationErrors } from "./ErrorMessages";

import {
  AddColumnButton,
  SaveSchemaButton,
  ApplySchemaButton,
  BackButton,
} from "./Buttons";

const ReportCustomizeLayout = (props) => {
  const {
    schemas,
    moveRowOrder,
    openModal,
    setOpenModal,
    newColumn,
    setNewColumn,
    submitNewColumn,
    updateColumnField,
    sendReportTransformRequest,
    sendUpdateSavedReport,
    appliedData,
    loading,
    setLoading,
    optionKeys,
    hasError,
    setHasError,
    validationErrors,
    report,
  } = props;

  const errorMsg = hasError ? <DataAppliedFailure error={hasError} /> : null;
  const appliedSchemaTable =
    schemas.length >= 8 ? (
      <div style={{ width: "100%", overflow: "scroll" }}>
        <AppliedSchemaTable {...props} />
      </div>
    ) : (
      <AppliedSchemaTable {...props} />
    );

  const existKeys = schemas.map((schema) => schema.name);
  const fieldOptions = optionKeys
    .map((key) => ({
      key,
      value: key,
      text: key,
    }))
    .filter((option) => existKeys.indexOf(option.key) === -1);

  return (
    <React.Fragment>
      <ContentCard
        title={`Customize Report: ${report.name}`}
        topRight={<BackButton />}>
        {`Modify the included columns and data format of your ${report.report_name} report`}
      </ContentCard>

      <ContentCard hasTable title="Customize Columns" loading={loading}>
        <ValidationErrors errors={validationErrors} />
        <DnDReportTable
          {...props}
          optionKeys={optionKeys}
          fieldOptions={fieldOptions}
        />
      </ContentCard>
      {fieldOptions.length > 0 ? (
        <AddColumnButton setNewColumn={setNewColumn} />
      ) : null}
      <br />
      <br />
      <hr />
      <ContentCard
        title="Data applied schema"
        loading={loading}
        hasTable
        topRight={
          <ApplySchemaButton
            sendReportTransformRequest={sendReportTransformRequest}
            setHasError={setHasError}
          />
        }>
        {errorMsg}
        {appliedSchemaTable}
      </ContentCard>
      <SaveSchemaButton
        appliedData={appliedData}
        sendUpdateSavedReport={sendUpdateSavedReport}
      />
    </React.Fragment>
  );
};

export default ReportCustomizeLayout;

import { useState, useMemo, useEffect } from 'react';

import toast from 'react-hot-toast';

import { getEmailSuppressionsAndDeletions } from './helpers';
import { getSubscriptions, postSubscriptions, deleteSubscriptions } from '../../../api/emailSubscriptions';
import { RBAC_EMAIL_SUBSCRIPTIONS_EDIT } from '../../../constants/rbac';
import { useGlobalState } from '../../../hooks/global';
import { useRBACPermissions, useRBACAdvertiser } from '../../../hooks/useRBAC';

export const useSubscriptionValues = () => {
  const [trackingParamValue, setTrackingParamValue] = useState(true);
  const [missingPixelValue, setMissingPixelValue] = useState(true);
  const [failedAuthValue, setFailedAuthValue] = useState(true);
  const [weeklyInsightsValue, setWeeklyInsightsValue] = useState(true);
  const [dataDelaysValue, setDataDelaysValue] = useState(true);

  const values = {
    trackingParamValue,
    missingPixelValue,
    failedAuthValue,
    weeklyInsightsValue,
    dataDelaysValue,
  };
  const setters = {
    setTrackingParamValue,
    setMissingPixelValue,
    setFailedAuthValue,
    setWeeklyInsightsValue,
    setDataDelaysValue,
  };

  const valuesObject = useMemo(() => ({
    'Tracking Parameter Alerts': trackingParamValue,
    'Missing Pixel Activity': missingPixelValue,
    'Failed Platform Authorization': failedAuthValue,
    'Weekly Insights': weeklyInsightsValue,
    'Data Processing Delay Alerts': dataDelaysValue,
  }), [values]);

  const settersObject = useMemo(() => ({
    'Weekly Insights': setWeeklyInsightsValue,
    'Tracking Parameter Alerts': setTrackingParamValue,
    'Missing Pixel Activity': setMissingPixelValue,
    'Failed Platform Authorization': setFailedAuthValue,
    'Data Processing Delay Alerts': setDataDelaysValue,
  }), [setters]);

  return {
    values, setters,
    valuesObject, settersObject,
  };
};

export const useSubscriptionDetails = ({ values, setters }) => {
  const { account } = useGlobalState();
  const { advertiserExistsRBAC } = useRBACAdvertiser();
  const { weeklyInsightsValue, trackingParamValue, missingPixelValue, failedAuthValue, dataDelaysValue } = values;
  const { setWeeklyInsightsValue, setTrackingParamValue, setMissingPixelValue, setFailedAuthValue, setDataDelaysValue } = setters;

  const isScale = useMemo(() => !!(account.tier !== 'starter' && account.tier !== 'lite'), [account]);

  const subscriptionDetails = useMemo(() => ([
    {
      name: 'Tracking Parameter Alerts',
      description: 'Get alerts about ads that are missing required Rockerbox parameters.',
      value: trackingParamValue,
      onChange: () => setTrackingParamValue(prev => !prev),
      hide: isScale,
    },
    {
      name: 'Missing Pixel Activity',
      description: 'Get alerts if Rockerbox can’t track activity on your webpage.',
      value: missingPixelValue,
      onChange: () => setMissingPixelValue(prev => !prev),
      hide: isScale,
    },
    {
      name: 'Failed Platform Authorization',
      description: 'Get alerts when you need to reconnect your advertising platforms to Rockerbox.',
      value: failedAuthValue,
      onChange: () => setFailedAuthValue(prev => !prev),
    },
    {
      name: 'Weekly Insights',
      description: 'Get a weekly email with stats about your marketing program.',
      value: weeklyInsightsValue,
      onChange: () => setWeeklyInsightsValue(prev => !prev),
    },
    {
      name: 'Data Processing Delay Alerts',
      description: 'Receive a daily email that includes details on any identified delays processing your data.',
      value: dataDelaysValue,
      onChange: () => setDataDelaysValue(prev => !prev),
      hide: !advertiserExistsRBAC,
    },
  ]), [values, setters, advertiserExistsRBAC]);

  return {
    subscriptionDetails,
  };
};

export const useEmailSubscriptionsAndAuthorization = ({ valuesObject, settersObject }) => {
  const { user } = useGlobalState();
  const { authorizedPermissions } = useRBACPermissions();
  const { email: userEmail } = user;
  const [loading, setLoading] = useState(true);
  const isAuthorized = useMemo(() => authorizedPermissions.includes(RBAC_EMAIL_SUBSCRIPTIONS_EDIT), [authorizedPermissions]);

  const fetchSubscriptions = () => {
    setLoading(true);
    getSubscriptions(userEmail)
      .then(({ suppressions }) => {
        suppressions.forEach(item => {
          const setterFunction = settersObject[item.name.trim()];
          if (setterFunction) {
            setterFunction(false);
          }
        });
      })
      .catch(error => {
        console.error('Failed to fetch email subscriptions:', error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const onSubscriptionChanges = () => {
    const { newSuppressions, newDeletions } = getEmailSuppressionsAndDeletions(valuesObject);
    postSubscriptions(userEmail, newSuppressions).then(() => {
      console.log('Subscriptions suppression posted successfully');
    });
    deleteSubscriptions(userEmail, newDeletions).then(() => {
      console.log('Subscriptions suppressions deleted successfully');
    });
    toast.success('Updated Subscription Settings');
  };

  return { loading, isAuthorized, onSubscriptionChanges };
};

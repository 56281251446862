import React from 'react';

import { LineChart } from '@rockerbox/styleguide';
import { Table, Icon } from 'semantic-ui-react';

import { track } from '../../../../utils/tracking';
import { numberFormatter } from '../../../utils/valueFormatter';
import { generateTicks } from '../helpers';
import { HourlyLabelFormatter } from '../parts';

export const ActionCell = (tierColorMap, account) => ({ item, col, handleRowExpand, isExpanded }) => {
  const handleExpand = () => {
    track('data.event_status.expand_action', {
      advertiser: account.pixel_source_name,
    });

    handleRowExpand();
  };

  return (
    <Table.Cell>
      <Icon name={isExpanded ? 'chevron down' : 'chevron right'} style={{ color: tierColorMap[item[col.key]], cursor: 'pointer' }} onClick={handleExpand} />
      <span>{item[col.key]}</span>
    </Table.Cell>
  );
};

export const NumberCell = ({ item, col }) => (
  <Table.Cell>
    {numberFormatter(item[col.key])}
  </Table.Cell>
);

const percentFormatter = number => (`${parseFloat(number.toFixed(2))}%`);

export const HourlyLineChart = ({ active, hourlyDetails }) => {
  const chartData = hourlyDetails.map(c => ({ date: c.date, [active]: c.percent_populated * 100 }));

  return (
    <div>
      <h2 style={{ textAlign: 'center' }}>{active}</h2>
      <LineChart
        {...{ height: 300, data: chartData, labelFormatter: HourlyLabelFormatter }}
        showTooltip
        showXAxis
        XAxisFormatter={generateTicks}
        dottedLineForInfinity
        sortKey="value"
        domainDefault={100}
        formatter={percentFormatter}
      />
    </div>
  );
};

import React, { useMemo } from 'react';

import { AreaChartLoader } from '@rockerbox/styleguide';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { Header } from 'semantic-ui-react';

import FitChart from './FitChart';
import { Tooltip as InfoTooltip } from '../../../components';
import { roundNumber } from '../helpers';

const InfoTooltipContent = ({ rSquared, mapeRounded }) => (
  <>
    {rSquared < 100 && (
      <p>
        <strong>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          R<sup>2</sup>
        </strong>
        &nbsp;
        is the coefficient of determination. It is a measure of how well the model fits the data. The higher the value, the better.
      </p>
    )}
    {mapeRounded < 100 && (
      <p>
        <strong>MAPE</strong>
        &nbsp;
        is the mean average percentage error. It is a measure of the model&apos;s prediction accuracy. The lower the value, the better.
      </p>
    )}
  </>
);

const PredVsActualCharts = ({ predVsActual, isConversionKPI }) => {
  const monthlyBreakdown = useMemo(() => {
    if (!predVsActual?.length) return undefined;
    const reformatDateToBeFirstOfMonth = d => (`${d.split('-').slice(0, 2).join('-')}-01`);
    return d3.nest()
      .key(row => reformatDateToBeFirstOfMonth(row.date))
      .rollup(rows => ({
        date: reformatDateToBeFirstOfMonth(rows[0].date),
        actual: d3.sum(rows, row => row.actual),
        pred: d3.sum(rows, row => row.pred),
        delta: d3.sum(rows, row => Math.abs(row.actual - row.pred)),
      }))
      .entries(predVsActual)
      .map(row => row.values);
  }, [predVsActual]);

  if (!predVsActual?.length) {
    return (
      <>
        <AreaChartLoader style={{ marginBottom: 50 }} />
        <AreaChartLoader style={{ marginBottom: 50 }} />
      </>
    );
  }

  return (
    <>
      <FitChart
        data={predVsActual}
        isConversionKPI={isConversionKPI}
        chartType="line"
        dataKeys={[
          { dataKey: 'actual', color: '#1C8ED1' },
          { dataKey: 'pred', color: '#FF9615' },
        ]}
      />
      <FitChart
        data={monthlyBreakdown}
        isConversionKPI={isConversionKPI}
        chartType="bar"
        dataKeys={[
          { dataKey: 'actual', color: '#1C8ED1' },
          { dataKey: 'pred', color: '#FF9615' },
        ]}
      />
    </>
  );
};

const ModelPredVsActual = ({ predVsActual, metrics, isConversionKPI }) => {
  const { rsq_train, mape } = metrics || {};

  const rSquared = roundNumber(rsq_train * 100);
  const mapeRounded = roundNumber(mape * 100);
  const kpiText = isConversionKPI ? 'Conversions' : 'Revenue';

  return (
    <>
      <Header as="h3">
        Training Period
      </Header>
      <Header as="h4">
        {`Predicted vs. Actual ${kpiText}`}
        <span style={{ float: 'right', color: '#FF9615', fontFamily: 'Poppins' }}>
          {(rSquared < 100 || mapeRounded < 100) && (
            <InfoTooltip
              position="left center"
              content={<InfoTooltipContent {...{ rSquared, mapeRounded }} />}
              style={{ fontSize: 14, marginRight: 10, marginBottom: 5 }}
            />
          )}
          {rSquared < 100 && (
            <>
              R
              <sup>2</sup>
              {` =${rSquared}%`}
            </>
          )}
          {mapeRounded < 100 && (
            <>
              &nbsp;&nbsp;&nbsp;
              {`   MAPE = ${mapeRounded}%`}
            </>
          )}
        </span>
      </Header>
      <Header as="h5"> Daily </Header>
      <PredVsActualCharts {...{ predVsActual, isConversionKPI }} />
    </>
  );
};

export default ModelPredVsActual;

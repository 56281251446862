import React from 'react';
import styled from 'styled-components';
import Container from '../../../../components/parts/Container';

const CodeBlockStyles = styled(Container)`
  font-family: Monaco, MonoSpace !important;
  color: black;
  padding: 10px;
  white-space: pre-line;
  margin-right: 5px;
  background-color: #f5f5f5;
`;

const CodeBlock = ({ code, height }) => (
  <CodeBlockStyles width="900" height={height}>
    <pre>
      {code}
    </pre>
  </CodeBlockStyles>
);

export default CodeBlock;

import React, { Component } from 'react';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';

import { Error } from '../styled';

export const Row = styled.div.attrs({"className":"rowContainer"})`
  margin-top:${props => props.marginTop || '15px' };
  margin-bottom:${props => props.marginBottom || '20px' };
  display: flex;
  flex-direction: ${props => props.direction || 'row' };
  flex: 1;
`

export const RowItem = styled.div`
  display: flex;
  flex-direction: column;
  padding-right:15px;
  padding-top: ${props => props.paddingTop || 0 };
  flex: ${props => props.flex || 1 };;
  justify-content: ${props => props.justify|| 'inherit' };
  text-align: ${props => props.justify|| 'inherit' };
`

export const Wrapper = styled.div`
  flex:1; 
  width: 100%; 
`


export const MiniStat = ({value, format, suffix}) => (
  <RowItem style={{fontSize:".9em",opacity:.8}}>
    <div style={{textAlign:"center"}}>
      (

      {value > 0 ? <FontAwesome color='green' name="arrow-up" /> : <FontAwesome color='red' name="arrow-down" />}
      <span> {value}% </span>
      <span style={{fontSize:".9em"}}> {suffix}</span>
      )
    </div>
  </RowItem>
)

export const CenteredRowItem = (props) => (
  <Row marginTop="0px" marginBottom="0px" >
    <RowItem justify="center">
       {props.children}
    </RowItem>
  </Row>
)

export const PaddedRowItem = (props) => (
  <Row style={props.style} >
    <RowItem />
    <RowItem flex={8} style={{minWidth:"1000px"}}>
      {props.children}
    </RowItem>
    <RowItem />
  </Row>
)

export const Err = ({ selected }) => <Error>No data found for {selected.action_name}</Error>

export const encode = value => encodeURIComponent(value).replaceAll('%25', '_PERCENT_');
export const decode = value => (value || value === 0) && decodeURIComponent(value.replaceAll('_PERCENT_', '%25'));
export const convertPctURL = val => val.replace(/%(?!\d|[ABCDEF]+)/g, '%25');
export const urlDecodeTierStr = val => decode(convertPctURL(val));

export const urlDecodeTier = tiers => {
  if (!tiers) return tiers;

  return Object.entries(tiers).reduce((accu, cur) => {
    const [key, value] = cur;
    if (value) accu[key] = decodeURIComponent(value);
    return accu;
  }, {});
};

export const urlDecodeTierAfterSplit = tiers => {
  if (!tiers) return tiers;

  return Object.entries(tiers).reduce((accu, cur) => {
    const [key, value] = cur;
    if (value) accu[key] = value.split(',').map(val => decode(convertPctURL(val)));
    return accu;
  }, {});
};

export const urlEncodeTier = tiers => {
  if (Array.isArray(tiers)) return tiers.map(tier => encode(tier));
  return encode(tiers);
};

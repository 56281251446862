import React, { Component } from 'react';
import { ContentCard, IndexGrid, SortableIndexGrid } from '@rockerbox/styleguide'
import { Button, Table, Label } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import * as routes from '../../routes';
import { getAttributableEvents, deleteAttributableEvent } from '../../utils/api';

export default class ExcludeEvents extends Component {

  state= {
    resolved: false,
    exclusionsData: false,
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    getAttributableEvents()
    .then(data => {
      const exclusionsData = data.filter(c => c.type === "exclude");
      this.setState({exclusionsData});
    })
  }

  deleteEvent = id => {
    deleteAttributableEvent(id).then(d => this.getData());
  };

  render() {
    const { exclusionsData } = this.state;

    const ManageCell = ({ item }) => {
      let route = routes.editExcludedEvent;
      route+= '/' + item.id;

      if (!!item.read_only) return(
        <Table.Cell collapsing>
          <Label content='Default' icon='lock' />
        </Table.Cell>
      )

      return(
        <Table.Cell collapsing>
          <IndexGrid.EditButton url={route}/>
          <IndexGrid.DeleteButton onDelete={() => this.deleteEvent(item.id)}/>
        </Table.Cell>
      )
    }

    let attributableCols = [
      { display: "Rule Name", key: "name" },
      { display: "Status", key: "active", as: IndexGrid.CelledCheckmark },
      { display: "Priority", key: "order" },
      { display: "Manage", key: "manage", as: ManageCell, sortable: false }
    ]

    return (
      <div>
        <ContentCard
          title={'Exclusion Rules'}
          hasTable
          explainer={'Exclusion rules will remove irrelevant events (e.g. password resets, payment processing) from your attribution reports. You can use information from the URL to create custom rules and define what should be excluded.'}
          topRight={
            <Button
              primary
              compact
              size="mini"
              icon="plus"
              content={'New Exclusion Rule'}
              as={Link}
              to={'exclusions/create'}
            />
          }>
          {<SortableIndexGrid data={exclusionsData} cols={attributableCols} as={ContentCard.Table} fallbackMsg="No Exclusions"/> }
        </ContentCard>
      </div>
    )
  }
}

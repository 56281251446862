import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'rockerbox_d3_legacy_clone';
import correlationChart from './correlationplot';
import { Select } from 'semantic-ui-react';
import { ContentCard } from '@rockerbox/styleguide';
import { ClusterSizeOverview, ClusterDetails, CorrelationSummary } from './parts';
import './corr.css';

const OPTIONS = [
  {"key":"all", "text":"All clusters", value:"all"},
  {"key":"large", "text":"Large clusters", value:"large"},
  {"key":"medium", "text":"Medium clusters", value:"medium"},
  {"key":"small", "text":"Small clusters", value:"small"},
  {"key":"none", "text":"No highlighting", value: "none"},
]

function processData(data, key) {
  const values = d3.nest()
    .key(x => x[key])
    .rollup()
    .entries(data)
    .map(group => {
      group.total_correlation = 0 
      group.unique = new Set()
      group.values.map((c) => {
        c.small = c.group
        c.medium = c.medium_group
        c.large = c.large_group

        if (c.value == 1) return
        group.total_correlation += c.value||0
        group.unique = group.unique.add(c.x_label);
      })
      group.count = group.unique.size || 1
      group.avg_correlation = (group.total_correlation/group.count)||0
      return group
    })

  const summary = values.reduce((p,c) => {
    p.count = (p.count||0) + 1
    p.total_count = (p.total_count||0) + c.count;
    p.total_corr = (p.total_corr||0) + c.avg_correlation;
    return p
  },{})

  const avg_correlation = summary.total_corr/summary.count;
  const avg_count = summary.total_count/summary.count;

  return { values, avg_correlation, avg_count }

}

class CorrelationPlot extends Component {
  state = {
    width: false,
    highlighting: "all",
    summary: {
      small: {},
      medium: {},
      large: {},
    }
  }
  renderD3() {
    const { title, data } = this.props;
    const { width, highlighting } = this.state;
    const target = ReactDOM.findDOMNode(this.refs.correlationplot);
    const chart = correlationChart(parseInt(width/3*2))
      .title(title)
      .highlight(highlighting)
      .onHover(this.updateGroup)

    d3
      .select(target)
      .selectAll('svg')
      .remove();

    d3
      .select(target)
      .datum(data)
      .call(chart);
  }

  getDimensions() {
    const target = ReactDOM.findDOMNode(this.refs.correlationplot);
    if (target) {
      const parent = target.parentNode.parentNode
      const x = (this.state.width && (this.state.width === parent.offsetWidth)) ? false : this.setState({"width": parent.offsetWidth});
    }
  }

  createSummary() {
    const { data } = this.props;
    const small = processData(data, "group")
    const medium = processData(data, "medium_group")
    const large = processData(data, "large_group")

    const summary = { small, medium, large }
    return summary
  }

  updateGroup = (item) => {
    const { highlighting, summary } = this.state;
    const group = item[highlighting]
    if (!group) return {}

    const summaryGroup = summary[highlighting].values.filter(x => x.key == group)[0]
    debugger
    summaryGroup.values.reduce((p,c) => {
      const splitted = c.x_label.split("|")
      p.tier_1 = (p.tier_1||new Set()).add(splitted[0])
      p.tier_2 = (p.tier_2||new Set()).add(splitted[0])
      p.tier_3 = (p.tier_3||new Set()).add(splitted[0])
      p.tier_4 = (p.tier_4||new Set()).add(splitted[0])
      p.tier_5 = (p.tier_5||new Set()).add(splitted[0])
      return p
    },summaryGroup)

    const correlationSummary = {
      x: item.x_label.split("|"),
      y: item.y_label.split("|"),
      correlation: item.value
    }

    this.setState({ summaryGroup, correlationSummary })
  }

  updateHighlight = (highlighting) => {
    const [ summaryGroup, correlationSummary ] = [false, false];
    this.setState({ highlighting, summaryGroup, correlationSummary });
  }

  componentDidMount() {
    this.getDimensions();
    const summary = this.createSummary();
    this.setState({ summary });
    this.renderD3();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.highlighting != this.state.highlighting) ||
      (prevState.width != this.state.width) 
    )this.renderD3();
  }

  render() {

    const currentSummary = this.state.summary[this.state.highlighting];
    const { correlationSummary, summaryGroup } = this.state;
    return (
    <ContentCard title="Feature Correlation">
      <div style={{display:"flex"}}>
        <div style={{width: `${this.state.width/3}px`}} >
          <div>
            <h3>Clustering Strategy</h3>
            <Select 
              options={OPTIONS} 
              value={this.state.highlighting} 
              onChange={(_, data) => this.updateHighlight(data.value) }
            />
          </div>
          <div>
            { currentSummary && <ClusterSizeOverview currentSummary={currentSummary} /> }
            { summaryGroup && <ClusterDetails summaryGroup={summaryGroup} /> }
            { correlationSummary && <CorrelationSummary correlationSummary={correlationSummary} /> }
          </div>
          
        </div>
        <div style={{position:"relative"}}>
          <div ref="correlationplot" />
        </div>
      </div>
    </ContentCard>
    );
  }
}

export default CorrelationPlot;

import React from "react";
import { ContentCard, PagedIndexGrid, IndexGrid } from "@rockerbox/styleguide";
import { groupByDate } from "./helper";
import SummarySelector from "./SummarySelector";
import ErrorPage from "./ErrorPage";

const cols = [
  { display: "Tier One", key: "tier_one" },
  { display: "Tier Two", key: "tier_two" },
  { display: "Tier Three", key: "tier_three" },
  { display: "Tier Four", key: "tier_four" },
  { display: "Tier Five", key: "tier_five" },
  { display: "Num Days", key: "num_days" }
];

const OttDataSource = props => {
  const {
    timePicker,
    summary,
    creativeData,
    activeView,
    setActiveView,
    expandedKey,
    setExpandedKey,
    tierKeyDict,
    firstMenu,
    loading,
    error
  } = props;
  const { startDate, endDate } = props.match.params;
  const dataType = firstMenu && firstMenu.split("_")[1];

  const tableData = (creativeData && creativeData[activeView]) || [];

  const expandedSection = ({ item }) => {
    if (item.key !== expandedKey) {
      setExpandedKey(item.key);
      return null;
    }
    const { tier_one, tier_two, tier_three, tier_four, tier_five } = item;

    const newKey = `${tier_one};${tier_two};${tier_three};${tier_four};${tier_five}`;

    const expandedData =
      activeView === "total"
        ? groupByDate(tierKeyDict[newKey])
        : tierKeyDict[newKey] &&
          tierKeyDict[newKey].filter(item => item.key === activeView);

    const cols = [
      { display: "Date", key: "date" },
      { display: "Count", key: "count" }
    ];

    const sortedData =
      expandedData &&
      expandedData.sort((a, b) => {
        return (
          parseInt(b.date.replace(/\-/g, "")) -
          parseInt(a.date.replace(/\-/g, ""))
        );
      });

    return <IndexGrid data={sortedData} cols={cols} key={activeView} />;
  };

  const datasetList = [
    {
      value: summary["total"] || 0,
      label: "Total",
      type: "total"
    },
    {
      value: summary[`${dataType}_ott_device_events`] || 0,
      label: "OTT Device",
      type: `${dataType}_ott_device_events`
    },
    {
      value: summary[`${dataType}_web_events`] || 0,
      label: "OTT Web",
      type: `${dataType}_web_events`
    },
    {
      value: summary[`${dataType}_in_app_events`] || 0,
      label: "OTT Mobile App",
      type: `${dataType}_in_app_events`
    }
  ];

  if (error) {
    return <ErrorPage />;
  }

  return (
    <React.Fragment>
      <SummarySelector
        activeView={activeView}
        onClick={e => setActiveView(e)}
        datasetList={datasetList}
        loading={loading}
      />
      <ContentCard
        hasTable
        title="OTT Data Source"
        explainer=""
        loading={loading}
      >
        <PagedIndexGrid
          itemsPerPage={100}
          expandable={true}
          data={tableData}
          cols={cols}
          as={ContentCard.Table}
          expandedComponent={expandedSection}
          idKey="key"
          fallbackMsg="No customer data"
        />
      </ContentCard>
    </React.Fragment>
  );
};

export default OttDataSource;

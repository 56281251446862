import React, { useEffect } from 'react'
import { Segment, Button, Grid, Icon, Input, Form, Header } from 'semantic-ui-react';

const IncompleteStep = ({ icon, message, buttonText, prevStep, className, setActiveStep }) => {

  return (
    <Segment className={className}>
      <Icon name={icon} size='huge' />
      {message}
      <Button
        size='large'
        primary
        fluid
        color='purple'
        className='m-t-15 m-b-10'
        onClick={() => setActiveStep(prevStep)}
      >
        {buttonText}
      </Button>
    </Segment>
  )
}

export default IncompleteStep;

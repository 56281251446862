import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { Header, Icon } from 'semantic-ui-react';
import * as Sentry from '@sentry/react';

const wrapStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'calc(100vh - 100px)',
}

class ErrorBoundary extends React.Component {
  state = {
      hasError: false
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    // Will only catch errors within render function
    Sentry.captureException(error);
    return {hasError: true}
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // Will only catch errors in lifecycle methods
    Sentry.captureException(error);
    this.setState({hasError: true});
  }

  componentDidUpdate(prevProps, prevState) {
    const { hasError } = this.state;
    if(prevState.hasError !== hasError) {
      this.props.handleBoundaryTriggered();
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={wrapStyles}>
          <Icon name="warning sign" size="massive" color="yellow"/>
          <Header
            as='h2'
            content='Sorry, we ran into a technical error'
            subheader="We've logged the issue so we can look into the source of the problem"
            textAlign='center'
          />
          <Button
            primary
            size='large'
            onClick={this.props.handleReset}
            >
            Try Again
          </Button>
          <p style={{ marginTop: 12, textAlign: 'center', opacity:0.75 }}>
            Still not working?
            Try going back to the <a href='/'>Home Page</a>.
          </p>
        </div>
      );
    }
    return this.props.children
  }
}

class GlobalErrorBoundary extends Component {
  state = {
    errorBoundaryKey: 0,
    boundaryTriggered: false
  }

  handleReset = () => {
    const { errorBoundaryKey, boundaryTriggered } = this.state;
    if(boundaryTriggered) {
      this.setState({errorBoundaryKey: errorBoundaryKey + 1, boundaryTriggered: false})
    }
  }

  handleBoundaryTriggered = () => {
    this.setState({boundaryTriggered: true})
  }

  componentDidUpdate(prevProps, prevState) {
    const { pathname } = this.props.location;
    const { boundaryTriggered } = this.state;
    if(prevProps.location.pathname !== pathname) {
      this.handleReset();
    }
  }

  render() {
    return (
      <ErrorBoundary key={this.state.errorBoundaryKey} handleReset={this.handleReset} handleBoundaryTriggered={this.handleBoundaryTriggered}>
        {this.props.children}
      </ErrorBoundary>
    )
  }
}

export default withRouter(GlobalErrorBoundary);

import React, { useEffect, useState, useMemo, useContext } from 'react';

import { SplitLayout } from '@rockerbox/styleguide';
import { Table, Pagination } from 'semantic-ui-react';

import { ParamsContext } from './index';
import { HourlyLineChart } from './parts';
import { formatPercent } from '../../../../utils/format_helpers';
import { GenericNoDataMessage } from '../../../components';
import { selectData, calculatePagerLength, recordsFrom, recordsTo } from '../../../utils/pagination';

const ExpandedData = ({ item }) => {
  const { paramsSummary, paramsHourly } = useContext(ParamsContext);
  const { action } = item;
  // eslint-disable-next-line react/destructuring-assignment
  const summary = paramsSummary?.[0] || {};
  const variables = summary[action] || [];
  const firstItem = variables?.[0];
  const params = paramsHourly?.[0];
  const itemsPerPage = 10;

  const [selectedPageNumber, setSelectedPageNumber] = useState(1);
  const [hourlyDetails, setHourlyDetails] = useState([]);
  const [hover, setHover] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (!firstItem || !params || !params[action]) return;
    const { param_name } = firstItem;
    const matchingItem = params[action].find(c => c.param_name === param_name);

    setActive(param_name);
    setHourlyDetails(matchingItem?.hourly_stats || []);
  }, [firstItem, params, action]);

  const selectedData = useMemo(() => (
    selectData(variables, itemsPerPage, selectedPageNumber)
  ), [variables, itemsPerPage, selectedPageNumber]);

  const pageNumbers = useMemo(() => (
    calculatePagerLength(variables, itemsPerPage)
  ), [variables, itemsPerPage]);

  const { startingRecord, endingRecord, totalRecords } = useMemo(() => {
    const from = recordsFrom(selectedPageNumber, itemsPerPage);
    const to = recordsTo(selectedPageNumber, itemsPerPage, variables.length);
    const all = new Intl.NumberFormat().format(variables.length);

    return { startingRecord: from, endingRecord: to, totalRecords: all };
  }, [selectedData, selectedPageNumber]);

  const handleRowClick = param_name => {
    const { hourly_stats } = params[action].find(c => c.param_name === param_name);
    setActive(param_name);
    setHourlyDetails(hourly_stats);
  };

  if (selectedData && selectedData.length === 0) {
    return <GenericNoDataMessage />;
  }

  return (
    <SplitLayout
      leftWidth={4}
      leftContent={(
        <div>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Variable</Table.HeaderCell>
                <Table.HeaderCell>% of pixels with variable</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                selectedData.map(c => (
                  <Table.Row
                    onClick={() => handleRowClick(c.param_name)}
                    onMouseEnter={() => setHover(c.param_name)}
                    onMouseLeave={() => setHover(false)}
                    active={hover === c.param_name || active === c.param_name}
                    style={{ cursor: 'pointer' }}
                  >
                    <Table.Cell>{c.param_name}</Table.Cell>
                    <Table.Cell>{formatPercent(c.percent_populated)}</Table.Cell>
                  </Table.Row>
                ))
              }
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="100">
                  <div className="footer-content">
                    <div>
                      {`Showing ${startingRecord}-${endingRecord} out of ${totalRecords} records`}
                    </div>
                    <Pagination
                      onPageChange={(e, { activePage }) => setSelectedPageNumber(activePage)}
                      totalPages={pageNumbers}
                      firstItem={false}
                      lastItem={false}
                      ellipsisItem={pageNumbers > 4 ? undefined : null}
                      activePage={selectedPageNumber}
                    />
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      )}
      rightWidth={12}
      rightContent={(<HourlyLineChart {...{ active, hourlyDetails }} />)}
    />
  );
};

export default ExpandedData;

import React from 'react';
import { Label, Icon, Grid } from 'semantic-ui-react';
import { colorHash } from '../../utils/colorHash';

const COMPARE_LABEL_COLORS = { blue: '#3e70cc', gray: '#d9d9d9' };

export const determineOpacity = number => {
  const magnitude = Math.abs(number);
  if (number === '-') return 0.0;
  if (magnitude < 10) return 0.2;
  if (magnitude < 20) return 0.3;
  if (magnitude < 30) return 0.4;
  if (magnitude < 40) return 0.7;
  if (magnitude < 50) return 0.9;
  return 1.0;
};

export const hexToRGBA = (hex, opacity) => {
  const hexProcessed = hex.replace('#', '');
  const r = parseInt(hexProcessed.substring(0, 2), 16);
  const g = parseInt(hexProcessed.substring(2, 4), 16);
  const b = parseInt(hexProcessed.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const determineTextColor = (isNeutral, isPositiveDiff, trendReversed) => {
  if (isNeutral) return 'black';
  if (isPositiveDiff && trendReversed) return 'black';
  if (!isPositiveDiff && !trendReversed) return 'black';
  return 'white';
};

const CompareLabel = ({ diff, selectedMetric }) => {
  const { blue, gray } = COMPARE_LABEL_COLORS;

  const isDash = diff === '-';
  const isInfinity = (diff === Number.POSITIVE_INFINITY || diff === Number.NEGATIVE_INFINITY) || diff === 'Infinity';
  const isNaN = Number.isNaN(diff) || diff === 'NaN'; // .toFixed coerces this to a string
  const isNeutral = (diff === 0 || isDash || isNaN);
  const trendReversed = selectedMetric === 'cpa';
  const isPositiveDiff = diff > 0;
  const opacity = determineOpacity(diff);
  const background = isNeutral ? '#f2f2f2'
    : isPositiveDiff
      ? trendReversed
        ? hexToRGBA(gray, opacity)
        : hexToRGBA(blue, opacity)
      : trendReversed
        ? hexToRGBA(blue, opacity)
        : hexToRGBA(gray, opacity);
  const color = determineTextColor(isNeutral, isPositiveDiff, trendReversed);
  const content = isInfinity ? ` ${String.fromCharCode(8734)} ` : isNaN ? 0 : diff;

  return (
    <div>
      <Label className="compare-label-tooltip" style={{ background, color }}>
        {`${diff > 0 ? '+' : ''}${content}%`}
      </Label>
    </div>
  );
};

// eslint-disable-next-line max-len
const CompareChartTooltip = ({ active, payload, tierColorMap, nameMap = {}, labelFormatter = x => x, formatter = x => x, showTotalInToolTip = true, hideKeys, dataKey, allCaps = false, selectedMetric }) => {
  if (active && payload && payload.length) {
    const capitalize = allCaps ? word => word.toUpperCase() : word => word.charAt(0).toUpperCase() + word.slice(1);

    const d = payload[0]?.payload;

    const label = d.date;
    const compareLabel = d.compare_date;

    const data = payload[0]?.payload[label];
    const compareData = payload[0]?.payload[compareLabel];
    // MUST INCLUDE `total` AS KEY IN DATA
    const { total } = data;
    const totalCompare = compareData.total;

    const totalDiff = (((total - totalCompare) / totalCompare) * 100).toFixed(2);

    return (
      <div
        style={{
          zIndex: 100,
          padding: 10,
          width: '500px',
          borderRadius: 4,
          background: '#fff',
          color: '#000',
          boxShadow: '0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15)',
          fontSize: 12,
        }}
      >
        <Grid>
          {/* header row */}
          <Grid.Row columns={20} style={{ fontWeight: '600', marginBottom: '-20px' }}>
            <Grid.Column width={6} />
            <Grid.Column width={3}>
              {labelFormatter(label)}
            </Grid.Column>
            <Grid.Column width={3}>
              {labelFormatter(compareLabel)}
            </Grid.Column>
            <Grid.Column width={4} />
          </Grid.Row>
          {/* body rows */}
          {Object.keys(data)
            .filter(name => name.length > 0 && name !== dataKey && !hideKeys?.includes(name) && name !== 'date' && name !== 'total')
            .sort()
            .map(name => {
              const diff = (((data[name] - compareData[name]) / compareData[name]) * 100).toFixed(2);
              const colorTip = tierColorMap[name] || colorHash(name);
              return (
                <Grid.Row columns={20} style={{ marginTop: 5, paddingBottom: 2, height: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Grid.Column width={6} style={{ textAlign: 'left' }}>
                    <Icon name="square" style={{ color: colorTip }} />
                    {nameMap[name] || capitalize(name)}
                  </Grid.Column>
                  <Grid.Column width={3}>
                    {formatter(data[name])}
                  </Grid.Column>
                  <Grid.Column width={3}>
                    {formatter(compareData[name])}
                  </Grid.Column>
                  <Grid.Column width={4}><CompareLabel diff={diff} selectedMetric={selectedMetric} /></Grid.Column>
                </Grid.Row>
              );
            })}
          {/* total row */}
          {showTotalInToolTip && (
            <>
              <p
                key="total"
                style={{
                  marginTop: '20px',
                  width: '95%',
                  height: '10px',
                  marginLeft: '13px',
                  borderTop: '1px solid grey',
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                }}
              />
              <Grid.Row columns={20} style={{ textAlign: 'left', marginTop: '-20px' }}>
                <Grid.Column width={6} style={{ paddingLeft: '30px' }}>
                  Total
                </Grid.Column>
                <Grid.Column width={3}>
                  {formatter(total)}
                </Grid.Column>
                <Grid.Column width={3}>
                  {formatter(totalCompare)}
                </Grid.Column>
                <Grid.Column width={4} style={{ marginTop: '-5px' }}><CompareLabel diff={totalDiff} selectedMetric={selectedMetric} /></Grid.Column>
              </Grid.Row>
            </>
          )}
        </Grid>
      </div>
    );
  }
  return null;
};

export default CompareChartTooltip;

import React, { Component } from 'react';
import SelectConversion from '../../SelectSegment';
import * as routes from '../../../routes';

import { Input, Grid, Form, Header, Menu, Icon, Dropdown } from 'semantic-ui-react';

const InlineDropdown = (props) => {
  return (
    <Dropdown {...props} inline preventFluid={true}/>
  )
}

class SelectConversionType extends Component {
  render() {
    const { modelRuns, runId, setRun } = this.props

    return (
      <React.Fragment>
        <Grid fluid="true" columns="equal">
          <Grid.Row className="segment-header">
            <Grid.Column>
              <Header as="h3">
                Conversion:{" "}
                <SelectConversion
                  endpoint={id => routes.modelsView + '/' + id}
                  {...this.props}
                  as={InlineDropdown}
                />
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    )
  }
}

export default SelectConversionType;

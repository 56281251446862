/* eslint-disable react/no-unused-prop-types */
import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Statistic, Header, Divider, Card } from 'semantic-ui-react';

const generateStatistics = ({
  statisticLabel, alignLabel = '', statisticValue, labelTop = true,
  bottom = 10, left = 10, top = 0, right = 10,
  format, maxDigits, color = '#000', icon, iconBg, fontWeight = 300, currencyCode = 'USD',
}) => {
  const formatNumber = ({ value, formatType, maxFractionDigits }) => {
    const numberFormatOptions = {
      style: formatType || 'decimal',
      maximumFractionDigits: maxFractionDigits || 0,
    };

    if (formatType === 'currency') numberFormatOptions.currency = currencyCode;

    const numberFormatter = new Intl.NumberFormat('en-US', numberFormatOptions);
    const formatted = numberFormatter.format(value);

    return formatted;
  };

  const padding = `${top}px ${right}px ${bottom}px ${left}px`;

  return (
    <div style={{ position: 'absolute', bottom: 0, padding }}>
      <Statistic vertical size="tiny" className="chart-card-statistic">
        {labelTop && <Statistic.Label style={{ textAlign: alignLabel, marginBottom: 5, opacity: 0.8 }}>{statisticLabel}</Statistic.Label>}
        <Statistic.Value style={{ color, fontWeight }}>
          {formatNumber({ value: statisticValue, formatType: format, maxFractionDigits: maxDigits })}
        </Statistic.Value>
        {!labelTop && <Statistic.Label style={{ textAlign: 'left' }}>{statisticLabel}</Statistic.Label>}
        {!!icon && (
          <div className="bubble-icon" style={{ background: iconBg }}>
            {icon}
          </div>
        )}
      </Statistic>
    </div>
  );
};

const ChartCard = ({ header, headerImage, chart, statistics, footerLinks, topRight, link: cardLink, content, className }) => (
  <Card
    fluid
    {...{ link: cardLink }}
    as={cardLink && Link}
    to={cardLink}
    className={`chart-card ${className}`}
  >
    <Card.Content>
      {!!topRight && <div className="top-right">{topRight}</div>}
      {(!!header || !!headerImage) && (
        <div className="title">
          <Header as="h4">
            {!!headerImage && <img src={headerImage} alt="header" />}
            {header}
          </Header>
        </div>
      )}
      <div style={{ position: 'relative', minHeight: '6em', maxHeight: '20em', overflow: 'hidden' }}>
        {!!chart && (
          <div style={{ marginLeft: -25, marginRight: -25, marginBottom: -5, marginTop: 5 }}>
            {chart}
          </div>
        )}
        <div>
          {statistics && statistics.map((obj, ix) => generateStatistics(obj, ix))}
        </div>
      </div>
      {content && (
        <>
          <Divider style={{ margin: 0 }} />
          <div style={{ flexGrow: 1 }}>
            {content}
          </div>
        </>
      )}
      {!!footerLinks?.length && (
        <>
          <Divider style={{ margin: 0 }} />
          <div className="footer">
            {footerLinks.map(({ HeroIcon, text, link }, i) => (
              <Link
                key={i}
                to={link}
              >
                <HeroIcon />
                {text}
              </Link>
            ))}
          </div>
        </>
      )}
    </Card.Content>
  </Card>
);

export default ChartCard;

ChartCard.propTypes = {
  as: PropTypes.element,
  header: PropTypes.string,
  headerImage: PropTypes.element,
  chart: PropTypes.element,
  statistics: PropTypes.arrayOf({
    statisticLabel: PropTypes.string,
    alignLabel: PropTypes.string,
    statisticValue: PropTypes.element,
    labelTop: PropTypes.bool,
    bottom: PropTypes.string,
    left: PropTypes.string,
    top: PropTypes.string,
    right: PropTypes.string,
  }),
  footerText: PropTypes.string,
  footerLink: PropTypes.string,
};

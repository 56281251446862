class TreeNode {
  constructor(val) {
    this.key = val;
    this.isPath = false;
    this.children = {};
    this.pathIds = { first: [], mid: [], last: [] };
  }

  getAllPathIds(position) {
    const allIds = [];
    const collectIds = pathIds => {
      if (position === 'any') {
        return [...pathIds.first, ...pathIds.mid, ...pathIds.last];
      }
      return pathIds[position];
    };
    const dfs = node => {
      allIds.push(...collectIds(node.pathIds));
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const child in node.children) {
        dfs(node.children[child]);
      }
    };
    dfs(this);
    return allIds;
  }
}

// eslint-disable-next-line func-names
export const Trie = function () {
  this.root = new TreeNode();
};

// eslint-disable-next-line func-names
Trie.prototype.insert = function (touchpoint, pathId, position) {
  let curNode = this.root;
  for (let i = 0; i < touchpoint.length; i++) {
    const hasChildNode = curNode.children[touchpoint[i]];

    if (!hasChildNode) {
      curNode.children[touchpoint[i]] = new TreeNode(touchpoint[i]);
    }
    curNode = curNode.children[touchpoint[i]];
    if (i === touchpoint.length - 1) {
      curNode.isPath = true;
      curNode.pathIds[position].push(pathId);
    }
    if (touchpoint[i + 1] === '') {
      curNode.isPath = true;
      curNode.pathIds[position].push(pathId);
      return;
    }
  }
};

// eslint-disable-next-line func-names
Trie.prototype.search = function (touchpoint) {
  let current = this.root;
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const tier of touchpoint) {
    if (current.children[tier] === undefined) {
      return false;
    }
    current = current.children[tier];
  }
  return current;
};

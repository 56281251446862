import React, { Component } from 'react';
import { Step, Loader } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { getFacebookSyntheticConfigs } from '../../../utils/api';

import View from './view';
import Form from './form';
import PixelSetting from './pixelSetting';

const Steps = ({ current, refresh }) => {
  const steps = ['Setup'];
  const [activeStep, setActiveStep] = React.useState(steps[0]);

  return (
    <>
      <Step.Group widths={steps.length} size="mini">
        {
      steps.map(value => (
        <Step active={activeStep === value} onClick={() => setActiveStep(value)}>
          <Step.Content><Step.Title>{ value }</Step.Title></Step.Content>
        </Step>
      ))
    }
      </Step.Group>

      { activeStep === 'Setup' && (
      <>
        <Form data={current} id={current.filter_id} onSuccess={refresh} />
        <PixelSetting />
      </>
      )}
    </>
  );
};

class FacebookComparison extends Component {
  state = { // eslint-disable-line react/state-in-constructor
    response: undefined,
  };

  UNSAFE_componentWillMount() {
    this.getData();
  }

  async getData() {
    const response = await getFacebookSyntheticConfigs();
    this.setState({ response });
  }

  render() {
    const { response } = this.state;
    const { match: { params: { type, id } } } = this.props;

    if (response === undefined) return <Loader active inline="centered" />;

    const current = response && id
      ? response.find(row => row.id === id) : response
        ? response[0] : {};

    if (current && (type === undefined)) {
      return (
        <Steps {...{ current, refresh: () => {} }} />
      );
    }

    return (
      <>
        { type === 'view' && id && <View data={current} id={current.filter_id} />}
        { type === 'edit' && id && <Form data={current} id={current.filter_id} onSuccess={this.refresh} />}
        { type === 'create' && <Form onSuccess={this.refresh} />}
      </>
    );
  }
}

export default withRouter(FacebookComparison);

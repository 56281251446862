export const DATE_GROUPINGS = [
  {
    text: 'None',
    value: 'none',
  },
  {
    text: 'Day',
    value: 'day',
  },
  {
    text: 'Week',
    value: 'week',
  },
  {
    text: 'Month',
    value: 'month',
  },
  {
    text: 'Quarter',
    value: 'quarter',
  },
  {
    text: 'Year',
    value: 'year',
  },
];

export const METRIC_COLUMNS = [
  { text: 'Conversions', value: 'conversions' },
  { text: 'Revenue', value: 'revenue' },
  { text: 'Spend', value: 'spend' },
  { text: 'CPA', value: 'cpa' },
  { text: 'ROAS', value: 'roas' },
];

export const CUSTOMER_TYPE_OPTIONS = [
  { text: 'All Customers', value: 'all' },
  { text: 'New Customers', value: 'new' },
  { text: 'Repeat Customers', value: 'repeat' },
];

export const ATTRIBUTION_MODEL_OPTIONS = [
  { text: 'Modeled Multi-Touch', value: 'normalized' },
  { text: 'Even Weight', value: 'even' },
  { text: 'First-Touch', value: 'first_touch' },
  { text: 'Last-Touch', value: 'last_touch' },
];

import { SET_ALL_SEGMENTS, SET_OUTBOXES } from "../../constants";
import {
  SET_SAVED_REPORTS,
  SET_SCHEDULED_REPORTS,
  SET_SHOULD_REFETCH_JOBS,
  SET_REPORT_TYPES,
  RESET_SCHEDULE_FORM,
} from "./constants";

const initialState = {
  savedReports: [],
  scheduledReports: [],
  refetch: false,
  forceSendStatus: undefined,
  segments: [],
  reportTypes: [],
  reportScheduled: undefined,
  outboxes: [],
  SFTPOutboxes: [],
  s3Outboxes: []
};

export default function ReportsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SAVED_REPORTS:
      action.data.response.map(
        report => (report["filters"] = [report["filters"]])
      );
      return {
        ...state,
        savedReports: action.data.response,
        refetch: false
      };
    case SET_SCHEDULED_REPORTS:
      return {
        ...state,
        scheduledReports: action.data.response,
        refetch: false
      };
    case SET_REPORT_TYPES:
      return {
        ...state,
        reportTypes: action.data.response,
        refetch: false
      };
    case SET_ALL_SEGMENTS:
      return {
        ...state,
        segments: action.data.response,
        refetch: false
      };
    case SET_OUTBOXES:
      return {
        ...state,
        outboxes: action.data.response,
        SFTPOutboxes: action.data.response.filter(o => o.outbox_type == "sftp"),
        s3Outboxes: action.data.response.filter(
          o => o.outbox_type == "amazon_s3"
        )
      };
    case RESET_SCHEDULE_FORM:
      const segments = state.segments;
      const reportTypes = state.reportTypes;
      const outboxes = state.outboxes;
      return {
        ...initialState,
        segments: segments,
        reportTypes: reportTypes,
        outboxes: outboxes
      };
    case SET_SHOULD_REFETCH_JOBS:
      return {
        ...state,
        refetch: action.data
      };
    default:
      return state;
  }
}

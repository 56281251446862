import React from 'react';
import { ContentCard, SplitLayout } from '@rockerbox/styleguide';
import { Table, Checkbox } from 'semantic-ui-react';

export const EmailSetup = (props) => (
  <SplitLayout
    leftWidth={8} rightWidth={8}
    leftContent={
      <ContentCard title="Inbox Details" hasTable>
        <Table definition>
          <Table.Row>
            <Table.Cell>Email</Table.Cell>
            <Table.Cell>
              <a target="_blank" href={`mailTo:${props.mapper.email_key}@report-inbox.getrockerbox.com`}>
                {`${props.mapper.email_key}@report-inbox.getrockerbox.com`}
              </a>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Upload ID Settings</Table.Cell>
            <Table.Cell>
              <div style={{paddingBottom: "4px"}}>
                <Checkbox 
                  label='Use date as upload_id'
                  checked={ props.mapper.date_as_upload_id || 0 }
                  onChange={ () => props.toggleUploadId(props.mapper.inbox_id, 'amazon_ses', 'date') }
                />
              </div>
              <div>
                <Checkbox 
                  label='Parse date from filename as upload_id'
                  checked={ props.mapper.upload_id_from_filename || 0 }
                  onChange={ () => props.toggleUploadId(props.mapper.inbox_id, 'amazon_ses', 'filename') }
                />   
              </div>
          </Table.Cell>
          </Table.Row>
        </Table>
      </ContentCard>
    }
    rightContent={
      <ContentCard title="Delivery Instructions">
        <h4>Email file delivery supports attachments up to 30 megabytes (MB). For file deliveries larger than 30MB, please use an S3 or SFTP inbox instead.</h4>
        <p>Supported File types (please include appropriate filename extension):</p>
        <ul>
          <li>csv</li>
          <li>tsv</li>
          <li>xlsx</li>
        </ul>
      </ContentCard>
    }
  />

)

export const S3Setup = (props) => {
  const rockerboxObj = `{
    "Action": [
        "s3:GetObject",
        "s3:GetObjectAcl",
        "s3:PutObject",
        "s3:PutObjectAcl",
        "s3:DeleteObject",
        "s3:AbortMultipartUpload",
        "s3:ListMultipartUploadParts"
    ],
    "Resource": [
        "arn:aws:s3:::${props.mapper.bucket_name + '/' + props.mapper.folder}/*"
    ],
    "Effect": "Allow",
    "Sid": "${props.name.replace(/[\W_]+/g,'')}"
  }`
  const bucket = `s3://${props.mapper.bucket_name}/${props.mapper.folder}`;
  return (
    <SplitLayout
      leftWidth={8} rightWidth={8}
      leftContent={
        <React.Fragment>
          <ContentCard title="Inbox Details" hasTable>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Inbox Type</Table.Cell>
                  <Table.Cell>{props.mapper.inbox_type}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Bucket Name</Table.Cell>
                  <Table.Cell>{props.mapper.bucket_name}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Folder</Table.Cell>
                  <Table.Cell>{props.mapper.folder}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Authorized ARN</Table.Cell>
                  <Table.Cell>{props.mapper.external_user_arn}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Upload ID Settings</Table.Cell>
                  <Table.Cell>
                    <div style={{paddingBottom: "4px"}}>
                      <Checkbox 
                        label='Use date as upload_id'
                        checked={ props.mapper.date_as_upload_id || 0 }
                        onChange={ () => props.toggleUploadId(props.mapper.inbox_id, 'amazon_s3', 'date') }
                      />
                    </div>
                    <div>
                      <Checkbox 
                        label='Parse date from filename as upload_id'
                        checked={ props.mapper.upload_id_from_filename || 0 }
                        onChange={ () => props.toggleUploadId(props.mapper.inbox_id, 'amazon_s3', 'filename') }
                      />             
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </ContentCard>
          <ContentCard title="Delivery Instructions">
            <h4>Upload files with <a href="https://docs.aws.amazon.com/AmazonS3/latest/API/RESTObjectPUTacl.html" target="_blank">bucket-owner-full-control</a> ACL header to:</h4>
            <pre>{bucket}</pre>
            <p>Supported File types (please include appropriate filename extension):</p>
            <ul>
              <li>csv</li>
              <li>tsv</li>
              <li>xlsx</li>
            </ul>
          </ContentCard>
        </React.Fragment>
      }
      rightContent={
        <ContentCard title="Setup Instructions">
          <h4>Please add the following statement to the inline or managed permissions policy associated with your user. This statement will give your user permissions to put files into our bucket:</h4>
          <pre>
            {rockerboxObj}
          </pre>
        </ContentCard>
      }
    />
  )
}

export const SftpSetup = (props) => {
  console.log(props.mapper)
  const sftpAddress = `  sftp ${props.mapper.username}@sftp.getrockerbox.com`;
  const success = `
  Connected to sftp.getrockerbox.com.
  sftp> ls
  /files
  `
  return (
  <SplitLayout
    leftWidth={8} rightWidth={8}
    leftContent={
      <React.Fragment>
        <ContentCard title="Inbox Details" hasTable>
          <Table>
            <Table.Row>
              <Table.Cell>Host</Table.Cell>
              <Table.Cell>sftp://sftp.getrockerbox.com</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Folder</Table.Cell>
              <Table.Cell>/files</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Username</Table.Cell>
              <Table.Cell>{props.mapper.username}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Password</Table.Cell>
              <Table.Cell>{props.mapper.sftp_key}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Port</Table.Cell>
              <Table.Cell>22</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Upload ID Settings</Table.Cell>
              <Table.Cell>
                <div style={{paddingBottom: "4px"}}>
                  <Checkbox 
                    label='Use date as upload_id'
                    checked={ props.mapper.date_as_upload_id || 0 }
                    onChange={ () => props.toggleUploadId(props.mapper.inbox_id, 'sftp', 'date') }
                  />
                </div>
                <div>
                  <Checkbox 
                    label='Parse date from filename as upload_id'
                    checked={ props.mapper.upload_id_from_filename || 0 }
                    onChange={ () => props.toggleUploadId(props.mapper.inbox_id, 'sftp', 'filename') }
                  />             
                </div>
              </Table.Cell>
           </Table.Row>    
        </Table>
        </ContentCard>
      </React.Fragment>
    }
    rightContent={
      <ContentCard title="Delivery Instructions">
        <h4>1. Authenticate to the Rockerbox SFTP server:</h4>
        <pre>{sftpAddress}</pre>

        <h4>2. Upload data to the <pre style={{display: 'inline'}}>/files</pre> directory listed on the server once authenticated:</h4>
        <pre>{success}</pre>

        <p>Supported File types (please include appropriate filename extension):</p>
        <ul>
          <li>csv</li>
          <li>tsv</li>
          <li>xlsx</li>
        </ul>
      </ContentCard>
    }
  />
)}

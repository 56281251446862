import moment from "moment";
import axios from "axios";

export function deleteSavedReport(id) {
  const url = `/reporting/saved?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "DELETE",
    headers: {
      Accept: "application/json"
    }
  })
    .then(response => response.json())
    .then(data => {});
}

export function deleteScheduledReport(id) {
  const url = `/reporting/scheduled?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "DELETE",
    headers: {
      Accept: "application/json"
    }
  })
    .then(response => response.json())
    .then(data => {});
}

export function toggleActiveScheduledReport({ id, active }) {
  const url = `/reporting/scheduled/deactive?id=${id}&active=${active}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json"
    }
  })
    .then(response => response.json())
    .then(data => {});
}

export function postNewOutbox(obj) {
  return axios
    .post("/account/outbox", obj)
    .then(() => axios.get("/account/outbox"))
    .then(d => d.data);
}

export function sendSavedReport(obj) {
  return axios.post("/run/send/report/", obj).then(d => d.data);
}

export function createSavedreport(obj) {
  return axios.post("/reporting/saved", obj).then(d => getSavedreports());
}

export function postScheduledReport(obj) {
  return axios
    .post("/reporting/scheduled", obj)
    .then(() => axios.get("/reporting/scheduled"))
    .then(d => d.data);
}

export function updateScheduledReport(obj) {
  return axios.put("/reporting/scheduled", obj);
}

export function updateSavedReport(id, obj) {
  return axios.put(`/reporting/saved/schema?id=${id}`, obj);
}

export function getReportType() {
  return axios.get("/reporting/types").then(d => d.data.response);
}

export function getAllSegments() {
  return axios.get("/segments?format=json").then(d => d.data.response);
}

export function getSavedreports() {
  return axios.get("/reporting/saved").then(d => d.data.response);
}

export function getScheduledreports() {
  return axios.get("/reporting/scheduled").then(d => d.data.response);
}

export function getOutboxes() {
  return axios.get("/account/outbox/data").then(d => d.data.response);
}

export function getOutboxById(id) {
  return axios.get(`/account/outbox?id=${id}`).then(d => d.data.response);
}

export function getOutboxWithoutDetails() {
  return axios.get(`/account/outbox`).then(d => d.data.response);
}

export function getReportingTypes() {
  return axios.get("/reporting/types").then(d => d.data.response);
}

export function getDeliveredFile(id) {
  return axios
    .get(`/reporting/delivered/files?id=${id}`)
    .then(d => d.data.response);
}

export function forceSendOnce(report) {
  const url = "/reporting/send";
  const {
    pixel_source_name,
    report_type_id,
    destination,
    filter_id,
    outbox_id,
    id
  } = report;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      scheduled_report_id: id,
      report_type_id: report_type_id,
      advertiser: pixel_source_name,
      destination: destination,
      outbox_id: outbox_id,
      filter_id: filter_id,
      date: moment.utc()
        .utcOffset(-5)
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
      num_days: report.num_days
    })
  })
    .then(response => response.json())
    .then(data => {
      return data;
    });
}

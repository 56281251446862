/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';

import { ArrowSmRightIcon } from '@heroicons/react/outline'; // eslint-disable-line import/no-extraneous-dependencies
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import toast from 'react-hot-toast';

import 'react-dates/lib/css/_datepicker.css';
import '../../../../../react_dates_overrides.css';
import { tomorrow } from '../time';

const DateRangePickerWrapper = ({ startDate, endDate, onChange, dateRange = false }) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);

  useEffect(() => {
    setFocusedInput('startDate');
  }, []);

  useEffect(() => {
    if (focusedInput == null) {
      if (start && end) {
        return onChange({ startDate: start, endDate: end });
      }
      toast('Invalid Date Input.', { icon: 'ℹ️' });
    }
  }, [focusedInput]);

  useEffect(() => {
    setStart(startDate);
    setEnd(endDate);
  }, [startDate, endDate]);

  const onDatesChange = dates => {
    const newStartDate = dates.startDate;
    const newEndDate = dates.endDate;
    if (dateRange) {
      const [rangeStart, rangeEnd] = dateRange;
      const minDate = moment(rangeStart).utc();
      const maxDate = moment(rangeEnd).utc();
      if (newStartDate && newEndDate) {
        const isStartDateOutsideRange = newStartDate.isBefore(minDate) || newStartDate.isAfter(maxDate);
        const isEndDateOutsideRange = newEndDate.isBefore(minDate) || newEndDate.isAfter(maxDate);
        const isEndDateBeforeStartDate = newEndDate.isBefore(newStartDate);
        if (isStartDateOutsideRange || isEndDateOutsideRange || isEndDateBeforeStartDate) {
          toast(`Dates must be within ${rangeStart} and ${rangeEnd}, and the end date must be after the start date`, { icon: 'ℹ️' });
          return;
        }
      }
    }
    setStart(newStartDate);
    setEnd(newEndDate);
  };

  const onFocusChange = focusedInputChange => {
    setFocusedInput(focusedInputChange);
    const uniqueStartDate = document.getElementById('uniqueStartDate').value;

    if (focusedInput === 'endDate' && !start) {
      const vaildDate = moment(uniqueStartDate).isValid();
      if (!vaildDate) {
        toast('Please enter a valid start date.', { icon: 'ℹ️' });
      }
    }
  };

  const handleOutsideRange = day => {
    const sameDay = moment(day.format('YYYY-MM-DD')).utc();
    if (dateRange) {
      const [rangeStart, rangeEnd] = dateRange;
      const minDate = moment(rangeStart).utc();
      const maxDate = moment(rangeEnd).utc();
      const isBeforeMinDate = sameDay.isBefore(minDate);
      const isAfterMaxDate = sameDay.isAfter(maxDate);
      return isBeforeMinDate || isAfterMaxDate;
    }
    const minDate = tomorrow;
    const isBeforeMinDate = sameDay.isBefore(minDate);
    if (focusedInput === 'startDate') {
      return isBeforeMinDate;
    }
    if (focusedInput === 'endDate' && start) {
      const isBeforeStartDate = sameDay.isBefore(start);
      return isBeforeMinDate || isBeforeStartDate;
    }
    return false;
  };

  return (
    <div>
      <DateRangePicker
        onDatesChange={onDatesChange}
        onFocusChange={onFocusChange}
        focusedInput={focusedInput}
        startDate={start}
        endDate={end}
        noBorder={true}
        isOutsideRange={day => handleOutsideRange(day)}
        hideKeyboardShortcutsPanel
        startDateId="uniqueStartDate"
        endDateId="uniqueEndDate"
        minimumNights={0}
        customArrowIcon={<ArrowSmRightIcon style={{ width: 20, height: 20 }} />}
        verticalSpacing={0}
        anchorDirection="right"
        numberOfMonths="1"
      />
    </div>
  );
};

export default DateRangePickerWrapper;

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'rockerbox_d3_legacy_clone';
import visavailChart from './visavail';

class AvailReportingPlot extends Component {
  renderD3() {
    const { data } = this.props;
    const target = ReactDOM.findDOMNode(this.refs.plot);
    const chart = visavailChart().width(800);

    d3
      .select(target)
      .selectAll('svg')
      .remove();

    d3
      .select(target)
      .datum(data)
      .call(chart);
  }

  componentDidMount() {
    this.renderD3();
  }
  componentDidUpdate() {
    this.renderD3();
  }

  render() {
    return <div ref="plot" />;
  }
}

export default AvailReportingPlot;

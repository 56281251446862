import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Aux } from '../stateless';

import LoginPage from './Login';
import ActivationPage from './Activate';
import PasswordReset from './PasswordReset';
import ForgotPassword from './ForgotPassword';
import PasswordSuccess from './PasswordSuccess';

const Gateway = (props) => {
  return (
    <Aux>
      <Route exact path="/set_password/first_time" component={ActivationPage} />
      <Route exact path="/test_login" component={LoginPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/set_password" component={PasswordReset} />
      <Route exact path="/password-forgot/" component={ForgotPassword} />
      <Route exact path="/password-success" component={PasswordSuccess} />
    </Aux>
  );
} 

export default Gateway;

import React from 'react';
import { StatCard, ContentCard, SplitLayout, PagedIndexGrid } from '@rockerbox/styleguide';
import { Table } from 'semantic-ui-react';

import DailyChart from '../DailyChart';

const BUFFER_LENGTH = 30;

const initBuffer = (size, value) => Array.from({length: size}, (v, i) => { return value !== undefined ? value : {} });
const prepareBuffer = (size) => (buffer) => (buffer.length > size) ? buffer.slice(1) : buffer;
const prepBuf = prepareBuffer(BUFFER_LENGTH);

const defaultCombine = (x,y) => { return x + y }

const processMessage = (names, buffer, messages, keyAccessor, valueAccessor, empty = 0, combine = defaultCombine) => {
  const newBuffer = prepBuf(buffer);

  const agg = messages.reduce((p,c) => {
    const key = keyAccessor(c);
    p[key] = combine((p[key] || empty), valueAccessor(c));
    return p;
  }, {})

  Array.from(names).map(k => {
    newBuffer[k] = prepBuf(newBuffer[k] || initBuffer(BUFFER_LENGTH, empty));
    newBuffer[k].push(agg[k] || empty);
  })

  return newBuffer
}


const Seconds = props => (
  <Table.Cell>
    <DailyChart data={props.item.buffer} width={250}/>
  </Table.Cell>
);

const WideNameCell = props => (
  <Table.Cell width={6}>
    { props.item.name }
  </Table.Cell>
);

class PixelLive extends React.Component {
  state = {
    buffer: initBuffer(BUFFER_LENGTH),
    actionCountBuffer: {},
    actionRevenueBuffer: {},
    actionUidBuffer: {},
    urlBuffer: {},
    urlUidBuffer: {},
    actionNames: new Set()
  };

  componentWillUnmount() {
    this.ws.close()
  }

  processActions(data) {
    const { actionCountBuffer, actionRevenueBuffer, actionUidBuffer, actionNames } = this.state;

    data.messages.map(m => actionNames.add(m.action))

    const count = processMessage(actionNames, actionCountBuffer, data.messages, (c) => c.action, (c) => 1)
    const revenue = processMessage(
      actionNames,
      actionRevenueBuffer,
      data.messages,
      (c) => c.action,
      (c) => parseFloat(c.revenue)
    );
    const uid = processMessage(
      actionNames,
      actionUidBuffer,
      data.messages,
      (c) => c.action,
      (c) => c.adnxs_uid,
      [],
      (x, y) => x.concat([y])
    );

    return {
      actionNames,
      actionCountBuffer: count,
      actionRevenueBuffer: revenue,
      actionUidBuffer: uid
    }

  }

  processVisits(data) {
    const { buffer } = this.state;
    const newBuffer = prepBuf(buffer);
    newBuffer.push(data);

    return { buffer: newBuffer }
  }

  processUrls(data) {
    const { urlBuffer } = this.state;
    const newBuffer = prepBuf(urlBuffer);
    const keys = Object.keys(newBuffer).reduce((p,url) => {
      p.add(url);
      return p;
    }, new Set())


    data.messages.map(m => {
      m.url = m.referrer.split("&action")[0].split("?")[0]
      keys.add(m.url)
    })

    const count = processMessage(
      Array.from(keys),
      newBuffer,
      data.messages,
      (c) => c.url,
      (c) => 1
    )

    return { buffer: count }
  }

  componentDidMount() {

    const protocol = window.location.protocol.indexOf("s") > -1 ? "wss://" : "ws://";
    this.ws = new WebSocket(protocol + window.location.host + "/websocket");
    this.ws.onopen = () => this.ws.send('{}');
    this.ws.onmessage = (m) => {
      const data = JSON.parse(m.data);

      const actionObj = this.processActions(data)
      const visitsObj = this.processVisits(data)
      const urlsObj = this.processUrls(data)

      const { actionCountBuffer, actionRevenueBuffer, actionNames } = actionObj;
      const { buffer } = visitsObj;

      this.setState({ buffer, actionCountBuffer, actionNames });
    };

  }

  render() {
    const { buffer, actionCountBuffer, actionRevenueBuffer, actionUidBuffer, urlBuffer, actionNames } = this.state
    const counts = buffer.map(x => x.matches || 0 )
    const uids = new Set(buffer.reduce((p,c) => p.concat(c.messages ? c.messages.map(x => x.adnxs_uid) : []), [])).size
    const sum = counts.reduce((p,c) => p + c, 0)

    const columns = [
      { display: 'Event Name', key: 'name', as: WideNameCell},
      { display: '', key: 'buffer', as: Seconds},
      { display: 'Count', key: 'count'},
      { display: 'Uniques', key: 'uniques'},
      { display: 'Revenue', key: 'revenue'},
    ]

    const eventData = Array.from(actionNames).map(name => {
      return {
        name: name,
        buffer: actionCountBuffer[name],
        count: actionCountBuffer[name].reduce((p,c) => p + c, 0),
        revenue: actionRevenueBuffer[name].reduce((p,c) => p + c, 0),
        uniques: (new Set(actionUidBuffer[name].reduce((p,c) => p.concat(c), []))).size
      }
    })
    .sort((p,c) => c.count - p.count)

    const urlColumns = [
      { display: 'URL', key: 'name', as: WideNameCell},
      { display: '', key: 'buffer', as: Seconds},
      { display: 'Count', key: 'count'},
    ]

    const urlCount = Object.keys(urlBuffer).reduce((p,k) => {
      p[k] = urlBuffer[k].reduce((q,r) => q +r, 0)
      return p
    }, {})

    const urlData = Object.keys(urlCount).map(name => {
      return {
        name: name,
        buffer: urlBuffer[name],
        count: urlCount[name]
      }
    })
    .sort((p,c) => c.count - p.count)
    .filter(x => x.count)


    return (
      <SplitLayout
        leftWidth={4}
        leftContent={
          <React.Fragment>
            <ContentCard title="Visits" >
              <DailyChart data={counts} height={60} style={{textAlign:"center"}} />
            </ContentCard>
            <StatCard value={sum} label='Visits' />
            <StatCard value={uids} label='Uniques' />
          </React.Fragment>
        }
        rightWidth={12}
        rightContent={
          <React.Fragment>
            <ContentCard hasTable title="Events">
              <PagedIndexGrid as={ContentCard.Table} static data={eventData} cols={columns} itemsPerPage={5} />
            </ContentCard>
            <ContentCard hasTable title="Pages">
              <PagedIndexGrid as={ContentCard.Table} static data={urlData} cols={urlColumns} itemsPerPage={5} />
            </ContentCard>
          </React.Fragment>
        }
      />
    )
  }
}



export default PixelLive;

/**
 * A set of functions that map a marketing event to its corresponding platform, channel, and strategy.
 * Uses regular expressions and memoization to improve performance.
 *
 * @module marketingEventMapping
 */

import { CHANNEL_MAPPING, PLATFORM_MAPPING, STRATEGY_MAPPING } from './constants';

/**
 * Takes a mapping constant as input and returns an array of objects.
 * Each object in the array contains the text, regex, and value properties.
 * The regex property is created by joining the matches array of the mapping constant
 * with the '|' separator and converting it into a regular expression.
 *
 * @param {object} mappingConstant - A mapping constant object that contains keys representing different options
 *                                  and values containing an array of matches and a text description.
 * @returns {Array} An array of objects, where each object represents an option from the mapping constant.
 *                  Each object contains properties `text`, `regex`, and `value`.
 *
 * @example
 * const CHANNEL_MAPPING = {
 *   channel1: {
 *     matches: ['match1', 'match2'],
 *     text: 'Channel 1'
 *   },
 *   channel2: {
 *     matches: ['match3', 'match4'],
 *     text: 'Channel 2'
 *   }
 * };
 *
 * const regexOptions = createRegexOptions(CHANNEL_MAPPING);
 * console.log(regexOptions);
 * // Output:
 * // [
 * //   { text: 'Channel 1', regex: /match1|match2/, value: 'channel1' },
 * //   { text: 'Channel 2', regex: /match3|match4/, value: 'channel2' }
 * // ]
 */
export const createRegexOptions = mappingConstant => {
  if (!Object.keys(mappingConstant).length) return [];
  const regexObj = Object.keys(mappingConstant)
    .reduce((accu, curr) => {
      const currentMapping = mappingConstant[curr];
      const mappingPattern = currentMapping.matches.join('|');
      if (currentMapping.exclude) {
        const { exclude } = currentMapping;
        const excludePattern = exclude.join('|');
        accu[curr] = new RegExp(`^(?!.*(${excludePattern})).*(${mappingPattern}).*$`, 'i');
        return accu;
      }

      accu[curr] = new RegExp(mappingPattern);
      return accu;
    }, {});
  return Object.keys(regexObj).map(value => ({ text: mappingConstant[value].text, regex: regexObj[value], value }));
};

const channelRegexOptions = createRegexOptions(CHANNEL_MAPPING);
const strategyRegexOptions = createRegexOptions(STRATEGY_MAPPING);

const eventToChannel = {};
const eventToPlatform = {};
const eventToStrategy = {};

/**
 * Finds the channel that matches the given marketing event.
 *
 * @private
 * @param {string} marketingEvent - The marketing event.
 * @returns {string|null} - The channel corresponding to the marketing event. If no channel is found, returns 'Other'.
 */
const _marketingEventToChannel = marketingEvent => {
  const foundChannel = channelRegexOptions.find(({ regex }) => marketingEvent.match(regex))?.value;
  return foundChannel || 'other';
};

/**
 * Finds the channel that matches the given marketing event.
 * Uses memoization to improve performance.
 *
 * @param {string} marketingEvent - The marketing event.
 * @returns {string} - The channel corresponding to the marketing event. If no channel is found, returns 'Other'.
 */
export const marketingEventToChannel = marketingEvent => {
  const memoized = eventToChannel[marketingEvent];
  if (memoized) return memoized;

  const result = _marketingEventToChannel(marketingEvent);
  eventToChannel[marketingEvent] = result;
  return result;
};

/**
 * Finds the platform that matches the given marketing event.
 *
 * @private
 * @param {string} marketingEvent - The marketing event.
 * @returns {string|null} - The platform corresponding to the marketing event. If no platform is found, returns 'Other'.
 */
const _marketingEventToPlatform = (marketingEvent, platformMappings) => {
  const platformRegexOptions = platformMappings ? createRegexOptions(platformMappings) : createRegexOptions(PLATFORM_MAPPING);
  const foundPlatform = platformRegexOptions.find(({ regex }) => marketingEvent.match(regex))?.value;
  return foundPlatform || 'other';
};

/**
 * Finds the platform that matches the given marketing event.
 * Uses memoization to improve performance.
 *
 * @param {string} marketingEvent - The marketing event.
 * @returns {string} - The platform corresponding to the marketing event. If no platform is found, returns 'Other'.
 */
export const marketingEventToPlatform = (marketingEvent, platformMappings) => {
  const memoized = eventToPlatform[marketingEvent];
  if (memoized) return memoized;

  const result = _marketingEventToPlatform(marketingEvent, platformMappings);
  eventToPlatform[marketingEvent] = result;
  return result;
};

/**
 * Finds the strategy that matches the given marketing event.
 *
 * @private
 * @param {string} marketingEvent - The marketing event.
 * @returns {string|null} - The strategy corresponding to the marketing event. If no strategy is found, returns 'Other'.
 */
const _marketingEventToStrategy = marketingEvent => {
  const foundStrategy = strategyRegexOptions.find(({ regex }) => marketingEvent.match(regex))?.value;
  return foundStrategy || 'other';
};

/**
 * Finds the strategy that matches the given marketing event.
 * Uses memoization to improve performance.
 *
 * @param {string} marketingEvent - The marketing event.
 * @returns {string} - The strategy corresponding to the marketing event. If no strategy is found, returns 'Other'.
 */
export const marketingEventToStrategy = marketingEvent => {
  const memoized = eventToStrategy[marketingEvent];
  if (memoized) return memoized;

  const result = _marketingEventToStrategy(marketingEvent);
  eventToStrategy[marketingEvent] = result;
  return result;
};

import React from 'react';

const HatchableBar = ({ fill, x, y, width, height, hatched }) => (
  <g>
    {/* Draw the bar with its original fill color */}
    <rect x={x} y={y} width={width} height={height} fill={fill} />
    {/* Conditionally overlay the diagonal hatch pattern if hatched is true */}
    {hatched && <rect x={x} y={y} width={width} height={height} fill="url(#diagonalHatch)" />}
  </g>
);

export default HatchableBar;

import * as Router from 'react-router-dom';

export const useRouteDates = () => {
  const match = Router.useRouteMatch();
  const { params } = match;
  const { startDate, endDate } = params;

  return { startDate, endDate };
}

export const useRoutePlatform = () => {
  const match = Router.useRouteMatch();
  const { params } = match;
  const { platform } = params;

  return { platform };
}
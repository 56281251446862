import React from 'react';
import { Table, Icon, Divider, Header } from 'semantic-ui-react';
import { SplitLayout } from '@rockerbox/styleguide';
import { LightBulbIcon } from '@heroicons/react/outline'; // eslint-disable-line import/no-extraneous-dependencies
import styled from 'styled-components';
import { convertToPercent } from './Overlap/helpers';
import { formatCurrency, secondsToDhms } from '../../utils/valueFormatter';
import { TreeTableLoader, KeyInsightsLoader, VennDiagramLoader, ChannelOverlapBaseline } from '../../components/loaders';

export const ChannelCell = ({ item, col }) => (
  <Table.Cell style={{ textAlign: 'left' }}>
    <Icon name="circle" style={{ color: item.color }} size="large" />
    {' '}
    {item[col.key]}
  </Table.Cell>
);

export const CurrencyCell = ({ item, col }) => (
  <Table.Cell>
    <p>{formatCurrency(item[col.key])}</p>
  </Table.Cell>
);

export const CurrencyCellPercent = ({ item, col }) => {
  const percentFormatter = col.key ? `${convertToPercent(item[col.key])} ${item[col.key] > 0 ? 'higher' : 'lower'}` : '';
  return (
    <Table.Cell>
      <p>{item[col.key] === Infinity ? 'N/A' : percentFormatter}</p>
    </Table.Cell>
  );
};

export const Loading = () => (
  <div>
    <div style={{ padding: '50px 20px' }}>
      <SplitLayout
        leftWidth={7}
        leftContent={<KeyInsightsLoader />}
        rightWidth={9}
        rightContent={<VennDiagramLoader />}
      />
    </div>
    <Divider style={{ padding: '20px' }} />
    <Divider />
    <ChannelOverlapBaseline />
    <TreeTableLoader />
  </div>
);

export const OverlapCell = ({ item, col }) => (
  <Table.Cell>
    <p>{`${item[col.key].toLocaleString('en-US')} (${convertToPercent(item[col.key_percent])})`}</p>
  </Table.Cell>
);

export const OverlapCellPercent = ({ item, col }) => (
  <Table.Cell>
    <p>{`${convertToPercent(item[col.key])}`}</p>
  </Table.Cell>
);

export const TimeCell = ({ item, col }) => {
  const timeFormatter = secondsToDhms(item[col.key]);
  return (
    <Table.Cell>
      <p>{timeFormatter}</p>
    </Table.Cell>
  );
};

export const cols = [
  { display: 'Channel', key: 'channel', as: ChannelCell, headerStyles: { textAlign: 'left' } },
  { display: 'Overlapping Conversions', as: OverlapCell, key: 'count_overlap', key_percent: 'count_overlap_ratio' },
];

export const revCols = [
  { display: 'Revenue', key: 'revenue_overlap', as: CurrencyCell },
  { display: 'Avg Revenue', key: 'avg_rev_overlap', as: CurrencyCell },
];

const GrayHeader = styled.h2`
  color: rgba(0, 0, 0, 0.5);
  margin-top: 0px;`;

export const FilterHeader = ({ headerText }) => (
  <div style={{ display: 'flex', alignItems: 'center', padding: '15px 0' }}>
    <LightBulbIcon style={{ width: 30, margin: '-2px 7px 0px 0', fill: '#FFDF78', stroke: '#b2b1ac' }} />
    <GrayHeader>{headerText}</GrayHeader>
  </div>
);

export const SimpleHeader = ({ headerText, style }) => (
  <div style={{ display: 'flex', alignItems: 'center', padding: '5px 0', ...style }}>
    <GrayHeader>{headerText}</GrayHeader>
  </div>
);
export const SimpleSubHeader = ({ headerText }) => <Header.Subheader style={{ paddingTop: 5 }}>{headerText}</Header.Subheader>;

import React from 'react';
import * as Router from "react-router-dom";
import * as routes from '../../routes';

import { ColumnLayout } from '@rockerbox/styleguide';
import { Menu, Icon} from 'semantic-ui-react';
import _ from 'lodash';
import CacheContext from '../../utils/CacheContext';
import { getStandardDatasets } from '../../utils/api';
import DatasetView from './DatasetView';
import DatasetTable from './DatasetTable';

const TYPES = {
  "platform_transform": "Meta Data",
  "platform_spend": "Spend Data",
  "external_conversions": "Order Data",
  "shopify_customer_journey": "Event Data",
  "unknown": "Other",
}

const DatasetsIndex = (props) => {

  const history = Router.useHistory();
  const routedData = Router.useRouteMatch(`${routes.datasetsIndex}/view/:selectedType?/:selectedReport?/:selectedFile?`) || {};
  const { params: { selectedType, selectedReport, selectedFile } = {} } = routedData

  const setSelectedType = (value) => history.push(`${routes.datasetsIndex}/view/${value}`)
  const setSelectedReport = (value) => history.push(`${routes.datasetsIndex}/view/${selectedType}/${value}`)

  const [datasets, setDatasets] = React.useState([]);
  const [state, setState, Context] = React.useContext(CacheContext);

  const ds = (Context.getCache(`standardDatasets`, getStandardDatasets) || [])

  React.useEffect(() => {
    const datasets = ds
      .map(row => {
        row.dataset_type = TYPES[row.report_type_name] || "Other"
        return row
      })
      .filter(row => row.dataset_type == selectedType)
    setDatasets(datasets)
  }, [ds.length, selectedType])

  const selectType = (type) => {
    setSelectedType(type)
  }


  return <ColumnLayout
    leftWidth={2}
    centerWidth={2}
    rightWidth={12}
    leftContent={
      <Menu vertical fluid>
        <Menu.Item>
          <Menu.Header style={{color: 'black'}}>Dataset Types</Menu.Header>
        </Menu.Item>
        {
          Object.values(TYPES).map(type => {
            return <Menu.Item active={type == selectedType} onClick={() => selectType(type)}>
              {type} {type == selectedType && <Icon name="chevron right" style={{float: 'right', margin: 0}}/>}
            </Menu.Item>
          })
        }
      </Menu>
    }
    rightContent={
      selectedFile ?
        <DatasetView /> :
        <DatasetTable {...{selectedType, id : selectedReport }} />
    }
  >
    <Menu vertical fluid>
      <Menu.Item>
        <Menu.Header style={{color: 'black'}}>Datasets</Menu.Header>
      </Menu.Item>
      {
        datasets.map(row => {
          return <Menu.Item active={row.id == selectedReport} onClick={() => setSelectedReport(row.id)}>
            {row.report_name} {row.id == selectedReport&& <Icon name="chevron right" style={{float: 'right', margin: 0}}/>}
          </Menu.Item>
        })
      }
    </Menu>
  </ColumnLayout>

}
export default DatasetsIndex;

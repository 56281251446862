import React from 'react';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { DEFAULT_TIERS } from '../constants/tiers';

const NoBorderCell = styled(Table.Cell)`
  border: none !important;
`;

const FormSummaryTable = ({ tableHeading, summary }) => (
  <div style={{ marginTop: 20, marginBottom: 20 }}>
    <h2>{tableHeading}</h2>
    <div style={{ backgroundColor: '#fbfbfe', border: 'solid 1px #efeffb', padding: 10 }}>
      <Table basic="very" compact collapsing>
        <Table.Body>
          {summary.map(({ key, text, value, collapsing = false }) => {
            if (!value || value.length === 0) return;
            if (key === 'Tier Filters') {
              const { tier_1, tier_2, tier_3, tier_4, tier_5 } = value;
              return (
                <Table.Row>
                  <NoBorderCell verticalAlign="top">
                    <strong>{text}</strong>
                  </NoBorderCell>
                  <NoBorderCell>
                    {(tier_1?.length === 0 || !tier_1) && <p>None</p>}
                    {tier_1?.length > 0 && (
                      <p>
                        Tier 1 contains
                        {tier_1.join(', ')}
                      </p>
                    )}
                    {tier_2?.length > 0 && (
                      <p>
                        Tier 2 contains
                        {tier_2.join(', ')}
                      </p>
                    )}
                    {tier_3?.length > 0 && (
                      <p>
                        Tier 3 contains
                        {tier_3.join(', ')}
                      </p>
                    )}
                    {tier_4?.length > 0 && (
                      <p>
                        Tier 4 contains
                        {tier_4.join(', ')}
                      </p>
                    )}
                    {tier_5?.length > 0 && (
                      <p>
                        Tier 5 contains
                        {tier_5.join(', ')}
                      </p>
                    )}
                  </NoBorderCell>
                </Table.Row>
              );
            }
            if (key === 'Breakdown') {
              // eslint-disable-next-line no-param-reassign
              value = value.map(c => DEFAULT_TIERS[c]).join(', ');
            }

            return (
              <Table.Row>
                <NoBorderCell collapsing={collapsing}><strong>{text}</strong></NoBorderCell>
                <NoBorderCell>{value}</NoBorderCell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  </div>
);

export default FormSummaryTable;

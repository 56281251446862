import React from 'react';
import { useStepsState } from '../StepsSectionContext';
import Button from '../../../../components/parts/Button';
import CodeBlock from './CodeBlock';

const CreateExternalSchema = () => {
  // eslint-disable-next-line no-unused-vars
  const [stateData, setStateData] = useStepsState();
  const [currentCopiedText, setCurrentCopiedText] = React.useState('');

  const onCopy = text => {
    setCurrentCopiedText(text);
    navigator.clipboard.writeText(text);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const CopyBlock = props => (
    <div>
      <Button
        primary
        style={{ width: '157px' }}
        // eslint-disable-next-line react/destructuring-assignment
        onClick={() => props.onCopyCallback(props.text)}
      >
        { currentCopiedText === '' ? 'Copy To Clipboard' : 'Copied!' }
      </Button>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      <CodeBlock code={props.text} height={170} />
    </div>
  );

  const renderCreateExternalSchemaStatement = () => {
    const text = Object.keys(stateData).length > 0
      ? `${stateData.platform_metadata.create_external_schema}`
      : 'Waiting for initial steps to be completed...';

    return <CopyBlock text={text} onCopyCallback={onCopy} />;
  };

  return (
    <div>
      <div>
        {renderCreateExternalSchemaStatement()}
      </div>
    </div>
  );
};

export default CreateExternalSchema;

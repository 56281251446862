import React, { useEffect, useCallback, useState } from "react";
import { Form, Button, Message, Table } from "semantic-ui-react";
import { IndexGridNew } from "@rockerbox/styleguide";
import { datasetAccountingStore, syncCardStore } from "./zustandStore";
import { scheduleTypeOptions } from "./constants";

const syncCardSelector = state => [
  state.setCardLoading,
  state.updateCardStatus
];
const datasetSelector = state => [
  state.datasetId,
  state.snapshotSchedules,
  state.fetchSnapshotSchedule,
  state.deleteSnapshotScheduleById
];
const snapshotScheduleSelect = state => [
  state.datasetId,
  state.newSnapshotSchedule,
  state.setNewSnapshotSchedule,
  state.createNewSnapshotSchedule
];

export const SnapshotScheduleCreation = props => {
  const [
    datasetId,
    newSnapshotSchedule,
    setNewSnapshotSchedule,
    createNewSnapshotSchedule
  ] = datasetAccountingStore(snapshotScheduleSelect);
  const [setCardLoading, updateCardStatus] = syncCardStore(syncCardSelector);
  const [submitError, setSubmitError] = useState(false);
  const onTypeChange = useCallback((e, data) => {
    setNewSnapshotSchedule("schedule_type", data.value);
  });
  const onCreation = useCallback(() => {
    setSubmitError(false);
    const body = Object.assign({}, newSnapshotSchedule, {
      dataset_id: datasetId
    });
    setCardLoading(true);
    createNewSnapshotSchedule(body)
      .then(() => updateCardStatus("snapshot_schedule"))
      .catch(e => setSubmitError(true))
      .finally(() => setCardLoading(false));
  });

  return submitError ? (
    <Message error>
      <Message.Header>Failed to created scheduled snapshot</Message.Header>
      <Message.Content>
        Please contact Rockerbox service to fix this.
      </Message.Content>
    </Message>
  ) : (
    <Form>
      <Form.Group>
        <Form.Field width={11}>
          <Form.Dropdown
            label="Schdule Type"
            selection
            value={newSnapshotSchedule["schedule_type"]}
            onChange={onTypeChange}
            options={scheduleTypeOptions}
          />
        </Form.Field>
      </Form.Group>
      <Button primary onClick={onCreation}>
        Add
      </Button>
    </Form>
  );
};

const SnapshotScheduleIndex = props => {
  const [
    datasetId,
    snapshotSchedules,
    fetchSnapshotSchedule,
    deleteSnapshotScheduleById
  ] = datasetAccountingStore(datasetSelector);
  const [setCardLoading, updateCardStatus] = syncCardStore(syncCardSelector);

  useEffect(() => {
    if (datasetId) {
      setCardLoading(true);
      fetchSnapshotSchedule(datasetId).finally(() => setCardLoading(false));
    }
  }, [datasetId]);

  const ManageButtons = ({ item }) => {
    const callback = useCallback(() => deleteSnapshotScheduleById(item.id));
    return (
      <Table.Cell>
        <IndexGridNew.DeleteButton
          onDelete={callback}
          popupContent={`Delete Schedule`}>
          Delete
        </IndexGridNew.DeleteButton>
      </Table.Cell>
    );
  };

  const cols = [
    { key: "id", display: "Id" },
    { key: "dataset_id", display: "Dataset Id" },
    { key: "schedule_type", display: "Scehdule Type" },
    { key: "manage", display: "Manage", as: ManageButtons }
  ];

  const onAddCallback = useCallback(() =>
    updateCardStatus("snapshot_schedule_add")
  );
  return (
    <React.Fragment>
      <IndexGridNew data={snapshotSchedules} cols={cols} />
      <Button primary onClick={onAddCallback}>
        Add
      </Button>
    </React.Fragment>
  );
};

export default SnapshotScheduleIndex;

import React from 'react';

// import _ from 'lodash';
import { CustomList } from '@rockerbox/styleguide';
import _ from 'lodash';
import { Image, Form, Button, Loader, Dimmer, Message, Header, Grid, Popup } from 'semantic-ui-react';

import LearningModeAlert from './learningModeAlert';
import MCCWarning from './MCCWarning';
import GenericMessage from '../../../components/GenericMessage';
import NONOAUTH_PLATFORMS from '../../constants/platforms';

export const OAuthForm = ({ integration, isManager, platformAccounts, platformName, handleConnect, selectionValue, setselectionValue, loading, isOnboarding }) => ( // eslint-disable-line
  <Grid className="select-platform-account">
    <Grid.Column>
      <Header as="h1" textAlign="center" style={{ marginTop: 50 }}>
        One final step...
        <Header.Subheader style={{ marginTop: 5 }}>
          {`Select the ${integration.display_name} account to connect to Rockerbox`}
        </Header.Subheader>
      </Header>
      <div style={{ margin: '0 auto', width: 300 }}>
        <Image centered size="medium" src={integration.src} />
        <Popup
          style={{ maxWidth: '400px', width: '400px', top: '50px' }}
          basic
          open={isManager}
          header="Google Ads Manager Account not supported"
          content={<MCCWarning />}
          position="right center"
          trigger={(
            <CustomList
              options={platformAccounts}
              onChange={(e, { value }) => setselectionValue(value)}
              headerKey="account_name"
              subheaderKey="account_id"
              noDataMsg="No Account Found"
              search
            />
          )}
        />
        <LearningModeAlert {...{ platformName }} />
        <Button
          fluid
          primary
          content="Connect"
          size="large"
          onClick={handleConnect}
          disabled={!selectionValue || !!loading || isManager}
          loading={loading}
        />
        {
          isOnboarding
          && <Button style={{ marginTop: '10px' }} fluid content="Cancel" size="large" inverted onClick={() => navigate('/onboarding/platforms')} /> // eslint-disable-line
        }
      </div>
    </Grid.Column>
  </Grid>
);

export const NonOAuthForm = ({ platform, integration }) => {
  const [values, setValues] = React.useState({});
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const objValues = Object.values(values);
  const [shopifyShopName, setShopifyShopName] = React.useState('');

  const { message, fields, postFunction } = NONOAUTH_PLATFORMS[platform];

  React.useEffect(() => {
    if (fields.length === objValues.filter(v => !!v).length) return setDisabled(false);
    setDisabled(true);
  }, [objValues]);

  const updateValue = field => (evt, { value }) => {
    const copy = Object.assign(_.cloneDeep(values), { [field]: value });
    setValues(copy);
    if (platform === 'shopify' && field === 'shop_name') {
      setShopifyShopName(value);
    }
  };

  const onSubmit = () => {
    setLoading(true);
    postFunction(values).then(resp => {
      if (resp.response.success === false) {
        setLoading(false);
        throw resp.response.message;
      }
      if (platform !== 'shopify') {
        window.location = '/v3/data/marketing/integrations/main';
      } else {
        window.location = `/v3/data/marketing/integrations/shopify_checkout_extensions/${shopifyShopName}`;
      }
    })
      .catch(e => {
        setErrorMsg(e);
        setLoading(false);
      });
  };

  return (
    <Grid className="select-platform-account">
      <Grid.Column>
        <Dimmer active={loading} inverted>
          <Loader inverted />
        </Dimmer>
        <Header as="h1" textAlign="center" style={{ marginTop: 50 }}>
          Enter account details
          {/* <Header.Subheader style={{ marginTop: 5 }}>
            {`Select the ${integration.display_name} account to connect to Rockerbox`}
          </Header.Subheader> */}
        </Header>
        <div style={{ margin: '0 auto', width: 400 }}>
          <Image centered size="medium" src={integration.src} />
          <div style={{ width: 400, textAlign: 'center' }}>
            { message && <GenericMessage {...{ message }} /> }
            <Form onSubmit={() => onSubmit()} error={errorMsg !== ''}>
              <Message error header="Authorization Error" content={errorMsg} />
              {
                fields.map(f => {
                  const { displayName, name, label } = f;
                  const value = values[name];
                  const action = label && { content: label, disabled };
                  const onChange = updateValue(name);
                  return (
                    <Form.Field>
                      <label>{displayName}</label>
                      <Form.Input {...{ value, name, action, onChange }} />
                    </Form.Field>
                  );
                })
              }
              <Button fluid color="purple" className="m-t-25" type="submit" {...{ disabled: disabled || loading }}>
                Connect Account
              </Button>
            </Form>
          </div>
        </div>
      </Grid.Column>
    </Grid>
  );
};

import { NumberCell, CurrencyCell, PercentCell } from '../parts/tableCells';

export const getHeaders = (period1, period2, isConversionKPI) => {
  const KPICell = isConversionKPI ? NumberCell : CurrencyCell;
  return ([
    {
      key: 'key',
      display: 'Channel',
    },
    {
      key: 'prevSpend1',
      display: `${period1} Spend`,
      as: CurrencyCell,
      reducer: data => data.reduce((acc, item) => acc + item.prevSpend1, 0),
    },
    {
      key: 'prevSpend2',
      display: `${period2} Spend`,
      as: CurrencyCell,
      reducer: data => data.reduce((acc, item) => acc + item.prevSpend2, 0),
    },
    {
      key: 'baseline',
      display: `${period1} Sales`,
      as: KPICell,
      reducer: data => data.reduce((acc, item) => acc + item.baseline, 0),
    },
    {
      key: 'current',
      display: `${period2} Sales`,
      as: KPICell,
      reducer: data => data.reduce((acc, item) => acc + item.current, 0),
    },
    {
      key: 'delta',
      display: 'Delta',
      as: KPICell,
      reducer: data => data.reduce((acc, item) => acc + item.delta, 0),
    },
    {
      key: 'deltaPercentOfBaselineTotal',
      display: 'Delta % of Total',
      as: PercentCell, formatter: x => parseInt(x * 10000) / 100,
    },
  ]);
};
export const getSummaryHeaders = (period1, period2, isConversionKPI) => {
  const KPICell = isConversionKPI ? NumberCell : CurrencyCell;
  return ([
    {
      key: 'actual1',
      display: `${period1} Sales`,
      as: KPICell,
    },
    {
      key: 'macroDelta',
      display: 'Macro Delta',
      as: KPICell,
    },
    {
      key: 'delta',
      display: 'Marketing Delta',
      as: KPICell,
    },
    {
      key: 'actual2',
      display: `${period2} Sales`,
      as: KPICell,
    },
  ]);
};

import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Container } from 'semantic-ui-react';
import { useStepsState } from '../StepsSectionContext';
import { CopyClipboardInlineText } from '../components';

const SetupServiceAccount = () => {
  const [stepsStateData] = useStepsState();
  const { serviceAccountEmail } = stepsStateData;
  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        Grant this Rockerbox service account to your BigQuery dataset with the DataEditor role to allow the creation of tables.
      </div>
      <CopyClipboardInlineText value={serviceAccountEmail} width="100%" />
    </div>
  );
};

export default SetupServiceAccount;

import React, { useState } from 'react';

import moment from 'moment';
import { Form, Message } from 'semantic-ui-react';

import SingleDayPicker from './SingleDayPicker';

const DateParser = ({ date, onChange, label, id, required }) => {
  const [error, setError] = useState(false);

  const checkDateFormat = dateParam => {
    const inputVal = moment(dateParam, 'MM/DD/YYYY', true);
    const isFormatted = inputVal.isValid();

    if (isFormatted) {
      setError(false);
      onChange(date);
    } else {
      setError(true);
      onChange(null);
    }
  };

  return (
    <Form.Field required={required}>
      {label && <label>{label}</label>}
      <SingleDayPicker date={date} onChange={() => checkDateFormat(date)} id={id} />
      { error && (
      <Message negative compact>
        <p>* Date format must be MM/DD/YYYY</p>
      </Message>
      )}
    </Form.Field>
  );
};

export default DateParser;

import React from 'react';

import { Header, Icon } from 'semantic-ui-react';

const TopRightLinks = ({ intercomTourId, helpDocLink, intercomMessage }) => (
  <div className="top-right-help">
    { !intercomTourId ? [] : (
      <Header
        as="h5"
        color="blue"
        className="cursor-pointer opacity-75-hover-100"
        onClick={() => !!window.Intercom && window.Intercom('startTour', intercomTourId)}
        style={{ margin: 0 }}
      >
        <Icon name="play circle" style={{ marginRight: '0.5rem', marginTop: -2, fontSize: '1.25em' }} />
        Take a Tour
      </Header>
    )}
    {!helpDocLink ? [] : (
      <Header
        as="h5"
        color="purple"
        className="cursor-pointer opacity-75-hover-100"
        onClick={() => window.open(helpDocLink, '_blank')}
        style={{ margin: 0, paddingLeft: 15 }}
      >
        <Icon name="book" style={{ marginRight: '0.5rem', marginTop: -2, fontSize: '1.25em' }} />
        Documentation
      </Header>
    )}
    <Header
      as="h5"
      color="grey"
      className="cursor-pointer opacity-75-hover-100"
      onClick={() => !!window.Intercom && window.Intercom('showNewMessage', intercomMessage)}
      style={{ margin: 0, paddingLeft: 15 }}
    >
      <Icon name="question circle" style={{ marginRight: '0.5rem', marginTop: -2, fontSize: '1.25em' }} />
      Questions/Feedback
    </Header>
  </div>
);

export default TopRightLinks;

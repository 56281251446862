import { useMemo } from 'react';

import { useAtom } from 'jotai';

import { calculateDifferenceInDays, getPeriodName } from '../../../parts/time';
import { startDateBudgetAtom, endDateBudgetAtom } from '../../atoms';

export const useBudgetPeriod = () => {
  const [startDate, setStartDate] = useAtom(startDateBudgetAtom);
  const [endDate, setEndDate] = useAtom(endDateBudgetAtom);

  const budgetPeriod = useMemo(() => calculateDifferenceInDays(startDate, endDate), [startDate, endDate]);
  const budgetPeriodName = useMemo(() => getPeriodName(budgetPeriod, startDate, endDate), [budgetPeriod, startDate, endDate]);

  const onDateChange = ({ startDate: start, endDate: end }) => {
    setStartDate(start);
    setEndDate(end);
  };

  return {
    budgetPeriod,
    budgetPeriodName,
    startDate, endDate,
    onDateChange,
  };
};

import React from 'react';

import { Grid } from 'semantic-ui-react';

const ContentWrap = ({ children }) => (
  <div className="content-wrap">
    <div className="grid-wrap">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            {children}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  </div>
);

export default ContentWrap;

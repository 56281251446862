import React from 'react';
import { useAtom } from 'jotai';
import { FilterWrapper, DateRangeHooks, StyledDropdown, SaveReportButton, DebouncedSearch, RollupFilter, ResetFilterButton } from '../../components';
import { topTabAtom } from '../../atoms';
import { track } from '../../../utils/tracking';

const metricOptions = [
  {
    text: 'Conversions',
    value: 'conversions',
    key: 'conversions',
  },
  {
    text: 'CPA',
    value: 'cpa',
    key: 'cpa',
  },
];

const Filter = ({
  tiersData, loading, // data
  attributionMethod, attributionOptions, setAttributionMethod, // model
  startDate, endDate, onDateChange, firstReportingDate,  // dates
  filters, resetFilters, search, setSearch, // jotai
  funnelConfigName, currentSegments,
}) => {
  const [selectedChartMetric, setSelectedChartMetric] = useAtom(topTabAtom);

  return (
    <FilterWrapper
      {...{ loading }}
      other={(
        <>
          <StyledDropdown
            label="Attribution Model"
            options={attributionOptions}
            value={attributionMethod}
            onChange={(e, { value }) => {
              track('Journeys.ConversionFunnel.Model.set.click');
              setAttributionMethod(value);
            }}
          />
          <StyledDropdown
            label="Chart Metric"
            options={metricOptions}
            value={selectedChartMetric}
            onChange={(e, { value }) => {
              track('Journeys.ConversionFunnel.Metric.set.click');
              setSelectedChartMetric(value);
            }}
          />
          <div style={{ paddingRight: 12 }}>
            <DebouncedSearch
              onChange={(evt, { value }) => {
                track('Journeys.ConversionFunnel.search.set.enter');
                setSearch(value.toLowerCase());
                resetFilters();
              }}
              value={search}
              disabled={filters.tier_1.length > 0}
              resetFilters={resetFilters}
            />
          </div>
        </>
      )}
      date={
        <DateRangeHooks {...{ startDate, endDate, onDateChange, firstReportingDate }} />
      }
      stickyRight={(
        <>
          <SaveReportButton
            {...{ loading }}
            options={{
              'Attribution Model': attributionMethod,
              'Conv Funnel Config': funnelConfigName,
              currentSegments,
            }}
            trackNameForSaving="Journeys.ConversionFunnel.savedview.set.save"
          />
          <ResetFilterButton />
        </>
      )}
      advancedFilters={<RollupFilter rawData={tiersData} />}
      trackNameForAdvancedFilterClick="Journeys.ConversionFunnel.advanced.set.click"
    />
  );
};

export default Filter;

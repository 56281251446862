/* eslint-disable react/jsx-props-no-spreading */
import React, { Component, useState } from 'react';

import moment from 'moment';
import { Link } from 'react-router-dom';
import d3 from 'rockerbox_d3_legacy_clone';
import { Icon, Popup, Button, Table, Header, Label } from 'semantic-ui-react';
import 'moment-timezone';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

import { metricMapper, setStyles } from './goalCellHelpers';
import HorizontalStackedBar from '../../charts/HorizontalStackedBar';

// NumberCell and BounceRate cell variable
const localeOptions = { minimumFractionDigits: 0, maximumFractionDigits: 2 };

const compareColor = '#005399';
const COMPARE_LABEL_COLORS = { white: '#ffffff', green: '#00ad39', red: '#e00000', blue: '#3e70cc', gray: '#d9d9d9' };

const determineOpacity = number => {
  const magnitude = Math.abs(number);
  if (number === '-') return 0.0;
  if (magnitude < 10) return 0.2;
  if (magnitude < 20) return 0.3;
  if (magnitude < 30) return 0.4;
  if (magnitude < 40) return 0.7;
  if (magnitude < 50) return 0.9;
  return 1.0;
};

const hexToRGBA = (hex, opacity) => {
  const hexProcessed = hex.replace('#', '');
  const r = parseInt(hexProcessed.substring(0, 2), 16);
  const g = parseInt(hexProcessed.substring(2, 4), 16);
  const b = parseInt(hexProcessed.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const determineTextColor = (isNeutral, isPositiveDiff, trendReversed, useColorGradient, diff) => {
  if (isNeutral) return 'black';
  if (useColorGradient) {
    if (isPositiveDiff && !trendReversed) {
      if (determineOpacity(diff) < 0.5) {
        return 'black';
      }
      return 'white';
    }
    if (!isPositiveDiff && !trendReversed) {
      return 'black';
    }
    // logic for flipped metrics like CPA where lower is better
    if (isPositiveDiff && trendReversed) {
      return 'black';
    }
    if (!isPositiveDiff && trendReversed) {
      if (determineOpacity(diff) < 0.5) {
        return 'black';
      }
      return 'white';
    }
  }

  return 'white';
};

export const CompareLabel = ({ diff, trendReversed = false, useColorGradient }) => {
  const { white, green, red, blue, gray } = COMPARE_LABEL_COLORS;

  const isDash = diff === '-';
  const isInfinity = (diff === Number.POSITIVE_INFINITY || diff === Number.NEGATIVE_INFINITY);
  const isNaN = diff === 'NaN'; // .toFixed coerces this to a string
  const isNeutral = (diff === 0 || isDash || isNaN);
  const isPositiveDiff = diff > 0;
  const opacity = determineOpacity(diff);
  const background = isNeutral ? white
    : isPositiveDiff
      ? trendReversed
        ? useColorGradient ? hexToRGBA(gray, opacity) : red
        : useColorGradient ? hexToRGBA(blue, opacity) : green
      : trendReversed
        ? useColorGradient ? hexToRGBA(blue, opacity) : green
        : useColorGradient ? hexToRGBA(gray, opacity) : red;
  // console.log(determineTextColor(isNeutral, isPositiveDiff, trendReversed, useColorGradient, diff), diff, trendReversed);
  const color = determineTextColor(isNeutral, isPositiveDiff, trendReversed, useColorGradient, diff);
  const content = isInfinity ? ` ${String.fromCharCode(8734)} ` : isNaN ? 0 : diff;

  if (isInfinity) return <div className="compare-label" />;

  return (
    <div>
      <Label className="compare-label" style={{ background, color }}>
        {diff > 0 && '+'}
        {content}
        %
      </Label>
    </div>
  );
};

export const RecommendationCell = ({ item }) => {
  if (!item.jotai_goal || !Object.keys(item.jotai_goal).length > 0) return null;
  const { target, metric } = item.jotai_goal;
  const { extreme_over_budget } = item;

  if (item.depth > 0 || isNaN(target)) {
    return <Table.Cell> </Table.Cell>;
  }

  const metricConfig = metricMapper[metric];
  const { formattedPercentage, increaseOrDecrease } = metricConfig.percentageFromGoal(target, item);
  const hidePercentage = increaseOrDecrease.type === 'onTarget' || increaseOrDecrease.type === 'insufficientData';
  const CustomIcon = increaseOrDecrease.icon;

  return (
    <Table.Cell style={{ textAlign: 'left', fontFeatureSettings: '"kern" 1, "tnum" 1', color: '#475ddc', fontWeight: 690, whiteSpace: 'nowrap' }}>
      <Popup
        hoverable={true} inverted content={increaseOrDecrease.tooltip(metricConfig.display_name, extreme_over_budget)}
        position="top center" trigger={(
          <div>
            <CustomIcon style={{ display: 'inline-block', height: 20, margin: '0px 5px -7px 0px' }} />
            {`${increaseOrDecrease.text} ${hidePercentage ? '' : formattedPercentage}`}
          </div>
        )}
      />

    </Table.Cell>
  );
};

export const MetricVsGoalCell = ({ item }) => {
  if (!item.jotai_goal || !Object.keys(item.jotai_goal).length > 0) return null;
  if (isNaN(item.jotai_goal.target)) {
    return <Table.Cell />;
  }

  const { target, metric } = item.jotai_goal;
  const metricConfig = metricMapper[metric];
  const { trendReversed, percentageFromGoal, format } = metricConfig;
  const delta = item[metric] - target;
  const formattedValue = format(delta);

  const { increaseOrDecrease } = percentageFromGoal(target, item);
  const onTarget = increaseOrDecrease.type === 'onTarget';
  const colorStyles = setStyles(onTarget, delta, trendReversed, increaseOrDecrease);

  return (
    <Table.Cell style={{ textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
      <span style={({ padding: 5, borderRadius: 4, ...colorStyles })}>
        {onTarget && delta > 0 ? `+${formattedValue}`
          : onTarget && delta < 0 ? formattedValue
            : delta > 0 ? `+${formattedValue}` : formattedValue}
      </span>
    </Table.Cell>
  );
};

export const VisitorCpaCell = spendFormatter => ({ item, label }) => (
  <Table.Cell style={{ textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
    <DataLabel {...{ label, rightAlign: true }} />
    {item.spend ? spendFormatter.format(item.spend / item.num_new_visitors) : '-'}
  </Table.Cell>
);

export const CpaCell = (conversionKey, spendFormatter, spendKey = false) => ({ item, label }) => {
  const uniqueSpendKey = spendKey || 'spend';
  return (
    <Table.Cell style={{ textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
      <DataLabel {...{ label, rightAlign: true }} />
      {item[uniqueSpendKey] ? spendFormatter.format(item[uniqueSpendKey] / item[conversionKey]) : '-'}
    </Table.Cell>
  );
};

export const CompareCpaCell = (conversionKey, spendFormatter) => ({ item, useColorGradient }) => {
  const value = item.spend ? item.spend / item[conversionKey] : '-';
  const compareValue = item.spend_compare ? item.spend_compare / item[`${conversionKey}_compare`] : '-';
  const diff = (value === '-' || compareValue === '-') ? '-' : (((value - compareValue) / compareValue) * 100).toFixed(1);

  return (
    <Table.Cell style={{ textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
      <div className="compare-cell">
        <div>
          {value === '-' ? value : spendFormatter.format(value)}
          <br />
          <div className="compare-value">{compareValue === '-' ? compareValue : spendFormatter.format(compareValue)}</div>
        </div>
        {/* eslint-disable-next-line no-compare-neg-zero, eqeqeq */}
        <CompareLabel diff={diff == -0 ? 0 : diff} trendReversed={true} useColorGradient={useColorGradient} />
      </div>
    </Table.Cell>
  );
};

// eslint-disable-next-line no-unused-vars
export const RoasCell = (revenueKey, spendFormatter = false, spendKey = false) => ({ item, label }) => {
  const uniqueSpendKey = spendKey || 'spend';
  const formatter = value => d3.format(',.2f')(value);
  return (
    <Table.Cell style={{ textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
      <DataLabel {...{ label, rightAlign: true }} />
      {item[uniqueSpendKey] ? formatter(item[revenueKey] / item[uniqueSpendKey]) : '-'}
    </Table.Cell>
  );
};

export const CompareRoasCell = revenueKey => ({ item, useColorGradient }) => {
  const value = item.spend ? d3.format(',.2f')(item[revenueKey] / item.spend) : '-';
  const compareValue = item.spend_compare ? d3.format(',.2f')(item[`${revenueKey}_compare`] / item.spend_compare) : '-';
  const diff = value === '-' ? value : (((value - compareValue) / compareValue) * 100).toFixed(1);

  return (
    <Table.Cell style={{ textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
      <div className="compare-cell">
        <div>
          {value}
          <br />
          <div className="compare-value">{compareValue}</div>
        </div>
        <CompareLabel diff={diff} useColorGradient={useColorGradient} />
      </div>
    </Table.Cell>
  );
};

export const BounceRateCell = ({ item, label }) => (
  <Table.Cell style={{ textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
    <DataLabel {...{ label, rightAlign: true }} />
    {((item.num_bounces) * 100).toLocaleString('en-US', localeOptions)}
    %
  </Table.Cell>
);

export const SpendCell = spendFormatter => ({ item, col, label }) => {
  const value = item[col.key];
  return (
    <Table.Cell style={{ textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
      <DataLabel {...{ label, rightAlign: true }} />
      {value === Infinity ? '-' : spendFormatter.format(item[col.key])}
    </Table.Cell>
  );
};

export const CompareSpendCell = spendFormatter => ({ item, col, useColorGradient }) => {
  const value = item[col.key];
  const number = typeof value === 'number' ? value : 0;
  const compareValue = item[col.compareKey];
  const compareNumber = typeof compareValue === 'number' ? compareValue : 0;
  const diff = (((number - compareNumber) / compareNumber) * 100).toFixed(1);

  return (
    <Table.Cell style={{ textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
      <div className="compare-cell">
        <div>
          {value === Infinity ? '-' : spendFormatter.format(item[col.key])}
          <br />
          <div className="compare-value">{value === Infinity ? '-' : spendFormatter.format(compareNumber)}</div>
        </div>
        <CompareLabel diff={diff} useColorGradient={useColorGradient} />
      </div>
    </Table.Cell>
  );
};

export const RevenuePerConversionsCell = (spendFormatter, revenueKey, conversionKey) => ({ item, label }) => {
  const value = item[revenueKey] / item[conversionKey];
  return (
    <Table.Cell style={{ textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
      <DataLabel {...{ label, rightAlign: true }} />
      {value === Infinity || !value ? '-' : spendFormatter.format(value)}
    </Table.Cell>
  );
};

export const CompareRevenuePerConversionsCell = (spendFormatter, revenueKey, revenueConversionKey, conversionKey, compareConversionKey) => ({ item, useColorGradient }) => {
  const value = item[revenueKey] / item[conversionKey];
  const number = typeof value === 'number' ? value : 0;
  const compareValue = item[revenueConversionKey] / item[compareConversionKey];
  const compareNumber = typeof compareValue === 'number' ? compareValue : 0;
  const diff = (((number - compareNumber) / compareNumber) * 100).toFixed(1);

  return (
    <Table.Cell style={{ textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
      <div className="compare-cell">
        <div>
          {value === Infinity || !value ? '-' : spendFormatter.format(value)}
          <br />
          <div className="compare-value">{value === Infinity || !value ? '-' : spendFormatter.format(compareNumber)}</div>
        </div>
        <CompareLabel diff={diff} useColorGradient={useColorGradient} />
      </div>
    </Table.Cell>
  );
};

export const NumberCell = ({ item, col, label }) => {
  const value = item[col.key];
  const number = typeof value === 'number' ? value : 0;
  return (
    <Table.Cell style={{ textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
      <DataLabel {...{ label, rightAlign: true }} />
      {value === Infinity ? '-' : number.toLocaleString('en-US', localeOptions)}
    </Table.Cell>
  );
};

export const NumberCellTwoDecimals = ({ item, col, label }) => {
  const value = item[col.key];
  const number = typeof value === 'number' ? value : 0;

  return (
    <Table.Cell style={{ textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
      <DataLabel {...{ label, rightAlign: true }} />
      {number.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
    </Table.Cell>
  );
};

export const CompareNumberCellTwoDecimals = ({ item, col, useColorGradient }) => {
  const value = item[col.key];
  const number = typeof value === 'number' ? value : 0;
  const compareValue = item[col.compareKey];
  const compareNumber = typeof compareValue === 'number' ? compareValue : 0;
  const diff = (((number - compareNumber) / compareNumber) * 100).toFixed(1);

  return (
    <Table.Cell style={{ textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
      <div className="compare-cell">
        <div>
          {number.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          <br />
          <div className="compare-value">
            {compareNumber.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
        <CompareLabel diff={diff} useColorGradient={useColorGradient} />
      </div>
    </Table.Cell>
  );
};

export const PercentageCellConversionRate = ({ item, col, showPercent = true }) => {
  const numerator = col.key;
  const denominator = col.denominatorKey;
  const convRate = item[numerator] ? (item[numerator] / item[denominator]).toFixed(10) : 0;

  return (
    <Table.Cell style={{ textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
      {showPercent && `${((convRate * 100).toFixed(2))}%`}
    </Table.Cell>
  );
};

export const PercentageCellOfTotal = total => ({ item, col }) => {
  const value = item[col.key];

  return (
    <Table.Cell style={{ textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
      {`${(((value / total) * 100).toFixed(2))}%`}
    </Table.Cell>
  );
};

export const ComparePercentageCellOfTotal = (total, compareTotal) => ({ item, col, useColorGradient }) => {
  const value = item[col.key];
  const compareValue = item[col.compareKey];
  const pct = ((value / total) * 100).toFixed(2);
  const comparePct = ((compareValue / compareTotal) * 100).toFixed(2);
  const diff = (pct - comparePct).toFixed(1);

  return (
    <Table.Cell style={{ textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
      <div className="compare-cell">
        <div>
          {`${pct}%`}
          <br />
          <div className="compare-value">{`${comparePct}%`}</div>
        </div>
        {/* eslint-disable-next-line no-compare-neg-zero, eqeqeq */}
        <CompareLabel diff={diff == -0 ? 0 : diff} useColorGradient={useColorGradient} />
      </div>
    </Table.Cell>
  );
};

export const PercentageCellAvg = ({ item, col, data, showPercent = true }) => {
  const avg = item[col.key] ? ((item[col.key] / col.reducer(data)) * 100) : 0;
  return (
    <Table.Cell style={{ textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
      {showPercent && `${(avg.toFixed(2))}%`}
    </Table.Cell>
  );
};

export const PercentageCellAvgAcrossForFunnelPosition = colsForAvg => ({ item, col }) => {
  const avg = item[col.key] ? (item[col.key] / colsForAvg.reduce((acc, curr) => acc + item[curr], 0)) * 100 : 0;
  return (
    <Table.Cell style={{ textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
      {`${parseInt(avg)}%`}
    </Table.Cell>
  );
};

// Two Numbers in 1 cell Starts here

export const ProgressBar = (colors, items) => ({ item }) => (
  <Table.Cell>
    <HorizontalStackedBar
      items={items}
      data={[item]}
      height={20}
      margin="auto"
      colors={colors}
    />
  </Table.Cell>
);

/* NameCell parts start (SummaryDot can be used as its own cell) */
export const SummaryDotCell = dotColors => ({ item, col }) => (
  <Table.Cell>
    <LabelSwatch
      style={{ background: dotColors[item[col.key]] || '#697AE5' }}
      size={16}
    />
  </Table.Cell>
);

const LabelSwatch = styled.div`
  display: inline-block;
  width: ${x => `${x.size || 6}px`};
  height: ${x => `${x.size || 6}px`};
  border-radius: ${x => `${(x.size || 6) / 2}px`};
  vertical-align: top;
  margin-left: 8px;
  background: ${props => (props.color ? props.color : '#697AE5')};
  position: relative;
`;

const ChevronDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: ${x => (tinycolor(x.color).getBrightness() > 200 ? '#000' : '#fff')};

  i.icon.chevron {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
`;

const Chevron = ({ hasOpenChildren, hasChildren, color }) => (
  <ChevronDiv {...{ hasOpenChildren, color }}>
    {(!!hasChildren || !!hasOpenChildren)
      && (
        <Icon
          name={`chevron ${hasOpenChildren ? 'down' : 'right'}`}
          style={{ opacity: hasOpenChildren ? 0.5 : 1 }}
        />
      )}
  </ChevronDiv>
);

const TierDot = ({ color, hasChildren, hasOpenChildren }) => (
  <div>
    <LabelSwatch color={color} size="19">
      <Chevron {...{ hasOpenChildren, hasChildren, color }} />
    </LabelSwatch>
  </div>
);

const TierCell = ({ item, col, color, depth, hasChildren, hasOpenChildren }) => (
  <div style={{
    display: 'flex',
    alignItems: 'center',
    // position: 'relative'
  }}
  >
    {!!depth
      && <div className="nest-bar" style={{ background: color || '#697AE5' }} />}
    <TierDot {...{ color, hasChildren, hasOpenChildren }} />
    <div style={{
      verticalAlign: 'top',
      display: 'inline-block',
      padding: '.75em',
      fontSize: 14 - depth,
    }}
    >
      {item[col.key]}
    </div>
  </div>
);

export const NameCell = dotColors => props => {
  const { item, col, data } = props;
  const { depth } = item;
  const color = dotColors[item.tier_1] || '#697AE5';

  const hasChildren = item.childKeys && item.childKeys.length > 0;
  const position = data.indexOf(item);
  const next = data[position + 1] || { key: '' };
  const hasOpenChildren = (next.key.includes(item.key) && next.depth > item.depth);

  return (
    <Table.Cell style={{ padding: 0 }}>
      <div
        className="name-cell"
        style={{
          minHeight: '2.9em',
          border: 0,
          display: 'flex',
          marginLeft: depth * 16,
        }}
      >
        <TierCell {...{ item, col, color, depth, hasChildren, hasOpenChildren }} />
      </div>
    </Table.Cell>
  );
};

export const CompareNameCell = dotColors => props => {
  const { item, col, data, compareStart, compareEnd } = props;
  const { depth } = item;
  const color = dotColors[item.tier_1] || '#697AE5';

  const hasChildren = item.childKeys && item.childKeys.length > 0;
  const position = data.indexOf(item);
  const next = data[position + 1] || { key: '' };
  const hasOpenChildren = (next.key.includes(item.key) && next.depth > item.depth);

  return (
    <Table.Cell style={{ padding: 0 }}>
      <div
        className="name-cell-compare"
        style={{ marginLeft: depth * 16 }}
      >
        <TierCell {...{ item, col, color, depth, hasChildren, hasOpenChildren }} />
      </div>
      {depth === 0
        && (
          <>
            <br />
            <div style={{ color: compareColor, marginTop: '-45px', marginLeft: '38px' }}>
              {compareStart}
              {' '}
              -
              {compareEnd}
            </div>
          </>
        )}
    </Table.Cell>
  );
};
/* NameCell parts end */

// IndexGrid utility cells
export const Schedule = ({ item }) => {
  const little_time = moment()
    .hours(item.time)
    .minutes(0)
    .format('LT');

  return (
    <Table.Cell>
      {item.days.split(',').length > 6
        ? 'Every day'
        : item.days.replace(/,/g, ', ')}
      {' '}
      {little_time}
    </Table.Cell>
  );
};

export const CelledCheckmark = ({ item, col }) => (
  <Table.Cell>
    <Icon
      color={item[col.key] ? 'green' : 'yellow'}
      name={item[col.key] ? 'checkmark' : 'exclamation triangle'}
      size="large"
    />
  </Table.Cell>
);

export const TextOnly = ({ item, col }) => (
  <Table.Cell>
    {typeof item[col.key] === 'object'
      ? item[col.key].map(c => c[col.displayValue]).join(', ')
      : item[col.key]}
  </Table.Cell>
);

export const Time = ({ item, col }) => {
  const time = moment(item[col.key]).format('LLL');
  return <Table.Cell width="75">{time}</Table.Cell>;
};

export const LocalFromUTCTime = ({ item, col, UTCOffset }) => {
  const utc = moment.tz(item[col.key], 'UTC');
  const time = utc.clone().add(UTCOffset, 'hours').format('LLL');
  return <Table.Cell width="75">{time}</Table.Cell>;
};

export const SendButton = ({ item, script, send, popup }) => (
  <ButtonWithHover
    popupContent={popup || 'Send'}
    positive
    icon="paper plane"
    onClick={() => send(item, script)}
  />
);

export const HistoryButton = ({ itemId, onClick }) => (
  <ButtonWithHover
    popupContent="View Status"
    icon="history"
    as={Link}
    to={`/v2/settings/reports/view/inspect/id/${itemId}`}
    onClick={onClick}
  />
);

export const SetupButton = ({ outboxId, onClick }) => (
  <ButtonWithHover
    popupContent="Setup Instruction"
    icon="file alternate outline"
    as={Link}
    to={`/v2/settings/destination/view/outbox/id/${outboxId}`}
    onClick={onClick}
  />
);

export const ViewReportButton = ({ itemId }) => (
  <ButtonWithHover
    popupContent="View Report"
    icon="eye"
    as={Link}
    to={`/v2/settings/conversion/view/${itemId}`}
  />
);

export const DocsButton = ({ itemId, onClick }) => (
  <ButtonWithHover
    primary
    popupContent="View Schema"
    icon="file alternate"
    onClick={onClick}
    as={Link}
    to={`/v2/settings/reports/view/schema/id/${itemId}`}
  />
);

export const CustomizeButton = ({ itemId, onClick }) => (
  <ButtonWithHover
    primary
    popupContent="Customize Columns"
    icon="file alternate"
    onClick={onClick}
    as={Link}
    to={`/v2/settings/reports/view/customize/id/${itemId}`}
  />
);

export const DownloadButton = ({ itemId }) => (
  <ButtonWithHover
    popupContent="Download"
    icon="download"
    as={Link}
    to={`/data/delivered/space/download?id=${itemId}`}
    target="_blank"
  />
);

export const ButtonWithHover = props => {
  const { noArrow, popupContent } = props;
  return (
    <Popup
      inverted
      basic={noArrow}
      content={popupContent}
      trigger={<Button {...props} />}
    />
  );
};

export const Spacer = () => (
  <Button icon="paper plane" inverted disabled className="visibility-hidden" />
);

export const DeleteButton = props => {
  const { onDelete, disabled, popupContent, popup } = props;
  const [popupOpen, setPopupOpen] = useState(false);

  const onDeleteClick = (e, data) => {
    onDelete(e, data);
    setPopupOpen(false);
  };

  const handleClose = () => {
    setPopupOpen(false);
  };

  if (disabled) {
    return (
      <ButtonWithHover
        negative
        noArrow={true}
        popupContent={popupContent}
        icon="trash alternate"
      />
    );
  }
  return (
    <Popup
      onClose={handleClose}
      open={popupOpen}
      on="click"
      trigger={(
        <Button
          {...props}
          negative
          icon="trash alternate"
          onClick={e => {
            e.stopPropagation();
            setPopupOpen(true);
          }}
        />
      )}
      content={(
        <Popup
          trigger={(
            <Button
              {...props}
              negative
              content={popup || 'Delete'}
              onClick={onDeleteClick}
              onMouseOut={handleClose}
            />
          )}
          on="hover"
          content={popupContent || 'Delete'}
        />
      )}
    />
  );
};

export class DeactivateButton extends Component {
  constructor(props) {
    super(props); // Always call super first in constructor
    this.state = {
      popupOpen: false,
    };
  }

  onActionClick = e => {
    const { onChange } = this.props;
    onChange(e);
    this.setState({ popupOpen: false });
  };

  handleClose = () => {
    this.setState({ popupOpen: false });
  };

  handleClick = e => {
    e.stopPropagation();
    this.setState({ popupOpen: true });
  };

  render() {
    const { popup, active } = this.props;
    const { popupOpen } = this.state;
    return (
      <Popup
        onClose={this.handleClose}
        open={popupOpen}
        on="click"
        trigger={
          active === 1 ? (
            <Button icon="pause" onClick={this.handleClick} />
          ) : (
            <Button icon="play" onClick={this.handleClick} />
          )
        }
        content={(
          <Button
            content={
              popup || active === 1
                ? 'Deactivate'
                : 'Activate'
            }
            onClick={this.onActionClick}
            onMouseOut={this.handleClose}
          />
        )}
      />
    );
  }
}

export const ViewButton = ({ url, onClick }) => (
  <ButtonWithHover
    popupContent="View"
    onClick={onClick}
    icon="eye"
    as={Link}
    to={url}
  />
);

export const IconButton = ({
  col: { popupContent = 'edit', icon = 'pencil', onClick },
}) => (
  <ButtonWithHover
    popupContent={popupContent}
    onClick={onClick}
    icon={icon}
  />
);

export const EditButton = props => {
  const { onClick, url, popupContent } = props;
  return (
    <ButtonWithHover
      onClick={onClick}
      icon="pencil"
      as={Link}
      to={url}
      {...props}
      popupContent={popupContent || 'Edit'}
    />
  );
};

export const TransformButton = props => {
  const { onClick, url, popupContent } = props;
  return (
    <ButtonWithHover
      onClick={onClick}
      icon="exchange"
      as={Link}
      to={url}
      {...props}
      popupContent={popupContent || 'Transform'}
    />
  );
};

export const CopyButton = ({ url, onClick }) => (
  <ButtonWithHover
    popupContent="Copy"
    onClick={onClick}
    icon="copy"
    as={Link}
    to={url}
  />
);

export const MatchingUrlsButton = ({ url }) => (
  <ButtonWithHover
    popupContent="View Matching URLs"
    icon="eye"
    as={Link}
    to={url}
    color="black"
  />
);

export const EllipsedCell = ({ item, col, style = {} }) => (
  <Table.Cell className="ellipsed" style={style}>
    <Popup
      trigger={<span>{item[col.key]}</span>}
      content={item[col.key]}
      inverted
      hoverable
    />
  </Table.Cell>
);

export const UrlCell = ({ item, col }) => {
  const urlArray = item[col.key].split(/\?|&/);
  const urlDomain = urlArray[0];
  const urlParams = urlArray
    .filter(p => p !== urlDomain)
    .map(p => (
      <Table.Row key={p}>
        <Table.Cell>{p.split('=')[0]}</Table.Cell>
        <Table.Cell>{p.split('=')[1]}</Table.Cell>
      </Table.Row>
    ));

  return (
    <Table.Cell className="ellipsed">
      <Popup
        hoverable
        wide
        position="bottom left"
        trigger={<span>{item[col.key]}</span>}
        content={(
          <>
            <Header as="h6" content={urlDomain} className="m-0" disabled />
            <Table celled compact="very" fluid size="small" columns={2}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Parameter</Table.HeaderCell>
                  <Table.HeaderCell>Value</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{urlParams}</Table.Body>
            </Table>
          </>
        )}
      />
    </Table.Cell>
  );
};

export const CollapsingCell = ({ item, col }) => (
  <Table.Cell collapsing style={{ minWidth: 70 }}>
    {item[col.key]}
  </Table.Cell>
);

export const ExpandContractCell = ({
  item,
  idKey,
  handleExpand,
  handleContract,
  expand,
  ownComponent,
}) => {
  if (ownComponent) {
    return (
      <Button
        as="a"
        size="mini"
        icon={expand === item[idKey] ? 'minus' : 'plus'}
        onClick={() => (expand === item[idKey]
          ? handleContract(item[idKey])
          : handleExpand(item[idKey]))}
      />
    );
  }
  return (
    <Table.Cell>
      <Button
        as="a"
        size="mini"
        icon={expand === item[idKey] ? 'minus' : 'plus'}
        onClick={() => (expand === item[idKey]
          ? handleContract(item[idKey])
          : handleExpand(item[idKey]))}
      />
    </Table.Cell>
  );
};

export const DownloadCell = ({ onClick }) => (
  <ButtonWithHover
    popupContent="Download All Reports"
    icon="download"
    size="mini"
    onClick={onClick}
  />
);

const DataLabel = ({ label, rightAlign }) => (
  !!label && <span className={rightAlign ? 'data-label-left' : 'data-label'}>{label}</span>
);

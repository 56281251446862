import { useAtom } from 'jotai';
import { atomWithHash } from 'jotai/utils';

// const sub = (x) => console.log(x);

export const layoutOptionAtom = atomWithHash('layoutOption', 0);
export const customerTypeAtom = atomWithHash('customerType', 'all');
export const attributionMethodAtom = atomWithHash('model', '');

export const modelAtom = atomWithHash('model', '');
export const segIdAtom = atomWithHash('segId', '0');
export const convValueAtom = atomWithHash('platformConversion', '');
export const attributionWindowAtom = atomWithHash('attributionWindow', '');

// time-period comparison
export const compareRangeAtom = atomWithHash('compareRange', '', { delayInit: true });
export const compareStartDateAtom = atomWithHash('compareStartDate', '');
export const compareEndDateAtom = atomWithHash('compareEndDate', '');

// position in path
export const anyAtom = atomWithHash('any', 'any');
export const firstAtom = atomWithHash('first', 'first');
export const midAtom = atomWithHash('mid', 'mid');
export const lastAtom = atomWithHash('last', 'last');
export const andOrAtom = atomWithHash('and_or', {});

// tiers
export const tier1Atom = atomWithHash('tier_1', []);
export const tier2Atom = atomWithHash('tier_2', []);
export const tier3Atom = atomWithHash('tier_3', []);
export const tier4Atom = atomWithHash('tier_4', []);
export const tier5Atom = atomWithHash('tier_5', []);

// goal
export const goalAtom = atomWithHash('goal', {});

// saved_report
export const saveIDAtom = atomWithHash('saveID', 0);

// search bar
export const searchAtom = atomWithHash('search', '');
export const analysisTypeAtom = atomWithHash('analysisType', 'tiers');
export const analysisSearchAtom = atomWithHash('analysisSearch', '');
export const platformStrategySearchAtom = atomWithHash('platformStrategySearch', '');

// edit breakdowns
export const tiersAtom = atomWithHash('tiers', []);

// customize columns
export const columnsAtom = atomWithHash('columns', []);

// benchmarks view
export const categoryAtom = atomWithHash('category', '');
export const categoryValueAtom = atomWithHash('categoryValue', '');
export const selectedChannelAtom = atomWithHash('channel', 'facebook');
export const platformAtom = atomWithHash('platform', 'facebook');
export const industryAtom = atomWithHash('industry', 'All industries');
export const spendLevelAtom = atomWithHash('spendLevel', 'all_spend');
export const adTypeAtom = atomWithHash('adType', 'All ad types');
export const benchmarksTopTapAtom = atomWithHash('topTab', 'cpa');

// overlap view
export const analysisAtom = atomWithHash('analysisType', 'platform');
export const overlapAtom = atomWithHash('overlapType', 'strategy');

// tab above chart
export const topTabAtom = atomWithHash('topTab', 'conversions');

// mmm
export const forecastViewAtom = atomWithHash('view', '');
export const forecastChannelAtom = atomWithHash('channel', '');

export const useAtomViaDropdown = atom => {
  const [value, setValue] = useAtom(atom);
  const onChange = (e, { value: valueUpdate }) => setValue(valueUpdate);
  return [value, setValue, onChange];
};

// new visitors, funnel position, paths
export const reportTypeAtom = atomWithHash('reportType', '');
export const minPathLenAtom = atomWithHash('minPathLen', '2');

// pixel qa view
export const eventTypeAtom = atomWithHash('eventType', 'pixel-events-qa');
export const relativeDateAtom = atomWithHash('duration', 'today');

import React, { useContext, useEffect, Suspense } from 'react';

import { AppWrap } from '@rockerbox/styleguide';
import { Provider } from 'jotai';
import { Route, Switch, Redirect } from 'react-router-dom';

import AttributableEvent from './components/AttributableEvent';
import MappedEvents from './components/AttributableIndex';
import Fetch from './components/parts/GenericFetch';
import V2 from './components/SceneApp';
import { checkGoogleLogin } from './components/SceneApp/data';
import Documentation from './components/SceneDocumentation';
import Gateway from './components/SceneGateway';
import Loading from './components/SceneLoading';
import Maintenance from './components/SceneMaintenance';
import Onboarding from './scenes/Onboarding';
import Signup from './scenes/Signup';
import SignupFormEmbed from './scenes/SignupFormEmbed';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { switchAccount } from './utils/api';
import { CacheContextProvider, CacheContext } from './utils/CacheContext';
import { StripeProvider } from './utils/stripe';
import Tracking from './utils/tracking';
import { DimmerContextProvider } from './V3/context/DimmerContext';
import V3 from './V3/views/AppV3';
import IntegrationsLastStep from './V3/views/Integrations/FinalStep';

const GATEWAY_PATHS = [
  '/set_password', '/login', '/set_password', '/password-forgot', '/password-success', '/test_login',
];

window.switchAccount = switchAccount;

const App = () => {
  const [state, setState] = useContext(CacheContext);
  const { user, account } = state;

  const setUserResp = ({ response }) => setState({ user: response });
  const setAccountResp = ({ response }) => setState({ account: response });

  const showRoutes = user !== undefined && account !== undefined;
  const isLoggedIn = !!user;

  const { username } = user || {};
  const isAdminUser = (username || '').includes('@rockerbox.com');

  const { tier, requires_setup, pixel_source_name } = account || {};
  const isOnboarding = tier !== 'lite' && !!requires_setup;
  const redirectPath = !isLoggedIn ? '/login' : isOnboarding ? '/onboarding' : '/v3';

  const allowV2 = isAdminUser;

  const handleCookieChange = event => {
    const changed = event?.changed[0]?.name;
    if (changed === 'advertiser_name') {
      document.location.reload();
    }
  };

  useEffect(() => {
    try {
      /* eslint-disable no-undef */
      // eslint-disable-next-line no-unused-expressions
      cookieStore;
      cookieStore.addEventListener('change', handleCookieChange);
      return () => {
        cookieStore.removeEventListener();
      };
      /* eslint-enable no-undef */
    } catch {
      console.warn('Cookie store not supported');
    }
  }, []);

  useEffect(() => {
    if (account && username) {
      const cookieName = 'google_login';
      const googleLoginCookieMatch = document.cookie.match(RegExp(`(?:^|;\\s*)${cookieName}=([^;]*)`));
      const cookieValue = googleLoginCookieMatch ? googleLoginCookieMatch[1] : null;

      if (!cookieValue || cookieValue !== username) {
        try {
          checkGoogleLogin(account, username, isAdminUser);
        } catch {
          console.warn(`Failed to check google login. Username: ${username}`);
        }
      }
    }
  }, [account, username]);

  const FORCE_MAINTENANCE = false;

  /*
    * COPY FOR MAINTENANCE PAGE LIVES IN attribution-ui/src/components/SceneMaintenance/index.js
    * set FORCE_MAINTENACE above to true to show the maintenance page.
    */

  if (FORCE_MAINTENANCE) {
    return (
      <Switch>
        <Route path="/maintenance" component={Maintenance} />
        <Route>
          <Redirect to="/maintenance" />
        </Route>
      </Switch>
    );
  }

  return (
    <>
      <Fetch endpoint="/user" success={setUserResp} error={() => setUserResp({ response: false })} />
      <Fetch endpoint="/account" success={setAccountResp} error={() => setAccountResp({ response: false })} />
      {showRoutes && (
        <Switch>

          {/* public pages */}
          <Route path="/documentation" component={Documentation} />
          <Route path="/signup_form" component={SignupFormEmbed} />
          <Route path="/loading" component={Loading} />

          {/* login and signup pages */}
          {!isLoggedIn && [
            <Route path={GATEWAY_PATHS} component={Gateway} />,
            <Route path="/signup/:tier?" component={Signup} />,
          ]}

          {/* onboarding */}
          {isLoggedIn && isOnboarding && [
            <Route
              path="/v3/data/marketing/integrations/auth/:platformName?"
              render={({ match }) => {
                const { platformName } = match.params;
                return <Redirect to={`/onboarding/platforms/${platformName}`} />;
              }}
            />,
            <Route path="/v3/data/customers/conversions/main">
              <Redirect to="/onboarding/store" />
            </Route>,
            <Route path="/onboarding/platforms/:platformName" component={IntegrationsLastStep} />,
            <Route path="/onboarding/:step?/:platformName?" component={Onboarding} />,
          ]}

          {/* app */}
          {isLoggedIn && !isOnboarding
          && <Route path="/v3" component={V3} />}

          {/* scale and admin users */}
          {isLoggedIn && allowV2
          && <Route path="/v2" component={V2} />}

          {(isLoggedIn && pixel_source_name === 'remitly')
          && [
            <Route exact path="/v2/channels/mappings" component={MappedEvents} />,
            <Route exact path="/v2/channels/mappings/create/:urlId?/:t1?/:t2?" component={AttributableEvent} />,
            <Route exact path="/v2/channels/mappings/edit/:id/:urlId?" component={AttributableEvent} />,
            <Route exact path="/v2/channels/mappings/copy/:id/:urlId?" component={AttributableEvent} />,
          ]}

          {/* default redirect */}
          <Route>
            <Redirect to={isLoggedIn ? redirectPath : `${redirectPath}?next=${window.location.pathname}`} />
          </Route>

        </Switch>
      )}
    </>
  );
};

const AppContext = () => (
  <AppWrap>
    <Provider>
      <Suspense fallback="Loading...">
        <CacheContextProvider>
          <DimmerContextProvider>
            <Tracking />
            <StripeProvider>
              <App />
            </StripeProvider>
          </DimmerContextProvider>
        </CacheContextProvider>
      </Suspense>
    </Provider>
  </AppWrap>
);

export default AppContext;

import React, { useMemo } from 'react';
import { Header } from 'semantic-ui-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ArrowCircleRightIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

import { dataSpendImage } from '@rockerbox/styleguide';
import * as routes from '../../baseRoutes';
import { useGlobalState } from '../../hooks/global';

const ConnectPromo = () => {
  const { authorizations } = useGlobalState();

  const hasSpendIntegrations = useMemo(
    () => !!authorizations && authorizations.filter(x => x.platform !== 'shopify').length,
    [authorizations],
  );

  if (hasSpendIntegrations) return null;

  return (
    <Link to={routes.connectIntegration} className="connect-platforms-promo">
      <div className="image-wrap">
        <img src={dataSpendImage} alt="spend data" />
      </div>
      <div className="text-wrap">
        <Header as="h2" inverted>
          <Header.Content>
            How does your marketing stack up against the rest of the industry?
            <Header.Subheader>
              Connect your advertising platforms to view detailed performance reports
            </Header.Subheader>
          </Header.Content>
        </Header>
      </div>
      <div className="icon-wrap">
        <ArrowCircleRightIcon />
      </div>
    </Link>
  );
};

export default ConnectPromo;

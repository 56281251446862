import React, { useState } from 'react';
import { Header, Button } from 'semantic-ui-react';
import { deleteDB } from 'idb';

const ClearCache = () => {
  const [loading, setLoading] = useState(false);

  const clearIndexedDB = async () => {
    setLoading(true);
    const dbs = await window.indexedDB.databases();

    const deletions = dbs.map(x => deleteDB(x.name, {
      blocked: () => {
        window.alert('Please close all other open Rockerbox tabs and try again');
        setLoading(false);
      },
    }));

    await Promise.all(deletions);
    setLoading(false);
    window.location.reload();
  };

  return (
    <>
      <Header
        as="h3"
        content="Advanced Settings"
      />
      <p>
        Experiencing issues with long load times or slow performance? Clearing your browser cache may help in some situations.
      </p>
      <Button
        content="Clear Cache"
        onClick={clearIndexedDB}
        loading={loading}
      />
    </>
  );
};

export default ClearCache;

import { useGlobalState } from '../../../hooks/global';

const formatNumber = num => {
  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
  });

  const number = (typeof num !== 'number' || Number.isNaN(num)) ? numberFormat.format(0) : numberFormat.format(num);

  return number;
};

const formatPercent = number => {
  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 4,
  });

  const percentage = (typeof number !== 'number' || Number.isNaN(number)) ? numberFormat.format(0) : numberFormat.format(number);

  return percentage;
};

const formatCurrency = (number, currencyOverride) => {
  const { currencyCode } = useGlobalState();
  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    maximumFractionDigits: 2,
    currency: currencyOverride || currencyCode || 'USD',
  });

  const currency = (typeof number !== 'number' || Number.isNaN(number)) ? currencyFormat.format(0) : currencyFormat.format(number);

  return currency;
};

export const metrics = {
  spend: {
    formatter: formatCurrency,
  },
  impressions: {
    formatter: formatNumber,
  },
  clicks: {
    formatter: formatNumber,
  },
  ctr: {
    formatter: formatPercent,
  },
  cpc: {
    formatter: formatCurrency,
  },
  cpm: {
    formatter: formatCurrency,
  },
  conversions: {
    formatter: formatNumber,
  },
  cpa: {
    formatter: formatCurrency,
  },
  revenue: {
    formatter: formatCurrency,
  },
  roas: {
    formatter: formatNumber,
  },
};

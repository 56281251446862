import { submitAwsBatch, getBatchStatus, createPresignedURL } from '../../../api/aws';
import { getEventReportStatus, getEventStatusFiles } from '../../../api/dataReporting';

/**
 * Check the status of an event report.
 *
 * @param {string} eventType - The type of event report.
 * @param {string} pixelSourceName - The name of the pixel source.
 * @param {string} startDate - The start date of the report.
 * @param {string} endDate - The end date of the report.
 * @param {string} action - The action of the report.
 * @returns {Promise<Object>} - The status of the event report.
 * @throws {Error} - If the event report status is 'error'.
 */
export async function checkEventReportStatus(eventType, pixelSourceName, startDate, endDate, action) {
  const eventReportStatus = await getEventReportStatus(eventType, pixelSourceName, startDate, endDate, action);
  if (eventReportStatus.status && eventReportStatus.status === 'error') {
    throw new Error(eventReportStatus.message);
  }
  return eventReportStatus;
}

/**
 * Submits an AWS Batch job based on the event report status and other parameters.
 *
 * @param {Object} eventReportStatus - The event report status object.
 * @param {string} startDate - The start date for the job.
 * @param {string} endDate - The end date for the job.
 * @param {string} action - The action for the job.
 * @param {string} pixelSourceName - The pixel source name for the job.
 * @returns {string} - The batch job ID.
 * @throws {Error} - If the AWS Batch job submission fails.
 */
export async function submitAwsBatchJob(eventReportStatus, startDate, endDate, action, pixelSourceName) {
  const batchJobId = eventReportStatus.batch_job_id;
  if (eventReportStatus.new_download_required) {
    const batchSubmitPostObj = {
      script_name: 'onsite-pixel-download-log-events',
      start_date: startDate,
      end_date: endDate,
      action,
      advertiser: pixelSourceName,
    };
    const awsBatchSubmitResult = await submitAwsBatch(batchSubmitPostObj);
    if (awsBatchSubmitResult.status && awsBatchSubmitResult.status === 'fail') {
      throw new Error(awsBatchSubmitResult.message);
    }
    return awsBatchSubmitResult.batch_job_id;
  }
  return batchJobId;
}

/**
 * Monitors the status of an AWS Batch job.
 *
 * @param {string} batchJobId - The ID of the AWS Batch job to monitor.
 * @returns {Promise<void>} - A promise that resolves when the batch job status is 'SUCCEEDED'.
 * @throws {Error} - If the batch job status is 'FAILED'.
 */
export async function monitorBatchJobStatus(batchJobId) {
  while (true) {
    const batchStatusResult = await getBatchStatus(batchJobId); // eslint-disable-line no-await-in-loop
    if (batchStatusResult.status === 'FAILED') {
      throw new Error('Batch job failed.');
    } else if (batchStatusResult.status === 'SUCCEEDED') {
      break;
    } else {
      await new Promise(resolve => setTimeout(resolve, 10000)); // eslint-disable-line no-await-in-loop, no-promise-executor-return
    }
  }
}

/**
 * Fetches event status files for a given batch job ID, event type, and action.
 *
 * @param {string} batchJobId - The ID of the batch job.
 * @param {string} eventType - The type of the event.
 * @param {string} action - The action to perform.
 * @returns {Promise<Array<string>>} - A promise that resolves to an array of filenames.
 * @throws {Error} - If there is an error fetching the event status files.
 */
export async function fetchEventStatusFiles(batchJobId, eventType, action) {
  const filenameResult = await getEventStatusFiles(batchJobId, eventType, action);
  if (filenameResult.status === 'error') {
    throw new Error(filenameResult.message);
  }
  return filenameResult.filenames;
}

/**
 * Processes an array of filenames by generating pre-signed URLs for each, categorizing them into successful and failed uploads based on the operation outcome.
 *
 * @param {Array} filenames - Filenames to generate URLs for.
 * @param {string} pixelSourceName - Source name used in filename processing.
 * @returns {Object} Contains arrays of successful and failed uploads, each with relevant details.
 */
export async function processFilenames(filenames, pixelSourceName) {
  const postFilenamePromises = filenames.map(async filename => {
    try {
      const method = 'get';
      const bucket = 'onsite-pixel-events-report';
      const stripPsn = filename.replace(`${pixelSourceName}/`, '');
      const postFilenameResult = await createPresignedURL(method, bucket, stripPsn, 'application/json');
      if (postFilenameResult.status && postFilenameResult.status === 'fail') {
        console.error(`Failed to post filename ${filename}: ${postFilenameResult.message}`);
        return { success: false, filename, message: postFilenameResult.message };
      }
      return { success: true, url: postFilenameResult.url, message: 'Success', filename };
    } catch (error) {
      console.error(`Error posting filename ${filename}: ${error.message}`);
      return { success: false, filename, message: 'There was an error downloading your file' };
    }
  });

  const results = await Promise.all(postFilenamePromises);
  return {
    successfulUploads: results.filter(result => result.success),
    failedUploads: results.filter(result => !result.success),
  };
}

/**
 * Process a report for a given event type, pixel source name, start date, end date, and action.
 *
 * @param {string} eventType - The type of event.
 * @param {string} pixelSourceName - The name of the pixel source.
 * @param {string} startDate - The start date of the report.
 * @param {string} endDate - The end date of the report.
 * @param {string} action - The action to perform on the report.
 * @returns {Promise<Object>} - A promise that resolves to an object with the processed report information.
 * @throws {Error} - If an error occurs during the report processing.
 */
export async function processReport(eventType, pixelSourceName, startDate, endDate, action) {
  try {
    // Execute the helper functions in sequence
    const eventReportStatus = await checkEventReportStatus(eventType, pixelSourceName, startDate, endDate, action);
    const batchJobId = await submitAwsBatchJob(eventReportStatus, startDate, endDate, action, pixelSourceName);
    await monitorBatchJobStatus(batchJobId);
    const filenames = await fetchEventStatusFiles(batchJobId, eventType, action);
    const { successfulUploads, failedUploads } = await processFilenames(filenames, pixelSourceName);

    return {
      processed: 'success',
      successfulUploads,
      failedUploads,
    };
  } catch (error) {
    console.error('An error occurred in the process:', error.message);
    return {
      processed: 'error',
      message: 'An error has occurred, Rockerbox has been notified',
    };
  }
}

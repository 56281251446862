import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { Grid, Header, Button, Image, Dropdown } from 'semantic-ui-react';

import { getAllIntegrations, confirmShopifyCheckoutExtensions, getShopifyAccounts } from '../../api/account';
import * as routes from '../../baseRoutes';

const InstallCheckoutExtensionApp = () => {
  const history = useHistory();
  const { shopName } = useParams();

  const platform = 'shopify';

  const [integration, setIntegration] = useState([]);
  const [pixelVariablesCorrect, setPixelVariablesCorrect] = useState(false);
  const [pixelVariablesInstalled, setPixelVariablesInstalled] = useState(false);
  const [appInstallWindowOpened, setAppInstallWindowOpened] = useState(false);
  const [shopifyAccountList, setShopifyAccountList] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');

  const navigate = route => history.push(route);

  const handleInstallClick = () => {
    setAppInstallWindowOpened(true);
    setPixelVariablesInstalled(false);

    if (selectedAccount) {
      window.open(`https://app.rockerbox.com/auth/shopify_pixel/login?shop=${selectedAccount}`, '_blank');
    } else {
      window.open('https://app.rockerbox.com/auth/shopify_pixel/login', '_blank');
    }
  };

  const validateInstalledMetafields = () => {
    setPixelVariablesInstalled(false);
    confirmShopifyCheckoutExtensions(selectedAccount)
      .then(data => {
        setPixelVariablesCorrect((data.pixel_string === 1 && data.cname_domain === 1));
        setPixelVariablesInstalled(true);
      })
      .catch(
        setPixelVariablesCorrect(false),
        setPixelVariablesInstalled(false),
      );
  };

  const fetchAndSetShopifyAccountList = () => {
    getShopifyAccounts()
      .then(data => {
        const accountList = data.account_list.map(value => ({
          key: value,
          text: value,
          value,
        }));
        setShopifyAccountList(accountList);
      })
      .catch(
        setShopifyAccountList([]),
      );
  };

  useEffect(() => {
    getAllIntegrations()
      .then(data => {
        const i = data.find(c => c.platform === platform);
        i.src = `https://images.rockerbox.com/integrations/${i.logo_img}`;
        setIntegration(i);
      });
  }, []);

  useEffect(() => {
    fetchAndSetShopifyAccountList();
  }, []);

  useEffect(() => {
    validateInstalledMetafields();
  }, [selectedAccount]);

  useEffect(() => {
    if (shopName) {
      setSelectedAccount(shopName);
    }
  }, [shopName]);

  return (
    <Grid className="set-up-shopify-checkout-extensions">
      <Grid.Column>
        <Header as="h1" textAlign="center" style={{ marginTop: 50 }} />
        <div style={{ margin: '0 auto', width: 400 }}>
          <Image centered size="medium" src={integration.src} />
          <div style={{ width: 400, textAlign: 'center', background: '#f8f8f9', padding: 10 }}>
            {shopifyAccountList
              && (
              <Dropdown
                fluid
                selection
                defaultValue={selectedAccount}
                value={selectedAccount}
                onChange={(e, data) => setSelectedAccount(data.value)}
                options={shopifyAccountList}
              />
              )}
          </div>
          <div style={{ width: 400, textAlign: 'center', background: '#f8f8f9' }}>
            {pixelVariablesInstalled
            && !pixelVariablesCorrect
            && !appInstallWindowOpened
            && (
            <div>
              <p>
                Using Shopify Checkout Extensibility? Install the Rockerbox Checkout Pixel App to ensure that your checkout pages have Rockerbox&lsquo;s required pixels.
              </p>
            </div>
            )}
            {!pixelVariablesInstalled
            && (
            <div>
              <p>
                Confirm that the Rockerbox Checkout Pixel App is configured correctly.
              </p>
            </div>
            )}
            {pixelVariablesInstalled
            && pixelVariablesCorrect
            && (
            <div>
              <p>
                Your Rockerbox Checkout Pixel App is correctly configured! You may leave this page.
              </p>
              <Button fluid color="purple" className="m-t-25" onClick={() => navigate(routes.integrationsPlatforms)}>
                View All Integrations
              </Button>
            </div>
            )}
            {pixelVariablesInstalled
            && !pixelVariablesCorrect
            && appInstallWindowOpened
            && (
            <div>
              <p>
                Your Rockerbox Checkout Pixel App is not correctly configured. If you&lsquo;ve already clicked the link and installed the app in Shopify, please contact Rockerbox support.
              </p>
            </div>
            )}
            {pixelVariablesInstalled
            && !pixelVariablesCorrect
            && (
            <Button fluid color="purple" className="m-t-25" onClick={() => handleInstallClick()}>
              Install Rockerbox Checkout Pixel App
            </Button>
            )}
            {appInstallWindowOpened
            && (
              <Button fluid color="purple" className="m-t-25" onClick={() => validateInstalledMetafields()}>
                Confirm Rockerbox Checkout Pixel App Setup
              </Button>
            )}
            {pixelVariablesInstalled
            && !pixelVariablesCorrect
            && !appInstallWindowOpened
            && (
            <Button fluid color="gray" className="m-t-25" onClick={() => navigate(routes.integrationsPlatforms)}>
              I&lsquo;m not using Shopify Checkout Extensibility
            </Button>
            )}
          </div>
        </div>
      </Grid.Column>
    </Grid>
  );
};

export default InstallCheckoutExtensionApp;

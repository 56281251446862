import React from 'react';

import { ContentWrap } from '@rockerbox/styleguide';
import { Toaster } from 'react-hot-toast';
import { Route, Switch, Redirect } from 'react-router-dom';

import CommandBar from './CommandBar';
import HelpDocsSuggestions from './HelpDocsSuggestions';
import { withRedirect } from './helpers';
import GlobalErrorBoundary from '../../../components/GlobalErrorBoundary';
import GlobalApplicationData from '../../../components/SceneApp/globalApplicationData';
import TrackPageChange from '../../../components/TrackPageChange';
import * as baseRoutes from '../../baseRoutes';
import { Navbar } from '../../components';
import * as routes from '../../routes';
import { redirects } from '../../routes';
import AdhocExportsHistory from '../AdhocExports';
import AdvertisingTrackingParams from '../AdvertisingTrackingParams';
import AttributionReportIndex from '../AttributionReport';
import ChannelOverlap from '../ChannelOverlap';
import ConversionFunnel from '../ConversionFunnel';
import ConversionsDetail from '../ConversionsDetail';
import ConversionsOverview from '../ConversionsOverview';
import ConversionsUserProfile from '../ConversionsUserProfile';
import DataWarehouse from '../DataWarehouse';
import SetupConnection from '../DataWarehouse/SetupConnection';
import EventStatus from '../EventStatus';
import FunnelPosition from '../FunnelPosition';
import Home from '../Home';
import Integrations from '../Integrations';
import IntegrationsLastStep from '../Integrations/FinalStep';
import PlatformConnectGallery from '../Integrations/PlatformConnectGallery';
import ShopifyCheckoutExtensions from '../Integrations/ShopifyCheckoutExtensions';
import MarketingMixModeling from '../MarketingMixModeling';
import MarketingPaths from '../MarketingPaths';
import NewVisitors from '../NewVisitors';
import PlatformBenchmarks from '../PlatformBenchmarks';
import PlatformPerformance from '../PlatformPerformance';
import PromoCodes from '../PromoCodes';
import PromoCodeCreate from '../PromoCodes/CreatePromo';
import PromoCodeEdit from '../PromoCodes/EditPromo';
import RockerboxPerformance from '../RockerboxPerformance';
import SavedReports from '../SavedReports';
import Settings from '../Settings';
import SpendTrends from '../SpendTrends';
import Surveys from '../Surveys';
import SurveyCreate from '../Surveys/CreateSurvey';
import SurveyEdit from '../Surveys/EditSurvey';
// import SyncHistory from '../SyncHistory';

const V3 = ({ location }) => (
  <div className="ui-v3">
    <GlobalApplicationData />
    <TrackPageChange path={location.pathname} />
    <Route path="/" component={CommandBar} />
    <Route path="/" component={HelpDocsSuggestions} />
    <Route path="/" component={Navbar} />
    <ContentWrap>
      <GlobalErrorBoundary>
        <Switch>
          <Route exact path={routes.home} component={Home} />

          {/* Saved Reports */}
          <Route exact path={routes.savedReports} component={SavedReports} />

          {/* Data */}
          {/* DATA -> Collect Marketing Data */}
          <Route exact path={routes.integrations} component={Integrations} />
          <Route exact path={routes.connectIntegration} component={PlatformConnectGallery} />
          <Route path={routes.integrationLastStep} component={IntegrationsLastStep} />
          <Route path={routes.integrationTrackingParameters} component={AdvertisingTrackingParams} />
          <Route path={routes.shopifyCheckoutExtensions} component={ShopifyCheckoutExtensions} />
          <Route exact path={routes.promoCodes} component={PromoCodes} />
          <Route exact path={routes.promoCodesCreate} component={PromoCodeCreate} />
          <Route exact path={routes.promoCodesEdit} component={PromoCodeEdit} />
          <Route exact path={routes.surveys} component={Surveys} />
          <Route exact path={routes.surveyCreate} component={SurveyCreate} />
          <Route exact path={routes.surveyEdit} component={SurveyEdit} />
          {/* <Route exact path={routes.syncHistory} component={SyncHistory} /> */}

          {/* DATA -> Track Customer Data */}
          <Route path={routes.conversionsOverview} component={ConversionsOverview} />
          <Route path={routes.conversionsDetail} component={ConversionsDetail} />
          <Route path={routes.conversionsUserProfile} component={ConversionsUserProfile} />
          <Route exact path={routes.eventStatus} component={EventStatus} />

          {/* DATA -> Data exports */}
          <Route exact path={routes.adHocExportsHistory} component={AdhocExportsHistory} />
          <Route path={routes.datawarehouseSetup} component={SetupConnection} />
          <Route exact path={routes.datawarehouse} component={DataWarehouse} />

          {/* Attribution */}
          <Route exact path={routes.crossChannelReport} component={AttributionReportIndex} />
          <Route exact path={routes.platformPerformance} component={PlatformPerformance} />
          <Route path={routes.rockerboxPerformance} component={RockerboxPerformance} />

          {/* Journeys */}
          <Route exact path={routes.marketingPaths} component={MarketingPaths} />
          <Route exact path={routes.funnelPosition} component={FunnelPosition} />
          <Route exact path={routes.conversionFunnel} component={ConversionFunnel} />
          <Route exact path={routes.channelOverlap} component={ChannelOverlap} />
          <Route exact path={routes.newVisitors} component={NewVisitors} />

          {/* MMM */}
          <Route exact path={routes.marketingMixModeling} component={MarketingMixModeling} />

          {/* Benchmarks */}
          <Route path={routes.spendTrends} component={SpendTrends} />
          <Route path={routes.platformBenchmarks} component={PlatformBenchmarks} />

          {/* Settings */}
          <Route path={routes.settingsIndex} component={Settings} />

          {/* OLD ROUTES WITH REDIRECT */}
          <Route exact path={redirects.crossChannelReport.oldRoute} component={withRedirect(AttributionReportIndex, redirects.crossChannelReport)} />
          <Route exact path={redirects.platformPerformance.oldRoute} component={withRedirect(PlatformPerformance, redirects.platformPerformance)} />
          <Route exact path={redirects.rockerboxPerformance.oldRoute} component={withRedirect(RockerboxPerformance, redirects.rockerboxPerformance)} />
          <Route exact path={redirects.marketingPaths.oldRoute} component={withRedirect(MarketingPaths, redirects.marketingPaths)} />
          <Route exact path={redirects.funnelPosition.oldRoute} component={withRedirect(FunnelPosition, redirects.funnelPosition)} />
          <Route exact path={redirects.conversionFunnel.oldRoute} component={withRedirect(ConversionFunnel, redirects.conversionFunnel)} />
          <Route exact path={redirects.channelOverlap.oldRoute} component={withRedirect(ChannelOverlap, redirects.channelOverlap)} />
          <Route exact path={redirects.newVisitors.oldRoute} component={withRedirect(NewVisitors, redirects.newVisitors)} />
          <Route exact path={redirects.marketingMixModeling.oldRoute} component={withRedirect(MarketingMixModeling, redirects.marketingMixModeling)} />
          <Route exact path={redirects.integrations.oldRoute} component={withRedirect(Integrations, redirects.integrations)} />

          {/* Default */}
          <Route>
            <Redirect to={baseRoutes.home} />
          </Route>
        </Switch>
      </GlobalErrorBoundary>
    </ContentWrap>
    <Toaster position="top-center" reverseOrder={false} />
  </div>
);

export default V3;

import React, { useMemo } from 'react';

import CorrelationTable from './CorrelationTable';
import ModelPredVsActual from './ModelPredVsActual';
import PredVsActualTest from './PredVsActualTest';
import TrainingDataChart from './TrainingDataChart';
import { useMenu } from '../hooks/useMenu';
import { ModelSectionHeader } from '../parts/stateless';

const ModelInfo = ({ isConversionKPI, modelData, features, featureNameMap, modelDate }) => {
  const { predVsActual, metrics, trainingData, correlationData, predVsActualTestData } = modelData || {};

  const impKeys = useMemo(() => {
    if (!trainingData || !trainingData.length) return [];
    return Object.keys(trainingData[0]).filter(key => key.endsWith('_IMP'));
  }, [trainingData]);

  const trainingChartData = useMemo(() => {
    if (!trainingData || !trainingData.length) return [];
    return trainingData.map(accu => {
      impKeys.forEach(impKey => delete accu[impKey]);
      return accu;
    });
  }, [trainingData, impKeys]);
  const items = ['Fit', 'Features'];
  const { Component: ResultsMenu, selectedItem } = useMenu(items, items[0], false, false, false);

  return (
    <>
      <ResultsMenu />
      {selectedItem === 'Fit' && (
        <div>
          <ModelSectionHeader text="Model Results" right={!!modelDate && `Build Date: ${modelDate}`} />
          <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            <ModelPredVsActual {...{ predVsActual, metrics, isConversionKPI }} />
            <PredVsActualTest predVsActualTestData={predVsActualTestData} isConversionKPI={isConversionKPI} />
          </div>
        </div>
      )}
      {selectedItem === 'Features' && (
        <>
          <TrainingDataChart {...{ isConversionKPI, trainingData: trainingChartData, features, featureNameMap, predVsActual }} />
          <CorrelationTable {...{ isConversionKPI, correlationData, features }} />
        </>
      )}
    </>
  );
};

export default ModelInfo;

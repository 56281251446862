import React from 'react';
import RockerboxDatasetSyncForm from './RockerboxDatasetSyncForm';

const RockerboxDataSync = ({ submitDatasetToSync, isSidebar, disableInputs, open }) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!open) return <></>;

  const formStyles = {
    display: 'flex',
    flexDirection: isSidebar ? 'column' : 'row',
    height: 'auto',
    margin: 0,
    ...isSidebar ? { justifyContent: 'center' } : { alignItems: 'flex-end' },
  };

  return (
    <>
      <h2>Add a new share - Rockerbox data</h2>
      <RockerboxDatasetSyncForm
        styles={formStyles}
        submitDatasetToSync={submitDatasetToSync}
        disableInputs={disableInputs}
        width={isSidebar ? 400 : 250}
      />
    </>
  );
};
export default RockerboxDataSync;

import { NAV_MENU } from './constants';
import * as routes from '../../baseRoutes';

export const NAV_ROUTES = NAV_MENU.flatMap(x => x.children.flatMap(y => (!!y.children
  ? y.children.map(z => ({ section: x.name, ...z }))
  : ({ section: x.name, ...y }))));

export const ROUTE_MAP = Object.entries(routes).reduce((accu, [key, value]) => {
  accu[value] = key;
  return accu;
}, {});

export const NAV_MAP = NAV_ROUTES.reduce((accu, curr) => {
  const routeName = ROUTE_MAP[curr.route];
  if (!!routeName) accu[routeName] = curr;
  return accu;
}, {});

import React, { useEffect } from 'react';

import FilterTouchPoint from './FilterTouchPoint';
import { FilterWrapper, DateRangeHooks, StyledDropdown, SaveReportButton, ResetFilterButton } from '../../components';
import { NTF_OPTIONS } from '../../constants/options';
import { segmentOptions } from '../../utils/options';

const GROUP_BY_OPTIONS = [
  { text: 'Tier 1', value: '1', key: '1' },
  { text: 'Tier 1-2', value: '2', key: '2' },
  { text: 'Tier 1-3', value: '3', key: '3' },
  { text: 'Tier 1-4', value: '4', key: '4' },
  { text: 'Tier 1-5', value: '5', key: '5' },
];

const Filter = ({
  loading, tiers, firstTiers, lastTiers, // data
  tier, setTier, // tier groupby filter
  segments, id, setId, // conversion segment
  startDate, endDate, onDateChange,  // dates
  hasNtf, customerType, setCustomerType, // customer type
  filterTouchpoints, filterTouchpointsMethods, // advanced filters
}) => {
  const SEGMENT_OPTIONS = segmentOptions(segments);

  useEffect(() => {
    if (!hasNtf) setCustomerType('all');
  }, [hasNtf]);

  return (
    <FilterWrapper
      {...{ loading }}
      other={(
        <>
          <StyledDropdown
            label="Conversion Type"
            options={SEGMENT_OPTIONS}
            value={parseInt(id)}
            onChange={(e, { value }) => setId(value)}
          />
          <StyledDropdown
            label="Group By"
            options={GROUP_BY_OPTIONS}
            value={tier}
            onChange={(e, { value }) => setTier(value)}
          />
          <StyledDropdown
            label="Customer Type"
            disabled={!hasNtf}
            options={NTF_OPTIONS}
            value={customerType}
            onChange={(e, { value }) => setCustomerType(value)}
          />
        </>
      )}
      date={
        <DateRangeHooks {...{ startDate, endDate, onDateChange }} /> // add back firstReportingDate
      }
      stickyRight={(
        <>
          <SaveReportButton {...{ loading }} />
          <ResetFilterButton />
        </>
      )}
      advancedFiltersOpened={<FilterTouchPoint {...{ tiers, firstTiers, lastTiers, filterTouchpoints, filterTouchpointsMethods }} />}
    />
  );
};

export default Filter;

import { useMemo } from 'react';
import moment from 'moment';
import * as Router from 'react-router-dom';
import * as routes from '../routes';
import { encode, decode } from '../utils/urlFormatter';

/**
 * Used to update a path with dynamic filters and values
 * @param path
 * @param current
 * @param updates
 * @returns {*}
 */
const updatePath = (path, current, updates) => {
  const pathParts = path.split('/');
  const updateObj = Object.assign(current, updates);
  const updateObjKeys = Object.keys(updateObj);

  const pathArray = pathParts.reduce((p, split) => {
    const keyName = split.includes(':') && split.replace(':', '').replace('?', '');
    const hasKey = updateObjKeys.indexOf(keyName) > -1;

    if (hasKey && updateObj[keyName] !== undefined) p.push(updateObj[keyName]);
    if (!keyName) p.push(split);
    return p;
  }, []);

  return pathArray.join('/');
};

export const useURLState = (fieldName, defaultValue) => {
  const match = Router.useRouteMatch();
  const { push } = Router.useHistory();
  const { path, params } = match;
  const value = params[fieldName];
  const setValue = val => push(updatePath(path, params, { [fieldName]: val }));

  return [value || defaultValue, setValue];
};

export const useURLStateAndSetDefault = (fieldName, defaultValue, forceDefault = false) => {
  const match = Router.useRouteMatch();
  const { push } = Router.useHistory();
  const { path, params } = match;
  const value = params[fieldName]?.includes(':') ? undefined : params[fieldName];

  const { hash } = document.location;

  const setValue = val => (
    push(updatePath(path, params, { [fieldName]: val ? encode(val) : val }) + hash)
  );

  if (
    (forceDefault)
    && (value === undefined)
    && (defaultValue !== undefined)
  ) setValue(defaultValue);

  return [decode(value) || defaultValue, setValue];
};

/**
 * Used to build a path using a route name and params object
 * @param route
 * @param params
 * @returns {*}
 */
export const buildHash = hash => {
  const rebuiltHash = Object.keys(hash).map(key => `${key}=${hash[key]}`).join('&');
  return `#${rebuiltHash}`;
};

export const resolveMacro = x => {
  const isMacro = x?.startsWith('{{') && x?.endsWith('}}');
  if (!isMacro) return x;

  const macro = x.replace(/{{|}}/g, '').trim().toLowerCase();
  if (macro.endsWith('d')) {
    const numDaysAgo = parseInt(macro.replace('d', ''));
    return moment.utc().utcOffset(-5).subtract(numDaysAgo, 'days').format('YYYY-MM-DD');
  }

  return x;
};

export const buildPath = (route, params = {}) => {
  const path = routes[route];
  if (!path) return null;

  const pathParts = path.split('/');

  let outOfParams = false;
  const pathArray = pathParts.reduce((acc, pathPart) => {
    if (outOfParams) return acc;

    const isParam = pathPart.startsWith(':');
    if (!isParam) {
      acc.push(pathPart);
      return acc;
    }

    const keyName = pathPart.replace(':', '').replace('?', '');
    const hasKey = params[keyName] !== undefined;
    if (hasKey) {
      const value = resolveMacro(params[keyName]);
      acc.push(value);
      return acc;
    }

    outOfParams = true;
    return acc;
  }, []);

  return pathArray.join('/');
};

/**
 * Used to extract query params from a URL
 */
export const useQuery = () => {
  const { search } = Router.useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

import React, { useEffect, useMemo } from 'react';

import { Segment } from 'semantic-ui-react';

import Announcements from './Announcements';
import FreeDashboard from './FreeDashboard';
import { getTimeOfDay } from './helpers';
import MetricsDashboard from './MetricsDashboard';
import { GenericHeader } from './parts';
import StarterDashboard from './StarterDashboard';
import { SavedReportsGrid } from '../../components';
import { useGlobalState } from '../../hooks/global';
import { useURLStateAndSetDefault } from '../../hooks/urlState';
import { monthAgo as defaultStartDate, yesterday as defaultEndDate } from '../../utils/time';

const Home = () => {
  const { state, segments, currencyCode } = useGlobalState();
  const { account, user } = state;
  const { advertiser_name, tier } = account || {};

  const [startDate, setStartDate] = useURLStateAndSetDefault('startDate', defaultStartDate, true);
  const [endDate, setEndDate] = useURLStateAndSetDefault('endDate', defaultEndDate, true);

  const featuredSegment = useMemo(() => {
    if (!segments) return null;
    return segments.find(s => !!s.featured);
  }, [segments]);

  const firstReportingDate = featuredSegment?.first_reporting_date;
  const firstName = !!user ? user.first_name : false;
  const name = firstName === false ? '' : firstName || advertiser_name;
  const heading = `Good ${getTimeOfDay()}, ${name}`;
  const isLite = tier === 'lite';
  const isStarter = tier === 'starter';
  const greeting = "Let's dive into your marketing.";
  const onDateChange = input => {
    setStartDate(input.startDate);
    setEndDate(input.endDate);
  };

  useEffect(() => {
    if (!isLite && !isStarter && !!firstReportingDate && startDate < firstReportingDate) {
      setStartDate(firstReportingDate);
    }
  }, [startDate, firstReportingDate]);

  return (
    currencyCode === undefined ? null
      : isLite
        ? <FreeDashboard {...{ heading, startDate, endDate, onDateChange, currencyCode, tier }} />
        : isStarter
          ? <StarterDashboard {...{ heading, startDate, endDate, onDateChange, currencyCode, tier }} />
          : (
            <>
              <div className="hero-top">
                <GenericHeader
                  header={heading}
                  subheader={`Welcome to Rockerbox! ${greeting}`}
                  paddingBottom="1em"
                  datePicker={!!startDate && !!endDate && {
                    startDate,
                    endDate,
                    firstReportingDate: !isLite && firstReportingDate,
                    onDateChange,
                    marginTop: 2,
                  }}
                />
                <Announcements />
                <MetricsDashboard {...{ startDate, endDate }} />
              </div>

              <Segment>
                <SavedReportsGrid columns={3} />
              </Segment>
            </>
          )
  );
};

export default Home;

// const HomeWrapper = () => (
//   <ViewAccessWrapper
//     viewName='home'
//     viewComponent={<Home />}
//   />
// );

// export default HomeWrapper;

/* eslint-disable no-undef */
import { useState, useEffect, useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment';

import { buildRecommendation } from './buildRecommendation';
import { getMMMRuns, getMMMData } from '../../../api/attributionModel';
import { buildChannelTactics, featuresToNameMap } from '../helpers';

const useCurrentModelRun = () => {
  const [, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState();

  // get available models and fetch most recent one
  const initData = async () => {
    const res = await getMMMRuns();
    const runs = res?.model_run;
    setModels(runs);
    const latestRun = runs?.[0];
    setSelectedModel(latestRun);
  };
  useEffect(() => { initData(); }, []);

  return {
    selectedModel,
  };
};

const useCurrentModelRunData = selectedModel => {
  const [modelData, setModelData] = useState();
  useEffect(() => {
    if (!selectedModel) return;
    getMMMData(selectedModel)
      .then(data => setModelData(data));
  }, [selectedModel]);

  return {
    modelData,
  };
};

export const useModelData = () => {
  const { selectedModel } = useCurrentModelRun();
  const { modelData } = useCurrentModelRunData(selectedModel);

  const { trainingStats } = modelData || {};

  // generate nicely formatted list of features and channels
  const [features, channels] = useMemo(() => {
    if (!modelData) return [[], []];
    const rawFeatures = modelData?.features;
    const f = buildChannelTactics(rawFeatures);
    const c = _.uniq(f.map(g => g.channel));
    return [f, c];
  }, [modelData]);

  // generate nicely formatted list of spend features and channels
  const [spendFeatures, spendChannels] = useMemo(() => {
    if (!modelData) return [[], []];
    const rawFeatures = modelData?.features.filter(f => !f.endsWith('_IMP'));
    const f = buildChannelTactics(rawFeatures);
    const c = _.uniq(f.map(g => g.channel));
    return [f, c];
  }, [modelData]);

  // calculate response curves for each feature
  const _responseCurves = useMemo(() => {
    if (!modelData || !features?.length) return;

    const { raw } = modelData;
    return features.reduce((accu, f) => {
      const featureKey = f.value;

      // filter response curves by training spend range
      const { spend_min, spend_max } = trainingStats.find(x => x.feature === featureKey) || {};
      const res = raw.response_curves
        .filter(row => row.feature_name === featureKey)
        .filter(row => row.spend_input >= spend_min && row.spend_input <= spend_max);

      // remove duplicate spend values and calc roas
      const curve = _.uniqBy(res, 'spend_input')
        .sort((a, b) => b.spend_input - a.spend_input) // sort in descending order
        .map(row => ({ predicted_roas: row.marginal_response / row.spend_input, ...row }));

      accu[featureKey] = curve;
      return accu;
    }, {});
  }, [modelData, features]);

  // calculate key stats for each feature
  const featureStats = useMemo(
    () => buildRecommendation(_responseCurves, trainingStats),
    [_responseCurves, trainingStats],
  );

  const featureNameMap = useMemo(() => featuresToNameMap(features), [features]);

  // Originally, the thought was that the UI based predict function was yielding a prediction that was too high versus what would be seen server-side.
  // This code validates that the UI based prediction is within 5% of the server-side prediction by using an actual date range rather than the averages.
  // This code is commented out, but should be migrated in the future to a function so we can more easily validate the UI code

  /*
  if (featureStats && _responseCurves) {

    const {
      predVsActual,
      trainingData,
      trainingDates
    } = modelData;

    const totalObservations = predVsActual.length;

    const last30 = trainingData
      .slice(totalObservations - 31, totalObservations -1)
      .reduce((p,c) => {
        Object.keys(c).forEach(k => {
          if (k === 'date') return;
          p[k] = c[k] + (p[k] || 0);
        })
        return p
      }, {})

    const targets = Object.keys(last30)
      .reduce((p,key) => {
        p[key] = { value: last30[key] / 30 }
        return p
      }, {})

    const rawCurves = Object.entries(_responseCurves)
      .reduce((p, [k, v]) => {
        p.push({ key: k , values: v })
        return p
      }, [])

    const result = forecastData(rawCurves, targets)
    const resultPred = Object.values(result).reduce((p,c) => p+c,0)*30

    const totalPred = predVsActual
      .slice(totalObservations - 31, totalObservations -1)
      .reduce((p,row) => p + row.pred, 0)

    //debugger

  }
  */

  const responseCurves = _responseCurves || undefined;

  // run date of selected model
  const modelDate = useMemo(() => {
    if (!selectedModel) return;
    return moment.unix(selectedModel).format('MMMM D, YYYY');
  }, [selectedModel]);

  return {
    selectedModel,
    modelData,
    features,
    featureNameMap,
    spendFeatures,
    channels,
    spendChannels,
    responseCurves,
    featureStats,
    trainingStats,
    modelDate,
  };
};

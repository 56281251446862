import ContentCard from './ContentCard'
import * as SubParts from './ContentCard'

import React from 'react';
import { Button } from 'semantic-ui-react';

const ShowMore = (props) => {

  const { maxHeight } = props
  const [hide, setHide] = React.useState(maxHeight == 0);
  React.useEffect(() => {
    setHide(maxHeight == 0)
  }, [maxHeight])

  const Footer = () => <div style={{
    position: "absolute",
    bottom: "0", 
    left: "0",
    width: "100%", 
    textAlign: "center", 
    margin: "0", 
    padding: "20px 0", 
    backgroundImage: "linear-gradient(to bottom, transparent, white)"
  }}>
    <div>
      <Button onClick={() => setHide(true)}>Show More</Button>
    </div>
  </div>

  return <ContentCard 
    interiorHeight={!hide ? props.maxHeight: undefined} 
    footer={!hide && <Footer />} 
    {...props} 
  />
}

export default Object.assign(ContentCard, SubParts, { ShowMore })

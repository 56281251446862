import React from 'react';
import styled from 'styled-components'; 
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom'; 
import * as identity from '../../../identity'; 

const ActionButtonStyles = styled(Button)`
  background-color: ${props => props.color || identity.BLUE} !important;
  color: white !important;

  a {
    color: white !important;
  }
`; 

const UrlButton = (props) => (
  <Link to={props.url} onClick={props.callback}>
    <ActionButtonStyles {...props}>
      {props.text}
    </ActionButtonStyles>
  </Link>
)

export default UrlButton; 
  
UrlButton.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  callback: PropTypes.func
}

import React from 'react';
import styled from 'styled-components';
import Container from '../../components/parts/Container';
import * as identity from '../../identity';

export const ToastStyles = styled.div`
  padding: 30px;
  background-color: ${identity.BLUE2};
  color: white;
  border-radius: 6px;
  width: 300px;
`;

export const CloseButtonStyles = styled(Container)`
  position: absolute;
  right: 10px;
  top: 5px;
  color: white;
`;

export const Toast = ({ message }) => (
  <ToastStyles>
    <CloseButtonStyles>x</CloseButtonStyles>
    {message}
  </ToastStyles>
);

export default Toast;

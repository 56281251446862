import React, { useState } from 'react';

import { Dropdown } from 'semantic-ui-react';

import { FormWrapSegment } from '../BudgetForecast/form/Layout';

export const quarterlyDataToOptions = quarterlyData => Object.keys(quarterlyData).map(key => ({
  key,
  text: key,
  value: key,
}));

export const ComparisonForm = ({ fiscalQuarters, setPeriod1, setPeriod2 }) => {
  const quarterlyOptions = quarterlyDataToOptions(fiscalQuarters);

  return (
    <FormWrapSegment>
      <Dropdown placeholder="Starting Quarter" selection options={quarterlyOptions} onChange={(e, { value }) => setPeriod1(value)} />
      <Dropdown placeholder="Ending Quarter" selection options={quarterlyOptions} onChange={(e, { value }) => setPeriod2(value)} />
    </FormWrapSegment>
  );
};

export const useFormData = () => {
  const [period1, setPeriod1] = useState();
  const [period2, setPeriod2] = useState();
  return {
    period1, period2,
    setPeriod1, setPeriod2,
  };
};

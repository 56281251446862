import * as baseRoutes from './baseRoutes';

/* MAINTENANCE */

export const maintenance = `${baseRoutes.maintenance}`;

/* HOME */
export const home = `${baseRoutes.home}/:startDate?/:endDate?`;

/* SAVED REPORTS */
export const savedReports = `${baseRoutes.savedReports}`;

/* DATA */
// DATA -> Collect Marketing Data
export const integrations = `${baseRoutes.integrationsPlatforms}/:startDate?/:endDate?`;
export const integrationLastStep = `${baseRoutes.integrationLastStep}/:platformName?`;
export const integrationTrackingParameters = `${baseRoutes.integrationTrackingParameters}/:platformName`;
export const shopifyCheckoutExtensions = `${baseRoutes.shopifyCheckoutExtensions}/:shopName?`;
export const connectIntegration = `${baseRoutes.connectIntegration}`;
export const promoCodes = `${baseRoutes.promoCodes}/:activeStep?/:showCreate?`;
export const promoCodesCreate = `${baseRoutes.promoCodesCreate}/:entityType?`;
export const promoCodesEdit = `${baseRoutes.promoCodesEdit}/:id`;
export const surveys = `${baseRoutes.surveys}`;
export const surveyCreate = `${baseRoutes.surveyCreate}`;
export const surveyEdit = `${baseRoutes.surveyEdit}/:id`;
// export const syncHistory = `${baseRoutes.syncHistory}`;

// DATA -> Track Customer Data
export const conversionsOverview = `${baseRoutes.conversionsOverview}/:startDate?/:endDate?`;
export const conversionsDetail = `${baseRoutes.conversionsDetail}/:id?/:startDate?/:endDate?`;
export const conversionsUserProfile = `${baseRoutes.conversionsUserProfile}/:id/:date/:convHashId`;
export const conversionsConnect = `${baseRoutes.conversionsConnect}/:platform`;
export const eventStatus = `${baseRoutes.eventStatus}`;

// DATA -> Data exports
export const adHocExportsHistory = `${baseRoutes.adHocExportsHistory}`;
export const datawarehouse = `${baseRoutes.datawarehouse}`;
export const datawarehouseSetup = `${baseRoutes.datawarehouseSetup}/:platformName`;

/* ATTRIBUTION */
export const crossChannelReport = `${baseRoutes.crossChannelReport}/:id?/:startDate?/:endDate?/:creditRedistributionId?`;
export const platformPerformance = `${baseRoutes.platformPerformance}/:view?/:platformName?/:startDate?/:endDate?`;
export const rockerboxPerformance = `${baseRoutes.rockerboxPerformance}/:view?/:platformName?/:startDate?/:endDate?`;

/* JOURNEYS */
export const marketingPaths = `${baseRoutes.marketingPaths}/:id?/:startDate?/:endDate?/:tier?`;
export const funnelPosition = `${baseRoutes.funnelPosition}/:id?/:startDate?/:endDate?`;
export const conversionFunnel = `${baseRoutes.conversionFunnel}/:startDate?/:endDate?/:conversionFunnelConfigId?`;
export const channelOverlap = `${baseRoutes.channelOverlap}/:platformName?/:startDate?/:endDate?/:id?`;
export const newVisitors = `${baseRoutes.newVisitors}/:startDate?/:endDate?`;

/* MMM */
export const marketingMixModeling = `${baseRoutes.marketingMixModeling}`;

/* BENCHMARKS */
export const spendTrends = `${baseRoutes.spendTrends}`;
export const platformBenchmarks = `${baseRoutes.platformBenchmarks}`;

/* SETTINGS */
export const settingsIndex = `${baseRoutes.settingsIndex}`;

/* REDIRECTS FOR OLD ROUTES */
export const redirects = {
  crossChannelReport: {
    oldRoute: '/v3/attribution/attribution_report/:id?/:startDate?/:endDate?/:creditRedistributionId?',
    newRoute: baseRoutes.crossChannelReport,
  },
  platformPerformance: {
    oldRoute: '/v3/channels/digital_advertising/platform/:platformName?/:startDate?/:endDate?',
    newRoute: baseRoutes.platformPerformance,
  },
  rockerboxPerformance: {
    oldRoute: '/v3/channels/digital_advertising/comparison/:platformName?/:startDate?/:endDate?',
    newRoute: baseRoutes.rockerboxPerformance,
  },
  marketingPaths: {
    oldRoute: '/v3/funnel/marketing_paths/:id?/:startDate?/:endDate?/:tier?',
    newRoute: baseRoutes.marketingPaths,
  },
  funnelPosition: {
    oldRoute: '/v3/funnel/position/:id?/:startDate?/:endDate?',
    newRoute: baseRoutes.funnelPosition,
  },
  conversionFunnel: {
    oldRoute: '/v3/funnel/conversion_funnel/:startDate?/:endDate?/:conversionFunnelConfigId?',
    newRoute: baseRoutes.conversionFunnel,
  },
  channelOverlap: {
    oldRoute: '/v3/channels/channel_overlap/:platformName?/:startDate?/:endDate?/:id?',
    newRoute: baseRoutes.channelOverlap,
  },
  newVisitors: {
    oldRoute: '/v3/funnel/new_visitors/:startDate?/:endDate?',
    newRoute: baseRoutes.newVisitors,
  },
  marketingMixModeling: {
    oldRoute: '/v3/modeling/mmm',
    newRoute: baseRoutes.marketingMixModeling,
  },
  integrations: {
    oldRoute: '/v3/data/marketing/advertising_platforms/:startDate?/:endDate?',
    newRoute: baseRoutes.integrationsPlatforms,
  },
};

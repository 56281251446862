// PERMISSIONS in RBAC (Oso Policy)

// USER MANAGEMENT
export const RBAC_CREATE_CUSTOM_ROLE = 'user_management.create_custom_role';
export const RBAC_ASSIGN_ROLES = 'user_management.assign_roles';

// EMAIL SUBSCRIPTIONS
export const RBAC_EMAIL_SUBSCRIPTIONS_READ = 'email_subscriptions.read';
export const RBAC_EMAIL_SUBSCRIPTIONS_EDIT = 'email_subscriptions.edit';
export const RBAC_EMAIL_SUBSCRIPTIONS_RECEIVE = 'email_subscriptions.receive';

// UI Views
export const RBAC_UI_VIEWS_DATA_INTEGRATIONS = 'ui_views.data.integrations.read';
export const RBAC_UI_VIEWS_DATA_PROMO_CODES = 'ui_views.data.promo_codes.read';
export const RBAC_UI_VIEWS_DATA_SURVEYS = 'ui_views.data.surveys.read';
export const RBAC_UI_VIEWS_DATA_ORDERS_AND_CONVERSIONS = 'ui_views.data.orders_and_conversions.read';
export const RBAC_UI_VIEWS_DATA_AD_HOC_EXPORTS = 'ui_views.data.ad_hoc_exports.read';
export const RBAC_UI_VIEWS_DATA_GOOGLE_SHEETS_PLUGIN = 'ui_views.data.google_sheets_plugin.read';
export const RBAC_UI_VIEWS_DATA_DATA_WAREHOUSE = 'ui_views.data.data_warehouse_sync.read';
export const RBAC_UI_VIEWS_ATTRIBUTION_CROSS_CHANNEL_REPORT = 'ui_views.attribution.cross_channel_report.read';
export const RBAC_UI_VIEWS_ATTRIBUTION_PLATFORM_PERFORMANCE = 'ui_views.attribution.platform_performance.read';
export const RBAC_UI_VIEWS_ATTRIBUTION_RB_PERFORMANCE = 'ui_views.attribution.rockerbox_performance.read';
export const RBAC_UI_VIEWS_JOURNEYS_MARKETING_PATHS = 'ui_views.journeys.marketing_paths.read';
export const RBAC_UI_VIEWS_JOURNEYS_FUNNEL_POSITION = 'ui_views.journeys.funnel_position.read';
export const RBAC_UI_VIEWS_JOURNEYS_CHANNEL_OVERLAP = 'ui_views.journeys.channel_overlap.read';
export const RBAC_UI_VIEWS_MMM_MARKETING_MIX_MODELING = 'ui_views.mmm.marketing_mix_modeling.read';
export const RBAC_UI_VIEWS_BENCHMARKS_SPEND_TRENDS = 'ui_views.benchmarks.spend_trends.read';
export const RBAC_UI_VIEWS_BENCHMARKS_PERFORMANCE_BENCHMARKS = 'ui_views.benchmarks.performance_benchmarks.read';

// INTEGRATIONS
export const RBAC_INTEGRATIONS_PLATFORM_ADWORDS = 'integrations.platform.adwords';
export const RBAC_INTEGRATIONS_PLATFORM_BING = 'integrations.platform.bing';
export const RBAC_INTEGRATIONS_PLATFORM_FACEBOOK = 'integrations.platform.facebook';
export const RBAC_INTEGRATIONS_PLATFORM_LINKEDIN = 'integrations.platform.linkedin';
export const RBAC_INTEGRATIONS_PLATFORM_PINTEREST = 'integrations.platform.pinterest';
export const RBAC_INTEGRATIONS_PLATFORM_SNAPCHAT = 'integrations.platform.snapchat';
export const RBAC_INTEGRATIONS_PLATFORM_TIKTOK = 'integrations.platform.tiktok';

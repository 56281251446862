import moment from 'moment';
import { colorHash } from '../../utils/colorHash';
import { isJsonString } from '../../utils/valueFormatter';

// Build underlying datasets
export const generateDateRange = (startDate, endDate) => {
  const dateArray = [];
  const stopDate = moment(endDate);
  let currentDate = moment(startDate);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
};

export const generateColorMap = (tier1Summary, tierColors) => {
  // eslint-disable-next-line no-shadow
  const tier1Unique = [...new Set(tier1Summary.map(x => Object.keys(x).filter(x => x !== 'name')).flat())];
  const colors = {};
  // eslint-disable-next-line no-return-assign
  tier1Unique.forEach(tier1 => (tierColors && tierColors[tier1] !== undefined ? colors[tier1] = tierColors[tier1] : colors[tier1] = colorHash(tier1)));
  return colors;
};

// Customer Types
const customerIndex = { all: 0, new: 1, repeat: 2 };
export const getSummaryByCustomer = (customerType, channelSummary) => channelSummary.map(group => {
  const groupObj = { name: group.name };
  Object.entries(group)
    // eslint-disable-next-line no-return-assign
    .filter(x => x[0] !== 'name' && x[0] !== 'total').forEach(obj => (
      groupObj[[obj[0]]] = obj[1][customerIndex[customerType]]
    ));
  return groupObj;
});

// Filters
export const filters = (positionFilter, checkJSON) => {
  const filter = checkJSON ? isJsonString(positionFilter) : positionFilter;
  return filter && filter.tier_1.length > 0 ? filter : false;
};

export const createFilterObj = (filterObj, checkJSON) => ({
  any: filters(filterObj.any, checkJSON),
  first: filters(filterObj.first, checkJSON),
  mid: filters(filterObj.mid, checkJSON),
  last: filters(filterObj.last, checkJSON),
});

export const arrayMatches = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((element, index) => element === arr2[index]);
};

import { getCurrentRoas } from '../../../forecast/forecastData';

export const calcTotalSpendFromFieldValues = (fieldValues, field = 'value', defaultValue = 0) => {
  const getFieldWithDefault = (obj, key) => obj[key] || defaultValue;
  return Object.values(fieldValues).reduce((p, c) => p + getFieldWithDefault(c, field), 0);
};
/**
 * Creates starting field values from current field values.
 *
 * @param {Object} fieldValues - The current field values.
 * @param {Object} constraints - The constraints for each field.
 * @returns {Object} - The starting field values.
 */

export const startingFieldValuesFromCurrent = (fieldValues, constraints) => Object.entries(fieldValues).reduce(
  (p, [k, v]) => Object.assign(p, {
    [k]: {
      ...v,
      selection: 'recommended',
      fixed: constraints[k].min_value === constraints[k].max_value,
      starting: v.current || 0,
      recommended: v.current || 0,
      value: v.current || 0,
    },
  }),
  {},
);
/**
 * Converts field values to starting positions.
 *
 * @param {Object} fieldValues - The field values object.
 * @param {Object} constraints - The constraints object.
 * @param {string} [fieldName='min_value'] - The field name.
 * @returns {Object} - The starting positions object.
 */

export const fieldValuesToStartingPosition = (fieldValues, constraints, fieldName = 'min_value') => Object.entries(fieldValues).reduce((p, [k, v]) => Object.assign(p, {
  [k]: {
    ...v,
    selection: 'recommended',
    fixed: constraints[k].min_value === constraints[k].max_value,
    starting: parseInt(constraints[k][fieldName] / 50) * 50,
    recommended: parseInt(constraints[k][fieldName] / 50) * 50,
    value: parseInt(constraints[k][fieldName] / 50) * 50,
  },
}), {});
/**
 * Evaluates the channel constraints for optimization.
 *
 * @param {string} channel - The channel to evaluate.
 * @param {Object} current - The current values for optimization.
 * @param {Object} constraints - The constraints for the channel.
 * @returns {boolean} - Returns true if the channel constraints are met, otherwise false.
 */

export const evalChannelConstraints = (channel, current, constraints, direction) => {
  const { min_value, max_value, min_roas } = constraints[channel];
  const { recommended, currentROAS } = current;
  const fixed = min_value === max_value;

  // perform min/max constraints analysis
  const isIncrease = direction === 'increase';

  const reccLessThanMin = recommended <= min_value;
  const reccGreaterThanMin = recommended > min_value;
  const reccGreaterThanMax = recommended >= max_value;
  const reccLessThanMax = recommended < max_value;

  const satisfiesMin = (isIncrease && reccLessThanMin) || reccGreaterThanMin;
  const satisfiesMax = (!isIncrease && reccGreaterThanMax) || reccLessThanMax;

  // if min_roas is not defined, we don't want to filter by it
  const aboveMinRoas = min_roas ? currentROAS >= min_roas : true;

  return !fixed && satisfiesMin && satisfiesMax && aboveMinRoas;
};
/**
 * Finds the channel with the highest ROI (Return on Investment) based on the given field values,
 * constraints, and response curves.
 *
 * @param {Object} fieldValues - The field values object containing channel data.
 * @param {Object} constraints - The constraints object containing channel constraints.
 * @param {Array} responseCurves - The response curves object containing channel response curves.
 * @returns {string|null} - The channel with the highest ROI, or null if no channels meet the constraints.
 */

export const findHighestROIChannel = (fieldValues, constraints, responseCurves) => {
  const channels = Object.entries(fieldValues)
    .map(([k, v]) => [k, Object.assign(v, { currentROAS: getCurrentRoas(responseCurves, k, v.recommended) })])
    .filter(([k, v]) => evalChannelConstraints(k, v, constraints, 'increase'))
    .filter(([, v]) => v.currentROAS > 0)
    .sort((a, b) => b[1].currentROAS - a[1].currentROAS);

  if (channels.length === 0) return null;

  return channels[0][0];
};
/**
 * Finds the channel with the lowest ROI (Return on Investment) based on the given field values, constraints, and response curves.
 *
 * @param {Object} fieldValues - The field values object containing channel data.
 * @param {Object} constraints - The constraints object containing channel constraints.
 * @param {Array} responseCurves - The response curves object containing channel response curves.
 * @returns {string|null} - The channel with the lowest ROI, or null if no channels meet the constraints.
 */

export const findLowestROIChannel = (fieldValues, constraints, responseCurves) => {
  const channels = Object.entries(fieldValues)
    .map(([k, v]) => [k, Object.assign(v, { currentROAS: getCurrentRoas(responseCurves, k, v.recommended) })])
    .filter(([k, v]) => evalChannelConstraints(k, v, constraints, 'decrease'))
    .filter(([, v]) => v.currentROAS > 0)
    .sort((a, b) => a[1].currentROAS - b[1].currentROAS);

  if (channels.length === 0) return null;
  return channels[0][0];
};

import React, { useState, useEffect } from 'react';
import { getPixelValidateData } from '../../utils/api';
import { ContentCard, IndexGridNew } from '@rockerbox/styleguide';

const PixelValidation = ({platform}) => {
  const [ alertMsg, setAlertMsg ] = useState("");
  const [ errors, setErrors ] = useState([]);
  const hasErrors = errors && errors.length > 0;

  useEffect(() => {
    getPixelValidateData(platform)
      .then(data => {
        setAlertMsg(data.alerts.message);
        setErrors(data.alerts.errors);
      })
  }, [])

  const cols = [
    {display: 'Description', key: 'description', as: IndexGridNew.CollapsingCell},
    {display: 'Resolution', key: 'resolution'},
  ];

  const groupBySubplatformType = errors.reduce((p,c) => {
    let { subplatform }  = c;
    return {...p, [subplatform]: [...(p[subplatform] || []), c]};
  }, {})

  const subplatformTypes = Object.keys(groupBySubplatformType);


  return (
    <React.Fragment>
      <ContentCard><h2>{alertMsg}</h2></ContentCard>
      {(hasErrors) && (
        subplatformTypes.map(c => {
          return (
            <ContentCard title={c} hasTable>
              <IndexGridNew data={groupBySubplatformType[c]} cols={cols}/>
            </ContentCard>
          )
        })
      )}
    </React.Fragment>
  )
}

export default PixelValidation;
import React, { useState } from "react";
import { Message, Button } from "semantic-ui-react";
import { ColumnLayout } from "@rockerbox/styleguide";
import DisplayTable from "./DisplayTable";
import EditorWindow from "./EditorWindow";

const Review = (props) => {
  const { customSchema, setCustomSchema, defaultSchema, displayResult } = props;
  const [showDefaultSchema, setShowDefaultSchema] = useState(false);

  const CustomSchemaMsg = (
    <Message
      info
      header="Custom schema is used to modify, validate and fill missing value for output report data."
      content="Custom schema is optional, report type default schema will be used if custom schema is not setup."
    />
  );
  const customSchemaEditor = (
    <React.Fragment>
      {CustomSchemaMsg}
      <EditorWindow
        nane="customSchema"
        debounceChangePeriod={1}
        value={customSchema}
        placeholder="Custom Schema is optional."
        onChange={(obj) => setCustomSchema((prevState, curProps) => obj)}
      />
    </React.Fragment>
  );

  const displayContent =
    Object.keys(displayResult).length > 0 ? (
      <div style={{ width: "100%", overflow: "scroll", marginBottom: "20px" }}>
        <DisplayTable displayData={displayResult} />
      </div>
    ) : (
      <Message
        warning
        content="Result is an empty report, please check inbound schema and transform function in previous steps are setup correctly."
      />
    );

  const DefaultSchema = (
    <React.Fragment>
      <Button onClick={() => setShowDefaultSchema(true)} primary>
        Show Default Schema
      </Button>
      {showDefaultSchema ? (
        <EditorWindow
          nane="defaultSchema"
          debounceChangePeriod={1}
          value={defaultSchema}
          readOnly={true}
        />
      ) : null}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {displayContent}
      <ColumnLayout
        leftWidth={8}
        rightWidth={7}
        leftContent={customSchemaEditor}
        rightContent={DefaultSchema}
      />
    </React.Fragment>
  );
};

export default Review;

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import PagedIndexGrid from '../PagedIndexGrid';

class SortablePagedIndexGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      direction: null,
      data: [],
      sortKeys: [],
    };
  }

  componentDidMount() {
    const { data, cols } = this.props;
    const sortKeys = cols
      .filter(col => col.sortable !== false)
      .map(col => col.key);
    this.setState({ data, sortKeys });
  }

  componentDidUpdate(prevProps) {
    const { data, cols } = this.props;
    if (data !== prevProps.data) {
      const sortKeys = cols
        .filter(col => col.sortable !== false)
        .map(col => col.key);

      if (data.length !== prevProps.data.length) {
        this.setState({ sortKeys, data });
        return;
      }

      const dataChanged = data.some((item, index) => {
        if (item && prevProps.data[index]) {
          return Object.keys(item).some(key => item[key] !== prevProps.data[index][key]);
        }
        return false;
      });

      if (dataChanged) {
        this.setState({ sortKeys, data });
      } else {
        this.setState({ sortKeys });
      }
    }
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction, sortKeys } = this.state;
    if (sortKeys.indexOf(clickedColumn) !== -1) {
      if (column !== clickedColumn) {
        const type = typeof data[0][clickedColumn];

        const sortedArray = type === 'string'
          ? data.sort((a, b) => (a[clickedColumn]
            .toLowerCase()
            .replace(/[^a-zA-Z0-9]+/g, '-')
            < b[clickedColumn].toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-')
            ? -1
            : 1))
          : data.sort();

        this.setState({
          column: clickedColumn,
          data: sortedArray,
          direction: 'ascending',
        });
      } else {
        this.setState({
          data: data.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        });
      }
    }
  };

  render() {
    const { cols, pagination } = this.props;
    const { column, data, direction } = this.state;

    const Headers = cols.map(col => {
      if (col.sortable === false) {
        return (
          <Table.HeaderCell width={col.headerWidth}>
            {col.display}
          </Table.HeaderCell>
        );
      }
      return (
        <Table.HeaderCell
          width={col.headerWidth}
          sorted={column === col.key ? direction : null}
          onClick={this.handleSort(col.key)}
        >
          {col.display}
        </Table.HeaderCell>
      );
    });
    return (
      /* eslint-disable react/jsx-props-no-spreading */
      <PagedIndexGrid
        sortedHeaders={Headers}
        {...this.props}
        data={data}
        sortable
        pagination={pagination}
      />
      /* eslint-enable react/jsx-props-no-spreading */
    );
  }
}

SortablePagedIndexGrid.propTypes = {
  /** @deprecated instead, wrap `IndexGrid` component with [ContentCard](#contentcard) and include the `hasTable` prop
   */
  as: PropTypes.element,
  /** Array of grid row objects */
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  /** Array of column objects */
  cols: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      display: PropTypes.string,
      as: PropTypes.element,
      sortable: PropTypes.bool,
    }),
  ).isRequired,
  /** Optional fallback message if no data is available */
  fallBackMsg: PropTypes.string,
  UTCOffset: PropTypes.number,
  pagination: PropTypes.string,
  /** Controls the location of the page selector on the footer--"left" or "right". Defaults to "right". */
};

export default SortablePagedIndexGrid;

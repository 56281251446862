import React from 'react'
import { Modal, Button } from 'semantic-ui-react'

import RunButton from '../../../components/parts/RunButton'

export const Error = ({ jobId, startIntercomChat }) => (
    <div className='upgrade-error'>
      <Header
        as='h2'
        content='Sorry, we had an issue upgrading your account'
        subheader='Please contact us for further assistance in getting it resolved'
        textAlign='center'
      />
      <Button
        fluid
        primary
        onClick={() => startIntercomChat(jobId)}
        content='Contact Us'
      />
    </div>
)

const CancelOnboardingModal = ({ open, onClose, error, setError, startIntercomChat, jobRunning, setJobRunning }) => {

    return (
        <Modal
            {...{ open, onClose }}
            closeIcon={!jobRunning}
            size='tiny'
            dimmer='inverted'
            className='modal-v3 cancel-onboarding-modal'
        >
        <Modal.Header>
            <div className='title'>
                Exit Onboarding?
            </div>
            </Modal.Header>
            <Modal.Content>
                {error ? <Error {...{ jobId: error, startIntercomChat }}/>
                :
                <>
                    <p>
                        If you exit, you will lose your progress and be downgraded to a Free account. You can upgrade again to Starter later on.
                    </p>
                    <div>
                        <Button
                            fluid
                            inverted
                            content='Nevermind, Back to Onboarding'
                            onClick={() => onClose()}
                            disabled={jobRunning}
                        />
                        <RunButton
                            negative
                            fluid
                            jobTypeKey={`set_advertiser_tier_${new Date().getTime()}`}
                            path="setup/set_advertiser_tier"
                            data={{'tier': 'lite'}}
                            onStart={() => setJobRunning(true)}
                            onSuccess={() => {
                                window.location = '/v3';
                            }}
                            onError={(d) => {
                                setJobRunning(false);
                                setError(d);
                            }}
                            content="Exit & Downgrade to Free"
                        />
                    </div>
                </>
                }
            </Modal.Content>
        </Modal>
  )
}

export default CancelOnboardingModal

import React, { Component } from 'react';
import { Button, Checkbox, Table } from 'semantic-ui-react';
import { ContentCard, IndexGrid } from '@rockerbox/styleguide';
import { Link } from 'react-router-dom';
import { getAccountDataSources, getDataSources, postAccountDataSources } from '../../utils/api';

export const ViewSource = props => (
  <Table.Cell collapsing>
    { props.item.is_active && props.item.path && <Button as={Link} to={props.item.path}>View</Button>}
  </Table.Cell>
)



export const CheckComponent = clickChecked => props => (
  <Table.Cell collapsing>
    <span>
      <Checkbox
        disabled
        toggle
        value={props.item.source_id}
        checked={props.item.is_active}
        onClick={ (e,data) => clickChecked(e, data)}
      />
    </span>
  </Table.Cell>
)

class EventDataSources extends Component {
  state = {
    dataSources: [],
    accountSources: [],
  }
  componentDidMount() {
    getAccountDataSources()
      .then(d => {
        this.setState({accountSources: d.map(x => x.data_source_id) })
      })

    getDataSources()
      .then(d => {
        this.setState({dataSources: d })
      })

  }
  
  clickChecked = (e,data) => {
    const source_id = data.value;
    const obj = {data_source_id: source_id};
    if (source_id == 3) {
      const accountSources = postAccountDataSources(obj)  
        .then(d => {
          this.setState({accountSources: d.map(x => x.data_source_id) })
        })
    }
  }

  render() {

    const { accountSources, dataSources, googleAnalyticsSource } = this.state;
    dataSources.map(source => {
      if (source.name == "visit_events") source.is_active = 1;
      else source.is_active = accountSources.includes(source.id)
      source.source_id = source['id']
    })

    const cols = [
      { display: '', key: 'check', as: CheckComponent(this.clickChecked) },
      { display: "Source Name", key: "display_name" },
      { display: '', key: 'path', as: ViewSource },
    ]

    return (
        <ContentCard
          hasTable
          title="Event Data Sources"
          explainer="Data sources are 'pipes' through which data enters the Rockerbox platform. Before data is available in the platform, the datasource must be enabled."
        >
          <IndexGrid data={dataSources} cols={cols} as={ContentCard.Table} fallbackMsg="No Data Sources"/>
        </ContentCard>

    )
  }
}

export default EventDataSources;

import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Grid, GridColumn, Button } from 'semantic-ui-react';
import { getActiveStripeCoupons, getActiveStripePromoCodes } from '../../api/account';
import InputFormMonthlySpend from './inputFormMonthlySpend';
import InputFormPricing from './inputFormPricing';
import PromoCode from './promoCode';
import { formatDollarValue, applyDiscount } from './helpers';

const PricingInputForm = ({
  estimated, prorated, isEdit, setIsEdit, isEditPromoCode, setIsEditPromoCode, monthlyAdSpend, setMonthlyAdSpend,
  promoCode, setPromoCode, codePending, setCodePending, promoValue, setPromoValue, promoTagline, promoPercent, setPromoPercent, setDiscountType, clientSecretOptions,
  oneHundredPercentOff, setOneHundredPercentOff, pricePending, setPricePending, taxInfo, setTaxInfo, address, setAddress,
}) => {
  const [data, setData] = useState(null);
  const [promoText, setPromoText] = useState(null);
  const [activeCoupons, setActiveCoupons] = useState(null);
  const [promoCodesToIds, setPromoCodesToIds] = useState(null);
  const [oneHundredPercentOffCodes, setOneHundredPercentOffCodes] = useState(null);

  useEffect(() => {
    getActiveStripeCoupons()
      .then(couponResponse => {
        getActiveStripePromoCodes()
          .then(promoCodeResponse => {
            const couponCodes = couponResponse.response.map(({ id }) => id);
            const combinedCodes = couponCodes.concat(promoCodeResponse.response.map(({ code }) => code));
            setActiveCoupons(combinedCodes);
            const idArray = promoCodeResponse.response.map(({ code, id }) => ({ [code]: id }));
            setPromoCodesToIds(Object.assign({}, ...idArray));
            const oneHundredPercentOffObjects = promoCodeResponse.response.filter(el => el.coupon.percent_off === 100);
            const oneHundredPercentOffObjectsPromoCodes = oneHundredPercentOffObjects.map(a => a.code);
            const oneHundredPercentOffCombinedCodes = oneHundredPercentOffObjectsPromoCodes.concat(oneHundredPercentOffObjects.map(a => a.coupon.id));
            setOneHundredPercentOffCodes(oneHundredPercentOffCombinedCodes);
          });
      });
  }, []);

  const monthlyAdSpendFormatted = formatDollarValue(monthlyAdSpend);

  const estimatedPrices = applyDiscount(estimated, promoPercent, promoValue, oneHundredPercentOff);
  const proratedPrices = applyDiscount(prorated, promoPercent, promoValue, oneHundredPercentOff);

  function handleClick() {
    setIsEditPromoCode(true);
  }

  function handleReset() {
    setPricePending(true);
    setPromoCode(null);
    setIsEditPromoCode(false);
    setPromoValue(null);
    setPromoPercent(null);
    setPromoText(null);
    setOneHundredPercentOff(false);
  }

  const onSubmit = () => {
    setPricePending(true);
    setTaxInfo(prevState => ({ ...prevState, recalcTax: true }));
    setIsEdit(false);
    setMonthlyAdSpend(data);
  };

  const onSubmitPromoCode = () => {
    setTaxInfo(prevState => ({ ...prevState, recalcTax: true }));
    const isValidCode = !!activeCoupons.includes(promoText.trim());
    setIsEditPromoCode(false);
    if (isValidCode) {
      if (oneHundredPercentOffCodes.includes(promoText.trim())) {
        setOneHundredPercentOff(true);
      }
      if (promoText in promoCodesToIds) {
        setPromoCode(promoCodesToIds[promoText]);
        setDiscountType('promo_code');
      } else {
        setPromoCode(promoText.trim());
        setDiscountType('coupon_code');
      }
      setCodePending(true);
    } else {
      toast.error('Invalid Code Entered');
      handleReset();
      setPricePending(false);
    }
  };

  const billingText = 'Your billing address:';

  const handleResetAddress = () => {
    setTaxInfo(prevState => ({ ...prevState, readyForPayment: false }));
    setAddress('');
  };

  return (
    <Grid>
      <GridColumn width={14}>
        <div>
          <InputFormMonthlySpend {...{ isEdit, promoCode, codePending, monthlyAdSpendFormatted, setIsEdit, data, setData, onSubmit }} />
          <InputFormPricing {...{ estimatedPrices, proratedPrices, promoCode, codePending, isEdit, pricePending }} />
        </div>
        <div>
          <PromoCode {...{ promoCode, isEditPromoCode, codePending, promoText, setPromoText, onSubmitPromoCode, handleClick, promoTagline, handleReset, clientSecretOptions, isEdit }} />
        </div>
        <div className="billing-address-text">
          {address && clientSecretOptions && taxInfo.readyForPayment
            && (
            <>
              {billingText}
              <br />
              {address.line1}
              {' '}
              {address.line2}
              <br />
              {address.city}
              ,
              {address.state}
              ,
              {address.postal_code}
              <br />
              <Button
                className="edit-address-button"
                type="submit"
                size="large"
                color="purple"
                content="Edit Billing Info"
                onClick={handleResetAddress}
              />
            </>
            )}
        </div>
      </GridColumn>
      <GridColumn width={2} />
    </Grid>
  );
};

export default PricingInputForm;

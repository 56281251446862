import { useEffect, useState } from 'react';
import * as d3 from 'rockerbox_d3_legacy_clone';
import * as aq from 'arquero';
import { colorHash } from '../../../utils/colorHash';
import { getFirstVisitsTiers } from '../../../api/attributionCache';
import { useGlobalState } from '../../../hooks/global';
import { getCache } from '../../../utils/data';
import { RENAME_FIRST_VISIT_TIERS } from '../../RockerboxPerformance/Comparison/constants';

export const useDatasets = (startDate, endDate, setLoading) => {
  const { account, segments, tierColors } = useGlobalState();

  const [tiersDataRaw, setTiersDataRaw] = useState(false);
  const [firstVisitsRaw, setFirstVisitsRaw] = useState([]);
  const [firstVistsSpendJoin, setFirstVistsSpendJoin] = useState([]);
  const [tierColorMap, setTierColorMap] = useState({});

  const badDate = !startDate || !endDate;
  const missingSegments = !segments || (segments.length < 1);
  const hasMissingParameters = (badDate || missingSegments || !account);

  const { filter_id } = segments.length > 0 ? segments.find(x => x.action_name === 'All Pages') : {};

  useEffect(() => {
    if (hasMissingParameters) return;
    const dataset = 'compiled_mta_tiers';
    const hasDataReportingApiFlag = account.features.find(c => c === 'data_reporting_api');
    const { filter_id: id } = segments.find(x => x.featured);
    const getViaCache = hasDataReportingApiFlag
      ? getCache('compiled_mta_tiers', 'data-services-v1')
      : getCache('compiled_mta_tiers', 'legacy');

    getViaCache(startDate, endDate, dataset, id)
      .then(data => {
        setTiersDataRaw(data);
      });
  }, [startDate, endDate, segments]);

  useEffect(() => {
    if (hasMissingParameters || !tierColors) return;
    setLoading(true);
    getFirstVisitsTiers(filter_id, startDate, endDate)
      .then(data => {
        const visits = data.first_visits_tiers.map(visit => {
          // eslint-disable-next-line no-param-reassign
          visit.num_new_visitors_percent_col = visit.num_new_visitors;
          return visit;
        }) || [];
        const colors = d3.nest()
          .key(g => g.tier_1)
          .entries(visits)
          .reduce((accu, c) => {
            accu[c.key] = tierColors[c.key] || colorHash(c.key);
            return accu;
          }, {});

        setFirstVisitsRaw(visits);
        setTierColorMap(colors);
      });
  }, [startDate, endDate, segments, tierColors]);

  useEffect(() => {
    if (!tiersDataRaw || !firstVisitsRaw.length) return;
    const PLATFORM_TO_TIERS = [
      ['spend_key', 'date', 'platform'],
      ['platform_join_key', 'date', 'platform'],
    ];

    const arrowFirstVisits = aq.from(firstVisitsRaw);

    const derive_tiers = {
      tier_1_first_visits: 'd => d.tier_1_first_visits === undefined ? d.tier_1_spend_mta : d.tier_1_first_visits',
      tier_2_first_visits: 'd => d.tier_2_first_visits === undefined ? d.tier_2_spend_mta : d.tier_2_first_visits',
      tier_3_first_visits: 'd => d.tier_3_first_visits === undefined ? d.tier_3_spend_mta : d.tier_3_first_visits',
      tier_4_first_visits: 'd => d.tier_4_first_visits === undefined ? d.tier_4_spend_mta : d.tier_4_first_visits',
      tier_5_first_visits: 'd => d.tier_5_first_visits === undefined ? d.tier_5_spend_mta : d.tier_5_first_visits',
    };

    const impute = {
      num_bounces: () => 0,
      num_new_visitors: () => 0,
      num_new_visitors_percent_col: () => 0,
      num_pageviews: () => 0,
      num_pageviews_mean: () => 0,
      num_pageviews_median: () => 0,
      num_sessions: () => 0,
      num_sessions_mean: () => 0,
      num_sessions_median: () => 0,
    };

    const joined = arrowFirstVisits
      .join_full(tiersDataRaw, PLATFORM_TO_TIERS, 0, { suffix: ['_first_visits', '_spend_mta'] })
      .derive(derive_tiers)
      .rename(RENAME_FIRST_VISIT_TIERS)
      .impute(impute)
      .rename({ spend_spend_mta: 'spend' })
      .filter(d => !(d.spend === 0 && d.num_new_visitors === 0))
      .objects();

    setFirstVistsSpendJoin(joined);
  }, [tiersDataRaw, firstVisitsRaw]);

  return {
    firstVistsSpendJoin,
    tierColorMap,
  };
};

import * as d3 from 'rockerbox_d3_legacy_clone';

import { generateDateRange } from '../../../utils/time';

export const getSummaryData = (uploadData, finalColumnName) => {
  /**
   * Calculate summary statistics from upload data.
   *
   * @param {Array<Object>} uploadData - An array of data objects to summarize.
   * @param {string} finalColumnName - The name of the column to compute statistics on.
   * @returns {Object} An object containing summary statistics including total, average, minimum, maximum, minDate, and maxDate.
   */
  if (!uploadData || uploadData.length < 1) return {};
  const total = d3.sum(uploadData, d => d[finalColumnName]);
  const average = d3.mean(uploadData, d => d[finalColumnName]);
  const minimum = d3.min(uploadData, d => d[finalColumnName]);
  const maximum = d3.max(uploadData, d => d[finalColumnName]);
  const minDate = d3.min(uploadData, d => d.date);
  const maxDate = d3.max(uploadData, d => d.date);

  return { total, average, minimum, maximum, minDate, maxDate };
};

export const generateBlankDateData = (startDate, endDate, fields) => {
  /**
   * Generate blank date-based data objects within a specified date range.
   *
   * @param {string} startDate - The start date of the date range.
   * @param {string} endDate - The end date of the date range.
   * @param {Array<string>} fields - An array of field names to initialize with zero values.
   * @returns {Object} An object with date keys, each containing a data object with initialized field values.
   */
  const blankObject = {};
  fields.forEach(f => {
    blankObject[f] = 0;
  });
  const blankDates = generateDateRange(startDate, endDate)
    .map(date => ({ date, ...blankObject }));

  const dateObj = {};
  blankDates.forEach(d => { dateObj[d.date] = d; });

  return dateObj;
};

export const validate = (errors, setErrors, config) => (value, column) => {
  /**
   * Validate a value using a custom validation function and handle errors.
   *
   * @param {Array<Error>} errors - An array to store validation errors.
   * @param {function} setErrors - A function to set the errors array.
   * @param {Object} config - A configuration object containing validation functions for specific columns.
   * @returns {function} A validation function that can be used to validate values for a specific column.
   */
  if (!Object.prototype.hasOwnProperty.call(config, column)) return value;
  const f = config[column].validateFxn;
  try {
    const x = f(value);
    return x;
  } catch (e) {
    errors.push(e);
    setErrors(errors);
    return value;
  }
};

export const computeChartData = (uploadData, finalColumnName, minDate, maxDate) => {
  /**
   * Compute chart data based on upload data, final column name, min date, and max date.
   *
   * This function generates chart data by aggregating values from upload data for a specific column
   * within the date range defined by minDate and maxDate.
   *
   * @param {Array<Object>} uploadData - An array of data entries.
   * @param {string} finalColumnName - The name of the final column for aggregation.
   * @param {string} minDate - The minimum date of the date range.
   * @param {string} maxDate - The maximum date of the date range.
   * @returns {Array<Object>} An array of chart data objects with date and aggregated values.
   *
   * @example
   * // Usage:
   * const uploadData = [
   *   { date: '2023-01-01', value: 100 },
   *   { date: '2023-01-02', value: 150 },
   *   { date: '2023-01-03', value: 200 },
   * ];
   * const minDate = '2023-01-01';
   * const maxDate = '2023-01-03';
   * const finalColumnName = 'value';
   *
   * const chartData = computeChartData(uploadData, finalColumnName, minDate, maxDate);
   * // Result:
   * // [
   * //   { date: '2023-01-01', value: 100 },
   * //   { date: '2023-01-02', value: 150 },
   * //   { date: '2023-01-03', value: 200 },
   * // ]
   */
  if (!minDate || !maxDate) return [];
  const dataByDate = generateBlankDateData(minDate, maxDate, [finalColumnName]);
  uploadData.forEach(x => {
    dataByDate[x.date][finalColumnName] += x[finalColumnName];
  });

  return Object.keys(dataByDate).map(x => ({
    date: x,
    [finalColumnName]: dataByDate[x][finalColumnName],
  }));
};

export const getFinalColumnName = (channel, strategy, uploadType) => {
  /**
   * Get the final column name based on channel, strategy, and upload type.
   *
   * This function generates the final column name based on the provided channel, strategy,
   * and upload type by appending a suffix specific to each upload type.
   *
   * @param {string} channel - The channel name.
   * @param {string|null} strategy - The strategy name (optional, can be null).
   * @param {string} uploadType - The type of upload (revenue, spend, imps, extra).
   * @returns {string|undefined} The final column name, or undefined if channel is not provided.
   *
   * @throws {Error} If the upload type is invalid.
   *
   * @example
   * // Usage:
   * const channel = 'Channel A';
   * const strategy = 'Strategy 1';
   * const uploadType = 'revenue';
   *
   * const finalColumnName = getFinalColumnName(channel, strategy, uploadType);
   * // Result:
   * // "ChannelA_Strategy1_R" for revenue upload type
   */
  if (!channel) return;
  let suffix = '';
  switch (uploadType) {
    case 'revenue':
      suffix = 'R';
      break;
    case 'spend':
      suffix = 'S';
      break;
    case 'imps':
      suffix = 'IMP';
      break;
    case 'extra':
      suffix = 'EF';
      break;
    default:
      throw Error('Invalid upload type');
  }
  return `${channel.replace(/ /g, '')}${strategy ? `_${strategy.replace(/ /g, '')}` : ''}_${suffix}`;
};

import React from "react";
import { Dropdown } from "semantic-ui-react";
import { optionConfig } from "./DeliveryOptionIndex";

export const DropdownGroup = props => {
  return (
    <Dropdown text="New Delivery Option" button className="primary">
      <Dropdown.Menu>
        <DropdownItem onItemClick={props.showModal} type="amazon_s3" />
        <DropdownItem onItemClick={props.showModal} type="sftp" />
        <DropdownItem
          onItemClick={props.showModal}
          type="googlecloud_storage"
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

const DropdownItem = props => {
  const { type } = props;
  const handleClick = () => {
    props.onItemClick(type);
  };
  return (
    <Dropdown.Item text={optionConfig[type]["name"]} onClick={handleClick} />
  );
};

const nameMap = {
  do_spaces: "Email",
  amazon_s3: "S3",
  sftp: "SFTP",
  ftp: "FTP",
  googlecloud_storage: "Google Cloud Storage"
};

const MenuList = props => {
  const { outbox_types, onItemClick } = props;
  return outbox_types.map(type => (
    <Dropdown.Item
      text={nameMap[type]}
      key={type}
      onClick={onItemClick(type)}
    ></Dropdown.Item>
  ));
};

export const NewReportDropdownGroup = props => {
  const { onItemClick, outbox_types } = props;
  return (
    <Dropdown
      text="Schedule New Report"
      button
      className="primary"
      disabled={!outbox_types || outbox_types.length === 0}
    >
      <Dropdown.Menu>
        <MenuList {...props} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

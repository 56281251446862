import React, { Component } from 'react';
import { ScrollingPopup, ContentCard } from '@rockerbox/styleguide'
import { Input, Icon, Button, Popup } from 'semantic-ui-react'
import { getBucketsExcludedRuleTree } from '../../utils/api';
import * as Tree from '../TreeParts';

const defaultMatcher = (filterText, node) => {
  const urlMatches = node.urls && node.urls.filter(url => url.toLowerCase().indexOf(filterText.toLowerCase()) !== -1).length;
  const referrerMatches = node.referrers && node.referrers.filter(referrer => referrer.toLowerCase().indexOf(filterText.toLowerCase()) !== -1).length;
  return (urlMatches||0) + (referrerMatches||0)
};

const findNode = (node, filter, matcher) => {
  return matcher(filter, node) || // i match
    (node.values && // or i have decendents and one of them match
    node.values.length &&
    !!node.values.find(child => findNode(child, filter, matcher)));
};

const filterTree = (node, filter, matcher = defaultMatcher) => {
  // If im an exact match then all my values get to stay
  if(matcher(filter, node) || !node.values){ return node; }
  // If not then only keep the ones that match or have matching descendants
  const filtered = node.values
    .filter(child => findNode(child, filter, matcher))
    .map(child => filterTree(child, filter, matcher));
  return Object.assign({}, node, { values: filtered });
};

export default class ExcludeEventsTree extends Component {

  state = {
    loading: false,
    ruleTreeData: [],
    filteredRuleTreeData: [],
    searchValue: "",
    organizeBy: "tier"
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {

    const check = ["id", "date"]

    if(check.filter(k => prevProps[k] != this.props[k]).length > 0) {
      this.getData();
    }
  }

  getData = () => {
    const { date } = this.props;
    this.setState({ loading: true })

    getBucketsExcludedRuleTree(this.props.id, date)
      .then( ruleTreeData  => {
        const filteredRuleTreeData = ruleTreeData;
        this.setState({ ruleTreeData, filteredRuleTreeData, loading: false  });
      });
  }

  handleClick = (e, { name }) => this.setState({ organizeBy: name })

  filterData = (evt, objdata) => {
    const { ruleTreeData } = this.state;
    const { value } = objdata;
    const searchValue = value;
    if (value == '') {
      return this.setState({ filteredRuleTreeData: ruleTreeData, searchValue })
    }

    const r = filterTree({"values":ruleTreeData}, searchValue)

    return this.setState({
      filteredRuleTreeData: r.values || [],
      searchValue
    })
  }

  buildRow = (row, tiers, pos=0, forceOpen=false) => {

    const RefPopup = row['referrers'].length > 11 ? ScrollingPopup : Popup
    const UrlPopup = row['urls'].length > 11 ? ScrollingPopup : Popup

    const RowComponent = (
      <Tree.Row style={{padding:"12px",borderBottom:"1px solid #ddd"}}>
        <Tree.Column flex={4}> { row[tiers[pos]] == "" ? "<blank>" : row[tiers[pos]] } </Tree.Column>
        <Tree.Column style={{textAlign:"left"}}> { row['count'] } </Tree.Column>
        <Tree.Column style={{textAlign:"left"}}> { row['values'] ? row.values.length : "-" } </Tree.Column>
        <Tree.Column style={{textAlign:"left"}}> { row['buckets'] ? row.buckets : "-" } </Tree.Column>
        <Tree.Column style={{textAlign:"left"}}>
          { row['urls'].length ?
              <UrlPopup
                hoverable
                header="Urls"
                position="left center"
                trigger={ <div><Icon name="eye" /> { row['num_urls'] }</div> }
                content={row['urls'].map(v => <div style={{maxWidth:"500px",wordWrap:"break-word"}}>{v}</div>)}
              /> :
              row['num_urls']
          }
        </Tree.Column>
        <Tree.Column style={{textAlign:"left"}}>
          { row['referrers'].length ?
              <RefPopup
                hoverable
                header="Referrers"
                position="left center"
                trigger={<div><Icon name="eye" /> { row['num_referrers'] }</div>}
                content={row['referrers'].map(v => <div style={{maxWidth:"500px",wordWrap:"break-word"}}>{v}</div>)}
              /> :
              row['num_referrers']
          }
        </Tree.Column>
        <Tree.Column style={{textAlign:"left"}}>
          {
            row['matches'].length == 1 ?
              <Button size='mini' compact circular style={{overflow:"hidden",textOverflow: "ellipsis", whiteSpace: "nowrap", width:"120px"}}>{ row['matches'][0] }</Button> :
              <Popup
                hoverable
                header="Rules"
                position="left center"
                trigger={<div><Icon name="eye" /> { row['matches'].length }</div>}
                content={row['matches'].map(v => <div style={{maxWidth:"500px",wordWrap:"break-word"}}>{v}</div>)}
              />
          }
        </Tree.Column>
        <Tree.Column>
        </Tree.Column>
      </Tree.Row>
    )

    if (row.buckets == 1 ) return RowComponent
    return (
      <Tree.Collapsible title={RowComponent} open={forceOpen} style={{}} useBorder>
        {
          (tiers.length-1 == pos) ?
            (<pre> { JSON.stringify(row.values,0,2) }</pre>) :
            row.values && row.values.sort((p,c) => c.count - p.count).map(_row => this.buildRow(_row,tiers,pos+1, forceOpen))
        }
      </Tree.Collapsible>
    )
  }

  render() {

    const { loading, filteredRuleTreeData, searchValue, organizeBy } = this.state;
    const forceLoading = loading;
    const { date } = this.props;
    const title = "Excluded Events";

    const TIERS = ["match", "tier_1","tier_2","tier_3","tier_4","tier_5"]

    const classification = "Rule";
    const tiers = TIERS
    const data = filteredRuleTreeData;

    return (
      <React.Fragment>
        <Tree.Row>
          <Tree.Column>
            <Input icon='search'  type="text" placeholder="Find an event" value={searchValue} onChange={this.filterData} />
          </Tree.Column>
          <Tree.Column flex={10}>
          </Tree.Column>
        </Tree.Row>
        <ContentCard title={`${title} ${date}`} hasTable {...{forceLoading}} >
          <Tree.Wrapper style={{marginBottom:"10px"}}>
            <Tree.Row style={{padding:"12px",borderBottom:"1px solid #ddd",background:"#F9FAFB",fontWeight:"bold"}}>
              <Tree.Column flex={4}> { classification } </Tree.Column>
              <Tree.Column> Matching Events </Tree.Column>
              <Tree.Column> Next Level </Tree.Column>
              <Tree.Column> All Levels </Tree.Column>
              <Tree.Column> Urls </Tree.Column>
              <Tree.Column> Referrers </Tree.Column>
              <Tree.Column> Rule </Tree.Column>
              <Tree.Column> </Tree.Column>
            </Tree.Row>
            {
              data.sort((p,c) => c.count - p.count).map(row => {
                return this.buildRow(row, tiers, 0, searchValue != "")
              })
            }
          </Tree.Wrapper>
        </ContentCard>
      </React.Fragment>
    )

  }
}

import { useMemoWithLog, s, applyModifiersArquero } from './helpers';
import { TIERS, DERIVED_TIERS, TIERS_LIMITED } from '../../../constants/tiers';
import { getStats, getTotals, filterTiers, filterSearch } from '../arqueroHelpers';
import { getDailyTiersSummary, getChartData } from '../helpers';

const filterZeroRevenueSpendConv = (table, conversionKey) => {
  const hasSpend = table.columnNames().includes('spend');
  const filteredTable = hasSpend ? table.filter(`d => !(d.spend === 0 && d['${conversionKey}'] === 0)`) : table.filter(`d => !(d['${conversionKey}'] === 0)`);
  return filteredTable;
};

export const useFilteredDataArq = (
  tiersDataRaw,
  selectedRedistribution,
  search,
  derivedTiersFilter,
  filters,
  conversionKey,
  revenueKey,
  startDate,
  endDate,
  metricColumn,
  attributionMethod,
  groupDatesBy,
  limitedTiers = false,
) => {
  const TIERS_RANGE = limitedTiers ? TIERS_LIMITED : TIERS;
  const { tier_1, tier_2, tier_3, tier_4, tier_5 } = filters;

  const numRowsExceedThreshold = tiersDataRaw && tiersDataRaw._nrows > 500000;
  const maxTiersGroupby = numRowsExceedThreshold ? limitedTiers ? TIERS.slice(0, 2) : TIERS.slice(0, 4) : TIERS_RANGE;
  const maxGroupby = ['date', ...maxTiersGroupby, ...DERIVED_TIERS];

  const tiersDataReduced = useMemoWithLog(() => {
    if (!tiersDataRaw) return false;
    const zerosFiltered = filterZeroRevenueSpendConv(tiersDataRaw, conversionKey);
    return getStats(maxGroupby, zerosFiltered, conversionKey, revenueKey, startDate, endDate, true);
  }, [tiersDataRaw, search, conversionKey]);

  const tiersData = useMemoWithLog(() => (
    !!tiersDataReduced ? filterSearch(tiersDataReduced, search, derivedTiersFilter, limitedTiers) : false
  ), [tiersDataReduced, search, derivedTiersFilter]);

  const tiersSummaryRaw = useMemoWithLog(() => {
    if (!tiersData) return false;
    return getStats(maxGroupby.slice(1), tiersData, conversionKey, revenueKey, startDate, endDate, true);
  }, [tiersData, conversionKey, revenueKey, startDate, endDate]);

  const tiersSummaryRawTotals = useMemoWithLog(() => (
    tiersSummaryRaw ? getTotals(tiersSummaryRaw) : false
  ), [tiersSummaryRaw]);

  const filteredTiers = useMemoWithLog(() => (
    !!tiersData ? filterTiers(tiersData, filters) : false
  ), [tiersData, s(tier_1), s(tier_2), s(tier_3), s(tier_4), s(tier_5)]);

  const filteredSummary = useMemoWithLog(() => (
    filteredTiers ? getStats(maxGroupby.slice(1), filteredTiers, conversionKey, revenueKey, startDate, endDate, true) : false
  ), [filteredTiers, conversionKey, revenueKey, startDate, endDate]);

  const filteredSummaryTotals = useMemoWithLog(() => (
    filteredSummary ? getTotals(filteredSummary) : false
  ), [filteredSummary]);

  const dailyTiersSummary = useMemoWithLog(() => {
    if (!filteredTiers) return false;
    if (!selectedRedistribution.weights) {
      return getStats(['date', 'tier_1'], filteredTiers, conversionKey, revenueKey, startDate, endDate);
    }

    const { weights } = selectedRedistribution;
    const modifiedFilterTiers = applyModifiersArquero(filteredTiers, weights, conversionKey, revenueKey, undefined, true);

    return getStats(['date', 'tier_1'], modifiedFilterTiers, conversionKey, revenueKey, startDate, endDate);
  }, [filteredTiers, selectedRedistribution, conversionKey, revenueKey, startDate, endDate]);

  const dailySummary = useMemoWithLog(() => (
    filteredTiers ? getStats(['date'], filteredTiers, conversionKey, revenueKey, startDate, endDate) : false
  ), [filteredTiers, conversionKey, revenueKey, startDate, endDate]);

  // use this for the modal
  const tierOneSummaryRaw = useMemoWithLog(() => {
    const statsWithoutBlankDates = getStats(['tier_1'], filteredTiers, conversionKey, revenueKey, startDate, endDate).filter(c => c.tier_1 && c.tier_1 !== 'Unmapped Events');
    return filteredTiers ? statsWithoutBlankDates : false;
  }, [filteredTiers, conversionKey, revenueKey, startDate, endDate]);

  const tiersSummaryTable = useMemoWithLog(() => {
    if (!!tiersSummaryRaw) {
      const filtered = filterTiers(tiersSummaryRaw, filters);
      return filtered;
    }
    return [];
  }, [tiersSummaryRaw, s(tier_1), s(tier_2), s(tier_3), s(tier_4), s(tier_5)]);

  const tiersSummary = useMemoWithLog(() => {
    if (!tiersSummaryTable || tiersSummaryTable.length === 0) return [];
    if (!selectedRedistribution.weights) return tiersSummaryTable.objects();

    const { weights } = selectedRedistribution;
    const modified = applyModifiersArquero(tiersSummaryTable, weights, conversionKey, revenueKey, undefined, true);

    return modified.objects();
  }, [tiersSummaryTable, selectedRedistribution, conversionKey]);

  const chartData = useMemoWithLog(() => (
    getChartData(dailyTiersSummary, metricColumn, revenueKey, conversionKey, startDate, endDate, groupDatesBy)
  ), [dailyTiersSummary, metricColumn, revenueKey, conversionKey, startDate, endDate, groupDatesBy]);

  const lineChartData = chartData;

  // base case
  if (!tiersData || tiersData.length === 0) {
    return {
      loading: true,
      filteredTiersData: [],
      dailyTiersSummary: getDailyTiersSummary([], conversionKey, revenueKey, startDate, endDate),
      dailySummary: getDailyTiersSummary([], conversionKey, revenueKey, startDate, endDate),
      tiersSummary: [],
      chartData: [],
      lineChartData: [],
      tiersDataReduced: [],
      tiersSummaryRaw: [],
      tiersSummaryRawTotals: {},
      filteredSummaryTotals: {},
      tierOneSummaryRaw: [],
      numRowsExceedThreshold: false,
    };
  }
  return {
    loading: false,
    filteredTiersData: filteredTiers.objects(),
    dailyTiersSummary,
    dailySummary,
    tiersSummary,
    chartData,
    lineChartData,
    tiersDataReduced,
    tiersSummaryRaw: tiersSummaryRaw.objects(),
    tiersSummaryRawTotals: tiersSummaryRawTotals.objects()[0],
    filteredSummaryTotals: filteredSummaryTotals.objects()[0],
    tierOneSummaryRaw,
    numRowsExceedThreshold,
  };
};

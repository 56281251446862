import React, {} from 'react';
import { upAndToTheRight } from '@rockerbox/styleguide';
import { Button } from 'semantic-ui-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ArrowCircleRightIcon } from '@heroicons/react/solid';

const CallToAction = ({ platform }) => {
  const rockerboxPerformance = `/v3/attribution/rockerbox_performance/comparison/${platform}`;
  const advertisingPlatforms = '/v3/data/marketing/integrations/main';
  const buttonText = 'Connect Your Platforms';

  return (
    <div style={{ backgroundColor: '#fbfbfe', padding: '15px', height: '572px', border: 'solid 1px #efeffb', borderRadius: '8px' }}>
      <div style={{ textAlign: 'center', width: '90%', paddingLeft: '10px', paddingTop: '5px' }}>
        <h3 style={{ textAlign: 'left', marginBottom: '20px' }}>How Do You Compare?</h3>
        <img id="homepage-teaser" src={upAndToTheRight} alt="up-and-to-the-right" style={{ width: '95%' }} />
        <div style={{ fontSize: '16.5px', textAlign: 'left', marginTop: '15px' }}>
          <p>Knowing the benchmarks for your industry is key, but it means nothing unless you&apos;re actively tracking your own performance against a goal.</p>
          <p>
            Connect your ad platforms to Rockerbox to see your current CPA or ROAS in our
            <a href={rockerboxPerformance} style={{ textDecoration: 'underline', marginLeft: '4px' }}>Rockerbox Performance report</a>
            , then set goals to track your performance against industry benchmarks.
          </p>
          <Button primary style={{ display: 'flex', alignItems: 'center', width: '45%', fontSize: '1.02rem' }} href={advertisingPlatforms}>
            {' '}
            {buttonText}
            {' '}
            <ArrowCircleRightIcon style={{ display: 'inline-block', height: '25px', marginLeft: '5px' }} />
            {' '}
          </Button>
        </div>
      </div>

    </div>
  );
};
export default CallToAction;

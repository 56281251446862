import React from 'react';
import { useUrlStateWithDefaults } from './hooks/urlParamHooks'; // cleanup for comparison only, cleanup original file so it's used only for platform performance
import useAppSettings from '../../hooks/useAppSettings';
import { ViewAccessWrapper } from '../../components';

import GatedByPlatformSelection from './GatedByPlatformSelection';

const GatedByGlobalSetup = () => {
  const settings = useAppSettings();
  const { defaultModel } = settings;

  const urlState = useUrlStateWithDefaults({ defaultView: 'comparison', defaultModel });

  return (
    <GatedByPlatformSelection
      {...{ urlState }}
    />
  );
};

const RockerboxPerformanceAccess = () => (
  <ViewAccessWrapper
    viewName="rockerbox_performance"
    viewComponent={<GatedByGlobalSetup />}
  />
);

export default RockerboxPerformanceAccess;

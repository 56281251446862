import React from 'react';
import { CacheContext } from '../../utils/CacheContext.js'
import { getAllIntegrations, getIntegrationFeatures, addPlatformFeature, getAuths, getAccount, getArtifacts, getVendorNofications, getAccountNotifications } from '../../utils/api';
import { ColumnLayout, ContentCard } from '@rockerbox/styleguide';
import { Dropdown, Menu, Icon, Button } from 'semantic-ui-react';
import { _pushPath, customHistory } from '../pushpath';
import _ from 'lodash';

import PixelValidation from './PixelValidation';
import TransformTableDetails from './TransformTableDetails';
import Comparison from './Comparison';
import AdsManagementDetails from './AdsManagementDetails';
import GenericAutoParams from './Setups/GenericAutoParams';
import GoogleAdsReportingDetails from './GoogleAdsReportingDetails';
import ShopifySetup from '../Shopify';
import Facebook from '../Facebook/Comparison';
import Pinterest from '../Pinterest';
import PostlogIntegration from './PostlogIntegration';
import DirectMailIntegration from './DirectMailIntegration';
import Authorizations from './Authorizations';
import Help from './Help';
import Setups from './Setups';
import IntegrationGallery from './IntegrationGallery';
import { IntegrationDisplayName, IntegrationMenu, EmptyDetails, EmptyView } from './parts';
import { VendorNotificationWarning } from './VendorNotificationWarning';

const SHOPIFY_ALLOWED_INTEGRATIONS = ["shopify", "adwords", "facebook", "pinterest", "snapchat", "tiktok", "bing", "linkedin", "adroll", "criteo", "twitter"]

const HELP_DOC_DEFAULT = [{"key": "Setup Instructions","values": [{"feature_type":"help_doc"}]}];
const ACCOUNT_DEFAULT = [{"key": "Accounts","values": [{"feature_type":"accounts"}]}];
const WEBHOOK_DEFAULT = [{"key": "Webhooks","values": [{"feature_type":"webhook_setup"}]}];
const PIXEL_BATCH_SPEND_DEFAULT = [
  {"key": "Pixel Setup","values": [{"feature_type":"pixel_setup"}]},
  {"key": "Data Setup","values": [{"feature_type":"inbox_setup"}]}
];
const BATCH_SPEND_DEFAULT = [
  {"key": "Data Setup","values": [{"feature_type":"inbox_setup"}]}
];
const DIRECT_MAIL_DEFAULT = [
  {"key": "Data Setup", "values": [{"feature_type": "inbox_setup"}]}
]
const DEFAULT_MEMO = [{}, [], [], {}]

const DEFAULTS = { platformTypes: [], platformFeatures: false, integrations: [] }

const IntegrationsIndex = (props) => {

  const limitPlatforms = props.limitPlatforms || []
  const galleryHideHeader = props.galleryHideHeader || false
  const galleryHideCategoryHeaders = props.galleryHideCategoryHeaders || false
  const displayOnlyGallery = props.displayOnlyGallery || false
  const galleryNumColumns = props.galleryNumColumns || false
  const galleryCentered = props.galleryCentered || false
  const galleryShowAuthedAccounts = props.galleryShowAuthedAccounts || false

  const [chosenFeature, setChosenFeature] = React.useState(false);
  const [featureLoading, setFeatureLoading] = React.useState(false);
  const [deprecationAlert, setDeprecationAlert] = React.useState(0);
  const [showDeprecationAlert, setShowDeprecationAlert] = React.useState(false)
  const { platform, feature } = props.match.params;
  const history = customHistory(props);
  const { updatePath } = history;

  const [state, setState, Context] = React.useContext(CacheContext);
  const allIntegrations = (Context.getCache(`integrations`, getAllIntegrations) || []) // NOT USED HERE. caching
  const authorizations = (Context.getCache(`authorizations`, getAuths) || []) // NOT USED HERE. caching
  const vendorNotifications = (Context.getCache(`vendorNotifications`, getVendorNofications) || []) // NOT USED HERE. caching
  const notifications = (Context.getCache(`notifications`, getAccountNotifications) || []) // NOT USED HERE. caching
  const account = (Context.getCache(`account`, getAccount) || {})

  const { platformTypes, platformFeatures, integrations } = (Context.getCache(`integrationFeatures`, getIntegrationFeatures) || DEFAULTS)
  const { integrations_categories } = Context.getCache(`artifacts`, getArtifacts) || {}

  const integrationsCategories = JSON.parse(integrations_categories || "[]")

  const showGallery = ({ show='all' }) => updatePath({ platform: 'gallery', feature: show })
  if (!displayOnlyGallery && !platform && integrations.length) showGallery({ show: 'enabled' })

  const { signup_source } = account
  const enabledFeatures = account.features
  const is_shopify = signup_source === "shopify"

  const displayGallery = platform === "gallery"

  const [activeIntegration, features, details, options] = React.useMemo(() => {
    //if (integrations.length == 0) return DEFAULT_MEMO;

    try {
      const activeIntegration = integrations.find(i => i.key == platform) || { };

      const { auth_required, feature_type, help_doc } = platformFeatures[platform][0];

      const allFeatures = activeIntegration.key ? activeIntegration.values : []

      const authItems = !!auth_required ? ACCOUNT_DEFAULT : []
      const helpItems = !!help_doc ? HELP_DOC_DEFAULT : []

      const defaultItems = (feature_type == "webhook_conversion" || feature_type == "webhook_survey") ?
        WEBHOOK_DEFAULT : feature_type == "pixel_spend_batch" ?
        PIXEL_BATCH_SPEND_DEFAULT : feature_type == "spend_batch" || feature_type == "events_spend_batch" ?
        DIRECT_MAIL_DEFAULT : feature_type == "direct_mail" ?
        BATCH_SPEND_DEFAULT : [];

      const features = [].concat.apply([], [helpItems, authItems, defaultItems, allFeatures])

      // go to first feature if none defined in path
      if (!!platform && !feature) updatePath({platform, feature: features[0].key})

      const hasActiveIntegration = !!Object.keys(activeIntegration).length
      // always show Accounts view
      const selectedFeature = !!feature && (hasActiveIntegration || feature === 'Accounts')
        ? features.find(({key}) => key.toLowerCase() == feature.toLowerCase())
        : features[0]

      const details = selectedFeature.values[0];
      const { options } = activeIntegration;


      return [activeIntegration, features, details, options]
    } catch(e) {
      return DEFAULT_MEMO;
    }

  }, [integrations, platform, feature])

  const [enabledIntegrations] = React.useMemo(() => {
    const enabledIntegrations = integrations?.map(({ key }) => key)
    return [enabledIntegrations]
  }, [integrations])

  const selectPlatform = (platform) => updatePath({ platform, feature: null })
  const selectTarget = (feature) => updatePath({feature})
  const addFeature = () => {
    setFeatureLoading(true);
    const selectedFeature = platformFeatures[platform].filter(row => !row['deprecated'] && row['active']).find(row => row.display_name == chosenFeature)
    addPlatformFeature(platform, selectedFeature.id)
      .then(resp => {
        setFeatureLoading(false);
        refresh()
      })
  }

  const refresh = () => setState({"integrationFeatures": undefined});

  const activeFeature = (platformFeatures && chosenFeature) ?
    platformFeatures[platform].find(row => row.display_name == chosenFeature) :
    platformFeatures && platformFeatures[platform] && platformFeatures[platform].length > 0 ?
    platformFeatures[platform][0] : false

  const getFeatureComponent = (featureType) => {
    switch (featureType) {
      case 'help_doc':
        return () => <Help {...{platform, help_doc: activeFeature.help_doc}} />
      case 'postlog':
        return PostlogIntegration;
      case 'direct_mail':
        return DirectMailIntegration;
      case 'pinterest_events':
        return Pinterest;
      case 'events':
        return Facebook;
      case 'reporting':
        return TransformTableDetails;
      case 'auto_params':
        return GenericAutoParams;
      case 'ads_management':
        return AdsManagementDetails;
      case 'adwords_reporting':
        return GoogleAdsReportingDetails;
      case 'accounts':
        return () => <Authorizations {...{authorizations, showPlatform: platform, activeFeature}} />;
      case 'setup':
        return () => <Setups {...{showPlatform: platform, details, feature, activeFeature, activeIntegration, refresh}} />;
      case 'pixel_setup':
        return () => <Setups {...{showPlatform: platform, details, feature, activeFeature, activeIntegration, refresh}} />;
      case 'inbox_setup':
        return () => <Setups {...{showPlatform: platform, details, feature, activeFeature, activeIntegration, refresh}} />;
      case 'webhook_setup':
        return () => <Setups {...{showPlatform: platform, details, feature, activeFeature, activeIntegration, refresh}} />;
      case 'survey':
        return () => <Setups {...{showPlatform: platform, details, feature, activeFeature, activeIntegration, refresh}} />;
      case 'webhook_survey':
        return () => <Setups {...{showPlatform: platform, details, feature, activeFeature, activeIntegration, refresh}} />;
      case 'conversion':
        return () => <Setups {...{showPlatform: platform, details, feature, activeFeature, activeIntegration, refresh}} />;
      case 'webhook_conversion':
        return () => <Setups {...{showPlatform: platform, details, feature, activeFeature, activeIntegration, refresh}} />;
      case 'warehouse':
        return () => <Setups {...{showPlatform: platform, details, feature, activeFeature, activeIntegration, refresh}} />;
      case 'pixel_spend_batch':
        return TransformTableDetails;
      case 'events_spend_batch':
        return TransformTableDetails;
      case 'shopify_setup':
        return ShopifySetup;
      case 'spend_batch':
        return TransformTableDetails;
      case 'validate_tracking':
         return () => <PixelValidation platform={platform}/>
      case 'comparison':
        return () => <Comparison {...{platform}}/>
      default:
        return EmptyDetails;
    }
  }

  const defaults = { platformTypes, integrations, selectPlatform, authorizations, setDeprecationAlert, setShowDeprecationAlert}
  const menus = integrationsCategories.map(({ title, category }) =>
    Object({
      title: title,
      platformType: category,
      selectedPlatform: platform,
      ...defaults,
    })
  )

  const mainMenu = (
    <div>
      { menus.map(menu => <IntegrationMenu {...menu} />) }
      <Button
        fluid
        primary
        content="Add Integration"
        onClick={showGallery}
        active={displayGallery}
      />
    </div>
  )

  const allowedPlatforms = is_shopify ? SHOPIFY_ALLOWED_INTEGRATIONS : []
  const displayOnlyEnabledIntegrations = displayGallery && feature == 'enabled' && !!enabledIntegrations && !!enabledIntegrations.length
  const platformsToDisplay = limitPlatforms.length ? limitPlatforms : displayOnlyEnabledIntegrations ? enabledIntegrations : []
  const clickableCards = !!displayOnlyEnabledIntegrations

  const galleryHeader = !galleryHideHeader && Object({
    header: displayOnlyEnabledIntegrations ? "Enabled Integrations" : "All Integrations" ,
    subheader: displayOnlyEnabledIntegrations ? "Platforms currently connected to your Rockerbox account" : "Select a platform below to connect with Rockerbox",
  })

  const gallery = <IntegrationGallery {...{integrations: menus, galleryHeader, galleryHideCategoryHeaders, galleryNumColumns, galleryCentered, galleryShowAuthedAccounts, clickableCards, platformsToDisplay, allowedPlatforms, enabledFeatures, updatePath}} />

  // prepare props for VendorNotificationWarning
  const filteredVendorNotifications = React.useMemo(() => {

    const vendorNotification = vendorNotifications.filter(item => item['platform'] == platform) || []
    vendorNotifications.forEach((vendor) => {
      const {notification_id} = vendor;
      const notification = notifications.filter(notification => notification['id'] === notification_id)[0]
      if (!notification) {
        return;
      }
      const daysReg = /[0-9]* days/i
      const days = notification['description'].match(daysReg)
      vendor['days'] = Array.isArray(days) && days[0] ? days[0].split(" ")[0] : 1;
    })
    return vendorNotification
  }, [vendorNotifications, notifications, platform])

  const platformDisplayName = React.useMemo(() => {
    const matched = integrations?.filter(integration => integration.key === platform)[0];
    const displayName = matched ? matched['display_name'] : platform;
    return displayName;
  }, [platform, integrations])

  if (displayOnlyGallery) return gallery

  if (integrations === undefined) return <ContentCard.CardLoader />
  if (platform === undefined && integrations.length < 1) return <EmptyView onClick={showGallery} />
  if (platform === undefined || feature === undefined) return <ContentCard.CardLoader />

  const RightComponent = getFeatureComponent(details.feature_type);

  return (
    <ColumnLayout
      leftWidth={!!integrations.length ? 2 : false}
      leftContent={ !!integrations.length && mainMenu }
      rightWidth={displayGallery ? !!integrations.length ? 14 : 16 : 12}
      rightContent={ displayGallery ? gallery : 
        <>
          {filteredVendorNotifications.map(vendor => 
            <VendorNotificationWarning 
              key={vendor['notification_id']} 
              platformDisplayName={platformDisplayName}
              {...vendor}
              />
          )}
          {showDeprecationAlert && <div class="ui negative message">{deprecationAlert}</div>}
          <RightComponent {...{ details, platform }}/>
        </>
      }
      centerWidth={displayGallery ? false : 2}
      style={{minHeight: 250}}
    >
      <Menu vertical fluid>
        <Menu.Item header content={<IntegrationDisplayName {...{platform}} />} style={{color: "black"}} />
        {
          features && (features||[]).map(({key, values}) => {
            const active = key == feature
            const onClick = () => selectTarget(key, values)
            const chevron = active && <Icon name="chevron right" style={{float: 'right', margin: 0}}/>
            return <Menu.Item {...{active, onClick}}>{key} {chevron}</Menu.Item>
          })
        }
      </Menu>
      {
        options && !!options.length && <>
          <Dropdown button text={chosenFeature || 'Add Feature'} options={options} onChange={(_,{value}) => setChosenFeature(value) } size="tiny" />
          { chosenFeature && <Button color="green" size="tiny" onClick={addFeature} disabled={featureLoading} loading={featureLoading} >Add</Button> }
        </>
      }
    </ColumnLayout>
  )
}

export default IntegrationsIndex;

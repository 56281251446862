import React, { useState, useMemo, useEffect } from 'react';

import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';

import ChartTooltip from '../ChartTooltip';
import { defaultChartDimensions as defaults } from '../constants';
import { formatXAxisTickLabel, toolTipLabelFormatter, getMaxValueWithoutInfinity, findKeysWithInfinityValues, findColor, findYaxisSide } from '../helpers';
import { sortData } from '../hooks/sortData';
import { GroupByDropdown } from '../parts';

const Chart = ({
  data, tierColorMap,
  groupDatesToggle = false, groupDatesBy, setGroupDatesBy,
  defaultGroupBy, // add this prop to sum up data rollups
  height, showTooltip, sortKey, sortOrder, showTotalInToolTip = false, labelFormatter = toolTipLabelFormatter, hideKeys = [], formatter, dot = false, showXAxis, YAxisStyle = [], domainDefault = false,
  nameMap = {}, currencyOverride, tooltipComponent, XAxisFormatter = false, XAxisTickHeight = false,
  allCaps = false, yAnchor = 0,
  dottedLineForInfinity = false, showAxis = false,
  failureDates, showDataStatus = false, isTestAccount,
}) => {
  const { renderData } = sortData(data, groupDatesBy, defaultGroupBy);
  const [filteredData, setFilteredData] = useState(renderData);
  const toolTipValueFormatter = formatter?.format ? formatter.format
    : formatter || (v => v);
  const CTooltip = tooltipComponent || ChartTooltip;
  const lineType = dottedLineForInfinity ? 'linear' : 'monotone';
  // TODO: remove test account stipulation
  const isBadDate = date => (isTestAccount && date ? failureDates.has(date) : false);

  const maxWithoutInfinityLeft = useMemo(() => {
    const leftKey = YAxisStyle.filter((yaxis => yaxis.side !== 'Right')).map(({ keyName }) => keyName);
    return getMaxValueWithoutInfinity(renderData, leftKey);
  }, [renderData]);

  const maxWithoutInfinityRight = useMemo(() => {
    const rightKey = YAxisStyle.filter((yaxis => yaxis.side === 'Right')).map(({ keyName }) => keyName);
    return getMaxValueWithoutInfinity(renderData, rightKey);
  }, [renderData]);

  const keysToRender = useMemo(() => {
    if (!renderData.length) return [];
    if (YAxisStyle.length) {
      return YAxisStyle.map(k => k.keyName);
    }
    return Object.keys(renderData[0] || {}).filter(k => k !== 'date');
  }, [YAxisStyle]);

  const keysDottedToRender = useMemo(() => {
    if (!dottedLineForInfinity) return [];
    return findKeysWithInfinityValues(renderData).filter(key => keysToRender.includes(key));
  }, [keysToRender]);

  useEffect(() => {
    if (!dottedLineForInfinity) {
      setFilteredData(renderData);
      return;
    }
    const keysWithInfinityValues = findKeysWithInfinityValues(renderData);

    const allKeyNames = [...new Set(renderData.flatMap(entry => Object.keys(entry)))];
    const cleanData = renderData.map(entry => {
      const cleanedEntry = { ...entry };
      allKeyNames.forEach(keyName => {
        if (!entry[keyName]) cleanedEntry[keyName] = 0;
      });
      return cleanedEntry;
    });

    const dataReplaced = cleanData.map(entry => {
      const replaceInfinityValuesWithNull = keysWithInfinityValues.reduce((acc, curr) => ({
        [`${curr}`]: Number.isFinite(entry[curr]) ? entry[curr] : null,
        [`${curr}_dotted`]: Number.isFinite(entry[curr]) ? entry[curr] : null,
        ...acc,
      }), {});
      return {
        ...entry,
        ...replaceInfinityValuesWithNull,
      };
    });

    setFilteredData(dataReplaced);
  }, [renderData]);

  return (
    <>
      {groupDatesToggle && <GroupByDropdown {...{ groupDatesBy, setGroupDatesBy }} />}
      <ResponsiveContainer width="100%" height={height || defaults.height}>
        <LineChart data={filteredData}>
          <XAxis
            xAxisId="0"
            hide={!showXAxis}
            dataKey="date"
            tickFormatter={XAxisFormatter ? (date, i) => XAxisFormatter(date, i, renderData) : (date, i) => formatXAxisTickLabel(date, i, renderData)}
            tickLine={false}
            interval={0}
            height={XAxisTickHeight || 15}
            padding={{ left: 20, right: 20 }}
          />
          <YAxis
            hide={!showAxis}
            domain={[0, domainDefault || maxWithoutInfinityLeft || (dataMax => Math.ceil(dataMax))]}
            yAxisId="Left"
            type="number"
          />
          <YAxis
            hide
            domain={[0, domainDefault || maxWithoutInfinityRight || (dataMax => Math.ceil(dataMax))]}
            yAxisId="Right"
            type="number"
          />
          {keysDottedToRender
            .map(k => {
              const color = findColor(k, YAxisStyle, tierColorMap);
              const yAxisSide = findYaxisSide(k, YAxisStyle);
              return (
                <Line
                  key={`${k}_dotted`}
                  dataKey={`${k}_dotted`}
                  dot={dot}
                  fill={color}
                  stroke={color}
                  type={lineType}
                  yAxisId={yAxisSide}
                  strokeWidth={2}
                  strokeDasharray="3 3"
                  connectNulls
                />
              );
            })}
          {keysToRender
            .map(k => {
              const color = findColor(k, YAxisStyle, tierColorMap);
              const yAxisSide = findYaxisSide(k, YAxisStyle);
              return (
                <Line
                  key={k}
                  dataKey={k}
                  dot={dot}
                  activeDot={false}
                  fill={color}
                  stroke={color}
                  type={lineType}
                  yAxisId={yAxisSide}
                  strokeWidth={2}
                  connectNulls={!dottedLineForInfinity}
                />
              );
            })}
          {showTooltip
            && (
            <Tooltip
              cursor={{
                stroke: '#efefef',
                strokeWidth: 2,
                strokeDasharray: '5 5',
              }}
              position={{ y: yAnchor }}
              labelFormatter={v => labelFormatter(v, renderData)}
              formatter={toolTipValueFormatter}
              content={<CTooltip {...{ nameMap, currencyOverride, formatter, hideKeys, allCaps, showTotalInToolTip, labelFormatter, sortKey, sortOrder }} />}
              wrapperStyle={{ zIndex: 3 }}
              showDataStatus={showDataStatus}
              isBadDate={isBadDate}
            />
            )}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default Chart;

import { useMemo, useState, useEffect } from 'react';

import moment from 'moment';
import toast from 'react-hot-toast';

import { daysAgoFromSpecificDate } from '../../../../utils/time';

export const useDates = modelData => {
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);

  const modelDateRange = useMemo(() => {
    if (!modelData) return false;
    return modelData.trainingDates || [];
  }, [modelData]);

  const firstReportingDate = useMemo(() => {
    if (!!modelDateRange) {
      return modelDateRange[0];
    }
  }, [modelDateRange]);

  const maxSelectableDate = useMemo(() => {
    if (!!modelDateRange) {
      return modelDateRange[modelDateRange.length - 1];
    }
  }, [modelDateRange]);

  useEffect(() => {
    if (!endDate && !!modelDateRange) {
      const endDateToSet = modelDateRange[modelDateRange.length - 1];
      const startDateToSet = daysAgoFromSpecificDate(30, endDateToSet);
      setEndDate(endDateToSet);
      setStartDate(startDateToSet);
    }
  }, [modelDateRange]);

  const onDateChange = ({ startDate: startDateChange, endDate: endDateChange }) => {
    if (!modelDateRange || modelDateRange.length === 0) return;

    const lastModelDate = modelDateRange[modelDateRange.length - 1];
    const beforeLastModelDate = moment(startDateChange).isSameOrBefore(moment(lastModelDate)) && moment(endDateChange).isSameOrBefore(moment(lastModelDate));

    if (!beforeLastModelDate) {
      toast('Dates are outside of Model Date Range.', { icon: 'ℹ️' });
      return;
    }

    const formatStartDate = moment(startDateChange).format('YYYY-MM-DD');
    const formatEndDate = moment(endDateChange).format('YYYY-MM-DD');

    setStartDate(formatStartDate);
    setEndDate(formatEndDate);
  };

  return {
    firstReportingDate,
    maxSelectableDate,
    startDate,
    endDate,
    onDateChange,
  };
};

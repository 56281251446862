import React from 'react';

import { Header } from 'semantic-ui-react';

import { useProjectionPeriod } from './useProjectionPeriod';
import MMMDateRangeHooks from '../../../parts/MMMDateRangeHooks';
import { SplitLayout } from '../Layout';

const Projection = () => {
  const { startDate, endDate, onDateChange } = useProjectionPeriod();

  return (
    <SplitLayout
      header="1. Setup Forecast Period"
      leftContent={(
        <MMMDateRangeHooks {...{ startDate, endDate, onDateChange, localStorageKey: 'projectionPeriod' }} />
      )}
      rightContent={(
        <Header as="h4" style={{ margin: 10, marginTop: 0, fontWeight: 400 }}>
          The forecast period is the time frame for which you want to forecast your marketing mix.
          <br />
          <br />
          Adjusting your projection period can help you plan for different time horizons and be useful in different phases of the media budget planning and optimization process.
          For instance, quarterly forecasts might be more useful for budget planning, while monthly forecasts might be more useful for optimization.
        </Header>
        )}
    />
  );
};

export default Projection;

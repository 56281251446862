import React from "react";
import { Dropdown, Button } from "semantic-ui-react";

export const ottTypesMap = {
  batch: "batch",
  pixel: "served",
  tatari: "tatari",
};

const ottTitleMap = {
  batch: "OTT Batch",
  pixel: "OTT Pixel",
  tatari: "OTT Tatari",
};

const OttTypeSelection = (props) => {
  const { ottTypes, setOttType, ottType, style, setActiveArea } = props;
  const items = ottTypes["ott_types"].map((type) => ({
    key: type,
    value: ottTypesMap[type],
    text: ottTitleMap[type],
  }));

  const newStyle = style
    ? Object.assign({ width: "200px" }, style)
    : { width: "200px" };

  return (
    <Dropdown
      options={items}
      placeholder="Select OTT Type"
      fluid
      selection
      as={Button}
      primary
      value={ottType}
      style={newStyle}
      onChange={(e, data) => {
        setOttType(data.value);
        if (data.value === "tatari") {
          setActiveArea("tatari");
        } else {
          setActiveArea("web");
        }
      }}
    />
  );
};

export default OttTypeSelection;

import React, { useCallback } from 'react';
import { Menu, Button, Table, Dropdown, Form, Message } from 'semantic-ui-react';
import { PagedIndexGrid, ContentCard, ValidateSubmitBtn } from '@rockerbox/styleguide';
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/tomorrow';

const requiredFields = ["name"];

export const Config = (props) => {
  const { handleTextChange, name, extractSpec, inboxOptions, reportTypeOptions, inboxId, configTab, toggleActive, reportTypeId, config, fileTypeOptions, fileType, fileName, id, loadSwagger, handleSubmit, handleTemplateChange, templateOptions, templateData, selectedTemplate} = props;
  const key = configTab == 'extractSpec' ? configTab : 'config';
  const displayNameChange = useCallback((e,data) => handleTextChange(data.value, "name"))
  const fileNameChange = useCallback((e,data) => handleTextChange(data.value, "fileName"))

  return (
    <ContentCard>
      <Form>
        <Form.Group widths="equal">
          <Form.Field>
            <Form.Input label="Display name:" value={name} icon="edit" placeholder="Add display name" onChange={displayNameChange} inline required/>
          </Form.Field>
          { templateData && templateData.length > 0 && 
              <Form.Select placeholder="Select a Template" options={templateOptions} value={selectedTemplate} onChange={(e,data) => handleTemplateChange(e,data)} clearable/>
          }
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Select label="Report Type:" options={reportTypeOptions} value={reportTypeId} onChange={(e,data) => handleTextChange(data.value, "reportTypeId")} clearable disabled={id}/>
          <Form.Select label="Inbox:" options={inboxOptions} value={inboxId} onChange={(e,data) => handleTextChange(data.value, "inboxId")} clearable/>
          <Form.Select label="File type:" options={fileTypeOptions} value={fileType} onChange={(e,data) => handleTextChange(data.value, "fileType")} clearable/>
          <Form.Input label="Filename Regex:" value={fileName} icon="edit" placeholder="Add filename regex" onChange={fileNameChange} inline fluid />
        </Form.Group>
        <Menu
          widths={3}
          attached="top"
          secondary
          pointing
          fluid
          >
            <Menu.Item
              name="Extract Spec"
              active={configTab == 'extractSpec'}
              onClick={() => props.toggleActive('configTab','extractSpec')}
            />
            <Menu.Item
              name="Transform Config"
              active={configTab == 'config'}
              onClick={() => props.toggleActive("configTab","config")}
            />
            <Menu.Item
              name="Load"
              active={configTab == 'loadSwagger'}
              onClick={() => props.toggleActive("configTab","loadSwagger")}
            />
          </Menu>
          <AceEditor
            mode="json"
            theme="tomorrow"
            setOptions={{
              highlightGutterLine: false,
              showPrintMargin: false,
              showGutter: true,
              highlightActiveLine: false,
              fontSize: 14,
              wrapEnabled: true
            }}
            width={'100%'}
            height={'530px'}
            value={configTab == 'extractSpec' ? extractSpec : configTab == 'config' ? config : loadSwagger}
            onChange={(obj) => handleTextChange(obj, key)}
            style={{marginTop: 15}}
            readOnly={configTab == 'loadSwagger'}
          />
          
          <ValidateSubmitBtn style={{marginTop: 10}} required={requiredFields} fieldsObj={props} content={'submit'} submit={handleSubmit}/>
      </Form>
    </ContentCard>
  )
}

export const TestPane = (props) => {
  const { testTab, toggleActive, messagesOptions, fileVal, updatePreviewList } = props;

  return (
    <React.Fragment>
      <ContentCard>
        <Dropdown selection search options={messagesOptions} value={fileVal} onChange={updatePreviewList}/>
      </ContentCard>
      <ContentCard
        disableHeaderEllipse
      >
        <Menu
          widths={3}
          attached="top"
          secondary
          pointing
          fluid
          >
            <Menu.Item
              name="Select File"
              active={testTab == 'select'}
              onClick={() => toggleActive('testTab', 'select')}
            />
            <Menu.Item
              name="Test"
              active={testTab == 'test'}
              onClick={() => toggleActive('testTab', 'test')}
            />
            <Menu.Item
              name="Result"
              active={testTab == 'result'}
            />
          </Menu>
          {testTab == 'select' && <Inboxes {...props}/>}
          {testTab == 'test' && <Test {...props}/>}
          {testTab == 'result' && <Result {...props}/>}
      </ContentCard>
    </React.Fragment>
  )
}

const Inboxes = (props) => {
  const { handleUse } = props;
  const cols = [
    {display: '', key: '_id', as: NameCell(props.fileVal)},
    {display: '', as: (props) => <UseCell handleUse={handleUse} {...props} />}
  ]
  return (
    <PagedIndexGrid itemsPerPage={10} data={props.previewList} cols={cols}/>
  )
}

const NameCell = (fileVal) => ({item, col}) => {
  return (
    <Table.Cell>
      {`${item[col.key]} - ${fileVal}`}
    </Table.Cell>
  )
}

const UseCell = ({item, col, handleUse}) => {
  return (
    <Table.Cell>
      <Button onClick={() => handleUse(item)} content="use" size="tiny"/>
    </Table.Cell>
  )
}

const Test = (props) => {
  const { test, handleTest } = props;
  return (
    <React.Fragment>
      <div style={{textAlign: 'left'}}>
        <Button
          primary
          compact
          size="small"
          style={{marginTop: 10}}
          onClick={handleTest}
          >
            Test
          </Button>
      </div>
      <AceEditor
        mode="json"
        theme="tomorrow"
        setOptions={{
          highlightGutterLine: false,
          showPrintMargin: false,
          showGutter: true,
          highlightActiveLine: false,
          fontSize: 14,
          wrapEnabled: true
        }}
        width={'100%'}
        height={'530px'}
        value={test}
        style={{marginTop: 15}}
        readOnly={true}
      />
    </React.Fragment>
  )
}

const Result = (props) => {
  const { result, swaggerError } = props;
  return (
    <React.Fragment>
      <AceEditor
        mode="json"
        theme="tomorrow"
        setOptions={{
          highlightGutterLine: false,
          showPrintMargin: false,
          showGutter: true,
          highlightActiveLine: false,
          fontSize: 14,
          wrapEnabled: true
        }}
        width={'100%'}
        height={'530px'}
        value={result}
        style={{marginTop: 15}}
        readOnly={true}
      />
      {swaggerError && swaggerError.length > 0 &&
        swaggerError.map(c => {
          return (
            <Message negative>
              {JSON.stringify(c.error)}
            </Message>
          )
        })
      }
    </React.Fragment>
  )
}

import React, { useState, useMemo } from 'react';

import { Segment, Grid, Dropdown } from 'semantic-ui-react';

import BoxChart from './BoxChart';
import ChannelOptimizationChart from './chart';
import DailyChart, { SimplePlot } from './DailyChart';
import { computeFeatureHistograms } from './helpers';
import ChannelOptimizationLoader from './loaders';
import SpendStats from './SpendStats';
import { useChannelTactics } from './useChannelTactics';
import { getRevenueFromResponseCurve } from '../forecast/forecastData';

const useModelData = modelData => {
  const allHistograms = useMemo(() => (modelData ? computeFeatureHistograms(modelData) : {}), [modelData]);
  return { allHistograms };
};

const useKPIAndTargetSelection = isConversionKPI => {
  const [targetRoas, setTargetRoas] = useState(1.5);
  const [chartType, setChartType] = useState('kpi');

  /* eslint-disable no-param-reassign */
  const changeMetric = (minMetric, maxMetric, value) => {
    if (value < minMetric) value = minMetric;
    if (value > maxMetric) value = maxMetric;
    const updatedValue = isConversionKPI ? 1 / value : value;
    setTargetRoas(updatedValue);
  };
  /* eslint-enable no-param-reassign */

  const ChartTypeSelector = () => (
    <Dropdown
      compact
      selection
      options={[
        {
          text: `Predicted ${isConversionKPI ? 'Conversions' : 'Revenue'} by Spend Level`,
          value: 'kpi',
        },
        {
          text: `${isConversionKPI ? 'CPA' : 'ROAS'} by Spend Level`,
          value: 'metric',
        },
      ]}
      value={chartType}
      onChange={(e, { value }) => setChartType(value)}
      style={{ width: 245 }}
    />
  );

  return {
    targetRoas,
    setTargetRoas,
    chartType,
    setChartType,
    changeMetric,
    ChartTypeSelector,
  };
};

const ChannelOptimization = ({ isConversionKPI, channels, features, responseCurves, modelData }) => {
  const { chartType } = useKPIAndTargetSelection(isConversionKPI);

  const { allHistograms } = useModelData(modelData);
  const {
    selectedChannel,
    selectedTactic,
    selectedFeature,
    ChannelSelector,
    TacticSelector,
  } = useChannelTactics(channels, features);

  const histogram = useMemo(() => {
    if (allHistograms && selectedTactic && selectedFeature) {
      return allHistograms[selectedFeature.value];
    }
    return [];
  }, [allHistograms, selectedChannel, selectedTactic]);

  // response curve for selected feature
  const responseCurve = useMemo(() => {
    if (!responseCurves || !selectedFeature) return;
    return responseCurves[selectedFeature.value];
  }, [responseCurves, selectedFeature]);

  const currentFeatureSpendTimeseries = useMemo(() => {
    if (!modelData || !modelData.trainingData || !responseCurve) return [];

    const predMediaRevenueByDate = modelData.raw.pred_decomp.reduce((dateObj, point) => {
      if (point.media.slice(-2) === '_S') {
        dateObj[point.DATE] = dateObj[point.DATE] || 0; // eslint-disable-line no-param-reassign
        dateObj[point.DATE] += point.decomp_value; // eslint-disable-line no-param-reassign
      }
      return dateObj;
    }, {});

    return modelData.trainingData.map(x => {
      const totalSpend = Object.entries(x).reduce((acc, [key, value]) => (key === 'date' || key.slice(-2) !== '_S' ? acc : acc + value), 0);
      const revenue = getRevenueFromResponseCurve(responseCurve, x[selectedFeature.value]);
      const totalRevenue = predMediaRevenueByDate[x.date];

      return {
        day: x.date,
        spend: x[selectedFeature.value], totalSpend, percentOfTotalSpend: x[selectedFeature.value] / totalSpend,
        revenue, totalRevenue, percentOfTotalRevenue: revenue / totalRevenue,
      };
    }).map(row => {
      row.roas = row.revenue / row.spend; // eslint-disable-line no-param-reassign
      row.cpa = row.spend / row.revenue; // eslint-disable-line no-param-reassign
      return row;
    });
  }, [modelData, selectedFeature]);

  const data = currentFeatureSpendTimeseries;
  const averageSpend = data.reduce((acc, x) => acc + x.spend, 0) / data.length;
  const currentSpend = averageSpend;

  // calculate expected revenue for current spend
  const currentRevenue = useMemo(() => {
    if (!currentSpend) return;
    return getRevenueFromResponseCurve(responseCurve, currentSpend);
  }, [responseCurve, currentSpend]);

  const currentSpendEffectData = useMemo(
    () => ((!modelData || !selectedFeature) ? []
      : modelData
        .spendEffectData
        .filter(x => x.feature_name === selectedFeature.value)),
    [modelData, selectedFeature],
  );

  const spendEffectData = useMemo(
    () => currentSpendEffectData
      .map(x => {
        const spendEffect = parseInt(x.spend_effect * 100) / 100;
        return Object.assign(x, { spendEffect });
      })
      .sort((a, b) => a.day_in_window - b.day_in_window)
      .map((x, i) => {
        const previous = i > 0 ? currentSpendEffectData[i - 1] : { cumulativeEffect: 0 };
        const cumulativeEffect = previous.cumulativeEffect + x.spend_effect;
        const cumulativeEffectRounded = Math.round(cumulativeEffect * 1000, 2) / 1000;
        return Object.assign(x, { cumulativeEffect, cumulativeEffectRounded });
      })
      .filter((x, i) => {
        const previous = i > 0 ? currentSpendEffectData[i - 1] : { cumulativeEffect: 0 };
        return previous.cumulativeEffect === 0 || x.spendEffect > 0;
      }),
    [currentSpendEffectData],
  );

  const currentKPI = isConversionKPI ? currentRevenue : currentRevenue;

  if (!channels?.length || !responseCurve?.length) return <ChannelOptimizationLoader />;

  const currentROI = modelData.featureROI.find(x => x.feature === selectedFeature.value) || {};
  const whiskerData = [
    {
      actual: parseInt(currentROI.roi * 10000) / 10000,
      range: [
        0,
        parseInt((currentROI.roi - currentROI.q05) * 10000) / 10000,
        parseInt(currentROI.roi * 10000) / 10000,
        parseInt((currentROI.roi + currentROI.q95) * 10000) / 10000,
        parseInt((currentROI.roi + currentROI.q95) * 1.25 * 10000) / 10000,
      ],
    },
  ];

  const hasCurrentROI = currentROI.roi !== undefined;

  return (
    <>
      <ChannelSelector />
      <Segment attached="bottom" style={{ border: '1px solid #e7eafc' }}>
        <TacticSelector />
        <br />
        <DailyChart
          name={selectedFeature.value}
          data={data}
          isConversionKPI={isConversionKPI}
        />

        <Grid style={{ padding: 20, paddingTop: 0 }}>
          <Grid.Row>
            <h3>Spend Distribution</h3>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <div style={{ position: 'relative' }}>
                <ChannelOptimizationChart {...{
                  isConversionKPI,
                  chartType,
                  responseCurve,
                  currentSpend,
                  currentKPI,
                  // targetSpend,
                  // predictedKPI,
                  histogram,
                  histogramOnly: true,
                  height: 250,
                }}
                />
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              { data && <SpendStats data={data} /> }
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid style={{ padding: 20, paddingTop: 0 }}>
          <Grid.Row>
            <h3>Predicted Performance</h3>
          </Grid.Row>
          { hasCurrentROI && (
          <Grid.Row>
            <h5>ROI confidence at average spend</h5>
            <BoxChart data={whiskerData} />
          </Grid.Row>
          )}
          <Grid.Row>
            <h5>Diminishing returns of ROI based on spend saturation</h5>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <div style={{ position: 'relative' }}>
                <ChannelOptimizationChart {...{
                  isConversionKPI,
                  chartType,
                  responseCurve,
                  currentSpend,
                  currentKPI,
                  // targetSpend,
                  // predictedKPI,
                  // histogram
                  height: 250,
                }}
                />
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <div style={{ position: 'relative' }}>
                <ChannelOptimizationChart {...{
                  isConversionKPI,
                  chartType: 'metric',
                  responseCurve,
                  currentSpend,
                  currentKPI,
                  // targetSpend,
                  // predictedKPI,
                  // histogram
                  height: 250,
                }}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <h5>Peak effectiveness / delay</h5>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <div style={{ position: 'relative' }}>
                <SimplePlot
                  margin={{ top: 0, right: 10, left: 0, bottom: 0 }}
                  type="area"
                  dataKey="spendEffect"
                  timeKey="day_in_window"
                  data={spendEffectData}
                  showAxis
                />
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <div style={{ position: 'relative' }}>
                <SimplePlot
                  margin={{ top: 0, right: 10, left: 0, bottom: 0 }}
                  type="area"
                  dataKey="cumulativeEffectRounded"
                  timeKey="day_in_window"
                  data={spendEffectData}
                  showAxis
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
};

export default ChannelOptimization;

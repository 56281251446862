import {
  checkJob,
  checkJobsCache,
} from "../../utils/job_api";

export const getJobs = setStatus => jobKey => id => {
  checkJobsCache(id, jobKey).then(jobStatus => {
    const promises = Object.keys(jobStatus)
      .filter(key => {
        const started = jobStatus[key] == "started";
        const queued = jobStatus[key] == "queued";
        const untracked = jobStatus[key] == "unknown";
        return started || queued || untracked;
      })
      .map(key => checkJob(JSON.parse(key), id, jobKey));

    if (promises.length > 0)
      Promise.all(promises).then(() => {
        setTimeout(() => getJobs(setStatus)(jobKey)(id), 5000);
      });

    setStatus(Object.values(jobStatus)[0]);
  });
};

import React from "react";
import { Button, Message } from "semantic-ui-react";

import { ColumnLayout } from "@rockerbox/styleguide";
import EditorWindow from "./EditorWindow";

const TransformView = (props) => {
  const {
    transformFunction,
    setTranformFunction,
    errorContent,
    sendReportTransformRequest,
    activeStep,
    setActiveStep,
  } = props;

  const TranformFuncMsg = (
    <Message
      info
      header="Transform function is used to manipulate report data and generate desired output. Some rules for transform function: "
      list={[
        "It must be a function with name run",
        "It must receive two arguments: report dataframe and settings object",
        "It must return an dataframe",
      ]}
    />
  );

  const TestFlowMsg = (
    <Message
      info
      header="Test inbound schema and transform function"
      content="Inbound Schema and Transform function will be submitted to test parsing report data. Result will be displayed in next step if it runs successfully. Error message will be displayed in shell below."
    />
  );

  const TransformEditor = (
    <React.Fragment>
      {TranformFuncMsg}
      <EditorWindow
        mode={"python"}
        theme="tomorrow"
        value={transformFunction || ""}
        onChange={(obj) => setTranformFunction((prevState, curProps) => obj)}
      />
    </React.Fragment>
  );

  const displayContent = (
    <React.Fragment>
      {TestFlowMsg}
      <Button
        primary
        onClick={() =>
          sendReportTransformRequest().then((step) => setActiveStep(step))
        }>
        Submit test
      </Button>

      <EditorWindow value={errorContent} theme="monokai" mode="shell" />
    </React.Fragment>
  );

  return (
    <ColumnLayout
      leftWidth={9}
      rightWidth={6}
      leftContent={TransformEditor}
      rightContent={displayContent}></ColumnLayout>
  );
};

export default TransformView;

/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { GridRow, GridColumn, Table, Button } from 'semantic-ui-react';
import { IndexGridNew } from '@rockerbox/styleguide';
import { Link } from 'react-router-dom';
import { retrieveStripeInvoiceHistory, retrieveStripePaymentIntent, getStripePaymentMethod } from '../../api/account';
import { handlePaymentDetails, processReceiptUrl, handleInvoiceNoPaymentMethod } from './helpers';

const BillingHistory = ({ activeSubscription }) => {
  const [invoiceData, setInvoiceData] = useState(false);

  useEffect(() => {
    if (!activeSubscription) return;
    retrieveStripeInvoiceHistory(activeSubscription.id)
      .then(invoiceHistory => {
        const invoicePromises = invoiceHistory.response.map(invoice => {
          if (invoice.payment_intent === null) return handleInvoiceNoPaymentMethod(invoice);
          const paymentIntentPromise = retrieveStripePaymentIntent(invoice.payment_intent);
          return paymentIntentPromise.then(paymentIntentData => {
            const paymentId = paymentIntentData.response.payment_method
              ? paymentIntentData.response.payment_method
              : paymentIntentData.response.charges.data[0].payment_method
                ? paymentIntentData.response.charges.data[0].payment_method
                : null;
            if (paymentId === null) return handleInvoiceNoPaymentMethod(invoice);
            const paymentMethodPromise = getStripePaymentMethod(paymentId);
            return paymentMethodPromise.then(paymentMethodData => {
              paymentIntentData.invoice = invoice.number;
              const billingDate = new Date(0);
              billingDate.setUTCSeconds(Number(invoice.created));
              paymentIntentData.date = billingDate.toLocaleDateString('en-us', { month: 'long', day: 'numeric', year: 'numeric' });
              paymentIntentData.receiptLink = paymentIntentData?.response?.charges?.data[0] ? processReceiptUrl(paymentIntentData.response.charges.data[0].receipt_url) : null;
              paymentIntentData.invoiceLink = invoice.invoice_pdf;
              paymentIntentData.amount = `$${(Number(invoice.amount_due) / 100).toFixed(2)}`;
              paymentIntentData.paymentMethod = handlePaymentDetails(paymentMethodData.response);
              return paymentIntentData;
            });
          });
        });
        Promise.all(invoicePromises)
          .then(fulfilledAll => {
            setInvoiceData(fulfilledAll);
          }, () => {
            setInvoiceData([]);
          });
      });
  }, [activeSubscription]);

  // eslint-disable-next-line react/no-unstable-nested-components
  const UseCell = ({ item }) => (
    <Table.Cell>
      <Button
        icon="download"
        as={Link}
        to={{ pathname: item.invoiceLink }}
        content="Invoice"
        target="_blank"
        className="invoice-button"
        style={{ color: '#475ddc', backgroundColor: 'transparent' }}
      />
      <Button
        icon="download"
        as={Link}
        to={{ pathname: item.receiptLink }}
        content="Receipt"
        target="_blank"
        className="receipt-button"
        style={{ color: '#475ddc' }}
        disabled={!item.receiptLink}
      />
    </Table.Cell>
  );

  const cols = [
    { display: 'Invoice', key: 'invoice', isSearchable: true, style: { width: '20%' } },
    { display: 'Billing Date', key: 'date', isSearchable: true, style: { width: '20%' } },
    { display: 'Amount', key: 'amount', isSearchable: true, style: { width: '20%' } },
    { display: 'Payment Method', key: 'paymentMethod', style: { width: '20%' } },
    // eslint-disable-next-line react/no-unstable-nested-components, react/jsx-props-no-spreading
    { display: 'Download', key: 'invoiceLink', style: { width: '20%' }, as: props => <UseCell {...props} /> },
  ];

  return (
    <GridColumn width={15}>
      <GridRow className="billing-history-data-table">
        <Table>
          <IndexGridNew cols={cols} data={invoiceData} paginate itemsPerPage={10} searchable sortable title="Billing History" fallBackMsg="No invoices available" />
        </Table>
      </GridRow>
    </GridColumn>
  );
};

export default BillingHistory;

import React, { useState, useMemo, useEffect } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { GlobeIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';
import { Modal, Card, Button, Image, Message, Input, Form } from 'semantic-ui-react';

import * as routes from '../../baseRoutes';
import { useGlobalState } from '../../hooks/global';

const LockedItem = ({ image, title }) => (
  <Card
    className="integration-card"
    style={{ textAlign: 'center' }}
  >
    <Card.Content style={{ margin: 0 }}>
      <Image
        centered
        size="medium"
        src={`https://images.rockerbox.com/integrations/${image}`}
        style={{ height: 76, width: 'auto', marginBottom: 10 }}
      />
      <Card.Header style={{ justifyContent: 'center', color: 'rgba(0, 0, 0, 0.5)' }}>
        {title}
      </Card.Header>
      <Card.Description
        textAlign="center"
        style={{ paddingTop: 25 }}
      >
        <Button
          fluid
          disabled
          icon="lock"
          content="Upgrade to Access"
        />
      </Card.Description>
    </Card.Content>
  </Card>
);

const ConnectOrders = ({ open, onClose, defaultShowShopify = false }) => {
  const history = useHistory();

  const { authorizations, integrations: allIntegrations } = useGlobalState();

  const [showShopifyModal, setShowShopifyModal] = useState(false);
  const [showShopifyForm, setShowShopifyForm] = useState(false);
  const [shopifyStoreDomain, setShopifyStoreDomain] = useState('');

  const [shopifyIntegration, segmentIntegration] = useMemo(() => {
    if (!allIntegrations?.length) return [{}, {}];
    const shopify = allIntegrations.find(integration => integration.platform === 'shopify');
    const segment = allIntegrations.find(integration => integration.platform === 'segment');
    return [
      {
        ...shopify,
        src: `https://images.rockerbox.com/integrations/${shopify.logo_img}`,
      },
      {
        ...segment,
        src: `https://images.rockerbox.com/integrations/${segment.logo_img}`,
      },
    ];
  }, [allIntegrations]);
  const googleTagManagerImage = 'integrations-logo-google-tag-manager.png';
  const rockerboxImage = 'integrations-logo-rockerbox.png';

  const shopifyConnected = useMemo(() => {
    const connectedPlatforms = authorizations?.map(({ platform }) => platform) || [];
    return connectedPlatforms?.includes('shopify');
  }, [authorizations]);

  const authShopify = () => {
    if (!shopifyStoreDomain) return;
    const shopifyAuthUrl = `/auth/shopify?shop=${shopifyStoreDomain}.myshopify.com`;
    window.open(shopifyAuthUrl, '_self');
  };

  const navigate = route => history.push(route);

  useEffect(() => {
    setShowShopifyModal(defaultShowShopify);
    setShowShopifyForm(false);
  }, [defaultShowShopify]);

  return (
    <Modal
      closeIcon
      size={showShopifyModal ? 'tiny' : 'small'}
      dimmer="inverted"
      className="modal-v3"
      {...{ open, onClose }}
    >
      <Modal.Header>
        <div className="icon">
          <GlobeIcon />
        </div>
        <div className="title">
          {`Connect your ${showShopifyModal ? 'Shopify store' : 'orders & conversions'}`}
        </div>
      </Modal.Header>
      <Modal.Content>
        {showShopifyModal
        && !showShopifyForm
        && (
        <div>
          <Message
            info
            size="small"
            header="Are you using Shopify Checkout Extensions?"
            content={(
              <>
                If you&lsquo;re using Shopify Checkout extensions, we&lsquo;ll guide you through setting up the Rockerbox Checkout Pixel App after connecting your Shopify account to Rockerbox.
              </>
              )}
          />
          <Button fluid color="purple" className="m-t-25" onClick={() => navigate(`${routes.integrationLastStep}/shopify`)}>
            Yes, I am using Shopify Checkout Extensions
          </Button>
          <Button fluid color="purple" className="m-t-25" onClick={() => setShowShopifyForm(true)}>
            No, I am not using Shopify Checkout Extensions
          </Button>
        </div>
        )}
        {!!showShopifyModal
          ? (showShopifyForm
            && (
            <div>
              <Message
                info
                size="small"
                header="Enter the the myshopify subdomain you use to access Shopify admin"
                content={(
                  <>
                    For example, if your store admin URL is
                    <code>examplestore.myshopify.com</code>
                    , please enter
                    <code>examplestore</code>
                    {' '}
                    in the form below.
                  </>
                )}
              />
              <Form onSubmit={authShopify}>
                <Form.Field>
                  <label>Shopify Store Domain</label>
                  <Input
                    fluid
                    labelPosition="right"
                    label=".myshopify.com"
                    value={shopifyStoreDomain}
                    onChange={(e, { value }) => setShopifyStoreDomain(value)}
                  />
                </Form.Field>
                <Form.Button
                  fluid
                  primary
                  type="submit"
                  disabled={!shopifyStoreDomain}
                  content="Connect Shopify store"
                />
              </Form>
            </div>

            )
          )
          : (
            <Card.Group itemsPerRow={2}>
              <Card
                link={!shopifyConnected}
                className="integration-card"
                style={{ textAlign: 'center', cursor: !shopifyConnected ? 'pointer' : 'default' }}
                onClick={() => !shopifyConnected && setShowShopifyModal(true)}
              >
                <Card.Content style={{ margin: 0 }}>
                  <Image
                    centered
                    size="medium"
                    src={shopifyIntegration.src}
                    style={{ height: 76, width: 'auto', marginBottom: 10 }}
                  />
                  <Card.Header style={{ justifyContent: 'center', color: 'rgba(0, 0, 0, 0.5)' }}>
                    {shopifyIntegration.display_name}
                  </Card.Header>
                  <Card.Description
                    textAlign="center"
                    style={{ paddingTop: 25 }}
                  >
                    <Button
                      fluid
                      inverted={!shopifyConnected}
                      disabled={!!shopifyConnected}
                      color={!shopifyConnected ? 'purple' : 'green'}
                      icon={shopifyConnected && 'check'}
                      content={`Connect${shopifyConnected ? 'ed' : ''}`}
                    />
                  </Card.Description>
                </Card.Content>
              </Card>
              <LockedItem
                image={googleTagManagerImage}
                title="Google Tag Manager"
              />
              <LockedItem
                image={segmentIntegration.logo_img}
                title="Segment"
              />
              <LockedItem
                image={rockerboxImage}
                title="JavaScript, Webhooks, Batch Files"
              />
            </Card.Group>
          )}
      </Modal.Content>
    </Modal>
  );
};

export default ConnectOrders;

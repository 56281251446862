import React from "react";
import Container from "../parts/Container";
import { Button, Modal, Form, Message } from "semantic-ui-react";
import "react-dates/lib/css/_datepicker.css";
import "../../react_dates_overrides.css";
import { DateRangePicker } from "react-dates";
import { CloseButtonStyles } from "../styled";

const typeOptions = [
  { key: "csv", value: "csv", text: "csv" },
  { key: "tsv", value: "tsv", text: "tsv" },
  { key: "xlsx", value: "xlsx", text: "xlsx" }
];

const SendSavedReportModal = props => {
  const {
    loading,
    error,
    startDate,
    endDate,
    email,
    file_type,
    focusedInput,
    successPage,
    reportToSend,
    onFieldChange,
    onDatesChange,
    onFocusChange,
    handleOutsideRange,
    onFormSubmit
  } = props;

  const validate = () => {
    return startDate && endDate && email && file_type;
  };

  const formFieldsAndButton = (
    <React.Fragment>
      <Form.Group key={"destination"}>
        <Form.Field>
          <label>{"Email"}</label>
          <Form.Input value={email} onChange={onFieldChange("email")} />
        </Form.Field>
      </Form.Group>
      <Form.Group key={"dates"}>
        <Form.Field>
          <label>{"Date Range"}</label>
          <DateRangePicker
            onDatesChange={onDatesChange}
            onFocusChange={onFocusChange}
            focusedInput={focusedInput}
            startDate={startDate}
            endDate={endDate}
            noBorder={true}
            isOutsideRange={d => handleOutsideRange(d)}
            hideKeyboardShortcutsPanel
            startDateId="deliveryStartDate"
            endDateId="deliveryEndDate"
            minimumNights={0}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group key={"filetype"}>
        <Form.Field>
          <label>{"File Type"}</label>
          <Form.Dropdown
            selection
            options={typeOptions}
            value={file_type}
            onChange={onFieldChange("file_type")}
          />
        </Form.Field>
      </Form.Group>
      <Button type="submit" disabled={!validate()}>
        Send Saved Report
      </Button>
    </React.Fragment>
  );

  return (
    <Form onSubmit={() => onFormSubmit()} loading={loading} error={error}>
      <Message
        error
        header="Failed to send this saved report"
        content="Please try with a different date or contact us."
      />
      {successPage ? (
        <Container center column>
          <h5>
            Your report is now processing! You should receive an email to
            download shortly.
          </h5>
          <Container>
            <Button onClick={props.done}>Done</Button>
          </Container>
        </Container>
      ) : (
        formFieldsAndButton
      )}
    </Form>
  );
};

export default props => (
  <Modal
    size="small"
    open={props.open}
    onClose={props.close}
    closeOnDimmerClick={false}
  >
    <Modal.Header>
      <CloseButtonStyles onClick={props.close}>x</CloseButtonStyles>
      {props.title}
    </Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <SendSavedReportModal done={props.close} {...props} />
      </Modal.Description>
    </Modal.Content>
  </Modal>
);

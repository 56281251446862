import React, { useEffect, useState, useContext } from 'react';

import {
  ModalHeader,
  ModalContent,
  ModalActions,
  Button,
  Modal,
} from 'semantic-ui-react';

import { CacheContext } from '../../../../utils/CacheContext';
import { getAccount } from '../../../api/account';
import {
  getMMMCustomFeedsDimensions,
  putMMMCustomConfiguration,
  postMMMLockStatus,
} from '../../../api/attributionModel';
import { submitMMMConfigureAndBuildJob } from '../../../api/aws';

const FeatureSelectModal = ({ open, setOpen, setMMMLock }) => {
  const [,, Context] = useContext(CacheContext);
  const account = Context.getCache('account', getAccount) || {};

  const [loading, setLoading] = useState(false);
  const [features, setFeatures] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [extraFeatures, setExtraFeatures] = useState([]);
  const [selectedRevenue, setSelectedRevenue] = useState(undefined);

  useEffect(() => {
    getMMMCustomFeedsDimensions()
      .then(dimensions => dimensions.map(d => d.dimension))
      .then(setFeatures);
  }, []);

  const handleSpendChange = e => {
    const { value, checked } = e.target;
    const s = new Set(selectedFeatures);
    if (checked) {
      s.add(value);
    } else {
      s.delete(value);
    }
    setSelectedFeatures(Array.from(s));
  };

  const handleExtraFeatureChange = e => {
    const { value, checked } = e.target;
    const s = new Set(extraFeatures);
    if (checked) {
      s.add(value);
    } else {
      s.delete(value);
    }
    setExtraFeatures(Array.from(s));
  };

  const handleRevenueChange = e => {
    const { value } = e.target;
    setSelectedRevenue(value);
  };

  const onSubmit = () => {
    setLoading(true);
    const data = { features_list: selectedFeatures, extra_features: extraFeatures, revenue: selectedRevenue };
    putMMMCustomConfiguration(data)
      .then(() => {
        setLoading(false);
        submitMMMConfigureAndBuildJob(account.pixel_source_name)
          .then(() => {
            setLoading(false);
            setOpen(false);
          });
        postMMMLockStatus({ lock: true })
          .then(({ lock }) => {
            setMMMLock(lock);
          });
      });
  };

  const resetModalState = () => {
    setSelectedFeatures([]);
    setSelectedRevenue(undefined);
    setOpen(false);
  };

  return (
    <Modal
      onClose={() => resetModalState()}
      onOpen={() => setOpen(true)}
      open={open}
      className="mmm-feature-modal"
    >
      <ModalHeader>Refresh model</ModalHeader>
      <ModalContent>
        <h2>Select your features:</h2>
        <div className="three-columns">
          <div className="column">
            <h3>Spend</h3>
            {features.filter(d => d.endsWith('_S')).map(f => (
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  value={f}
                  key={f}
                  id={`feature-${f}`}
                  onChange={handleSpendChange}
                  checked={selectedFeatures.indexOf(f) > -1}
                />
                <label htmlFor={`feature-${f}`}>{f}</label>
              </div>
            ))}
          </div>
          <div className="column">
            <h3>Extra Features</h3>
            {features.filter(d => d.endsWith('_EF')).map(f => (
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  value={f}
                  key={f}
                  id={`feature-${f}`}
                  onChange={handleExtraFeatureChange}
                  checked={extraFeatures.indexOf(f) > -1}
                />
                <label htmlFor={`extra-feature-${f}`}>{f}</label>
              </div>
            ))}
          </div>
          <div className="column">
            <h3>Revenue</h3>
            {[...features.filter(d => d.endsWith('_R')), 'Shopify'].map(f => (
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  value={f}
                  key={f}
                  id={`feature-${f}`}
                  onChange={handleRevenueChange}
                  checked={selectedRevenue === f}
                />
                <label htmlFor={`feature-${f}`}>{f}</label>
              </div>
            ))}
          </div>
        </div>
      </ModalContent>
      <ModalActions>
        <Button onClick={() => resetModalState()}>
          Cancel
        </Button>
        <Button
          primary
          disabled={selectedFeatures.length < 1 || selectedRevenue === undefined || loading}
          onClick={() => onSubmit()}
        >
          Submit
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default FeatureSelectModal;

import { useEffect, useState, useMemo } from 'react';
import { useAtom } from 'jotai';
import { topTabAtom, convValueAtom, attributionWindowAtom } from '../../../atoms';

// shared v3
import { OVERRIDE_WINDOW_NAMES } from '../../../utils/channelPerformance/platformMetrics';

// local
import { buildConversionMetricsDropdown } from './platformConfigOptionHelpers';

// state
import { useConfig } from './platformConfig/config';
import { usePerformanceMetaData } from './platformConfig/metadata';
import { usePlatformNames } from './platformConfig/platformNames';

const eventOptionsFromConversionOptions = (conversionOptions, default_attribution_window) => {
  const defaultOptions = {
    options: [{ text: 'Default', value: default_attribution_window }],
  };
  return value => {
    const currentOption = conversionOptions.find(row => row.value === value);
    if (currentOption) return currentOption;
    return defaultOptions;
  };
};

const valueInOptionsWithDefault = (options, _value, defaultValue = '') => options.find(({ value }) => value === _value)?.value || defaultValue;

export const usePlatformPerformanceConfig = () => { // eslint-disable-line import/prefer-default-export
  // interact with router to get respective derived / async values
  const { platform, platformConfig } = useConfig();
  const { platformPerformanceMeta, current, dates } = usePerformanceMetaData();
  const { conversionNames } = usePlatformNames();

  const {
    default_metric,
    default_attribution_window,
    revenue_formatter = false,
    exclude_windows = [],
  } = platformConfig || {};
  const [defaultMetric, setDefaultMetric] = useState(default_metric);
  const [optionalMetric, setOptionalMetric] = useAtom(topTabAtom);
  const [platformConversionEvent, setPlatformConversionEvent] = useAtom(convValueAtom);
  const [_attributionWindow, setAttributionWindow] = useAtom(attributionWindowAtom);

  // memoize option lists based on selections / meta data
  const conversionOptions = useMemo(
    () => buildConversionMetricsDropdown(platformPerformanceMeta, conversionNames),
    [platform, platformPerformanceMeta, conversionNames],
  );

  const attributionWindowOptions = useMemo(
    () => {
      // TODO: clean this up further to move all inside a function so the memo is clean
      const getEventOptionsFromConversion = eventOptionsFromConversionOptions(
        conversionOptions,
        default_attribution_window,
      );
      const conversionEventOption = getEventOptionsFromConversion(platformConversionEvent);

      const revenueOptions = !revenue_formatter ? []
        : conversionEventOption.options
          .filter(option => revenue_formatter(option.value) !== option.value)
          .map(option => revenue_formatter(option.value));

      const options = conversionEventOption.options
        .filter(option => !revenueOptions.includes(option.value))
        .filter(option => !exclude_windows.includes(option.value))
        .map(option => {
          const text = OVERRIDE_WINDOW_NAMES[option.value];
          return { ...option, ...(text ? { text } : {}) };
        });

      return options;
    },
    [platformConversionEvent, conversionOptions],
  );

  const attributionWindow = useMemo(() => {
    const validWindow = attributionWindowOptions.find(c => c.value === _attributionWindow);
    if (validWindow) return _attributionWindow;

    return default_attribution_window;
  }, [_attributionWindow, attributionWindowOptions]);

  useEffect(() => {
    setAttributionWindow(attributionWindow);
  }, [attributionWindow]);

  const updatePlatformConversionEvent = value => {
    setPlatformConversionEvent(value);
  };

  const updateAttributionWindow = setAttributionWindow;

  // calculated values from selections
  const convValue = conversionOptions && valueInOptionsWithDefault(conversionOptions, platformConversionEvent, '');
  const windowValue = valueInOptionsWithDefault(attributionWindowOptions, attributionWindow, '');

  const hasOptionSelected = Boolean(platformConversionEvent) && Boolean(attributionWindow);
  const hasAvailConvMetrics = Boolean(convValue) && Boolean(windowValue);

  return {
    platform, platformConfig,
    defaultMetric, setDefaultMetric,
    optionalMetric, setOptionalMetric,
    conversionOptions,
    convValue, platformConversionEvent, setPlatformConversionEvent, updatePlatformConversionEvent,
    attributionWindowOptions, // setAttributionWindowOptions,
    windowValue, attributionWindow, setAttributionWindow, updateAttributionWindow,
    hasOptionSelected, hasAvailConvMetrics,
    current, dates,
  };
};

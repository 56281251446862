import React from 'react';
import { Header, Button, Container, Image } from 'semantic-ui-react';
import { adAutoParamsImage } from '@rockerbox/styleguide';
import { LightBulbIcon } from '@heroicons/react/outline'; // eslint-disable-line import/no-extraneous-dependencies
import styled from 'styled-components';

export const NoComparisonSetup = ({ onClick, platform }) => ( // eslint-disable-line import/prefer-default-export
  <Container textAlign="center">
    <div style={{ display: 'flex', padding: '60px 0', alignItems: 'center', justifyContent: 'center' }}>
      <div>
        <Image src={adAutoParamsImage} centered style={{ height: '160px' }} />
      </div>
      <div style={{ padding: '0 40px', textAlign: 'left' }}>
        <Header as="h1" style={{ fontWeight: 'normal' }}>
          Conversion Comparisons
        </Header>
        <p style={{ width: '400px', fontSize: '16px' }}>
          Compare how your
          {' '}
          {platform.charAt(0).toUpperCase() + platform.slice(1)}
          {' '}
          data compares to Rockerbox’s.
          Create a comparison between your Rockerbox and
          {' '}
          {platform.charAt(0).toUpperCase() + platform.slice(1)}
          {' '}
          conversions  by clicking the button below.
        </p>
        <Button primary onClick={() => onClick(true)}>Create a comparison...</Button>
      </div>
    </div>
  </Container>
);

const GrayHeader = styled.h2`
  color: rgba(0, 0, 0, 0.5);
  margin-top: 0px;
`;

export const FilterHeader = ({ headerText }) => (
  <div style={{ display: 'flex', alignItems: 'center', padding: '15px 0' }}>
    <LightBulbIcon style={{ width: 30, margin: '-2px 7px 0px 0', fill: '#FFDF78', stroke: '#b2b1ac' }} />
    <GrayHeader>{headerText}</GrayHeader>
  </div>
);

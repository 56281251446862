import React, { createContext, useMemo } from 'react';

import DownloadReportCell from './DownloadReportCell';
import ExpandedData from './ExpandedData'; // eslint-disable-line no-unused-vars
import { ActionCell, NumberCell } from './parts';
import { ExpandableGrid } from '../../../components';
import { TreeTableLoader } from '../../../components/loaders';

export const ParamsContext = createContext([]);
const SummaryTable = ({ updatedEventStatusData, paramsSummary, paramsHourly, tierColorMap, account, loading }) => {
  const cols = [
    {
      display: 'Action',
      key: 'action',
      as: ActionCell(tierColorMap, account),
    },
    {
      display: 'Count of Actions',
      key: 'count',
      as: NumberCell,
    },
    {
      display: 'Unique Actions',
      key: 'unique_count',
      as: NumberCell,
    },
    {
      display: 'Download',
      key: '',
      as: DownloadReportCell,
      sortable: false,
      headerStyle: { width: '350px' },
    },
  ];

  const value = useMemo(() => ({ paramsSummary, paramsHourly }), [paramsSummary, paramsHourly]);

  return (
    loading ? <TreeTableLoader /> : (
      <ParamsContext.Provider value={value}>
        <ExpandableGrid
          data={updatedEventStatusData}
          cols={cols}
          title="Actions"
          idKey="action"
          expandedComponent={ExpandedData}
          sorting
          paginate
        />
      </ParamsContext.Provider>
    )
  );
};

export default SummaryTable;

import React from 'react';
import moment from 'moment';
import { Table } from 'semantic-ui-react';
import { rockerboxPerformanceIcon } from '@rockerbox/styleguide';
import { // eslint-disable-line import/no-extraneous-dependencies
  ShoppingCartIcon,
  ChartSquareBarIcon,
  MapIcon,
  UserAddIcon,
  FilterIcon,
  ChartPieIcon,
  PresentationChartLineIcon,
  TrendingUpIcon,
} from '@heroicons/react/outline';
import * as routes from '../../routes';
import Link from '../../utils/link';
import { BasicViewHeader } from '../../components';
import { buildHash, buildPath } from '../../hooks/urlState';
import { track } from '../../../utils/tracking';

export const routeDisplayMapping = {
  crossChannelReport: {
    display: 'Cross Channel Report',
    path: routes.crossChannelReport,
    icon: <ChartSquareBarIcon style={{ height: 25, margin: 0 }} />,
  },
  conversionsDetail: {
    display: 'Conversions Detail',
    path: routes.conversionsDetail,
    icon: <ShoppingCartIcon style={{ height: 25, margin: 0 }} />,
  },
  conversionFunnel: {
    display: 'Conversions Funnel',
    path: routes.conversionFunnel,
    icon: <FilterIcon style={{ transform: 'rotate(-90deg)', height: 25, margin: 0 }} />,
  },
  funnelPosition: {
    display: 'Funnel Position',
    path: routes.funnelPosition,
    icon: <FilterIcon style={{ height: 25, margin: 0 }} />,
  },
  marketingPaths: {
    display: 'Marketing Paths',
    path: routes.marketingPaths,
    icon: <MapIcon style={{ height: 25, margin: 0 }} />,
  },
  channelOverlap: {
    display: 'Channel Overlap',
    path: routes.channelOverlap,
    icon: <ChartPieIcon style={{ height: 25, margin: 0 }} />,
  },
  newVisitors: {
    display: 'New Visitors',
    path: routes.newVisitors,
    icon: <UserAddIcon style={{ height: 25, margin: 0 }} />,
  },
  spendTrends: {
    display: 'Spend Trends',
    path: routes.spendTrends,
    icon: <PresentationChartLineIcon style={{ height: 25, margin: 0 }} />,
  },
  platformPerformance: {
    display: 'Platform Performance',
    path: routes.platformPerformance,
    icon: <PresentationChartLineIcon style={{ height: 25, margin: 0 }} />,
  },
  rockerboxPerformance: {
    display: 'Rockerbox Performance',
    path: routes.rockerboxPerformance,
    icon: <img src={rockerboxPerformanceIcon} alt="rockerbox navigation icon" style={{ height: 25, margin: 0 }} />,
  },
  platformBenchmarks: {
    display: 'Platform Benchmarks',
    path: routes.platformBenchmarks,
    icon: <TrendingUpIcon style={{ height: 25, margin: 0 }} />,
  },
};

export const MainHeader = () => (
  <BasicViewHeader
    header="Saved Reports"
    subheader=""
  />
);

export const TitleCell = ({ item }) => {
  const { name, route, params, id, user, shared } = item;
  const rebuiltPath = buildPath(route, params.route);
  const rebuiltHash = params.hash ? buildHash(params.hash) : '';
  const fullPath = rebuiltPath + rebuiltHash;

  return (
    <Table.Cell>
      <Link
        to={fullPath}
        onClick={() => {
          track('saved_report.open', { id, name, user, shared, route, params });
        }}
      >
        {name}
      </Link>
    </Table.Cell>
  );
};

export const BasedOnCell = ({ item }) => {
  const { route } = item;
  return (
    <Table.Cell>
      <Link to={routeDisplayMapping[route].path}>
        {routeDisplayMapping[route].display}
      </Link>
    </Table.Cell>
  );
};

export const TimeCell = ({ item }) => {
  const { created_at } = item;
  const dateString = moment(created_at).format('DD-MMM-YYYY');
  return (
    <Table.Cell>
      <p>{dateString}</p>
    </Table.Cell>
  );
};

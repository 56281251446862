// simple helpers
const _sortHighSpendFirst = (a, b) => b.spend_input - a.spend_input;
const _getFirst = arr => arr[0];
const _getLast = arr => arr[arr.length - 1];

export const getCurrentResponseCurve = (responseCurves, channel) => (responseCurves || []).find(row => row.key === channel);

/**
 * Returns the row in the given `values` array with the spend_input value just below the given `goal` value.
 * @param {Array} values - An array of objects representing the response curve for a feature, each with `spend_input` and `marginal_response` properties.
 * @param {number} goal - The goal value for the feature.
 * @returns {Object|null} - The row with the spend_input value just below the goal, or null if no such row exists.
 */
export function getLowerRow(values, goal) {
  const sorted = values.sort(_sortHighSpendFirst);
  const index = sorted.findIndex(row => row.spend_input <= goal);
  return index >= 0 ? sorted[index] : null;
}

/**
 * Returns the row in the given `values` array with the spend_input value just above the given `goal` value.
 * @param {Array} values - An array of objects representing the response curve for a feature, each with `spend_input` and `marginal_response` properties.
 * @param {number} goal - The goal value for the feature.
 * @returns {Object|null} - The row with the spend_input value just above the goal, or null if no such row exists.
 */
export function getUpperRow(values, goal) {
  const sorted = values.sort(_sortHighSpendFirst);
  const len = sorted.filter(row => row.spend_input >= goal).length;
  const index = len > 0 ? len : -1;
  return index >= 0 ? sorted[index - 1] : null;
}

/**
 * Returns the coefficients for a simple linear model based on the given `lower` and `actual` rows.
 * @param {Object} lower - The row with the spend_input value just below the goal.
 * @param {Object} actual - The row with the spend_input value just above the goal.
 * @returns {Object} - An object containing the coefficients for the linear model, with `B0` and `respCoef` properties.
 */
export function getLinearModelCoefficients(lower, actual) {
  const B0 = lower.marginal_response;
  const spendDelta = actual.spend_input - lower.spend_input;
  const respDelta = actual.marginal_response - lower.marginal_response;
  const respCoef = respDelta / spendDelta || 0;
  return { B0, respCoef };
}

/**
 * Returns the estimated response value for the given `goal` value and response curve represented by the `values` array.
 * @param {Array} values - An array of objects representing the response curve for a feature, each with `spend_input` and `marginal_response` properties.
 * @param {number} goal - The goal value for the feature.
 * @returns {number|null} - The estimated response value for the goal, or null if no such value can be calculated.
 */
export function getEstimatedResponseValue(values, goal) {
  if (values.length < 1) return null;
  const sorted = values.sort(_sortHighSpendFirst);
  const max = _getFirst(sorted);
  const min = _getLast(sorted);
  const lower = getLowerRow(sorted, goal) || { spend_input: 0, marginal_response: 0 };
  const upper = goal < min.spend_input ? min : getUpperRow(sorted, goal) || max;

  if (!lower || !upper) {
    return null;
  }
  const { B0, respCoef } = getLinearModelCoefficients(lower, upper);
  const spendDiff = goal - lower.spend_input;
  return B0 + spendDiff * respCoef;
}

/**
 * NOTE: this function has been stubbed out to eventually be replaced by a server-side implementation if necessary.
 * The idea being that all forecast for a set of field values should be sufficient to generate a forcast.
 * We will likely have more data (in the form of a distribution) returned from the server-side implementation.
 *
 *
 * Given an array of response curves and an object of field values, returns an object with estimated response values for each field.
 * If the input array or object is null or empty, or a response curve is missing for a field, the corresponding value will be null.
 * Uses the `getEstimatedResponseValue` helper function for each channel.
 * @param {Array} responseCurves - An array of objects representing response curves for different features, each with a `key` and a `values`
 * array of objects representing the curve, each with `spend_input` and `marginal_response` properties.
 * @param {Object} fieldValues - An object with keys representing field names and values representing goals for each field.
 * @returns {Object} - An object with keys representing field names and values representing estimated response values for each field.
 */
export function forecastData(responseCurves, fieldValues) {
  if (!responseCurves || Object.keys(fieldValues).length === 0) {
    return {};
  }

  return Object.entries(fieldValues).reduce((accu, [key, goal]) => {
    const values = getCurrentResponseCurve(responseCurves, key)?.values;
    if (!values) {
      accu[key] = null;
      return accu;
    }
    const respEst = getEstimatedResponseValue(values, goal.value);

    accu[key] = respEst;
    return accu;
  }, {});
}

/**
 * Calculates the forecasted response values based on a response curve and a set of goal values.
 *
 * @param {Array<number>} responseCurve - The response curve values.
 * @param {Array<number>} toPredict - The goal values to predict the response for.
 * @returns {Array<number>} - The forecasted response values.
 */
export function forecastDataSingleCurve(responseCurve, toPredict) {
  const values = responseCurve;
  return toPredict.reduce((p, goalValue) => {
    const respEst = getEstimatedResponseValue(values, goalValue);
    p.push(respEst);
    return p;
  }, []);
}

/**
 * Calculates the current Return on Advertising Spend (ROAS) for a given channel and spend.
 *
 * @param {Array} responseCurves - The array of response curves.
 * @param {string} channel - The channel for which to calculate the ROAS.
 * @param {number} spend - The amount spent on the channel.
 * @returns {number} The current ROAS for the channel and spend.
 */
export const getCurrentRoas = (responseCurves, channel, spend) => {
  try {
    const { values } = getCurrentResponseCurve(responseCurves, channel);
    const marginal_response = getEstimatedResponseValue(values, spend || 1);
    const roas = marginal_response / (spend || 1);
    return roas;
  } catch (e) {
    // console.log('Error getting current ROAS', channel, e);
  }
};


/**
 * Calculates the predicted Resposne for a given response curve at given spend level
 *
 * @param {Array} responseCurve - The response curve for a channel.
 * @param {number} spend - The amount spent on the channel.
 * @returns {number} The current ROAS for the channel and spend.
 */
export const getRevenueFromResponseCurve = (responseCurve, spend) => {
  try {
    const { values } = { values: responseCurve }
    const marginal_response = getEstimatedResponseValue(values, spend || 1);
    return marginal_response;
  } catch (e) {
    // console.log('Error getting current ROAS', channel, e);
  }
};
import React, { Component } from 'react'
import {
  Segment,
  Loader,
  Header,
  Icon,
  Button,
} from 'semantic-ui-react'
import { GatewayWrap } from '@rockerbox/styleguide'
import { checkJobWithToken } from '../../utils/api'


class LoadingPage extends Component {
  state = {
    loading: true,
    error: false,
  }

  getQueryParam(param) {
    const queryParams = new URLSearchParams(this.props.location.search)
    return queryParams.get(param)
  }

  getJobStatus(token) {
    checkJobWithToken(token)
      .then(resp => {
        if (resp.finished_at > 0) return resp.finish_type
        if (resp.started_at > 0) return "started"
        return "queued"
      })
      .then(jobStatus => {
        if (jobStatus == "success") return this.onSuccess()
        if (jobStatus == "error") return this.onError()

        this.setState({ loading: true })
        setTimeout(() => this.getJobStatus(token), 1000)
      })
      .catch(() => this.onError())
  }

  onSuccess(){
    const successPath = this.getQueryParam("success_url")
    window.location.href = successPath
  }

  onError(){
    this.setState({
      loading: false,
      error: true
    })
  }

  startIntercomChat(){
    const token = this.getQueryParam("token")
    const errorMessage = this.getQueryParam("error_msg")
    const jobId = errorMessage.match(/entry-(\w|-|_)*/)
    const chatMessage = `Help! There was an error setting up my account. Error Code: ${jobId.length ? jobId[0] : token}`
    if (window.Intercom) window.Intercom('showNewMessage', chatMessage)
  }

  componentDidMount() {
    const token = this.getQueryParam("token")
    this.getJobStatus(token)
  }

  render() {
    const { loading, error } = this.state

    const title = this.getQueryParam("title")
    const subtitle = this.getQueryParam("subtitle")
    const errorMessage = this.getQueryParam("error_msg")

    return (
      <GatewayWrap>
        <Segment raised padded="very">
          <div className="m-t-20 m-b-20">
            <Loader
              active={loading}
              size="massive"
              inline="centered"
            />
            {error &&
              <Icon
                circular
                inverted
                color="red"
                name="times"
                size="huge"
              />
            }
          </div>
          <Header as="h1" color="grey" style={{marginBottom: 20}}>
            {error ? "Error" : title || "Loading"}
            {(subtitle || (error && errorMessage)) &&
              <Header.Subheader style={{marginTop: 10, fontSize: "1.6rem"}}>
                {error ? errorMessage : subtitle}
              </Header.Subheader>
            }
          </Header>
          {error && errorMessage &&
            <Button
              primary
              content='Chat with Support'
              icon='comments'
              className='m-t-20'
              onClick={() => this.startIntercomChat()}
            />
          }
        </Segment>
        <div className="white m-t-30">
          Need help?&nbsp;
          <a href="mailto:support@rockerbox.com" className="white underlined">
            Contact us
          </a>
        </div>
      </GatewayWrap>
    )
  }
}


export default LoadingPage

/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

import { LineChart } from '@rockerbox/styleguide';
import { formatXAxisTickLabel } from '@rockerbox/styleguide/components/charts/helpers';
import { Button } from 'semantic-ui-react';

import { CHART_COLOR } from './constants';
import { spendFormatter } from '../../../utils/valueFormatter';

const Verify = ({ onCancel, total, average, minimum, maximum, minDate, maxDate, chartData, channel, finalColumnName, submitAndClose, submitAndReset }) => {
  const numberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  };
  const formatter = (finalColumnName.endsWith('_EF') || finalColumnName.endsWith('_IMP')) ? new Intl.NumberFormat('en-US', numberFormatOptions) : spendFormatter('USD');

  return (
    <div className="mmm-verify">
      <div className="mmm-metrics">
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ color: 'black', fontSize: 14 }}>
              <h4>{`Summary: ${channel}`}</h4>
              {`${minDate} - ${maxDate}`}
            </div>
          </div>
        </div>
        <div>
          <h3>TOTAL</h3>
          {formatter.format(total)}
        </div>
        <div>
          <h3>AVERAGE</h3>
          {formatter.format(average)}
        </div>
        <div>
          <h3>MINIMUM</h3>
          {formatter.format(minimum)}
        </div>
        <div>
          <h3>MAXIMUM</h3>
          {formatter.format(maximum)}
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <LineChart
          {...{
            dottedLineForInfinity: true,
            showAxis: true,
            data: chartData,
            height: 300,
            formatter,
            XAxisFormatter: formatXAxisTickLabel,
            XAxisTickHeight: 15,
            padding: 10,
            tierColorMap: { [finalColumnName]: CHART_COLOR },
          }}
          showTooltip
          showXAxis
          domainDefault={(dataMax => Math.ceil(dataMax))}
        />
      </div>
      <div style={{ float: 'right' }}>
        <Button
          onClick={() => submitAndReset()}
          primary
          style={{ margin: '10 10 10 0' }}
        >
          Save and add another channel
        </Button>
        <Button
          onClick={() => submitAndClose()}
          primary
          style={{ margin: '10 10 10 0' }}
        >
          Review & build your model
        </Button>
        <Button
          onClick={() => onCancel()}
          inverted
          style={{ margin: 10 }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default Verify;

import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import Create from './ManualSetup';
import { BasicViewHeader } from '../../components';
import * as routes from '../../baseRoutes';

const CreatePromoCode = () => (
  <>
    <BasicViewHeader
      headerOnTop={false}
      header="Create Promo Codes"
      subheader={(
        <>
          Marketing Data
          <Icon name="angle right" style={{ margin: 0 }} />
          <Link to={routes.promoCodes}>
            Promo Codes
          </Link>
        </>
      )}
    />
    <br />
    <Create />
  </>
);

export default CreatePromoCode;

import React from 'react'
import { SketchPicker } from 'react-color'

export const colorNames = {
  "green": "#66D9AE",
  "orange": "#FFA278",
  "purple": "#7C78DC",
  "red": "#E76D8E",
  "blue": "#0489c1",
  "yellow": "#FFDF78",
  "dark-green": "#42C493",
  "dark-orange": "#DE794C",
  "dark-purple": "#5B57C8",
  "dark-red": "#C94568",
  "dark-blue": "#03638D",
  "dark-yellow": "#DEBC4C",
  "light-green": "#92EBC9",
  "light-orange": "#FFBC9E",
  "light-purple": "#A3A0EC",
  "light-red": "#F095AD",
  "light-blue": "#1298D2",
  "light-yellow": "#FFE89E",
}

const ColorPicker = ({ color, onChange }) => {
  const [displayColorPicker, setSisplayColorPicker] = React.useState(false)

  const presetColors = Object.keys(colorNames).map(key => colorNames[key])

  const handleClick = () => setSisplayColorPicker(!displayColorPicker)

  const handleClose = () => setSisplayColorPicker(false)

  return (
    <div className='color-picker'>
      <div className='swatch' onClick={handleClick}>
        <div className='color-preview' style={{background: color}} />
      </div>
      {displayColorPicker &&
        <div className='color-picker-popup'>
          <div className='cover' onClick={handleClose}/>
          <SketchPicker
            disableAlpha
            color={color}
            onChange={onChange}
            presetColors={presetColors}
          />
        </div>
      }
    </div>
  )
}

export default ColorPicker

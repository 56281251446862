import React, { useState, useEffect } from 'react';
import { SplitLayout, IndexGrid, ContentCard } from '@rockerbox/styleguide';
import { Button, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { getSurveyConfigs } from '../../../api/attribution';
import { GenericMessage } from '../../../components';

const ManageCell = ({ item }) => (
  <Table.Cell>
    <IndexGrid.EditButton url={`/v3/data/marketing/surveys/edit/${item.id}`} />
  </Table.Cell>
);

const ViewAllSurveys = () => {
  const [surveyData, setSurveyData] = useState([]);

  const getData = () => {
    getSurveyConfigs()
      .then(data => {
        setSurveyData(data);
      });
  };

  const cols = [
    { display: 'Display name', key: 'display_name' },
    { display: 'Conversion Id', key: 'conversion_id' },
    { display: 'Conversion Key', key: 'conversion_key' },
    { display: 'Survey Id', key: 'survey_id' },
    { display: 'Survey Key', key: 'survey_key' },
    { display: 'Survey Response Key', key: 'survey_response_key' },
    { display: 'Manage', as: ManageCell },
  ];

  useEffect(() => {
    getData();
  }, []);

  return (
    <SplitLayout
      leftWidth={13}
      rightWidth={3}
      leftContent={(
        <ContentCard
          hasTable
          title="Survey"
          topRight={(
            <Button
              primary
              compact
              size="mini"
              icon="plus"
              content="Survey"
              as={Link}
              to="/v3/data/marketing/surveys/create"
            />
)}
        >
          <IndexGrid data={surveyData} cols={cols} />
        </ContentCard>
      )}
      rightContent={
        <GenericMessage message="survey_index" />
      }
    />
  );
};

export default ViewAllSurveys;

import React, { useCallback, useEffect } from "react";
import { Button, Message } from "semantic-ui-react";
import {
  datasetAccountingStore,
  syncCardStore
} from "./zustandStore";

const snapshotSelector = state => [
  state.createNewSnapshot,
  state.snapshotJobUrl,
  state.snapshotJobStatus
];
const syncCardSelector = state => [
  state.setCardLoading,
  state.updateCardStatus
];

const SnapshotCreationButton = props => {
  const { name, datasetId, newSnapshot } = props;

  const [
    createNewSnapshot,
    snapshotJobUrl,
    snapshotJobStatus
  ] = datasetAccountingStore(snapshotSelector);
  const [setCardLoading, updateCardStatus] = syncCardStore(syncCardSelector);

  const onCreation = useCallback(
    (e, data) => {
      setCardLoading(true);
      createNewSnapshot(datasetId, newSnapshot);
    },
    [datasetId, newSnapshot]
  );

  useEffect(() => {
    if (snapshotJobStatus === "success") {
      setCardLoading(false);
      updateCardStatus("snapshot_status");
    }
    if (snapshotJobStatus === "error") {
      setCardLoading(false);
    }
  }, [snapshotJobStatus]);

  return (
    <React.Fragment>
      {snapshotJobStatus === "error" && (
        <Message negative>
          <Message.Header>Failed to create snapshot</Message.Header>
          <Message.Content>
            Please contact our service team for more information.
          </Message.Content>
        </Message>
      )}
      <Button.Group style={{ marginRight: "15px" }}>
        <Button primary onClick={onCreation}>
          {name}
        </Button>
      </Button.Group>
    </React.Fragment>
  );
};

export default SnapshotCreationButton;

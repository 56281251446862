import {
  postOutbrainCredentials,
  putOutbrainCredentials,
  postTaboolaCredentials,
  putTaboolaCredentials,
  postCommissionJunctionCredentials,
  postInvocaCredentials,
  putInvocaCredentials,
  postPodsightsCredentials,
  putPodsightsCredentials,
  postTheTradeDeskCredentials,
  putTheTradeDeskCredentials,
} from '../../utils/api';

export default {
  outbrain: {
    fields: [
      { name: 'account_id', displayName: 'Account ID' },
      { name: 'username', displayName: 'Username' },
      { name: 'password', displayName: 'Password' },
    ],
    postFunction: postOutbrainCredentials,
    putFunction: putOutbrainCredentials,
  },
  taboola: {
    fields: [
      { name: 'account_id', displayName: 'Account ID' },
      { name: 'client_id', displayName: 'Client ID' },
      { name: 'client_secret', displayName: 'Client Secret' },
    ],
    postFunction: postTaboolaCredentials,
    putFunction: putTaboolaCredentials,
  },
  commissionjunction: {
    fields: [
      { name: 'account_id', displayName: 'Account ID' },
      { name: 'access_token', displayName: 'Access Token' },
    ],
    postFunction: postCommissionJunctionCredentials,
  },
  shopify: {
    fields: [
      { name: 'shop_name', displayName: 'Shop Name', label: '.myshopify.com' },
    ],
    message: 'shopify_auth',
    postFunction: ({ shop_name }) => {
      const shopifyAuthUrl = `/auth/shopify?shop=${shop_name}.myshopify.com`;
      window.open(shopifyAuthUrl, '_self');
    },
  },
  invoca: {
    fields: [
      { name: 'network_id', displayName: 'Network ID' },
      { name: 'token', displayName: 'API Token' },
    ],
    message: 'invoca_auth',
    postFunction: postInvocaCredentials,
    putFunction: putInvocaCredentials,
  },
  podsights: {
    fields: [
      { name: 'primary_key', displayName: 'Primary Key (Organization ID)' },
      { name: 'token', displayName: 'API Token' },
    ],
    message: 'podsights_auth',
    postFunction: postPodsightsCredentials,
    putFunction: putPodsightsCredentials,
  },
  the_trade_desk: {
    fields: [
      { name: 'partner_id', displayName: 'Partner ID' },
      { name: 'advertiser_id', displayName: 'Advertiser ID' },
      { name: 'login', displayName: 'Login' },
      { name: 'password', displayName: 'Password' },
    ],
    message: 'the_trade_desk_auth',
    postFunction: postTheTradeDeskCredentials,
    putFunction: putTheTradeDeskCredentials,
  },
};

import React from 'react';
import { Icon, Dropdown, Menu } from 'semantic-ui-react';

const DateSelector = ({ timeOptions, startDate, endDate, handleDateChange }) => {
  const defaultText = !!endDate ? `${startDate} - ${endDate}` : startDate
  return (
    <Menu.Item>
      <Icon name="clock outline" style={{marginRight: 5}}/>
        <Dropdown
          className="muted"
          size="mini"
          options={timeOptions}
          value={startDate}
          text={timeOptions.filter(o => o.value == startDate).length ? timeOptions.find(o => o.value == startDate).text : defaultText}
          onChange={handleDateChange}
        />
    </Menu.Item>
  )
}

export default DateSelector;

import React from 'react';

import PropTypes from 'prop-types';
import { Header, Grid, Image, Menu } from 'semantic-ui-react';

import TopRightLinks from './TopRightLinks';

const ViewHeader = ({ image, headerText, menuOptions, active, setOption, intercomTourId, intercomMessage, helpDocLink }) => (
  <Grid>
    <Grid.Row style={{ paddingBottom: 10 }}>
      <Grid.Column width={13} floated="left" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        {!!image
          && (
          <div style={{ marginLeft: 0, marginRight: 15, height: 70, maxWidth: 80 }}>
            <Image src={image} style={{ maxHeight: '100%' }} />
          </div>
          )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Header as="h2" color="purple" style={{ marginTop: 5, marginBottom: 0 }}>
            {headerText}
          </Header>
          {!menuOptions || menuOptions.length === 0 ? [] : (
            <Menu secondary>
              {menuOptions.map(option => (
                <Menu.Item
                  name={option.name}
                  active={active === option.value}
                  onClick={() => setOption(option.value)}
                />
              ))}
            </Menu>
          ) }
        </div>
      </Grid.Column>
      <Grid.Column width={3} textAlign="right">
        { (!!intercomTourId || !!helpDocLink)
          && <TopRightLinks {...{ intercomTourId, helpDocLink, intercomMessage }} />}
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

ViewHeader.propTypes = {
  image: PropTypes.element,
  headerText: PropTypes.element,
  menuOptions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  })),
  active: PropTypes.string,
  setOption: PropTypes.func,
  intercomTourId: PropTypes.number,
  intercomMessage: PropTypes.string,
  helpDocLink: PropTypes.string,
};

export default ViewHeader;

/*
const menuOptions = [
  { name: "Most New Visitors", value: "visitors", },
  { name: "Most Efficient Cost", value: "cpa" },
  { name: "Most Pageviews", value: "engagement", },
]
*/

import React from 'react';
import moment from 'moment';
import { Header } from 'semantic-ui-react';

import * as d3 from 'rockerbox_d3_legacy_clone';
import { formatCurrency } from '../../utils/valueFormatter';
import { yesterday } from '../../utils/time';
import { groupChartData, groupDivideChartData } from '../../utils/groupChartData';

import { blankObject } from './constants';
import { CHANNEL_MAPPING, PLATFORM_MAPPING, STRATEGY_MAPPING } from '../../components/filters/DerivedTiersFilter/constants';

/* eslint-disable no-return-assign, no-param-reassign */

export const generateDateRange = (startDate, endDate) => {
  const dateArray = [];
  const stopDate = endDate ? moment(endDate) : moment(yesterday);
  let currentDate = moment(startDate);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
};

export const filterTiersData = (raw, tier_1, tier_2, tier_3, tier_4, tier_5, limitedTiers = false) => {
  const tiers = limitedTiers ? { tier_1, tier_2, tier_3 } : { tier_1, tier_2, tier_3, tier_4, tier_5 };
  Object.keys(tiers).forEach(t => {
    if (tiers[t] === undefined || !tiers[t].length) delete tiers[t];
  });

  const tierKeysArr = Object.keys(tiers); // [tier_1, tier_2]
  if (tierKeysArr.length === 0) return raw;

  const filtered = raw.filter(data => {
    const found = tierKeysArr.flatMap(tier => {
      const filterTier = tiers[tier];
      const rawDataTierValue = data[tier];
      const splitTiers = typeof (filterTier) === 'string' ? filterTier.split(',') : filterTier;
      return splitTiers.includes(rawDataTierValue) ? data : [];
    });
    return found.length > 0;
  });

  return filtered;
};

export const generateBlankData = (startDate, endDate) => {
  const blankDates = generateDateRange(startDate, endDate)
    .map(date => ({ date, ...blankObject }));
  return blankDates;
};

export const getDailyTiersSummary = (tiersData, conversionKey, revenueKey, startDate, endDate) => {
  const blanks = generateBlankData(startDate, endDate);

  const data = tiersData.map(r => {
    const v = Object.keys(r).reduce((accu, k) => {
      accu[k] = typeof (r[k]) === 'bigint' ? Number(r[k]) : r[k];
      return accu;
    }, {});
    return v;
  });

  const summary = d3.nest()
    .key(g => g.date)
    .rollup(v => ({
      spend: d3.sum(v, d => d.spend),
      even: d3.sum(v, d => d.even),
      first_touch: d3.sum(v, d => d.first_touch),
      last_touch: d3.sum(v, d => d.last_touch),
      normalized: d3.sum(v, d => d.normalized),
      revenue_even: d3.sum(v, d => d.revenue_even),
      revenue_first_touch: d3.sum(v, d => d.revenue_first_touch),
      revenue_last_touch: d3.sum(v, d => d.revenue_last_touch),
      revenue_normalized: d3.sum(v, d => d.revenue_normalized),
      ntf_even: d3.sum(v, d => d.ntf_even),
      ntf_first_touch: d3.sum(v, d => d.ntf_first_touch),
      ntf_last_touch: d3.sum(v, d => d.ntf_last_touch),
      ntf_normalized: d3.sum(v, d => d.ntf_normalized),
      ntf_revenue_even: d3.sum(v, d => d.ntf_revenue_even),
      ntf_revenue_first_touch: d3.sum(v, d => d.ntf_revenue_first_touch),
      ntf_revenue_last_touch: d3.sum(v, d => d.ntf_revenue_last_touch),
      ntf_revenue_normalized: d3.sum(v, d => d.ntf_revenue_normalized),
      repeat_even: d3.sum(v, d => d.repeat_even),
      repeat_first_touch: d3.sum(v, d => d.repeat_first_touch),
      repeat_last_touch: d3.sum(v, d => d.repeat_last_touch),
      repeat_normalized: d3.sum(v, d => d.repeat_normalized),
      repeat_revenue_even: d3.sum(v, d => d.repeat_revenue_even),
      repeat_revenue_first_touch: d3.sum(v, d => d.repeat_revenue_first_touch),
      repeat_revenue_last_touch: d3.sum(v, d => d.repeat_revenue_last_touch),
      repeat_revenue_normalized: d3.sum(v, d => d.repeat_revenue_normalized),
      roas: d3.sum(v, x => x.spend) ? d3.sum(v, x => x[revenueKey]) / d3.sum(v, x => x.spend) : Infinity,
      cpa: d3.sum(v, x => x.spend) ? d3.sum(v, x => x.spend) / d3.sum(v, x => x[conversionKey]) : Infinity,
      rpc: d3.sum(v, x => x.spend) ? d3.sum(v, x => x[revenueKey]) / d3.sum(v, x => x[conversionKey]) : Infinity,
    }))
    .entries([...data, ...blanks])
    .map(d => {
      const o = {
        date: d.key,
      };
      Object.keys(d.values).forEach(k => {
        o[k] = d.values[k];
      });
      return o;
    });

  return summary;
};

export const rollupChartData = (data, metric, startDate, endDate, groupDatesBy = 'daily') => {
  const blanks = generateBlankData(startDate, endDate);
  const d = d3.nest()
    .key(g => g.date)
    .key(g => g.tier_1 || '')
    .rollup(v => d3.sum(v, x => x[metric]))
    .entries([...blanks, ...data])
    .reduce((accu, c) => {
      c.values.forEach(v => {
        accu[c.key] = accu[c.key] || {};
        accu[c.key].date = c.key;
        accu[c.key][v.key] = v.values;
      });
      return accu;
    }, {});

  const s = new Set();
  Object.values(d).map(o => Object.keys(o).map(k => s.add(k)));
  Object.values(d).forEach(o => {
    [...s].filter(k => !Object.keys(o).includes(k)).forEach(missing => o[missing] = 0);
  });

  const values = Object.values(d).sort((p, c) => d3.ascending(p.date, c.date));
  if (groupDatesBy !== 'daily') {
    return groupChartData(values, groupDatesBy);
  }
  return values;
};

export const rollupDivideChartData = (data, numeratorColumn, denominatorColumn, startDate, endDate, groupDatesBy = 'daily', includeTotals = true) => {
  if (groupDatesBy !== 'daily') {
    const numeratorData = rollupChartData(data, numeratorColumn, startDate, endDate);
    const denominatorData = rollupChartData(data, denominatorColumn, startDate, endDate);
    const groupedData = groupDivideChartData(numeratorData, denominatorData, groupDatesBy);
    return groupedData;
  }

  const blanks = generateBlankData(startDate, endDate);
  const d = d3.nest()
    .key(g => g.date)
    .key(g => g.tier_1 || '')
    .rollup(v => (d3.sum(v, z => z[denominatorColumn]) ? d3.sum(v, z => z[numeratorColumn]) / d3.sum(v, z => z[denominatorColumn]) : 0))
    .entries([...blanks, ...data])
    .reduce((accu, c) => {
      c.values.forEach(v => {
        accu[c.key] = accu[c.key] || {};
        accu[c.key].date = c.key;
        accu[c.key][v.key] = v.values;
      });
      return accu;
    }, {});

  const s = new Set();
  Object.values(d).map(o => Object.keys(o).map(k => s.add(k)));
  Object.values(d).forEach(o => {
    [...s].filter(k => !Object.keys(o).includes(k)).forEach(missing => o[missing] = 0);
  });

  if (includeTotals) {
    const blankData = generateBlankData(startDate, endDate);
    const totals = d3.nest()
      .key(g => g.date)
      .rollup(v => (d3.sum(v, z => z[denominatorColumn]) ? d3.sum(v, z => z[numeratorColumn]) / d3.sum(v, z => z[denominatorColumn]) : 0))
      .entries([...blankData, ...data])
      .reduce((accu, c) => {
        accu[c.key] = c.values;
        return accu;
      }, {});
    Object.keys(d).forEach(k => d[k].total = totals[k]);
  }

  const values = Object.values(d).sort((p, c) => d3.ascending(p.date, c.date));
  return values;
};

export const buildGroupsFromRaw = (raw, limitedTiers = false) => {
  const groups = limitedTiers ? ['tier_1', 'tier_2', 'tier_3'] : ['tier_1', 'tier_2', 'tier_3', 'tier_4', 'tier_5'];

  return groups.reduce((accu, c, i, a) => {
    accu[c] = d3.nest()
      .key(x => a.slice(0, i + 1).map(k => x[k]).join('|||'))
      .rollup(v => v.length)
      .entries(raw)
      .map(x => {
        const value = x.key.split('|||').reverse()[0];
        return {
          key: x.key,
          text: value,
          value,
          count: x.values,
          parentTiers: a.slice(0, i),
          parentValues: x.key.split('|||').slice(0, -1),
        };
      })
      .sort((prev, curr) => curr.count - prev.count);

    return accu;
  }, {});
};

export const splitData = ({ dates, dailyPerformance }) => {
  const numTestDays = dates.length > 14 ? 7 : 1;
  const testDays = dates.slice(-numTestDays);
  const baselineDays = dates.slice(0, -numTestDays);

  const baselineObj = dailyPerformance
    .filter(row => baselineDays.includes(row.date))
    .reduce((accu, c) => {
      const keys = Object.keys(c);
      keys.forEach(metric => {
        if (metric === 'date') return;
        accu[metric] = Number(accu[metric] || 0) + Number(c[metric]);
      });
      return accu;
    }, {});

  const testObj = dailyPerformance
    .filter(row => testDays.includes(row.date))
    .reduce((accu, c) => {
      const keys = Object.keys(c);
      keys.forEach(metric => {
        if (metric === 'date') return;
        accu[metric] = (accu[metric] || 0) + c[metric];
      });
      return accu;
    }, {});

  return [testObj, baselineObj, numTestDays, dates.length - numTestDays];
};

export const getChartData = (data, metricColumn, revenueKey, conversionKey, startDate, endDate, groupDatesBy) => {
  if (!data) return [];
  switch (metricColumn) {
    case 'cpa':
      return rollupDivideChartData(data, 'spend', conversionKey, startDate, endDate, groupDatesBy);
    case 'roas':
      return rollupDivideChartData(data, revenueKey, 'spend', startDate, endDate, groupDatesBy);
    case 'rpc':
      return rollupDivideChartData(data, revenueKey, conversionKey, startDate, endDate, groupDatesBy);
    default:
      return rollupChartData(data, metricColumn, startDate, endDate, groupDatesBy);
  }
};

// duplicated in digital advertising helpers
export const formatNumber = (item, number, currencyCode) => {
  const { format, maxDigits } = item;

  if (format === 'currency') return formatCurrency(number, currencyCode, maxDigits || 0);

  const numberFormatOptions = {
    style: format || 'decimal',
    maximumFractionDigits: maxDigits || 0,
  };

  const numberFormatter = new Intl.NumberFormat('en-US', numberFormatOptions);
  const formatted = Number.isNaN(number) ? '-' : typeof number !== 'number' ? numberFormatter.format(0) : numberFormatter.format(number);
  return formatted;
};

export const getCompareDates = (compareRange, startDate, endDate) => {
  let compareStartMoment;
  let compareEndMoment;
  const numDays = moment(endDate).diff(moment(startDate), 'days') + 1;
  switch (compareRange) {
    case 'last_n_days':
      compareStartMoment = moment(startDate).subtract(numDays, 'days');
      compareEndMoment = moment(endDate).subtract(numDays, 'days');
      break;
    case 'wow':
      compareStartMoment = moment(startDate).subtract(7, 'days');
      compareEndMoment = moment(endDate).subtract(7, 'days');
      break;
    case 'mom':
      compareStartMoment = moment(startDate).subtract(31, 'days');
      compareEndMoment = moment(endDate).subtract(31, 'days');
      break;
    case 'qoq':
      compareStartMoment = moment(startDate).subtract(1, 'quarter');
      compareEndMoment = moment(compareStartMoment).add(numDays - 1, 'days');
      break;
    case 'yoy':
      compareEndMoment = moment(endDate).subtract(1, 'year');
      compareStartMoment = moment(compareEndMoment).subtract(numDays - 1, 'days');
      break;
    default:
      compareStartMoment = undefined;
      compareEndMoment = undefined;
  }
  return [compareStartMoment, compareEndMoment];
};

export const generateCompareRanges = (startDate, endDate, firstReportingDate, isCustom, compareStartDate, compareEndDate) => {
  const numDays = moment(endDate).diff(moment(startDate), 'days') + 1;
  const daysTilFirst = moment(startDate).diff(moment(firstReportingDate), 'days');

  const [cStart, cEnd] = getCompareDates('last_n_days', startDate, endDate);
  const customDateString = `${moment(compareStartDate).format('MM/DD/YYYY')} - ${moment(compareEndDate).format('MM/DD/YYYY')}`;
  const maxDaysInAQuarter = 92;

  const COMPARE_OPTIONS = [
    {
      value: 'last_n_days',
      text: `Previous${numDays > 1 ? ` ${numDays}` : ''} day${numDays > 1 ? 's' : ''}`,
      order: 0,
      numDays,
    },
    {
      value: 'wow',
      text: 'Week over week',
      order: 1,
      numDays: 7,
    },
    {
      value: 'mom',
      text: 'Month over month',
      order: 2,
      numDays: 31,
    },
    {
      value: 'qoq',
      text: 'Quarter over quarter',
      order: 3,
      numDays,
    },
    {
      value: 'yoy',
      text: 'Year over year',
      order: 4,
      numDays: 365,
    },
    {
      value: '',
      text: 'No Comparison',
      order: 100,
      numDays: 0,
    },
    {
      value: 'custom',
      text: customDateString,
      order: -1,
      numDays,
    },
  ].map(accu => {
    const [compareStart, compareEnd] = getCompareDates(accu.value, startDate, endDate);
    accu.compareStart = compareStart?.format('YYYY-MM-DD');
    accu.compareEnd = compareEnd?.format('YYYY-MM-DD');
    accu.content = (
      <Header
        className="compare-option"
        content={accu.text}
        subheader={['', 'custom'].includes(accu.value) ? '' : accu.compareStart === accu.compareEnd ? `${accu.compareStart}` : `${accu.compareStart} - ${accu.compareEnd}`}
      />
    );
    return accu;
  }).filter(x => x.numDays < daysTilFirst);

  if (numDays < 7) {
    return isCustom ? COMPARE_OPTIONS : COMPARE_OPTIONS.filter(x => x.value !== 'custom');
  }

  const matchesLastNDays = x => x.value === 'last_n_days' || x.value === 'qoq' || (x.compareStart !== cStart.format('YYYY-MM-DD') || x.compareEnd !== cEnd.format('YYYY-MM-DD'));

  const filteredOptions = COMPARE_OPTIONS
    // we have >= 7 days so we want to remove the wow option
    .filter(x => x.value !== 'wow')
    // if there are more than `maxDaysInAQuarter` days selected, we want to remove the qoq option
    .filter(x => !(numDays > maxDaysInAQuarter && x.value === 'qoq'))
    // we typically don't want two options with identical compareStart and compareEnd values so we filter out the one that is not last_n_days.
    // The exception to this is quarter-over-quarter comparisons where we do include both options
    .filter(matchesLastNDays);

  return isCustom ? filteredOptions : filteredOptions.filter(x => x.value !== 'custom');
};

const getMainAndTrendNumbers = (metric, summary, summaryCompare, conversionKey, revenueKey) => {
  if (!summary || !summaryCompare) return [0, 0];
  let main;
  let compare;
  switch (metric) {
    case 'cpa':
      main = summary.spend / summary[conversionKey];
      compare = summaryCompare.spend / summaryCompare[conversionKey];
      break;
    case 'roas':
      main = summary[revenueKey] / summary.spend;
      compare = summaryCompare[revenueKey] / summaryCompare.spend;
      break;
    case 'rpc':
      main = summary[revenueKey] / summary[conversionKey];
      compare = summaryCompare[revenueKey] / summaryCompare[conversionKey];
      break;
    case 'conversions':
      main = summary?.[conversionKey];
      compare = summaryCompare?.[conversionKey];
      break;
    case 'revenue':
      main = summary[revenueKey];
      compare = summaryCompare[revenueKey];
      break;
    default:
      main = summary[metric];
      compare = summaryCompare[metric];
  }
  const trend = (main - compare) / compare;
  return [main, trend];
};

export const generateCompareTrendCards = ({ filteredSummaryTotals, filteredComparisonSummaryTotals, metrics_group, metricsObj, conversionKey, revenueKey, currencyCode }) => (
  metrics_group.flatMap(metric => {
    const selectedMetricObj = metricsObj[metric];

    if (!selectedMetricObj) return [];

    // CALC MAIN METRIC BY FORMULA
    const { name, display_name, format, trendReversed } = selectedMetricObj;
    const [mainNumber, trend] = getMainAndTrendNumbers(metric, filteredSummaryTotals, filteredComparisonSummaryTotals, conversionKey, revenueKey);

    const isCurrencyOrDecimal = ['currency', 'decimal'].includes(format);
    const isVeryLarge = mainNumber > 100000;
    const isPercent = format === 'percent';
    const isCTR = format === 'ctr';

    const maxDigits = isCurrencyOrDecimal && isVeryLarge ? 0
      : isCurrencyOrDecimal ? 2
        : isPercent ? 4
          : isCTR ? 4 : 0;

    return {
      currency: currencyCode,
      text: display_name,
      value: name,
      mainNumber: Number(mainNumber),
      trendNumber: Number(trend),
      baseline: '',
      format,
      maxDigits,
      trendReversed,
    };
  })
);

export const getAnalysisSearchRegex = (analysisType, analysisSearch, isPlatformStrategySearchValue = false) => {
  /**
   * Generates a regular expression pattern based on the analysis type and search value provided.
   * @param {string} analysisType - The type of analysis to perform. Can be 'channel', 'platform', or 'strategy'.
   * @param {string} analysisSearch - The search value to match in the mapping object.
   * @param {boolean} [isPlatformStrategySearchValue=false] - Optional. Determines whether to the analysisSearch value is the platformStrategySearchValue
   * @returns {string|RegExp} - The generated regular expression pattern based on the analysis type and search value provided.
  */
  if (analysisType === 'tiers') return '';
  const mapping = analysisType === 'channel' ? CHANNEL_MAPPING
    : analysisType === 'platform' ? PLATFORM_MAPPING
      : analysisType === 'strategy' ? STRATEGY_MAPPING
        : null;
  if (!isPlatformStrategySearchValue && !mapping[analysisSearch]) return '';
  if (mapping && mapping[analysisSearch]) {
    const mappingPattern = mapping[analysisSearch].matches.join('|');
    const exclude = Object.keys(mapping)
      .filter(v => v !== analysisSearch)
      .flatMap(value => mapping[value].matches);
    const excludePattern = exclude.join('|');

    return new RegExp(`^(?!.*(${excludePattern})).*(${mappingPattern}).*$`, 'i');
  }
  return analysisSearch;
};

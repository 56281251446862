import React from "react";
import { StatCard } from "@rockerbox/styleguide";
import { Grid } from "semantic-ui-react";

const Summary = props => {
  const { activeView, onClick, value, label, summaryType, width } = props;

  return (
    <Grid.Column style={{ width: width }}>
      <StatCard
        segmentColor={activeView == summaryType && "blue"}
        onClick={() => onClick(summaryType)}
        value={value}
        label={label}
        size="small"
      />
    </Grid.Column>
  );
};

const SummarySelector = ({ onClick, activeView, datasetList, loading }) => {
  if (loading) {
    return null;
  }
  const childProps = datasetList.map(item => {
    return {
      value: item.value,
      label: item.label,
      onClick: onClick,
      activeView: activeView,
      summaryType: item.type,
      width: `${Math.floor(100 / datasetList.length)}%`
    };
  });
  return (
    <Grid fluid="true">
      <Grid.Row stretched>
        {childProps.map((config, i) => (
          <Summary key={i} {...config} />
        ))}
      </Grid.Row>
    </Grid>
  );
};

export default SummarySelector;

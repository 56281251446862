import * as d3 from 'rockerbox_d3_legacy_clone';

export const buildGroupsFromRaw = (raw, hierarchyArr) => {
  const groups = hierarchyArr.map(c => c.value);
  return groups.reduce((accu, c, i, a) => {
    accu[c] = d3.nest()
      .key(x => a.slice(0, i + 1).map(k => x[k]).join('|||'))
      .rollup(v => v.length)
      .entries(raw)
      .map(x => {
        const value = x.key.split('|||').reverse()[0];
        return {
          key: x.key,
          text: value,
          value,
          count: x.values,
          parentTiers: a.slice(0, i),
          parentValues: x.key.split('|||').slice(0, -1),
        };
      })
      .sort((p, curr) => curr.count - p.count)
      .filter(curr => curr.key);
    return accu;
  }, {});
};

export const resetTiers = (val, tierFuncs, index) => {
  if (val === '') {
    const neededFuncs = tierFuncs.slice(index, tierFuncs.length);
    neededFuncs.map(c => c([]));
  }
};

export const buildHierarchy = obj => {
  const hierarchy = obj.reduce((accu, c, i) => {
    accu[c.value] = `tier_${i + 1}`;
    return accu;
  }, {});
  return hierarchy;
};

export const createTierOptions = (key, tiers, selectedTiers, hierarchyObj) => {
  const options = tiers[key].filter(x => x.parentValues.reduce((p, v, i) => {
    const t = x.parentTiers[i];
    return (selectedTiers[hierarchyObj[t]].indexOf(v) > -1) ? p + 1 : p;
  }, 0) === x.parentValues.length);
  return options;
};

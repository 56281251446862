import React, { useState, useEffect } from 'react';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

const noop = () => {};

const DateRangeByType = ({
  rangeType = 'week', onChange = noop,
  startDateOffset = day => day.startOf(rangeType), endDateOffset = day => day.endOf(rangeType),
}) => {
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [focusedInput, setFocusedInput] = useState(true);

  const onFocusChange = input => {
    setFocusedInput(!input ? startDate : input);
  };

  const onDatesChange = dates => {
    setStartDate(dates.startDate);
    setEndDate(dates.endDate);
  };

  useEffect(() => {
    onChange(false, { startDate, endDate });
  }, [startDate, endDate]);

  return (
    <DayPickerRangeController
      {...{
        focusedInput,
        autoFocusEndDate: false,
        startDate,
        endDate,
        onFocusChange,
        onDatesChange,
        startDateOffset,
        endDateOffset,
        hideKeyboardShortcutsPanel: true,
      }}
    />
  );
};

export default DateRangeByType;

import React, { useEffect } from "react";
import GCBucketForm from "./GCPBucketForm";
import SnowflakeForm from "./SnowflakeForm";
import RedshiftInit from "./RedshiftInit";
import RedshiftScript from "./RedshiftScript";
import WarehouseMain from "./WarehouseMain";
import {
  LoadingCard,
  SetupFailureCard
} from "./SetupInstructionCard";
import { warehouseStore, statusStore } from "./zustandStore";
import { STATUS_UNSET, STATUS_FINISHED, STATUS_INCOMPLETE } from "./constants";
import CacheContext from "../../utils/CacheContext.js";
import { getSegments } from "../../utils/api";
import { connect } from "react-redux";

const platformMap = {
  gcp_finished: WarehouseMain,
  gcp_pending: LoadingCard,
  gcp_unset: GCBucketForm,
  snowflake_finished: WarehouseMain,
  snowflake_pending: LoadingCard,
  snowflake_unset: SnowflakeForm,
  snowflake_failed: SetupFailureCard,
  aws_pending: LoadingCard,
  aws_unset: RedshiftInit,
  aws_finished: WarehouseMain,
  aws_incomplete: RedshiftScript,
  aws_failed: SetupFailureCard
};

const DataWarehouse = props => {
  const [
    fetchWarehouse,
    warehouses,
    setActiveWarehouseId
  ] = warehouseStore(state => [
    state.fetchWarehouse,
    state.warehouses,
    state.setActiveWarehouseId
  ]);
  const [status, setStatus] = statusStore(state => [
    state.status,
    state.setStatus
  ]);
  const { username, platform } = props;
  const [state, setState, Context] = React.useContext(CacheContext);
  const segments = Context.getCache("segments", getSegments) || [];
  const segment =
    segments.length > 0
      ? segments.filter(item => item.featured === 1)[0]
      : null;

  useEffect(() => {
    if (warehouses && warehouses.length) return;

    fetchWarehouse().then(warehouses => {
      const activeWarehouseId = warehouses.some(
        warehouse => warehouse.platform === platform
      )
        ? warehouses.filter(warehouse => warehouse.platform === platform)[0][
            "id"
          ]
        : null;
      setActiveWarehouseId(activeWarehouseId);
    });
  }, []);

  useEffect(() => {
    if (!warehouses) {
      return;
    }
    const currentWarehouse = warehouses.filter(
      item => item.platform === platform
    );
    const status =
      platform !== "aws"
        ? currentWarehouse.length > 0
          ? STATUS_FINISHED
          : STATUS_UNSET
        : currentWarehouse.length > 0
        ? currentWarehouse[0] && currentWarehouse[0]["bucket"]["cluster_name"]
          ? STATUS_FINISHED
          : STATUS_INCOMPLETE
        : STATUS_UNSET;
    const activeWarehouseId =
      currentWarehouse.length > 0 ? currentWarehouse[0]["id"] : null;
    setStatus(status);
    setActiveWarehouseId(activeWarehouseId);
  }, [platform, warehouses]);

  const ContentComponent = platformMap[`${platform}_${status}`]
    ? platformMap[`${platform}_${status}`]
    : () => null;

  return username ? (
    <ContentComponent
      username={username}
      segment={segment}
      platform={platform}
    />
  ) : null;
};

function mapStateToProps(state, props) {
  const { username } = state.app;

  return { username };
}

export default connect(mapStateToProps)(DataWarehouse);

import React from 'react';
import { formatXAxisTickLabel } from '@rockerbox/styleguide/components/charts/helpers';

import { ComposedChart, XAxis, YAxis, Line, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import { currentPeriodLinePurple, previousPeriodLinePurple } from '@rockerbox/styleguide';

import CompareChartTooltip from './CompareChartTooltip';

const solidColor = '#ad5dc6';
const dashedColor = '#ad5dc6';

const DualLineChart = ({ data, formatter, tierColorMap, height = undefined, startDate, endDate, compareStart, compareEnd, selectedMetric }) => {
  const key = 'date';
  const compareKey = 'compare_date';

  const toolTipValueFormatter = formatter?.format ? formatter.format : formatter || (v => v);

  if (data.length < 1) return null;

  const findMaxTotal = totalData => totalData.reduce((maxTotal, item) => {
    const total = parseFloat(item.total);
    const totalCompare = parseFloat(item.total_compare);
    return Math.max(maxTotal, total, totalCompare);
  }, 0);

  const maxTotal = findMaxTotal(data);

  const renderLegend = (dateRangeStartDate, dateRangeEndDate) => (
    <div>
      <span style={{ fontSize: '14px' }}>
        <img id="currentPeriodBars" src={currentPeriodLinePurple} alt="current-period-legend" style={{ marginRight: '5px' }} />
        {`${dateRangeStartDate} - ${dateRangeEndDate}`}
        <img id="previousPeriodLine" src={previousPeriodLinePurple} alt="previous-period-legend" style={{ margin: '0 5px 0 10px' }} />
        {`${compareStart} - ${compareEnd}`}
      </span>
    </div>
  );

  return (
    <div className="dualline-chart-container">
      <ResponsiveContainer width="100%" height={height || 500}>
        <ComposedChart width="100%" height={height || 250} data={data} barCategoryGap={30} barGap={5}>
          <XAxis
            dataKey={key}
            xAxisId="bar"
            tickFormatter={(date, i) => formatXAxisTickLabel(date, i, data)}
            tickLine={false}
            padding={{ left: 15 }}
          />
          <XAxis
            hide
            dataKey={compareKey}
            xAxisId="line"
            axisLine={false}
            tickLine={false}
            padding={{ left: 15 }}
          />

          <YAxis
            hide
            yAxisId="bar"
            type="number"
            domain={[0, maxTotal]}
            padding={{ top: 45, left: 25 }}
          />
          <YAxis
            hide
            yAxisId="line"
            type="number"
            domain={[0, maxTotal]}
            padding={{ top: 45, left: 25 }}
          />

          <Tooltip
            formatter={toolTipValueFormatter}
            content={<CompareChartTooltip tierColorMap={tierColorMap} selectedMetric={selectedMetric} />}
          />
          <Legend
            align="left"
            verticalAlign="top"
            iconSize={26}
            content={renderLegend(startDate, endDate)}
          />

          <Line
            isAnimationActive={false}
            dataKey="total"
            type="linear"
            stroke={solidColor}
            dot={false}
            strokeDasharray=""
            strokeWidth={3}
            yAxisId="line"
            xAxisId="line"
          />

          <Line
            isAnimationActive={false}
            dataKey="total_compare"
            type="linear"
            stroke={dashedColor}
            dot={false}
            strokeDasharray="6 3"
            strokeWidth={3}
            yAxisId="line"
            xAxisId="line"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DualLineChart;

import React from 'react';
import { SplitLayout } from '@rockerbox/styleguide';
import { Image, Icon, Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { useGlobalState } from '../hooks/global';
import { productLinesToUpsellMapping } from '../constants/mappings';
import { track } from '../../utils/tracking';

const H1Styled = styled.h1`
  margin-bottom: 60px;
  font-size: 36px;
  font-weight: bold !important;
  text-align: center;
`;
const SubHeadingStyled = styled.p`
  font-size: 24px;
  margin-bottom: 28px;
`;
const ContentStyled = styled.p`
  font-size: 16px;
`;
const H2Styled = styled.h2`
  margin-top: 100px !important;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: bold !important;
  text-align: center;
`;
const SubHeadingHighlightStyled = styled.p`
  font-size: 24px;
  margin-bottom: 10px;
`;
const PStyled = styled.p`
  font-size: 24px;
  text-align: center;
`;
const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
`;

const LogoContainer = styled.div`
display: flex; 
justify-content: space-between;
align-items: center;
margin-bottom: 30px;
`;

const HeadingLayout = ({ buttonDisplayText, purpose, productLineDisplay, onClick }) => {
  const { title, contentTitle, content, image } = purpose;
  return (
    <>
      <H1Styled>{`${title} Rockerbox ${productLineDisplay}`}</H1Styled>
      <SplitLayout
        leftWidth={8}
        leftContent={(
          <div>
            <SubHeadingStyled>{contentTitle}</SubHeadingStyled>
            <ContentStyled>{content}</ContentStyled>
            <Button
              content={(
                <>
                  {buttonDisplayText}
                  <Icon name="arrow circle right" />
                </>
              )}
              onClick={onClick}
              primary
              size="large"
              style={{ marginTop: 20 }}
            />
          </div>
        )}
        rightWidth={8}
        rightContent={
          <Image src={image} style={{ maxHeight: 300, width: 'auto', margin: 'auto' }} />
        }
      />
    </>
  );
};

const HighlightsLayout = ({ highlights }) => {
  const { title, content } = highlights;
  return (
    <>
      <H2Styled>{title}</H2Styled>
      <div style={{ display: 'flex' }}>
        {content.map(({ heading, subheading, image }) => (
          <div style={{ flex: 1, padding: '0 30px' }}>
            <img
              src={image}
              style={{ height: '300px', width: '100%', objectFit: 'cover' }}
              alt=""
            />
            <div style={{ padding: '20px 0', textAlign: 'center' }}>
              <SubHeadingHighlightStyled>{heading}</SubHeadingHighlightStyled>
              <ContentStyled>{subheading}</ContentStyled>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const ActionLayout = ({ action }) => {
  const { title, description, logosOfPlatforms, productLine } = action;
  const { integrations } = useGlobalState();
  const integrationsLists = {
    collect: ['facebook', 'adwords', 'pinterest', 'snapchat', 'tiktok', 'facebook', 'adwords', 'pinterest'],
    export: ['snowflake', 'aws', 'gcp'],
    forecast: ['shopify', 'adwords', 'facebook', 'tiktok', 'snapchat', 'pinterest', 'bing', 'linkedin'],
  };
  const integrationList = integrationsLists[productLine] || [];

  const integrationImages = integrationList.map(platform => {
    const integration = integrations.find(x => x.platform === platform);
    const suffix = productLine === 'collect' || productLine === 'forecast' ? 'icon_img' : 'logo_img';
    return integration ? `https://images.rockerbox.com/integrations/${integration[suffix]}` : '';
  });

  return (
    <>
      <H2Styled>{title}</H2Styled>
      {productLine && (
      <LogoContainer>
        {logosOfPlatforms && integrationImages.map(imageSrc => (
          <div>
            <img src={imageSrc} style={{ height: 80 }} alt="" />
          </div>
        ))}
      </LogoContainer>
      )}
      <PStyled>{description}</PStyled>
    </>
  );
};

const Upsell = ({ productLine }) => {
  const { productLineDisplay, trackItem, buttonText, chatMessage, purpose, highlights, action } = productLinesToUpsellMapping[productLine];

  const onClick = () => {
    track(trackItem);
    if (window.Intercom) window.Intercom('showNewMessage', chatMessage);
  };
  const buttonDisplayText = buttonText || `Contact Sales to get access to ${productLineDisplay}`;

  return (
    <div style={{ padding: '50px 100px' }}>
      <HeadingLayout {...{ buttonDisplayText, purpose, productLineDisplay, onClick }} />
      <HighlightsLayout {...{ highlights }} />
      <ActionLayout {...{ action }} />
      <CenteredDiv>
        <SubHeadingHighlightStyled>{`Get started with Rockerbox ${productLineDisplay} today`}</SubHeadingHighlightStyled>
        <Button
          content={(
            <>
              {buttonDisplayText}
              <Icon name="arrow circle right" />
            </>
          )}
          onClick={onClick}
          primary
          size="large"
          style={{ marginTop: 20 }}
        />
      </CenteredDiv>
    </div>
  );
};

export default Upsell;

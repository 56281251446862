import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { checkUrlParamValue } from '../../utils/dataValidation';
import { useURLStateAndSetDefault } from '../../hooks/urlState';
import { yesterday, monthAgo } from '../../utils/time';
import { customerTypeAtom, modelAtom, segIdAtom } from '../../atoms';

export const usePlatform = () => {
  const [platformName, setPlatform] = useURLStateAndSetDefault('platformName', '0', true);
  const platform = checkUrlParamValue(platformName) ? platformName : '';
  return {
    platform, platformName, setPlatform,
  };
};

export const useParamsWithDefaults = () => {
  const { view, startDate, endDate } = useParams();
  const [segId] = useAtom(segIdAtom);

  const { platform, platformName } = usePlatform();

  return {
    startDate,
    endDate,
    platform,
    platformName,
    segId,
    view,
  };
};

export const useUrlStateWithDefaults = ({ defaultView, defaultModel }) => {
  const { platform, platformName, setPlatform } = usePlatform();
  const [segId, setSegId] = useAtom(segIdAtom);
  const [_model, setModel] = useAtom(modelAtom);
  const [ntfFilter, setNtfFilter] = useAtom(customerTypeAtom);
  const [startDate, setStartDate] = useURLStateAndSetDefault('startDate', monthAgo, true);
  const [endDate, setEndDate] = useURLStateAndSetDefault('endDate', yesterday, true);

  const model = useMemo(() => {
    if (_model === '') {
      return defaultModel;
    }
    return _model;
  }, [segId, _model, defaultModel]);

  useEffect(() => {
    setModel(model);
  }, [model]);

  const [activeMenuItem, setActiveMenuItem] = useURLStateAndSetDefault('view', defaultView, true);

  return {
    platform, platformName, setPlatform,
    startDate, setStartDate,
    endDate, setEndDate,
    segId, setSegId,
    model, setModel,
    ntfFilter, setNtfFilter,
    view: activeMenuItem, activeMenuItem, setActiveMenuItem,
  };
};

import React, { Component } from "react";
import { postNewOutbox } from "../../utils/api";
import Container from "../parts/Container";
import { Button, Modal, Form, Message } from "semantic-ui-react";
import { CloseButtonStyles } from "../styled";

class NewOutboxModal extends Component {
  constructor(props) {
    super(props);
    const allFields = [...props.fields, "outboxName"];
    this.state = allFields.reduce((all, current) => {
      if (current.name) {
        all[current.name] = current.default;
      }
      return all;
    }, {});
  }

  onFieldChange = fieldName => (evt, data) => {
    this.setState({ [fieldName]: data.value });
  };

  onSubmit = () => {
    const { outboxName } = this.state;
    const { onLoadingChange, onErrorChange } = this.props;

    const obj = {
      name: outboxName,
      outbox_type: this.props.outbox_type,
      config: this.props.fields.reduce((all, current) => {
        all[current.name] = this.state[current.name];
        return all;
      }, {})
    };

    onLoadingChange(true);
    postNewOutbox(obj)
      .then(() => this.props.setOutboxes())
      .then(() => this.props.close())
      .catch(err => {
        onLoadingChange(false);
        onErrorChange(true);
      });
  };

  validate = () => {
    const { outbox_type } = this.props;
    const { username } = this.state;
    const usernameSpaceError =
      outbox_type === "sftp" ? username && username.indexOf(" ") === -1 : true;
    const prefixError =
      outbox_type !== "sftp"
        ? this.state["bucket_name"] &&
          this.state["bucket_name"].indexOf("://") === -1
        : true;
    return (
      usernameSpaceError &&
      prefixError &&
      Object.keys(this.state).every(val => this.state[val])
    );
  };

  render() {
    const { fields, loading, error } = this.props;
    const { outboxName } = this.state;
    const formFields = fields.map(field => {
      const usernameSpaceError =
        field.name === "username" &&
        this.state[field.name] &&
        this.state[field.name].indexOf(" ") !== -1
          ? {
              content:
                "Spaces are not allowed for usernames. Please remove all spaces to continue.",
              pointing: "below"
            }
          : false;
      const prefixError =
        field.name === "bucket_name" &&
        this.state[field.name] &&
        this.state[field.name].indexOf("://") !== -1
          ? {
              content: `Please remove platform prefix(${(this.state[
                field.name
              ].split("://")[0] || "") + "://"}) from bucket name`,
              pointing: "below"
            }
          : false;
      return (
        <Form.Group key={field}>
          <Form.Field>
            <label>{field.label}</label>
            <Form.Input
              error={usernameSpaceError || prefixError}
              value={this.state[field.name]}
              onChange={this.onFieldChange(field.name)}
            />
          </Form.Field>
        </Form.Group>
      );
    });

    return (
      <Container>
        <Form onSubmit={() => this.onSubmit()} loading={loading} error={error}>
          <Message
            error
            header="Failed to create new outbox"
            content="Please sure all fields are filled properly or contact us for help."
          />
          <Form.Group>
            <Form.Field>
              <label>Name</label>
              <Form.Input
                value={outboxName}
                onChange={this.onFieldChange("outboxName")}
              />
            </Form.Field>
          </Form.Group>
          {formFields}
          <Button type="submit" disabled={!this.validate()}>
            Add Delivery Option
          </Button>
        </Form>
      </Container>
    );
  }
}

export default props => (
  <Modal
    size="small"
    open={props.open}
    onClose={props.close}
    closeOnDimmerClick={false}>
    <Modal.Header>
      <CloseButtonStyles onClick={props.close}>x</CloseButtonStyles>
      {props.title}
    </Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <NewOutboxModal done={props.close} {...props} />
      </Modal.Description>
    </Modal.Content>
  </Modal>
);

import { useGlobalState } from '../../../hooks/global';
import { segmentOptions } from '../../../utils/options';
import { getTiersAsOptions } from '../../../api/attributionCache';

const useOptions = segment_id => {
  const { account, segments, Context } = useGlobalState();
  const segmentOptionsDropdown = segmentOptions(segments);

  const tiersOptions = segment_id ? (Context.getCache(`tiers${segment_id}`, getTiersAsOptions, segment_id) || false) : false;

  return {
    account,
    segments,
    segmentOptionsDropdown,
    tiersOptions,
  };
};

export default useOptions;

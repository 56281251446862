import moment from 'moment';

import { yesterday, today, weekAgo, twoWeeksAgo } from '../../utils/time';

export const getStartAndEndDate = relativeDate => {
  const dateMap = {
    today: {
      startDate: today,
      endDate: today,
    },
    yesterday: {
      startDate: yesterday,
      endDate: yesterday,
    },
    last7: {
      startDate: weekAgo,
      endDate: today,
    },
    last14: {
      startDate: twoWeeksAgo,
      endDate: today,
    },
  };

  return dateMap[relativeDate];
};

export const generateTicks = (tickItem, i, renderData) => {
  if (!moment(tickItem, moment.ISO_8601, true).isValid()) return; // sometimes recharts doesn't give back datestring

  const hasHours = tickItem.split(' ').length > 1;
  const numItems = renderData.length;
  const time = moment(tickItem);
  const hour = time.hour();
  const date = time.format('MMM DD');

  if (hasHours && (numItems <= 24)) {
    return time.format('ha');
  }

  return (hour === 0) ? date : '';
};

export const sumByDate = data => {
  const result = data.reduce((accu, curr) => {
    const date = curr.date.split(' ')[0];
    if (!accu[date]) {
      accu[date] = { date };
    }
    Object.keys(curr).forEach(key => {
      if (key !== 'date') {
        if (!accu[date][key]) {
          accu[date][key] = 0;
        }
        accu[date][key] += curr[key];
      }
    });
    return accu;
  }, {});

  return Object.values(result);
};

export const filterObjectBySelectedKeys = (obj, selectedKeysSet) => Object.fromEntries(
  Object.entries(obj).filter(([key]) => selectedKeysSet.has(key)),
);

export const filterArrayBySelectedKeys = (arr, selectedKeysSet) => arr.filter(obj => [...selectedKeysSet].some(item => Object.values(obj).includes(item)));

import React from 'react'
import { Header, Button, Loader } from 'semantic-ui-react'

const StepView = ({ title, subtitle, children, setNextStep, disableNext, loadingNext }) => (
  <>
    <div className='main-content-wrap'>
      <div className='main-content'>
        <Header as='h2' content={title} subheader={subtitle} />
        {children}
      </div>
    </div>
    <div className='main-footer'>
      <Button primary size='medium' content='Next' icon='right arrow' labelPosition='right'
        disabled={disableNext || loadingNext} loading={loadingNext} onClick={setNextStep} />
    </div>
  </>
)

export default StepView


export const StepLoader = () => (
  <div className='flex-centered'>
    <Loader active />
  </div>
)

export const getSteps = (tier) => {
  let steps = [{ key: "survey", title: "Create Profile", icon: "user" },
  { key: "platforms", title: "Connect Advertising", icon: "paper plane" },
  { key: "tracking", title: "Tracking Parameters", icon: "tags" },
  { key: "cname", title: "First-Party Domain", icon: "globe" },
  { key: "invite", title: "Invite Team", icon: "envelope open outline" },]
  if(tier === 'starter'){
    steps.splice(1, 0, { key: "store", title: "Connect Store", icon: "shop" })
    steps.splice(6, 0, { key: "payment", title: "Billing", icon: "payment" })
  }
  return steps
}

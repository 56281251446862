import React, { useMemo } from 'react';

import { // eslint-disable-line import/no-extraneous-dependencies
  UserAddIcon, FilterIcon,
} from '@heroicons/react/outline'; // eslint-disable-line import/no-extraneous-dependencies

import { NAV_MENU } from './constants';
import * as routes from '../../baseRoutes';
import { useRBACAdvertiser, useRBACPermissions } from '../../hooks/useRBAC';

export const CONVERSION_FUNNEL_ADVERTISERS = ['brookdale', 'moscot', 'wildgrain', 'burton', 'avocadogreenmattress'];

const NEW_VISITORS = {
  name: 'New Visitors',
  description: 'Identify the top-of-funnel marketing driving net-new visitors to your website',
  icon: <UserAddIcon />,
  route: routes.newVisitors,
};

const CONVERSION_FUNNEL = {
  name: 'Conversion Funnel',
  description: 'Gain insight into your marketing performance at different phases of the conversion funnel',
  icon: <FilterIcon style={{ transform: 'rotate(-90deg)' }} />,
  route: routes.conversionFunnel,
  lockedTiers: ['lite'],
};

const updateNavItemChildWithPermissions = (child, authorizedPermissions) => {
  if (!child.rbacPermission) return child;
  const isAuthorized = authorizedPermissions.includes(child.rbacPermission);
  return isAuthorized ? child : { ...child, unauthorized: true };
};

export const useNavMenu = account => {
  const { advertiserExistsRBAC } = useRBACAdvertiser();
  const { authorizedPermissions } = useRBACPermissions();

  const navMenu = useMemo(() => {
    // NEW VISITORS
    if (account?.features?.includes('new_visitors')) {
      const idx = NAV_MENU.findIndex(x => x.name === 'Journeys');
      NAV_MENU[idx].children.push(NEW_VISITORS);  // insert at end
    }

    // CONVERSION FUNNEL
    if (CONVERSION_FUNNEL_ADVERTISERS.includes(account?.pixel_source_name)) {
      const idx = NAV_MENU.findIndex(x => x.name === 'Journeys');
      NAV_MENU[idx].children.splice(2, 0, CONVERSION_FUNNEL);  // insert after Funnael Position
    }
    return NAV_MENU;
  }, [account]);

  const navMenuWithPermissions = useMemo(() => {
    if (!advertiserExistsRBAC) return navMenu;

    return navMenu.map(menu => {
      if (menu.multiSection) {
        const children = menu.children.map(section => {
          const sectionChildren = section.children.map(child => (updateNavItemChildWithPermissions(child, authorizedPermissions)));
          return { ...section, children: sectionChildren };
        });
        return { ...menu, children };
      }
      const children = menu.children.map(child => updateNavItemChildWithPermissions(child, authorizedPermissions));
      return { ...menu, children };
    });
  }, [navMenu, advertiserExistsRBAC, authorizedPermissions]);

  return navMenuWithPermissions;
};

import React, { useState, useEffect, useMemo } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { DEFAULT_TIERS_ARR } from '../../../constants/tiers';
import { useTiers } from './hooks';
import { buildGroupsFromRaw, resetTiers, buildHierarchy, createTierOptions } from './helpers';

const TiersRollupForm = ({ rawData, hierarchy = DEFAULT_TIERS_ARR, rollupHeadingLabel }) => {
  const {
    tier_1, set_tier_1,
    tier_2, set_tier_2,
    tier_3, set_tier_3,
    tier_4, set_tier_4,
    tier_5, set_tier_5,
    clearFilters,
  } = useTiers();

  const [tierOptions, setTierOptions] = useState({});

  const selectedTiers = { tier_1, tier_2, tier_3, tier_4, tier_5 };
  const hierarchyObj = buildHierarchy(hierarchy);
  const tiers = useMemo(() => (rawData ? buildGroupsFromRaw(rawData, hierarchy) : []), [rawData, hierarchy]);
  const tierKeys = Object.keys(tiers);

  const tierLength = tierKeys.reduce((accu, k) => {
    accu[k] = (selectedTiers[hierarchyObj[k]] || []).length;
    return accu;
  }, {});

  const selectTier = tier => obj => {
    const { value } = obj;
    const param_vals = value;
    const chosenTier = hierarchyObj[tier];
    const tierFuncs = [set_tier_2, set_tier_3, set_tier_4, set_tier_5];

    switch (chosenTier) {
      case 'tier_1':
        set_tier_1(param_vals);
        resetTiers(param_vals, tierFuncs, 0);
        break;
      case 'tier_2':
        set_tier_2(param_vals);
        resetTiers(param_vals, tierFuncs, 1);
        break;
      case 'tier_3':
        set_tier_3(param_vals);
        resetTiers(param_vals, tierFuncs, 2);
        break;
      case 'tier_4':
        set_tier_4(param_vals);
        resetTiers(param_vals, tierFuncs, 3);
        break;
      case 'tier_5':
        set_tier_5(param_vals);
        break;
      default:
    }
  };

  const checkOptionsToSelected = (list, selected, tier) => {
    const verifySelected = selected.filter(x => list.find(item => item.value === x));
    if (verifySelected.length === selected.length) return;
    const chosenTier = hierarchyObj[tier];
    switch (chosenTier) {
      case 'tier_1':
        set_tier_1(verifySelected);
        break;
      case 'tier_2':
        set_tier_2(verifySelected);
        break;
      case 'tier_3':
        set_tier_3(verifySelected);
        break;
      case 'tier_4':
        set_tier_4(verifySelected);
        break;
      case 'tier_5':
        set_tier_5(verifySelected);
        break;
      default:
    }
  };

  useEffect(() => {
    const optionsList = tierKeys.reduce((accu, key) => {
      accu[key] = createTierOptions(key, tiers, selectedTiers, hierarchyObj);
      return accu;
    }, {});

    const firstTierKey = tierKeys[0];
    if (!optionsList[firstTierKey]?.length) return;
    tierKeys.forEach(key => {
      const chosenTier = hierarchyObj[key];
      checkOptionsToSelected(optionsList[key], selectedTiers[chosenTier], key);
    });

    setTierOptions(optionsList);
  }, [tiers, tier_1, tier_2, tier_3, tier_4, tier_5]);

  return (
    <Form>
      <Form.Field>
        {rollupHeadingLabel && <label>{rollupHeadingLabel}</label>}
        {tierKeys.map((key, i) => {
          if (i !== 0 && tierLength[tierKeys[i - 1]] === 0) return null;
          return (
            <Form.Select
              search
              multiple
              placeholder={`${(hierarchy.find(c => c.value === key) || {}).text} contains`}
              value={selectedTiers[hierarchyObj[key]] || []}
              onChange={(_, x) => selectTier(key)(x)}
              options={tierOptions[key]}
            />
          );
        })}
      </Form.Field>
      {tier_1 && (
      <Form.Field style={{ minHeight: '30px', display: 'flex', justifyContent: 'flex-end' }}>
        <Button style={{ cursor: 'pointer' }} onClick={() => clearFilters()}>
          Clear All Filters
        </Button>
      </Form.Field>
      )}
    </Form>
  );
};

export default TiersRollupForm;

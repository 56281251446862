import React, { useEffect, useState } from 'react';
import { Label, Checkbox, Form, Button, Segment } from 'semantic-ui-react';
import { FormDropdown } from './parts';

const POSITION_NUMBERS = [1, 2, 3, 4, 5];

const codeOptions = [
  { text: 'Response', value: '{response}' },
  { text: 'Response Followup', value: '{response_followup}' },
];

const ValueField = React.memo(({ value, keyName, updateKey, tierOptions }) => {
  const key = keyName;
  const placeholder = keyName;
  const style = { width: 168 };
  const dropdownStyle = { ...style, backgroundColor: '#fcfff5 !important' };

  const defaultType = (!!value && !!value.length && value[0] === '{')
    ? 'code' : (tierOptions.find(row => row.value === value))
      ? 'tags' : (!!value && !!value.length)
        ? 'text' : 'tags';

  const [inputType, setInputType] = useState(defaultType);
  useEffect(() => {
    setInputType(defaultType);
  }, [defaultType]);

  const onChange = updateKey(key);

  return (
    <>
      <Button.Group size="mini">
        <Button active={inputType === 'tags'} as="a" icon="tags" style={{ lineHeight: '18px' }} onClick={() => setInputType('tags')} />
        <Button active={inputType === 'text'} as="a" icon="quote left" style={{ lineHeight: '18px' }} onClick={() => setInputType('text')} />
        <Button active={inputType === 'code'} as="a" icon="code" style={{ lineHeight: '18px' }} onClick={() => setInputType('code')} />
      </Button.Group>
      {inputType === 'tags' && <Form.Dropdown selection {...{ placeholder, style: dropdownStyle, value, onChange, options: tierOptions }} />}
      {inputType === 'text' && <Form.Input {...{ placeholder, style, value, onChange }} />}
      {inputType === 'code' && <Form.Dropdown selection {...{ placeholder, style: dropdownStyle, value, onChange, options: codeOptions }} />}
    </>
  );
});

const PriorityField = React.memo(({ event, updateKey }) => {
  const key = 'priority';
  const placeholder = '50';

  const onChange = updateKey(key);
  const value = event.priority;

  return (
    <Form.Input className="PPS-Priority" {...{ placeholder, value, onChange }} />
  );
});

const CreateEvents = ({
  tierOptions, options,
  event, remove, updateStateArrayRowField, pos,
}) => {
  const [showCurrent, setShowCurrent] = useState(1);
  const [showAs, setShowAs] = useState(1);
  const [showSkipCreation, setShowSkipCreation] = useState(false);

  useEffect(() => {
    const showCurrentUpdate = POSITION_NUMBERS.reduce((accu, c) => {
      if (event[`mta_tier_${c}`]) {
        accu = c; // eslint-disable-line no-param-reassign
      }
      return accu;
    }, 1);

    const showAsUpdate = POSITION_NUMBERS.reduce((accu, c) => {
      if (event[`tier_${c}`]) {
        accu = c; // eslint-disable-line no-param-reassign
      }
      return accu;
    }, 1);
    setShowCurrent(showCurrentUpdate);
    setShowAs(showAsUpdate);
  }, []);

  const handleAddField = key => {
    if (key === 'showAs') {
      setShowAs(showAs + 1);
      return;
    }
    setShowCurrent(showCurrent);
  };

  const toggleShowSkipCreation = () => {
    setShowSkipCreation(true);
  };

  const onResponseChange = updateStateArrayRowField('events', 'response')(event);
  const displayAs = showAs === 5 ? 'none' : 'inline-block';
  const displayCurrent = showCurrent === 5 ? 'none' : 'inline-block';

  const updateKey = key => updateStateArrayRowField('events', key)(event);
  const tierPositions = POSITION_NUMBERS.slice(0, showAs);
  const skipPositions = POSITION_NUMBERS.slice(0, showCurrent);

  let currentOptions = [];
  let nextOptions = tierOptions;

  return (
    <Segment secondary>
      <Button as="a" floated="right" icon="remove" onClick={() => remove(pos)} color="google plus" />
      <Form.Group>
        <FormDropdown
          options={options}
          label="If the response is"
          value={event.response}
          onChange={onResponseChange}
        />
        <div className="field" style={{ margin: 0 }}>
          <label>Create an event where</label>
          {tierPositions.map(position => {
            const value = event[`tier_${position}`];
            currentOptions = nextOptions || [];

            const next = currentOptions.find(({ text }) => text === value) || { values: [] };
            nextOptions = next.values;

            return (
              <Form.Group>
                <Label style={{ lineHeight: 1.8 }}>{`Tier ${position}`}</Label>
                <ValueField
                  keyName={`tier_${position}`}
                  {...{ updateKey, value, tierOptions: currentOptions, pos: position }}
                />
              </Form.Group>
            );
          })}
          <Button
            icon="plus"
            onClick={() => handleAddField('showAs')}
            style={{ display: displayAs }}
            as="span"
          />
        </div>
      </Form.Group>
      <Form.Group>
        Priority
        <PriorityField {...{ event, updateKey }} />
      </Form.Group>
      {!showSkipCreation && (
        <>
          <Checkbox onChange={toggleShowSkipCreation} />
          Skip Creation if Event Exists
        </>
      )}
      {showSkipCreation && (
        <>
          <div className="field" style={{ margin: 0 }}>
            <label>Skip creation if existing event matches</label>
          </div>
          <Form.Group>
            {skipPositions.map(position => (
              <ValueField
                keyName={`mta_tier_${position}`}
                {...{ updateKey, value: event[`mta_tier_${position}`], tierOptions, pos: position }}
              />
            ))}
            <Button
              icon="plus"
              onClick={() => handleAddField('showCurrent')}
              style={{ display: displayCurrent }}
              as="span"
            />
          </Form.Group>
        </>
      )}
    </Segment>
  );
};

export default CreateEvents;

import React from 'react';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { DateRangeHooks } from '../../components';

export const BackfillingMessage = () => (
  <div className="loading-overlay">
    <Header
      as="h2"
      textAlign="center"
      content="We’re importing your data now"
      subheader="It might take a few hours to import your complete spend data after connecting a new ad platform. Check again soon for updates."
    />
  </div>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: '0px';
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0px')};
`;

export const GenericHeader = ({
  header, subheader, // Text
  datePicker, // Links
  paddingBottom, // styling
}) => (
  <Wrapper {...{ paddingBottom }} className="generic-header">
    <div>
      <Header as="h1">
        {header}
        <Header.Subheader>{subheader}</Header.Subheader>
      </Header>
    </div>
    <div>
      <div style={{ display: 'inline-flex', alignItems: 'flex-end' }}>
        {!!datePicker && (
          <div style={{ height: 60, width: 332, padding: '9px 0 15px 24px' }}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <DateRangeHooks {...datePicker} invert marginTop={0} transparent />
          </div>
        )}
      </div>
    </div>
  </Wrapper>
);

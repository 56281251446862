/**
 * Calculates the rolled-up data based on the given input data.
 *
 * @param {Array} data - The input data to be rolled up.
 * @param {number} numDays - The number of days over which to calculate the rolled-up data.
 * @param {string} [display=''] - The display value for the rolled-up data.
 * @param {boolean} [isConversionKPI=false] - Indicates whether the rolled-up data is for conversion KPI.
 * @returns {Object} - The rolled-up data object with the following properties:
 *   - display: The display value for the rolled-up data.
 *   - currentValue: The current value of the rolled-up data.
 *   - targetValue: The target value of the rolled-up data.
 *   - estimatedTotalValue: The estimated total value of the rolled-up data.
 *   - totalKPIAtTargetValue: The total KPI at the target value of the rolled-up data.
 *   - totalKPIAtCurrentValue: The total KPI at the current value of the rolled-up data.
 *   - spendShare: The spend share of the rolled-up data.
 *   - effectShare: The effect share of the rolled-up data.
 *   - kpiPercentDiff: The KPI percent difference of the rolled-up data.
 *   - metricAtCurrentValue: The metric at the current value of the rolled-up data.
 *   - metricAtTargetValue: The metric at the target value of the rolled-up data.
 *   - metricDiff: The metric difference of the rolled-up data.
 *   - incrementalMetric: The incremental metric difference based on the target and current values.
 */
export const rollupTableDataToCollapseRows = (data, numDays, display = '', isConversionKPI = false) => {
  const initSummary = {
    display,
    currentValue: 0,
    targetValue: 0,
    estimatedTotalValue: 0,
    totalKPIAtTargetValue: 0,
    totalKPIAtCurrentValue: 0,
    spendShare: 0,
    effectShare: 0,
  };
  const keysToAdd = Object.keys(initSummary).filter(key => key !== 'display');
  const rolledUpData = data.reduce((accu, curr) => {
    keysToAdd.forEach(key => {
      accu[key] += curr[key] || 0;
    });
    return accu;
  }, initSummary);
  const { totalKPIAtTargetValue, totalKPIAtCurrentValue, estimatedTotalValue, currentValue, targetValue } = rolledUpData;
  const totalCurrentValue = numDays * currentValue;
  const metricAtTarget = isConversionKPI ? estimatedTotalValue / totalKPIAtTargetValue : totalKPIAtTargetValue / estimatedTotalValue;
  const metricAtCurrent = isConversionKPI ? totalCurrentValue / totalKPIAtCurrentValue : totalKPIAtCurrentValue / totalCurrentValue;
  const incrementalKPI = totalKPIAtTargetValue - totalKPIAtCurrentValue;
  const incrementalSpend = targetValue * numDays - currentValue * numDays;
  const incrementalMetric = isConversionKPI ? incrementalSpend / incrementalKPI : incrementalKPI / incrementalSpend;

  rolledUpData.kpiPercentDiff = (totalKPIAtTargetValue - totalKPIAtCurrentValue) / totalKPIAtCurrentValue;
  rolledUpData.metricAtTargetValue = metricAtTarget;
  rolledUpData.metricAtCurrentValue = metricAtCurrent;
  rolledUpData.metricDiff = metricAtTarget - metricAtCurrent;
  rolledUpData.incrementalMetric = incrementalMetric;
  return rolledUpData;
};

export function getRemainingDays() {
  const date = new Date();
  const time = new Date(date.getTime());
  time.setMonth(date.getMonth() + 1);
  time.setDate(0);
  return time.getDate() > date.getDate() ? time.getDate() - date.getDate() + 1 : 1;
}

export function getNumDaysInMonth() {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
}

export function calculatePortionOfMonthRemaining() {
  const remainingDays = getRemainingDays();
  const totalDays = getNumDaysInMonth();
  return remainingDays / totalDays;
}

export function calculateProratedCost(monthlyPrice) {
  const portionOfMonth = calculatePortionOfMonthRemaining();
  return (portionOfMonth * monthlyPrice).toFixed(2);
}

export function getMonth() {
  const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const d = new Date();
  const name = month[d.getMonth()];
  return name;
}

export function getNextMonth() {
  const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  const d = new Date();
  let nextMonthIndex = d.getMonth() + 1;
  if (nextMonthIndex > 11) nextMonthIndex = 0; // Handling the December case
  const name = month[nextMonthIndex];
  return name;
}

export function getYearAtStartOfNextMonth() {
  const d = new Date();
  const currentMonth = d.getMonth();
  const currentYear = d.getFullYear();

  if (currentMonth === 11) { // December is represented as 11
    return currentYear + 1;
  }
  return currentYear;
}

export function getDay() {
  const date = new Date().getDate();
  return date;
}

export function roundToNext1000(num) {
  if (num < 10000) {
    // eslint-disable-next-line no-param-reassign
    num = 10000;
  }
  return Math.ceil(num / 1000) * 1000;
}

// TODO: rename
export function getNumUnits(num) {
  if (num < 1000) {
    // eslint-disable-next-line no-param-reassign
    num = 1;
  }
  return Math.ceil(num / 1000);
}

export function roundStripeUnits(num) {
  return getNumUnits(roundToNext1000(num));
}

export function getPriceIndex(num) {
  return num / 1000 - 10;
}

const { floor } = Math;
const { abs } = Math;
const { log } = Math;
const abbrev = 'kmb';

export function round(n, precision) {
  const prec = 10 ** precision;
  return Math.round(n * prec) / prec;
}

export function formatNumber(n) {
  let base = floor(log(abs(n)) / log(1000));
  const suffix = abbrev[Math.min(2, base - 1)];
  base = abbrev.indexOf(suffix) + 1;
  return suffix ? round(n / 1000 ** base, 2) + suffix : `${n}`;
}

const MAX_STRIPE_TIER = 1999000;

export function monthlyAdSpendToPrice(monthlyAdSpend, pricingData) {
  if (monthlyAdSpend <= MAX_STRIPE_TIER) {
    const index = getPriceIndex(roundToNext1000(monthlyAdSpend));
    return pricingData[index].price.toFixed(2);
  }  // handles case where spend is > than the max tier in stripe
  const index = getPriceIndex(roundToNext1000(MAX_STRIPE_TIER));
  const costUpToMax = pricingData[index].price;
  const spendUpToMax = pricingData[index]['Monthly Ad Spend'];
  const remainingSpend = monthlyAdSpend - spendUpToMax;
  const remainingCostRate = pricingData[index].price - pricingData[index - 1].price;
  const remainingUnits = (getNumUnits(remainingSpend));
  const remainingCost = remainingUnits * remainingCostRate;
  const totalcost = (costUpToMax + remainingCost).toFixed(2);
  return totalcost;
}

export function monthlyAdSpendToCostPer1k(monthlyAdSpend, pricingData) {
  if (monthlyAdSpend <= MAX_STRIPE_TIER) {
    const index = getPriceIndex(roundToNext1000(monthlyAdSpend));
    return pricingData[index]['Cost per $1K in Ad Spend'];
  }  // handles case where spend is > than the max tier in stripe
  const index = getPriceIndex(roundToNext1000(MAX_STRIPE_TIER));
  return pricingData[index]['Cost per $1K in Ad Spend'];
}

export function setToFixed(number) {
  return Number(number).toFixed(2).toLocaleString();
}

export function addCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatDollarValue(number) {
  const fixedDecimals = setToFixed(number);
  return addCommas(fixedDecimals);
}

export function getOriginalPrice(number, magnitude, type) {
  if (type === 'fixed_amount') {
    return Number(number) + magnitude;
  } if (type === 'percentage') {
    return (number / (1 - (magnitude / 100)));
  }
}

export function applyDiscount(original, promoPercent, promoValue, oneHundredPercentOff) {
  const prices = { original: 0, discounted: 0 };
  if (oneHundredPercentOff) {
    prices.original = formatDollarValue(original);
    prices.discounted = formatDollarValue(0);
  } else if (promoPercent) {
    prices.original = formatDollarValue(getOriginalPrice(original, promoPercent, 'percentage'));
    prices.discounted = formatDollarValue(original);
  } else if (promoValue) {
    prices.original = formatDollarValue(getOriginalPrice(original, promoValue, 'fixed_amount'));
    prices.discounted = formatDollarValue(original);
  } else {
    prices.original = formatDollarValue(original);
    prices.discounted = formatDollarValue(original);
  }
  return prices;
}

export function handleDiscount(activeSubscription, pricingResponse, invoiceResponse, setEstimated, setProrated, setOneHundredPercentOff) {
  const discountObj = {};
  if (activeSubscription.discount) {
    if (activeSubscription.discount.coupon.amount_off) {
      discountObj.estimated = monthlyAdSpendToPrice(activeSubscription.quantity * 1000, pricingResponse.response) - (activeSubscription.discount.coupon.amount_off / 100);
      discountObj.prorated = calculateProratedCost(monthlyAdSpendToPrice(activeSubscription.quantity * 1000, pricingResponse.response) - (activeSubscription.discount.coupon.amount_off) / 100);
    } else {
      if (activeSubscription.discount.coupon.percent_off === 100) {
        setOneHundredPercentOff(true);
      }
      discountObj.estimated = monthlyAdSpendToPrice(activeSubscription.quantity * 1000, pricingResponse.response) * (1 - (activeSubscription.discount.coupon.percent_off / 100));
      discountObj.prorated = calculateProratedCost(monthlyAdSpendToPrice(activeSubscription.quantity * 1000, pricingResponse.response) * (1 - (activeSubscription.discount.coupon.percent_off / 100)));
    }
  } else if (invoiceResponse.discount) {
    if (invoiceResponse.discount.coupon.amount_off) {
      discountObj.estimated = monthlyAdSpendToPrice(activeSubscription.quantity * 1000, pricingResponse.response);
      discountObj.prorated = calculateProratedCost(monthlyAdSpendToPrice(activeSubscription.quantity * 1000, pricingResponse.response) - (invoiceResponse.discount.coupon.amount_off) / 100);
    } else {
      if (invoiceResponse.discount.coupon.percent_off === 100) {
        setOneHundredPercentOff(true);
      }
      discountObj.estimated = monthlyAdSpendToPrice(activeSubscription.quantity * 1000, pricingResponse.response);
      discountObj.prorated = calculateProratedCost(monthlyAdSpendToPrice(activeSubscription.quantity * 1000, pricingResponse.response) * (1 - invoiceResponse.discount.coupon.percent_off / 100));
    }
  } else {
    discountObj.estimated = monthlyAdSpendToPrice(activeSubscription.quantity * 1000, pricingResponse.response);
    discountObj.prorated = calculateProratedCost(monthlyAdSpendToPrice(activeSubscription.quantity * 1000, pricingResponse.response));
  }
  setEstimated(discountObj.estimated);
  setProrated(discountObj.prorated);
}

export const formatAddress = billingAddress => {
  const obj = {
    address: billingAddress,
    address_source: 'billing',
  };
  return obj;
};

export const formatLineItemsForTaxes = amount => {
  const obj = [
    {
      amount: Math.round(amount * 100),
      reference: 'Rockerbox Starter',
    },
  ];
  return obj;
};

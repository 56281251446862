import React, { Component } from 'react';
import { SplitLayout, ContentCard } from '@rockerbox/styleguide'
import { Icon, Menu } from 'semantic-ui-react'
import Timeseries from './timeseries';
import UnmappedEvents from './unmapped';
import EventIssues from './issues';
import EventsTree from './tree';
import ExcludedTree from './exclude_tree';
import moment from 'moment';
import { getSegments } from '../../utils/api';
import { _pushPath } from '../pushpath';

export default class Overview extends _pushPath(Component) {
  constructor(props) {
    super(props)
    this.state = {
      activeItem: 'mapped events' ,
      date: moment.utc().utcOffset(-5).subtract(1, 'days').format("YYYY-MM-DD")
    }
    this.timerRef = React.createRef();
    this.timerRef.current = true;
  }

  componentDidMount() {
    getSegments()
      .then(resp => {
        if (this.timerRef.current) {
          this.updatePath({"id":resp.filter(row => row.touchpoints)[0]['filter_id']})
        }
      })
  }

  componentWillUnmount() {
    if (this.timerRef.current) {
      this.timerRef.current = false;
    }
  }
  handleClick = (e, { name }) => this.setState({ activeItem: name })

  setDate = (date) => this.setState({ date })

  render() {
    const { id } = this.props.match.params;
    const { date, activeItem } = this.state;

    return (
      <div>
        <ContentCard title={"Event Overview"} disableHeaderEllipse noContent />
        { id && <Timeseries id={id} setDate={this.setDate} /> }
        <SplitLayout
          leftWidth={3}
          rightWidth={13}
          leftContent={
            <Menu pointing vertical style={{"width":"100%"}}>
              <Menu.Item name='mapped events' active={activeItem === 'mapped events'} onClick={this.handleClick}>
                <span><Icon name='sitemap' /> Mapped </span>
              </Menu.Item>
              <Menu.Item name='unmapped events' active={activeItem === 'unmapped events'} onClick={this.handleClick}>
                <span><Icon name='question circle outline' /> Unmapped </span>
              </Menu.Item>
              <Menu.Item name='issues' active={activeItem === 'issues'} onClick={this.handleClick}>
                <span><Icon name='exclamation triangle' /> Issues </span>
              </Menu.Item>
              <Menu.Item name='excluded events' active={activeItem === 'excluded events'} onClick={this.handleClick}>
                <span><Icon name='trash alternate outline' /> Excluded </span>
              </Menu.Item>
            </Menu>
          }
          rightContent={
            <React.Fragment>
              { activeItem == "mapped events" && id && <EventsTree id={id} date={date} /> }
              { activeItem == "excluded events" && id && <ExcludedTree id={id} date={date} /> }
              { activeItem == "unmapped events" && id && <UnmappedEvents id={id} date={date} /> }
              { activeItem == "issues" && <EventIssues id={id} date={date} /> }
            </React.Fragment>
          } />
      </div>
    )
  }
}

Overview.propTypes = { }

import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faRectangleAd } from '@fortawesome/free-solid-svg-icons';

export const CHANNELS = [
  {
    key: 'facebook',
    name: 'Facebook',
    icon: 'facebook f',
    color: '#479BF5',
  },
  {
    key: 'google',
    name: 'Google',
    icon: 'google',
    color: '#12D975',
  },
  {
    key: 'bing',
    name: 'Bing',
    icon: 'microsoft',
    color: '#00B8A1',
  },
  {
    key: 'pinterest',
    name: 'Pinterest',
    icon: 'pinterest',
    color: '#E61939',
  },
  {
    key: 'snapchat',
    name: 'Snapchat',
    icon: 'snapchat ghost',
    color: '#F5F33D',
  },
  {
    key: 'tiktok',
    name: 'TikTok',
    customIcon: faTiktok,
    color: '#42F0EB',
  },
  {
    key: 'linkedin',
    name: 'LinkedIn',
    icon: 'linkedin in',
    color: '#0A94C2',
    hideFromBenchmarks: true,
  },
  {
    key: 'reddit',
    name: 'Reddit',
    icon: 'reddit alien',
    color: '#ff4500',
  },
  {
    key: 'affiliate',
    name: 'Affiliates',
    icon: 'tags',
    color: '#239ab9',
  },
  {
    key: 'ott',
    name: 'OTT',
    icon: 'laptop',
    color: '#39bd00',
  },
  {
    key: 'linear_tv',
    name: 'Linear TV',
    icon: 'tv',
    color: '#00629b',
  },
  {
    key: 'direct_mail',
    name: 'Direct Mail',
    icon: 'envelope',
    color: '#2d3277',
    hideFromBenchmarks: true,
  },
  {
    key: 'display',
    name: 'Display Ads',
    customIcon: faRectangleAd,
    color: '#fbb034',
  },
];

export const CHANNELS_LIST = CHANNELS.map(x => x.key);
export const CHANNELS_BREAKDOWNS_LIST = CHANNELS.filter(x => !x.hideFromBenchmarks).map(x => x.key);

export const CHANNEL_NAME_MAP = CHANNELS.reduce((acc, cur) => {
  // eslint-disable-next-line no-param-reassign
  acc[cur.key] = cur.name;
  return acc;
}, {});

export const CHANNEL_MAP = CHANNELS.reduce((acc, cur) => {
  // eslint-disable-next-line no-param-reassign
  acc[cur.key] = { ...cur };
  return acc;
}, {});

export const NAME_MAP = {
  pct_of_spend: 'Avg. Percentage of Spend Budget',
  pct_change: 'Week-over-Week Change in Spend',
};

import { useMemo } from 'react';

import { changeDisplayName } from '../helpers';

const CARRYOVER_WINDOW_LENGTH = 30;

/**
 * Custom hook to process spend effect data and calculate cumulative values.
 *
 * Data comes is passed in the form of an array of objects, each containing a day-in-window, feature name, and spend effect.
 * The function returns an object with two properties: chartData and cumulativeChartData, each of which is an array of objects,
 * with each object representing the spend effect for all given features for the day-in-window.
 * We rewrite day_in_window to date so the line chart can more easily display x-axis labels.
 *
 * @param {Array} spendEffectData - Array of objects containing spend effect data.
 * @param {Object} featureNameMap - Mapping object for feature names.
 * @returns {Object} An object containing processed chart data and cumulative chart data.
 */

export const useSpendEffect = (spendEffectData, featureNameMap) => {
  const { chartData, cumulativeChartData } = useMemo(() => {
    const cumsum = {};
    const grouped = spendEffectData.reduce((accu, item) => {
      const { feature_name, day_in_window, spend_effect } = item;

      if (!accu[day_in_window]) {
        accu[day_in_window] = {};
      }

      if (!cumsum[day_in_window]) {
        cumsum[day_in_window] = {};
      }

      if (!cumsum[day_in_window][feature_name]) {
        cumsum[day_in_window][feature_name] = [];
      }

      accu[day_in_window][feature_name] = spend_effect;
      cumsum[day_in_window][feature_name] = day_in_window === 0 ? spend_effect : (spend_effect + cumsum[day_in_window - 1][feature_name] || 0);

      return accu;
    }, {});

    const effectData = Object.keys(grouped).map(date => {
      const key = date.length === 1 ? `0${date}` : date;
      return { date: key, ...grouped[date] };
    });

    const cumulativeEffectData = Object.keys(cumsum).map(date => {
      const key = date.length === 1 ? `0${date}` : date;
      return { date: key, ...cumsum[date] };
    });

    return {
      chartData: effectData.sort((a, b) => Number(a.date) - Number(b.date)).slice(0, CARRYOVER_WINDOW_LENGTH),
      cumulativeChartData: cumulativeEffectData.sort((a, b) => Number(a.date) - Number(b.date)).slice(0, CARRYOVER_WINDOW_LENGTH),
    };
  }, [spendEffectData]);

  return {
    chartData: changeDisplayName(chartData, featureNameMap),
    cumulativeChartData: changeDisplayName(cumulativeChartData, featureNameMap),
  };
};

import React from 'react';
import { Icon, Button, Table, Loader } from 'semantic-ui-react';
import { ContentCard, IndexGrid } from '@rockerbox/styleguide'
import moment from 'moment';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { getMetaData, getACETLMetaData } from '../../utils/api';
import { getLocalJSON, setLocalJSON, checkJobStatus } from "../../utils/job_api";

const jobTemplate = (jobId) => `http://wq.internal.getrockerbox.com/v2/workqueue_status/job/${jobId}`

export const BackfillCell = props => {
  const [disabled, setDisabled] = React.useState(false)
  const [submission, setSubmission] = React.useState(false)
  const [increment, setIncrement] = React.useState(0)
  const [jobStatus, setJobStatus] = React.useState(false)
  const [finishType, setFinishType] = React.useState(false)
  const { onClick } = props.col

  const { key } = props.col
  const { date } = props.item
  const today = moment.utc().utcOffset(-5).format("YYYY-MM-DD")

  const localStorageKey = [key, date, today].join("|")

  const buttonClick = () => {
    setDisabled(true)
    onClick(props.item, props)
      .then(({ response })=> {
        setLocalJSON(localStorageKey, response)
        setSubmission(response)
      })
  }

  React.useEffect(() => {
    const submission = getLocalJSON(localStorageKey)
    if (Object.keys(submission).length > 0) setSubmission(submission)
  }, [])

  const checkStatus = (job_id) => {
    checkJobStatus(job_id)
      .then(resp => {
        const { finished_at, finish_type } = resp
        if (!finished_at) {
          setFinishType("running")
          return setIncrement(increment + 1)
        }
        setFinishType(finish_type)
      })
  }

  React.useEffect(() => {
    if (submission) {
      const { job_id } = submission;
      setFinishType("running")
      setTimeout(() => checkStatus(job_id), 5000)
    }
  }, [submission, increment])

  return (
    <Table.Cell collapsing style={{ minWidth: 70 }}>
      <Button {...{disabled}} onClick={buttonClick}>{props.col.text}</Button>
      { finishType == "running" && <Loader active inline size='mini' /> }
      { finishType == "error" && <Icon name='warning sign' /> }
      { finishType == "success" && <Icon name='check circle' /> }
    </Table.Cell>
  );
};

export const defaultFunc = (row, props) => {
  const { filter_id, item: { date }, col: { url, username = null } } = props;
  const data = { filter_id, date };
  if (username) {
    data['username'] = username;
  }

  return fetch(url, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
    .then(resp => resp.json())
}

const SetupStatus = ({
  filter_id,
  startDate = moment.utc().utcOffset(-5).subtract(31,"d"),
  endDate = moment.utc().utcOffset(-5).subtract(1, "d"),
  datasets = [
    { dataset: "facebook_conversions_hourly", display: "Data Ingested?", type: "acetl" },
    { dataset: "facebook_views_base", display: "Events Created?" },
    { dataset: "fb_synthetic_summary", display: "Summary Produced?" }
  ],
  funcs = [
    { func: defaultFunc, text: "Reingest", display: "", url: "/run/sync/facebook_synthetic" },
    { func: defaultFunc, text: "Build Events", display: "", url: "/run/agg/facebook_events" },
    { func: defaultFunc, text: "Backfill", display: "", url: "/run/agg/events" }
  ]
}) => {

  const [data, setData] = React.useState(false)

  const thirtyDayRange = React.useMemo(() => {
    return d3.time.day.range(startDate, endDate)
      .map(date => moment(date).format("YYYY-MM-DD"))
  }, [startDate, endDate])

  const cols = React.useMemo(() => {
    const cols = [{ key: "date", display: "Date" }]

    datasets.map(({ dataset, display }) => {
      cols.push({ key: `has_${dataset}`, display, as: IndexGrid.CelledCheckmark })
    })

    funcs.map(({ func, text, display, url, username = null }) => {
      cols.push({ key: text, text, display, onClick: func, as: BackfillCell, url, username })
    })

    return cols
  }, [])

  React.useEffect(() => {

    const promises = datasets.map(({ dataset, datasetFilterId, type }) => {
      return type == "acetl" ? getACETLMetaData(datasetFilterId || filter_id, dataset) :
        getMetaData(datasetFilterId || filter_id, dataset)
    })

    Promise.all(promises)
      .then((results) => {

        const resultsByDate = datasets.reduce((p, { dataset }, i) => {
          const { current } = results[i]
          p[dataset] = d3.nest()
            .key(row => row.date)
            .map(current)
          return p
        }, {})

        const grouped = thirtyDayRange.reduce((p,c) => {
          p[c] = { date: c }
          datasets.map(({ dataset }) => {
            p[c][dataset] = resultsByDate[dataset][c]
            p[c][`has_${dataset}`] = resultsByDate[dataset][c]
          })
          return p
        }, {})

        const groupedData = Object.values(grouped)
        setData(groupedData)
      })
  } , [])

  return <ContentCard title="Data Status" hasTable>
    <IndexGrid {...{filter_id, data, cols}}  />
  </ContentCard>
}

export default SetupStatus;

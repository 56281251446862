import React from 'react';
import { findMetricIndex } from './helpers';

export const calculateMeans = data => {
  const initialSums = {
    cpa: 0,
    roas: 0,
    cpm: 0,
    cpc: 0,
    ctr: 0,
  };

  const totalSums = data.reduce((sums, item) => ({
    cpa: sums.cpa + item.cpa,
    roas: sums.roas + item.roas,
    cpm: sums.cpm + item.cpm,
    cpc: sums.cpc + item.cpc,
    ctr: sums.ctr + item.ctr,
  }), initialSums);

  return {
    cpa: totalSums.cpa / data.length,
    roas: totalSums.roas / data.length,
    cpm: totalSums.cpm / data.length,
    cpc: totalSums.cpc / data.length,
    ctr: (totalSums.ctr / data.length),
  };
};

export const formatMetrics = metrics => {
  const formattedMetrics = {};

  Object.keys(metrics).forEach(key => {
    if (key === 'roas') {
      formattedMetrics[key] = parseFloat(metrics[key].toFixed(2));
    } else if (key === 'ctr') {
      formattedMetrics[key] = `${(metrics[key] * 100).toFixed(2)}%`;
    } else {
      formattedMetrics[key] = `$${metrics[key].toFixed(2)}`;
    }
  });

  return formattedMetrics;
};

export const CustomLegend = ({ activeIndex, onClick, data, activeMetrics }) => {
  const means = formatMetrics(calculateMeans(data));
  const tabs = Object.keys(means).filter(key => Object.keys(activeMetrics).includes(key));
  const activeIndexNum = findMetricIndex(activeIndex, activeMetrics);
  return (
    <div className="legend">
      {tabs.map((tab, index) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          key={index}
          className={`tab ${index === activeIndexNum ? 'active' : ''}`}
          onClick={() => onClick(tabs[index])}
          style={{
            left: `${
              index === activeIndexNum
                ? `${index * 190}px`
                : index > activeIndexNum
                  ? '188px'
                  : index < activeIndexNum
                    ? '-2px'
                    : '0px'
            }`,
          }}
        >
          <p className="tab-name">{tab.toUpperCase()}</p>
          <p className="tab-value">{means[tab]}</p>
        </div>
      ))}
    </div>
  );
};

import React from "react";
import { Message } from "semantic-ui-react";

export const DataAppliedFailure = ({ error }) => {
  return (
    <Message
      error
      header="Error Occured when applying new custom schema."
      content={`${error}`}
    />
  );
};

export const ValidationErrors = ({ errors }) => {
  if (!errors || !errors.length) {
    return null;
  }
  return (
    <Message error>
      {errors.map(({ message }) => (
        <Message.Item key={message} content={message} />
      ))}
    </Message>
  );
};

import React from "react";
import { ContentCard, PagedIndexGrid, IndexGrid } from "@rockerbox/styleguide";
import {
  getCreativeTiersTimeseriesData,
  getCreativeTiersData,
  getConversionSegments
} from "../../utils/api";
import TimeRange from "../timerange";

class CreativeDataSource extends TimeRange {
  state = {
    featuredFilterId: false,
    customerData: [],
    loading: true
  };

  componentDidMount() {
    getConversionSegments().then(data => {
      const filteredFeatured = data
        .filter(segment => segment.featured == 1)
        .map(seg => {
          return { id: seg.action_id };
        });
      const featuredFilterId =
        filteredFeatured.length > 0 ? filteredFeatured[0]["id"] : false;
      this.setState({ featuredFilterId });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { featuredFilterId, loading } = this.state;
    const { startDate, endDate } = this.props.match.params;
    const {
      startDate: prevStartDate,
      endDate: prevEndDate
    } = prevProps.match.params;

    if (!featuredFilterId) {
      return
    }

    if (
      startDate != prevStartDate ||
      endDate != prevEndDate ||
      prevState.featuredFilterId != featuredFilterId
    ) {
      this.setState({ loading: true });
      getCreativeTiersData(featuredFilterId, startDate, endDate)
        .then(customerData => {
          this.setState({ customerData, loading: false });
        })
        .catch(err => {
          this.setState({ loading: false });
        });
    }
  }

  getExpandedData = item => {
    const { startDate, endDate } = this.props;
    const { tier_one, tier_two, tier_three, tier_four, tier_five } = item;

    getCreativeTiersTimeseriesData(
      startDate,
      endDate,
      tier_one,
      tier_two,
      tier_three,
      tier_four,
      tier_five
    ).then(expandedData => this.setState({ expandedData }));
  };

  expandedSection = ({ item }) => {
    const { expandedData, expandedKey } = this.state;
    if (expandedKey != item.key) {
      this.setState({ expandedData: [], expandedKey: item.key }, () =>
        this.getExpandedData(item)
      );
    }
    const cols = [
      { display: "Timestamp", key: "timestamp" },
      { display: "Count", key: "uid" }
    ];

    return <IndexGrid data={expandedData} cols={cols} />;
  };

  render() {
    const { customerData, loading } = this.state;
    const { timePicker, startDate, endDate } = this.props;

    const cols = [
      { display: "Tier One", key: "tier_one" },
      { display: "Tier Two", key: "tier_two" },
      { display: "Tier Three", key: "tier_three" },
      { display: "Tier Four", key: "tier_four" },
      { display: "Tier Five", key: "tier_five" },
      { display: "Num Days", key: "num_days" }
    ];

    return (
      <React.Fragment>
        <ContentCard
          hasTable
          title="Creative Data"
          explainer=""
          loading={loading}
        >
          <PagedIndexGrid
            itemsPerPage={100}
            expandable={true}
            data={customerData}
            cols={cols}
            as={ContentCard.Table}
            idKey="key"
            expandedComponent={this.expandedSection}
            fallbackMsg="No customer data"
          />
          {this.renderDateModal()}
        </ContentCard>
      </React.Fragment>
    );
  }
}

export default CreativeDataSource;

import React from 'react';

import { ResponsiveContainer, ComposedChart, Line, Bar, Tooltip, YAxis, XAxis } from 'recharts';

import { axisLabelStyles, CustomAxisTick, CustomTooltip } from './chartHelpers';

const FitChart = ({ data, isConversionKPI, chartType, dataKeys }) => {
  const kpiText = isConversionKPI ? 'Conversions' : 'Revenue';
  const formatting = isConversionKPI ? 'number' : 'currency';

  return (
    <ResponsiveContainer width="100%" height={250}>
      <ComposedChart
        data={data}
        margin={{ top: 5, right: 5, bottom: 35, left: 0 }}
      >
        <XAxis
          dataKey="date"
          tickLine={false}
          minTickGap={180}
          tick={CustomAxisTick('x', 'date')}
          stroke="#e6e6e6"
        />
        <YAxis
          type="number"
          tickLine={false}
          minTickGap={70}
          tick={CustomAxisTick('y', formatting)}
          label={{ value: kpiText, angle: -90, position: 'insideLeft', offset: 20, style: axisLabelStyles }}
          stroke="#e6e6e6"
        />
        <Tooltip content={<CustomTooltip {...{ isConversionKPI }} />} />
        {dataKeys.map(({ dataKey, color }) => (chartType === 'line' ? (
          <Line
            dataKey={dataKey}
            stroke={color}
            type="monotone"
            dot={false}
            strokeWidth={2}
            isAnimationActive={false}
          />
        ) : (
          <Bar
            dataKey={dataKey}
            stroke={color}
            fill={color}
            type="monotone"
            dot={false}
            strokeWidth={2}
            isAnimationActive={false}
          />
        )))}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default FitChart;

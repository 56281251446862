import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { SplitLayout, ContentCard, IndexGrid } from '@rockerbox/styleguide'
import { Form, Grid, Input, Button, Container, Message, Table } from 'semantic-ui-react';
import * as d3 from 'rockerbox_d3_legacy_clone';

import plot from './plot';
import cumulative from './cumulative';
import './plot.css';

class Features extends React.Component {
  state = {
    sortBy: "normal",
    transform: "log"
  }

  renderD3() {
    const { touchpoints } = this.props;
    const { width, sortBy, transform } = this.state;
    const target = ReactDOM.findDOMNode(this.refs.featureplot);
    const chart = plot(parseInt(width))

    d3
      .select(target)
      .selectAll('svg')
      .remove();

    d3
      .select(target)
      .datum(touchpoints)
      .call(chart);

    const linear = touchpoints.sort((p,c) => p.num_users - c.num_users)
    const leftright = linear.reduce((p,c,i) => {
      (i%2) ? p.right.push(c) : p.left.push(c)
      return p
    },{left: [], right: []})
    const normal = leftright.left.concat(leftright.right.reverse())

    const sorted = sortBy == "normal" ? normal : linear;
    const scale = transform == "log" ? "log" : "linear";


    const target2 = ReactDOM.findDOMNode(this.refs.cumulative);
    const chart2 = cumulative(parseInt(width))
      .title("Touchpoints ")
      .scale(scale)

    const data2 = sorted.map((c) => { return {"y":c.num_users} })

    d3
      .select(target2)
      .selectAll('svg')
      .remove();

    d3
      .select(target2)
      .datum(data2)
      .call(chart2);


    const target3 = ReactDOM.findDOMNode(this.refs.conversions);
    const chart3 = cumulative(parseInt(width))
      .title("Conversions ")
      .scale(scale)

    const data3 = sorted.map((c) => { return {"y":c.num_converters} })

    d3
      .select(target3)
      .selectAll('svg')
      .remove();

    d3
      .select(target3)
      .datum(data3)
      .call(chart3);


    const target4 = ReactDOM.findDOMNode(this.refs.conv_rate);
    const chart4 = cumulative(parseInt(width))
      .title("Conversion Rate ")


    const data4 = sorted.map((c) => { return {"y":c.num_converters/c.num_users} })

    d3
      .select(target4)
      .selectAll('svg')
      .remove();

    d3
      .select(target4)
      .datum(data4)
      .call(chart4);

    const target5 = ReactDOM.findDOMNode(this.refs.weight);
    const chart5 = cumulative(parseInt(width))
      .title("Weight ")
      .formatCount(d3.format(",.2f"))


    const data5 = sorted.map((c) => { return {"y":c.weight} })

    d3
      .select(target5)
      .selectAll('svg')
      .remove();

    d3
      .select(target5)
      .datum(data5)
      .call(chart5);



  }


  getDimensions() {
    const target = ReactDOM.findDOMNode(this.refs.featureplot);
    if (target) {
      const parent = target.parentNode
      const x = (this.state.width && (this.state.width === parent.offsetWidth)) ? false : this.setState({"width": parent.offsetWidth});
    }
  }
  componentDidMount() {
    this.getDimensions();
    this.renderD3();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.sortBy!= this.state.sortBy) ||
      (prevState.transform!= this.state.transform) ||
      (prevState.width != this.state.width) 
    )this.renderD3();
  }

  setOrdering = (sortBy, transform) => {
    this.setState({ sortBy, transform })
  }

  render() {

    const { touchpoints } = this.props;

    const cols = [{display:"Statistic",key:"key"},{display:"",key:"value"}];
    const numTouchpoints = touchpoints.length;
    const avgTouchpoints = touchpoints.reduce((p,c) => p + c.num_users,0)/numTouchpoints;
    const convTouchpoints = touchpoints.reduce((p,c) => p + c.num_converters, 0)/numTouchpoints;
    const avgWeight = touchpoints.reduce((p,c) => p + c.weight, 0)/numTouchpoints;
    const avgConvRate = convTouchpoints/avgTouchpoints;

    const summaryRows = [
      {key: "Features", value: numTouchpoints},
      {key: "Avg Touchpoints per Feature", value: avgTouchpoints.toPrecision(4)},
      {key: "Avg Conversions per Feature", value: convTouchpoints.toPrecision(4)},
      {key: "Avg Conversion Rate", value: avgConvRate.toPrecision(4)},
      {key: "Avg Weight", value: avgWeight.toPrecision(4)},
    ]

    const values = touchpoints.map(x => x.num_users)

    const max = d3.max(values);
    const min = 10;
    const x = d3.scale.log()
          .base(10)
          .domain([min, max])
          .range([0, 400]);
    
    var data = d3.layout.histogram()
      .bins(x.ticks(20))(values);
    

    return (
        <ContentCard hasTable title="Feature Summary" >
          <SplitLayout 
            leftWidth={8} rightWidth={8} 
            leftContent={
              <div>
                <IndexGrid style={{border:"0px", marginLeft:"1px"}}
                  data={summaryRows} cols={cols} as={ContentCard.Table} 
                  fallbackMsg="No model settings available"
                />
              </div>
            } 
            rightContent={
              <div>
                <div ref="featureplot" />
              </div>
            } 
          />
          <Grid>
            <Grid.Row>
            <Grid.Column style={{textAlign:"center"}}>
              <Button onClick={() => this.setOrdering("linear","linear")} size='tiny' compact>Linear</Button>
              <Button onClick={() => this.setOrdering("normal","linear")} size='tiny' compact>Normal</Button>
              <Button onClick={() => this.setOrdering("normal","log")} size='tiny' compact>Log Normal</Button>
            </Grid.Column>
            </Grid.Row>
          </Grid>
          <SplitLayout 
            leftWidth={8} rightWidth={8} 
            leftContent={
              <div>
                <div ref="cumulative" />
              </div>
            } 
            rightContent={
              <div>
                <div ref="conversions" />
              </div>
            } 
          />
          <SplitLayout 
            leftWidth={8} rightWidth={8} 
            leftContent={
              <div>
                <div ref="conv_rate" />
              </div>
            } 
            rightContent={
              <div>
                <div ref="weight" />
              </div>
            } 
          />
        </ContentCard>
    )
  }
}
export default Features;

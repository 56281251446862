import React, { useState, useEffect } from 'react';
import moment from 'moment';

// helpers
import { arrowColumnUnique } from './hooks/reportingData';
import { useGlobalState } from '../../hooks/global';

// state
import { useTiers } from './hooks/tierFilters';
import { usePlatformComparisonConfig } from './hooks/comparisonConfig';
import { usePlatformPerformanceConfig } from './hooks/platformConfig';
import { usePerformanceDataWithSelection } from './hooks/performanceData';
import { useSegmentValues } from './hooks/segment';
import { useTreeColumns } from './hooks/treeColumns';

// views
import Comparison from './Comparison';
import { platformPerformanceFirstReportingDate } from '../../utils/time';

const FirstPartyAttributedPerformance = props => {
  const { account, currencyCode } = useGlobalState();
  const { platformSelection, urlState } = props;
  const { integrations } = platformSelection;

  const {
    view, // what page is shown
    platform, // what platform
    startDate, setStartDate, endDate, setEndDate, // what date range
    segId, setSegId, // what RB segment?
    model, setModel, // what model, what user grouping
  } = urlState;

  const performanceConfig = usePlatformPerformanceConfig();
  // performance data configuration / selection
  const {
    optionalMetric, setOptionalMetric, // card selection metric
    conversionOptions,
    convValue, platformConversionEvent, updatePlatformConversionEvent,
    attributionWindowOptions,
    windowValue, attributionWindow, updateAttributionWindow,
    platformConfig,
  } = performanceConfig;

  // read / reset tiers on url
  const { tier_1, tierFilterMap } = useTiers(view, platformConfig);
  const [firstReportingDate, setFirstReportingDate] = useState(platformPerformanceFirstReportingDate); // eslint-disable-line no-unused-vars

  // comparison selection
  const comparisonConfig = usePlatformComparisonConfig();
  const { segsInfo, comparisonConfigsList, createConfigList, updateConfigList } = comparisonConfig;

  const setSegOptions = () => {};
  const setModelOptions = () => {};

  const { revenue_formatter } = platformConfig;
  const { optional_metrics } = platformConfig;

  const { segOptions, modelOptions, hasRevenue } = useSegmentValues(view, segId, comparisonConfigsList, segsInfo, model, setModel);

  const { tiers, setTiers, allColumns, selectedColumns, setSelectedColumns, groupBy, modelDisplayName } = useTreeColumns(
    platform,
    model,
    integrations,
    modelOptions,
    hasRevenue,
    optional_metrics,
    currencyCode,
  );

  // get performance data using configuration
  const {
    _platformPerformanceData,
    platformPerformanceData,
    filteredPlatformPerformanceTiers,
  } = usePerformanceDataWithSelection(
    { groupBy, attributionWindow, platformConversionEvent, revenue_formatter, tierFilterMap },
  );

  useEffect(() => {
    const firstReportingDate = segOptions.find(option => option.filter_id === segId)?.first_reporting_date; // eslint-disable-line no-shadow
    const reportingDateBeforePpDate = moment(firstReportingDate).isBefore(platformPerformanceFirstReportingDate);
    const checkedDate = reportingDateBeforePpDate ? platformPerformanceFirstReportingDate : firstReportingDate;

    setFirstReportingDate(checkedDate);
  }, [segOptions, segId]);

  const platformDates = React.useMemo(
    () => _platformPerformanceData?.size && arrowColumnUnique(_platformPerformanceData, 'date'),
    [_platformPerformanceData],
  );

  const updateSegmentAndModel = value => {
    const { platform_conversion } = comparisonConfigsList.find(config => config.filter_id === value);

    setSegId(value);
    updatePlatformConversionEvent(platform_conversion);
  };

  // State update handlers
  const onOptionalMetricChange = (e, { value }) => setOptionalMetric(value);

  const onDateChange = ({ startDate, endDate }) => { // eslint-disable-line no-shadow
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const { display_platform_name } = platformConfig;
  const headerText = `How is Rockerbox’s first-party tracking measuring my ${display_platform_name} advertising?`;

  return (
    <Comparison
      {...{ convValue, windowValue, conversionOptions, attributionWindowOptions, updatePlatformConversionEvent, updateAttributionWindow, view }} // values/methods for conversions metrics dropdown
      {...{ headerText, tier_1, platform, platformConfig, startDate, endDate, onDateChange, firstReportingDate }}  // Basic params and filters
      {...{ platformDates, platformData: filteredPlatformPerformanceTiers, platformPerformanceData, integrations, segsInfo }} // Data
      {...{ optionalMetric, segId, model }} // values
      {...{ onOptionalMetricChange }} // trendCard
      {...{ segOptions, modelOptions }}  // options
      {...{ updateSegmentAndModel, setModel, setSegId, setSegOptions, setModelOptions, createConfigList, updateConfigList }} // methods for RB data
      {...{ tiers, setTiers, allColumns, selectedColumns, setSelectedColumns, modelDisplayName }} // for customize cols and edit breakdowns
      {...{ optional_metrics, hasRevenue, comparisonConfigsList, account }}
    />
  );
};

export default FirstPartyAttributedPerformance;

import moment from 'moment';

export const formatXAxisTickLabel = (date, i, renderData) => {
  if (!renderData) return;
  const [year, month, day] = date.split('-');

  const monthString = moment().month(Number(month) - 1).format('MMM');
  const dayString = Number(day).toString();

  if (renderData.length > 365) {
    const lastMonth = i > 1 ? renderData[i - 2].date.split('-')[1] : '';
    if (dayString !== '1') return '';
    if (i === 0 || (month !== lastMonth && month !== '')) {
      return monthString === 'Jan' ? `${year}` : monthString;
    }
    return '';
  }

  if (renderData.length > 45) {
    const lastMonth = i > 1 ? renderData[i - 2].date.split('-')[1] : '';
    if (i % 2 !== 0) return '';
    if (i === 0 || (month !== lastMonth && month !== '')) {
      return monthString;
    }
    return dayString;
  }

  if (renderData.length > 5) {
    const lastMonth = i > 0 ? renderData[i - 1]?.date?.split('-')[1] : '';
    if (i === 0 || (month !== lastMonth && month !== '')) {
      return monthString;
    }
    return dayString;
  }

  return `${monthString} ${dayString}`;
};

export const reformatDateMMDDYYYY = date => moment(date, 'YYYY-MM-DD').format('MMMM DD, YYYY');

export const toolTipLabelFormatter = (v, renderData) => {
  const hasDate = Object.keys(renderData[0]).includes('date');
  if (hasDate) {
    return reformatDateMMDDYYYY(v);
  }
  return v;
};

export const getMaxValueWithoutInfinity = (arr, sideKey) => {
  const allValuesMapped = arr
    .map(obj => {
      const values = [];
      sideKey.forEach(k => values.push(obj[k]));
      return values;
    })
    .flat()
    .filter(v => v !== Infinity);
  return Math.max(...allValuesMapped);
};

export const findKeysWithInfinityValues = data => {
  if (data.length === 0) return [];

  const keysWithInfinityValues = [];
  data.forEach(item => {
    const keyNames = Object.keys(item).filter(key => !keysWithInfinityValues.includes(key));
    keyNames.forEach(keyName => {
      if (typeof item[keyName] === 'number' && !isFinite(item[keyName])) {
        keysWithInfinityValues.push(keyName);
      }
    });
  });
  return keysWithInfinityValues;
};

export const findColor = (key, YAxisStyle, tierColorMap = {}) => {
  if (YAxisStyle.length) {
    const findObj = YAxisStyle.find(entry => entry.keyName === key);
    return findObj ? findObj.color : '#026CAC';
  }
  return tierColorMap[key];
};

export const findYaxisSide = (key, YAxisStyle) => {
  if (YAxisStyle.length) {
    const findObj = YAxisStyle.find(entry => entry.keyName === key);
    return findObj && findObj.side ? findObj.side : 'Left';
  }
  return 'Left';
};

import React, { useState } from 'react';
import DemoVideoModal from './DemoVideoModal';
import DemoVideoHelpMessage from './DemoVideoHelpMessage';

const DemoVideoWrapper = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <DemoVideoModal {...{ open: modalOpen, onClose: () => setModalOpen(false) }} />
      <DemoVideoHelpMessage {...{ setModalOpen }} />
    </>
  );
};

export default DemoVideoWrapper;

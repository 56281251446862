import React from 'react';

import { Dropdown } from 'semantic-ui-react';

import { GROUPBY_DATE_OPTIONS } from './constants';

export const GroupByDropdown = ({ groupDatesBy, setGroupDatesBy }) => (
  <Dropdown
    style={{ margin: 10 }}
    selection
    options={GROUPBY_DATE_OPTIONS}
    value={groupDatesBy}
    onChange={(e, { value }) => setGroupDatesBy(value)}
  />
);

import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  List,
  Image,
  Button,
  Icon,
} from 'semantic-ui-react';
import { track } from '../../../../utils/tracking';
import * as routes from '../../../baseRoutes';

const WarehouseCard = ({ name, route, logo, items, featureEnabled }) => {
  const startIntercomChat = () => {
    const chatMessage = 'Hi! I\'m interested in upgrading to access the Data Warehousing integration.';
    if (window.Intercom) window.Intercom('showNewMessage', chatMessage);
    track('upgrade_contact.data_warehousing');
  };

  const buttonText = featureEnabled ? `Connect to ${name}` : 'Contact Us to Upgrade';

  return (
    <Card>
      <Card.Content>
        <Image src={`https://images.rockerbox.com/integrations/${logo}`} />
        <div style={{ padding: '15px 20px', height: '150px' }}>
          <p style={{ textAlign: 'center' }}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            To complete setup, you'll need
          </p>
          <List as="ul">
            {items.map(i => (
              <List.Item as="li">{i}</List.Item>
            ))}
          </List>
        </div>
        <Button
          primary
          fluid
          as={!!featureEnabled && Link}
          to={`${routes.datawarehouseSetup}/${route}`}
          onClick={!featureEnabled ? startIntercomChat : null}
        >
          {!featureEnabled
            && <Icon name="lock" />}
          {buttonText}
        </Button>
      </Card.Content>
    </Card>
  );
};

export default WarehouseCard;

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Checkbox, Message, Button, Segment, Icon } from 'semantic-ui-react';
import { ColumnLayout, ContentCard, DataTable } from '@rockerbox/styleguide';
import { isNumeric } from '../helpers';

/* eslint-disable no-shadow, no-param-reassign, eqeqeq */

const states = [
  {
    key: 'Approved',
    icon: 'check circle',
    color: 'green',
  },
  {
    key: 'Invalid Dates',
    content: 'You must enter valid date(s) for the campaign',
    icon: 'warning sign',
    color: 'red',
  },
  {
    key: 'Incorrect Spend Format',
    content: 'The spend value entered is in the incorrect format',
    icon: 'warning sign',
    color: 'red',
  },
  {
    key: 'Missing Spend',
    content: 'No spend was detected. Please enter a numerical spend value',
    icon: 'warning sign',
    color: 'red',
  },
  {
    key: 'Missing Name',
    content: 'You must enter a name to create a new entry',
    icon: 'warning sign',
    color: 'red',
  },
];

const VALIDATION_COLUMN = {
  style: { width: '1.9em' },
  display: '', key: 'state', type: 'text',
  asEdit: ({ value }) => value,
  asView: DataTable.StatusCell({ states }),
};

const PODCAST_COLUMNS = [
  VALIDATION_COLUMN,
  { display: 'Podcast Campaign', key: 'name', type: 'text' },
  { display: 'Release Date', key: 'start_date', type: 'date' },
  { display: 'Campaign Cost', key: 'cost', type: 'currency' },
];

const INFLUENCER_COLUMNS = [
  VALIDATION_COLUMN,
  { display: 'Promotion Name', key: 'name', type: 'text' },
  { display: 'Promotion / Post Date', key: 'start_date', type: 'date' },
  { display: 'Cost', key: 'cost', type: 'currency' },
];

const SITE_COLUMNS = [
  VALIDATION_COLUMN,
  { display: 'Campaign Name', key: 'name', type: 'text' },
  { display: 'Start Date', key: 'start_date', type: 'date' },
  { display: 'End Date', key: 'end_date', type: 'date' },
  { display: 'Cost', key: 'cost', type: 'currency' },
];

const EVENT_COST_COLUMNS = [
  { display: 'Tier 1', key: 'tier_1', type: 'text', readOnly: true },
  { display: 'Tier 2', key: 'tier_2', type: 'text', readOnly: true },
  { display: 'Tier 3', key: 'tier_3', type: 'text', readOnly: true },
  { display: 'Tier 4', key: 'tier_4', type: 'text', readOnly: true },
  { display: 'Tier 5', key: 'tier_5', type: 'text', readOnly: true },
  { display: 'Fixed Payout ($)', key: 'fixed_unit_cost', type: 'currency' },
  { display: 'Revenue Share (%)', key: 'rev_share_percent', type: 'percent' },
];

const PROMO_COST_COLUMNS = [
  { display: 'Description', key: 'name', type: 'text', readOnly: true },
  { display: 'Promo code', key: 'conversion_field_value', type: 'text', readOnly: true },
  { display: 'Discount ($)', key: 'fixed_discount', type: 'currency', readOnly: true },
  { display: 'Discount (%)', key: 'percent_discount', type: 'percent', readOnly: true },
  { display: 'Fixed Payout ($)', key: 'fixed_unit_cost', type: 'currency' },
  { display: 'Revenue Share (%)', key: 'rev_share_percent', type: 'percent' },
];

const paymentOptions = [
  { text: 'Campaign Cost', field: 'costViaSponsorship' },
  { text: 'Cost per Purchase', field: 'costViaPurchase' },
];

const SetupSpend = ({
  state, setState, updateStateField,
  removeItemFromList, addItemToList, setIsDisabled,
}) => {
  const { costViaSponsorship, costViaPurchase } = state;
  const { costs, promo_codes, events, name, entity_type } = state;
  const [issues, setIssues] = useState([]);

  useEffect(() => {
    const notApproved = costs.filter(row => row.state && row.state !== 'Approved');
    setIssues(notApproved);
    setIsDisabled(notApproved.length > 0);
  }, [costs]);

  const COST_COLUMNS = entity_type === 'podcast' ? PODCAST_COLUMNS
    : entity_type === 'influencer' ? INFLUENCER_COLUMNS
      : SITE_COLUMNS;

  const setEndDates = costsReset => costsReset.map(row => {
    const rowModified = { ...row };
    rowModified.end_date = moment(row.start_date).add(14, 'days').format('YYYY-MM-DD');
    return rowModified;
  });

  const onSpendUpdate = costs => {
    costs.forEach(row => {
      const missingName = row.name.length === 0;
      const validDates = [row.start_date, row.end_date].filter(date => date === '').length > 0;
      const missingSpend = !row.cost;
      const numericSpend = !missingSpend ? row.cost.toString().replace(/\$/g, '').replace(/,/g, '') : null;
      const incorrectSpendFormat = !isNumeric(numericSpend) || numericSpend === null || isNaN(numericSpend) || numericSpend < 0;
      row.cost = !incorrectSpendFormat ? `${numericSpend}` : row.cost;

      row.state = missingName ? 'Missing Name'
        : validDates ? 'Invalid Dates'
          : missingSpend ? 'Missing Spend'
            : incorrectSpendFormat ? 'Incorrect Spend Format' : 'Approved';
    });

    return COST_COLUMNS == SITE_COLUMNS ? setState({ costs }) : setState({ costs: setEndDates(costs) });
  };

  return (
    <ColumnLayout leftWidth={4} centerWidth={8} rightWidth={4}>
      <ContentCard>
        <Message header={`How do you pay ${name} for marketing?`} content="Choose all payment methods that apply" />
        <Segment secondary>
          {paymentOptions.map(row => {
            const { text, field } = row;
            if (field === 'costViaPurchase' && promo_codes.length === 0 && events.length === 0) return;
            const value = state[field];
            return (
              <>
                <Checkbox label={text} checked={value} onClick={() => updateStateField(field)(false, { value: !value })} />
                <br />
              </>
            );
          })}
        </Segment>
      </ContentCard>
      {costViaSponsorship && (
        <ContentCard title="Campaign / Sponsor Costs">
          <Message
            header="Record cost of sponsorships"
            content={`If you have multiple sponsorships for ${name}, you can record them here with flight dates.`}
          />
          <div style={{ width: '100%' }}>
            <DataTable
              {...{
                columns: COST_COLUMNS,
                data: costs,
                onUpdate: onSpendUpdate,
                onRemove: row => removeItemFromList('costs')(row),
              }}
            />
          </div>
          <br />
          <Button primary onClick={() => addItemToList('costs')} content="Campaign" icon="plus" size="mini" as="span" floated="right" />
          <br />
          <br />
          {issues.length > 0 && (
            <Segment>
              <Message
                content={(
                  <>
                    <Icon name="warning" color="orange" />
                    Issues have been detected with the campaigns entered above. You must resolve all issues before uploading.
                  </>
                )}
                negative
              />
            </Segment>
          )}
        </ContentCard>
      )}
      {costViaPurchase && (
        <>
          {promo_codes.length > 0 && (
            <ContentCard title="Promo Code Usage and Costs">
              <Message
                header="Record conversion costs"
                content="Promo-codes allow you to track certain marketing channels at the time of conversion as well as assign fixed unit costs and revenue based costs associated with the a conversion. If you need to add or modify other details about the promo-codes, please go back to tracking and make the necessary modifications." // eslint-disable-line
              />
              <div style={{ width: '100%' }}>
                <DataTable
                  {...{
                    columns: PROMO_COST_COLUMNS,
                    data: promo_codes,
                    onUpdate: promoCodes => setState({ promo_codes: promoCodes }),
                  }}
                />
                <br />
                <br />
              </div>
            </ContentCard>
          )}
          {events.length && (
            <ContentCard title="Record Affiliate Costs Associated with Events">
              <Message
                header="Record affiliate costs associated with marketing events"
                content="do some stuff here"
              />
              <div style={{ width: '100%' }}>
                <DataTable
                  {...{
                    columns: EVENT_COST_COLUMNS,
                    data: events,
                    onUpdate: eventsUpdate => setState({ events: eventsUpdate }),
                  }}
                />
                <br />
                <br />
              </div>
            </ContentCard>
          )}
        </>
      )}
    </ColumnLayout>
  );
};

export default SetupSpend;

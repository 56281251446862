import React, { useState, useMemo, useCallback, useEffect } from 'react';

import moment from 'moment';
import { Card } from 'semantic-ui-react';

import ConversionSummary from './ConversionSummary';
import { ConversionSummaryLoader } from './loaders';
import { track } from '../../../utils/tracking';
import { BasicViewHeader, FilterWrapper, DateRangeHooks, ViewAccessWrapper } from '../../components';
import { useGlobalState } from '../../hooks/global';
import { yesterday, monthAgo } from '../../utils/time';

const ConversionsOverview = () => {
  const { segments } = useGlobalState();

  const [startDate, setStartDate] = useState(monthAgo);
  const [endDate, setEndDate] = useState(yesterday);

  useEffect(() => {
    track('data.customers.conversions.main.view');
  }, []);

  const [featuredSegment, conversionSegments] = useMemo(() => {
    if (!segments) return [null, null];
    return [
      segments.find(s => !!s.featured),
      segments
        .filter(s => s.action_type === 'conversion' && !s.is_child && !s.featured)
        .sort((a, b) => a.display_order - b.display_order),
    ];
  }, [segments]);

  const onDateChange = useCallback(({ startDate: _startDate, endDate: _endDate }) => {
    const formatStartDate = moment(_startDate).format('YYYY-MM-DD');
    const formatEndDate = moment(_endDate).format('YYYY-MM-DD');

    setStartDate(formatStartDate);
    setEndDate(formatEndDate);
  });

  // TODO: handle case of no conversion segments

  return (
    <>
      <BasicViewHeader
        header="Orders &amp; Conversions"
        subheader="See Your Customer Data for All Your Conversions"
      />
      <FilterWrapper
        date={<DateRangeHooks {...{ startDate, endDate, onDateChange }} />}
      />
      <Card.Group>
        { !segments && <ConversionSummaryLoader featured /> }
        {
          !!featuredSegment
          && (
          <ConversionSummary
            featured
            {...{ segment: featuredSegment, startDate, endDate }}
          />
          )
        }
      </Card.Group>

      <Card.Group itemsPerRow={3} style={{ marginTop: 0 }}>
        {
          !segments && (
          <>
            <ConversionSummaryLoader />
            <ConversionSummaryLoader />
          </>
          )
        }
        {!!conversionSegments && conversionSegments.map(segment => (
          <ConversionSummary
            key={segment.action_id}
            {...{ segment, startDate, endDate }}
          />
        ))}
      </Card.Group>
    </>
  );
};

const ConversionsOverviewAccess = () => (
  <ViewAccessWrapper
    viewName="orders_and_conversions_summary"
    viewComponent={<ConversionsOverview />}
  />
);

export default ConversionsOverviewAccess;

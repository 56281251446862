import React, { Component } from 'react'
import { Grid, Menu, Header, Icon, Dropdown, Tab, Input, Popup, Segment } from 'semantic-ui-react'

import logoIconWhite from '../../../images/logo-white-icon.svg'
import logoIconWhiteAnimated  from '../../../images/logo-white-icon-animated.svg'

const AppNav = props => (
  <div className='nav-wrap'>
    {props.children}
  </div>
)

export class Top extends Component {

  handleLogout = (logoutURL) => {
    fetch(logoutURL, {'headers': {'accept': 'application/json'}}).then(d => {
      if (window.gapi) {
        try {
          var auth2 = window.gapi.auth2.getAuthInstance();
          auth2.signOut().then(() => {
            console.log('Google user signed out.');
          });
          window.location = '/login';
        }
        catch (e) {
          console.log(e);
          window.location = '/login';
        }
      }
    });
  }

  render(){
    const props = this.props

    return(
      <div className='nav-top'>
        <div className='grid-wrap'>
          <Grid fluid='true' columns='equal'>
            <Grid.Column textAlign='left'>
              <div
                onClick={props.onHomeClick}
                onMouseEnter={() => !props.homeActive && (this.logoImageRef.src = logoIconWhiteAnimated + '?' + new Date().getTime())}
                onMouseLeave={() => (this.logoImageRef.src = logoIconWhite)}
                className={`logo-wrap ${props.homeActive ? 'active' : ''}`}
              >
                <img
                  src={logoIconWhite}
                  ref={logoImageRef => this.logoImageRef = logoImageRef}
                  width={32}
                  className='logo-image'
                />
                <a className='logo-text'>
                  {props.homeText}
                </a>
              </div>
            </Grid.Column>
            <Grid.Column width={8} textAlign='center' className='nav-section-middle'>
              <Menu inverted secondary fluid>
                {
                  props.menuItems.map((menuItem, index) => menuItem.displayName && (
                    <TopItem key={menuItem.name}
                             name={menuItem.name}
                             displayName={menuItem.displayName}
                             icon={menuItem.iconName}
                             active={index === props.activeIndex}
                             onClick={() => props.handleChange(menuItem)} />
                  ))
                }
              </Menu>
            </Grid.Column>
            <Grid.Column textAlign='right' className='nav-section-right'>
              {
                props.menuItems.map((menuItem, index) => menuItem.displayName == "" && (
                  <TopItem key={menuItem.name}
                           as={(props) => {
                             return <Icon.Group 
                                 size='large' 
                                 className='notification-trigger notifications-trigger'
                                 name={props.name}
                                 active={props.active}
                                 onClick={props.onClick}
                               >
                                 { props.children }
                               </Icon.Group>
                             }
                           }
                           name={menuItem.name}
                           displayName={menuItem.displayName}
                           icon={menuItem.iconName}
                           active={index === props.activeIndex}
                           onClick={() => props.handleChange(menuItem)} />
                ))
              }
              <Popup
                on='click'
                position='bottom right'
                wide='very'
                style={{marginRight: -7}}
                trigger={
                  <Icon.Group
                    size='large'
                    className='notification-trigger notifications-trigger'
                  >
                    <Icon
                      name='bell outline'
                      inverted
                      className='notification-bell'
                    />
                    {(props.notifications && props.notifications.length) ?
                      <Icon
                        corner
                        name='circle'
                        color='red'
                        className='notification-dot'
                      />
                    : null}
                  </Icon.Group>
                }
                content={<Notifications messages={props.notifications} />}
              />
              <Popup
                on='click'
                position='bottom right'
                wide='very'
                style={{marginRight: -7}}
                trigger={
                  <Icon.Group
                    size='large'
                    className='help-trigger-wrapper'
                  >
                    <Icon
                      name='question circle outline'
                      inverted
                      className='help-trigger'
                    />
                  </Icon.Group>
                }
                content={
                  <HelpSection items={props.helpItems} />
                }
              />
              <Icon
                link
                inverted
                name='comments'
                size='large'
                className='help-trigger'
                onClick={props.onChatClick}
              />
              {props.companyOptions && props.companyOptions.length > 1 ?
                <AccountSwitcher {...props} />
              :
                <Header as='h5' inverted>
                  {props.companyName}
                </Header>
              }
              <Dropdown pointing='top right' icon={null}
                        trigger={(
                          <Icon.Group size='big' className='user-trigger'>
                            <Icon name='thin circle' color='purple' />
                            <Icon name='user circle' inverted  />
                          </Icon.Group>
                        )} >
                <Dropdown.Menu>
                  <Dropdown.Header content={props.username} />
                  <Dropdown.Divider />
                  <Dropdown.Item as='a' onClick={() => window.location = '/v3'} icon="sync alternate" text="Switch to New UI" />
                  <Dropdown.Item as='a' onClick={() => this.handleLogout(props.logoutPath)} icon="log out" text="Sign Out" style={{ color: '#ad2262' }} />
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    )
  }
}

class AccountSwitcher extends Component {
  state = { filteredOptions: this.props.companyOptions }

  // listener to check for clicking outside dropdown
  componentWillMount(){
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount(){
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  handleClick = event => {
    // if click is outside dropdown, close it down
    if (!this.containerRef.contains(event.target)) {
      this.setState({ opened: false })
    }
  }

  // handle clicking of dropdown trigger
  toggleOpen = () => {
    this.setState({
      query: '',
      filteredOptions: this.props.companyOptions,
      opened: !this.state.opened
    })
    // focus search input after opening dropdown
    setTimeout(this.focusInput, 10)
  }

  handleTriggerFocus = () => {
    if (!this.state.opened) this.toggleOpen()
  }

  focusInput = () => {
    this.inputRef.focus()
  }

  handleSearch = event => {
    this.setState({ query: event.target.value })
    const filtered = this.props.companyOptions.filter(tag => tag.text.toLowerCase().includes(event.target.value.toLowerCase()))
    this.setState({ filteredOptions: filtered })
  }

  handleChange = (event, chosen) => {
    if (this.props.onCompanyChange) this.props.onCompanyChange(event, chosen)
    this.setState({ opened: false })
  }

  setFocusedOption = value => {
    this.focusedOption = value;
  }

  handleEnter = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
      // get complete option object and call handleChange fn
      const selectedOption = this.state.filteredOptions.find((option) => option.value === this.focusedOption)
      this.handleChange(event, selectedOption)
    }
  }

  render(){
    const { opened, query, filteredOptions } = this.state
    const props = this.props

    return(
      <div ref={containerRef => this.containerRef = containerRef}>
        <Dropdown
          pointing='top right'
          icon=''
          className='company-switcher'
          trigger={
            <Header
              as='h5'
              inverted
              onMouseDown={this.toggleOpen}
              onFocus={this.handleTriggerFocus}
              tabIndex={0}
            >
              <span>{props.companyName}&nbsp;&nbsp;</span>
              <Icon name='caret down' size='mini' />
            </Header>
          }
        >
          <Dropdown.Menu open={opened}>
            <Input
              icon='search'
              iconPosition='left'
              ref={inputRef => this.inputRef = inputRef}
              value={query}
              onChange={this.handleSearch}
            />
            <Dropdown.Divider />
            <Dropdown.Header icon='exchange' content=' Switch Account' />
            <Dropdown.Menu scrolling>
              {filteredOptions.map((option, i) =>
                <Dropdown.Item
                  {...option}
                  onClick={this.handleChange}
                  active={option.text === props.companyName}
                  tabIndex={0}
                  onFocus={() => this.setFocusedOption(option.value)}
                  onKeyDown={this.handleEnter}
                />
              )}
            </Dropdown.Menu>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  }
}

const TopItem = props => {
  const Component = props.as || Menu.Item
  return (
    <Component name={props.name}
               active={props.active}
               onClick={props.onClick}>
      <Icon inverted name={props.icon} />
      {props.displayName}
    </Component>
  )
}

export const Sub = props => (
  <div className='nav-sub'>
    <Tab menu={{ secondary: true, pointing: true }}
         panes={props.panes}
         activeIndex={props.activeIndex}
         onTabChange={props.onTabChange} />
  </div>
)

const Notifications = ({ messages }) => {
  if (messages && messages.length){
    return(messages.map(item =>
      <Segment vertical>
        <Header as='h3'>
          <Icon name='exclamation triangle' color='yellow' />
          <Header.Content>
            {item.title}
            <Header.Subheader>{item.message}</Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
    ))
  }

  return <NotificationEmpty />
}

const NotificationEmpty = () => (
  <Segment basic>
    <Header as='h5'>
      <Icon name='check' color='green' />
      <Header.Content>
        No Notifications
      </Header.Content>
    </Header>
  </Segment>
)

const HelpSection = ({ items }) => (
  items.map(item =>
    <Segment
      vertical
      className='help-section'
      key={item.title}
    >
      <Header
        as='h5'
        onClick={item.onClick ? item.onClick : () => window.open(item.link, '_blank')}
      >
        {item.icon &&
          <Icon
            disabled
            name={item.icon}
          />
        }
        <Header.Content>
          {item.title}
          <Header.Subheader>
            {item.description}
          </Header.Subheader>
        </Header.Content>
      </Header>
    </Segment>
  )
)

export default AppNav

export function getUserRBAC(id) {
  const url = `/auth-tools/rbac/users/${id}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(response => response.response)
    .catch(error => console.error(`Failed to get RBAC user id ${id} with error: ${error.message}`)); // eslint-disable-line no-console
}
export function postUserRBAC(id, role) {
  const obj = { user_ids: [id], role };
  const url = '/auth-tools/rbac/users';
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  })
    .then(response => response.json())
    .then(response => response.response)
    .catch(error => console.error(`Failed to add RBAC user id ${id} with error: ${error.message}`)); // eslint-disable-line no-console
}

export function updateUserRBAC(id, role) {
  const obj = { role };
  const url = `/auth-tools/rbac/users/${id}`;
  return fetch(url, {
    credentials: 'include',
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  })
    .then(response => response.json())
    .then(response => response.response)
    .catch(error => console.error(`Failed to update RBAC user id ${id} with error: ${error.message}`)); // eslint-disable-line no-console
}

export function deleteUserRBAC(id) {
  const url = `/auth-tools/rbac/users/${id}`;
  return fetch(url, {
    credentials: 'include',
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(response => response.response)
    .catch(error => console.error(`Failed to delete RBAC user id ${id} with error: ${error.message}`)); // eslint-disable-line no-console
}

export function getAllUsersRBAC() {
  const url = '/auth-tools/rbac/users';
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(response => response.response)
    .catch(error => console.error(`Failed to get all RBAC users with error: ${error.message}`)); // eslint-disable-line no-console
}

export function getAdvertiserRBAC(pixel_source_name) {
  const url = `/auth-tools/rbac/advertisers/${pixel_source_name}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(response => response.response)
    .catch(error => console.error(`Failed to get RBAC advertiser with error: ${error.message}`)); // eslint-disable-line no-console
}

export function getRolesInRBAC() {
  const url = '/auth-tools/rbac/roles';
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(response => response.response)
    .catch(error => console.error(`Failed to get RBAC roles with error: ${error.message}`)); // eslint-disable-line no-console
}

export function getAllPermissionsInRBAC() {
  const url = '/auth-tools/rbac/permissions';
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(response => response.response)
    .catch(error => console.error(`Failed to get RBAC permissions with error: ${error.message}`)); // eslint-disable-line no-console
}

export function getUserAuthorizationActions(id) {
  const url = `/auth-tools/rbac/users/${id}/authorized-actions`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(response => response.response)
    .catch(error => console.error(`Failed to get RBAC authorization actions for 
    user ${id} with error: ${error.message}`)); // eslint-disable-line no-console
}

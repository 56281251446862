import moment from "moment";

const dateFormats = ["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD"];

const jsFormatToPythonFormat = (str) =>
  str
    .replace("YYYY", "%Y")
    .replace("MM", "%m")
    .replace("DD", "%d")
    .replace("HH", "%H")
    .replace("mm", "%M")
    .replace("ss", "%S");

const isInt = (n) => {
  return n % 1 === 0;
};

const castType = (value) => {
  const type = typeof value;
  if (type === "string") {
    if (moment(value, dateFormats, true).isValid()) {
      return "datetime";
    } else {
      return "str";
    }
  }
  if (type === "number") {
    return isInt(value) ? "int" : "float";
  }
  if (type === "object") {
    return "json";
  }
};

export const generateDefaultInboundSchema = (displayData) => {
  const example =
    Object.values(displayData)[0] && Object.values(displayData)[0][0];
  if (!example) {
    return "{}";
  }

  const defaultValue = Object.entries(example).reduce((prev, cur) => {
    const [key, value] = cur;
    if (value == null) {
      return prev;
    }
    const body = {
      type: castType(value),
      from: key,
    };

    if (body["type"] === "datetime") {
      body["format"] = jsFormatToPythonFormat(
        moment(value, dateFormats, true)["_f"]
      );
    }

    prev[key] = body;
    return prev;
  }, {});

  return JSON.stringify(defaultValue, undefined, 4);
};

export const prettyInitialValue = (report, cols) => {
  if (!report) {
    return {};
  }

  const newSavedReport = Object.assign({}, report);

  cols.forEach((col) => {
    if (newSavedReport[col]) {
      newSavedReport[col] = JSON.stringify(
        JSON.parse(newSavedReport[col]),
        undefined,
        4
      );
    }
  });

  return newSavedReport;
};

import React, { useEffect, useState } from 'react';

import { Table, Input, Dropdown, Icon, Popup } from 'semantic-ui-react';

import { useBudgetPeriod } from '../budget/useBudgetPeriod';
import { SplitLayout, FormWrapSegment, FormDescription } from '../Layout';

const ConstraintRow = ({ isConversionKPI, featureName, feature, featureNameMap, setConstraints, budgetPeriod }) => {
  const { min_value, max_value, min_roas } = feature;
  const [localMin, setLocalMin] = useState(min_value);
  const [localMax, setLocalMax] = useState(max_value);
  const [localROAS, setLocalROAS] = useState(min_roas);
  const [issue, setIssue] = useState(false);

  const onChange = featureValue => (e, { value }) => {
    if (featureValue === 'min_value') setLocalMin(parseInt(value / budgetPeriod));
    if (featureValue === 'max_value') setLocalMax(parseInt(value / budgetPeriod));
    if (featureValue === 'min_roas') setLocalROAS(parseInt(value * 10) / 10);
  };

  useEffect(() => {
    // validate
    if (localMin > localMax) {
      setIssue('Cannot have a min value greater than max value');
      return;
    }

    setIssue(false);

    const values = {};
    if (localMin || localMin === 0) values.min_value = localMin;
    if (localMax || localMax === 0) values.max_value = localMax;
    if (localROAS) {
      isConversionKPI ? values.min_roas = 1 / localROAS : values.min_roas = localROAS;
    }

    setConstraints({ [featureName]: values });
  }, [localMin, localMax, localROAS]);

  const metricText = isConversionKPI ? 'CPA' : 'ROAS';

  return (
    <Table.Row key={featureName}>
      <Table.Cell>
        {featureNameMap[featureName] || featureName}
        &nbsp; &nbsp;
        {!!issue && <Popup open content={issue} trigger={<Icon name="warning circle" color="red" />} />}
      </Table.Cell>
      <Table.Cell>
        <Input error={!!issue} icon="dollar" iconPosition="left" defaultValue={min_value * budgetPeriod} placeholder="Min Spend" onChange={onChange('min_value')} />
      </Table.Cell>
      <Table.Cell>
        <Input error={!!issue} icon="dollar" iconPosition="left" defaultValue={max_value * budgetPeriod} placeholder="Max spend" onChange={onChange('max_value')} />
      </Table.Cell>
      <Table.Cell>
        <Input
          defaultValue={min_roas && isConversionKPI ? 1 / min_roas : min_roas}
          iconPosition={isConversionKPI ? 'left' : false}
          icon={isConversionKPI ? 'dollar' : false}
          placeholder={isConversionKPI ? `Max ${metricText}` : `Min ${metricText}`}
          onChange={onChange('min_roas')}
        />
      </Table.Cell>
    </Table.Row>
  );
};

const Constraints = ({ isConversionKPI, featureNameMap, constraints, setConstraints, setConstraintPercentage }) => {
  const { budgetPeriod, budgetPeriodName } = useBudgetPeriod();
  const [collapsed, setCollapsed] = useState(true);
  const [percentage, setPercentage] = useState();

  const _setConstraintPercentage = newPercentage => {
    setPercentage(newPercentage);
    setConstraintPercentage(newPercentage);
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const percentageOptions = [
    { text: '15% (Tight)', value: 0.15 },
    { text: '30% (Recommended)', value: 0.3 },
    { text: '50%', value: 0.5 },
    { text: '100%', value: 1 },
  ];

  const metricText = isConversionKPI ? 'CPA' : 'ROAS';

  return (
    <SplitLayout
      header="5. Setup Constraints"
      leftContent={(
        <FormWrapSegment>
          <Dropdown selection onChange={(e, { value }) => _setConstraintPercentage(value)} options={percentageOptions} defaultValue={0.3} placeholder="Select Percentage" />
          <div
            role="button"
            tabIndex={0}
            onClick={toggleCollapse}
            onKeyDown={toggleCollapse}
            style={{ cursor: 'pointer', margin: '25px 0 10px' }}
          >
            <h5>+ Refine Constraints per Channel</h5>
          </div>
          {!collapsed && (
            <div style={{ width: '50%' }}>
              <Table key={percentage}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell content="Feature" />
                    <Table.HeaderCell content={`Min ${budgetPeriodName} Spend`} />
                    <Table.HeaderCell content={`Max ${budgetPeriodName} Spend`} />
                    <Table.HeaderCell content={`${metricText} Target`} />
                  </Table.Row>
                </Table.Header>
                {Object.entries(constraints).map(([key, feature]) => (
                  <ConstraintRow
                    {...{ isConversionKPI, featureName: key, feature, featureNameMap, setConstraints, budgetPeriod }}
                  />
                ))}
              </Table>
            </div>
          )}
        </FormWrapSegment>
      )}
      rightContent={(
        <FormDescription>
          Constraints are a neccessary part of optimizing to a specific scenario.
          {' '}
          <br />
          <br />
          By default, constaints are bounded to a range based on the chosen baseline. You can modify this range and further refine these constraints on a per channel basis to match your knowledge of
          what is possible within a channel or to maintain specific performance in a channel.
        </FormDescription>
      )}
    />
  );
};

export default Constraints;

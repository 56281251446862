import React from 'react';
import { Container, Image, Header } from 'semantic-ui-react';
import { spendPreviewImage } from '@rockerbox/styleguide';

const defaultMessage = "Your account is not setup to use this feature. Reach out to Rockerbox customer success to learn more."

const EmptyFeature = (props) => (
  <Container textAlign='center'>
    <Image
      src={spendPreviewImage}
      size='medium'
      bordered
      circular
      centered
      className='spend-preview-image m-t-20'
    />
    <Header as='h2'>
      <Header.Subheader>{ props.message || defaultMessage }</Header.Subheader>
      { props.callToAction ? <><br/> { props.callToAction } </> : "" }
    </Header>
  </Container>
)

export default EmptyFeature;

import React, { useEffect, useState } from 'react';

import toast from 'react-hot-toast';
import { Table, Button, Icon } from 'semantic-ui-react';

import { track } from '../../../utils/tracking';
import { postPixelTrackingResolution } from '../../api/attributionPixels';

export const FeedbackCell = platform => ({ item, col }) => {
  const savedResolution = item[col.key];
  const [resolution, setResolution] = useState(null);

  // set resolution from saved value
  useEffect(() => {
    setResolution(savedResolution);
  }, [savedResolution]);

  // eslint-disable-next-line no-shadow
  const submitResolution = async resolution => {
    const res = await postPixelTrackingResolution({
      platform,
      resolution,
      hash: item.rowHash,
      params: item.params,
      url: item.url,
    });
    const { success } = res || {};
    if (!success) {
      toast.error('Error submitting resolution');
      return;
    }
    setResolution(resolution);
    track('data.marketing.advertising_platforms.tracking.resolve', { platform, resolution });
  };

  return (
    <Table.Cell collapsing style={{ textAlign: 'center' }} key={item.rowHash}>
      {!resolution ? (
        <Button.Group basic size="tiny">
          <Button
            size="tiny"
            icon="check"
            content="Fixed"
            onClick={() => submitResolution('fixed')}
          />
          <Button
            size="tiny"
            icon="alarm mute"
            content="Ignore"
            onClick={() => submitResolution('ignore')}
          />
        </Button.Group>
      ) : (
        <Button
          basic
          disabled
          size="tiny"
          color={resolution === 'fixed' && 'green'}
          icon={resolution === 'fixed' ? 'check' : 'alarm mute'}
          content={resolution === 'fixed' ? 'Fixed' : 'Ignored'}
          className="remove-box-shadow"
        />
      )}
    </Table.Cell>
  );
};

export const PagesCell = ({ item, col }) => (
  <Table.Cell>
    {!!item[col.key]?.length && item[col.key].map(({ url, path }, i) => (
      <div className="ellipsed" key={i + path}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          {path}
        </a>
      </div>
    ))}
  </Table.Cell>
);

export const OpenAdsManagerCell = platformDisplayName => ({ item, col }) => (
  <Table.Cell>
    <a href={item[col.key]} target="_blank" rel="noopener noreferrer">
      {`Open in ${platformDisplayName} Ads Manager `}
      <Icon name="external" />
    </a>
  </Table.Cell>
);

import moment from 'moment';
import d3 from 'rockerbox_d3_legacy_clone';

export default {
  datetime: {
    monthly: value => moment(value).format('YYYY-MM-01'),
    weekly: value => moment(value).startOf('week').format('YYYY-MM-DD'),
    daily: value => moment(value).format('YYYY-MM-DD'),
  },
  number: {
    sum: values => d3.sum(values),
    'percent-row': (values, { rowKey, rowSummary }) => (rowKey ? d3.sum(values) / rowSummary[rowKey] : d3.sum(values)),
    'percent-col': (values, { colKey, colSummary }) => (colKey ? d3.sum(values) / colSummary[colKey] : d3.sum(values)),
    'percent-all': (values, { colKey, colSummary }) => (colKey ? d3.sum(values) / d3.sum(Object.values(colSummary)) : d3.sum(values)),
  },
  string: {
    string: value => value,
  },
};

import React from 'react';
import * as d3 from 'rockerbox_d3_legacy_clone';
import moment from 'moment';

import AssignPromoCode from './AssignPromoCode';
import { StatCard, IndexGrid, IndexGridNew, ContentCard } from '@rockerbox/styleguide';
import { Message, Menu, Form, Button, Segment, Dropdown, Table, Header } from 'semantic-ui-react';

import { getEntityProgramConfigs, getEntityConfigs, getDataset, getSegments } from '../../utils/api';
import { Currency } from '../../utils/format_helpers'
import { useConversionEntityNameMemo, useMissingAssignedCodesMemo } from './memo';
import {forceNewPath } from "../hooks";
import { promoResults } from "../../routes";
import { promoCodesEdit } from '../../V3/baseRoutes';

const daysAgo = (num) => moment.utc().utcOffset(-5).subtract(num, "days").format("YYYY-MM-DD")

const PromoCodes = (props) => {
  const [promo_field, setPromoField] = React.useState("coupon")
  const [discountField, setDiscountField] = React.useState("discount")
  const [showAdvanced, setShowAdvanced] = React.useState(false)
  const [segments, setSegments] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [entityConfigs, setEntityConfigs] = React.useState(false)
  const [entityProgramConfigs, setEntityProgramConfigs] = React.useState(false)
  const [conversionData, setConversionData] = React.useState(false)
  const [selectedMenu, setSelectedMenu] = React.useState("missing")
  const [selectedItem, setSelectedItem] = React.useState(false);

  const [endDate, setEndDate] = React.useState(daysAgo(1));
  const [startDate, setStartDate] = React.useState(daysAgo(7));

  const [sponsorEditLink, setSponsorEditPath] = forceNewPath('', promoResults)

  React.useEffect(() => {
    setLoading(true)
    getEntityConfigs()
      .then(setEntityConfigs)
    getEntityProgramConfigs()
      .then(setEntityProgramConfigs)
  }, [])

  React.useEffect(() => {
    if (entityConfigs === false) return
    if (entityConfigs.length == 0) {
      setShowAdvanced(true)
      return
    }

    const configWithPromoCode = entityConfigs.find(config => config.promo_codes.length > 0)
    const defaultCodes = configWithPromoCode.promo_codes[0]
    
    const { conversion_field_name } = defaultCodes || {}
    setPromoField(conversion_field_name)

  }, [entityConfigs])


  const entitySegment = React.useMemo(() => {
    if (entityConfigs === false) return [];
    
    if (entityConfigs == false && segments) return segments
      .filter(row => row.featured)
      .map(row => Object.assign({ segment_id: row.action_id, promo_field}))

    const entitySegmentObj = entityConfigs.reduce((p,c) => {
      if (c.segments.length) p[c.segments[0].segment_id] = 1
      return p
    }, {})

    const entitySegmentArr = Object.keys(entitySegmentObj)

    return entitySegmentArr
      .map(segment_id => Object.assign({ segment_id, promo_field}))

  }, [entityConfigs, segments, startDate, endDate])

  React.useEffect(() => {
    if (entityConfigs === false) return ;
    if (entityConfigs == false && segments === false) {
      getSegments()
        .then(setSegments)
      return ;
    }
    setLoading(true)

    entitySegment.slice(0,1).map(({ segment_id }) => {
      getDataset("conversion_data_path", segment_id, false, 'cache', startDate, endDate)
        .then(data => data.response['conversion_data_path'])
        .then(setConversionData)
    })

  }, [entityConfigs, entitySegment, startDate, endDate])

  const fieldOptions = React.useMemo(() => {
    if (!conversionData || conversionData.length == 0) return []
    const standardObj = conversionData.reduce((p,c) => Object.assign(p,c), {})
    return Object.keys(standardObj).map(value => Object.assign({text: value, value}))
  }, [conversionData])

  const conversionsWithEntityName = useConversionEntityNameMemo(conversionData, entityConfigs, promo_field);
  const { missingPromoCodes, assignedPromoCodes } = useMissingAssignedCodesMemo(conversionsWithEntityName, discountField);

  const discountAnalysis = React.useMemo(() => {
    const grouped = d3.nest()
      .key(row => row.promoCode)
      .rollup(values => {
        return values.reduce((p,c) => {
          try {
            const discount = parseFloat(c[discountField])
            const original = c.revenue + discount

            p.field = c.field
            p.entityName = c.entityName
            p.entityId = c.entityId
            p.discount_type = c.discount_type
            p.revenue += c.revenue
            p.discount += discount
            p.discount_amount = c.discount_type == "fixed_amount" ? `$${c[discountField]}` : discount ? `${Math.round(c[discountField]/(original)*100)}%` : "-"
            p.count += 1
            p.aov = p.revenue / p.count
            return p
          } catch(e) {}
        }, {revenue:0, discount: 0, count:0})
      })
      .entries(conversionsWithEntityName)
      .map(row => Object.assign({promoCode: row.key}, row.values))
      .sort((p,c) => c.count - p.count)

    return grouped

  }, [discountField, conversionsWithEntityName])

  const totalDiscount = discountAnalysis.reduce((p,c) => p + c[discountField], 0)
  const totalRevenue = discountAnalysis.reduce((p,c) => p + c.revenue, 0)
  const noPromoOrders = (conversionData||[]).filter(c => !c[promo_field] )
  const noPromoRevenue = noPromoOrders.reduce((p,c) => p + c.revenue, 0)

  const onEdit = (item, col) => setSelectedItem(item)
  const goToSponsorshipEdit = (item) => setSponsorEditPath(`${promoCodesEdit}/${item.entityId}`)

  const tableCellPromoCodeAction = (item, col) => {
    if (item.entityName === undefined) {
      onEdit(item, col)
    }
  }

  const entityNameColumn = React.memo(({item, col}) => {
    if (item.entityName !== undefined) {
      return <Table.Cell style={{cursor: "pointer"}} onClick={() => goToSponsorshipEdit(item)}>
        <a>{item[col.key]}</a>
      </Table.Cell>
    } else {
      return <Table.Cell/>
    }
  })

  const promoCodeColumn = React.memo(({ item, col }) => {
    if (item.entityName !== undefined) {
      return <Table.Cell>{item[col.key]}</Table.Cell>
    } else {
      return <Table.Cell style={{cursor:"pointer"}} onClick={() => tableCellPromoCodeAction(item, col) }>
        <a>{ item[col.key] }</a>
      </Table.Cell>
    }
  })

  const currencyColumn = ({ item, col }) => (
    <Table.Cell>
      <Currency amount={item[col.key]}/>
    </Table.Cell>
  )

  const columnCells = {
    "entityName": entityNameColumn,
    "promoCode": promoCodeColumn,
    "revenue": currencyColumn,
    "discount": currencyColumn,
    "aov": currencyColumn,
  }

  const DISPLAY_NAMES = {
    "promoCode": "Promo Code",
    "count": "Num Uses",
    "discount_type": "Discount Type",
    "discount_amount": "Discount Amount",
    "entityName": "Associated Sponsorship",
    "revenue": "Total Revenue",
    "discount": "Total Discount",
    "aov": "Avg Order Value",
  }

  const missingColumns = IndexGrid.arrayToColumns(["promoCode", "count", "discount_type", "discount_amount"])
    .map(row => {
      row.display = DISPLAY_NAMES[row.key] || row.display
      return Object.assign(row, {as: columnCells[row.key]})
    })

  const analysisColumns = IndexGrid.arrayToColumns(["entityName","promoCode", "count", "discount_type", "discount_amount", "revenue", "discount", "aov"])
    .map(row => {
      row.display = DISPLAY_NAMES[row.key] || row.display
      return Object.assign(row, {as: columnCells[row.key]})
    })

  analysisColumns[0].isSearchable = true
  analysisColumns[1].isSearchable = true

  const addItem = (item) => {
    setEntityConfigs([...entityConfigs, item])
    setSelectedItem(false)
  }
  const updateItem = (item) => {
    const filtered = entityConfigs.filter(row => (row.name != item.name) || (row.id != item.id))
    setEntityConfigs([...filtered, item])
    setSelectedItem(false)
  }

  const hasEntityConfigs = entityConfigs && entityConfigs.length > 0

  return <ContentCard
    title="Promo Codes"
    topRight={<span>
      <Button size="tiny" basic icon="cog" onClick={() => setShowAdvanced(!showAdvanced)} />
    </span>}
  >
   { showAdvanced && <>
       { !hasEntityConfigs && <Message content="You currently have no sponsorships. Choose the field for the promo code you will use to construct sponsorships to get started assigning promotions to sponsorships." /> }
       <Form>
         <Form.Group>
           <Form.Field>
             <label>Promo Code Field Name</label>
             <Dropdown selection search value={promo_field}
               options={fieldOptions}
               onChange={(evt, { value }) => setPromoField(value)}
             />
           </Form.Field>
           <Form.Field>
             <label>Discount Value</label>
             <Dropdown selection search value={discountField}
               options={fieldOptions}
               onChange={(evt, { value }) => setDiscountField(value)}
             />
           </Form.Field>
         </Form.Group>
       </Form>
     </>
   }
    <AssignPromoCode
      onClose={({ add, update }) => add ? addItem(add) : update ? updateItem(update) : setSelectedItem(false)}
      open={!!selectedItem}
      startDate={startDate}
      endDate={endDate}
      entityData={entityConfigs}
      entityPrograms={entityProgramConfigs}
      entitySegment={entitySegment}
      selectedItem={selectedItem}
    />
    <Menu pointing secondary>
      <Menu.Item name='Overview' active={selectedMenu == "overview"} onClick={() => setSelectedMenu("overview")} />
      <Menu.Item name='Discount Analysis' active={selectedMenu == "discount"} onClick={() => setSelectedMenu("discount")} />
      <Menu.Item name='Missing Assignment' active={selectedMenu == "missing"} onClick={() => setSelectedMenu("missing")} />
    </Menu>
    { selectedMenu == "overview" && <>
      <Header as='h4'>Promo Code Usage</Header>
      <Segment secondary style={{flexDirection:"row", display:"flex"}}>
        <div style={{flex:0.5}}/>
        <StatCard value={ conversionData.length } label="Num Conversions" style={{flex:1}}/>
        <div style={{fontSize:"3em",lineHeight:"100px",height:"100px",padding:"10px",width:"40px",textAlign:"center"}}> * </div>
        <StatCard value={ conversionsWithEntityName.length/conversionData.length } label="Promo Usage Rate" type="percent" style={{flex:1}}/>
        <div style={{fontSize:"3em",lineHeight:"80px",height:"100px",padding:"10px",width:"40px",textAlign:"center"}}> = </div>
        <StatCard value={ conversionsWithEntityName.length } label="Promo Used" style={{flex:1}}/>
        <div style={{flex:0.5}}/>
      </Segment>

      <Header as='h4'>Average Discount</Header>
      <Segment secondary style={{flexDirection:"row", display:"flex"}}>
        <div style={{flex:0.5}}/>
        <StatCard value={ totalDiscount } label="Discounts" type="currency" style={{flex:1}}/>
        <div style={{fontSize:"3em",lineHeight:"90px",height:"100px",padding:"10px",width:"40px",textAlign:"center"}}> / </div>
        <StatCard value={ totalRevenue } label="Revenue (Promo Used)" type="currency" style={{flex:1}}/>
        <div style={{fontSize:"3em",lineHeight:"80px",height:"100px",padding:"10px",width:"40px",textAlign:"center"}}> = </div>
        <StatCard value={ totalDiscount/(totalDiscount + totalRevenue)} label="Average Discount" type="percent" style={{flex:1}}/>
        <div style={{flex:0.5}}/>
      </Segment>

      <Header as='h4'>Average Order Size</Header>
      <Segment secondary style={{flexDirection:"row", display:"flex"}}>
        <div style={{flex:0.5}}/>
        <StatCard value={ noPromoRevenue/noPromoOrders.length } label="Without Promo" type="currency" style={{flex:1}}/>
        <div style={{fontSize:"3em",lineHeight:"80px",height:"100px",padding:"10px",width:"40px",textAlign:"center"}}>  </div>
        <StatCard value={ (totalRevenue + totalDiscount)/conversionsWithEntityName.length } label="With Promo (Before Applied)" type="currency" style={{flex:1}}/>
        <div style={{fontSize:"3em",lineHeight:"90px",height:"100px",padding:"10px",width:"40px",textAlign:"center"}}>  </div>
        <StatCard value={ totalRevenue/conversionsWithEntityName.length } label="With Promo (After Applied)" type="currency" style={{flex:1}}/>
        <div style={{flex:0.5}}/>
      </Segment>
      </>
    }
    { selectedMenu == "missing" && <IndexGridNew sortable paginate={!!missingPromoCodes} itemsPerPage={20} data={missingPromoCodes||false} cols={missingColumns} /> }
    { selectedMenu == "discount" && <IndexGridNew searchable sortable paginate={!!discountAnalysis} itemsPerPage={20} data={discountAnalysis||false} cols={analysisColumns} /> }
  </ContentCard>
}

export default PromoCodes;

import { useState, useEffect, useMemo } from 'react';

import { getLegacyPaths } from '../../../api/attributionCache';
import { addMetrics } from './helpers';
import { EXCLUDED_TIERS } from './constants';
import { marketingEventToChannel, marketingEventToPlatform, marketingEventToStrategy } from './eventTransforms';

const PATHS_DATASET = 3; // path_t3

export const useGetPathsRaw = (segId, startDate, endDate) => {
  const [pathsRaw, setPathsRaw] = useState(undefined);

  useEffect(() => {
    if (!segId || Number(segId) === 0) return;
    setPathsRaw(undefined);
    getLegacyPaths(segId, PATHS_DATASET, startDate, endDate)
      .then(rawData => {
        setPathsRaw(rawData);
      })
      .catch(err => console.log(err)); // eslint-disable-line no-console
  }, [segId, startDate, endDate]);

  return pathsRaw;
};

export const useIndexedPaths = pathsRaw => {
  const organicRegex = /organic/;
  const exclusionRegex = new RegExp(EXCLUDED_TIERS.join('|'));

  const {
    platformToPathIndex,
    strategyToPathIndex,
    channelToPathIndex,
    tierNToPathIndex,
    pathIndexToTierN,
  } = useMemo(
    () => {
      if (!pathsRaw) return {};

      const newTierNToPathIndex = {};
      const newPathIndexToTierN = {};

      // TODO: build these indices
      const newChannelToPathIndex = {};
      const newStrategyToPathIndex = {};
      const newPlatformToPathIndex = {};

      const TIER_INDICES = [0, 1, 2];
      console.time('process path indices'); // eslint-disable-line no-console
      pathsRaw.forEach(({ path }, index) => {
        path.forEach(marketingEvent => {
          const tierCombined = marketingEvent.join('___').toLowerCase();

          // skip adding to indices if organic or part of exclusion list
          if (tierCombined.match(organicRegex)) return null;
          if (tierCombined.match(exclusionRegex)) return null;

          const channel = marketingEventToChannel(tierCombined);
          if (channel) {
            newChannelToPathIndex[channel] = newChannelToPathIndex[channel] || new Set();
            newChannelToPathIndex[channel].add(index);
          }

          const platform = marketingEventToPlatform(tierCombined);
          if (platform) {
            newPlatformToPathIndex[platform] = newPlatformToPathIndex[platform] || new Set();
            newPlatformToPathIndex[platform].add(index);
          }

          const strategy = marketingEventToStrategy(tierCombined);
          if (strategy) {
            newStrategyToPathIndex[strategy] = newStrategyToPathIndex[strategy] || new Set();
            newStrategyToPathIndex[strategy].add(index);
          }

          TIER_INDICES.forEach(n => {
          // get tierN of current marketing event in path
            const tierNOfMarketingEvent = marketingEvent[n];

            // skip adding to indices if tier value is purely numeric
            if (parseInt(tierNOfMarketingEvent) === tierNOfMarketingEvent) return;

            // Make indices that can locate each path by a specifc marketing event (tier_n)
            const currentTier = newTierNToPathIndex[n] || {};
            newTierNToPathIndex[n] = currentTier;
            currentTier[tierNOfMarketingEvent] = currentTier[tierNOfMarketingEvent] || new Set();
            currentTier[tierNOfMarketingEvent].add(index);

            // Make indices that can locate each tier by the index of a marketing path
            const currentPathByIndexLookup = newPathIndexToTierN[index] || {};
            newPathIndexToTierN[index] = currentPathByIndexLookup;
            currentPathByIndexLookup[n] = currentPathByIndexLookup[n] || new Set();
            currentPathByIndexLookup[n].add(tierNOfMarketingEvent);
          });
        });
      });
      console.timeEnd('process path indices'); // eslint-disable-line no-console

      return {
        strategyToPathIndex: newStrategyToPathIndex,
        platformToPathIndex: newPlatformToPathIndex,
        channelToPathIndex: newChannelToPathIndex,
        tierNToPathIndex: newTierNToPathIndex,
        pathIndexToTierN: newPathIndexToTierN,
      };
    },
    [pathsRaw],
  );

  const findMatchingPaths = (_pattern, type = 'tier', comparisonType = 'tier') => {
    const pattern = _pattern.toLowerCase();
    const tiers = Object.keys(tierNToPathIndex);

    let matchingIndices = [];

    if (type === 'channel') {
      const tokens = Object.keys(channelToPathIndex);
      const matchedTokens = tokens.filter(token => token.toLowerCase().includes(pattern));
      const matchedPathIndices = matchedTokens.flatMap(token => [...channelToPathIndex[token]]);
      matchingIndices = [...new Set(matchedPathIndices)];
    } else if (type === 'platform') {
      const tokens = Object.keys(platformToPathIndex);
      const matchedTokens = tokens.filter(token => token.toLowerCase().includes(pattern));
      const matchedPathIndices = matchedTokens.flatMap(token => [...platformToPathIndex[token]]);
      matchingIndices = [...new Set(matchedPathIndices)];
    } else if (type === 'strategy') {
      const tokens = Object.keys(strategyToPathIndex);
      const matchedTokens = tokens.filter(token => token.toLowerCase().includes(pattern));
      const matchedPathIndices = matchedTokens.flatMap(token => [...strategyToPathIndex[token]]);
      matchingIndices = [...new Set(matchedPathIndices)];
    } else {
      tiers.forEach(tier => {
        const currentTier = tierNToPathIndex[tier];
        const tokens = Object.keys(currentTier);
        const matchedTokens = tokens.filter(token => token.toLowerCase().includes(pattern));

        const matchedPathIndices = matchedTokens.flatMap(token => [...currentTier[token]]);
        matchingIndices.push(...new Set(matchedPathIndices));
      });
    }
    const matchingSet = new Set(matchingIndices);
    const filteredPaths = pathsRaw.filter((path, index) => matchingSet.has(index));

    const comparisonTier = 0;

    const processed = {};
    const processedPlatform = {};
    const overlappingKeys = [];

    const matchedTokens = [];

    const comparsionFunction = type === 'channel'
      ? marketingEventToChannel : type === 'platform'
        ? marketingEventToPlatform : type === 'strategy'
          ? marketingEventToStrategy : evt => (evt.includes(pattern) ? pattern : '');

    // classify paths
    filteredPaths.forEach(path => {
      // generate a list of platforms in each path
      const neighboringEvents = path.path.map(tp => {
        const tierCombined = tp.join('___').toLowerCase();

        // TODO: reimplement this for the other search types
        const comparator = (comparsionFunction(tierCombined) || '').toLowerCase();
        if (comparator === pattern) {
          matchedTokens.push(tierCombined.replace(/___/g, ' — '));
          return null;     // exclude the touchpoint that matches the search
        }
        if (tierCombined.match(organicRegex)) return null;
        if (tierCombined.match(exclusionRegex)) return null;

        const channel = marketingEventToChannel(tierCombined);
        const platform = marketingEventToPlatform(tierCombined);
        const strategy = marketingEventToStrategy(tierCombined);
        const inspectCategories = `${channel} — ${platform} — ${strategy}`;

        const compareBy = comparisonType === 'channel'
          ? channel : comparisonType === 'platform'
            ? platform : comparisonType === 'strategy'
              ? strategy : comparisonType === 'inspect'
                ? inspectCategories : comparisonType === 'tiers'
                  ? tierCombined.replace(/___/g, ' — ') : (tp[comparisonTier] || '').toLowerCase();

        return compareBy;
      });
      const neighborSet = [...new Set(neighboringEvents)].filter(p => !!p);

      addMetrics(processedPlatform, path); // Add to total platform counts

      if (!neighborSet.length) {
        // console.log(path)
        return; // if there are not other touchpoints, dont bother counting
      }

      addMetrics(processedPlatform, path, 'overlap'); // If registered touchpoint exists add also to overlap platform counts

      neighborSet.forEach(p => {
        processed[p] = processed[p] || {};
        addMetrics(processed[p], path);
        addMetrics(processed[p], path, 'overlap');
        overlappingKeys.push(p);
      });
    });

    processed.platform = processedPlatform;

    const matches = [...new Set(matchedTokens)].map(token => token.split('___'));

    return {
      matchedTokens: matches,
      paths: processed,
      overlapChannels: [...new Set(overlappingKeys)],
    };
  };

  const channelOptions = channelToPathIndex
    ? Object.keys(channelToPathIndex).map(text => ({ text, value: text }))
    : [];

  const platformOptions = platformToPathIndex
    ? Object.keys(platformToPathIndex).map(text => ({ text, value: text }))
    : [];

  const strategyOptions = strategyToPathIndex
    ? Object.keys(strategyToPathIndex).map(text => ({ text, value: text }))
    : [];

  return {
    channelOptions,
    platformOptions,
    strategyOptions,
    findMatchingPaths,
    tierNToPathIndex,
    pathIndexToTierN,
  };
};

import React, { useEffect } from 'react';
import { DateRangeHooks, FilterWrapper, StyledDropdown, SaveReportButton, ResetFilterButton } from '../../../components';
import { NTF_OPTIONS } from '../../../constants/options';

const Filter = ({
  loading, // data
  startDate, endDate, onDateChange, firstReportingDate, // dates
  ntfFilter, setNtfFilter, hasNtf, // ntf
  segOptions, segId, setSegId, // segments
}) => {
  useEffect(() => {
    if (hasNtf === '') return; // return if segId has not loaded yet
    if (!hasNtf) setNtfFilter('all');
  }, [hasNtf]);

  return (
    <FilterWrapper
      {...{ loading }}
      other={(
        <>
          <StyledDropdown
            label="Conversion"
            options={segOptions}
            value={parseInt(segId)}
            onChange={(e, { value }) => setSegId(value)}
          />
          <StyledDropdown
            label="Customer Type"
            disabled={!hasNtf}
            options={NTF_OPTIONS}
            value={ntfFilter}
            onChange={(e, { value }) => setNtfFilter(value)}
          />
        </>
      )}
      date={<DateRangeHooks {...{ startDate, endDate, onDateChange, firstReportingDate }} />}
      stickyRight={(
        <>
          <SaveReportButton {...{ loading }} />
          <ResetFilterButton />
        </>
      )}
    />
  );
};

export default Filter;

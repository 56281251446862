/* eslint-disable import/no-extraneous-dependencies */
import * as aq from 'arquero';
import { tableToIPC, tableFromIPC } from 'apache-arrow';

import { default as init, readParquet } from '../parquet'; // eslint-disable-line import/no-named-default, no-unused-vars

/*
* Example of raw response to arrow table
* const parquetUint8Array = new Uint8Array(await resp.arrayBuffer());
* const arrowUint8Array = readParquet(parquetUint8Array);
* const arrowTable = tableFromIPC(arrowUint8Array);u
*/

// parquet buffer
export const parquetBufferToArrowBufferIPC = async parquetBuffer => {
  const arrowBufferIPC = await readParquet(parquetBuffer);
  return arrowBufferIPC;
};

// arrow type converters
export const arrowToArquero = arrow => {
  const arquero = aq.fromArrow(arrow);
  return arquero;
};
export const arrowToArrowBufferIPC = arrow => {
  const arrowBufferIPC = tableFromIPC(arrow);
  return arrowBufferIPC;
};

// arrow buffer
export const arrowBufferIPCToArrowTable = arrowBufferIPC => {
  const arrowTable = tableFromIPC(arrowBufferIPC);
  return arrowTable;
};
export const arrowBufferIPCToArquero = arrowBufferIPC => {
  const arrowTable = arrowBufferIPCToArrowTable(arrowBufferIPC);
  const arquero = arrowToArquero(arrowTable);
  return arquero;
};

// raw buffer
export const rawBufferToParquetBuffer = rawArrayBuffer => {
  const parquetBuffer = new Uint8Array(rawArrayBuffer);
  return parquetBuffer;
};
export const rawBufferToArrowBufferIPC = async rawArrayBuffer => {
  const parquetBuffer = rawBufferToParquetBuffer(rawArrayBuffer);
  const arrowBufferIPC = await parquetBufferToArrowBufferIPC(parquetBuffer);
  return arrowBufferIPC;
};
export const rawBufferToArrow = async rawArrayBuffer => {
  const arrowBufferIPC = await rawBufferToArrowBufferIPC(rawArrayBuffer);
  const arrow = tableFromIPC(arrowBufferIPC);
  return arrow;
};
export const rawBufferToArquero = async rawArrayBuffer => {
  const arrowBufferIPC = await rawBufferToArrowBufferIPC(rawArrayBuffer);
  const arquero = arrowBufferIPCToArquero(arrowBufferIPC);
  return arquero;
};

// json type converters
export const jsonToArquero = json => {
  const table = aq.from(json);
  return table;
};
export const jsonToArrow = json => {
  const arrow = jsonToArquero(json).toArrow();
  return arrow;
};
export const jsonToArrowBufferIPC = json => {
  const arrow = jsonToArrow(json);
  const arrowBufferIPC = tableToIPC(arrow);
  return arrowBufferIPC;
};

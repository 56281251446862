import React, { useState, useEffect, useMemo } from 'react';

import moment from 'moment';
import { Popup, Dropdown } from 'semantic-ui-react';

import DateRangePicker from './DateRangePicker';
import { Label, DateWrap, CalendarButton, CalendarDropdown } from '../stateless';
import { PRESET_PROJECTION_RANGES, tomorrow, isAfterTomorrow, monthFromToday, daysAfter, numDaysLater, reformatDateMMDDYYYY, reformatDateYYYYMMDD } from '../time';

const MMMDateRangeHooks = ({
  startDate = tomorrow, endDate = monthFromToday, onDateChange = () => { }, options = PRESET_PROJECTION_RANGES, dateRange, // dates
  marginTop, marginBottom, // styling
  label = false,
  localStorageKey = 'mmmDateRange',
}) => {
  const [showCustom, setShowCustom] = useState(false);
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);
  const [presetValue, setPresetValue] = useState(undefined);
  const [activeText, setActiveText] = useState('');
  const [recentDatesOptions, setRecentDatesOptions] = useState([]);

  const presetOptions = useMemo(() => {
    if (!options.length) return PRESET_PROJECTION_RANGES.map(({ text, numDays }) => ({ key: text, text, value: numDays }));
    if (Object.keys(options[0]).includes('value')) {
      return options;
    }
    return options.map(({ text, numDays }) => ({ key: text, text, value: numDays }));
  }, [options]);

  const checkForCustomDates = (startDateCheck, endDateCheck) => {
    const formattedDateValue = `${reformatDateMMDDYYYY(startDateCheck)} - ${reformatDateMMDDYYYY(endDateCheck)}`;
    const presetFound = presetOptions.find(({ value }) => value === formattedDateValue);
    if (presetFound) {
      setPresetValue(formattedDateValue);
      setActiveText(presetFound.text);
      if (startDateCheck !== start || endDateCheck !== end) {
        setStart(startDateCheck);
        setEnd(endDateCheck);
      }
      return false;
    }
    const checkDaysLater = numDaysLater(startDateCheck, endDateCheck, true);
    if (checkDaysLater !== 'custom') {
      setPresetValue(checkDaysLater);
      const findActiveText = options.find(({ numDays }) => numDays === checkDaysLater);
      setActiveText(findActiveText.text);
      return false;
    }
    setPresetValue(formattedDateValue);
    setActiveText(formattedDateValue);
    return formattedDateValue;
  };

  useEffect(() => {
    if (!localStorageKey) return;
    const dates = JSON.parse(localStorage.getItem(localStorageKey)) || [];
    const removeOldDates = dates
      .filter(({ value }) => isAfterTomorrow(value.split(' - ')[0]))
      .map(row => Object.assign(row, { key: row.value }));
    setRecentDatesOptions(removeOldDates);
    checkForCustomDates(startDate, endDate);
  }, []);

  useEffect(() => {
    checkForCustomDates(startDate, endDate);
  }, [presetOptions, startDate, endDate]);

  useEffect(() => {
    if (start && end) {
      onDateChange({ startDate: start, endDate: end });
    }
    checkForCustomDates(start, end);
  }, [start, end]);

  useEffect(() => {
    if (showCustom) setShowCustom(false);
  }, [endDate, activeText]);

  useEffect(() => {
    if (presetValue === 'custom') {
      const customDateValue = checkForCustomDates(start, end);
      if (!customDateValue || !localStorageKey) return;

      const dates = JSON.parse(localStorage.getItem(localStorageKey)) || [];
      const foundInDates = dates.find(({ value }) => value === customDateValue);
      if (!foundInDates) {
        const customObj = {
          key: customDateValue,
          text: customDateValue,
          value: customDateValue,
        };
        const removeOldDates = dates.filter(({ value }) => isAfterTomorrow(value.split(' - ')[0]));
        const last4Dates = removeOldDates.length > 4 ? removeOldDates.slice(0, 4) : removeOldDates;
        localStorage.setItem(localStorageKey, JSON.stringify([customObj, ...last4Dates]));
        setRecentDatesOptions([customObj, ...last4Dates]);
      }
    }
  }, [presetValue, start, end]);

  const onPresetDatesChange = (e, { text, value }) => {
    setPresetValue(value);
    setActiveText(text);
    if (typeof value === 'number') {
      setStart(tomorrow);
      setEnd(daysAfter(value));
      return;
    }
    const [customStart, customEnd] = value.split(' - ');
    setStart(reformatDateYYYYMMDD(customStart));
    setEnd(reformatDateYYYYMMDD(customEnd));
  };

  const setStartEnd = ({ startDate: startUpdate, endDate: endUpdate }) => {
    setPresetValue('custom');
    setStart(startUpdate.format('YYYY-MM-DD'));
    setEnd(endUpdate.format('YYYY-MM-DD'));
  };

  return (
    <div>
      {label && <Label>{Label}</Label>}
      <div style={{ marginTop, marginBottom, display: 'flex', height: 'auto', minWidth: 'auto' }}>
        <CalendarDropdown
          fluid
          text={activeText}
          scrolling={false}
        >
          <Dropdown.Menu
            style={{
              minWidth: '100%',
              fontFamily: 'Poppins',
              maxHeight: '300px',
              overflowY: 'auto',
            }}
          >
            {presetOptions.map(({ key, text, value }) => (
              <Dropdown.Item
                {...{ key, text, value }}
                onClick={onPresetDatesChange}
                active={value === presetValue}
                selected={value === presetValue}
              />
            ))}
            {recentDatesOptions.length > 0 && (
              <>
                <Dropdown.Divider />
                <Dropdown.Header content="Recent date ranges" />
              </>
            )}
            {recentDatesOptions.map(({ key, text, value }) => (
              <Dropdown.Item
                {...{ key, text, value }}
                onClick={onPresetDatesChange}
                active={value === presetValue}
                selected={value === presetValue}
              />
            ))}
          </Dropdown.Menu>
        </CalendarDropdown>
        <Popup
          wide="very"
          trigger={(
            <CalendarButton
              icon="calendar alternate"
              onClick={() => setShowCustom(true)}
              active={showCustom}
            />
          )}
          content={(
            <DateWrap>
              <DateRangePicker
                startDate={moment(start)}
                endDate={moment(end)}
                onChange={setStartEnd}
                dateRange={dateRange}
              />
            </DateWrap>
          )}
          on="click"
          open={showCustom}
          onClose={() => setShowCustom(false)}
          onOpen={() => setShowCustom(true)}
          position="bottom right"
          pinned
          positionFixed
          flowing
          basic
          style={{
            height: 396,
            width: 346,
          }}
        />
      </div>
    </div>
  );
};

export default MMMDateRangeHooks;

/*
>>>>>>>> EXAMPLE <<<<<<<<<<
<MMMDateRangeHooks
  {...{startDate, endDate, onDateChange, marginTop: '0', marginBottom: '0',   fluid: true }}
/>
*/

import React from 'react';
import { Message } from 'semantic-ui-react';
import { getMessages } from '../api/account';
import CacheContext from '../../utils/CacheContext';

const GenericMessage = ({ style, message, as }) => {
  const [,, Context] = React.useContext(CacheContext);
  const messages = Context.getCache('messages', getMessages) || [];

  const activeMessage = messages.find(({ name }) => name === message) || {};
  const { header, text, link } = activeMessage;

  if (!text) return null;

  const content = () => (
    <>
      <div dangerouslySetInnerHTML={{ __html: text }} />
      { link && (
      <>
        <br />
        <a href={link} target="_blank" rel="noreferrer">Learn More ⤏</a>
      </>
      ) }
    </>
  );

  const Component = as || Message;

  return <Component {...{ key: message, header, content, style }} />;
};

export default GenericMessage;

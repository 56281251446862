import { useEffect } from 'react';
import { yesterday, monthAgo } from '../../../utils/time';
import { useURLStateAndSetDefault } from '../../../hooks/urlState';

const getFeaturedSegmentId = segments => {
  const { filter_id } = segments.find(x => x.featured === 1);
  return filter_id;
};

const checkIsValidSegment = (id, segments) => !!segments.find(x => Number(x.filter_id) === Number(id));

export const usePageParams = segments => {
  const [id, setId] = useURLStateAndSetDefault('id', '0', true);
  const [startDate, setStartDate] = useURLStateAndSetDefault('startDate', monthAgo, true);
  const [endDate, setEndDate] = useURLStateAndSetDefault('endDate', yesterday, true);
  const [creditRedistributionId, setCreditRedistributionId] = useURLStateAndSetDefault('creditRedistributionId', '0', true);

  const hasSegments = segments && segments.length > 0;
  const isValidSegment = checkIsValidSegment(id, segments || []);

  useEffect(() => {
    if (id === undefined) setId(0); // make sure the default state is set correctly
    if (creditRedistributionId === undefined) setCreditRedistributionId(0);
  }, []);

  useEffect(() => {
    if (isValidSegment || !hasSegments) return;
    const defaultId = getFeaturedSegmentId(segments);
    setId(defaultId);
  }, [id, segments]);

  return {
    id, setId,
    isValidSegment, // TODO: warning in UI if segment is invalid and no featured?
    startDate, setStartDate,
    endDate, setEndDate,
    creditRedistributionId, setCreditRedistributionId,
  };
};

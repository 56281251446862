import React, { useMemo } from 'react';
import { Button } from 'semantic-ui-react';
import moment from 'moment';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BarChartLoader, TreeTableLoader } from '../../components/loaders';
import * as routes from '../../baseRoutes';

const Parent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Overlay = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1; 
`;

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; 
  max-width: 100%; 
  max-height: 100%; 
  text-align: center; 
`;

const FunnelSVG = () => (
  <svg width="1360" height="460" viewBox="0 0 1360 460" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path fill="#FAFAFA" d="M0 460V60l272 131 272 68 272 138.398L1088 434h272v26z" />
      <path fill="#F5F5F5" d="M0 460V115l272 112.988 272 58.65 272 119.367 272 31.57h272V460z" />
      <path fill="#FAFAFA" d="M0 460V165l272 96.613 272 50.15L816 413.83l272 26.994h272V460z" />
      <path fill="#F5F5F5" d="M0 460V216l272 79.91 272 41.48 272 84.423 272 22.327h272V460z" />
      <path fill="#FAFAFA" d="M0 460V260l272 65.5 272 34 272 69.199L1088 447h272v13z" />
      <path fill="#F5F5F5" d="M0 460V299l272 52.728 272 27.37 272 55.705 272 14.732h272V460z" />
      <path fill="#FAFAFA" d="M0 460V331l272 42.248 272 21.93 272 44.633 272 11.804h272V460z" />
      <path fill="#F5F5F5" d="M0 460V360l272 32.75 272 17 272 34.6 272 9.15h272v6.5z" />
      <path fill="#FAFAFA" d="M0 460v-74l272 24.235 272 12.58 272 25.604 272 6.771h272V460z" />
      <path fill="#F5F5F5" d="M0 460v-50l272 16.375 272 8.5 272 17.3 272 4.575h272V460z" />
      <path fill="#FAFAFA" d="M0 460v-25l272 8.188 272 4.25 272 8.65 272 2.287h272V460z" />
      <path fill="#F5F5F5" d="M0 460v-9l272 2.947 272 1.53 272 3.114 272 .824h272V460z" />
      <g fill="#F7F7F7">
        <path d="M1085 0h1v460h-1zM814 0h1v460h-1zM543 0h1v460h-1zM272 0h1v460h-1zM0 0h1v460H0z" />
      </g>
      <g fill="#D8D8D8" fillRule="nonzero">
        <text fontFamily="Inter-Light, Inter" fontSize="20" fontWeight="300" transform="translate(1106)">
          <tspan x="0" y="39">—</tspan>
        </text>
        <text fontFamily="Inter-Medium, Inter" fontSize="14" fontWeight="400" letterSpacing="1" transform="translate(1106)">
          <tspan x=".5" y="14">PURCHASE</tspan>
        </text>
      </g>
      <g fill="#D8D8D8" fillRule="nonzero">
        <text fontFamily="Inter-Light, Inter" fontSize="20" fontWeight="300" transform="translate(835)">
          <tspan x="0" y="39">—</tspan>
        </text>
        <text fontFamily="Inter-Medium, Inter" fontSize="14" fontWeight="400" letterSpacing="1" transform="translate(835)">
          <tspan x=".5" y="14">CHECKOUT</tspan>
        </text>
      </g>
      <g fill="#D8D8D8" fillRule="nonzero">
        <text fontFamily="Inter-Light, Inter" fontSize="20" fontWeight="300" transform="translate(564)">
          <tspan x="0" y="39">—</tspan>
        </text>
        <text fontFamily="Inter-Medium, Inter" fontSize="14" fontWeight="400" letterSpacing="1" transform="translate(564)">
          <tspan x=".5" y="14">ADD TO CART</tspan>
        </text>
      </g>
      <g fill="#D8D8D8" fillRule="nonzero">
        <text fontFamily="Inter-Light, Inter" fontSize="20" fontWeight="300" transform="translate(293)">
          <tspan x=".5" y="39">—</tspan>
        </text>
        <text fontFamily="Inter-Medium, Inter" fontSize="14" fontWeight="400" letterSpacing="1" transform="translate(293)">
          <tspan x="0" y="14">VIEW PRODUCT</tspan>
        </text>
      </g>
      <g fill="#D8D8D8" fillRule="nonzero">
        <text fontFamily="Inter-Light, Inter" fontSize="20" fontWeight="300" transform="translate(20)">
          <tspan x=".5" y="39">—</tspan>
        </text>
        <text fontFamily="Inter-Medium, Inter" fontSize="14" fontWeight="400" letterSpacing="1" transform="translate(20)">
          <tspan x="0" y="14">VISITORS</tspan>
        </text>
      </g>
    </g>
  </svg>
);

const FunnelConfigurationRedirectButton = () => (
  <Button
    primary
    content="Funnel Configuration"
    as={Link}
    to={routes.conversionFunnelConfig}
  />
);

const EmptyFunnel = ({
  funnelConfiguration, hasInvalidSegments,
  rbDataAvailable, firstReportingDateFromAllSegments,
}) => {
  const formatDate2WeeksPost = useMemo(() => {
    if (!firstReportingDateFromAllSegments) return;
    return moment(firstReportingDateFromAllSegments).add(2, 'weeks').format('MMM Do');
  }, [firstReportingDateFromAllSegments]);

  const text = hasInvalidSegments
    ? {
      heading: 'Invalid Segments in Funnel Configuration',
      subheading: 'Please check your funnel configuration set up in your settings.',
      button: <FunnelConfigurationRedirectButton />,
    }
    : !rbDataAvailable
      ? {
        heading: 'We need to compile more data ... hang tight',
        subheading: `It takes a few weeks of monitoring your marketing for us to generate our multi-touch model. 
        We estimate this report will be ready by ${formatDate2WeeksPost}. 
        We will send you an e-mail reminder when it is time.`,
      }
      : {
        heading: 'This conversion funnel view has not been set up yet.',
        subheading: 'Please go to your funnel configuration settings from the button below and set up.',
        button: <FunnelConfigurationRedirectButton />,
      };

  if (!funnelConfiguration) {
    return (
      <>
        <BarChartLoader />
        <TreeTableLoader showSearch />
      </>
    );
  }

  return (
    <Parent>
      <Overlay><FunnelSVG /></Overlay>
      <Content>
        <h1>{text.heading}</h1>
        <h3>{text.subheading}</h3>
        {text.button && <div>{text.button}</div>}
      </Content>
    </Parent>
  );
};

export default EmptyFunnel;

import React from 'react';
import { Loader } from 'semantic-ui-react';

const InputFormPricing = ({ estimatedPrices, promoCode, codePending, isEdit, pricePending }) => {
  if (isEdit) {
    return null;
  } if (promoCode) {
    if (codePending) {
      return (
        <>
          <p className="monthly-price"> Based on your monthly advertising spend, your price would be: </p>
          <div className="monthly-price-value">
            $
            {' '}
            <Loader active inline size="small" />
            {' '}
            per month plus tax
          </div>
        </>
      );
    }
    return (
      <>
        <p className="monthly-price"> Based on your monthly advertising spend, your price would be: </p>
        <p className="monthly-price-value">
          {' '}
          <span style={{ textDecoration: 'line-through' }}>
            $
            {estimatedPrices.original}
          </span>
          <span>
            {' '}
            $
            {estimatedPrices.discounted}
            {' '}
            per month plus tax
          </span>
        </p>
      </>
    );
  }
  if (pricePending) {
    return (
      <>
        <p className="monthly-price"> Based on your monthly advertising spend, your price would be: </p>
        <div className="monthly-price-value">
          $
          {' '}
          <Loader active inline size="small" />
          {' '}
          per month plus tax
        </div>
      </>
    );
  }
  return (
    <>
      <p className="monthly-price"> Based on your monthly advertising spend, your price would be: </p>
      <p className="monthly-price-value">
        $
        {estimatedPrices.original}
        {' '}
        per month plus tax
      </p>
    </>
  );
};
export default InputFormPricing;

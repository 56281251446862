/* eslint import/prefer-default-export: 0 */

import * as aq from 'arquero';

import { getStats, getTotals, filterTiers, filterSearch } from '../arqueroHelpers';
import { getDailyTiersSummary, getChartData } from '../helpers';
import { useMemoWithLog, applyModifiersArquero, s } from './helpers';
import { TIERS, DERIVED_TIERS, TIERS_LIMITED } from '../../../constants/tiers';

// eslint-disable-next-line
export const useComparisonDataArq = (
  comparisonDataRaw,
  tiersSummary,
  dailyTiersSummary,
  chartData,
  selectedRedistribution,
  search,
  derivedTiersFilter,
  filters,
  conversionKey,
  revenueKey,
  startDate,
  endDate,
  compareStart,
  compareEnd,
  metricColumn,
  groupDatesBy,
  numRowsExceedThreshold,
  limitedTiers = false,
  limitedComparisonTiers = false,
) => {
  const useLimitedDatasets = (limitedTiers || limitedComparisonTiers);
  const TIERS_RANGE = useLimitedDatasets ? TIERS_LIMITED : TIERS;
  const { tier_1, tier_2, tier_3, tier_4, tier_5 } = filters;

  const maxTiersGroupby = numRowsExceedThreshold ? useLimitedDatasets ? TIERS_RANGE.slice(0, 2) : TIERS_RANGE.slice(0, 4) : TIERS_RANGE;
  const maxGroupby = ['date', ...maxTiersGroupby, ...DERIVED_TIERS];

  const comparisonDataReduced = useMemoWithLog(() => (
    !!comparisonDataRaw ? getStats(maxGroupby, comparisonDataRaw, conversionKey, revenueKey, compareStart, compareEnd, true) : false
  ), [comparisonDataRaw, search, maxGroupby]);

  const comparisonData = useMemoWithLog(() => (
    !!comparisonDataRaw ? filterSearch(comparisonDataReduced, search, derivedTiersFilter, useLimitedDatasets) : false
  ), [comparisonDataReduced, search, derivedTiersFilter]);

  const comparisonSummaryRaw = useMemoWithLog(() => {
    if (!comparisonData) return false;
    return getStats(maxGroupby.slice(1), comparisonData, conversionKey, revenueKey, compareStart, compareEnd, true);
  }, [comparisonData, conversionKey, revenueKey, compareStart, compareEnd]);

  const comparisonSummaryRawTotals = useMemoWithLog(() => (
    comparisonSummaryRaw ? getTotals(comparisonSummaryRaw) : false
  ), [comparisonSummaryRaw]);

  const filteredComparison = useMemoWithLog(() => (
    !!comparisonData ? filterTiers(comparisonData, filters) : false
  ), [comparisonData, s(tier_1), s(tier_2), s(tier_3), s(tier_4), s(tier_5)]);

  const filteredComparisonSummary = useMemoWithLog(() => (
    filteredComparison ? getStats(maxGroupby.slice(1), filteredComparison, conversionKey, revenueKey, compareStart, compareEnd, true) : false
  ), [filteredComparison, conversionKey, revenueKey, compareStart, compareEnd]);

  const filteredComparisonSummaryTotals = useMemoWithLog(() => (
    filteredComparisonSummary ? getTotals(filteredComparisonSummary) : false
  ), [filteredComparisonSummary]);

  const dailyComparisonSummary = useMemoWithLog(
    () => {
      if (!filteredComparison) return false;
      if (!selectedRedistribution.weights) {
        return getStats(['date', 'tier_1'], filteredComparison, conversionKey, revenueKey, startDate, endDate);
      }

      const { weights } = selectedRedistribution;
      const modifiedFilterTiers = applyModifiersArquero(filteredComparison, weights, conversionKey, revenueKey, undefined, true);

      return getStats(['date', 'tier_1'], modifiedFilterTiers, conversionKey, revenueKey, startDate, endDate);
    },
    [filteredComparison, selectedRedistribution, conversionKey, revenueKey, startDate, endDate],
  );

  const dailySummaryCompare = useMemoWithLog(() => (
    filteredComparison ? getStats(['date'], filteredComparison, conversionKey, revenueKey, compareStart, compareEnd) : false
    // filteredComparison ? getStats(['date'], filteredComparison, conversionKey, revenueKey, compareStart, compareEnd) : false
  ), [filteredComparison, conversionKey, revenueKey, compareStart, compareEnd]);

  const compareSummaryTable = useMemoWithLog(
    () => (!!comparisonSummaryRaw ? filterTiers(comparisonSummaryRaw, filters) : []),
    [comparisonSummaryRaw, s(tier_1), s(tier_2), s(tier_3), s(tier_4), s(tier_5)],
  );

  const comparisonSummary = useMemoWithLog(
    () => {
      if (!compareSummaryTable || compareSummaryTable.length === 0) return [];
      if (!selectedRedistribution.weights) return compareSummaryTable.objects();

      const { weights } = selectedRedistribution;
      const modified = applyModifiersArquero(compareSummaryTable, weights, conversionKey, revenueKey, undefined, true);

      return modified.objects();
    },
    [compareSummaryTable, selectedRedistribution, conversionKey],
  );

  const compareChartData = useMemoWithLog(() => {
    if (!dailySummaryCompare || dailySummaryCompare.length !== chartData.length) {
      return [];
    }
    const d = getChartData(dailyComparisonSummary, metricColumn, revenueKey, conversionKey, compareStart, compareEnd, groupDatesBy, true);
    const joined = chartData.toSorted().map((x, i) => ({
      date: x.date,
      compare_date: d[i].date,
      total: Object.keys(x).filter(k => k !== 'date').reduce((p, c) => p + x[c], 0)?.toFixed(2),
      total_compare: Object.keys(d[i]).filter(k => k !== 'date').reduce((p, c) => p + d[i][c], 0)?.toFixed(2),
      [x.date]: {
        ...x,
        total: Object.values(x).filter(Number.isFinite).reduce((a, b) => a + b, 0),
      },
      [d[i].date]: {
        ...d[i],
        total: Object.values(d[i]).filter(Number.isFinite).reduce((a, b) => a + b, 0),
      },
    }));
    return joined;
  }, [dailySummaryCompare, chartData, metricColumn, revenueKey, conversionKey, groupDatesBy]);

  const compareLineChartData = useMemoWithLog(() => {
    if (!dailyComparisonSummary || compareChartData.length < 1) {
      return [];
    }
    const d = getChartData(dailyComparisonSummary, metricColumn, revenueKey, conversionKey, compareStart, compareEnd, groupDatesBy, true);
    const data = getChartData(dailyTiersSummary, metricColumn, revenueKey, conversionKey, startDate, endDate, groupDatesBy, true);
    if (d.length < 1) return [];
    const joined = data.toSorted().map((x, i) => ({
      date: x.date,
      compare_date: d[i].date,
      total: x.total?.toFixed(2),
      total_compare: d[i].total?.toFixed(2),
      [x.date]: x,
      [d[i].date]: d[i],
    }));
    return joined;
  }, [dailyComparisonSummary, dailyTiersSummary, metricColumn, revenueKey, conversionKey, compareStart, compareEnd, startDate, endDate, groupDatesBy]);

  const compareData = useMemoWithLog(() => {
    if (!tiersSummary.length) return false;

    const tiers = aq.from(tiersSummary);
    const filteredTiers = useLimitedDatasets ? tiers.select(aq.not('tier_4', 'tier_5')) : tiers;

    const groupedTiers = filteredTiers.groupby(...maxTiersGroupby);
    if (!comparisonSummary.length) return groupedTiers;

    const groupedComparison = aq.from(comparisonSummary).groupby(...maxTiersGroupby);
    // trying to do this with filteredTiers and filteredComparison gives a bigint issue

    const columns = Object.keys(groupedTiers.columns());
    const renames = columns.filter(c => !TIERS_RANGE.includes(c)).reduce((p, c) => Object.assign(p, { [`${c}_1`]: c }), {});
    const data = groupedTiers.join_full(groupedComparison, [maxTiersGroupby, maxTiersGroupby], undefined, { suffix: ['_1', '_compare'] }).rename(renames);

    return data;
  }, [tiersSummary, filteredComparison]);

  if (!comparisonData || comparisonData.length === 0 || compareChartData.length === 0) {
    return {
      compareLoading: true,
      compareData: [],
      compareChartData: [],
      compareLineChartData: [],
      comparisonSummaryRawTotals,
      filteredComparisonSummaryTotals: {},
      dailyComparisonSummary: getDailyTiersSummary([], conversionKey, revenueKey, compareStart, compareEnd),
      dailySummaryCompare: getDailyTiersSummary([], conversionKey, revenueKey, compareStart, compareEnd),
    };
  }

  return {
    compareLoading: false,
    compareData: compareData ? compareData.objects() : [],
    compareChartData,
    compareLineChartData,
    comparisonSummaryRawTotals: comparisonSummaryRawTotals.objects()[0] || {},
    filteredComparisonSummaryTotals: filteredComparisonSummaryTotals.objects()[0] || {},
    dailyComparisonSummary,
    dailySummaryCompare,
  };
};

import React from "react";
import { ContentCard } from "@rockerbox/styleguide";
import { Input, Icon, Message } from "semantic-ui-react";

export const CopyIcon = props => {
  return (
    <Icon
      name="copy outline"
      link
      onClick={() => {
        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
          navigator.clipboard.writeText(props.path);
        } else {
          console.log(
            "Current browser doesn't support navigator.clipboard.writeText()"
          );
        }
      }}
    />
  );
};

const InstructionSetupCard = props => {
  return (
    <ContentCard title="Instruction">
      <Message success>
        <Message.Header>
          Your Google Cloud data warehouse has been setup.
        </Message.Header>
        <Message.List>
          <Message.Item>{`Rockerbox created a new storage bucket: ${props.bucket &&
            props.bucket.bucket_name} for you`}</Message.Item>
          <Message.Item>{`File will be delivered to new bucket daily.`}</Message.Item>
          <Message.Item>{`Use below GCS bucket path to create table(External Table) in Big Query.`}</Message.Item>
          <Message.Item>{`Proper role has been granted to your manage account.`}</Message.Item>
        </Message.List>
      </Message>
      {props.paths.map(path => (
        <Input fluid icon={<CopyIcon path={path} />} value={path} key={path} />
      ))}
    </ContentCard>
  );
};

export const LoadingCard = props => <ContentCard loading={true} />;
export const NotAvailableCard = props => {
  return (
    <ContentCard>
      <Message warning>Alpha feature. Not available yet.</Message>
    </ContentCard>
  );
};

export const SetupFailureCard = props => {
  return (
    <ContentCard>
      <Message
        error
        header="Snowflake Setup failed"
        content="Pleasae contact Rockerbox representative for more information."></Message>
    </ContentCard>
  );
};

export default InstructionSetupCard;

import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button } from 'semantic-ui-react'
import { getSegments, getSurveyConfigs } from '../../../utils/api.js'
import * as routes from '../../../routes.js'
import * as routesV3 from '../../../V3/baseRoutes.js'

const SurveySetup = ({ platform }) => {

  const [activeSegment, setActiveSegment] = React.useState(false)
  const [survey, setSurvey] = React.useState(false)

  React.useEffect(() => {
    getSegments()
      .then(resp => {
        const activeSegment = resp.find(row => row.url_pattern[0].includes(platform))
        setActiveSegment(activeSegment)
      })

    getSurveyConfigs()
      .then(resp => {
        const activeSurvey = resp[0]
        setSurvey(activeSurvey)
      })
  }, [])

  return <>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={15}>Conversion Segment</Table.HeaderCell>
          <Table.Cell width={1}></Table.Cell>
        </Table.Row>
      </Table.Header>
      <Table.Row>
        <Table.Cell>{ activeSegment['action_name'] }</Table.Cell>
        <Table.Cell>
          <Button color='blue' icon='pencil' as={Link} to={routes.editConversionEvent + "/" + activeSegment['action_id'] } />
         </Table.Cell>
      </Table.Row>
    </Table>

    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={15}>Survey Setup</Table.HeaderCell>
          <Table.Cell width={1}></Table.Cell>
        </Table.Row>
      </Table.Header>
      <Table.Row>
        <Table.Cell>{ survey['display_name'] }</Table.Cell>
        <Table.Cell>
          <Button color='blue' icon='pencil' as={Link} to={routesV3.surveyEdit + "/" + survey['id'] } />
         </Table.Cell>
      </Table.Row>
    </Table>

  </>

}
export default SurveySetup;

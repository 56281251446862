import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form' 
import { Grid, Segment, Header, Form, Button } from 'semantic-ui-react'
import { getOnboardingSurvey, saveOnboardingSurvey } from '../../../V3/api/account'
import { SURVEY_OPTIONS } from '../helpers/constants'

const Survey = ( { completeStep }) => {
  const [loading, setLoading] = useState(false);
  const [onboardingSurvey, setOnboardingSurvey] = useState(null);

  useEffect(() => {
    setLoading(true);
    getOnboardingSurvey().then(res => {
      if (Object.keys(res).length < 1) {
        setOnboardingSurvey(null);
        setLoading(false);
        return;
      }
      setOnboardingSurvey(res);
      Object.keys(res).map(k => {
        setValue(k, res[k])
        trigger(k);
      })
      setLoading(false);
    })
  }, [])

  const { control, handleSubmit, formState: {errors}, setValue, trigger } = useForm({
    defaultValues: {
      role: null,
      industry: null,
      company_size: null,
      annual_marketing_spend: null
    }
  })

  const handleInputSelectChange = (_, { name, value }) => {
    setValue(name, value)
    trigger(name)
  }


  const onSubmit = (data) => {
    setLoading(true)
    saveOnboardingSurvey(data)
      .then(completeStep)
      .catch(err => console.log('Error', err))
  }

  return (
    <div className='onboarding-survey-view'>
    <Grid
      centered
      columns={2}
      verticalAlign='middle'
      padded='vertically'
    >
      <Grid.Row>
        <Grid.Column>
          <Segment basic>
            <Header
              style={{fontWeight: 500}}
              as='h1'
              textAlign='center'
              className='m-b-30'
              content="Tell us a little bit about yourself"
              subheader="This will help us configure your account for you"
            />
            <Form className='onboarding-survey' onSubmit={!!onboardingSurvey ? completeStep : handleSubmit(onSubmit)}>
              <Form.Field error={errors.role?.message} disabled={!!onboardingSurvey}>
                <Controller
                  name='role'
                  control={control}
                  render={({ field }) =>
                  <Form.Select
                    {...field}
                    fluid
                    label="How would you describe your role?"
                    options={SURVEY_OPTIONS.role}
                    onChange={handleInputSelectChange}
                  />}
                  rules={{required: "Please select a role"}}
                />
                {errors.role?.message && <div className="ui pointing above prompt label">{errors.role.message}</div>}
              </Form.Field>
              <Form.Field error={errors.industry?.message} disabled={!!onboardingSurvey}>
                <Controller
                  name='industry'
                  control={control}
                  render={({ field }) =>
                  <Form.Select
                  {...field}
                  fluid
                  label="Which industry is your business in?"
                  options={SURVEY_OPTIONS.industry}
                  onChange={handleInputSelectChange}
                  />}
                  rules={{required: "Please select an industry"}}
                />
                {errors.industry?.message && <div className="ui pointing above prompt label">{errors.industry.message}</div>}
              </Form.Field>
              <Form.Field error={errors.company_size?.message} disabled={!!onboardingSurvey}>
                <Controller
                  name='company_size'
                  control={control}
                  render={({ field }) =>
                    <Form.Select
                      {...field}
                      fluid
                      label="Company Size"
                      options={SURVEY_OPTIONS.company_size}
                      onChange={handleInputSelectChange}
                    />}
                  rules={{required: "Please select a company size"}}
                  />
                  {errors.company_size?.message && <div className="ui pointing above prompt label">{errors.company_size.message}</div>}
              </Form.Field>
              <Form.Field error={errors.annual_marketing_spend?.message} disabled={!!onboardingSurvey}>
                <Controller
                  fluid
                  name='annual_marketing_spend'
                  control={control}
                  render={({ field }) => <Form.Input {...field} fluid type="number" label="Annual marketing spend"  error={errors.annual_marketing_spend?.message}/>}
                  rules={{required: "Please select an annual marketing spend"}}
                />
                {errors.annual_marketing_spend?.message && <div className="ui pointing above prompt label">{errors.annual_marketing_spend.message}</div>}
              </Form.Field>
              <Form.Button
                type="submit"
                size='large'
                fluid
                color='purple'
                className='m-t-25 m-b-30'
                loading={loading}
                disabled={loading}
              >
                {!!onboardingSurvey ? "Next" : "Create Profile"}
              </Form.Button>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    </div>
  )
}

export default Survey

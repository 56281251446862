import moment from 'moment';

export const formatDatesIso = (dateList, obj) => {
  const updatedObj = { ...obj };

  dateList.forEach(c => {
    updatedObj[c] = moment(updatedObj[c]).format('YYYY-MM-DD hh:mm:ss');
  });

  return updatedObj;
};

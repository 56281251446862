import React, { useMemo } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { InformationCircleIcon, UserIcon, GlobeIcon, DeviceMobileIcon } from '@heroicons/react/outline';
import { ContentCard } from '@rockerbox/styleguide';
import { Divider, Grid, List, Popup, Icon } from 'semantic-ui-react';

import { CONVERSION_INFO_FIELDS, USER_INFO_FIELDS, ADDRESS_FIELDS, DEVICE_FIELDS } from './constants';
import ConversionDetailsLoader from './conversionDetailsLoader';
import { formatFieldValues } from './helpers';

const DataItem = ({ label, value, tooltip, style }) => (
  <Grid.Column {...{ style }}>
    <List>
      <List.Item>
        <List.Header style={{ fontWeight: 500 }}>
          {label}
          {!!tooltip
            && (
              <Popup
                inverted
                trigger={(
                  <Icon
                    name="question circle"
                    style={{ marginLeft: 2, opacity: 0.5 }}
                  />
                )}
                content={tooltip}
              />
            )}
        </List.Header>
        <span className={label}>
          {value}
        </span>
      </List.Item>
    </List>
  </Grid.Column>
);

// eslint-disable-next-line no-shadow
export const Section = ({ Icon, items }) => {
  if (!items?.length) return null;

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ marginRight: 10 }}>
        <Icon style={{ height: 20, width: 20, opacity: 0.5 }} />
      </div>
      <div style={{ flexGrow: 1, marginTop: '-1em' }}>
        <Grid style={{ marginTop: 0 }}>
          <Grid.Row columns={4}>
            {items.map(({ label, value, tooltip }, i) => (
              <DataItem
                key={i}
                {...{ label, value, tooltip }}
                style={{ marginTop: i >= 4 ? '1em' : 0 }}
              />
            ))}
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

const defaultValidator = x => !!x && x !== '0' && x !== '0.0';

export const prepareConversionData = (fields, conversionData, currencyCode) => {
  if (!conversionData) return [];
  const reducedFields = fields.reduce((acc, item) => {
    const { key, validate } = item;
    const originalValue = conversionData[key];
    const validator = validate || defaultValidator;
    const isValid = validator(originalValue, conversionData);

    if (isValid) {
      const value = formatFieldValues(item, originalValue, conversionData, currencyCode);

      acc.push({
        ...item,
        value,
      });
    }
    return acc;
  }, []);

  return reducedFields;
};

const ConversionDetails = ({ conversionData, currencyCode }) => {
  const [convInfo, userInfo, addressInfo, deviceInfo] = useMemo(() => ([
    prepareConversionData(CONVERSION_INFO_FIELDS, conversionData, currencyCode),
    prepareConversionData(USER_INFO_FIELDS, conversionData),
    prepareConversionData(ADDRESS_FIELDS, conversionData),
    prepareConversionData(DEVICE_FIELDS, conversionData),
  ]), [conversionData, currencyCode]);

  if (!conversionData || !currencyCode) return <ConversionDetailsLoader />;

  return (
    <ContentCard
      hasTable
      title="Conversion Details"
      style={{ marginTop: 0 }}
    >
      <Section
        Icon={InformationCircleIcon}
        items={convInfo}
      />
      <Divider />
      <Section
        Icon={UserIcon}
        items={userInfo}
      />
      {!!addressInfo?.length && (
        <>
          <Divider />
          <Section
            Icon={GlobeIcon}
            items={addressInfo}
          />
        </>
      )}
      {!!deviceInfo?.length && (
        <>
          <Divider />
          <Section
            Icon={DeviceMobileIcon}
            items={deviceInfo}
          />
        </>
      )}
    </ContentCard>
  );
};

export default ConversionDetails;

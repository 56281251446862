export const METRICS = { cpa: 'cpa', roas: 'roas', cpm: 'cpm', cpc: 'cpc', ctr: 'ctr' };
export const COLORS = ['red', 'blue', 'green', 'purple', 'orange'];

export const PLATFORMS = ['facebook', 'google', 'snapchat', 'pinterest', 'tiktok', 'bing'];
export const FILTER_TYPES = ['Industry', 'Spend Level'];

export const baseCols = [
  {
    display: 'Date',
    key: 'date',
  },
  {
    display: 'CPC',
    key: 'cpc',
    reducer: data => data.reduce((acc, item) => acc + item.cpc, 0) / data.length,
    formatter: value => `$${Number(value).toFixed(2)}`,
  },
  {
    display: 'CPM',
    key: 'cpm',
    reducer: data => data.reduce((acc, item) => acc + item.cpm, 0) / data.length,
    formatter: value => `$${Number(value).toFixed(2)}`,
  },
  {
    display: 'CTR',
    key: 'ctr',
    reducer: data => data.reduce((acc, item) => acc + item.ctr, 0) / data.length,
    formatter: value => `${Number(value * 100).toFixed(2)}%`,
  },
];

export const facebookCols = [
  {
    display: 'ROAS',
    key: 'roas',
    reducer: data => data.reduce((acc, item) => acc + item.roas, 0) / data.length,
    formatter: value => `${Number(value).toFixed(2)}`,
  },
  {
    display: 'CPA',
    key: 'cpa',
    reducer: data => data.reduce((acc, item) => acc + item.cpa, 0) / data.length,
    formatter: value => `$${Number(value).toFixed(2)}`,
  },
];

export const BUTTON_WIDTH = { facebook: '255px', google: '230px', snapchat: '255px', pinterest: '250px', tiktok: '220px', bing: '210px' };

import React, { useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, Legend, CartesianGrid, Label, Tooltip, ReferenceLine, ReferenceDot, ResponsiveContainer } from 'recharts';
import { getStripePricingTiers } from '../../api/account';
import { roundToNext1000, formatNumber, monthlyAdSpendToCostPer1k } from './helpers';

const givenSpendLevel = 500000;

// eslint-disable-next-line no-unused-vars
let roundSpendLevel = roundToNext1000(givenSpendLevel);

const formatYAxis = value => `$${value}`;

const CustomTooltip = ({ payload, label }) => {
  if (payload[0] && typeof (payload[0] === 'object')) {
    const pricePer1K = payload[0].value;
    const totalPrice = payload[0].payload.price;

    return (
      <div className="custom-tooltip" style={{ backgroundColor: 'black', color: '#ffffff', borderRadius: '4px', width: '160px', height: '88px', fontSize: '11px' }}>
        <p className="adSpend" style={{ paddingLeft: '5px', paddingTop: '7px', color: '#bbbbc4' }}>{`$${formatNumber(label)} Monthly Ad Spend`}</p>
        <p>
          <text className="totalPrice" style={{ paddingLeft: '5px', fontWeight: 'bolder', fontSize: '14px' }}>{`$${totalPrice.toFixed(2)}`}</text>
          <text style={{ fontWeight: 'bold' }}> per month</text>
        </p>
        <text className="unitPrice" style={{ paddingLeft: '5px', paddingBottom: '3px', color: '#0db791', fontWeight: 'bold' }}>{`$${(pricePer1K).toFixed(2)}`}</text>
        <text style={{ paddingBottom: '2px', color: '#bbbbc4' }}> per $1K of Ad Spend</text>
      </div>
    );
  }
  return null;
};

const renderLegendText = () => <span style={{ color: 'black', fontWeight: '500' }}>Monthly Pricing</span>;

// eslint-disable-next-line no-shadow
function PricingGraph({ pricing, givenSpendLevel, heightPercent, minHeight, YAxisLabel }) {
  useEffect(() => {
    getStripePricingTiers();
  }, []);
  if (pricing && givenSpendLevel) {
    roundSpendLevel = roundToNext1000(givenSpendLevel);
  }
  return (
    <ResponsiveContainer width="100%" minHeight={minHeight} height={heightPercent.toLocaleString('en', { style: 'percent' })}>
      <LineChart
        data={pricing.response}
        margin={{ top: -30, right: 20, bottom: 5, left: 0 }}
      >
        <XAxis dataKey="Monthly Ad Spend" ticks={[250000, 500000, 750000, 1000000, 1250000, 1500000, 1750000, 2000000]} type="number" tickFormatter={formatNumber}>
          {' '}
          <Label value="Monthly Ad Spend" position="insideBottom" offset="-2" style={{ fontWeight: '500' }} />
          {' '}
        </XAxis>
        <YAxis tickFormatter={formatYAxis} label={{ value: YAxisLabel, angle: -90, position: 'insideLeft', dy: 80, fontWeight: '500' }} />
        <CartesianGrid horizontal={true} vertical={false} />
        <Tooltip content={<CustomTooltip />} allowEscapeViewBox={{ x: true, y: true }} />
        <Legend verticalAlign="top" align="right" iconType="plainline" height={50} formatter={renderLegendText} />
        <ReferenceLine x={givenSpendLevel} stroke="#475ddc" />
        <ReferenceDot x={givenSpendLevel} y={monthlyAdSpendToCostPer1k(givenSpendLevel, pricing.response)} r={4} fill="#475ddc" />
        <Line type="monotone" dataKey="Cost per $1K in Ad Spend" stroke="#475ddc" dot={false} />
      </LineChart>
    </ResponsiveContainer>
  );
}
export default PricingGraph;

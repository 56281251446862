import React, { useState, useMemo, useEffect } from 'react';

import { SearchIcon, CogIcon, LightningBoltIcon } from '@heroicons/react/outline'; // eslint-disable-line import/no-extraneous-dependencies
import { LockClosedIcon, ExternalLinkIcon, ChevronDownIcon } from '@heroicons/react/solid'; // eslint-disable-line import/no-extraneous-dependencies
import { logoIconWhite, logoIconWhiteAnimated } from '@rockerbox/styleguide';
import { getControlKey } from 'commandbar-launcher';
import { Menu, Segment, Popup, Button, Icon, Grid, Image } from 'semantic-ui-react';

import AccountMenu from './AccountMenu';
import BetaMenu from './BetaMenu';
import { HOME, SETTINGS } from './constants';
import HelpMenu from './HelpMenu';
import { useNavMenu } from './hooks';
import LockedModal from './LockedModal';
import NotificationsMenu from './NotificationsMenu';
import { track } from '../../../utils/tracking';
import { getSavedReports } from '../../api/attribution';
import { useGlobalState } from '../../hooks/global';
import { buildPath, buildHash } from '../../hooks/urlState';
import Link from '../../utils/link';
import { routeDisplayMapping } from '../../views/SavedReports/stateless';

/* eslint-disable react/jsx-props-no-spreading, no-shadow, no-unused-expressions */

const NavMenuItem = ({ section, name, icon, description, unauthorized, lockedTiers, external, route, artifactLink, handleClose, handleLockedItemClick, artifacts }) => {
  const { state } = useGlobalState();
  const { account } = state;
  const { tier } = account || {};

  const locked = useMemo(() => {
    if (unauthorized || lockedTiers === 'all') return true;
    if (!lockedTiers) return false;
    return lockedTiers.includes(tier);
  }, [tier, lockedTiers]);

  const externalLink = useMemo(() => {
    if (!artifactLink) return;
    const artifactParts = artifactLink.split('.');
    return artifactParts.reduce((acc, curr) => !!acc && acc[curr], artifacts);
  }, [artifactLink]);

  const NavItemComponent = !locked && !external && !externalLink ? Link : 'div';

  return (
    <NavItemComponent
      to={!locked && !externalLink && route}
      className={`item ${locked ? 'locked' : ''}`}
      onClick={() => {
        if (unauthorized) return;
        ((!!locked || !!external) && !externalLink)
          ? handleLockedItemClick({ name, icon, section, route, locked })
          : !!externalLink
              && window.open(externalLink, '_blank');
        if (!locked && !external && !externalLink) document.title = `${name} | Rockerbox`;
        handleClose();
        track('nav.click', {
          name,
          section,
          route,
          locked,
          external,
        });
      }}
    >
      <div className={`icon ${section}`}>{icon}</div>
      <div className="text">
        <div className="name">
          {name}
          {!!locked
            ? <LockClosedIcon className="link-icon" />
            : !!external
              && <ExternalLinkIcon className="link-icon" />}
        </div>
        <div className="description">{description}</div>
      </div>
    </NavItemComponent>
  );
};

const NavCustomSavedMenu = ({ route, handleClose }) => {
  const { savedReports: savedReportsFromCache, integrations } = useGlobalState();
  const [savedReports, setSavedReports] = useState(savedReportsFromCache);

  useEffect(() => {
    getSavedReports().then(x => setSavedReports(x));
  }, []);

  const mySavedReports = savedReports.filter(x => x.shared === 0).sort((a, b) => b.created_at - a.created_at);
  const sharedSavedReports = savedReports.filter(x => x.shared === 1).sort((a, b) => b.created_at - a.created_at);
  const maxNumberDisplayed = 20;

  const generateFullPath = (route, params) => {
    const rebuiltPath = buildPath(route, params.route);
    const rebuiltHash = params.hash ? buildHash(params.hash) : '';
    return rebuiltPath + rebuiltHash;
  };

  const getIcon = (route, params) => {
    if (route !== 'platformPerformance') {
      return routeDisplayMapping[route]?.icon;
    }
    const { platformName } = params.route;
    const integration = integrations.find(x => x.platform === platformName);
    const suffix = 'icon_img';
    const iconImgsrc = integration ? `https://images.rockerbox.com/integrations/${integration[suffix]}` : '';
    return iconImgsrc ? <Image size="tiny" src={iconImgsrc} style={{ width: 20, marginRight: 5 }} /> : routeDisplayMapping[route].icon;
  };

  const onClickSavedLink = data => {
    track('saved_report.open', data);
    handleClose();
  };

  return (
    <div style={{ width: 850, fontSize: 14 }}>
      <Grid verticalAlign="top">
        <Grid.Row columns={2}>
          <Grid.Column>
            <Grid.Row style={{ paddingBottom: 5, borderBottom: '1px solid grey', margin: '0 0 15px 0', fontFamily: 'Poppins', fontWeight: 500, fontSize: 15 }}>
              My Reports
            </Grid.Row>
            {!mySavedReports.length && <Grid.Row style={{ paddingBottom: 5 }}>You have no saved reports.</Grid.Row>}
            {mySavedReports.slice(0, maxNumberDisplayed).map(({ id, name, user, shared, route, params }) => {
              const fullPath = generateFullPath(route, params);
              const icon = getIcon(route, params);
              return (
                <Grid.Row columns={2} style={{ paddingBottom: 5 }}>
                  <Link
                    to={fullPath}
                    onClick={() => onClickSavedLink({ id, name, user, shared, route, params })}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                      <Grid.Column>
                        {icon}
                      </Grid.Column>
                      <Grid.Column style={{ width: '100%' }}>
                        <p>{name}</p>
                      </Grid.Column>
                    </div>
                  </Link>
                </Grid.Row>
              );
            })}
          </Grid.Column>
          <Grid.Column>
            <Grid.Row style={{ paddingBottom: 5, borderBottom: '1px solid grey', margin: '0 0 10px 0', fontFamily: 'Poppins', fontWeight: 500, fontSize: 15 }}>
              Team Reports
            </Grid.Row>
            {!sharedSavedReports.length && <Grid.Row style={{ paddingBottom: 5 }}>There are no saved team reports.</Grid.Row>}
            {sharedSavedReports.slice(0, maxNumberDisplayed).map(({ id, name, user, shared, route, params }) => {
              const fullPath = generateFullPath(route, params);
              const icon = getIcon(route, params);
              return (
                <Grid.Row columns={2} style={{ paddingBottom: 5 }}>
                  <Link
                    to={fullPath}
                    onClick={() => onClickSavedLink({ id, name, user, shared, route, params })}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                      <Grid.Column>
                        {icon}
                      </Grid.Column>
                      <Grid.Column style={{ width: '100%' }}>
                        <p>{name}</p>
                      </Grid.Column>
                    </div>
                  </Link>
                </Grid.Row>
              );
            })}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div className="text" style={{ cursor: 'pointer', paddingTop: 30 }}>
        <Link
          to={route}
          onClick={handleClose}
        >
          View All Saved Reports
          {'  '}
          <Icon name="arrow circle right" />
        </Link>
      </div>
    </div>
  );
};

const NavMultiSectionMenu = ({ children, navigate, handleClose, handleLockedItemClick, artifacts }) => (
  <div className="menu-inner-wrap">
    {children.map(child => (
      <div className="menu-column">
        <div className="menu-column-header">{child.name}</div>
        {child.children.map(x => (
          <NavMenuItem
            {...x}
            {...{ navigate, handleClose, handleLockedItemClick, artifacts }}
          />
        ))}
      </div>
    ))}
  </div>
);

const NavbarV3 = ({ navigate, account, accounts, user, handleAccountChange, handleLogout, artifacts }) => {
  const [activeItem, setActiveItem] = React.useState(null);
  const [logoSrc, setLogoSrc] = React.useState(logoIconWhiteAnimated);
  const [lockedModalItem, setLockedModalItem] = React.useState(null);
  const navBarOptions = useNavMenu(account);

  const handleClose = () => {
    setActiveItem(null);
  };

  const handleLockedItemClick = item => {
    setLockedModalItem(item);
  };

  return (
    <div className="nav-v3">
      <Segment inverted className="navbar">
        <Link
          to={HOME}
          tabIndex={-1}
          className="logo-wrap"
          onMouseEnter={() => setLogoSrc(`${logoIconWhiteAnimated}?${Date.now()}`)}
          onMouseLeave={() => setLogoSrc(logoIconWhite)}
          onClick={() => {
            document.title = 'Rockerbox Marketing Data Platform';
          }}
        >
          <img
            className="logo-image"
            src={logoSrc}
            alt="rockerbox logo"
          />
        </Link>
        <Menu inverted secondary className="main-menu">
          {/* <Menu.Item
            as={Link}
            to={HOME}
            content='Dashboard'
            onClick={() => {
              document.title = `Rockerbox Marketing Data Platform`
            }}
          /> */}
          {navBarOptions.map(option => (
            !!option.children
              ? (
                <Popup
                  transition={{ animation: 'fade down', duration: 500 }}
                  trigger={(
                    <Menu.Item
                      content={(
                        <>
                          {option.name}
                          <ChevronDownIcon className="caret" />
                        </>
                      )}
                      onClick={() => setActiveItem(option.name)}
                      active={activeItem === option.name}
                    />
                  )}
                  basic
                  on="click"
                  position="bottom left"
                  open={activeItem === option.name}
                  onClose={() => handleClose()}
                  className={`nav-dropdown-menu ${!!option.multiSection && 'multi-section-menu'}`}
                  content={
                    option.multiSection
                      ? (
                        <NavMultiSectionMenu
                          {...option}
                          {...{ navigate, handleClose, handleLockedItemClick, artifacts }}
                        />
                      )
                      : option.custom
                        ? option.children.map(child => (
                          <NavCustomSavedMenu
                            {...child}
                            {...{ navigate, handleClose, handleLockedItemClick, artifacts }}
                          />
                        ))
                        : option.children.map(child => (
                          <NavMenuItem
                            {...child}
                            {...{ navigate, section: option.name, handleClose, handleLockedItemClick, artifacts }}
                          />
                        ))
                    }
                />
              )
              : (
                <Menu.Item
                  name={option.name}
                  onClick={() => navigate(option.route)}
                />
              )
          ))}
        </Menu>
        <Menu inverted secondary className="right-menu">
          {account?.tier === 'lite'
            && (
            <Button
              color="green"
              content="Upgrade"
              className="upgrade-button"
              onClick={() => handleLockedItemClick({
                name: 'all of Rockerbox',
                icon: <LightningBoltIcon />,
                locked: true,
              })}
            />
            )}
          <Popup
            inverted
            position="bottom center"
            content={`${getControlKey()} + K`}
            trigger={(
              <Menu.Item
                className="icon-button"
                content={<SearchIcon />}
                onClick={() => {
                  !!window.CommandBar && window.CommandBar.open();
                }}
              />
            )}
          />
          <HelpMenu {...{ artifacts }} />
          <NotificationsMenu />
          <Menu.Item
            as={Link}
            to={SETTINGS}
            tabIndex={-1}
            className="icon-button"
            content={<CogIcon />}
            onClick={() => {
              document.title = 'Settings | Rockerbox';
            }}
          />
          <AccountMenu {...{ account, accounts, user, handleAccountChange, handleLogout }} />
          <BetaMenu {...{ artifacts }} />
        </Menu>
      </Segment>
      <LockedModal
        open={!!lockedModalItem}
        item={lockedModalItem}
        onClose={() => setLockedModalItem(null)}
      />
    </div>
  );
};

export default NavbarV3;

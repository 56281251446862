import React, { useMemo, useState, useEffect } from 'react';

import { Button } from 'semantic-ui-react';

import { rollupTableDataToCollapseRows } from './helpers';
import { createColumns } from './tableColumns';
import { CHANNEL_MAPPING, STRATEGY_MAPPING } from '../../../../components/filters/DerivedTiersFilter/constants';
import { marketingEventToChannel, marketingEventToPlatform, marketingEventToStrategy } from '../../../../components/filters/DerivedTiersFilter/eventTransforms';
import { usePlatformMappings } from '../../../../components/filters/DerivedTiersFilter/hooks';
import { useGlobalState } from '../../../../hooks/global';
import { colorHash } from '../../../../utils/colorHash';
import { GroupedChannelTooltipContent } from '../../parts/tableCells';

export const useRows = ({ isDirty, constraints, forecastRows }) => {
  const [readyForGroups, setReadyForGroups] = useState(false);

  useEffect(() => {
    if (isDirty || forecastRows.length === 0) setReadyForGroups(false);
    else setReadyForGroups(true);
  }, [isDirty, forecastRows]);

  const constrained = useMemo(() => Object.entries(constraints)
    .filter(([, obj]) => obj.min_value === obj.max_value)
    .reduce((accu, [key]) => {
      accu[key] = true;
      return accu;
    }, {}), [constraints]);

  const rowGroups = useMemo(() => readyForGroups
    && forecastRows.reduce(
      (accu, c) => {
        if (!constrained[c.key]) {
          accu.adjusted[c.key] = true;
        } else if (constrained[c.key] && c.targetValue > 0) {
          accu.constrained[c.key] = true;
        } else {
          accu.zeros[c.key] = true;
        }
        return accu;
      },
      {
        zeros: {},
        adjusted: {},
        constrained: {},
      },
    ), [readyForGroups, constraints]);

  const zeroRows = useMemo(() => (!readyForGroups ? [] : forecastRows.filter(obj => rowGroups.zeros[obj.key])), [readyForGroups, forecastRows]);

  const adjustedRows = useMemo(() => (!readyForGroups ? [] : forecastRows.filter(obj => rowGroups.adjusted[obj.key])), [readyForGroups, forecastRows]);

  const constrainedRows = useMemo(() => (!readyForGroups ? [] : forecastRows.filter(obj => rowGroups.constrained[obj.key])), [readyForGroups, forecastRows]);

  return {
    zeroRows,
    constrainedRows,
    adjustedRows,
  };
};

export const useTableColumns = (isConversionKPI, updateFieldValues, featureStats, columnGroups, nameByPeriod, summaryText) => {
  const displayCols = useMemo(
    () => createColumns(isConversionKPI, featureStats, updateFieldValues, columnGroups, nameByPeriod, summaryText),
    [isConversionKPI, featureStats, updateFieldValues, nameByPeriod, columnGroups],
  );

  return {
    displayCols,
  };
};

export const useTableData = (data, isConversionKPI, featureNameMap, numDaysByPeriod, summaryDisplay, toggleTableData) => {
  const { projectionPeriod, budgetPeriod } = numDaysByPeriod;
  const { tierColors } = useGlobalState();
  const { platformMappings } = usePlatformMappings();
  const [showChannels, setShowChannels] = useState(false);

  const tableData = useMemo(() => data.map(row => {
    const {
      key, revenueAtTargetValue, revenueAtCurrentValue, revenuePercentDiff,
      ROIAtTargetValue, ROIAtCurrentValue,
      targetValue, currentValue,
    } = row;
    const metricAtTargetValue = isConversionKPI ? 1 / ROIAtTargetValue : ROIAtTargetValue;
    const metricAtCurrentValue = isConversionKPI ? 1 / ROIAtCurrentValue : ROIAtCurrentValue;
    const incrementalRevenue = revenueAtTargetValue - revenueAtCurrentValue;
    const incrementalSpend = targetValue - currentValue;
    const incrementalMetric = isConversionKPI ? incrementalSpend / incrementalRevenue : incrementalRevenue / incrementalSpend;
    const totalKPIAtTargetValue = revenueAtTargetValue * projectionPeriod;
    const totalKPIAtCurrentValue = revenueAtCurrentValue * projectionPeriod;
    const color = tierColors[key] || colorHash(key);

    return (
      {
        ...row,
        currentValue: currentValue * budgetPeriod,
        targetValue: targetValue * budgetPeriod,
        display: featureNameMap[key],
        kpiAtTargetValue: revenueAtTargetValue,
        kpiAtCurrentValue: revenueAtCurrentValue,
        kpiPercentDiff: revenuePercentDiff,
        metricAtTargetValue,
        metricAtCurrentValue,
        incrementalMetric,
        metricDiff: metricAtTargetValue - metricAtCurrentValue,
        totalKPIAtTargetValue,
        totalKPIAtCurrentValue,
        color,
        channel: marketingEventToChannel(key.toLowerCase()),
        platform: marketingEventToPlatform(key.toLowerCase(), platformMappings),
        strategy: marketingEventToStrategy(key.toLowerCase()),
      }
    );
  }), [data, featureNameMap, projectionPeriod]);

  const rolledUpTableData = useMemo(() => {
    const rolledUpData = rollupTableDataToCollapseRows(tableData, projectionPeriod, summaryDisplay, isConversionKPI);
    rolledUpData.expandButton = <Button content="Show Channels" size="mini" onClick={() => setShowChannels(true)} />;
    return [rolledUpData];
  }, [tableData, summaryDisplay]);

  const displayTableData = useMemo(() => {
    if (!tableData.length) return [];
    if (showChannels) {
      const expandedData = tableData;
      expandedData[0].expandButton = <Button content="Hide Channels" size="mini" onClick={() => setShowChannels(false)} />;
      return expandedData;
    }
    return rolledUpTableData;
  }, [tableData, rolledUpTableData, showChannels]);

  return {
    tableData: toggleTableData ? displayTableData : tableData,
  };
};

export const useGroupedData = (isConversionKPI, data, groupBy, numDaysByPeriod, projectionName) => {
  const { projectionPeriod } = numDaysByPeriod;
  const { tierColors } = useGlobalState();
  const { platformMappings } = usePlatformMappings();
  const groupedTableData = useMemo(() => {
    if (!groupBy || groupBy === 'default') return data;
    const groups = [...new Set(data.map(row => row[groupBy]))];
    const groupedRows = groups.map(group => {
      const relevantRows = data.filter(row => row[groupBy] === group);
      const displayName = groupBy === 'channel' ? CHANNEL_MAPPING[group].text
        : groupBy === 'platform' ? platformMappings[group].text
          : groupBy === 'strategy' ? STRATEGY_MAPPING[group].text
            : group;

      const color = tierColors[relevantRows[0].key] || colorHash(relevantRows[0].key);
      const tooltip = {
        icon: 'zoom in',
        content: <GroupedChannelTooltipContent
          data={relevantRows}
          isConversionKPI={isConversionKPI}
          projectionName={projectionName}
        />,
      };
      return {
        ...rollupTableDataToCollapseRows(relevantRows, projectionPeriod, displayName, isConversionKPI),
        color,
        tooltip,
      };
    });
    return groupedRows;
  }, [data, groupBy, platformMappings]);

  return {
    groupedTableData,
  };
};

import moment from 'moment';
import * as d3 from 'rockerbox_d3_legacy_clone';

export default function cdfChart(w) {
  // define chart layout
  var margin = {
    top: 8, // top margin includes title and legend
    right: 40, // right margin should provide space for last horz. axis title
    bottom: 10,
    left: 0 // left margin should provide space for y axis titles
  };

  var _w = w || 840
  // height & width
  var width = (_w - margin.left - margin.right);
  var height = (_w - margin.top - margin.bottom);
  var legendWidth = _w -240;
  var legendHeight = 300;

  // cosmetics
  var color = "steelblue";
  var title = '';

  // global div for tooltip


  function chart(selection) {
    selection.each(function drawGraph(dataset) {

      const rows = dataset;
      const extent = d3.extent(rows.map(function(d){ return d.value; }).filter(function(d){ return d !== 1; }));

      const colsObj = {}

      rows.map(x => colsObj[x.x] = x.x_label)

      const cols = Object.keys(colsObj).reduce((p,k) => {
        p.push(colsObj[k])
        return p
      }, [])
      var d3this = d3.select(this);

      var div = d3this.append('div')
          .attr('class', 'corr-tooltip')
          //.style('opacity', 0);

      var svg = d3this.append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
        .append("g")
          .attr("transform", "translate(" + margin.left + ", " + margin.top + ")");
    
      var padding = .1;
    
      var x = d3.scale.ordinal()
        .rangePoints([0, width], padding)
        .domain(cols);
    
      var y = d3.scale.ordinal()
        .rangePoints([0, height], padding)
        .domain(cols);

      function linspace(start, end, n) {
          var out = [];
          var delta = (end - start) / (n - 1);

          var i = 0;
          while(i < (n - 1)) {
              out.push(start + (i * delta));
              i++;
          }

          out.push(end);
          return out;
      }

    
      //const r = ["#9e0142", "#d53e4f", "#f46d43", "#fdae61", "#fee08b", "#ffffbf", "#e6f598", "#abdda4", "#66c2a5", "#3288bd", "#5e4fa2"]
      const r = ['#7f3b08', '#b35806', '#e08214', '#fdb863', '#fee0b6', '#f7f7f7', '#d8daeb', '#b2abd2', '#8073ac', '#542788', '#2d004b']
      var c = d3.scale.linear()
        .range(r)
        .domain([extent[0], extent[0]*.8, extent[0]*.25, extent[0]*.1, extent[0]*.05, 0, extent[1]*.05, extent[1]*.1, extent[1]*.25, extent[1]*.8, 1]);
    
      //var x_axis = d3.svg.axis(y).tickFormat(function(d, i){ return cols[i]; });
      //var y_axis = d3.svg.axis(x).tickFormat(function(d, i){ return cols[i]; });
    
      //svg.append("g")
      //    .attr("class", "x axis")
      //    .call(x_axis);
    
      //svg.append("g")
      //    .attr("class", "y axis")
      //    .call(y_axis);
    
      const rect = svg.selectAll("rect")
          .data(rows, function(d){ return d.x_label + d.y_label; })

      const size = width/cols.length;


      rect
        .enter().append("rect")
          .attr("x", function(d){ return x(d.x_label); })
          .attr("y", function(d){ return y(d.y_label); })
          .attr("width", size)
          .attr("height", size)
          .style("fill", function(d){ return c(d.value); })
          .style("opacity", highlight)
        //.transition()
        //  .style("opacity", 1);
    
      svg.selectAll("rect")
    
      d3.selectAll("rect")
        .on("mouseover", function(d){
    
          //d3.select(this).classed("selected", true);
          onHover(d)

          /*const tip = "<div style='flex-direction:column; width:100px; text-overflow: ellipsis;white-space: nowrap; overflow: hidden; '>" +
            d.x_label.split("|").map(x => `<div>${x}</div>`).join("") + 
            "</div><div style='flex-direction:column; width:100px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;'>" +
            d.y_label.split("|").map(x => `<div>${x}</div>`).join("") + 
            "</div><div style='flex-direction:column'>" + 
            d.value.toFixed(2) + ": " + d.group + ", " + d.medium_group + ", " + d.large_group
            "</div>"
            
    
          d3this.select(".corr-tooltip")
              .style("display", "flex")
              .style("flex-direction", "row")
              .html(tip);
    
          var row_pos = y(d.y_label);
          var col_pos = x(d.x_label);
          var tip_pos = d3this.select(".corr-tooltip").node().getBoundingClientRect();
          var tip_width = tip_pos.width;
          var tip_height = tip_pos.height;
          var grid_pos = d3this.node().getBoundingClientRect();
          var grid_left = grid_pos.left;
          var grid_top = grid_pos.top;

          console.log(grid_left)
          console.log(grid_top)
    
          var left = col_pos + margin.left + (size / 2) - (tip_width / 2);
          var top = row_pos + margin.top - tip_height - 5;

          console.log(left)
          console.log(top)
    
          d3this.select(".corr-tooltip")
              .style("left", left + "px")
              .style("top", top + "px");
          */
        })
        .on("mouseout", function(){
          d3.selectAll("rect").classed("selected", false);
          d3this.select(".corr-tooltip").style("display", "none");
        });
    
      // legend scale
      var legend_top = 15;
      var legend_height = 15;
    
      var legend_svg = d3.select("#legend").append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", legend_height + legend_top)
        .append("g")
          .attr("transform", "translate(" + margin.left + ", " + legend_top + ")");
    
      var defs = legend_svg.append("defs");
    
      var gradient = defs.append("linearGradient")
          .attr("id", "linear-gradient");
    
      var stops = [{offset: 0, color: "tomato", value: extent[0]}, {offset: .5, color: "white", value: 0}, {offset: 1, color: "steelblue", value: extent[1]}];
      
      gradient.selectAll("stop")
          .data(stops)
        .enter().append("stop")
          .attr("offset", function(d){ return (100 * d.offset) + "%"; })
          .attr("stop-color", function(d){ return d.color; });
    
      legend_svg.append("rect")
          .attr("width", width)
          .attr("height", legend_height)
          .style("fill", "url(#linear-gradient)");
    
      legend_svg.selectAll("text")
          .data(stops)
        .enter().append("text")
          .attr("x", function(d){ return width * d.offset; })
          .attr("dy", -3)
          .style("text-anchor", function(d, i){ return i == 0 ? "start" : i == 1 ? "middle" : "end"; })
          .text(function(d, i){ return d.value.toFixed(2) + (i == 2 ? ">" : ""); })


    });
  }

  chart.width = function(_) {
    if (!arguments.length) return width;
    width = _;
    return chart;
  };

  chart.height = function(_) {
    if (!arguments.length) return height;
    height = _;
    return chart;
  };

  chart.title = function(_) {
    if (!arguments.length) return title;
    title = _;
    return chart;
  };

  const highlighters = {
    "all": function(d) {return d.group > 0 ? 1 : d.medium_group ? .85 : d.large_group ? .15 : d.value > 0 ? .15 : 0 },
    "small": function(d) { return d.group > 0 ? 1 : 0},
    "medium": function(d) { return d.medium_group ? 1 : 0 },
    "large": function(d) { return d.large_group > 0 ? 1 : 0 },
    "none": function(d) { return 1 },
  }

  var highlight = highlighters["all"];

  chart.highlight = function(_) {
    if (!arguments.length) return highlight;
    highlight = highlighters[_];
    return chart;
  };

  var onHover = function(){}

  chart.onHover = function(_) {
    if (!arguments.length) return onHover;
    onHover = _;
    return chart;
  };

  return chart;
}

import React from 'react';
import { Table } from 'semantic-ui-react';

// barebones, display-only grid
// no sorting, searching, summary etc.

const defaultFormatter = x => x;

const DefaultCell = ({ item, col }) => {
  const formatter = col.formatter || defaultFormatter;
  return (
    <Table.Cell width={item[col.cellWidth]} className={`data-${col?.key}`}>
      <span>{formatter(item[col.key])}</span>
    </Table.Cell>
  );
};

const writeCell = (item, col, index) => {
  const CellComponent = col.as || DefaultCell;

  return (
    <CellComponent {...{ col, item }} key={index} />
  );
};

const GridRow = ({ item, cols }) => (
  <Table.Row>
    {cols.map((col, index) => writeCell(item, col, index))}
  </Table.Row>
);

const BasicGrid = ({ data, cols, className }) => (
  <Table className={className}>
    <Table.Header>
      <Table.Row>
        {cols.map((col, index) => (col.headerAs ? col.headerAs(col) : <Table.HeaderCell style={col.headerStyle} key={index}>{col.display}</Table.HeaderCell>))}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {data.map((item, index) => <GridRow item={item} cols={cols} key={index} />)}
    </Table.Body>
  </Table>
);

export default BasicGrid;

import _ from 'lodash';
import { colorHash } from '../../utils/colorHash';

/**
 * Returns an array of unique values from the specified column of an arrow object.
 *
 * @param {object} arrow - The arrow object from which the unique values are extracted.
 * @param {string} column - The name of the column from which the unique values are extracted.
 * @returns {array} - An array of unique values from the specified column.
 */
export const arrowColumnUnique = (arrow, column) => {
  const columnNames = arrow.column(column);
  return [...new Set(columnNames)];
};

/**
 * Returns an array of unique values from the specified column in the given tables.
 *
 * @param {Array} tables - The tables to extract the column values from.
 * @param {string} column - The name of the column to extract values from.
 * @returns {Array} - An array of unique values from the specified column.
 */
export const uniqueColumns = (tables, column) => {
  const allColumns = tables.flatMap(table => arrowColumnUnique(table, column));
  return [...new Set(allColumns)];
};

/**
 * Generates color codes based on the given tiers data and tier colors.
 *
 * @param {Array} tiersDataRaw - The raw tiers data.
 * @param {Object} tierColors - The tier colors object.
 * @returns {Object} - The object containing color codes for each tier.
 */
export const getColorsFromHash = (tiersDataRaw, tierColors) => {
  if (!tiersDataRaw) return {};

  return uniqueColumns(tiersDataRaw, 'tier_1')
    .reduce((accu, c) => {
      accu[c] = tierColors[c] || colorHash(c);
      return accu;
    }, {});
};

/**
 * Maps the data based on the provided configuration.
 *
 * @param {Array} data - An array of objects representing the data to be mapped.
 * @param {Array} funnelConfiguration - An array of segment ids representing the events to be mapped.
 * @param {Array} currentSegments - An array of objects representing the current segments details.
 * @param {string} conversionKey - A string representing the conversion key.
 * @param {string} metric - A string representing the metric to be used.
 * @returns {Array} - An array of objects representing the mapped data for each event in the funnelConfiguration array.
 */
export const mapDataByConfig = (data, funnelConfiguration, currentSegments, conversionKey, metric) => (
  funnelConfiguration.map(event => {
    const configKeyName = `${event}_${conversionKey}`;
    const configSpend = `${event}_spend`;
    const eventName = currentSegments.find(({ action_id }) => action_id === event).action_name;

    const tierObj = data.reduce((accu, curr) => {
      const hasSpend = !!curr[configSpend];
      if (!accu[curr.tier_1]) {
        accu[curr.tier_1] = 0;
      }
      if (metric === 'cpa') {
        accu[curr.tier_1] = hasSpend ? curr[configSpend] / curr[configKeyName] : 0;
      } else {
        accu[curr.tier_1] += curr[configKeyName];
      }

      return accu;
    }, {});

    return { event: eventName, ...tierObj };
  })
);

/**
 * Formats the chart data based on the provided parameters.
 *
 * @param {Array} data - The data to be formatted.
 * @param {Array} funnelConfiguration - The configuration for the funnel.
 * @param {Array} currentSegments - The current segments.
 * @param {string} conversionKey - The conversion key.
 * @param {string} metric - The metric to be used.
 * @returns {Array} The formatted chart data.
 */
export const formatChartData = (data, funnelConfiguration, currentSegments, conversionKey, metric) => {
  if (!data) return [];
  const dataMappedByConfig = mapDataByConfig(data, funnelConfiguration, currentSegments, conversionKey, metric);
  const lastSegment = _.cloneDeep(dataMappedByConfig[dataMappedByConfig.length - 1]);
  lastSegment.event = `${lastSegment.event} (Duplicated)`;

  return [...dataMappedByConfig, lastSegment];
};

import React, { Component } from "react";
import OutboxReportIndex from "./OutboxReportIndex";
import { hourFromESTToLocal } from "./timezoneConversion";
import {
  getReportType,
  getAllSegments,
  getSavedreports,
  getScheduledreports,
  getOutboxes
} from "./api";

function changeOutboxName(data) {
  return data.map(report => {
    if (report.outbox_name === "Spaces Delivery") {
      report.outbox_name = "Email Delivery";
    }
    return report;
  });
}

function changeToLocalTimeZone(data) {
  return data.map(report =>
    Object.assign({}, report, {
      time: hourFromESTToLocal(report["time"])
    })
  );
}

export default class ReportIndexContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savedReports: [],
      scheduledReports: [],
      refetch: false,
      forceSendStatus: undefined,
      segments: [],
      reportTypes: [],
      reportScheduled: undefined,
      outboxes: [],
      sftpOutboxes: [],
      s3Outboxes: [],
      exportedReports: [],
      loading: true,
      loadingOutbox: true
    };

    this.setReportTypes = this.setReportTypes.bind(this);
    this.setAllSegments = this.setAllSegments.bind(this);
    this.setOutboxes = this.setOutboxes.bind(this);
    this.setSavedReports = this.setSavedReports.bind(this);
    this.setScheduledReports = this.setScheduledReports.bind(this);
  }

  componentDidMount() {
    this.initializeState();
  }

  add_unique_user(outbox) {
    if (outbox.outbox_type === "amazon_s3") {
      return outbox.iam_user;
    } else if (outbox.outbox_type === "sftp" || outbox.outbox_type === "ftp") {
      return `${outbox.config.username}@${outbox.config.hostname}${outbox.config.root_directory}`;
    } else if (outbox.outbox_type === "googlecloud_storage") {
      return outbox.config.email;
    } else {
      return "";
    }
  }

  parsedOutboxes(outboxes) {
    const types = [
      "amazon_s3",
      "sftp",
      "ftp",
      "do_spaces",
      // "email",
      "googlecloud_storage"
    ];

    return outboxes
      .filter(outbox => outbox.name && types.includes(outbox.outbox_type))
      .map(outbox => {
        const identifier =
          (outbox.config && outbox.config.bucket_name) ||
          (outbox.config && outbox.config.hostname) ||
          "";

        return {
          ...outbox,
          name:
            outbox.name === "Spaces Delivery" ? "Email Delivery" : outbox.name,
          outbox_type: outbox.outbox_type,
          identifier,
          credential: this.add_unique_user(outbox)
        };
      });
  }

  initializeState() {
    return Promise.all([
      getScheduledreports().then(data => {
        data = changeOutboxName(data);
        data = changeToLocalTimeZone(data);
        this.setState({
          scheduledReports: data
        });
      }),
      getReportType().then(reportTypes => this.setState({ reportTypes })),
      getAllSegments().then(segments => this.setState({ segments })),
      getSavedreports().then(data => {
        const savedReports = data.map(report => {
          report["filters"] = [report["filters"]];
          return report;
        });
        this.setState({ savedReports });
      })
    ])
      .then(() => {
        this.setState({ loading: false });
        return getOutboxes();
      })
      .then(data => {
        const outboxes = this.parsedOutboxes(data);
        const spaceOutbox = outboxes.filter(
          o => o.outbox_type == "do_spaces"
        )[0];
        const exportedReports = spaceOutbox
          ? spaceOutbox["messages"].map(item => Object.assign({}, item))
          : [];
        const s3Outboxes = outboxes.filter(o => o.outbox_type == "amazon_s3");
        const sftpOutboxes = outboxes.filter(o => o.outbox_type == "sftp");
        this.setState({
          outboxes,
          s3Outboxes,
          sftpOutboxes,
          exportedReports,
          loadingOutbox: false
        });
      });
  }

  setReportTypes(obj) {
    return getReportType(obj).then(data => {
      this.setState({ reportTypes: data });
    });
  }

  setAllSegments(obj) {
    return getAllSegments(obj).then(data => {
      this.setState({ segments: data });
    });
  }

  setOutboxes(obj) {
    return getOutboxes(obj).then(data => {
      const s3Outboxes =
        data &&
        this.parsedOutboxes(data.filter(o => o.outbox_type == "amazon_s3"));
      const sftpOutboxes =
        data && this.parsedOutboxes(data.filter(o => o.outbox_type == "sftp"));
      this.setState({
        outboxes: this.parsedOutboxes(data),
        s3Outboxes,
        sftpOutboxes
      });
    });
  }

  setSavedReports(obj) {
    return getSavedreports(obj).then(data => {
      this.setState({
        savedReports: data.map(report => {
          report["filters"] = [report["filters"]];
          return report;
        })
      });
    });
  }

  setScheduledReports(obj) {
    return getScheduledreports(obj).then(data => {
      data = changeOutboxName(data);
      data = changeToLocalTimeZone(data);
      this.setState({ scheduledReports: data });
    });
  }

  render() {
    
    const { segments, savedReports, scheduledReports } = this.state;
    const conversionIdNameMap = segments.reduce((prev, cur) => {
      const { deleted, active, action_id, action_name } = cur;
      if (deleted == 0 && active == 1) {
        prev[action_id] = action_name;
      }
      return prev;
    }, {});
    const savedReportsWithName = savedReports.map(item => {
      item["conversion_name"] =
        conversionIdNameMap[item["filter_id"]] || "Not Found";
      return item;
    });
    const scheduledReportsWithName = scheduledReports.map(item => {
      item["conversion_name"] =
        conversionIdNameMap[item["filter_id"]] || "Not Found";
      return item;
    });

    return (
      <OutboxReportIndex
        setReportTypes={this.setReportTypes}
        setAllSegments={this.setAllSegments}
        setOutboxes={this.setOutboxes}
        setSavedReports={this.setSavedReports}
        setScheduledReports={this.setScheduledReports}
        {...this.state}
        savedReports={savedReportsWithName}
        scheduledReports={scheduledReportsWithName}
      />
    );
  }
}

import React, { useState, useEffect, useMemo } from 'react';
import shallow from 'zustand/shallow';
import _ from 'lodash';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { Segment, Form, Message, Button } from 'semantic-ui-react';
import { ColumnLayout, ContentCard, DataTable } from '@rockerbox/styleguide';
import { postEntityData, updateEntityData } from '../../../api/attribution';
import { entityStore } from '../hooks/entityStore';
import FindMarketingEvent from './FindMarketingEvent';
import { TIERS } from '../../../constants/tiers';

/* eslint-disable no-shadow, no-param-reassign */

const DEFAULT_CREATE = {
  display: [{}],
  segments: [{ segment_id: '', promo_field: '' }],
  events: [],
};

const states = [
  {
    key: 'Approved',
    content: 'This will create a new entity and promo-code',
    icon: 'check circle',
    color: 'green',
  },
  {
    key: 'Same Name',
    content: 'This will add a new promo code to the existing entity',
    icon: 'warning circle',
    color: 'green',
  },
  {
    key: 'Same Tier',
    content: 'This exact tier structure is associated with another entity',
    icon: 'warning sign',
    color: 'orange',
  },
  {
    key: 'Missing Tier',
    content: 'You must include a tier structure you want to associated with this entity',
    icon: 'warning sign',
    color: 'red',
  },
  {
    key: 'Missing Name',
    content: 'You must enter a name to create a new entry',
    icon: 'warning sign',
    color: 'red',
  },
];

export const COLUMNS = [
  {
    style: { width: '1.9em' },
    display: '',
    key: 'state',
    type: 'text',
    asEdit: ({ value }) => value,
    asView: DataTable.StatusCell({ states }),
  },
  { display: 'Name', key: 'name', type: 'text' },
  { display: 'Tier 1', key: 'tier_1', type: 'text' },
  { display: 'Tier 2', key: 'tier_2', type: 'text' },
  { display: 'Tier 3', key: 'tier_3', type: 'text' },
  { display: 'Tier 4', key: 'tier_4', type: 'text' },
  { display: 'Tier 5', key: 'tier_5', type: 'text' },
];

const makeReadOnly = readOnlyColumns => row => {
  const readOnly = readOnlyColumns.includes(row.key);
  return { readOnly, ...row };
};

const CreateViaEvent = ({ program, entityData, onReset }) => {
  const { fixed_unit_cost, rev_share_percent } = program || {};
  const [data, setData] = useState([]);
  const [segment_id, setSegment] = useState(false);
  const [loading, setLoading] = useState(undefined);

  const [showAddType, setShowAddType] = useState(undefined);

  const [featuredSegmentId, segmentOptions, getSegments] = entityStore(
    ({ featuredSegmentId, segmentOptions, getSegments }) => [featuredSegmentId, segmentOptions, getSegments],
    shallow,
  );

  const hasCosts = !!(fixed_unit_cost || rev_share_percent);

  const _columns = useMemo(() => {
    const options = entityData.map(({ name }) => ({ name, text: name, value: name }));
    const columns = COLUMNS;
    const entityColumn = columns.find(row => row.key === 'name');
    entityColumn.asEdit = DataTable.SearchCell(options);
    return columns;
  }, [entityData]);

  useEffect(() => {
    getSegments();
  }, []);
  useEffect(() => {
    const hasEntityData = !!entityData && entityData.length > 0;
    const _segmentId = hasEntityData ? entityData[0].segments[0].segment_id : featuredSegmentId || null;
    if (!segment_id && _segmentId) setSegment(_segmentId);
  }, [featuredSegmentId]);
  useEffect(() => {
    if (loading === false) onReset();
  }, [loading]);

  const { existingTier, existingName } = useMemo(() => {
    const existingTierMapped = entityData.reduce((p, c) => {
      c.promo_codes.forEach(code => {
        p[code.conversion_field_value] = c;
      });
      return p;
    }, {});
    const existingNameMapped = d3
      .nest()
      .key(row => row.name)
      .map(entityData);
    return { existingTier: existingTierMapped, existingName: existingNameMapped };
  }, [entityData]);

  const onUpdate = d => {
    d.forEach(row => {
      const sameName = !!existingName[row.name];
      const sameTier = !!existingTier[row.conversion_field_value];
      row.state = sameTier
        ? 'Same Tier' : sameName
          ? 'Same Name' : !row.tier_1
            ? 'Missing Tier' : !row.name
              ? 'Missing Name' : 'Approved';
    });
    setData(d);
  };

  const addMarketingRow = row => {
    const tiers = [...row.parents, row.key];

    const newData = TIERS.reduce((p, tier, i) => Object.assign(p, { [tier]: tiers[i] }), { state: 'Missing Name' });

    const filtered = data.filter(dataRow => !TIERS.reduce((p, c) => p && dataRow[c] === newData[c], true));

    setShowAddType(undefined);

    if (filtered.length === data.length) setData(data.concat([newData]));
  };
  const onRemove = row => setData(data.filter(r => r !== row));
  const onSave = () => {
    setLoading(true);

    const newEntities = data.filter(row => row.state === 'Approved');

    const newToPost = newEntities.reduce((p, c) => {
      const eventTiers = Object.fromEntries(TIERS.map(k => [k, c[k]]));

      p[c.name] = p[c.name] || _.cloneDeep(DEFAULT_CREATE);
      p[c.name].name = c.name;
      p[c.name].entity_type = program.entity_type;
      p[c.name].events.push(eventTiers);

      const display = p[c.name].display[0];
      const segments = p[c.name].segments[0];

      segments.segment_id = segment_id;

      TIERS.forEach(tier => {
        display[tier] = program[tier];
      });

      return p;
    }, {});

    const existingEntites = data.filter(row => row.state === 'Same Name');
    const toUpdate = existingEntites.reduce((p, c) => {
      const eventTiers = Object.fromEntries(TIERS.map(k => [k, c[k]]));
      const { name } = c;

      p[name] = p[name] || _.cloneDeep(existingName[name])[0];

      p[c.name].events.push(eventTiers);

      return p;
    }, {});

    const updatePromises = Object.values(toUpdate).map(obj => {
      const { id } = obj;
      const send = updateEntityData;
      return send(obj, id);
    });

    const createPromises = Object.values(newToPost).map(obj => {
      const send = postEntityData;
      return send(obj);
    });

    Promise.all([...updatePromises, ...createPromises]).then(() => onReset());
  };

  const removeErrors = () => {
    const valid = data.filter(row => row.state === 'Approved' || row.state === 'Same Name');
    setData(valid);
  };

  const toRemove = data.filter(row => row.state !== 'Approved' && row.state !== 'Same Name');

  const readOnlyColumns = TIERS;
  const skipColumns = program && !hasCosts ? ['entity_type'] : [];

  const columns = program ? _columns.filter(row => !skipColumns.includes(row.key)).map(makeReadOnly(readOnlyColumns)) : _columns;

  return (
    <ContentCard title="Create Multiple Sponsorships via Marketing Events">
      <ColumnLayout
        leftWidth={12}
        rightWidth={4}
        leftContent={(
          <Form>
            <Form.Dropdown
              required
              onChange={(x, { value }) => setSegment(value)}
              as={Form.Dropdown}
              options={segmentOptions}
              value={segment_id}
              label="Segment"
              select
              selection
            />
            <br />
          </Form>
        )}
        rightContent={<Message header="Associate a segment" content="Choose a conversion segment to associate with this sponsorship" />}
      />
      <Segment {...{ loading }}>
        {toRemove.length > 0 && (
          <Message
            content={(
              <>
                The records below either have insufficent data or are already associated with other sponsorships. Either remove or correct the errors before submitting.
                <Button style={{ marginLeft: '5px' }} color="orange" compact size="tiny" onClick={removeErrors}>
                  Remove Errors
                </Button>
              </>
            )}
          />
        )}

        {data.length > 0 && <DataTable {...{ columns, data, onUpdate, onRemove }} />}
        {!showAddType && (
          <div style={{ textAlign: 'center', marginTop: '15px' }}>
            <Button basic size="huge" content="Find Marketing Event to Associate" icon="search" onClick={() => setShowAddType('find')} color="blue" />
          </div>
        )}
        <br />
        <br />
        {showAddType === 'find' && <FindMarketingEvent segment={{ segment_id }} onChange={(evt, { value }) => addMarketingRow(value)} />}
        <br />
        <Button disabled={loading || data.length === 0 || !segment_id} loading={loading} floated="right" color="green" onClick={onSave} submit>
          Add Entities & Event Codes
        </Button>
        <br />
        <br />
      </Segment>
    </ContentCard>
  );
};

export default CreateViaEvent;

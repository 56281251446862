import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { tier1Atom, tier2Atom, tier3Atom, tier4Atom, tier5Atom } from '../../../atoms';

export const useTiers = () => {
  const [tier_1, set_tier_1] = useAtom(tier1Atom);
  const [tier_2, set_tier_2] = useAtom(tier2Atom);
  const [tier_3, set_tier_3] = useAtom(tier3Atom);
  const [tier_4, set_tier_4] = useAtom(tier4Atom);
  const [tier_5, set_tier_5] = useAtom(tier5Atom);

  const resetTier1 = useResetAtom(tier1Atom);
  const resetTier2 = useResetAtom(tier2Atom);
  const resetTier3 = useResetAtom(tier3Atom);
  const resetTier4 = useResetAtom(tier4Atom);
  const resetTier5 = useResetAtom(tier5Atom);

  const clearFilters = () => {
    resetTier1();
    resetTier2();
    resetTier3();
    resetTier4();
    resetTier5();
  };

  return {
    tier_1, set_tier_1,
    tier_2, set_tier_2,
    tier_3, set_tier_3,
    tier_4, set_tier_4,
    tier_5, set_tier_5,
    clearFilters,
  };
};

import React, { useCallback } from 'react';

import { Segment, Form, Grid, Button, Message, Select } from 'semantic-ui-react';

import { createSelectedTiers, updateSelectedTiersforURL } from './helpers';
import { track } from '../../../utils/tracking';
import { StyledDropdown } from '../../components';
import { DEFAULT_TIERS, TIERS as tierKeys, AND_OR_OPTIONS } from '../../constants/tiers';

const TierSelection = ({ selectedTiers, tiers, selectTier, title = 'Filter by Marketing Tiers', andOr = false, setAndOr = false }) => {
  const selected = createSelectedTiers(selectedTiers);
  const updateAndOr = tier => (e, { value }) => {
    const update = {
      ...andOr,
      [tier]: value,
    };
    setAndOr(update);
    const trackingValue = `funnel.marketing_paths.${value}.${tier}.toggle`;
    track(trackingValue);
  };

  return (
    <Form.Field>
      {andOr && <div style={{ display: 'inline-block', width: 72 }} />}
      <label>{ title }</label>
      {
      tierKeys.map((key, i) => {
        const prevKey = tierKeys[i - 1];
        if (i === 0) {
          return (
            <div style={{ display: 'flex', paddingBottom: 10 }}>
              {andOr && (
                <StyledDropdown
                  value={andOr[key] || 'or'}
                  options={AND_OR_OPTIONS}
                  onChange={updateAndOr(key)}
                />
              )}
              <Select
                fluid
                value={selected[key] && selected[key]}
                placeholder={`${DEFAULT_TIERS[key]} contains`}
                search
                multiple
                onChange={(_, x) => selectTier(key, andOr)(x)}
                options={(tiers[key] || [])}
                style={{ marginBottom: 10 }}
              />
            </div>
          );
        }
        if (i > 0 && tiers[key] !== undefined) {
          return (
            <div style={{ display: 'flex', paddingBottom: 10 }}>
              {andOr && (
                <StyledDropdown
                  value={andOr[key] || 'or'}
                  options={AND_OR_OPTIONS}
                  onChange={updateAndOr(key)}
                  disabled={!selected[prevKey] || selected[prevKey].length === 0}
                />
              )}
              <Select
                fluid
                value={selected[key] && selected[key]}
                placeholder={`${DEFAULT_TIERS[key]} contains`}
                search
                multiple
                onChange={(_, x) => selectTier(key, andOr)(x)}
                options={(tiers[key] || [])}
                style={{ marginBottom: 10 }}
                disabled={!selected[prevKey] || selected[prevKey].length === 0}
              />
            </div>
          );
        }
        return null;
      })
    }
    </Form.Field>
  );
};

const genericSelectTier = (touchPointPosition, setTouchPointPosition, resetUrl) => (tier, andOr) => value => {
  const selectedTiers = createSelectedTiers(touchPointPosition);
  selectedTiers[tier] = value.value;

  if (value.value.length === 0) {
    const tierIndex = tierKeys.indexOf(tier);
    const afterTiers = tierKeys.slice(tierIndex);
    afterTiers.forEach(_tier => { selectedTiers[_tier] = []; });
  }
  const updatedTiers = updateSelectedTiersforURL(selectedTiers, resetUrl);
  setTouchPointPosition(updatedTiers);
  if (andOr) {
    const andORValue = andOr[tier] || 'or';
    const trackingValue = `funnel.marketing_paths.${andORValue}.${tier}.tier`;
    track(trackingValue);
  }
};

const FilterTouchPoint = ({ tiers, firstTiers, lastTiers, filterTouchpoints, filterTouchpointsMethods }) => {
  const { minPathLen, any, first, last, andOr } = filterTouchpoints;
  const { setMinPathLen, setAny, setFirst, setLast, setAndOr } = filterTouchpointsMethods;

  const clearFilters = useCallback(() => {
    setAny('any');
    setFirst('first');
    setLast('last');
    setAndOr({});
    setMinPathLen(1);
  });

  const handleMinSteps = useCallback(evt => {
    const selectedSteps = evt.target.value;
    setMinPathLen(selectedSteps);
  });

  return (
    <Segment style={{ backgroundColor: 'transparent', flex: 1 }}>
      <Grid columns="equal" style={{ marginTop: '0px', paddingTop: '10px', paddingBottom: '10px' }} relaxed>
        <Grid.Column>
          <TierSelection {...{ tiers, selectedTiers: any, selectTier: genericSelectTier(any, setAny, 'any'), title: 'Anywhere in Path', andOr, setAndOr }} />
        </Grid.Column>
        <Grid.Column>
          <TierSelection {...{ tiers: firstTiers, selectedTiers: first, selectTier: genericSelectTier(first, setFirst, 'first'), title: 'First Touch' }} />
        </Grid.Column>
        <Grid.Column>
          <TierSelection {...{ tiers: lastTiers, selectedTiers: last, selectTier: genericSelectTier(last, setLast, 'last'), title: 'Last Touch' }} />
        </Grid.Column>
        <Grid.Column width={3} textAlign="center">
          <div><b>{`Minimum Number of Steps: ${minPathLen}`}</b></div>
          <br />
          <input
            type="range"
            min={1}
            max={5}
            value={minPathLen}
            onChange={handleMinSteps}
          />
        </Grid.Column>
      </Grid>
      <Form.Field style={{ minHeight: '30px' }}>
        <Message compact info>
          <p>
            Each path must meet the conditions of
            {' '}
            <b>any</b>
            {' '}
            of the tiers within a selected filter. It must also meet the condition of
            {' '}
            <b>all</b>
            {' '}
            populated filters.
          </p>
        </Message>
        <Button style={{ float: 'right', cursor: 'pointer' }} onClick={() => clearFilters()}>Clear All Filters</Button>
      </Form.Field>
    </Segment>
  );
};

export default FilterTouchPoint;

import { useMemo } from 'react';

// TODO: add unit tests
const getPrefix = ntfFilter => {
  if (ntfFilter === 'new') return 'ntf_';
  if (ntfFilter === 'repeat') return 'repeat_';
  return '';
};

const getConversionKey = (ntfFilter, attributionMethod) => {
  const prefix = getPrefix(ntfFilter);
  return `${prefix}${attributionMethod}`;
};

const getRevenueKey = (ntfFilter, attributionMethod) => {
  const prefix = getPrefix(ntfFilter);
  return `${prefix}revenue_${attributionMethod}`;
};

const getMetricKey = (selectedMetric, conversionKey, revenueKey) => {
  if (selectedMetric === 'conversions') return conversionKey;
  if (selectedMetric === 'revenue') return revenueKey;
  return selectedMetric;
};

const getColumnOrder = (conversionKey, revenueKey) => ({
  conversions: { key: conversionKey, direction: 'descending' },
  revenue: { key: revenueKey, direction: 'descending' },
  spend: { key: 'spend', direction: 'descending' },
  cpa: { key: 'cpa', direction: 'ascending' },
  roas: { key: 'roas', direction: 'descending' },
  rpc: { key: 'rpc', direction: 'descending' },
});

export const useDerivedKeys = (ntfFilter, attributionMethod, selectedMetric) => {
  const conversionKey = useMemo(
    () => getConversionKey(ntfFilter, attributionMethod),
    [ntfFilter, attributionMethod],
  );

  const revenueKey = useMemo(
    () => getRevenueKey(ntfFilter, attributionMethod),
    [ntfFilter, attributionMethod],
  );

  const metricColumn = useMemo(
    () => getMetricKey(selectedMetric, conversionKey, revenueKey),
    [selectedMetric, conversionKey, revenueKey, ntfFilter],
  );

  const orderDict = useMemo(
    () => getColumnOrder(conversionKey, revenueKey),
    [conversionKey, revenueKey],
  );

  const orderObj = orderDict[selectedMetric];
  const orderBy = orderObj.key;
  const orderDirection = orderObj.direction;

  return {
    conversionKey,
    revenueKey,
    metricColumn,
    orderDict,
    orderBy,
    orderDirection,
  };
};

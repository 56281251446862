export const segmentOptions = segments => (
  segments
    .filter(s => s.action_type === 'conversion')
    .map(s => ({
      value: s.filter_id,
      key: s.filter_id,
      text: s.action_name,
      filter_id: s.action_id,
      first_reporting_date: s.first_reporting_date,
    }))
);

export const findCurrentSegments = (segments, ids) => {
  const idsAsNumbers = ids.map(id => Number(id));
  const currentSegments = idsAsNumbers.map(id => {
    const segment = segments.find(c => c.filter_id === id);
    return segment;
  });
  return currentSegments.length === ids.length ? currentSegments : false;
};

export const findFeaturedActionId = actions => {
  const featuredAction = actions.find(action => action.featured === 1);
  // if no featured action, default to '0'
  return featuredAction ? featuredAction.action_id : actions ? '0' : undefined;
};

import React, { useEffect, useState, useContext } from 'react';
import { Header, Grid, GridRow, Button, Modal } from 'semantic-ui-react';
import { Elements } from '@stripe/react-stripe-js';
import { getStripePaymentMethod, postSetupIntent, retrieveStripeSetupIntent, modifyStripePaymentMethod, retrieveStripeCustomerDetails } from '../../api/account';
import StripeSetupIntentForm from './stripeSetupIntentForm';
import { StripeContext } from '../../../utils/stripe';
import { appearance, parsePaymentResponse, displayPaymentDetails, handleSetupIntent, displayPaymentCopy, setAutofillValues } from './helpers';

const PaymentMethod = ({ customerId, activeSubscription }) => {
  const { stripePromise } = useContext(StripeContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [updatePaymentMethodComplete, setUpdatePaymentMethodComplete] = useState(false);
  const [clientSecretOptions, setClientSecretOptions] = useState(false);
  const [setupIntentComplete, setSetupIntentComplete] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(false);
  const [setupIntent, setSetupIntent] = useState(false);
  const [paymentType, setPaymentType] = useState(false);
  const [customerName, setCustomerName] = useState(false);
  const [customerEmail, setCustomerEmail] = useState(false);

  const options = {
    clientSecret: '',
    appearance,
  };

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const intent = params.setup_intent;
  if (!setupIntent && intent) {
    setSetupIntent(intent);
    setSetupIntentComplete(false);
  }

  const onSubmit = () => {
    if (intent) {
      postSetupIntent(customerId, ['card', 'us_bank_account'])
        .then(setupIntentResponse => {
          options.clientSecret = setupIntentResponse.response.client_secret;
          setClientSecretOptions(options);
          setModalOpen(true);
        });
    } else {
      setModalOpen(true);
    }
  };

  useEffect(() => {
    if (!customerId || !activeSubscription || intent) return;
    getStripePaymentMethod(activeSubscription.default_payment_method)
      .then(paymentResponse => {
        parsePaymentResponse(paymentResponse, setPaymentDetails, setPaymentType);
        handleSetupIntent(postSetupIntent, customerId, updatePaymentMethodComplete, options, setClientSecretOptions, intent, setSetupIntentComplete);
      }, () => {
        // handle case where an active subscription has no default payment method (this can happen if a sub is created in the stripe UI)
        parsePaymentResponse('none', setPaymentDetails, setPaymentType);
        postSetupIntent(customerId, ['card', 'us_bank_account']);
        handleSetupIntent(postSetupIntent, customerId, updatePaymentMethodComplete, options, setClientSecretOptions, intent, setSetupIntentComplete);
      });
    retrieveStripeCustomerDetails(activeSubscription.customer)
      .then(customerResponse => {
        setAutofillValues(customerResponse, setCustomerEmail, setCustomerName);
      });
  }, [customerId, activeSubscription]);

  useEffect(() => {
    if (!activeSubscription || !setupIntent) return;
    retrieveStripeSetupIntent(setupIntent)
      .then(setupResponse => {
        modifyStripePaymentMethod(activeSubscription.id, setupResponse.response.payment_method)
          .then(paymentMethodResponse => {
            getStripePaymentMethod(paymentMethodResponse.response.default_payment_method)
              .then(paymentResponse => {
                parsePaymentResponse(paymentResponse, setPaymentDetails, setPaymentType);
                setUpdatePaymentMethodComplete(true);
                setSetupIntentComplete(true);
              });
          }, () => {
            parsePaymentResponse('none', setPaymentDetails, setPaymentType);
            setUpdatePaymentMethodComplete(true);
            setSetupIntentComplete(true);
          });
      });
    retrieveStripeCustomerDetails(activeSubscription.customer)
      .then(customerResponse => {
        setAutofillValues(customerResponse, setCustomerEmail, setCustomerName);
      });
  }, [setupIntent, setupIntentComplete, activeSubscription]);

  return (
    <>
      <Header
        as="h2"
        content="Payment Method"
        className="header"
      />
      <Grid>
        <GridRow className="top-row">
          {displayPaymentCopy(paymentType, setupIntentComplete)}
        </GridRow>
        <GridRow className="second-row">
          {displayPaymentDetails(setupIntentComplete, activeSubscription, paymentType, paymentDetails)}
        </GridRow>
        <GridRow className="third-row" style={{ paddingLeft: '12px' }}>
          <Button
            size="big"
            color="purple"
            inverted={true}
            content="Change Payment Method"
            onClick={onSubmit}
            disabled={!setupIntentComplete}
            className="button"
          />
          <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
            <Modal.Header>Change Your Payment Method</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <Elements stripe={stripePromise} appearance={appearance} options={clientSecretOptions}>
                  <StripeSetupIntentForm {...{ customerEmail, customerName }} />
                </Elements>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </GridRow>
      </Grid>
    </>
  );
};

export default PaymentMethod;

import moment from 'moment';

export const mmm_custom_feed_field_validations = {
  date: {
    validateFxn: value => {
      const errorMsg = `VALIDATION FAILURE: Could not convert "${value}" into a date.`;
      const m = moment(value);
      if (!m.isValid()) throw errorMsg;
      return m.format('YYYY-MM-DD');
    },
    unique: true,
    allowEmpty: false,
  },
  currency: {
    validateFxn: value => {
      const errorMsg = `VALIDATION FAILURE: Could not convert "${value}" into a number.`;
      const negativeErrorMsg = `VALIDATION FAILURE: Can not accept negative values. Saw "${value}".`;
      // eslint-disable-next-line no-param-reassign
      if (value.charAt(0) === '$') value = value.substring(1);
      const n = Number(value);
      if (isNaN(n)) throw errorMsg;
      if (n < 0) throw negativeErrorMsg;
      return n;
    },
    unique: false,
    allowEmpty: false,
  },
};

export const DEFAULT_STRATEGIES = ['Prospecting', 'Retargeting', 'Add New'];
export const KPI_OPTIONS = ['Revenue', 'Spend'];
// export const TEST_CHANNELS = ['Facebook', 'Adwords Brand', 'Add New'];
export const TEST_CHANNELS = ['Add New'];

export const stepNames = {
  upload: 'Upload your data',
  map: 'Map columns',
  verify: 'Verify  data',
};

export const CHART_COLOR = '#47d0dc';

import React from 'react';
import { FilterWrapper, RollupFilter, DateRangeHooks, StyledDropdown, SaveReportButton, ResetFilterButton } from '../../components';
import { REPORT_TYPE_OPTIONS_NEW_VISITORS } from '../../constants/options';

const Filter = ({
  loading, firstVisitsRaw, // data
  startDate, endDate, onDateChange, // dates
  reportType, setReportType, // report type
}) => (
  <FilterWrapper
    {...{ loading }}
    other={(
      <StyledDropdown
        label="Report Type"
        options={REPORT_TYPE_OPTIONS_NEW_VISITORS}
        value={reportType}
        onChange={(e, { value }) => setReportType(value)}
      />
      )}
    date={
      <DateRangeHooks {...{ startDate, endDate, onDateChange }} />
      }
    stickyRight={(
      <>
        <SaveReportButton {...{ loading }} />
        <ResetFilterButton />
      </>
      )}
    advancedFilters={(
      <RollupFilter
        rawData={firstVisitsRaw}
        rollupHeadingLabel="Marketing Channels"
      />
)}
  />
);

export default Filter;

import { connect } from 'react-redux';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AttributableEvent from './component';
import * as routes from '../../routes';
import { getAttributableEvents, getSegmentFields, getTransformTables } from '../../utils/api';

const getCategory = (fields) => (key) => {
  return Object.keys(fields).find((field) => {
    return fields[field].includes(key);
  });
}

class AttributableEventData extends Component {
  state = {
    transforms: [],
    segmentFields: [],
    attributableEvents: [],
    editing: undefined,
    editBuffer: undefined
  }

  componentDidMount() {
    this.getFields();
    this.getTransforms();
  }

  getTransforms = () => {
    getTransformTables()
      .then(transforms => {
        transforms.map(row => {
          row.text = row.name
          row.value = row.id
          row.key = row.id
        })
        this.setState({ transforms })
      })
  }
  getFields = () => {
    getSegmentFields()
      .then(fields => {
        const updatedFields = fields.map(c => {
          if(c.hasOwnProperty('type')) {
            c.field = c.field.toLowerCase().trim();
          }
          return c;
        })

        const data = updatedFields.reduce((fieldsObj, seg) => {
          const type = seg.type || 'other';
          fieldsObj[type] = fieldsObj[type] || [];

          if (!fieldsObj[type].includes(seg.field)) {
            fieldsObj[type].push(seg.field);
          }

          return fieldsObj;
        }, {});

        this.setState({segmentFields: data}, () => this.getEvents())
      })
      .catch(error => {
        this.setState({segmentFields: []}, () => this.getEvents())
      })
  }

  getEvents = () => {
    getAttributableEvents()
      .then(attributableEvents => {
        const { segmentFields } = this.state
        const getCat = getCategory(segmentFields)
        const { id } = this.props.match.params
        const editing = this.props.match.path.includes('edit') ? true : false;
        const copying = this.props.match.path.includes('copy') ? true : false;
        const editBuffer = ((editing || copying) && id && id !== 0 ) ? attributableEvents.filter(c => c.id == id)[0] : undefined;
        if (editBuffer != undefined) {
          const group = Object.assign([], editBuffer.group);

          group.forEach((subGroup) => {
            subGroup.logic.forEach((log) => {
              const { field } = log;
              if (log.field === 'url' || log.field === 'referrer') {
                log.category = log.field;
              } else {
                log.param = log.field;
                log.category = getCat(log.field) || "custom_tier";
              }
              if(!log.hasOwnProperty('type')) {
                log.type = log.field;
              }
            })
          });

          editBuffer.group = group;
        }

        if(copying) {
          editBuffer.name = "";
        }

        this.setState({attributableEvents, editing, editBuffer});
      })
  }

  returnToIndex = type => {
    const url = type === 'exclude' ? routes.excludedEventIndex : routes.mappedEventIndex;
    return this.props.history.push(url)
  };

  render() {
    const { match: { path, params: { urlId } } } = this.props;
    const { segmentFields, attributableEvents, editing, editBuffer } = this.state;

    const isMapped = path.indexOf(routes.mappedEventIndex) > -1;
    const what = isMapped ? "Bucketing Rule" : "Exclusion Rule";
    const type = isMapped ? "rename" : "exclude";
    return (
      <AttributableEvent
        {...{what, type, urlId}}
        {...this.props}
        segmentFields={segmentFields}
        attributable_events={attributableEvents}
        editing={editing}
        editBuffer={editBuffer}
        returnToIndex={this.returnToIndex}
        transforms={this.state.transforms}
      />
    )
  }
}

function mapStateToProps(state, props) {
  return {
    artifacts: state.app.artifacts,
  };
}

export default withRouter(connect(mapStateToProps)(AttributableEventData));

import React from 'react';
import { Grid, Statistic } from 'semantic-ui-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { TrendingDownIcon, TrendingUpIcon } from '@heroicons/react/solid';
import { percentFormatter } from './helpers';

const STATS_LABEL_STYLES = {
  textAlign: 'left',
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '1.2em',
};

const TREND_WRAP_STYLES = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  marginLeft: 10,
};

const TREND_ICON_STYLES = {
  display: 'inline-block',
  height: 20,
  marginRight: 2,
  marginBottom: -4,
};

const TREND_STAT_STYLES = {
  fontSize: 16,
};

const TREND_RANGE_STYLES = {
  color: '#c7c7c7',
  fontSize: 12,
  fontWeight: 500,
};

const ConversionStat = ({ label, value, formatter, color, trend }) => {
  const decreasing = trend.pctChange < 0;
  const TrendIcon = decreasing ? TrendingDownIcon : TrendingUpIcon;
  const trendColor = decreasing ? '#E76D8E' : '#75BE42';

  return (
    <Grid.Column style={{ display: 'flex' }}>
      <Statistic size="small" color="purple" style={{ margin: 0 }}>
        <Statistic.Label style={STATS_LABEL_STYLES}>
          {label}
        </Statistic.Label>
        <Statistic.Value style={{ color, textAlign: 'left' }}>
          {formatter(value)}
        </Statistic.Value>
      </Statistic>
      {!!trend.pctChange
        && (
        <div style={TREND_WRAP_STYLES}>
          <div style={{ ...TREND_STAT_STYLES, color: trendColor }}>
            <TrendIcon
              style={TREND_ICON_STYLES}
            />
            {percentFormatter(Math.abs(trend.pctChange))}
          </div>
          <div style={TREND_RANGE_STYLES}>
            {`over the last ${trend.range} day${trend.range > 1 ? 's' : ''}`}
          </div>
        </div>
        )}
    </Grid.Column>
  );
};

export default ConversionStat;

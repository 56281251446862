import React, { createContext, useState, useMemo } from 'react';
import { Sidebar } from 'semantic-ui-react';

export const DimmerContext = createContext();

export const DimmerContextProvider = ({ children }) => {
  const [dimmed, setDimmed] = useState(false);
  const contextValue = useMemo(() => ({ dimmed, setDimmed }), [dimmed, setDimmed]);

  return (
    <DimmerContext.Provider value={contextValue}>
      <Sidebar.Pushable>
        <Sidebar.Pusher dimmed={dimmed}>
          {children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>

      <div id="v3-custom-sidebar" />
    </DimmerContext.Provider>
  );
};

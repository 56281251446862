import React, { useState } from "react";
import SetupStatus, { defaultFunc } from "../DataStatus";

const Status = (props) => {
  const { segmentId, username } = props;
  const menus = ["Status & Backfill", "View Data", "Set Threshold"];
  const [activeMenu, setActiveMenu] = useState(menus[0]);
  const [entityData, setEntityData] = React.useState([]);

  const menuProps = {
    menus,
    activeMenu,
    setActiveMenu,
  };

  const datasets = [{ dataset: "ott_difference", display: "Has OTT Data?" }];

  const funcs = [
    {
      func: defaultFunc,
      text: "Backfill",
      display: "",
      url: "/run/agg/ott",
      username,
    },
  ];

  const ContentMap = {
    "Status & Backfill": (
      <SetupStatus filter_id={segmentId} {...{ datasets, funcs }} />
    ),
    "View Data": null,
    "Set Threshold": null,
  };

  const HasDataPage = segmentId ? ContentMap[activeMenu] : null;

  return HasDataPage;
};

export default Status;

import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { map } from 'lodash/fp';
import { postRedshiftInit } from '../../../api/datalake';
import {useStepsDisplay, useStepsState} from '../StepsSectionContext';
import { REDSHIFT_REGIONS } from '../constants';
import ErrorMessage from '../components/ErrorMessage';
import {useDatalake} from "../DatalakeContext/hooks";

const getRegionOptions = map(region => ({ key: region, value: region, text: region }));
const formStyles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  height: 'auto',
};

const RedshiftInitialize = () => {
  const { datalakeConnection, setDatalakeConnection } = useDatalake();
  const [stepDisplay, setStepDisplay] = useStepsDisplay();
  // eslint-disable-next-line no-unused-vars
  const [_, setStepsStateData] = useStepsState();
  // eslint-disable-next-line no-unused-vars
  const [stateData, setStateData] = useStepsState();

  const [clientRegion, setClientRegion] = useState('');
  const [error, setError] = useState('');
  const [, setStepsDisplay] = useStepsDisplay();
  const AwsRegionOptions = getRegionOptions(REDSHIFT_REGIONS);
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    if (datalakeConnection?.details) {
      const {
        details: {
          connection_details: {
            client_region
          }
        }
      } = datalakeConnection;
      setStepDisplay(1, true);
      setDisableSubmit(true);
      setClientRegion(client_region)
    }
  }, [datalakeConnection]);

  const handleSubmitRegion = () => {
    setError('');
    setDisableSubmit(true);
    postRedshiftInit(clientRegion)
      .then(data => {
        // eslint-disable-next-line no-param-reassign
        data = data.data;
        if (data.status === 'success') {
          setStepsDisplay(1, true);
        }
        setDisableSubmit(false);
      })
      .catch(e => {
        setError(e.response.data.message);
        setDisableSubmit(false);
      });
  };

  const handleDropdownChange = (_, { value }) => setClientRegion(value);

  return (
    <Form>
      <Form.Group style={formStyles}>
        <Form.Field>
          <Form.Dropdown
            label="AWS Region"
            placeholder="Select AWS Region"
            fluid
            search
            selection
            options={AwsRegionOptions}
            onChange={handleDropdownChange}
            value={clientRegion}
            style={{ width: 200 }}
          />
        </Form.Field>
        <Form.Field>
          <Form.Button
            primary
            size="small"
            content="Submit Region"
            type="button"
            onClick={handleSubmitRegion}
            style={{ fontSize: 13 }}
            disabled={disableSubmit}
          />
        </Form.Field>
      </Form.Group>
      {error && <ErrorMessage message={error} />}
    </Form>
  );
};

export default RedshiftInitialize;

import React, { useEffect, useMemo, useState } from 'react';

import { AreaChartLoader } from '@rockerbox/styleguide';
import _ from 'lodash';
import { ResponsiveContainer, ComposedChart, Legend, Area, Line, Tooltip, YAxis, XAxis } from 'recharts';
import { Header } from 'semantic-ui-react';

import { axisLabelStyles, CustomAxisTick, TooltipRow, tooltipStyles, PLATFORM_COLORS } from './chartHelpers';
import { Tooltip as InfoTooltip } from '../../../components';
import { colorHash } from '../../../utils/colorHash';
import { formatCurrency } from '../../../utils/valueFormatter';
import { roundNumber } from '../helpers';

const CustomTooltip = ({ active, payload, features, kpiText }) => {
  if (!active || !payload?.length) return null;

  const date = payload?.[0]?.payload?.date;
  const sum = payload.filter(d => d.dataKey !== kpiText).reduce((acc, p) => (acc + p.value), 0);
  const kpi = payload.find(p => p.dataKey === kpiText)?.value || 0;

  return (
    <div style={tooltipStyles}>
      <TooltipRow label={date} />
      {payload
        .filter(p => p.dataKey !== kpiText)
        .map((p, i) => (
          <TooltipRow key={i} color={p.fill} label={features.find(x => x.value === p.name)?.name || p.name} value={formatCurrency(p.value, 'USD', 0)} />
        ))}
      <TooltipRow final label="Total Daily Spend" value={formatCurrency(sum, 'USD', 0)} style={{ marginBottom: 10 }} />
      <TooltipRow color="#dc47cb" label={`Daily ${kpiText}`} value={kpiText === 'revenue' ? formatCurrency(kpi, 'USD', 0) : roundNumber(kpi, 2)} style={{ marginBottom: 0 }} />
    </div>
  );
};

const useTrainingData = ({ trainingData, predVsActual, features, kpiText }) => {
  const [visibility, setVisibility] = useState({});

  const data = useMemo(() => {
    if (!trainingData) return;
    return _.zipWith(trainingData, predVsActual, (a, b) => ({ ...a, [kpiText]: b?.actual || 0 }));
  }, [trainingData, kpiText]);

  const featureColors = useMemo(
    () => features.reduce((acc, f) => {
      const platformColor = PLATFORM_COLORS?.[f.platform] || colorHash(f.platform);

      return { ...acc, [f.value]: platformColor };
    }, {}),
    [features],
  );

  useEffect(() => {
    const visible = features.reduce((acc, f) => ({ ...acc, [f.value]: true }), {});
    setVisibility(visible);
  }, [features]);

  return { data, visibility, setVisibility, featureColors };
};

const TrainingDataChart = ({ isConversionKPI, trainingData, features, featureNameMap, predVsActual }) => {
  const kpiText = isConversionKPI ? 'conversions' : 'revenue';
  const { data, visibility, setVisibility, featureColors } = useTrainingData({ trainingData, predVsActual, features, kpiText });

  const chartFeatures = features.filter(f => !f.value.endsWith('_IMP'));

  const updateVisibility = evt => {
    const { dataKey } = evt;
    const isVisible = !visibility[dataKey];
    setVisibility({ ...visibility, [dataKey]: isVisible });
  };

  const renderLegend = value => featureNameMap[value] || value;

  return (
    <>
      <Header
        as="h4"
        content={(
          <>
            Training Data
            <InfoTooltip
              position="right center"
              content={`Review the advertising spend and ${kpiText} data that was used to train your model.`}
              style={{ fontSize: 14, marginLeft: 5, marginBottom: 5 }}
            />
          </>
        )}
      />
      {!data?.length ? (
        <AreaChartLoader style={{ marginBottom: 50 }} />
      ) : (
        <ResponsiveContainer width="100%" height={350}>
          <ComposedChart data={data} margin={{ top: 5, right: 5, bottom: 5, left: 50 }}>
            <Legend verticalAlign="top" align="left" layout="vertical" wrapperStyle={{ paddingRight: '10px' }} onClick={updateVisibility} formatter={renderLegend} />
            <XAxis dataKey="date" tickLine={false} minTickGap={180} tick={CustomAxisTick('x', 'date')} stroke="#e6e6e6" />
            <YAxis
              yAxisId="spend"
              type="number"
              tickLine={false}
              minTickGap={70}
              tick={CustomAxisTick('y', 'currency')}
              label={{ value: 'Spend', angle: -90, position: 'insideLeft', offset: 20, style: axisLabelStyles }}
              stroke="#e6e6e6"
              orientation="left"
            />
            <YAxis hide yAxisId={kpiText} type="number" />

            <Tooltip content={<CustomTooltip {...{ features: chartFeatures, kpiText }} />} />

            {chartFeatures.map((feature, i) => (
              <Area
                key={i}
                dataKey={feature.value}
                yAxisId="spend"
                type="monotone"
                dot={false}
                fill={featureColors?.[feature.value]}
                stroke={featureColors?.[feature.value]}
                strokeWidth={1}
                strokeOpacity={0.6}
                isAnimationActive={false}
                stackId="features"
                hide={!visibility[feature.value]}
              />
            ))}
            <Line dataKey={kpiText} yAxisId={kpiText} stroke="#dc47cb" type="monotone" dot={false} strokeWidth={2} strokeOpacity={0.5} isAnimationActive={false} />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default TrainingDataChart;

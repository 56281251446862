import {
  SET_CONVERSION_SEGMENTS,
  SET_CONVERSION_SEGMENT
 } from './index';

const initialState = {
  segments: [],
  selected: "",
  selectedParams: {}
}

export default function AttributionHeaderReducer (state = initialState, action) {
  switch (action.type) {
    case SET_CONVERSION_SEGMENTS:
      return {
        ...state,
        segments: action.data
      }
    case SET_CONVERSION_SEGMENT: 
       return {
         ...state,
         selected: action.data.action_name,
         selectedParams: action.data

       }
    default:
      return state;
  }
}

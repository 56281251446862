import * as aq from 'arquero';
import { generateBlankData } from './helpers';

const DEFAULT_COLUMNS = ['even', 'first_touch', 'last_touch'];
const DEFAULT_NTF_COLUMNS = DEFAULT_COLUMNS.map(col => `ntf_${col}`);
const DEFAULT_REPEAT_COLUMNS = DEFAULT_COLUMNS.map(col => `repeat_${col}`);
const MODEL_COLUMNS = ['normalized'];
const MODEL_NTF_COLUMNS = MODEL_COLUMNS.map(col => `ntf_${col}`);
const MODEL_REPEAT_COLUMNS = MODEL_COLUMNS.map(col => `repeat_${col}`);

const DEFAULT_WITH_NTF = [...DEFAULT_COLUMNS, ...DEFAULT_NTF_COLUMNS, ...DEFAULT_REPEAT_COLUMNS];
const MODEL_WITH_NTF = [...MODEL_COLUMNS, ...MODEL_NTF_COLUMNS, ...MODEL_REPEAT_COLUMNS];

const getColumnsViaDatasetProperties = (hasModel, hasNTF) => (hasModel && hasNTF ? [...DEFAULT_WITH_NTF, ...MODEL_WITH_NTF]
  : hasModel ? [...DEFAULT_COLUMNS, ...MODEL_COLUMNS]
    : hasNTF ? [...DEFAULT_WITH_NTF]
      : [...DEFAULT_COLUMNS]);

const hasColumn = (table, column) => table
  .columnNames()
  .includes(column);

const constructSumRollups = columns => columns.reduce((accu, col) => {
  accu[`${col}`] = `d => op.sum(op.number(d['${col}']))`;

  const isNTF = col.includes('ntf');
  const isRepeat = col.includes('repeat');
  const revenueColumn = isNTF ? col.replace('ntf', 'ntf_revenue')
    : isRepeat ? col.replace('repeat', 'repeat_revenue') : `revenue_${col}`;

  accu[`${revenueColumn}`] = `d => op.sum(op.number(d['${revenueColumn}']))`;
  return accu;
}, {});

/**
 * Verifies if all dates in the data are present within a specified date range.
 *
 * @param {Table} aqData - The table data in arquero to be verified.
 * @param {string} startDate - The start date of the date range.
 * @param {string} endDate - The end date of the date range.
 * @returns {Array} - The verified data with missing dates inserted if necessary.
 */
export const verifyAllDatesInData = (aqData, startDate, endDate) => {
  const blankDatesDataObj = generateBlankData(startDate, endDate);
  const summaryForDates = aqData.objects();

  if (summaryForDates.length !== blankDatesDataObj.length) {
    const insertMissingDates = blankDatesDataObj.map(date => {
      const foundDateInData = summaryForDates.find(obj => obj.date === date.date);
      return foundDateInData || date;
    });
    return insertMissingDates;
  }
  return summaryForDates;
};

/**
 * Calculates statistics based on the provided parameters.
 *
 * @param {Array} groupby - The columns to group the data by.
 * @param {Table} tiersDataRaw - The table data in arquero to perform calculations on.
 * @param {string} conversionKey - The key for the conversion column selected.
 * @param {string} revenueKey - The key for the revenue column selected.
 * @param {string} startDate - The start date for the data range.
 * @param {string} endDate - The end date for the data range.
 * @param {boolean} [raw=false] - Whether to return the raw summary or not.
 * @returns {Array|Table} - The calculated statistics, returns it in arquero if raw = true.
 */
export const getStats = (groupby, tiersDataRaw, conversionKey, revenueKey, startDate, endDate, raw = false) => {
  aq.addFunction('number', Number);
  const logString = groupby.join(', ');

  if (!tiersDataRaw) return [];
  console.time(`new - ${logString}`);
  const hasModel = hasColumn(tiersDataRaw, 'normalized');
  const hasNTF = hasColumn(tiersDataRaw, 'ntf_even');
  const columns = getColumnsViaDatasetProperties(hasModel, hasNTF);
  const hasSpend = hasColumn(tiersDataRaw, 'spend');

  const spend = {};

  if (hasSpend) {
    spend.spend = 'd => op.sum(op.number(d.spend))';
  }

  const specific = constructSumRollups(columns);
  const rollup = { ...spend, ...specific };

  const summary = tiersDataRaw
    .groupby(groupby)
    .rollup(rollup);

  if (raw) return summary;

  if (groupby.join() === 'date') {
    return verifyAllDatesInData(summary, startDate, endDate);
  }
  console.timeEnd(`new - ${logString}`);

  console.time(`new objects - ${logString}`);
  const summaryObjects = summary.objects();
  console.timeEnd(`new objects - ${logString}`);

  return summaryObjects;
};

export const filterTiers = (raw, filters, limitedTiers = false) => {
  const { tier_1, tier_2, tier_3, tier_4, tier_5 } = filters;
  const tiers = limitedTiers ? { tier_1, tier_2, tier_3 } : { tier_1, tier_2, tier_3, tier_4, tier_5 };

  Object.keys(tiers).forEach(t => {
    if (tiers[t] === undefined || tiers[t].length === 0) delete tiers[t];
  });

  const toFilter = raw
    .params(tiers);

  const filtered = Object.entries(tiers)
    .reduce((data, [key, values]) => {
      if (values.length === 0) return data;
      // const filteredTable = toFilter
      //   .filter(`(d, $) => op.includes($['${key}'], d['${key}'])`)

      // return i === 0 ? filteredTable : data.union(filteredTable)

      return data.filter(`(d, $) => op.includes($['${key}'], d['${key}'])`); // enable if using regular rollup in advanced
    }, toFilter);

  return filtered;
};

export const getTotals = table => {
  const columns = table.columns();
  const numericColumns = Object.keys(columns)
    .filter(col => typeof columns[col].data[0] === 'number')
    .reduce((accu, col) => {
      accu[col] = `d => op.sum(op.number(d['${col}']))`;
      return accu;
    }, {});

  return table.rollup(numericColumns);
};

/**
 * Filters the given raw data based on the derived tiers filter.
 *
 * @param {Object} raw - The raw data (in arquero) to be filtered.
 * @param {Object} derivedTiersFilter - The filter object containing analysisType, analysisSearch, and platformStrategySearch.
 * @returns {Object} - The filtered data.
 */
export const filterSearchForDerivedTiers = (raw, derivedTiersFilter) => {
  if (!derivedTiersFilter || !Object.keys(derivedTiersFilter).length) return raw;

  const { analysisType, analysisSearch, platformStrategySearch } = derivedTiersFilter;

  const analysisSearchString = analysisSearch.join("', '");
  const filtered = raw
    .filter(`(d, $) => op.includes(['${analysisSearchString}'], d['${analysisType}'])`);

  if (platformStrategySearch.length > 0) {
    const platformStrategySearchString = platformStrategySearch.join("', '");
    return filtered.filter(`(d, $) => op.includes(['${platformStrategySearchString}'], d.strategy)`);
  }

  return filtered;
};

export const filterSearch = (raw, search, derivedTiersFilter, limitedTiers = false) => {
  if (derivedTiersFilter) return filterSearchForDerivedTiers(raw, derivedTiersFilter);
  if (!search || search.length === 0) return raw;

  const escapedSearch = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  raw.params({
    search: escapedSearch,
  });

  const filtered = raw
    // made new column "searchable" -- lower case and add all tiers together
    .derive({
      searchable: limitedTiers ? 'd => op.lower(d.tier_1 + d.tier_2 + d.tier_3)' : 'd => op.lower(d.tier_1 + d.tier_2 + d.tier_3 + d.tier_4 + d.tier_5)',
    })
    .filter('(d, $) => op.match(d[\'searchable\'], $[\'search\'])');

  return filtered;
};

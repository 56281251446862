import React from 'react';
import * as Router from 'react-router-dom';
import ButterToast from 'butter-toast';
import { ToastStyles, CloseButtonStyles } from '../Toast';
import * as routes from '../../routes';
import { getSegments, createConversion, attachChildConversion, deleteSegment } from '../../utils/api';
import CacheContext from '../../utils/CacheContext';

import { Segment, Form, Dropdown, Table, Popup, Button } from 'semantic-ui-react';
import { IndexGridNew } from '@rockerbox/styleguide';

import UrlButton from '../parts/UrlButton';
import { defaultCols } from '../ConversionIndex/component';

import _ from 'lodash';

const ManageCell = (deleteChild) => ({ item }) => (
  <Table.Cell collapsing>
    <IndexGridNew.EditButton url={routes.editConversionEvent + '/' + item.action_id} />
    <Popup inverted content='View Conversion Event'
      trigger={
        <UrlButton url={routes.viewConversionEvent + '/' + item.action_id} icon="eye" />
      } />
    <IndexGridNew.DeleteButton onDelete={() => deleteChild(item.action_id)} />
  </Table.Cell>
)


const AttachChild = ({ attachChild }) => {
  
  const [state, setState, Context] = React.useContext(CacheContext)
  
  const [selectedChild, setSelectedChild] = React.useState(false)
  const setChild = (_, { value }) => setSelectedChild(value)
  const onSubmit = () => attachChild(selectedChild)
  const segments = (Context.getCache('segments', getSegments) || [])
  .filter(row => row.action_type == "conversion" && !row.is_child)
  
  React.useEffect(() => {
    segments.map(row => {
      row.text = row.action_name;
      row.value = row.filter_id;
    })
  }, [segments.length])
  
  return <Segment secondary>
    <b>Attach Segment as Child</b><br />
    <br />
    <Dropdown onChange={setChild} options={segments} selection /> 
    <Button onClick={() => attachChild(selectedChild)} disabled={selectedChild == false} as='a'>Add</Button>
  </Segment>
}



const ChildSegments = ({ childSegments, prepareSubmit, refreshEdit }) => {
  
  const { id } = Router.useParams()
  const [showAttachChild, setShowAttachChild] = React.useState(false)
  const [showCreateChild, setShowCreateChild] = React.useState(false)
  const [childSuffix, setChildSuffix] = React.useState("")
  
  const revealAttachChild = () => {
    setShowAttachChild(true)
    setShowCreateChild(false)
  }
  const revealCreateChild = () => {
    setShowAttachChild(false)
    setShowCreateChild(true)
  }
  const createChild = () => {
    const data = _.cloneDeep(prepareSubmit());
    data.action_name += ' - ' + childSuffix;
    createConversion(data)
    .then((resp) => {
      if (resp.response.error) {
        throw Error(resp.response.error);
      }
      return resp;
    })
    .then((resp) => {
      const childId = resp.response['action_id'];
      attachChild(childId);
      setChildSuffix('');
    })
    .catch((err) => {
      console.log('Unable to create child segment', err);
      ButterToast.raise({
        dismissOnClick: true,
        content: ({ toastId, dismiss }) => (
          <ToastStyles>
              <CloseButtonStyles>x</CloseButtonStyles>
              {`There was an error with your submission. ${err}`}
            </ToastStyles>
          ),
          toastTimeout: 5000,
      });
    });
  };
    
  const attachChild = (selectedChild) => {
    const data = {
      child_filter_id: String(selectedChild),
      parent_filter_id: String(id),
    };
    
    attachChildConversion(data).then((resp) => {
      refreshEdit();
    });
    // TODO: api for attaching
  };
    
  const deleteChild = (id) => {
    deleteSegment(id).then(() => {
      ButterToast.raise({
        dismissOnClick: true,
        content: ({ toastId, dismiss }) => (
          <ToastStyles>
            <CloseButtonStyles>x</CloseButtonStyles>
            Your conversion event was successfully deleted.  So long!
          </ToastStyles>
        ),
        toastTimeout: 5000
      });
      refreshEdit();
    })
  };
  
  const cols = [
    ...defaultCols,
    { display: "Manage", key: "manage", as: ManageCell(deleteChild) }
  ];
  
  return <>
    <IndexGridNew data={childSegments} cols={cols} fallBackMsg={"No Child Segments"}/>
    <Button as='a' size='small' onClick={revealAttachChild}>Attach Existing Segment</Button>
    <Button as='a' size='small' onClick={revealCreateChild}>+ Create Child Segment</Button>
    <br />
    
    { showAttachChild && <AttachChild {...{attachChild}} /> }
    { showCreateChild && 
      <Segment secondary>
        <b>Child Segment Name</b><br/>
        <br/>
        <Form.Input onChange={(_,{value}) => setChildSuffix(value)} value={childSuffix} /> 
        <Button as='a' onClick={createChild}>Create Child</Button>
      </Segment>
    }
  </>
}

export default ChildSegments;

import React, {Component} from 'react';
import { getBatchConfigById } from '../../utils/api';
import { ContentCard, PagedIndexGrid } from '@rockerbox/styleguide';
import { Table, Icon } from 'semantic-ui-react';
import moment from 'moment';

const SuccessCell = ({item, col}) => {
  return (
    <Table.Cell>
      <Icon
        color={item[col.key] ? 'green' : 'red'}
        name={item[col.key] ? 'checkmark' : 'close'} size='large' />
    </Table.Cell>
  )
}

const DateCell = ({item, col}) => {
  const timestamp = moment(item[col.key]).format('MMM D, YYYY - h:mm a');

  return <Table.Cell>{timestamp}</Table.Cell>
}

class ProcessedView extends Component {
  state = {
    configObj: {},
    loadStatus: []
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    getBatchConfigById(id)
    .then(data => {
      const configObj = data.find(c => c.id == id);

      this.setState({configObj, loadStatus: configObj.load_status})
    })

  }
  render() {
    const { loadStatus, configObj } = this.state;
    const cols = [
      {display: 'File Name', key: 'filename'},
      {display: 'Date', key: 'updated_at', as: DateCell},
      {display: 'Processed', key: 'process_success', as: SuccessCell},
      {display: 'Upload ID', key: 'upload_id'}

    ]

    return (
      <ContentCard hasTable title={configObj.name}>
        <PagedIndexGrid cols={cols} data={loadStatus} itemsPerPage={50}/>
      </ContentCard>
    )
  }
}

export default ProcessedView;

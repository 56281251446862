/* eslint-disable no-unused-vars, no-shadow */
import { rawBufferToArrowBufferIPC, arrowBufferIPCToArrowTable } from '../format/convertFormat';
import init from '../parquet';

const path = '/v3/static/js/arrow1_bg.wasm';
let arrowIsInit = false;
init(path).then(resp => {
  arrowIsInit = resp;
});

/*
 * Functions for interacting with /report_cache/data APIs
 */

const buildBase = dataset => `/report_cache/data/${dataset}`;
const buildMetaBase = dataset => `/report_cache/stats/${dataset}`;
const buildDataURL = (date, identifier, dataset) => `${buildBase(dataset)}?start_date=${date}&end_date=${date}&identifier=${identifier}&format=raw`;
const buildDataURLWithId = (date, identifier, dataset, id) => `${buildBase(dataset)}?start_date=${date}&end_date=${date}&identifier=${identifier}&id=${id}&format=raw`;
const buildMetaDataUrl = (startDate, endDate, identifier, dataset) => `${buildMetaBase(dataset)}?start_date=${startDate}&end_date=${endDate}&identifier=${identifier}`;
const buildMetaDataUrlWithoutIdentifier = (startDate, endDate, dataset) => `${buildMetaBase(dataset)}?start_date=${startDate}&end_date=${endDate}`;

// Used to fetch
const fetchMetaData = dataset => (identifier, startDate, endDate, dataset) => {
  const statsUrl = identifier
    ? buildMetaDataUrl(startDate, endDate, identifier, dataset)
    : buildMetaDataUrlWithoutIdentifier(startDate, endDate, dataset);

  return fetch(statsUrl, { credentials: 'include' })
    .then(response => response.json())
    .then(({ current }) => current)
    .then(arr => arr.map(row => ({ ...row, dataset, identifier: identifier || row.identifier })));
};

const fetchData = dataset => ({ date, identifier, id }) => {
  const url = id ? buildDataURLWithId(date, identifier, dataset, id)
    : buildDataURL(date, identifier, dataset);
  return fetch(url, { credentials: 'include' });
};

// Used to get

export const getMetaData = fetchMetaData;

export const getData = dataset => async ({ dataset, date, identifier, id }) => {
  const promise = await fetchData(dataset)({ date, identifier, id });
  const rawBuffer = await promise.arrayBuffer();
  const buffer = await rawBufferToArrowBufferIPC(rawBuffer);
  const data = await arrowBufferIPCToArrowTable(buffer);
  return { buffer, data, dataset, date, identifier, id };
};

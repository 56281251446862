import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

/*
    <StyledDropdown
      label="Customer Type"
      disabled={!hasNtf || noData}
      selection
      options={NTF_OPTIONS}
      value={ntfFilter}
      onChange={handleNtfChange}
    />
*/

export const Label = styled.div`
  font-family: 'Poppins' !important;
  color: #333333;
`;
export const RockerboxStyledDropdown = styled(Dropdown)`
  border: solid 0.5px #bfc6f3 !important; 
  .text {
    color: #333333 !important;
  }
`;
const StyledDropdown = ({
  label, disabled, options, value, onChange, advancedDropdown,
  text, defaultOpen = false, rightBtn,
  fluid = true, containerStyles, style,
  clearable, className, multiple = false,
}) => (
  <div className="styled-dropdown-container" style={containerStyles || { paddingRight: '12px' }}>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Label>{label}</Label>
      {rightBtn && rightBtn}
    </div>
    <RockerboxStyledDropdown
      className={className}
      style={style}
      multiple={multiple}
      selection
      disabled={disabled}
      options={options}
      value={value}
      onChange={onChange}
      text={text}
      defaultOpen={defaultOpen}
      fluid={fluid}
      clearable={clearable}
    >
      {advancedDropdown && advancedDropdown}
    </RockerboxStyledDropdown>
  </div>
);

export default StyledDropdown;

import React from 'react';
import { Header } from 'semantic-ui-react';
import { SplitLayout } from '@rockerbox/styleguide';
import { PixelCnameDomain, AllowedDomains, ExcludedDomains, ViewAccessWrapper } from '../../components';

const Domains = () => (
  <>
    <Header
      as="h1"
      content="Manage Your Custom Domain"
    />
    <PixelCnameDomain />
    <SplitLayout
      rightWidth={8}
      leftWidth={8}
      rightContent={<ExcludedDomains />}
      leftContent={<AllowedDomains />}
    />
  </>
);

const DomainsWrapper = () => (
  <ViewAccessWrapper
    viewName="settings_domains"
    viewComponent={<Domains />}
  />
);

export default DomainsWrapper;

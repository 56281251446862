import React from 'react';
import { Route } from 'react-router-dom';
import { Aux } from '../stateless';
import ApiDocs from './apiDocs';
import ReportDocs from './reportDocs';

const Documentation = () => (
  <Aux>
    <Route exact path="/documentation/api" component={ApiDocs} />
    <Route exact path="/documentation/reports/:id?" component={ReportDocs} />
  </Aux>
);

export default Documentation;

import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { Button, Popup, Dropdown } from 'semantic-ui-react';
import DateRangePicker from './DateRangePicker';
import {
  yesterday, startOfMonth, startOfPreviousMonth,
  endOfPreviousMonth, firstReportingDate as firstReportDate,
  daysAgo, numDaysAgo, reformatDateMMDDYYYY, reformatDateYYYYMMDD,
  startOfYear, startOfQuarter, startOfPreviousQuarter,
  endOfPreviousQuarter,
  PRESET_DATE_RANGES,
} from '../../utils/time';

const DateWrap = styled.div`
  .DateInput_input {
    font-size: 14px;
    text-align: center;
    font-family: 'Inter', 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }

  .DateRangePickerInput_arrow {
    margin: 0 30px;
  }

  .DateInput_input__focused {
    border: none;
    background: rgba(71, 93, 220, 0.10);
    border-radius: 4px;
  }

  .DateRangePicker_picker {
    right: 0px !important;
  }

  .CalendarMonth_caption,
  .DayPicker_weekHeader_li {
    font-family: 'Poppins', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }

  .CalendarDay {
    font-family: 'Inter', 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-feature-settings: "kern" 1, "tnum" 1;
  }
`;

const CalendarButton = styled(Button)`
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border: ${props => (props.invert ? '1px solid rgba(255,255,255,.15) !important' : '1px solid rgba(34,36,38,.15) !important')};
  border-left: none !important;
  box-shadow: none !important;
  color: ${props => (props.invert ? '#FFF !important' : '#475ddc !important')};
  background: transparent !important;

  &:hover {
    border-color: transparent !important;
    background-color: ${props => (props.invert ? 'rgba(255,255,255,.15) !important' : '#475ddc !important')};
    color: white !important;
  }
`;

const CalendarDropdown = styled(Dropdown)`
  border-radius: 4px 0 0 4px;
  line-height: 1em;
  white-space: normal;
  min-height: 36px;
  background: transparent !important;
  display: inline-block;
  padding: 11px 14px;
  border: ${props => (props.invert ? '1px solid rgba(255,255,255,.15)' : '1px solid rgba(34,36,38,.15)')};
  outline: 0;
  font-family: Poppins;

  &:hover {
    border-color: ${props => (props.invert ? 'rgba(255,255,255,0) !important' : '#475ddc !important')};
    background-color: ${props => (props.invert ? 'rgba(255,255,255,.15) !important' : 'transparent !important')};
  }
`;

const LocalStateDatePicker = ({
  startDate = yesterday,
  endDate = yesterday,
  onDateChange = () => { },
  firstReportingDate = firstReportDate.format('YYYY-MM-DD'), // dates
  marginTop, marginBottom, invert = false, // styling
  presetDates,
}) => {
  const [showCustom, setShowCustom] = useState(false);
  const dateSelection = presetDates || PRESET_DATE_RANGES;
  const presetOptions = useMemo(() => dateSelection.map(({ text, numDays }) => ({ key: text, text, value: numDays })), [dateSelection]);

  const [_start, _setStart] = useState(startDate);
  const [_end, _setEnd] = useState(endDate);
  const [presetValue, setPresetValue] = useState(undefined);
  const [activeText, setActiveText] = useState('');
  const [recentDatesOptions, setRecentDatesOptions] = useState([]);

  const setStart = _setStart;
  const setEnd = _setEnd;

  const start = (_start);
  const end = (_end);

  const checkForCustomDates = (startDateCheck, endDateCheck) => {
    const checkDaysAgo = numDaysAgo(startDateCheck, endDateCheck, true, true);
    // const checkDaysAgo = 'custom'
    if (checkDaysAgo !== 'custom') {
      setPresetValue(checkDaysAgo);
      const findActiveText = dateSelection.find(({ numDays }) => numDays === checkDaysAgo) || 'custom';
      setActiveText(findActiveText.text);
      return false;
    }
    const customDateValue = `${reformatDateMMDDYYYY(startDateCheck)} - ${reformatDateMMDDYYYY(endDateCheck)}`;
    setPresetValue(customDateValue);
    setActiveText(customDateValue);
    return customDateValue;
  };

  useEffect(() => {
    if (start && end) {
      const startMomentDate = moment(start);
      const endMomentDate = moment(end);
      const firstReportingMomentDate = moment(firstReportingDate);

      if (endMomentDate.isBefore(firstReportingMomentDate)) {
        onDateChange({ startDate: firstReportingDate, endDate: firstReportingDate });
        return;
      }

      const checkStartVsFirstReportingDate = startMomentDate.isBefore(firstReportingMomentDate) ? firstReportingDate : start;
      const finalStart = endMomentDate.isBefore(moment(checkStartVsFirstReportingDate)) ? end : checkStartVsFirstReportingDate;

      onDateChange({ startDate: finalStart, endDate: end });
    }
    checkForCustomDates(start, end);
  }, [start, end, firstReportingDate]);

  useEffect(() => {
    if (showCustom) setShowCustom(false);
  }, [endDate, activeText]);

  useEffect(() => {
    if (presetValue === 'custom') {
      const customDateValue = checkForCustomDates(start, end);
      if (!customDateValue) return;

      const dates = JSON.parse(localStorage.getItem('dates')) || [];
      const foundInDates = dates.find(({ value }) => value === customDateValue);
      if (!foundInDates) {
        const customObj = {
          key: customDateValue,
          text: customDateValue,
          value: customDateValue,
        };

        const last4Dates = dates.length > 4 ? dates.slice(0, 4) : dates;
        localStorage.setItem('dates', JSON.stringify([customObj, ...last4Dates]));
        setRecentDatesOptions([customObj, ...last4Dates]);
      }
    }
  }, [presetValue, start, end]);

  useEffect(() => {
    if (moment(start).diff(moment(firstReportingDate), 'days') < 0) {
      setStart(firstReportingDate);
      setPresetValue('custom');
    }
  }, [start]);

  const onPresetDatesChange = (e, { text, value }) => {
    setPresetValue(value);
    setActiveText(text);
    if (value === 'prev_month') {
      setStart(startOfPreviousMonth);
      setEnd(endOfPreviousMonth);
      return;
    }
    if (value === 'current_month') {
      setStart(startOfMonth);
      setEnd(yesterday);
      return;
    }
    if (typeof value === 'number') {
      setStart(daysAgo(value));
      setEnd(yesterday);
      return;
    }
    if (value === 'previous_quarter') {
      setStart(startOfPreviousQuarter);
      setEnd(endOfPreviousQuarter);
      return;
    }
    if (value === 'quarter_to_date') {
      setStart(startOfQuarter);
      setEnd(yesterday);
      return;
    }
    if (value === 'year_to_date') {
      setStart(startOfYear);
      setEnd(yesterday);
      return;
    }
    const [customStart, customEnd] = value.split(' - ');
    setStart(reformatDateYYYYMMDD(customStart));
    setEnd(reformatDateYYYYMMDD(customEnd));
  };

  const setStartEnd = ({ startDate: startUpdate, endDate: endUpdate }) => {
    setPresetValue('custom');
    setStart(startUpdate.format('YYYY-MM-DD'));
    setEnd(endUpdate.format('YYYY-MM-DD'));
  };

  return (
    <div style={{ marginTop, marginBottom, display: 'flex', height: '100%', minWidth: 300 }}>
      <CalendarDropdown
        fluid
        text={activeText}
        scrolling={false}
        invert={invert}
        className={invert ? 'date-selected' : ''}
      >
        <Dropdown.Menu
          scrolling={false}
          style={{
            minWidth: '100%',
            backgroundColor: invert ? '#697AE5' : 'none',
            fontFamily: 'Poppins',
          }}
        >
          {presetOptions.map(({ key, text, value }) => (
            <Dropdown.Item
              {...{ key, text, value }}
              onClick={onPresetDatesChange}
              active={value === presetValue}
              selected={value === presetValue}
              style={{ color: invert ? '#fff' : 'none' }}
            />
          ))}
          {recentDatesOptions.length > 0 && (
          <>
            <Dropdown.Divider />
            <Dropdown.Header content="Recent date ranges" />
          </>
          )}
          {recentDatesOptions.map(({ key, text, value }) => (
            <Dropdown.Item
              {...{ key, text, value }}
              onClick={onPresetDatesChange}
              active={value === presetValue}
              selected={value === presetValue}
              style={{ color: invert ? '#fff' : 'none' }}
            />
          ))}
        </Dropdown.Menu>
      </CalendarDropdown>
      <Popup
        wide="very"
        trigger={(
          <CalendarButton
            icon="calendar alternate"
            onClick={e => e.preventDefault() && setShowCustom(true)}
            active={showCustom}
            invert={invert}
          />
        )}
        content={(
          <DateWrap>
            <DateRangePicker
              startDate={moment(start)}
              endDate={moment(end)}
              onChange={setStartEnd}
              {...{ firstReportingDate }}
            />
          </DateWrap>
)}
        on="click"
        open={showCustom}
        onClose={() => setShowCustom(false)}
        onOpen={() => setShowCustom(true)}
        position="bottom right"
        pinned
        positionFixed
        flowing
        basic
        style={{
          height: 396,
          width: 346,
        }}
      />
    </div>
  );
};

LocalStateDatePicker.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onDateChange: PropTypes.func,
  marginTop: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  marginBottom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  // fluid: PropTypes.bool,
  // firstReportingDate: PropTypes.object, // moment
  // showDates: PropTypes.bool,
  // inline: PropTypes.bool,
  // label: PropTypes.string,
};

export default LocalStateDatePicker;

/*
>>>>>>>> EXAMPLE <<<<<<<<<<
<DateRangeHooks
  {...{startDate, endDate, onDateChange, marginTop: '0', marginBottom: '0',   fluid: true, firstReportingDate}}
/>
*/

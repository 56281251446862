import moment from 'moment';
import * as d3 from 'rockerbox_d3_legacy_clone';

export default function cdfChart(w) {
  var margin = {
    top: 60, // top margin includes title and legend
    right: 20, // right margin should provide space for last horz. axis title
    bottom: 30,
    left: 10 // left margin should provide space for y axis titles
  };

  var _w = w || 840
  // height & width
  var width = (_w - margin.left - margin.right);
  var height = (_w/2 - margin.top - margin.bottom);
  var legendWidth = _w -240;
  var legendHeight = 300;

  // cosmetics
  var color = "steelblue";
  var title = '';

  // global div for tooltip


  var formatCount = d3.format(",.0f");

  function chart(selection) {
    selection.each(function drawGraph(touchpoints) {

      const values = touchpoints.map(x => x.num_users)

      var d3this = d3.select(this);

      var max = d3.max(values);
      var min = 10;
      var x = d3.scale.log()
            .base(10)
            .domain([min, max])
            .range([0, width]);
      
      var data = d3.layout.histogram()
          .bins(x.ticks(buckets))
          (values);

      var yMax = d3.max(data, function(d){return d.length});
      var yMin = d3.min(data, function(d){return d.length});
      var colorScale = d3.scale.linear()
                  .domain([yMin, yMax])
                  .range([d3.rgb(color).brighter(), d3.rgb(color).darker()]);
      
      var y = d3.scale.linear()
          .domain([0, yMax])
          .range([height, 0]);


      var barWidth = width/data.length;

      var xLinear = d3.scale.ordinal()
          .domain(data.map(x => x.x).filter((x,i) => (i % 5) == 0 ))
          .range(d3.range(0,data.length/5).map(x => x*barWidth*5 + barWidth/2));

      
      var xAxis = d3.svg.axis()
          .scale(xLinear)
          .orient("bottom");
      
      var svg = d3this.append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
        .append("g")
          .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
      

      var bar = svg.selectAll(".bar")
          .data(data)
        .enter().append("g")
          .attr("class", "bar")
          .attr("transform", function(d,i) { return "translate(" + (i*barWidth) + "," + y(d.y) + ")"; });

      
      bar.append("rect")
          .attr("x", 1)
          .attr("width", barWidth - 1)
          .attr("height", function(d) { return height - y(d.y); })
          .attr("fill", function(d) { return colorScale(d.y) })
          .on("mouseover", function() {
            d3.select(this.parentNode).select("text").text(function(d) { return formatCount(d.y); });
          })
          .on("mouseout", function() {
            d3.select(this.parentNode).select("text").text("")
          })
      
      bar.append("text")
          .attr("dy", ".75em")
          .attr("y", -12)
          .attr("x", barWidth / 2)
          .attr("text-anchor", "middle")
      
      var X = svg.append("g")
          .attr("class", "x axis")
          .attr("transform", "translate(0," + height + ")")
          .call(xAxis);

      X.append("text")
        .attr("x", width / 2)
        .attr("y", 28)
        .attr("text-anchor", "middle")
        .text("Num users")

      svg.append("text")
        .attr("x", width / 2)
        .attr("y", -15)
        .attr("text-anchor", "middle")
        .text("Num features per user count bucket")


    });
  }

  chart.width = function(_) {
    if (!arguments.length) return width;
    width = _;
    return chart;
  };

  chart.height = function(_) {
    if (!arguments.length) return height;
    height = _;
    return chart;
  };

  chart.title = function(_) {
    if (!arguments.length) return title;
    title = _;
    return chart;
  };

  var buckets = 20;
  chart.buckets = function(_) {
    if (!arguments.length) return buckets;
    buckets = _;
    return chart;
  };

  return chart;
}

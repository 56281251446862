import React, { useState, useMemo, useCallback, useEffect } from 'react';

import json2csv from 'json2csv';
import Papa from 'papaparse';
import { Modal, Icon, Segment, Dimmer, Loader } from 'semantic-ui-react';

import { mmm_custom_feed_field_validations } from './constants';
import { getSummaryData, validate, computeChartData, getFinalColumnName } from './helpers';
import Map from './Map';
import Upload from './Upload';
import Verify from './Verify';
import { createPresignedURL, uploadUsingPresignedURL } from '../../../api/aws';

const ModalLoader = () => (
  <div style={{ height: '100%' }}>
    <Segment style={{ height: '100%' }}>
      <Dimmer inverted active>
        <Loader size="massive" indeterminate>Validating File</Loader>
      </Dimmer>
    </Segment>
  </div>
);

const FileUploadModal = ({ open = false, setOpen, uploadType }) => {
  // modal
  const [loading, setLoading] = useState(false);

  // file data
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState(null);
  const [uploadData, setUploadData] = useState(undefined);
  const [invalidFile, setInvalidFile] = useState(false);

  // mapping data
  const [headers, setHeaders] = useState([]);
  const [channel, setChannel] = useState(undefined);
  const [strategy, setStrategy] = useState(undefined);
  const [dateColumn, setDateColumn] = useState(undefined);
  const [KPIColumn, setKPIColumn] = useState(undefined);

  const [errors, setErrors] = useState([]);

  const finalColumnName = useMemo(() => (
    getFinalColumnName(channel, strategy, uploadType)
  ), [channel, strategy, uploadType]);

  const resetModalState = () => {
    setFile(null);
    setFilename(undefined);
    setUploadData(undefined);
    setHeaders([]);
    setChannel(undefined);
    setStrategy(undefined);
    setDateColumn(undefined);
    setKPIColumn(undefined);
    setErrors([]);
  };

  const onCancel = () => {
    resetModalState();
    setOpen(false);
  };

  const onFileUpload = useCallback(files => {
    setInvalidFile(false);
    const f = files[0];
    setFilename(f.name);
    setFile(f);
    Papa.parse(f, { header: true, complete: results => setHeaders(results.meta.fields) });
  });

  const onFileUploadFailure = useCallback(() => {
    setInvalidFile(false);
  });

  const onParse = results => {
    const { data } = results;
    if (errors.length > 0) {
      return;
    }
    setUploadData(data);
  };

  const onFinish = results => {
    if (errors.length > 0) {
      setLoading(false);
      return;
    }
    const { data } = results;
    const filteredData = data.map(d => ({ date: d[dateColumn], [finalColumnName]: d[KPIColumn] }));
    setUploadData(filteredData);
    setLoading(false);
  };

  const onFormSubmit = () => {
    setLoading(true);
    setErrors([]);
    const transformConfig = {};
    transformConfig[dateColumn] = mmm_custom_feed_field_validations.date;
    transformConfig[KPIColumn] = mmm_custom_feed_field_validations.currency;

    const parseConfig = {
      header: true,
      skipEmptyLines: true,
      complete: onFinish,
      transform: validate(errors, setErrors, transformConfig),
    };

    Papa.parse(file, parseConfig);
  };

  const uploadDataToS3 = () => {
    const bucket = 'rockerbox-custom-mmm-uploads';
    const method = 'PUT';
    const parser = new json2csv.Parser();
    const data = parser.parse(uploadData);

    const fname = `${finalColumnName}/${Date.now()}`;

    return createPresignedURL(method, bucket, fname, 'text/csv').then(({ url }) => {
      uploadUsingPresignedURL(url, data, 'text/csv').then(() => {
        document.cookie = 'activeMMMUpload=true; max-age=150; path=/';
      });
    });
  };

  const submitAndClose = () => {
    uploadDataToS3().then(() => {
      setOpen(false);
      resetModalState();
    });
  };

  const submitAndReset = () => {
    uploadDataToS3().then(() => {
      resetModalState();
    });
  };

  const { total, average, minimum, maximum, minDate, maxDate } = useMemo(() => (
    getSummaryData(uploadData, finalColumnName)
  ), [uploadData]);

  const chartData = useMemo(() => (
    computeChartData(uploadData, finalColumnName, minDate, maxDate)
  ), [uploadData, finalColumnName, minDate, maxDate]);

  useEffect(() => {
    if (!uploadData) return;
    setUploadData(undefined);
  }, [channel, strategy, dateColumn, KPIColumn]);

  const getUploadDisplayName = type => {
    switch (type) {
      case 'revenue':
        return 'Revenue';
      case 'spend':
        return 'Marketing Spend';
      case 'imps':
        return 'Impressions';
      case 'extra':
        return 'Extra Features';
      default:
        return 'Unknown';
    }
  };

  return (
    <Modal
      className="mmm-upload-modal"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="fullscreen"
      style={{ height: '95%' }}
    >
      <Modal.Content style={{ height: '100%', overflowY: 'scroll' }}>
        <div style={{ textAlign: 'right' }}>
          <Icon name="close" size="large" color="grey" onClick={() => setOpen(false)} style={{ cursor: 'pointer' }} />
        </div>
        { loading && <ModalLoader /> }
        <h2>MMM Data Upload ({getUploadDisplayName(uploadType)})</h2>
        <Upload {...{
          onParse,
          filename,
          invalidFile,
          onDropAccepted: onFileUpload,
          onDropRejected: onFileUploadFailure,
        }}
        />
        <div style={{ display: 'flex' }}>
          { file && (
            <Map {...{
              onCancel,
              onFormSubmit,
              headers,
              channel,
              setChannel,
              strategy,
              setStrategy,
              dateColumn,
              setDateColumn,
              uploadType,
              KPIColumn,
              setKPIColumn,
              errors,
            }}
            />
          )}
          { chartData.length > 0 && (
            <Verify {...{
              onCancel,
              total,
              average,
              minimum,
              maximum,
              minDate,
              maxDate,
              chartData,
              channel,
              finalColumnName,
              submitAndClose,
              submitAndReset,
            }}
            />
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default FileUploadModal;

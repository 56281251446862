import React from 'react';

import ContentLoader from 'react-content-loader';
import { Card } from 'semantic-ui-react';

const ChartCardLoader = ({ itemsPerRow, padding }) => (
  <Card.Group {...{ itemsPerRow }} style={{ padding }}>
    {[...Array(itemsPerRow)].map((_, i) => (
      <Card fluid key={i}>
        <ContentLoader
          viewBox="0 0 296 259"
          preserveAspectRatio="none"
          foregroundColor="#e5e5e5"
          height={216}
          width="100%"
        >
          <circle cx="19.33" cy="17.6" r="10.59" />
          <path d="M100.55,23.08H40.51c-2.21,0-4-1.79-4-4v-4.39c0-2.21,1.79-4,4-4h60.03c2.21,0,4,1.79,4,4v4.39
            C104.55,21.29,102.76,23.08,100.55,23.08z"
          />
          <path d="M221.52,245.05H74.48c-2.21,0-4-1.79-4-4v-4.38c0-2.21,1.79-4,4-4h147.03c2.21,0,4,1.79,4,4v4.38
            C225.52,243.26,223.73,245.05,221.52,245.05z"
          />
          <path d="M0,114.01c0,0,50.35,25.14,87.94-3.01c33.27-24.9,75.39-21.58,75.39-21.58s48.23,3.27,68.23-29.12
            C251.23,28.46,296,41.4,296,41.4v177.53l-296,1V114.01z"
          />
        </ContentLoader>
      </Card>
    ))}
  </Card.Group>
);

export default ChartCardLoader;

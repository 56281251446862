export const checkLastValueEndsWith = (str, value) => {
  const parts = str.split('_');
  const lastValue = parts[parts.length - 1];
  return lastValue === value;
};

export const filterObjectHelper = (obj, filterStr) => (
  Object.keys(obj).reduce((accu, key) => {
    if (!checkLastValueEndsWith(key, filterStr)) {
      accu[key] = obj[key];
    }
    return accu;
  }, {})
);

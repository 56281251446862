import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useStripe, useElements, PaymentElement, AddressElement } from '@stripe/react-stripe-js';
import { Button, Grid, GridColumn } from 'semantic-ui-react';
import { calculateSalesTax, updateStripeCustomer } from '../../api/account';
import { getNextMonth, getYearAtStartOfNextMonth, getOriginalPrice, formatAddress, formatLineItemsForTaxes } from './helpers';

// eslint-disable-next-line max-len
const CheckoutForm = ({ estimated, prorated, customerId, zip, setZip, tax, setTax, promoCode, promoValue, promoPercent, address, setAddress, proratedTotal, setProratedTotal, taxInfo, setTaxInfo }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentPending, setPaymentPending] = useState(false);
  const [addressFormComplete, setAddressFormComplete] = useState(false);
  const [addressPending, setAddressPending] = useState(false);

  const originalPrice = promoValue ? getOriginalPrice(estimated, promoValue, 'fixed_amount') : promoPercent ? getOriginalPrice(estimated, promoPercent, 'percentage') : estimated;
  const originalPriceFormatted = parseFloat(originalPrice).toFixed(2);
  const discount = parseFloat(originalPrice - estimated).toFixed(2);
  const taxedTotal = tax === '-' ? parseFloat(estimated).toFixed(2) : (parseFloat(estimated) + parseFloat(tax)).toFixed(2);

  const taxItems = formatLineItemsForTaxes(estimated);
  const taxItemsProrated = formatLineItemsForTaxes(prorated);

  useEffect(() => {
    if (!address.postal_code || address.postal_code.length < 5) return;
    if (taxInfo.readyForPayment && !taxInfo.recalcTax) return;
    setAddressPending(true);
    const update = { customer: customerId, address };
    updateStripeCustomer(update)
      .then(() => {
        setAddressPending(false);
        setAddressFormComplete(true);
      });
    calculateSalesTax(formatAddress(address), taxItems)
      .then(response => {
        setTax((parseFloat(response.response.tax_amount_exclusive) / 100).toFixed(2));
      });
    // we need to calculate sales tax separately on the prorated portion
    calculateSalesTax(formatAddress(address), taxItemsProrated)
      .then(proratedResponse => {
        setProratedTotal(parseFloat((proratedResponse.response.amount_total) / 100).toFixed(2));
      });
  }, [zip, estimated, taxInfo.recalcTax]);

  const enterLoading = () => {
    setPaymentPending(true);
  };

  const enterPaymentMode = () => {
    setTaxInfo(prevState => ({ ...prevState, zipCollected: 'has zip', readyForPayment: true }));
  };

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const { error } = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.href}`,
      },
      // redirect: 'if_required'
    });
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setPaymentPending(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      <Redirect to="/onboarding/payment" />;
    }
  };

  const nextMonth = getNextMonth();
  const nextYear = getYearAtStartOfNextMonth();
  return (
    <form onSubmit={handleSubmit}>
      {errorMessage && <div className="onboarding-payment-error-message">{errorMessage}</div>}
      {taxInfo.readyForPayment && (
      <>
        <div className="onboarding-payment-active-view">
          <PaymentElement />
        </div>
        <Grid className="onboarding-payment-view-billing">
          <Grid.Row>
            <GridColumn width={14} className="onboarding-payment-tax-left-text">Subscription Estimate</GridColumn>
            <GridColumn width={2} className="onboarding-payment-tax-right-text">
              $
              {originalPriceFormatted}
            </GridColumn>
            {promoCode && (
            <>
              <GridColumn width={14} className="onboarding-payment-tax-left-text">Discount</GridColumn>
              <GridColumn width={2} className="onboarding-payment-tax-right-text">
                -$
                {discount}
              </GridColumn>
            </>
            )}
            <GridColumn width={14} className="onboarding-payment-tax-left-text">Tax</GridColumn>
            <GridColumn width={2} className="onboarding-payment-tax-right-text">
              $
              {tax}
            </GridColumn>
          </Grid.Row>
          <hr className="onboarding-payment-tax-divider" />
          <Grid.Row>
            <GridColumn width={14} className="onboarding-payment-tax-left-text">
              Estimated Total (Renews:
              {' '}
              {nextMonth}
              {' '}
              1,
              {nextYear}
              )
            </GridColumn>
            <GridColumn width={2} className="onboarding-payment-tax-right-text">
              $
              {taxedTotal}
            </GridColumn>
            <GridColumn width={14} className="onboarding-payment-tax-left-text">Total Due Today:</GridColumn>
            <GridColumn width={2} className="onboarding-payment-tax-right-text">
              $
              {proratedTotal}
            </GridColumn>
          </Grid.Row>
          <p className="due-today-explainer">
            Your initial charge of $
            {proratedTotal}
            {' '}
            {/* eslint-disable-next-line max-len */}
            is prorated from today through the end of the month. Every month thereafter, you will be billed on the 1st day of each month based on your ad spend that Rockerbox has tracked for the previous month.
          </p>
        </Grid>
      </>
      )}
      {!taxInfo.readyForPayment
      && (
      <div className="onboarding-payment-address-view">
        <p className="address-collection-para">First we need to collect your billing address for tax purposes:</p>
        <AddressElement
          options={{ mode: 'billing', autocomplete: { mode: 'automatic' } }}
          onChange={event => {
            if (event.value.address.postal_code.length === 5) {
              setAddress(event.value.address);
              setZip(event.value.address.postal_code);
            }
          }}
        />
        <Button
          disabled={!addressFormComplete}
          color="purple"
          className="onboarding-payment-ready_button"
          onClick={enterPaymentMode}
          loading={addressPending}
        >
          Proceed to payment
        </Button>
      </div>
      )}
      {taxInfo.readyForPayment
      && <Button disabled={!stripe} color="purple" className="onboarding-payment-submit_button" loading={paymentPending} onClick={enterLoading}>Submit payment</Button>}
    </form>
  );
};

export default CheckoutForm;

import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const PLACEHOLDER = "Choose conversion event";

const segToOptions = (segments) => segments.map((plan) => {
  return {
    key: plan.action_name,
    text: plan.action_name,
    value: plan.action_id
  }
})

class AttributionHeader extends Component {

  componentDidMount() {
    const { defaultSelection } = this.props;
    this.props.setDefault(defaultSelection)
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.selected || !this.props.selected) {
      const { defaultSelection } = this.props;
      this.props.setDefault(defaultSelection)
    }
  }

  onChange = (data) => {
    const { segments, selected } = this.props;
    const obj = segments.find((segment) => segment.action_id == data.value)

    if ((obj && obj.action_id) !== (selected && selected.action_id)) this.props.setConversionSegment(obj)
  }

  render() {
    const { segments, selected, filter, label } = this.props;
    const segs = filter ? segments.filter(filter) : segments

    const options = segToOptions(segs);
    const boundChange = (e,data) => this.onChange(data);
    const { action_id } = (selected || {});
    const Component = this.props.as || Dropdown
    const preventFluid = !this.props.fluid;

    return <Component fluid={preventFluid ? undefined : true} options={options} value={action_id} placeholder={PLACEHOLDER} onChange={boundChange} label={label ? label : false}/>
  }
}

AttributionHeader.propTypes = {
  segments: PropTypes.array,
  setConversionSegment: PropTypes.func,
}

export default AttributionHeader;

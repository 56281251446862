import React from 'react';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { getSegments, getPostlogLift } from '../../utils/api';
import { IndexGrid, SplitLayout, ContentCard, ExpandableIndexGrid, IndexGridNew } from '@rockerbox/styleguide'
import { Statistic, Input, Dropdown, Checkbox, Table, Icon } from 'semantic-ui-react';
import { styler } from "react-timeseries-charts";
import TimeRange, { yesterday, month } from '../timerange';
import Analysis from './Analysis';
import moment from 'moment';
import SummaryBar from '../SummaryBar';

const options = [{"text":"Dual Feed","value":"Dual"},{"text":"Live Feed","value":"Live"},{"text":"All Feeds","value":"all"}]
const summaryOptions = [
  {"text":"Summary","value":"summary"},
  {"text":"All Commercials","value":"all"},
  {"text":"Channel","value":"tier_three"},
  {"text":"Program","value":"tier_four"}
]

const formatOptions = new Intl.NumberFormat('en-US',{
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})
const formatPercent = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
})

const Percentage = ({item, col}) => {
  const percent = formatPercent.format(item[col.key])
  return (
    <Table.Cell collapsing>
      {item[col.key] > 0 ? percent : '-'}
    </Table.Cell>
  )
}
const Collapsing = ({item, col}) => {
  return (
    <Table.Cell collapsing style={{minWidth:"80px"}} textAlign="right">
      {item[col.key]}
    </Table.Cell>
  )
}
const NumberCell = ({item, col}) => (
  <Table.Cell>
    { formatOptions.format(item[col.key]) }
  </Table.Cell>
)
const TimestampCell = ({item, col}) => {
  const time = moment(item[col.key]).format('MMM D, YYYY - h:mm a');
  return (
    <Table.Cell>{time}</Table.Cell>
  )
};
const StatusCell = ({item, col}) => {
  return (
    <Table.Cell>
      {item[col.key] ? <Icon name="check" color="green"/> : ""}
    </Table.Cell>
  )
}

const ExpandedAnalysis = (style, min, max) => (props) => {

  const row = props.item;
  const item = style.columnStyles[row.timezone || "Other"];
  const color = item ? item.color : '#d62728';

  return (<Analysis selected={row} start={min} end={max} color={color}/>)
}

const ExpandedTable = (selectRow, selectedRow) => (props) => {

  const row = props.item

  return <IndexGrid
    selectedRow={selectedRow}
    onRowClick={selectRow}
    data={row.values}
    cols={cols}
    as={ContentCard.Table}
  />
}


const tierThreeCols = [
  { display: "Channel", key: "key"},
  { display: "With Lift", key: "lift", as: Collapsing},
  { display: "", key: "liftPercent", as: Percentage},
  { display: "No Lift", key: "noLift", as: Collapsing},
  { display: "", key: "noLiftPercent", as: Percentage},
  { display: "Airings", key: "airings", as: Collapsing},
]

const tierFourCols = [
  { display: "Program", key: "key"},
  { display: "With Lift", key: "lift", as: Collapsing},
  { display: "", key: "liftPercent", as: Percentage},
  { display: "No Lift", key: "noLift", as: Collapsing},
  { display: "", key: "noLiftPercent", as: Percentage},
  { display: "Airings", key: "airings", as: Collapsing},
]

let cols = [
  { display: "Tier One", key: "tier_one"},
  { display: "Tier Two", key: "tier_two"},
  { display: "Tier Three", key: "tier_three"},
  { display: "Tier Four", key: "tier_four"},
  { display: "Tier Five", key: "tier_five"},
  { display: "Feed Type", key: "feed_type"},
  { display: "Timestamp", key: "timestamp", as: TimestampCell},
  { display: "Has Lift?", key: "hasLift", as: StatusCell},
]
const stats = [
  { display: "Timezone", key: "timezone"},
  { display: "Control", key: "baseline_mean", as: NumberCell},
  { display: "(stdev)", key: "baseline_std", as: NumberCell},
  { display: "Postlog", key: "postlog_mean", as: NumberCell},
  { display: "Diff", key: "diff", as: NumberCell},
  { display: "P-value", key: "p_value", as: NumberCell},
  { display: "Effect Size", key: "effect_size", as: NumberCell},
  { display: "Lift", key: "lift", as: Percentage},
];

class PostlogLift extends TimeRange  {

  state = {
    data : [],
    parsedData : [],
    columns : [],
    sessions : false,
    markerMode: "flag",
    tracker: null,
    trackerEvent: null,
    trackerValue: null,
    hasLift: false,
    feedType: "all",
    summaryType: "all",
    search: "",
    loading: true
  }

  updatePath = (obj) => {
    const objKeys = Object.keys(obj)

    const { history: { push } } = this.props;
    const { path, url } = this.props.match;
    const keys = path.split("/")
    const values = url.split("/")

    const pathArray = keys.reduce((p,c,i) => {

      const k = c.includes(":") && c.replace(":","").replace("?","")

      if (k && objKeys.indexOf(k) > -1) p.push(obj[k])
      else if (values.length > i && values[i] !== undefined && values[i].length) {
        p.push(values[i])
      }
      return p
    }, [])

    push("/" + pathArray.filter(x => x).join("/"))

  }

  setDefaults = () => {
    const startDate = month
    const endDate = yesterday
    getSegments()
      .then(segments => {
        const id = segments.filter(row => row.touchpoints == 1)[0]['action_id'];
        this.updatePath({ startDate, endDate, id })
      })
  }

  componentDidMount() {
    const { id, startDate, endDate } = this.props.match.params;
    if (id) return this.getApi(id, startDate, endDate)

    this.setDefaults()
  }

  componentDidUpdate(prevProps, prevState) {
    const prevParams = prevProps.match.params
    const { id, startDate, endDate } = this.props.match.params;

    if (id != prevParams.id) {
      if (id) return this.getApi(id, startDate, endDate)
      this.setDefaults()
    }

    const sstartDate = this.state.startDate;
    const sendDate = this.state.endDate;
    const psStartDate = prevState.startDate;
    const psEndDate = prevState.endDate;

    if ((sstartDate != psStartDate) | (sendDate != psEndDate)) {
      return this.updatePath({ id, startDate: sstartDate, endDate: sendDate})
    }

    const ppStartDate = prevParams.startDate;
    const ppEndDate = prevParams.endDate;

    if ((startDate != ppStartDate) | (endDate != ppEndDate)) {
      this.getApi(id, startDate, endDate)
    }


  }

  getApi = (id, startDate, endDate) => {
    getPostlogLift(id, startDate, endDate)
      .then(data => {

        const d = d3.nest()
          .key(row => row.event_id)
          .entries(data)
          .map(row => {
            const values = row.values.sort((p,c) => p.timestamp - c.timestamp)
            const first = values[0]
            const hasLift = row.values.filter(v => {
              v.positive = (v.postlog_mean > v.baseline_mean) && (v.p_value < .21) && (v.diff > 1)
              return v.positive
            }).length
            const { event_id, tier_one, tier_two, tier_three, tier_four, tier_five, feed_type, timestamp, timezone } = first;
            const searchable = tier_one + tier_two + tier_three + tier_four + tier_five
            return {
              event_id,
              tier_one, tier_two, tier_three, tier_four, tier_five,
              feed_type, timestamp, timezone, values, hasLift, searchable
            }
          })
          .sort((p,c) =>  p.timestamp - c.timestamp)
        this.setState({data, parsedData: d, loading: false})
      })

    /*
    Minute sessions data set is not used in this view. Downloading/setting state
    based on this dataset is significantly hindeirng performance. Commenting out
    for now.

    getSessions(id, startDate, endDate)
      .then(sessions => {
        if (!sessions || !sessions.length) return;
        const keys = Object.keys(sessions[0]).filter(k => k != "date" && k != "session_minutes")

        const columns = ["time"].concat(keys)
        const points = sessions.map(row => {
           return [+row.date].concat(keys.map(k => row[k]))
        })

        const name = "timeseries"
        const new_points = points.sort((a, b) => a[0] - b[0])
        const obj = new TimeSeries({ name, columns, points:new_points })

        const options = {
          windowSize: "20m",
          aggregation: keys.reduce((p,k) => { p[k] = {[k]: sum() }; return p }, {})
        }
        const XX = obj.fixedWindowRollup(options)
        this.setState({columns: keys})
      })

      */
  }

  selectRow = (selectedRow) => {
    this.setState({ selectedRow })
  }
  changeLift = (evt, data) => {
    const hasLift = data.checked
    this.setState({ hasLift })
  }
  changeSearch = (evt, data) => {
    const search = data.value
    this.setState({ search })
  }
  changeFeed = (evt, data) => {
    const feedType = data.value
    this.setState({ feedType })
  }
  changeSummary = (evt, data) => {
    const summaryType = data.value
    this.setState({ summaryType })
  }

  render() {

    const d = this.state.parsedData
      .filter(row => {
        if (this.state.hasLift) return row.hasLift > 0
        return true
      })
      .filter(row => {
        if (this.state.feedType != "all") return row.feed_type == this.state.feedType
        return true
      })
      .filter(row => {
        if (this.state.search != "") return row.searchable.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1
        return true
      })

    const tierThreeSummary = d3.nest()
      .key(row => row.tier_three)
      .rollup(values => {
        return values
      })
      .entries(d)
      .map(row => {
        row.airings = row.values.length
        row.lift = row.values.filter(v => v.hasLift).length
        row.noLift = row.airings - row.lift
        row.liftPercent = row.lift/row.airings
        row.noLiftPercent = row.noLift/row.airings
        return row
      })

    const tierFourSummary = d3.nest()
      .key(row => row.tier_four)
      .rollup(values => {
        return values
      })
      .entries(d)
      .map(row => {
        row.airings = row.values.length
        row.lift = row.values.filter(v => v.hasLift).length
        row.noLift = row.airings - row.lift
        row.liftPercent = row.lift/row.airings
        row.noLiftPercent = row.noLift/row.airings
        return row
      })

    const customColorsList = [
        "#1f77b4", "#aec7e8", "#ff7f0e", "#2ca02c",
        "#98df8a", "#d62728", "#ff9896", "#9467bd", "#c5b0d5",
        "#8c564b", "#c49c94", "#e377c2", "#f7b6d2", "#7f7f7f",
        "#c7c7c7", "#bcbd22", "#dbdb8d", "#17becf", "#9edae5"
    ];
    const style = styler(this.state.columns.map((c, i) => ({
        key: c,
        color: customColorsList[i]
    })));

    const categories = this.state.columns.map(c => { return {key: c, label: c, value: c, type: "dot"}})
    const selectedRow = this.state.selectedRow;
    const subtitle = selectedRow ? `(${selectedRow.tier_one} > ${selectedRow.tier_two} > ${selectedRow.tier_three} > ${selectedRow.tier_four} > ${selectedRow.tier_five})` : "";

    const summaryMap = d3.nest()
      .key(row => moment(row.timestamp).format("YYYY-MM-DD"))
      .rollup(values => {
        const hasLift = values.filter(v => v.hasLift).length
        const noLift = values.length - hasLift
        return { hasLift, noLift }
      })
      .map(d)


    const summaryValues = this.state.parsedData.length == 0 ? [] : d3.time.scale()
      .domain([moment(this.props.match.params.startDate || new Date()), moment(this.props.match.params.endDate || new Date())])
      .ticks(d3.time.days, 1)
      .map(d => {
        const date = moment(d).format("YYYY-MM-DD")
        return Object.assign({ date }, summaryMap[date] || {hasLift: 0, noLift: 0})
      })

    const { hideHeader } = this.props
    const { loading } = this.state

    return (
      <React.Fragment>
        {!hideHeader &&
          <ContentCard
            hasTable title="Postlog Analysis" explainer=""
            topRight={this.renderDateSelector()}
          />
        }
        <SplitLayout
          leftWidth={8}
          leftContent={
            <React.Fragment>
              <ContentCard borderless>
                <div style={{"display":"flex","justifyContent":"space-between"}}>
                  <div style={{"flex":1}}><Input icon='search' size="mini" onChange={this.changeSearch} /></div>
                  <div style={{"flex":1,"display":"flex","justifyContent":"space-between"}}>
                    <div />
                    <div />
                    <div>
                      <Dropdown defaultValue="all" size="small" options={options} onChange={this.changeFeed} />
                    </div>
                    <div>
                      <Checkbox label="Has lift?" onChange={this.changeLift}/>
                    </div>
                  </div>
                </div>
                <SummaryBar data={summaryValues} exclude={[]} height={140} />
              </ContentCard>
              <ContentCard
                {...{loading}}
                disableHeaderEllipse
                hasTable
                title={
                  <Dropdown value={this.state.summaryType} options={summaryOptions} onChange={this.changeSummary} />
                }
              >
                { this.state.summaryType == "all" && <IndexGridNew
                  selectedRow={this.state.selectedRow}
                  onRowClick={this.selectRow}
                  data={d}
                  cols={cols}
                  paginate
                  itemsPerPage={50}
                />}
                { this.state.summaryType == "tier_three" && <ExpandableIndexGrid
                  expandable={true}
                  expandedComponent={ExpandedTable(this.selectRow, this.state.selectedRow)}
                  idKey={"key"}
                  data={tierThreeSummary}
                  cols={tierThreeCols}
                  as={ContentCard.Table}
                />}
                { this.state.summaryType == "tier_four" && <ExpandableIndexGrid
                  expandable={true}
                  expandedComponent={ExpandedTable(this.selectRow, this.state.selectedRow)}
                  idKey={"key"}
                  data={tierFourSummary}
                  cols={tierFourCols}
                  as={ContentCard.Table}
                />}
              </ContentCard>
                {
                  this.state.summaryType == "summary" &&
                  <React.Fragment>
                  <ContentCard>
                    <div style={{display:"flex",justifyContent:"space-between",paddingTop:"20px"}}>
                      <Statistic style={{flex:1}}>
                        <Statistic.Value>{d.length}</Statistic.Value>
                        <Statistic.Label>Total Airings</Statistic.Label>
                      </Statistic>
                      <Statistic style={{flex:1}}>
                        <Statistic.Value>{d.filter(r => r.hasLift).length}</Statistic.Value>
                        <Statistic.Label>With Lift</Statistic.Label>
                      </Statistic>
                      <Statistic style={{flex:1}}>
                        <Statistic.Value>{formatPercent.format(d.filter(r => r.hasLift).length/d.length)}</Statistic.Value>
                        <Statistic.Label>% Showing Lift</Statistic.Label>
                      </Statistic>
                    </div>
                  </ContentCard>
                  <ContentCard title={"Top Networks"} hasTable>
                      <IndexGrid
                        data={tierThreeSummary.sort((p,c) => c.liftPercent -p.liftPercent).slice(0,5)}
                        cols={tierThreeCols}
                        as={ContentCard.Table}
                      />
                  </ContentCard>
                  <ContentCard title={"Top Programs"} hasTable>
                      <IndexGrid
                        data={
                        tierFourSummary
                          .sort((p,c) => c.airings -p.airings).slice(0,tierFourSummary.length / 4)
                          .sort((p,c) => c.liftPercent -p.liftPercent).slice(0,5)
                        }
                        cols={tierFourCols}
                        as={ContentCard.Table}
                      />
                  </ContentCard>
                  </React.Fragment>
                }
            </React.Fragment>
          }
          rightWidth={8}
          rightContent={
            <React.Fragment>
              <ContentCard title={`Lift analysis ${subtitle}`} collapsing style={{"marginBottom":"-15px"}}>
                <b>Goal: </b>
                <div> - Determine if a TV commercial had a statistically significant increase in the number of new sessions to site</div>
                <b>Setup: </b>
                <div> - Use a baseline period 10 minutes before a commercial aired to determine average sessions started</div>
                <div> - Use a test period 5 minutes after a commercial aired to determine lift from a commercial</div>
                <b>Test: </b>
                <div>{" - Null Hypothesis: Baseline (sessions/minute) >= Postlog (sessions/minute)"}</div>
                <div>{" - Alternative: Postlog (sessions/minute) > Baseline (sessions/minute)"}</div>
                <b>Criteria: </b>
                <div>{" - Use a t-test (p-value < .1) to determine if the result is significant"}</div>
              </ContentCard>
              { this.state.selectedRow &&
                <React.Fragment>
                  <ContentCard hasTable>
                    <ExpandableIndexGrid
                      defaultExpand={this.state.selectedRow.values[0].timezone}
                      expandable={true}
                      expandedComponent={ExpandedAnalysis(style,
                        d3.min(this.state.selectedRow.values, x => x.timestamp_min),
                        d3.max(this.state.selectedRow.values, x => x.timestamp_max)
                      )}
                      idKey={"timezone"}
                      data={this.state.selectedRow.values}
                      cols={stats}
                      as={ContentCard.Table} />
                  </ContentCard>

                </React.Fragment>
              }

            </React.Fragment>
          }
        />
        { this.renderDateModal() }
      </React.Fragment>
    )
  }

}
export default PostlogLift;

//[{"origin":"juul_tv_postlog","timestamp_min":1556681700000,"timezone":"","postlog_mean":95.4,"diff":12.7636363636,"baseline_mean":82.6363636364,"city":"","dma_id":"","network":"CNN","timestamp_max":1556682900000,"baseline_std":7.1031363111,"event_id":"a07aaac8895ca28542284177d0a6abcf","effect_size":1.7969015101,"source":"juul","t_value":20.7817034401,"outlet_type":"Cable - National","tier_two":"Cable - National","timedelta":600000000000,"tier_one":"TV","timestamp":1556682300000,"baseline_events":[0,0,0,0,0,0,0,0,0,1,0],"feed_type":"Live","lift":0.1544554455,"date":"2019-05-01 00:00:00","region":"","postlog_events":[120,112,89,75,100,110,80,97,79,92],"p_value":0,"inventory":"CNN Tonight","tier_five":"Carolyn Rev_:30","tier_three":"CNN","country":"US","tier_four":"CNN Tonight"}]

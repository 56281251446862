import { postSubscriptions } from '../api/emailSubscriptions';
import { getUserAuthorizationActions } from '../api/rbacApi';
import { suppressionGroupIds } from '../constants/mappings';
import { RBAC_EMAIL_SUBSCRIPTIONS_RECEIVE } from '../constants/rbac';

export const isDefaultAdmin = (email, advertiserExistsRBAC) => {
  const isAdminBasedOnEmail = email.includes('@rockerbox.com');
  return isAdminBasedOnEmail || !advertiserExistsRBAC;
};

export const updateEmailSuppressions = (id, email) => {
  const newSuppressions = Object.values(suppressionGroupIds);
  getUserAuthorizationActions(id)
    .then(actions => {
      // if user has access to receive emails, do not suppress
      if (actions.includes(RBAC_EMAIL_SUBSCRIPTIONS_RECEIVE)) return;

      // if user does not have access to receive emails, suppress all
      postSubscriptions(email, newSuppressions).then(() => {
        console.log('Subscriptions suppression posted successfully');
      });
    });
};

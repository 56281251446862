import React, { useState } from 'react';
import { ContentCard, IndexGridNew } from '@rockerbox/styleguide';
import { Divider, Dropdown, Form, Button, Header, Loader, Table } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { postPlatformComparisonConfigs, deletePlatformComparisonConfigs } from '../../../api/attribution';

/* eslint-disable no-console, react/jsx-props-no-spreading */

const StyledDropdown = styled(Dropdown)`
  &:hover {
    border-color: #475ddc !important;
    background-color: rgba(71, 93, 220, 0.05) !important;
  }
`;

const ManageCell = ({ item, col }) => {
  const { updateConfigList } = col;
  return (
    <Table.Cell>
      <IndexGridNew.OverlayDeleteBtn
        onClick={() => {
          deletePlatformComparisonConfigs(item.id)
            .then(() => {
              updateConfigList();
            })
            .catch(err => console.log(err));
        }}
      />
    </Table.Cell>
  );
};

const ComparisonSetup = props => {
  const { segsInfo, conversionOptions, comparisonConfigsList, handleCloseComparisonSetup, platform, updateConfigList } = props;

  const { control, handleSubmit, formState: { errors }, setValue, trigger } = useForm({
    defaultValues: {
      platform_conversion: '',
      filter_id: '',
    },
  });
  const [loading, setLoading] = useState(false);

  const filteredSegmentOptions = segsInfo.flatMap(segment => {
    const hasMatch = comparisonConfigsList.find(config => config.filter_id === segment.action_id);
    if (hasMatch || segment.action_name === 'All Pages') return [];

    return { text: segment.action_name, value: segment.filter_id };
  });

  const cols = [
    {
      display: 'Rockerbox Segment',
      key: 'text',
    },
    {
      display: `${platform.charAt(0).toUpperCase() + platform.slice(1)} Conversion`,
      key: 'platform_conversion',
    },
    {
      display: 'Delete',
      as: ManageCell,
      updateConfigList,
    },
  ];

  const onSubmit = data => {
    const postObj = {
      ...data,
      platform,
    };
    setLoading(true);
    postPlatformComparisonConfigs(postObj)
      .then(() => {
        updateConfigList();
        handleCloseComparisonSetup();
        setLoading(false);
      })
      .catch(err => console.log(err));
  };

  return (
    <ContentCard hasTable>
      <Loader active={loading} />
      {comparisonConfigsList.length > 0
        && (
        <div>
          <Header as="h1" style={{ fontWeight: 'normal' }}>
            Current Comparison(s)
          </Header>
          <IndexGridNew cols={cols} data={comparisonConfigsList} />
          <Divider />
        </div>
        )}
      <Header as="h1" style={{ fontWeight: 'normal' }}>
        Add a Conversion Comparison
      </Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Field>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="rbox-conversion">Rockerbox Conversion</label>
          <Controller
            name="filter_id"
            control={control}
            render={({ field }) => (
              <StyledDropdown
                {...field}
                selection
                options={filteredSegmentOptions}
                onChange={(e, { name, value }) => {
                  setValue(name, value);
                  trigger(name);
                }}
                placeholder="Select"
                error={errors.filter_id?.message}
                disabled={loading}
                id="rbox-conversion"
              />
            )}
            rules={{ required: 'Missing Rockerbox Segment' }}
          />
          {errors.filter_id?.message && <div className="ui pointing above prompt label">{errors.filter_id.message}</div>}
        </Form.Field>
        <Form.Field>
          <label htmlFor="platform-conversion">
            {platform.charAt(0).toUpperCase() + platform.slice(1)}
            {' '}
            Conversion
          </label>
          <Controller
            name="platform_conversion"
            control={control}
            render={({ field }) => (
              <StyledDropdown
                {...field}
                selection
                options={conversionOptions}
                onChange={(e, { name, value }) => {
                  setValue(name, value);
                  trigger(name);
                }}
                placeholder="Select"
                error={errors.platform_conversion?.message}
                disabled={loading}
                id="platform-conversion"
              />
            )}
            rules={{ required: 'Missing Platform Conversion' }}
          />
          {errors.platform_conversion?.message && <div className="ui pointing above prompt label">{errors.platform_conversion.message}</div>}
        </Form.Field>
        <Button type="submit" primary disabled={loading}>Add comparison</Button>
      </Form>
    </ContentCard>
  );
};

export default ComparisonSetup;

import React, { Component } from 'react';
import { getWebhooksData } from '../../utils/api';
import { IndexGrid, ContentCard } from '@rockerbox/styleguide';
import { Table, Button, Modal, Form, Message } from 'semantic-ui-react';
import * as routes from '../../routes';
import { createWebhook } from '../../utils/api';
import { withRouter } from 'react-router-dom';

const ManageCell = ({item, col}) => {
  return (
    <Table.Cell collapsing>
      <IndexGrid.EditButton url={`${routes.webhooksEdit}/${item.id}`}/>
    </Table.Cell>
  )
}

class WebhooksIndex extends Component {
  state = {
    webhooks: [],
    modalOpen: false,
    displayName: '',
    action: '',
    disableButton: false,
    errorMessage: null
  }

  componentDidMount() {
    getWebhooksData()
      .then(webhooks => this.setState({webhooks}))
  }

  handleTextChange = (val, key) => {
    this.setState({[key]: val});
  }

  submitCreateWebhook = () => {
    const { displayName, action } = this.state;
    const postObj = {
      action,
      name: action,
      display_name: displayName,
      config: {}
    }

    this.setState({
      disableButton: true,
      errorMessage: null
    });

    createWebhook(postObj)
      .then(data => {
        const selected = data.find(item => item.action == action);
        const url = `${routes.webhooksCreate}/${selected.id}`;
        return this.props.history.push(url)
      })
      .catch(err => {
        this.setState({
          disableButton: false,
          errorMessage: "Webhook with same Path already exists"
        });
      })
  }

  onModalClose = () => {
    this.setState({modalOpen: false, action: '', displayName: '', errorMessage: null})
  }

  render() {
    const { webhooks, errorMessage } = this.state;
    const cols = [
      {display: 'Display Name', key: 'display_name'},
      {display: 'Path', key: 'action' },
      {display: 'Manage', as: ManageCell}
    ]

    return(
      <ContentCard
        title="Webhooks"
        hasTable
        topRight={
          <Button
            primary
            compact
            size="mini"
            icon="plus"
            content={'New Webhook'}
            onClick={() => this.setState({modalOpen: true})}
          />
        }
      >
        <IndexGrid data={webhooks} cols={cols}/>
        <Modal size="small" open={this.state.modalOpen} onClose={this.onModalClose}>
          <Modal.Header>Set Display Name and Path</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              {errorMessage &&
                <Message negative>
                  <Message.Header>
                    Error creating webhook
                  </Message.Header>
                  <p>{errorMessage}</p>
                </Message>
              }
              <Form onSubmit={this.submitCreateWebhook}>
                <Form.Input label="Display Name" value={this.state.displayName} onChange={(e,d) => this.handleTextChange(d.value, 'displayName')}/>
                <Form.Input label="Path" value={this.state.action} onChange={(e,d) => this.handleTextChange(d.value, 'action')}/>
                <Form.Field>
                  <Button type="submit" disabled={this.state.disableButton}>Create Webhook</Button>
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </ContentCard>
    )
  }
}

export default withRouter(WebhooksIndex);

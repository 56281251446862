import React from 'react';

import { useAtom } from 'jotai';
import { Button } from 'semantic-ui-react';

import BaselinePeriod from './baseline/BaselinePeriod';
import BudgetPeriod from './budget/BudgetPeriod';
import Constraints from './constraints/Constraints';
import Optimization from './optimize/Optimization';
import Projection from './projection/Projection';
import { budgetAtom, optimizationAtom } from '../atoms';

const Form = ({
  isConversionKPI,
  trainingData,
  baselineTotal,
  activeFeatures,
  inactiveFeatures,
  featureNameMap,
  constraints,
  setConstraints,
  setConstraintPercentage,
  submitForecast,
}) => {
  const [optimization] = useAtom(optimizationAtom);
  const [budget] = useAtom(budgetAtom);
  const invalidForm = !optimization || !budget;
  return (
    <>
      <Projection />
      <BudgetPeriod />
      <BaselinePeriod
        {...{
          trainingData,
          baselineTotal,
          activeFeatures,
          inactiveFeatures,
        }}
      />
      <Optimization {...{ isConversionKPI, baselineTotal }} />
      <Constraints
        {...{
          isConversionKPI,
          featureNameMap,
          constraints,
          setConstraints,
          setConstraintPercentage,
        }}
      />
      <br />
      <Button
        content="Generate Forecast"
        onClick={() => submitForecast()}
        disabled={invalidForm}
      />
      <br />
      <br />
    </>
  );
};

export default Form;

import React from 'react';

import { StackedBarChart, LineChart } from '@rockerbox/styleguide';
import { Link } from 'react-router-dom';
import { Button, Grid, GridColumn, Icon } from 'semantic-ui-react';

import { splitData, formatNumber, generateCompareTrendCards } from './helpers';
import * as routes from '../../baseRoutes';
import { TrendCard, BasicViewHeader } from '../../components';
import DualLineChart from '../../components/charts/DualLineChart';
import { formatDateToLongString } from '../../utils/dateFormatters';
import { generateTrendCards } from '../PlatformPerformance/PlatformPerformance/helpers';

export const MainHeader = () => (
  <BasicViewHeader
    header="Cross-Channel Attribution"
    subheader="View your overall marketing performance using various attribution methodologies"
    topRight={(
      <Button
        primary
        icon="external"
        labelPosition="left"
        content="Ad Hoc Exports"
        as={Link}
        to={routes.adHocExportsHistory}
        target="_blank"
      />
    )}
  />
);

export const TrendCardsFromTiersData = props => {
  const { dailySummary, metrics_group, dates, metrics, currencyCode } = props;

  const [testObj, baselineObj, testDays, baselineDays] = splitData({ dates, dailyPerformance: dailySummary });

  const cardParams = { testObj, baselineObj, testDays, baselineDays };

  const cards = React.useMemo(() => generateTrendCards({ ...cardParams, metrics_group, metricsObj: metrics })
    .map(option => {
      // pre-format values to display in card, this map can be done elsewhere
      const optionCopy = { ...option };
      optionCopy.heading = option.text;
      optionCopy.statistic = formatNumber(option, option.mainNumber, currencyCode);
      return optionCopy;
    }), [cardParams]);

  return <TrendCard {...props} options={cards} hideTrend defaultCard={TrendCard.TrendingCard} />; // eslint-disable-line react/jsx-props-no-spreading
};

export const TrendCardsFromComparisonData = props => {
  const { filteredSummaryTotals, filteredComparisonSummaryTotals, metrics_group, metrics, currencyCode, conversionKey, revenueKey, loading } = props;

  const cards = React.useMemo(() => generateCompareTrendCards({ filteredSummaryTotals, filteredComparisonSummaryTotals, metrics_group, metricsObj: metrics, conversionKey, revenueKey })
    .map(accu => {
      // pre-format values to display in card, this map can be done elsewhere
      accu.heading = accu.text;
      accu.statistic = formatNumber(accu, accu.mainNumber, currencyCode);
      return accu;
    }), [filteredSummaryTotals, filteredComparisonSummaryTotals]);
  return <TrendCard {...props} loading={loading} options={cards} defaultCard={TrendCard.TrendingCard} />; // eslint-disable-line react/jsx-props-no-spreading
};

const DelayModal = ({ showDelayModal, delayDate }) => {
  if (!showDelayModal) {
    return null;
  }

  return (
    <Grid>
      <GridColumn
        width={1}
        verticalAlign="middle"
        style={{ display: 'flex', alignItems: 'center', paddingTop: '40px' }}
      >
        <div className="icon-container">
          <Icon
            name="exclamation triangle"
            color="orange"
            style={{ fontSize: '3em', paddingTop: '15px' }}
          />
        </div>
      </GridColumn>
      <GridColumn width={15} style={{ marginLeft: '-10px', paddingTop: '35px', fontSize: '13px' }}>
        <p className="delay-header-text">
          Data Processing Delay!
        </p>
        <p className="delay-second-line">
          We are currently experiencing delays processing your data for
          {' '}
          {delayDate}
          . We are working to address this issue as quickly as possible and expect all data to be available once resolved
        </p>
      </GridColumn>
    </Grid>
  );
};

export const ChartComponent = ({ chartData, lineChartData, tierColorMap, selectedMetric, spendFormatter, groupDatesBy, setGroupDatesBy, failureDates, isTestAccount }) => {
  const d = new Intl.NumberFormat('en');
  const formatter = ['conversions', 'roas'].includes(selectedMetric) ? d : spendFormatter;
  const height = 300;
  const useStackedBarChart = ['conversions', 'revenue', 'spend'].includes(selectedMetric);
  const data = useStackedBarChart ? chartData : lineChartData;
  // TODO: remove check for test account
  const showDelayModal = isTestAccount && failureDates?.size > 0;
  const delayDate = failureDates.size > 1 ? 'multiple days'
    : failureDates.size === 1 ? formatDateToLongString(failureDates.values().next().value)
      : '';

  if (useStackedBarChart) {
    return (
      <div>
        <StackedBarChart
          {...{ data, height, formatter, tierColorMap, groupDatesBy, setGroupDatesBy, failureDates, isTestAccount }}
          showTooltip
          hideKeys={['Unmapped Events']}
          showXAxis
          showTotalInToolTip
          groupDatesToggle
          showDataStatus={isTestAccount}
        />
        <DelayModal showDelayModal={showDelayModal} delayDate={delayDate} />
      </div>
    );
  }
  return (
    <div>
      <LineChart
        {...{ data, height, formatter, tierColorMap, groupDatesBy, setGroupDatesBy, failureDates, isTestAccount }}
        showTooltip
        hideKeys={['Unmapped Events']}
        showXAxis
        groupDatesToggle
        dottedLineForInfinity
        showDataStatus={isTestAccount}
      />
      <DelayModal showDelayModal={showDelayModal} delayDate={delayDate} />
    </div>
  );
};

export const CompareChartComponent = ({
  chartData, lineChartData, tierColorMap, selectedMetric, spendFormatter,
  startDate, endDate, compareStart, compareEnd, compareRange, numDays, compareOptions,
}) => {
  const d = new Intl.NumberFormat('en');
  const formatter = ['conversions', 'roas'].includes(selectedMetric) ? d : spendFormatter;
  const height = 500;
  const useChartData = ['conversions', 'revenue', 'spend'].includes(selectedMetric);
  const data = useChartData ? chartData : lineChartData;

  return (
    <DualLineChart
      {...{ data, height, formatter, tierColorMap, startDate, endDate, compareStart, compareEnd, compareRange, numDays, compareOptions, selectedMetric }}
    />
  );
};

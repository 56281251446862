import create from 'zustand';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { getSegments, getEntityConfigs, getEntityProgramConfigs } from '../../../api/attribution';

export const entityStore = create((set, get) => ({
  set: updates => {
    set(state => {
      const entityConfigs = updates.entityConfigs || state.entityConfigs;
      const entityProgramConfigs = updates.entityProgramConfigs || state.entityProgramConfigs;

      const entitySegmentId = entityConfigs.length > 0 ? entityConfigs[0].segments[0].segment_id : false;
      const entityByType = d3.nest()
        .key(row => row.entity_type)
        .map(entityConfigs);

      const programByType = d3.nest()
        .key(row => row.entity_type)
        .map(entityProgramConfigs);

      const getProgramTypeName = type => (programByType[type] || {}).name;

      const programUnion = Array.from(new Set([...Object.keys(programByType), ...Object.keys(entityByType)]));

      const allTypes = programUnion
        .map((text, i) => ({
          text: getProgramTypeName(text) || text,
          value: i,
          entityData: entityByType[text],
          programs: programByType[text],
        }));

      const programOptions = allTypes.filter(row => row.programs);
      const nonProgramOptions = allTypes.filter(row => !row.programs);

      return {
        entityLoading: false,
        entityConfigs,
        entityProgramConfigs,
        entitySegmentId,
        entityByType,
        programByType,
        allTypes,
        programOptions,
        nonProgramOptions,
      };
    });
  },
  getSegments: async force => {
    if (!force) {
      const state = get();
      if (state.segments) return;
    }
    const segments = await getSegments();
    const featured = segments.find(row => !!row.featured);
    const featuredSegmentId = featured.action_id;
    const segmentOptions = segments.map(row => ({ text: row.action_name, value: row.action_id }));

    set({
      featuredSegmentId,
      segmentOptions,
      segments,
    });
  },
  getEntityConfigs: async force => {
    if (!force) {
      const state = get();
      if (state.entityConfigs && state.entityProgramConfigs) return;
    }

    const entityConfigs = await getEntityConfigs();
    const entityProgramConfigs = await getEntityProgramConfigs();

    const entitySegmentId = entityConfigs.length > 0 ? entityConfigs[0].segments[0].segment_id : false;
    const entityByType = d3.nest()
      .key(row => row.entity_type)
      .map(entityConfigs);

    const programByType = d3.nest()
      .key(row => row.entity_type)
      .map(entityProgramConfigs);

    const getProgramTypeName = type => (programByType[type] || {}).name;

    const programUnion = Array.from(new Set([...Object.keys(programByType), ...Object.keys(entityByType)]));
    const allTypes = programUnion
      .map((text, i) => ({
        text: getProgramTypeName(text) || text,
        value: i,
        entityData: entityByType[text],
        programs: programByType[text],
      }));

    const programOptions = allTypes.filter(row => row.programs);
    const nonProgramOptions = allTypes.filter(row => !row.programs);

    set({
      entityLoading: false,
      entityConfigs,
      entityProgramConfigs,
      entitySegmentId,
      entityByType,
      programByType,
      allTypes,
      programOptions,
      nonProgramOptions,
    });
  },
  entityLoading: true,
  entityConfigs: null,
  entityProgramConfigs: null,
  entitySegmentId: null,
  entityByType: null,
  programByType: null,
  programOptions: null,
  segments: null,
  segmentOptions: null,
  featuredSegmentId: null,
}));

import { Menu, Icon } from "semantic-ui-react";
import React from "react";

const DeliveredReportMenu = props => {
  const { delieveredReports, handleOutboxNameClick, outbox_name } = props;

  function renderMenuItems() {
    return Object.keys(delieveredReports).map(name => (
      <Menu.Item
        active={outbox_name === name}
        onClick={() => handleOutboxNameClick(name)}
      >
        <Menu.Header>
          {name}
          {outbox_name === name && (
            <Icon name="chevron right" style={{ float: "right", margin: 0 }} />
          )}
        </Menu.Header>
      </Menu.Item>
    ));
  }

  return (
    <Menu vertical fluid>
      {renderMenuItems()}
    </Menu>
  );
};

export default DeliveredReportMenu;

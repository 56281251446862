import React, { useState, useEffect, useMemo } from 'react';
import { Segment, Message, Popup, Icon, Button } from 'semantic-ui-react';
import { ContentCard, DataTable } from '@rockerbox/styleguide';
import _ from 'lodash';
import * as d3 from 'rockerbox_d3_legacy_clone';
import moment from 'moment';
import { updateEntityData } from '../../../api/attribution';
import { isNumeric } from '../helpers';
import BulkSpendByDate from './BulkSpendByDate';

/* eslint-disable no-param-reassign */

const copyToClipboard = str => {
  /* ——— Derived from: https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
           improved to add iOS device compatibility——— */
  const el = document.createElement('textarea'); // Create a <textarea> element

  const storeContentEditable = el.contentEditable;
  const storeReadOnly = el.readOnly;

  el.value = str; // Set its value to the string that you want copied
  el.contentEditable = true;
  el.readOnly = false;
  el.setAttribute('readonly', false); // Make it readonly false for iOS compatability
  el.setAttribute('contenteditable', true); // Make it editable for iOS
  el.style.position = 'absolute';
  el.style.left = '-9999px'; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selected = document.getSelection().rangeCount > 0 // Check if there is any content selected previously
    ? document.getSelection().getRangeAt(0) // Store selection if found
    : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  el.setSelectionRange(0, 999999);
  document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }

  el.contentEditable = storeContentEditable;
  el.readOnly = storeReadOnly;
};

const allColumns = [
  {
    style: { width: '1.9em' },
    display: '',
    key: 'state',
    type: 'text',
    asEdit: ({ value }) => value,
    asView: ({ cell, row, rowProps }) => (cell.value === 'Approved' ? (
      <Popup
        content="This found an entity and this is a unique entry"
        trigger={<Icon name="check circle" color="green" />}
      />
    ) : cell.value === 'Invalid Dates' ? (
      <Popup
        content="You must enter a valid start and end date for the campaign"
        trigger={<Icon name="warning sign" color="red" />}
      />
    ) : cell.value === 'Same Dates' ? (
      <Popup
        content="This has overlapping dates for this campaign for this entity"
        trigger={<Icon name="warning circle" color="green" />}
      />
    ) : cell.value === 'Same Name' ? (
      <Popup
        content="A campaign with the same name has already been added"
        trigger={<Icon name="warning sign" color="red" />}
      />
    ) : typeof cell.value === 'object' ? (
      <Popup
        on="click"
        content={cell.value.map(value => (
          <>
            Did you mean
            {' '}
            {value}
            ?
            <Button
              inline
              color="green"
              size="mini"
              content="Yes, Replace All"
              onClick={() => rowProps.updateAllNames(row.entity_name, value)}
            />
            <br />
          </>
        ))}
        trigger={<Icon name="warning sign" color="orange" />}
      />
    ) : cell.value === 'Missing Entity' ? (
      <Popup
        content="No entity was found to which spend could be attached"
        trigger={<Icon name="warning sign" color="red" />}
      />
    ) : cell.value === 'Incorrect Spend Format' ? (
      <Popup
        content="The spend value entered is in the incorrect format"
        trigger={<Icon name="warning sign" color="red" />}
      />
    ) : cell.value === 'Missing Spend' ? (
      <Popup
        content="No spend was detected. Please enter a numerical spend value"
        trigger={<Icon name="warning sign" color="red" />}
      />
    ) : cell.value === 'Missing Name' ? (
      <Popup
        content="You must enter a name to create a new entry"
        trigger={<Icon name="warning sign" color="red" />}
      />
    ) : (
      JSON.stringify(cell)
    )
    ),
  },
  { display: 'Sponsorship', key: 'entity_name', tpe: 'text' },
  { display: 'Spend', key: 'cost', type: 'text' },
  { display: 'Start Date', key: 'start_date', type: 'text' },
  { display: 'End Date', key: 'end_date', type: 'text' },
];

const BulkCreateCampaigns = ({ entityData, onReset }) => {
  const [data, setData] = useState([{}]);
  const [issues, setIssues] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addSpendBy, setAddSpendBy] = useState(false);

  useEffect(() => {
    const notApproved = data.filter(row => row.state && row.state !== 'Approved');
    setIssues(notApproved);
  }, [data]);

  const entityCostsByName = useMemo(() => entityData.reduce((accu, c) => {
    accu[c.name] = c.costs.reduce((accu1, d) => {
      accu1[d.name] = d;
      return accu1;
    }, {});
    return accu;
  }, {}), [entityData]);

  const entityByName = useMemo(() => entityData.reduce((p, c) => Object.assign(p, { [c.name]: c }), {}), [entityData]);

  const columns = useMemo(() => {
    const options = entityData.map(({ name }) => ({ name, text: name, value: name }));
    const entityColumn = allColumns.find(row => row.key === 'entity_name');
    entityColumn.asEdit = DataTable.SearchCell(options);
    return allColumns;
  }, [entityData]);

  const evalRow = row => {
    // convert moment objects from datepicker to strings
    row.start_date = !!row.startDate ? row.startDate.format('YYYY-MM-DD') : row.start_date;
    row.end_date = !!row.endDate ? row.endDate.format('YYYY-MM-DD') : row.end_date;

    const entityName = row.entity_name || '';

    const hasEntity = entityCostsByName[entityName];
    row.campaign_name = `${entityName} - ${moment(row.start_date).format('MMM DD YYYY')}`;
    const duplicateCampaign = hasEntity && entityCostsByName[entityName][row.campaign_name];
    const fuzzyMatch = Object.keys(entityCostsByName)
      .filter(key => key.indexOf(entityName.slice(0, 6)) > -1)
      .reverse()
      .slice(0, 3);

    const missingSpend = !row.cost;
    const numericSpend = !missingSpend ? row.cost.replace(/\$/g, '').replace(/,/g, '') : null;
    const incorrectSpendFormat = !isNumeric(numericSpend);
    row.cost = !incorrectSpendFormat ? `$${numericSpend}` : row.cost;

    row.start_date = moment(row.start_date).format('YYYY-MM-DD');
    row.end_date = moment(row.end_date).format('YYYY-MM-DD');

    const validDates = [row.start_date, row.end_date].filter(date => date === 'Invalid date').length > 0;

    row.state = validDates
      ? 'Invalid Dates'
      : duplicateCampaign
        ? 'Same Name'
        : missingSpend
          ? 'Missing Spend'
          : incorrectSpendFormat
            ? 'Incorrect Spend Format'
            : hasEntity && !missingSpend
              ? 'Approved'
              : fuzzyMatch.length
                ? fuzzyMatch
                : 'Missing Entity';
  };
  const onSave = () => {
    setLoading(true);
    const campaigns = d3.nest()
      .key(row => row.entity_name)
      .rollup(values => {
        const valuesMapped = values.map(value => ({
          ...value,
          cost: value.cost.replace(',', '').replace('$', ''),
          name: value.campaign_name,
        }));
        return valuesMapped;
      })
      .map(data);

    const needUpdates = Object.keys(campaigns).reduce((p, name) => {
      const entity = entityByName[name];
      entity.costs = [...entity.costs, ...campaigns[name]];
      return [...p, entity];
    }, []);

    const promises = needUpdates.map(obj => {
      const { id } = obj;
      const send = updateEntityData;
      return send(obj, id);
    });

    Promise.all(promises).then(() => onReset());
  };

  const onUpdate = d => {
    d.forEach(evalRow);
    setData(d);
  };
  const onRemove = row => setData(data.filter(r => r !== row));
  const onRemoveAndCopy = (shouldCopy = false, type = false) => {
    const evalApproval = type ? row => row.state !== type : row => row.state === 'Approved';

    const approved = data.filter(evalApproval);
    const bad = data.filter(row => !evalApproval(row));

    const colNames = allColumns.slice(1).map(({ key }) => key);

    const string = bad.map(row => colNames.map(name => row[name]).join('\t')).join('\n');
    if (shouldCopy) copyToClipboard(string);

    setData(approved);
  };
  const addRow = () => setData(data.concat([{}]));

  const updateAllNames = (name, value) => {
    const newData = _.cloneDeep(data);
    newData.forEach(row => {
      if (row.entity_name === name) Object.assign(row, { entity_name: value });
      evalRow(row);
    });

    setData(newData);
  };

  return (
    <ContentCard>
      {!addSpendBy && (
        <>
          <Message
            header="Add campaign or sponsorship costs to entities"
            content="Use the worksheet below to bulk upload campaign or sponsorship spend for existing entities.
            Note that the spend will be evenly distributed over the start and end dates specified."
          />
          <Button
            size="large"
            content="Add weekly campaign costs"
            icon="browser"
            onClick={() => setAddSpendBy('week')}
          />
          <Button
            size="large"
            content="Add monthly campaign costs"
            icon="calendar"
            onClick={() => setAddSpendBy('month')}
          />
          <Button
            size="large"
            content="Add costs by date of activation"
            icon="bullseye"
            onClick={() => setAddSpendBy('sponsorship')}
          />
        </>
      )}
      {['week', 'month'].includes(addSpendBy) && (
        <Segment secondary>
          <BulkSpendByDate
            {...{ onUpdate, onRemove, updateAllNames, onRemoveAndCopy, addRow }}
            {...{ data, rangeType: 'month', columns, issues }}
            rangeType={addSpendBy}
          />
        </Segment>
      )}
      {addSpendBy === 'sponsorship' && (
        <Segment secondary>
          <Segment>
            <h5>
              Add the name of the sponsorship, the activation dates over which to assign spend and the cost of the sposorship.
            </h5>
            <DataTable
              {...{ columns, data, onUpdate, onRemove, rowProps: { updateAllNames } }}
            />
          </Segment>
        </Segment>
      )}
      {addSpendBy
        && data.filter(row => row.state).length > 0
          && issues.length === 0 && (
          <Button
            loading={loading}
            disabled={loading || (issues && issues.length > 0)}
            color="green"
            size="small"
            onClick={onSave}
          >
            Upload Spend
          </Button>
      )}
    </ContentCard>
  );
};

export default BulkCreateCampaigns;

import React, { Component } from 'react';
import shallow from 'zustand/shallow'
import moment from 'moment'

import modelStore from './modelStore'
import { getDataFeatures, getDatasetSchemaStartEnd, getDatasetSchema, getDataset } from "../../utils/api";
import { customHistory } from '../pushpath'
import { BackfillCell, defaultFunc } from '../DataStatus'
import DateRangeHooksUrlState from '../helpers/DateRangeHooksUrlState';

import { PivotGrid, ContentCard, SplitLayout, IndexGrid } from '@rockerbox/styleguide';
import { Table, Form } from 'semantic-ui-react';

const submitFunc = (row, props) => {
  const { dataset, filter_id, item: { date }, col: { url, username = null } } = props;
  const data = { dataset, filter_id, date };
  if (username) {
    data['username'] = username;
  }

  return fetch(url, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
    .then(resp => resp.json())
}

const daysAgo = (num) => moment.utc().utcOffset(-5).subtract(num, "days").format("YYYY-MM-DD")

const getDaysArray = function(start, end) {
    const dateArray = [];
    const stopDate = moment(end);

    let currentDate = moment(start);
    while (currentDate <= stopDate) {
        dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
}

const DATASET_OPTIONS = [
  {value: "sessions", text: "Onsite Sessions"},
  {value: "events_tiers", text: "Onsite Marketing Events"},
  {value: "postlog_events", text: "Postlog Events"},
  {value: "ott_events", text: "OTT Events"},
  {value: "generic_tiers", text: "Generic Events"},
  {value: "served", text: "Creative Pixel"},
]

const ModelValidation = (props) => {

  const [ touchpoint, featured, segmentOptions, getSegments ] = modelStore(({ 
      touchpoint,
      featured, 
      segmentOptions, 
      getSegments,
  }) => [ touchpoint, featured, segmentOptions, getSegments ], shallow)

  const { match: {params}, history, updatePath } = customHistory(props);
  const { featuredId, touchpointId, datasetName, startDate, endDate } = params;

  React.useEffect(() => { getSegments() }, [])
  React.useEffect(() => { 
    if (!startDate && !endDate && !datasetName && touchpointId) updatePath({ startDate: daysAgo(30), endDate: daysAgo(1), datasetName: "_"})
  }, [startDate, endDate, datasetName, touchpointId])
  React.useEffect(() => { 
    const toUpdate = {}
    if (!featuredId) Object.assign(toUpdate, { featuredId: "_"})
    if (!touchpointId) Object.assign(toUpdate, { touchpointId: "_"})
    if (featured && !featuredId != featured.action_id) Object.assign(toUpdate, { featuredId: featured.action_id})
    if (touchpoint && !touchpointId != touchpoint.action_id) Object.assign(toUpdate, { touchpointId: touchpoint.action_id })
    if (Object.keys(toUpdate).length) updatePath(toUpdate)
  }, [touchpoint, featured])

  const updateField = (fieldName) => (evt, { value }) => {
    updatePath({[fieldName]: value || "_"})
  }

  return <>

    <ContentCard>
      <Form.Dropdown label={"Build model for:"} selection value={parseInt(featuredId)} options={segmentOptions} onChange={updateField("featuredId")} />
      <DateRangeHooksUrlState label={"On date range:"} showDates inline startDate={moment.utc().utcOffset(-5).subtract(30, "days").format("YYYY-MM-DD")} />
    </ContentCard>
    <SplitLayout 
      leftWidth={2}
      rightWidth={14}
      leftContent={
        <ContentCard>
          <b>Dataset</b>
          <Form.Dropdown clearable selection value={datasetName} options={DATASET_OPTIONS} onChange={updateField("datasetName")} />
          {/* <b>Segment</b> */}
          {/* <Form.Dropdown selection value={parseInt(featuredId)} options={segmentOptions} onChange={updateField("featuredId")} /> */}
          {/* <Form.Dropdown selection value={parseInt(touchpointId)} options={segmentOptions} onChange={updateField("touchpointId")} /> */}
        </ContentCard>
      }
      rightContent={<ContentCard>
        { datasetName && datasetName != "_" && <DatasetSummary {...params} {...{ startDate, endDate, updatePath}} /> }
        { datasetName == "_" && <DatasetOverview {...params} {...{ startDate, endDate, updatePath}} /> }
      </ContentCard>}
    />
  </>
}

const Choose = ({ item, col, onSelect }) => <Table.Cell>
  <a href="" 
    onClick={(evt) => {
      onSelect(item.date)
      evt.preventDefault()
    }} 
  >View</a>
</Table.Cell>

const getDatasetIndex = (datasetName, touchpointId, startDate, endDate, useIndexAPI = true) => {
  const dates = getDaysArray(startDate, endDate)

  if (useIndexAPI) return getDatasetSchemaStartEnd(datasetName, touchpointId, "onsite_events", startDate, endDate)
    .then(resp => dates.map(date => {
        const schema = resp.response.current.find(row => row.date == date)
        return schema ? schema : { date }
      })
    )

  return Promise.all(dates.map(date => getDatasetSchema(datasetName, touchpointId, "onsite_events", date)))
    .then(allDates => {
      const data = allDates.map(({ response }, i) => {
        const date = dates[i]
        const { current } = response;
        try{
          const schema = Object.keys(current.schema[0].data_schema).sort(d3.ascending)
          const stats = current.stats[0]
          const { size } = stats
          
          return { date, schema: JSON.stringify(schema), size}
        } catch(e) {
          return { date }
        }
      })
      return data
    })
  
}

const DatasetSummary = (props) => {
  const { touchpointId, featuredId, datasetName, startDate, endDate, viewDate, updatePath } = props

  const [ data, setData] = React.useState()
  const [ viewDataset, setViewDataset] = React.useState()


  React.useEffect(() => {
    setData(undefined)
    getDatasetIndex(datasetName, touchpointId, startDate, endDate, false)
      .then(setData)
  }, [touchpointId, datasetName, startDate, endDate])

  React.useEffect(() => {
    setViewDataset(false)
    if (!viewDate) {
      setViewDataset(false)
      return
    }
    getDataset(datasetName, touchpointId, false, "data", viewDate, viewDate, "onsite_events")
      .then(({ response }) => {
        const data = response[datasetName]
        data.map(row => Object.assign(row, { count: 1}))
        setViewDataset(data)
      })
  }, [viewDate, datasetName, touchpointId])
  

  if (viewDate) {
    return <>
      <a href="#" onClick={() => updatePath({ viewDate: ""})} style={{display:"inline-block", marginTop:-10, marginBottom:10}}>← View Index</a>
      <br />
      <PivotGrid 
        key={datasetName + viewDate}
        data={viewDataset} 
        hasRawData={viewDataset && viewDataset.length > 0} defaultCols={[]} defaultRows={[]} defaultSummary={["count"]} showOptions={true} 
        selectColumnWidth={3} 
      /> 
    </> 
  }

  const runCols = [{ 
    key: "Run", 
    text: "Run", 
    display: "", 
    onClick: submitFunc, 
    as: BackfillCell, 
    url: "/run/extract/model"
  }]

  return <>
    <IndexGrid 
      filter_id={touchpointId}
      dataset={datasetName}
      onSelect={(date) => updatePath({ viewDate: date })} 
      data={data} cols={[
        ...IndexGrid.arrayToColumns(["date","size","schema"]), 
        {key: "", as: Choose}, 
        ...runCols
      ]} 
    />
  </>
}

const sd = numbers => {
  const mean = numbers.reduce((acc, n) => acc + n, 0) / numbers.length;
  return Math.sqrt(
    numbers.reduce((acc, n) => acc + (n - mean) ** 2, 0) / numbers.length
  );
};

const FEATURE_TO_DATASET = {
  identifier: ["sessions", "events_tiers"],
  has_postlog: ["postlog_events"],
  has_ott: ["ott_events"],
  has_ott_generic: ["generic_tiers"],
  has_served: ["served"]
}

const datasetToOptionsWithValues = (data, i) => Object.assign({ 
  value: DATASET_OPTIONS[i].value, 
  key: DATASET_OPTIONS[i].text, 
  values: data
})

const buildDatasetPromises = (startDate, endDate, touchpointId) => DATASET_OPTIONS.map(
  ({ value }) => getDatasetIndex(value, touchpointId, startDate, endDate)
)

const DatasetOverview = (props) => {
  const { featuredId, touchpointId, startDate, endDate } = props
  const [ features, setFeatures ] = React.useState([])
  const [ datasets, setDatasets ] = React.useState()

  React.useEffect(() => {
    getDataFeatures()
      .then((resp) => {
        resp.map(row => row.has_ott && row.has_ott_generic ? Object.assign(row, {has_ott: false}) : row)
        setFeatures(resp)
      })
  }, [])

  const requiredDatasets = React.useMemo(() => {
    if (features.length == 0) return []

    const featured = features.find(feature => feature.identifier == featuredId)
    return Object.entries(FEATURE_TO_DATASET)
      .reduce((p, [key, datasets]) => {
        if (!featured[key]) return p
        return [...p, ...FEATURE_TO_DATASET[key]]
      }, [])
  }, [features])

  React.useEffect(() => {
    const hasRequirements = startDate && endDate && touchpointId && features.length;

    if (hasRequirements) Promise.all(buildDatasetPromises(startDate, endDate, touchpointId))
      .then((datasets) => datasets.map(datasetToOptionsWithValues))
      .then((datasets) => datasets.filter(({ value }) => requiredDatasets.includes(value)))
      .then((datasets) => datasets.map(dataset => {

        const emptyDatasets = dataset.values.filter(row => row.size == 2).length
        const withSize = dataset.values.map(row => row.size).filter(row => row)
        const expectedDatasets = dataset.values.length 
        const missingDatasets = expectedDatasets - withSize.length 
        const stddev = sd(withSize)
        const mean = withSize.reduce((total, value) => total + value, 0)/withSize.length
        const deviant = withSize.filter(value => (value < mean - 4*stddev) || (value > mean + 4*stddev))
        const sizeIssues = deviant.length 
        const uniqueSchemas = Object.keys(dataset.values
          .reduce(
            (p,c) => c.schema_id ?  Object.assign(p, { [c.schema_id]: 1}) : p,
            {}
          )).length

        return Object.assign(dataset, {uniqueSchemas, sizeIssues, missingDatasets, expectedDatasets, emptyDatasets})
      }))
      .then(setDatasets)
  }, [startDate, endDate, touchpointId, features])

  const cols = IndexGrid.arrayToColumns(["key", "uniqueSchemas","sizeIssues", "emptyDatasets", "missingDatasets", "expectedDatasets"])
  
  return <IndexGrid data={datasets} cols={cols} />
}

export default ModelValidation;

import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { get } from 'lodash/fp';
import { useDatalake } from '../DatalakeContext/hooks';
import { PLATFORM_REPORT_TYPE } from '../constants';
import ErrorMessage from './ErrorMessage';

const PlatformDatasetSyncForm = ({
  disableInputs,
  submitDatasetToSync,
  width,
  styles,
}) => {
  const {
    datalakeReports,
    selectDropdownOptionsByReportType,
  } = useDatalake();

  const [platformReportOptions, setPlatformReportOptions] = useState([]);
  const [platformReport, setPlatformReport] = useState({});
  const [tableName, setTableName] = useState('');
  const [error, setError] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);

  const retrieveDatalakeReportOptions = () => {
    const reportPlatformOptions = selectDropdownOptionsByReportType(PLATFORM_REPORT_TYPE);
    setPlatformReportOptions(reportPlatformOptions);
  };

  const handleTableNameChange = (_, { value }) => setTableName(value);
  const handlePlatformReportChange = (_, { value }) => setPlatformReport(value);

  const handleSubmit = () => {
    setError('');
    setDisableSubmit(true);
    return submitDatasetToSync(platformReport, tableName)
      // eslint-disable-next-line no-unused-vars
      .then(e => {
        setDisableSubmit(false);
      })
      .catch(e => {
        const errorMessage = get(e, 'response.data.message');
        setError(errorMessage);
        setDisableSubmit(false);
      })
      .finally(() => {
        setPlatformReport({});
        setTableName('');
      });
  };

  // retrieveDatalakeReportOptions depends on datalakeReports.
  // We only want to run retrieveDatalakeReportOptions when datalakeReports are set.
  useEffect(() => {
    if (datalakeReports.length) retrieveDatalakeReportOptions();
  }, [datalakeReports]);

  return (
    <>
      <Form>
        <Form.Group style={styles}>
          <Form.Field style={{ width }}>
            <Form.Select
              fluid
              label="Platform"
              options={platformReportOptions}
              value={platformReport}
              onChange={handlePlatformReportChange}
              disabled={disableInputs}
            />
          </Form.Field>
          <Form.Field style={{ width }}>
            <Form.Input
              fluid
              label="Table Name"
              placeholder="Enter a table name"
              value={tableName}
              onChange={handleTableNameChange}
              disabled={disableInputs}
            />
          </Form.Field>
          <Form.Field>
            <Form.Button
              primary
              size="medium"
              content="Sync this dataset"
              type="button"
              onClick={handleSubmit}
              disabled={disableInputs || disableSubmit}
              style={{ marginTop: 20 }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      {error && <ErrorMessage message={error} />}
    </>
  );
};

export default PlatformDatasetSyncForm;

import React, { Component } from "react";
import OutboxReportIndex from "./OutboxReportIndex";
import { getOutboxes } from "../../utils/api";

export default class ReportIndexContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      segments: [],
      outboxes: [],
      loading: true
    };
    this.setOutboxes = this.setOutboxes.bind(this);
  }

  componentDidMount() {
    this.initializeState();
  }

  add_unique_user(outbox) {
    if (outbox.outbox_type === "amazon_s3") {
      return outbox.iam_user;
    } else if (outbox.outbox_type === "sftp" || outbox.outbox_type === "ftp") {
      return `${outbox.config.username}@${outbox.config.hostname}${outbox.config.root_directory}`;
    } else if (outbox.outbox_type === "googlecloud_storage") {
      return outbox.config.email;
    } else {
      return "";
    }
  }

  parsedOutboxes(outboxes) {
    const types = [
      "amazon_s3",
      "sftp",
      "ftp",
      "do_spaces",
      // "email",
      "googlecloud_storage"
    ];

    return outboxes
      .filter(outbox => outbox.name && types.includes(outbox.outbox_type))
      .map(outbox => {
        const identifier =
          (outbox.config && outbox.config.bucket_name) ||
          (outbox.config && outbox.config.hostname) ||
          "";

        return {
          ...outbox,
          name:
            outbox.name === "Spaces Delivery" ? "Email Delivery" : outbox.name,
          outbox_type: outbox.outbox_type,
          identifier,
          credential: this.add_unique_user(outbox)
        };
      });
  }

  initializeState() {
    return getOutboxes()
      .then(data => {
        const outboxes = this.parsedOutboxes(data);
        const s3Outboxes = outboxes.filter(o => o.outbox_type == "amazon_s3");
        const sftpOutboxes = outboxes.filter(o => o.outbox_type == "sftp");
        this.setState({
          outboxes,
          s3Outboxes,
          sftpOutboxes
        });
      })
      .then(() => this.setState({ loading: false }));
  }

  setOutboxes(obj) {
    return getOutboxes(obj).then(data => {
      const s3Outboxes =
        data &&
        this.parsedOutboxes(data.filter(o => o.outbox_type == "amazon_s3"));
      const sftpOutboxes =
        data && this.parsedOutboxes(data.filter(o => o.outbox_type == "sftp"));
      this.setState({
        outboxes: this.parsedOutboxes(data),
        s3Outboxes,
        sftpOutboxes
      });
    });
  }

  render() {
    return <OutboxReportIndex setOutboxes={this.setOutboxes} {...this.state} />;
  }
}

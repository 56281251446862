import React, { useMemo } from 'react';

const OptimizationDescription = ({ title, descriptions }) => (
  <>
    <b>{title}</b>
    {descriptions.map(descrption => (
      <>
        {descrption}
        <br />
        <br />
      </>
    ))}
  </>
);

const generateOptimizationOptions = (isConversionsKPI, budgetPeriodName) => {
  const kpiText = isConversionsKPI ? 'conversions' : 'revenue';
  const metricText = isConversionsKPI ? 'CPA' : 'ROAS';

  return ({
    maxKPI: {
      text: `Optimize channel mix to maximize ${kpiText}`,
      title: 'Optimizing your channel mix',
      value: 'max_kpi',
      inputLabel: `New ${budgetPeriodName} Value`,
      inputPlaceholder: 'Enter amount',
      descriptions: [
        ` will allocate your marketing budget to maximize ${kpiText}. 
        This scenario will both reallocate budget across your existing marketing mix and target a specific ${budgetPeriodName} spend limit to maximize ${kpiText}.`,
        `For this scenario, we recommend setting refined ${metricText} constraints per channel to achieve a result that is both profitable and maximizes ${kpiText}.`,
      ],
    },
    reduceBudget: {
      text: `Reduce ${budgetPeriodName} Budget`,
      title: 'Budget Reduction',
      value: 'reduce_budget',
      inputLabel: `New ${budgetPeriodName} Value`,
      inputPlaceholder: 'Enter amount',
      descriptions: [
        ' starts optimizing from your current baseline spend and reduces channel specific budgets to meet your new budget goal based on the medix mix model.',
        'Budget reduction on a channel will stop when either a channel specific constraint is reached or the overall target budget is achieved.',
        'Since budget reduction starts at the current spend for each channel, a min spend constraint set higher than the current baseline spend will not be respected.',
      ],
    },
    expandBudget: {
      text: `Expand ${budgetPeriodName} Budget`,
      title: 'Budget Expansion',
      value: 'expand_budget',
      inputLabel: `New ${budgetPeriodName} Value`,
      inputPlaceholder: 'Enter amount',
      descriptions: [
        ' starts optimizing from your current baseline spend and increases channel specific budgets to meet your new budget goal based on the medix mix model.',
        'Budget expansion on a channel will stop when either a channel constraint is reached or the overall target budget is achieved.',
        'Since budget expansion starts at the current spend for each channel, a max spend constraint set lower than the current baseline spend will not be respected.',
      ],
    },
    targetMetric: {
      text: `Optimize to ${metricText} Target`,
      title: `Optimize to ${metricText} Target`,
      value: 'target_metric',
      inputLabel: `Target ${metricText}`,
      inputPlaceholder: 'Enter target',
      descriptions: [
        ` will optimize your marketing mix to meet a specific ${metricText} target.`,
        `This optimization strategy will increase spend on a channel that is above the target ${metricText} 
        and decrease spend on a channel that is below the target ${metricText} until channel constraints are hit.`,
      ],
    },
    defaultOpt: {
      title: 'Scenario planning is designed to cover the major budgeting decisions marketing teams face.  The four scenarios covered:',
      descriptions: [
        <>
          <li>{`How to design the optimal channel mix to achieve more ${kpiText}`}</li>
          <li>{`Where to cut budget while minimizing impact on ${kpiText}`}</li>
          <li>Where to spend more when incremental dollars become available</li>
          <li>{`How to optimize to a specific ${metricText} target`}</li>
          Choose the scenario which matches your current business needs to leverage the marketing mix model for budget planning.
        </>,
      ],
    },
  });
};

export const useOptimizationOptions = (isConversionsKPI, budgetPeriodName, selectedOptimization) => {
  const optimizations = useMemo(() => generateOptimizationOptions(isConversionsKPI, budgetPeriodName), [isConversionsKPI, budgetPeriodName]);

  const optimizationOptions = useMemo(() => (
    Object.keys(optimizations)
      .filter(optimization => optimizations[optimization].value)
      .map(optimization => ({
        text: optimizations[optimization].text,
        value: optimizations[optimization].value,
        inputLabel: optimizations[optimization].inputLabel,
        inputPlaceholder: optimizations[optimization].inputPlaceholder,
      }))
  ), [optimizations]);

  const selectedOptimizationOption = useMemo(() => {
    if (selectedOptimization) {
      return optimizationOptions.find(({ value }) => value === selectedOptimization);
    }
    return false;
  }, [optimizations, selectedOptimization]);

  const optimizationDescriptions = useMemo(() => {
    const { maxKPI, reduceBudget, expandBudget, targetMetric, defaultOpt } = optimizations;
    return ({
      max_kpi: <OptimizationDescription title={maxKPI.title} descriptions={maxKPI.descriptions} />,
      reduce_budget: <OptimizationDescription title={reduceBudget.title} descriptions={reduceBudget.descriptions} />,
      expand_budget: <OptimizationDescription title={expandBudget.title} descriptions={expandBudget.descriptions} />,
      target_metric: <OptimizationDescription title={targetMetric.title} descriptions={targetMetric.descriptions} />,
      default: <OptimizationDescription title={defaultOpt.title} descriptions={defaultOpt.descriptions} />,
    });
  }, [optimizations]);

  return {
    optimizationOptions,
    optimizationDescriptions,
    selectedOptimizationOption,
  };
};

import { TIERS } from '../../../constants/tiers';

// Used for JOINS
export const JOIN_KEYS = [...TIERS, 'date', 'platform_join_key', 'platform'];

export const PLATFORM_TO_TIERS = [
  ['spend_key', 'date'],
  ['platform_join_key', 'date'],
];
export const TIERS_TO_ASSISTED = [
  ['date', 'platform', 'platform_join_key'],
  ['date', 'platform', 'platform_join_key'],
];
export const TIERS_TO_ASSISTED_WINDOW = [
  ['date', 'platform', 'platform_join_key'],
  ['date', 'platform', 'platform_join_key'],
];

const buildRenameObj = (suffix = '_spend_mta') => TIERS.reduce(
  (obj, tier) => Object.assign(obj, { [`${tier}${suffix}`]: tier }),
  {},
);

export const RENAME_PLATFORM_TIERS = buildRenameObj('_platform');
export const RENAME_FIRST_VISIT_TIERS = buildRenameObj('_first_visits');

export const TIERS_DATA_COLS = [
  'conversions_even_rb',
  'conversions_first_touch_rb',
  'conversions_last_touch_rb',
  'conversions_normalized_rb',
  'revenue_even_rb',
  'revenue_first_touch_rb',
  'revenue_last_touch_rb',
  'revenue_normalized_rb',
];

export const RATIO_NAMES = {
  cpa: ['spend', 'conversions'],
  cpa_assisted_rb: ['spend', 'conversions_assisted_rb'],
  cpa_normalized_rb: ['spend', 'conversions_normalized_rb'],
  cpa_assisted_att_win_rb: ['spend', 'conversions_assisted_att_win_rb'],
  cpa_even_rb: ['spend', 'conversions_even_rb'],
  cpa_first_touch_rb: ['spend', 'conversions_first_touch_rb'],
  cpa_last_touch_rb: ['spend', 'conversions_last_touch_rb'],

  roas: ['revenue', 'spend'],
  roas_assisted_rb: ['revenue_assisted_rb', 'spend'],
  roas_normalized_rb: ['revenue_normalized_rb', 'spend'],
  roas_assisted_att_win_rb: ['revenue_assisted_att_win_rb', 'spend'],
  roas_even_rb: ['revenue_even_rb', 'spend'],
  roas_first_touch_rb: ['revenue_first_touch_rb', 'spend'],
  roas_last_touch_rb: ['revenue_last_touch_rb', 'spend'],
};

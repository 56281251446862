import React from 'react';

import { TransitionablePortal, Segment, Header, Icon } from 'semantic-ui-react';

const FullscreenModal = ({
  open,
  onOpen,
  onClose,
  trigger,
  headerIcon,
  headerContent,
  children,
}) => (
  <TransitionablePortal
    open={open}
    onOpen={onOpen}
    onClose={onClose}
    trigger={trigger}
    openOnTriggerClick
    closeOnTriggerClick
    transition={{
      animation: 'fade up',
      duration: 500,
    }}
  >
    <Segment className="fullscreen-modal">
      <div className="fullscreen-modal-inner">
        <div className="fullscreen-modal-header">
          <Header
            icon={headerIcon}
            content={headerContent}
          />
          <Icon
            name="close"
            color="grey"
            size="large"
            link
            onClick={onClose}
          />
        </div>
        <div className="fullscreen-modal-content">
          {children}
        </div>
      </div>
    </Segment>
  </TransitionablePortal>
);

export default FullscreenModal;

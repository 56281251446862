import React from 'react';

import { Button, Step } from 'semantic-ui-react';

const MultiStepLayout = props => {
  const { steps: initialSteps, isDisabled: disabled, onSubmit: handleSubmit } = props;

  const [steps, setSteps] = React.useState(initialSteps || []);
  const [activeStep, setActiveStep] = React.useState(steps && steps.length && steps[0].value);

  React.useEffect(() => setSteps(steps), [initialSteps]);

  const active = React.useMemo(() => steps.find(row => row.value === activeStep), [steps, activeStep]);
  const ActiveComponent = active.as;

  const activeIndex = React.useMemo(() => steps.indexOf(active), [active]);
  const showSubmit = (activeIndex + 1) === steps.length;
  const isDisabled = disabled || false;

  const setNextStep = () => setActiveStep(steps[activeIndex + 1].value);
  const onSubmit = handleSubmit || (() => {});

  return (
    <>
      <Step.Group widths={steps.length} size="mini">
        { steps.map(({ value, text }) => (
          <Step active={activeStep === value} onClick={() => setActiveStep(value)}>
            <Step.Content>
              <Step.Title>{ text }</Step.Title>
            </Step.Content>
          </Step>
        ))}
      </Step.Group>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ActiveComponent {...props} {...{ activeStep, setActiveStep }} />
      <div style={{ position: 'fixed', bottom: '20px', right: '20px' }}>
        { showSubmit
          ? <Button size="big" color="green" disabled={isDisabled} type="submit" content="Submit" icon="right check" labelPosition="right" onClick={onSubmit} />
          : <Button size="big" disabled={isDisabled} as="a" primary content="Next" onClick={setNextStep} icon="right arrow" labelPosition="right" />}
      </div>

    </>
  );
};

export default MultiStepLayout;

import React from 'react';
import { ContentCard, IndexGrid } from '@rockerbox/styleguide';
import OtherDataSources from './OtherDataSources'
import { ViewSource, CheckComponent } from './EventDataSources';
import { CacheContext } from '../../utils/CacheContext.js'
import { getAccount } from '../../utils/api'

const cols = [
  { display: '', key: 'check', as: CheckComponent },
  { display: "Source Name", key: "display_name" },
  { display: '', key: 'path', as: ViewSource },
]

const dataSources = [
  { display_name: "Historical Purchase Data", path: "/v2/admin/data_sources/view/customer_data", is_active: true}
]

const DataSources = () => {
  const [state, setState, Context] = React.useContext(CacheContext);
  const account = (Context.getCache(`account`, getAccount) || {})
  const { features } = account
  const showLegacyDataSources = features.indexOf('legacy_data_sources') > -1

  return (
    <React.Fragment>
      {/* <EventDataSources /> */}
      {!!showLegacyDataSources &&
        <OtherDataSources />
      }
      <IndexGrid data={dataSources} cols={cols} as={ContentCard.Table} fallbackMsg="No Data Sources"/>
    </React.Fragment>
  )
}

export default DataSources;

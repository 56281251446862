import React from 'react';

import { IndexGridNew } from '@rockerbox/styleguide';
import { Header } from 'semantic-ui-react';

import { Charts, SpendEffectChart, ROIHatChart } from './charts';
import { createTierColorMap } from './helpers';
import { useTableCalculations } from './hooks/useChartAndTable';
import { useDates } from './hooks/useDates';
import { useTrainingData } from './hooks/useTrainingData';
import { DateRangeHooks } from '../../../components';
import { useGlobalState } from '../../../hooks/global';

const MarketingPerformance = ({ isConversionKPI, modelData, responseCurves, featureNameMap }) => {
  const { tierColors } = useGlobalState();
  const { startDate, endDate, onDateChange, firstReportingDate, maxSelectableDate } = useDates(modelData);

  const { trainingData, spendEffectData, featureROI } = modelData || {};
  const {
    selectedData,
    selectedRevData: selectedKPIData,
    selectedROI: selectedMetric,
    timeSeriesDataByDateRange,
  } = useTrainingData(isConversionKPI, trainingData, responseCurves, featureNameMap, startDate, endDate);

  const tierColorMap = createTierColorMap(timeSeriesDataByDateRange, tierColors, featureNameMap);

  const { cols, tableData } = useTableCalculations(timeSeriesDataByDateRange, isConversionKPI, tierColorMap);
  const chartData = { selectedData, selectedMetric, timeSeriesDataByDateRange, selectedKPIData };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 3 }}>
          <Header as="h2" style={{ margin: '1rem', marginTop: '1.5rem' }}>
            Performance Overview
          </Header>
          <Header as="h5" style={{ margin: '1rem' }}>
            Below is an overview of performance, as measured and evaluated using the Marketing Mix Model.
          </Header>
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ marginBottom: '1rem', margin: '1rem', maxWidth: '300px' }}>
            {startDate && <DateRangeHooks hidePresetDates {...{ startDate, endDate, onDateChange, firstReportingDate, maxSelectableDate }} /> }
          </div>
        </div>
      </div>
      {!!modelData && (
        <>
          <IndexGridNew
            data={tableData}
            cols={cols}
          />
          <Charts {...{ isConversionKPI, chartData, featureNameMap, tierColorMap }} />
        </>
      )}
      {spendEffectData && spendEffectData.length > 0 && (
        <SpendEffectChart
          spendEffectData={spendEffectData}
          isConversionKPI={isConversionKPI}
          tierColorMap={tierColorMap}
          featureNameMap={featureNameMap}
        />
      )}
      {true && (
        <ROIHatChart ROIHatData={featureROI} {...{ tierColorMap, featureNameMap }} />
      )}
    </>
  );
};

export default MarketingPerformance;

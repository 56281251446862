import React, { useState, useEffect } from 'react';

import { useResetAtom } from 'jotai/utils';
import { Image } from 'semantic-ui-react';

import { useGlobalState } from './global';
import { useURLStateAndSetDefault } from './urlState';
import { useRBACPermissions, useRBACAdvertiser } from './useRBAC';
import { goalAtom } from '../atoms';
import {
  RBAC_INTEGRATIONS_PLATFORM_ADWORDS,
  RBAC_INTEGRATIONS_PLATFORM_BING,
  RBAC_INTEGRATIONS_PLATFORM_FACEBOOK,
  RBAC_INTEGRATIONS_PLATFORM_LINKEDIN,
  RBAC_INTEGRATIONS_PLATFORM_PINTEREST,
  RBAC_INTEGRATIONS_PLATFORM_SNAPCHAT,
  RBAC_INTEGRATIONS_PLATFORM_TIKTOK,
} from '../constants/rbac';
import { checkUrlParamValue } from '../utils/dataValidation';

const platformSettings = {
  adwords: {
    color: '#34a853',
    rbacPermissions: RBAC_INTEGRATIONS_PLATFORM_ADWORDS,
  },
  bing: {
    color: '#60a79b',
    rbacPermissions: RBAC_INTEGRATIONS_PLATFORM_BING,
  },
  facebook: {
    color: '#1877f2',
    rbacPermissions: RBAC_INTEGRATIONS_PLATFORM_FACEBOOK,
  },
  linkedin: {
    color: '#00a0dc',
    rbacPermissions: RBAC_INTEGRATIONS_PLATFORM_LINKEDIN,
  },
  pinterest: {
    color: '#e60023',
    rbacPermissions: RBAC_INTEGRATIONS_PLATFORM_PINTEREST,
  },
  snapchat: {
    color: '#fffc00',
    rbacPermissions: RBAC_INTEGRATIONS_PLATFORM_SNAPCHAT,
  },
  tiktok: {
    color: '#25ece8',
    rbacPermissions: RBAC_INTEGRATIONS_PLATFORM_TIKTOK,
  },
};
const platforms = Object.keys(platformSettings);

// Auth / Options Setup
const integrationsToMap = integrations => {
  const integrationsMap = {};

  platforms.forEach(platform => {
    const selectedObj = integrations.find(obj => obj.platform === platform && obj.type === 'spend');
    const src = `https://images.rockerbox.com/integrations/${selectedObj.icon_img}`;
    const alt = `https://images.rockerbox.com/integrations/${selectedObj.icon_white_img}`;
    integrationsMap[platform] = { icon: src, color: platformSettings[platform].color, display_name: selectedObj.display_name, alt };
  });

  return integrationsMap;
};

const authorizationsToAuthedPlatforms = authorizations => authorizations
  .filter(c => platforms.includes(c.platform) && c.authorizations.filter(a => !!a.account_id).length > 0)
  .map(c => c.platform);

const integrationsAndplatformsToOptions = (integrations, authedPlatforms, filterIntegrations) => integrations
  .filter(integration => authedPlatforms.includes(integration.platform) && filterIntegrations[integration.platform]) // Enable only platforms with config
  .map(integration => {
    const integrationMapped = { ...integration };
    integrationMapped.src = `https://images.rockerbox.com/integrations/${integration.logo_img}`;
    integrationMapped.icon = `https://images.rockerbox.com/integrations/${integration.icon_img}`;
    return integrationMapped;
  })
  .map(c => ({
    key: c.platform,
    text: (
      <span>
        <Image size="tiny" src={c.icon} style={{ marginRight: 5, width: 15, display: 'inline' }} />
        {c.display_name}
      </span>
    ),
    value: c.platform,
    // image: { src: c.icon },
  }));

const authorizationsAndPlatformsToAccounts = (authorizations, authedPlatforms) => authorizations.reduce((accu, obj) => {
  if (authedPlatforms.includes(obj.platform)) {
    obj.authorizations.forEach(authz => {
      accu[authz.account_id] = authz.account_name;
    });
  }
  return accu;
}, {});

const useAuthedIntegrations = filterIntegrations => {
  const [platformName, setPlatform] = useURLStateAndSetDefault('platformName', '0', true);
  const platform = checkUrlParamValue(platformName) ? platformName : '';
  const { authorizations, integrations } = useGlobalState();
  const { advertiserExistsinRBAC } = useRBACAdvertiser();
  const { authorizedPermissions } = useRBACPermissions();
  const resetGoal = useResetAtom(goalAtom);
  const [accountMap, setAccountMap] = useState(undefined);
  const [dropdownOptions, setDropdownOptions] = useState(undefined);
  const [dropdownOptionsLoading, setDropdownOptionsLoading] = useState(true);
  const [integrationsMap, setIntegrationsMap] = useState(undefined);

  // This doesnt seem like it needs to be a use effect.
  // likely creating a rerender when it is not necessary
  useEffect(() => {
    if (!integrations || !authorizations) return;

    const integrationsMapObj = integrationsToMap(integrations);
    const authedPlatforms = authorizationsToAuthedPlatforms(authorizations);
    const options = integrationsAndplatformsToOptions(integrations, authedPlatforms, filterIntegrations);
    const authorizedOptions = advertiserExistsinRBAC ? options.filter(option => authorizedPermissions.includes(platformSettings[option.value].rbacPermissions)) : options;
    const accounts = authorizationsAndPlatformsToAccounts(authorizations, authedPlatforms);

    setAccountMap(accounts);
    setDropdownOptions(authorizedOptions);
    setIntegrationsMap(integrationsMapObj);
    setDropdownOptionsLoading(false);
  }, [integrations, authorizations, authorizedPermissions]);

  const onDropdownChange = ((e, { value }) => {
    setPlatform(value);
    resetGoal();
  });

  return {
    platform,
    accountMap,
    onDropdownChange,
    dropdownOptions,
    dropdownOptionsLoading,
    integrations: integrationsMap,
    authorizations,
  };
};

export default useAuthedIntegrations;

import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import { useParams } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import { Container, Grid, Header } from 'semantic-ui-react';
import { compose, map, sortBy, every, defaultTo } from 'lodash/fp';

import { StepsProvider, useStepsDisplay } from './StepsSectionContext';

const StepIcon = ({ order }) => (
  <div style={{
    backgroundColor: '#14B8B8',
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
    flex: '0 0 auto',
  }}
  >
    {order}
  </div>
);

const Step = ({
  order,
  description,
  props,
  component: Component,
  requires,
}) => {
  const [stepsDisplay] = useStepsDisplay();
  const display = compose(
    defaultTo(true),
    every(req => stepsDisplay[req]),
  )(requires);
  const rowStyles = {
    padding: '20px 0px',
  };
  const disabledStyles = {
    ...rowStyles,
    opacity: 0.25,
    pointerEvents: 'none',
  };
  return (
    <Grid.Row style={display ? rowStyles : disabledStyles}>
      <Grid.Column width={1} style={{ display: 'flex', justifyContent: 'center' }}>
        <StepIcon order={order} />
      </Grid.Column>
      <Grid.Column width={15}>
        <Header size="medium">{description}</Header>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...props} />
      </Grid.Column>
    </Grid.Row>
  );
};

Step.propTypes = {
  order: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  props: PropTypes.object,
  component: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  requires: PropTypes.array,
};

const Sections = ({ steps, customSection }) => {
  const sections = compose(
    map(Step),
    sortBy('order'),
  )(steps);
  const CustomSection = customSection ? customSection.component : null;

  return (
    <Grid>
      {sections}
      {customSection && <CustomSection />}
    </Grid>
  );
};

const StepsSection = ({ steps, customSection }) => (
  <StepsProvider steps={steps}>
    <Sections steps={steps} customSection={customSection} />
  </StepsProvider>
);

export default StepsSection;

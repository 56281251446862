import mixpanel from 'mixpanel-browser'
import * as FullStory from '@fullstory/browser'

export const track = async (eventName, metadata={}, options={}) => {
  mixpanelTrack(eventName, metadata, options)
  intercomTrack(eventName, metadata)
  fullstoryTrack(eventName, metadata)
}

// Mixpanel
export const mixpanelTrack = async (eventName, metadata, options) => {
  mixpanel.track(eventName, {
    ...metadata,
    window_height: window.innerHeight,
    window_width: window.innerWidth,
  }, {
    ...options,
  })
}

// Intercom
export const intercomTrack = async (eventName, metadata) => {
  if (!window.Intercom) return
  window.Intercom('trackEvent', eventName, metadata)
}

// FullStory
export const fullstoryTrack = async (eventName, metadata) => {
  const { isInitialized, event } = FullStory
  if (!isInitialized()) return
  event(eventName, metadata)
}

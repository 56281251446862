import React, { useEffect, useState } from 'react';

import { useAtom } from 'jotai';
import { Input, Icon, Message } from 'semantic-ui-react';

import { useOptimizationOptions } from './useOptimizeOptions';
import { RadioDropdown } from '../../../../../components/select/SelectRadioList';
import { optimizationAtom, budgetAtom } from '../../atoms';
import { useBudgetPeriod } from '../budget/useBudgetPeriod';
import { SplitLayout, FormWrapSegment, FormDescription } from '../Layout';

const Optimization = ({ isConversionKPI, baselineTotal }) => {
  const [, setBudget] = useAtom(budgetAtom);
  const [optimization, setOptimization] = useAtom(optimizationAtom);
  const { budgetPeriod, budgetPeriodName } = useBudgetPeriod();
  const { optimizationDescriptions, optimizationOptions, selectedOptimizationOption } = useOptimizationOptions(isConversionKPI, budgetPeriodName, optimization);

  const [localBudget, setLocalBudget] = useState(false);
  const [issue, setIssue] = useState(false);

  const resetBudget = () => {
    const defaulBudget = optimization === 'target_metric' ? 1 : baselineTotal * budgetPeriod;
    setLocalBudget(defaulBudget);
  };

  const onInputChange = (e, { value }) => setLocalBudget(value);

  const validateBudget = () => {
    if (optimization === 'reduce_budget' && localBudget > baselineTotal) {
      setIssue('The proposed budget is greater than the current budget');
      return;
    }

    if (optimization === 'expand_budget' && localBudget < baselineTotal) {
      setIssue('The proposed budget is less than the current budget');
      return;
    }

    if (isNaN(Number(localBudget)) || localBudget < 0) {
      setIssue('Please enter a valid number');
      setBudget(false);
      return;
    }

    setIssue(false);
    const newBudget = optimization === 'target_metric' ? localBudget : localBudget / budgetPeriod;
    setBudget(newBudget);
  };

  useEffect(() => {
    setOptimization(false);
    resetBudget();
  }, [budgetPeriod, baselineTotal]);

  useEffect(() => {
    resetBudget();
  }, [optimization]);

  useEffect(() => {
    if (localBudget !== false) {
      validateBudget();
    }
  }, [localBudget]);

  return (
    <SplitLayout
      header="4. Choose Scenario"
      leftContent={(
        <FormWrapSegment key={baselineTotal + optimization}>
          <div style={{ padding: '.5rem' }}>
            <RadioDropdown
              defaultValue={optimization}
              onChange={(e, { value }) => setOptimization(value)}
              options={optimizationOptions}
            />
            <br />
            {localBudget !== false && optimization && (
              <Input
                label={{
                  style: { maxWidth: 140 },
                  content: selectedOptimizationOption.inputLabel,
                }}
                placeholder={selectedOptimizationOption.inputPlaceholder}
                value={localBudget}
                onChange={onInputChange}
              />
            )}
            {!!issue && (
              <Message>
                <Icon name="warning sign" color="red" />
                {issue}
              </Message>
            )}
          </div>
        </FormWrapSegment>
      )}
      rightContent={<FormDescription>{optimizationDescriptions[optimization] || optimizationDescriptions.default}</FormDescription>}
    />
  );
};

export default Optimization;

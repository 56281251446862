import React from "react";
import { ColumnLayout } from "@rockerbox/styleguide";
import { warehouseStore } from "./zustandStore";
import {
  BigQuerySettingTable,
  SnowflakeSettingTable,
  RedshiftSettingTable
} from "./SettingTables";
import InstructionSetupCard from "./SetupInstructionCard";
import SnowflakeSuccessInstruction from "./SnowflakeSuccessInstruction";

const Snowflake = props => (
  <ColumnLayout
    leftWidth={9}
    rightWidth={6}
    leftContent={<SnowflakeSuccessInstruction {...props} />}
    rightContent={<SnowflakeSettingTable {...props} />}
  />
);

const tableMaps = {
  gcp: BigQuerySettingTable,
  snowflake: Snowflake,
  aws: RedshiftSettingTable,
  empty: () => {
    return null;
  }
};

const createPaths = (bucket, pixel_source_name, datasets) => {
  const paths = datasets
    ? datasets.map(
        dataset =>
          `gs://${bucket && bucket["bucket_name"]}/${pixel_source_name}/${
            dataset["filter_id"]
          }/*-${dataset["dataset"]}.parquet.gz`
      )
    : [];
  return paths;
};

const warehouseSelector = state => [state.warehouses, state.activeWarehouseId];

const ConfigurationSettings = props => {
  const [warehouses, activeWarehouseId] = warehouseStore(warehouseSelector);
  const { platform } = props;
  const activeWarehouse =
    warehouses.filter(warehouse => warehouse.id === activeWarehouseId)[0] || {};
  const { bucket, datasets, pixel_source_name } = activeWarehouse;
  const SettingTable = tableMaps[platform] || tableMaps["empty"];
  const paths = createPaths(bucket, pixel_source_name, datasets);
  // get filter_id from dataset and use first one
  const filter_id = datasets && datasets.length ? datasets.map(dataset => dataset.filter_id)[0]: null
  return (
    <React.Fragment>
      <React.Fragment>
        <SettingTable {...bucket} datasets={datasets} filter_id={filter_id}/>
        {platform === "gcp" ? (
          <InstructionSetupCard bucket={bucket} paths={paths} />
        ) : null}
      </React.Fragment>
    </React.Fragment>
  );
};

export default ConfigurationSettings;

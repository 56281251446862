export const PLATFORMS = {
  facebook: {
    color: '#479BF5',
  },
  snapchat: {
    color: '#F5F33D',
  },
  bing: {
    color: '#00B8A1',
  },
  tiktok: {
    color: '#42F0EB',
  },
  linkedin: {
    color: '#0A94C2',
  },
  pinterest: {
    color: '#E61939',
  },
  adwords: {
    color: '#12D975',
  },
};

export const WARNING_PLATFORMS = ['facebook', 'tiktok'];

export const RED = '#F44336';
export const GREEN = '#4CAF50';
export const GREY = '#ccc';
export const PURPLE = '#542c9c';
export const CURRENCY_GREEN = '#12D975';
export const BLUE = '#475edc';
export const WHITE = 'fff';

export const SUCCESS_STATUSES = ['success', 'empty', 'excluded'];
export const NEUTRAL_STATUSES = ['in_progress', 'upstream_unavailable'];
export const AUTO_PARAMS_PLATFORMS = ['facebook', 'adwords'];

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { Loader, Segment } from 'semantic-ui-react';
import { ContentCard, IndexGridTree, HorizontalStackedBar } from '@rockerbox/styleguide';
import { useAtom } from 'jotai';
import { atomWithHash } from 'jotai/utils';
import { getLegacyPaths, getLegacyPathsDetails } from '../../api/attributionCache';
import { getDatasetSchemaStartEnd } from '../../api/attributionEvents';
import { useURLStateAndSetDefault } from '../../hooks/urlState';
import { useGlobalState } from '../../hooks/global';
import { HandlerBoundary, DataWarning, NoDataCat, Drawer, PopupCard, CustomizeColumnsButton, ViewAccessWrapper, LayoutToggles } from '../../components';
import Filter from './Filter';
import { MainHeader } from './stateless';
import { generateDateRange, getSummaryByCustomer, generateColorMap, createFilterObj } from './helpers';
import { useTreeColumns } from './hooks/treeColumns';
import { processTableData, filterIdsByDate, filterIdsByLen, filterIdsByTouchPoint, intersection } from './filterDataMain';
import { TreeTableLoader } from '../../components/loaders';
import { yesterday, weekAgo } from '../../utils/time';
import { Trie } from './tiersTree';
import { track, time } from '../../../utils/tracking';
import { anyAtom, firstAtom, midAtom, lastAtom, customerTypeAtom } from '../../atoms';

const reportTypeAtom = atomWithHash('reportType', 'mix');
const minPathLenAtom = atomWithHash('minPathLen', '2');

// must use pixel source name as key for override to work
const tierOverrides = {
  eneba: 3,
  stockx: 2,
};

const FunnelPosition = () => {
  const { state, segments: segsInfo, tierColors, artifacts } = useGlobalState();

  // router params
  const [segId, setSegId] = useURLStateAndSetDefault('id', 0, true);
  const [startDate, setStartDate] = useURLStateAndSetDefault('startDate', weekAgo, true);
  const [endDate, setEndDate] = useURLStateAndSetDefault('endDate', yesterday, true);

  // filters
  const [reportType, setReportType] = useAtom(reportTypeAtom);
  const [customerType, setCustomerType] = useAtom(customerTypeAtom);
  const [minPathLen, setMinPathLen] = useAtom(minPathLenAtom);
  const [any, setAny] = useAtom(anyAtom);
  const [first, setFirst] = useAtom(firstAtom);
  const [mid, setMid] = useAtom(midAtom);
  const [last, setLast] = useAtom(lastAtom);

  const [dates, setDates] = useState([]);
  const [hasNtf, setHasNtf] = useState(true);
  const [error, setError] = useState(false);
  const [filterObj, setFilterObj] = useState({});
  const [segmentSize, setSegmentSize] = useState(undefined);
  const maxSize = 10000000;
  const hourMs = 3600000;

  // Exclude problematic accounts
  const { account } = state;
  const { pixel_source_name } = account || {};
  const clientsWithOverride = Object.keys(tierOverrides);
  const EXCLUDED_ACCOUNTS = [];
  const isExcluded = useMemo(() => EXCLUDED_ACCOUNTS.includes(pixel_source_name), [pixel_source_name]);

  // Loader Manegment
  const [caching, setCaching] = useState(true);
  const [applyFilters, setApplyFilters] = useState(true);
  const [loading, setLoading] = useState(true);

  // Tooltips
  const { funnel_tooltips, funnel_help_docs, intercom_product_tours } = artifacts;
  const tooltips = !!funnel_tooltips ? JSON.parse(funnel_tooltips) : {};
  const helpDocs = !!funnel_help_docs ? JSON.parse(funnel_help_docs) : {};
  // eslint-disable-next-line no-unused-vars
  const helpDocLink = helpDocs?.funnel_help_docs_link;
  const intercomTours = !!intercom_product_tours ? JSON.parse(intercom_product_tours) : {};
  // eslint-disable-next-line no-unused-vars
  const intercomTourId = intercomTours?.funnel;

  // Cache Datasets
  const [cacheInfo, setCacheInfo] = useState({});
  const [pathIdsByDate, setPathIdsByDate] = useState({});
  const [pathIdsByLen, setPathIdsByLen] = useState({ 2: [], 3: [], 4: [], more: [] });
  const [pathIdsByTiers, setPathIdsByTiers] = useState(new Trie());
  const prevPathIdsByTiersRef = useRef();
  const [pathLookup, setPathLookup] = useState({});
  // Filter Datasets
  const [filteredByDate, setFilteredByDate] = useState(undefined);
  const [filteredByLen, setFilteredByLen] = useState(undefined);
  const [filteredPathIds, setFilteredPathIds] = useState(undefined);
  const [finalPathIds, setFinalPathIds] = useState([]);
  // View Datasets
  const [tableData, setTableData] = useState([]);
  const [tierColorMap, setTierColorMap] = useState({});
  const [channelSummary, setChannelSummary] = useState(undefined);
  const [chartData, setChartData] = useState([]);
  const [columnsDrawerOpen, setColumnsDrawerOpen] = useState(false);

  const filterTouchPoints = { minPathLen, any, first, mid, last, filterObj, applyFilters };
  const filterTouchpointsMethods = { setMinPathLen, setAny, setFirst, setMid, setLast, setFilterObj, setApplyFilters };

  // columns
  const { allColumns, selectedColumns, setSelectedColumns } = useTreeColumns(customerType, reportType, tooltips, tierColorMap);

  useEffect(() => {
    prevPathIdsByTiersRef.current = pathIdsByTiers;
  }); // Supports updating nested data on async cache

  useEffect(() => {
    const updateFilterObj = createFilterObj({ any, first, mid, last }, true);

    setFilterObj(updateFilterObj);
  }, [any, first, mid, last]);

  useEffect(() => {
    if (Number(segId) === 0) return;
    getDatasetSchemaStartEnd('path_t5', segId, 'attribution_events', weekAgo, yesterday)
      .then(r => {
        const { current: currentSizes = [] } = r.response;
        const avgSize = currentSizes.reduce((agg, obj) => (agg + obj.size), 0) / currentSizes.length;
        setSegmentSize(avgSize);
      // eslint-disable-next-line no-unused-vars
      }).catch(err => setError(true));
  }, [segId]);

  useEffect(() => {
    setCacheInfo({});
    setPathIdsByDate({});
    setPathLookup({});
    setPathIdsByLen({ 2: [], 3: [], 4: [], more: [] });
    setPathIdsByTiers(new Trie());
    setFilteredByDate(undefined);
    setFilteredByLen(undefined);
    setFilteredPathIds(undefined);
    setFinalPathIds([]);
    setCaching(true);
    setApplyFilters(true);
    setLoading(true);
  }, [segId]);

  useEffect(() => {
    if (!segId || segsInfo.length === 0) return;
    const selected = segsInfo.find(x => {
      if (segId === '0') return x.featured === 1;
      return x.filter_id === Number(segId);
    });
    const { filter_id, has_new_to_file } = selected || {};
    // eslint-disable-next-line no-unused-expressions
    segId === '0' && setSegId(filter_id);
    setHasNtf(!!has_new_to_file);
  }, [segId, segsInfo]);

  useEffect(() => {
    if (!segmentSize) return;
    if (startDate && endDate) setDates(generateDateRange(startDate, endDate));
  }, [startDate, endDate, segId, segmentSize]); // Get dates only after evaluating segment sizes to prevent fetching data of unsupported segments

  useEffect(() => {
    if (!dates.length) return;

    const allLoaded = dates.every(date => pathIdsByDate[date] !== undefined);
    const allInfoUpdated = dates.every(date => (cacheInfo[date] === undefined ? false : (new Date().getTime() - cacheInfo[date].timestamp) < hourMs));
    allLoaded && allInfoUpdated ? setCaching(false) : setCaching(true);
  }, [dates, cacheInfo, pathIdsByDate]);

  useEffect(() => {
    if (segId === 0 || !dates.length || !segmentSize || !pixel_source_name || (segmentSize > maxSize && !clientsWithOverride.includes(pixel_source_name))) return;
    const tierVal = (segmentSize > maxSize && clientsWithOverride.includes(pixel_source_name)) ? tierOverrides[pixel_source_name] : 5;

    if (dates.every(date => (!cacheInfo[date] ? false : (new Date().getTime() - cacheInfo[date].timestamp) < hourMs))) return;
    const chunks = [];
    const chunkLength = 10;

    for (let i = 0; i < dates.length; i += chunkLength) {
      const chunk = dates.slice(i, i + chunkLength);
      if (!chunk.every(date => (!cacheInfo[date] ? false : (new Date().getTime() - cacheInfo[date].timestamp) < hourMs))) { chunks.push(chunk); }
    }

    chunks.forEach(chunk => {
      Promise.all([getLegacyPaths(segId, tierVal, chunk[0], chunk[chunk.length - 1]), getLegacyPathsDetails(segId, tierVal, chunk[0], chunk[chunk.length - 1])])
        .then(response => { // response
          const data = response[0];
          const details = response[1];

          const infoObj = {};
          const datesToUpdate = Object.keys(details).filter(date => (!cacheInfo[[date]] ? true : details[[date]] !== cacheInfo[[date]].cache_id));
          chunk.forEach(date => {
            infoObj[date] = { cache_id: !details[[date]] ? 0 : details[[date]], timestamp: new Date().getTime() };
          });
          setCacheInfo(prev => ({ ...prev, ...infoObj }));
          if (datesToUpdate.length === 0) return;

          const chunkPathLookup = {};
          const chunkPathIdsByDate = {};
          const chunkPathIdsByLen = { 2: [], 3: [], 4: [], more: [] };
          const groupData = list => {
            // eslint-disable-next-line array-callback-return
            list.reduce((r, obj, i) => {
              const { path, count, count_new_to_file, path_length, date } = obj;
              if (!datesToUpdate.includes(date)) return;
              if (path_length === 1) return;
              const lengroup = path_length <= 4 ? path_length : 'more';
              const pathId = `${Date.now() + i}-${date}`;
              chunkPathIdsByDate[date] = chunkPathIdsByDate[date] || [];
              chunkPathIdsByDate[date].push(pathId);
              chunkPathIdsByLen[lengroup].push(pathId);
              chunkPathLookup[pathId] = { count_all: count, count_ntf: count_new_to_file, count_ret: count - count_new_to_file, path };
              path.forEach((touchpoint, index) => {
                const position = (index === 0) ? 'first'
                  : (index === path_length - 1) ? 'last' : 'mid';
                prevPathIdsByTiersRef.current.insert(touchpoint, pathId, position);
              });
            });
          };

          if (data) {
            groupData(data);
            chunk.forEach(date => { if (!chunkPathIdsByDate[date] && !cacheInfo[date]) chunkPathIdsByDate[date] = []; }); // Account for missing dates
          }
          setPathLookup(prev => ({ ...prev, ...chunkPathLookup }));
          setPathIdsByDate(prev => ({ ...prev, ...chunkPathIdsByDate }));
          setPathIdsByLen(prev => ({
            2: [...prev['2'], ...chunkPathIdsByLen['2']],
            3: [...prev['3'], ...chunkPathIdsByLen['3']],
            4: [...prev['4'], ...chunkPathIdsByLen['4']],
            more: [...prev.more, ...chunkPathIdsByLen.more],
          }));
          setPathIdsByTiers(prevPathIdsByTiersRef.current);
        })
        .catch(() => setError(true));
    });
  }, [dates]);

  useEffect(() => {
    if (caching) return;
    const idsByDate = filterIdsByDate(pathIdsByDate, dates);
    setFilteredByDate(idsByDate);
  }, [pathIdsByDate, caching, dates]);

  useEffect(() => {
    if (caching) return;
    const idsByLen = filterIdsByLen(pathIdsByLen, minPathLen);
    setFilteredByLen(idsByLen);
  }, [pathIdsByLen, caching, applyFilters]);

  useEffect(() => {
    if (caching) return;
    const updateFilterObj = createFilterObj(filterObj, false);

    const idsByTiers = filterIdsByTouchPoint(pathIdsByTiers, updateFilterObj, pathIdsByDate);
    setFilteredPathIds(idsByTiers);
  }, [pathIdsByTiers, pathIdsByDate, caching, applyFilters]);

  useEffect(() => {
    if (caching || !filteredPathIds || !filteredByDate || !filteredByLen) return;
    const intersectionIds = intersection(filteredByDate, filteredByLen, filteredPathIds);
    setFinalPathIds(intersectionIds);
    setApplyFilters(false);
  }, [filteredPathIds, filteredByDate, filteredByLen]);

  useEffect(() => {
    if (caching || !finalPathIds) return;
    if (!finalPathIds.every(id => pathLookup[id])) return;
    const filtered = [];
    const tier1Summary = [{ name: 'First Touch', total: [0, 0, 0] }, { name: 'Middle Touch', total: [0, 0, 0] }, { name: 'Last Touch', total: [0, 0, 0] }];
    processTableData(finalPathIds, pathLookup, filtered, tier1Summary);
    setTableData(filtered);
    setChannelSummary(tier1Summary);
    const colorMap = generateColorMap(tier1Summary, tierColors);
    setTierColorMap(colorMap);
    setLoading(false);
  }, [finalPathIds]);

  useEffect(() => {
    const data = !channelSummary ? [] : getSummaryByCustomer(customerType, channelSummary);
    setChartData(data);
  }, [customerType, reportType, channelSummary]);

  useEffect(() => {
    if (!reportType || !segId || !parseInt(segId) || !startDate || !endDate) return;
    if (!!loading) {
      time('funnel.funnel_position.view');
      return;
    }
    track('funnel.funnel_position.view', {
      segment_id: parseInt(segId),
      view: reportType,
      start_date: startDate,
      end_date: endDate,
    });
  }, [reportType, segId, startDate, endDate, loading]);

  // eslint-disable-next-line no-shadow
  const onDateChange = ({ startDate, endDate }) => {
    const formatStartDate = startDate;
    const formatEndDate = endDate;

    setStartDate(formatStartDate);
    setEndDate(formatEndDate);
    setFilteredByDate(undefined);
    setCaching(true);
    setLoading(true);
  };

  return (
    <>
      <MainHeader />
      <Filter
        {...{
          pathIdsByTiers,
          loading,
          setLoading, // data
          segsInfo,
          segId,
          setSegId, // conversion segment
          startDate,
          endDate,
          onDateChange, // dates
          hasNtf,
          customerType,
          setCustomerType, // customer type
          reportType,
          setReportType, // report type
          filterTouchPoints,
          filterTouchpointsMethods, // filters
        }}
      />
      {error ? (
        <HandlerBoundary />
      ) : !!isExcluded || (segmentSize > maxSize && !clientsWithOverride.includes(pixel_source_name)) ? (
        <DataWarning header="Not Available" details="This view is not supported for the selected Conversion. Please try another." />
      ) : segmentSize === 0 ? (
        <Segment>
          <NoDataCat message="No Data Found" />
        </Segment>
      ) : reportType === 'mix' ? (
        <LayoutToggles
          chartComponent={(
            <ContentCard title={loading || tableData.length === 0 ? '' : 'Distribution of Channels at each Funnel Stage'}>
              {caching || loading ? (
                <div style={{ height: 200 }}>
                  <Loader active />
                </div>
              ) : tableData.length === 0 ? (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <></>
              ) : (
                <HorizontalStackedBar
                  data={chartData}
                  hideYAxis={false}
                  yAxisWidth={120}
                  yAxisStyle={{ fontWeight: 'bold' }}
                  showTooltip
                  hideKeys={['Unmapped Events']}
                  dataAsRatio={true}
                  alphabetize={true}
                  colors={tierColorMap}
                />
              )}
            </ContentCard>
          )}
          tableComponent={(
            <ContentCard hasTable>
              {caching || loading ? (
                <TreeTableLoader />
              ) : tableData.length === 0 ? (
                <NoDataCat message="No Data Found" />
              ) : (
                <IndexGridTree
                  cols={selectedColumns}
                  allCols={allColumns}
                  data={tableData}
                  orderBy="group"
                  orderDirection="ascending"
                  showSearch
                  searchPlaceholder="Search Channels"
                  rightContent={<CustomizeColumnsButton onClick={() => setColumnsDrawerOpen(true)} />}
                  hideKeys={['Unmapped Events']}
                />
              )}
            </ContentCard>
          )}
        />
      ) : (
        <ContentCard hasTable>
          {caching || loading ? (
            <TreeTableLoader />
          ) : tableData.length === 0 ? (
            <NoDataCat message="No Data Found" />
          ) : (
            <IndexGridTree
              cols={selectedColumns}
              allCols={allColumns}
              data={tableData}
              orderBy="group"
              orderDirection="ascending"
              showSearch
              searchPlaceholder="Search Channels"
              rightContent={<CustomizeColumnsButton onClick={() => setColumnsDrawerOpen(true)} />}
              hideKeys={['Unmapped Events']}
            />
          )}
        </ContentCard>
      )}
      <Drawer openDrawer={columnsDrawerOpen} onDrawerClose={() => setColumnsDrawerOpen(false)}>
        <PopupCard
          title="Customize Columns"
          isCustom={true}
          customKey="display"
          list={selectedColumns}
          originalList={allColumns}
          setOrder={setSelectedColumns}
          icon="none"
          callbackFn={() => setColumnsDrawerOpen(false)}
          excludeFirst={1}
          open={columnsDrawerOpen}
        />
      </Drawer>
    </>
  );
};

const FunnelPositionAccess = () => (
  <ViewAccessWrapper
    viewName="funnel_position"
    viewComponent={<FunnelPosition />}
  />
);

export default withRouter(FunnelPositionAccess);

import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'semantic-ui-react';

const AggregationsModal = ({selected, modalStatus, handleModalClose, handleSubmit}) => {
  const [suffix, setSuffix] = useState("");
  const [tier_1, setTier1] = useState("");
  const [tier_2, setTier2] = useState("");
  const [tier_3, setTier3] = useState("");
  const [tier_4, setTier4] = useState("");

  useEffect(() => {
    setSuffix(selected.suffix);
    setTier1(selected.tier_1);
    setTier2(selected.tier_2);
    setTier3(selected.tier_3);
    setTier4(selected.tier_4);
  }, [selected])

  const prepareObject = () => {
    const postObj = {
      suffix,
      group_by: {
        tier_1,
        tier_2,
        tier_3,
        tier_4
      }
    };

    handleSubmit(postObj)
  }

  return (
    <Modal open={modalStatus} onClose={handleModalClose}>
      <Modal.Header>Aggregation</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form onSubmit={prepareObject}>
            <Form.Input label="Suffix:" value={suffix} onChange={(e,d) => setSuffix(d.value)}/>
            <Form.Group>
              <Form.Input label="Tier 1:" value={tier_1} onChange={(e,d) => setTier1(d.value)}/>
              <Form.Input label="Tier 2:" value={tier_2} onChange={(e,d) => setTier2(d.value)}/>
              <Form.Input label="Tier 3:" value={tier_3} onChange={(e,d) => setTier3(d.value)}/>
              <Form.Input label="Tier 4:" value={tier_4} onChange={(e,d) => setTier4(d.value)}/>
            </Form.Group>
            <Button primary type="submit" content="Submit"/>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default AggregationsModal;

import React, { useEffect, useState } from "react";
import { IndexGridNew, ContentCard } from "@rockerbox/styleguide";
import { datasetAccountingStore } from "./zustandStore";

const datasetSelector = state => [
  state.datasetId,
  state.datasetMetadataHistry,
  state.fetchDatasetMetadataHistry
];

const cols = [
  { display: "Date", key: "date" },
  { display: "Spaces Cache Id", key: "spaces_cache_id" },
  { display: "Number of Rows", key: "num_rows" },
  { display: "Timestamp", key: "updated_at" }
];

const ExpandableComponent = ({ item: { date } }) => {
  const [
    datasetId,
    datasetMetadataHistry,
    fetchDatasetMetadataHistry
  ] = datasetAccountingStore(datasetSelector);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (date) {
      setLoading(true);
      fetchDatasetMetadataHistry(date).finally(() => setLoading(false));
    }
  }, [datasetId]);
  return (
    <ContentCard title="Metadata History" loading={loading}>
      <IndexGridNew
        loading={loading}
        data={datasetMetadataHistry}
        cols={cols}
        collapsed
      />
    </ContentCard>
  );
};

const MetaTable = props => {
  const { meta } = props;

  const sortedMeta =
    meta.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    }) || [];
  return (
    <IndexGridNew
      data={sortedMeta}
      cols={cols}
      itemsPerPage={10}
      paginate
      collapsed
      sortable
      sortKey={"date"}
      expandable={true}
      expandedComponent={ExpandableComponent}
      idKey="date"
    />
  );
};

export default MetaTable;

import React, { Component, useState, useEffect } from "react";
import moment from "moment";
import { ContentCard, PagedIndexGrid, IndexGrid } from "@rockerbox/styleguide";
import { groupByDate } from "./helper";
import SummarySelector from "./SummarySelector";
import ErrorPage from "./ErrorPage";
import CacheContext from "../../utils/CacheContext.js";
import OttTypeSelection, { ottTypesMap } from "./TypeSelection";
import { fetchServedSummary, getSegments, getOttTypes } from "../../utils/api";
import { aggregateSummary } from "./helper";

const cols = [
  { display: "Tier One", key: "tier_one" },
  { display: "Tier Two", key: "tier_two" },
  { display: "Tier Three", key: "tier_three" },
  { display: "Tier Four", key: "tier_four" },
  { display: "Tier Five", key: "tier_five" },
  { display: "Num Days", key: "num_days" },
];

const OttDataSource = (props) => {
  const [ottType, setOttType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [summary, setSummary] = useState({});
  const [creativeData, setCreativeData] = useState({});
  const [expandedKey, setExpandedKey] = useState();
  const [tierKeyDict, setTierKeyDict] = useState({});
  const [activeView, setActiveView] = useState("total");
  const [state, setState, Context] = React.useContext(CacheContext);
  const segments = Context.getCache("segments", getSegments) || [];
  const ottTypes = Context.getCache("ottTypes", getOttTypes) || null;

  const startDate =
    props.startDate || moment.utc().utcOffset(-5).subtract(8, "days").format("YYYY-MM-DD");
  const endDate =
    props.endDate || moment.utc().utcOffset(-5).subtract(1, "days").format("YYYY-MM-DD");

  const allPageSegmentId = segments
    .filter((item) => item.action_name === "All Pages")
    .map((item) => item.filter_id)[0];

  const tableData = (creativeData && creativeData[activeView]) || [];

  const apiDataAsync = async (type) => {
    try {
      setLoading(true);
      setError(false);
      const data = await fetchServedSummary(
        `${type}_event_summary`,
        startDate,
        endDate,
        allPageSegmentId
      );

      const { tiers: tableData, counts, detailDict } = aggregateSummary(
        data,
        ottType
      );
      setSummary(counts);
      setTierKeyDict(detailDict);
      setCreativeData(tableData);
      setLoading(false);
      setError(false);
    } catch (e) {
      console.log(e);
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ottTypes == null) {
      return;
    }
    if (ottTypes["ott_types"] && ottTypes["ott_types"].length > 0) {
      setOttType(ottTypesMap[ottTypes["ott_types"][0]]);
    } else {
      setError(true);
      setLoading(false);
    }
  }, [ottTypes && ottTypes["ott_types"]]);

  useEffect(() => {
    if (allPageSegmentId && ottType) {
      apiDataAsync(ottType);
    }
  }, [allPageSegmentId, ottType]);

  const expandedSection = ({ item }) => {
    if (item.key !== expandedKey) {
      setExpandedKey(item.key);
      return null;
    }
    const { tier_one, tier_two, tier_three, tier_four, tier_five } = item;

    const newKey = `${tier_one};${tier_two};${tier_three};${tier_four};${tier_five}`;

    const expandedData =
      activeView === "total"
        ? groupByDate(tierKeyDict[newKey])
        : tierKeyDict[newKey] &&
          tierKeyDict[newKey].filter((item) => item.key === activeView);

    const cols = [
      { display: "Date", key: "date" },
      { display: "Count", key: "count" },
    ];

    const sortedData =
      expandedData &&
      expandedData.sort((a, b) => {
        return (
          parseInt(b.date.replace(/\-/g, "")) -
          parseInt(a.date.replace(/\-/g, ""))
        );
      });

    return <IndexGrid data={sortedData} cols={cols} key={activeView} />;
  };

  const datasetList = [
    {
      value: summary["total"] || 0,
      label: "Total",
      type: "total",
    },
    {
      value: summary[`${ottType}_ott_device_events`] || 0,
      label: "OTT Device",
      type: `${ottType}_ott_device_events`,
    },
    {
      value: summary[`${ottType}_web_events`] || 0,
      label: "OTT Web",
      type: `${ottType}_web_events`,
    },
    {
      value: summary[`${ottType}_in_app_events`] || 0,
      label: "OTT Mobile App",
      type: `${ottType}_in_app_events`,
    },
  ];

  if (error) {
    return <ErrorPage />;
  }

  return (
    <React.Fragment>
      {ottTypes && (
        <OttTypeSelection
          ottTypes={ottTypes}
          setOttType={setOttType}
          ottType={ottType}
          style={{ marginBottom: "15px" }}
        />
      )}
      <SummarySelector
        activeView={activeView}
        onClick={(e) => setActiveView(e)}
        datasetList={datasetList}
        loading={loading}
      />
      <ContentCard
        hasTable
        title="OTT Data Source"
        explainer=""
        loading={loading}>
        <PagedIndexGrid
          itemsPerPage={100}
          expandable={true}
          data={tableData}
          cols={cols}
          as={ContentCard.Table}
          expandedComponent={expandedSection}
          idKey="key"
          fallbackMsg="No customer data"
        />
      </ContentCard>
    </React.Fragment>
  );
};

export default OttDataSource;

import create from "zustand";
import * as d3 from 'rockerbox_d3_legacy_clone';
import { getSegments } from "../../utils/api";

const DEFAULTS = {
  segments: null, 
  touchpoint: null, 
  featured: null, 
  segmentOptions: null
}

const modelStore = create((set, get) => ({
  set: (updates) => {},
  getSegments: async (force) => {

    const state = get()
    if (!force && state.segments) return

    const segments = await getSegments();
    const featured = segments.find(row => !!row.featured)
    const touchpoint = segments.find(row => !!row.touchpoints)
    const featuredSegmentId = featured.action_id
    const segmentOptions = segments.map(row => Object.assign({text: row.action_name, value: row.action_id}))


    set({ 
      segments,
      touchpoint,
      featured,
      segmentOptions
    });
  },
  ...DEFAULTS
}));

export default modelStore;

import { useMemo } from 'react';

import { forecastDataSingleCurve } from '../../forecast/forecastData';
import { convertToX, convertToPercent } from '../helpers';

// TODO: build doc string for this hook
export const useTrainingData = (isConversionKPI, trainingData, responseCurves, featureNameMap, startDate, endDate) => {
  const selectedData = useMemo(() => {
    if (!startDate || !trainingData) return [];
    return trainingData.filter(row => row.date >= startDate && row.date <= endDate);
  }, [trainingData, startDate, endDate]);

  const channels = useMemo(() => {
    if (!selectedData.length) return [];
    return Object.keys(selectedData[0]).filter(key => key !== 'date');
  }, [selectedData]);

  const selectedRevData = useMemo(() => selectedData.map(row => {
    const revObj = { date: row.date };
    channels.forEach(channel => {
      const responseCurve = responseCurves[channel];
      if (!responseCurve) return;
      const value = row[channel];
      const predictions = forecastDataSingleCurve(responseCurve, [value]);
      revObj[channel] = predictions[0]; // eslint-disable-line prefer-destructuring
    });
    return revObj;
  }), [selectedData, responseCurves]);

  const selectedROI = useMemo(() => selectedData.map((row, i) => {
    const revRow = selectedRevData[i];
    const { date } = row;
    const roiObj = { date };
    const keys = Object.keys(row).filter(key => key !== 'date');
    keys.forEach(channel => {
      const value = row[channel];
      const revValue = revRow[channel];
      roiObj[channel] = revValue / value || 0;
    });
    return roiObj;
  }), [selectedData, selectedRevData]);

  const timeSeriesDataByDateRange = useMemo(() => {
    if (!selectedData.length) return [];
    /* eslint-disable no-param-reassign */
    const keys = Object.keys(selectedData[0]);

    const timeSeries = selectedData.reduce((accu, row) => {
      keys.forEach(featureName => {
        if (featureName === 'date') return;

        const value = row[featureName];
        if (value < 1) return;

        accu[featureName] = accu[featureName] || [];
        accu[featureName].push(value);
      });
      return accu;
    }, {});

    const asItems = Object.entries(timeSeries)
      .reduce(
        (acc, [featureName, values]) => [
          ...acc,
          {
            channel: featureName,
            display: featureNameMap[featureName] || featureName,
            values,
          },
        ],
        [],
      )
      .map(row => {
        const { channel, values } = row;
        const isImpChannel = channel.endsWith('_IMP')
        const responseCurve = responseCurves[channel];
        row.spend_total = isImpChannel ? 0 : values.reduce((acc, value) => acc + value, 0);

        if (!responseCurve) return row;

        const predictions = forecastDataSingleCurve(responseCurve, values);
        row.revenue = predictions;
        row.roi = values.map((value, i) => predictions[i] / value);
        row.revenue_total = row.revenue.reduce((acc, value) => acc + value, 0);
        row.roi_total = isImpChannel ? 'N/A' : isConversionKPI ? row.revenue_total / row.spend_total : convertToX(row.revenue_total / row.spend_total);
        return row;
      })
      .filter(row => row.revenue_total > 0);

    const { spendTotal, revenueTotal } = asItems.reduce((accu, row) => {
      const { spend_total, revenue_total } = row;
      accu.spendTotal += spend_total;
      accu.revenueTotal += revenue_total;
      return accu;
    }, { spendTotal: 0, revenueTotal: 0 });

    asItems.forEach(row => {
      row.spend_share = convertToPercent(row.spend_total / spendTotal);
      row.effect_share = convertToPercent(row.revenue_total / revenueTotal);
      row.effect_spend_ratio = `${convertToX(row.effect_share / row.spend_share)}X`;
      row.spend_share_label = `${row.spend_share}% (spend)`;
      row.effect_share_label = `${row.effect_share}% ${isConversionKPI ? '(conv)' : '(revenue)'}`;
    });

    return asItems;
  }, [selectedData, responseCurves]);

  /* eslint-enable no-param-reassign */

  return {
    selectedData,
    selectedROI,
    selectedRevData,
    timeSeriesDataByDateRange,
    channels,
  };
};

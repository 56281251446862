import React from 'react';
import { Form, Popup, Icon } from 'semantic-ui-react';
import { SplitLayout } from '@rockerbox/styleguide';
import ActionSelect from './ActionSelect';
import { PaddedLabel } from './form';

const RadioMethodGroup = (props) => (
  <Form.Group grouped required>
    <Form.Field required>
    <label>Method</label>
    <Form.Radio
      onChange={(e, data) => props.handleChange(data, "pixel")}
      checked={props.methodOption === 'pixel'}
      name="pixel"
      label="Event captured by Rockerbox's Pixel"
    />
    <Form.Radio
      onChange={(e, data) => props.handleChange(data, "server")}
      checked={props.methodOption === 'server'}
      name="server"
      label='Event sent to Rockerbox via Webhooks'
    />
    <Form.Radio
      onChange={(e, data) => props.handleChange(data, "batch")}
      checked={props.methodOption === 'batch'}
      name="batch"
      label='Event sent to Rockerbox via Batch Uploads'
    />
    </Form.Field>
  </Form.Group>
)

const MethodOptions = (props) => {
  
  return (
    <React.Fragment>
      <Form.Group widths='equal'>
        {(props.methodOption === 'pixel') && (
          <Popup on='focus' size='tiny' position='right center'
            trigger={
              <ActionSelect key="pixel"
                options={props.pixelEventOptions}
                value={props.events}
                handleChange={props.handleChange}
              />
            }>
            <Popup.Header>
              {`What is a ${props.methodOption} event?`}
            </Popup.Header>
            <Popup.Content>
              { props.artifacts.pixel_event_text }
            </Popup.Content>
          </Popup>
        )}
        {props.methodOption === 'server' && (
          <Popup on='focus' size='tiny' position='right center'
            trigger={
              <ActionSelect key="server"
                options={props.apiEventOptions}
                value={props.events}
                handleChange={props.handleChange}
              />
            }
          >
            <Popup.Header>
              <Icon name='exclamation triangle' />
              {`What is a ${props.methodOption} event?`}
            </Popup.Header>
            <Popup.Content>
              { props.artifacts.api_event_text }
            </Popup.Content>
          </Popup>
        )}
        {props.methodOption === 'batch' && (
          <Popup on='focus' size='tiny' position='right center'
            trigger={
              <ActionSelect key="batch"
                options={props.apiEventOptions}
                value={props.events}
                handleChange={props.handleChange}
              />
            }
          >
            <Popup.Header>
              <Icon name='exclamation triangle' />
              {`What is a ${props.methodOption} event?`}
            </Popup.Header>
            <Popup.Content>
              { props.artifacts.api_event_text }
            </Popup.Content>
          </Popup>
        )}
      </Form.Group>
    </React.Fragment>
  )
}

const ConversionDataSource = (props) => {
  return <>
    <PaddedLabel>Data Source</PaddedLabel>
    <SplitLayout
      leftWidth={8}
      leftContent={<RadioMethodGroup {...props} />}
      rightWidth={8}
      rightContent={<>{ props.methodOption && <MethodOptions {...props} />}</>}
    />
  </>
}

export default ConversionDataSource;

import React from 'react';
import { Form } from 'semantic-ui-react';
import { IndexGrid } from '@rockerbox/styleguide';
import * as Tree from './TreeParts';

export const FormDropdown = ({ options, label, value, onChange, loading }) => (
  <Form.Dropdown
    {...{ options, label, value, onChange, loading }}
    select
    selection
  />
);

const cols = [
  'spend_key',
  'transform_tier_1',
  'transform_tier_2',
  'transform_tier_3',
  'transform_tier_4',
  'transform_tier_5',
].map(key => ({ key, display: key }));

export const TreeRow = ({
  row,
  defaultOpen = 2,
  formatter = {},
  fields = [],
  pos = 0,
  columns = cols,
  controlColumn = false,
  parents = [],
}) => {
  const rowWithParents = { ...row, parents };
  const RowComponent = (
    <Tree.Row style={{ padding: '12px', borderBottom: '1px solid #ddd' }}>
      <Tree.Column flex={1}>
        {row.key}
      </Tree.Column>
      <Tree.Column flex={1} style={{ textAlign: 'right' }}>
        {row.count}
      </Tree.Column>
      {fields.map(field => (
        <Tree.Column flex={1} style={{ textAlign: 'right' }}>
          {formatter[field] ? formatter[field](row[field]) : row[field]}
        </Tree.Column>
      ))}
      {fields.length === 0 && <Tree.Column flex={1} style={{ marginRight: 15 * (1 / 2) * pos }} />}
      {controlColumn && (
        <Tree.Column flex={1} style={{ marginRight: 15 * (1 / 2) * pos }}>
          {controlColumn(rowWithParents)}
        </Tree.Column>
      )}
    </Tree.Row>
  );

  return (
    <Tree.Collapsible
      open={pos < defaultOpen}
      title={RowComponent}
      style={{}}
      useBorder
      alignOnOpen
    >
      {
        row.values && row.values.length > 0 && row.values[0].values
          ? row.values.map(innerRow => (
            <TreeRow
              {...{
                parents: parents.concat([row.key]),
                columns,
                defaultOpen,
                formatter,
                fields,
                row: innerRow,
                pos: pos + 1,
                controlColumn,
              }}
            />
          ))
          : row.values[0].row !== false
            ? <IndexGrid {...{ cols: columns, data: row.values.map(r => r.row) }} />
            : null
      }
    </Tree.Collapsible>
  );
};

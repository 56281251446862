import React, { useEffect } from "react";
import AceEditor from "react-ace";
import { ContentCard } from "@rockerbox/styleguide";
import { redshiftStore } from "./zustandStore";
import { Message } from "semantic-ui-react";

import "brace/mode/json";
import "brace/mode/python";
import "brace/theme/tomorrow";

const redshiftSelector = state => [
  state.setupScript,
  state.fetchRedshiftSetupScript
];
const RedshiftScript = props => {
  const [setupScript, fetchRedshiftSetupScript] = redshiftStore(
    redshiftSelector
  );
  useEffect(() => {
    fetchRedshiftSetupScript();
  }, []);
  return (
    <ContentCard title="Redshift Setup Script">
      <Message info>
        <Message.Header>
          Please review and run below python script with AWS admin credentials.
        </Message.Header>
        <Message.Content>
          This will grant rockerbox necessary permission to finish Redshift
          Warehouse Setup
        </Message.Content>
        <Message.Content>
          Please refresh this page when you finish running below script
        </Message.Content>
      </Message>
      <AceEditor
        mode="python"
        theme="tomorrow"
        setOptions={{
          highlightGutterLine: false,
          showPrintMargin: false,
          showGutter: true,
          highlightActiveLine: false,
          fontSize: 14,
          wrapEnabled: true
        }}
        width={"100%"}
        height={"530px"}
        value={setupScript}
        defaultValue={"test"}
        style={{ marginTop: 15 }}
        onChange={() => {}}
      />
    </ContentCard>
  );
};

export default RedshiftScript;

import React, { Component } from 'react';
import { Modal, Form, Input, Button } from 'semantic-ui-react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateModal = props => (
  <Modal size="tiny" open={props.modalOpen} onClose={props.handleClose}>
    <Modal.Header>Select Custom Date Range</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <Form onSubmit={props.handleClose}>
          <Form.Group widths='equal'>
            <Form.Field>
              <label>Start Date</label>
              <DatePicker
                customInput={<Input fluid icon='calendar alternate'/>}
                selected={moment(props.startDate)}
                onChange={(e) => props.handleChange(e, "startDate")}
                minDate={moment.utc().utcOffset(-5).subtract(120, 'days')}
                maxDate={moment.utc().utcOffset(-5).subtract(1, 'days')}
                showDisabledMonthNavigation
              />
            </Form.Field>
            <Form.Field>
              <label>End Date</label>
              <DatePicker
                customInput={<Input fluid icon='calendar alternate'/>}
                selected={moment(props.endDate)}
                onChange={(e) => props.handleChange(e, "endDate")}
                minDate={moment.utc().utcOffset(-5).subtract(120, 'days')}
                maxDate={moment.utc().utcOffset(-5).subtract(1, 'days')}
                showDisabledMonthNavigation
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <Button fluid type="submit">Apply</Button>
          </Form.Field>
        </Form>
      </Modal.Description>
    </Modal.Content>
  </Modal>
)

export default DateModal;

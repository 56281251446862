import React, { useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { DownloadIcon } from '@heroicons/react/solid';
import { useAtom } from 'jotai';
import { Table, Button } from 'semantic-ui-react';

import { processReport } from './downloadReportHelpers';
import { track } from '../../../../utils/tracking';
import { eventTypeAtom, relativeDateAtom } from '../../../atoms';
import { useGlobalState } from '../../../hooks/global';
import { getStartAndEndDate } from '../helpers';

const DownloadReportCell = ({ item }) => {
  const [loading, setLoading] = useState(false);
  const [downloads, setDownloads] = useState({});

  // api call parameters
  const [eventType] = useAtom(eventTypeAtom);
  const [date] = useAtom(relativeDateAtom);
  const { action } = item;
  const { account } = useGlobalState();

  // other
  const disable = action === 'view' || action === 'identify';
  const hasDownloads = Object.keys(downloads).length > 0;

  const handleClick = async () => {
    setLoading(true);
    const { startDate, endDate } = getStartAndEndDate(date);
    const { pixel_source_name } = account;

    track('data.event_status.download', {
      advertiser: account.pixel_source_name,
    });

    const downloadResponse = await processReport(eventType, pixel_source_name, startDate, endDate, action);
    setDownloads(downloadResponse);
    setLoading(false);
  };

  return (
    <Table.Cell>
      {!hasDownloads && (
        <Button
          inverted
          color="purple"
          style={{ padding: 10, fontSize: 14 }}
          className="remove-box-shadow"
          onClick={handleClick}
          disabled={disable}
          loading={loading}
        >
          <DownloadIcon style={{ display: 'inline-block', height: 20 }} />
        </Button>
      )}
      {loading && <span>please wait, download may take a few minutes</span>}
      {(!loading && hasDownloads && downloads.processed === 'error') && <p style={{ color: 'red' }}>{downloads.message}</p>}
      {(!loading && hasDownloads && downloads.processed === 'success') && (
        <>
          {downloads.successfulUploads.map(successfulUpload => <a href={successfulUpload.url}>Get file here</a>)}
          {downloads.failedUploads.map(failedUpload => <p style={{ color: 'red' }}>{failedUpload.message}</p>)}
        </>
      )}
    </Table.Cell>
  );
};

export default DownloadReportCell;

import React from 'react';

import { Grid, Button, Segment, Header } from 'semantic-ui-react';

import { InviteUsers } from '../../../V3/components';
import InviteUsersForm from '../../../V3/components/InviteUsers/InviteUsersForm';

export const InviteUsersWidget = ({ header, subheader }) => (
  <Segment
    basic
    className="invite-users-widget"
    textAlign="left"
  >
    <Header as="h5">
      {header}
      { subheader
          && (
          <Header.Subheader>
            {subheader}
          </Header.Subheader>
          )}
    </Header>
    <InviteUsersForm {...{ handleManageUpdate: () => {} }} />
  </Segment>
);

const InviteUsersView = ({ completeStep }) => (
  <div className="onboarding-invite-view">
    <Grid
      columns={1}
    >
      <Grid.Row>
        <Grid.Column>
          <Segment
            basic
            textAlign="center"
          >
            <Header as="h1">
              Invite Users
              <Header.Subheader>
                Add other members of your team to Rockerbox.
                <br />
                You&apos;ll be able to share dashboards and collaborate on marketing insights.
              </Header.Subheader>
            </Header>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3}>
        <Grid.Column width={3} />
        <Grid.Column width={10}>
          <InviteUsers hideTitle />
          <div className="button-group">
            <Button
              size="large"
              fluid
              inverted
              color="purple"
              className="m-t-25 m-b-30"
              onClick={completeStep}
            >
              Skip
            </Button>
            <div style={{ width: '50px' }} />
            <Button
              size="large"
              fluid
              color="purple"
              className="m-t-25 m-b-30"
              onClick={completeStep}
            >
              Next
            </Button>
          </div>
        </Grid.Column>
        <Grid.Column width={3} />
      </Grid.Row>
      <Grid.Row />
    </Grid>
  </div>
);

export default InviteUsersView;

const specificWordsFormat = str => {
  if (str.toLowerCase() === 'adwords') return 'Google';
  if (str.toLowerCase() === 'nonbrand') return 'Non-Brand';
  if (str.toLowerCase() === 'nonbrand_search') return 'Non-Brand Search';
  if (str.toLowerCase() === 'brand_search') return 'Brand Search';
  if (str.toLowerCase() === 'pmax') return 'Performance Max';
  if (str.toLowerCase() === 'youtube') return 'YouTube';
  if (str.toLowerCase() === 'tiktok') return 'TikTok';
  if (str.toLowerCase() === 'linkedin') return 'LinkedIn';
  if (str.toLowerCase() === 'dr') return 'Direct Response';
  return str;
};

export const titleCase = str => str.toLowerCase().split(' ').map(word => {
  const fmt = specificWordsFormat(word);
  try {
    return fmt[0].toUpperCase() + fmt.substring(1);
  } catch {
    return fmt;
  }
}).join(' ');

const formatChannelDisplayName = str => titleCase(str.replace(/(_S)$/, '').replace(/(_IMP)$/, '').replace(/_/g, ' '));

export const buildChannelTactics = features => {
  if (!features) return [];
  const channels = features
    .map(x => {
      const platform = x.split('_')[0].toLowerCase();
      const name = formatChannelDisplayName(x);
      const [channel, ...rest] = name.split(' ');
      const tactic = rest.join(' ');
      return {
        name,
        channel,
        tactic: tactic || 'Ads',
        platform,
        value: x,
      };
    });
  return channels;
};

export const roundNumber = (num, decimals = 0) => {
  if (num === false) return '';
  const scale = 10 ** decimals;
  return Math.round(num * scale) / scale;
};

// https://tutorial.math.lamar.edu/classes/calciii/curvature.aspx
export const calcMaxCurvature = curve => {
  // remove noisy points at both ends
  const oneThird = Math.floor(curve.length / 3);
  const data = curve.slice(oneThird, -oneThird);

  // calculate first and second orders derivatives
  const x = data.map(({ spend_input }) => spend_input);
  const y = data.map(({ marginal_response }) => marginal_response);
  const dx = x.slice(1).map((xi, i) => (xi - x[i]));
  const dy = y.slice(1).map((yi, i) => (yi - y[i]) / dx[i]);
  const ddy = dy.slice(1).map((dyi, i) => (dyi - dy[i]) / dx[i]);

  // find the curvature at each point
  const curvature = ddy.map((ddyi, i) => {
    const yPrime = dy[i];
    const denominator = (1 + yPrime ** 2) ** (3 / 2);
    return Math.abs(ddyi) / denominator;
  });

  // find the "curviest" point
  const curviestIdx = curvature.reduce((idxMax, _x, i) => (_x > curvature[idxMax] ? i : idxMax), 0);
  const curviestX = x[curviestIdx];
  const curviestY = y[curviestIdx];
  const curviestRoas = curviestY / curviestX;

  return [curviestX, curviestY, curviestRoas];
};

/**
 * Find closest point on curve to a given value
 * @param {Array} curve - Array of objects containing values for key
 * @param {String} key - Key to use for values in curve
 * @param {Number} value - External value to find closest point to
 * @returns {Number} - Value of closest point on curve
 */
export const findClosestPoint = (curve, key, value) => {
  const closestValue = curve.reduce((prev, curr) => {
    const currValue = curr?.[key] || 0;
    return Math.abs(currValue - value) < Math.abs(prev - value) ? currValue : prev;
  }, 0);
  return closestValue || 0;
};

// calculate percentage change between two values
export const calcPercentChange = (a, b) => (b - a) / a * 100; // eslint-disable-line no-mixed-operators

// calculate percentage difference between two values
export const calcPercentDiff = (a, b) => Math.abs((a - b) / ((a + b) / 2));

// parse artifacts from API response into objects
export const parseArtifacts = artifacts => {
  if (!artifacts || Object.keys(artifacts).length === 0) return;
  return Object.entries(artifacts).reduce((accu, [k, v]) => {
    try {
      accu[k] = JSON.parse(v);
    } catch (e) {
      accu[k] = v;
    }
    return accu;
  }, {});
};

export const featuresToNameMap = features => features?.reduce((acc, x) => Object.assign(acc, { [x.value]: x.name }), {});

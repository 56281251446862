import React, { useMemo } from 'react';
import { useAtom } from 'jotai';
import { Button, Divider } from 'semantic-ui-react';
import { layoutOptionAtom } from '../atoms';
import {
  ChartTableActive, TableChartActive, ChartOnlyActive, TableOnlyActive,
  ChartTableInactive, TableChartInactive, ChartOnlyInactive, TableOnlyInactive,
} from '../assets/LayoutToggleIcons';
import { track } from '../../utils/tracking';

const LayoutToggles = ({ chartComponent, tableComponent }) => {
  const [layoutSelected, setLayoutSelected] = useAtom(layoutOptionAtom);

  const renderLayout = useMemo(() => {
    switch (layoutSelected) {
      case 0:
        return (
          <>
            {chartComponent}
            {tableComponent}
          </>
        );
      case 1:
        return (
          <>
            {tableComponent}
            {chartComponent}
          </>
        );
      case 2:
        return chartComponent;
      case 3:
        return tableComponent;
      default:
        return (
          <>
            {chartComponent}
            {tableComponent}
          </>
        );
    }
  }, [layoutSelected, chartComponent, tableComponent]);

  const onToggleClick = optionSelected => {
    setLayoutSelected(optionSelected);
    switch (optionSelected) {
      case 0:
        track('graph.toggle.graphtable');
        break;
      case 1:
        track('graph.toggle.tablegraph');
        break;
      case 2:
        track('graph.toggle.graph');
        break;
      case 3:
        track('graph.toggle.table');
        break;
      default:
        break;
    }
  };

  const getLayoutToggleSelected = (activeIcon, inactiveIcon, layoutNumber) => (layoutNumber === Number(layoutSelected) ? activeIcon : inactiveIcon);

  return (
    <div>
      <Divider horizontal style={{ marginBottom: 20 }}>
        <Button.Group>
          <Button basic icon onClick={() => onToggleClick(0)}>
            {getLayoutToggleSelected(<ChartTableActive />, <ChartTableInactive />, 0)}
          </Button>
          <Button basic icon onClick={() => onToggleClick(1)}>
            {getLayoutToggleSelected(<TableChartActive />, <TableChartInactive />, 1)}
          </Button>
          <Button basic icon onClick={() => onToggleClick(2)}>
            {getLayoutToggleSelected(<ChartOnlyActive />, <ChartOnlyInactive />, 2)}
          </Button>
          <Button basic icon onClick={() => onToggleClick(3)}>
            {getLayoutToggleSelected(<TableOnlyActive />, <TableOnlyInactive />, 3)}
          </Button>
        </Button.Group>
      </Divider>
      {renderLayout}
    </div>
  );
};

export default LayoutToggles;

import React from 'react';

import { ContentCard } from '@rockerbox/styleguide';
import { Icon, Header } from 'semantic-ui-react';

const GenericNoDataMessage = ({ callToAction, emptyIcon = 'info', fallbackMsg = 'No data found' }) => (
  <ContentCard style={{ textAlign: 'center' }}>
    <Icon
      circular
      disabled
      name={emptyIcon}
      size="big"
      className="m-b-10"
    />
    <Header
      as="h3"
      disabled
      content={fallbackMsg}
      className="m-0"
    />
    {callToAction && <div style={{ marginTop: 8 }}>{callToAction}</div>}
    <br />
  </ContentCard>
);

export default GenericNoDataMessage;

import { getFacebookAutoParamsErrors } from '../../api/attribution';
import { parseFbErrors, determineHasIssues } from '../../utils/trackingQa';

export const processPlatformData = (platform, authorizations, trackingQAData) => {
  if (platform === 'facebook' && authorizations) {
    const fbAuths = authorizations.find(x => x.platform === 'facebook')?.authorizations;
    if (!fbAuths) return Promise.resolve(null);

    const fbAccountIds = fbAuths.map(x => x.account_id);
    const fbPromises = fbAccountIds.map(accountId => getFacebookAutoParamsErrors(accountId));

    return Promise.all(fbPromises)
      .then(responses => parseFbErrors(responses))
      .then(allFbErrors => {
        if (!allFbErrors.length) return null;
        return {
          facebook: {
            hasIssues: true,
            // numMissing: allFbErrors.length,
            errors: allFbErrors,
          },
        };
      });
  }

  const platformQAData = trackingQAData[platform] || {};
  const { has_missing_keys, platform_total } = platformQAData.all || {};
  const percentMissing = (has_missing_keys / platform_total);
  const hasIssues = determineHasIssues(percentMissing);

  return { [platform]: { hasIssues, percentMissing } };
};

export const createAdvertisingIntegrationsList = integrations => {
  const list = integrations
    .filter(integration => integration?.category?.includes('advertising'))
    .map(integration => integration.platform);

  return [...new Set(list)];
};

export const determineConnectedAccounts = (authorizations, advertisingIntegrations) => {
  const accountsList = authorizations.reduce((acc, obj) => {
    const { platform, authorizations: authList } = obj;

    const accounts = authList
      .filter(account => advertisingIntegrations.includes(platform) && !account.batch_platform)
      .map(() => ({ platform_key: platform }));

    return acc.concat(accounts);
  }, []);

  const uniquePlatformKeys = [...new Set(accountsList.map(x => x.platform_key))];
  return uniquePlatformKeys;
};

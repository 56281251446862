import { useMemo } from 'react';

import { filterObjectHelper, checkLastValueEndsWith } from './helpers';

export const getPlanNames = isConversionKPI => {
  const planNames = useMemo(() => {
    const kpiText = isConversionKPI ? 'Conversions' : 'Revenue';
    const metricText = isConversionKPI ? 'CPA' : 'ROAS';
    return (
      {
        max_kpi: `${kpiText} Maximization`,
        reduce_budget: 'Budget Reduction',
        expand_budget: 'Budget Expansion',
        target_metric: `${metricText} Target`,
      }
    );
  }, [isConversionKPI]);

  return {
    planNames,
  };
};

export const useFilteredData = (featuresRaw, responseCurvesRaw, trainingDataRaw, trainingStatsRaw, filterBy) => {
  const features = useMemo(() => featuresRaw.filter(({ value }) => !checkLastValueEndsWith(value, filterBy)), [featuresRaw]);

  const responseCurves = useMemo(() => {
    if (!responseCurvesRaw) return responseCurvesRaw;
    return filterObjectHelper(responseCurvesRaw, filterBy);
  }, [responseCurvesRaw]);

  const trainingData = useMemo(() => {
    if (!trainingDataRaw) return trainingDataRaw;
    return trainingDataRaw.map(data => filterObjectHelper(data, filterBy));
  }, [trainingDataRaw]);

  const trainingStats = useMemo(() => {
    if (!trainingStatsRaw) return trainingStatsRaw;
    return trainingStatsRaw.filter(({ feature }) => !checkLastValueEndsWith(feature, filterBy));
  }, [trainingStatsRaw]);

  return {
    features,
    responseCurves,
    trainingData,
    trainingStats,
  };
};

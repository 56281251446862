import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Form from './form';

class FacebookAds extends Component {

  state = {
    response: []
  }

  render() {
    const { response } = this.state;
    const { type, id } = this.props.match.params

    const current = response && response.find(row => row.id == id) || {}


    return (
      <React.Fragment>
        { type == "edit" && id && <Form data={current} id={current.filter_id} onSuccess={this.refresh}/>}
        { type == "create" && <Form onSuccess={this.refresh}/>}
      </React.Fragment>
    )
  }
}


export default withRouter(FacebookAds);

import React, { Component } from 'react';
import { ScrollingPopup, ContentCard, IndexGrid, SearchableDropdown } from '@rockerbox/styleguide'
import { Form, Input, Icon, Button, Popup } from 'semantic-ui-react'
import { setSavedBucketUrls, getBucketsTree, getBucketsRuleTree, getCreativeBucketsTree, getCreativeBucketsRuleTree, getAttributableEvents } from '../../utils/api';
import { withRouter } from 'react-router-dom';
import * as Tree from '../TreeParts';
import * as routes from '../../routes';

const defaultMatcher = (filterText, node) => {
  const urlMatches = node.urls && node.urls.filter(url => url.toLowerCase().indexOf(filterText.toLowerCase()) !== -1).length;
  const referrerMatches = node.referrers && node.referrers.filter(referrer => referrer.toLowerCase().indexOf(filterText.toLowerCase()) !== -1).length;
  return (urlMatches||0) + (referrerMatches||0)
};

const findNode = (node, filter, matcher) => {
  return matcher(filter, node) || // i match
    (node.values && // or i have decendents and one of them match
    node.values.length &&
    !!node.values.find(child => findNode(child, filter, matcher)));
};

export const filterTree = (node, filter, matcher = defaultMatcher) => {
  // If im an exact match then all my values get to stay
  if(matcher(filter, node) || !node.values){ return node; }
  // If not then only keep the ones that match or have matching descendants
  const filtered = node.values
    .filter(child => findNode(child, filter, matcher))
    .map(child => filterTree(child, filter, matcher));
  return Object.assign({}, node, { values: filtered });
};

export const leaves = (tree, urls, referrers) => {
  if (tree.values && tree.values.length > 0) {
    return tree.values.reduce((values, subtree) => {
      const { urls, referrers } = leaves(subtree, urls, referrers)
      const oUrls = values.urls || []
      const oReferrers = values.referrers || []

      return { urls: oUrls.concat(urls), referrers: oReferrers.concat(referrers) }
    }, {})
  }
  return { urls: tree.urls, referrers: tree.referrers }
}

const TIERS = ["tier_1","tier_2","tier_3","tier_4","tier_5"]

const nestBy = (data, tier, func=false) => {

  const pos = func ? 0 : TIERS.indexOf(tier)+1;
  const makeKey = (add) => !func || !add ? 
      (row) => TIERS.slice(0, pos + add).map(k => row[k]).join("=") :
      func

  const nested = d3.nest()
    .key(makeKey(0))
    .rollup(values => {
      const first = values[0];
      const urls = Object.keys(values.reduce((p,v) => { p[v.original_url] = 1; return p}, {}))
      const referrers = Object.keys(values.reduce((p,v) => { p[v.request_referrer] = 1; return p}, {}))
      const matches = Array(values.reduce((p,c) => p.add(c.matches.slice(-1)[0]), new Set()))
      const _tiers = TIERS.slice(0, pos).reduce((p,c) => {
        p[c] = first[c]
        return p
      },{})

      const childKeys = Array(...(new Set(values.map(makeKey(1)))))

      return { urls, referrers, matches, num_urls: urls.length, count: values.length, childKeys, ..._tiers }
    })
    .map(data)

  return nested
  
}

class EventsTree extends Component {

  state = {
    loading: false,
    treeData: [],
    filteredTreeData: [],
    ruleTreeData: [],
    filteredRuleTreeData: [],
    searchValue: "",
    organizeBy: "tier"
  }

  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps) {
    const check = ["id", "date"]

    if(check.filter(k => prevProps[k] != this.props[k]).length > 0) {
      this.getData();
    }
  }

  getData = () => {
    const { date } = this.props;
    const bucketsTreeApiCalls = [
      getBucketsTree(this.props.id, date),
      getCreativeBucketsTree(this.props.id, date)
    ];
    const bucketsRuleTreeApiCalls = [
      getBucketsRuleTree(this.props.id, date),
      getCreativeBucketsRuleTree(this.props.id, date).catch( (e) => [])
    ];
    this.setState({ loading: true });

    Promise.all(bucketsTreeApiCalls)
      .then( (treeData)  => {
        treeData = treeData.reduce( (x,y) => x.concat(y) )
          .map(row => {
            try { row.match = row.matches.slice(-1)[0] } catch(e) {}
            return row
          })

        const outer = TIERS.slice().reverse().reduce((childrenMap,c) => {
          const parentMap = nestBy(treeData, c)
          Object.keys(parentMap).map(key => {
            const obj = parentMap[key]
            const { childKeys } = obj
            obj['values'] = childKeys.map(key => childrenMap[key])
          })
          return parentMap
        },{})

        // console.log(outer)

        const parentMap = nestBy(treeData, "tier_1", row => row.match)
        Object.keys(parentMap).map(key => {
          const obj = parentMap[key]
          const { childKeys } = obj
          obj['match'] = obj['tier_1']
          obj['values'] = childKeys.map(key => outer[key])
        })

        const filteredRuleTreeData = Object.values(parentMap);
        const filteredTreeData = Object.values(outer);

        this.setState({ treeData, filteredTreeData, filteredRuleTreeData, loading: false });
      })
      .catch((err) => console.log(err));

    //Promise.all(bucketsRuleTreeApiCalls)
    //  .then( (ruleTreeData)  => {
    //    ruleTreeData = ruleTreeData.reduce( (x,y) => x.concat(y) );
    //    const filteredRuleTreeData = ruleTreeData;
    //    this.setState({ ruleTreeData, filteredRuleTreeData });
    //  });
    getAttributableEvents()
      .then( attributable_events  => {
        this.setState({ attributable_events });
      });
  }

  handleClick = (e, { name }) => this.setState({ organizeBy: name })
  handleEditAction = (e, { name, value, rule_type }) => {

    const { filteredTreeData } = this.state;
    const { push } = this.props.history;

    const editRouteMapping = {
      mapping: routes.editMappedEvent,
      exclude: routes.editExcludedEvent
    }
    const toSave = leaves({"values":filteredTreeData}, [], []);
    setSavedBucketUrls(toSave)
      .then(_id => {
        if (editRouteMapping[rule_type]) push(editRouteMapping[rule_type] + `/${value}/${_id}`)
      })
  }
  handleAction = (e, { name, value, type }) => {
    const { filteredTreeData } = this.state;
    const { push } = this.props.history;

    const routeMapping = {
      mapping: routes.createMappedEvent,
      exclude: routes.createExcludedEvent
    }
    const toSave = leaves({"values":filteredTreeData}, [], []);

    setSavedBucketUrls(toSave)
      .then(_id => {
        if (routeMapping[name]) push(routeMapping[name] + `/${_id}`)
      })
  }

  filterData = (evt, objdata) => {
    const { ruleTreeData, treeData } = this.state;
    const { value } = objdata;
    const searchValue = value;
    if (value == '') {
      return this.setState({ filteredTreeData: treeData, filteredRuleTreeData: ruleTreeData, searchValue })
    }

    const v = filterTree({"values":treeData}, searchValue)
    const r = filterTree({"values":ruleTreeData}, searchValue)

    return this.setState({
      filteredTreeData: v.values || [],
      filteredRuleTreeData: r.values || [],
      searchValue
    })
  }

  buildRow = (row, tiers, pos=0, forceOpen=false) => {
    if (row == undefined) return null;

    const RefPopup = row['referrers'].length > 11 ? ScrollingPopup : Popup
    const UrlPopup = row['urls'].length > 11 ? ScrollingPopup : Popup

    const RowComponent = (
      <Tree.Row style={{padding:"12px",borderBottom:"1px solid #ddd"}}>
        <Tree.Column flex={4}> { row[tiers[pos]] == "" ? "<blank>" : row[tiers[pos]] } </Tree.Column>
        <Tree.Column style={{textAlign:"left"}}> { row['count'] } </Tree.Column>
        <Tree.Column style={{textAlign:"left"}}> { row['values'] ? row.values.length : "-" } </Tree.Column>
        <Tree.Column style={{textAlign:"left"}}> { row['buckets'] ? row.buckets : "-" } </Tree.Column>
        <Tree.Column style={{textAlign:"left"}}>
          { row['urls'].length ?
              <UrlPopup
                hoverable
                header="Urls"
                position="left center"
                trigger={ <div><Icon name="eye" /> { row['num_urls'] }</div> }
                content={row['urls'].map(v => <div style={{maxWidth:"500px",wordWrap:"break-word"}}>{v}</div>)}
              /> :
              row['num_urls']
          }
        </Tree.Column>
        <Tree.Column style={{textAlign:"left"}}>
          { row['referrers'].length ?
              <RefPopup
                hoverable
                header="Referrers"
                position="left center"
                trigger={<div><Icon name="eye" /> { row['num_referrers'] }</div>}
                content={row['referrers'].map(v => <div style={{maxWidth:"500px",wordWrap:"break-word"}}>{v}</div>)}
              /> :
              row['num_referrers']
          }
        </Tree.Column>
        <Tree.Column style={{textAlign:"left"}}>
          {
            row['matches'].length == 1 ?
              <Button size='mini' compact circular style={{overflow:"hidden",textOverflow: "ellipsis", whiteSpace: "nowrap", width:"120px"}}>{ row['matches'][0] }</Button> :
              <Popup
                hoverable
                header="Rules"
                position="left center"
                trigger={<div><Icon name="eye" /> { row['matches'].length }</div>}
                content={row['matches'].map(v => <div style={{maxWidth:"500px",wordWrap:"break-word"}}>{v}</div>)}
              />
          }
        </Tree.Column>
        <Tree.Column>
        </Tree.Column>
      </Tree.Row>
    )

    if (row.buckets == 1 ) return RowComponent
    return (
      <Tree.Collapsible title={RowComponent} open={forceOpen} style={{}} useBorder>
        {
          (tiers.length-1 == pos) ?
            (<pre> { JSON.stringify(row.values,0,2) }</pre>) :
            row.values && row.values.sort((p,c) => c.count - p.count).map(_row => this.buildRow(_row,tiers,pos+1, forceOpen))
        }
      </Tree.Collapsible>
    )
  }

  render() {

    const { loading, attributable_events, filteredTreeData, filteredRuleTreeData, searchValue, organizeBy } = this.state;
    const forceLoading = loading;
    const { date } = this.props;
    const title = "Mapped Events";

    const dataCols = filteredTreeData.length ? [
      { display: "Tier 1", key: "tier_1", as: IndexGrid.EllipsedCell, headerWidth: 7},
      { display: "Count", key: "count", headerWidth: 2}
    ] : [];

    const TIERS = ["tier_1","tier_2","tier_3","tier_4","tier_5"]
    const editOptions = (attributable_events||[]).map(_event => {
      const rule_type = _event.rewrite_tier_1 == "" ? "exclude" : "mapping";
      return { key: _event.id, text: _event.name, value: _event.id, rule_type }
    })

    const classification = organizeBy == "tier" ? "Tier" : "Rule";
    const tiers = organizeBy == "tier" ? TIERS : ["match", ...TIERS];
    const data = organizeBy == "tier" ? filteredTreeData: filteredRuleTreeData;

    return (
      <React.Fragment>
        <Tree.Row>
          <Tree.Column>
            <Input icon='search'  type="text" placeholder="Find an event" value={searchValue} onChange={this.filterData} />
          </Tree.Column>
          <Tree.Column flex={10}>
            { searchValue.length > 0 &&
              <Form.Group style={{marginLeft:"10px"}}>
                <Button name="mapping" onClick={this.handleAction}>Create New Rule</Button>
                <Button name="exclude" onClick={this.handleAction}>Create Exclusion</Button>
                <SearchableDropdown options={editOptions} onSelect={this.handleEditAction} title="Choose Rule">
                  <Button icon="caret down" labelPosition='right' content="Edit Existing" />
                </SearchableDropdown>

              </Form.Group>
            }
          </Tree.Column>
          <Tree.Column>
            <Button.Group style={{marginLeft:"10px"}}>
              <Button name="tier" positive={organizeBy == "tier"} onClick={this.handleClick}>Tier</Button>
              <Button.Or />
              <Button name="rule" positive={organizeBy == "rule"} onClick={this.handleClick}>Rule</Button>
            </Button.Group>
          </Tree.Column>
        </Tree.Row>
        <ContentCard title={`${title} (${date})`} hasTable {...{forceLoading}} >
          <Tree.Wrapper style={{marginBottom:"10px"}}>
            <Tree.Row style={{padding:"12px",borderBottom:"1px solid #ddd",background:"#F9FAFB",fontWeight:"bold"}}>
              <Tree.Column flex={4}> { classification } </Tree.Column>
              <Tree.Column> Matching Events </Tree.Column>
              <Tree.Column> Next Level </Tree.Column>
              <Tree.Column> All Levels </Tree.Column>
              <Tree.Column> Urls </Tree.Column>
              <Tree.Column> Referrers </Tree.Column>
              <Tree.Column> Rule </Tree.Column>
              <Tree.Column> </Tree.Column>
            </Tree.Row>
            {
              data.sort((p,c) => c.count - p.count).map(row => {
                return this.buildRow(row, tiers, 0, searchValue != "")
              })
            }
          </Tree.Wrapper>
        </ContentCard>
      </React.Fragment>
    )
  }
}
export default withRouter(EventsTree);

import React from 'react';

import { HatchableBar } from '@rockerbox/styleguide';

const COLORS = [
  '#4f64de',
  '#47aadc',
  '#60e1d0',
  '#3eda7f',
  '#ffa64d',
  '#dc4749',
  '#b847dc',
  '#6847dc',
  '#477cdc',
  '#47d0dc',
  '#36f29b',
  '#f0df4d',
  '#de5f31',
  '#dc47cb',
  '#9247dc',
];

export const generateColors = numColors => [...Array(numColors)].map((_, i) => COLORS[i % COLORS.length]);

export const formatPlatformName = platform => platform
  .replace('_v1', '')
  .replace('_v2', '')
  .replace('_v3', '')
  .replace('_uac', '')
  .replace('_batch', '')
  .replace('_spend', '')
  .replace('_standard', '')
  .replace('_custom', '')
  .replace('_site_served', '')
  .replace('_dcm', '')
  .replace('_dv360', '')
  .replace('-dsp', '')
  .replace('_dsp', '')
  .replace('_dfp', '')
  .replace('-core', '')
  .replace('-custom', '')
  .replace('-national', '')
  .replace('_postlog', '_linear_tv')
  .replace('_linear_linear_', '_linear_')
  .replace('entity', 'sponsorships');

export const composeHatchableBar = failureDates => ({ payload, fill, x, y, width, height }) => {
  const { date } = payload;
  const isHatched = failureDates.includes(date);
  return <HatchableBar fill={fill} x={x} y={y} width={width} height={height} hatched={isHatched} />;
};

const EXEMPT_KEYS = ['date', 'conversions', 'revenue'];

export const transformData = (origData, failureDates) => origData.map((entry, index) => {
/**
 * Transforms an array of data entries by appending a suffix to each key (excluding exempt keys) based on
 * whether the entry's date matches any date in the `failureDates` array. Each entry is also augmented with
 * a boolean flag `isTransitionPoint` indicating whether the current entry represents a transition between
 * valid and invalid states based on `failureDates`. This is neccessary to connect the solid and dotted line
 *
 *
 * @param {Array} origData - The original array of data objects to be transformed. Each object must at least
 *                           contain a `date` property.
 * @param {Array} failureDates - An array of dates (in the same format as `date` property of `origData` entries)
 *                               used to determine which entries are considered 'bad' or failing.
 * @returns {Array} A new array of data objects where each object has been modified to include:
 *                  1. An `isTransitionPoint` property indicating a transition in 'bad' status relative to
 *                     its immediate neighbor.
 *                  2. A suffix (`_dotted` or `_undotted`) appended to each key (except those in `EXEMPT_KEYS`)
 *                     indicating whether the entry is 'bad' according to `failureDates`.
 *
 * @example
 * // Assuming EXEMPT_KEYS = ['date', 'roas', 'cpa', 'conversions', 'revenue'];
 * const data = [{ date: '2023-03-25', spend: 100 }, { date: '2023-03-26', spend: 150 }];
 * const failureDates = ['2023-03-26'];
 * const transformed = transformData(data, failureDates);
 * // Returns:
 * // [
 * //   { date: '2023-03-25', spend_undotted: 100, isTransitionPoint: true },
 * //   { date: '2023-03-26', spend_dotted: 150, isTransitionPoint: true }
 * // ]
 */
  const isCurrentBad = failureDates.includes(entry.date);
  const isNextBad = index < origData.length - 1 && failureDates.includes(origData[index + 1].date);
  const isTransitionPoint = isCurrentBad !== isNextBad;
  const newEntry = { ...entry, isTransitionPoint };

  Object.keys(entry).forEach(key => {
    if (!EXEMPT_KEYS.includes(key)) {
      const suffix = isCurrentBad ? '_dotted' : '_undotted';
      newEntry[`${key}${suffix}`] = entry[key];
    }
  });
  return newEntry;
});

export const containsDottedValues = data => {
  if (data.length === 0 || !data[0].payload) {
    return false;
  }

  const firstPayload = data[0].payload;
  return 'roas_dotted' in firstPayload || 'cpa_dotted' in firstPayload;
};

import React, { useMemo } from 'react';
import { useAtom } from 'jotai';
import { topTabAtom } from '../../../atoms';
import { formatChartData } from '../helpers';
import { numberFormatter, currencyFormatter, percentFormatter } from '../../../utils/valueFormatter';

export const getChartData = (tier1SummaryByEvent, tiersSummaryTotals, funnelConfiguration, conversionKey, currentSegments, currencyCode) => {
  const [selectedChartMetric] = useAtom(topTabAtom);

  const missingData = !conversionKey || !funnelConfiguration || !funnelConfiguration.length || !tiersSummaryTotals || !currentSegments || !currentSegments.length;

  const chartData = useMemo(() => {
    if (missingData) return [];
    const data = formatChartData(tier1SummaryByEvent, funnelConfiguration, currentSegments, conversionKey, selectedChartMetric);
    return data;
  }, [tier1SummaryByEvent, currentSegments, conversionKey, selectedChartMetric]);

  const tooltipValueFormatter = useMemo(() => {
    if (selectedChartMetric === 'conversions') return numberFormatter;
    if (selectedChartMetric === 'cpa') return currencyFormatter(currencyCode, 2);
  }, [selectedChartMetric]);

  const conversionTotalsByEvent = useMemo(() => {
    if (missingData) return [];
    return funnelConfiguration.map(id => {
      const conversionKeyName = `${id}_${conversionKey}`;
      return tiersSummaryTotals[conversionKeyName] ? tiersSummaryTotals[conversionKeyName] : 0;
    });
  }, [conversionKey, funnelConfiguration, tiersSummaryTotals]);

  const spendTotalsByEvent = useMemo(() => {
    if (missingData) return [];
    return funnelConfiguration.map(id => {
      const spendKeyName = `${id}_spend`;
      return tiersSummaryTotals[spendKeyName] ? tiersSummaryTotals[spendKeyName] : 0;
    });
  }, [conversionKey, funnelConfiguration, tiersSummaryTotals]);

  const cpaByEvent = useMemo(() => {
    if (conversionTotalsByEvent.length !== spendTotalsByEvent.length) return [];
    return spendTotalsByEvent.map((spend, i) => (conversionTotalsByEvent[i] ? spend / conversionTotalsByEvent[i] : 0));
  }, [conversionTotalsByEvent, spendTotalsByEvent]);

  const summaryDataByEvent = useMemo(() => {
    if (!cpaByEvent || !cpaByEvent.length) return [];
    const summaryData = [];
    const convEventLength = conversionTotalsByEvent.length;
    for (let i = 0; i < convEventLength; i++) {
      const conversionRates = [];
      const conversionRateDisplayNames = [];

      for (let x = 1; x <= convEventLength; x++) {
        // if (i - x >= 0) { // this logic is for all steps conversion rate
        if (i - x === 0 || (i - x === i - 1 && i - x > 0)) {
          const rate = conversionTotalsByEvent[i] / conversionTotalsByEvent[i - x];
          conversionRates.push(percentFormatter(rate));
          conversionRateDisplayNames.push(`${currentSegments[i - x].action_name} to ${currentSegments[i].action_name} Conversion Rate: `);
        }
      }

      const funnelData = {
        event: currentSegments[i].action_name,
        conversionRates,
        conversionRateDisplayNames,
        conversions: numberFormatter(conversionTotalsByEvent[i]),
        spend: spendTotalsByEvent[i],
        cpa: currencyFormatter(currencyCode, 2)(cpaByEvent[i]),
      };
      summaryData.push(funnelData);
    }
    return summaryData;
  }, [cpaByEvent]);

  const chartSummaryComponents = useMemo(() => summaryDataByEvent.map(e => {
    const { event, conversions, conversionRates, conversionRateDisplayNames, cpa } = e;
    const number = selectedChartMetric === 'cpa' ? cpa : conversions;

    return {
      event,
      heading: <h2>{event.toUpperCase()}</h2>,
      subHeading1: <h2 style={{ color: 'rgb(71, 93, 220)' }}>{number}</h2>,
      subHeading2: (
        <>
          {
            conversionRates.map((rate, i) => (
              <h3 style={{ margin: '5px 0' }}>
                {conversionRateDisplayNames[i]}
                <span style={{ color: 'rgb(71, 93, 220)' }}>{rate}</span>
              </h3>
            ))
          }
        </>
      ),
    };
  }), [summaryDataByEvent, selectedChartMetric]);

  if (!tier1SummaryByEvent) {
    return {
      selectedChartMetric: 'conversions',
      chartData: [],
      tooltipValueFormatter: numberFormatter,
      chartSummaryComponents: [],
    };
  }

  return {
    selectedChartMetric,
    chartData,
    tooltipValueFormatter,
    chartSummaryComponents,
  };
};

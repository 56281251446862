import React, { useEffect, useState } from 'react';

import { LineChart } from '@rockerbox/styleguide';
import { formatXAxisTickLabel } from '@rockerbox/styleguide/components/charts/helpers';
import { Segment, Button, Loader, Message, MessageHeader } from 'semantic-ui-react';

import { useCustomFeedData } from './hooks';
import {
  DataSection,
  REVENUE_SUBHEADING,
  MARKETING_SPEND_SUBHEADING,
  IMP_SUBHEADING,
  EXTRA_SUBHEADING,
} from './parts';
import { getMMMCustomFeedsData, getMMMLockStatus } from '../../../api/attributionModel';
import { getCookieValue } from '../../../utils/cookies';
import { spendFormatter } from '../../../utils/valueFormatter';
import FeatureSelectModal from '../FeatureSelectModal';
import FileUploadModal from '../FileUploadModal';

const ModelRefresh = () => {
  const [activeUpload, setActiveUpload] = useState(false);
  const [MMMlock, setMMMLock] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customFeedData, setCustomFeedData] = useState([]);

  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [featureModalOpen, setFeatureModalOpen] = useState(false);

  const [uploadType, setUploadType] = useState(undefined);

  const formatter = spendFormatter('USD');
  const numberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  };
  const numberFormatter = new Intl.NumberFormat('en-US', numberFormatOptions);

  useEffect(() => {
    setLoading(true);
    getMMMCustomFeedsData().then(data => {
      setCustomFeedData(data);
      setLoading(false);
    });
    getMMMLockStatus()
      .then(({ lock }) => {
        setMMMLock(lock);
      });
    const val = getCookieValue('activeMMMUpload');
    setActiveUpload(val === 'true');
  }, []);

  const {
    chartData,
    tierColorMap,
    spendMetrics,
    impMetrics,
    revenueMetrics,
    extraMetrics,
  } = useCustomFeedData(customFeedData);

  const openModal = type => {
    setUploadType(type);
    setUploadModalOpen(true);
  };

  return (
    <div>
      <div className="model-refresh-body">
        {activeUpload && (
          <Message info size="large">
            <MessageHeader>Please allow up to 5 minutes for your uploaded data to be processed before being displayed.</MessageHeader>
          </Message>
        )}
        {loading ? (
          <Segment className="loader-container">
            &nbsp;&nbsp;&nbsp;
            <Loader active inline size="medium" />
          </Segment>
        )
          : (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div />
                <div className="button-group" style={{ float: 'right' }}>
                  <Button primary disabled={MMMlock} onClick={() => setFeatureModalOpen(true)}>
                    Refresh Model
                  </Button>
                </div>
              </div>
              <DataSection headerText="Revenue" subheaderText={REVENUE_SUBHEADING} metrics={revenueMetrics} formatter={formatter} />
              <Button primary onClick={() => openModal('revenue')}>
                Add Revenue Target
              </Button>
              <DataSection headerText="Marketing Spend" subheaderText={MARKETING_SPEND_SUBHEADING} metrics={spendMetrics} formatter={formatter} />
              <Button primary onClick={() => openModal('spend')}>
                Add Channel / Strategy
              </Button>
              <DataSection headerText="Impressions" subheaderText={IMP_SUBHEADING} metrics={impMetrics} formatter={numberFormatter} />
              <Button primary onClick={() => openModal('imps')}>
                Add Impression Data
              </Button>
              <DataSection headerText="Extra Features" subheaderText={EXTRA_SUBHEADING} metrics={extraMetrics} formatter={numberFormatter} />
              <Button primary onClick={() => openModal('extra')}>
                Add Extra Feature
              </Button>
              { chartData.length > 0 && (
                <Segment className="chart-container">
                  <LineChart
                    {...{
                      dottedLineForInfinity: true,
                      showAxis: true,
                      data: chartData,
                      height: 400,
                      formatter,
                      XAxisFormatter: formatXAxisTickLabel,
                      tierColorMap,
                      padding: 10,
                      XAxisTickHeight: 15,
                    }}
                    showTooltip
                    showXAxis
                    domainDefault={(dataMax => Math.ceil(dataMax))}
                  />
                </Segment>
              )}
            </>
          )}
      </div>
      <FileUploadModal {...{ open: uploadModalOpen, setOpen: setUploadModalOpen, uploadType }} />
      <FeatureSelectModal {...{ open: featureModalOpen, setOpen: setFeatureModalOpen }} />
    </div>
  );
};

export default ModelRefresh;

import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { CreditCardIcon, ClockIcon, LibraryIcon } from '@heroicons/react/solid';
import { GridColumn, Loader } from 'semantic-ui-react';

const dateOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const appearance = {
  theme: 'stripe',
  variables: {
    colorPrimary: '#475ddc',
    colorBackground: '#ffffff',
    colorText: '#30313d',
    colorDanger: '#df1b41',
    fontFamily: 'Ideal Sans, system-ui, sans-serif',
    spacingUnit: '4px',
    borderRadius: '8px',
  },
};

export function getFirstDayOfNextMonth() {
  const now = new Date();
  if (now.getMonth() === 11) {
    return new Date(now.getFullYear() + 1, 0, 1).toLocaleString('default', dateOptions);
  }
  return new Date(now.getFullYear(), now.getMonth() + 1, 1).toLocaleString('default', dateOptions);
}

export function getFirstDayOfCurrentMonth() {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split('T')[0];
}

export function getYesterday() {
  const now = new Date();
  const previous = new Date(now.getTime());
  previous.setDate(now.getDate() - 1);
  return previous.toISOString().split('T')[0];
}

export function setStatus(active, activeThrough, setToCancel) {
  if (active) {
    if (setToCancel) {
      return `Active, cancels ${activeThrough}`;
    }
    return `Active, renews ${activeThrough}`;
  }
  return 'Overdue';
}

export const epochToLocalTime = epoch => {
  const date = new Date(epoch * 1000);
  date.setDate(date.getDate());

  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];

  // eslint-disable-next-line no-use-before-define
  return `${monthNames[date.getMonth()]} ${getOrdinalNum(date.getDate())}`;
};

const getOrdinalNum = n => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export function setColor(active) {
  if (active) {
    return 'green';
  }
  return 'red';
}

export const parsePaymentResponse = (paymentResponse, setPaymentDetails, setPaymentType) => {
  if (paymentResponse === 'none') {
    setPaymentDetails({ message: 'no default payment method for this subscription' });
    setPaymentType('no default');
  } else if (paymentResponse.response.type === 'card') {
    const cardLast4 = paymentResponse.response.card.last4;
    const cardExpirationMonth = paymentResponse.response.card.exp_month;
    const cardExpirationYear = paymentResponse.response.card.exp_year;
    setPaymentDetails({ lastFour: cardLast4, expirationMonth: cardExpirationMonth, expirationYear: cardExpirationYear, brand: paymentResponse.response.card.brand });
    setPaymentType('card');
  } else if (paymentResponse.response.type === 'us_bank_account') {
    const bankName = paymentResponse.response.us_bank_account.bank_name;
    const { last4 } = paymentResponse.response.us_bank_account;
    setPaymentDetails({ name: bankName, last4 });
    setPaymentType('bank');
  } else {
    setPaymentDetails({ message: 'error pulling payment details' });
    setPaymentType('error');
  }
};

export const handleSetupIntent = (postSetupIntent, customerId, updatePaymentMethodComplete, options, setClientSecretOptions, intent, setSetupIntentComplete) => {
  postSetupIntent(customerId, ['card', 'us_bank_account'])
    .then(setupIntent => {
      if (!updatePaymentMethodComplete) {
        // eslint-disable-next-line no-param-reassign
        options.clientSecret = setupIntent.response.client_secret;
        setClientSecretOptions(options);
        (intent && updatePaymentMethodComplete) ? setSetupIntentComplete(false) : setSetupIntentComplete(true);
      }
    });
};

export function displayPaymentDetails(setupIntentComplete, activeSubscription, paymentType, paymentDetails) {
  return (
    <>
      {setupIntentComplete
            && (
            <>
              <GridColumn width={7} className="left-column">
                {(paymentType === 'card')
                && (
                <>
                  <span className="digits-span">
                    {paymentDetails.brand[0].toUpperCase() + paymentDetails.brand.substring(1)}
                    {' '}
                    ending in
                    {' '}
                    {paymentDetails.lastFour}
                  </span>
                  <span className="expiration-span">
                    Expires
                    {' '}
                    {paymentDetails.expirationMonth.toString().padStart(2, '0')}
                    /
                    {paymentDetails.expirationYear}
                  </span>
                </>
                )}
                {(paymentType === 'bank')
                && (
                <>
                  <span className="digits-span">
                    Account ending in
                    {' '}
                    {paymentDetails.last4}
                  </span>
                  <span className="expiration-span">
                    {paymentDetails.name[0].toUpperCase() + paymentDetails.name.substring(1)}
                  </span>
                </>
                )}
                {(paymentType === 'no default' || paymentType === 'error')
                && (
                <>
                  <span className="digits-span">
                    No default payment method on file
                  </span>
                  <span className="expiration-span">
                    Add one below
                  </span>
                </>
                )}
              </GridColumn>
              <GridColumn width={9} className="right-column">
                <span className="billing-text">
                  Billed on the 1st of each month
                </span>
              </GridColumn>
            </>
            )}
      {(!setupIntentComplete || !activeSubscription || !paymentDetails || !paymentType)
        && (
        <>
          <GridColumn width={7}>
            <span className="digits-span">
              <Loader active size="tiny" className="loader" />
            </span>
          </GridColumn>
          <GridColumn width={9}>
            <span className="billing-text-loading">
              Billed on the 1st of each month
            </span>
          </GridColumn>
        </>
        )}
    </>
  );
}

export function displayPaymentCopy(paymentType, setupIntentComplete) {
  if (!setupIntentComplete) {
    return (
      <>
        <GridColumn width={7}>
          <CreditCardIcon className="cc-icon" />
          <p className="cc-header">Payment Details</p>
        </GridColumn>
        <GridColumn width={9}>
          <ClockIcon className="clock-icon" />
          <p className="billing-header">Auto Pay</p>
        </GridColumn>
      </>
    );
  }
  const isCreditCard = paymentType === 'card';
  return (
    <>
      <GridColumn width={7}>
        {isCreditCard
                && (
                <>
                  <CreditCardIcon className="cc-icon" />
                  <p className="cc-header">Credit Card</p>
                </>
                )}
        {!isCreditCard
                && (
                <>
                  <LibraryIcon className="cc-icon" />
                  <p className="cc-header">Bank ACH</p>
                </>
                )}
      </GridColumn>
      <GridColumn width={9}>
        <ClockIcon className="clock-icon" />
        <p className="billing-header">Auto Pay</p>
      </GridColumn>
    </>
  );
}

export function processReceiptUrl(url) {
  const strippedUrl = url.split('?')[0];
  const pdfUrl = `${strippedUrl}/pdf`;
  return pdfUrl;
}

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function handlePaymentDetails(paymentMethod) {
  return paymentMethod.card ? `${capitalizeFirstLetter(paymentMethod.card.brand)} ending in ${paymentMethod.card.last4}` : `Account ending in ${paymentMethod.us_bank_account.last4}`;
}

export function setAutofillValues(customerResponse, setCustomerEmail, setCustomerName) {
  if (customerResponse.response.email) setCustomerEmail(customerResponse.response.email);
  if (customerResponse.response.name) setCustomerName(customerResponse.response.name);
}

export function handleInvoiceNoPaymentMethod(invoice) {
  const noReceiptData = {};
  noReceiptData.invoice = invoice.number;
  const billingDate = new Date(0);
  billingDate.setUTCSeconds(Number(invoice.created));
  noReceiptData.date = billingDate.toLocaleDateString('en-us', { month: 'long', day: 'numeric', year: 'numeric' });
  noReceiptData.invoiceLink = invoice.invoice_pdf;
  noReceiptData.amount = `$${(Number(invoice.amount_due) / 100).toFixed(2)}`;
  noReceiptData.paymentMethod = 'none';
  return noReceiptData;
}

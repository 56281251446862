/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { Table, Icon, Header } from 'semantic-ui-react';

export const ContentCardTableFooter = props => (
  <Table.Footer>
    <Table.Row>
      <Table.HeaderCell colSpan="100">
        <div className="footer-content">{props.children}</div>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Footer>
);

export const EmptyMessage = props => (
  <Table.Body>
    <Table.Row>
      <Table.Cell
        textAlign="center"
        selectable={false}
        className={props.callToAction ? undefined : 'empty'}
        style={{ borderTop: 'solid 1px #efefef' }}
      >
        <br />
        <Icon
          circular
          disabled
          name={props.emptyIcon || 'info'}
          size="big"
          className="m-b-10"
        />
        <Header
          as="h3"
          disabled
          content={props.fallbackMsg || props.fallBackMsg || 'No data found'}
          className="m-0"
        />
        { props.callToAction && <div style={{ marginTop: '8px' }}>{ props.callToAction }</div> }
        <br />
      </Table.Cell>
    </Table.Row>
  </Table.Body>
);

const defaultFormatter = x => x;

export const DefaultCell = ({ item, col }) => {
  const formatter = col.formatter || defaultFormatter;
  const { textAlign } = col;
  return (
    <Table.Cell width={item[col.cellWidth]} className={`data-${col?.key}`} {...{ textAlign }}>
      <span>{formatter(item[col.key])}</span>
    </Table.Cell>
  );
};

export const SummaryRow = ({ cols, data }) => {
  const item = cols.reduce((p, col) => {
    // eslint-disable-next-line no-param-reassign
    if (col.reducer) p[col.key] = col.reducer(data);
    return p;
  }, {});

  return (
    <Table.Footer>
      <Table.Row style={{ fontWeight: 'bold', backgroundColor: '#f3f4f5', marginTop: '2px' }}>
        {cols.map((col, i) => {
          if (i === 0) {
            return col.summaryLabel ? <Table.Cell>{col.summaryLabel}</Table.Cell> : <Table.Cell>Summary</Table.Cell>;
          }
          if (!col.reducer) return <Table.Cell>-</Table.Cell>;
          const Component = col.as || DefaultCell;
          return <Component {...{ col, item, data: [item], showPercent: col.showPercent || false, label: col.summaryLabel }} />;
        })}
      </Table.Row>
    </Table.Footer>
  );
};

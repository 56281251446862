import React from 'react';
import { Button } from 'semantic-ui-react';
import { getConversionData, testLookup } from '../../utils/api';
import { IndexGrid } from '@rockerbox/styleguide';
import { PaddedLabel } from './form';
import Sankey from '../Triggers/Sankey';

import moment from 'moment';

const today = moment.utc().utcOffset(-5).subtract(1,'d').format("YYYY-MM-DD")

const COLS = [
  {display: "Lookup", key: "path_display"},
  {display: "Identifiers", key: "identifiers_display"},
  {display: "New UIDs Yielded", key: "yield"},
  {display: "", key: ""},
]

const validIdentifiers = [
  "uid",
  "external_id",
  "email",
  "segment_anonymous_id"
]

const LookupTest = (props) => {
  const { lookupItems } = props;
  const [uids, setUids] = React.useState(false)
  const [data, setData] = React.useState([])
  const [shouldTest, setShouldTest] = React.useState(false)
  const [entries, setEntries] = React.useState([])
  const [sankeyData, setSankeyData] = React.useState(false)
  const conversionSegmentId = props.id

  React.useEffect(() => {
    if (conversionSegmentId) getConversionData(conversionSegmentId, today, today)
      .then(data => {
        const uids = data.map(row => row.uid)
        setUids(uids)
        setData(data)
      })
  }, [conversionSegmentId])

  React.useEffect(() => {
    if (shouldTest && uids.length && lookupItems) {
      const tables = lookupItems.map(row => row.table_name)
      setEntries(false)
      setSankeyData(false)
      const _baseId = tables[0].split("_")[0]
      const baseId = validIdentifiers.find(identifier => identifier.includes(_baseId) )
      const identifiers = data.map(row => row[baseId]).filter(id => id && id.length).slice(0,100) // TODO: remove this limit from the handler

      testLookup(identifiers, false, tables)
        .then(arr => {
          arr.viable_sequences.map(row => {
            row.path_display = row.path.join(",")
            row.identifiers_display = row.identifiers.join(",")
          })
          setEntries(arr.viable_sequences);
          const { links, nodes } = arr;
          links.map(row => row.value = Math.log(row.value + 1)/Math.log(1.1))
          setSankeyData({ links, nodes })
        })
    }
  }, [uids, shouldTest])

  React.useEffect(() => {
    setShouldTest(false)
  }, [lookupItems])

  return (
    <React.Fragment>
      <PaddedLabel>Test Sequence</PaddedLabel>
      <div style={{textAlign:"center"}}>
        { (shouldTest == false) && <Button onClick={() => setShouldTest(true)}>Test Sequence</Button>}
        { sankeyData && <Sankey data={sankeyData} /> } 
      </div>
      <IndexGrid cols={COLS} data={entries} negative={"bad_lookup"} />
    </React.Fragment>
  )
}

export default LookupTest;


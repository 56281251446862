import React, { useState } from 'react';

import { StackedBarChart, LineChart } from '@rockerbox/styleguide';
import { ResponsiveContainer, LabelList, BarChart, Bar, XAxis, YAxis, Tooltip, ErrorBar, Cell } from 'recharts';
import { Header, Checkbox } from 'semantic-ui-react';

import { useChartCalculations } from './hooks/useChartAndTable';
import { useSpendEffect } from './hooks/useSpendEffect';

export const SparkChart = ({ maxVal, data }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const handleMouseEnter = () => {
    setActiveIndex(true);
  };
  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <BarChart
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      layout="vertical"
      width={250}
      height={40}
      data={data}
      margin={{
        top: 0, right: 10, left: 5, bottom: 0,
      }}
    >
      <XAxis type="number" domain={[0, Math.ceil(maxVal * 1.42)]} hide />
      <YAxis hide dataKey="channel" type="category" tick={{ fontSize: 10 }} width={200} />
      <Bar dataKey="effect_share" fill="#1C8ED1">
        {activeIndex && <LabelList stroke="#1C8ED1" width={90} dataKey="effect_share_label" position="right" visible={activeIndex} /> }
        <LabelList stroke="#1C8ED1" width={90} dataKey="effect_share_label" position="right" />
      </Bar>
      <Bar dataKey="spend_share" fill="#FF9615">
        {activeIndex && <LabelList stroke="#FF9615" width={90} dataKey="spend_share_label" position="right" visible={activeIndex} /> }
      </Bar>
    </BarChart>
  );
};

export const Charts = ({ isConversionKPI, chartData, featureNameMap, tierColorMap }) => {
  const {
    barChartProps,
    lineChartProps,
  } = useChartCalculations(isConversionKPI, chartData, tierColorMap, featureNameMap);
  const { barChartData, barChartTitle, barChartFormatter } = barChartProps;
  const { lineChartData, lineChartTitle, lineChartFormatter, hideKeys } = lineChartProps;

  return (
    <>
      <Header as="h2" style={{ margin: '1rem' }}>
        {barChartTitle}
      </Header>
      {!!barChartData && !!barChartData.length && (
        <StackedBarChart
          height={300}
          data={barChartData}
          tierColorMap={tierColorMap}
          formatter={barChartFormatter}
          showTooltip
          showXAxis
          showTotalInToolTip
        />
      )}
      <Header as="h2" style={{ margin: '1rem' }}>
        {lineChartTitle}
      </Header>
      {lineChartData && lineChartData.length && (
        <LineChart
          height={200}
          hideKeys={hideKeys}
          tierColorMap={tierColorMap}
          data={lineChartData}
          showTooltip
          showXAxis
          showTotalInToolTip
          dottedLineForInfinity
          formatter={lineChartFormatter}
        />
      )}
    </>
  );
};

export const SpendEffectChart = ({ spendEffectData, tierColorMap, featureNameMap }) => {
  const [cumulative, setCumulative] = useState(false);

  const lineChartFormatter = v => (Number(v) > 1
    ? v.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    : v.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 20 }));
  const XAxisFormatter = (date, i) => (i % 5 === 0 ? date : '');
  const { chartData, cumulativeChartData } = useSpendEffect(spendEffectData, featureNameMap);

  return (
    <>
      <Header as="h2" style={{ margin: '1rem' }}>
        <div style={{ display: 'flex' }}>
          <div>Spend Effect</div>
          <Checkbox
            id="cumulative-checkbox"
            style={{ marginLeft: '2rem' }}
            toggle
            label="Cumulative"
            checked={cumulative}
            onChange={() => setCumulative(!cumulative)}
          />
        </div>
      </Header>
      { spendEffectData && spendEffectData.length && (
        <LineChart
          height={200}
          tierColorMap={tierColorMap}
          data={cumulative ? cumulativeChartData : chartData}
          showTooltip
          showXAxis
          XAxisFormatter={XAxisFormatter}
          showTotalInToolTip={false}
          dottedLineForInfinity
          formatter={lineChartFormatter}
          labelFormatter={v => `Day in window: ${v}`}
          yAnchor={-250}
        />
      )}
    </>
  );
};

const ROITooltip = ({ active, payload, label }) => {
  const format = v => v.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  if (active && payload && payload.length) {
    const { confidence } = payload[0].payload;
    const { value } = payload[0];
    console.log(confidence, value);
    return (
      <div className="roi-tooltip">
        <p>{`${label} : ${format(value)}`}</p>
        <p>
          90% confidence interval:
          {' '}
          {format(value - confidence[0])}
          {' '}
          -
          {' '}
          {format(value + confidence[1])}
        </p>
      </div>
    );
  }

  return null;
};

export const ROIHatChart = ({ ROIHatData = undefined, tierColorMap, featureNameMap }) => {
  if (!Object.keys(tierColorMap).length || !ROIHatData || !ROIHatData.length) return null;
  const chartData = ROIHatData.sort((a, b) => a.feature - b.feature).map(x => (
    { name: (featureNameMap[x.feature] || x.feature), roi: x.roi, confidence: [x.q05, x.q95] }
  ));

  return (
    <>
      <Header as="h2" style={{ margin: '1rem' }}>
        <div style={{ display: 'flex' }}>
          <div>Estimated media channel ROI Hat</div>
        </div>
      </Header>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart data={chartData}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<ROITooltip />} />
          <Bar dataKey="roi" fill="#8884d8">
            {chartData.map(entry => (
              <Cell key={`cell-${entry.name}`} fill={tierColorMap[entry.name]} />
            ))}
            <ErrorBar dataKey="confidence" width={4} strokeWidth={2} stroke="black" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

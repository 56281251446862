import React, { Component } from 'react';
import moment from 'moment';

import { Table } from 'semantic-ui-react';
import { ContentCard } from '@rockerbox/styleguide';
import { getClickDataTimeseries, getConversionSegments } from '../../utils/api';

class ClickDataSource extends Component {
  state = {
    startDate: moment.utc().utcOffset(-5).subtract(30, 'days').format('YYYY-MM-DD'),
    endDate: moment.utc().utcOffset(-5).subtract(1,'days').format('YYYY-MM-DD'),
    touchPointsFilterId: false,
    clickData: []
  }
  componentDidMount() {

    getConversionSegments()
      .then(data => {
        const touchPointsFilterId = data
          .filter(segment => segment.touchpoints == 1)
          .map(seg => {
            return {
              id: seg.action_id
            }
          })[0]['id']
        this.setState({ touchPointsFilterId })
      })
  }

  componentDidUpdate(prevProps, prevState) {

    const { startDate, endDate, touchPointsFilterId } = this.state;
    if (prevState.touchPointsFilterId != touchPointsFilterId) { 

      getClickDataTimeseries(touchPointsFilterId, startDate, endDate)
        .then(clickData => {
          this.setState({ clickData })
        })
    }

  }

  render() {

    const { clickData } = this.state;


    return (
      <ContentCard hasTable title="Click Data" explainer="" >
        <ContentCard.Table celled structured sortable columns={11}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell rowSpan="2"> Date </Table.HeaderCell>
              <ContentCard.TableHeaderCellNoHover colSpan="3" textAlign="center">
                All Clicks
              </ContentCard.TableHeaderCellNoHover>
              <ContentCard.TableHeaderCellNoHover colSpan="3" textAlign="center">
                Clicks to Article 
              </ContentCard.TableHeaderCellNoHover>
              <ContentCard.TableHeaderCellNoHover colSpan="3" textAlign="center">
                Clicks to Site 
              </ContentCard.TableHeaderCellNoHover>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell> Click </Table.HeaderCell>
              <Table.HeaderCell> With Visit </Table.HeaderCell>
              <Table.HeaderCell> Total </Table.HeaderCell>
              <Table.HeaderCell> Click </Table.HeaderCell>
              <Table.HeaderCell> With Visit </Table.HeaderCell>
              <Table.HeaderCell> Total </Table.HeaderCell>
              <Table.HeaderCell> Click </Table.HeaderCell>
              <Table.HeaderCell> With Visit </Table.HeaderCell>
              <Table.HeaderCell> Total </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { clickData.map( row => (
                <Table.Row>
                  <Table.Cell> { row.date } </Table.Cell>
                  <Table.Cell> { row.total.click_only } </Table.Cell>
                  <Table.Cell> { row.total.click_and_view } </Table.Cell>
                  <Table.Cell> { row.total.click_only + row.total.click_and_view } </Table.Cell>
                  <Table.Cell> { row.article.click_only } </Table.Cell>
                  <Table.Cell> { row.article.click_and_view } </Table.Cell>
                  <Table.Cell> { row.article.click_only + row.article.click_and_view } </Table.Cell>
                  <Table.Cell> { row.site.click_only } </Table.Cell>
                  <Table.Cell> { row.site.click_and_view } </Table.Cell>
                  <Table.Cell> { row.site.click_only + row.site.click_and_view } </Table.Cell>
                </Table.Row>
              ))
            }
          </Table.Body>
        </ContentCard.Table>
      </ContentCard>

    )
  }
}

export default ClickDataSource;


import React, { useState, useEffect } from 'react';
// eslint-disable-next-line no-unused-vars
import { Form, Grid, Icon, Input, Dropdown, Button, Message } from 'semantic-ui-react';
import { postSnowflakeInit, postSnowflakeParseURL } from '../../../api/datalake';
import { useStepsState, useStepsDisplay } from '../StepsSectionContext';
import ErrorMessage from '../components/ErrorMessage';
import { useDatalake } from '../DatalakeContext/hooks';

const SetupShare = () => {
  const { datalakeConnection, refreshDatalakeConnection } = useDatalake();
  const [stepDisplay, setStepDisplay] = useStepsDisplay();
  // eslint-disable-next-line no-unused-vars
  const [_, setStepsStateData] = useStepsState();
  // eslint-disable-next-line no-unused-vars
  const [stateData, setStateData] = useStepsState();

  const [clientURL, setClientURL] = useState('');
  const [account, setAccount] = useState('');
  const [cloud, setCloud] = useState('');
  const [region, setRegion] = useState('');
  const [urlCallStatus, setUrlCallStatus] = useState('');

  const [apiError, setAPIError] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (datalakeConnection?.details) {
      const {
        details: {
          instance_url,
          account_id,
          cloud,
          client_region,
          share,
          rockerbox_account_id
        }
      } = datalakeConnection;
      setStepDisplay(1, true);
      setClientURL(instance_url);
      setAccount(account_id);
      setCloud(cloud);
      setRegion(client_region);
      setStepsStateData({
        details: {
          rockerbox_account_id: rockerbox_account_id,
          share: share
        }
      });
    }
  }, [datalakeConnection]);

  const onCreateDBClick = () => {
    setLoading(true);
    setDisableSubmit(true);
    postSnowflakeInit(account, cloud, region, clientURL)
      .then(res => {
        if (res.data.status === 'success') {
          setStateData(res.data);
          refreshDatalakeConnection()
          setStepsStateData({
            details: {
              rockerbox_account_id: res.data.details.rockerbox_account_id,
              share: res.data.details.share
            }
          });
          setStepDisplay(1, true);
          setDisableSubmit(false);
          setLoading(false);
        }
      });
  };

  const onURLInput = value => {
    setClientURL(value);
    if (value.length > 3) {
      postSnowflakeParseURL(value)
        .then(data => {
          setAPIError(data.message);
          setAccount(data.account);
          setCloud(data.cloud);
          setRegion(data.region);
          setUrlCallStatus(data.status);
        });
    }
  };

  const returnURLIconStyle = status => {
    if (status === 'error') {
      return { name: 'remove circle', color: 'red' };
    }

    if (status === 'success') {
      return { name: 'check circle', color: 'green' };
    }
  };

  return (
    <div>
      <div>
        <Grid columns={2}>
          <Grid.Column width={6}>
            <Form>
              <Form.Field width={12}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>Snowflake Instance URL</label>
                <div className="ui icon input">
                  <Input
                    style={{ width: 400 }}
                    placeholder="123xyz.snowflakecomputing.com"
                    value={clientURL}
                    iconPosition="right"
                    onChange={({ target: { value } }) => onURLInput(value)}
                  >
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <Icon {...returnURLIconStyle(urlCallStatus)} />
                    <input />
                  </Input>
                </div>
                <p />
                {!!loading
                  ? <Button loading disabled={true}>Loading</Button>
                  : <Button primary content="Setup Share" onClick={onCreateDBClick} disabled={urlCallStatus !== 'success' || disableSubmit} />}
              </Form.Field>
              {apiError && <ErrorMessage message={apiError} />}
            </Form>
          </Grid.Column>
          <Grid.Column
            width={6}
          >
            <Message>
              <div>
                <p>Account: {account}</p>
                <p>Cloud: {cloud}</p>
                <p>Region: {region}</p>
              </div>
            </Message>
          </Grid.Column>
        </Grid>
      </div>
    </div>
  );
};

export default SetupShare;

import React from "react";
import CacheContext from "../../utils/CacheContext.js";
import { getSegments } from "../../utils/api";
import { Table, List, Message } from "semantic-ui-react";

export const RedshiftSettingTable = props => (
  <Table definition>
    <Table.Body>
      <Table.Row>
        <Table.Cell>Client</Table.Cell>
        <Table.Cell>{props.pixel_source_name}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Bucket Name</Table.Cell>
        <Table.Cell>{props.bucket}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Region</Table.Cell>
        <Table.Cell>{props.region}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Cluster Name</Table.Cell>
        <Table.Cell>{props.cluster_name}</Table.Cell>
      </Table.Row>
      {props.datasets && (
        <Table.Row>
          <Table.Cell>Datasets (Segment)</Table.Cell>
          <Table.Cell>
            <List>
              {props.datasets.map(user => (
                <List.Item>{`${user.warehouse_table_name}: ${user.dataset} (${user.filter_id})`}</List.Item>
              ))}
            </List>
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
);

export const BigQuerySettingTable = props => (
  <Table definition>
    <Table.Body>
      <Table.Row>
        <Table.Cell>Client</Table.Cell>
        <Table.Cell>{props.pixel_source_name}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Account Name</Table.Cell>
        <Table.Cell>{props.bucket_name}</Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>Region</Table.Cell>
        <Table.Cell>{props.region}</Table.Cell>
      </Table.Row>
      {props.users && (
        <Table.Row>
          <Table.Cell>Manage Account</Table.Cell>
          <Table.Cell>
            <List>
              {props.users.map(user => (
                <List.Item>{`${user.warehouse_table_name}:${user.account} (${user.account_type})`}</List.Item>
              ))}
            </List>
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
);

export const SnowflakeSettingTable = props => (
  <Table definition>
    <Table.Body>
      <Table.Row>
        <Table.Cell>Client</Table.Cell>
        <Table.Cell>{props.pixel_source_name}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Account Name</Table.Cell>
        <Table.Cell>{props.account}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Account Type</Table.Cell>
        <Table.Cell>
          {(props.type && props.type.toLowerCase()) || ""}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Bucket Name</Table.Cell>
        <Table.Cell>{props.bucket}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Cloud/Region</Table.Cell>
        <Table.Cell>{`${props.cloud}\/${props.aws_region}`}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Snowflake Region</Table.Cell>
        <Table.Cell>{props.region}</Table.Cell>
      </Table.Row>
      {props.datasets && (
        <Table.Row>
          <Table.Cell>Datasets (Segment)</Table.Cell>
          <Table.Cell>
            <List>
              {props.datasets.map(user => (
                <List.Item>{`${user.warehouse_table_name}: ${user.dataset} (${user.filter_id})`}</List.Item>
              ))}
            </List>
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
);

export const DatasetSettingTable = props => {
  const [state, setState, Context] = React.useContext(CacheContext);
  const segments = Context.getCache("segments", getSegments) || [];
  const segment =
    segments.filter(segment => segment.filter_id === props.filter_id)[0] || {};

  const isFeatured = segment.featured;
  return (
    <React.Fragment>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Table Name</Table.Cell>
            <Table.Cell>{props.warehouse_table_name}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Dataset</Table.Cell>
            <Table.Cell>{props.dataset}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Segment</Table.Cell>
            <Table.Cell>{`${segment.action_name} (${props.filter_id})`}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Checksum</Table.Cell>
            <Table.Cell>{props.checksum}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Message
        info
        header="Try this simple query to get started:"
        content={`SELECT max(date) FROM ${props.warehouse_table_name}_live`}
      />
    </React.Fragment>
  );
};

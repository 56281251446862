export const summarizeForecastRows = (forecastRows, featureStats, baseline, numDays = 30) => {
  if (!forecastRows) return;

  const obj = forecastRows.reduce((acc, curr) => ({
    dailySpend: acc.dailySpend + parseInt(curr.currentValue || 0),
    targetDailySpend: acc.targetDailySpend + parseInt(curr.targetValue || 0),
    totalSpend: acc.totalSpend + (parseInt(curr.targetValue || 0) * numDays),
    predictedRevenue: acc.predictedRevenue + (curr.revenueAtTargetValue || 0 * numDays),
    currentRevenue: acc.currentRevenue + (curr.revenueAtCurrentValue || 0 * numDays),
  }), {
    dailySpend: 0,
    targetDailySpend: 0,
    totalSpend: 0,
    predictedRevenue: 0,
    currentRevenue: 0,
  });

  obj.revenueDiff = obj.predictedRevenue - obj.currentRevenue;
  obj.revenuePercentDiff = obj.revenueDiff / ((obj.predictedRevenue + obj.currentRevenue) / 2);
  obj.revenuePercentDiffWithBaseline = obj.revenueDiff / ((obj.predictedRevenue + obj.currentRevenue + (2 * baseline)) / 2);

  obj.predictedRoas = (obj.predictedRevenue + baseline) / obj.totalSpend;
  obj.currentRoas = (obj.currentRevenue + baseline) / (obj.dailySpend * numDays);

  obj.roasDiff = obj.predictedRoas - obj.currentRoas;
  return obj;
};

export const trainingStatsWithSpend = (trainingStats, featureStats) => trainingStats.map(row => {
  const statsRow = { ...row, spend_mean: featureStats[row.feature]?.currentSpend };
  return statsRow;
});

export const featuresToNameMap = features => features?.reduce((acc, x) => Object.assign(acc, { [x.value]: x.name }), {});

export const fieldValuesToCurrentSpend = fieldValues => Object.entries(fieldValues).reduce((p, [k, v]) => Object.assign(p, { [k]: { value: v.current } }), {});

export const calcAverageBaselineFromSeasonality = (seasonality, numDays) => {
  if (!seasonality) return;
  const overallSum = seasonality.reduce((p, row) => p + row.sum, 0);

  if (overallSum === 0) return;

  const overallAverage = overallSum / seasonality.length;

  return overallAverage * numDays;
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConversionEvents from './component';

export const GET_ALL_SEGMENTS_ENDPOINT = '/segments?format=json';

export class ConversionsWrapper extends Component {
  render() {
    return <ConversionEvents {...this.props} />
  }
}

function mapStateToProps(state, props) {
  return Object.assign({
    username: state.app.username,
    permissions: state.app.permissions,
    advertiser: state.app.user.pixel_source_name
  }, props);
}

export default connect(mapStateToProps)(ConversionsWrapper);

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Icon } from 'semantic-ui-react';

import { ContentCard, IndexGridNew } from '@rockerbox/styleguide';
import { useAtom } from 'jotai';
import { getConversionData } from '../../api/attributionCache';
import { FilterWrapper, StyledDropdown, BasicViewHeader, DateRangeHooks, Drawer, PopupCard, CustomizeColumnsButton, SaveReportButton, ResetFilterButton, ViewAccessWrapper } from '../../components';
import { useURLStateAndSetDefault } from '../../hooks/urlState';
import { yesterday } from '../../utils/time';
import { segmentOptions } from '../../utils/options';
import { useGlobalState } from '../../hooks/global';
import * as routes from '../../baseRoutes';
import { track } from '../../../utils/tracking';
import { TreeTableLoader } from '../../components/loaders';

import { processConversionData, ViewDetailsCell } from './helpers';
import { CONVERSION_COLUMNS } from './constants';
import { NTF_OPTIONS } from '../../constants/options';
import { customerTypeAtom, columnsAtom } from '../../atoms';

const ConversionsDetail = () => {
  const { segments } = useGlobalState();
  const [segId, setSegId] = useURLStateAndSetDefault('id');
  const [startDate, setStartDate] = useURLStateAndSetDefault('startDate', yesterday);
  const [endDate, setEndDate] = useURLStateAndSetDefault('endDate', yesterday);

  const [ntfFilter, setNtfFilter] = useAtom(customerTypeAtom);
  const [colsUrl, setColsUrl] = useAtom(columnsAtom);

  useEffect(() => {
    if (!segments?.length || !!segId) return;
    const featuredSegment = segments.find(x => !!x.featured);
    const featuredSegmentId = featuredSegment?.action_id;
    setSegId(featuredSegmentId);
  }, [segments]);

  const [loading, setLoading] = useState(true);
  const [conversionData, setConversionData] = useState(null);
  const [tableColumns, setTableColumns] = useState([]);
  const [columnsDrawerOpen, setColumnsDrawerOpen] = useState(false);

  const onDateChange = useCallback(({ startDate, endDate }) => { // eslint-disable-line no-shadow
    const formatStartDate = moment(startDate).format('YYYY-MM-DD');
    const formatEndDate = moment(endDate).format('YYYY-MM-DD');

    setStartDate(formatStartDate);
    setEndDate(formatEndDate);
  });

  const segmentInfo = useMemo(() => {
    if (!segments?.length) return null;
    const seg = segments.find(s => s.action_id === parseInt(segId));

    const features = [];
    if (!!seg.include_revenue) features.push('revenue');
    if (!!seg.has_new_to_file) features.push('new_to_file');
    if (!!seg.lookup_sequence && !!seg.lookup_sequence.length) features.push('lookups');
    seg.features = features;

    return seg;
  }, [segments, segId]);

  useEffect(() => {
    if (tableColumns.length < 1) return;
    setColsUrl(tableColumns.map(c => c.key));
  }, [tableColumns]);

  useEffect(() => {
    if (!segmentInfo) return;
    if (!segmentInfo.has_new_to_file) setNtfFilter('all');
  }, [segmentInfo]);

  useEffect(() => {
    if (!segId || segId === '0' || !startDate || !endDate) return;
    setLoading(true);
    getConversionData(segId, startDate, endDate)
      .then(data => {
        const processedData = data.map(processConversionData(segId));
        setConversionData(processedData);
        setLoading(false);
      });
    track('data.customers.conversions.detail.view', {
      segment_id: segId,
      start_date: startDate,
      end_date: endDate,
    });
  }, [segId, startDate, endDate]);

  const tableData = useMemo(() => {
    if (!conversionData) return;
    if (ntfFilter === 'all') return conversionData;
    const fitlerValue = ntfFilter === 'new' ? 1 : 0;
    return conversionData.filter(x => x?.new_to_file === fitlerValue);
  }, [conversionData, ntfFilter]);

  const setDefaultTableColumns = () => {
    const conversionDataCols = !!conversionData?.length ? Object.keys(conversionData[0]) : [];
    const defaultTableCols = CONVERSION_COLUMNS.filter(c => conversionDataCols.includes(c.key) && c.default
        && (!c.requirement || segmentInfo.features.includes(c.requirement)));
    setTableColumns(colsUrl.length > 0 ? defaultTableCols.filter(col => colsUrl.includes(col.key)) : defaultTableCols);
  };

  useEffect(() => {
    if ((tableColumns.length === 0 || colsUrl.length === 0) && segmentInfo && conversionData) {
      setDefaultTableColumns();
    }
  }, [segmentInfo, conversionData, colsUrl, tableColumns]);

  const allColumns = useMemo(() => {
    if (!segmentInfo || !conversionData) return;
    const conversionDataCols = !!conversionData?.length ? Object.keys(conversionData[0]) : [];
    const allCols = CONVERSION_COLUMNS.filter(c => conversionDataCols.includes(c.key)
      && (!c.requirement || segmentInfo.features.includes(c.requirement)));
    return allCols;
  }, [segmentInfo, conversionData]);

  const SEGMENT_OPTIONS = segmentOptions(segments);

  return (
    <>
      <BasicViewHeader
        header="Conversions"
        subheader={(
          <>
            {'Customer Data '}
            <Icon name="angle right" style={{ margin: 0 }} />
            {' '}
            <Link to={routes.conversionsOverview}>
              Orders &amp; Conversions
            </Link>
          </>
        )}
      />
      <FilterWrapper
        {...{ loading }}
        other={(
          <>
            <StyledDropdown
              label="Conversion Type"
              options={SEGMENT_OPTIONS}
              value={parseInt(segId)}
              onChange={(e, { value }) => setSegId(value)}
            />
            <StyledDropdown
              label="Customer Type"
              disabled={!segmentInfo?.has_new_to_file}
              options={NTF_OPTIONS}
              value={ntfFilter}
              onChange={(e, { value }) => setNtfFilter(value)}
            />
          </>
      )}
        date={<DateRangeHooks {...{ startDate, endDate, onDateChange }} />}
        stickyRight={(
          <>
            <SaveReportButton {...{ loading }} />
            <ResetFilterButton />
          </>
      )}
      />

      <ContentCard hasTable>
        {!!loading ? <TreeTableLoader showSearch />
          : (
            <IndexGridNew
              sortable
              selectable
              paginate
              itemsPerPage={10}
              data={tableData}
              cols={[...tableColumns, { display: '', key: 'path', as: ViewDetailsCell, sortable: false }]}
              allColumns={[...(allColumns ?? []), { display: '', key: 'path', as: ViewDetailsCell, sortable: false }]}
              searchable
              alignSearchLeft
              placeholder="Search Conversions"
              fallBackMsg="No Matching Conversions Found"
              topRight={
                <CustomizeColumnsButton onClick={() => setColumnsDrawerOpen(true)} />
          }
            />
          )}
      </ContentCard>

      <Drawer
        openDrawer={columnsDrawerOpen}
        onDrawerClose={() => setColumnsDrawerOpen(false)}
      >
        <PopupCard
          title="Customize Columns"
          isCustom={true}
          customKey="display"
          list={tableColumns}
          originalList={allColumns}
          setOrder={setTableColumns}
          icon="none"
          callbackFn={() => setColumnsDrawerOpen(false)}
          open={columnsDrawerOpen}
        />
      </Drawer>
    </>
  );
};

const ConversionsDetailAccess = () => (
  <ViewAccessWrapper
    viewName="orders_and_conversions_details"
    viewComponent={<ConversionsDetail />}
  />
);

export default ConversionsDetailAccess;

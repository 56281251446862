import React, { useState, useCallback } from 'react';

import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Message, Form } from 'semantic-ui-react';

import { addNewUser } from '../../api/account';
import { postUserRBAC } from '../../api/rbacApi';
import { RBAC_ASSIGN_ROLES } from '../../constants/rbac';
import { useRBACAdvertiser, useRBACRoles, useRBACPermissions } from '../../hooks/useRBAC';
import { VALID_EMAIL_REGEX } from '../../utils/formValidators';
import { updateEmailSuppressions } from '../../utils/rbac';

const InviteUsersForm = ({ handleManageUpdate }) => {
  const { advertiserExistsRBAC } = useRBACAdvertiser();
  const { roleOptions } = useRBACRoles();
  const { authorizedPermissions } = useRBACPermissions();
  const authorizedToAssignRoles = authorizedPermissions.includes(RBAC_ASSIGN_ROLES);
  const {
    control,
    handleSubmit, setValue, trigger,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: { email: '', role: 'admin' } });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrMsg] = useState('');

  const resetForm = () => {
    reset({ email: '' });
    setLoading(false);
    setError(false);
    toast.success('Successfully invited new user');
    handleManageUpdate();
  };

  const onSubmit = useCallback(({ email, role }) => {
    const postObj = {
      email: email.trim(),
    };
    setLoading(true);
    addNewUser(postObj)
      .then(({ error: errorResponse, error_message: errorMessage, id }) => {
        if (errorResponse) {
          toast.error('User already exists');
          setLoading(false);
          setErrMsg(errorMessage);
          return;
        }
        if (advertiserExistsRBAC) {
          postUserRBAC(id, role).then(() => {
            updateEmailSuppressions(id, email);
            resetForm();
          });
        } else {
          resetForm();
        }
      })
      .catch(err => {
        console.log('error posting inviting user', err.response);
        toast.error('User already exists');
        setLoading(false);
      });
  });

  return (
    <div style={{ marginBottom: 14 }}>
      {errors.email?.message && (
        <div style={{ backgroundColor: 'rgb(224, 180, 180)' }} className="ui pointing below prompt label">
          {errors.email.message}
        </div>
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group inline>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Form.Input
                {...field} // eslint-disable-line react/jsx-props-no-spreading
                style={{ width: 275 }}
                placeholder="user@email.com"
              />
            )}
            rules={{
              required: 'Missing email',
              pattern: {
                value: VALID_EMAIL_REGEX,
                message: 'Please use a valid email',
              },
            }}
            disabled={!authorizedToAssignRoles}
          />
          {advertiserExistsRBAC && (
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <Form.Dropdown
                  {...field} // eslint-disable-line react/jsx-props-no-spreading
                  options={roleOptions}
                  selection
                  onChange={(e, { name, value }) => {
                    setValue(name, value);
                    trigger(name);
                  }}
                />
              )}
              rules={{
                required: 'Missing Role',
              }}
              disabled={!authorizedToAssignRoles}
            />
          )}
          <Form.Button
            type="submit"
            fluid
            primary
            content="Invite"
            loading={loading}
            disabled={!authorizedToAssignRoles}
          />
        </Form.Group>
      </Form>
      {error && (
        <Message negative>
          <p>{errorMsg}</p>
        </Message>
      )}
    </div>
  );
};

export default InviteUsersForm;

/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';

import { Dropdown, Input } from 'semantic-ui-react';

class SearchableDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredOptions: [],
    };
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentDidMount() {
    const { options } = this.props;
    this.setState({ filteredOptions: options });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = ({ target }) => {
    const { containerRef } = this;
    return this.setState({ opened: containerRef.contains(target) });
  };

  setFocusedOption = value => { this.focusedOption = value; };

  handleSearch = event => {
    const { target: { value } } = event;
    const { options } = this.props;
    this.setState({ query: value });
    const filtered = options.filter(tag => tag.text.toLowerCase().includes(value.toLowerCase()));
    this.setState({ filteredOptions: filtered });
  };

  handleChange = (event, chosen) => {
    const { value } = chosen;
    const { options, onSelect } = this.props;
    const selected = options.filter(row => row.value === value)[0];
    if (selected) {
      onSelect(event, selected);
      this.setState({ opened: false });
    }
  };

  handleEnter = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.handleChange(event, { value: this.focusedOption });
    }
  };

  render() {
    const { opened, query, filteredOptions } = this.state;
    const { props: { children, title } } = this;
    const { as } = this.props;

    const onClick = this.handleChange;
    const onKeyDown = this.handleEnter;

    const defaultElement = props => (<span {...props}>{props.children}</span>);
    const Wrap = as || defaultElement;

    return (
      <Wrap ref={containerRef => { this.containerRef = containerRef; }}>
        <Dropdown pointing="top right" icon="" className="company-switcher" trigger={children}>
          <Dropdown.Menu open={opened}>
            <Input icon="search" iconPosition="left" value={query} onChange={this.handleSearch} />
            <Dropdown.Divider />
            { title && <Dropdown.Header icon="exchange" content={title} /> }
            <Dropdown.Menu scrolling>
              {filteredOptions.map(option => <Dropdown.Item {...option} tabIndex={0} onFocus={() => this.setFocusedOption(option.value)} {...{ onClick, onKeyDown }} />)}
            </Dropdown.Menu>
          </Dropdown.Menu>
        </Dropdown>
      </Wrap>
    );
  }
}
export default SearchableDropdown;

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ContentCard } from '@rockerbox/styleguide'
import { Input, Button, Icon, Popup, Header, Modal, Table } from 'semantic-ui-react'
import { getPixelCnameDomain, postPixelCnameDomain } from '../../utils/api'

class PixelCnameDomain extends Component {
  state = {
    domain: '',
    domainInput: '',
    verified: false,
    loading: true,
    error: false,
    errorMsg: '',
    showForm: false,
  }

  componentDidMount() {
    this.getDataFromApi(false)
  }

  getDataFromApi = (force_run_test) => {
    this.setState({ loading: true })

    getPixelCnameDomain(force_run_test)
      .then(data =>
        this.setState({
          domain: data.domain,
          verified: data.verified,
          loading: false,
          errorMsg: '',
        })
      )
      .catch(err => this.setState({
        error: true,
        errorMsg: "Error retrieving data",
        loading: false,
      }))
  }

  refreshCnameStatus = () => (
    this.getDataFromApi(true)
  )

  handleChange = (event, data) => {
    const { value } = data
    this.setState({ domainInput: value })
  }

  handleSubmit = () => {
    // validate input is a domain
    const value = this.state.domainInput
    const re = new RegExp(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g)
    const m = value.match(re)
    const isValid = (m && m.length && m[0] == value)

    if (!isValid) {
      this.setState({
        error: true,
        errorMsg: "Invalid Domain",
      })
      return
    }

    this.setState({ loading: true })

    const postObj = {
      domain: value
    }

    postPixelCnameDomain(postObj)
      .then(data =>
        this.setState({
          domain: data.domain,
          verified: data.verified,
          loading: false,
          errorMsg: '',
        })
      )
      .catch(err => this.setState({
        error: true,
        errorMsg: "Error saving domain name",
        loading: false,
      }))
  }

  buildCnameTarget = (pixel_source_name) => pixel_source_name && `${pixel_source_name.toLowerCase().replace(/[^a-z0-9]/g, "")}.cname.getrockerbox.com`

  render() {
    const { account, cname_instructions } = this.props
    const { loading, error, domain, verified, domainInput, errorMsg, showForm } = this.state

    return (
      <ContentCard
        title="Custom Tracking Domain"
        loading={loading}
        style={{minHeight: 109}}
      >
        <div style={{textAlign: 'center', padding: 5}}>
          {domain ?
            <DomainView
              domain={domain}
              cname_target={this.buildCnameTarget(account.pixel_source_name)}
              cname_instructions={cname_instructions}
              verified={verified}
              refresh={this.refreshCnameStatus}
            />
          :
            showForm ?
              <InputForm
                value={domainInput}
                onChange={this.handleChange}
                onSubmit={this.handleSubmit}
                errorMsg={errorMsg}
              />
            :
              <EmptyView onClick={() => this.setState({showForm: true})} />
          }
        </div>
      </ContentCard>
    )
  }
}

const DomainView = ({ domain, cname_target, cname_instructions, verified, refresh }) => (
  <React.Fragment>
    <Header
      as='h5'
      style={{marginBottom: 10}}
    >
      Your custom domain is <strong>{domain}</strong>
    </Header>
    <div>
      <SetupInstructions
        domain={domain}
        cname_target={cname_target}
        cname_instructions={cname_instructions}
      />
      &nbsp;
      <Button
        content='Test'
        color={!verified && 'blue'}
        size='small'
        onClick={refresh}
        label={{
          basic: true,
          content: (
            verified ?
            <React.Fragment>
              <Icon color='green' name='check circle' />
              Verified
            </React.Fragment>
            :
            <React.Fragment>
              <Icon color='yellow' name='warning sign' />
              Not Configured
            </React.Fragment>
          )
        }}
        labelPosition='left'
      />
    </div>
  </React.Fragment>
)

const SetupInstructions = ({ domain, cname_target, cname_instructions }) => (
  <Modal
    closeIcon
    trigger={
      <Button inverted color='blue' size='small'>
        Set Up Instructions
      </Button>
    }
  >
    <Modal.Header>
      Set Up your Custom Tracking Domain
    </Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <Header>
          Add the following record in your DNS settings:
        </Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Host</Table.HeaderCell>
              <Table.HeaderCell>Target Value</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>CNAME Record</Table.Cell>
              <Table.Cell>{domain.split('.')[0]}</Table.Cell>
              <Table.Cell>{cname_target}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <br />
        <p>
          Adding the above CNAME record will enable you to set up Rockerbox tracking on the domain you specified ({domain})<br />
          More detailed setup instructions are <a href={cname_instructions} target='_blank'>available in our Help Center <Icon name='external' /></a>
        </p>

      </Modal.Description>
    </Modal.Content>
  </Modal>
)

const InputForm = ({ value, onChange, onSubmit, errorMsg }) => (
  <React.Fragment>
    <Header
      as='h5'
      style={{marginBottom: 7}}
    >
      Enter a subdomain of your website to use for Rockerbox tracking
    </Header>
    <Popup
      content={
        <React.Fragment>
          <Icon color='yellow' name='warning sign' />
          {errorMsg}
        </React.Fragment>
      }
      open={errorMsg}
      position='left center'
      trigger={
        <Input
          placeholder="track.yourdomain.com"
          value={value}
          action
          onChange={onChange}
          style={{width: 300}}
        >
          <input />
          <Button
            primary
            type='submit'
            onClick={onSubmit}
            disabled={value === ''}
          >Add</Button>
        </Input>
      }
    />
  </React.Fragment>
)

const EmptyView = ({ onClick }) => (
  <React.Fragment>
    <Header
      as='h5'
      style={{marginBottom: 11}}
    >
      Improve your tracking rates by serving Rockerbox pixels from your own domain
    </Header>
    <Button inverted color='blue' size='small' onClick={onClick}>
      Start Set Up
    </Button>
  </React.Fragment>
)

function mapStateToProps(state, props) {
  return {
    account: state.app.user,
    cname_instructions: state.app.artifacts.cname_instructions,
  }
}

export default connect(mapStateToProps)(PixelCnameDomain)

import React from 'react';

import { Checkbox, Radio } from 'semantic-ui-react';
import styled from 'styled-components';

const Description = styled.div`
  font-weight:bold;
  padding-bottom:5px;
`;
const RadioWrap = styled.div`
  padding:8px;
  ${({ checked, showBorder }) => (checked && showBorder ? 'border:1.5px solid rgb(2 108 172 / 75%); border-radius:.5em;' : '')}
  ${({ disabled }) => (disabled ? '' : '')}

  & .ui.disabled.checkbox.checked label {
    opacity: 1;
  }
  & .ui.checkbox.disabled input:checked~label:before {
    background: rgb(2 108 172 / 50%);
    border-color: rgb(2 108 172 / 50%);
  }
  & .ui.checkbox.disabled label:before {
    background: inherit;
  }

`;

const RadioOption = ({ disabled, showBorder, multiple, label, checked, onClick, ShowSelected }) => {
  const Component = multiple ? Checkbox : Radio;
  const parameters = multiple ? { defaultChecked: checked, checked: !!checked } : { checked: !!checked };
  const wrapperClick = !ShowSelected ? onClick : undefined;

  return (
    <RadioWrap {...{ disabled, showBorder, checked, onClick: wrapperClick }}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...{ label, onClick, disabled }} {...parameters} />
      { checked && ShowSelected }
    </RadioWrap>
  );
};

const RadioOptions = props => {
  const { disabled, description, options, onChange, multiple, value, showBorder = true } = props;
  const [isInit, setIsInit] = React.useState(true);
  const [selected, setSelected] = React.useState(value);

  React.useEffect(() => setSelected(value), [value]);

  const isSelected = selectedValue => {
    if (!multiple) return (selected === selectedValue) ? 'checked' : undefined;
    return (selected || []).find(v => v === selectedValue) ? true : undefined;
  };

  const selectRow = row => {
    if (!multiple) return setSelected(row.value);

    const isValueSelected = (selected || []).find(cur => cur === row.value);
    isValueSelected ? setSelected(selected.filter(cur => cur !== row.value)) : setSelected([...(selected || []), row.value]);
  };

  React.useEffect(() => {
    (!isInit) ? onChange(false, { value: selected }) : setIsInit(false);
  }, [selected]);

  return (
    <div>
      { description && <Description>{ description }</Description>}
      {
      options.map(row => {
        const { ShowSelected } = row;
        return (
          <RadioOption
            {...{ disabled, showBorder, multiple, label: row.text, checked: isSelected(row.value), ShowSelected }}
            onClick={() => (!disabled ? selectRow(row) : false)}
          />
        );
      })
    }
    </div>
  );
};

export default RadioOptions;

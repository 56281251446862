import React from 'react';
import { Modal, Button, Header } from 'semantic-ui-react';
import RunButton from '../../../components/parts/RunButton';

export const Error = ({ jobId, startIntercomChat }) => (
  <div className="upgrade-error">
    <Header
      as="h2"
      content="Sorry, we had an issue upgrading your account"
      subheader="Please contact us for further assistance in getting it resolved"
      textAlign="center"
    />
    <Button
      fluid
      primary
      onClick={() => startIntercomChat(jobId)}
      content="Contact Us"
    />
  </div>
);

export const UnavailableContent = ({ route }) => (
  <Modal.Content>
    <p>
      Pardon the interruption, we&apos;re still working on migrating all our views to the new Rockerbox UI.
    </p>
    <p>
      To view this page, you&apos;ll need to navigate back to the classic Rockerbox UI.
    </p>
    <Button
      fluid
      primary
      as="a"
      target="_blank"
      href={route || '/v2'}
      content="Open Rockerbox Classic UI"
      icon="external"
      labelPosition="right"
    />
  </Modal.Content>
);

export const UpgradeContent = ({ username, tier, is_shopify_hosted, error, setError, startIntercomChat, setJobRunning }) => (
  <Modal.Content>
    { error
      ? <Error {...{ jobId: error, startIntercomChat }} />
      : (tier === 'starter' || !is_shopify_hosted)
        // starter and non-shopify free content
        ? (
          <>
            <p>
              Your current plan only includes access to digital advertising platforms and their self-reported performance.
            </p>
            <p>
              To view deduplicated conversions, multi-touch attribution, and advanced measurement for online and offline channels, you&apos;ll need to upgrade.
              For more information on available plans and pricing, contact us!
            </p>
            <Button
              fluid
              primary
              as="a"
              target="_blank"
              href={`https://www.rockerbox.com/demo?email=${username}`}
              content="Contact Us to Upgrade"
              icon="external"
              labelPosition="right"
            />
          </>
        )
        : (
          <>
            <p>
              Your current plan only includes access to digital advertising platforms and their self-reported performance.
            </p>
            <p>
              Upgrade to Rockerbox Starter to view deduplicated conversions, multi-touch attribution, and advanced measurement for online and offline channels!
            </p>
            <RunButton
              primary
              fluid
              icon="external"
              needJobId
              jobTypeKey={`set_advertiser_tier_${new Date().getTime()}`}
              path="setup/set_advertiser_tier"
              data={{ tier: 'starter' }}
              onStart={() => setJobRunning(true)}
              onSuccess={() => {
                window.location = '/onboarding';
              }}
              onError={d => {
                setJobRunning(false);
                setError(d);
              }}
              content="Upgrade"
            />
          </>
        )}
  </Modal.Content>
);

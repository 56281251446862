import { useState, useEffect, useMemo } from 'react';

import { createAdvertisingIntegrationsList, determineConnectedAccounts, processPlatformData } from './helpers';
import { getOnsiteDataset } from '../../api/attributionReporting';
import { yesterdayTrackingQa } from '../../utils/time';
import { useGlobalState } from '../global';

export const useMissingParams = () => {
  const { segments, integrations: advertiserIntegrations, authorizations } = useGlobalState();
  const [missingParamsSummary, setMissingParamsSummary] = useState(undefined);

  const allPagesSegment = useMemo(() => {
    if (!segments) return null;
    return segments.find(s => s.action_type === 'segment');
  }, [segments]);

  useEffect(() => {
    if (!allPagesSegment || !authorizations || !advertiserIntegrations) return;
    const advertisingIntegrations = createAdvertisingIntegrationsList(advertiserIntegrations);
    const connectedAccounts = determineConnectedAccounts(authorizations, advertisingIntegrations);

    getOnsiteDataset(allPagesSegment.action_id, 'tracking_qa_results', yesterdayTrackingQa, yesterdayTrackingQa)
      .then(resp => {
        const trackingQAData = resp?.tracking_qa_results?.[0] || {};
        Promise.all(connectedAccounts.map(platform => processPlatformData(platform, authorizations, trackingQAData)))
          .then(results => {
            const modifiedResults = results.reduce((acc, result) => ({ ...acc, ...result }), {});
            setMissingParamsSummary(modifiedResults);
          });
      });
  }, [allPagesSegment, authorizations, advertiserIntegrations]);

  return {
    missingParamsSummary,
  };
};

import React from 'react';
import RedshiftInitialize from './RedshiftInitialize';
import RedshiftGrantPermission from './RedshiftGrantPermission';
import InlinePolicy from './InlinePolicy';
import CreateExternalSchema from './CreateExternalSchema';
import RedshiftCreateTable from './RedshiftCreateTable';

const GrantPermissionDescription = (
  <>
    <a href="https://help.rockerbox.com/docs/warehousing-getting-started-redshift-step-1" target="_blank" style={{ textDecoration: 'underline' }} rel="noreferrer">Follow these instructions</a>
    {' '}
    {/* eslint-disable-next-line react/no-unescaped-entities */}
    to create an IAM Role within AWS that Rockerbox will use to connect to your warehouse. After creating the role, you'll be provided an ARN, which you'll enter into the field below.
  </>
);

const InlinePolicyDescription = (
  <>
    Copy the code block below and
    {' '}
    <a href="https://help.rockerbox.com/docs/warehousing-getting-started-redshift-step-2" target="_blank" style={{ textDecoration: 'underline' }} rel="noreferrer">follow these instructions</a>
    {' '}
    to add the following inline policy to your IAM Role.
  </>
);

const CreateExternalSchemaDescription = (
  <>
    Copy the code block below and
    {' '}
    <a href="https://help.rockerbox.com/docs/warehousing-getting-started-redshift-step-3" target="_blank" style={{ textDecoration: 'underline' }} rel="noreferrer">follow these instructions</a>
    {' '}
    to create your Rockerbox schema in AWS Redshift.
  </>
);

export default {
  name: 'Redshift',
  logo: 'redshift-logo.png',
  steps: [
    {
      order: 1,
      description: 'Select the AWS region that your Redshift cluster is in.',
      component: RedshiftInitialize,
    },
    {
      order: 2,
      description: GrantPermissionDescription,
      component: RedshiftGrantPermission,
      requires: [1],
    },
    {
      order: 3,
      description: InlinePolicyDescription,
      component: InlinePolicy,
      requires: [2],
    },
    {
      order: 4,
      description: CreateExternalSchemaDescription,
      component: CreateExternalSchema,
      requires: [2],
    },
    {
      order: 5,
      description: 'Create your tables',
      component: RedshiftCreateTable,
      requires: [2],
    },
  ],
};

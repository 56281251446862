import {
  getData as getDataDataReporting,
  getMetaData as getMetaDataDataReporting,
} from './apis/data_reporting';
import {
  getData as getDataReportCache,
  getMetaData as getMetaDataReportCache,
} from './apis/report_cache';

import { initCache } from './cache';

const dataAPIMapping = {
  platform_performance: {
    version: {
      legacy: {
        format: 'parquet',
        getData: getDataReportCache('platform_performance'),
        getMetaData: getMetaDataReportCache('platform_performance'),
      },
    },
    default: 'legacy',
  },
  time_to_conversion: {
    version: {
      // "legacy": {
      //     "format": "json",
      //     "getData": getDataCache("time_to_conversion"),
      //     "getMetaData": getMetaDataCache("time_to_conversion")
      // },
      'data-services-v1': {
        format: 'parquet',
        getData: getDataDataReporting('time-to-conversion'),
        getMetaData: getMetaDataDataReporting('time-to-conversion'),
      },
    },
    default: 'data-services-v1',
  },
  compiled_mta_tiers: {
    version: {
      legacy: {
        format: 'parquet',
        getData: getDataReportCache('compiled_mta_tiers'),
        getMetaData: getMetaDataReportCache('compiled_mta_tiers'),
      },
      'data-services-v1': {
        format: 'parquet',
        getData: getDataDataReporting('spend-mta'),
        getMetaData: getMetaDataDataReporting('spend-mta'),
      },
    },
    default: 'data-services-v1',
  },
  compiled_mta_tiers_limited: {
    version: {
      'data-services-v1-limited': {
        format: 'parquet',
        getData: getDataDataReporting('spend-mta-limited'),
        getMetaData: getMetaDataDataReporting('spend-mta'),
      },
    },
    default: 'data-services-v1-limited',
  },
};

const getAPI = (dataset, _version) => {
  const datasetObj = dataAPIMapping[dataset];
  const version = _version || datasetObj.default;
  const api = datasetObj.version[version];
  return api;
};

export const getCache = (dataset, version) => {
  const api = getAPI(dataset, version);
  const { getData, getMetaData } = api;

  // TODO: add format to cache

  const cache = initCache(1, getMetaData, getData);
  return cache;
};

export default getCache;

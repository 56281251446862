import React, { useEffect, useState } from 'react';
import { Form, Button, Step } from 'semantic-ui-react';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
// TODO: convert component to use MultiStepLayout
import { MultiStepLayout } from '@rockerbox/styleguide'; // eslint-disable-line
import { getSurveyConfigs, postSurveyData, updateSurveyData } from '../../../api/attribution';
import GeneralSetup from './SetupGeneral';
import ResponseSetup from './SetupResponses';
import ConversionSetup from './SetupConversion';
import EventSetup from './SetupEvents';

const STEPS = [
  { value: 'general', text: 'General Setup', validationFields: ['displayName', 'survey', 'surveyResponseKey'] },
  { value: 'responses', text: 'Organize Responses', validationFields: ['responses', 'defaultResponse'] },
  { value: 'events', text: 'Marketing Events', validationIf: ['conversion'], validationFields: ['events'] },
];

const defaultState = {
  activeStep: 'general',
  surveyKey: '',
  conversionKey: '',
  questionKey: '',
  displayName: '',
  conversion: '',
  survey: '',
  surveyResponseKey: '',
  id: '',
  events: [],
  excluded: '',
  defaultResponse: 'Other',
  responses: [{ response_display: '', response_values: '' }],
  fieldErrors: { disable: true },
  ruleValues: [],
};

const CreateSurvey = () => {
  const { id } = useParams();
  const history = useHistory();
  const [state, _setState] = useState(defaultState);
  const setState = newState => _setState(existingState => ({ ...existingState, ...newState }));

  const { activeStep } = state;

  useEffect(() => {
    if (id) {
      getSurveyConfigs().then(surveyData => {
        const selected = surveyData.find(c => Number(c.id) === Number(id));

        const ruleValues = selected.responses.reduce((p, c) => p.concat(c.response_values.split('|')), []);

        setState({
          displayName: selected.display_name,
          conversion: selected.conversion_id,
          conversionKey: selected.conversion_key,
          questionKey: selected.question_key,
          survey: selected.survey_id,
          surveyKey: selected.survey_key,
          surveyResponseKey: selected.survey_response_key,
          id: selected.id,
          events: selected.events,
          excluded: selected.no_response_values,
          defaultResponse: selected.default_response_value || 'Other',
          responses: selected.responses,
          ruleValues,
          fieldErrors: {},
        });
      });
    }
  }, [id]);

  const setResponseValues = responses => {
    setState({ responses });
  };

  const validateSteps = step => {
    const nextStepPosition = STEPS.map(({ value }) => value).indexOf(step) + 1;

    const fieldErrors = {};
    STEPS.slice(0, nextStepPosition).forEach(({ validationFields }) => {
      validationFields.forEach(field => {
        const value = state[field];
        if (value.length === 0) {
          fieldErrors[field] = 'Value is missing';
        }
      });
    });
    return { fieldErrors };
  };

  const handleChange = (val, key) => {
    const newState = { ...state, activeStep, ...validateSteps(activeStep) };
    newState[key] = val;
    setState(newState);
  };

  const updateStateArrayRowField = (stateSlice, field, idKey = 'id', newIdKey = 'new_id') => item => (e, { value }) => {
    const stateCopy = _.cloneDeep(state);
    const selectedObj = item.new_id ? stateCopy[stateSlice].find(c => c[newIdKey] === item[newIdKey])
      : stateCopy[stateSlice].find(c => c[idKey] === item[idKey]);
    selectedObj[field] = value;

    setState({ ...stateCopy, ...validateSteps(activeStep) });
  };

  const handleSubmit = () => {
    const {
      displayName, surveyKey, questionKey, conversionKey, surveyResponseKey,
      survey, conversion, events, excluded, defaultResponse, responses,
    } = state;

    const postObj = {
      display_name: displayName,
      survey_key: surveyKey,
      survey_id: survey,
      conversion_key: conversionKey,
      question_key: questionKey,
      conversion_id: conversion,
      survey_response_key: surveyResponseKey,
      events: events.length > 0 ? events.filter(c => c.response) : [],
      no_response_values: excluded,
      default_response_value: defaultResponse,
      responses: responses.length > 0 ? responses.filter(c => c.response_display && c.response_values) : [],
    };

    const send = id ? updateSurveyData : postSurveyData;

    send(postObj, id).then(() => {
      history.push('/v3/data/marketing/surveys/main');
    });
  };

  const removeAdvanced = key => pos => {
    const stateCopy = _.cloneDeep(state);
    if (key === 'events') {
      stateCopy.events.splice(pos, 1);
    } else {
      stateCopy.responses.splice(pos, 1);
    }
    setState(stateCopy);
  };

  const addAdvanced = (key, value) => {
    const stateCopy = _.cloneDeep(state);

    if (key === 'events') {
      const new_id = `new_${stateCopy.events.length}_${parseInt(Math.random(1, 100) * 10000000)}`;
      const eventsObj = {
        response: '',
        mta_tier_1: '',
        mta_tier_2: '',
        mta_tier_3: '',
        mta_tier_4: '',
        mta_tier_5: '',
        tier_1: '',
        tier_2: '',
        tier_3: '',
        tier_4: '',
        tier_5: '',
        new_id,
      };

      stateCopy.events.push(eventsObj);
    } else {
      const new_id = `new_${stateCopy.responses.length}_${parseInt(Math.random(1, 100) * 10000000)}`;
      const responseObj = value
        ? Object.assign(value, { new_id })
        : {
          response_display: '',
          response_values: '',
          new_id,
        };

      stateCopy.responses = stateCopy.responses.filter(row => !(row.response_display === '' && row.response_values === ''));
      stateCopy.responses.push(responseObj);
    }

    setState(stateCopy);
  };

  const setActiveStep = step => {
    const newState = { ...state, activeStep: step, ...validateSteps(step) };
    setState(newState);
  };

  const setNextStep = () => {
    const nextStepPosition = STEPS.map(({ value }) => value).indexOf(state.activeStep) + 1;
    const nextStep = STEPS[nextStepPosition].value;
    const newState = { ...state, activeStep: nextStep, ...validateSteps(nextStep) };
    setState(newState);
  };

  const getActiveComponent = step => {
    const {
      displayName, surveyKey, questionKey, conversionKey, surveyResponseKey,
      survey, conversion, events, excluded, defaultResponse, responses, ruleValues,
    } = state;

    switch (step) {
      case 'general':
        return (
          <GeneralSetup
            {...{
              id, handleChange, displayName,
              questionKey, survey, surveyKey, surveyResponseKey,
              conversion, conversionKey,
            }}
          />
        );
      case 'responses':
        return (
          <ResponseSetup
            {...{
              survey, conversion, surveyResponseKey, excluded, defaultResponse, responses, ruleValues,
              setResponseValues, handleChange, updateStateArrayRowField, addAdvanced, removeAdvanced,
            }}
          />
        );
      case 'conversion':
        return (
          <ConversionSetup {...{ handleChange, survey, surveyKey, conversion, conversionKey }} />
        );
      case 'events':
        return (
          <EventSetup {...{
            survey, events, surveyResponseKey, responses, defaultResponse,
            updateStateArrayRowField, addAdvanced, removeAdvanced,
          }}
          />
        );
      default:
        return null;
    }
  };

  const activeComponent = getActiveComponent(activeStep);
  const showSubmit = STEPS.map(({ value }) => value).indexOf(activeStep) + 1 === STEPS.length;
  const isDisabled = Object.keys(state.fieldErrors).length > 0;
  return (
    <Form onSubmit={handleSubmit}>
      <Step.Group widths={STEPS.length} size="mini">
        {STEPS.map(({ value, text }) => (
          <Step active={activeStep === value} onClick={() => setActiveStep(value)}>
            <Step.Content>
              <Step.Title>{text}</Step.Title>
            </Step.Content>
          </Step>
        ))}
      </Step.Group>
      {activeComponent}
      <br />
      <div style={{ position: 'fixed', bottom: '20px', right: '20px' }}>
        {showSubmit ? (
          <Button size="big" color="green" disabled={isDisabled} type="submit" content="Submit" icon="right check" labelPosition="right" />
        ) : (
          <Button size="big" disabled={isDisabled} as="a" primary content="Next" onClick={setNextStep} icon="right arrow" labelPosition="right" />
        )}
      </div>
    </Form>
  );
};

export default CreateSurvey;

import React from 'react';
import { connect } from 'react-redux';
import { rootAction } from '../../rootAction';
import Fetch from '../parts/GenericFetch';
import ReportDeliveryStatus from './component';
import { SET_REPORT_DELIVERY_STATUS, RESET_REPORT_DELIVERY_STATUS } from './constants';

const ReportDeliveryStatusWrapper = (props) => (
  [
    <Fetch endpoint={`/reporting/delivery/scheduled_report?id=${props.match.params.id}`} success={props.setReportDeliveryStatus} />,
    <ReportDeliveryStatus {...props} />
  ]
)


function mapDispatchToProps(dispatch) {
  return {
    setReportDeliveryStatus: (data) => dispatch(rootAction(SET_REPORT_DELIVERY_STATUS,data)),
    resetReportDeliveryStatus: () => dispatch(rootAction(RESET_REPORT_DELIVERY_STATUS))
  }
}


function mapStateToProps(state) {
  return {
    ...state.reportDeliveryStatus,
    dates:state.reportDeliveryStatus.dates,
    reportDeliveryStatusData: state.reportDeliveryStatus.reportDeliveryStatusData,
    report: state.reportDeliveryStatus.report,
    resolved: state.reportDeliveryStatus.resolved
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDeliveryStatusWrapper);

import React from 'react';

import { Icon } from 'semantic-ui-react';

const ChartTooltip = ({
  active, payload, reverseOrder,
  label, sortKey, sortOrder = 'desc', nameMap = {}, labelFormatter = x => x,
  formatter = x => x,
  showTotalInToolTip = false,
  hideKeys, dataKey, allCaps = false,
  showDataStatus, isBadDate,
}) => {
  if (active && payload && payload.length) {
    const total = payload.reduce((accu, { value }) => accu += value, 0); // eslint-disable-line no-param-reassign, no-return-assign
    const capitalize = allCaps ? word => word.toUpperCase() : word => word.charAt(0).toUpperCase() + word.slice(1);

    if (reverseOrder) payload.reverse();
    return (
      <div style={{
        borderRadius: 4,
        background: '#fff',
        color: '#000',
        boxShadow: '0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15)',
      }}
      >
        <div style={{ padding: 10 }}>
          {!!label && (
            <p
              style={{
                fontSize: '14px',
                color: '#4d4d4d',
              }}
            >
              {labelFormatter(label)}
            </p>
          )}
          {payload
            .filter(({ name }) => name.length > 0 && name !== dataKey && !hideKeys?.includes(name))
            .sort((a, b) => {
              // Only sort if sortKey is provided
              if (sortKey) {
                const valueA = a[sortKey];
                const valueB = b[sortKey];
                if (valueA < valueB) return sortOrder === 'asc' ? -1 : 1;
                if (valueA > valueB) return sortOrder === 'asc' ? 1 : -1;
              }
              return 0; // Do not sort if sortKey is not provided
            })
            .map(({ name, value, color }, i) => {
              const colorTip = color || payload[i].payload.fill;
              return (
                <p
                  key={name}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginBottom: i < payload.length - 1 || showTotalInToolTip ? 5 : 0,
                  }}
                >
                  <span style={{ marginRight: 20 }}>
                    <Icon name="square" style={{ color: colorTip }} />
                    {nameMap[name] || capitalize(name)}
                  </span>
                  <span style={{ fontFeatureSettings: '"kern" 1, "tnum" 1' }}>{formatter(value)}</span>
                </p>
              );
            })}
          {showTotalInToolTip && (
            <p
              key="total"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                paddingTop: '10px',
                borderTop: '1px solid grey',
                fontWeight: 'bold',
              }}
            >
              <span style={{ marginRight: 20 }}>Total</span>
              <span style={{ fontFeatureSettings: '"kern" 1, "tnum" 1' }}>{formatter(total)}</span>
            </p>
          )}
        </div>
        {showDataStatus && isBadDate(label) && (
          <div style={{
            width: '100%',
            textAlign: 'center',
            backgroundColor: '#e9eaf1',
          }}
          >
            Incomplete day
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default ChartTooltip;

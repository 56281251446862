import React, { Component } from 'react';
import { Segment, Dropdown, Button, Form } from 'semantic-ui-react';
import { ContentCard } from '@rockerbox/styleguide'
import { withRouter } from 'react-router-dom';
import * as routes from '../../../routes';

import { getAuthorizations, createFacebookAdsConfig, getSegments } from '../../../utils/api';


class FacebookAdsForm extends Component {

  state = {
    account_id: false,
    facebookAuths: [],
    selectedAuth: undefined,
    allPagesId: undefined
  }

  componentDidMount() {
    this.getFacebookAccounts()
    this.getAllPagesId()
  }

  getFacebookAccounts() {
    getAuthorizations().then(resp => {
      const accounts = resp.filter(row => row.platform.indexOf("facebook") > -1)

      if (accounts.length > 0) {
        const facebookAuths = accounts
          .reduce((p,c) => p.concat(c.authorizations), [])
          .map(row => {
            row.text = row.account_name
            row.value = row.id
            return row
          })
        this.setState({ facebookAuths })
      }
    });
  }

  getAllPagesId() {
    getSegments().then(segments => {
      const allPagesId = segments.find(s => s.action_type == 'segment').action_id;
      this.setState({ allPagesId })
    })
  }

  setDropdownField = (name) => (event, { value }) => {
    this.setState({ [name] : value })
  }

  onSubmit = () => {
    const { selectedAuth, facebookAuths, allPagesId } = this.state;
    const auth = facebookAuths.find(row => row.id == selectedAuth);
    const data = {
      account_id: auth.account_id,
      account_name: auth.account_name,
      allpages_id: allPagesId,
      max_imps: 20
    }

    createFacebookAdsConfig(data).then(resp => {
      this.props.history.push(routes.dataSources + `/view/fb_data`)
    })

  }



  render() {
    const { onSubmit } = this;
    const { facebookAuths, selectedAuth } = this.state;

    return (
      <ContentCard title={`Add Ad Account`} >
        <Form onSubmit={onSubmit}>
          <Segment>
            <Form.Group inline>
              <Form.Field>
                <label>Account</label>
                <Dropdown
                  onChange={this.setDropdownField("selectedAuth")}
                  value={selectedAuth}
                  options={facebookAuths}
                  selection
                />
              </Form.Field>
            </Form.Group>
          </Segment>
          <Button primary type="submit" disabled={selectedAuth == undefined}>Save</Button>
        </Form>
      </ContentCard>
    )
  }
}

export default withRouter(FacebookAdsForm);

import React, { useEffect, useState } from 'react';
import { Header, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import Pricing from './Pricing';
import PaymentMethod from './PaymentMethod';
import CancelSubscription from './CancelSubscription';
import BillingHistory from './BillingHistory';
import { getStripeSubscription, getCustomerID } from '../../api/account';
import { epochToLocalTime } from './helpers';

const Billing = () => {
  const [customerId, setCustomerID] = useState(false);
  const [activeSubscription, setActiveSubscription] = useState(false);
  const [activeThrough, setActiveThrough] = useState(false);
  const [setToCancel, setSetToCancel] = useState(false);

  useEffect(() => {
    getCustomerID()
      .then(customerResponse => {
        const cid = customerResponse.response;
        setCustomerID(cid);
        getStripeSubscription(cid)
          .then(subscriptionResponse => {
            const subscriptionData = subscriptionResponse.response.data;
            const activeDate = epochToLocalTime(subscriptionData[0].current_period_end);
            setSetToCancel(subscriptionData[0].cancel_at_period_end);
            setActiveThrough(activeDate);
            // eslint-disable-next-line no-shadow
            const activeSubscription = subscriptionData.find(x => x.status === 'active');
            setActiveSubscription(activeSubscription);
            if (!activeSubscription) {
              const overdueSubscription = subscriptionData.find(x => x.status === 'past_due');
              setActiveSubscription(overdueSubscription);
            }
          });
      });
  }, []);

  return (
    <div className="starter-settings">
      <Header
        as="h1"
        content="Manage Billing"
        className="page-header"
      />
      <Grid>
        <GridColumn width={7} style={{ marginTop: '33px', marginLeft: '20px' }}>
          {' '}
          <Pricing {...{ customerId, activeSubscription, activeThrough, setToCancel }} />
          {' '}
        </GridColumn>
        <GridColumn width={8}>
          <GridRow className="payment-pane">
            {' '}
            <PaymentMethod {...{ customerId, activeSubscription }} />
            {' '}
          </GridRow>
          <GridRow className="cancel-pane">
            {' '}
            <CancelSubscription />
            {' '}
          </GridRow>
        </GridColumn>
      </Grid>
      <Grid>
        <BillingHistory {...{ activeSubscription }} />
      </Grid>
    </div>
  );
};

export default Billing;

import { useGlobalState } from '../../hooks/global';
import { segmentOptions } from '../../utils/options';
import { getConversionDataSummary, getTiersAsOptions } from '../../api/attributionCache';

const useOptions = (survey, conversion) => {
  const { segments, Context } = useGlobalState();
  const segmentOptionsDropdown = segmentOptions(segments);

  const surveyData = survey
    ? (Context.getCache(`conversionDataSummary${survey}`, getConversionDataSummary, survey) || false) : false;

  const conversionData = conversion
    ? (Context.getCache(`conversionDataSummary${conversion}`, getConversionDataSummary, conversion) || false) : false;

  const featuredSegment = segments.find(seg => seg.featured) ? segments.find(seg => seg.featured).action_id : false;
  const tierOptions = featuredSegment ? Context.getCache(`tiers${featuredSegment}`, getTiersAsOptions, featuredSegment) || false : false;

  return {
    segmentOptionsDropdown,
    surveyData,
    conversionData,
    tierOptions,
  };
};

export default useOptions;

import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import DataSheet, { DataEditor } from 'react-datasheet';
// eslint-disable-next-line import/no-extraneous-dependencies
import DatePicker from 'react-datepicker';
import { Form, Icon } from 'semantic-ui-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datasheet/lib/react-datasheet.css';
import './datasheet.css';
import styled from 'styled-components';

import SearchCell from './SearchCell';
import StatusCell from './StatusCell';

const EditorWrapper = styled.div`
  & > .data-editor {
    height: 2em !important;
  }
`;

const numberFormatOptions = {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};
const numberFormatter = new Intl.NumberFormat('en-US', numberFormatOptions);

const COMPONENTS = {
  // eslint-disable-next-line react/jsx-props-no-spreading
  default: props => <EditorWrapper><DataEditor {...props} /></EditorWrapper>,
  date: props => {
    const { onCommit } = props;
    return <DatePicker utcOffset={0} open onChange={value => onCommit(moment.utc(value).format('YYYY-MM-DD'))} />;
  },
  select: props => {
    const { onCommit, column } = props;
    const { options, editProps } = column;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Form.Dropdown search options={options} {...props} {...editProps} value={false} onChange={(_, { value }) => { onCommit(value); }} />;
  },
  currency: props => {
    const { onChange } = props;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <DataEditor style={{ width: '100%' }} {...props} onChange={value => onChange(value.replace('$', ''))} />;
  },
  percent: props => {
    const { onChange } = props;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <DataEditor style={{ width: '100%' }} {...props} onChange={value => onChange(value.replace('%', ''))} />;
  },
};

const HoverWrapper = styled.div`
  opacity: .5;
  width: 110%;
  text-align: center;

  &:hover {
    opacity:1
  }
`;

const DataTable = ({ columns, data, onUpdate, onRemove, rowProps, readOnly: tableReadOnly }) => {
  const gridValues = data.map(row => columns.map(({ key, readOnly: columnReadOnly }) => ({ value: row[key], readOnly: columnReadOnly })));
  const grid = gridValues;
  const cols = columns.map(({ key, display, style }) => ({ key, display, style }));

  const onChange = clonedData => ({ row, col, value }) => {
    // eslint-disable-next-line no-param-reassign
    clonedData[row][cols[col].key] = value;
  };

  const onCellsChanged = (array, additions) => {
    // eslint-disable-next-line no-undef
    const clonedData = _.cloneDeep(data);
    const update = onChange(clonedData);
    array.forEach(valueUpdate => {
      update(valueUpdate);
    });
    if (additions && additions.length > 0) {
      const newValues = additions.map(() => ({}));
      additions.forEach((valueUpdate, i) => {
        clonedData.push(newValues[i]);
        update(valueUpdate);
      });
    }

    // Race condition with keyboard events
    setTimeout(() => onUpdate(clonedData.filter(row => Object.keys(row).length > 0)), 1);
  };

  const onValueRender = ((cellData, row, col) => {
    const { type, asView } = columns[col];
    if (!cellData.value || String(cellData.value).trim().length === 0) return '';

    if (!!asView) {
      const Comp = asView;
      return <Comp {...{ cell: cellData, row: data[row], rowProps }} />;
    }

    const value = String(cellData.value);

    return type === 'currency' ? `$${numberFormatter.format(value.replace('$', ''))}`
      : type === 'percent' ? `${value.replace('%', '')}%`
        : type === 'date' ? value
          : type === 'number' ? numberFormatter.format(value) : value;
  });

  const onReadOnly = ((cellData, row, col) => {
    // eslint-disable-next-line no-param-reassign
    cellData.readOnly = true;
    return onValueRender(cellData, row, col);
  });

  return (
    <DataSheet
      readOnly="true"
      onCellsChanged={onCellsChanged}
      data={grid}
      // eslint-disable-next-line react/no-unstable-nested-components
      sheetRenderer={props => (
        <table className={`${props.className} my-awesome-extra-class`} style={{ width: '100%' }}>
          <thead>
            <tr style={{ height: '1.9em', lineHeight: '1.9em' }}>
              { onRemove && <th className="cell read-only" style={{ height: '1.9em', width: '1.9em' }} /> }
              { cols.map(({ style, display }) => (
                <th {...{ style }} className="cell read-only">
                  {display}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {' '}
            {props.children}
            {' '}
          </tbody>
        </table>
      )}
      // eslint-disable-next-line react/no-unstable-nested-components
      rowRenderer={props => (
        <tr style={{ height: '2.0em', lineHeight: '2.0em' }}>
          { onRemove && <td><HoverWrapper onClick={() => onRemove(data[props.row])}><Icon name="remove" /></HoverWrapper></td> }
          {props.children}
        </tr>
      )}
      valueRenderer={tableReadOnly ? onReadOnly : onValueRender}
      // eslint-disable-next-line react/no-unstable-nested-components
      dataEditor={props => {
        const { col: colIndex } = props;
        const column = columns[colIndex];
        const { type, asEdit } = column;
        const Component = asEdit || COMPONENTS[type] || COMPONENTS.default;

        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Component {...props} {...{ column }} />;
      }}
    />
  );
};

DataTable.propTypes = {
  /** Array of grid row objects */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
    }),
  ).isRequired,
  /** Array of column objects */
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    display: PropTypes.string,
    type: PropTypes.string,
    asCell: PropTypes.element,
    asEdit: PropTypes.element,
  })).isRequired,
  /** */
  onUpdate: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default Object.assign(DataTable, { StatusCell, SearchCell });

import { useState, useEffect, useMemo } from 'react';

import { useAtom } from 'jotai';

import { reformatDateYYYYMMDD, dateIsBetween, generateDateRangesByType, reformatDateMMDDYYYY } from '../../../parts/time';
import { startDateBaselineAtom, endDateBaselineAtom } from '../../atoms';

export const useBaselinePeriod = ({ trainingData, projectionPeriod }) => {
  const [startDate, setStartDate] = useAtom(startDateBaselineAtom);
  const [endDate, setEndDate] = useAtom(endDateBaselineAtom);
  const [dateRange, setDateRange] = useState([]);
  const [options, setOptions] = useState([]);

  const onDateChange = ({ startDate: start, endDate: end }) => {
    setStartDate(start);
    setEndDate(end);
  };

  const periodType = projectionPeriod > 30 ? 'quarter' : 'month';
  const loading = !(startDate && endDate);

  useEffect(() => {
    if (!trainingData) return;
    const dates = trainingData.map(row => row.date);
    const rangeByMonth = generateDateRangesByType(dates, 'month');
    const rangeByQuarter = generateDateRangesByType(dates, 'quarter');
    setOptions([...rangeByMonth, ...rangeByQuarter]);
    setDateRange([dates[0], dates[dates.length - 1]]);
  }, [trainingData]);

  const selectedData = useMemo(() => {
    if (!trainingData || !startDate || !endDate || loading) return [];
    return trainingData.filter(row => dateIsBetween(row.date, startDate, endDate));
  }, [trainingData, startDate, endDate, loading]);

  const featureStats = useMemo(() => {
    if (!selectedData.length) return;
    const total = selectedData.reduce((accu, row) => {
      Object.keys(row).forEach(key => {
        if (key === 'date') return;
        accu[key] = (accu[key] || 0) + row[key];
      });
      return accu;
    }, {});

    return Object.keys(total).reduce((accu, key) => {
      accu[key] = { currentSpend: parseInt(total[key] / selectedData.length / 50) * 50 };
      return accu;
    }, {});
  }, [selectedData]);

  const formattedOptions = useMemo(() => {
    if (!options.length) return [];
    return options
      .filter(({ type }) => type === periodType)
      .map(option => ({
        ...option,
        key: option.text,
        value: `${reformatDateMMDDYYYY(option.startDate)} - ${reformatDateMMDDYYYY(option.endDate)}`,
      }))
      .reverse();
  }, [projectionPeriod, options]);

  useEffect(() => {
    if (formattedOptions.length === 0 || !formattedOptions[0].value) return;
    const initialPeriod = formattedOptions[0].value;
    const [initialStartDate, initialEndDate] = initialPeriod.split(' - ');
    setStartDate(reformatDateYYYYMMDD(initialStartDate));
    setEndDate(reformatDateYYYYMMDD(initialEndDate));
  }, [formattedOptions]);

  const formattedPeriod = useMemo(() => {
    if (loading) return;
    const value = `${reformatDateMMDDYYYY(startDate)} - ${reformatDateMMDDYYYY(endDate)}`;
    return formattedOptions.find(option => option.value === value)?.text || value;
  }, [startDate, endDate, formattedOptions]);

  return {
    loading, startDate, endDate, onDateChange, dateRange,
    formattedPeriod, formattedOptions,
    featureStats,
  };
};

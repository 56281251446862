import React, { useEffect, useState } from 'react';
import { Segment, Form, Dropdown, Button } from 'semantic-ui-react';
import { getFacebookConversions } from '../../utils/api';


const Row = (props) => {
  const {rowData, facebookAuths, onChange, position, removeFromList} = props;
  const [ selected_auth, setSelectedAuth ] = useState(rowData.selected_auth || "");
  const [ platform_conversion, setPlatformConversion ] = useState(rowData.platform_conversion || "");
  const [ facebookSegments, setFacebookSegments ] = useState([]);
  const [ account_id, setAccountId ] = useState(rowData.platform_account_id || "");

  useEffect(() => {
    setSelectedAuth(rowData.selected_auth);
    setPlatformConversion(rowData.platform_conversion);
    setAccountId(rowData.platform_account_id);
  }, [rowData.selected_auth, rowData.platform_conversion, rowData.platform_account_id])

  useEffect(() => {
    getAllFacebookConversions();
  }, [])

  useEffect(() => {
    const changedAuth = selected_auth != props.selected_auth;
    const changedConv = platform_conversion != props.platform_conversion;
    const changedAcctId = account_id != props.platform_account_id;
    if (changedAuth || changedConv || changedAcctId) onChange(false, { value: {selected_auth, platform_conversion, account_id}})
  }, [selected_auth, platform_conversion])

  useEffect(() => {
    getAllFacebookConversions();
  }, [selected_auth])

  const getAllFacebookConversions = () => {
    if (selected_auth) {
      getFacebookConversions(selected_auth)
      .then(resp => {
        const facebookSegments = [
          {"text":"View Content","value":"offsite_conversion.fb_pixel_view_content"},
          {"text":"Purchase","value":"offsite_conversion.fb_pixel_purchase"},
          {"text":"Add Payment Info","value":"offsite_conversion.fb_pixel_add_payment_info"},
          {"text":"Add To Cart","value":"offsite_conversion.fb_pixel_add_to_cart"},
          {"text":"Complete Registration","value":"offsite_conversion.fb_pixel_complete_registration"},
          {"text":"Initiate Checkout","value":"offsite_conversion.fb_pixel_initiate_checkout"}
        ].concat(resp
          .map(row => {
            row.text = row.name
            row.value = `offsite_conversion.custom.${row.id}`

            return row
          })
        )

      const { account_id } = facebookAuths.find(row => row.id == selected_auth) || {};

      setAccountId(account_id);
      setFacebookSegments(facebookSegments);
      })
    }
  }

  return (
    <React.Fragment>
      {rowData.deleted === 0 && <Segment secondary>
        <label>Facebook</label>
        <Form.Group inline>
          <Form.Field>
            <label>Account</label>
            <Dropdown 
              onChange={(_, {value}) => {
                setSelectedAuth(value);
                setPlatformConversion("")
              }}
              value={selected_auth}
              options={facebookAuths}
              selection
            />
          </Form.Field>
          <Form.Field>
            <label>Conversion Segment </label>
            <Dropdown 
              onChange={(_, {value}) => setPlatformConversion(value)}
              value={platform_conversion}
              options={facebookSegments}
              selection
            />
          </Form.Field>
          <Form.Field>
            <Button icon="close" onClick={e => removeFromList(position)}/>
          </Form.Field>
        </Form.Group>
      </Segment>}
   </React.Fragment>
  )
}

export default Row;
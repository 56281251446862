import moment from 'moment';

import { urlDecodeTierStr } from '../../utils/urlFormatter';
import { DEFAULT_TIERS_ARR, TIERS } from '../../constants/tiers';
import { ATTRIBUTABLE_MODELS } from '../../constants/options';
import { groupChartData } from '../../utils/groupChartData';

export const generateDateRange = (startDate, endDate) => {
  const dateArray = [];
  const stopDate = moment(endDate);
  let currentDate = moment(startDate);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
};

export const tierFiltersToColumnNameMap = (tierFilters, columnNames) =>
  // TODO: unittest
  TIERS.reduce((accu, tier, i) => { // eslint-disable-line implicit-arrow-linebreak
    const columnName = columnNames[i];
    const currentTier = tierFilters[tier];
    const tierSelections = typeof (currentTier) === 'string' ? currentTier.split(',') : currentTier;
    const decodedTierSelections = tierSelections.map(urlDecodeTierStr).filter(v => v.length);
    accu[columnName] = decodedTierSelections;
    return accu;
  }, {});

export const platformConfigViewToColumns = (view, platformConfig) => {
  const hierarchy = (view === 'comparison') ? DEFAULT_TIERS_ARR : platformConfig.hierarchy.slice(1);
  return hierarchy.map(c => c.value);
};

export const buildModelOptions = hasModel => (hasModel === 1 ? ATTRIBUTABLE_MODELS : ATTRIBUTABLE_MODELS.filter(x => x.value !== 'normalized'));

export const prettyDisplayNames = text => {
  if (!text) return text;
  const format = text.replaceAll('d_', 'D_').replaceAll('_', ' ').replaceAll('.', ' ');
  const spaceCamelCase = format.replace(/([a-z])([A-Z])/g, '$1 $2');
  const words = spaceCamelCase.split(' ');
  const addCaps = words.map(word => {
    try {
      return word[0].toUpperCase() + word.substring(1);
    } catch (e) {
      return word;
    }
  }).join(' ');
  if (addCaps === '7D Click 1D View') return '7D Click + 1D View';
  return addCaps;
};

export const updateCols = (cols, tiers, tiersNameDisplay, setTiersNameDisplay) => {
  const tier1DisplayName = tiers.map(c => (
    c.text
      ? c.text.replace(' name', '')
      : (prettyDisplayNames(c))
  ))
    .join(' / ');
  const tiersColumnIndex = cols.findIndex(col => col.display === tiersNameDisplay);
  setTiersNameDisplay(tier1DisplayName);
  const updatedCols = cols.slice();
  updatedCols[tiersColumnIndex].display = tier1DisplayName;
  return updatedCols;
};

export const fillDailyPerformanceMissingDates = (dates, _dailyPerformance) => {
  const [first] = _dailyPerformance;
  const performanceByDate = _dailyPerformance
    .reduce((dateObj, row) => Object.assign(dateObj, { [row.date]: row }), {});

  const defaultDataObj = Object.keys(first)
    .reduce((obj, key) => (key === 'date' ? obj : Object.assign(obj, { [key]: 0 })), {});

  return dates.map(date => performanceByDate[date] || ({ date, ...defaultDataObj }));
};

const fixedMetrics = ['clicks', 'conversions', 'impressions', 'revenue', 'spend'];
const fixedMetricsRB = [
  'conversions_assisted_att_win_rb', 'conversions_assisted_rb', 'conversions_even_rb', 'conversions_first_touch_rb', 'conversions_last_touch_rb', 'conversions_normalized_rb',
  'revenue_assisted_att_win_rb', 'revenue_assisted_rb', 'revenue_even_rb', 'revenue_first_touch_rb', 'revenue_last_touch_rb', 'revenue_normalized_rb',
];

const calcsMapping = {
  cpa: d => (d.spend || 0) / d.conversions,
  ctr: d => (d.clicks || 0) / d.impressions,
  cpc: d => (d.spend || 0) / d.clicks,
  cpm: d => (d.spend || 0) / (d.impressions * 1000),
  roas: d => (d.revenue || 0) / d.spend,
};

const calcsRBMapping = {
  cpa_assisted_rb: d => (d.spend || 0) / d.conversions_assisted_rb,
  roas_assisted_rb: d => (d.revenue_assisted_rb || 0) / (d.spend || 0),
  cpa_assisted_att_win_rb: d => (d.spend || 0) / d.conversions_assisted_att_win_rb,
  roas_assisted_att_win_rb: d => (d.revenue_assisted_att_win_rb || 0) / (d.spend || 0),
  cpa_even_rb: d => (d.spend || 0) / d.conversions_even_rb,
  roas_even_rb: d => (d.revenue_even_rb || 0) / (d.spend || 0),
  cpa_first_touch_rb: d => (d.spend || 0) / d.conversions_first_touch_rb,
  roas_first_touch_rb: d => (d.revenue_first_touch_rb || 0) / (d.spend || 0),
  cpa_last_touch_rb: d => (d.spend || 0) / d.conversions_last_touch_rb,
  roas_last_touch_rb: d => (d.revenue_last_touch_rb || 0) / (d.spend || 0),
  cpa_normalized_rb: d => (d.spend || 0) / d.conversions_normalized_rb,
  roas_normalized_rb: d => (d.revenue_normalized_rb || 0) / (d.spend || 0),
};

export const getChartData = (data, groupDatesBy, addRBMetrics = false) => {
  const metrics = addRBMetrics ? [...fixedMetrics, ...fixedMetricsRB] : fixedMetrics;
  const calcs = addRBMetrics ? { ...calcsMapping, ...calcsRBMapping } : calcsMapping;

  if (groupDatesBy !== 'daily' && data) {
    const selectedKeys = [...metrics, ...Object.keys(calcs)];
    const grouped = groupChartData(data, groupDatesBy, selectedKeys);
    grouped.forEach(group => {
      const updatedGroup = { ...group };
      Object.keys(calcs).forEach(metric => {
        updatedGroup[metric] = calcs[metric](updatedGroup);
      });
      return updatedGroup;
    });
    return grouped;
  }
  return data;
};

export const verifyColsUrl = (colsUrlParams, generatedCols) => {
  const hasColsUrl = colsUrlParams.length > 0;
  const hasAllParams = colsUrlParams.filter(colParam => generatedCols.find(col => colParam === col.key));
  const isEqual = colsUrlParams.length === hasAllParams.length;

  return !!((hasColsUrl && isEqual));
};

export const getColumnNames = columns => (
  columns.map(col => {
    if (typeof col === 'string') return col;
    return col.key;
  })
);

export const arrayMatches = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((element, index) => element === arr2[index]);
};

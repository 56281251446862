import React, { Component } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { checkJob, checkJobsCache, trackJob, JOB_SUBMISSION, getLocalJSON, keyFromRow } from '../../../utils/job_api'
import { submitAttributionRunJob } from '../../../utils/api'

const IN_PROGRESS_STATUSES = ["started", "queued", "unknown"]
const JOB_STATUS_KEY = "job_id"

class RunButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      jobStatus: null,
      jobItemKey: null,
      loading: false,
    }
  }

  componentDidMount() {
    const { jobTypeKey, path, data, autorun, onSuccess, onError, onStart } = this.props
    const jobItemKey = generateJobHash(path, data)
    this.setState({ jobItemKey })
    this.getJobs(onSuccess, onError)(jobTypeKey, jobItemKey)
  }

  componentDidUpdate() {
    const { jobTypeKey, path, data, autorun, onSuccess, onError, onStart } = this.props
    const { jobItemKey, jobStatus } = this.state

    if (autorun && jobItemKey && jobStatus === undefined) {
      this.runJob(jobTypeKey, jobItemKey, onSuccess, onError, onStart)(path, data)
    }
  }

  getJobs = (onSuccess, onError) => (jobTypeKey, jobItemKey) => {

    checkJobsCache(jobTypeKey, jobItemKey)
      .then(status => {
        const jobStatus = status[JSON.stringify(JOB_STATUS_KEY)]
        const inProgress = IN_PROGRESS_STATUSES.indexOf(jobStatus) > -1

        if (inProgress) {
          checkJob(JOB_STATUS_KEY, jobTypeKey, jobItemKey)
            .then(() => {
              setTimeout(() => this.getJobs(onSuccess, onError)(jobTypeKey, jobItemKey), 5000)
            })
        }

        if (jobStatus == "success" && onSuccess) onSuccess()

        if (jobStatus == "error" && onError) {
          const { needJobId } = this.props;
          if (needJobId) {
            const KEY1 = `${JOB_SUBMISSION}.${jobTypeKey}.${jobItemKey}`
            const submissionId = getLocalJSON(KEY1)[keyFromRow(JOB_STATUS_KEY)]
            return onError(submissionId)
          }
          onError()
        }

        this.setState({ jobStatus })
      })
  }

  runJob = (jobTypeKey, jobItemKey, onSuccess, onError, onStart) => (path, data) => {
    this.setState({loading: true})
    if (onStart) onStart()
    submitAttributionRunJob(path, data)
      .then(r => {
        this.setState({loading: false})
        trackJob(JOB_STATUS_KEY, jobTypeKey, jobItemKey, r.job_id)
        this.getJobs(onSuccess, onError)(jobTypeKey, jobItemKey)
      })
  }

  render(){
    const { jobStatus, jobItemKey, loading } = this.state
    const { jobTypeKey, path, data, onSuccess, onError, onStart, style, ...contentProps } = this.props

    const submitJob = this.runJob(jobTypeKey, jobItemKey, onSuccess, onError, onStart)
    return(
      <Button
        style={style}
        onClick={() => submitJob(path, data)}
        disabled={jobStatus != undefined || contentProps.disabled || loading}
        {...contentProps}
      >
        <ButtonContent jobStatus={jobStatus} {...contentProps} />
      </Button>
    )
  }
}

const ButtonContent = ({ jobStatus, content, loadingContent, successContent, errorContent }) => {
  switch(jobStatus) {
    case "error":
      return errorContent || <Icon name='warning sign' />
    case "success":
      return successContent || <Icon name='check circle' />
    case undefined:
      return content || <Icon name='play' />
    default:
      return loadingContent || (
        <React.Fragment>
          <Icon loading name='circle notch' className='h-1em w-1em' />
          &nbsp;
          Processing
        </React.Fragment>
      )
  }
}

const generateJobHash = (path, data) => {
  const base = path + JSON.stringify(data)
  const hash = [...base].reduce((a, b) => {
    a = ((a << 5) - a) + b.charCodeAt(0)
    return a & a
  }, 0)
  return hash
}

export default RunButton

import React, { Component } from 'react'
import Swagger from './docs'

const API_DOCS_SWAGGER = "/static/docs/api.yaml"

const ApiDocs = () => (
  <Swagger swagger_url={API_DOCS_SWAGGER} />
)

export default ApiDocs

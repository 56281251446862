import _ from 'lodash';

export function getMMMRuns() {
  const url = '/data_model_run/mmm';
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(data => data?.data);
}

export function getMMMData(id) {
  const url = `/data_model_run/mmm/${id}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(({ data }) => {
      const features = _.uniq(data.roi_mean.filter(x => x.roi_mean > 0).map(x => x.rn)).sort();

      const _predVsActual = _.uniqBy(data.pred_decomp_vs_actual, 'DATE')
        .map(x => ({
          date: x.DATE,
          pred: x.depVarHat,
          actual: x.dep_var,
          delta: Math.abs(x.dep_var - x.depVarHat),
        }));

      const predActualTotal = _predVsActual.reduce((acc, x) => {
        acc.pred += x.pred;
        acc.actual += x.actual;
        return acc;
      }, { pred: 0, actual: 0 });

      const predVsActual = _predVsActual.map(x => {
        const { pred, actual } = predActualTotal;
        const predToActual = pred > 0 ? actual / pred : 0;
        const newPred = x.pred * predToActual;
        return {
          ...x,
          pred: newPred,
          delta: Math.abs(newPred - x.actual),
        };
      });

      const metrics = data.metrics.slice(-1)[0];

      const trainingDataObj = data.training_data.reduce((accu, cur) => {
        const { DATE, media, media_value } = cur;
        if (!accu[DATE]) accu[DATE] = { date: DATE };
        accu[DATE][media] = media_value;
        return accu;
      }, {});
      const trainingDates = Object.keys(trainingDataObj);
      const trainingData = Object.values(trainingDataObj);

      const trainingMinMax = trainingData.reduce((accu, row) => {
        Object.keys(row).forEach(media => {
          if (media === 'date' || !row[media]) return;
          if (!accu[media]) accu[media] = { min: Infinity, max: 0 };
          if (row[media] < accu[media].min) accu[media].min = row[media];
          if (row[media] > accu[media].max) accu[media].max = row[media];
        });
        return accu;
      }, {});
      const trainingTotals = trainingData.slice(-30).reduce((accu, row) => {
        Object.keys(row).forEach(media => {
          if (media === 'date') return;
          if (!accu[media]) accu[media] = 0;
          accu[media] += row[media];
        });
        return accu;
      }, {});
      const trainingStats = Object.keys(trainingTotals).map(media => {
        const spend = trainingTotals[media];
        const num_spend_days = trainingData.filter(x => x[media] > 0).length;
        const mean = !!num_spend_days ? spend / 30 : 0;
        const { min, max } = trainingMinMax[media] || {};
        return {
          feature: media,
          spend_30d: spend || 0,
          spend_mean: mean || 0,
          spend_min: min || 0,
          spend_max: max || 0,
        };
      });

      const seasonality = data.seasonality_holidays.map(x => {
        const day = {
          date: x.DATE,
          sum: 0,
        };
        Object.entries(x).forEach(([key, value]) => {
          if (key === 'DATE') return;
          day[key] = value;
          day.sum += value;
        });
        return day;
      });

      const correlationData = data.correlation_matrix;
      const spendEffectData = data.spend_effect;
      const predVsActualTestData = data.predicted_vs_actual_test;
      const featureROI = data.roi_hat;

      return {
        features,
        correlationData,
        predVsActual,
        metrics,
        trainingDates,
        trainingData,
        trainingStats,
        seasonality,
        raw: data,
        spendEffectData,
        predVsActualTestData,
        featureROI,
      };
    });
}

export const getMMMCustomFeedsData = () => {
  const url = '/data-reporting/mmm-custom-feeds';
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(response => response.response);
};

export const getMMMCustomFeedsDimensions = () => {
  const url = '/data-reporting/mmm-custom-feeds?dimensions=true';
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(response => response.response);
};

export function getMMMCustomChannels() {
  const url = '/mmm/channel';
  return fetch(url, {
    credentials: 'include',
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function postMMMCustomChannel(obj) {
  const url = '/mmm/channel';
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getMMMCustomSubchannels() {
  const url = '/mmm/subchannel';
  return fetch(url, {
    credentials: 'include',
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function postMMMCustomSubchannel(obj) {
  const url = '/mmm/subchannel';
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function putMMMCustomConfiguration(obj) {
  const url = '/mmm/configuration';
  return fetch(url, {
    credentials: 'include',
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getMMMLockStatus() {
  const url = '/mmm/lock';
  return fetch(url, {
    credentials: 'include',
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function postMMMLockStatus(obj) {
  const url = '/mmm/lock';
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  })
    .then(response => response.json())
    .then(data => data.response);
}

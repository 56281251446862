import { useMemo } from 'react';
import { useGlobalState } from '../../../hooks/global';
import { createDropdownOptions, generatePlatformIntegrationMatches, generateOtherPlatformMatches } from './helpers';
import { CHANNEL_MAPPING, STRATEGY_MAPPING } from './constants';

export const usePlatformMappings = () => {
  const { integrations } = useGlobalState();

  const platformIntegrations = useMemo(() => {
    if (!integrations.length) return [];
    return integrations
      .filter(({ category, deprecated }) => category?.includes('advertising') && !deprecated)
      .map(({ platform, display_name }) => ({ platform, display_name }));
  }, [integrations]);

  const platformMappings = useMemo(() => {
    if (!platformIntegrations.length) return {};
    const mappingsFromIntegrations = generatePlatformIntegrationMatches(platformIntegrations);
    const otherMappings = generateOtherPlatformMatches(platformIntegrations);
    return { ...mappingsFromIntegrations, ...otherMappings };
  }, [platformIntegrations]);

  return {
    platformMappings,
  };
};

export const useIndexedMTA = (tableData, analysisType, analysisValue) => {
  const { platformMappings } = usePlatformMappings();
  const tableExists = tableData && tableData.size;
  const {
    channelToEventIndex,
    platformToEventIndex,
    strategyToEventIndex,
  } = useMemo(() => {
    if (!tableExists) return {};

    const data = tableData.objects();
    const newChannelToEventIndex = {};
    const newPlatformToEventIndex = {};
    const newStrategyToEventIndex = {};

    console.time('process data indices'); // eslint-disable-line no-console
    data.forEach(({ channel, platform, strategy }, index) => {
      if (channel) {
        newChannelToEventIndex[channel] = newChannelToEventIndex[channel] || new Set();
        newChannelToEventIndex[channel].add(index);
      }

      if (platform) {
        newPlatformToEventIndex[platform] = newPlatformToEventIndex[platform] || new Set();
        newPlatformToEventIndex[platform].add(index);
      }

      if (strategy) {
        newStrategyToEventIndex[strategy] = newStrategyToEventIndex[strategy] || new Set();
        newStrategyToEventIndex[strategy].add(index);
      }
    });
    console.timeEnd('process data indices'); // eslint-disable-line no-console

    return {
      channelToEventIndex: newChannelToEventIndex,
      strategyToEventIndex: newStrategyToEventIndex,
      platformToEventIndex: newPlatformToEventIndex,
    };
  }, [tableData]);

  const platformStrategyToEventIndex = useMemo(() => {
    if (!tableExists || analysisType !== 'platform' || analysisValue.length === 0) return {};

    const analysisSearchString = analysisValue.join("', '");
    const data = tableData
      .filter(`(d, $) => op.includes(['${analysisSearchString}'], d['platform'])`)
      .objects();

    const newPlatformStrategyToEventIndex = {};

    data.forEach(({ strategy }, index) => {
      if (strategy) {
        newPlatformStrategyToEventIndex[strategy] = newPlatformStrategyToEventIndex[strategy] || new Set();
        newPlatformStrategyToEventIndex[strategy].add(index);
      }
    });
    console.timeEnd('process data indices'); // eslint-disable-line no-console

    return newPlatformStrategyToEventIndex;
  }, [tableData, analysisValue]);

  const channelOptions = createDropdownOptions(channelToEventIndex, CHANNEL_MAPPING);
  const platformOptions = createDropdownOptions(platformToEventIndex, platformMappings);
  const strategyOptions = createDropdownOptions(strategyToEventIndex, STRATEGY_MAPPING);
  const platformStrategyOptions = createDropdownOptions(platformStrategyToEventIndex, STRATEGY_MAPPING);

  return {
    channelOptions,
    platformOptions,
    strategyOptions,
    platformStrategyOptions,
  };
};

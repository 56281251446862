import * as aq from 'arquero';
import { initDb } from '../db';
import { parseArch } from '../../parquetToJson';
import {
  db_clearInvalidCache,
  db_getDataFromCache,
  db_setCacheData,
  db_checkCacheState,
} from './dbInterface';
import {
  arrowBuffersToArquero,
  concatArqThroughArrow,
} from './datasetHelpers';

const processCacheState = async (dataset, db, getData, cacheState) => {
  const { hasCache, missedCache, invalidCache } = cacheState;

  console.time(`${dataset}-read-write-from-cache`); // eslint-disable-line no-console
  const cleared = await db_clearInvalidCache(db, invalidCache); // eslint-disable-line no-unused-vars
  const dataFromCache = await db_getDataFromCache(db, hasCache);
  const dataFromRemote = await Promise.all(missedCache.map(getData));
  const cached = await db_setCacheData(db, dataFromRemote); // eslint-disable-line no-unused-vars
  console.timeEnd(`${dataset}-read-write-from-cache`); // eslint-disable-line no-console

  return [...dataFromCache, ...dataFromRemote];
};

export const initCache = (version, getMetaData, getData) => async (startDate, endDate, dataset, identifier) => {
  const db = await initDb(dataset, version);
  const requiredDatasets = await getMetaData(identifier, startDate, endDate, dataset);
  const cacheState = await db_checkCacheState(db, requiredDatasets);
  const arrowBuffers = await processCacheState(dataset, db, getData, cacheState);

  const arqTables = arrowBuffersToArquero(dataset, arrowBuffers);

  try {
    const _arch = concatArqThroughArrow(dataset, arqTables);
    const _data = arqTables.length ? arqTables[0].data : {};

    console.time(`${dataset}-parse`); // eslint-disable-line no-console
    const parsed = parseArch(_arch, _data);
    console.timeEnd(`${dataset}-parse`); // eslint-disable-line no-console

    return parsed;
  } catch (e) {
    console.log(e); // eslint-disable-line no-console
    console.log('error pulling data from cache'); // eslint-disable-line no-console
    return aq.from([]);
  }
};

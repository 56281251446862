import { useGlobalState } from './global';

const useAccountSettings = () => {
  const { state } = useGlobalState();
  const { account } = state;
  const { tier } = account || {};
  const isLite = tier === 'lite';
  const isStarter = tier === 'starter';
  const defaultView = isLite ? 'platform' : 'comparison';
  const defaultModel = isStarter ? 'assisted' : 'normalized';

  return {
    tier,
    account,
    isLite,
    defaultView,
    defaultModel,
  };
};

const useAppSettings = () => {
  const { tier, account, isLite, defaultView, defaultModel } = useAccountSettings();
  const { artifacts, tierColors } = useGlobalState();
  const gSheetsPluginLink = JSON.parse(artifacts?.google_sheets_plugin || '{}')?.link;

  return {
    artifacts,
    tierColors,
    gSheetsPluginLink,
    tier, account, isLite, defaultView, defaultModel,
  };
};

export default useAppSettings;

import React from 'react';
import { Button } from 'semantic-ui-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DownloadIcon } from '@heroicons/react/solid';
import json2csv from 'json2csv';
import { useRouteMatch } from 'react-router-dom';

import * as routes from '../../routes';
import { useMappings } from '../SavedReports/hooks/useMappings';
import { formatCSVData } from './helpers';
import { track } from '../../../utils/tracking';

const DownloadCSVButton = ({
  filterOptions = {}, data, selectedColumns,
  addColumns = false, trackName,
}) => {
  const match = useRouteMatch();
  const { path, params } = match;
  const [routeName] = Object.entries(routes).find(x => x[1] === path);
  const { appliedFilters, csvFileName } = useMappings(routeName, params, filterOptions);

  const exportCSV = () => {
    const editBreakdowns = appliedFilters.find(({ key }) => key === 'Breakdown') ? appliedFilters.find(({ key }) => key === 'Breakdown').value : false;
    const rbEditBreakdowns = routeName === 'rockerboxPerformance' && editBreakdowns && editBreakdowns.length === 0 ? ['tier_3', 'tier_4', 'tier_5'] : editBreakdowns;

    const parser = new json2csv.Parser();
    const formattedData = formatCSVData(data, selectedColumns, addColumns, rbEditBreakdowns);
    const csv = parser.parse(formattedData);

    const csvContent = `data:text/csv;charset=utf-8,${csv}`;
    const fileName = `${csvFileName}.csv`;

    const link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    track(trackName);
  };

  return (
    <Button
      inverted
      color="purple"
      onClick={() => exportCSV(true)}
      style={{ padding: '11px 13px', marginRight: 10, fontSize: 14 }}
      className="remove-box-shadow"
    >
      <DownloadIcon style={{ display: 'inline-block', height: 15, margin: '-4px 10px -3px -2px' }} />
      Download CSV
    </Button>
  );
};

export default DownloadCSVButton;

export const findMatchedReportTypeOptions = (
  filter_id,
  segments,
  reportTypes,
  reportTypeLimits
) => {
  const currentSegmentActionType =
    segments &&
    segments.filter(
      segment =>
        segment.active === 1 &&
        segment.deleted === 0 &&
        segment.filter_id === filter_id
    ).length > 0
      ? segments.filter(
          segment =>
            segment.active === 1 &&
            segment.deleted === 0 &&
            segment.filter_id === filter_id
        )[0]["action_type"]
      : null;

  if (currentSegmentActionType) {
    const excludedReportTypes = reportTypeLimits ? reportTypeLimits.map((item) => item['report_type_id']) : []
    const typeOptions = reportTypes.filter(
      reportType => reportType.action_type === currentSegmentActionType && excludedReportTypes.indexOf(reportType.id) === -1
    );

    return typeOptions;
  } else {
    return reportTypes;
  }
};

export const invalidRootFolder = (v) => {
  const regEx = /^\//
  return regEx.test(v)
}
import React, { useState, useEffect, useCallback } from 'react';
import { Button, Icon, Message, Table, Loader, Dimmer } from 'semantic-ui-react';
import { useGlobalState } from '../../hooks/global';
import { getDomains } from '../../api/account';
import { getPixelCnameDomain, postPixelCnameDomain } from '../../api/attributionPixels';

const PixelCnameDomain = ({ cnameAdded }) => {
  const { state, artifacts } = useGlobalState();
  const { account } = state;
  const { cname_instructions } = artifacts;

  const [domain, setDomain] = useState('');
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const randomAlphaNumericGenerator = length => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i <= length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const postDomain = useCallback(domainName => {
    const postObj = { domain: domainName };
    postPixelCnameDomain(postObj)
      .then(data => {
        setDomain(data.domain);
        setVerified(data.verified);
        setLoading(false);
        setErrorMsg('');
      }).catch(() => {
        setError(true);
        setErrorMsg('Error retrieving data');
        setLoading(false);
      });
  }, [domain]);

  const getDataFromApi = force_run_test => {
    setLoading(true);
    getPixelCnameDomain(force_run_test)
      .then(data => {
        setDomain(data.domain);

        if (!data.domain) {
          getDomains().then(domains => {
            const { domain: domainResponse } = domains[0];
            const removeHTTP = domainResponse.replace('https://', '');
            const cleanDomain = removeHTTP.replace('www.', '');
            const customDomain = `r${randomAlphaNumericGenerator(6)}.${cleanDomain}`;
            postDomain(customDomain);
          }).catch(err => console.log('error getting domains', err)); // eslint-disable-line no-console
        }
        setVerified(data.verified);
        setLoading(false);
        setError(false);
        setErrorMsg('');
      }).catch(err => {
        setLoading(false);
        setError(true);
        setErrorMsg(`Error retrieving data:${err}`);
      });
  };

  useEffect(() => {
    if (account && account.pixel_source_name) getDataFromApi(false);
  }, [account]);

  useEffect(() => {
    if (cnameAdded) cnameAdded(!!verified);
  }, [verified]);

  const refreshCnameStatus = () => getDataFromApi(true);

  const buildCnameTarget = pixel_source_name => pixel_source_name && `${pixel_source_name.toLowerCase().replace(/[^a-z0-9]/g, '')}.cname.getrockerbox.com`;

  return (
    <div>
      {loading
        && (
        <div style={{ height: '300px' }}>
          <Dimmer style={{ background: 'none' }} inverted active>
            <Loader inverted />
          </Dimmer>
        </div>
        )}
      {error && <Message error header={errorMsg} />}
      {!loading && account
        && (
        <DomainView
          domain={domain}
          cname_target={buildCnameTarget(account.pixel_source_name)}
          cname_instructions={cname_instructions}
          verified={verified}
          refresh={refreshCnameStatus}
        />
        )}
    </div>
  );
};

const DomainView = ({ domain, cname_target, cname_instructions, verified, refresh }) => (
  <>
    <p>
      <strong>
        Your custom tracking domain is
        {' '}
        <span className="domain">{domain}</span>
      </strong>
    </p>
    <div>
      <SetupInstructions domain={domain} cname_target={cname_target} cname_instructions={cname_instructions} />
      &nbsp;
      <div className="cname-test-wrapper">
        <div>
          <p>
            Once you&apos;ve made the change, verify that it&apos;s working correctly:
            <br />
            <span style={{ color: '#bbbbc4' }}>It may take a few minutes for your DNS changes to take effect</span>
          </p>
        </div>
        <div>
          <Button
            content="Test"
            color="purple"
            size="small"
            onClick={refresh}
            label={{
              basic: true,
              pointing: false,
              content: (
                verified
                  ? (
                    <>
                      <Icon color="green" name="check circle" />
                      Verified
                    </>
                  )
                  : (
                    <>
                      <Icon color="yellow" name="warning sign" />
                      Not Configured
                    </>
                  )
              ),
            }}
            labelPosition="left"
          />
        </div>
      </div>
    </div>
  </>
);

const SetupInstructions = ({ domain, cname_target, cname_instructions }) => (
  <div>
    <p>Add the following record in your DNS settings:</p>
    <Table className="cname-setup-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Host</Table.HeaderCell>
          <Table.HeaderCell>Target Value</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>CNAME Record</Table.Cell>
          <Table.Cell>{domain?.split('.')[0]}</Table.Cell>
          <Table.Cell>{cname_target}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
    <p style={{ fontWeight: 500 }}>
      Not sure how to update your DNS settings?
      <a href={cname_instructions} target="_blank" style={{ paddingLeft: 3 }} rel="noreferrer">
        View detailed instructions here
        {' '}
        <Icon name="external" />
      </a>
    </p>
  </div>
);

export default PixelCnameDomain;

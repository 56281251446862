import React, { useState, useMemo } from 'react';

import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, LabelList } from 'recharts';

import { numberFormatter } from '../../../utils/valueFormatter';

/**
 * Converts an array of data to cumulative data by calculating the start and end values.
 *
 * @param {Array} data - The input data array.
 * @param {string} data[].name - The name of the data item.
 * @param {number} data[].value - The value of the data item.
 * @returns {Array} - The transformed data array with start and end values.
 */
const dataToCumulativeData = data => {
  let lastTotal = 0;
  return data.map(item => {
    const start = lastTotal;
    const end = start + item.value;
    const dataArr = [start, end];
    lastTotal = end;
    return { ...item, start, end, data: dataArr };
  });
};

const useCumulativeData = data => {
  const cumulativeData = useMemo(() => dataToCumulativeData(data), [data]);

  return { cumulativeData };
};

const defaultFormatter = x => numberFormatter(x);
const defaultMinFunc = () => 0;
const defaultMaxFunc = dataMax => (dataMax * 1.1);
const defaultTickFormatter = x => `${parseInt(x / 1000)}k`;

const CustomizedAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={-12} dy={16} textAnchor="end" fill="#666" transform="rotate(-90)">
      {payload.value}
    </text>
  </g>
);

const renderCustomizedLabel = formatter => ({ x, y, width, value }) => {
  const formattedValue = Math.abs(value) > 1000 ? `${formatter(value / 1000)}k` : formatter(value);
  return (
    <text x={x + width / 2} y={y - 10} textAnchor="middle" dominantBaseline="middle">
      {formattedValue}
    </text>
  );
};

const defaultColor = '#CCC';

const renderShape = (props, data, selected) => {
  const { payload, x, y, width, height } = props;

  const { isFirst, isLast, isBaseline } = payload;
  const eligible = data.filter(({ isValue }) => isValue);

  const color = (isBaseline || eligible.length === 0) ? 'gray' : height < 0 ? 'red' : 'green';

  if (isFirst && eligible.length > 0) {
    const eligibleTotal = eligible.reduce((acc, { period1 }) => acc + period1, 0);

    let offset = y;
    const slices = data.filter(({ isValue }) => isValue).map(item => {
      const size = (item.period1 / eligibleTotal) * height;

      const isSelected = item.name === selected;
      const selectedColor = isSelected ? defaultColor : '#475ddc';
      offset += size;
      const conditionalSizeAdjustment = size > 20 ? 20
        : size < 10 ? -5
          : 10;

      const textPosition = Math.max(offset - size + conditionalSizeAdjustment, y + 15);
      return (
        <>
          <rect x={x} y={offset - size} width={width} height={size} fill={selectedColor} />
          {isSelected && size > 0 && (
          <>
            <text x={x + width * 2 + 15} y={textPosition - 15} textAnchor="start">
              {item.period1Name}
            </text>
            <text x={x + width * 2 + 15} y={textPosition} textAnchor="start">
              {item.name}
            </text>
            <text x={x + width * 2 + 15} y={textPosition + 15} textAnchor="start">
              {`Total sales: ${numberFormatter(item.period1)}`}
            </text>
          </>
          )}
        </>
      );
    });

    return slices;
  }

  if (isLast && eligible.length > 0) {
    const eligibleTotal = eligible.reduce((acc, { period2 }) => acc + period2, 0);

    let offset = y + height;
    const slices = data.filter(({ isValue }) => isValue).map(item => {
      const size = Math.abs((item.period2 / eligibleTotal) * height);

      const isSelected = item.name === selected;
      const selectedColor = isSelected ? defaultColor : '#475ddc';
      offset += size;
      const conditionalSizeAdjustment = size > 20 ? 20
        : size < 10 ? -5
          : 10;

      const rawPosition = offset - size + conditionalSizeAdjustment;
      const textPosition = (rawPosition < 65) ? rawPosition + 15 : rawPosition;
      return (
        <>
          <rect x={x} y={offset - size} width={width} height={size} fill={selectedColor} />
          {isSelected && size > 0 && (
            <>
              <text x={x - width} y={textPosition - 15} textAnchor="end">
                {item.period2Name}
              </text>
              <text x={x - width} y={textPosition} textAnchor="end">
                {item.name}
              </text>
              <text x={x - width} y={textPosition + 15} textAnchor="end">
                {`Total sales: ${numberFormatter(item.period2)}`}
              </text>
            </>
          )}
        </>
      );
    });

    return slices;
  }

  if (height < 0) {
    return <rect x={x} y={y + height} width={width} height={-height} fill={color} />;
  }

  return <rect x={x} y={y} width={width} height={height} fill={color} />;
};

/**
 * Renders a waterfall chart component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.data - The data used to render the chart.
 * @param {Object[]} props.data[].name - The name of the data item.
 * @param {Object[]} props.data[].value - The value of the data item.
 * @param {Function} [props.formatter] - The function used to format the tooltip value.
 * @param {Function} [props.minFunc] - The function used to calculate the minimum value for the y-axis domain.
 * @param {Function} [props.maxFunc] - The function used to calculate the maximum value for the y-axis domain.
 * @returns {JSX.Element} The rendered waterfall chart component.
 */
export const WaterfallChart = ({
  data,
  formatter = defaultFormatter,
  tickFormatter = defaultTickFormatter,
  minFunc = defaultMinFunc,
  maxFunc = defaultMaxFunc,
  children = null,
}) => {
  const [selectedCategory, _setSelectedCategory] = useState(null);
  const { cumulativeData } = useCumulativeData(data);

  const domainFuncs = [minFunc, maxFunc];

  return (
    <ResponsiveContainer width="100%" height={700}>
      <BarChart data={cumulativeData} barCategoryGap={2}>
        <XAxis
          dataKey="name"
          interval={0}
          angle={-90}
          height={300}
          tick={<CustomizedAxisTick />}
        />
        <YAxis hide type="number" domain={domainFuncs} tickFormatter={tickFormatter} />
        <Tooltip content={<ExtendedTooltip onHover={_setSelectedCategory} />} />
        {children}
        <Bar
          dataKey="data"
          shape={props => renderShape(props, cumulativeData, selectedCategory)}
          minPointSize={3}
        >
          <LabelList dataKey="originalValue" position="top" content={renderCustomizedLabel(formatter)} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

class ExtendedTooltip extends React.Component {
  componentDidUpdate() {
    const { onHover, label } = this.props;
    onHover(label);
  }

  render() {
    return null;
  }
}

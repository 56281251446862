import React from "react";
import { StepsHeader } from "../OTT";
import ConfigurationSettings from "./ConfigurationSettings";
import { statusStore } from "./zustandStore";
import { steps } from "./constants";
import SyncTable from "./SyncTable";

const componentMap = {
  Setup: ConfigurationSettings,
  Sync: SyncTable
};
const statusSelector = state => [state.activeStep, state.setActiveStep];

const WarehouseMain = props => {
  const [activeStep, setActiveStep] = statusStore(statusSelector);

  const Content = componentMap[activeStep];

  return (
    <React.Fragment>
      <StepsHeader
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
      <Content {...props} />
    </React.Fragment>
  );
};

export default WarehouseMain;

/**
 * Parses a list of error responses from Facebook API calls and extracts relevant information into a simplified format.
 *
 * @param {Array<Array<Object>>} responses - A matrix of error response objects from Facebook. Each object in the matrix
 *   should have the properties `error_title`, `account_id`, `campaign_id`, and `ad_id`.
 * @returns {Array<Object>} - An array of objects, each containing the `error`, `account_id`, `campaign_id`, and `ad_id`
 *   fields extracted from the original error response objects.
 */

export const parseFbErrors = responses => responses.flat().map(x => ({
  error: x.error_title,
  account_id: x.account_id,
  campaign_id: x.campaign_id,
  ad_id: x.ad_id,
}));

export const determineHasIssues = num => num > 0.1;

import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { topTabAtom } from '../../../atoms';

const getConversionKey = attributionMethod => `${attributionMethod}`;

const getRevenueKey = attributionMethod => `revenue_${attributionMethod}`;

export const useDerivedKeys = (attributionMethod, funnelConfiguration) => {
  const [selectedChartMetric] = useAtom(topTabAtom);
  const conversionKey = useMemo(() => getConversionKey(attributionMethod), [attributionMethod]);

  const revenueKey = useMemo(() => getRevenueKey(attributionMethod), [attributionMethod]);

  const orderBy = useMemo(() => {
    if (selectedChartMetric === 'cpa') return `${funnelConfiguration[0]}_cpa`;
    return `${funnelConfiguration[0]}_${conversionKey}`;
  }, [funnelConfiguration, conversionKey, selectedChartMetric]);

  return {
    conversionKey,
    revenueKey,
    orderBy,
  };
};

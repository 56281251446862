import { useEffect, useMemo } from 'react';
import * as aq from 'arquero';
import _ from 'lodash';
import { ntfDict } from './constants';
import { applyModifiersArquero } from '../hooks/helpers';
import { generateCols, removeDirect, removeSpend, calculateDefaults } from './helpers';

export const useConversionAndRevenueKey = (model, ntfFilter) => useMemo(() => ({
  conversionKey: `${ntfDict[ntfFilter]}${model}`,
  revenueKey: `revenue_${model}`,
}), [model, ntfFilter]);

export const useGridData = (data, modifiers, conversionKey, revenueKey, showOriginalCols) => (
  useMemo(() => {
    if (!data.length || !modifiers.length) return [];

    const weightedData = applyModifiersArquero(aq.from(data), modifiers, conversionKey, revenueKey, showOriginalCols).objects();
    const filterData = weightedData.filter(item2 => modifiers.some(item1 => item1.tier_1 === item2.tier_1))
      .sort((p, c) => p.tier_1.localeCompare(c.tier_1));  // preserve order so items don't move around when modifiers are applied

    return filterData;
  }, [data, modifiers, conversionKey, revenueKey])
);

export const useDefaultWeights = data => useMemo(() => {
  if (!data.length) return [];
  return data.map(c => ({ tier_1: c.tier_1, modifier: 1 }));
}, [data]);

// if selectedRedistribution is available use it to populate the form fields
export const useSelectedRedistribution = (defaultWeights, selectedRedistribution, data, hasSelectedRedistribution, setModifiers, setModel, setName) => (
  useEffect(() => {
    if (!data.length) return;
    if (hasSelectedRedistribution) {
      const { weights, name, model } = selectedRedistribution; // eslint-disable-line no-shadow

      weights.sort((a, b) => a.tier_1.localeCompare(b.tier_1));
      setModifiers(weights);
      setModel(model);
      setName(name);
    } else {
      setName('Custom Model');
      setModifiers(defaultWeights);
    }
  }, [defaultWeights, selectedRedistribution])
);

export const useRecommended = (recommended, modifiers, gridData, track, setModifiers) => (
  useEffect(() => {
    if (!recommended) return;
    if (recommended === 'removeSpend') {
      track('Attribution.AttributionReport.CustomModel.set.config0');
      setModifiers(removeSpend(modifiers, gridData));
    }
    if (recommended === 'removeDirect') {
      track('Attribution.AttributionReport.CustomModel.set.configdirect');
      setModifiers(removeDirect(modifiers));
    }
    if (recommended === 'default') setModifiers(calculateDefaults(modifiers));
  }, [recommended])
);

export const watchModifiersSetRecommended = (modifiers, setRecommended, gridData) => (
  useMemo(() => {
    const removedSpend = removeSpend(modifiers, gridData);
    const removedDirect = removeDirect(modifiers);
    const defaults = calculateDefaults(modifiers);

    if (_.isEqual(modifiers, defaults)) {
      setRecommended('default');
    } else if (_.isEqual(modifiers, removedDirect)) {
      setRecommended('removeDirect');
    } else if (_.isEqual(modifiers, removedSpend)) {
      setRecommended('removeSpend');
    } else {
      setRecommended('');
    }
  }, [modifiers])
);

export const watchDataSetOptionsList = (data, recommendedOptions, setOptionsList) => {
  useEffect(() => {
    if (!data.length) return;
    let filteredOptions = recommendedOptions;
    const itemWithNoSpend = data.some(item => item.spend === 0);
    const hasDirect = data.some(item => item.tier_1 === 'Direct');

    if (!itemWithNoSpend) {
      filteredOptions = filteredOptions.filter(option => option.key !== 'removeSpend');
    }
    if (!hasDirect) {
      filteredOptions = filteredOptions.filter(option => option.key !== 'removeDirect');
    }
    setOptionsList(filteredOptions);
  }, [data]);
};

export const useCreditRedistributionId = (creditRedistributionId, setRecommended) => {
  useEffect(() => {
    if (Number(creditRedistributionId) === 0) setRecommended('default');
  }, [creditRedistributionId]);
};

export const useCols = (model, modifiers, conversionKey, revenueKey, onModifierChange, resetModifiers, setRecommended, setInputError, selectedRedistribution, defaultWeights) => (
  useMemo(() => {
    if (!model || !modifiers) return [];
    return generateCols(conversionKey, revenueKey, onModifierChange, modifiers, resetModifiers, setRecommended, setInputError, selectedRedistribution, defaultWeights);
  }, [model, modifiers])
);

import React, {useState, useEffect, useMemo} from 'react';
import moment from 'moment';
import { Button, Popup, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import DateRangePicker from './DatePicker';
import * as Router from 'react-router-dom';
import { CalendarIcon } from '@heroicons/react/outline'

const daysAgo = (num) => moment.utc().utcOffset(-5).subtract(num, "days").format("YYYY-MM-DD")
const isDaysAgo = (value, num) => value == daysAgo(num)
const yesterday = moment.utc().utcOffset(-5).subtract(24, 'hours').format('YYYY-MM-DD');

const PRESET_DATE_RANGES = [
  {
    text: "7D",
    numDays: 7
  },
  {
    text: "30D",
    numDays: 30
  },
  {
    text: "60D",
    numDays: 60
  },
  {
    text: "90D",
    numDays: 90
  }
]

const updatePath = (path, current, updates) => {

  const pathParts = path.split("/")
  const updateObj = Object.assign(current, updates);
  const updateObjKeys = Object.keys(updateObj);

  const pathArray = pathParts.reduce((p,split,i) => {

    const keyName = split.includes(":") && split.replace(":","").replace("?","")
    const hasKey = updateObjKeys.indexOf(keyName) > -1

    if (hasKey && updateObj[keyName] != undefined) p.push(updateObj[keyName])
    if (hasKey && updateObj[keyName] == undefined) {
      //p.push("0")
    }
    if (!keyName) p.push(split)
    
    
    return p
  }, [])

  return pathArray.join("/")

}

const DateWrap = styled.div`
  ${({ inline }) => inline ? "border: 1px solid #ddd;" : "" }
  ${({ inline }) => inline ? "border-radius: 3px;" : "" }
  ${({ inline }) => inline ? "padding: 1px;" : "" }
  ${({ inline }) => inline ? "display:inline-block;" : "" }
  & input.DateInput_input {
    font-size: ${({ inline }) => inline ? "14px": "15px"};
    height: ${({ inline }) => inline ? "2.3em": ""};
    ${({ inline }) => !inline ? "text-align:center;" : "" }
  }
  font-family: 'Inter', 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
`

const CalendarIconWrap = styled.div`
  display: inline-block;
  margin-bottom: -2px;
  margin-right: 4px;
  height: 12px;
  width: 12px;
`

const DateRangeHooksUrlState = ({ startDate = yesterday, endDate = yesterday, onDateChange = () => {}, marginTop, marginBottom, fluid, firstReportingDate, showDates = false, inline, label, customPresets }) => {
  const [showCustom, setShowCustom] = useState(false);
  const match = Router.useRouteMatch();
  const { push } = Router.useHistory();
  const { path, params } = match;

  const [_start, _setStart] = useState(startDate);
  const [_end, _setEnd] = useState(endDate);
  const noURLStartDate = !path.includes(":startDate");                                                                  
  const noURLEndDate = !path.includes(":endDate");

  const setStart = noURLStartDate ? _setStart : (startDate) => push(updatePath(path, params, { startDate }))
  const setEnd = noURLEndDate ? _setEnd : (endDate) => push(updatePath(path, params, { endDate }))
  const start = noURLStartDate ? _start : params.startDate
  const end = noURLEndDate ? _end : params.endDate


  useEffect(() => {
    if ((!start && startDate) || (!end && endDate)) {
      const newPath = updatePath(path, params, { startDate: start || startDate, endDate: end || endDate})
      push(newPath)
    }
  }, [startDate, endDate, start, end])

  useEffect(() => {
    if (start && end) onDateChange({ startDate: start, endDate: end })
  },[start, end])

  const setDates = (start, end) => () => {
    setShowCustom(false)
    setStart(start)
    setEnd(end)
  }

  const setStartEnd = ({ startDate, endDate }) => {
    setStart(startDate.format("YYYY-MM-DD"))
    setEnd(endDate.format("YYYY-MM-DD"))
  }

  const presetOptions = customPresets || PRESET_DATE_RANGES

  const isCustomDateRange = !isDaysAgo(end,1) || presetOptions.every(({ numDays }) => !isDaysAgo(start,numDays))

  return (
    <div style={{ marginTop: marginTop || 10, marginBottom: marginBottom || 10, display: inline ? "inline-block" : undefined }}>
      { showDates && <Form.Field>
          { label && <label>{ label }</label>}
          <DateWrap inline={inline}>
            <DateRangePicker startDate={moment(start)} endDate={moment(end)} onChange={setStartEnd} {...{firstReportingDate}} />
          </DateWrap>
        </Form.Field>
      }
      { !showDates && <Button.Group size="small" basic fluid={fluid}>
          <Popup
            trigger={<Button content={<><CalendarIconWrap><CalendarIcon /></CalendarIconWrap>{` Custom`}</>} onClick={() => setShowCustom(true)} active={showCustom} />}
            content={<DateWrap><DateRangePicker startDate={moment(start)} endDate={moment(end)} onChange={setStartEnd} {...{firstReportingDate}} /></DateWrap>}
            on='click'
            open={showCustom}
            onClose={() => setShowCustom(false)}
            onOpen={() => setShowCustom(true)}
            position='bottom left'
            flowing
            basic
            style={{padding: '0 23px'}}
          />
          {presetOptions.map(({ text, numDays }) =>
            <Button active={!showCustom && isDaysAgo(start,numDays) && isDaysAgo(end,1)} onClick={setDates(daysAgo(numDays),daysAgo(1))} content={text} />
          )}
        </Button.Group>
      }
    </div>
  )
}

export default DateRangeHooksUrlState;

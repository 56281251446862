import moment from 'moment';
import * as d3 from 'rockerbox_d3_legacy_clone';

export default function probsChart(forcedBuckets) {
  // define chart layout
  var margin = {
    // top margin includes title and legend
    top: 0,

    // right margin should provide space for last horz. axis title
    right: 0,

    bottom: 0,

    // left margin should provide space for y axis titles
    left: 0
  };

  var startDate = moment.utc().utcOffset(-5).subtract(30, 'days').format('YYYY-MM-DD');

  // height & width
  var width = 240 - margin.left - margin.right;
  var height = 25 - margin.top - margin.bottom;

  // bar chart stuff
  var numBuckets = 30;

  // cosmetics
  var color = "#0489c1";
  var title = '';

  function chart(selection) {

    var container = d3.select("body").selectAll("div.daily_chart_tooltip")
      .data([0]);

    container.enter().append('div').attr('class', 'daily_chart_tooltip').style('opacity', 0);

    selection.each(function drawGraph(dataset) {

      numBuckets = forcedBuckets || dataset.length || numBuckets;

      var barWidth = (width / numBuckets);
      var forcedPadding = (numBuckets - dataset.length)*barWidth

      var daily_x = d3.time.scale().range([0, width]);
      var daily_y = d3.scale.linear().range([height, 0]);

      dataset = dataset.map(function(d, idx) {
        var dataStartDate = moment(startDate);
        return {
          total: d.total ? +d.total : +d,
          date: d.date ? moment(d.date, "YYYY-MM-DD") : dataStartDate.add(idx, 'days')
        }
      });


      var dayExtent = d3.extent(dataset, function(d) { return d.date; });
      var dayBins = d3.time.days(d3.time.day.offset(dayExtent[1],-1), d3.time.day.offset(dayExtent[0],1));

      var histData = d3.layout.histogram()
        .value(function(d) { return d.date; })
        .bins(dayBins)(dataset);

      daily_x.domain(d3.extent(dayBins));
      daily_y.domain([0, d3.max(dataset, function(d) { return d.total; })]);

      // create SVG element
      var svg = d3.select(this).append('svg')
          .attr('width', width + margin.left + margin.right)
          .attr('height', height + margin.top + margin.bottom)

      //svg.append('g')
      //    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      var tinyBar = svg.selectAll("g")
          .data(dataset)
        .enter().append("g")
          .attr("transform", function(d, i) { return "translate(" + ((i * barWidth) + forcedPadding) + ",0)"; });

      tinyBar.append("rect")
          .attr("y", function(d) { return daily_y(d.total) || 0 })
          .attr("height", function(d) { return (height - daily_y(d.total)) || 0 })
          .attr("width", barWidth - (.5 * barWidth))
          .attr("fill", color)
        .on("mousemove", function(d) {
          container.transition()
            .duration(50)
            .style("opacity", .9);
          container.html("<div>" + d.date.format("MMM D, YYYY") + '<br /><strong style="font-size:12px">' + d3.format(",.2f")(d.total) + "</strong></div>")
            .style("left", (d3.event.pageX - 80) + "px")
            .style("top", (d3.event.pageY - 28) + "px")
            .style("border-width", 0);
          })
        .on("mouseout", function(d) {
          container.transition()
            .duration(50)
            .style("opacity", 0);
        });

    });
  }

  chart.width = function(_) {
    if (!arguments.length) return width;
    width = _;
    return chart;
  };

  chart.height = function(_) {
    if (!arguments.length) return height;
    height = _;
    return chart;
  };

  chart.title = function(_) {
    if (!arguments.length) return title;
    title = _;
    return chart;
  };

  chart.startDate = function(_) {
    if (!arguments.length) return startDate;
    startDate = _;
    return chart;
  };


  return chart;
}

import React, { useMemo } from 'react';
import { Header, Message, Form, Button } from 'semantic-ui-react';

import { changePassword } from '../../api/account';

const ChangePassword = () => {
  const [message, setMessage] = React.useState('');
  const [result, setResult] = React.useState('');
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  // eslint-disable-next-line max-len
  const isValid = useMemo(() => currentPassword.length > 0 && newPassword.length >= 8 && confirmPassword.length >= 8 && newPassword === confirmPassword, [currentPassword, newPassword, confirmPassword]);

  const submitChangePassword = () => {
    setLoading(true);
    const obj = {
      current_password: currentPassword,
      new_password: newPassword,
    };
    changePassword(obj)
      .then(res => {
        const { error } = res;
        if (!!error) {
          setMessage(error);
          setResult('error');
          setLoading(false);
          return;
        }
        setMessage('Password changed successfully');
        setResult('success');
        setLoading(false);
      })
      .catch(res => {
        const { error } = res;
        setMessage(error);
        setResult('Error changing password, please try again');
        setLoading(false);
      });
  };

  return (
    <>
      <Header
        as="h3"
        content="Change Password"
      />
      <Message
        hidden={!message}
        error={result === 'error'}
        success={result === 'success'}
        header={message}
        onDismiss={() => setMessage('')}
      />
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            fluid
            type="password"
            label="Current Password"
            placeholder="your current password"
            value={currentPassword}
            onChange={e => setCurrentPassword(e.target.value)}
          />
          <Form.Input
            fluid
            type="password"
            label="New Password"
            placeholder="must be at least 8 characters"
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
          />
          <Form.Input
            fluid
            type="password"
            label="Confirm New Password"
            placeholder="one more time to confirm"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
          />
          <Button
            primary
            style={{ alignSelf: 'end', marginBottom: 1, marginRight: 6 }}
            disabled={!isValid}
            onClick={submitChangePassword}
            loading={loading}
          >
            Submit
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

export default ChangePassword;

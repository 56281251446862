import { tableFromIPC } from 'apache-arrow'; // eslint-disable-line import/no-extraneous-dependencies
import * as aq from 'arquero';
import { trimEnd } from 'lodash';
import { isDate, isValidJSONArray, isDecimal, isBigInt } from './dataValidation';
import { readParquet } from './data/parquet';

const MAX_INT32 = 2 ** 31 - 1;
function isNegative(value) {
  return value[value.length - 1] > MAX_INT32;
}

function negate(value) {
  let carry = 1;
  for (let i = 0; i < value.length; i++) {
    const elem = value[i];
    const updated = ~elem + carry;
    value[i] = updated; // eslint-disable-line no-param-reassign
    carry &= elem === 0 ? 1 : 0;
  }
}

function uint32ArrayToDecimal(value, scale = 10) {
  const negative = isNegative(value);
  const sign = negative ? '-' : '';
  if (negative) {
    negate(value);
  }

  const str = value.toString().padStart(scale, '0');
  if (scale === 0) {
    return `${sign}${str}`;
  }

  const wholePart = str.slice(0, -scale) || '0';
  const decimalPart = trimEnd(str.slice(-scale), '0') || '0';
  return `${sign}${wholePart}.${decimalPart}`;
}

const fetchToParquetBuffer = async promise => {
  const resp = await promise;
  const raw_buffer = await resp.arrayBuffer();
  return new Uint8Array(raw_buffer);
};

const getAsBuffer = async url => {
  const promise = fetch(url, { credentials: 'include' });
  const parquetBuffer = await fetchToParquetBuffer(promise);
  const arrowIPC = await readParquet(parquetBuffer);
  return arrowIPC;
};

export const parseArch = (_arch, _data) => {
  const columnNames = _arch.columnNames();
  const utcDateColumns = columnNames
    .filter(col => isDate(_arch.get(col)))
    .reduce((accu, col) => {
      accu[col] = `d => op.format_utcdate(d['${col}'])`;
      return accu;
    }, {});

  const decimalCols = _data.schema ? _data.schema.fields.filter(f => f.type.scale) : [];
  const scaleMap = decimalCols.reduce((accu, c) => {
    accu[c.name] = c.type.scale;
    return accu;
  }, {});

  const decimalColumns = columnNames
    .filter(col => isDecimal(_arch.get(col)))
    .reduce((accu, col) => {
      accu[col] = aq.escape(d => Number(uint32ArrayToDecimal(d[col], scaleMap[col])));
      return accu;
    }, {});

  const bigIntColumns = columnNames
    .filter(col => isBigInt(_arch.get(col)))
    .reduce((accu, col) => {
      accu[col] = aq.escape(d => Number(d[col]));
      return accu;
    }, {});

  const arch = _arch
    .derive(utcDateColumns)
    .derive(decimalColumns)
    .derive(bigIntColumns);

  return arch;
};

export const getParquetAndReturnJson = async url => {
  const _buffer = await getAsBuffer(url);
  const _data = tableFromIPC(_buffer);
  const _arch = aq.fromArrow(_data);
  return parseArch(_arch, _data).objects();
};

// used exclusively for MarketingPaths to prevent validJSONColumnArrays validation in general parseArch fn
const parseArchPaths = (_arch, _data) => {
  const columnNames = _arch.columnNames();
  const utcDateColumns = columnNames
    .filter(col => isDate(_arch.get(col)))
    .reduce((accu, col) => {
      accu[col] = `d => op.format_utcdate(d['${col}'])`;
      return accu;
    }, {});

  const validJSONColumnsArrays = columnNames
    .filter(col => isValidJSONArray(_arch.get(col)))
    .reduce((accu, col) => {
      accu[col] = aq.escape(d => JSON.parse(d[col]));
      return accu;
    }, {});

  const decimalCols = _data.schema ? _data.schema.fields.filter(f => f.type.scale) : [];
  const scaleMap = decimalCols.reduce((accu, c) => {
    accu[c.name] = c.type.scale;
    return accu;
  }, {});

  const decimalColumns = columnNames
    .filter(col => isDecimal(_arch.get(col)))
    .reduce((accu, col) => {
      accu[col] = aq.escape(d => Number(uint32ArrayToDecimal(d[col], scaleMap[col])));
      return accu;
    }, {});

  const arch = _arch
    .derive(utcDateColumns)
    .derive(validJSONColumnsArrays)
    .derive(decimalColumns);

  return arch;
};

export const getParquetAndReturnJsonPaths = async url => {
  const _buffer = await getAsBuffer(url);
  const _data = tableFromIPC(_buffer);
  const _arch = aq.fromArrow(_data);
  return parseArchPaths(_arch, _data).objects();
};

export const dataToRatio = data => {
  const totals = [];

  data.forEach(obj => {
    const sum = Object.values(obj).reduce((a, b) => a + ((typeof (b) === 'number') && b), 0);
    totals.push(sum);
  });

  data.forEach((obj, i) => {
    for (const key in obj) { // eslint-disable-line no-restricted-syntax
      if (typeof (obj[key]) === 'number') obj[key] = (obj[key] / totals[i]) * 100; // eslint-disable-line no-param-reassign
    }
  });
  return data;
};

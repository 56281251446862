import React from 'react'
import { Segment, Icon } from 'semantic-ui-react'
import ButterToast from 'butter-toast'


const ToastNotification = ({ type, icon, content, timeout }) => {

  const message = (
    <Segment
      inverted
      color={type == "success" ? 'green' : type == "error" ? 'red' : 'blue'}
      style={{width: 'calc(100vw - 10px)'}}
    >
      <Icon
        name={icon || type == "success" ? 'check circle' : type == "error" ? 'exclamation triangle' : 'info circle'}
      />
      &nbsp;
      {content}
    </Segment>
  )
  
  ButterToast.raise({
    dismissOnClick: true,
    content: () => message,
    toastTimeout: timeout || 5000 // default to 5s
  })
}

export default ToastNotification

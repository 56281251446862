import React from "react";
import { Menu, Icon } from "semantic-ui-react";

const defaultMenu = {
  external: "data",
  creative: "data",
  ott_served: "analysis",
  ott_batch: "analysis",
};
const categories = [
  { name: "creative", label: "Display (Pixel)" },
  { name: "external", label: "Display (Batch)" },
  { name: "ott_served", label: "OTT (Pixel)" },
  { name: "ott_batch", label: "OTT (Batch)" },
];

const categoriesMap = {
  external: [{ name: "data", label: "Data Source" }],
  creative: [{ name: "data", label: "Data Source" }],
  ott_served: [
    { name: "analysis", label: "Setup Threshold" },
    { name: "data", label: "View Data" },
  ],
  ott_batch: [
    { name: "analysis", label: "Setup Threshold" },
    { name: "data", label: "View Data" },
  ],
};

export const FirstMenu = (props) => {
  const { firstMenu, setFirstMenu, setSecondMenu, updatePath } = props;
  const items = categories.map((category) => {
    return (
      <Menu.Item
        active={firstMenu === category.name}
        onClick={() => {
          setFirstMenu(category.name);
          setSecondMenu(defaultMenu[category.name]);
        }}>
        <Menu.Header>
          {category.label}
          {firstMenu === category.name && (
            <Icon name="chevron right" style={{ float: "right", margin: 0 }} />
          )}
        </Menu.Header>
      </Menu.Item>
    );
  });

  return (
    <React.Fragment>
      <Menu vertical fluid>
        {items}
      </Menu>
    </React.Fragment>
  );
};

export const SecondMenu = (props) => {
  const { firstMenu, secondMenu, setSecondMenu, updatePath } = props;
  const categories = categoriesMap[firstMenu];

  const items = categories
    ? categories.map((category) => {
        return (
          <Menu.Item
            active={secondMenu === category.name}
            onClick={() => setSecondMenu(category.name)}>
            <Menu.Header>
              {category.label}
              {secondMenu === category.name && (
                <Icon
                  name="chevron right"
                  style={{ float: "right", margin: 0 }}
                />
              )}
            </Menu.Header>
          </Menu.Item>
        );
      })
    : null;

  return (
    <React.Fragment>
      <Menu vertical fluid>
        {items}
      </Menu>
    </React.Fragment>
  );
};

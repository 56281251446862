import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { RockerboxStyledDropdown, Label } from '../../components/StyledDropdown';

const AttributionModelSelector = props => {
  const { label, rightBtn, disabled, value, onChange, attributionOptions, standardAttributionOptions, redistributionOptions } = props;

  return (
    <div style={{ paddingRight: '12px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Label>{label}</Label>
        {rightBtn && rightBtn}
      </div>
      <RockerboxStyledDropdown
        selection
        disabled={disabled}
        value={value}
        text={attributionOptions.find(c => c.value === value)?.text || ''}
        onChange={onChange}
      >
        <Dropdown.Menu>
          <Dropdown.Header content="Standard Models" />
          <Dropdown.Divider />
          {
            standardAttributionOptions.map(c => <Dropdown.Item text={c.text} value={c.value} active={c.value === value} onClick={onChange} />)
          }
          {
            (redistributionOptions && redistributionOptions.length > 0) && (
              <>
                <Dropdown.Header content="Custom Models" />
                <Dropdown.Divider />
                {
                  redistributionOptions.map(c => <Dropdown.Item text={c.text} value={c.value} active={c.value === value} onClick={onChange} />)
                }
              </>
            )
          }
        </Dropdown.Menu>
      </RockerboxStyledDropdown>
    </div>
  );
};

export default AttributionModelSelector;

import React, { useMemo, useEffect, useState } from 'react';

import { Grid } from 'semantic-ui-react';

import ComboChart from './ComboChart';
import { formatPlatformName } from './helpers';
import MiniChartCards from './MiniChartCards';
import SpendBreakdown from './SpendBreakdown';
import { useURLState } from '../../../../components/hooks';
import { track, time } from '../../../../utils/tracking';
import { getDatasetStatusRollup } from '../../../api/dataReporting';
import { DataStatusModal } from '../../../components';
import { useGlobalState } from '../../../hooks/global';
import { getCache } from '../../../utils/data';
import { formatCurrency } from '../../../utils/valueFormatter';

const SummaryCharts = ({ startDate, endDate }) => {
  const { currencyCode, segments, state, dataStatusPlatforms } = useGlobalState();
  const { account } = state;

  // eslint-disable-next-line no-unused-vars
  const [urlStartDate, _setStartDate] = useURLState('startDate');
  // eslint-disable-next-line no-unused-vars
  const [urlEndDate, _setEndDate] = useURLState('endDate');

  const [loading, setLoading] = useState(true);
  const [conversionSummary, setConversionSummary] = useState(null);
  const [checkCurrentRaw, setCheckCurrentRaw] = useState({});
  const [spendStatusRollup, setSpendStatusRollup] = useState([]);
  const [conversionStatusRollup, setConversionStatusRollup] = useState([]);
  const [syntheticStatusRollup, setSyntheticStatusRollup] = useState([]);

  // TODO: remove this once we remove gating
  const isTestAccount = true;

  // eslint-disable-next-line no-unused-vars
  const currencyFormatter = numDecimals => number => formatCurrency(number, currencyCode);

  const featuredSegment = useMemo(() => {
    if (!segments || segments.length === 0) return null;
    return segments.find(s => !!s.featured);
  }, [segments]);
  const featuredSegmentName = featuredSegment?.action_name || 'Orders';
  const hasRevenue = !!featuredSegment?.include_revenue;

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions, no-use-before-define
    !!featuredSegment && getDailySummary() && setLoading(true);
  }, [featuredSegment, startDate, endDate]);

  useEffect(() => {
    if (!featuredSegment || !dataStatusPlatforms) return;
    const fetchData = async () => {
      try {
        const [spendResponse, conversionResponse, syntheticResponse] = await Promise.all([
          getDatasetStatusRollup('spend', startDate, endDate),
          getDatasetStatusRollup('conversions', startDate, endDate, featuredSegment?.action_id),
          getDatasetStatusRollup('synthetic_events', startDate, endDate),
        ]);
        const filteredSpend = {
          ...spendResponse.response,
          failures: spendResponse.response.failures.filter(failure => dataStatusPlatforms.includes(failure.platform)),
        };
        setSpendStatusRollup(filteredSpend);
        setConversionStatusRollup(conversionResponse.response);
        const filteredSynthetic = {
          ...syntheticResponse.response,
          failures: syntheticResponse.response.failures.filter(failure => failure.platform !== 'tiktok'),
        };
        setSyntheticStatusRollup(filteredSynthetic);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
    fetchData();
  }, [startDate, endDate, featuredSegment, dataStatusPlatforms]);

  useEffect(() => {
    // check if raw data is valid with dates
    if (checkCurrentRaw.startDate !== urlStartDate || checkCurrentRaw.endDate !== urlEndDate) return;
    // eslint-disable-next-line no-use-before-define
    const dailySummary = calculateDailySummary(checkCurrentRaw.conversionMTATiersDataAq);
    setConversionSummary(dailySummary);
  }, [checkCurrentRaw]);

  const getDailySummary = async () => {
    if (!featuredSegment || !account) return;
    const { action_id } = featuredSegment;
    const hasDataReportingApiFlag = account.features.find(feature => feature === 'data_reporting_api');

    const getViaCache = hasDataReportingApiFlag
      ? getCache('compiled_mta_tiers', 'data-services-v1')
      : getCache('compiled_mta_tiers', 'legacy');

    const conversionMTATiersDataAq = await getViaCache(startDate, endDate, 'compiled_mta_tiers', action_id);
    setCheckCurrentRaw({ conversionMTATiersDataAq, startDate, endDate });
  };

  const calculateDailySummary = conversionMTATiersDataAq => {
    const conversionMTATiersDataRaw = conversionMTATiersDataAq.objects();
    const conversionMTATiersData = Object.values(conversionMTATiersDataRaw.reduce((obj, row) => {
      // eslint-disable-next-line no-param-reassign
      obj[row.date] = obj[row.date] || [];
      obj[row.date].push(row);
      return obj;
    }, {}));
    const dailySummaryData = conversionMTATiersData.map(dailyData => dailyData.reduce((acc, x) => {
      const newAcc = { ...acc };
      newAcc.date = x.date;
      newAcc.conversions += x.even || 0;
      newAcc.revenue += x.revenue_even || 0;
      newAcc.spend_total += x.spend || 0;
      if (x.spend) {
        const platform = formatPlatformName(x.platform) || 'other';
        newAcc.spend[platform] = x.spend + (newAcc.spend[platform] || 0);
      }
      return newAcc;
    }, {
      date: '',
      conversions: 0,
      revenue: 0,
      spend_total: 0,
      spend: {},
    })).sort((a, b) => (a.date > b.date ? 1 : -1));

    return dailySummaryData;
  };

  const dailyData = useMemo(() => {
    if (!conversionSummary) return;
    return conversionSummary.map(x => ({
      date: x.date,
      revenue: x.revenue,
      conversions: x.conversions,
      spend_total: x.spend_total,
      roas: (x.revenue || 0) / (x.spend_total || 1),
      cpa: (x.spend_total || 0) / (x.conversions || 1),
      ...x.spend,
    }));
  }, [conversionSummary]);

  const spendBreakdown = useMemo(() => {
    if (!conversionSummary) return;
    const spendSummary = conversionSummary.reduce((acc, x) => {
      const newAcc = { ...acc };
      const platforms = Object.keys(x.spend || {});
      platforms.forEach(p => {
        newAcc[p] = x.spend[p] + (newAcc[p] || 0);
      });
      return newAcc;
    }, {});
    return Object.keys(spendSummary)
      .map(platform => ({
        platform,
        spend: spendSummary[platform],
      }))
      .sort((a, b) => b.spend - a.spend);
  }, [conversionSummary]);

  const spendPlatforms = useMemo(() => {
    if (!spendBreakdown) return;
    setLoading(false);
    return spendBreakdown.map(x => x.platform);
  }, [spendBreakdown]);

  useEffect(() => {
    if (!!loading) {
      time('home.view');
      return;
    }
    track('home.view');
  }, [loading]);

  return (
    <>
      <DataStatusModal
        isTestAccount={isTestAccount}
        connections={[]}
        startDate={startDate}
        endDate={endDate}
      />
      <Grid>
        <Grid.Column stretched width={5}>
          <SpendBreakdown
            {...{ loading, currencyFormatter, data: spendBreakdown }}
          />
        </Grid.Column>
        <Grid.Column width={11}>
          <Grid stretched columns="equal">
            <MiniChartCards {...{ loading, hasRevenue, featuredSegmentName, currencyFormatter, data: dailyData, spendStatusRollup, conversionStatusRollup, syntheticStatusRollup, isTestAccount }} />
            <Grid.Row>
              <Grid.Column>
                <ComboChart
                  {...{ loading, hasRevenue, currencyFormatter, data: dailyData, platforms: spendPlatforms, spendStatusRollup, conversionStatusRollup, syntheticStatusRollup, isTestAccount }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default SummaryCharts;

import React from 'react';
import { withRouter } from 'react-router-dom'
import { AppNav } from '@rockerbox/styleguide'

import { CacheContext } from '../../utils/CacheContext'
import { switchAccount, getUser, getUserPermissions, getAnnouncements } from '../../utils/api'
import { toPane, toMenuItem, markActiveRoutes } from './helpers'
import { HELP_ITEMS, openIntercomMessenger } from './helpSection'

const Navigation = (props) => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const { account } = state
  const { advertiser_name, signup_source } = account || {}
  const features = account?.features || []

  const user = (Context.getCache(`user`, getUser) || {})
  const { username } = user

  const permissions = (Context.getCache(`permissions`, getUserPermissions) || {})
  const allowedAccounts = permissions.accounts || []
  const accounts = React.useMemo(() =>
    allowedAccounts.map(({ advertiser_name, pixel_source_name }) => 
      Object({
        text: advertiser_name,
        key: pixel_source_name,
        value: pixel_source_name,
      })
    )
  , [allowedAccounts])

  const announcements = (Context.getCache(`announcements`, getAnnouncements) || [])
  const minorAnnouncements = React.useMemo(() => announcements.filter(a => !a.major), [announcements])

  const { history, location, allRoutes } = props
  const { push } = history
  const { pathname } = location

  React.useEffect(() => {
    updateNav()
  }, [pathname])

  const [topLevel, setTopLevel] = React.useState([])
  const [topLevelActiveIndex, setTopLevelActiveIndex] = React.useState(0)
  const [secondLevel, setSecondLevel] = React.useState([])
  const [secondLevelActiveIndex, setSecondLevelActiveIndex] = React.useState(0)
  const topLevelPanes = React.useMemo(() => topLevel.map(toMenuItem), [topLevel])
  const secondLevelPanes = React.useMemo(() => secondLevel.map(toPane), [secondLevel])

  const handleChange = (_, {activeIndex, panes}) => {
    push(panes[activeIndex].route)
    updateNav()
  }

  const handleChangeMenu = (route) => {
    push(route.route)
    updateNav()
  }

  const updateNav = () => {
    const isLiteUser = signup_source === "shopify";
    const isRBUser = username && username.slice(-14) === '@rockerbox.com'
    const {
      topLevel,
      secondLevel,
      topLevelActiveIndex,
      secondLevelActiveIndex,
      forceRoute
    } = markActiveRoutes(allRoutes, pathname, isLiteUser, features);

    const secondLevelFiltered = isRBUser ? secondLevel : secondLevel.filter(level => !level.alpha)

    if (forceRoute) push(forceRoute)

    setTopLevel(topLevel)
    setSecondLevel(secondLevelFiltered)
    setTopLevelActiveIndex(topLevelActiveIndex)
    setSecondLevelActiveIndex(secondLevelActiveIndex)

    if (window.Intercom) window.Intercom('update')
  }

  const handleAccountChange = (_, d) => {
    switchAccount(d.value)
      .then(x => document.location.reload(true))
  }

  return (
    <AppNav>
      <AppNav.Top
        activeIndex={topLevelActiveIndex}
        menuItems={topLevelPanes}
        handleChange={handleChangeMenu}
        homeText='Admin'
        onHomeClick={() => handleChangeMenu(topLevelPanes[0])}
        helpItems={HELP_ITEMS}
        onChatClick={(e) => openIntercomMessenger(e)}
        companyName={advertiser_name}
        companyOptions={accounts}
        onCompanyChange={handleAccountChange}
        logoutPath={"/user/logout"}
        username={username}
        notifications={minorAnnouncements}
      />
      <AppNav.Sub
        panes={secondLevelPanes}
        activeIndex={secondLevelActiveIndex}
        onTabChange={handleChange}
      />
    </AppNav>
  )
}

export default withRouter(Navigation)

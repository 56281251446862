import React, { useState, useMemo, useEffect } from 'react';
import { Grid, Segment, Button, Modal, Icon, Dropdown } from 'semantic-ui-react';
import { TemplateIcon } from '@heroicons/react/outline'; // eslint-disable-line import/no-extraneous-dependencies

import toast from 'react-hot-toast';

import Link from '../../../utils/link';
import { getSavedReports, deleteSavedReport } from '../../../api/attribution';
import { useGlobalState } from '../../../hooks/global';
import { buildHash, buildPath } from '../../../hooks/urlState';
import { track } from '../../../../utils/tracking';
import { NAV_MAP } from '../../Navbar/utils';
import { SavedReportsGridHeader, SavedReportsGridLoader, EmptyGridSavedReports } from './stateless';

const ConfirmDeleteModal = ({ open, onClose, onDelete, name }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      {...{ open, onClose }}
      closeIcon
      size="mini"
      dimmer="inverted"
      className="modal-v3"
    >
      <Modal.Header>
        <div className="title">
          Delete Saved Report
          <div className="sub">
            Are you sure you want to delete
            {' '}
            {name}
            ?
          </div>
        </div>
      </Modal.Header>
      <Modal.Content>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Button
                fluid
                content="Cancel"
                onClick={onClose}
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                fluid
                color="red"
                content="Yes, Delete"
                onClick={() => {
                  setLoading(true);
                  onDelete();
                }}
                {...{ loading }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

export const SavedReportItem = ({ id, name, route, params, user, shared, onClick }) => {
  const { user: username } = useGlobalState();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const fullPath = useMemo(() => {
    const rebuiltPath = buildPath(route, params.route);
    const rebuiltHash = params.hash ? buildHash(params.hash) : '';
    return rebuiltPath + rebuiltHash;
  }, [route, params]);

  const { icon, section } = useMemo(() => {
    const routeKey = route.startsWith('conversions') ? 'conversionsOverview' : route;
    return NAV_MAP[routeKey] || {};
  }, [route]);

  const onCopyLink = () => {
    const fullLink = `${window.location.origin}${fullPath}`;
    navigator.clipboard.writeText(fullLink);
    toast('Link copied to clipboard', { icon: '📋' });
    track('saved_report.copy_link', { id, name, user, shared, route, params });
  };

  const onDelete = () => {
    deleteSavedReport(id)
      .then(() => {
        toast.success('Deleted Saved Report');
        setDeleted(true);
        track('saved_report.delete', { id, name, user, shared, route, params });
      })
      .catch(err => {
        console.log('Delete saved report error:', err);
        toast.error('Error deleting saved report');
      });
  };

  if (deleted) return null;

  return (
    <>
      <Grid.Column>
        <Segment
          className="saved-item"
          as={Link}
          to={fullPath}
          onClick={() => {
            track('saved_report.open', { id, name, user, shared, route, params });
            if (!!onClick) onClick();
          }}
        >
          <div className={`icon ${section}`}>
            {icon || <TemplateIcon />}
          </div>
          <div className="text">
            <div className="name">
              {name}
            </div>
            <div className="description">
              <Icon
                name={!shared ? 'lock' : 'user'}
                style={{ marginRight: 5 }}
              />
              {user === username ? 'You' : user}
            </div>
          </div>
        </Segment>
        <Dropdown
          icon="ellipsis horizontal"
          button
          className="icon inverted purple actions-menu"
          direction="left"
        >
          <Dropdown.Menu>
            <Dropdown.Item
              icon={{
                color: 'purple',
                name: 'share alternate',
              }}
              text="Copy Shareable Link"
              onClick={onCopyLink}
            />
            <Dropdown.Item
              icon={{
                color: 'red',
                name: 'trash alternate outline',
              }}
              text="Delete"
              onClick={() => setDeleteModalOpen(true)}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Grid.Column>
      <ConfirmDeleteModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        {...{ name, onDelete }}
      />
    </>
  );
};

const SavedReportsGrid = ({ columns, routeName, hideTitle, headerStyles = {}, onClick }) => {
  const [savedReports, setSavedReports] = useState(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getSavedReports()
      .then(data => {
        setSavedReports(data);
      });
  }, []);

  const filteredSavedReports = useMemo(() => {
    if (!savedReports) return null;
    return savedReports
      .filter(report => (!routeName || report.route === routeName)
        && report.name.toLowerCase().includes(search.toLowerCase()));
  }, [savedReports, search]);

  const loading = !filteredSavedReports;
  const empty = !filteredSavedReports?.length;
  const emptyText = !!savedReports?.length
    ? 'No matches found'
    : 'Save your favorite reports to quickly access them and share with your team';

  return (
    <>
      <SavedReportsGridHeader
        {...{ hideTitle, headerStyles }}
        value={search}
        onChange={(e, { value }) => setSearch(value)}
        hideSearch={!savedReports?.length}
      />
      {loading && <SavedReportsGridLoader {...{ columns }} />}
      {!loading && empty && <EmptyGridSavedReports {...{ emptyText }} />}
      {!loading && !empty
      && (
      <Grid {...{ columns }} className={`saved-views columns-${columns}`}>
        <Grid.Row stretched>
          {filteredSavedReports.map(item => {
            const { id, name, route, params, user, shared } = item;
            return (
              <SavedReportItem
                key={id}
                {...{ id, name, route, params, user, shared }}
                {...{ onClick }}
              />
            );
          })}
        </Grid.Row>
      </Grid>
      )}
    </>
  );
};

export default SavedReportsGrid;

import { useMemo, useEffect } from 'react';
import { useGlobalState } from '../../hooks/global';

const HelpDocsSuggestions = ({ location }) => {
  const { pathname } = location;
  const { artifacts } = useGlobalState();
  const { helpdocs_suggestions } = artifacts;

  const helpDocsSuggestions = useMemo(() => {
    if (!helpdocs_suggestions) return null;
    return JSON.parse(helpdocs_suggestions || '{}');
  }, [helpdocs_suggestions]);

  const helpDocsSuggestionsForPath = useMemo(() => {
    if (!helpDocsSuggestions || !pathname) return null;
    return helpDocsSuggestions.find(({ route }) => pathname.includes(route))?.suggestions;
  }, [helpDocsSuggestions, pathname]);

  useEffect(() => {
    if (!window.hdlh) return;
    window.hdlh = {
      ...window.hdlh,
      suggestions: helpDocsSuggestionsForPath,
    };
    // eslint-disable-next-line no-unused-expressions
    !!window.Lighthouse && window.Lighthouse.reload();
  }, [helpDocsSuggestionsForPath]);

  return null;
};

export default HelpDocsSuggestions;

import React from 'react';

const DividingHeader = ({ icon, text }) => (
  <div className="section-dividing-header">
    <span>
      {icon}
      {text}
    </span>
  </div>
);

export default DividingHeader;

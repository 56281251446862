import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { SplitLayout, ColumnLayout, ContentCard, StatCard, IndexGrid } from '@rockerbox/styleguide';
import { Grid, Header, Menu, Icon, Dropdown } from 'semantic-ui-react';
import * as d3 from 'rockerbox_d3_legacy_clone';
import rocChart from './rocplot';


export const TPFPMatrix = ({confusion_data}) => (
  <Grid columns="equal">
    <Grid.Row style={{fontWeight:"bold"}}>
      <Grid.Column> </Grid.Column> 
      <Grid.Column> Predicted Yes </Grid.Column>
      <Grid.Column> Total </Grid.Column>
      <Grid.Column> Rate </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column style={{fontWeight:"bold"}}> Actual No </Grid.Column>
      <Grid.Column> { confusion_data['false positive'] } </Grid.Column>
      <Grid.Column> { confusion_data['true negative'] + confusion_data['false positive']} </Grid.Column>
      <Grid.Column> { (confusion_data['false positive'] / ( confusion_data['true negative'] + confusion_data['false positive'] )).toPrecision(2) } </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column style={{fontWeight:"bold"}}> Actual Yes </Grid.Column>
      <Grid.Column> { confusion_data['true positive'] } </Grid.Column>
      <Grid.Column> { confusion_data['false negative'] + confusion_data['true positive']} </Grid.Column>
      <Grid.Column> { (confusion_data['true positive'] / ( confusion_data['false negative'] + confusion_data['true positive'] )).toPrecision(2) } </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column style={{fontWeight:"bold"}}> Total </Grid.Column>
      <Grid.Column> { confusion_data['false positive'] + confusion_data['true positive']} </Grid.Column>
      <Grid.Column> { confusion_data['true negative'] + confusion_data['false negative'] + confusion_data['false positive'] + confusion_data['true positive'] } </Grid.Column>
      <Grid.Column> { } </Grid.Column>
    </Grid.Row>
  </Grid>
)

class ROCPlot extends Component {
  state = {
    width: false,
    threshold: 0.5,
    confusion_data: {}
  }

  buildConfusionData = (threshold) => {
    try {
      const { cfData } = this.props;
      const confusion_data = cfData
        .sort((p,c) => p.threshold - c.threshold)
        .filter(x => x.threshold > threshold)[0]

      if (confusion_data == undefined) throw "missing data"

      confusion_data['false positive rate'] = (confusion_data['false positive'] / ( confusion_data['true negative'] + confusion_data['false positive'] )).toPrecision(2)
      confusion_data['false negative rate'] = (confusion_data['true positive'] / ( confusion_data['false negative'] + confusion_data['true positive'] )).toPrecision(2)


      return confusion_data
    } catch(e) { return {} }
  }

  setThreshold = (value) => {
    const threshold = value.currentTarget.value/100
    this._setThreshold(threshold)
  }
  _setThreshold = (threshold) => {
    const confusion_data = this.buildConfusionData(threshold)
    this.setState({ confusion_data, threshold })
  }

  renderD3() {
    const { title, data } = this.props;
    const { width, confusion_data } = this.state;
    const target = ReactDOM.findDOMNode(this.refs.rocplot);
    const chart = rocChart(width)
      .title(title)
      .marker({x: confusion_data['false positive rate'], y: confusion_data['false negative rate']})



    d3
      .select(target)
      .selectAll('svg')
      .remove();

    d3
      .select(target)
      .datum(data)
      .call(chart);
  }

  getDimensions() {
    const target = ReactDOM.findDOMNode(this.refs.rocplot);
    if (target) {
      const parent = target.parentNode
      const x = (this.state.width && (this.state.width === parent.offsetWidth)) ? false : this.setState({"width": parent.offsetWidth});
    }
  }

  componentDidMount() {
    this.getDimensions();

    const { threshold } = this.state;
    const confusion_data = this.buildConfusionData(threshold)
    this.setState({ confusion_data })
    
    this.renderD3();
  }
  componentDidUpdate(prevProps) {

    const { threshold } = this.props;
    if ( threshold && (threshold != prevProps.threshold)) {
      this._setThreshold( threshold )
    }
    this.renderD3();
  }

  render() {

    const { confusion_data, threshold } = this.state;
    const { onSetThreshold } = this.props;

    return (
      <ContentCard title="True Positives versus False Positives">
        <SplitLayout 
          rightWidth={8} 
          leftWidth={8} 
          leftContent={
            <div><div ref="rocplot" /></div>
          } 
          rightContent={
            <React.Fragment>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <b>Probability Threshold: </b> { threshold.toPrecision(2) } <br />
                    <input type="range" value={threshold*100} orientation="vertical" min={0} max={100} step={0.01} onChange={this.props.onSetThreshold || this.setThreshold}  />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <TPFPMatrix confusion_data={confusion_data} />
            </React.Fragment>
          } 
        />
        </ContentCard>
      );
  }
}

export default ROCPlot;

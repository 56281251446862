import { useMemo } from 'react';

import moment from 'moment';

export const dateArrayToQuarters = trainingData => {
  const formatString = 'YYYY[Q]Q';

  return trainingData.reduce((accu, row) => {
    const date = moment(row.date);
    const byQuarter = accu[date.format(formatString)] || [];
    byQuarter.push(row);
    accu[date.format(formatString)] = byQuarter;

    return accu;
  }, {});
};

export const trainingWithPredAndActual = (trainingData, predVsActual) => trainingData.map((row, i) => ({ ...row, ...predVsActual[i] }));
const addToBase = (baseObject, valuesObject) => Object.keys(valuesObject).reduce((accu, key) => {
  if (key === 'date') return accu;
  accu[key] = (accu[key] || 0) + valuesObject[key];
  return accu;
}, baseObject);

const sumAllKeys = data => data.reduce((accu, row) => addToBase(accu, row), {});

export const summarizeQuarterlyData = quarterlyData => {
  const totals = quarterlyData.reduce((accu, row) => addToBase(accu, row), {});
  const data = Object.keys(totals).reduce((accu, key) => {
    const value = totals[key];

    if ((key === 'pred') || (key === 'actual') || (key === 'delta')) {
      const { pred } = accu;
      pred[key] = value;
      return accu;
    }

    const { features } = accu;
    features[key] = value;
    return accu;
  }, { features: {}, pred: {} });

  return data;
};

export const useQuarterlyData = ({ modelData, trainingData }) => {
  const { predVsActual, seasonality } = modelData || {};

  const trainingWithPredAndActualData = useMemo(
    () => (trainingData ? trainingWithPredAndActual(trainingData, predVsActual) : []),
    [trainingData, predVsActual],
  );

  const baselineGroupedByFiscalQuarters = useMemo(
    () => (seasonality ? dateArrayToQuarters(seasonality) : {}),
    [seasonality],
  );

  const baselineFiscalQuarters = useMemo(
    () => Object.keys(baselineGroupedByFiscalQuarters).reduce((accu, key) => ({ ...accu, ...{ [key]: sumAllKeys(baselineGroupedByFiscalQuarters[key]) } }), {}),
    [baselineGroupedByFiscalQuarters],
  );

  const groupedByFiscalQuarters = useMemo(
    () => dateArrayToQuarters(trainingWithPredAndActualData),
    [trainingWithPredAndActualData],
  );

  const fiscalQuarters = useMemo(
    () => Object.keys(groupedByFiscalQuarters).reduce((accu, key) => ({ ...accu, ...{ [key]: summarizeQuarterlyData(groupedByFiscalQuarters[key]) } }), {}),
    [groupedByFiscalQuarters],
  );
  return { fiscalQuarters, baselineFiscalQuarters };
};

/* eslint-disable react/jsx-props-no-spreading, no-shadow */

import React from 'react';

import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const ListItem = styled.div`
  height: 36px;
  line-height: 36px;
  ${({ placeholder }) => (placeholder ? '' : 'background-color: #fbfbfb;')}
  ${({ placeholder }) => (placeholder ? 'border: 2px dashed #ddd;' : 'border-bottom: 1px solid #ddd;')}
  ${({ placeholder }) => (placeholder ? 'margin-top:2px;' : '')}
  ${({ placeholder }) => (placeholder ? 'text-align:center;' : '')}
  ${({ placeholder, clickable }) => (placeholder && clickable ? 'color:rgb(2 108 172 / 85%);cursor:pointer;' : placeholder ? 'color:#aaa;' : 'color: #333;')}
  ${({ placeholder }) => (placeholder ? 'font-weight:bold;' : '')}
  
`;

export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  // userSelect: "none",
  padding: '0 0',
  // margin: `5 0`,

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle,
});

export const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#F8F8F9' : 'none',
});

const DraggableList = props => {
  const { onChange, options, value: valueFromProps } = props;
  const [value, setValue] = React.useState(valueFromProps || []);
  const [showAvailable, setShowAvailable] = React.useState(false);
  const hasOptions = options.length > 0;

  React.useEffect(() => {
    if (value !== valueFromProps) setValue(valueFromProps);
  }, [valueFromProps]);

  const onDragEnd = v => {
    if (!v.destination || !v.destination.droppableId) return false;

    const { destination: { droppableId, index }, source } = v;
    const newValue = _.cloneDeep(value);

    const selected = options.find(c => c.value === v.draggableId);

    if (droppableId === 'selected' && source.droppableId !== droppableId) {
      newValue.splice(index, 0, selected.value);
      setValue(newValue);
      return;
    }

    if (source.droppableId !== droppableId) {
      const indexValue = value.indexOf(selected.value);
      newValue.splice(indexValue, 1);
      setValue(newValue);
      return;
    }

    if (source.index !== index) {
      newValue.splice(source.index, 1);
      newValue.splice(index, 0, selected.value);
      setValue(newValue);
    }
  };

  const removeValue = selected => {
    const newValue = _.cloneDeep(value);
    const indexValue = value.indexOf(selected.value);
    newValue.splice(indexValue, 1);
    setValue(newValue);
  };

  React.useEffect(() => {
    onChange(false, { value });
  }, [value]);

  return (
    <DragDropContext {...{ onDragEnd }}>
      <div>
        <Droppable droppableId="selected">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} {...{ ref: provided.innerRef, style: getListStyle(snapshot.isDraggingOver) }}>
              {
                value.map((id, index) => {
                  const item = options.find(row => row.value === id);
                  if (!item) return null;

                  return (
                    <Draggable key={item.value} draggableId={item.value} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <ListItem>
                            <Icon name="ellipsis vertical" style={{ opacity: 0.5 }} />
                            {item.text}
                            {item.option}
                            <Icon name="remove" corner="top right" style={{ opacity: 0.5, float: 'right', cursor: 'pointer' }} onClick={() => removeValue(item)} />
                          </ListItem>
                        </div>
                      )}
                    </Draggable>
                  );
                })
              }
              <ListItem placeholder clickable={!showAvailable} onClick={() => !showAvailable && setShowAvailable(true)}>
                {!showAvailable && <Icon name="plus" />}
                {!showAvailable ? 'Add Item' : 'Drop Item'}
              </ListItem>
            </div>
          )}
        </Droppable>
        {showAvailable && (
          <Droppable droppableId="available">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} {...{ ref: provided.innerRef, style: getListStyle(snapshot.isDraggingOver) }}>
                <div style={{ margin: 10, display: 'flex', justifyContent: 'space-between' }}>
                  <Icon name="angle up" style={{ cusor: 'pointer', color: '#026CAC', float: 'left' }} onClick={() => setShowAvailable(false)} />
                  <label>
                    Additional Options
                  </label>
                  <Icon name="angle up" style={{ cusor: 'pointer', color: '#026CAC', float: 'left' }} onClick={() => setShowAvailable(false)} />
                </div>
                {hasOptions && options.map((item, index) => (!value.includes(item.value) ? (
                  <Draggable key={item.value} draggableId={item.value} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                      >
                        <ListItem>
                          <Icon name="ellipsis vertical" style={{ opacity: 0.5 }} />
                          {item.text}
                        </ListItem>
                      </div>
                    )}
                  </Draggable>
                ) : null))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </div>
    </DragDropContext>
  );
};

export default DraggableList;

import React, { useMemo, useEffect } from 'react';

import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useRouteMatch } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

import { useURLState } from '../../components/hooks';
import * as atoms from '../atoms';
import { useGlobalState } from '../hooks/global';
import { buildPath, buildHash } from '../hooks/urlState';
import * as routes from '../routes';
import Link from '../utils/link';
import { monthAgo, weekAgo, yesterday } from '../utils/time';
import { useSavedReports } from '../views/SavedReports/_hooks/savedReports';

const ResetButton = styled(Button)`
  color: #475ddc !important;
  background: transparent !important;

  &:hover {
    border-color: transparent !important;
    background-color: #475ddc !important;
    color: white !important;
  }
`;

const defaultStartEndDate = {
  crossChannelReport: [monthAgo, yesterday],
  conversionsDetail: [yesterday, yesterday],
  conversionFunnel: [monthAgo, yesterday],
  funnelPosition: [weekAgo, yesterday],
  marketingPaths: [weekAgo, yesterday],
  newVisitors: [monthAgo, yesterday],
  spendTrends: [],
  platformBenchmarks: [],
  channelOverlap: [monthAgo, yesterday],
  platformPerformance: [monthAgo, yesterday],
  rockerboxPerformance: [monthAgo, yesterday],
};

const resetParams = () => {
  const [, setId] = useURLState('id');
  const [, setStartDate] = useURLState('startDate');
  const [, setEndDate] = useURLState('endDate');

  return { setStartDate, setEndDate, setId };
};

const ResetFilterButton = ({ setCompare }) => {
  const match = useRouteMatch();
  const { path } = match;
  // eslint-disable-next-line no-unused-vars
  const [routeName, _routePath] = Object.entries(routes).find(x => x[1] === path);

  const { setStartDate, setEndDate, setId } = resetParams();
  const { segments } = useGlobalState();
  const { savedReports, handleGetSavedReports } = useSavedReports();
  const defaultSegId = segments.find(({ featured }) => featured === 1)?.filter_id;

  const [startDate, endDate] = defaultStartEndDate[routeName];

  // jotai
  const [saveId] = useAtom(atoms.saveIDAtom);

  // jotai reset
  const resetCustomerType = useResetAtom(atoms.customerTypeAtom);
  const resetAttributionMethod = useResetAtom(atoms.attributionMethodAtom);
  const resetModel = useResetAtom(atoms.modelAtom);
  const resetSegId = useResetAtom(atoms.segIdAtom);
  const resetConvValue = useResetAtom(atoms.convValueAtom);
  const resetAttributionWindow = useResetAtom(atoms.attributionWindowAtom);
  const resetAny = useResetAtom(atoms.anyAtom);
  const resetFirst = useResetAtom(atoms.firstAtom);
  const resetMid = useResetAtom(atoms.midAtom);
  const resetLast = useResetAtom(atoms.lastAtom);
  const resetAndOr = useResetAtom(atoms.andOrAtom);
  const resetTier1 = useResetAtom(atoms.tier1Atom);
  const resetTier2 = useResetAtom(atoms.tier2Atom);
  const resetTier3 = useResetAtom(atoms.tier3Atom);
  const resetTier4 = useResetAtom(atoms.tier4Atom);
  const resetTier5 = useResetAtom(atoms.tier5Atom);
  const resetGoal = useResetAtom(atoms.goalAtom);
  const resetSearch = useResetAtom(atoms.searchAtom);
  const resetTiers = useResetAtom(atoms.tiersAtom);
  const resetColumns = useResetAtom(atoms.columnsAtom);
  const resetCategory = useResetAtom(atoms.categoryAtom);
  const resetCategoryValue = useResetAtom(atoms.categoryValueAtom);
  const resetSelectedChannel = useResetAtom(atoms.selectedChannelAtom);
  const resetTopTab = useResetAtom(atoms.topTabAtom);
  const resetReportType = useResetAtom(atoms.reportTypeAtom);
  const resetMinPathLen = useResetAtom(atoms.minPathLenAtom);
  const resetCompareRange = useResetAtom(atoms.compareRangeAtom);

  useEffect(() => {
    handleGetSavedReports();
  }, [saveId]);

  const savedReport = useMemo(() => {
    if (!savedReports || !saveId) return null;
    return savedReports.find(({ id }) => id === saveId);
  }, [savedReports, saveId]);

  const fullPath = useMemo(() => {
    if (!savedReport) return '/';
    const { route, params: savedReportParams } = savedReport;
    const rebuiltPath = buildPath(route, savedReportParams.route);
    const rebuiltHash = savedReportParams.hash ? buildHash(savedReportParams.hash) : '';
    return rebuiltPath + rebuiltHash;
  }, [savedReport]);

  const resetFilters = () => {
    resetCustomerType();
    resetAttributionMethod();
    resetModel();
    resetSegId();
    resetConvValue();
    resetAttributionWindow();
    resetAny();
    resetFirst();
    resetMid();
    resetLast();
    resetAndOr();
    resetTier1();
    resetTier2();
    resetTier3();
    resetTier4();
    resetTier5();
    resetGoal();
    resetSearch();
    resetTiers();
    resetColumns();
    resetCategory();
    resetCategoryValue();
    resetSelectedChannel();
    resetTopTab();
    resetReportType();
    resetMinPathLen();
    setStartDate(startDate);
    setEndDate(endDate);
    setId(defaultSegId);
    resetCompareRange();
    // eslint-disable-next-line no-unused-expressions
    setCompare && setCompare(false);
  };

  return (
    savedReport ? (
      <Link to={fullPath}>
        <ResetButton
          icon="sync"
        />
      </Link>
    ) : (
      <ResetButton
        icon="sync"
        onClick={resetFilters}
      />
    )
  );
};

export default ResetFilterButton;

import React from 'react';
import { Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import CacheContext from '../../../utils/CacheContext';
import { getIntegrations } from '../../api/account';
import * as routes from '../../baseRoutes';

import { FREE_PLATFORMS } from './constants';
import { CHANNELS } from '../SpendTrends/constants';

const Item = ({ platform, integrations, advertiserIntegrations }) => {
  if (platform === 'bing') return null;
  if (platform === 'more') {
    return (
      <Link
        as="li"
        className="item"
        to={routes.connectIntegration}
      >
        <FontAwesomeIcon
          as="i"
          icon={faArrowCircleRight}
          className="icon more"
        />
        <span className="text">More...</span>
      </Link>
    );
  }
  const integration = integrations.find(x => x.platform === platform);
  const hasIntegrations = advertiserIntegrations.map(x => x.platform);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
    <li
      // eslint-disable-next-line no-return-assign
      onClick={() => window.location = `${integration.path}`}
      className="item"
    >
      {hasIntegrations.includes(platform)
        ? (
          <FontAwesomeIcon
            as="i"
            icon={faCheckCircle}
            className="icon completed"
          />
        )
        : (
          <img
            src={`https://images.rockerbox.com/integrations/${integration?.icon_img}`}
            className="icon"
            alt="integration icon"
          />
        )}
      <span className="text">{CHANNELS.find(p => p.key === (platform === 'adwords' ? 'google' : platform)).name}</span>
    </li>
  );
};

const GetStartedIntegrations = ({ integrations }) => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState, Context] = React.useContext(CacheContext);
  const advertiserIntegrations = Context.getCache('advertiserIntegrations', getIntegrations);

  const platforms = Object.keys(FREE_PLATFORMS);

  if (!integrations || !advertiserIntegrations) return null;

  return (
    <div className="get-started-integrations">
      <Header
        as="h1"
        content={(
          <>
            Get started
            <br />
            with Rockerbox
          </>
)}
        subheader="Connect your advertising platforms to pull ad spend and performance data"
      />
      <ul className="platforms-list">
        {platforms.map(i => <Item platform={i} {...{ integrations, advertiserIntegrations }} />)}
        <Item platform="more" />
      </ul>
    </div>
  );
};

export default GetStartedIntegrations;

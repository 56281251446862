import React from 'react';

import { Header } from 'semantic-ui-react';

import { useBudgetPeriod } from './useBudgetPeriod';
import MMMDateRangeHooks from '../../../parts/MMMDateRangeHooks';
import { SplitLayout } from '../Layout';

const BudgetPeriod = () => {
  const { startDate, endDate, onDateChange } = useBudgetPeriod();

  return (
    <SplitLayout
      header="2. Setup Budget Period"
      leftContent={(
        <MMMDateRangeHooks {...{ startDate, endDate, onDateChange, localStorageKey: 'budgetPeriod' }} />
      )}
      rightContent={(
        <Header as="h4" style={{ margin: 10, marginTop: 0, fontWeight: 400 }}>
          The budget period is the time frame for which you want to budget your marketing mix.
          <br />
          <br />
          Choose the granularity of your budget allocation by selecting whether you want your budget to be managed on a daily, weekly, or monthly basis.
          This setting ensures consistency with how you set budgets in other platforms or with your media partners.
        </Header>
        )}
    />
  );
};

export default BudgetPeriod;

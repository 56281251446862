import React, { useMemo } from 'react';

import { Alert } from '../../components';
import { useGlobalState } from '../../hooks/global';

const Announcements = () => {
  const { announcements } = useGlobalState();

  const majorAnnouncements = useMemo(
    () => announcements.filter(a => !!a.active && !!a.major),
    [announcements],
  );

  if (!majorAnnouncements?.length) return null;

  return (
    <div className="announcements">
      {majorAnnouncements.map((x, i) => (
        <Alert
          key={i}
          type="warning"
          title={x.title}
          description={x.message}
        />
      ))}
    </div>
  );
};

export default Announcements;

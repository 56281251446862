import React from 'react';
import { heroIntegrationsImage } from '@rockerbox/styleguide';
import { Image, Header, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import * as routes from '../baseRoutes';

const NoConnections = () => (
  <div style={{ paddingTop: 30, textAlign: 'center' }}>
    <Image
      src={heroIntegrationsImage}
      size="medium"
      centered
      className="m-t-20"
    />
    <Header as="h2" style={{ margin: '30px 0 20px' }}>
      Connect your
      {' '}
      <b>Integrations</b>
      <Header.Subheader style={{ marginTop: 5 }}>
        We&apos;ll automatically pull all your running ads, amount spent, and how they&apos;re performing
      </Header.Subheader>
    </Header>
    <Button
      primary
      size="big"
      content="Connect Accounts"
      as={Link}
      to={routes.connectIntegration}
    />
  </div>
);

export default NoConnections;

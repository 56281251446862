import React from 'react';

import { Link } from 'react-router-dom';
import { List } from 'semantic-ui-react';

const iconNameBuilder = (loading, completed) => {
  if (loading) return 'spinner';
  if (completed) return 'circle outline check';
  return 'circle outline';
};

const Checklist = ({ items }) => (
  <List divided relaxed>
    {items.map(item => (
      <List.Item
        key={item.title}
        as={(item.link || item.onClick) && ((item.link && !item.external) ? Link : 'a')}
        href={item.link}
        target={!!item.external && '_blank'}
        to={item.link}
        className={item.completed && 'completed'}
        onClick={item.onClick}
      >
        <List.Content floated="right">
          {item.button}
        </List.Content>
        {!!item.icon
          ? <i className="large icon middle aligned custom-icon">{item.icon}</i>
          : (
            <List.Icon
              size="large"
              verticalAlign="middle"
              loading={item.loading}
              name={iconNameBuilder(item.loading, item.completed)}
              color={item.completed ? 'green' : ''}
              style={!!item.loading ? { padding: 0 } : {}}
            />
          )}
        <List.Content
          as={item.completed && 'strike'}
          style={item.button && { paddingTop: 4 }}
        >
          <List.Header>
            {item.title}
          </List.Header>
          <List.Description>
            {item.description}
          </List.Description>
        </List.Content>
      </List.Item>
    ))}
  </List>
);

export default Checklist;

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { activateAccount } from '../../../utils/api';
import {
  Grid,
  Segment,
  Form,
  Header,
  Input,
  Button,
  Container,
  Message
} from 'semantic-ui-react';
import { GatewayWrap } from '@rockerbox/styleguide';

class PasswordReset extends Component {
  state = {
    password: '',
    confirmPassword: '',
    disabledStatus: true,
    nonce: '',
    hasError: false,
    errorMsg: '',
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const nonce = urlParams.get('nonce');

    this.setState({ nonce });
  }

  handleInputChange = event => {
    const target = event.target;
    const name = target.name;
    const value = event.target.value;

    this.setState(
      {
        [name]: value
      },
      () => this.validatePassword()
    );
  };

  validatePassword = () => {
    const { password, confirmPassword } = this.state;
    if (
      password.length >= 8 &&
      confirmPassword.length >= 8 &&
      password === confirmPassword
    ) {
      this.setState({ disabledStatus: false });
    } else {
      this.setState({ disabledStatus: true });
    }
  };

  handleSubmit = () => {
    const submitObj = {
      password: this.state.password,
      nonce: this.state.nonce
    };

    activateAccount(submitObj).then(data => {
      if (data.error) {
        const msg = data.error == "Nonce mismatch" ? 'Bad password reset link.' : 'Error resetting your password.';
        this.setState({hasError: true, errorMsg: msg + ' Please request a new link by clicking "Request new reset link"'})
      }
      else window.location = '/'
    });
  };

  render() {
    return (
      <GatewayWrap>
        <Segment raised loading={false}>
          <Header as="h3" color="grey" className="m-b-20">
            Reset your Account Password
            <Header.Subheader>
              Set a new password for your acccount.
            </Header.Subheader>
          </Header>
          {this.state.hasError && (
            <Message negative>
              <Message.Header>{this.state.errorMsg}</Message.Header>
            </Message>
          )}
          <Form onSubmit={this.handleSubmit}>
            <Container textAlign="left">
              <Form.Field>
                <label>
                    <Grid>
                      <Grid.Column width={5} floated="left">
                        Password
                      </Grid.Column>
                      <Grid.Column width={5} floated="right" textAlign="right">
                        <a href="/password-forgot" tabIndex={-1}>Request new reset link</a>
                      </Grid.Column>
                    </Grid>
                  </label>
                <Input
                  type="password"
                  fluid
                  icon="shield"
                  iconPosition="left"
                  placeholder="Use at least 8 characters"
                  value={this.state.password}
                  name="password"
                  onChange={this.handleInputChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Confirm Password</label>
                <Input
                  type="password"
                  fluid
                  icon="shield"
                  iconPosition="left"
                  placeholder="One more time, just to make sure!"
                  value={this.state.confirmPassword}
                  name="confirmPassword"
                  onChange={this.handleInputChange}
                />
              </Form.Field>
            </Container>
            <div className="m-t-20">
              <Button
                primary
                fluid
                size="big"
                disabled={this.state.disabledStatus}
              type="submit">
                Reset Password
              </Button>
            </div>
          </Form>
        </Segment>
        <div className="white m-t-30">
          Need help?&nbsp;
          <a href="mailto:support@rockerbox.com" className="white underlined">
            Contact us
          </a>
        </div>
      </GatewayWrap>
    );
  }
}

export default withRouter(PasswordReset);

import React, { Component } from 'react';
import { Message, Menu, Button, Form, Icon } from 'semantic-ui-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getListStyle, getItemStyle } from './dndHelpers';

import LookupTest from './LookupTest';
import LookupResults from './LookupResults';
import LookupChecks from './LookupChecks';
import { PaddedLabel } from './form';

const STYLE = {marginBottom: 10}
const rowStyle = {
  display: 'flex',
  alignItems: 'center',
  paddingTop: '0px',
  paddingBottom: '0px'
}

const LookupResultsMenu = (props) => {
  
  const [showType, setShowType] = React.useState("current");
  const onChange = (_, evt) => setSearchValue(evt.value);

  return (
    <React.Fragment>
      <div style={{display:"flex",alignContent:"stretch"}}>
        <div style={{flex:1}}>
          <Menu pointing secondary>
            <Menu.Item name={"Current Sequence"} active={showType === 'current'} onClick={() => setShowType("current")} />
            <Menu.Item name={"Test All UID Lookups"} active={showType === 'uid'} onClick={() => setShowType("uid")} />
            <Menu.Item name={"Test Sequence"} active={showType === 'sequence'} onClick={() => setShowType("sequence")} />
          </Menu>
          { showType == "current" && <LookupResults conversionSegmentId={props.id} /> }
          { showType == "uid" && <LookupChecks {...props} /> }
          { showType == "sequence" && <LookupTest {...props} /> }
        </div>
      </div>
    </React.Fragment>
  )
}

class LookupItems extends Component {
  render() {
    const { lookupOptions, handleAddLookup, handleLookupSelect, handleLookupRemove } = this.props;
    return (
      <React.Fragment>
        <Message
          size="tiny"
          header="About Identity Resolution"
          content="Identity resolution is a deterministic way of tying together customer data. It enables a single customer to have mutliple anonymous identifiers that are used across different devices to be tied together using information that you know about a customer, such as their email address or stored customer id. The sequence in which these identifiers are linked together is important because they resolve in sequence. Below is an interactive environment that allows you to test the lookup sequence for the conversion event"
        />
        <Form.Group widths="equal">
          <div className="field">
            <PaddedLabel>Identity Resolution (Lookups)</PaddedLabel>
            <Button size="small" content="add new lookup" icon="plus" type="button" onClick={handleAddLookup} style={STYLE}/>
            <DragDropContext onDragEnd={this.props.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {this.props.lookupItems.length > 0 && this.props.lookupItems.map((item, index) => (
                      <Draggable key={item.drag_id} draggableId={item.drag_id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {
                              <div style={rowStyle}>
                                <Icon name="arrows alternate" size="large" color="grey"/>
                                <Form.Select search options={lookupOptions} value={item.table_name} onChange={(e,data) => handleLookupSelect(data.value, item.drag_id)}/>
                                <Button icon="remove alternate" color="grey" style={{paddingLeft:"10px"}} onClick={() => handleLookupRemove(item.drag_id) } as='a' size='mini' />
                              </div>
                            }
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="field">
            <LookupResultsMenu {...this.props} />
          </div>
        </Form.Group>
      </React.Fragment>
    )
  }
}

export default LookupItems;


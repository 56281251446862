import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'rockerbox_d3_legacy_clone';
import dailyChart from './chart';
import moment from 'moment';
import { Table } from 'semantic-ui-react';

export const SparklineCell = ({item, col}) => {
  const data = item[col.key]
  const width = 100
  const height = 18
  const style = {width: "0px"}
  return <Table.Cell><DailyChart {...{width, height, style, data, forcedBuckets: Math.max(data.length,30)}} /></Table.Cell> 
}

export default class DailyChart extends Component {
  renderD3() {
    const { title, refPrefix } = this.props;
    const data = this.props.data;
    if (data) {
      const width = this.props.width || 250;
      const height = this.props.height || 25;
      const start_date = this.props.startDate ? this.props.startDate : moment.utc().utcOffset(-5).subtract(data.length, 'days').format('YYYY-MM-DD');
      const ref = `${refPrefix}dailychart`;
      const target = ReactDOM.findDOMNode(this.refs[ref]);
      const chart = dailyChart(this.props.forcedBuckets).width(width).height(height).startDate(start_date)

      d3
      .select(target)
      .selectAll('svg')
      .remove();

      d3
      .select(target)
      .datum(data)
      .call(chart);
    }
  }

  componentDidMount() {
    this.renderD3();
  }
  componentDidUpdate() {
    this.renderD3();
  }

  render() {
    return (
      <div ref={`${this.props.refPrefix}dailychart`} style={this.props.style} />
    );
  }
}

import React, { useState, useEffect } from 'react';
import { Header, Grid, GridColumn, Divider, Loader, Icon } from 'semantic-ui-react';
import PricingGraph from '../../components/StarterPricing/graph';
import { useGlobalState } from '../../hooks/global';
import { getStripePricingTiers } from '../../api/account';
import { getPlatformPerformanceSummary } from '../../api/platformPerformanceApi';
import { getSpendByPlatform } from '../../api/attribution';
import { monthlyAdSpendToPrice, calculatePortionOfMonthRemaining, formatDollarValue } from '../../components/StarterPricing/helpers';
import { getFirstDayOfNextMonth, getFirstDayOfCurrentMonth, getYesterday, setStatus, setColor } from './helpers';

const Pricing = ({ customerId, activeSubscription, activeThrough, setToCancel }) => {
  const { authorizations } = useGlobalState();
  const [pricing, setPricing] = useState(null);
  const [graphLoading, setGraphLoading] = useState(true);
  const [subscriptionStatusLoading, setSubscriptionStatusLoading] = useState(true);
  const [totalSpend, setTotalSpend] = useState(false);
  const [estimatedCost, setEstimatedCost] = useState(null);
  const [hasActiveSub, setHasActiveSub] = useState(false);
  const [spendComplete, setSpendComplete] = useState(false);

  const PLATFORM_PERFORMANCE_LIST = ['facebook', 'adwords', 'pinterest', 'snapchat', 'tiktok', 'bing', 'linkedin'];
  const nonPlatformPerformanceList = ['adroll', 'criteo'];
  // eslint-disable-next-line max-len
  const estimatedSpendText = setToCancel ? `Your Rockerbox Starter subscription expires on ${getFirstDayOfNextMonth()}` : `Estimated next bill on ${getFirstDayOfNextMonth()}: $${formatDollarValue(estimatedCost)}`;
  const spendPendingText = 'Calculating your estimated next bill...';
  const startDate = getFirstDayOfCurrentMonth();
  const endDate = getYesterday();

  useEffect(() => {
    if (!customerId || !activeSubscription) return;
    (activeSubscription.status === 'past_due') ? setHasActiveSub(false) : setHasActiveSub(true);
    setSubscriptionStatusLoading(false);
  }, [customerId, activeSubscription]);

  useEffect(() => {
    if (!authorizations) return;
    const apiCalls = [];
    authorizations.filter(a => PLATFORM_PERFORMANCE_LIST.includes(a.platform) || nonPlatformPerformanceList.includes(a.platform)).forEach(a => {
      // eslint-disable-next-line no-shadow
      const { platform, authorizations } = a;
      if (PLATFORM_PERFORMANCE_LIST.includes(platform)) {
        apiCalls.push(getPlatformPerformanceSummary(platform, startDate, endDate));
      } else if (nonPlatformPerformanceList.includes(platform)) {
        const vPlatform = platform === 'adroll' ? 'adroll_v1' : platform;
        // eslint-disable-next-line array-callback-return
        authorizations.map(account => {
          apiCalls.push(getSpendByPlatform(vPlatform, account.account_id, startDate, endDate));
        });
      }
    });
    Promise.all(apiCalls).then(responses => {
      // eslint-disable-next-line no-shadow
      const totalSpend = responses.reduce((totalAccumulator, platformLevelItem) => {
        const platformSpend = platformLevelItem.reduce((platformAccumulator, accountLevelItem) => {
          // eslint-disable-next-line no-prototype-builtins
          if (accountLevelItem.hasOwnProperty('spend')) {
            return (platformAccumulator + accountLevelItem.spend);
          }
          const accountSpend = accountLevelItem.metrics.reduce((accountAccumulator, dayLevelItem) => accountAccumulator + dayLevelItem.summary.spend, 0);
          return (platformAccumulator + accountSpend);
        }, 0);
        return totalAccumulator + platformSpend;
      }, 0);
      setSpendComplete(true);
      setTotalSpend(totalSpend);
    });
  }, [authorizations]);

  useEffect(() => {
    if (!spendComplete) return;
    const portionOfMonthElapsed = 1 - calculatePortionOfMonthRemaining();
    const projectedSpend = totalSpend / portionOfMonthElapsed;
    getStripePricingTiers()
      .then(pricingResponse => {
        setPricing(pricingResponse);
        setEstimatedCost(monthlyAdSpendToPrice(projectedSpend, pricingResponse.response));
        setGraphLoading(false);
      });
  }, [totalSpend]);

  return (
    <Grid className="pricing-pane">
      <GridColumn width={16}>
        <Grid className="pane-header">
          <GridColumn width={12} style={{ display: 'inline-flex', paddingBottom: '0px' }}>
            <Header
              as="h2"
              content="Starter Plan"
              className="header"
            />
            {!subscriptionStatusLoading
                && (
                <span className="status-icon">
                  <Icon color={setColor(hasActiveSub)} name="circle" size="small" className="icon" />
                  <p>{setStatus(hasActiveSub, activeThrough, setToCancel)}</p>
                </span>
                )}
            {subscriptionStatusLoading && <span className="status-icon-loading" />}
          </GridColumn>
        </Grid>
        <p className="your-account-text">
          Your account is billed monthly, based on your ad spend
        </p>
        {!graphLoading
            && (
            <Header
              as="h3"
              content={estimatedSpendText}
              className="estimated-bill-text"
            />
            )}
        {graphLoading
        && (
        <Grid style={{ margin: '0 -12px 0 -12px' }}>
          <GridColumn width={16} style={{ display: 'inline-flex', padding: '0 12px 0 12px' }}>
            <span className="estimated-bill">
              <Header
                as="h3"
                content={spendPendingText}
                className="estimated-bill-text"
              />
              <Loader active size="tiny" className="text-loader" />
            </span>
          </GridColumn>
        </Grid>
        )}
        <Divider style={{ paddingBottom: '26px' }} />
        <Header
          as="h3"
          content="Starter Plan pricing curve"
          style={{ margin: '-30px 0 12px 0' }}
        />
        {!graphLoading && <PricingGraph pricing={pricing} givenSpendLevel={totalSpend} heightPercent={0.58} minHeight={0} YAxisLabel="Cost Per $1K in Ad Spend*" />}
        {graphLoading && <Loader active className="main-loader" />}
        <p className="disclaimer-text">
          *Estimated cost does not reflect any promotions or discounts applied to your account.
        </p>
      </GridColumn>
    </Grid>
  );
};

export default Pricing;

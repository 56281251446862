import { useMemo } from 'react';

import { spendFormatter } from '../../../../utils/valueFormatter';
import { CurrencyCell, NumberCell, ChannelCell, SpendEffectCell, CPACell, AddXCell } from '../../parts/tableCells';
import { inverseChartData, changeDisplayName } from '../helpers';

export const useChartCalculations = (isConversionKPI, chartData, tierColorMap, featureNameMap) => {
  const { selectedData, selectedMetric, selectedKPIData } = chartData;

  const hideKeys = useMemo(() => {
    if (!selectedData.length) return [];
    const first = selectedData[0];
    const firstKeysSet = new Set(Object.keys(first));
    const colorKeysSet = new Set(Object.keys(tierColorMap));

    const keysToHide = [...firstKeysSet].filter(key => !colorKeysSet.has(key));

    return keysToHide;
  }, [tierColorMap, selectedData]);

  const lineChartProps = useMemo(() => {
    const data = isConversionKPI ? inverseChartData(selectedMetric) : selectedMetric;
    const lineChartData = changeDisplayName(data, featureNameMap);
    const lineChartTitle = isConversionKPI ? 'CPA by Channel by Day' : 'ROI by Channel by Day';
    const lineChartFormatter = isConversionKPI ? spendFormatter('USD') : v => v.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return { lineChartData, lineChartTitle, lineChartFormatter, hideKeys };
  }, [isConversionKPI, selectedMetric, hideKeys]);

  const barChartProps = useMemo(() => {
    const barChartData = changeDisplayName(selectedKPIData, featureNameMap);
    const barChartTitle = `${isConversionKPI ? 'Conversion' : 'Revenue'} by Channel by Day`;
    const barChartFormatter = isConversionKPI ? v => v.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : spendFormatter('USD');
    return { barChartData, barChartTitle, barChartFormatter };
  }, [isConversionKPI, selectedKPIData]);

  return { tierColorMap, hideKeys, lineChartProps, barChartProps };
};

export const useTableCalculations = (timeSeriesDataByDateRange, isConversionKPI, tierColorMap) => {
  const maxVal = useMemo(() => (
    Math.max(...timeSeriesDataByDateRange.map(item => Math.max(item.spend_share, item.effect_share)))
  ), [timeSeriesDataByDateRange]);

  const cols = useMemo(() => {
    if (isConversionKPI) {
      return (
        [
          { as: ChannelCell, key: 'display', display: 'Channel' },
          { key: 'spend_total', display: 'Spend', as: CurrencyCell, sigfigs: 2 },
          { key: 'revenue_total', display: 'Predicted Conversions', as: NumberCell },
          { key: 'roi_total', display: 'CPA', as: CPACell },
          { key: 'effect_spend_ratio', display: 'Conversion vs Spend', as: SpendEffectCell, maxValue: maxVal },
        ]
      );
    } return (
      [
        { as: ChannelCell, key: 'display', display: 'Channel' },
        { key: 'spend_total', display: 'Spend', as: CurrencyCell, sigfigs: 2 },
        { key: 'revenue_total', display: 'Predicted Revenue', as: CurrencyCell },
        { key: 'roi_total', display: 'ROI', as: AddXCell },
        { key: 'effect_spend_ratio', display: 'Revenue vs Spend', as: SpendEffectCell, maxValue: maxVal },
      ]
    );
  }, [maxVal, isConversionKPI]);

  const tableData = useMemo(() => timeSeriesDataByDateRange.map(row => ({
    ...row,
    color: tierColorMap[row.display],
  })), [timeSeriesDataByDateRange, tierColorMap]);

  return { cols, tableData };
};

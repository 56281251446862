import React, { Component } from "react";
import { ContentCard, PagedIndexGrid } from "@rockerbox/styleguide";
import { getConversionSegments, getTemporalTiersData } from "../../utils/api";
import { _timeRange, yesterday, month } from "../timerange";
import { _pushPath } from "../pushpath.js";

class TemporalDataSource extends _pushPath(_timeRange(Component)) {
  state = {
    featuredFilterId: false,
    customerData: [],
    loading: true
  };

  componentDidMount() {
    const { startDate, endDate } = this.props.match.params;
    getConversionSegments().then(data => {
      const featuredFilterId = data
        .filter(segment => segment.featured == 1)
        .map(seg => {
          return { id: seg.action_id };
        })[0]["id"];
      this.setState({ featuredFilterId });
      this.updatePath({ startDate: month, endDate: yesterday });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { featuredFilterId } = this.state;
    const { startDate, endDate } = this.props.match.params;
    const {
      startDate: prevStartDate,
      endDate: prevEndDate
    } = prevProps.match.params;
    if (
      (startDate && startDate !== prevStartDate) ||
      (endDate && endDate !== prevEndDate) ||
      prevState.featuredFilterId != featuredFilterId
    ) {
      getTemporalTiersData(featuredFilterId, startDate, endDate).then(
        customerData => {
          this.setState({ customerData, loading: false });
        }
      );
    }
  }

  render() {
    const { customerData, loading } = this.state;
    const cols = [
      { display: "Date", key: "date" },
      { display: "Tier One", key: "tier_one" },
      { display: "Tier Two", key: "tier_two" },
      { display: "Tier Three", key: "tier_three" },
      { display: "Tier Four", key: "tier_four" },
      { display: "Tier Five", key: "tier_five" },
      { display: "Num Events", key: "num_events" }
    ];

    return (
      <ContentCard
        hasTable
        title="Temporal Data"
        explainer=""
        topRight={this.renderDateSelector()}
      >
        {loading && <ContentCard.CardLoader />}
        {!loading && (
          <PagedIndexGrid
            itemsPerPage={100}
            data={customerData}
            cols={cols}
            as={ContentCard.Table}
            idKey="key"
            expandedComponent={this.expandedSection}
            fallbackMsg="No customer data"
          />
        )}
        {this.renderDateModal()}
      </ContentCard>
    );
  }
}

export default TemporalDataSource;

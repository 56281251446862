import React from 'react';
import { getPinterestSegment } from '../../utils/api';
import Impact from '../Impact';

const getPinterestSegmentId = () => {
  return getPinterestSegment()
    .then(segments => (segments.length > 0) ? segments[0].filter_id : false)
}

const Pinterest = (props) => {
  return <>
    <Impact title="Pinterest Events" datasets={["pinterest_difference"]} getSegmentId={getPinterestSegmentId} />
  </>

}

export default Pinterest

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import moment from 'moment';
import * as d3 from 'rockerbox_d3_legacy_clone';

import WidthWrapper from './WidthWrapper';

const BarWrap = styled.div`
& div.summary_tooltip_stacked {
  position: absolute;
  text-align: center;
  vertical-align: middle;
  width: 250px;
  min-height: 70px;
  padding: 10px;
  background: rgba(0, 0, 0, .8);
  color: white;
  border: 0px;
  border-radius: 10px;
  opacity: 1;
  pointer-events: none;
  font: 14px sans-serif;
}

& div.summary_tooltip {
  position: absolute;
  text-align: center;
  vertical-align: middle;
  width: 150px;
  min-height: 50px;
  padding: 10px;
  background: rgba(0, 0, 0, .8);
  color: white;
  border: 0px;
  border-radius: 10px;
  opacity: 1;
  pointer-events: none;
  font: 14px sans-serif;
}

& .summary_dot {
height: 12px;
width: 12px;
padding-top: 2px;
border-radius: 50%;
display: inline-block;
}

& rect {
border-radius: 0px;
}
`




const numberFormatOptions = {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
}

const numberFormatter = (new Intl.NumberFormat('en-US', numberFormatOptions)).format;

export const colorNames = {
  "green": "#66D9AE",
  "orange": "#FFA278",
  "purple": "#7C78DC",
  "red": "#E76D8E",
  "blue": "#0489c1",
  "yellow": "#FFDF78",
  "dark-green": "#42C493",
  "dark-orange": "#DE794C",
  "dark-purple": "#5B57C8",
  "dark-red": "#C94568",
  "dark-blue": "#03638D",
  "dark-yellow": "#DEBC4C",
  "light-green": "#92EBC9",
  "light-orange": "#FFBC9E",
  "light-purple": "#A3A0EC",
  "light-red": "#F095AD",
  "light-blue": "#1298D2",
  "light-yellow": "#FFE89E",
}

const greys = [
  "#9D9C9C","#7D7E7D","#6B6A6A","#555555","#404040","#333333","#292929","#1F1F1F",
]

export const solid_colors = Object.keys(colorNames).map(x => colorNames[x] )
export const colors = solid_colors

class StackedBar extends Component {

  componentDidMount() { this.renderBar() }
  componentDidUpdate(prevProps) { 
    if (this.props.data != prevProps.data) this.renderBar() 
  }

  setupVariables() {

    const { 
      width, height = 240,
      minBarWidth = 2, maxBarWidth = (this.props.tickType == "month") ? 120 : 40, space = 2,
      minNumItems = 30, 
      hideXAxis = false, stacked = false,
      markerSize = 6, fromTop = 30,
      data, 
      exclude = [], 
      customRange 
    } = this.props;

    const dateKey = Object.keys(data[0]).find(key => key.includes("date"))

    const barColors = this.props.colors || colors;

    const columns = [dateKey, ...Object.keys(data.reduce((p,c) => Object.assign(p,c), {})).filter(key => key != dateKey)]
    const dataColumns = columns.slice(1).filter(x => (exclude.indexOf(x) == -1) )

    const columnColors = dataColumns.length === 1 ? {[dataColumns[0]]: '#0489c1'} : dataColumns.reduce((p,c,i) => {
      p[c] = barColors[i] || 'grey'
      return p
    }, {})

    const totals = data.map((row) => d3.sum(dataColumns.map(col => row[col])))
    const max = d3.max(totals)
    const numItems = Math.max(minNumItems, data.length)

    const paddingTop = hideXAxis ? 20 : 50
    const paddingBottom = hideXAxis ? 0 : 30 
    const drawableHeight = height - paddingTop - paddingBottom

    const paddingLeft = 15
    const paddingRight = 15

    const usableWidth = width - paddingLeft - paddingRight
    const _width = Math.min(Math.max(usableWidth/numItems,minBarWidth), maxBarWidth)

    const range = !!customRange ? customRange : [0,max];
    const vScale = d3.scale.linear().range([0,drawableHeight]).domain(range);


    return {
      dateKey,
      drawableHeight,
      dataColumns,
      columnColors,
      totals,
      height,
      paddingBottom,
      paddingLeft,
      paddingRight,
      usableWidth,
      width: _width,
      space,
      vScale,
      data,
      fromTop,
      markerSize,
      stacked,
      numItems
    }

  }

  renderWrapper(variables) {
    const { tickType, tickFormat } = this.props;
    const target = ReactDOM.findDOMNode(this.refs.bar);

    const {
      width, paddingLeft, paddingRight, height,
      data, numItems, dateKey
    } = variables


    const [minDate, maxDate] = [d3.min(data, x => x[dateKey]), d3.max(data, x => x[dateKey])];

    const type = tickType || "day";
    const formatDate = tickFormat ? date => moment(date).format(tickFormat) :
      type == "day" ? date => moment(date).format("YYYY-MM-DD") :
      type == "week" ? date => moment(date).startOf("week").format("YYYY-MM-DD") :
      type == "month" ? date => moment(date).format("YYYY-MM-01") : 
        date => moment(date).format("YYYY-MM-DD")

    const dateRange = d3.time.scale()
      .domain([moment(minDate), moment(maxDate)])
      .ticks(d3.time[type], 1)
      .map(formatDate)
      .reverse()

    const nested = d3.nest()
      .key(x => x[dateKey])
      .map(data)

    const newData = dateRange
      .map(date => (nested[date]|| [{}])[0] )

    debugger


    const svg = d3.select(target.parentNode)
      .selectAll("svg").data([newData]);

    svg
      .enter()
        .append("svg")

    svg.selectAll('g').remove();

    svg
      .attr("width",numItems*(width)+paddingLeft+paddingRight)
      .attr("height",height)

    const main = svg.selectAll("g.main")
      .data(x => [x]);

    main.enter()
      .append("g")
      .attr("class","main")

    main
      .attr("transform","translate(" + paddingLeft + ",0)")
      .attr("width", numItems*(width))

    return { svg, main }
  }

  renderBottomAxis(main, variables) {

    const {
      dateKey,
      width,
      fromTop,
      markerSize,
      vScale,
      totals,
      height,
      paddingBottom,
      numItems
    } = variables

    const axis = main.selectAll("g.top.axis")
      .data(x => [x]);

    axis.enter()
      .append("g").attr("class","top axis")

    const lineAxis = axis.selectAll("line.baseline")
      .data(x => [x])
      .enter()
        .append("line")
        .attr("class","baseline")
        .attr("stroke-dasharray", "5,5")
        .attr("y1",fromTop)
        .attr("y2",fromTop)
        .attr("x0",0)
        .attr("x1",(width)*numItems)
        .attr("style","stroke:#ccc;stroke-width:2")


    const buildTick = (pos, label) => {

      const tick = axis.selectAll("g." + label)
        .data(x => [x])
        .enter()
          .append("g")
          .attr("class",label)
          .attr("transform","translate("+ ((width)*(numItems-1-pos) + (width)/2 ) + ","+ (height-markerSize/2) +")")

      tick
        .append("rect")
        .attr("width",markerSize)
        .attr("height",markerSize)
        .attr("transform","translate(-"+(markerSize/2)+",-"+ (20+markerSize/2) + ")")
        .attr("fill","#333")

      tick
        .append("line")
        .attr("style","stroke:#ccc;stroke-width:1")
        .attr("y1",markerSize + 2)
        .attr("size", vScale(totals[pos]))
        .attr("y2",Math.max(height - paddingBottom - fromTop - (vScale(totals[pos]) || 0), markerSize + 2) )

      tick
        .append("text")
        .text(label.toUpperCase())
        .attr("transform","translate(0,-5)")
        .style("text-anchor","middle")
        .style("font-weight","900")
        .style("font-size","1.1em")


    }

    const datum = main.datum()

    if (datum.length > 0 && !this.props.hideXAxis) {
      const lastDate = datum[0][dateKey]
      const yest = moment().subtract(1, 'days').format("YYYY-MM-DD")
      const lastMoment = moment(datum[0][dateKey])

      const STEP_SIZE = (datum.length / 7 < 11) ? 7 : parseInt(datum.length / 11);
      d3.range(0,datum.length,STEP_SIZE).map((num) => {
        if (num == 0) buildTick(0, (lastDate == yest) ? "YESTERDAY" : lastMoment.format("MMM-Do"))
        else buildTick(num, lastMoment.subtract(STEP_SIZE, this.props.tickType + "s").format("MMM-Do"))
      })
    }

  }

  renderMainBar(main, variables) {
    const {
      width, dataColumns, space, height, paddingBottom, columnColors, vScale, numItems,
      drawableHeight, stacked,
      dateKey
    } = variables

    const { onClick, tooltipKey, tooltipDirection } = this.props;
    const tipDirection = tooltipDirection || "left"

    const tooltipCls = stacked ? "summary_tooltip_stacked" : "summary_tooltip"

    const container = d3.select("body").selectAll(`div.${tooltipCls}`)
      .data([0]);

    container.enter().append('div').attr('class', tooltipCls).style('opacity', 0);

    const bar = main.selectAll("g.bar")
      .data(x => x);

    bar.enter()
      .append("g")
      .attr("class","bar")
      .attr("transform", (x,i) => "translate("+ ((numItems-1-i)*(width)) + ",0)")
      .style("cursor",onClick ? "pointer" : undefined)
      .on("click",onClick)

    bar.exit().remove()

    const section = bar
      .selectAll("rect")
      .data(function(x) {
        let prev = 0
        const total = d3.sum(dataColumns.map(col => x[col]));
        const datum = dataColumns.map(col => {
          const p = prev
          const curr = vScale(x[col] || 0)
          prev += curr
          const y1 = Math.max(2,curr)
          const date = moment(x[dateKey], 'YYYY-MM-DD').format("MMMM DD, YYYY")
          const hour = moment(x[dateKey]).format("HH:mm UTC")

          let obj = {"y0":p,"y1":y1, "column":col, "count":x[col], date: date, hour}

          if (!x.total) obj['total'] = total;

          return obj
        })

        return datum
      },(x,i) => i)


    section.exit().remove()

    section.enter()
      .append("rect")

   const self = this;

   section
      .attr("width",width-space)
      .attr("y", d => height - d.y0 - d.y1 - paddingBottom)
      .attr("height", d => d.y1 )
      .attr("rx",0)
      .attr("ry",0)
      .attr("fill", (x,i) => columnColors[x.column])
      .on("mousemove", function(d) {
        container.transition()
          .duration(50)
          .style("opacity", .9);
        
        container.html(self.generateTooltip(d, variables))
          .style("left", (d3.event.pageX - (tipDirection == "right" ? 150 : 0)) + "px")
          .style("top", (d3.event.pageY - 28) + "px")
          .style("width", "200px")
          .style("border-width", 0);
        })
      .on("mouseout", function(d) {
        container.transition()
          .duration(50)
          .style("opacity", 0);
      });

  }

  generateTooltip(d, variables) {
    const { dateKey, columnColors, stacked } = variables;
    const { tooltipKey } = this.props;

    const key = tooltipKey || dateKey;
    return `
      <div>
        <div style="padding-bottom: 3px;">
          ${d[key]}
        </div>
        <div style="padding-bottom: 3px;">
          <span class="summary_dot" style="background-color:${columnColors[d.column]}"></span>
          <strong>${d.column}:</strong> ${numberFormatter(d3.round(d.count))}
        </div>
        ${ stacked ?
        `<div>
          <span class="summary_dot" style="background-color:#ddd"></span>
          <strong>Total:</strong> ${numberFormatter(d.total)}
        </div>` : ''}
      </div>
    `
  }

  renderBar() {

    const { data = {} } = this.props
    const { length } = data
    const shouldRender = (data && length)

    if (!shouldRender) return null

    const variables = this.setupVariables()
    const { main } = this.renderWrapper(variables)

    this.renderBottomAxis(main, variables)
    this.renderMainBar(main, variables)
  }

  render() {
    return <svg ref="bar"></svg>
  }

}

const AttributionBar = (props) => {
  return  <BarWrap><WidthWrapper component={StackedBar} {...props} /></BarWrap>
}

export default AttributionBar;

import React from 'react';
import { Card as SemanticCard, Statistic } from 'semantic-ui-react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

/* eslint-disable react/jsx-props-no-spreading */

const CardWrapper = styled(SemanticCard)`
  box-shadow: none !important;
  background: #FAFBFF !important;
  border-right: 1px solid #BFC7F9 !important;
  border-left: 1px solid #BFC7F9 !important;
  border-top: ${({ point }) => (point === 'up' ? 'none !important' : '1px solid #BFC7F9 !important')};
  border-bottom: ${({ point }) => (point === 'down' ? 'none !important' : '1px solid #BFC7F9 !important')};
  ${({ point }) => (point === 'up' ? `
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  ` : `
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  `)
}
  ${({ isActive }) => isActive && `
    border-color: #e6e6e6 !important;
    background: #ffffff !important;
  `
}
  ${({ isActive, point }) => (isActive && point === 'down') && `
    &:after {
      content: '' !important;
      display: block !important;
      visibility: visible !important;
      border-bottom: 2px solid #ffffff;
    }   
  `
}
  ${({ isActive, point }) => (!isActive && point === 'down') && `
    &:after {
      content: '' !important;
      display: block !important;
      visibility: visible !important;
      border-bottom: 2px solid #e6e6e6;
    }
    
    &:hover:after {
      margin-bottom: 1px;
      border-width: 1px;
      background: #fff;
      height: 2px !important;
      position: absolute;
      bottom: -1px;
      left: -1px;
      width: 101%;
      border-top: 1px solid #e6e6e6;
      border-bottom: none;
    }`
}
  ${({ isActive, point }) => (!isActive && point === 'up') && `
    &:before {
      content: '' !important;
      display: block !important;
      visibility: visible !important;
      border-top: 1px solid #e6e6e6 !important;
    }

    &:hover:before {
      margin-top: 1px;
      border-width: 1px;
      background: #fff;
      height: 2px !important;
      position: absolute;
      top: -2px;
      left: -1px;
      width: 101%;
      border-bottom: 1px solid #e6e6e6;
      border-top: none !important;
    }
  `}
  ${({ isActive }) => (!isActive) && `
    &.link {
      &:hover {
        background-color: #4D5ECA !important;
        * {
          color: #fff !important;
        }
        ${({ point }) => point === 'down' && `
          &:after {
            border-bottom: 1px solid #ffffff;
          }
        `}
        &:before {
          border-top: 1px solid #ffffff;
        }
      }
    }
  `}
}
`;

const Heading = styled.p`
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.05em;
  font-family: 'Poppins' !important;
  font-weight: 500;
`;

const StatValue = styled(Statistic.Value)`
  color: ${props => (props.activeColor ? props.activeColor : '#475ddc')} !important;
  .secondary-metric {
    color: #FFA278;
  }
`;

const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: rgba(191, 191, 191, 0.9);
  font-family: 'Poppins' !important;
`;

export const Group = ({ itemsPerRow, cardGroupStyle, children }) => (
  <SemanticCard.Group itemsPerRow={itemsPerRow || 6} style={cardGroupStyle}>
    {children}
  </SemanticCard.Group>
);

export const Loader = () => (
  <CardWrapper>
    <SemanticCard.Content style={{ textAlign: 'center', padding: '.5em' }}>
      <ContentLoader
        viewBox="0 0 180 77"
        preserveAspectRatio="none"
        foregroundColor="#e5e5e5"
        height={78}
        width="100%"
      >
        <circle cx="31.2" cy="67.78" r="4.53" />
        <rect x="44.05" y="63.25" width="113.96" height="9.05" rx="4" ry="4" />
        <rect x="73.5" y="4.53" width="33.53" height="10.7" rx="4" ry="4" />
        <rect x="55.23" y="29" width="69.53" height="18.84" rx="4" ry="4" />
      </ContentLoader>
    </SemanticCard.Content>
  </CardWrapper>
);

export const Placeholder = ({ option, activeColor }) => (
  <CardWrapper className="trend-card placeholder">
    <SemanticCard.Content style={{ textAlign: 'center', padding: '.5em' }}>
      <Heading style={{ margin: 0 }}>{option.text}</Heading>
      <svg viewBox="0 0 180 54" style={{ opacity: 0.1, paddingBottom: 4 }}>
        <circle cx="31.2" cy="49.38" r="4.53" fill="#999" />
        <rect x="44.05" y="44.86" width="113.96" height="9.05" rx="4" ry="4" fill="#999" />
        <rect x="55.23" y="10.61" width="69.53" height="18.84" rx="4" ry="4" fill={activeColor} />
      </svg>
    </SemanticCard.Content>
  </CardWrapper>
);

/* Simplest card that acts as a wrapper to provide overall style
  also contains defaults like active color and pointer direction */
export const DummyCard = props => {
  const { item, setActive, isActive, children, text, itemsPerRow } = props;
  const { activeColor = '#ffffff', point = 'up' } = props;

  return (
    <CardWrapper
      link
      {...{ isActive, activeColor, point, itemsPerRow }}
      className="trend-card"
      onClick={() => setActive(item.value)}
    >
      <SemanticCard.Content style={{ textAlign: 'center', padding: '.5em' }}>
        {children || text}
      </SemanticCard.Content>
    </CardWrapper>
  );
};

export const StatisticCard = props => {
  const { loading, heading, statistic, subheading, isActive, activeColor, trendNumber, hideTrend } = props;
  if (!!loading) return <Loader />;

  return (
    <DummyCard {...props}>
      {heading && <Heading {...{ isActive }}>{heading}</Heading>}
      {statistic && (
      <Statistic style={{ margin: '0 0 .5em 0' }}>
        <StatValue {...{ isActive, activeColor }}>
          <div style={{ fontSize: '1.8rem', fontFamily: 'Poppins' }}>
            {statistic}
          </div>
        </StatValue>
      </Statistic>
      )}
      { (!hideTrend && subheading && trendNumber !== Infinity) && (
      <SubHeader {...{ isActive }}>
        { subheading }
      </SubHeader>
      )}
    </DummyCard>
  );
};

export const DefaultCard = StatisticCard;

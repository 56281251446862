import React, { useState, useEffect, useMemo, memo } from 'react';
import { Label, Checkbox, Form, Button, Message } from 'semantic-ui-react';
import _ from 'lodash';
import { ColumnLayout, ContentCard, IndexGrid } from '@rockerbox/styleguide';
import { StyledSegment, StyledButton } from '../parts/styled';
import { TIERS } from '../../../constants/tiers';
import useOptions from '../hooks/useOptions';

const DEFAULT_CODE_OPTIONS = [];
const DEFAULT_OPTION = { values: [] };

const ValueField = memo(({ row, value, keyName, updateRowKey, tierOptions, macroOptions }) => {
  const key = keyName;
  const placeholder = keyName;
  const style = { width: 168 };
  const dropdownStyle = { ...style, backgroundColor: '#fcfff5 !important' };

  const defaultType = (value && value[0] === '{')
    ? 'code' : (tierOptions.find(tierRow => tierRow.value === value))
      ? 'tags' : (!value && tierOptions.length)
        ? 'tags' : 'text';

  const [inputType, setInputType] = useState(defaultType);

  useEffect(() => {
    setInputType(defaultType);
  }, [defaultType]);

  const onChange = updateRowKey(row, key);

  return (
    <>
      <Button.Group size="mini">
        <StyledButton active={inputType === 'tags'} as="a" icon="tags" onClick={() => setInputType('tags')} />
        <StyledButton active={inputType === 'text'} as="a" icon="quote left" onClick={() => setInputType('text')} />
        <StyledButton active={inputType === 'code'} as="a" icon="code" onClick={() => setInputType('code')} />
      </Button.Group>
      {inputType === 'tags' && <Form.Dropdown selection {...{ placeholder, style: dropdownStyle, value, onChange, options: tierOptions }} />}
      {inputType === 'text' && <Form.Input {...{ placeholder, style, value, onChange }} />}
      {inputType === 'code' && <Form.Dropdown selection {...{ placeholder, style: dropdownStyle, value, onChange, options: macroOptions }} />}
    </>
  );
});

const EditRow = memo(({ row, i, pos, updateRowKey, value, tierOptions, macroOptions = DEFAULT_CODE_OPTIONS }) => (
  <Form.Group key={i}>
    <Label style={{ lineHeight: 1.8 }}>{ `Tier ${pos}` }</Label>
    <ValueField keyName={`tier_${pos}`} {...{ row, updateRowKey, value, tierOptions, macroOptions, pos }} />
  </Form.Group>
));

const TierCreation = memo(({ tiersOptions, row, onUpdate, WrapperComponent = false, macroOptions = DEFAULT_CODE_OPTIONS, tier1Empty }) => {
  const tiersVisible = useMemo(() => {
    const filledTier = TIERS.reduce((p, c) => ((row[c] || '').length ? c : p), '');
    const index = TIERS.indexOf(filledTier) + 1;
    return (index < TIERS.length) ? TIERS[index] : 'tier_5';
  }, [JSON.stringify(row)]);

  const selectedTiers = useMemo(() => {
    const index = TIERS.indexOf(tiersVisible);
    return TIERS.slice(0, index + 1);
  }, [tiersVisible]);

  const Wrapper = WrapperComponent || StyledSegment;

  const optionsByTier = {
    tier_1: tiersOptions,
  };

  const updateRowKey = (updateRow, tier) => (evt, { value }) => {
    const index = TIERS.indexOf(tier);
    const clearUpdates = TIERS.slice(index, TIERS.length)
      .reduce((accu, c) => {
        accu[c] = '';
        return accu;
      }, {});

    onUpdate({ ...updateRow, ...clearUpdates, [tier]: value });
  };

  return (
    <Wrapper secondary key={row.id || row.new_id || 1}>
      {
      selectedTiers.map((tier, i) => {
        const pos = i + 1;
        const nextTier = selectedTiers[i + 1];
        const value = row[tier];
        const options = optionsByTier[tier] || [];
        if (value) {
          const selectedOption = options.find(selectedRow => selectedRow.value === value) || DEFAULT_OPTION;
          const nextOptions = selectedOption.values || [];
          optionsByTier[nextTier] = nextOptions.filter(option => option.value.trim().length > 0);
        }
        return <EditRow key={pos} {...{ row, i, pos, updateRowKey, value, tierOptions: options, macroOptions }} />;
      })
    }
      { tier1Empty && <Message negative compact><p>Please add a tier_1 value before submitting</p></Message>}
    </Wrapper>
  );
});

const ExampleEvents = ({ showExampleOnly, promo_codes, displayRows, name }) => {
  const firstDisplay = displayRows[0];
  const macroFields = TIERS
    .filter(tier => ['{conversion_field_value}', '{name}']
      .includes(firstDisplay[tier]));
  const hasMacros = macroFields.length > 0;

  const exampleDisplay = useMemo(() => {
    const row = { ...firstDisplay };
    macroFields.forEach(field => {
      if (row[field] === '{name}') row[field] = '{ Sponsorship Name }';
      if (row[field] === '{conversion_field_value}') row[field] = '{ Promo Code }';
    });
    return row;
  }, [firstDisplay]);

  const items = (!hasMacros || promo_codes.length === 0) ? [exampleDisplay]
    : promo_codes
      .map(code => {
        const row = { ...firstDisplay };
        macroFields.forEach(field => {
          const macroName = row[field].slice(1, -1);
          row[field] = macroName === 'name' ? name : code[macroName];
        });
        return row;
      });

  if (!showExampleOnly) {
    const columns = IndexGrid.arrayToColumns(TIERS);
    return <IndexGrid cols={columns} data={items} />;
  }

  const columns = IndexGrid.arrayToColumns(['tier', ...items.map((item, i) => `Example ${i + 1}`)]);

  const tierData = TIERS.map(tier => {
    const row = { tier };
    items.forEach((item, i) => {
      row[`Example ${i + 1}`] = item[tier];
    });
    return row;
  });

  return <IndexGrid cols={columns} data={tierData} />;
};

const SetupDisplay = ({ state, setState, WrapperComponent = false, skipLayout = false, showExampleOnly = false, tier1Empty }) => {
  const { name, display, promo_field } = state;
  const { promo_codes, segments } = state;
  const { segment_id } = segments[0] || {};
  const macroOptions = [
    { text: 'Promo Code', value: '{conversion_field_value}' },
    { text: 'Sponsorship Name', value: '{name}' },
  ];

  const [showExamples, setShowExamples] = useState(false);
  const { tiersOptions } = useOptions(segment_id);

  const displayRows = display.filter(row => row).length > 0 ? display : [{}];

  const onUpdate = useMemo(() => row => {
    const index = display.map(evt => evt.id || evt.new_id).indexOf(row.id || row.new_id);
    const newDisplay = _.cloneDeep(display);
    newDisplay.splice(index, 1, row);
    setState({ display: newDisplay });
  }, []);

  const Content = (
    <>
      {!showExampleOnly && (
        <>
          {displayRows.map(row => (
            <TierCreation
              key={row?.tier_1}
              {...{ WrapperComponent, state, row, tiersOptions, onUpdate, macroOptions, tier1Empty }}
            />
          ))}
          <Checkbox
            label="Show Example Events"
            onClick={() => setShowExamples(!showExamples)}
            checked={showExamples}
          />
          <br />
          <br />
        </>
      )}
      {(showExampleOnly || showExamples) && (
        <>
          <ExampleEvents {...{ showExampleOnly, displayRows, segment_id, promo_codes, promo_field, name }} />
          {!showExampleOnly && (
            <>
              <br />
              <br />
            </>
          )}
        </>
      )}
    </>
  );

  if (skipLayout) return Content;

  return (
    <ColumnLayout leftWidth={4} centerWidth={8} rightWidth={4}>
      <ContentCard>
        <Message
          header={`How do you want to display ${name} as a marketing channel?`}
          content={`Now that we have the marketing and spend associated with ${name}, we can define how we want to organize this data`}
        />
        { Content }
      </ContentCard>
    </ColumnLayout>
  );
};

export const SetupDisplaySelector = ({ showExampleOnly, segment_id, onChange, value }) => {
  const [display, setDisplay] = useState(value);
  const { segments: allSegments } = useOptions();

  const segments = segment_id ? [{ segment_id }] : allSegments
    .filter(row => row.featured)
    .map(row => ({ segment_id: row.action_id }));

  const setState = state => setDisplay(state.display[0]);

  useEffect(() => {
    setDisplay(value);
  }, [value]);

  useEffect(() => {
    onChange(false, { value: display });
  }, [display]);

  return (
    <SetupDisplay
      state={{ segments, promo_codes: [], display: [display] }}
      skipLayout
      {...{ setState, showExampleOnly }}
      WrapperComponent={React.Fragment}
    />
  );
};

export default SetupDisplay;

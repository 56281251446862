import React, { Component } from 'react';
import _ from 'lodash';
import * as d3 from 'rockerbox_d3_legacy_clone';
import moment from 'moment';
import { Button, Form, Table, Checkbox, Input } from 'semantic-ui-react';

import { formatBitmap, formatRollup } from './helpers';

const CohortRow = ({ formatter, row, cohortColumns, cohortSize, detailsAs, detailsProps }) => {

  const [showDetails, setShowDetails] = React.useState(false)
  const DetailsComponent = detailsAs

  return <>
    <Table.Row>
      <Table.Cell style={{width:"30px"}}><Button icon={showDetails ? "minus" : "plus"} size="mini" onClick={() => setShowDetails(!showDetails) }/></Table.Cell>
      <Table.Cell width={1}>{ row.key }</Table.Cell>
      { cohortColumns.find(col => col == "initial") && <Table.Cell width={1}>{ formatter(row.initial) }</Table.Cell> }
      { cohortColumns.find(col => col == "current") && <Table.Cell width={1}>{ formatter(row.current) }</Table.Cell> }
      { cohortColumns.find(col => col == "retention") && <Table.Cell width={1} style={{borderRight:"1px solid #ddd"}}>{ row.retention }</Table.Cell> }
      { cohortColumns.find(col => col == "retentionCycle") && <Table.Cell width={1}>{ formatter(row.retentionYr1) }</Table.Cell> }
      { cohortColumns.find(col => col == "retentionCycle") && <Table.Cell width={1}>{ formatter(row.retentionYr2) }</Table.Cell> }
      { d3.range(0,row.counts.length,cohortSize).map(i=> <Table.Cell colspan={cohortSize}>{ formatter(row.counts[i]) }</Table.Cell>) }
    </Table.Row>
    { showDetails && detailsAs&& <Table.Row>
        <Table.Cell colspan={100} >
          <DetailsComponent data={row.rawValues} {...detailsProps} />
        </Table.Cell>
      </Table.Row>
    }
  </>
}

const SummaryRow = ({ formatter, counts, cohortSize, row, summary, cohortColumns, detailsAs, detailsProps, detailsData }) => {
  const [showDetails, setShowDetails] = React.useState(false)
  const DetailsComponent = detailsAs

  return <>
    <Table.Row style={{fontWeight:"", background:"#F9FAFB"}}>
      <Table.Cell style={{width:"30px"}}><Button icon={showDetails ? "minus" : "plus"} size="mini" onClick={() => setShowDetails(!showDetails) }/></Table.Cell>
      <Table.Cell width={1}> { summary.firstKey } - { row.key } </Table.Cell>
      { cohortColumns.find(col => col == "initial") && <Table.Cell width={1}>{ formatter(summary.initial) }</Table.Cell> }
      { cohortColumns.find(col => col == "current") && <Table.Cell width={1}>{ formatter(summary.current) }</Table.Cell> }
      { cohortColumns.find(col => col == "retention") && <Table.Cell width={1} style={{borderRight:"1px solid #ddd"}}>{ summary.retention }</Table.Cell> }
      { cohortColumns.find(col => col == "retentionCycle") && <Table.Cell width={1}> {counts[cohortSize] ? Math.round(counts[cohortSize]/counts[0]*100) + "%" : "" }</Table.Cell> }
      { cohortColumns.find(col => col == "retentionCycle") && <Table.Cell width={1}> {counts[cohortSize*2] ? Math.round(counts[cohortSize*2]/counts[0]*100) + "%" : "" }</Table.Cell> }
      { d3.range(0, Object.keys(counts).length, cohortSize)
          .map(count => <Table.Cell colspan={cohortSize}> 
              { formatter(counts[count]) } 
              <br /> 
              { count > 0 ? `(${Math.round(counts[count]/summary.initial*100)}%)` : "-" } 
            </Table.Cell>
          )
      }

    </Table.Row>
    { showDetails && detailsAs&& <Table.Row>
        <Table.Cell colspan={100} >
          <DetailsComponent data={detailsData} {...detailsProps} />
        </Table.Cell>
      </Table.Row>
    }
  </>
}

const currency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}).format;


const number = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  notation: 'standard'
}).format;

const CohortTable = (props) => {

  const { format, extraFields, detailsAs, detailsProps } = props

  const formatter = React.useMemo(() => format == "$" ? currency : number, [format])

  const [showCohortSummaryRow, setShowCohortSummaryRow] = React.useState(true);
  const [showDates, setShowDates] = React.useState(false);
  const [showRevenue, setShowRevenue] = React.useState(false);
  const [startMonth, setStartMonth] = React.useState(false);
  const [cohortColumns, setCohortColumns] = React.useState(["initial","current","retention"]);
  const [cohortSize, setCohortSize] = React.useState(12);

  const { 
    data, 
    cohortValueKey = "signed_monthly_revenue", 
    startKey = "first_contract_date", 
    endKey = "contract_end_date" 
  } = props;
  if (data.length == 0) return null; // LOAD DATA


  const first = data[0];
  if (!first[startKey] || !first[endKey]) return null // DATA FORMATTED INCORRECTLY

  const nowMonth = moment.utc().format("-MM")
  const formatted = data.map(formatBitmap(cohortValueKey, startKey, endKey))

  const activeCohorts = d3.nest()
    .key(row => row.startMonth)
    .rollup(formatRollup(cohortSize))
    .entries(formatted)
    .sort((p,c) => d3.ascending(p.key, c.key))
    .map(row => Object.assign(row, row.values))


  const activeCohortsObj = activeCohorts.reduce((p,c) => {
    p[c.key] = c
    return p
  }, {})
  
  const numCohorts = props.numCohorts ? props.numCohorts : activeCohorts[0].counts.length

  const monthRange = d3.time.month.range(moment(activeCohorts[0].key+"-01"), moment(), 1)
    .map(date => moment(date).format("YYYY-MM"))

  const JANUARY = moment(`2000-${startMonth}-01`)
  const CURRENT = moment().format("YYYY-MM-DD")
  const FIRST = moment(activeCohorts[0].key+"-01")

  const cohortStartMonth = startMonth ? JANUARY : CURRENT

  const offsetFromJanuary = (moment("2000-01-01").diff(cohortStartMonth, "months") % 12)
  const offsetFromFirstCohort = (moment("2000-01-01").diff(FIRST, "months") % 12)

  const offsetMonths = (offsetFromJanuary - offsetFromFirstCohort + 1) % 12

  const isOffset = offsetMonths && cohortStartMonth != moment(activeCohorts[0].key+"-01")

  const periodOptions = [
    {"text":"Yearly", "value":12},
    {"text":"Quarterly", "value":3},
    {"text":"Monthly", "value":1}
  ]

  const monthOptions = [
    {"text": "Start of Year", "value":"01"},
    {"text": "Current Month", "value":false}
  ]

  const cohortName = cohortSize == 12 ? "Year" :
    cohortSize == 3 ? "Quarter" : 
    cohortSize == 1 ? "M" : "Period"

  return <>
    <div>
      <Form>
        <Form.Group>
        <Form.Field>
          <label>Analysis Period</label>
          <Form.Dropdown selection value={cohortSize} options={periodOptions} onChange={(_, { value }) => setCohortSize(value) }/>
        </Form.Field>
        <Form.Field>
          <label>Analysis Start</label>
          <Form.Dropdown selection value={startMonth} options={monthOptions} onChange={(_, { value }) => setStartMonth(value) }/>
        </Form.Field>
        </Form.Group>
        { extraFields }
      </Form>
      <br />
      <Checkbox label="Show Cycle Summary" checked={showCohortSummaryRow} onClick={() => setShowCohortSummaryRow(!showCohortSummaryRow)} />
      <br />
      <Checkbox label="Show Months" checked={showDates} onClick={() => setShowDates(!showDates)} />
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{width:"30px"}} />
            <Table.HeaderCell width={1}>Start Date</Table.HeaderCell>
            { cohortColumns.find(col => col == "initial") && <Table.HeaderCell width={1}>Signed</Table.HeaderCell> }
            { cohortColumns.find(col => col == "current") && <Table.HeaderCell width={1}>Active</Table.HeaderCell> }
            { cohortColumns.find(col => col == "retention") && <Table.HeaderCell width={1} style={{borderRight:"1px solid #ddd"}}>Retention</Table.HeaderCell> }
            { cohortColumns.find(col => col == "retentionCycle") && <Table.HeaderCell width={1}>YR 1</Table.HeaderCell> }
            { cohortColumns.find(col => col == "retentionCycle") && <Table.HeaderCell width={1}>YR 2</Table.HeaderCell> }
            { !!numCohorts && d3.range(0, numCohorts, cohortSize).map((i,j) => <Table.HeaderCell colspan={cohortSize}>{ j == 0 ? "Start" : `${cohortName} ${j+1}`}</Table.HeaderCell>) }
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { 
            monthRange.map((date,i) => {
              const row = activeCohortsObj[date] 
              if ( !!cohortSize && cohortSize > 1 && ((i + offsetMonths) % cohortSize) == 0) {

                const steps = cohortSize - 1;
                const minValue = (i-steps) > 0 ? (i-steps) : 0;
                const range = monthRange.slice(minValue, i+1)
                  .map(d => activeCohortsObj[d])
                  .filter(row => !!row)

                if (range.length == 0) return null;

                const counts = range.reduce((p,c) => {
                  c.counts.map((v,i) => { p[i] = (p[i] || 0) + v })
                  return p
                }, {})
                const initial = range.reduce((p,c) => p + c.initial, 0);
                const current = range.reduce((p,c) => p + c.current, 0);
                const firstKey = range[0].key || date;
                const summary = { firstKey, initial, current, retention: `${Math.round(current/initial*100)}%` }

                const detailsData = range.reduce((p,c) => {
                  return [...p, ...c.rawValues]
                }, [])

                return <>
                  { !row && showDates && <Table.Row><Table.Cell width={1}>{ date }</Table.Cell> </Table.Row> }
                  { row && showDates && <CohortRow {...{row, cohortColumns, cohortSize, detailsAs, detailsProps, formatter}} /> }
                  { showCohortSummaryRow && <SummaryRow {...{detailsData, detailsAs, detailsProps, summary, counts, cohortSize, cohortColumns, formatter}} row={row || {key: date}} /> }
                </>
              }

              if (row && (showDates || cohortSize == 1)) return <CohortRow {...{row, cohortColumns, cohortSize, detailsAs, detailsProps, formatter}} />
              if (showDates) return <Table.Row>
                <Table.Cell />
                <Table.Cell width={1}>{ date }</Table.Cell>
              </Table.Row>
              return null
            })
          }
          { 
            isOffset && (() => {
              const row = false
              const date = monthRange.slice(-1)[0]

              const range = monthRange.slice(parseInt((monthRange.length + offsetMonths) / cohortSize)*cohortSize - offsetMonths + 1)
                .map(d => activeCohortsObj[d])
                .filter(row => !!row)

              if (range.length == 0) return null;

              const counts = range.reduce((p,c) => {
                c.counts.map((v,i) => { p[i] = (p[i] || 0) + v })
                return p
              }, {})
              const initial = range.reduce((p,c) => p + c.initial, 0);
              const current = range.reduce((p,c) => p + c.current, 0);
              const firstKey = range[0].key || date;
              const summary = { firstKey, initial, current, retention: `${Math.round(current/initial*100)}%` }

              const detailsData = range.reduce((p,c) => {
                return [...p, ...c.rawValues]
              }, [])

              return <>
                { !row && showDates && <Table.Row><Table.Cell width={1}>{ date }</Table.Cell> </Table.Row> }
                { row && showDates && <CohortRow {...{row, cohortColumns, cohortSize, detailsAs, detailsProps, formatter}} /> }
                { showCohortSummaryRow && <SummaryRow {...{detailsData, detailsAs, detailsProps, summary, counts, cohortSize, cohortColumns, formatter}} row={row || {key: date}} /> }
              </>
            })()
          }
        </Table.Body>
      </Table>
    </div>
  </>
}

export default CohortTable;

import React from 'react'
import { ContentCard } from '@rockerbox/styleguide'
import CacheContext from '../../utils/CacheContext'
import HelpDoc from '../HelpDocs'
import { IntegrationDisplayName } from './parts'
import { getAccount, getPixelCode, getWebhooksData } from '../../utils/api'


const HelpSetup = ({ platform, help_doc }) => {
  const [loading, setLoading] = React.useState(true)
  const [context, setContext] = React.useState({})

  const [state, setState, Context] = React.useContext(CacheContext)
  const account = Context.getCache(`account`, getAccount) || false

  React.useEffect(() => {
    buildContext(platform)
  }, [platform])

  const updateContext = (data) => {
    const newContext = {...context, ...data}
    setContext(newContext)
    setLoading(false)
  }

  // TODO: move this out of this component to make make generic
  const buildContext = (platform) => {
    switch (platform){
      case "segment":
        Promise.all([getPixelCode(), getWebhooksData()])
          .then(([pixelData, webhooksData]) => {
            const pixel_hash = pixelData['pixels'][0]['pixel_hash']
            const webhook = webhooksData.find(({ action }) => !!action && action == platform)
            const { action, pixel_source_name, webhook_secret } = webhook
            const webhookUrl = `https://webhooks.getrockerbox.com/${action}?advertiser=${pixel_source_name}`
            updateContext({
              'Pixel code is available from your Rockerbox account manager.': `Pixel code is: \`${pixel_hash}\``,
              'Webhook URL is available from your Rockerbox account manager.': `Webhook URL is: \`${webhookUrl}\``,
              'Shared Secret is available from your Rockerbox account manager.': `Shared Secret is: \`${webhook_secret}\``,
            })
          })
        break
      case "proveit":
      case "appsflyer":
        if (!!account) {
          const { pixel_source_name } = account
          updateContext({
            'Your Account ID is available under Settings > Account > General in your Rockerbox account.': `Your Account ID is: \`${pixel_source_name}\``,
          })
        }
        break
    }
  }

  const docs = React.useMemo(() =>
    !!help_doc && help_doc.replace(" ", "").split(",")
  , [help_doc])

  const Title = ({ idx }) => (<>
    <IntegrationDisplayName {...{platform}} />
    &nbsp;Setup Instructions
    {docs.length > 1 && `: Part ${idx+1} of ${docs.length}`}
  </>)

  return (
    <>
      {!!docs && !!docs.length && docs.map((slug, idx) =>
        <ContentCard title={<Title {...{idx}} />} collapsing>
          <HelpDoc
            slug={slug}
            context={context}
          />
        </ContentCard>
      )}
    </>
  )
}

export default HelpSetup

export const ntfDict = {
  all: '',
  repeat: 'repeat_',
  new: 'ntf_',
};

export const recommendedOptions = [
  { text: 'Remove 0 Spend', value: 'removeSpend', key: 'removeSpend' },
  { text: 'Remove Direct', value: 'removeDirect', key: 'removeDirect' },
  { text: 'Default', value: 'default', key: 'default' },
];

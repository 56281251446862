// currency formatter
export const spendFormatter = (currencyCode, maxDigits) => {
  const maximumFractionDigits = maxDigits || maxDigits === 0 ? maxDigits : 2;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    maximumFractionDigits,
    currency: currencyCode || 'USD',
  });
};

// currencyOverride & maxDigits params are optional
export const formatCurrency = (number, currencyOverride, maxDigits) => {
  const currencyFormat = spendFormatter(currencyOverride, maxDigits);

  const formatted = !Number.isFinite(number) ? '-' : typeof number !== 'number' ? currencyFormat.format(0) : currencyFormat.format(number);

  return formatted;
};

export const currencyFormatter = (currencyCode, maxDigits) => number => {
  const currencyFormat = spendFormatter(currencyCode, maxDigits);

  const formatted = !Number.isFinite(number) ? '-' : typeof number !== 'number' ? currencyFormat.format(0) : currencyFormat.format(number);

  return formatted;
};

// decimal formatter
export const numberFormatter = (number, maximumFractionDigits = 0) => {
  const nanCheck = Number.isFinite(number);
  const num = (!nanCheck) ? '-' : typeof number === 'number' ? number : 0;
  const formattedNumber = num.toLocaleString('en-US', {
    maximumFractionDigits,
  });
  return formattedNumber;
};

export const numberToTwoDecimals = number => {
  const nanCheck = Number.isFinite(number);
  const num = (!nanCheck) ? '-' : typeof number === 'number' ? number : 0;
  const formattedNumber = num.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formattedNumber;
};

// percent formatter
export const percentFormatter = number => {
  if (typeof number !== 'number') {
    return;
  }

  const formattedPercent = `${(number * 100).toFixed(2)}%`;
  return formattedPercent;
};

// time display formatter
export const formatTimeDisplay = (number, timeText) => {
  if (number > 0) {
    const isPlural = number === 1 ? '' : 's';
    return `${number} ${timeText}${isPlural}`;
  }
  return '';
};

export const displayTime = arrayDayHoursMinutes => {
  const display = arrayDayHoursMinutes
    .filter(time => time.length > 0)
    .slice(0, 2);
  return display.join(' ');
};

export const secondsToDhms = seconds => {
  const isNegative = Number(seconds) < 0 ? '-' : '';
  const absSeconds = Math.abs(Number(seconds));
  const days = Math.floor(absSeconds / (3600 * 24));
  const hours = Math.floor((absSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((absSeconds % 3600) / 60);

  const dDisplay = formatTimeDisplay(days, 'day');
  const hDisplay = formatTimeDisplay(hours, 'hour');
  const mDisplay = formatTimeDisplay(minutes, 'minute');

  return isNegative + displayTime([dDisplay, hDisplay, mDisplay]);
};

export const isJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return JSON.parse(str);
};

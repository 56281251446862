// see hooks.js usePlatformMappings to get all platforms
export const PLATFORM_MAPPING = {
  adwords: {
    matches: ['google', 'gdn', 'dcm', 'pmax', 'performance max', 'performance-max'],
    text: 'AdWords',
    exclude: ['organic'],
  },
  apple: {
    matches: ['apple'],
    text: 'Apple',
    exclude: ['organic'],
  },
  impactradius: {
    matches: ['impact radius', 'impact'],
    text: 'ImpactRadius',
    exclude: ['organic'],
  },
  facebook: {
    matches: ['instagram', 'fb\/ig', 'fb-ig', 'igstories', 'fb'], // eslint-disable-line no-useless-escape,
    text: 'Facebook',
    exclude: ['organic'],
  },
  lineartv: {
    matches: ['linear', 'linear tv'],
    text: 'Linear TV',
    exclude: ['organic'],
  },
  the_trade_desk: {
    matches: ['ttd', 'trade desk', 'ttt', 'the trade desk', 'tradedesk'],
    text: 'The Trade Desk',
    exclude: ['organic'],
  },
  youtube: {
    matches: ['youtube'],
    text: 'YouTube',
    exclude: ['organic'],
  },
  verizon: {
    matches: ['verizon'],
    text: 'Verizon',
    exclude: ['organic'],
  },
  other: {
    matches: [],
    text: 'Other',
  },
};

export const STRATEGY_MAPPING = {
  awareness: {
    matches: ['awareness', 'tof', 'tofu'],
    text: 'Awareness',
  },
  brand: {
    matches: ['_brand_', '_branded_', 'brand'],
    text: 'Brand',
    exclude: ['non brand', 'nonbrand', 'nb', 'non-brand'],
  },
  nonbrand: {
    matches: ['non brand', 'nonbrand', 'nb', 'non-brand'],
    text: 'Non Brand',
  },
  organic: {
    matches: ['organic'],
    text: 'Organic',
  },
  prospecting: {
    matches: ['prospect', 'acq', '_pro_', '_pros', '_prosp_', 'mof', 'mofu'],
    text: 'Prospecting',
  },
  retargeting: {
    matches: ['retarget', 'remarket', 'rtg', 'bof', 'bofu', 'rmkt', 'rmk', 'retarg'],
    text: 'Retargeting',
  },
  retention: {
    matches: ['retention', '_rtn_'],
    text: 'Retention',
  },
  other: {
    matches: [],
    text: 'Other',
  },
};

export const CHANNEL_MAPPING = {
  affiliate: {
    matches: ['affiliate', 'coupon', 'promo.code', 'impactradius', 'impact radius', 'pepperjam', 'promo code'],
    text: 'Affiliate',
  },
  amazon: {
    matches: ['amazon'],
    text: 'Amazon',
  },
  audio: {
    matches: ['radio', 'audio'],
    text: 'Audio',
  },
  display: {
    matches: ['display', 'programmatic'],
    text: 'Display',
  },
  email: {
    matches: ['email'],
    text: 'Email',
  },
  influencer: {
    matches: ['influencer'],
    text: 'Influencer',
  },
  organic: {
    matches: ['organic'],
    text: 'Organic',
  },
  paidsocial: {
    matches: ['paid social'],
    text: 'Paid Social',
  },
  printdirectmail: {
    matches: ['mail', 'print', 'direct mail', 'postie'],
    text: 'Print / Direct Mail',
  },
  podcast: {
    matches: ['podcast'],
    text: 'Podcast',
  },
  referral: {
    matches: ['referral'],
    text: 'Referral',
  },
  search: {
    matches: ['search', 'pmax'],
    text: 'Search',
  },
  shopping: {
    matches: ['shopping'],
    text: 'Shopping',
  },
  sms: {
    matches: ['sms'],
    text: 'SMS',
  },
  tv: {
    matches: ['tv', 'linear'],
    text: 'TV',
  },
  video: {
    matches: ['video', 'ctv', 'youtube', 'ott'],
    text: 'Video',
  },
  other: {
    matches: [],
    text: 'Other',
  },
};

import * as d3 from 'rockerbox_d3_legacy_clone';
import moment from 'moment';
import { colorNames } from '../parts/ColorPicker';

export const calcWeekly = (currentDate, data, weeklyNames) => {

  const evalDate = (date) => {

    const thisDate = moment(date).toDate()

    if (thisDate > moment(currentDate).subtract(7, 'days').toDate()) {
      return weeklyNames[0]
    }
    if (thisDate > moment(currentDate).subtract(14, 'days').toDate()) {
      return weeklyNames[1]
    }
    if (thisDate > moment(currentDate).subtract(21, 'days').toDate()) {
      return weeklyNames[2]
    }
    if (thisDate > moment(currentDate).subtract(28, 'days').toDate()) {
      return weeklyNames[3]
    }
  }

  const allKeys = {}

  const weeklyData = d3.nest()
      .key(x => evalDate(x.date))
      .rollup(values => {
        return values.reduce((p,c) => {
          Object.keys(c).map(k => {
            if (k != "date") {
              p[k] = (p[k] || 0) + c[k];
              allKeys[k] = 0
            }
          })
          return p
        },{})
      })
      .map(data)

  return weeklyData

}

const greys = [
  "#9D9C9C","#7D7E7D","#6B6A6A","#555555","#404040","#333333","#292929","#1F1F1F",
]

export const solid_colors = Object.keys(colorNames).map(x => colorNames[x] )
export const colors = solid_colors
const x = []
.concat(
  solid_colors.slice(0,-1).map((c,i) => `repeating-linear-gradient(45deg, ${c}, ${c} 2px, ${solid_colors[i+1]} 2px, ${solid_colors[i+1]} 4px)`)
)
.concat(
  solid_colors.map((c,i) => `repeating-linear-gradient(45deg, ${c}, ${c} 2px, white 2px, white 4px)`)
)
.concat(
  solid_colors.map((c,i) => `repeating-linear-gradient(45deg, ${c}, ${c} 2px, black 4px, black 4px)`)
)
.concat(
  solid_colors.slice(0,-3).map((c,i) => `repeating-linear-gradient(45deg, ${c}, ${c} 2px, ${solid_colors[i+3]} 2px, ${solid_colors[i+3]} 4px)`)
)
.concat(
  solid_colors.map((c,i) => `repeating-linear-gradient(45deg, ${c}, ${c} 2px, ${greys[i]} 2px, ${greys[i]} 4px)`)
)
.concat(greys)
//.concat(
//  solid_colors.slice(0,-1).map((c,i) => `repeating-linear-gradient(0deg, ${c}, ${c} 2px, ${solid_colors[i+1]} 2px, ${solid_colors[i+1]} 4px)`)
//)
//.concat(
//  solid_colors.slice(0,-1).map((c,i) => `repeating-linear-gradient(135deg, ${c}, ${c} 2px, ${solid_colors[i+1]} 2px, ${solid_colors[i+1]} 4px)`)
//)
import React, { useEffect } from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';
import { Header, Menu } from 'semantic-ui-react';

import { SETTINGS_MENU } from './constants';
import { track } from '../../../utils/tracking';
import * as routes from '../../baseRoutes';
import { CONVERSION_FUNNEL_ADVERTISERS } from '../../components/Navbar/hooks';
import { useGlobalState } from '../../hooks/global';

const Settings = ({ history, location }) => {
  const { account } = useGlobalState();
  const { tier, pixel_source_name } = account || {};

  const { pathname } = location;
  const navigate = route => history.push(route);

  // only display the billing menu for starter clients
  const filteredSettingsMenu = tier === 'starter'
    ? SETTINGS_MENU.filter(item => item.name !== 'Conversion Funnel')
    : CONVERSION_FUNNEL_ADVERTISERS.includes(pixel_source_name)
      ? SETTINGS_MENU.filter(item => item.name !== 'Billing')
      : SETTINGS_MENU.filter(item => item.name !== 'Conversion Funnel' && item.name !== 'Billing');

  useEffect(() => {
    if (pathname === '/v3/settings') return;
    const view = pathname.replace('/v3/settings/', '').replaceAll('/', '.');
    track(`settings.${view}.view`);
  }, [pathname]);

  return (
    <div className="layout-sidebar">
      <div className="sidebar">
        <Header
          as="h2"
          content="Settings"
        />
        <Menu secondary vertical>
          {filteredSettingsMenu.map(({ name, route, icon }) => (
            <Menu.Item
              active={pathname === route}
              onClick={() => navigate(route)}
            >
              <div className="icon">
                {icon}
              </div>
              {name}
            </Menu.Item>
          ))}
        </Menu>
      </div>
      <div className="content">
        <Switch>
          {filteredSettingsMenu.map(({ route, component }) => <Route exact path={route} component={component} key={route} />)}
          <Route>
            <Redirect to={routes.myAccount} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Settings;

import React from 'react';

import { // eslint-disable-line import/no-extraneous-dependencies
  CashIcon, TagIcon, ClipboardListIcon,
  ShoppingCartIcon, CursorClickIcon, // eslint-disable-line no-unused-vars
  DocumentDownloadIcon, TableIcon, DatabaseIcon,
  ChartSquareBarIcon, PresentationChartLineIcon, PresentationChartBarIcon,
  MapIcon, FilterIcon, CalculatorIcon, ChartPieIcon, TrendingUpIcon,
  BookOpenIcon, ChatAlt2Icon, AnnotationIcon, AcademicCapIcon,
  // ClockIcon,
} from '@heroicons/react/outline'; // eslint-disable-line import/no-extraneous-dependencies
import { rockerboxPerformanceIcon } from '@rockerbox/styleguide';

import * as routes from '../../baseRoutes';
import {
  RBAC_UI_VIEWS_DATA_INTEGRATIONS,
  RBAC_UI_VIEWS_DATA_PROMO_CODES,
  RBAC_UI_VIEWS_DATA_SURVEYS,
  RBAC_UI_VIEWS_DATA_ORDERS_AND_CONVERSIONS,
  RBAC_UI_VIEWS_DATA_AD_HOC_EXPORTS,
  RBAC_UI_VIEWS_DATA_GOOGLE_SHEETS_PLUGIN,
  RBAC_UI_VIEWS_DATA_DATA_WAREHOUSE,
  RBAC_UI_VIEWS_ATTRIBUTION_CROSS_CHANNEL_REPORT,
  RBAC_UI_VIEWS_ATTRIBUTION_PLATFORM_PERFORMANCE,
  RBAC_UI_VIEWS_ATTRIBUTION_RB_PERFORMANCE,
  RBAC_UI_VIEWS_JOURNEYS_MARKETING_PATHS,
  RBAC_UI_VIEWS_JOURNEYS_FUNNEL_POSITION,
  RBAC_UI_VIEWS_JOURNEYS_CHANNEL_OVERLAP,
  RBAC_UI_VIEWS_MMM_MARKETING_MIX_MODELING,
  RBAC_UI_VIEWS_BENCHMARKS_SPEND_TRENDS,
  RBAC_UI_VIEWS_BENCHMARKS_PERFORMANCE_BENCHMARKS,
} from '../../constants/rbac';

export const LOGOUT = '/user/logout';
export const LOGIN = '/login';

export const HOME = routes.home;
export const SETTINGS = routes.settingsIndex;

export const NAV_MENU = [
  {
    name: 'Saved Reports',
    custom: true,
    children: [
      {
        name: 'Saved Reports',
        route: routes.savedReports,
      },
    ],
  },
  {
    name: 'Data',
    multiSection: true,
    children: [
      {
        name: 'Collect Marketing Data',
        children: [
          {
            name: 'Integrations',
            description: 'Centralize your data by connecting your advertising and ecommerce platforms',
            icon: <CashIcon />,
            route: routes.integrationsPlatforms,
            rbacPermission: RBAC_UI_VIEWS_DATA_INTEGRATIONS,
          },
          {
            name: 'Promo Codes',
            description: 'View promo code usage and associate them with marketing efforts',
            icon: <TagIcon />,
            route: routes.promoCodes,
            rbacPermission: RBAC_UI_VIEWS_DATA_PROMO_CODES,
          },
          {
            name: 'Surveys',
            description: 'Use survey data about how customers heard about your brand',
            icon: <ClipboardListIcon />,
            route: routes.surveys,
            rbacPermission: RBAC_UI_VIEWS_DATA_SURVEYS,
          },
          // {
          //   name: 'History',
          //   description: 'View Historical Sync Status',
          //   icon: <ClockIcon />,
          //   route: routes.syncHistory,
          // },
        ],
      },
      {
        name: 'Track Customer Data',
        children: [
          {
            name: 'Orders & Conversions',
            description: 'Track the important customer actions that your marketing is driving towards',
            icon: <ShoppingCartIcon />,
            route: routes.conversionsOverview,
            rbacPermission: RBAC_UI_VIEWS_DATA_ORDERS_AND_CONVERSIONS,
          },
          // {
          //   name: 'Pixel Event Status',
          //   description: 'Monitor the site data passed to Rockerbox via on-site pixel for implementation, troubleshooting, and anomaly detection',
          //   icon: <CursorClickIcon />,
          //   route: routes.eventStatus,
          // },
        ],
      },
      {
        name: 'Data Exports',
        children: [
          {
            name: 'Ad Hoc File Exports',
            description: 'Run ad hoc exports of your Rockerbox data as CSV or Excel spreadsheets',
            icon: <DocumentDownloadIcon />,
            route: routes.adHocExportsHistory,
            rbacPermission: RBAC_UI_VIEWS_DATA_AD_HOC_EXPORTS,
          },
          {
            name: 'Google Sheets Plugin',
            description: 'Use our Google Sheets plugin to export and sync your data in a spreadsheet',
            icon: <TableIcon />,
            artifactLink: 'google_sheets_plugin.link',
            external: true,
            rbacPermission: RBAC_UI_VIEWS_DATA_GOOGLE_SHEETS_PLUGIN,
          },
          {
            name: 'Data Warehouse Sync',
            description: 'Configure live syncs of your data to data warehouses like Snowflake, AWS Redshift, and Google BigQuery',
            icon: <DatabaseIcon />,
            route: routes.datawarehouse,
            rbacPermission: RBAC_UI_VIEWS_DATA_DATA_WAREHOUSE,
          },
        ],
      },
    ],
  },
  {
    name: 'Attribution',
    children: [
      {
        name: 'Cross-Channel Report',
        description: 'View your overall marketing performance using multi-touch attribution',
        icon: <ChartSquareBarIcon />,
        route: routes.crossChannelReport,
        rbacPermission: RBAC_UI_VIEWS_ATTRIBUTION_CROSS_CHANNEL_REPORT,
      },
      {
        name: 'Platform Performance',
        description: 'Gauge your marketing performance based on platform-reported metrics',
        icon: <PresentationChartLineIcon />,
        route: routes.platformPerformance,
        rbacPermission: RBAC_UI_VIEWS_ATTRIBUTION_PLATFORM_PERFORMANCE,
      },
      {
        name: 'Rockerbox Performance',
        description: 'Compare platform-reported metrics to Rockerbox performance metrics',
        icon: <img src={rockerboxPerformanceIcon} alt="rockerbox navigation icon" />,
        route: routes.rockerboxPerformance,
        rbacPermission: RBAC_UI_VIEWS_ATTRIBUTION_RB_PERFORMANCE,
      },
    ],
  },
  {
    name: 'Journeys',
    children: [
      {
        name: 'Marketing Paths',
        description: 'View the sequences of marketing touchpoints leading customers to convert',
        icon: <MapIcon />,
        route: routes.marketingPaths,
        rbacPermission: RBAC_UI_VIEWS_JOURNEYS_MARKETING_PATHS,
      },
      {
        name: 'Funnel Position',
        description: 'Understand the role of each channel and make up of your overall marketing funnel',
        icon: <FilterIcon />,
        route: routes.funnelPosition,
        rbacPermission: RBAC_UI_VIEWS_JOURNEYS_FUNNEL_POSITION,
      },
      {
        name: 'Channel Overlap',
        description: 'View how different marketing channels intersect with each other to affect performance',
        icon: <ChartPieIcon />,
        route: routes.channelOverlap,
        rbacPermission: RBAC_UI_VIEWS_JOURNEYS_CHANNEL_OVERLAP,
      },
    ],
  },
  {
    name: 'MMM',
    children: [
      {
        name: 'Marketing Mix Modeling',
        description: 'Plan budgets and predict future revenue using historical marketing performance',
        icon: <CalculatorIcon />,
        route: routes.marketingMixModeling,
        rbacPermission: RBAC_UI_VIEWS_MMM_MARKETING_MIX_MODELING,
      },
    ],
  },
  {
    name: 'Benchmarks',
    children: [
      {
        name: 'Spend Trends',
        description: 'See how marketers are adjusting their budgets on different channels',
        icon: <PresentationChartBarIcon />,
        route: routes.spendTrends,
        rbacPermission: RBAC_UI_VIEWS_BENCHMARKS_SPEND_TRENDS,
      },
      {
        name: 'Performance Benchmarks',
        description: 'View trends and benchmarks for advertising KPIs on popular digital platforms',
        icon: <TrendingUpIcon />,
        route: routes.platformBenchmarks,
        rbacPermission: RBAC_UI_VIEWS_BENCHMARKS_PERFORMANCE_BENCHMARKS,
      },
    ],
  },
];

export const HELP_MENU = [
  {
    name: 'Rockerbox Academy',
    description: 'Access a variety of courses and learn at your own speed',
    icon: <AcademicCapIcon />,
    external: true,
    onClick: () => window.open('https://learn.rockerbox.com', '_blank'),
  },
  {
    name: 'Help Center',
    description: 'How-to guides and documentation about the Rockerbox platform',
    icon: <BookOpenIcon />,
    external: true,
    onClick: () => window.open('https://help.rockerbox.com', '_blank'),
  },
  {
    name: 'Contact Support',
    description: 'Need help or experiencing issues? Get in touch with our team',
    icon: <ChatAlt2Icon />,
    onClick: () => !!window.Intercom && window.Intercom('show'),
  },
  {
    name: 'Submit Feedback',
    description: 'Have feedback? We\'d love to hear about your experience and how we can improve',
    icon: <AnnotationIcon />,
    onClick: ({ intercomSurveyId }) => {
      if (!!window.Intercom) {
        window.Intercom('startSurvey', intercomSurveyId);
      }
    },
  },
];

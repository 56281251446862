import { useMemo } from 'react';

export const useResponseCurves = ({ responseCurves }) => {
  const responseCurvesArr = useMemo(() => {
    if (!responseCurves) return;
    return Object.entries(responseCurves).map(([k, v]) => ({
      key: k,
      values: v,
    }));
  }, [responseCurves]);

  return { responseCurvesArr };
};

import React from 'react';

import CacheContext from '../../utils/CacheContext';
import { createBatchTrigger } from '../../utils/api';
import { Form, Button, Modal } from 'semantic-ui-react';

const CreateTrigger = ({ onClose }) => {
  const [name, setName] = React.useState("");
  const [state, setState, Context] = React.useContext(CacheContext);
  const onSubmit = () => {
    createBatchTrigger({ name });
    setState({triggers: undefined})
    onClose()
  }
  return (
      <Modal open onClose={onClose} >
        <Modal.Header>Create Trigger</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Form.Group>
                <label>Name</label>
                <Form.Input onChange={(_, event) => setName(event.value)} />
              </Form.Group>
              <Button as="a" onClick={onSubmit}> Create </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
  )
}

export default CreateTrigger;

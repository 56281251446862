export const openIntercomMessenger = (event) => {
  event.preventDefault();
  if (window.Intercom) window.Intercom('show');
  return false;
}

export const HELP_ITEMS = [
  {
    title: "Help Center",
    description: "How-to guides & platform walkthrough",
    icon: "book",
    link: "https://help.rockerbox.com"
  },
  {
    title: "Reporting Docs",
    description: "Schemas of available data exports",
    icon: "file excel",
    link: "/documentation/reports"
  }
]

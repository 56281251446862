import React from 'react';
import { useStepsDisplay } from '../StepsSectionContext';
import { CreateTableDoneButton, GCPDataUnavailable } from '../components';

const TableCreationDocs = () => {
  const [stepsDisplay] = useStepsDisplay();
  const disabled = !stepsDisplay[1];
  const button = <CreateTableDoneButton disabled={disabled} />;
  return (
    <GCPDataUnavailable button={button} />
  );
};

export default TableCreationDocs;

import React from 'react';

import { isNumber } from 'lodash';

export const HEADER_DESCRIPTION = (
  <div style={{ padding: 10 }}>
    The performance comparision view is designed to help you understand the impact of changes you have made to your marketing mix on a quarterly basis.
    The results will generate a waterfall chart highlighting the change in total performance between two periods.
    <br />
    <br />
    Below you can select the quarters you would like to compare and see the impact of the changes on your performance.
    <br />
  </div>
);

export const CustomizedLabel = props => {
  const { justifyPosition, yOffset, text, viewBox } = props;
  const { x, y, width } = viewBox;

  const offset = isNumber(yOffset) ? yOffset
    : yOffset === 'middle' ? y - 60 : 0;
  const size = width / 2;
  const xPosition = justifyPosition === 'start' ? (x + size) : (x + width - size);

  return (
    <text x={xPosition} y={y - offset} fill="#666" textAnchor="middle" dominantBaseline="middle">
      {text}
    </text>
  );
};

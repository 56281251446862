import React, { Component } from 'react';
import { Input, Grid, Form, Header, Menu, Icon, Dropdown, Button } from 'semantic-ui-react';
import { FormWrap, FieldWrap, SplitLayout, ColumnLayout, ContentCard, IndexGrid } from '@rockerbox/styleguide';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const BuildModel = ({projects, model, touchpoints, updateBuild, submitModel}) => {

  return (
    <ContentCard title="Build New Model Run">
      <Form>
        <Form.Field>
          <label>Start Date</label>
          <DatePicker
            customInput={<Input icon='calendar alternate'/>}
            selected={moment(model.start_date)}
            onChange={(m,evt) => updateBuild("start_date")(evt, {value: m.format("YYYY-MM-DD")})}
            minDate={moment.utc().utcOffset(-5).subtract(90, "days")}
            maxDate={moment.utc().utcOffset(-5)}
            showDisabledMonthNavigation
          />
        </Form.Field>
        <Form.Field>
          <label>End Date</label>
          <DatePicker
            customInput={<Input icon='calendar alternate'/>}
            selected={moment(model.end_date)}
            onChange={(m,evt) => updateBuild("end_date")(evt, {value: m.format("YYYY-MM-DD")})}
            minDate={moment(model.start_date)}
            maxDate={moment.utc().utcOffset(-5)}
            showDisabledMonthNavigation
          />
        </Form.Field>
        <Form.Field>
          <label>Project</label>
          <Dropdown selection options={projects} onChange={updateBuild("project_id")} />
        </Form.Field>
  
  
        <Button onClick={submitModel}>Build Model</Button>
      </Form>
    </ContentCard>
  )
}

export default BuildModel;

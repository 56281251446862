import mixpanel from 'mixpanel-browser'

export const time = async (eventName) => {
  mixpanelTime(eventName)
}

// Mixpanel
export const mixpanelTime = async (eventName) => {
  mixpanel.time_event(eventName)
}

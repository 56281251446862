import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { Button, Popup } from 'semantic-ui-react';
import DateRangePicker from './DateRangePicker';
import StyledDropdown from '../StyledDropdown';
import { yesterday, firstReportingDate as firstReportDate } from '../../utils/time';

const COMPARISON_MAX_DAYS = 184;

const CalendarButton = styled(Button)`
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border: ${props => (props.invert ? '1px solid rgba(255,255,255,.15) !important' : '1px solid #bfc6f3 !important')};
  border-left: none !important;
  box-shadow: none !important;
  color: ${props => (props.invert ? '#FFF !important' : '#475ddc !important')};
  background:  ${props => (props.transparent ? 'transparent !important' : 'white !important')};

  &:hover {
    border-color: transparent !important;
    background-color: ${props => (props.invert ? 'rgba(255,255,255,.15) !important' : '#475ddc !important')};
    color: white !important;
  }
`;

const DateWrap = styled.div`
  .DateInput_input {
    font-size: 14px;
    text-align: center;
    font-family: 'Inter', 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }

  .DateRangePickerInput_arrow {
    margin: 0 30px;
  }

  .DateInput_input__focused {
    border: none;
    background: rgba(71, 93, 220, 0.10);
    border-radius: 4px;
  }

  .DateRangePicker_picker {
    right: 0px !important;
  }

  .CalendarMonth_caption,
  .DayPicker_weekHeader_li {
    font-family: 'Poppins', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }

  .CalendarDay {
    font-family: 'Inter', 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-feature-settings: "kern" 1, "tnum" 1;
  }
`;

const CompareDateDropdown = ({ compareOptions, compareRange, numDays, onCompareDateChange }) => {
  const disabled = numDays > COMPARISON_MAX_DAYS;
  return (
    <Popup
      content="Historical comparisons are only available for time periods up to 31 days."
      position="top center"
      disabled={!disabled}
      trigger={(
        <StyledDropdown
          className="compare-date-dropdown"
          fluid
          containerStyles={{ paddingRight: '12px', minWidth: '175px', borderRight: 'none' }}
          options={compareOptions}
          defaultValue=""
          value={compareRange}
          label="Compare Range"
          onChange={(e, { value }) => onCompareDateChange(value)}
          disabled={disabled}
          clearable
        />
      )}
    />
  );
};

const CompareDateRangePicker = ({
  compareStart, compareEnd, compareStartDate, setCompareStartDate, compareEndDate, setCompareEndDate, // comparison getters/setters
  firstReportingDate = firstReportDate.format('YYYY-MM-DD'), maxSelectableDate, // other dates
  marginTop, marginBottom, invert = false, transparent = false, // styling
  setCompareRange, compareOptions, compareRange, numDays, onCompareDateChange, // comparison info
}) => {
  const [showCustom, setShowCustom] = useState(false);
  const [start, setStart] = useState(compareStartDate || yesterday);
  const [end, setEnd] = useState(compareEndDate || yesterday);

  useEffect(() => {
    setStart(compareStart);
    setEnd(compareEnd);
  }, [compareStart, compareEnd]);

  useEffect(() => {
    if (showCustom) setShowCustom(false);
  }, [compareEndDate]);

  const setStartEnd = ({ startDate: startUpdate, endDate: endUpdate }) => {
    const startMatch = compareStart === startUpdate.format('YYYY-MM-DD') || compareStart === '';
    const endMatch = compareEnd === endUpdate.format('YYYY-MM-DD') || compareEnd === '';
    if (startMatch && endMatch) return;
    const s = startUpdate.format('YYYY-MM-DD');
    const e = endUpdate.format('YYYY-MM-DD');
    // diff is apparently 2 days off here, but this is working
    const isOutsideRange = endUpdate.isAfter(startUpdate?.clone().add(numDays, 'days')) || endUpdate.isBefore(startUpdate?.clone().add(numDays - 2, 'days'));
    if (isOutsideRange || !s || !e) return;
    setCompareRange('custom');
    setStart(s);
    setEnd(e);
    setCompareStartDate(s);
    setCompareEndDate(e);
  };

  const disabled = numDays > COMPARISON_MAX_DAYS;

  return (
    <div>
      <div style={{ marginTop, marginBottom, display: 'flex', height: 'auto', minWidth: transparent ? 300 : 'auto', marginLeft: '12px' }}>
        { disabled ? (
          <Popup
            content="Historical comparisons are only available for time periods up to 31 days."
            position="top center"
            disabled={!disabled}
            trigger={(
              <div style={{ minWidth: '180px', display: 'flex' }}>
                <CompareDateDropdown {...{ compareOptions, compareRange, numDays, onCompareDateChange }} />
                <CalendarButton
                  disabled={disabled}
                  icon="calendar alternate"
                  style={{ marginLeft: '-12px', marginTop: '17px', zIndex: '1000' }}
                  onClick={() => setShowCustom(true)}
                  active={showCustom}
                  invert={invert}
                  transparent={transparent}
                />
              </div>
              )}
          />
        )
          : (
            <div style={{ minWidth: '180px', display: 'flex' }}>
              <CompareDateDropdown {...{ compareOptions, compareRange, numDays, onCompareDateChange }} />
              <Popup
                wide="very"
                trigger={(
                  <CalendarButton
                    disabled={disabled}
                    icon="calendar alternate"
                    style={{ marginLeft: '-12px', marginTop: '17px' }}
                    onClick={() => setShowCustom(true)}
                    active={showCustom}
                    invert={invert}
                    transparent={transparent}
                  />
              )}
                content={(
                  <DateWrap>
                    <DateRangePicker
                      startDate={moment(start)}
                      endDate={end ? moment(end) : null}
                      onChange={setStartEnd}
                      {...{ firstReportingDate, maxSelectableDate, setShowCustom, numDays }}
                    />
                  </DateWrap>
                )}
                on="click"
                open={showCustom}
                onClose={() => setShowCustom(false)}
                onOpen={() => setShowCustom(true)}
                position="bottom right"
                pinned
                positionFixed
                flowing
                basic
                style={{
                  height: 396,
                  width: 346,
                }}
              />
            </div>
          )}
      </div>
    </div>
  );
};

CompareDateRangePicker.propTypes = {
  marginTop: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  marginBottom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  // eslint-disable-next-line react/forbid-prop-types
  firstReportingDate: PropTypes.object, // moment
};

export default CompareDateRangePicker;

/*
>>>>>>>> EXAMPLE <<<<<<<<<<
<DateRangeHooks
  {...{startDate, endDate, onDateChange, marginTop: '0', marginBottom: '0',   fluid: true, firstReportingDate}}
/>
*/

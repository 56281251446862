import React from 'react';
import { Image } from 'semantic-ui-react';
import { noDataCat } from '@rockerbox/styleguide';

const NoDataCat = ({ message, height, imgSize }) => (
  <div className="flex-center-center flex-column" style={{ height: height || '100%' }}>
    <Image src={noDataCat} size={imgSize || 'tiny'} centered />
    <h3 style={{ fontWeight: 500, marginTop: 10, marginBottom: 15, opacity: 0.6, textAlign: 'center' }}>
      {message}
    </h3>
  </div>
);

export default NoDataCat;

import React from 'react';
import { Table } from 'semantic-ui-react';
import { Baseline, styler, Resizable, AreaChart, EventMarker, Charts, ChartContainer, ChartRow, YAxis } from "react-timeseries-charts";
import { TimeSeries, TimeRange } from "pondjs";
import moment from 'moment';

const formatOptions = new Intl.NumberFormat('en-US',{
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const Percentage = ({item, col}) => {
  const percent = (new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
  })).format(item[col.key])

  return (
    <Table.Cell>
      {item[col.key] > 0 ? percent : '-'}
    </Table.Cell>
  )
}

const NumberCell = ({item, col}) => (
  <Table.Cell>
    { formatOptions.format(item[col.key]) }
  </Table.Cell>
)

const TimestampCell = ({item, col}) => {
  const time = moment(item[col.key]).format('MMM D, YYYY - h:mm a');
  return (
    <Table.Cell>{time}</Table.Cell>
  )
};

const ExpandedRow = ({item}) => {
  return "YO"
}


let cols = [
  { display: "Tier One", key: "tier_one"},
  { display: "Tier Two", key: "tier_two"},
  { display: "Tier Three", key: "tier_three"},
  { display: "Tier Four", key: "tier_four"},
  { display: "Tier Five", key: "tier_five"},
  { display: "Feed Type", key: "feed_type"},
  { display: "Timestamp", key: "timestamp", as: TimestampCell},
]
const stats = [
  { display: "Control", key: "baseline_mean", as: NumberCell},
  { display: "(stdev)", key: "baseline_std", as: NumberCell},
  { display: "Postlog", key: "postlog_mean", as: NumberCell},
  { display: "Diff", key: "diff", as: NumberCell},
  { display: "P-value", key: "p_value", as: NumberCell},
  { display: "Effect Size", key: "effect_size", as: NumberCell},
  { display: "Lift", key: "lift", as: Percentage},
];

class PostlogAnalysis extends React.Component {

  state = { }

  renderMarker = () => {
    if (!this.state.tracker) return <g />;
    return (
      <EventMarker
        type="flag"
        axis="axis"
        event={this.state.trackerEvent}
        column="Other"
        info={[{ label: "Anomaly", value: this.state.trackerValue }]}
        infoTimeFormat="%Y-%m-%d %H:%M:%S"
        infoWidth={120}
        markerRadius={2}
        markerStyle={{ fill: "black" }}
      />
    );
  }

  handleTrackerChanged = t => {
    if (t) {
      const e = this.state.sessions.atTime(t)
      const eventValue = e.get("Other");
      const eventTime = new Date(
          e.begin().getTime() + (e.end().getTime() - e.begin().getTime()) / 2
      );
      const v = `${eventValue > 0 ? "+" : ""}${eventValue}`;
      this.setState({ tracker: eventTime, trackerValue: v, trackerEvent: e });
    } else {
      this.setState({ tracker: null, trackerValue: null, trackerEvent: null });
    }
  }

  render() {

    const row = this.props.selected;
    const { start, end, color } = this.props;
    if (!row.baseline_events || !row.postlog_events) return null

    const evts = []
    const baselineEvents = row.baseline_events.map((e,i) => {
      evts.push([row.timestamp + (-10 + i)*60*1000, e, 0])
    })
    const postlogEvents = row.postlog_events.map((e,i) => {
      evts.push([row.timestamp + (i)*60*1000, 0, e])
    })

    const series = new TimeSeries({
        name: row.timezone,
        columns: ["time", "baseline", "postlog"],
        points: evts
      })

    const max = Math.max(series.max("baseline"), series.max("postlog"))

    //const timerange = start && end ? new TimeRange(start, end) : series.timerange();
    const timerange = new TimeRange(series.timerange().begin(), new Date(+series.timerange().end() + 5*60*1000));

    const avgLineStyle = {
      label: { fill: "#8B7E7E", fontWeight: 100, fontSize: 11, pointerEvents: "none" },
      line: { stroke: "#bbb", strokeWidth: 1, strokeDasharray: "2,2", pointerEvents: "none" }
    }

    const stdLineStyle = {
      label: { fill: "#8B7E7E", fontWeight: 100, fontSize: 11, pointerEvents: "none" },
      line: { stroke: "#bbb", strokeWidth: 1, strokeDasharray: "2,2", pointerEvents: "none" }
    }

    const style = styler(["baseline","postlog"].map((c, i) => ({
        key: c,
        color: i == 0 ? "#999" : color
    })));

    // debugger

    return (
      <React.Fragment>
        { series.name }
        <Resizable>
          <ChartContainer
            /*onTrackerChanged={/*this.handleTrackerChanged}*/
            timeRange={timerange} >
            <ChartRow height="100">
                <YAxis id="axis" min={0} max={max} width="60" type="linear" format=",.2f" labelOffset={0} tickCount={4} />
                <Charts>
                  <AreaChart style={style} axis="axis" series={series} columns={{up:["baseline","postlog"]}} interpolation="curveStep"/>
                  <Baseline axis="axis" value={row.baseline_mean} label="Avg" position="right" style={avgLineStyle}/>
                  <Baseline axis="axis" value={row.baseline_mean-row.baseline_std} style={stdLineStyle} />
                  <Baseline axis="axis" value={row.baseline_mean+row.baseline_std} style={stdLineStyle} />

                  { this.renderMarker() }
                </Charts>
            </ChartRow>
          </ChartContainer>
        </Resizable>
      </React.Fragment>
    )
  }

}
export default PostlogAnalysis;

//[{"origin":"juul_tv_postlog","timestamp_min":1556681700000,"timezone":"","postlog_mean":95.4,"diff":12.7636363636,"baseline_mean":82.6363636364,"city":"","dma_id":"","network":"CNN","timestamp_max":1556682900000,"baseline_std":7.1031363111,"event_id":"a07aaac8895ca28542284177d0a6abcf","effect_size":1.7969015101,"source":"juul","t_value":20.7817034401,"outlet_type":"Cable - National","tier_two":"Cable - National","timedelta":600000000000,"tier_one":"TV","timestamp":1556682300000,"baseline_events":[0,0,0,0,0,0,0,0,0,1,0],"feed_type":"Live","lift":0.1544554455,"date":"2019-05-01 00:00:00","region":"","postlog_events":[120,112,89,75,100,110,80,97,79,92],"p_value":0,"inventory":"CNN Tonight","tier_five":"Carolyn Rev_:30","tier_three":"CNN","country":"US","tier_four":"CNN Tonight"}]

import React, { Component } from "react";
import { getSavedReportSchema } from "../../utils/api";
import { getSavedreports, updateSavedReport } from '../ReportIndex/api';
import { ContentCard, ReportSchemaTable } from "@rockerbox/styleguide";
import { Icon, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import * as routes from '../../routes';

const CardTitle = ({ loading, schema }) => (
  <React.Fragment>
    Schema for Report:&nbsp;
    {loading ? (
      <Icon loading name="circle notch" color="grey" />
    ) : (
      Object.keys(schema.definitions)[0]
    )}
  </React.Fragment>
);

class ReportSchema extends Component {
  state = {
    schema: null,
    loading: true,
    defaultSchema: {},
    selectedColumns: []
  };

  componentDidMount() {
    getSavedReportSchema(this.props.match.params.id)
      .then(schema => {
        const defaultSchema = JSON.parse(schema.info.default_schema);
        const selectedColumns = schema.info.custom_schema ?
          Object.keys(JSON.parse(schema.info.custom_schema)) :
          Object.keys(defaultSchema)
        const updateState = {
          loading: false,
          defaultSchema,
          selectedColumns,
          schema, };

        return this.setState(updateState);
      })
      .catch(err => {
        console.log(`error load saved report schema ${err}`);
      });
  }

  updateSelectedColumns(name) {
    const { selectedColumns } = this.state;
    if (selectedColumns.includes(name)) {
      const updatedCols = selectedColumns.filter(col => col != name);
      this.setState({ selectedColumns: updatedCols });
    } else {
      const newSelectedCols = selectedColumns.slice(0);
      newSelectedCols.push(name);
      this.setState({ selectedColumns: newSelectedCols });
    }
  }

  selectAllOrEmpty(bool) {
    const { selectedColumns, defaultSchema } = this.state;
    if (selectedColumns.length === Object.keys(defaultSchema).length && selectedColumns.length !== 0) {
      return this.setState({selectedColumns: []});
    }
    this.setState({selectedColumns: Object.keys(defaultSchema)});
  }

  submitCustomSchema() {
    const { selectedColumns, defaultSchema, schema } = this.state;
    const { id } = this.props.match.params;
    const submitBody = selectedColumns.reduce((requestBody, colName) => {
      requestBody["custom_schema"][colName] = defaultSchema[colName];
      return requestBody;
    }, { custom_schema: {} });
    const customSchemaStr =   JSON.stringify(submitBody['custom_schema']);
    this.setState({ loading: true });
    return getSavedreports().then(savedReports => {
      const report = savedReports.filter(report => report.id == id)[0];
      return Object.assign(report, {custom_schema: customSchemaStr, filters: JSON.stringify(report.filters)});
    }).then(body => updateSavedReport(id, body))
      .then(response => this.setState({ loading: false }))
      .catch(err => console.log("Error happened when submit custom schema"));
  }

  render() {
    const { schema, loading, selectedColumns, defaultSchema } = this.state;
    return (
      <ContentCard
        hasTable
        loading={loading}
        title={
          <div>
            <CardTitle loading={loading} schema={schema} />
            {!loading && (
              <Button
                primary
                style={{marginLeft: "10px"}}
                onClick={() => this.submitCustomSchema()}
              >
                Save Schema
              </Button>
            )}
          </div>
        }
        topRight={
          <Link to={routes.reports}>
            <Icon name="angle left" />
            Back to Exports
          </Link>
        }
      >
        {schema && (
          <ReportSchemaTable
            schema={schema}
            selectedColumns={selectedColumns}
            defaultSchema={defaultSchema}
            selectAllOrEmpty={this.selectAllOrEmpty.bind(this)}
            updateSelectedColumns={this.updateSelectedColumns.bind(this)}
          />
        )}
      </ContentCard>
    );
  }
}

export default ReportSchema;

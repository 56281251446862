/* eslint-disable import/prefer-default-export */

export function getOnsiteDataset(filterId, dataset, startDate, endDate) {
  const url = `/data/v3/onsite_events?identifier=${filterId}&keys=${dataset}&start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(({ response }) => response);
}

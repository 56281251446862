/* eslint-disable react/jsx-props-no-spreading, no-shadow, no-unused-vars, react/forbid-prop-types */
import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { Message, Popup, Icon, Table, Grid, Header, Segment, Checkbox, Button } from 'semantic-ui-react';
import styled from 'styled-components';

import { NumberCell, defaultFormatTypeAs } from './Cells';
import { ChooseFormat } from './FormatSelector';
import formatterFuncs from './formatters';
import {
  getColumnsByType,
  buildColumnOptions,
  buildGridColumns,
} from './helpers';
import Bar from '../Bar';
import IndexGrid from '../IndexGridNew';
import SelectList from '../SelectList';

const setDropdownValue = func => (evt, { value }) => func(value);
const FORMAT_TICK_TYPES = { daily: 'day', weekly: 'week', monthly: 'month' };

const CustomButtonFormat = styled.div`
  & > .ui.basic.active.button, .ui.basic.buttons .active.button {
   color:rgb(2 108 172 / 85%) !important;
  }
  & > .ui.basic.button, .ui.basic.buttons .button {
   color:rgb(2 108 172 / 75%) !important;
  }
  & > .ui.basic.buttons {
    border:none
  }
  & > .ui.basic.buttons .button {
    border-left:none
  }
`;

const SelectionSection = ({ title, value, options, onChange, metricDirection, changeMetricDirection }) => (
  <Segment secondary>
    <Header
      as="h4"
      content={title !== 'Metrics' ? title
        : (
          <CustomButtonFormat>
            {title}
            <Button.Group size="mini" floated="right" compact basic style={{ marginTop: '-3px' }}>

              <Popup
                content="Show as rows" trigger={(
                  <Button active={metricDirection === 'row'} onClick={() => changeMetricDirection('row')}>
                    <Icon name="columns" style={{ transform: 'rotate(-90deg) translate(-1px, 0)' }} />
                  </Button>
            )}
              />
              <Popup
                content="Show as columns" trigger={(
                  <Button active={metricDirection === 'column'} onClick={() => changeMetricDirection('column')}>
                    <Icon name="columns" style={{ transform: 'translate(3px, 0)' }} />
                  </Button>
            )}
              />
              <Popup
                content="Show as table" trigger={(
                  <Button active={metricDirection === 'table'} onClick={() => changeMetricDirection('table')}>
                    <Icon name="table" style={{ transform: 'translate(3px, 0)' }} />
                  </Button>
            )}
              />
            </Button.Group>
          </CustomButtonFormat>
        )}
    />
    <SelectList multiple selection {...{ value, options, onChange }} />
  </Segment>
);

const PivotGrid = props => {
  const { hasRawData, title, description, hasFooter = true, selectColumnWidth = 2, tooltips = {} } = props;
  const {
    calculatedColumns = {},
    skipColumns = [],
    skipFormatter = [],
    formatTypesAs = {},
    data = [],
    columnCells = {},
    columnNames = {},
    showOptions: showOptionsProps,
    defaultRows,
    defaultMetricDirection,
    defaultCols,
    defaultSummary,
    defaultFormats,
  } = props;

  const [showOptions, setShowOptions] = React.useState(showOptionsProps);
  const [rows, setRows] = React.useState(defaultRows);
  const [metricDirection, setMetricDirection] = React.useState(defaultMetricDirection || 'row');
  const [showTimeseries, setShowTimeseries] = React.useState(true);
  const [showRowTotal] = React.useState(false);
  const [cols, setCols] = React.useState(defaultCols);
  const [summary, setSummary] = React.useState(defaultSummary);
  const [formats, setFormats] = React.useState(defaultFormats || {});

  const [defaults, setDefaults] = React.useState({
    defaultCols,
    defaultMetricDirection,
    defaultRows,
    defaultSummary,
    defaultFormats: defaultFormats || {},
  });

  React.useEffect(() => {
    setDefaults({
      showOptions: showOptionsProps,
      defaultCols,
      defaultMetricDirection,
      defaultRows,
      defaultSummary,
      defaultFormats: defaultFormats || {},
    });
    if (JSON.stringify(showOptionsProps) !== JSON.stringify(defaults.showOptions)) setShowOptions(showOptionsProps);
    if (JSON.stringify(defaultCols) !== JSON.stringify(defaults.defaultCols)) setCols(defaultCols);
    if (JSON.stringify(defaultMetricDirection) !== JSON.stringify(defaults.defaultMetricDirection)) setMetricDirection(defaultMetricDirection);
    if (JSON.stringify(defaultRows) !== JSON.stringify(defaults.defaultRows)) setRows(defaultRows);
    if (JSON.stringify(defaultSummary) !== JSON.stringify(defaults.defaultSummary)) setSummary(defaultSummary);
    if (defaultFormats && (JSON.stringify(defaultFormats) !== JSON.stringify(defaults.defaultFormats))) setFormats(defaultFormats);
  }, [
    showOptionsProps,
    defaultCols,
    defaultMetricDirection,
    defaultRows,
    defaultSummary,
    defaultFormats,
  ]);

  const changeMetricDirection = direction => setMetricDirection(direction);
  const toggleShowTimeseries = () => setShowTimeseries(!showTimeseries);
  const columnNameFormatter = display => (display === 'metric' ? 'Metric' : (columnNames[display] || display));

  const setFormat = column => (evt, { value }) => {
    const newFormats = { ...formats, [column]: value };
    setFormats(newFormats);
  };

  const columns = React.useMemo(
    () => {
      const options = buildColumnOptions(data, skipColumns, calculatedColumns);

      options.map(row => {
        const copy = { ...row };
        copy.option = <ChooseFormat {...copy} {...{ column: copy.key, formats, setFormat }} />;
        return copy;
      });
      return options;
    },
    [data, formats],
  );

  const { textCols, valueCols } = React.useMemo(
    () => getColumnsByType(columns, formatterFuncs, columnNameFormatter, skipFormatter),
    [columns],
  );
  const tickType = React.useMemo(() => {
    const dateKey = Object.keys(formats).find(key => key.includes('date')) || 'date';
    return (FORMAT_TICK_TYPES)[formats[dateKey] || 'daily'] || 'day';
  }, [formats]);

  const getValueFormatter = summary => {
    const [, fType] = summary.split(':');
    const formatterType = fType || formats[summary] || 'sum';
    return formatTypesAs[formatterType] || defaultFormatTypeAs[formatterType];
  };

  const getFormatter = key => {
    if (key.includes(':')) {
      const [colKey, formatterName] = key.split(':');
      const { type } = columns.find(col => col.key === colKey) || {};
      if (!type && formatterName === 'sum') return (v => v || 0);
      const funcType = formatterFuncs[type];
      const func = funcType[formatterName];
      return func;
    }

    const formatterName = formats[key];
    const { type } = columns.find(col => col.key === key) || {};

    if (!type) return v => v;

    const defaultFormatter = (type === 'datetime')
      ? 'daily' : (type === 'number')
        ? 'sum' : 'string';

    if (calculatedColumns[key]) return calculatedColumns[key];
    return formatterFuncs[type][formatterName] || formatterFuncs[type][defaultFormatter];
  };

  const getSummaryFunc = summaries => (values, obj = {}) => summaries.reduce((accu, summary) => {
    if (calculatedColumns[summary]) {
      accu[summary] = getFormatter(summary)(values, obj);
      return accu;
    }
    const [colKey] = summary.split(':');
    const arr = values.map(row => (row[colKey] !== '' ? row[colKey] : 0));

    const formatter = getFormatter(summary);
    accu[summary] = formatter(arr, obj);
    return accu;
  }, {});

  const keysFunc = keys => row => keys.map(key => getFormatter(key)(row[key])).join('|');
  const summaryFunc = summary => values => getSummaryFunc(summary)(values, {})[summary[0]];

  const summarizeByKeys = (data, keys) => d3.nest()
    .key(keysFunc(keys))
    .rollup(summaryFunc(summary))
    .map(data);

  const [preparedData, preparedRowData, datetimeData, , colSummary, rowMetricHeaders, colMetricHeaders] = React.useMemo(() => {
    if (!rows || !cols || !summary) return [];

    const columnSet = new Set();
    const metricColumnSet = new Set();

    const rowSummary = summarizeByKeys(data, rows);
    const colSummary = summarizeByKeys(data, cols);
    colSummary.value = d3.sum(Object.values(colSummary));

    const preparedEntries = d3.nest()
      .key(keysFunc(rows))
      .key(keysFunc(cols))
      .rollup(values => {
        const row = values[0];
        const rowKey = keysFunc(rows)(row);
        const colKey = keysFunc(cols)(row);
        return getSummaryFunc(summary)(values, { rowKey, colKey, rowSummary, colSummary });
      })
      .entries(data);

    const metricSummary = summary
      .filter(row => row.includes('sum'))
      .reduce((accu, metric) => {
        accu[metric.split(':')[0]] = d3.sum(data, row => row[metric.split(':')[0]]);
        return accu;
      }, {});

    summary
      .filter(row => !row.includes('sum'))
      .reduce((accu, metric) => {
        if (calculatedColumns[metric]) accu[metric] = calculatedColumns[metric]([accu]);
        return accu;
      }, metricSummary);

    const preparedRows = preparedEntries
      .reduce((accu, row) => {
        const splitKey = row.key.split('|');
        const keyObj = splitKey.reduce((accu2, c, i) => Object.assign(accu2, { [rows[i]]: c }), {});

        const pivotedByMetric = row.values.reduce((q, colObj) => {
          const byValue = Object.entries(colObj.values)
            .reduce((accu3, [key, value]) => {
              accu3[key] = accu3[key] || {};
              accu3[key][colObj.key] = value;
              metricColumnSet.add(colObj.key);
              return accu3;
            }, q);

          return q;
        }, {});

        const newRows = Object.entries(pivotedByMetric)
          .map(([metric, values]) => Object.assign(_.cloneDeep(keyObj), values, { metric: columnNameFormatter(metric) }));

        return [...accu, ...newRows];
      }, [])
      .map(row => {
        const key = rows.map(key => row[key]).join('|');
        const value = rowSummary[key];
        return Object.assign(row, { value });
      });

    const prepared = preparedEntries
      .reduce((accu2, row) => {
        const splitKey = row.key.split('|');
        const keyObj = splitKey.reduce((accu2, c, i) => Object.assign(accu2, { [rows[i]]: c }), {});

        const obj = row.values.reduce((accu, colObj) => {
          Object.entries(colObj.values)
            .forEach(([key, value]) => {
              accu[`${colObj.key}|${key}`] = value;
              columnSet.add(`${colObj.key}|${key}`);
            });

          return accu;
        }, keyObj);

        return [...accu2, obj];
      }, [])
      .map(row => {
        const key = rows.map(key => row[key]).join('|');
        const value = rowSummary[key];
        return Object.assign(row, { value });
      });

    const datetimeCols = cols.filter(col => col.includes('date'));
    const nonDatetimeCols = cols.filter(col => !col.includes('date'));

    const datetimeData = d3.nest()
      .key(keysFunc(datetimeCols))
      .key(keysFunc([...nonDatetimeCols, ...rows]))
      .rollup(values => getSummaryFunc(summary)(values, { }))
      .entries(data)
      .reduce((accu, row) => {
        const splitKey = row.key.split('|');
        const keyObj = splitKey.reduce((accu2, c, i) => Object.assign(accu2, { [datetimeCols[i]]: c }), {});

        const obj = row.values.reduce(
          (q, colObj) => Object.assign(q, { [colObj.key]: colObj.values[summary[0]] }),
          keyObj,
        );

        return [...accu, obj];
      }, []);

    const singleValue = summary.length === 1;
    const colMetricHeaders = buildGridColumns(
      rows,
      columnSet,
      getValueFormatter,
      defaultFormatTypeAs,
      singleValue,
      columnSet.size === 1 && showRowTotal,
      columnNameFormatter,
      summary,
      columnCells,
      tooltips,
    );
    const rowMetricHeaders = buildGridColumns(
      [...rows, 'metric'],
      metricColumnSet,
      getValueFormatter,
      defaultFormatTypeAs,
      false,
      metricColumnSet.size === 1 && showRowTotal,
      columnNameFormatter,
      [],
      columnCells,
      tooltips,
    );

    return [prepared, preparedRows, datetimeData, rowSummary, metricSummary, rowMetricHeaders, colMetricHeaders];
  }, [data, rows, cols, summary, formats]);

  const isDatetime = React.useMemo(() => {
    if (!cols || !columns) return [];
    const colTypes = cols.map(col => (columns.find(c => c.key === col) || {}).type).filter(x => x);
    return !!colTypes.find(type => type === 'datetime');
  }, [cols, columns]);

  const { headerRows, gridColumns } = (metricDirection === 'column') ? colMetricHeaders : rowMetricHeaders;
  const gridData = (metricDirection === 'column') ? preparedData : preparedRowData;

  const additionalHeaders = headerRows.map(headerRow => {
    const spacerColumns = rows.map(() => ({}));
    const headerColumns = [...spacerColumns, ...headerRow];

    if (headerRow.filter(row => !!row.display).length === 0) return null;

    return (
      <Table.Row>
        { headerColumns.map(cell => <Table.HeaderCell colSpan={cell.colSpan}>{ cell.display }</Table.HeaderCell>) }
      </Table.Row>
    );
  });

  const formatParams = { rowName: rows.join(', '), colName: cols.join(', '), columns, formats, setFormat };
  const sections = [
    { title: 'Rows', value: rows, options: textCols, onChange: setDropdownValue(setRows), formatParams },
    { title: 'Columns', value: cols, options: textCols, onChange: setDropdownValue(setCols), formatParams },
    { title: 'Metrics', value: summary, options: valueCols, onChange: setDropdownValue(setSummary), formatParams, changeMetricDirection, metricDirection },
  ];

  const timeseriesCompatible = React.useMemo(() => cols.find(row => row === 'date'), [cols]);

  return (
    <>
      { description && <Message attached="top" size="small" header={description} style={{ marginBottom: '5px' }} />}
      <Grid celled="internally">
        { showOptions && (
        <Grid.Column width={selectColumnWidth} style={{ marginLeft: -13, marginTop: -13 }}>
          <h4>{ title || 'Pivot Data' }</h4>
          { sections.map(props => <SelectionSection {...props} />) }
        </Grid.Column>
        )}

        <Grid.Column width={showOptions ? (16 - selectColumnWidth) : 16} style={{ marginTop: -13, boxShadow: 'none' }}>
          {(showOptions || timeseriesCompatible)
        && (
        <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', marginBottom: 10 }}>
          {showOptions
            && (
            <div>
              <h3>
                {' '}
                <b>
                  { rows.map(columnNameFormatter).join(', ') }
                  {' '}
                  { (cols.length > 0 && rows.length > 0) ? 'by' : '' }
                  {' '}
                  { cols.map(columnNameFormatter).join(',') }
                  {' '}
                </b>
                {' '}
              </h3>
              <h5 style={{ marginTop: 0 }}>
                {' '}
                { summary.map(col => columnNameFormatter(col)).join(', ') }
                {' '}
              </h5>
            </div>
            )}
          <div>
            { timeseriesCompatible && (
            <div style={{ display: 'flex' }}>
              {/* <FormatSelector  {...{ columnNameFormatter, fields: cols||[], ...formatParams }} /> */}
              <div style={{ lineHeight: '25px', marginLeft: '10px' }}>
                <Checkbox inline checked={showTimeseries} label="Show timeseries" onClick={toggleShowTimeseries} />
              </div>
            </div>
            )}
          </div>
        </div>
        )}
          { showTimeseries && isDatetime && <Bar stacked data={datetimeData} exclude={['key']} {...{ tickType, minNumItems: 15 }} /> }
          <div style={{ maxHeight: '500px', overflow: 'auto' }}>
            { metricDirection === 'table'
              ? summary.map(row => (
                <>
                  <h5 style={{ marginTop: 15 }}><b>{ columnNameFormatter(row) }</b></h5>
                  <IndexGrid
                    sortable headerRows={additionalHeaders} cols={gridColumns.filter(col => col.key !== 'metric')}
                    data={gridData.filter(d => d.metric === columnNameFormatter(row))}
                  />
                </>
              ))
              : (
                <IndexGrid
                  paginate itemsPerPage={500} sticky
                  sortable
                  headerRows={additionalHeaders}
                  cols={gridColumns}
                  data={(hasRawData && (gridData.length >= 0)) ? gridData : false}
                  footer={hasFooter
                    ? (
                      <Table.Footer>
                        { metricDirection === 'column' && (
                        <Table.Row style={{ fontWeight: 'bold', backgroundColor: '#F9FAFB' }}>
                          {
                    gridColumns.map(col => {
                      const keys = col.key.split('|');
                      const key = keys[keys.length - 1].split(':')[0];
                      return colSummary[key]
                        ? <NumberCell item={colSummary} col={{ key }} style={{ borderTop: '1px solid #ccc' }} />
                        : <Table.Cell style={{ borderTop: '1px solid #aaa' }}>{ rows.includes(col.key) ? 'Summary' : '-' }</Table.Cell>;
                    })
                  }
                        </Table.Row>
                        ) }
                      </Table.Footer>
                    ) : null}
                />
              )}
          </div>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default PivotGrid;

PivotGrid.propTypes = {
  /** Array of objects */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Array of strings */
  defaultRows: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Array of strings */
  defaultCols: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Bool that determines whether or not to show loading state */
  hasRawData: PropTypes.bool.isRequired,
  /** Array of strings */
  defaultSummary: PropTypes.arrayOf(PropTypes.string).isRequired,
  showOptions: PropTypes.bool,
};

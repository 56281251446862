import styled from 'styled-components';
import { Segment, Button } from 'semantic-ui-react';

export const StyledSegment = styled(Segment)`
  font-size:.95rem
`;

export const StyledButton = styled(Button)`
  line-height:18px !important
`;

import React, { Component } from 'react';
import { ContentCard, PagedIndexGrid } from '@rockerbox/styleguide';
import { Input, Button, Message, Table } from 'semantic-ui-react';
import axios from 'axios';
import { getAccountUsers } from '../../utils/api';
import { InviteUsersRows } from './InviteUsersRows';

const InviteStatus = ({item, col}) => {
  return (
    <Table.Cell>
      {item[col.key] === 0 ? 'Active' : 'Invite Sent'}
    </Table.Cell>
  )
}

class InviteUsers extends Component {
  state = {
    email: '',
    loading: false,
    error: false,
    loadingUsers: true,
    users: []
  }

  componentDidMount() {
    getAccountUsers().then(data => this.setState({users: data, loadingUsers: false}));
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.users !== prevState.users) {
      this.setState({users: this.state.users})
    }
  }

  handleChange = (event, data) => {
    const { value } = data;
    this.setState({ email: value})
  }

  handleManageUpdate = () => {
    getAccountUsers().then(data => this.setState({users: data}))
  }

  handleSubmit = () => {
    const postObj = {
      email: this.state.email.trim(),
      advertiser_name: this.props.advertiser
    };

    this.setState({ loading: true })
    console.log(postObj)
    axios.post('/user/signup', postObj)
      .then(response => {
        console.log('response', response)
        this.setState({loading: false, email: '', error: false })
        getAccountUsers().then(data => {
          console.log('data', data)
          this.setState({ users: data })
        })
        .catch(err => console.log('err', err))
      })
      .catch(error => {
        console.log('error', error)
        this.setState({error: true, loading: false })
      })

  }

  render() {
    const { email, loading, error, users, username, loadingUsers } = this.state;
    const { hideTitle } = this.props;
    const current_username= this.props.username;
    const manage_params = {
      current_username: this.props.username,
      handleManageUpdate: this.handleManageUpdate
    };

    const cols = [
      {display: 'Email', key: 'email'},
      {display: '', key: 'has_invite', as: InviteStatus},
      {display: '', key: manage_params, as: InviteUsersRows}
    ];

    return (
      <ContentCard title={!hideTitle && "Invite Users"} loading={loadingUsers}>
        <div style={{marginBottom: 14}}>
          <Input
            type="email"
            placeholder="sally@sample.com"
            value={email}
            action
            onChange={this.handleChange}
            style={{width: 300}}
          >
            <input />
            <Button
              primary
              type="submit"
              onClick={this.handleSubmit}
              disabled={email === ''}
              loading={loading}
            >Invite</Button>
          </Input>
          { error && (
            <Message negative>
              <p>This user may have already been invited</p>
            </Message>
          )}
        </div>
        <PagedIndexGrid data={users} cols={cols} itemsPerPage={10} collapsed/>
      </ContentCard>
    )
  }
}

export default InviteUsers

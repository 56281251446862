import React from 'react';
import { Icon, Grid, GridColumn } from 'semantic-ui-react';
import { WARNING_PLATFORMS } from './constants';

const capitalize = platformName => {
  if (!platformName) return;
  const lower = platformName.toLowerCase();
  return platformName.charAt(0).toUpperCase() + lower.slice(1);
};

const LearningModeAlert = ({ platformName }) => {
  const formattedPlatformName = capitalize(platformName);
  if (WARNING_PLATFORMS.includes(platformName)) {
    return (
      <div className="updateWarning">
        <Grid>
          <GridColumn width={2} className="icon">
            <Icon size="big" name="info circle" color="purple" />
          </GridColumn>
          <GridColumn width={14}>
            <p>
              Connecting this
              {' '}
              {formattedPlatformName}
              {' '}
              account will add Rockerbox&apos;s required parameters to your
              {' '}
              {formattedPlatformName}
              {' '}
              ads. Updating ads on
              {' '}
              {formattedPlatformName}
              {' '}
              may cause your ads to be put back into Learning Mode. Ads typically move out of Learning Mode in 24-48 hours.
            </p>
            <p>
              Rockerbox cannot accurately track
              {' '}
              {formattedPlatformName}
              {' '}
              ads without our parameters.
            </p>
          </GridColumn>
        </Grid>
      </div>
    );
  }
  return null;
};
export default LearningModeAlert;

import React from 'react';

import fuzzysort from 'fuzzysort';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

let init = true;
const SearchCell = options => props => {
  const [search, setSearch] = React.useState(false);
  const { onCommit, value } = props;

  const upHandler = e => {
    if (init && e.metaKey === false && search !== false) {
      init = false;
      setSearch(e.key);
    }
  };

  React.useEffect(() => {
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  // eslint-disable-next-line no-shadow
  const searchLev = (options, query) => fuzzysort.go(query, options, { key: 'text' })
    .map(row => row.obj);

  return (
    <div style={{ fontSize: '.7em' }}>
      <Form.Dropdown
        {...{ value }}
        search
        open allowAdditions fluid
        selection
        closeOnChange={false}
        selectOnNavigation
        // eslint-disable-next-line react/jsx-no-duplicate-props
        search={searchLev}
        searchQuery={search !== false ? search : value || ''}
        searchInput={{ autoFocus: true }}
        onSearchChange={(e, o) => {
          init = false;
          setSearch(o.searchQuery);
        }}
        options={options}
        // eslint-disable-next-line no-shadow
        onChange={(e, { value }) => {
          e.preventDefault();
          if (e.type === 'keydown' && e.key !== 'Enter') return;
          console.log(value);
          onCommit(value);
          init = true;
        }}
      />
    </div>
  );
};

SearchCell.propTypes = {
  /** Object of states */
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  /** */
};

export default SearchCell;

export const loadHelpDocs = (widget_key) => {
  window.hdlh = {
    widget_key,
    launcher_button_image: 'https://images.rockerbox.com/help-widget-launcher.svg',
  };
  (function(h, d) {
    var s = d.createElement("script");
    s.type = 'text/javascript';
    s.async = true;
    s.defer = true;
    s.src = h + "?t=" + new Date().getTime();
    d.head.appendChild(s);
  })("https://lighthouse.helpdocs.io/load", document);
}

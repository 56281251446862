import { STRATEGY_OPTIONS, PLATFORM_NAMES, EXCLUDED_TIERS } from './constants';

const exclusionRegex = new RegExp(EXCLUDED_TIERS.join('|'));
const organicRegex = /organic/;

const platformRegexObj = Object.entries(PLATFORM_NAMES).reduce(
  (obj, [key, values]) => Object.assign(obj, { [key]: new RegExp(values.join('|')) }),
  {},
);
const strategyRegexObj = STRATEGY_OPTIONS.map(({ text, values }) => ({ text, regex: new RegExp(values.join('|')) }));

const eventToChannel = {};
const eventToPlatform = {};
const eventToStrategy = {};

const _marketingEventToPlatform = marketingEvent => {
  if (
    (marketingEvent.includes('direct') && !marketingEvent.includes('direct resp'))
    || marketingEvent.match(exclusionRegex)
    || marketingEvent.match(organicRegex)
  ) return null;

  const foundPlatform = Object.entries(platformRegexObj).find(platformRegex => marketingEvent.match(platformRegex[1]))?.[0];

  return foundPlatform || 'Other';
};

export const marketingEventToPlatform = marketingEvent => {
  const memoized = eventToPlatform[marketingEvent];
  if (memoized) return memoized;

  const result = _marketingEventToPlatform(marketingEvent);
  eventToPlatform[marketingEvent] = result;
  return result;
};

const _marketingEventToChannel = marketingEvent => {
  if (
    (marketingEvent.includes('direct') && !marketingEvent.includes('direct resp'))
    || marketingEvent.match(exclusionRegex)
    || marketingEvent.match(organicRegex)
  ) return null;

  if (marketingEvent.includes('influencer')) return 'Influencer';
  if (marketingEvent.includes('email')) return 'Email';
  if (marketingEvent.includes('sms')) return 'SMS';
  if (
    marketingEvent.includes('search')
    || marketingEvent.includes('pmax')
  ) return 'Search';
  if (
    marketingEvent.includes('mail')
    || marketingEvent.includes('print')
  ) return 'Print / Direct Mail';
  if (marketingEvent.includes('shopping')) return 'Shopping';
  if (marketingEvent.includes('paid social')) return 'Paid Social';
  if (marketingEvent.includes('display')) return 'Display';
  if (
    marketingEvent.includes('video')
    || marketingEvent.includes('youtube')
    || marketingEvent.includes('ott')
  ) return 'Video';
  if (
    marketingEvent.includes('radio')
    || marketingEvent.includes('audio')
  ) return 'Audio';
  if (marketingEvent.includes('podcast')) return 'Podcast';
  if (marketingEvent.includes('amazon')) return 'Amazon';
  if (marketingEvent.includes('tv')) return 'TV';
  if (marketingEvent.includes('referral')) return 'Referral';
  if (
    marketingEvent.includes('affiliate')
    || marketingEvent.includes('coupon')
    || marketingEvent.includes('promo.code')
  ) return 'Affiliate';

  return 'Other';
};

export const marketingEventToChannel = marketingEvent => {
  const memoized = eventToChannel[marketingEvent];
  if (memoized) return memoized;

  const result = _marketingEventToChannel(marketingEvent);
  eventToChannel[marketingEvent] = result;
  return result;
};

const _marketingEventToStrategy = marketingEvent => {
  if (
    (marketingEvent.includes('direct') && !marketingEvent.includes('direct resp'))
    || marketingEvent.match(exclusionRegex)
    || marketingEvent.match(organicRegex)
  ) return null;

  const foundStrategy = strategyRegexObj.find(({ regex }) => marketingEvent.match(regex))?.text;

  return foundStrategy || 'Other';
};

export const marketingEventToStrategy = marketingEvent => {
  const memoized = eventToStrategy[marketingEvent];
  if (memoized) return memoized;

  const result = _marketingEventToStrategy(marketingEvent);
  eventToStrategy[marketingEvent] = result;
  return result;
};

import React from 'react';
import { Message, Grid, Form, Button } from 'semantic-ui-react'
import { ContentCard } from '@rockerbox/styleguide'
import { getPixelCode } from '../../../utils/api.js'

import AceEditor from 'react-ace';
import 'brace/mode/text';
import 'brace/theme/iplastic';

const PixelBatchSpendSetup = ({ platform }) => {

  const DEFAULT_URL = "http://yourdomain.com?utm_source=google"

  const [creativePixels, setCreativePixels] = React.useState([])
  const [clickPixels, setClickPixels] = React.useState([])
  const [clickURL, setClickURL] = React.useState(DEFAULT_URL)

  const [clickPixelPos, setClickPixelPos] = React.useState(0)
  const [creativePixelPos, setCreativePixelPos] = React.useState(0)

  React.useEffect(() => {
    getPixelCode()
      .then(resp => {

        const pixels = resp.creative_pixels
          .filter(row => row.platform == platform) 
          .map(({ name, compiled, type, notes }, pos) => { return {text: name, value: { pos, compiled, notes }, type } })

        const creativePixels = pixels.filter(({ type }) => type == "creative")
        const clickPixels = pixels.filter(({ type }) => type == "click")

        setCreativePixels(creativePixels)
        setClickPixels(clickPixels)
      })

  }, [])

  if (clickPixels.length == 0) return null

  const separator = clickURL.includes("?") ? "&" : "?"
  const activeClickPixel = clickPixels[clickPixelPos];
  const activeCreativePixel = creativePixels.length ? creativePixels[creativePixelPos] : false;

  const { compiled, notes } = activeClickPixel.value;
  const formattedUrl = clickURL.includes("://") && clickURL.includes(".") && clickURL.split(".")[1].length > 1 ? 
    `${clickURL}${separator}${compiled}` : 
    "Please supply a valid landing page starting with http(s)://"

  return <>
    <h3 style={{fontWeight:"bold"}}>Pixel Implementations Instructions</h3>
    <Message
      content={`For ${platform}, it is important to make sure that all landing pages that are being driven to your site are appropriately tagged so that all click based traffic is appropriately tagged and all the creatives that are served on the platform have the appropriate view through tracking pixel included. Below provides instructions for both implementations.`}
    />
    <ContentCard title="Construct Landing Page URLs">
        <Form>
          <Form.Group unstackable>
            <Form.Dropdown width={3} selection options={clickPixels} value={activeClickPixel.value} />
            <Form.Input 
              width={10}
              value={clickURL} 
              onChange={(e,{value}) => setClickURL(value)} 
            />
            <Grid.Column width={3}>
              { clickURL.includes("yourdomain.com") && <Message content="⇠ Add URL to format" size='tiny' /> }
            </Grid.Column>
          </Form.Group>
          <Grid>
            <Grid.Row>
              <Grid.Column width={3}>
                <Message content={notes} />
              </Grid.Column>
              <Grid.Column width={10}>
                <div style={{padding:10,backgroundColor:"#eeeeee",borderRadius:3,border:"1px solid #ccc"}}>
                  <AceEditor mode="text" theme="iplastic" readOnly={true} fontSize={12}
                    showPrintMargin={false} showGutter={false} highlightActiveLine={false} value={formattedUrl}
                    setOptions={{
                      showLineNumbers: true,
                      highlightGutterLine: false
                    }}
                    wrapEnabled={true}
                    width={'100%'}
                    height={'65px'}
                    onLoad={(editor) => {
                      editor.renderer.setPadding(10) 
                    }
                    }
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={3}>
                { !clickURL.includes("yourdomain.com") && 
                  <Button icon="copy" primary style={{marginTop: 10}} size="tiny"
                    onClick={(event) => this.handleCopy(event, c.compiled)} 
                  />
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
    </ContentCard>
    { activeCreativePixel && <ContentCard title="Get Creative Pixels">
        <Form>
          <Grid>
            <Grid.Row>
              <Grid.Column width={3}>
                <Form.Dropdown  
                  selection options={creativePixels} value={activeCreativePixel.value} 
                  onChange={(e, {value: { pos }}) => setCreativePixelPos(pos)}
                />
                <Message content={activeCreativePixel.value.notes} />
              </Grid.Column>
              <Grid.Column width={10}>
                <div style={{padding:10,backgroundColor:"#eeeeee",borderRadius:3,border:"1px solid #ccc"}}>
                  <AceEditor mode="text" theme="iplastic" readOnly={true} fontSize={12}
                    showPrintMargin={false} showGutter={false} highlightActiveLine={false} 
                    value={activeCreativePixel.value.compiled}
                    setOptions={{
                      showLineNumbers: true,
                      highlightGutterLine: false
                    }}
                    wrapEnabled={true}
                    width={'100%'}
                    height={'65px'}
                    onLoad={(editor) => { editor.renderer.setPadding(10) } }
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={3}>
                { !clickURL.includes("yourdomain.com") && 
                  <Button icon="copy" primary style={{marginTop: 10}} size="tiny"
                    onClick={(event) => this.handleCopy(event, c.compiled)} 
                  />
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
    </ContentCard>
    }
  </>
}

export default PixelBatchSpendSetup;

import React, { Component } from 'react';
import { S3, Sftp, Email } from './parts'
import { createInbox } from '../../../utils/api';
import { Modal, Form, Dimmer, Loader } from 'semantic-ui-react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

const inboxOptions = [
  {text: "S3", value: "s3"},
  {text: "SFTP", value: "sftp"},
  {text: "Email", value: 'email'}
]

class InboxCreateModal extends Component {
  state = {
    loading: false,
    inboxVal: '',
    s3: {
      name: '',
      userArn: '',
      folder: ''
    },
    sftp: {
      name: '',
      vendor: ''
    },
    email: {
      name: ''
    }
  }

  handleInboxChange = (inboxVal) => {
    this.setState({inboxVal});
  }

  handleFormUpdate = (key, val) => {
    const { inboxVal } = this.state;
    const stateCopy = _.cloneDeep(this.state);
    stateCopy[inboxVal][key] = val

    this.setState(stateCopy)
  }

  handleSubmit = () => {
    const { inboxVal } = this.state;
    const { name, userArn, folder, vendor } = this.state[inboxVal];
    let postObj = { name };

    this.setState({ loading: true });

    if(inboxVal == 'email') {
      postObj.inbox_type = 'amazon_ses';
    } else if(inboxVal == 'sftp') {
      postObj.inbox_type = 'sftp';
      postObj.vendor = vendor;
    } else {
      // s3
      postObj.inbox_type = "amazon_s3";
      postObj.user_arn = userArn;
      postObj.folder = folder;
    }

    createInbox(postObj)
    .then(data => {
      const responseObj = data.response[0];
      const {inbox_id, } = responseObj;
      this.setState({ loading: false });

      this.props.history.push(`/v2/settings/batch/create/${inbox_id}`);
    })
  }

  render() {
    const { inboxVal, loading } = this.state;

    return (
      <Modal open={this.props.modalOpen} onClose={this.props.handleClose}>
        <Modal.Header>Create Inbox</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            { loading &&
              <div>
                <Dimmer active inverted>
                  <Loader active inline='centered' />
                </Dimmer>
              </div>
            }
            <Form onSubmit={this.handleSubmit}>
              <Form.Select options={inboxOptions} label="Inbox Type:" value={inboxVal} onChange={(e,d) => this.handleInboxChange(d.value)} clearable/>
              {inboxVal == 's3' && <S3 {...this.state} handleFormUpdate={this.handleFormUpdate}/>}
              {inboxVal == 'sftp' && <Sftp {...this.state} handleFormUpdate={this.handleFormUpdate}/>}
              {inboxVal == 'email' && <Email {...this.state} handleFormUpdate={this.handleFormUpdate}/>}
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

export default withRouter(InboxCreateModal);

import React, { useEffect, useMemo, useState } from 'react';
import { Form, Dropdown, Button, Loader } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { sharedOptions } from '../../constants/options';
import { useSavedReports } from '../../views/SavedReports/_hooks/savedReports';
import { track } from '../../../utils/tracking';

const EditSavedReport = ({ savedReport, onClose, open, refresh }) => {
  const { id, name, description, shared } = savedReport;
  const { savedReports, updateSavedReport, handleGetSavedReports } = useSavedReports();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formDefaultValues = useMemo(() => ({
    name,
    description,
    shared,
  }), [savedReport]);

  const { control, handleSubmit, formState: { errors }, setValue, trigger, reset } = useForm({
    defaultValues: formDefaultValues,
  });

  useEffect(() => {
    if (open) {
      reset(formDefaultValues);
    }
  }, [open]);

  const onSave = data => {
    setIsSubmitting(true);
    updateSavedReport(data, id)
      .then(() => {
        toast.success('Updated Saved Report');
        track('saved_report.edit', data);
        refresh();
        setIsSubmitting(false);
        handleGetSavedReports();
        onClose();
      })
      .catch(err => {
        console.log('Save Report error:', err);
        toast.error('Error updating saved report');
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSave)}>
      <Loader active={isSubmitting} />
      <h2>Edit Report</h2>
      <Form.Field disabled={isSubmitting}>
        <label>Saved Report title</label>
        <Controller
          fluid
          name="name"
          control={control}
          render={({ field }) => <Form.Input {...field} placeholder="Title" fluid error={errors.name?.message} />} // eslint-disable-line react/jsx-props-no-spreading
          rules={{
            required: 'Missing Title',
            validate: {
              titleExists: v => (
                !savedReports
                  .map(({ name: validName }) => validName)
                  .filter(x => x !== name)
                  .includes(v)
              ) || 'A Saved Report with the same title currently exists, please use a different title.',
            },
          }}
        />
      </Form.Field>
      <Form.Field disabled={isSubmitting}>
        <label>Description</label>
        <Controller
          fluid
          name="description"
          control={control}
          render={({ field }) => <Form.Input {...field} placeholder="Description" fluid error={errors.description?.message} />} // eslint-disable-line react/jsx-props-no-spreading
        />
      </Form.Field>
      <Form.Field disabled={isSubmitting}>
        <label>Who can see this report?</label>
        <Controller
          name="shared"
          control={control}
          render={({ field }) => (
            <Dropdown
              {...field} // eslint-disable-line react/jsx-props-no-spreading
              selection
              options={sharedOptions}
              onChange={(e, { name: nameChange, value }) => {
                setValue(nameChange, value);
                trigger(nameChange);
              }}
              placeholder="Select"
              fluid
              error={errors.shared?.message}
            />
          )}
          rules={{ required: 'Missing sharing privileges' }}
        />
        {errors.shared?.message && <div className="ui pointing above prompt label">{errors.shared.message}</div>}
      </Form.Field>
      <Button type="submit" primary style={{ marginRight: 10 }} disabled={isSubmitting}>Save changes</Button>
      <Button onClick={() => onClose()} color="purple" inverted type="button" disabled={isSubmitting}>Cancel</Button>
    </Form>
  );
};

export default EditSavedReport;

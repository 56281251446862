import * as Router from 'react-router-dom';
import { useState, useEffect } from 'react';

export const useKeyPress = (targetKey) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // If pressed key is our target key then set to true
  function downHandler(e) {
    const { key, preventDefault } = e
    if (key === targetKey) {
      e.preventDefault();
      e.stopImmediatePropagation();
      setKeyPressed(true);
      return false
    }
  }

  // If released key is our target key then set to false
  const upHandler = (e) => {
    const { key, preventDefault } = e
    if (key === targetKey) {
      e.preventDefault();
      e.stopImmediatePropagation();
      setKeyPressed(false);
      return false
    }
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
}

/**
 * Used to update a path with dynamic filters and values
 * @param path
 * @param current
 * @param updates
 * @returns {*}
 */
const updatePath = (path, current, updates) => {

  const pathParts = path.split("/")
  const updateObj = Object.assign(current, updates);
  const updateObjKeys = Object.keys(updateObj);

  const pathArray = pathParts.reduce((p,split,i) => {

    const keyName = split.includes(":") && split.replace(":","").replace("?","")
    const hasKey = updateObjKeys.indexOf(keyName) > -1

    if (hasKey && updateObj[keyName] !== undefined) p.push(updateObj[keyName])
    if (!keyName) p.push(split)
    //if (hasKey && updateObj[keyName] === undefined && i != pathParts.length -1) p.push("-")
    
    return p
  }, [])

  return pathArray.join("/")

}

export const useURLState = (fieldName, defaultValue) => {
  const match = Router.useRouteMatch();
  const { push } = Router.useHistory();
  const { path, params } = match;
  const value = params[fieldName]
  const setValue = (value) => push(updatePath(path, params, { [fieldName]: value }))

  return [value || defaultValue, setValue]
}


// export const useURLMatchState = (path) => (fieldName, defaultValue) => {
//   const match = Router.useRouteMatch(path);
//   const { push } = Router.useHistory();
//   const { params } = match;
//   const value = params[fieldName]
//   const setValue = (value) => push(updatePath(path, params, { [fieldName]: value }))

//   if (value === undefined && defaultValue) setValue(defaultValue || "-")

//   return [value || defaultValue, setValue]
// }


export const forceViewPath = () => {
  const match = Router.useRouteMatch();
  const { push } = Router.useHistory();
  const { path, params } = match;
  if (!path.includes("/view")) push(path + "/view")
}

export const forceNewPath = (newPath, defaultValue) => {
  const { push } = Router.useHistory();
  const value = newPath
  const setValue = (value) => push(value || defaultValue)

  return [value || defaultValue, setValue]
}

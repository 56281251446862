import React from 'react';
import { Button } from 'semantic-ui-react';
import { testTransformTableData } from '../../utils/api';
import TreeTableData from './TreeTableData';

const TransformTableTest = ({ transformTable, searchValue, forceTest = false }) => {

  const transformTableId = transformTable.id
  const [rawTestData, setRawTestData] = React.useState(null);
  const [rawTestDataLoading, setRawTestDataLoading] = React.useState(false);

  React.useEffect(() => { setRawTestDataLoading(rawTestData == null) }, [rawTestData])
  React.useEffect(() => { setRawTestData([]) }, [transformTableId])

  const handleTest = () => {
    transformTable.modified = false;
    setRawTestData(null);
    const postObj = transformTable;
    const acetlReportId = transformTable.acetl_report_id

    testTransformTableData(postObj, acetlReportId)
      .then(setRawTestData)
  }

  React.useEffect(() => {
    if (forceTest) handleTest()
  }, [forceTest])

  return <TreeTableData {...{title: "Preview Changes", transformTableId, searchValue, rawData: rawTestData, loading: rawTestDataLoading}}>
    <div style={{textAlign:"center"}}>
      { !transformTable.modified && (!rawTestData || !rawTestData.length) ?
        "Modifications to the transform table tier structure can be previewed here." : transformTable.modified ?
        <Button onClick={handleTest} content="Test Changes" /> : ""
      }
    </div>
  </TreeTableData>
}

export default TransformTableTest;

import React from 'react';
import { Image, Form, Button, Loader, Dimmer, Message } from 'semantic-ui-react'
import { getAllIntegrations } from '../../../utils/api.js'
import CacheContext from '../../../utils/CacheContext.js'
import _ from 'lodash'
import NONOAUTH_PLATFORMS from './NonOAuthPlatforms';
import GenericMessage from '../../GenericMessage';

const AuthLogo = ({ platform }) => {
  const [state, setState, Context] = React.useContext(CacheContext);
  const integrations = (Context.getCache(`integrations`, getAllIntegrations) || [])
  const integrationObject = integrations.find(row => row.platform == platform) || {};
  const { logo_img } = integrationObject;
  const src = `https://images.rockerbox.com/integrations/${logo_img}`

  return <Image centered size='medium' {...{src}} />
}

const AuthForm = ({ platform, rowId }) => {

  const [state, setState, Context] = React.useContext(CacheContext);
  const [values, setValues] = React.useState({})
  const [disabled, setDisabled] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState('')
  const objValues = Object.values(values)
  React.useEffect(() => {
    if (fields.length == objValues.filter(v => !!v).length) return setDisabled(false)
    setDisabled(true)
  }, [objValues])

  const { message, fields, postFunction, putFunction } = NONOAUTH_PLATFORMS[platform];

  const updateValue = (field) => (evt, {value}) => {
    const copy = Object.assign(_.cloneDeep(values), {[field]: value})
    setValues(copy)
  }

  const onSubmit = () => {
    setLoading(true)
    const isEditingAuth = !!rowId
    const apiFunction = isEditingAuth ? putFunction : postFunction
    const postBody = isEditingAuth ? Object.assign(_.cloneDeep(values), {id: rowId}) : values
    apiFunction(postBody).then(resp => {
      if (resp.response.success === false) {
        setLoading(false);
        throw resp.response.message
      }
      setState({"authorizations": undefined, "integrationFeatures": undefined})
    })
    .catch((e) => {
      setErrorMsg(e)
      setLoading(false)
    });
  }

  return <div style={{display:"flex", justifyContent:"center"}}>
    <Dimmer active={loading} inverted>
      <Loader inverted />
    </Dimmer>
    <div style={{width: 400, textAlign:"left"}}>
      <AuthLogo {...{platform}} />
      { message && <GenericMessage {...{message}} /> }
      <Form onSubmit={() => onSubmit()} error={errorMsg != ''}>
        <Message error header='Authorization Error' content={errorMsg} />
        {
          fields.map(f => {
            const { displayName, name, label, labelPosition } = f;
            const value = values[name];
            const action = label && { content: label, disabled };
            const onChange = updateValue(name);
            return (
              <Form.Field>
                <label>{displayName}</label>
                <Form.Input {...{value, name, action, onChange}} />
              </Form.Field>
            )})
        }
        <Button fluid color='blue' className='m-t-25' type="submit" {...{disabled}}>
          Connect Account
        </Button>
      </Form>
    </div>
  </div>
}

export default AuthForm;

import React from 'react';
import { Button, Icon, Input } from 'semantic-ui-react';

const PromoCode = ({ promoCode, isEditPromoCode, codePending, promoText, setPromoText, onSubmitPromoCode, handleClick, promoTagline, handleReset, clientSecretOptions, isEdit }) => {
  // eslint-disable-next-line react/no-unstable-nested-components
  const ClearPromoCodeButton = props => (
    <Button
      onClick={() => {
        handleReset();
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Icon name="times circle" />
    </Button>
  );

  if (codePending || isEdit) {
    return null;
  } if (isEditPromoCode && !promoCode) {
    return (
      <div className="promo-code-entry-div" style={{ width: '100%' }}>
        <p className="enter-a-promo-code">Enter referral code</p>
        <form className="promo-code-input-form">
          <Input
            onChange={e => setPromoText(e.target.value)}
            value={promoText}
            type="text"
            className="promo-code-input"
            labelPosition="left"
          >
            <input />
          </Input>
          <Button
            className="promo-code-submit-button"
            type="submit"
            size="large"
            color="purple"
            content="Apply"
            disabled={promoText === null}
            onClick={onSubmitPromoCode}
          />
        </form>
      </div>
    );
  } if (!isEditPromoCode && !promoCode) {
    if (clientSecretOptions) {
      return (
        <div>
          <button
            className="have-a-promo-code"
            onClick={handleClick}
            type="submit"
            style={{
              paddingTop: '30px',
              color: '#475ddc',
              backgroundColor: 'transparent',
              border: 'none',
              marginLeft: '-5px',
              margiTop: '2px',
              marginBottom: '15px',
            }}
          >
            Have a referral code?
          </button>
          <br />
        </div>
      );
    }
    return null;
  } if (promoCode && promoTagline) {
    return (
      <span className="promo-code-tagline" style={{ float: 'left', width: '100%', marginBottom: '10px' }}>
        {' '}
        <text style={{ color: '#0db791' }}>
          {promoTagline}
          {' '}
          with
        </text>
        {' '}
        <text style={{ color: '#475ddc' }}>{promoText}</text>
        <ClearPromoCodeButton className="reset-promo-code-button" style={{ color: '#475ddc', background: 'none', marginLeft: '-10px' }} />
      </span>
    );
  }
  return null;
};
export default PromoCode;

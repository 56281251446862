import React from "react";
import {
  AreaChart,
  ReferenceArea,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid
} from "recharts";

const OffsetAreaChart = React.memo(({ data, offset, offsetItem, areaKey }) => {
  const total = offsetItem ? offsetItem[areaKey][1] : 0;
  const AreaSelected = {
    device: <Area dataKey="device" stroke="#FF9933" fill="#FF9933" />,
    web: <Area dataKey="web" stroke="#8884d8" fill="#8884d8" />,
    mobile: <Area dataKey="mobile" stroke="#FF3333" fill="#FF3333" />
  };
  return (
    <AreaChart
      width={600}
      height={250}
      data={data}
      margin={{
        top: 30,
        right: 20,
        bottom: 20
      }}
    >
      <XAxis dataKey="offset" />
      <YAxis />
      <CartesianGrid strokeDasharray="3 3" />
      {offset && (
        <ReferenceArea
          x1={5}
          x2={offset}
          y1={0}
          y2={total}
          stroke="red"
          strokeOpacity={0.5}
        />
      )}
      {AreaSelected[areaKey]}
      <Tooltip />
    </AreaChart>
  );
});

export default OffsetAreaChart;

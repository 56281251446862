import React, { useState, useEffect } from 'react';
import { Segment, Button, Grid, Icon, Input, Form, Header, Loader } from 'semantic-ui-react';
import { CacheContext } from '../../../utils/CacheContext';
import { getAccount, getAuthorizations, getUser, putAdvertiserShopifyHosted } from '../../../V3/api/account';
import { InviteUsersWidget } from '../parts/Invite';

const ContactUsToUpgrade = ({ username, setIsShopifyHosted }) => (
  <div class='upgrade'>
    <p>
      Your current plan only works for Shopify-hosted sites.
    </p>
    <p>
      If you aren't on Shopify, we have other plans available to work with any type of backend. For more information on plans and pricing, please get in touch!
    </p>
    <Button
      fluid
      primary
      as='a'
      target='_blank'
      href={`https://www.rockerbox.com/demo?email=${username}`}
      content='Contact Us'
      icon='external'
      labelPosition='right'
    />
    <p class='shopify-auth'>Using Shopify? <a onClick={() => putAdvertiserShopifyHosted(true).then(setIsShopifyHosted(true))}>Authenticate your account!</a></p>
  </div>
)

const ShopifyConnectedStatus = (shopifyConnected, loading) => {
  if (loading) return null;
  return shopifyConnected ?
  <div style={{margin: '5px 2px 25px'}}>
    <p style={{fontWeight: '600', fontSize: '14px'}}  ><Icon circular inverted color='green' name='check' size='small' />Your Shopify store is connected</p>
  </div>
  :
  <div style={{margin: '5px 2px 25px'}}>
    <p style={{fontWeight: '600', fontSize: '14px'}}>Enter the the myshopify subdomain you use to access Shopify admin</p>
    <p>For example, if your store admin URL is <code>examplestore.myshopify.com</code>, please enter <code>examplestore</code> in the form below.</p>
  </div>
}

const Store = ({ completeStep, advertiser }) => {
  const [state, setState, Context] = React.useContext(CacheContext);
  const authorizations = Context.getCache(`authorizations`, getAuthorizations) || null;
  const account = Context.getCache(`account`, getAccount) || {};
  const user = Context.getCache(`user`, getUser) || {};

  const [loading, setLoading] = useState(true);
  const [shopifyConnected, setShopifyConnected] = useState(false);
  const [shopifyAccount, setShopifyAccount] = useState(null);
  const [shopifyStoreDomain, setShopifyStoreDomain] = useState('');
  const [isShopifyHosted, setIsShopifyHosted] = useState(false);

  useEffect(() => {
    const { is_shopify_hosted } = account;
    setIsShopifyHosted(!!is_shopify_hosted)
  }, [account]);

  useEffect(() => {
    if (authorizations === null) return;
    const connectedPlatforms = authorizations?.map(({ platform }) => platform) || [];
    const isConnected = connectedPlatforms?.includes('shopify')
    if (isConnected) {
      const acct = authorizations.find(p => p.platform == 'shopify').authorizations[0];
      setShopifyAccount(acct);
      setShopifyStoreDomain(acct.account_id);
    } else {
      setShopifyAccount(null);
      setShopifyStoreDomain('');
    }
    setShopifyConnected(isConnected)
    setLoading(false);
  }, [authorizations]);


  const authShopify = () => {
    if (!shopifyStoreDomain) return;
    const shopifyAuthUrl = `/auth/shopify?shop=${shopifyStoreDomain}.myshopify.com`;
    window.open(shopifyAuthUrl, '_self');
  };

  const { username } = user;

  return (
    <div className='onboarding-store-view fluid-height'>
      <Grid columns={1} >
        <Grid.Row>
          <Grid.Column>
            <Segment
              basic
              textAlign='center'
            >
              <Header as='h1'>
                Connect your Store
                <Header.Subheader>
                Link your Shopify store to Rockerbox to sync orders and customer data
                </Header.Subheader>
              </Header>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column></Grid.Column>
          <Grid.Column>
            {!isShopifyHosted ?
            <ContactUsToUpgrade {...{ username, setIsShopifyHosted}} />
            :
            ShopifyConnectedStatus(shopifyConnected, loading)
            }
            {isShopifyHosted ?
              loading ? <div style={{height: 250}} ><Loader active /></div>
                :
                <Form onSubmit={!shopifyConnected ? authShopify : completeStep}>
                  <Form.Field disabled={!!shopifyConnected}>
                    <Input
                      fluid
                      className={shopifyConnected ? 'connected' : ''}
                      placeholder="examplestore"
                      labelPosition="right"
                      label=".myshopify.com"
                      value={shopifyStoreDomain}
                      onChange={(e, { value }) => setShopifyStoreDomain(value)} />
                    </Form.Field>
                  <Form.Button fluid primary type="submit" disabled={!shopifyStoreDomain} content={shopifyConnected ? "Next" : "Connect Shopify store"} />
                </Form>
              :
              null
            }
            <InviteUsersWidget
              header="Don't have access to Shopify?"
              subheader='Invite someone else on your team who has access to help set it up'
              advertiser={advertiser}
            />
          </Grid.Column>
          <Grid.Column></Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Store;

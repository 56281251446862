import { useState, useEffect, useReducer } from 'react';

import { useAtom } from 'jotai';

import { buildUpdateFieldValues } from './formHelpers';
import { optimizeToBudget, optimizeExpandBudget, optimizeReduceBudget, optimizeROAS } from './optimize';
import { optimizationAtom, budgetAtom } from '../../atoms';

/**
 * Calculates the target spend based on the given optimization type, constraints, budget, and KPI type.
 *
 * @param {string} optimization - The type of optimization to be performed (e.g., 'current_budget', 'max_kpi', etc.).
 * @param {Object} constraints - The constraints for the optimization, each having 'max_value' and 'min_value'.
 * @param {number} budget - The budget value for optimization.
 * @param {boolean} isConversionKPI - Indicates if the KPI is a conversion KPI.
 * @returns {number} The calculated target spend.
 */

const getTargetSpend = (optimization, constraints, budget, isConversionKPI) => {
  const totalConstraints = Object.values(constraints).reduce((p, v) => ({
    max: p.max + v.max_value,
    min: p.min + v.min_value,
  }), { max: 0, min: 0 });

  switch (optimization) {
    case 'current_budget':
      return (totalConstraints.max + totalConstraints.min) / 2;
    case 'max_kpi':
      return parseInt(budget) || totalConstraints.max;
    case 'expand_budget':
      return parseInt(budget);
    case 'reduce_budget':
      return parseInt(budget);
    case 'target_metric':
      return isConversionKPI ? 1 / budget : parseInt(budget * 10) / 10;
    default:
  }
};

/**
 * Retrieves the appropriate optimization function based on the given optimization type.
 *
 * @param {string} optimization - The type of optimization to be performed (e.g., 'current_budget', 'max_kpi', etc.).
 * @returns {Function|null} The corresponding optimization function, or null if no match is found.
 */
const getOptimizationFunction = optimization => {
  switch (optimization) {
    case 'current_budget':
      return optimizeToBudget;
    case 'max_kpi':
      return optimizeToBudget;
    case 'expand_budget':
      return optimizeExpandBudget;
    case 'reduce_budget':
      return optimizeReduceBudget;
    case 'target_metric':
      return optimizeROAS;
    default:
      return null;
  }
};

/**
 * Calculates the optimized values based on the given parameters.
 *
 * @param {string} optimization - The type of optimization to be performed (e.g., 'current_budget', 'max_kpi', etc.).
 * @param {number} budget - The budget value for optimization.
 * @param {Object} constraints - The constraints for the optimization, each having 'max_value' and 'min_value'.
 * @param {Object} featureRecommendationsObj - The feature recommendations object.
 * @param {Object} responseCurves - The response curves object.
 * @param {boolean} isConversionKPI - Indicates if the KPI is a conversion KPI.
 * @returns {Object} The calculated optimization values.
 */
const calcOptimization = (optimization, budget, constraints, featureRecommendationsObj, responseCurves, isConversionKPI) => {
  const targetSpend = getTargetSpend(optimization, constraints, budget, isConversionKPI);
  const optimizationFunction = getOptimizationFunction(optimization);
  return optimizationFunction(targetSpend, constraints, featureRecommendationsObj, responseCurves);
};

export const useOptimization = ({ baseline, responseCurves, constraints, isConversionKPI }) => {
  const [budget] = useAtom(budgetAtom);
  const [optimization] = useAtom(optimizationAtom);
  const [fieldValues, _setFieldValues] = useReducer((state, newState) => ({ ...state, ...newState }), {});
  const [isDirty, setIsDirty] = useState(true);

  // methods to set the field values and update optimization
  const setFieldValues = values => values && _setFieldValues(values);
  const submitForecast = () => setIsDirty(false);
  const updateFieldValues = buildUpdateFieldValues(fieldValues, setFieldValues);

  useEffect(() => {
    setIsDirty(true);
  }, [baseline, constraints, budget, optimization]);

  useEffect(() => {
    if (isDirty || !optimization) return;
    const values = calcOptimization(optimization, budget, constraints, baseline, responseCurves, isConversionKPI);
    setFieldValues(values);
  }, [optimization, budget, constraints, baseline, responseCurves, isConversionKPI, isDirty]);

  return {
    budget,
    optimization,
    isDirty, setIsDirty,
    fieldValues,
    updateFieldValues,
    setFieldValues,
    submitForecast,
  };
};

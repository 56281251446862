import React, { useState } from 'react';
import { Menu } from 'semantic-ui-react';
import { getSurveyConfigs } from '../../../api/attribution';
import { StatusTable, ImpactTable } from '../../../components';
import ViewAll from './ViewAll';

const getSegmentIdFromSurvey = () => getSurveyConfigs()
  .then(impactData => ((impactData.length > 0) ? impactData[0].conversion_id : false));

const Steps = () => {
  const steps = ['Setup', 'Status', 'Impact'];
  const [activeStep, setActiveStep] = useState(steps[0]);

  return (
    <>
      <Menu pointing secondary widths={3}>
        {steps.map(value => (
          <Menu.Item
            name={value}
            active={activeStep === value}
            onClick={() => setActiveStep(value)}
          />
        ))}
      </Menu>
      {activeStep === 'Setup' && <ViewAll />}
      {activeStep === 'Status' && (
        <StatusTable
          getSegmentId={getSegmentIdFromSurvey}
          datasets={[
            { dataset: 'survey_difference', display: 'Has Survey Data?' },
          ]}
          funcs={[
            { text: 'Backfill', display: '', url: '/run/agg/survey' },
          ]}
        />
      )}
      {activeStep === 'Impact' && (
        <ImpactTable
          title="Survey"
          datasets={['survey_difference']}
          getSegmentId={getSegmentIdFromSurvey}
        />
      )}
    </>
  );
};

export default Steps;

import React, { Component } from "react";
import moment from "moment";
import { IndexGrid, ContentCard, SortablePagedIndexGrid } from "@rockerbox/styleguide";
import { deleteSavedReport } from "./api";
import { Table, Button } from "semantic-ui-react";
import SendSavedReportModal from "./SendSavedReportModal";
import NewSavedReportModal from "./NewSavedReportModal";
import { sendSavedReport, getReportingTypes, createSavedreport } from "./api";
import { track } from "../../utils/tracking";
import * as routes from "../../routes";

class SavedReportIndex extends Component {
  state = {
    showModal: false,
    loading: false,
    error: false,
    reportToSend: {},
    startDate: moment.utc().utcOffset(-5).subtract(1, "days"),
    endDate: moment.utc().utcOffset(-5).subtract(1, "days"),
    email: "",
    file_type: "",
    focusedInput: null,
    successPage: false,
    report_type_id: undefined,
    filter_id: undefined,
    report_name: "",
    outboxId: undefined,
    savedReportId: undefined,
    rootFolder: "",
    contact: "",
    showNewReportModal: false,
    showNewScheduledReportModal: false,
  };

  deleteReport = (report) => {
    deleteSavedReport(report).then((d) => {
      this.props.setSavedReports();
      this.props.setScheduledReports();
    });
  };

  onFieldChange = (field) => (evt, data) => {
    this.setState({
      [field]: data.value,
    });
  };

  onSendButtonClick = (item) => {
    this.setState({
      reportToSend: item,
      showModal: true,
    });
  };

  onModalClose = () => {
    this.setState({
      showNewReportModal: false,
      showModal: false,
      reportToSend: {},
      successPage: false,
      email: "",
      file_type: "",
      error: false,
      focusedInput: null,
      loading: false,
      outboxId: undefined,
      savedReportId: undefined,
      rootFolder: "",
      contact: "",
      showNewScheduledReportModal: false,
    });
  };

  onDatesChange = ({ startDate, endDate }) => {
    const newState = {
      startDate: startDate || this.state.startDate,
      endDate: endDate || this.state.endDate,
    };
    this.setState(newState);
  };

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
  };

  handleOutsideRange = (day) => {
    const { startDate, endDate } = this.state;
    const maxDays = 60;
    const yesterday = moment.utc().utcOffset(-5).subtract(1, "days").endOf("day");

    // necessary for the state values not to get all messed up
    const endDateCopy = moment(endDate.format());
    const startDateCopy = moment(startDate.format());

    const beforeMin = day.isBefore(endDateCopy.subtract(maxDays, "days"));
    const afterMax = day.isAfter(yesterday);
    const afterSixty = day.isAfter(startDateCopy.add(maxDays, "days"));

    return beforeMin || afterMax || afterSixty;
  };

  onFormSubmit = () => {
    const { startDate, endDate, reportToSend, email, file_type } = this.state;
    const { outboxes } = this.props;
    const spaceOutbox = outboxes.filter(
      (outbox) => outbox.outbox_type === "do_spaces"
    )[0];

    const parseDate = (str) => moment(str).format("YYYY-MM-DD");

    const requestBody = {
      email: email,
      file_type: file_type,
      outbox_id: spaceOutbox ? spaceOutbox.id : null,
      saved_report_id: reportToSend["id"],
      start_date: parseDate(startDate),
      end_date: parseDate(endDate),
    };

    this.setState({ loading: true, error: false });
    return sendSavedReport(requestBody)
      .then(() => {
        this.setState({
          reportToSend: {},
          loading: false,
          successPage: true,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          error: true,
        });
      });
  };

  onCreateNewSavedReport() {
    const { report_type_id, filter_id, report_name } = this.state;
    const { setSavedReports } = this.props;
    this.setState({ loading: true, error: false });
    const requestBody = {
      report_type_id,
      filter_id,
      name: report_name,
      num_days: 1,
      filters: {},
    };

    track("create-saved-report", requestBody);
    createSavedreport(requestBody)
      .then((data) => {
        setSavedReports(data);
        const report =
          data.filter((report) => report.name === report_name)[0] || {};
        this.setState({
          loading: false,
          error: false,
          successPage: true,
          report_type_id: undefined,
          filter_id: undefined,
          report_name: "",
          savedReportId: report.id || undefined,
        });
      })
      .then(() => {})
      .catch((err) => this.setState({ loading: false, error: true }));
  }

  showEditForAdmin(stopPropagation, item) {
    const scheduledReport = this.props.scheduledReports.filter(
      (report) => report.saved_report_id === item.id
    );
    const { isRBUser } = this.props;
    const shouldShowEdit = isRBUser();
    return shouldShowEdit ? (
      <React.Fragment>
        <IndexGrid.EditButton
          popupContent={
            scheduledReport.length === 1
              ? "A scheduled report is attached with this saved report. Please be careful while making update."
              : "Edit"
          }
          onClick={stopPropagation}
          url={`${routes.reports}/edit/id/${item.id}`}
        />
        <IndexGrid.TransformButton
          popupContent={
            scheduledReport.length === 1
              ? "A scheduled report is attached with this saved report. Please be careful while making update."
              : "Transform"
          }
          onClick={stopPropagation}
          url={`${routes.reports}/edit/interactive/id/${item.id}`}
        />
      </React.Fragment>
    ) : null;
  }

  onOutboxIdChange() {
    return (evt, data) => {
      const id = data.value;
      if (!id) {
        return this.setState({ outboxId: id });
      }
      const { outboxes } = this.props;
      const target = outboxes.filter((outbox) => outbox["id"] === id)[0];
      if (!target) {
        return this.setState({ outboxId: id });
      }
      const root_directory =
        (target["config"] && target["config"]["root_directory"]) ||
        "from-rockerbox";

      this.setState({
        outboxId: id,
        rootFolder: root_directory,
      });
    };
  }

  render() {
    const {
      segments,
      savedReports,
      scheduledReports,
      isRBUser,
      reportTypes,
      outboxes,
      setScheduledReports,
      loading: dataLoading,
    } = this.props;

    const { showModal, showNewReportModal } = this.state;
    const stopPropagation = (e) => e.stopPropagation();
    const ManageCell = ({ item, col }) => {
      const scheduledReport = scheduledReports.filter(
        (report) => report.saved_report_id === item.id
      );

      return (
        <Table.Cell textAlign="center" collapsing>
          <IndexGrid.SendButton
            item={item}
            send={this.onSendButtonClick.bind(this)}
          />
          {this.showEditForAdmin(stopPropagation, item)}
          <IndexGrid.CustomizeButton onClick={stopPropagation} itemId={item.id} />
          <IndexGrid.DeleteButton
            onDelete={() => this.deleteReport(item.id)}
            disabled={scheduledReport.length === 1}
            popupContent={
              scheduledReport.length === 1
                ? "A scheduled report is currently associated with this saved report. You must remove the scheduled report before you can delete this saved report."
                : null
            }
          />
        </Table.Cell>
      );
    };

    const reportCols = [
      { display: "Name", key: "name" },
      { display: "Report Type", key: "display_name", sortable: false },
      {
        display: "Segment",
        key: "filter_id",
        displayValue: "action_name",
        as: IndexGrid.TextOnly,
        sortable: false,
      },
      {
        display: "Conversion Name",
        key: "conversion_name",
        as: IndexGrid.TextOnly,
        sortable: false,
      },
      {
        display: "Bucket 1 Filter",
        key: "filters",
        displayValue: "tier_1",
        as: IndexGrid.TextOnly,
        sortable: false,
      },
      {
        display: "Bucket 2 Filter",
        key: "filters",
        displayValue: "tier_2",
        as: IndexGrid.TextOnly,
        sortable: false,
      },
      {
        display: "Bucket 3 Filter",
        key: "filters",
        displayValue: "tier_3",
        as: IndexGrid.TextOnly,
        sortable: false,
      },
      {
        display: "Bucket 4 Filter",
        key: "filters",
        displayValue: "tier_4",
        as: IndexGrid.TextOnly,
        sortable: false,
      },
      {
        display: "Bucket 5 Filter",
        key: "filters",
        displayValue: "tier_5",
        as: IndexGrid.TextOnly,
        sortable: false,
      },
      {
        display: "Manage",
        key: "manage_actions",
        as: ManageCell,
        headerStyles: { textAlign: "center" },
        sortable: false,
      },
    ];

    return (
      <React.Fragment>
        <Button
          onClick={() => {
            this.setState({ showNewReportModal: true });
            getReportingTypes().then((data) => {
              this.setState({ reportTypes: data });
            });
          }}
          primary
          loading={dataLoading}>
          Create New Saved Report
        </Button>
        <ContentCard title="Saved Reports" hasTable loading={dataLoading}>
          <SortablePagedIndexGrid
            onRowClick={this.props.updateReportId}
            as={ContentCard.Table}
            itemsPerPage={10}
            fallBackMsg="No reports currently saved"
            cols={reportCols}
            data={savedReports}
          />
        </ContentCard>
        <SendSavedReportModal
          open={showModal}
          {...this.state}
          close={this.onModalClose.bind(this)}
          title={"Send Report"}
          onFieldChange={this.onFieldChange.bind(this)}
          onDatesChange={this.onDatesChange.bind(this)}
          onFocusChange={this.onFocusChange.bind(this)}
          handleOutsideRange={this.handleOutsideRange.bind(this)}
          onFormSubmit={this.onFormSubmit.bind(this)}
        />
        <NewSavedReportModal
          open={showNewReportModal}
          {...this.state}
          close={this.onModalClose.bind(this)}
          title={"New Saved Report"}
          onFieldChange={this.onFieldChange.bind(this)}
          onFormSubmit={this.onFormSubmit.bind(this)}
          segments={segments}
          onCreateNewSavedReport={this.onCreateNewSavedReport.bind(this)}
          outboxes={outboxes}
          savedReports={savedReports}
          onOutboxIdChange={this.onOutboxIdChange.bind(this)}
          setScheduledReports={setScheduledReports}
          isRBUser={isRBUser}
        />
      </React.Fragment>
    );
  }
}

export default SavedReportIndex;

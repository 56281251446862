import React from 'react';
import { Input, Icon, Dropdown, Segment } from 'semantic-ui-react';

const addValueToOptionsIfMissing = (value, options) => {
  const obj = options.find(o => o.key === value)
  if (obj == undefined) {
      options.push({ key: value, text: value, value: value })
  }
  return options
}

export const EventSelectionRow = ({ tiersOptions, eventRow, onUpdate, onRemove, WrapperComponent = false, readOnly = false}) => {
  const tiers = ["tier_1", "tier_2", "tier_3", "tier_4","tier_5"]

  const tiersVisible = React.useMemo(() => {
    const filledTier = tiers.reduce((p,c) => eventRow[c] && eventRow[c].length ? c : p, "")
    const index = tiers.indexOf(filledTier) + 1
    return (index < tiers.length) ? tiers[index] : "tier_5"

  }, [...Object.values(eventRow)])

  const selectedTiers = React.useMemo(() => {
    const index = tiers.indexOf(tiersVisible)
    return tiers.slice(0, index+1)
  }, [tiersVisible])

  const Wrapper = WrapperComponent || ((props) => <Segment style={{fontSize: ".95rem"}} {...props} />)

  const optionsByTier = {
    tier_1: tiersOptions
  }

  const updateTier = (row, tier) => (evt, { value }) => {
    const index = tiers.indexOf(tier)
    const clearUpdates = tiers.slice(index, tiers.length)
      .reduce((p, c) => {
        p[c] = ""
        return p
      }, {})

    onUpdate(Object.assign({}, row, clearUpdates, {[tier]: value}))
  }

  return <Wrapper secondary>
    { !readOnly && <Icon name='remove' onClick={() => onRemove(eventRow)} floated="right" /> }
    {
      selectedTiers.map((tier, i) => {
        const nextTier = selectedTiers[i+1]
        const value = eventRow[tier]
        let options = optionsByTier[tier] || []
        if (value) {
          const nextOptions = (options.find(row => row.value == value) || {}).values || []
          optionsByTier[nextTier] = nextOptions.filter(({ value }) => value.trim().length > 0)
        }

        const hasValue = !!value
        const hasOptions = options.length > 0

        //check if the value is present in options. If not, we'll append it to options so the dropdown renders properly
        options = addValueToOptionsIfMissing(value, options)
        
        return hasOptions && !readOnly ? <Dropdown placeholder={tier} selection {...{options, value}} onChange={updateTier(eventRow, tier)} /> : 
        readOnly && hasValue ? <div style={{display:"inline-block"}}> 
            { i > 0 && <div style={{display:"inline-block",padding:"10px"}}><Icon name="right arrow" /></div> }
            <div style={{display:"inline-block"}}>
              <b>Tier { i+1 }</b><br />
              <Input disabled {...{value}} />
            </div>
          </div> : null
      })
    }
    { readOnly && <div style={{float:"right",marginTop:"15px"}}>
        <Icon name='remove' onClick={() => onRemove(eventRow)} style={{cursor:"pointer"}}/> 
      </div>
    }
  </Wrapper>

}

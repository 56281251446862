export const loadVitally = (token) => {
  (function(h, d) {
    var s = d.createElement("script");
    s.type = 'text/javascript';
    s.defer = true;
    s.src = h;
    d.head.appendChild(s);
  })("https://cdn.vitally.io/vitally.js/v1/vitally.js", document);
  !function(n, t, r) {
    for (var i = n[t] = n[t] || [], o = function(r) {
        i[r] = i[r] || function() {
          for (var n = [], t = 0; t < arguments.length; t++) n[t] = arguments[t];
          return i.push([r, n])
        }
      }, u = 0, c = ["init", "user", "account", "track", "nps"]; u < c.length; u++) {
      o(c[u])
    }
  }(window, "Vitally");
  window.Vitally.init(token);
}

import React, { useMemo } from 'react';

import { SummaryRow } from '@rockerbox/styleguide/components/layouts/IndexGridNew/parts';
import { Table } from 'semantic-ui-react';

/* eslint-disable react/jsx-props-no-spreading */

const defaultFormatter = x => x;

const DefaultCell = ({ item, col }) => {
  const formatter = col.formatter || defaultFormatter;
  const { cellStyle } = col;
  return (
    <Table.Cell width={item[col.cellWidth]} className={`data-${col?.key}`} style={{ ...cellStyle }}>
      <span>{formatter(item[col.key])}</span>
    </Table.Cell>
  );
};

const writeCell = (item, col, index, otherProps) => {
  const CellComponent = col.as || DefaultCell;

  return (
    <CellComponent {...{ col, item, ...otherProps }} key={index} />
  );
};

const GridRow = props => {
  const { item, cols } = props;
  return (
    <Table.Row>
      {cols.map((col, index) => writeCell(item, col, index, { ...props }))}
    </Table.Row>
  );
};

/**
 * Renders a structured grid component with a structured header.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.data - The data to be displayed in the grid.
 * @param {Array} props.cols - The columns configuration for the grid.
 * @param {string} props.cols[].id - The unique identifier for the column.
 * @param {string} props.cols[].display - The display name for the column.
 * @param {string} props.cols[].key - The key to access the corresponding data in each item.
 * @param {string} props.cols[].textAlign - The text alignment for the column.
 * @param {number} props.cols[].colSpan - The number of columns the cell should span horizontally.
 * @param {string} props.cols[].primaryColumn - The key of the primary column that the cell belongs to.
 * @param {number} props.cols[].primaryRowSpan- The number of rows the cell should span vertically.
 * @param {Object} props.cols[].cellStyle - The style object for the cell.
 * @param {Function} props.cols[].formatter - The function to format the cell value.
 * @param {React.Component} props.cols[].as - The custom component to render for the cell.
 * @param {Function} props.cols[].headerAs - The custom component to render for the header cell.
 * @param {Object} props.cols[].headerStyle - The style object for the header cell.
 * @param {boolean} props.summaryRow - Whether to display a summary row at the end of the grid.
 *
 * @returns {React.Component} The rendered structured grid component.
 */
const StructuredGrid = props => {
  const { data, cols, summaryRow } = props;

  const { primaryCols, colSpanCols, uniqueColSpan } = useMemo(() => {
    const allRowSpan = cols.filter(col => 'rowSpan' in col);
    const allColSpan = cols.filter(col => 'colSpan' in col);
    const _colSpanCols = allColSpan.filter(col => !('primaryRowSpan' in col));

    const seen = new Set();
    const uniqueColSpans = allColSpan.filter(item => {
      if (!seen.has(item.primaryColumn)) {
        seen.add(item.primaryColumn);
        return true;
      }
      return false;
    });

    return {
      primaryCols: allRowSpan,
      colSpanCols: _colSpanCols,
      uniqueColSpan: uniqueColSpans,
    };
  }, [cols]);

  return (
    <Table className="index-grid-structured" celled structured>
      <Table.Header>
        <Table.Row>
          {primaryCols.map((col, index) => (col.headerAs ? col.headerAs(col) : <Table.HeaderCell style={col.headerStyle} key={index} rowSpan={col.rowSpan}>{col.primaryColumn}</Table.HeaderCell>))}
          {uniqueColSpan.map((col, index) => (
            col.headerAs ? col.headerAs(col) : <Table.HeaderCell style={col.headerStyle} key={index} colSpan={col.colSpan} rowSpan={col.primaryRowSpan}>{col.primaryColumn}</Table.HeaderCell>))}
        </Table.Row>
        <Table.Row>
          {colSpanCols.map((col, index) => (col.headerAs ? col.headerAs(col) : <Table.HeaderCell style={col.headerStyle} key={index}>{col.display}</Table.HeaderCell>))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map((item, index) => <GridRow item={item} cols={cols} key={index} {...props} />)}
      </Table.Body>
      {summaryRow && (
      <SummaryRow
        cols={cols}
        data={data}
      />
      )}

    </Table>
  );
};

export default StructuredGrid;

import React, { Component } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { ContentCard, IndexGrid } from '@rockerbox/styleguide'
import { withRouter, Link } from 'react-router-dom';
import * as routes from '../../routes';

import { getFacebookSyntheticConfigs, getFacebookAdAccounts } from '../../utils/api';


const SynthManageCell = ({item, col}) => {
  return (
    <Table.Cell collapsing>
      <IndexGrid.EditButton url={`${routes.dataSources}/view/fb_data/synthetic/edit/${item.id}`}/>
      <IndexGrid.ViewButton url={`${routes.dataSources}/view/fb_data/synthetic/view/${item.id}`}/>
    </Table.Cell>
  )
}

const AdsManageCell = ({item, col}) => {
  return (
    <Table.Cell collapsing>
      <IndexGrid.EditButton url={`${routes.dataSources}/view/fb_data/ads_data/edit/${item.id}`}/>
      <IndexGrid.ViewButton url={`${routes.integrationsIndex}/facebook/auto update`}/>
    </Table.Cell>
  )
}


class Facebook extends Component {

  state = {
    syntheticEvents: [],
    eventsLoading: false,
    adAccounts: [],
    accountsLoading: false
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    this.setState({ eventsLoading: true, accountsLoading: true });
    getFacebookSyntheticConfigs().then(resp => this.setState({ syntheticEvents: resp, eventsLoading: false }))
    getFacebookAdAccounts().then(resp => this.setState({ adAccounts: resp, accountsLoading: false }))
  }

  refresh = () => {
    this.setState({ syntheticEvents: [], adAccounts: [] })
    this.getData()
  }

  render() {
    const { syntheticEvents, eventsLoading, adAccounts, accountsLoading } = this.state;
    const { type, id } = this.props.match.params

    const synthCols = [
      {display: 'Name', key: 'segment_name' },
      {display: 'Manage', as: SynthManageCell}
    ]

    const adsCols = [
      {display: 'Name', key: 'account_name' },
      {display: 'ID', key: 'account_id' },
      {display: 'Manage', as: AdsManageCell}
    ]
    

    return (
      <React.Fragment>
        <SyntheticIndex data={syntheticEvents} cols={synthCols} loading={eventsLoading} />
        <AdsIndex data={adAccounts} cols={adsCols} loading={accountsLoading} />
      </React.Fragment>
    )
  }
}

const SyntheticIndex = ({data, cols, on, loading}) => (
  <ContentCard
    title="Synthetic Events"
    hasTable
    topRight={
      <Button
        primary compact size="mini" icon="plus" content={'New Config'} as={Link}
        to={`${routes.dataSources}/view/fb_data/synthetic/create`}
      />
    }
  >
    { loading ? <ContentCard.CardLoader /> : <IndexGrid {...{data, cols}} /> }
  </ContentCard>
)


const AdsIndex = ({data, cols, on, loading}) => (
  <ContentCard
    title="Ads Data"
    hasTable
    topRight={
      <Button
        primary compact size="mini" icon="plus" content={'New Account'} as={Link}
        to={`${routes.dataSources}/view/fb_data/ads_data/create`}
      />
    }
  >
    { loading ? <ContentCard.CardLoader /> : <IndexGrid {...{data, cols}} /> }
  </ContentCard>
)

export default withRouter(Facebook);

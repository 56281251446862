import React from "react";
import { BarChart, XAxis, YAxis, Tooltip, Legend, Bar } from "recharts";

const TimeSeriesChart = props => {
  const { data } = props;
  return (
    <BarChart width={730} height={250} data={data}>
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="num_rows" fill="#8884d8" />
    </BarChart>
  );
};

export default TimeSeriesChart;

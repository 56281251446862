import React from 'react';

import { Header } from 'semantic-ui-react';

import { InviteUsers, ViewAccessWrapper } from '../../components';

const ManageUsers = () => (
  <>
    <Header
      as="h1"
      content="Manage Team Users"
    />
    <InviteUsers hideTitle showFooter />
  </>
);

const ManageUsersWrapper = () => (
  <ViewAccessWrapper
    viewName="settings_team_users"
    viewComponent={<ManageUsers />}
  />
);

export default ManageUsersWrapper;

import React, { Component } from 'react';

import { Checkbox, Table } from 'semantic-ui-react';

import { IndexGrid, ContentCard } from '../../..';

const ColumnCheckbox = (selectedColumns, updateSelectedColumns) => ({ item }) => (
  <Table.Cell collapsing>
    <span>
      <Checkbox
        checked={
          selectedColumns && selectedColumns.includes(item.manage.name)
        }
        onChange={() => updateSelectedColumns(item.manage.name)}
      />
    </span>
  </Table.Cell>
);

const ColumnSelectAll = (defaultSchema, selectAllOrEmpty, { length } = []) => (
  <Checkbox
    checked={length === Object.keys(defaultSchema).length}
    onChange={() => selectAllOrEmpty()}
  />
);

class ReportSchemaTable extends Component {
  render() {
    const {
      schema,
      reportKey,
      selectedColumns,
      updateSelectedColumns,
      defaultSchema,
      selectAllOrEmpty,
    } = this.props;

    const tableCols = defaultSchema
      ? [
        { display: ColumnSelectAll(defaultSchema, selectAllOrEmpty, selectedColumns), key: 'manage', as: ColumnCheckbox(selectedColumns, updateSelectedColumns) },
        { display: 'Column Number', key: 'column_number' },
        { display: 'Column Name', key: 'name' },
        { display: 'Data Type', key: 'type' },
        { display: 'Data Format', key: 'format' },
        { display: 'Description', key: 'description' },
      ]
      : [
        { display: 'Column Number', key: 'column_number' },
        { display: 'Column Name', key: 'name' },
        { display: 'Data Type', key: 'type' },
        { display: 'Data Format', key: 'format' },
        { display: 'Description', key: 'description' },
      ];

    const reportName = reportKey || Object.keys(schema.definitions)[0];

    const reportSchema = schema.definitions[reportName].properties;

    const reportCols = Object.keys(reportSchema);

    const buildFormat = col_schema => {
      const { format } = col_schema;
      const { pattern } = col_schema;
      if (!format) return '';
      if (!pattern) return format;
      return `${format} (${pattern})`;
    };

    const transformed = reportCols
      .map(col => Object({
        name: col,
        column_number: reportSchema[col].column_number,
        type: reportSchema[col].type,
        format: buildFormat(reportSchema[col]),
        description: reportSchema[col].description,
        manage: {
          checked: selectedColumns && selectedColumns.includes(col),
          name: col,
        },
      }))
      .sort((a, b) => (a.column_number > b.column_number ? 0 : -1));

    return (
      <div>
        {schema && (
          <IndexGrid
            as={ContentCard.Table}
            fallBackMsg="No reports currently saved"
            cols={tableCols}
            data={transformed}
          />
        )}
      </div>
    );
  }
}

export default ReportSchemaTable;

/* eslint-disable react/no-unknown-property */
/* eslint-disable max-len */
import React from 'react';

export const ChartTableActive = () => (
  <svg width="60px" height="38px" viewBox="0 0 60 38" version="1.1">
    <title>graph and table</title>
    <g id="Version-A" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="B-01.-Channel-Performance---Platform-Detail" transform="translate(-584, -288)">
        <g id="layout-controls" transform="translate(19, 288)">
          <g id="title" transform="translate(555, 0)">
            <g id="graph-and-table" transform="translate(10, 0)">
              <rect id="Rectangle" stroke="#475DDC" fill="#F0F3FF" x="0.5" y="0.5" width="59" height="37" rx="4" />
              <rect id="Rectangle" fill="#7283E4" x="5" y="20" width="50" height="3" rx="1.5" />
              <rect id="Rectangle" fill="#7283E4" x="5" y="25" width="50" height="3" rx="1.5" />
              <rect id="Rectangle" fill="#7283E4" x="5" y="30" width="50" height="3" rx="1.5" />
              <path
                d="M5.19613573,14.798376 C6.33192573,14.798376 6.33192573,14.3320417 7.46771574,14.3320417 C8.60350574,14.3320417 8.60350574,14.2109097 9.73929575,14.2109097 C10.8750858,14.2109097 10.8750858,8.01144374 12.0108758,8.01144374 C13.1466658,8.01144374 13.1466658,14.3320417 14.2824558,14.3320417 C15.4182458,14.3320417 15.4182458,14.9193504 16.5540358,14.9193504 C17.6898258,14.9193504 17.6898258,14.798376 18.8256158,14.798376 C19.9614058,14.798376 19.9614058,14.4991691 21.0971958,14.4991691 C22.2329858,14.4991691 22.2329858,14.798376 23.3687758,14.798376 C24.5045658,14.798376 24.5045658,15 25.6403558,15 C26.7761458,15 26.7761458,12.0873205 27.9119358,12.0873205 C29.0477258,12.0873205 29.0477258,15 30.1835158,15 C31.3193058,15 31.3193058,14.9193504 32.4550958,14.9193504 C33.5908859,14.9193504 33.5908859,14.798376 34.7266759,14.798376 C35.8624659,14.798376 35.8624659,13.925092 36.9982559,13.925092 C38.1340459,13.925092 38.1340459,14.4991691 39.2698359,14.4991691 C40.4056259,14.4991691 40.4056259,13.3691295 41.5414159,13.3691295 C42.6772059,13.3691295 42.6772059,14.4757776 43.8129959,14.4757776 C44.9487859,14.4757776 44.9141899,14.7889616 46.0845759,14.798376 C47.1857699,14.8072338 47.2203659,5 48.3561559,5 C49.4919459,5 49.4919459,12.7695343 50.6277359,12.7695343 C51.7635259,12.7695343 51.7572084,14.2109097 52.8993159,14.2109097 C53.660721,14.2109097 54.4263276,14.4067318 55.1961357,14.798376"
                id="Line"
                stroke="#7283E4"
                stroke-width="2"
                stroke-linecap="square"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ChartTableInactive = () => (
  <svg width="60px" height="38px" viewBox="0 0 60 38" version="1.1">
    <title>graph and table</title>
    <g id="Version-A" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Prototype---Table-on-Top" transform="translate(-584, -288)">
        <g id="layout-controls" transform="translate(19, 288)">
          <g id="title" transform="translate(555, 0)">
            <g id="graph-and-table" transform="translate(10, 0)">
              <rect id="Rectangle" stroke="#BFC6F3" fill="#F0F3FF" x="0.5" y="0.5" width="59" height="37" rx="4" />
              <rect id="Rectangle" fill="#BFC6F3" x="5" y="20" width="50" height="3" rx="1.5" />
              <rect id="Rectangle" fill="#BFC6F3" x="5" y="25" width="50" height="3" rx="1.5" />
              <rect id="Rectangle" fill="#BFC6F3" x="5" y="30" width="50" height="3" rx="1.5" />
              <path
                d="M5.19613573,14.798376 C6.33192573,14.798376 6.33192573,14.3320417 7.46771574,14.3320417 C8.60350574,14.3320417 8.60350574,14.2109097 9.73929575,14.2109097 C10.8750858,14.2109097 10.8750858,8.01144374 12.0108758,8.01144374 C13.1466658,8.01144374 13.1466658,14.3320417 14.2824558,14.3320417 C15.4182458,14.3320417 15.4182458,14.9193504 16.5540358,14.9193504 C17.6898258,14.9193504 17.6898258,14.798376 18.8256158,14.798376 C19.9614058,14.798376 19.9614058,14.4991691 21.0971958,14.4991691 C22.2329858,14.4991691 22.2329858,14.798376 23.3687758,14.798376 C24.5045658,14.798376 24.5045658,15 25.6403558,15 C26.7761458,15 26.7761458,12.0873205 27.9119358,12.0873205 C29.0477258,12.0873205 29.0477258,15 30.1835158,15 C31.3193058,15 31.3193058,14.9193504 32.4550958,14.9193504 C33.5908859,14.9193504 33.5908859,14.798376 34.7266759,14.798376 C35.8624659,14.798376 35.8624659,13.925092 36.9982559,13.925092 C38.1340459,13.925092 38.1340459,14.4991691 39.2698359,14.4991691 C40.4056259,14.4991691 40.4056259,13.3691295 41.5414159,13.3691295 C42.6772059,13.3691295 42.6772059,14.4757776 43.8129959,14.4757776 C44.9487859,14.4757776 44.9141899,14.7889616 46.0845759,14.798376 C47.1857699,14.8072338 47.2203659,5 48.3561559,5 C49.4919459,5 49.4919459,12.7695343 50.6277359,12.7695343 C51.7635259,12.7695343 51.7572084,14.2109097 52.8993159,14.2109097 C53.660721,14.2109097 54.4263276,14.4067318 55.1961357,14.798376"
                id="Line"
                stroke="#BFC6F3"
                stroke-width="2"
                stroke-linecap="square"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const TableChartActive = () => (
  <svg width="60px" height="38px" viewBox="0 0 60 38" version="1.1">
    <title>table and graph</title>
    <g id="Version-A" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Prototype---Table-on-Top" transform="translate(-654, -288)">
        <g id="layout-controls" transform="translate(19, 288)">
          <g id="title" transform="translate(555, 0)">
            <g id="Group-6" transform="translate(10, 0)">
              <g id="table-and-graph" transform="translate(70, 0)">
                <rect id="Rectangle" stroke="#475DDC" fill="#F0F3FF" x="0.5" y="0.5" width="59" height="37" rx="4" />
                <rect id="Rectangle" fill="#7283E4" x="5" y="5" width="50" height="3" rx="1.5" />
                <rect id="Rectangle" fill="#7283E4" x="5" y="10" width="50" height="3" rx="1.5" />
                <rect id="Rectangle" fill="#7283E4" x="5" y="15" width="50" height="3" rx="1.5" />
                <path
                  d="M5.19613573,32.798376 C6.33192573,32.798376 6.33192573,32.3320417 7.46771574,32.3320417 C8.60350574,32.3320417 8.60350574,32.2109097 9.73929575,32.2109097 C10.8750858,32.2109097 10.8750858,26.0114437 12.0108758,26.0114437 C13.1466658,26.0114437 13.1466658,32.3320417 14.2824558,32.3320417 C15.4182458,32.3320417 15.4182458,32.9193504 16.5540358,32.9193504 C17.6898258,32.9193504 17.6898258,32.798376 18.8256158,32.798376 C19.9614058,32.798376 19.9614058,32.4991691 21.0971958,32.4991691 C22.2329858,32.4991691 22.2329858,32.798376 23.3687758,32.798376 C24.5045658,32.798376 24.5045658,33 25.6403558,33 C26.7761458,33 26.7761458,30.0873205 27.9119358,30.0873205 C29.0477258,30.0873205 29.0477258,33 30.1835158,33 C31.3193058,33 31.3193058,32.9193504 32.4550958,32.9193504 C33.5908859,32.9193504 33.5908859,32.798376 34.7266759,32.798376 C35.8624659,32.798376 35.8624659,31.925092 36.9982559,31.925092 C38.1340459,31.925092 38.1340459,32.4991691 39.2698359,32.4991691 C40.4056259,32.4991691 40.4056259,31.3691295 41.5414159,31.3691295 C42.6772059,31.3691295 42.6772059,32.4757776 43.8129959,32.4757776 C44.9487859,32.4757776 44.9141899,32.7889616 46.0845759,32.798376 C47.1857699,32.8072338 47.2203659,23 48.3561559,23 C49.4919459,23 49.4919459,30.7695343 50.6277359,30.7695343 C51.7635259,30.7695343 51.7572084,32.2109097 52.8993159,32.2109097 C53.660721,32.2109097 54.4263276,32.4067318 55.1961357,32.798376"
                  id="Line"
                  stroke="#7283E4"
                  stroke-width="2"
                  fill="#D8D8D8"
                  stroke-linecap="square"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const TableChartInactive = () => (
  <svg width="60px" height="38px" viewBox="0 0 60 38" version="1.1">
    <title>table and graph</title>
    <g id="Version-A" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="B-01.-Channel-Performance---Platform-Detail" transform="translate(-654, -288)">
        <g id="layout-controls" transform="translate(19, 288)">
          <g id="title" transform="translate(555, 0)">
            <g id="Group-6" transform="translate(10, 0)">
              <g id="table-and-graph" transform="translate(70, 0)">
                <rect id="Rectangle" stroke="#BFC6F3" fill="#F0F3FF" x="0.5" y="0.5" width="59" height="37" rx="4" />
                <rect id="Rectangle" fill="#BFC6F3" x="5" y="5" width="50" height="3" rx="1.5" />
                <rect id="Rectangle" fill="#BFC6F3" x="5" y="10" width="50" height="3" rx="1.5" />
                <rect id="Rectangle" fill="#BFC6F3" x="5" y="15" width="50" height="3" rx="1.5" />
                <path
                  d="M5.19613573,32.798376 C6.33192573,32.798376 6.33192573,32.3320417 7.46771574,32.3320417 C8.60350574,32.3320417 8.60350574,32.2109097 9.73929575,32.2109097 C10.8750858,32.2109097 10.8750858,26.0114437 12.0108758,26.0114437 C13.1466658,26.0114437 13.1466658,32.3320417 14.2824558,32.3320417 C15.4182458,32.3320417 15.4182458,32.9193504 16.5540358,32.9193504 C17.6898258,32.9193504 17.6898258,32.798376 18.8256158,32.798376 C19.9614058,32.798376 19.9614058,32.4991691 21.0971958,32.4991691 C22.2329858,32.4991691 22.2329858,32.798376 23.3687758,32.798376 C24.5045658,32.798376 24.5045658,33 25.6403558,33 C26.7761458,33 26.7761458,30.0873205 27.9119358,30.0873205 C29.0477258,30.0873205 29.0477258,33 30.1835158,33 C31.3193058,33 31.3193058,32.9193504 32.4550958,32.9193504 C33.5908859,32.9193504 33.5908859,32.798376 34.7266759,32.798376 C35.8624659,32.798376 35.8624659,31.925092 36.9982559,31.925092 C38.1340459,31.925092 38.1340459,32.4991691 39.2698359,32.4991691 C40.4056259,32.4991691 40.4056259,31.3691295 41.5414159,31.3691295 C42.6772059,31.3691295 42.6772059,32.4757776 43.8129959,32.4757776 C44.9487859,32.4757776 44.9141899,32.7889616 46.0845759,32.798376 C47.1857699,32.8072338 47.2203659,23 48.3561559,23 C49.4919459,23 49.4919459,30.7695343 50.6277359,30.7695343 C51.7635259,30.7695343 51.7572084,32.2109097 52.8993159,32.2109097 C53.660721,32.2109097 54.4263276,32.4067318 55.1961357,32.798376"
                  id="Line"
                  stroke="#BFC6F3"
                  stroke-width="2"
                  stroke-linecap="square"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ChartOnlyActive = () => (
  <svg width="60px" height="38px" viewBox="0 0 60 38" version="1.1">
    <title>graph only</title>
    <g id="Version-A" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Prototype---Graph-Only" transform="translate(-724, -288)">
        <g id="layout-controls" transform="translate(19, 288)">
          <g id="title" transform="translate(555, 0)">
            <g id="Group-6" transform="translate(10, 0)">
              <g id="graph-only" transform="translate(140, 0)">
                <rect id="Rectangle" stroke="#475DDC" fill="#F0F3FF" x="0.5" y="0.5" width="59" height="37" rx="4" />
                <path
                  d="M5.19613573,32.4354527 C6.33192573,32.4354527 6.33192573,31.1297167 7.46771574,31.1297167 C8.60350574,31.1297167 8.60350574,30.7905473 9.73929575,30.7905473 C10.8750858,30.7905473 10.8750858,13.4320425 12.0108758,13.4320425 C13.1466658,13.4320425 13.1466658,31.1297167 14.2824558,31.1297167 C15.4182458,31.1297167 15.4182458,32.7741811 16.5540358,32.7741811 C17.6898258,32.7741811 17.6898258,32.4354527 18.8256158,32.4354527 C19.9614058,32.4354527 19.9614058,31.5976734 21.0971958,31.5976734 C22.2329858,31.5976734 22.2329858,32.4354527 23.3687758,32.4354527 C24.5045658,32.4354527 24.5045658,33 25.6403558,33 C26.7761458,33 26.7761458,24.8444975 27.9119358,24.8444975 C29.0477258,24.8444975 29.0477258,33 30.1835158,33 C31.3193058,33 31.3193058,32.7741811 32.4550958,32.7741811 C33.5908859,32.7741811 33.5908859,32.4354527 34.7266759,32.4354527 C35.8624659,32.4354527 35.8624659,29.9902575 36.9982559,29.9902575 C38.1340459,29.9902575 38.1340459,31.5976734 39.2698359,31.5976734 C40.4056259,31.5976734 40.4056259,28.4335625 41.5414159,28.4335625 C42.6772059,28.4335625 42.6772059,31.5321771 43.8129959,31.5321771 C44.9487859,31.5321771 44.9141899,32.4090925 46.0845759,32.4354527 C47.1857699,32.4602546 47.2203659,5 48.3561559,5 C49.4919459,5 49.4919459,26.754696 50.6277359,26.754696 C51.7635259,26.754696 51.7572084,30.7905473 52.8993159,30.7905473 C53.660721,30.7905473 54.4263276,31.3388491 55.1961357,32.4354527"
                  id="Line"
                  stroke="#7283E4"
                  stroke-width="2"
                  stroke-linecap="square"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ChartOnlyInactive = () => (
  <svg width="60px" height="38px" viewBox="0 0 60 38" version="1.1">
    <title>graph only</title>
    <g id="Version-A" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="B-01.-Channel-Performance---Platform-Detail" transform="translate(-724, -288)" stroke="#BFC6F3">
        <g id="layout-controls" transform="translate(19, 288)">
          <g id="title" transform="translate(555, 0)">
            <g id="Group-6" transform="translate(10, 0)">
              <g id="graph-only" transform="translate(140, 0)">
                <rect id="Rectangle" fill="#F0F3FF" x="0.5" y="0.5" width="59" height="37" rx="4" />
                <path
                  d="M5.19613573,32.4354527 C6.33192573,32.4354527 6.33192573,31.1297167 7.46771574,31.1297167 C8.60350574,31.1297167 8.60350574,30.7905473 9.73929575,30.7905473 C10.8750858,30.7905473 10.8750858,13.4320425 12.0108758,13.4320425 C13.1466658,13.4320425 13.1466658,31.1297167 14.2824558,31.1297167 C15.4182458,31.1297167 15.4182458,32.7741811 16.5540358,32.7741811 C17.6898258,32.7741811 17.6898258,32.4354527 18.8256158,32.4354527 C19.9614058,32.4354527 19.9614058,31.5976734 21.0971958,31.5976734 C22.2329858,31.5976734 22.2329858,32.4354527 23.3687758,32.4354527 C24.5045658,32.4354527 24.5045658,33 25.6403558,33 C26.7761458,33 26.7761458,24.8444975 27.9119358,24.8444975 C29.0477258,24.8444975 29.0477258,33 30.1835158,33 C31.3193058,33 31.3193058,32.7741811 32.4550958,32.7741811 C33.5908859,32.7741811 33.5908859,32.4354527 34.7266759,32.4354527 C35.8624659,32.4354527 35.8624659,29.9902575 36.9982559,29.9902575 C38.1340459,29.9902575 38.1340459,31.5976734 39.2698359,31.5976734 C40.4056259,31.5976734 40.4056259,28.4335625 41.5414159,28.4335625 C42.6772059,28.4335625 42.6772059,31.5321771 43.8129959,31.5321771 C44.9487859,31.5321771 44.9141899,32.4090925 46.0845759,32.4354527 C47.1857699,32.4602546 47.2203659,5 48.3561559,5 C49.4919459,5 49.4919459,26.754696 50.6277359,26.754696 C51.7635259,26.754696 51.7572084,30.7905473 52.8993159,30.7905473 C53.660721,30.7905473 54.4263276,31.3388491 55.1961357,32.4354527"
                  id="Line"
                  stroke-width="2"
                  stroke-linecap="square"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const TableOnlyActive = () => (
  <svg width="60px" height="38px" viewBox="0 0 60 38" version="1.1">
    <title>no graph</title>
    <g id="Version-A" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Prototype---Table-Only" transform="translate(-794, -288)">
        <g id="layout-controls" transform="translate(19, 288)">
          <g id="title" transform="translate(555, 0)">
            <g id="Group-6" transform="translate(10, 0)">
              <g id="no-graph" transform="translate(210, 0)">
                <rect id="Rectangle" stroke="#475DDC" fill="#F0F3FF" x="0.5" y="0.5" width="59" height="37" rx="4" />
                <rect id="Rectangle" fill="#7283E4" x="5" y="20" width="50" height="3" rx="1.5" />
                <rect id="Rectangle" fill="#7283E4" x="5" y="25" width="50" height="3" rx="1.5" />
                <rect id="Rectangle" fill="#7283E4" x="5" y="30" width="50" height="3" rx="1.5" />
                <rect id="Rectangle" fill="#7283E4" x="5" y="5" width="50" height="3" rx="1.5" />
                <rect id="Rectangle" fill="#7283E4" x="5" y="10" width="50" height="3" rx="1.5" />
                <rect id="Rectangle" fill="#7283E4" x="5" y="15" width="50" height="3" rx="1.5" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const TableOnlyInactive = () => (
  <svg width="60px" height="38px" viewBox="0 0 60 38" version="1.1">
    <title>no graph</title>
    <g id="Version-A" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="B-01.-Channel-Performance---Platform-Detail" transform="translate(-794, -288)">
        <g id="layout-controls" transform="translate(19, 288)">
          <g id="title" transform="translate(555, 0)">
            <g id="Group-6" transform="translate(10, 0)">
              <g id="no-graph" transform="translate(210, 0)">
                <rect id="Rectangle" stroke="#BFC6F3" fill="#F0F3FF" x="0.5" y="0.5" width="59" height="37" rx="4" />
                <rect id="Rectangle" fill="#BFC6F3" x="5" y="20" width="50" height="3" rx="1.5" />
                <rect id="Rectangle" fill="#BFC6F3" x="5" y="25" width="50" height="3" rx="1.5" />
                <rect id="Rectangle" fill="#BFC6F3" x="5" y="30" width="50" height="3" rx="1.5" />
                <rect id="Rectangle" fill="#BFC6F3" x="5" y="5" width="50" height="3" rx="1.5" />
                <rect id="Rectangle" fill="#BFC6F3" x="5" y="10" width="50" height="3" rx="1.5" />
                <rect id="Rectangle" fill="#BFC6F3" x="5" y="15" width="50" height="3" rx="1.5" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

import React from 'react';

import MMMView from './component';
import { BasicViewHeader, ViewAccessWrapper } from '../../components';

const MarketingMixModeling = () => (
  <>
    <BasicViewHeader
      header="Marketing Mix Modeling"
      subheader="Plan budgets and predict future revenue using historical marketing performance"
    />
    <MMMView />
  </>
);

const MMMViewWrapper = () => (
  <ViewAccessWrapper
    viewName="marketing_mix_modeling"
    viewComponent={<MarketingMixModeling />}
  />
);

export default MMMViewWrapper;

import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import * as Parts from './parts';

/* eslint-disable react/jsx-props-no-spreading */

const TrendCards = props => {
  const { options, onChange, value } = props; // required
  const { defaultCard = Parts.DefaultCard, activeColor = '#475ddc', showPlaceholder = false } = props;
  const [active, setActive] = useState(value || '');

  useEffect(() => {
    onChange(false, { value: active, data: options.find(c => c.value === active) });
  }, [active]);

  return (
    <Parts.Group {...props}>
      {options.map(option => {
        const isActive = option.value === value;
        // NOTE: you can override the display card default on a per option basis
        const CardComponent = option.component || defaultCard;

        if (showPlaceholder) return <Parts.Placeholder {...{ option, activeColor }} />;

        return (
          <CardComponent
            {...{ active, setActive, item: option, isActive }}
            {...option}
            {...props}
          />
        );
      })}
    </Parts.Group>
  );
};

TrendCards.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  heading: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
  ]),
  statistic: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
  ]),
  subheading: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
  ]),
  activeColor: PropTypes.string,
  showPlaceholder: PropTypes.bool,
  // tooltip: element?
};

export default TrendCards;

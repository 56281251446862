import React from 'react';

import moment from 'moment';
import { Button } from 'semantic-ui-react';

import { BasicViewHeader } from '../../components';

export const MainHeader = () => (
  <BasicViewHeader
    header="Pixel Event Status"
    subheader="Monitor the site data passed to Rockerbox via on-site pixel for the purposes of implementation, troubleshooting, and anomaly detection."
    topRight={(
      <Button
        primary
        icon="external"
        labelPosition="left"
        content="Help Docs"
        as="a"
        href="https://help.rockerbox.com/article/0wdx5z61md-google-tag-manager-gtm-pixel-implementation"
        target="_blank"
      />
    )}
  />
);

export const DailyLabelFormatter = label => {
  const date = moment(label).format('MMMM DD');

  return (
    <span>
      {`${date}`}
    </span>
  );
};

export const HourlyLabelFormatter = label => {
  const date = moment(label).format('MMMM DD');
  const hour = moment(label).format('ha');

  return (
    <span>
      {`${date}, ${hour}`}
    </span>
  );
};

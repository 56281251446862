import React, { useState, useMemo } from 'react';
import { Header, Label, Dropdown, Button, Grid, Segment, Icon, Divider } from 'semantic-ui-react';
import { RadialBarChart, RadialBar } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';

import { ContentCard } from '@rockerbox/styleguide';
import { postChannelRecommendations } from '../../api/attribution';
import { CHANNELS, CHANNEL_MAP, CHANNELS_LIST } from './constants';
import { ChannelRecommendationsLoader } from './loaders';

const ChannelRecommendations = () => {
  const [selectedChannels, setSelectedChannels] = useState([]);

  const [recommendations, setRecommendations] = useState(null);
  const [loading, setLoading] = useState(false);

  const availableChannels = useMemo(
    () => CHANNELS.filter(x => !selectedChannels.includes(x.key)),
    [selectedChannels],
  );

  const recommendationsHeading = useMemo(() => {
    if (!selectedChannels.length) return 'Select your channels above';
    // eslint-disable-next-line max-len
    const channelsString = `${selectedChannels.slice(0, selectedChannels.length - 1).map(x => CHANNEL_MAP[x].name).join(', ')}${selectedChannels.length > 2 ? ',' : ''} ${selectedChannels.length > 1 ? 'and' : ''} ${CHANNEL_MAP[selectedChannels.slice(-1)[0]].name}`;
    return `Companies who advertise on ${channelsString} are also likely to run on the following channels:`;
  }, [selectedChannels]);

  const onSubmit = () => {
    setLoading(true);
    const obj = {
      channels: selectedChannels,
    };
    postChannelRecommendations(obj)
      .then(data => {
        const filteredRecs = data.filter(x => CHANNELS_LIST.includes(x.channel)).slice(0, 4);
        setRecommendations(filteredRecs);
        setLoading(false);
      })
      .catch(() => {
        toast.error('Error getting channel results');
        setRecommendations(null);
        setLoading(false);
      });
  };

  return (
    <ContentCard
      title={(
        <>
          <FontAwesomeIcon
            icon={faWandMagicSparkles}
            style={{ height: 15, marginRight: 7 }}
          />
          <Header
            as="h3"
            content="Channel Explorer"
          />
          <Label
            size="mini"
            color="green"
            content="beta"
            style={{
              fontSize: 7,
              marginTop: -4,
              marginLeft: 6,
            }}
          />
        </>
)}
      style={{ marginTop: 0 }}
    >
      <Header
        as="h3"
        content="Wonder what similar companies are advertising on? Enter your channels below to see which other channels you may want to consider next."
      />

      <Grid>
        <Grid.Row columns={5} stretched style={{ marginBottom: -24 }}>
          {selectedChannels.map(c => (
            <Grid.Column key={c} style={{ marginBottom: 24 }}>
              <Segment
                style={{
                  boxShadow: 'none',
                  border: `2px solid ${CHANNEL_MAP[c].color}`,
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <Header
                  as="h3"
                  style={{
                    color: CHANNEL_MAP[c].color,
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 0,
                    paddingBottom: 0,
                    marginTop: 7,
                  }}
                >
                  {!!CHANNEL_MAP[c].icon
                      && (
                      <Icon
                        name={CHANNEL_MAP[c].icon}
                        style={{
                          marginBottom: 7,
                        }}
                      />
                      )}
                  {!!CHANNEL_MAP[c].customIcon
                      && (
                      <FontAwesomeIcon
                        as="i"
                        className="icon"
                        icon={CHANNEL_MAP[c].customIcon}
                        style={{
                          marginBottom: 7,
                          marginLeft: 0,
                          marginRight: 'auto',
                        }}
                      />
                      )}
                  {CHANNEL_MAP[c].name}
                </Header>
                <Icon
                  link
                  name="close"
                  color="grey"
                  onClick={() => {
                    setSelectedChannels(selectedChannels.filter(x => x !== c));
                    setRecommendations(null);
                  }}
                  style={{
                    position: 'absolute',
                    top: 8,
                    right: 6,
                  }}
                />
              </Segment>
            </Grid.Column>
          ))}
          {!!availableChannels.length && selectedChannels.length < 10
            && (
            <Grid.Column style={{ marginBottom: 24 }}>
              <Segment
                className="empty-view"
                style={{
                  padding: 25,
                }}
              >
                <Dropdown
                  button
                  className="icon"
                  floating
                  labeled
                  search
                  selectOnBlur={false}
                  icon="plus"
                  text="Add Channel"
                  options={availableChannels.map(x => ({ key: x.key, text: x.name, value: x.key }))}
                  onChange={(e, { value }) => {
                    setSelectedChannels([...selectedChannels, value]);
                    setRecommendations(null);
                  }}
                  value={null}
                />
              </Segment>
            </Grid.Column>
            )}
        </Grid.Row>
      </Grid>

      <Divider style={{ marginTop: 20, marginBottom: -7, opacity: 0.5 }} />

      <Header
        as="h3"
        content={recommendationsHeading}
        disabled={!selectedChannels.length}
      />

      {!recommendations
        && <ChannelRecommendationsLoader animate={loading} />}

      {!!recommendations && (
      <Grid>
        <Grid.Row columns={4} stretched>
          {recommendations.map(({ channel, value }) => (
            <Grid.Column key={channel}>
              <Segment
                style={{
                  boxShadow: 'none',
                  border: `2px solid ${CHANNEL_MAP[channel].color}`,
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <Header
                  as="h3"
                  style={{
                    color: CHANNEL_MAP[channel].color,
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 0,
                    paddingBottom: 0,
                    marginTop: 7,
                  }}
                >
                  {!!CHANNEL_MAP[channel].icon
                        && (
                        <Icon
                          name={CHANNEL_MAP[channel].icon}
                          style={{
                            marginBottom: 7,
                          }}
                        />
                        )}
                  {!!CHANNEL_MAP[channel].customIcon
                        && (
                        <FontAwesomeIcon
                          as="i"
                          className="icon"
                          icon={CHANNEL_MAP[channel].customIcon}
                          style={{
                            marginBottom: 7,
                            marginLeft: 0,
                            marginRight: 'auto',
                          }}
                        />
                        )}
                  {CHANNEL_MAP[channel].name}
                </Header>
                <RadialBarChart
                  width={50}
                  height={50}
                  innerRadius="25%"
                  outerRadius="100%"
                  data={[
                    {
                      name: 'total',
                      value: 1,
                      fill: '#fff',
                    },
                    {
                      name: channel,
                      value,
                      fill: CHANNEL_MAP[channel].color,
                    },
                  ]}
                  startAngle={90}
                  endAngle={-270}
                  style={{
                    position: 'absolute',
                    top: 15,
                    right: 15,
                  }}
                >
                  <RadialBar
                    background
                    clockWise={true}
                    dataKey="value"
                  />
                </RadialBarChart>
              </Segment>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
      )}

      {!!selectedChannels.length && !recommendations && !loading
        && (
        <div
          style={{
            position: 'absolute',
            bottom: 37,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            primary
            content="View Result"
            onClick={onSubmit}
            style={{
              marginLeft: 0,
              marginRight: 0,
            }}
          />
        </div>
        )}
    </ContentCard>
  );
};

export default ChannelRecommendations;

import React, { useEffect, useState } from 'react';

import { Form } from 'semantic-ui-react';

export const RadioDropdown = ({ options, defaultValue, onChange }) => {
  const [value, setValue] = useState(defaultValue);
  const [evt, setEvent] = useState(false);

  const handleChange = (e, { value: newValue }) => {
    setEvent(e);
    setValue(newValue);
  };

  useEffect(() => {
    onChange(evt, { value });
  }, [value]);

  return (
    <Form.Field>
      {options.map(option => (
        <Form.Radio key={option.value} label={option.text} value={option.value} checked={value === option.value} onChange={handleChange} />
      ))}
    </Form.Field>
  );
};

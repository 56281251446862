import React, { useMemo } from 'react';

import { BeakerIcon } from '@heroicons/react/outline'; // eslint-disable-line import/no-extraneous-dependencies
import { Menu, Popup, Header, Button } from 'semantic-ui-react';

const BetaMenu = ({ artifacts }) => {
  const [opened, setOpened] = React.useState(false);

  const intercomSurveyId = useMemo(() => {
    if (!artifacts) return null;
    const { intercom_surveys } = artifacts || {};
    const survey = intercom_surveys?.general_feedback || {};
    const intercomEnv = window.env === 'prod' ? 'prod' : 'dev';
    const surveyId = survey[intercomEnv];
    return surveyId;
  }, [artifacts]);

  const enrolled = useMemo(() => document.cookie.indexOf('beta=true') > -1, []);

  const submitFeedback = () => {
    if (!!window.Intercom) {
      window.Intercom('startSurvey', intercomSurveyId);
    }
  };

  const leaveBeta = () => {
    document.cookie = 'beta=false; path=/;';
    window.location.reload();
  };

  if (!enrolled) return null;

  return (
    <Popup
      transition={{ animation: 'fade down', duration: 500 }}
      trigger={(
        <Menu.Item
          className="icon-button"
          content={<BeakerIcon color="#BFC6F3" />}
          active={!!opened}
        />
      )}
      basic
      on="click"
      position="bottom right"
      open={!!opened}
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      className="nav-dropdown-menu beta-menu"
      content={(
        <>
          <Header
            as="h3"
            content="Rockerbox Beta"
            subheader="Thank you for participating in our beta program!"
          />
          <Button
            color="purple"
            fluid
            icon="comment alternate"
            content="Submit Feedback"
            onClick={submitFeedback}
          />
          <Button
            inverted
            color="purple"
            fluid
            icon="close"
            content="Leave Beta"
            onClick={leaveBeta}
          />
        </>
)}
    />
  );
};

export default BetaMenu;

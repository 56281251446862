import React from 'react';
import * as Router from "react-router-dom";
import * as routes from '../../routes';
import CacheContext from '../../utils/CacheContext';
import { getProcessedReports } from '../../utils/api';
import { exportCSV } from '../../utils/export';

import { Button, Table } from 'semantic-ui-react';
import { PagedIndexGrid, ContentCard, IndexGrid } from '@rockerbox/styleguide';

const ListTypeSelector = (props) => {
  const [state, setState, Context] = React.useContext(CacheContext);

  const listType = Context.getCache("listType", "processed")
  const setListType = (listType) => setState({ listType })

  return <Button.Group {...props} >
    <Button active={listType =="processed"} onClick={() => setListType("processed")} >Processed</Button>
    <Button.Or />
    <Button active={listType =="load"} onClick={() => setListType("load")}>Loaded</Button>
  </Button.Group>
}

const DatasetsHeader = () => {
  return <React.Fragment>
    Dataset Entries
    <ListTypeSelector size="mini" style={{float:"right"}} />
  </React.Fragment>
}

const ManageCell = (listType, onRowClick) => ({ item }) => {
  const fileName = `${item.pixel_source_name}-${item.upload_id}-${item.id}.csv`
  const handleDownload = () => {
    getProcessedReports(item.report_id, listType, "data", item.id)
      .then(data => {
        exportCSV(data, fileName)
      })
  }

  return <Table.Cell>
    <Button primary icon="eye" onClick={() => onRowClick(item)}/>
    <Button icon="download" onClick={handleDownload}/>
  </Table.Cell>
}

const NumRows = ({ item={} }) => {
  const { num_rows } = item;
  return (
      <Table.Cell>{ num_rows ? num_rows : "" }</Table.Cell>
  )
}

const Datasets = ({ selectedType, id }) => {

  const history = Router.useHistory();
  const [list, setList] = React.useState([]);
  const [state, setState, Context] = React.useContext(CacheContext);
  const listType = Context.getCache("listType", "processed")
  const setSelectedFile = (value) => history.push(`${routes.datasetsIndex}/view/${selectedType}/${id}/${value}`)

  React.useEffect(() => {
    setList([])
    if (id) {
      setList(false)
      getProcessedReports(id, listType)
        .then(data => setList(data))
    }
  }, [id, listType])

  const onRowClick = (row) => {
    const { id } = row
    setSelectedFile(id)
  }

  const COLS = [
    {display: "Upload ID", key: "upload_id"},
    {display: "File ID", key: "id"},
    {display: "Location", key: "location", as: IndexGrid.EllipsedCell},
    {display: "Num Rows", key:"num_rows", as: NumRows},
    {display: "Manage", as: ManageCell(listType, onRowClick)} 
  ]

  if (!id) return <ContentCard.EmptyView fallBackMsg="Select a Dataset to view its contents" />

  return <ContentCard title={<DatasetsHeader />} hasTable>
    <PagedIndexGrid itemsPerPage={20} data={list} cols={COLS} />
  </ContentCard>
}

export default Datasets;

import React, { Component } from 'react';
import { ContentCard } from '@rockerbox/styleguide';
import ButterToast from 'butter-toast';
import Toast from '../Toast';
import _ from 'lodash';
import * as routes from '../../routes';
import { getSpendFields, getRawTransformTableDataById, createAttributableEvent, updateAttributableEvent, deleteAttributableEvent } from '../../utils/api';
import FormBody from './FormBody';
import * as Styles from './FormBodyStyles';
import ExampleEvents from './ExampleEvents';
import { initialState, initialGroup, initialLogic, initialTransform } from './initial';
import { track } from '../../utils/tracking';

const validateForTrySubmit = (groups) => {
  let isComplete = true;

  if (!groups) return false;

  groups.forEach((group) => {
    if (!group.logic) return false;

    group.logic.forEach((item) => {

      try {
          new RegExp(item.value);
      } 
      catch(e) {
        isComplete = false;
      }

      if (!item.field || !item.value) {
        isComplete = false;
      }
    })
  });

  return isComplete;
}

const validateRegex = (groups) => {
  let valid = true;

  if (!groups) return true;

  groups.forEach((group) => {
    if (!group.logic) return true;

    group.logic.forEach((item) => {

      try {
          new RegExp(item.value);
      } 
      catch(e) {
        valid = false;
      }
    })
  });

  return valid;
}

export class AttributableEvent extends Component {

  state = Object.assign({},initialState)

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.editBuffer !== this.props.editBuffer) {
      const newState = _.cloneDeep(this.props.editBuffer);
      if (newState.transform) {
        const transformKeys = newState.transform.transform_key.split("|").reduce((p,c,i) => {
          p[`transform_key_${i+1}`] = c
          return p
        },{})
        Object.assign(newState, newState.transform, transformKeys, {"event_type":"transform"})
      }
      this.setState(newState);
    }

    if (this.state.transform_table_id != prevState.transform_table_id) {
      this.getTransformColumns()
    }
  }

  getTransformColumns = () => {
    this.setState({ transformColumns: [] })
    const { platform } = this.props.transforms.find(row => row.id == this.state.transform_table_id) || {}
    getRawTransformTableDataById(this.state.transform_table_id)
      .then(resp => {

        getSpendFields(platform)
          .then(fields => {
            const fieldDict = d3.nest()
              .key(row => row.cassandra_name)
              .map(fields)
            
            const transformColumns = Object.keys(resp[0]).map(key => {
              const defaultTextObj = [{ display_name: `{${key}}` }];
              const text = (fieldDict[key] || defaultTextObj)[0]['display_name'];
              return { key, text, value: `{${key}}` }
            })

            this.setState({ transformColumns })
          })
      })
  }


  componentDidMount() {
    if (!this.state.logic && !this.props.editBuffer) {
      this.addGroup();
    }
    const { t1, t2, type } = this.props.match.params;
    const hast1 = t1 !== undefined;
    const hast2 = t2 !== undefined;
    const stateUpdates = { event_type: type == "exclude" ? "exclude" : "rename" }

    if(hast1 && hast2) {
      Object.assign(stateUpdates,{rewrite_tier_1: t1, rewrite_tier_2: t2});
    }
    this.setState(stateUpdates)
  }

  isBoundValue = (field) => {
    let value = this.state[field];
    return new RegExp('^{\..*}$').test(value);
  }

  onFieldChange = (fieldName) => (evt, data) => {
    const {value} = data;
    this.customSetState({[fieldName] : value});
  }

  customSetState = (newState) => {
    this.setState(this.processTransformUpdates(newState))
  }

  processTransformUpdates = (obj) => {
    const { event_type } = this.state;
    if (obj.event_type == "rename") {
      return Object.assign(obj, initialTransform)
    }

    if (event_type == "transform") {
      const prevState = this.state;
      const state = Object.assign({}, this.state, obj)
      const { transform_table_id, num_mta_key_fields, num_transform_key_fields } = state;

      const mta_fields = [1,2,3,4,5].map(i => "rewrite_tier_" + i)
      const existingMTAKeys = (state.mta_key || "").split("|")
      const defaultMTAKeys = existingMTAKeys.filter((key, i) => key.replace("{tier_","").replace("}","") == (i+1))
      const isDefaultMTA = defaultMTAKeys.length == existingMTAKeys.length

      const mta_key = !isDefaultMTA ?
        existingMTAKeys.join("|") :
        mta_fields.slice(0,num_mta_key_fields).map((k,i) => "{tier_" + (i+1) + "}").join("|")

      const transform_fields = [1,2,3,4,5].map(i => "transform_key_" + i)

      const transformKeys = (state.transform_key || "").split("|")
      const isDefaultTransform = transformKeys.filter((k,i) => prevState[transform_fields[i]] != k).length == 0
      const newTransformKey = transform_fields
        .slice(0,num_transform_key_fields)
        .map(k => state[k])
        .filter(x => x).join("|")

      const transform_key = isDefaultTransform && num_transform_key_fields >= transformKeys.length ?
        newTransformKey :
        transformKeys.join("|")

      const transform_entries = [1,2,3,4,5].reduce((p,i) => {
        const key = "transform_key_" + i
        p[key] = state[key]
        return p
      },{})

      const { platform } = this.props.transforms.find(row => row.id == transform_table_id) || {}

      const transform = {
        transform_table_id,
        platform,
        mta_key,
        transform_key,
        ...transform_entries
      }

      return Object.assign(obj, { transform_key, mta_key, transform })
    }
    return obj
  }

  onReset = () => {
    const copiedInitial = Object.assign({}, initialState);
    this.setState(copiedInitial);
    this.addGroup();
  }

  onLogicChange = (group_id) => (pos, field) => (evt, data) => {
    const { value, category, type, param } = data;
    const copyGroups = _.cloneDeep(this.state.group || [])
    const copyLogics = _.cloneDeep(copyGroups[group_id].logic)
    let copyLogic = _.cloneDeep(copyLogics[pos]);
    copyLogic[field] = value;

    if (type) copyLogic.type = type;
    if (param) copyLogic.param = param;
    if (category) {
      copyLogic.param = undefined;
      copyLogic.value = '';
      copyLogic.category = category;
    };

    copyGroups[group_id].logic[pos] = copyLogic;
    this.setState({"group": copyGroups});
  }

  addGroup = () => {
    const copyGroup = _.cloneDeep(this.state.group || [])
    const copyInitial = Object.assign({}, initialGroup)
    copyInitial.logic = [Object.assign({}, initialLogic)]
    copyGroup.push(copyInitial)
    this.setState({"group": copyGroup})
  }

  addLogic = (group_id) => {
    const copyGroups = _.cloneDeep(this.state.group || [])
    const copyLogic = _.cloneDeep(copyGroups[group_id].logic)

    const copyInitialLogic = Object.assign({}, initialLogic)
    copyLogic.push(copyInitialLogic)

    copyGroups[group_id].logic = copyLogic

    this.setState({"group": copyGroups})
  }

  removeLogic = (group_id, ind) => {
    const copyGroups = _.cloneDeep(this.state.group || [])
    const logic = _.cloneDeep(copyGroups[group_id].logic);
    logic.splice(ind, 1);
    copyGroups[group_id].logic = logic;

    if (ind === 0) {
      copyGroups.splice(group_id, 1);
    }
    this.setState(({"group": copyGroups}));
  }

  onResponse = (resp) => {
    const { type, what, editing } = this.props;
    const verb = editing ? 'updated' : 'created';

    this.onReset();
    ButterToast.raise({
      dismissOnClick: true,
      content: ({toastId, dismiss}) => (<Toast message={`${what} ${verb} successfully!`} />),
      toastTimeout: 5000
    })
    setTimeout(this.props.returnToIndex(type), 0);
  }

  onSubmit = () => {
    const { type, editing } = this.props;
    const data = Object.assign({}, this.state, { event_type: type });

    for (var i in data.group) {
      for (var j in data.group[i].logic) {
        data.group[i].logic[j].value = data.group[i].logic[j].value.trim()
      }
    }
    const send = editing ? updateAttributableEvent : createAttributableEvent;

    const trackingEventName = `${editing ? 'edit' : 'create'}-mapping-rule`;
    track(trackingEventName, data);

    send(data)
      .then(this.onResponse)
      .catch((err) => { console.log('error! ', err); })
  }

  handlePopupClose = (e) => {
    e.preventDefault();
    this.setState({popupOpen: false})
  }

  handlePopupOpen = (e) => {
    e.preventDefault();
    this.setState({popupOpen: true})
  }

  deleteEvent = (e) => {
    e.preventDefault();
    const { id } = this.props.match.params;

    deleteAttributableEvent(id)
      .then(d => {
        this.setState({popupOpen: false})
        this.props.history.push(routes.mappedEventIndex)
      });
  }

  computeTypeParams = (what, editing, event_type) => {
    const verb = editing ? "Edit" : "Create";
    const wrapperParams = {
      title: `${verb} ${what}`,
      linkTitle: `Back to ${what}s`,
      linkTo: event_type === 'exclude' ? routes.excludedEventIndex : routes.mappedEventIndex
    }

    const submitText = editing ? 'Save changes' : 'Create and Save';
    const cancelUrl = event_type === 'exclude' ? routes.excludedEventIndex : routes.mappedEventIndex;
    return { wrapperParams, submitText, cancelUrl }
  }

  render() {
    const { props, state } = this;
    const { event_type } = state;
    const { transforms, type, attributable_events, what, artifacts, segmentFields, editBuffer, editing, returnToIndex, urlId } = props;
    const { t1, t2 } = props.match.params;

    const { wrapperParams, submitText, cancelUrl } = this.computeTypeParams(what, editing, event_type);

    const fromProps = { event_type, type, artifacts, segmentFields, wrapperParams, t1, t2, editing, submitText, cancelUrl, transforms };

    const { onSubmit, isBoundValue, onLogicChange, onFieldChange, addGroup, addLogic, removeLogic, deleteEvent, handlePopupClose, handlePopupOpen } = this;
    const funcs = { onSubmit, isBoundValue, onLogicChange, onFieldChange, addGroup, addLogic, removeLogic, deleteEvent, handlePopupClose, handlePopupOpen, validateForTrySubmit, validateRegex };

    return (
      <React.Fragment>
      {
        editing && !editBuffer ?
          <Styles.Wrapper {...wrapperParams}><ContentCard.CardLoader /></Styles.Wrapper> :
          <FormBody {...funcs} {...state} {...fromProps}>
            <ExampleEvents {...{urlId, state, attributable_events}} />
          </FormBody>
      }
      </React.Fragment>
    )
  }
}

export default AttributableEvent;

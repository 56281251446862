import moment from 'moment';

export const JOB_SUBMISSION = "RB.job.submission"
const JOB_STATUS = "RB.job.status"

export const getLocalJSON = (key) => JSON.parse(localStorage.getItem(key) || "{}")
export const setLocalJSON = (key, data) => { localStorage.setItem(key, JSON.stringify(data)) }
export const keyFromRow = (row) => JSON.stringify(row)

export function checkJobStatus(submissionId) {
  const url = `/run/check/job?job_id=${submissionId}`;
  return fetch(url, { credentials: 'include' })
    .then(response => response.json())
    .then(data => { return data.response; })
}

export function checkJobSubmissionDate(date, action, agg) {
  const submissionDate = moment().format("YYYY-MM-DD")
  const keyTrack = `${JOB_SUBMISSION}.${agg}.${action}.${submissionDate}.${date}`
  
  const submissionKey = getLocalJSON(keyTrack)

  const p = new Promise((resolve, rej) => {
    resolve(submissionKey)
  })
  return p
}

export function checkAggSubmission(action, agg, _subDate=false) {
  const submissionDate = _subDate || moment().format("YYYY-MM-DD")
  const keySubmissionDate = `${JOB_SUBMISSION}.${agg}.${action}.${submissionDate}`
  console.log(keySubmissionDate)
  
  const submissionKey = getLocalJSON(keySubmissionDate)

  const p = new Promise((resolve, rej) => {
    resolve(submissionKey)
  })
  return p
}

export function trackJobV2(date, action, agg, submissionId, state={"running":0,"success":0,"planned":0}, _subDate=false) {
  const submissionDate = _subDate || moment().format("YYYY-MM-DD")

  const keyAgg = `${JOB_SUBMISSION}.${agg}.${action}`
  const keySubmissionDate = `${JOB_SUBMISSION}.${agg}.${action}.${submissionDate}`
  const keyTrack = `${JOB_SUBMISSION}.${agg}.${action}.${submissionDate}.${date}`

  const submissionAgg = getLocalJSON(keyAgg)
  submissionAgg[submissionDate] = submissionAgg[submissionDate] || {}
  submissionAgg[submissionDate]["days"] = submissionAgg[submissionDate]["days"] || {}
  submissionAgg[submissionDate]["days"][submissionId] = state
  setLocalJSON(keyAgg, submissionAgg)

  const submissionDateObj = getLocalJSON(keySubmissionDate)
  submissionDateObj["days"] = submissionDateObj["days"] || {}
  submissionDateObj["days"][date] = submissionDateObj["days"][date] || {}
  submissionDateObj["days"][date][submissionId] = state
  setLocalJSON(keySubmissionDate, submissionDateObj)

  const submissionKey = getLocalJSON(keyTrack)
  Object.assign(submissionKey, { [submissionId]: state})
  setLocalJSON(keyTrack, submissionKey)


}

export function trackJob(data, action, type, submissionId) {
  const KEY1 = `${JOB_SUBMISSION}.${action}.${type}`
  const jobIds = getLocalJSON(KEY1)
  const key = keyFromRow(data)

  const date = moment().format("YYYY-MM-DD")

  if (jobIds['date'] != date) {
    setLocalJSON(KEY1, { date })
    return trackJob(data, action, type, submissionId)
  }

  jobIds[key] = submissionId
  jobIds['date'] = date
  setLocalJSON(KEY1, jobIds)
}

export function checkJobsCache(action, type) {
  const KEY1 = `${JOB_SUBMISSION}.${action}.${type}`
  const jobIds = getLocalJSON(KEY1)
  const cachedJobStatus = getLocalJSON(JOB_STATUS)

  const date = moment().format("YYYY-MM-DD")

  if (jobIds['date'] != date) {
    setLocalJSON(KEY1, { date })
    return checkJobsCache(action, type)
  }

  if (cachedJobStatus['date'] != date) {
    setLocalJSON(JOB_STATUS, { date })
    return checkJobsCache(action, type)
  }

  const cachedState = Object.keys(jobIds)
    .filter(key => key != "date")
    .reduce((p,key) => {
      const submissionId = jobIds[key]
      const state = cachedJobStatus[submissionId]
      p[key] = state || "unknown"
      return p
    },{ })

  const p = new Promise((resolve, rej) => {
    resolve(cachedState)
  })
  return p

}

function checkJobCacheComplete(submissionId) {
  const cachedJobStatus = getLocalJSON(JOB_STATUS)
  const cachedState = cachedJobStatus[submissionId]

  if ( (cachedState) && (["error","success"].includes(cachedState)) ) {
    return cachedState
  }
  return false

}

export function checkJob(data, action, type) {
  const KEY1 = `${JOB_SUBMISSION}.${action}.${type}`
  const submissionId = getLocalJSON(KEY1)[keyFromRow(data)]

  const state = checkJobCacheComplete(submissionId)
  if (state) return new Promise((resolve, rej) => resolve(state))

  return checkJobStatus(submissionId)
    .then(resp => {
      const cachedJobStatus = getLocalJSON(JOB_STATUS)

      cachedJobStatus[submissionId] = "queued"
      if (resp.started_at > 0) cachedJobStatus[submissionId] = "started"
      if (resp.finished_at > 0) cachedJobStatus[submissionId] = resp.finish_type

      cachedJobStatus['date'] = moment().format("YYYY-MM-DD")
      setLocalJSON(JOB_STATUS, cachedJobStatus)
      return resp.finish_type
    })
}

export function getJobId(action, type, data=null) {
  const KEY1 = `${JOB_SUBMISSION}.${action}.${type}`
  const cache = getLocalJSON(KEY1);
  if (!data) {
    return cache;
  } else {
    return cache[keyFromRow(data)]
  }
}

/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react';

import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';
import { Icon } from 'semantic-ui-react';

import { usePapaParseConfig } from '../../../hooks/papaParse';

export const FileDropzone = ({
  onParse,
  transform,
  filename,
  invalidFile,
  onDropAccepted = undefined,
  onDropRejected = undefined,
  acceptedFileTypes = null,
}) => {
  const config = usePapaParseConfig();

  if (onParse) config.complete = onParse;
  if (transform) config.transform = transform;

  const defaultOnDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];
    Papa.parse(file, config);
  }, []);

  const dropzoneParams = {
    onDropAccepted: onDropAccepted || defaultOnDrop,
    onDropRejected: onDropRejected || (() => {}),
    maxFiles: 1,
  };

  if (acceptedFileTypes) {
    dropzoneParams.accept = acceptedFileTypes;
  }

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone(dropzoneParams);

  const baseStyle = {
    height: '100%',
    cursor: 'pointer',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '10px 0 10px 0',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#f5f7ff',
    color: '#000000',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    fontSize: '18px',
  };

  const focusedStyle = {
    borderColor: '#2196f3',
  };

  const acceptStyle = {
    borderColor: '#00e676',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
    backgroundColor: '#ffaaba',
  };

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject || invalidFile ? rejectStyle : {}),
  }), [
    isFocused,
    isDragAccept,
    isDragReject,
  ]);

  return (
    <div {...getRootProps({ role: 'button', style })}>
      <input {...getInputProps()} />
      {
      filename
        ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '20px' }}>
            <div>Click to reupload</div>
            <div>
              File:
              {filename}
            </div>
          </div>
        )
        : invalidFile
          ? (
            <>
              <Icon name="x" size="massive" style={{ margin: '30px', color: '#ff1744' }} />
              <p>Unfortunately we require your data in a CSV format. Please update your file and re-upload.</p>
            </>
          ) : (
            <>
              <Icon name="upload" size="massive" style={{ margin: '30px', color: '#475ddc' }} />
              <p>Drop your CSV here or click to browse your files.</p>
            </>
          )
      }
    </div>
  );
};

import React, { useEffect, useState } from 'react'
import { Segment, Button, Grid, Icon, Input, Form, Header, Accordion, Checkbox } from 'semantic-ui-react';

import Help from '../../../V3/components/HelpDocs';
import { InviteUsersWidget } from '../parts/Invite';

export const AUTO_PARAMS_PLATFORMS = ['facebook','adwords', 'tiktok']

const Item = ({ platform, platformsComplete, index, activeIndex, setActiveIndex, integrations, integrationFeatures, markPlatformComplete, advertiser, auto_params_help_docs }) => {
  if (integrationFeatures.length < 1 || auto_params_help_docs === undefined) return null;
  const integration = integrations.find(x => x.platform == platform);
  const { help_doc } = integrationFeatures.find(x => x.platform == platform && x.feature_group == 'spend_platform' && x.help_doc) || {};
  const autoParams = AUTO_PARAMS_PLATFORMS.includes(platform)

  return (
    <div className='item'>
      <Accordion.Title
        active={activeIndex === index}
        index={index}
      >
        <div className='title-row'>
          <img
            src={`https://images.rockerbox.com/integrations/${integration.logo_img}`}
            className='logo'
          />
          <span className='button-wrapper'>
            {activeIndex != index && !autoParams && <Button color='purple' inverted onClick={() => setActiveIndex(index)}>Open Instructions</Button> }
          </span>
          <Checkbox readOnly checked={platformsComplete.includes(platform)}/>
        </div>
        {autoParams &&
          <div className='auto-params'>
            <Icon name='circle info'/>
            No need to do anything here, we'll automatically update your tracking parameters in {platform == 'facebook' ? 'Facebook' : platform == 'tiktok' ? 'Tiktok': 'Google'} Ads
            (<a target="_blank" style={{cursor: 'pointer'}} href={`${auto_params_help_docs[platform]}`}>learn more</a>)
          </div>
        }
      </Accordion.Title>
      <Accordion.Content active={activeIndex === index}>
        <hr className='divider' />
        <InviteUsersWidget
          header={`Don't have access to ${integration.display_name}?`}
          subheader='Invite someone else on your team who has access to help set it up'
          advertiser={advertiser}
        />
        <Help slug={help_doc} showTitle={false}/>
        <div className='button-group'>
          <Button
            size='large'
            fluid
            inverted
            color='purple'
            onClick={() => setActiveIndex(-1)}
          >
            Close
          </Button>
          <div style={{width:'50px'}}></div>
          <Button
            size='large'
            fluid
            color='purple'
            onClick={() => {
              markPlatformComplete(platform)
              setActiveIndex(-1)  
            }}
          >
            Mark Completed
          </Button>
        </div>
      </Accordion.Content>
    </div>
  )
}


export const TrackingPlatformsList = ({ authorizedPlatforms, platformsComplete, integrations, integrationFeatures, markPlatformComplete, advertiser, auto_params_help_docs }) => {
  if (authorizedPlatforms.length < 1) return null;

  const [activeIndex, setActiveIndex] = useState(undefined);

  return (
    <div>
      <Accordion className='platforms-list'>
        {authorizedPlatforms.map((platform, index) => {
          return <Item {...{ platform, platformsComplete, index, activeIndex, setActiveIndex, integrations, integrationFeatures, markPlatformComplete, advertiser, auto_params_help_docs }} />
        })}
      </Accordion>
    </div>
  )
}

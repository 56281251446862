/* eslint-disable no-undef */
import { useMemo } from 'react';

import { computeChartData, computeMMMFeedMetrics } from './helpers';
import { colorHash } from '../../../utils/colorHash';

export const useCustomFeedData = data => {
  /**
   * Custom React hook for processing and managing custom feed data for chart rendering.
   *
   * This hook computes chart data, dimensions, dimension color mapping, and metrics based on the input data.
   * This hook ignores all data with a dimension that does not end with '_S', '_R', '_EF', or '_IMP'.
   *
   * @param {Array<Object>} data - An array of data entries, where each entry represents a single dimension/date data point.
   * @returns {Object} An object containing processed data suitable for rendering charts and tables.
   *
   * @example
   * // Usage:
   * const data = [
   *   { date: '2023-01-15', dimension: 'A_S', value: 100 },
   *   { date: '2023-01-16', dimension: 'B_R', value: 150 },
   *   { date: '2023-01-17', dimension: 'A_IMP', value: 200 },
   * ];
   *
   * const {
   *   chartData,
   *   revenueData,
   *   spendData,
   *   impData,
   *   extraData,
   *   dimensions,
   *   tierColorMap,
   *   spendMetrics,,
   *   revenueMetrics,
   *   extraMetrics,
   *   impMetrics,
   * } = useCustomFeedData(data);
   */
  const { chartData, dimensions } = useMemo(() => {
    if (!data || data.length < 1) return { chartData: [], dimensions: [] };
    return computeChartData(data);
  }, [data]);

  const tierColorMap = useMemo(() => (
    dimensions.reduce((accu, c) => {
      accu[c] = colorHash(c);
      return accu;
    }, {})
  ), [dimensions]);

  const { spendData, revenueData, extraData, impData } = useMemo(() => ({
    spendData: data.filter(({ dimension }) => dimension.endsWith('_S')),
    revenueData: data.filter(({ dimension }) => dimension.endsWith('_R')),
    extraData: data.filter(({ dimension }) => dimension.endsWith('_EF')),
    impData: data.filter(({ dimension }) => dimension.endsWith('_IMP')),
  }), [data]);

  const spendMetrics = useMemo(() => {
    if (!spendData || spendData.length < 1) return {};
    return computeMMMFeedMetrics(spendData);
  }, [spendData]);

  const revenueMetrics = useMemo(() => {
    if (!revenueData || revenueData.length < 1) return {};
    return computeMMMFeedMetrics(revenueData);
  }, [revenueData]);

  const extraMetrics = useMemo(() => {
    if (!extraData || extraData.length < 1) return {};
    return computeMMMFeedMetrics(extraData);
  }, [extraData]);

  const impMetrics = useMemo(() => {
    if (!impData || impData.length < 1) return {};
    return computeMMMFeedMetrics(impData);
  }, [impData]);

  if (!chartData || chartData.length < 1) {
    return { chartData: [], spendData: [], revenueData: [], extraData: [], impData: [], dimensions: [], metrics: undefined, tierColorMap: {} };
  }

  return {
    chartData,
    revenueData,
    spendData,
    impData,
    extraData,
    dimensions,
    tierColorMap,
    spendMetrics,
    revenueMetrics,
    extraMetrics,
    impMetrics,
  };
};

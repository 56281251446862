import React from 'react';

import { Table, Icon, Header, Pagination, Popup } from 'semantic-ui-react';

import { recordsFrom, recordsTo } from '../PagedIndexGrid/helpers';

const defaultClick = () => { };

/* Table Header */
export const TableHeaders = ({ cols, onClick = () => { }, orderBy, orderDirection }) => (
  <Table.Header>
    <Table.Row>
      {cols.map(col => {
        const { headerWidth, display, tooltip, hoverable, sortable } = col;
        // eslint-disable-next-line eqeqeq
        if (sortable == 0) {
          return (
            <Table.HeaderCell style={col.style} width={headerWidth}>
              {display}
              {tooltip && <Popup inverted content={tooltip} position="top center" trigger={<Icon name="question circle" className="tooltip-icon" />} hoverable={hoverable || false} />}
            </Table.HeaderCell>
          );
        }
        return (
          <Table.HeaderCell style={col.style} width={headerWidth} onClick={evt => onClick(evt, { value: col.key })}>
            {display}
            {tooltip && <Popup inverted content={tooltip} position="top center" trigger={<Icon name="question circle" className="tooltip-icon" />} hoverable={hoverable || false} />}
            {col.key === orderBy && orderDirection && <Icon name={`caret ${orderDirection === 'descending' ? 'down' : 'up'}`} style={{ marginLeft: 5 }} />}
          </Table.HeaderCell>
        );
      })}
    </Table.Row>
  </Table.Header>
);

/* Table Rows */
const DefaultCell = ({ item, col }) => (
  <Table.Cell width={item[col.cellWidth]}>
    <span>{item[col.key]}</span>
  </Table.Cell>
);

const writeCell = (item, col, otherProps) => {
  const CellComponent = col.as || DefaultCell;
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <CellComponent {...{ col, item, ...otherProps }} />
  );
  /* eslint-enable react/jsx-props-no-spreading */
};

export const TableRow = props => {
  const { item, cols, onClick = defaultClick, idx, data } = props;

  const hasChildren = item.childKeys && item.childKeys.length > 0;
  const nextItem = idx < data.length ? data[idx + 1] : {};
  const nextIsParent = nextItem?.depth === 0;
  const hasOpenChildren = nextItem?.key.includes(item?.key);

  const style = {
    cursor: hasChildren ? 'pointer' : 'default',
  };
  return (
    <Table.Row
      onClick={evt => onClick(evt, { value: item })}
      style={style}
      className={`depth-${item?.depth} ${hasOpenChildren && 'expanded'} ${(!nextItem || !!nextIsParent) && 'last-of-group'}`}
      verticalAlign="top"
    >
      {cols.map(col => writeCell(item, col, { ...props }))}
    </Table.Row>
  );
};

/* Footer/Pagination */
const ContentCardTableFooter = props => {
  const { children } = props;
  return (
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell colSpan="100">
          <div className="footer-content">{children}</div>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  );
};

export const Paginate = props => {
  const { data, selectedPageNumber, itemsPerPage, pageNumbers, setSelectedPageNumber } = props;
  return (
    <ContentCardTableFooter>
      <div>
        {`Showing ${recordsFrom(selectedPageNumber, itemsPerPage)}-${recordsTo(selectedPageNumber, itemsPerPage, data.length)} out of ${new Intl.NumberFormat().format(data.length)} records`}
      </div>
      <Pagination
        activePage={selectedPageNumber}
        setSelectedPageNumber={setSelectedPageNumber}
        totalPages={pageNumbers}
        firstItem={false}
        lastItem={false}
        ellipsisItem={pageNumbers > 7 ? undefined : null}
        onPageChange={(e, { activePage }) => setSelectedPageNumber(activePage)}
      />
    </ContentCardTableFooter>
  );
};

/* Misc. */
export const EmptyMessage = props => {
  const { callToAction, fallBackMsg, fallbackMsg } = props;
  return (
    <Table.Body>
      <Table.Row>
        <Table.Cell
          textAlign="center"
          selectable={false}
          className={callToAction ? undefined : 'empty'}
        >
          <br />
          <Icon
            circular
            disabled
            name="info"
            size="big"
            className="m-b-10"
          />
          <Header
            as="h3"
            disabled
            content={fallbackMsg || fallBackMsg || 'No data found'}
            className="m-0"
          />
          {callToAction && <div style={{ marginTop: '8px' }}>{callToAction}</div>}
          <br />
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  );
};

const SummaryCell = ({ compareStart, compareEnd, compare }) => {
  if (compare) {
    return (
      <Table.Cell>
        <div style={{ paddingLeft: 25 }}>
          Summary
          <br />
          <span className="summary-dates">
            {compareStart}
            {' '}
            -
            {' '}
            {compareEnd}
          </span>
        </div>
      </Table.Cell>
    );
  }
  return <Table.Cell>Summary</Table.Cell>;
};

export const SummaryRow = ({ cols, allCols, data, compareStart, compareEnd, compare, useColorGradient }) => {
  const columns = allCols || cols;
  const item = columns.reduce((accu, col) => {
    if (col.reducer) accu[col.key] = col.reducer(data);
    if (col.compareReducer) accu[col.compareKey] = col.compareReducer(data);
    return accu;
  }, {});

  return (
    <Table.Footer>
      {/* <tr className='spacer'><td></td></tr> */}
      <Table.Row style={{ fontWeight: 'bold' }}>
        {cols.map((col, i) => {
          if (i === 0) return <SummaryCell {...{ compareStart, compareEnd, compare }} />;
          if (!col.reducer) return <Table.Cell>-</Table.Cell>;
          const Component = col.as || DefaultCell;
          return <Component {...{ col, item, useColorGradient, data: [item], showPercent: col.showPercent || false, label: col.summaryLabel }} />;
        })}
      </Table.Row>
    </Table.Footer>
  );
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

class HindsightButton extends Component {
  render() {
    const { value } = this.props;
    return (
      <Button {...this.props} >
        { value ? value : this.props.children }
      </Button>
    )
  }
}

HindsightButton.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  onClick: PropTypes.func.isRequired
}

export default HindsightButton;

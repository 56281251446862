import { useEffect, useState, useMemo } from 'react';
import { useAtom } from 'jotai';
import { columnsAtom, tiersAtom, goalAtom } from '../../../atoms';
import { generateCols } from './platformConfig/displayHelpers';
import { usePlatformPerformanceConfig } from './platformConfig';
import { prettyDisplayNames, verifyColsUrl, getColumnNames, arrayMatches } from '../helpers';

export const useTreeColumns = ( // eslint-disable-line import/prefer-default-export
  platform,
  integrations, // for comparison cols
  currencyCode,
  tier,
) => {
  const [colsUrl, setColsUrl] = useAtom(columnsAtom);
  const [_tiers, setTiers] = useAtom(tiersAtom);
  const [goal] = useAtom(goalAtom);
  const [tiersNameDisplay, setTiersNameDisplay] = useState('');

  const setSelectedColumns = selected => {
    const columnNames = getColumnNames(selected);
    setColsUrl(columnNames);
  };

  // platform performance config
  const performanceConfig = usePlatformPerformanceConfig();
  const { platformConfig, hasOptionSelected } = performanceConfig;

  const allTiers = useMemo(() => platformConfig.hierarchy.slice(1), [platformConfig]);

  const allColumns = useMemo(() => {
    const platformAllColumns = generateCols({ platformConfig, hasOptionSelected, currencyCode, goal, tier });
    setTiersNameDisplay(platformAllColumns[0].display);
    return platformAllColumns;
  }, [hasOptionSelected, integrations, platform, goal, colsUrl]);

  const { groupBy = [] } = useMemo(() => (
    allColumns?.find(c => c.groupBy) || {}
  ), [allColumns]);

  const tiers = useMemo(() => {
    if (!_tiers || _tiers.length === 0) {
      return allTiers;
    }

    let found = false;

    found = _tiers[0].value ? !!allTiers.find(t => t.value === _tiers[0].value) : false;

    if (!found) {
      return allTiers;
    }

    return _tiers;
  }, [_tiers, allTiers]);

  useEffect(() => {
    if (arrayMatches(tiers, _tiers) || !_tiers || _tiers.length === 0) return;
    setTiers([]); //  todo: use jotai library reset atom
  }, [tiers, _tiers]);

  const updateColsTiersDisplayName = columns => {
    const tier1DisplayName = tiers.map(c => (c.text ? c.text.replace(' name', '') : (prettyDisplayNames(c)))).join(' / ');
    const tiersColumnIndex = columns.findIndex(col => col.key === 'group');
    setTiersNameDisplay(tier1DisplayName);
    const updatedCols = columns.slice();
    updatedCols[tiersColumnIndex].display = tier1DisplayName;
    return updatedCols;
  };

  const selectedColumns = useMemo(() => {
    if (!allColumns) return [];

    if (colsUrl.length === 0 || tiers.length === 0 || tiersNameDisplay.length === 0) {
      let selected = allColumns;
      if (tiers.length > 0) {
        selected = updateColsTiersDisplayName(allColumns);
      }
      return selected;
    }

    const verify = verifyColsUrl(colsUrl, allColumns);
    if (!verify) {
      return allColumns;
    }

    const findSelectedColumns = colsUrl.map(key => allColumns.find(col => key === col.key));
    const updatedCols = findSelectedColumns.length > 0 ? updateColsTiersDisplayName(findSelectedColumns) : findSelectedColumns;
    return updatedCols;
  }, [allColumns, colsUrl, tiers, tiersNameDisplay]);

  useEffect(() => {
    const columnNames = getColumnNames(selectedColumns);
    if (arrayMatches(colsUrl, columnNames) || colsUrl.length === 0) return;
    setColsUrl([]);
  }, [colsUrl, selectedColumns]);

  return {
    allTiers,
    tiers,
    setTiers,
    allColumns,              // used by the drawer (all Columns)
    selectedColumns,         // used by the tree and drawer (selected Columns)
    setSelectedColumns,      // used by the drawer (set selected Columns)
    groupBy,
  };
};

import React, { useState, useEffect } from 'react';
// eslint-disable-next-line no-unused-vars
import { Form, Input, Dropdown, Button, List, Divider } from 'semantic-ui-react';
// eslint-disable-next-line no-unused-vars
import { map, compose, get } from 'lodash/fp';
import { invert } from 'lodash';

import { useStepsDisplay } from '../StepsSectionContext';
import { postEmailToPermissions, getPermissionsList } from '../../../api/datalake';
import ErrorMessage from '../components/ErrorMessage';

const ENTITY_TYPE_LABEL = {
  SERVICE_ACCOUNT: 'Service Account',
  USER: 'User',
  GROUP: 'Group',
};

const ENTITY_TYPES = {
  SERVICE_ACCOUNT: 'serviceAccount',
  USER: 'user',
  GROUP: 'group',
};

const getEntityTypesOptions = (types, labels) => Object.keys(types).map(key => ({
  text: labels[key],
  value: types[key],
}));

const ROLE = 'roles/storage.objectViewer';

const SetupPermissions = () => {
  // eslint-disable-next-line no-unused-vars
  const [stepDisplay, setStepDisplay] = useStepsDisplay();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [entityType, setEntityType] = useState(ENTITY_TYPES.SERVICE_ACCOUNT);
  const [loading, setLoading] = useState(false);
  const [storagePrinciples, setStoragePrinciples] = useState([]);

  const entityTypeOptions = getEntityTypesOptions(ENTITY_TYPES, ENTITY_TYPE_LABEL);

  const handleEmailInputOnChange = (_, { value }) => setEmail(value);
  const handleEntityTypeOnChange = (_, { value }) => setEntityType(value);

  const loadPermissionsList = role => getPermissionsList(role)
    .then(({ data }) => {
      setStoragePrinciples(data.response[0].members);
    });

  const handleSubmitPermissions = () => {
    setError('');
    setLoading(true);
    postEmailToPermissions(email, entityType)
      .then(resp => {
        setStepDisplay(2, true);
        setLoading(false);
        loadPermissionsList(ROLE);
        setEmail('');
        return resp;
      })
      .catch(e => {
        setStepDisplay(2, true);
        setLoading(false);
        setError(e.response.data.message);
      });
  };

  useEffect(() => {
    loadPermissionsList(ROLE);
  }, []);

  const selectPrincipalsDisplay = map(principal => ({
    type: principal.split(':')[0],
    email: principal.split(':')[1],
    value: principal,
  }));

  const getPrincipalTypeDisplay = type => {
    const typeValue = invert(ENTITY_TYPES)[type];
    return ENTITY_TYPE_LABEL[typeValue];
  };

  return (
    <>
      Emails that have access to warehousing:
      <List bulleted style={{ paddingLeft: '10px' }}>
        {
          selectPrincipalsDisplay(storagePrinciples).map(item => (
            <List.Item>
              {item.email}
              {' '}
              -
              {getPrincipalTypeDisplay(item.type)}
            </List.Item>
          ))
        }
      </List>
      <Form>
        <Form.Group>
          <Form.Field width={4}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Email</label>
            <Input
              placeholder="Enter email"
              value={email}
              onChange={handleEmailInputOnChange}
            />
          </Form.Field>
          <Form.Field width={4}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Type</label>
            <Dropdown
              selection
              defaultValue={ENTITY_TYPES.SERVICE_ACCOUNT}
              options={entityTypeOptions}
              onChange={handleEntityTypeOnChange}
            />
          </Form.Field>
        </Form.Group>
        <Button
          primary
          onClick={handleSubmitPermissions}
          content="Add permissions"
          disabled={!email || loading}
          {...{ loading }}
        />
        {error && <ErrorMessage message={error} />}
      </Form>
    </>
  );
};

export default SetupPermissions;

import React, { Component } from "react";
import { Segment, Form, Button } from "semantic-ui-react";

const inputPositionNumbers = ["one", "two", "three", "four", "five"];
const eventTypeOptions = [
  { text: "Live Pixel", value: "served_source_hash_ip_urls" },
  { text: "Batch Data", value: "hash_ip_events_source_tiers" },
  { text: "Tatari", value: "generic_events" },
];

const TiersFilter = (props) => {
  const {
    showCurrent,
    index,
    handleTierFieldChange,
    handleAddField,
    handleRemoveField,
    removeTierFilter,
    tiers_filter,
  } = props;
  const currentFields = inputPositionNumbers.slice(
    0,
    inputPositionNumbers.indexOf(showCurrent) + 1
  );

  return (
    <Form.Group>
      {currentFields.map((value) => {
        const key = "tier_" + value;

        return (
          <Form.Input
            placeholder={key}
            value={(tiers_filter[index] && tiers_filter[index][key]) || ""}
            onChange={handleTierFieldChange(key, index)}
          />
        );
      })}
      <Button
        icon="plus"
        onClick={() => handleAddField(index)}
        style={{
          display: showCurrent == "five" ? "none" : "inline-block",
        }}
        as="span"
      />
      <Button
        icon="minus"
        onClick={() => handleRemoveField(index)}
        style={{
          display: showCurrent == "one" ? "none" : "inline-block",
        }}
        as="span"
      />
      <Button
        secondary
        size={"tiny"}
        icon="minus"
        content="Remove Tier"
        onClick={() => removeTierFilter(index)}
        disabled={tiers_filter.length <= 1}
        style={{
          position: "absolute",
          right: "29%",
          marginTop: "4px",
        }}></Button>
    </Form.Group>
  );
};
class CrossDeviceEventCard extends Component {
  state = {
    showCurrent: ["one"],
  };

  componentDidUpdate(prevProps) {
    if (prevProps.tiers_filter != this.props.tiers_filter) {
      const { event, tiers_filter } = this.props;

      const showCurrent = tiers_filter.map((filter) => {
        return inputPositionNumbers.reduce((p, c) => {
          if (filter[`tier_${c}`]) {
            p = c;
          }
          return p;
        }, "one");
      });
      this.setState({ showCurrent });
    }
  }

  handleAddField = (index) => {
    const { showCurrent } = this.state;
    const nextIndex = inputPositionNumbers.indexOf(showCurrent[index]) + 1;

    const newState = showCurrent.map((current, i) => {
      if (i === index) {
        return inputPositionNumbers[nextIndex];
      }
      return current;
    });
    this.setState({ showCurrent: newState });
  };

  handleRemoveField = (index) => {
    const { showCurrent } = this.state;
    const nextIndex = inputPositionNumbers.indexOf(showCurrent[index]) - 1;
    const { removeTierField } = this.props;

    const newState = showCurrent.map((current, i) => {
      if (i === index) {
        return inputPositionNumbers[nextIndex];
      }
      return current;
    });

    removeTierField(`tier_${showCurrent[index]}`, index);
    this.setState({ showCurrent: newState });
  };

  render() {
    const {
      event,
      handlerEventFieldChange,
      handleFormSubmit,
      eventIndex,
      addNewTierFilter,
      removeTierFilter,
      tiers_filter,
    } = this.props;
    const { showCurrent } = this.state;
    const currentFields = inputPositionNumbers.slice(
      0,
      inputPositionNumbers.indexOf(showCurrent) + 1
    );

    return (
      <Segment secondary>
        <Form>
          <Form.Field width={10}>
            <Form.Input
              label="Vendor:"
              value={event["event_source"]}
              onChange={handlerEventFieldChange("event_source")}
            />
          </Form.Field>
          <Form.Field width={10}>
            <Form.Select
              label="Event Type:"
              options={eventTypeOptions}
              value={event["event_table_name"]}
              onChange={handlerEventFieldChange("event_table_name")}
            />
          </Form.Field>

          <div className="field" style={{ margin: 0 }}>
            <label>Tiers:</label>
          </div>
          {showCurrent.map((current, i) => (
            <React.Fragment>
              <TiersFilter
                {...this.props}
                showCurrent={current}
                index={i}
                handleAddField={this.handleAddField.bind(this)}
                handleRemoveField={this.handleRemoveField.bind(this)}
              />
            </React.Fragment>
          ))}
        </Form>
        <Button
          secondary
          size={"tiny"}
          icon="plus"
          content="Add Tier"
          onClick={() => addNewTierFilter()}
          style={{ marginTop: "25px" }}></Button>
      </Segment>
    );
  }
}

export default CrossDeviceEventCard;

import { useState, useEffect } from 'react';

import { buildNestedData, filterCustomerPath, buildTiers, summarizePaths } from './helpers';
import { getTierColors } from '../../api/account';
import { getLegacyPaths } from '../../api/attributionCache';

export const useCustomerPaths = (filtersAndDates, filterTouchpoints, filterTouchpointsMethods) => {
  const { id, startDate, endDate, customerType, tier } = filtersAndDates;
  const { minPathLen, any, first, last, andOr } = filterTouchpoints;
  const { setAny, setFirst, setLast } = filterTouchpointsMethods;

  const [pathsData, setPathsData] = useState(undefined);
  const [allNested, setAllNested] = useState({});
  const [ntfNested, setNtfNested] = useState({});
  const [returnNested, setReturnNested] = useState({});
  const [resolved, setResolved] = useState(false);
  const [error, setError] = useState(false);
  const [customerPaths, setCustomerPaths] = useState([]);
  const [tiers, setTiers] = useState({});
  const [firstTiers, setFirstTiers] = useState({});
  const [lastTiers, setLastTiers] = useState({});
  const [summaryStats, setSummaryStats] = useState({ overallSummary: [], filteredSummary: [], percentOfTotal: {} });
  const [newCustomers, setNewCustomers] = useState([]);
  const [hasData, setHasData] = useState(false);

  const customerTypeMapping = {
    all: { key: '', nestedObj: allNested },
    new: { key: '_new_to_file', nestedObj: ntfNested },
    repeat: { key: '_returning', nestedObj: returnNested },
  };

  useEffect(() => {
    if ((!id || id === '0') || !tier || !startDate || !endDate) return;
    setResolved(false);
    setHasData(false);
    getLegacyPaths(id, tier, startDate, endDate)
      .then(data => {
        const updatedData = data.map(row => {
          const updatedRow = { ...row };
          updatedRow.count_returning = updatedRow.count - updatedRow.count_new_to_file;
          updatedRow.revenue_returning = updatedRow.revenue - updatedRow.revenue_new_to_file;

          const totalSeconds = updatedRow.count * updatedRow.avg_seconds_til_conversion;
          const ntfSeconds = updatedRow.count_new_to_file * updatedRow.avg_seconds_til_conversion_new_to_file;

          updatedRow.avg_seconds_til_conversion_returning = (totalSeconds - ntfSeconds) / updatedRow.count_returning;

          return updatedRow;
        });

        const ntfKeys = customerType => ({ // eslint-disable-line no-shadow
          count: `count${customerTypeMapping[customerType].key}`,
          revenue: `revenue${customerTypeMapping[customerType].key}`,
          avg_seconds_til_conversion: `avg_seconds_til_conversion${customerTypeMapping[customerType].key}`,
        });

        const allNestedData = buildNestedData(any, updatedData, ntfKeys('all'));
        const ntfNestedData = buildNestedData(any, updatedData, ntfKeys('new'));
        const returnNestedData = buildNestedData(any, updatedData, ntfKeys('repeat'));

        setAllNested(allNestedData);
        setNtfNested(ntfNestedData);
        setReturnNested(returnNestedData);
        setPathsData(updatedData);
        setHasData(true);

        return allNestedData;
      })
      .catch(err => { // eslint-disable-line no-unused-vars
        setError(true);
        setResolved(true);
      });
  }, [id, tier, startDate, endDate]);

  useEffect(() => {
    const { originalCustomerPath, originalTiers } = allNested;

    if (!originalCustomerPath || !ntfNested.originalCustomerPath || !returnNested.originalCustomerPath) return;
    if (Object.keys(originalTiers).length !== Number(tier)) return;
    const customerPath = filterCustomerPath(any, first, last, andOr, minPathLen, customerTypeMapping[customerType].nestedObj);
    const newCustomersData = filterCustomerPath(any, first, last, andOr, minPathLen, customerTypeMapping.new.nestedObj);
    const [tiers, updateAny] = buildTiers(any, customerTypeMapping[customerType].nestedObj, 'any'); // eslint-disable-line no-shadow
    const [firstTiers, updateFirst] = buildTiers(first, customerTypeMapping[customerType].nestedObj, 'first'); // eslint-disable-line no-shadow
    const [lastTiers, updateLast] = buildTiers(last, customerTypeMapping[customerType].nestedObj, 'last'); // eslint-disable-line no-shadow
    setTiers(tiers);
    setFirstTiers(firstTiers);
    setLastTiers(lastTiers);
    setAny(updateAny);
    setFirst(updateFirst);
    setLast(updateLast);
    setCustomerPaths(customerPath);
    setNewCustomers(newCustomersData);
  }, [first, last, any, andOr, customerType, minPathLen, pathsData, allNested, ntfNested, returnNested]);

  useEffect(() => {
    const allNestedOriginalCustomerPath = allNested.originalCustomerPath;
    const ntfNestedOriginalCustomerPath = ntfNested.originalCustomerPath;
    const returnNestedOriginalCustomerPath = returnNested.originalCustomerPath;

    if ((!allNestedOriginalCustomerPath || !ntfNestedOriginalCustomerPath || !returnNestedOriginalCustomerPath || !customerPaths)) return;
    const overallSummary = summarizePaths(allNestedOriginalCustomerPath || []);
    const ntfSummary = summarizePaths(ntfNestedOriginalCustomerPath || []);
    const returnNestedSummary = summarizePaths(returnNestedOriginalCustomerPath || []);
    const summaryDict = {
      all: overallSummary,
      new: ntfSummary,
      repeat: returnNestedSummary,
    };

    const selectedDataset = summaryDict[customerType];

    const filteredSummary = summarizePaths(customerPaths || []);

    const percentOfTotal = {
      paths: (filteredSummary.totalPaths / selectedDataset.totalPaths) * 100,
      conversions: (filteredSummary.conversions / selectedDataset.conversions) * 100,
      revenue: selectedDataset.averageRevenue - filteredSummary.averageRevenue,
      touchpoints: selectedDataset.averageLength - filteredSummary.averageLength,
      time: selectedDataset.averageTime - filteredSummary.averageTime,
    };
    setSummaryStats({ overallSummary: selectedDataset, filteredSummary, percentOfTotal });
  }, [allNested, customerPaths]);

  return {
    pathsData,
    allNested,
    resolved,
    error,
    customerPaths,
    tiers,
    firstTiers,
    lastTiers,
    summaryStats,
    newCustomers,
    hasData,
  };
};

export const useTierColors = () => {
  const [tierColors, setTierColors] = useState(false);

  useEffect(() => {
    getTierColors()
      .then(data => setTierColors(data));
  }, []);

  return {
    tierColors,
  };
};

import { useMemo } from 'react';

import { forecastData } from './forecastData';
import { calcAverageBaselineFromSeasonality, summarizeForecastRows, fieldValuesToCurrentSpend } from './forecastHelpers';
import { useProjectionPeriod } from '../BudgetForecast/form/projection/useProjectionPeriod';
import { buildForecastRows } from '../BudgetForecast/table/forecastTableRows';

export const useForecast = ({ fieldValues, responseCurves, featureStats, seasonality }) => {
  const { projectionPeriod: numDays } = useProjectionPeriod();
  const forecastedData = useMemo(() => forecastData(responseCurves, fieldValues), [responseCurves, fieldValues]);

  const currentSpendValues = useMemo(() => fieldValuesToCurrentSpend(fieldValues), [fieldValues]);

  const currentForecastedData = useMemo(() => forecastData(responseCurves, currentSpendValues), [responseCurves, currentSpendValues]);

  const forecastRows = useMemo(() => {
    if (!responseCurves || !featureStats) return [];
    return buildForecastRows(fieldValues, responseCurves, currentSpendValues, currentForecastedData, forecastedData, numDays);
  }, [fieldValues, responseCurves, currentSpendValues, currentForecastedData, forecastedData, numDays]);

  const baseline = useMemo(
    () => calcAverageBaselineFromSeasonality(seasonality, numDays),
    [seasonality, numDays],
  );

  const summary = useMemo(() => summarizeForecastRows(forecastRows, featureStats, baseline, numDays), [forecastRows, numDays]);

  return {
    data: { forecastRows, baseline, summary },
  };
};

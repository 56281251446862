import React from "react";
import { ContentCard, SplitLayout } from "@rockerbox/styleguide";
import { getOffsetOptions } from "./helper";
import OffsetAreaChart from "./AreaChart";
import ErrorPage from "./ErrorPage";
import SettingForm from "./OttOffsetSetting";

const OttAnalysis = props => {
  const {
    loading,
    chartData,
    offset,
    setOffset,
    error,
    firstMenu,
    apiOffset
  } = props;
  const { startDate, endDate } = props.match.params;
  const areaKeys = ["device", "web", "mobile"];
  const offsetType = firstMenu ? firstMenu.split("_")[1] : null;

  if (error) {
    return <ErrorPage />;
  }

  return areaKeys.map(areaKey => {
    const type = areaKey.charAt(0).toUpperCase() + areaKey.slice(1);
    const defaultOffset =
      offsetType &&
      apiOffset[`${offsetType}_offset`] &&
      apiOffset[`${offsetType}_offset`][areaKey];
    const currentOffset =
      offsetType &&
      offset[`${offsetType}_offset`] &&
      offset[`${offsetType}_offset`][areaKey];
    const offsetOptions = getOffsetOptions(chartData, areaKey).map(item =>
      Object.assign(item, { text: `${item.text} (${item.value} mins)` })
    );
    const offsetItem =
      currentOffset && chartData.find(item => item.offset == currentOffset);
    const total =
      chartData.length > 0 ? chartData[chartData.length - 1][areaKey][1] : 0;
    const percentage =
      Math.floor(offsetItem && (offsetItem[areaKey][1] * 100) / total) || 0;

    if (defaultOffset) {
      const currentItem = {
        pcg: 1,
        key: 0,
        text: `Current (${defaultOffset} mins)`,
        value: defaultOffset
      };
      offsetOptions.unshift(currentItem);
    }

    return (
      <ContentCard
        title={`Setup ${type} Eligibility Time window`}
        explainer=""
        loading={loading}
        key={areaKey}
      >
        <SplitLayout
          leftWidth={8}
          leftContent={
            <OffsetAreaChart
              offset={currentOffset}
              data={chartData}
              offsetItem={offsetItem}
              areaKey={areaKey}
            />
          }
          rightWidth={8}
          rightContent={
            <SettingForm
              options={offsetOptions}
              setOffset={setOffset}
              offsetItem={offsetItem}
              offset={offset}
              offsetType={offsetType}
              currentOffset={currentOffset}
              areaKey={areaKey}
              chartData={chartData}
              total={total}
              percentage={percentage}
            />
          }
        />
      </ContentCard>
    );
  });
};

export default OttAnalysis;

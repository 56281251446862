import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Input, Icon, Popup } from 'semantic-ui-react';
import { useAtom } from 'jotai';
import _ from 'lodash';
import styled from 'styled-components';
import { analysisAtom, analysisSearchAtom } from '../atoms';

const Label = styled.div`
  font-family: 'Poppins' !important;
  color: #333333;
`;

function DebouncedSearch({ onChange, value, resetFilters, disabled, label = true, placeholder }) {
  const [analysisType] = useAtom(analysisAtom);
  const [analysisSearch] = useAtom(analysisSearchAtom);
  const initialRender = useRef(true);
  const [searchVal, setSearchVal] = useState(value);
  const hasSearch = searchVal.length > 0;

  const handleSearch = searchValue => {
    // skip first render so saved views will work
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    onChange(false, { value: searchValue });
  };

  useEffect(() => {
    setSearchVal(value);
  }, [value]);

  const debouncedResults = useCallback(_.debounce(v => handleSearch(v), 300), []);

  useEffect(() => {
    debouncedResults(searchVal);
  }, [searchVal]);

  const disableSearchForDerivedTiers = !!analysisSearch.length;

  return (
    <div>
      {label && (
        <Label>
          Search
        </Label>
      )}
      <div style={{ position: 'relative' }}>
        <Input
          className="debounce-search"
          icon={(
            <Icon
              name="search"
              style={{
                color: `${disabled ? '#f0f0f0' : !hasSearch ? '#475ddc' : '#fff'}`,
              }}
            />
          )}
          onChange={(evt, { value: v }) => setSearchVal(v.toLowerCase())}
          value={analysisSearch ? '' : searchVal}
          disabled={disabled || disableSearchForDerivedTiers}
          placeholder={placeholder}
        />
        {(hasSearch && !disabled) && (
          <Icon
            onClick={() => setSearchVal('')}
            name="close"
            color="purple"
            size="small"
            circular
            style={{
              backgroundColor: '#fff',
              cursor: 'pointer',
              position: 'absolute',
              top: 9,
              right: 5,
            }}
          />
        )}
        {(hasSearch && disabled) && (
          <Popup
            content="Clearing your search term will remove all tier filters."
            trigger={(
              <Icon
                onClick={() => setSearchVal('')}
                name="close"
                color="purple"
                size="small"
                circular
                style={{
                  backgroundColor: '#fff',
                  cursor: 'pointer',
                  position: 'absolute',
                  top: 9,
                  right: 5,
                }}
              />
            )}
          />
        )}
        {(!hasSearch && disabled) && (
          <Popup
            hoverable
            content={(
              <p>
                Please&nbsp;
                <span
                  tabIndex={0}
                  role="button"
                  onClick={() => resetFilters()}
                  onKeyDown={() => resetFilters()}
                  style={{ color: '#475DDC', cursor: 'pointer', textDecoration: 'underline' }}
                >
                  clear your tier filters
                </span>
                &nbsp;
                to begin a new search.
              </p>
            )}
            trigger={(
              <Icon
                name="info"
                color="purple"
                size="small"
                circular
                style={{
                  backgroundColor: '#fff',
                  cursor: 'pointer',
                  position: 'absolute',
                  top: 9,
                  right: 5,
                }}
              />
            )}
          />
        )}
        {disableSearchForDerivedTiers && (
          <Popup
            hoverable
            content={(
              <p>
                {`Please clear your ${analysisType} selection ${analysisSearch} to begin a new search.`}
              </p>
            )}
            trigger={(
              <Icon
                name="info"
                color="purple"
                size="small"
                circular
                style={{
                  backgroundColor: '#fff',
                  cursor: 'pointer',
                  position: 'absolute',
                  top: 9,
                  right: 5,
                }}
              />
            )}
          />
        )}
      </div>
    </div>
  );
}

export default DebouncedSearch;

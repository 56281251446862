import React from 'react';

import PropTypes from 'prop-types';
import { Popup, Icon } from 'semantic-ui-react';

const StatusCell = ({ states }) => {
  const statesObj = states.reduce((p, c) => Object.assign(p, { [c.key]: c }), {});

  return ({ cell }) => {
    const match = statesObj[cell.value];
    if (!match) return null;

    const { content, icon, color } = match;

    return (
      <Popup content={content} trigger={<Icon name={icon} color={color} />} />
    );
  };
};
StatusCell.propTypes = {
  /** Object of states */
  states: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    icon: PropTypes.string,
    color: PropTypes.string,
  })).isRequired,
  /** */
};

export default StatusCell;

import * as aq from 'arquero';

const arqHasColumn = (arch, column) => arch.columnNames().includes(column);

const arqZeroType = (arch, column) => {
  const columnData = arch._data[column].data;
  const first = columnData[0];

  if (typeof (first) === 'bigint') return 'n';
  return '';
};

const arqBuildSums = (arch, columns) => columns
  .reduce((accu, tier) => {
    if (!arqHasColumn(arch, tier)) return accu;

    const zero = arqZeroType(arch, tier);
    accu[tier] = `(d) => op.sum(d.${tier} || 0${zero})`;
    return accu;
  }, {});

export const sumColumnsByGroup = (data, columns, group) => data
  .groupby(...group)
  .rollup(arqBuildSums(data, columns));

export const sumNumericColumnsByGroup = (data, groupby) => {
  const numericCols = data
    .columnNames()
    .filter(col => {
      const typeOf = typeof (data._data[col].data[0]);
      if (typeOf === 'bigint') return true;
      if (typeOf === 'number') return true;
      return false;
    });

  return sumColumnsByGroup(data, numericCols, groupby);
};

export const validateJoinColumns = (left, right, leftValidateCols, rightValidateCols) => {
  const leftDataCols = left.columnNames();
  const rightDataCols = right.columnNames();

  const missingColumns = [
    ...leftValidateCols.filter(col => !leftDataCols.includes(col)),
    ...rightValidateCols.filter(col => !rightDataCols.includes(col)),
  ];

  if (missingColumns.length) throw new Error(`Missing columns on attempted join ${missingColumns}`);
};

export const deriveNumberFromBigIntColumns = data => {
  const bigIntColumns = data
    .columnNames()
    .filter(col => {
      const typeOf = typeof (data.array(col)[0]);
      if (typeOf === 'bigint') return true;
      return false;
    });

  const derive = bigIntColumns
    .reduce((accu, curr) => {
      accu[curr] = aq.escape(d => Number(d[curr]));
      return accu;
    }, {});

  return derive;
};

export const deriveRatiosFromRatioObject = (data, ratioObject) => Object.entries(ratioObject)
  .reduce((accu, [key, values]) => {
    const numerator = values[0];
    const denominator = values[1];

    const columns = data.columnNames();
    if (!columns.includes(numerator)) return accu;
    if (!columns.includes(denominator)) return accu;

    accu[key] = `d => d.${numerator} / d.${denominator}`;
    return accu;
  }, {});

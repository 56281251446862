import React from 'react';

import { FileDropzone } from './FileDropzone';

const Upload = ({ onParse, onDropAccepted, onDropRejected, filename, invalidFile }) => (
  <div>
    <h3>We need the latest marketing data to update your model, so we&apos;ll start by uploading your data.</h3>
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        <FileDropzone {...{ onParse, onDropAccepted, onDropRejected, filename, invalidFile, acceptedFileTypes: { 'text/csv': ['.csv'] } }} />
      </div>
    </div>
  </div>
);

export default Upload;

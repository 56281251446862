import { useMemo } from 'react';

import { useAtom } from 'jotai';

import { calculateDifferenceInDays, getPeriodName } from '../../../parts/time';
import { startDateProjectionAtom, endDateProjectionAtom } from '../../atoms';

export const useProjectionPeriod = () => {
  const [startDate, setStartDate] = useAtom(startDateProjectionAtom);
  const [endDate, setEndDate] = useAtom(endDateProjectionAtom);

  const projectionPeriod = useMemo(() => calculateDifferenceInDays(startDate, endDate), [startDate, endDate]);
  const projectionName = useMemo(() => getPeriodName(projectionPeriod, startDate, endDate), [projectionPeriod, startDate, endDate]);

  const onDateChange = ({ startDate: start, endDate: end }) => {
    setStartDate(start);
    setEndDate(end);
  };

  return {
    projectionPeriod,
    projectionName,
    startDate, endDate,
    onDateChange,
  };
};

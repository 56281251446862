import React from "react";
import { Dropdown } from "semantic-ui-react";

const nameMap = {
  do_spaces: "Email",
  amazon_s3: "S3",
  sftp: "SFTP",
  ftp: "FTP",
  googlecloud_storage: "Google Cloud Storage"
};

const MenuList = props => {
  const { outbox_types, onItemClick } = props;
  return outbox_types.map(type => (
    <Dropdown.Item
      text={nameMap[type]}
      key={type}
      onClick={onItemClick(type)}
    ></Dropdown.Item>
  ));
};

export const NewReportDropdownGroup = props => {
  const { onItemClick, outbox_types, loadingOutbox } = props;
  return (
    <Dropdown
      text="Schedule New Report"
      button
      className="primary"
      loading={loadingOutbox}
      disabled={!outbox_types || outbox_types.length === 0}
    >
      <Dropdown.Menu>
        <MenuList {...props} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

import React, { useState, useEffect } from 'react'
import {
  Grid,
  Segment,
  Image,
  Header,
  Button,
} from 'semantic-ui-react'

import OnboardingProgress from './OnboardingProgress'

import { homePageView, logoColorVector } from '@rockerbox/styleguide'

const Welcome = ({ completeStep, tier }) => {
  const welcomeText = tier === 'starter' ? 'Welcome to Rockerbox Starter' : 'Welcome to Rockerbox'
  return (
    <Segment
      basic
      textAlign='center'
    >
      <Image
        centered
        src={homePageView}
        size='big'
        style={{aspectRatio: 'auto'}}
      />
      <Header as='h1'>
        {welcomeText}
        <Header.Subheader>
          You're one step closer to clearer marketing insights with first-party attribution!
        </Header.Subheader>
        <Header.Subheader>
          Complete the following steps to get your account set up.<br/>(Should take 5-10 minutes)
        </Header.Subheader>
      </Header>
      <Button
        size='huge'
        color='purple'
        content='Get Started'
        onClick={completeStep}
      />
    </Segment>
  )
}

const Start = ({ completeStep, completedOnboardingSteps, setActiveStep, tier }) => {
  const [visible, setVisible] = useState(false)
  const showWelcome = !completedOnboardingSteps.includes('start');

  useEffect(() => {
    window.setTimeout(() => setVisible(true), 1000);
  }, []);

  return (
    <>
      <div style={{ position: 'absolute', left: '25px', top: '20px', zindex: 10000 }}>
        <img src={logoColorVector} width="205" />
      </div>
      <div className='onboarding-welcome-view'>
        <Grid
          centered
          columns={1}
          verticalAlign='middle'
          padded='vertically'
          
        >
          <Grid.Row>
            <Grid.Column>
              { showWelcome ?
                <Welcome {...{ completeStep, tier }} />
                :
                <OnboardingProgress {...{completedOnboardingSteps, setActiveStep, tier }} />
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </>
  )
}

export default Start

/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';

import BudgetForecast from './BudgetForecast';
import ChannelOptimization from './ChannelOptimization';
import { useMenu } from './hooks/useMenu';
import { useModelData } from './hooks/useModelData';
import MarketingPerformance from './MarketingPerformance';
import ModelInfo from './ModelInfo';
import ModelRefresh from './ModelRefresh';
import { MenuWrapper } from './parts/stateless';
import WaterfallComparison from './WaterfallComparison';
import { track } from '../../../utils/tracking';
import { forecastViewAtom, useAtomViaDropdown } from '../../atoms';
import { useGlobalState } from '../../hooks/global';

const UNLOCK_MMM_UPLOADER_ADVERTISERS = ['brunt_workwear', 'newton_baby', 'virtue_labs'];
const CONVERSION_KPI_CUSTOMERS = ['bonafide_health', 'weight_watchers_subscription', 'testmmm7', 'wildgrain', 'ollie'];
const HIDE_SIDEBAR_ADVERTISERS = ['avocadogreenmattress', 'ec2-demoscenarioplanner-rbtest'];

const ChosenView = ({ selectedItem, channelOptData, marketingPerformanceData, forecastData, waterfallData, detailsData }) => {
  switch (selectedItem) {
    case 'Channel Overview':
      return <ChannelOptimization {...channelOptData} />;
    case 'Marketing Performance':
      return <MarketingPerformance {...marketingPerformanceData} />;
    case 'Build a Media Plan':
      return <BudgetForecast {...forecastData} />;
    case 'Compare Performance':
      return <WaterfallComparison {...waterfallData} />;
    case 'Model Details':
      return <ModelInfo {...detailsData} />;
    case 'Build Training Data':
      return <ModelRefresh />;
    default:
      return null;
  }
};

const MMMView = () => {
  const { modelData, features, spendFeatures, featureNameMap, spendChannels, responseCurves, featureStats, trainingStats, modelDate } = useModelData();
  const { trainingData } = modelData || {};
  const { user, account } = useGlobalState();
  const { pixel_source_name } = account;
  const isConversionKPI = !!CONVERSION_KPI_CUSTOMERS.includes(pixel_source_name);

  const { seasonality } = modelData || {};

  const items = HIDE_SIDEBAR_ADVERTISERS.includes(pixel_source_name) ? ['Build a Media Plan'] : [
    'Marketing Performance',
    'Build a Media Plan',
    'Compare Performance',
    'Channel Overview',
    'Model Details',
  ];

  const isAdminUser = (user.username || '').includes('@rockerbox.com');
  const isUnlockedAdvertiser = UNLOCK_MMM_UPLOADER_ADVERTISERS.includes(account?.pixel_source_name);
  const isDemoAccount = pixel_source_name === 'ec2-demoscenarioplanner-rbtest';
  if ((isAdminUser || isUnlockedAdvertiser) && !isDemoAccount) items.push('Build Training Data');

  const { Component: ModelEvaluationMenu, selectedItem } = useMenu(items, items[0], forecastViewAtom, useAtomViaDropdown, true);

  useEffect(() => {
    track('modeling.mmm.view');
  }, []);

  const channelOptData = { isConversionKPI, channels: spendChannels, features: spendFeatures, featureStats, modelDate, modelData, responseCurves };
  const marketingPerformanceData = { isConversionKPI, featureNameMap, modelData, responseCurves };
  const forecastData = { isConversionKPI, features, featureNameMap, featureStats, responseCurves, seasonality, trainingData, trainingStats };
  const waterfallData = { isConversionKPI, featureNameMap, modelData, trainingData, responseCurves };
  const channelData = { isConversionKPI, features, featureStats, responseCurves, seasonality };
  const detailsData = { isConversionKPI, modelData, modelDate, features, featureNameMap };

  return (
    <div className="mmm-view">
      <MenuWrapper>
        <ModelEvaluationMenu />
      </MenuWrapper>
      <div className="right-content" style={{ flex: 7, maxWidth: '85%' }}>
        <ChosenView {...{ selectedItem, marketingPerformanceData, channelOptData, forecastData, waterfallData, channelData, detailsData }} />
      </div>
    </div>
  );
};

export default MMMView;

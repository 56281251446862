import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { dataToRatio } from './helpers';
import ChartTooltip from '../ChartTooltip';
import { reformatDateMMDDYYYY } from '../helpers';

const HorizontalStackedBar = ({
  items, data, colors, dataKey = 'name', alphabetize, // dataset
  width, height, margin, // chart size
  xType, hideXAxis = true, xDomain = ['dataMin', 'dataMax'], // x-axis
  yType, hideYAxis = true, yAxisLine = false, yAxisTick = false, yAxisWidth, yAxisStyle, // y-axis
  legend, barSize, dataAsRatio, animate = true, // other
  showTooltip, formatter, showTotalInToolTip, hideKeys = [], // tooltip
}) => {
  const xAxis = hideXAxis || false;
  const yAxis = hideYAxis || false;
  const xAxisType = xType || 'number';
  const yAxisType = yType || 'category';
  const toolTipValueFormatter = formatter?.format ? formatter.format
    : formatter || (v => v);

  const [dataSet, setDataSet] = useState(data);
  const [renderItems, setRenderItems] = useState([]);

  useEffect(() => {
    let formatData = data;
    if (dataAsRatio) {
      formatData = dataToRatio(formatData);
    }
    setDataSet(formatData);
    if (items) {
      setRenderItems(items);
      return;
    }
    const itemsFormatted = [];
    data.forEach(d => {
      itemsFormatted.push(Object.keys(d));
    });
    const uniqueItems = items || (alphabetize ? [...new Set(itemsFormatted.flat())].sort() : [...new Set(itemsFormatted.flat())]);

    setRenderItems(uniqueItems);
  }, [dataAsRatio, data]);

  const toolTipLabelFormatter = v => {
    const hasDate = Object.keys(data[0]).includes('date');
    if (hasDate) {
      return reformatDateMMDDYYYY(v);
    }
    return v;
  };

  return (
    <ResponsiveContainer width={width || '100%'} height={height || 200}>
      <BarChart
        data={dataSet}
        margin={margin || {
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        layout="vertical"
        barSize={barSize || 20}
        isAnimationActive={animate}
      >
        <XAxis
          type={xAxisType}
          hide={xAxis}
          domain={dataAsRatio ? [0, 100] : xDomain}
        />
        <YAxis
          type={yAxisType}
          dataKey={dataKey}
          hide={yAxis}
          axisLine={yAxisLine}
          tickLine={yAxisTick}
          width={yAxisWidth}
          tick={yAxisStyle}
        />
        {showTooltip
          && (
          <Tooltip
            cursor={false}
            formatter={dataAsRatio ? value => (value / 100).toLocaleString('en-US', { style: 'percent' }) : toolTipValueFormatter}
            labelFormatter={v => toolTipLabelFormatter(v)}
            content={<ChartTooltip {...{ dataKey, showTotalInToolTip, hideKeys }} />}
            wrapperStyle={{ zIndex: 3 }}
          />
          )}
        {legend && <Legend />}
        {renderItems
          .filter(k => k != { dataKey }) // eslint-disable-line eqeqeq
          .map(k => (
            <Bar
              key={k}
              dataKey={k}
              stackId="a"
              fill={colors[k]}
            />
          ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

HorizontalStackedBar.propTypes = {
  as: PropTypes.element,
  /** Array of objects */
  data: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line react/forbid-prop-types
  /** Array of bar objects */
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    display: PropTypes.string,
    fill: PropTypes.string.isRequired,
  })).isRequired,
  /** Optional if no data is available */
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  xType: PropTypes.string, // "number"|| "category"
  yType: PropTypes.string, // "number" || "category"
  hideXAxis: PropTypes.bool,
  hideYAxis: PropTypes.bool,
  yAxisLine: PropTypes.bool,
  yAxisTick: PropTypes.bool,
  legend: PropTypes.bool,
  hideTooltip: PropTypes.bool,
  barSize: PropTypes.number,
};

export default HorizontalStackedBar;

/*

  const items = [
    { display: 'uv', key: 'uv', fill: '#8884d8' },
    { display: 'pv', key: 'pv', fill: '#82ca9d'},
    { display: 'pv', key: 'amt', fill: 'red'},
  ];

  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210
    },
  ];
*/

import React, { Component } from 'react'
import _ from 'lodash'
import { Header } from 'semantic-ui-react'
import { AppWrap, ContentWrap, ContentCard, ReportSchemaTable } from '@rockerbox/styleguide'
import { getReportSchema } from '../../utils/api'

class ReportSchema extends Component {
  state = {
    schema: null,
    loading: true,
  }

  componentDidMount() {
    getReportSchema(this.props.match.params.id)
      .then(schema => this.setState({schema}))
      .then(() => this.setState({loading: false}))
  }

  render() {
    const { schema, loading } = this.state

    const reportKeys = schema ? Object.keys(schema.definitions) : []
    const numReports = reportKeys ? reportKeys.length : 0

    const reportsGrouped = schema ? _.groupBy(schema.definitions, x => x.report_type) : {}
    const reportTypes = reportsGrouped ? Object.keys(reportsGrouped): []
    reportTypes.map(group => reportsGrouped[group].sort((a, b) => a.report_id - b.report_id))

    const title = schema && numReports > 1 ? schema.info.title : reportKeys[0]
    const description = schema && (numReports > 1
      ? schema.info.description
      : `Schema for Rockerbox ${schema.definitions[reportKeys[0]]['report_type']}`)

    return (
      <AppWrap>
        <ContentWrap>
          {loading ?
            <ContentCard.CardLoader />
            :
            <React.Fragment>
              <Header
                as='h1'
                content={title}
                subheader={description}
              />
              {reportTypes.map(group =>
                <div style={{paddingBottom: 20}}>
                  {numReports > 1 &&
                    <Header
                      as='h2'
                      dividing
                      content={group}
                      style={{paddingTop: 10}}
                    />
                  }
                  {reportsGrouped[group].map(report =>
                    <ContentCard
                      hasTable
                      title={`${report.id} Report Schema`}
                      explainer={`Report Type: ${report.report_type}\nReport ID: ${report.report_id}`}
                      collapsing={numReports > 1}
                      defaultCollapsed={numReports > 1}
                      collapseOnHeaderClick={numReports > 1}
                      key={report.id}
                    >
                      <ReportSchemaTable
                        schema={schema}
                        reportKey={report.id}
                      />
                    </ContentCard>
                  )}
                </div>
              )}
            </React.Fragment>
          }
        </ContentWrap>
      </AppWrap>
    )
  }
}

export default ReportSchema

import React, { Component } from "react";
import { ContentCard } from "@rockerbox/styleguide";
import CrossDeviceEventCard from "./CrossDeviceEventCard";
import { Button, Message } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { createCrossDeviceConfig } from "../../utils/api";
import * as routes from "../../routes";

class CrossDeviceCreationIndex extends Component {
  state = {
    event: {},
    loading: false,
    error: false,
    tiers_filter: [{}]
  };

  handlerEventFieldChange = key => {
    const { event } = this.state;

    return (e, data) => {
      const newEvent = Object.assign({}, event, { [key]: data.value });
      return this.setState({ event: newEvent });
    };
  };

  handleTierFieldChange = (key, index) => {
    const { tiers_filter } = this.state;
    const filter = tiers_filter[index];

    return (e, data) => {
      const newFilter = Object.assign({}, tiers_filter[index], {
        [key]: data.value
      });
      const newState = tiers_filter.map((current, i) => {
        if (i === index) {
          return newFilter;
        }
        return current;
      });
      return this.setState({ tiers_filter: newState });
    };
  };

  removeTierField = (key, index) => {
    const { tiers_filter } = this.state;
    const newTier = Object.assign({}, tiers_filter[index]);
    delete newTier[key];
    const newTiers = tiers_filter.map((event, i) => {
      if (i === index) {
        return newTier;
      }
      return event;
    });

    this.setState({ tiers_filter: newTiers });
  };

  addNewTierFilter = () => {
    const { event, tiers_filter } = this.state;
    const newTiers = tiers_filter.map(d => d);
    newTiers.push({});

    // const newEvents = Object.assign({}, event, { tiers_filter: newTiers });

    this.setState({ tiers_filter: newTiers });
  };

  removeTierFilter = index => {
    const { event, tiers_filter } = this.state;
    const newTiers = tiers_filter.filter((filter, i) => i !== index);
    this.setState({ tiers_filter: newTiers });
  };

  validate = event => {
    return event["event_source"] && event["event_table_name"];
  };

  handleFormSubmit = () => {
    const { event, tiers_filter } = this.state;
    if (!this.validate(event)) {
      return this.setState({ error: true });
    }
    this.setState({ loading: true, error: false });
    const postBody = { events: Object.assign(event, { tiers_filter }) };
    postBody["events"]["tiers_filter"] = postBody["events"]["tiers_filter"].map(
      tier_filter => {
        const pairs = Object.entries(tier_filter);
        for (let [key, value] of pairs) {
          if (value == "") {
            tier_filter[key] = null;
          }
        }
        return tier_filter;
      }
    );

    return createCrossDeviceConfig(postBody)
      .then(data => {
        return this.setState({ loading: false });
      })
      .then(() =>
        this.props.history.push(
          routes.ottIndex
        )
      )
      .catch(err => this.setState({ error: true, loading: false }));
  };

  render() {
    const { event, loading, error, tiers_filter } = this.state;
    return (
      <ContentCard
        hasTable
        title="OTT Event Setting Creation"
        loading={loading}
        topRight={
          <Link to={routes.ottIndex}>
            Back to OTT tier Setting
          </Link>
        }
      >
        {error ? (
          <Message
            error
            content="Please check all form input are filled and contact your account manager for help."
            header="We were unable to create this event"
          />
        ) : null}

        <CrossDeviceEventCard
          event={event}
          tiers_filter={tiers_filter}
          eventIndex={0}
          handlerEventFieldChange={this.handlerEventFieldChange.bind(this)}
          removeTierField={this.removeTierField.bind(this)}
          handleFormSubmit={this.handleFormSubmit.bind(this)}
          handleTierFieldChange={this.handleTierFieldChange.bind(this)}
          addNewTierFilter={this.addNewTierFilter.bind(this)}
          removeTierFilter={this.removeTierFilter.bind(this)}
        />

        <Button
          primary
          loading={loading}
          content="Submit"
          onClick={() => this.handleFormSubmit()}
          style={{ marginTop: "25px", marginLeft: "10px" }}
        ></Button>
      </ContentCard>
    );
  }
}

export default CrossDeviceCreationIndex;

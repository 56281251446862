import React from 'react';
import { ContentCard, ExpandableIndexGrid, IndexGrid } from '@rockerbox/styleguide';
import { getCustomerDataTimeseries, getConversionSegments } from '../../utils/api';
import TimeRange, { yesterday, week } from '../timerange';

class CustomerDataSource extends TimeRange {
  state = {
    startDate: week,
    endDate: yesterday,
    featuredFilterId: false,
    customerData: [],
    loading: true
  }

  componentDidMount() {
    getConversionSegments()
      .then(data => {
        const featuredFilter = data
          .filter(segment => segment.featured == 1)
          .map(seg => {
            return seg
          })[0]

        const featuredFilterId = featuredFilter['action_id']
        const newToFileKey = featuredFilter['new_to_file_key'] || "external_id"
        this.setState({ featuredFilterId, newToFileKey })
      })
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate, featuredFilterId, newToFileKey } = this.state;
    if ((startDate != prevState.startDate) || (prevState.featuredFilterId != featuredFilterId)) {
      getCustomerDataTimeseries(featuredFilterId, newToFileKey, startDate, endDate)
        .then(customerData => {
          this.setState({ customerData, loading: false })
        })
    }
  }

  expandedSection = ({item}) => {
    const data = item.values;

    const cols = [
      { display: "External Id", key: "external_id"},
      { display: "Email", key: "email"},
      { display: "conversion_hash_id", key: "conversion_hash_id"},
      { display: "Order Id", key: "order_id"},
      { display: "Revenue", key: "revenue"},
      { display: "New Customer", key: "ntf"},
    ];

    return <IndexGrid data={data} cols={cols} />
  }


  render() {
    const { customerData, startDate, endDate, loading } = this.state;
    const cols = [
      { display: "Date", key: "date"},
      { display: "New Customer", key: "new_to_file"},
      { display: "Returning Customer", key: "returning"},
      { display: "Total", key: "total"},
    ];

    return (
      <ContentCard
        hasTable title="Customer Data" explainer=""
        topRight={this.renderDateSelector()}
      >
        { loading && <ContentCard.CardLoader /> }
        {
          !loading && <ExpandableIndexGrid
            data={customerData}
            cols={cols}
            as={ContentCard.Table}
            idKey="date"
            expandedComponent={this.expandedSection}
            fallbackMsg="No customer data"
          />
        }
        { this.renderDateModal() }
      </ContentCard>
    )
  }
}

export default CustomerDataSource;

import React, { useState, useMemo } from 'react';
import { Icon, Header, Divider, Button } from 'semantic-ui-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CursorClickIcon, ChartBarIcon } from '@heroicons/react/solid';

import { ChartCard, AreaChartViz } from '@rockerbox/styleguide';
import { Link } from 'react-router-dom';
import { CacheContext } from '../../../utils/CacheContext';
import { buildPath } from '../../hooks/urlState';
import { useTrackingParamAlerts } from '../../hooks/trackingParamAlerts';
import { formatCurrency } from '../../utils/valueFormatter';
import { Alert } from '../../components';
import BatchInboxInstructions from './BatchInboxInstructions';

const PlatformCard = ({ data, platformsDist, integrations, colorMap, platform, isConversionPanel }) => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState, Context] = React.useContext(CacheContext);
  const { account } = state;
  const { tier } = account || {};
  const isFreeAccount = tier === 'lite';

  const trackingParamAlerts = useTrackingParamAlerts();
  const [expanded, setExpanded] = useState(false);
  const [batchInstructionsModalOpen, setBatchInstructionsModalOpen] = useState(false);

  const platformData = useMemo(() => {
    if (!data) return;
    return data.map(x => ({
      date: x.date,
      spend: x[platform],
    }));
  }, [data, platform]);

  const platformObj = useMemo(() => {
    if (!integrations) return {};
    return integrations.find(x => x.platform_key === platform) || {};
  }, [integrations, platform]);

  const icon = platformObj.icon_path;
  const display = platformObj.platform;
  const isBatchPlatform = !!platformObj.batch_platform;
  const hasPlatformPerformance = !!platformObj.has_platform_performance;
  const color = !colorMap ? undefined : colorMap[platform];
  const totalSpend = Number(platformsDist[platform] || 0);
  const analyticsLink = buildPath('platformPerformance', { view: 'platform', platformName: platform });
  const trackingParamsLink = buildPath('integrationTrackingParameters', { platformName: platform });

  const accounts = useMemo(() => {
    if (!integrations) return;
    return integrations.filter(x => x.platform_key === platform);
  }, [integrations, platform]);

  const trackingParamAlert = useMemo(
    // eslint-disable-next-line no-mixed-operators
    () => !!trackingParamAlerts && trackingParamAlerts[platform] || {},
    [trackingParamAlerts, platform],
  );
  const hasTrackingParamIssue = !!trackingParamAlert?.hasIssues;

  const lapsedAuths = useMemo(() => {
    if (!accounts) return [];
    return accounts.filter(x => !x.auth_status);
  }, [accounts]);
  const hasAuthIssue = !isBatchPlatform && !!lapsedAuths.length;

  const footerLinks = useMemo(() => (
    [
      !isFreeAccount && {
        HeroIcon: CursorClickIcon,
        text: 'Tracking',
        link: trackingParamsLink,
      },
      !!hasPlatformPerformance && {
        HeroIcon: ChartBarIcon,
        text: 'Analytics',
        link: analyticsLink,
      },
    ].filter(Boolean)
  ), [tier]);

  const cardContent = (
    <div className="platform-status">
      {tier !== 'lite' && (
      <>
        <div className="section-header">
          <Header
            as="h4"
            content={(
              <>
                <Icon
                  name={hasTrackingParamIssue ? 'exclamation triangle' : 'check circle'}
                  color={hasTrackingParamIssue ? 'orange' : 'green'}
                />
                Tracking Status
              </>
)}
          />
          {hasTrackingParamIssue
            && (
            <Button
              inverted
              color="orange"
              size="mini"
              className={expanded !== 'tracking' && 'pulsing'}
              content={(
                <div>
                  {expanded !== 'tracking' ? 'View' : 'Close'}
                  {' '}
                  Issues
                </div>
)}
              onClick={() => (!expanded ? setExpanded('tracking') : setExpanded(false))}
            />
            )}
        </div>
        {hasTrackingParamIssue && expanded === 'tracking' && (
        <div className="alerts stacked">
          {hasTrackingParamIssue
              && (
              <Alert
                hideIcon
                type="warning"
                title="Review untracked ads"
                description={!!trackingParamAlert.numMissing ? `${trackingParamAlert.numMissing} ads missing Rockerbox parameters`
                  : `${trackingParamAlert?.percentMissing || '0'}% of links missing Rockerbox parameters`}
                action={{
                  content: 'Fix Issues',
                  as: Link,
                  to: trackingParamsLink,
                }}
              />
              )}
        </div>
        )}
        <Divider />
      </>
      )}

      <div className="section-header">
        {isBatchPlatform
          && (
          <Header
            as="h4"
            content={(
              <>
                <Icon
                  name="file alternate outline"
                  color="grey"
                />
                Data via Batch Files
              </>
)}
          />
          )}
        {!isBatchPlatform
          && (
          <Header
            as="h4"
            content={(
              <>
                <Icon
                  name={hasAuthIssue ? 'exclamation triangle' : 'check circle'}
                  color={hasAuthIssue ? 'orange' : 'green'}
                />
                {`${accounts.length} Connected Account${accounts.length > 1 ? 's' : ''}`}
              </>
)}
          />
          )}
        {hasAuthIssue
          && (
          <Button
            inverted
            color="orange"
            size="mini"
            className={expanded !== 'accounts' && 'pulsing'}
            content={(
              <div>
                {expanded !== 'accounts' ? 'View' : 'Close'}
                {' '}
                Issues
              </div>
)}
            onClick={() => (!expanded ? setExpanded('accounts') : setExpanded(false))}
          />
          )}
        {isBatchPlatform && (
        <>
          <Button
            inverted
            color="purple"
            size="mini"
            content={<div>Instructions</div>}
            onClick={() => setBatchInstructionsModalOpen(true)}
          />
          {!!batchInstructionsModalOpen
            && (
            <BatchInboxInstructions
              platform={platform}
              display={display}
              onClose={() => setBatchInstructionsModalOpen(false)}
            />
            )}
        </>
        )}
      </div>
      {hasAuthIssue && expanded === 'accounts' && (
      <div className="alerts stacked">
          {lapsedAuths.map(accountObj => (
            <Alert
              key={account.account_id}
              hideIcon
              type="warning"
              title="Unable to connect to account"
              description={`Connection to ${accountObj.account_name} (${accountObj.account_id}) has expired`}
              action={{
                content: 'Reconnect',
                onClick: () => { window.location = `/auth/${accountObj.platform_key}?account_id=${accountObj.account_id}`; },
              }}
            />
          ))}
      </div>
      )}
    </div>
  );

  if (isConversionPanel) return null;
  return (
    <ChartCard
      key={platform}
      className="platform-card"
      chart={(
        <AreaChartViz
          data={platformData}
          height={85}
          XAxisKey="date"
          solidChart
          fillOpacity={0.25}
          YAxisStyle={[{
            keyName: 'spend',
            color,
          }]}
          formatter={formatCurrency}
          strokeWidth={0}
        />
      )}
      header={display}
      headerImage={icon}
      statistics={[{
        statisticLabel: <>
          Spend
          <span style={{ fontFamily: 'Poppins', opacity: 0.5 }}>Last 30D</span>
          {/* eslint-disable-next-line react/jsx-closing-tag-location */}
        </>,
        statisticValue: totalSpend,
        alignLabel: 'left',
        format: 'currency',
        maxDigits: 2,
        color,
      }]}
      footerLinks={footerLinks}
      content={cardContent}
    />
  );
};

export default PlatformCard;

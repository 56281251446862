import React, { Component } from 'react';
import SwaggerUI from 'swagger-ui'
import 'swagger-ui/dist/swagger-ui.css';
const DOM_ID = 'swagger-ui-mountpoint'

class ModelsLayout extends Component {
  render() {
    const { getComponent } = this.props

    const Models = getComponent('Models', true)
    const BaseLayout = getComponent('BaseLayout', true)

    return (
      <div className='swagger-ui'>
        <div className='information-container wrapper'>
          <Models />
        </div>
      </div>
    )
  }
}

const ModelsLayoutPlugin = () => {
  return {
    components: {
      ModelsLayout: ModelsLayout
    }
  }
}

class Swagger extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    const plugins = this.props.models_only ? [ ModelsLayoutPlugin ] : [];
    const layout = this.props.models_only ? 'ModelsLayout' : 'BaseLayout';
    SwaggerUI({
      dom_id: `#${DOM_ID}`,
      url: this.props.swagger_url,
      plugins: plugins,
      layout: layout
    })
  }

  render() {
    return (
      <div className='swagger'>
        <div id={DOM_ID} />
      </div>
    );
  }
}

export default Swagger;

import React, { Component } from 'react'

import * as routes from '../../routes';

// array of root paths from routes.js
const rootPaths = Object.keys(routes).map(key => routes[key]);

// find root path that matches a given path
const getRootPathForPath = (path) => rootPaths.find(rootPath => path.includes(rootPath));

// code to run on detected page change
const onPageChange = () => {
  if (window.RB) window.RB.track('view');
  if (window.Intercom) window.Intercom('update');
}

class TrackPageChange extends Component {
  state = { trackedRootPath: null }

  componentDidUpdate(prevProps) {
    const prevPath = prevProps.path;
    const { path } = this.props;
    const { trackedRootPath } = this.state;

    if (path != prevPath) {
      const rootPath = getRootPathForPath(path);
      if (rootPath && rootPath != trackedRootPath) {
        this.setState({ trackedRootPath: rootPath });
        onPageChange();
      }
    }
  }

  render(){ return null }
}

export default TrackPageChange

import React from 'react';
import { Button } from 'semantic-ui-react';
import { useGlobalState } from '../../hooks/global';
import RocketIcon from '../../assets/RocketIcon';

const SetAGoalButton = ({ onClick }) => {
  // eslint-disable-next-line no-unused-vars
  const { accessibleFeatures } = useGlobalState();

  if (accessibleFeatures.includes('goals_and_recs')) {
    return (
      <Button
        inverted
        color="purple"
        onClick={onClick}
        style={{ padding: '11px 13px', marginRight: 10, fontSize: 14 }}
        className="remove-box-shadow"
      >
        <RocketIcon style={{ display: 'inline-block', height: 15, margin: '-4px 10px -3px -2px' }} />
        Set a Goal
      </Button>
    );
  }

  return null;
};

export default SetAGoalButton;

import React, { Component } from "react";
import { getCrossDeviceConfig } from "../../utils/api";
import CrossDeviceMainTable from "./CrossDeviceMainTable";

class CrossDeviceIndex extends Component {
  constructor(props) {
    super(props);
    this.loadCrossDeviceData = this.loadCrossDeviceData.bind(this);
  }
  state = {
    events: [],
    loading: true
  };

  loadCrossDeviceData() {
    this.setState({ loading: true });
    getCrossDeviceConfig().then(data => {
      this.setState({ events: data.events, loading: false });
    });
  }

  componentDidMount() {
    this.loadCrossDeviceData();
  }

  render() {
    const { loading } = this.state;
    return (
      <CrossDeviceMainTable
        {...this.state}
        loading={loading}
        loadCrossDeviceData={this.loadCrossDeviceData}
      />
    );
  }
}

export default CrossDeviceIndex;

import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';
import { Header, Divider, Card } from 'semantic-ui-react';

import { RED, GREEN, GREY, SUCCESS_STATUSES, NEUTRAL_STATUSES, WHITE } from './constants';
import { transformBatchFileStatus, transformConversionStatus, getMostRecentSuccessfulDate, getRecentDataEntries, fillInMissingDays } from './helpers';
import { IntegrationStatus, BatchGrid, StackedConversionGrid } from './parts';
import SpendCard from './SpendCard';
import { useGlobalState } from '../../hooks/global';
import { formatDateWithType } from '../../utils/dateFormatters';

const StatusGrid = ({ data, label }) => {
  if (!data) return null;
  const recentData = getRecentDataEntries(data, fillInMissingDays);
  const gridContent = recentData.map(dayData => {
    const dateFormatted = new Date(dayData.date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    const statusFormatted = dayData.status === 'upstream_unavailable' ? 'Required datasets unavailable' : dayData.status.charAt(0).toUpperCase() + dayData.status.slice(1);
    const backgroundColor = (() => {
      if (SUCCESS_STATUSES.includes(dayData.status)) {
        return GREEN;
      } if (NEUTRAL_STATUSES.includes(dayData.status)) {
        return GREY;
      } if (dayData.status === 'no_data') {
        return WHITE;
      }
      return RED;
    })();
    return (
      <div key={dayData.date} className="status-tooltip grid-icon" style={{ backgroundColor }}>
        <span className="status-tooltip-text">
          <div className="tooltip-header">Sync Status:</div>
          {`${dateFormatted}: ${statusFormatted}`}
        </span>
      </div>
    );
  });

  return (
    <div className="status-grid-container">
      <div className="status-grid-label">
        {label}
        :
      </div>
      <div className="status-grid">
        {gridContent}
      </div>
    </div>
  );
};

const SpendStatusGrid = ({ spendData, platform }) => {
  if (!spendData) return null;
  const platformData = spendData.find(item => item.platform === platform);
  const isPlatformDataAvailable = platformData && platformData.type === 'platform_spend';
  const label = isPlatformDataAvailable ? 'Platform Spend Data' : 'Spend Data';
  return <StatusGrid data={platformData ? platformData.statuses : []} label={label} />;
};

const SyntheticStatusGrid = ({ syntheticData, platform }) => {
  // TODO: remove this once tiktok synthetic is reliable
  if (!syntheticData || platform === 'tiktok') return null;
  const platformData = syntheticData.find(item => item.platform === platform);
  if (!platformData || !platformData.statuses) return null;
  return <StatusGrid data={platformData ? platformData.statuses : []} label="Synthetic Events" />;
};

const BatchStatusGrid = ({ batchFileStatus, platform, isBatchPlatform }) => {
  if (!batchFileStatus || !isBatchPlatform) return null;
  const batchData = batchFileStatus.find(item => item.platform === platform);
  if (!batchData || !batchData.files_received) return null;
  const transformedData = transformBatchFileStatus(batchData);
  return (
    <>
      <BatchGrid data={transformedData.files_received} label="File Received" />
      <BatchGrid data={transformedData.files_processed} label="File Processed" />
    </>
  );
};

const ConversionStatusGrid = ({ conversionStatus, platform, segments }) => {
  if (!conversionStatus) return null;
  if (platform === 'pixel_conversions' || platform === 'webhook_conversions') {
    const conversionData = transformConversionStatus(conversionStatus, segments, platform);
    return (
      <StackedConversionGrid data={conversionData} label="Conversion Data" />
    );
  }
  const conversionData = conversionStatus.find(item => item.type === platform)?.statuses;
  return (
    <div>
      <StatusGrid data={conversionData} label="Conversion Data" />
    </div>
  );
};

const SyncHistory = ({ spendStatus, batchStatus, syntheticStatus, conversionStatus, isConversionPlatform }) => (
  <>
    <Divider className="card-divider" />
    <div className="sync-status">
      <div className="header">
        <div className="first-line">Sync History</div>
        <div className="second-line">Last 30 Days</div>
      </div>
      <div className="charts">
        {conversionStatus}
        {batchStatus}
        {!isConversionPlatform && spendStatus}
        {syntheticStatus}
      </div>
    </div>
  </>
);

const PlatformStatusCard = ({
  accounts, // advertiser-level data
  header, headerImage, footerLinks, className, // formatting
  statistics, platform, spendData, isBatchPlatform, batchFileStatus, hasTrackingParamIssue, syntheticData, conversionData, // data
  isConversionPlatform,
}) => {
  const { currencyCode, tier, segments } = useGlobalState();

  const spendThruTimestamp = useMemo(
    () => getMostRecentSuccessfulDate(platform, spendData, conversionData),
    [platform, spendData],
  );

  const formattedSpendThruTimestamp = useMemo(
    () => (spendThruTimestamp ? formatDateWithType(spendThruTimestamp, 'MM/DD/YYYY HH:MM AM UTC') : 'N/A'),
    [spendThruTimestamp],
  );

  const dataType = isConversionPlatform ? 'Conversions ' : 'Spend ';

  return (
    <Card fluid className={`chart-card ${className}`}>
      <Card.Content>
        {(header || headerImage) && (
          <div className="title" style={{ minHeight: '35px' }}>
            <Header as="h4" style={{ fontSize: '2em', fontWeight: '600' }}>
              {headerImage && <img src={headerImage} alt="platform logo" style={{ height: '40px', width: '40px' }} />}
              {header}
            </Header>
          </div>
        )}
        <div className="sync-tile">
          {!isConversionPlatform && statistics && statistics.map(({
            statisticLabel, alignLabel, statisticValue, labelTop,
            fontWeight, format, maxDigits, icon, iconBg,
          }, statIndex) => (
            <SpendCard
              key={statIndex}
              statisticLabel={statisticLabel}
              alignLabel={alignLabel}
              statisticValue={statisticValue}
              labelTop={labelTop}
              fontWeight={fontWeight}
              format={format}
              maxDigits={maxDigits}
              icon={icon}
              iconBg={iconBg}
              currencyCode={currencyCode}
            />
          ))}
          <div className="timestamps">
            {dataType}
            Thru:
            {' '}
            {formattedSpendThruTimestamp}
          </div>
        </div>
        <div className="connections-tile">
          <Divider className="card-divider" />
          <div className="connection-status">
            <div className="connected-accounts">
              {`${accounts.length} Connected Account${accounts.length > 1 ? 's' : ''}`}
            </div>
            <IntegrationStatus
              tier={tier}
              hasTrackingParamIssue={hasTrackingParamIssue}
              isConversionPlatform={isConversionPlatform}
              accounts={accounts}
            />
          </div>
          <SyncHistory
            spendStatus={<SpendStatusGrid spendData={spendData} platform={platform} />}
            batchStatus={<BatchStatusGrid batchFileStatus={batchFileStatus} platform={platform} isBatchPlatform={isBatchPlatform} />}
            syntheticStatus={<SyntheticStatusGrid syntheticData={syntheticData} platform={platform} />}
            conversionStatus={<ConversionStatusGrid conversionStatus={conversionData} platform={platform} segments={segments} />}
            isConversionPlatform={isConversionPlatform}
          />
        </div>
        {footerLinks?.length > 0 && (
          <>
            <Divider className="card-divider" />
            <div className="footer" style={{ padding: '10px' }}>
              {footerLinks.map((footerLink, footerLinkIndex) => (
                <Link key={footerLinkIndex} to={footerLink.link}>
                  <footerLink.HeroIcon />
                  {' '}
                  {footerLink.text}
                </Link>
              ))}
            </div>
          </>
        )}
      </Card.Content>
    </Card>
  );
};

export default PlatformStatusCard;

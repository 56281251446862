import React, { useState, useEffect, useContext } from 'react';
import { Table, Form, Container, Popup, Divider, Button, Input, Icon, Accordion, Segment, Message, Grid } from 'semantic-ui-react';
import { isEqual } from 'lodash';
import { SplitLayout, IndexGrid } from '@rockerbox/styleguide';
import { defaultCols } from '../ConversionIndex/component';
import UrlButton from '../parts/UrlButton';
import * as routes from '../../routes';
import { CacheContext } from '../../utils/CacheContext';
import { getUser } from '../../utils/api';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import LookupItems from './LookupItems';
import EventExclusions from './EventExclusions';
import SpendExclusions from './SpendExclusions';
import ChildSegments from './ChildSegments';
import DataSource from './DataSource';
import DataTransformations from './DataTransformations';

export const Label = styled.div`
  margin: 0em 0em 0.5em 0em;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
`
export const PaddedLabel = styled(Label)`
  color: rgba(0, 0, 0, 0.77);
  font-size: 1.076em;
  padding-bottom:5px
`
const expectedFrequencyOptions = [{text: 'daily', value: 1}, {text: 'weekly', value: 7}, {text: 'monthly', value: 30}];

const customerIdentiferOptions = [{key:'external_id' ,text: 'external_id', value: 'external_id'}, {key:'email', text: 'email', value: 'email'}]

const segmentNewToFileOptions = [{key: 0, text:'Across all conversions in account (Default)', value: 0},{key: 1, text:'Within this one conversion', value: 1}]

const minThresholdOptions = [
  {text: '1', value: 1},
  {text: '10', value: 10},
  {text: '100', value: 100},
  {text: '200', value: 200},
  {text: '500', value: 500},
  {text: '1000', value: 1000},
]

const AggregationSettings = (props) => {

  const [currentNTFMethod, setCurrentNTFMethod] = useState("");

  useEffect(() => {
    const segmentNewToFileOption = segmentNewToFileOptions.find(option => option.value===props.segmentNewToFile)

    if (segmentNewToFileOption == undefined) {
      return
    }

    const {value: segmentNewToFileValue} = segmentNewToFileOption

    if (segmentNewToFileValue == 0 || segmentNewToFileValue == 1) {
      setCurrentNTFMethod(segmentNewToFileValue);
    }

	},[props.segmentNewToFile])

  function handleIdentifierChange(e,data) {
    props.handleChange(data, 'ntfKeyword');
    props.handleChange({'value': Number(data.value == 'email' || data.value == 'external_id')}, 'newToFile');
    if (Number(data.value != 'email' && data.value != 'external_id')) {
      props.handleChange({'value': 0}, 'segmentNewToFile')
    }
  }

  return (
  <React.Fragment>
    <PaddedLabel>Aggregation Settings</PaddedLabel>
    <Grid columns={3} container spacing={2}>
      <Grid.Row>
      <Grid.Column key={1} width={3}>
        <Form.Dropdown
          selection
          disabled = {!(props.ntfKeyword == 'email' || props.ntfKeyword == 'external_id') && props.ntfKeyword != undefined && props.ntfKeyword != ''}
          label='Customer Identifier (Optional: For New vs. Repeat Analysis)'
          clearable
          options={customerIdentiferOptions}
          onChange={(e, data) => handleIdentifierChange(e, data)}
          value={props.ntfKeyword}
        />
      </Grid.Column>
          <Grid.Column key={2} width={3}>
              <Form.Dropdown
                label='Look for New vs. Returning Users'
                disabled = {!(props.ntfKeyword == 'email' || props.ntfKeyword == 'external_id')}
                selection
                options = {segmentNewToFileOptions}
                onChange={(e, data) => {
                  props.handleChange(data, "segmentNewToFile")}}
                value={currentNTFMethod}
                style={{marginBottom: '3em', marginTop: '.5em'}}
              />
          </Grid.Column>
          <Grid.Column key={3} width={2}>
              <Label>Enable LTV</Label>
              <Form.Checkbox checked={props.hasLtv} label="" onChange={() => props.handleCheckbox("hasLtv")} style={{marginTop: '.5em'}}/>
          </Grid.Column>
       <div style={{flex:3, paddingLeft:"5px"}}>
        <Message
          size="tiny"
          header="Input the name of the field that appropriately identifies a customer (e.g., external_id or email)"
          content="If this is a transactional segment and a historical order report has been loaded, enable LTV."
        />
        </div>
      </Grid.Row>
    </Grid>
    <div style={{marginTop:"20px", display:"flex"}}>
      <div style={{flex:2,paddingRight:"10px"}}>
        <Form.Input
          fluid
          label='Unique Conversion Key'
          onChange={(e,data) => props.handleChange(data, "conversionKeyName")}
          value={props.conversionKeyName}
        />
      </div>
      <div style={{flex:1}}>
        <Label>Enable Cross-day Deduplication</Label>
        <Form.Checkbox checked={props.crossDayDedupe} label="" onChange={() => props.handleCheckbox("crossDayDedupe")}/>
      </div>
      <div style={{flex:3}}>
        <Message
          size="tiny"
          header="For transactional events, set to the unique order identifier and enable cross-day deduplication"
          content="For non-transational events (e.g., Add to Cart), set this value to the customer identifier and disable cross-day deduplication."
        />
      </div>
    </div>
  </React.Fragment>
  )
}

const UISettings = (props) => {
  const [,, Context] = useContext(CacheContext);
  const user = (Context.getCache('user', getUser))
  const isRockerboxUser = user && isEqual(user.email.split('@')[1], 'rockerbox.com');
  return (
    <React.Fragment>
      <PaddedLabel>UI Settings</PaddedLabel>
      <Form.Group widths='equal'>
        <Form.Input
          fluid
          label='Display Order'
          onChange={(e,data) => props.handleChange(data, "displayOrder")}
          value={props.displayOrder}
        />
        {props.id && (
          <div className="field">
            <Label>First Reporting Date</Label>
            <DatePicker
              customInput={<Input icon='calendar alternate'/>}
              selected={props.firstReportingDate}
              onChange={ (e) => props.handleDateChange(e, "firstReportingDate") }

            />
          </div>
        )}
        <div className="field">
          <Label>Show Revenue</Label>
          <Form.Checkbox checked={props.revenue} label="" onChange={() => props.handleCheckbox("revenue")}/>
        </div>
        {isRockerboxUser && (
          <div className="field">
            <Label>Admin Only</Label>
            <Form.Checkbox checked={props.adminOnly} label="" onChange={() => props.handleCheckbox("adminOnly")}/>
          </div>
        )}
      </Form.Group>
    </React.Fragment>
  );
}

const AlertSettings = (props) => (
  <React.Fragment>
    <PaddedLabel>Alerting</PaddedLabel>
    <Form.Group widths='equal'>
      <Form.Select fluid label="Expected frequency" options={expectedFrequencyOptions} onChange={(e,data) => props.handleChange(data, "expectedFrequency")} value={props.expectedFrequency}/>
      <Form.Select fluid label="Minimum threshold" options={minThresholdOptions} onChange={(e,data) => props.handleChange(data, "minThreshold")} value={props.minThreshold}/>
    </Form.Group>
  </React.Fragment>
)



const EditAdvancedOptions = (props) => {

  const { childSegments, prepareSubmit, refreshEdit } = props
  return (
  <>
    <Message
      color="orange"
      size="tiny"
      icon="exclamation triangle"
      header="Changing these options may break your reports"
      content="Please contact us if you need to make changes here."
    />
    <Accordion
      defaultActiveIndex={-1}
      panels={[
        {
          key: "advanced",
          title: "Setup Options",
          content: {
            content: (
            <>
              <AdvancedOptions {...props} />
            </>
            )
          }
        },
        {
          key: "identity",
          title: "Customer Identity Resolution",
          content: {
            content: (
              <Segment secondary>
                <LookupItems {...props}/>
              </Segment>
            )
          }
        },
        {
          key: "transformations",
          title: "Data Transformations",
          content: {
            content: (
              <Segment secondary>
                <DataTransformations {...props} />
              </Segment>
            )
          }
        },
        {
          key: "exclusions",
          title: "Marketing Exclusions",
          content: {
            content: (
                <SplitLayout
                  leftWidth={8}
                  rightWidth={8}
                  leftContent={
                    <Segment secondary>
                    <Message
                      size="tiny"
                      header="Setup Event Exclusions"
                      content="Use this to remove marketing events from being reporting in a users path to conversion"
                    />
                      <EventExclusions {...props}/>
                    </Segment>
                  }
                  rightContent={
                    <Segment secondary>
                    <Message
                      size="tiny"
                      header="Setup Spend Exclusions"
                      content="Use this to remove spend from being included as part of ROAS calcuations for marketing associated with this event"
                    />
                      <SpendExclusions {...props}/>
                    </Segment>
                  }
                />
            )
          }
        },

        {
          key: "validation",
          title: "Data Validation",
          content: {
            content: (
              <Segment secondary>
                <Message
                  size="tiny"
                  header="Setup Validations"
                  content="Setup alerts to know if the contents of the conversion segment changes"
                />
                <AlertSettings {...props}/>
              </Segment>
            )
          }
        },
        {
          key: "children",
          title: "Child Segments",
          content: {
            content: (
              <Segment secondary>
                <Message
                  size="tiny"
                  header="Child Segments"
                  content="Child segments contain a subset of the data from a parent segment. Specific conversions, marketing events or spend can be excluded from child segments to provide more insight into the performance of marketing using child segments."
                />
                <ChildSegments {...{ childSegments, prepareSubmit, refreshEdit }} />
              </Segment>
            )
          }
        },
      ]
      }
    />
  </>
  )
}

const AdvancedOptions = (props) => {
  return (
    <React.Fragment>
      <Segment secondary>
        <DataSource {...props} />
      </Segment>
      <Segment secondary>
        <AggregationSettings {...props}/>
      </Segment>
      <Segment secondary>
        <UISettings {...props}/>
      </Segment>
    </React.Fragment>
  )
}

const ConversionForm = (props) => {

  const ManageCell = ({item}) => (
    <Table.Cell collapsing>
      <IndexGrid.EditButton url={routes.editConversionEvent + '/' + item.action_id} />
      <Popup inverted content='View Conversion Event'
        trigger={
          <UrlButton url={routes.viewConversionEvent + '/' + item.action_id} icon="eye" />
        } />
    </Table.Cell>
  )
  const cols = [
    ...defaultCols,
    { display: "Manage", key: "manage", as: ManageCell }
  ]

  const HelpLabel = (props) => {
    const { label, help } = props;
    return (
      <>
        <label style={{ display: 'inline' }}>{label}</label>
        <Popup
          content={help}
          hoverable
          trigger={<Icon name="help" size="small" circular />}
        />
      </>
    );
  };


  return (
    <Form onSubmit={props.handleSubmit}>
      <Container fluid>
        <Form.Input
          required
          onChange={(e,data) => props.handleChange(data, "conversionName")}
          fluid
          value={props.conversionName}
          label={
            <HelpLabel
              help="The conversion event name must be unique. Duplicates will return an error."
              label="Conversion Name"
            />
          }
          placeholder='Give your conversion event a unique name to make it easy to remember'
          type='text' />
        {props.id ? <EditAdvancedOptions {...props}/> : <AdvancedOptions {...props}/>}
      </Container>
      <Container fluid>
        <Divider section />
        <div className='m-t-20'>
          <Button
            primary
            disabled={!props.conversionName || props.events.length == 0}
            type="submit"
            >
              {props.id ? 'Save Changes' : 'Create Conversion'}
            </Button>
            <UrlButton text="Cancel" url={routes.conversionEventIndex} color="black" secondary />
          </div>
        </Container>
      </Form>
  )
}

export default ConversionForm;

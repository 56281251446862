import React, { useState, useMemo, useEffect } from 'react';

import { ContentCard, IndexGridTree } from '@rockerbox/styleguide';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import moment from 'moment';

// local
import { buildCardMetricArray } from './cardHelpers';
import { getColorsFromHash } from './colorHelpers';
import { TREND_CARDS } from './constants';
import Filter from './Filter';
import { generateCompareRanges, getCompareDates } from './helpers';
// state
import { useComparisonDataArq } from './hooks/comparisonData';
import { useDatasets, useComparisonDatasets } from './hooks/datasets';
import { useDataStatus } from './hooks/dataStatus';
import { useDerivedKeys } from './hooks/derivedKeys';
import { useFilteredDataArq } from './hooks/filteredData';
import { useFilters } from './hooks/filters';
import { usePageParams } from './hooks/pageParams';
import { useTreeColumns } from './hooks/treeColumns';
import { TrendCardsFromTiersData, ChartComponent, CompareChartComponent, MainHeader, TrendCardsFromComparisonData } from './parts';
import { track, time } from '../../../utils/tracking';
import { getDatasetStatusRollup } from '../../api/dataReporting';
import { customerTypeAtom, compareRangeAtom, compareStartDateAtom, compareEndDateAtom } from '../../atoms';
import { Drawer, PopupCard, BreakdownsButton, CustomizeColumnsButton, NoDataCat, ViewAccessWrapper, DownloadCSVButton, LayoutToggles, DataStatusModal } from '../../components';
import { ChartPickerLoader, BarChartLoader, TreeTableLoader } from '../../components/loaders';
import { useGlobalState } from '../../hooks/global';
import { findFeaturedActionId } from '../../utils/options';
import { spendFormatter } from '../../utils/valueFormatter';

const AttributionReportIndex = () => {
  // GLOBAL / ACCOUNT
  const { account, currencyCode, segments, tierColors, dataStatusPlatforms } = useGlobalState();

  const featuredSegment = findFeaturedActionId(segments);

  // API INPUT AND CONTROL
  const { id, setId, startDate, setStartDate, endDate, setEndDate, creditRedistributionId, setCreditRedistributionId } = usePageParams(segments);

  // GET COMPARISON DATA
  const [compare, setCompare] = useState(false);
  const [compareRange, setCompareRange] = useAtom(compareRangeAtom);
  const [compareStartDate, setCompareStartDate] = useAtom(compareStartDateAtom);
  const [compareEndDate, setCompareEndDate] = useAtom(compareEndDateAtom);
  const [compareStart, setCompareStart] = useState(undefined);
  const [compareEnd, setCompareEnd] = useState(undefined);
  const resetCompareRange = useResetAtom(compareRangeAtom);
  const resetCompareStartDate = useResetAtom(compareStartDateAtom);
  const resetCompareEndDate = useResetAtom(compareEndDateAtom);

  // GET DATA: from global state and url params
  const datasets = useDatasets(segments, startDate, endDate, id, account, creditRedistributionId, setCreditRedistributionId);

  const {
    tiersDataRaw,
    tiersDataRawLimited,
    loading, noData,
    hasNtf, hasModel,
    attributionMethod, setAttributionMethod, attributionOptions,
    dates, firstReportingDate,
    selectedRedistribution, getAndSetRedistributions,
    standardAttributionOptions, redistributionOptions,
  } = datasets;

  const tiersData = tiersDataRaw && tiersDataRaw.size > 0 ? tiersDataRaw : tiersDataRawLimited;
  const useLimited = !tiersDataRaw;

  // DISPLAY settings
  const [ntfFilter, setNtfFilter] = useAtom(customerTypeAtom);
  const [selectedMetric, setSelectedMetric] = useState('conversions');
  const [groupDatesBy, setGroupDatesBy] = useState('daily');
  const [dataLoading, setDataLoading] = useState(true);

  const derivedKeys = useDerivedKeys(ntfFilter, attributionMethod, selectedMetric);
  const { conversionKey, revenueKey, metricColumn, orderBy, orderDirection } = derivedKeys;

  const metrics = useMemo(() => (
    buildCardMetricArray(revenueKey, conversionKey, currencyCode)
  ), [revenueKey, conversionKey, currencyCode]);

  // FILTER
  const { filters, filterSetters, search, setSearch, resetFilters, derivedTiersFilter } = useFilters();
  const filteredDataArq = useFilteredDataArq(
    tiersData,
    selectedRedistribution,
    search,
    derivedTiersFilter,
    filters,
    conversionKey,
    revenueKey,
    startDate,
    endDate,
    metricColumn,
    attributionMethod,
    groupDatesBy,
    useLimited,
  );
  const {
    loading: arqLoading,
    dailySummary,
    tiersDataReduced,
    tiersSummaryRaw,
    tiersSummaryRawTotals,
    tiersSummary,
    dailyTiersSummary,
    chartData,
    lineChartData,
    tierOneSummaryRaw,
    filteredSummaryTotals,
    numRowsExceedThreshold,
  } = filteredDataArq;

  const numDays = useMemo(() => {
    if (startDate === undefined || endDate === undefined) return;
    return moment(endDate).diff(moment(startDate), 'days') + 1;
  }, [startDate, endDate]);

  const compareDatasets = useComparisonDatasets(segments, compareStart, compareEnd, id, account, creditRedistributionId, setCreditRedistributionId, compare);
  const {
    loading: compareDataLoading,
    tiersDataRaw: comparisonDataRaw,
    tiersDataRawLimited: comparisonDataRawLimited,
  } = compareDatasets;

  const comparisonData = comparisonDataRaw && comparisonDataRaw.size > 0 ? comparisonDataRaw : comparisonDataRawLimited;
  const useLimitedCompare = !comparisonDataRaw;

  const compareArq = useComparisonDataArq(
    comparisonData,
    tiersSummary,
    dailyTiersSummary,
    chartData,
    selectedRedistribution,
    search,
    derivedTiersFilter,
    filters,
    conversionKey,
    revenueKey,
    startDate,
    endDate,
    compareStart,
    compareEnd,
    metricColumn,
    groupDatesBy,
    numRowsExceedThreshold,
    useLimited,
    useLimitedCompare,
  );

  const {
    compareLoading,
    compareData,
    compareChartData,
    compareLineChartData,
    comparisonSummaryRawTotals,
    filteredComparisonSummaryTotals,
    // dailyComparisonSummary,
    // dailySummaryCompare,
  } = compareArq;

  // DATA STATUS
  const { setSpendStatusRollup, setConversionStatusRollup, setSyntheticStatusRollup, failureDates } = useDataStatus(selectedMetric);

  // TREE GRID settings based on display settings
  const tierColorMap = useMemo(() => (
    getColorsFromHash(tiersData, tierColors)
  ), [tiersData, tierColors]);

  const treeColumns = useTreeColumns(tiersSummaryRaw, tierColorMap, conversionKey, currencyCode, revenueKey, tiersSummaryRawTotals, comparisonSummaryRawTotals, compare);
  const { tiers, setTiers, allColumns, selectedColumns, setColumns } = treeColumns;

  // DRAWER
  const [breakdownsDrawerOpen, setBreakdownsDrawerOpen] = useState(false);
  const [columnsDrawerOpen, setColumnsDrawerOpen] = useState(false);

  const onMetricChange = (e, { value }) => {
    setSelectedMetric(value);
  };

  const onDateChange = ({ startDate: _startDate, endDate: _endDate }) => {
    const formatStartDate = moment(_startDate).format('YYYY-MM-DD');
    const formatEndDate = moment(_endDate).format('YYYY-MM-DD');

    setStartDate(formatStartDate);
    setEndDate(formatEndDate);
  };

  const onCompareDateChange = rangeKey => {
    setCompareRange(rangeKey);
    setCompare(rangeKey !== '');
    if (rangeKey !== 'custom') {
      resetCompareStartDate();
      resetCompareEndDate();
    }
    if (rangeKey === '') resetCompareRange();
  };

  useEffect(() => {
    setCompare(compareRange !== '');
    if (compareRange === 'custom' && compareStartDate && compareEndDate) {
      setCompareStart(compareStartDate);
      setCompareEnd(compareEndDate);
    }
  }, []);

  useEffect(() => {
    if (compareRange === 'custom' && compareStartDate && compareEndDate) {
      setCompareStart(compareStartDate);
      setCompareEnd(compareEndDate);
    }
  }, [compareRange, compareStartDate, compareEndDate]);

  useEffect(() => {
    if (!compareRange) return setCompare(false);
    const isCustom = compareRange === 'custom';
    const [compareStartMoment, compareEndMoment] = getCompareDates(compareRange, startDate, endDate);

    const formatStartDate = moment(compareStartMoment).format('YYYY-MM-DD');
    const formatEndDate = moment(compareEndMoment).format('YYYY-MM-DD');

    setCompare(true);
    setCompareStart(!isCustom ? formatStartDate : compareStartDate);
    setCompareEnd(!isCustom ? formatEndDate : compareEndDate);
  }, [compareRange, startDate, endDate, compareStartDate, compareEndDate]);

  useEffect(() => {
    if (!id || !parseInt(id) || !startDate || !endDate) return;
    if (!!loading) {
      time('attribution.attribution_report.view');
      return;
    }
    track('attribution.attribution_report.view', {
      segment_id: id,
      start_date: startDate,
      end_date: endDate,
    });
  }, [id, startDate, endDate, loading]);

  useEffect(() => {
    if (!compare) return;
    if (!id || !parseInt(id) || !compareRange) return;
    track('attribution.attribution_report.view_comparison', {
      segment_id: id,
      compareRange,
    });
  }, [compare]);

  if (!id) return null;

  useEffect(() => {
    const load = compare ? loading || compareLoading || compareDataLoading || arqLoading : loading || arqLoading;
    setDataLoading(load);
  }, [loading, compareLoading, compareDataLoading, arqLoading, compare]);

  useEffect(() => {
    if (!featuredSegment || !dataStatusPlatforms) return;
    // only use conversion data status if featured conversion segment is selected
    const conversionsStatusPromise = featuredSegment === id ? null : getDatasetStatusRollup('conversions', startDate, endDate, featuredSegment);
    const fetchData = async () => {
      try {
        const [spendResponse, syntheticResponse, conversionResponse] = await Promise.all([
          getDatasetStatusRollup('spend', startDate, endDate),
          getDatasetStatusRollup('synthetic_events', startDate, endDate),
          conversionsStatusPromise,
        ]);
        const filteredSpend = {
          ...spendResponse.response,
          failures: spendResponse.response.failures.filter(failure => dataStatusPlatforms.includes(failure.platform)),
        };
        setSpendStatusRollup(filteredSpend);
        setConversionStatusRollup(conversionResponse?.response);
        // TODO: remove filtering of Tiktok synthetic once the data is reliable
        const filteredSynthetic = {
          ...syntheticResponse.response,
          failures: syntheticResponse.response.failures.filter(failure => failure.platform !== 'tiktok'),
        };
        setSyntheticStatusRollup(filteredSynthetic);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
    fetchData();
  }, [startDate, endDate, featuredSegment, dataStatusPlatforms]);

  const tableData = compare ? compareData : tiersSummary;

  const isCustom = compareRange === 'custom';

  const compareOptions = generateCompareRanges(startDate, endDate, firstReportingDate, isCustom, compareStartDate, compareEndDate);

  const isTestAccount = true;
  const queryReturnedNoData = !dataLoading && !compareDataLoading && tableData?.length === 0 && compareData?.length === 0 && search.length > 0;

  return (
    <>
      <div>
        <DataStatusModal
          isTestAccount={isTestAccount}
          connections={[]}
          startDate={startDate}
          endDate={endDate}
        />
        <MainHeader />
        <Filter
          {...{
            loading: dataLoading,
            tiersData: tiersSummaryRaw,
            rawData: tiersDataReduced, // data
            segments,
            id,
            setId, // conversion segment
            compareRange,
            setCompareRange,
            compare,
            setCompare,
            compareStart,
            compareEnd,
            onCompareDateChange,
            compareOptions,
            numDays, // comparison
            startDate,
            endDate,
            onDateChange,
            firstReportingDate,
            compareStartDate,
            compareEndDate,
            setCompareStartDate,
            setCompareEndDate, // dates
            hasNtf,
            ntfFilter,
            setNtfFilter, // customer type
            attributionMethod,
            setAttributionMethod,
            attributionOptions,
            hasModel,
            noData, // model
            filters,
            filterSetters,
            resetFilters, // jotai
            search,
            setSearch,
            standardAttributionOptions,
            redistributionOptions,
            tierOneSummaryRaw,
            setCreditRedistributionId,
            selectedRedistribution,
            getAndSetRedistributions, // credit redistribution modal
            account,
          }}
        />
      </div>
      {noData && <NoDataCat message={`There is no data available in the date range. Please make sure your reporting date is set to on or after ${firstReportingDate}`} imgSize="small" />}
      {queryReturnedNoData && <NoDataCat message="No data found for the selected filters. Please try a different filter." imgSize="small" />}
      {!noData && (
        <LayoutToggles
          chartComponent={(
            <>
              <div style={{ justifyContent: 'space-between', width: '100%' }}>
                {compare ? (
                  <TrendCardsFromComparisonData
                    {...{ loading: dataLoading, filteredSummaryTotals, filteredComparisonSummaryTotals, metrics_group: TREND_CARDS, metrics, currencyCode }}
                    onChange={onMetricChange}
                    value={selectedMetric}
                    cardGroupStyle={{ paddingTop: '1em', marginBottom: '-2.05rem', zIndex: 10 }}
                    point="down"
                    {...{ conversionKey, revenueKey }}
                  />
                ) : (
                  <TrendCardsFromTiersData
                    {...{ loading: dataLoading, dates, dailySummary, metrics_group: TREND_CARDS, metrics, currencyCode }}
                    onChange={onMetricChange}
                    value={selectedMetric}
                    cardGroupStyle={{ paddingTop: '1em', marginBottom: '-2.05rem', zIndex: 10 }}
                    point="down"
                    {...{ conversionKey, revenueKey }}
                  />
                )}
              </div>
              <ContentCard style={{ borderRadius: 0, border: '1px solid #e6e6e6', zIndex: 0, position: 'static', boxShadow: 'none' }} hasTable>
                {dataLoading ? (
                  <>
                    <ChartPickerLoader />
                    <BarChartLoader />
                  </>
                ) : (
                  <div style={{ marginBottom: 3 }}>
                    {compare ? (
                      <CompareChartComponent
                        {...{
                          chartData: compareChartData,
                          lineChartData: compareLineChartData,
                          tierColorMap,
                          selectedMetric,
                          spendFormatter: spendFormatter(currencyCode),
                          startDate,
                          endDate,
                          compareStart,
                          compareEnd,
                          compareRange,
                          numDays,
                          compareOptions,
                        }}
                      />
                    ) : (
                      <ChartComponent
                        {...{
                          chartData,
                          lineChartData,
                          tierColorMap,
                          selectedMetric,
                          spendFormatter: spendFormatter(currencyCode),
                          groupDatesBy,
                          setGroupDatesBy,
                          failureDates,
                          isTestAccount,
                        }}
                      />
                    )}
                  </div>
                )}
              </ContentCard>
            </>
          )}
          tableComponent={(
            <ContentCard style={{ marginTop: 20 }} hasTable>
              {dataLoading ? (
                <TreeTableLoader showSearch />
              ) : (
                <IndexGridTree
                  cols={selectedColumns}
                  allCols={allColumns}
                  data={tableData}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                  compareRange={compareRange}
                  summaryRow
                  title="Channel Breakdown"
                  rightContent={(
                    <div>
                      <BreakdownsButton onClick={() => setBreakdownsDrawerOpen(true)} />
                      <CustomizeColumnsButton onClick={() => setColumnsDrawerOpen(true)} />
                      <DownloadCSVButton
                        filterOptions={{
                          'Attribution Model': attributionMethod,
                          'Compare Range': compareOptions,
                        }}
                        data={tableData}
                        selectedColumns={selectedColumns}
                        addColumns={compare ? {
                          appendSuffix: '_compare',
                          columnKeyName: 'compareKey',
                          appendDateToAddedCols: `${compareStart} to ${compareEnd}`,
                          appendDate: `${startDate} to ${endDate}`,
                          addDeltas: true,
                        } : false}
                        trackName={compare ? 'attribution.timeperiod.download' : 'attribution.general.download'}
                      />
                    </div>
                  )}
                  tiersOrder={tiers}
                  hideKeys={['Unmapped Events']}
                  sticky
                  compareStart={compareStart}
                  compareEnd={compareEnd}
                  compare={compare}
                  useColorGradient
                />
              )}
            </ContentCard>
          )}
        />
      )}
      <Drawer openDrawer={breakdownsDrawerOpen} onDrawerClose={() => setBreakdownsDrawerOpen(false)}>
        <PopupCard
          title="Breakdowns"
          list={tiers}
          setOrder={setTiers}
          callbackFn={() => setBreakdownsDrawerOpen(false)}
          open={breakdownsDrawerOpen}
        />
      </Drawer>
      <Drawer openDrawer={columnsDrawerOpen} onDrawerClose={() => setColumnsDrawerOpen(false)}>
        <PopupCard
          title="Customize Columns"
          isCustom={true}
          customKey="display"
          list={selectedColumns}
          originalList={allColumns}
          setOrder={setColumns}
          icon="none"
          callbackFn={() => setColumnsDrawerOpen(false)}
          excludeFirst={1}
          open={columnsDrawerOpen}
        />
      </Drawer>
    </>
  );
};

const AttributionReportWrapper = () => (
  <ViewAccessWrapper
    viewName="cross_channel_report"
    viewComponent={<AttributionReportIndex />}
  />
);

export default AttributionReportWrapper;

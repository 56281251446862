import React, { useEffect, useState } from 'react';
import { Button, Grid, Icon, Loader } from 'semantic-ui-react';
import { getMonth, getDay, getNumDaysInMonth, setToFixed, addCommas, formatAddress, formatLineItemsForTaxes } from './helpers';
import { calculateSalesTax } from '../../api/account';

function ConfirmationPage({ estimated, completeStep, oneHundredPercentOff, proratedTotal, address }) {
  const month = getMonth();
  const day = getDay();
  const lastDay = getNumDaysInMonth();
  const proratedFormatted = oneHundredPercentOff ? addCommas(setToFixed(0)) : addCommas(setToFixed(proratedTotal));
  const [estimatedCharge, setEstimatedCharge] = useState(0);

  const taxItems = formatLineItemsForTaxes(estimated);

  useEffect(() => {
    if (!address || !address.address.postal_code) return;
    calculateSalesTax(formatAddress(address.address), taxItems)
      .then(response => {
        setEstimatedCharge(addCommas(setToFixed(response.response.amount_total / 100)));
      });
  }, [estimated]);

  if ((estimated === 0 && !oneHundredPercentOff) || estimatedCharge === 0) {
    return (
      <Loader active />
    );
  }
  return (
    <div className="onboarding-payment-confirmation-view">
      <Icon circular inverted color="green" name="check" size="big" className="icon" />
      <p className="thank"> Thank you!</p>
      <p className="success">Your payment has been received successfully</p>
      <Grid>
        <Grid.Column width={8}>
          <p className="firstChargeText">Charged today:</p>
          <p className="prorationDates">
            {' '}
            Prorated for
            {month}
            {' '}
            {day}
            {' '}
            -
            {' '}
            {lastDay}
          </p>
        </Grid.Column>
        <Grid.Column width={8}>
          <p className="firstChargePrice">
            $
            {proratedFormatted}
          </p>
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column width={8}>
          <p className="estimatedChargeText"> Est. charge next month: </p>
          <p className="estimation"> Based on your provided monthly ad spend</p>
        </Grid.Column>
        <Grid.Column width={8}>
          <p className="estimatedChargePrice">
            $
            {estimatedCharge}
          </p>
        </Grid.Column>
      </Grid>
      <p className="legalese">
        Moving forward, your monthly charge will be based on the advertising spend tracked by Rockerbox.
        On the 1st of each month, we will recalculate your price based on the previous month&apos;s spend.
      </p>

      <Button
        size="large"
        fluid
        color="purple"
        className="onboarding-payment-confirmation-button"
        onClick={completeStep}
      >
        Next
      </Button>
    </div>
  );
}
export default ConfirmationPage;

import { colorHash } from '../../utils/colorHash';

export const arrowColumnUnique = (arrow, column) => new Array(...new Set([...arrow.column(column)]));

export const getColorsFromHash = (tiersDataRaw, tierColors) => {
  if (!tiersDataRaw || !tierColors) return {};

  return arrowColumnUnique(tiersDataRaw, 'tier_1')
    .reduce((accu, c) => {
      accu[c] = tierColors[c] || colorHash(c);
      return accu;
    }, {});

  // return d3.nest()
  //  .key(g => g.tier_1)
  //  .entries(tiersData)
  //  .reduce((p,c) => {
  //    p[c['key']] = tierColors[c['key']] || colorHash(c['key']);
  //    return p;
  //  },{});
};

import React, { useMemo } from 'react';

import { Area, ResponsiveContainer, ComposedChart, ReferenceLine, Line, Customized, Cross, Tooltip, YAxis, XAxis, ReferenceDot } from 'recharts';

import { formatCurrency } from '../../../utils/valueFormatter';
import { roundNumber } from '../helpers';

const axisLabelStyles = {
  fontSize: 12,
  textAnchor: 'middle',
  fontFamily: 'Poppins',
  fontWeight: 600,
  fill: '#919191',
  textTransform: 'uppercase',
};

const CustomAxisTick = (axisType, format) => ({ x, y, payload }) => {
  const value = payload?.value;
  if (!value) return null;

  const formatted = format === 'currency' ? formatCurrency(value, 'USD', 0) : roundNumber(value, 2);
  const rotation = axisType === 'x' ? 0 : -90;
  const dy = axisType === 'x' ? 8 : 0;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={dy} textAnchor="end" fill="#919191" transform={`rotate(${rotation})`}>
        {formatted}
      </text>
    </g>
  );
};

const CrossHairs = chartType => ({ xAxisMap, yAxisMap, activePayload, isTooltipActive, width, height }) => {
  const xAxisMapper = xAxisMap?.[0]?.scale;
  const yAxisMapper = yAxisMap?.[0]?.scale;

  const activePoint = activePayload?.[0]?.payload;
  const yKey = chartType === 'kpi' ? 'marginal_response' : 'predicted_roas';
  const crossX = xAxisMapper?.(activePoint?.spend_input);
  const crossY = yAxisMapper?.(activePoint?.[yKey]);

  if (!isTooltipActive || !crossY) return null;

  return (
    <Cross
      x={crossX}
      y={crossY}
      top={-45}
      left={60}
      height={height}
      width={width}
      stroke="#919191"
      strokeOpacity={0.5}
    />
  );
};

const TooltipRow = ({ label, value, final }) => (
  <p style={{
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: !final && 5,
    paddingTop: !!final && 5,
    borderTop: !!final && '1px solid #e6e6e6',
    color: '#4d4d4d',
    fontSize: 12,
  }}
  >
    <span style={{ marginRight: 20, fontWeight: 600 }}>{label}</span>
    <span style={{ fontFeatureSettings: '"kern" 1, "tnum" 1' }}>{value}</span>
  </p>
);

const CustomTooltip = ({ active, payload, label, isConversionKPI }) => {
  if (!active || !payload?.length) return null;

  const kpi = payload?.[0]?.payload?.marginal_response;
  const metric = payload?.[0]?.payload?.predicted_roas;

  const kpiText = isConversionKPI ? 'Conversions' : 'Revenue';
  const kpiValue = isConversionKPI ? roundNumber(kpi, 2) : formatCurrency(kpi, 'USD', 0);
  const metricText = isConversionKPI ? 'CPA' : 'ROAS';
  const metricValue = isConversionKPI ? formatCurrency(metric, 'USD', 0) : roundNumber(metric, 2);

  return (
    <div style={{
      padding: 10,
      borderRadius: 4,
      background: '#fff',
      color: '#000',
      boxShadow: '0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15)',
    }}
    >
      <TooltipRow
        label="Spend"
        value={formatCurrency(label, 'USD', 0)}
      />
      <TooltipRow
        label={`Projected ${kpiText}`}
        value={kpiValue}
      />
      <TooltipRow
        final
        label={`Projected ${metricText}`}
        value={metricValue}
      />
    </div>
  );
};

const ChannelOptimizationChart = ({ isConversionKPI, chartType, responseCurve, currentSpend, currentKPI, targetSpend, predictedKPI, histogram = false, histogramOnly = false, height = false }) => {
  if (!responseCurve?.length) return null;

  const renderData = useMemo(() => {
    const data = responseCurve.map(accu => {
      const point = { ...accu };
      if (isConversionKPI) {
        point.predicted_roas = 1 / accu.predicted_roas;
      }
      point.count_of_days = histogram ? histogram[accu.spend_input] : 0;
      return point;
    });
    return data;
  }, [isConversionKPI, responseCurve]);

  const maxSpend = useMemo(() => {
    if (!renderData?.length) return 0;
    const filtered = renderData.filter(row => !histogram || row.count_of_days > 1);
    return filtered.reduce((p, c) => (!!c.marginal_response ? Math.max(p, c.spend_input) : p), 0);
  }, [renderData]);

  const maxRevenue = useMemo(() => {
    if (!renderData?.length) return 0;
    return renderData.filter(row => maxSpend === row.spend_input).map(row => row.marginal_response)[0];
  }, [maxSpend]);

  const yAxisLabel = useMemo(() => {
    if (isConversionKPI) return chartType === 'kpi' ? 'Projected Conversions' : 'Projected CPA';
    return chartType === 'kpi' ? 'Projected kpi' : 'Projected ROAS';
  }, [chartType, isConversionKPI]);

  const yAxisFormat = useMemo(() => {
    if (isConversionKPI) return chartType === 'kpi' ? 'decimal' : 'currency';
    return chartType === 'kpi' ? 'currency' : 'decimal';
  }, [chartType, isConversionKPI]);

  const getYCoodinatesReferenceDot = (kpi, spend) => {
    if (isConversionKPI) {
      return chartType === 'kpi' ? kpi : spend / kpi;
    }
    return chartType === 'kpi' ? kpi : kpi / spend;
  };

  const yDomain = chartType === 'metric'
    ? false
    : [0, (parseInt(maxRevenue / 1000 * 1.25) * 1000)]; // eslint-disable-line

  return (
    <ResponsiveContainer width="100%" height={height || 350}>
      <ComposedChart data={renderData} margin={{ top: 5, right: 5, bottom: 15, left: 0 }}>
        <XAxis
          dataKey="spend_input"
          type="number"
          domain={['dataMin', () => maxSpend]}
          tickLine={false}
          tick={CustomAxisTick('x', 'currency')}
          label={{ value: 'Spend (Daily)', position: 'bottom', offset: 0, style: axisLabelStyles }}
          stroke="#e6e6e6"
        />
        <YAxis
          type="number"
          yAxisId="kpi"
          hide={histogramOnly}
          domain={yDomain}
          tickLine={false}
          tick={CustomAxisTick('y', yAxisFormat)}
          label={{ value: yAxisLabel, angle: -90, position: 'insideLeft', offset: 20, style: axisLabelStyles }}
          stroke="#e6e6e6"
        />
        <YAxis
          hide={!histogramOnly}
          type="number"
          label={{ value: 'Days', angle: -90, position: 'insideLeft', offset: 20, style: axisLabelStyles }}
          yAxisId="days"
          tick={CustomAxisTick('y', yAxisFormat)}
          tickLine={false}
          stroke="#e6e6e6"
        />

        {chartType === 'roas' && !isConversionKPI
          && (
          <ReferenceLine
            y={1}
            yAxisId="kpi"
            stroke="#e6e6e6"
            strokeDasharray="4 4"
          />
          )}
        {!!currentSpend
          && (
          <ReferenceLine
            x={currentSpend}
            yAxisId="kpi"
            stroke="#1C8ED1"
            opacity={0.25}
          />
          )}
        {!!targetSpend
          && (
          <ReferenceLine
            x={targetSpend}
            yAxisId="kpi"
            stroke="#FF9615"
            opacity={0.25}
          />
          )}

        <Customized component={CrossHairs(chartType)} />
        <Tooltip content={<CustomTooltip />} cursor={false} isConversionKPI={isConversionKPI} />

        { histogram && (
        <Area
          dataKey="count_of_days"
          yAxisId="days"
          stroke="white"
          type="monotone"
          dot={false}
          fillOpacity={0.1}
          fill="grey"
          strokeWidth={1}
          isAnimationActive={false}
        />
        )}
        { !histogramOnly && (
        <Line
          dataKey={chartType === 'kpi' ? 'marginal_response' : 'predicted_roas'}
          yAxisId="kpi"
          stroke="#12d975"
          type="monotone"
          dot={false}
          strokeWidth={3}
          isAnimationActive={false}
        />
        )}
        {!!currentSpend
          && (
          <ReferenceDot
            x={currentSpend}
            y={getYCoodinatesReferenceDot(currentKPI, currentSpend)}
            yAxisId="kpi"
            r={4}
            fill="#fff"
            stroke="#1C8ED1"
            strokeWidth={2}
            isFront={true}
            label={{
              value: currentSpend !== targetSpend ? 'Average' : '',
              position: 'bottom',
              offset: 15,
              fill: '#1C8ED1',
              fontSize: 12,
              fontWeight: 500,
              fontFamily: 'Inter',
            }}
          />
          )}
        {!!targetSpend
          && (
          <ReferenceDot
            x={targetSpend}
            y={getYCoodinatesReferenceDot(predictedKPI, targetSpend)}
            yAxisId="kpi"
            r={4}
            fill="#fff"
            stroke="#FF9615"
            strokeWidth={2}
            isFront={true}
            label={{
              value: 'Target',
              position: 'bottom',
              offset: 15,
              fill: '#FF9615',
              fontSize: 12,
              fontWeight: 500,
              fontFamily: 'Inter',
            }}
          />
          )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ChannelOptimizationChart;

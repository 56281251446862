import { useMemo } from 'react';

import { buildRecommendation } from '../../../hooks/buildRecommendation';
import { trainingStatsWithSpend } from '../../../forecast/forecastHelpers';

const getBaselineRecommendation = (responseCurves, trainingStats, constraints, featureStats) => {
  if (!responseCurves || !trainingStats || !constraints || !featureStats) return {};

  const stats = trainingStatsWithSpend(trainingStats, featureStats);
  const recommendation = buildRecommendation(responseCurves, stats, constraints);

  if (!recommendation) return {};
  return recommendation;
};

export const useBaselineRecommendation = ({
  trainingStats,
  constraints,
  responseCurves,
  featureStats,
}) => {
  // Note: we build the baseline recommendation, but it is primarily used to calculate the baseline spend for channels
  // We recompute the baseline projections in the optimization step based on the "current" spend from the baseline
  // Not entirely sure why we need to do this, but it is what it is?
  // Ideally, we should unpack the buildRecommendation function so that it only supplies the necessary information we need

  // TODO: refactor buildRecommendation to only supply the necessary information.
  // Dont actually produce a forecast at this step
  const baseline = useMemo(
    () => getBaselineRecommendation(responseCurves, trainingStats, constraints, featureStats),
    [trainingStats, constraints, responseCurves, featureStats],
  );

  const baselineTotal = useMemo(() => {
    if (!baseline) return;
    return Object.keys(baseline).reduce((p, key) => p + baseline[key].current, 0);
  }, [baseline]);

  const activeFeatures = useMemo(() => {
    if (!baseline) return;
    return Object.keys(baseline).filter(key => baseline[key].current > 0);
  }, [baseline]);

  const inactiveFeatures = useMemo(() => {
    if (!baseline) return;
    return Object.keys(baseline).filter(key => baseline[key].current === 0);
  }, [baseline]);

  return {
    baseline,
    baselineTotal,
    activeFeatures,
    inactiveFeatures,
  };
};

import React, { useState, useEffect } from 'react';
import { ContentCard, PagedIndexGrid } from '@rockerbox/styleguide';
import { Input, Button, Message } from 'semantic-ui-react';
import { getExcludedDomains, postExcludedDomains } from '../../api/account';

const ExcludedDomains = () => {
  const [domain, setDomain] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [domains, setDomains] = useState([]);

  const cols = [{ display: 'Domains', key: 'domain' }];
  const allowAdd = domains.length < 10;

  const updateDomains = () => {
    getExcludedDomains().then(data => setDomains(data));
  };

  useEffect(() => updateDomains(), []);

  const handleChange = (event, { value }) => setDomain(value);

  const handleSubmit = () => {
    const postObj = { domain: domain.trim() };
    setLoading(true);
    postExcludedDomains(postObj)
      .then(() => {
        setLoading(false);
        setDomain('');
        setError(false);
        updateDomains();
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };

  return (
    <ContentCard title="Excluded Domains">
      {allowAdd
        && (
        <div style={{ marginBottom: 14 }}>
          <p>Add up to 10 domain exclusions</p>
          <Input
            placeholder="yourDomain.com"
            value={domain}
            action
            onChange={handleChange}
            style={{ width: 300 }}
          >
            <input />
            <Button
              primary
              type="submit"
              onClick={handleSubmit}
              disabled={domain === ''}
              loading={loading}
            >
              Add
            </Button>
          </Input>
          { error && (
          <Message negative>
            <p>This domain may have already been added</p>
          </Message>
          )}
        </div>
        )}
      {!allowAdd
        && (
        <div>
          <Message negative>
            <p>Maximum number of excluded domains reached</p>
          </Message>
        </div>
        )}
      <PagedIndexGrid data={domains} cols={cols} itemsPerPage={10} collapsed />
    </ContentCard>
  );
};

export default ExcludedDomains;

export const EXTERNAL_APP_KEYS = {
  fullstory: {
    internalOrgId: 'CR8M0',
    prodOrgId: 'CR69K',
  },
  intercom: {
    testAppId: 'vab0ytf3',
    prodAppId: 'b5016nwk',
  },
  mixpanel: {
    stagingToken: '1daf35896688874c502d4e4b0412edd3',
    prodToken: 'a48368904183cf405deb90881e154bd8',
  },
  profitwell: {
    token: '74e16a3d55dc49d09ec675d99259da3b',
  },
  sentry: {
    dsn: 'https://d3351f6ed16d44a4aa23f4abbfb5e0e5@o488947.ingest.sentry.io/5550230',
  },
  helpdocs: {
    widgetKey: 'u7wmu3b13xyzk70c3cpg',
  },
  vitally: {
    token: 'ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmhkV1FpT2lJMVlURTFaV0poWWkweE5qVTBMVFE1WkdZdFlqa3dOeTFtTW1FMU5EZ3lPRGszTkRVaUxDSmhjR2tpT2lKaU1EVTJOVGsxWVMxak9ESTJMVFEzTW1JdFlqZzBaaTAzTXpKa1l6ZGhNek0xWm1RaUxDSnpaV01pT2lJME1qVXhZV1V3WlNKOS5IZ1RkbklfZjJSSERkUmNCQVh1ZlRSS3Jyc3AweXc0M3JZTzhCdWo0NExVOg==',
  },
}

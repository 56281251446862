/* eslint-disable no-shadow, react/jsx-props-no-spreading */

import React from 'react';

import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Input, Label, Form, Button } from 'semantic-ui-react';
import styled from 'styled-components';

import Ace from './Ace';

export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  padding: 0,
  ...draggableStyle,
});

export const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#F8F8F9' : 'none',
  padding: 0,
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const CONDITION_OPTIONS = [
  { text: 'expression', value: 'code' },
  { text: 'contains', value: 'select' },
];
const expressionConditionValue = expression => {
  const [value, condition] = expression.split(' if ');
  const ins = condition.split(' in ');
  const [contains, target] = ins.slice(0, 2);
  const defaultType = ins.length > 2 ? 'code' : 'select';
  return { condition, value, contains, target, defaultType };
};

const InlineGroup = styled.div`
  display: flex;
  & > .ui.label {
    line-height: 24px;
  }
  & > .ui {
    margin:0;
    border-radius:0;
  }
  & > .ui > .ui.button, 
  & > .ui.buttons,
  & > .ui.input > input {
    border-radius:0 !important;
  }
  & > .ui.basic > .ui.button.icon {
    max-height: 17px
  }
  & .ui.selection.dropdown {
    min-height: 3.2em;
    border-radius:0 !important;
  }
  & .ui.selection.dropdown > i.dropdown.icon {
    margin: -.91666667em;
  }
  & .ui.dropdown > i.dropdown.icon {
    margin: 0;
  }
  
`;

const Condition = React.memo(({ condition, contains, target, selectOptions, defaultType, onChange }) => {
  const [expressionType, setExpressionType] = React.useState(defaultType || 'select');
  const changeExpressionType = (_unused, evt) => setExpressionType(evt.value);

  const setValue = value => onChange(false, { value });
  const setTarget = (_unused, evt) => setValue(`${contains} in ${evt.value}`);
  const setContains = (_unused, evt) => setValue(`"${evt.value}" in ${target}`);

  return (
    <>
      <Label>
        <Form.Dropdown options={CONDITION_OPTIONS} value={expressionType} trigger={<span />} onChange={changeExpressionType} />
      </Label>
      { expressionType === 'select' && (
      <>
        <Input value={contains.trim().slice(1, -1)} onChange={setContains} />
        <Label>in</Label>
        <Form.Select key={target} options={selectOptions} defaultValue={target} onChange={setTarget} />
      </>
      )}
      { expressionType === 'code' && (
      <div style={{ minWidth: 220 }}>
        <Ace.Inline value={condition} onChange={setValue} />
      </div>
      )}
    </>
  );
});

const valueToExpressions = value => {
  const splitted = value.split(' else ');
  if (splitted.length < 2) return ['"" if "" in ""', value || '""'];
  return splitted;
};

const Template = React.memo(({ value, selectOptions, onChange }) => {
  const expressions = valueToExpressions(value);
  const defaultValue = expressions.slice(-1)[0];

  const conditionals = React.useMemo(() => expressions.slice(0, -1).map(_.memoize(expressionConditionValue)), [value]);

  const changeValue = pos => (raw, evt) => {
    const condition = expressions[pos].split(' if ')[1];
    expressions[pos] = `"${evt.value}" if ${condition}`;
    const newValue = expressions.join(' else ');
    onChange(false, { value: newValue });
  };
  const changeDefaultValue = (raw, evt) => {
    expressions[expressions.length - 1] = `"${evt.value}"`;
    const newValue = expressions.join(' else ');
    onChange(false, { value: newValue });
  };

  const changeCondition = pos => (raw, evt) => {
    const current = expressions[pos].split(' if ')[0];
    expressions[pos] = `${current} if ${evt.value}`;
    const newValue = expressions.join(' else ');
    onChange(false, { value: newValue });
  };
  const addCondition = pos => () => {
    expressions.splice(pos + 1, 0, '"" if "" in ""');
    const newValue = expressions.join(' else ');
    onChange(false, { value: newValue });
  };
  const removeCondition = pos => () => {
    expressions.splice(pos, 1);
    const newValue = expressions.join(' else ');
    onChange(false, { value: newValue });
  };

  const onDragEnd = result => {
    const destinationIndex = result.destination ? result.destination.index : expressions.length - 1;
    const newValue = reorder(expressions, result.source.index, destinationIndex).join(' else ');
    onChange(false, { value: newValue });
  };

  return (
    <>
      <DragDropContext {...{ onDragEnd }}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {conditionals.map(({ condition, value, contains, target, defaultType }, i) => (
                <Draggable key={i} draggableId={condition} index={i}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <InlineGroup key={i}>
                        <Label style={{ minWidth: 50 }}>{ i ? 'elseif' : 'if' }</Label>
                        <Condition {...{ condition, contains, target, selectOptions, defaultType }} onChange={changeCondition(i)} />
                        <Label style={{ borderRadius: 0 }}>then</Label>
                        <Input value={value.trim().slice(1, -1)} labelPosition="left" onChange={changeValue(i)} />
                        <Button.Group vertical size="mini">
                          <Button as="a" icon="remove" style={{ height: 18 }} onClick={removeCondition(i)} />
                          <Button as="a" icon="add" style={{ height: 18 }} onClick={addCondition(i)} />
                        </Button.Group>
                      </InlineGroup>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <InlineGroup key="default">
        <Input type="text" labelPosition="left">
          <Label style={{ width: 50 }}>else</Label>
          <Input value={defaultValue.trim().slice(1, -1)} onChange={changeDefaultValue} />
        </Input>
      </InlineGroup>
    </>
  );
});
export default Template;

import React from 'react';
import { RollupFilter, DateRangeHooks, FilterWrapper, StyledDropdown, SaveReportButton, ResetFilterButton } from '../../../components';
import useAuthedIntegrations from '../../../hooks/useAuthedIntegrations';
import { channelPerformancePlatformsConfig } from '../../../constants/mappings';

const Filter = ({
  platform, loading, platformPerformanceData, platformConfig, // data
  startDate, endDate, onDateChange, firstReportingDate, // dates
  conversionOptions, convValue, updatePlatformConversionEvent, // conversion metrics
  attributionWindowOptions, windowValue, updateAttributionWindow, // attribution window
}) => {
  const platformSelection = useAuthedIntegrations(channelPerformancePlatformsConfig);
  const { onDropdownChange, dropdownOptions } = platformSelection;

  const hierarchyArr = platformConfig.hierarchy.slice(1);
  const openDefault = (convValue === '' || convValue.length === 0) && !!conversionOptions?.length;

  const platformDisplayName = platformConfig?.display_platform_name || 'Platform';

  return (
    <FilterWrapper
      {...{ loading, collapseKey: platform }}
      other={(
        <>
          <StyledDropdown
            label="Ad Platform"
            value={platform}
            options={dropdownOptions}
            onChange={onDropdownChange}
          />
          <StyledDropdown
            label={`${platformDisplayName} Conversion`} // do we need to add Platform + Conversion Metrics?
            placeholder="Select Conversion"
            options={conversionOptions}
            value={convValue}
            onChange={(e, { value }) => updatePlatformConversionEvent(value)}
            disabled={!conversionOptions?.length}
            defaultOpen={openDefault}
          />
          <StyledDropdown
            label={`${platformDisplayName} Attribution`}
            placeholder="Select Attribution Window"
            options={attributionWindowOptions}
            value={windowValue}
            onChange={(e, { value }) => updateAttributionWindow(value)}
            disabled={!attributionWindowOptions?.length}
          />
        </>
      )}
      date={
        <DateRangeHooks {...{ startDate, endDate, onDateChange, firstReportingDate }} />
      }
      stickyRight={(
        <>
          <SaveReportButton
            {...{ loading }}
            options={{
              'Platform Conversion': conversionOptions,
              'Platform Attribution': attributionWindowOptions,
            }}
          />
          <ResetFilterButton />
        </>
      )}
      advancedFilters={!!platform && platform !== '0' && (
      <RollupFilter
        rawData={platformPerformanceData}
        hierarchy={hierarchyArr}
        rollupHeadingLabel="Marketing Account Objects"
      />
      )}
    />
  );
};

export default Filter;

export const getCookieValue = name => {
  /**
   * Get the value of a cookie by name from the document's cookies.
   *
   * This function searches for a cookie with the specified name and returns its value if found.
   *
   * @param {string} name - The name of the cookie to retrieve.
   * @returns {string|null} The value of the cookie if found, or null if the cookie does not exist.
   *
   * @example
   * // Usage:
   * const cookieValue = getCookieValue('my_cookie');
   * // Result:
   * // "cookie_value" if 'my_cookie' exists, otherwise null
   */
  // eslint-disable-next-line prefer-template
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? match[2] : null;
};

import * as d3 from 'rockerbox_d3_legacy_clone';
import { TIERS as tiers } from '../../constants/tiers';

export const sumRecursive = node => {
  if (!node.count) {
    node.count = node.values.reduce((p, c) => p + sumRecursive(c), 0); // eslint-disable-line no-param-reassign
  }
  return node.count;
};

export const rollupImpact = (tier, impactDifference) => {
  const allowedTiers = tiers.slice(0, tiers.indexOf(tier) + 1);

  const impactDifferenceSummary = d3.nest()
    .key(row => allowedTiers.map(t => row[t]).join('||'))
    .rollup(values => {
      const row = values.reduce((accu, c) => {
        Object.keys(accu).forEach(key => {
          accu[key] += c[key];
        });
        return accu;
      }, { even: 0, revenue_even: 0, even_without: 0, revenue_even_without: 0 });

      row.change = ((row.even - row.even_without) * 2) / (row.even + row.even_without);
      row.revenue_change = ((row.revenue_even - row.revenue_even_without) * 2) / (row.revenue_even + row.revenue_even_without);

      return row;
    })
    .entries(impactDifference)
    .map(row => row.key
      .split('||')
      .reduce((accu, c, i) => {
        const key = allowedTiers[i];
        accu[key] = c;
        return accu;
      }, row.values))
    .sort((p, c) => p.change - c.change);

  return impactDifferenceSummary;
};

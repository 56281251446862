import React from 'react';

import { Grid, Button } from 'semantic-ui-react';

import { useSubscriptionValues, useSubscriptionDetails, useEmailSubscriptionsAndAuthorization } from './hooks';
import { SubscriptionRow } from './parts';

const EmailSubscriptions = () => {
  const { values, setters, valuesObject, settersObject } = useSubscriptionValues();
  const { loading, isAuthorized, onSubscriptionChanges } = useEmailSubscriptionsAndAuthorization({ valuesObject, settersObject });
  const { subscriptionDetails } = useSubscriptionDetails({ values, setters, isAuthorized });

  return (
    <div style={{ fontSize: '14px' }}>
      <h1 style={{ margin: '0 0 15px 25px', fontWeight: 'bold', fontSize: '28px' }}>
        Email Subscriptions
      </h1>
      <Grid>
        {subscriptionDetails.map(({ name, description, value, onChange, hide }) => (
          !hide && <SubscriptionRow key={name} name={name} description={description} checked={value} onChange={onChange} disabled={!isAuthorized || loading} />
        ))}
      </Grid>
      <Button size="big" color="purple" onClick={onSubscriptionChanges} style={{ margin: '40px 0 0 25px' }} disabled={!isAuthorized || loading} loading={loading}>
        Save Changes
      </Button>
    </div>
  );
};

export default EmailSubscriptions;

import React, { useCallback } from "react";
import { Form } from "semantic-ui-react";
import { datasetAccountingStore } from "./zustandStore";
import SnapshotCreationButton from "./SnapshotCreationButton";

const datasetSelector = state => [
  state.newSnapshot,
  state.setNewSnapshot,
  state.datasetId,
  state.createNewSnapshot
];

const SnapshotCreationForm = props => {
  const [
    newSnapshot,
    setNewSnapshot,
    datasetId,
    createNewSnapshot
  ] = datasetAccountingStore(datasetSelector);
  const onNameChange = useCallback((e, data) => {
    setNewSnapshot("name", data.value);
  });
  const onDescriptionChange = useCallback((e, data) => {
    setNewSnapshot("description", data.value);
  });

  return (
    <Form size="large">
      <Form.Group>
        <Form.Field width={8}>
          <Form.Input
            value={newSnapshot["name"]}
            label="Snapshot Name"
            onChange={onNameChange}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field width={8}>
          <Form.Input
            value={newSnapshot["description"]}
            label="Description"
            onChange={onDescriptionChange}
          />
        </Form.Field>
      </Form.Group>
      <SnapshotCreationButton
        name="Create new snapshot"
        datasetId={datasetId}
        newSnapshot={newSnapshot}
      />
    </Form>
  );
};

export default SnapshotCreationForm;

import React, { useState } from 'react';
import { Card, Grid, Button, Icon, Popup, Message, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { ArrowCircleRightIcon, XIcon } from '@heroicons/react/solid'; // eslint-disable-line import/no-extraneous-dependencies
import { track } from '../../../../utils/tracking';
import { SaveReportButton, ViewAccessWrapper } from '../../../components';

/* eslint-disable max-len */

const Container = styled.div`
  font-family: 'Poppins', 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
`;

const CalloutText = styled.span`
  display: inline-block;
  font-size: 16px;
  font-family: 'Poppins', 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
`;
const StyledMessage = styled(Message)`
  box-shadow: none !important;
  background-color: #EEFCFA !important;
  color: #167466 !important;
`;

// eslint-disable-next-line no-unused-vars
const GoalsShowMeHowButton = ({ setGoalsDrawerOpen, hasGoalsAccess }) => {
  const [openModal, setOpenModal] = useState(false);

  if (hasGoalsAccess) {
    return (
      <Button
        primary
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={() => {
          setGoalsDrawerOpen(true);
          track('channels.digitalAdvertising.goals.card.click');
        }}
      >
        Show me how
        <ArrowCircleRightIcon style={{ display: 'inline-block', height: 16, marginLeft: 10 }} />
      </Button>
    );
  }

  return (
    <Modal
      onClose={() => setOpenModal(false)}
      onOpen={() => setOpenModal(true)}
      size="fullscreen"
      open={openModal}
      trigger={(
        <Button
          primary
          style={{ display: 'flex', alignItems: 'center' }}
        >
          Get Access to Optimize
          <ArrowCircleRightIcon style={{ display: 'inline-block', height: 16, marginLeft: 10 }} />
        </Button>
      )}
    >
      <Modal.Content>
        <div style={{ textAlign: 'right', marginBottom: 10 }}>
          <Icon name="close" size="large" color="grey" onClick={() => setOpenModal(false)} link />
        </div>
        <ViewAccessWrapper
          viewName="goals_and_recs"
          viewComponent={null}
        />
      </Modal.Content>
    </Modal>
  );
};

export const CalloutCards = ({ loading, setGoalsDrawerOpen, hasGoal, accessibleFeatures, conversionOptions, attributionWindowOptions }) => {
  const hasGoalsAccess = accessibleFeatures?.includes('goals_and_recs');
  const [customReportCardState, setCustomReportCardState] = useState(true);
  const [goalCardState, setGoalCardState] = useState(true);

  return (
    <div>
      {(hasGoal || goalCardState || customReportCardState) && (
        <Grid columns="equal" style={{ marginBottom: 10 }}>
          <Grid.Row>
            <Grid.Column>
              {customReportCardState && (
                <Card fluid style={{ backgroundColor: '#fbfbfe' }}>
                  <Card.Content>
                    <Container>
                      <h2 style={{ fontSize: 21 }}>Save frequently used settings as a custom report</h2>
                      <CalloutText style={{ lineHeight: 1.5 }}>Adjust basic and advanced settings to generate your own data set and save the results for easy retrieval later.</CalloutText>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
                        <SaveReportButton
                          {...{ loading }}
                          options={{
                            'Platform Conversion': conversionOptions,
                            'Platform Attribution': attributionWindowOptions,
                          }}
                          buttonContent={(
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              Show me how
                              <ArrowCircleRightIcon style={{ display: 'inline-block', height: 16, marginLeft: 10 }} />
                            </div>
                          )}
                        />
                        <CalloutText style={{ fontSize: '1rem', marginLeft: 20 }} className="rb-clickable" onClick={() => setCustomReportCardState(false)}>
                          Dismiss
                        </CalloutText>
                      </div>
                    </Container>
                  </Card.Content>
                </Card>
              )}
            </Grid.Column>
            <Grid.Column>
              {goalCardState && (
                <Card fluid style={{ backgroundColor: '#fbfbfe' }}>
                  <Card.Content>
                    <Container>
                      <h2 style={{ fontSize: 21 }}>Set goals for CPA and ROAS</h2>
                      <CalloutText style={{ lineHeight: 1.5 }}>
                        Track campaigns against a fixed goal or rolling average, then receive recommendations from Rockerbox on how to optimize toward your goal.
                      </CalloutText>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
                        <GoalsShowMeHowButton {...{ setGoalsDrawerOpen, hasGoalsAccess }} />
                        <CalloutText style={{ fontSize: '1rem', marginLeft: 20 }} className="rb-clickable" onClick={() => setGoalCardState(false)}>
                          Dismiss
                        </CalloutText>
                      </div>
                    </Container>
                  </Card.Content>
                </Card>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </div>
  );
};

export const RemoveGoal = ({ resetGoal }) => (
  <Button
    className="remove-box-shadow"
    style={{ marginLeft: 5, fontSize: 14 }}
    color="purple"
    inverted
    onClick={() => resetGoal()}
  >
    <XIcon style={{ display: 'inline-block', height: 15, margin: '-4px 10px -3px -2px' }} />
    Remove Goal
  </Button>
);

export const NoGoal = () => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <span>
      N/A
      <Popup
        hoverable={true}
        inverted
        content="Within the date range selected, there are days of 0 conversions which does not allow a moving average to be calculated. Try using a Fixed Target goal on this filter set."
        position="top center"
        trigger={<Icon name="question circle" style={{ marginLeft: 5, fontSize: 14, color: '#bfc6f3', cursor: 'help', display: 'inline' }} />}
      />
    </span>
  </div>
);

export const NoEmaMessage = ({ optionalMetrics }) => {
  const hasRoas = optionalMetrics.find(c => c === 'roas');

  return (
    <StyledMessage content={`Within the date range selected, there are days of 0 conversions which does not allow a moving average to be calculated. Try using ${hasRoas ? 'ROAS or switching to' : ''} a Fixed Target goal on this filter set.`} />
  );
};

export const LowBudgetMessage = () => (
  <Message color="yellow" style={{ boxShadow: 'none' }}>
    <p>Your Spend and Budget are close, so there may not be major recommendations available.</p>
  </Message>
);

import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { compose, filter, map, get } from 'lodash/fp';
import { useDatalake } from '../DatalakeContext/hooks';
import ErrorMessage from './ErrorMessage';
import { ROCKERBOX_REPORT_TYPE } from '../constants';

const getConversionTypes = compose(
  map(conversion => ({ key: conversion.action_id, value: conversion.action_id, text: `${conversion.action_name} (${conversion.action_id})` })),
  filter(conversion => !conversion.action_name.toLowerCase().includes('all pages')),
);

const RockerboxDatasetSyncForm = ({
  disableInputs,
  submitDatasetToSync,
  width,
  styles,
}) => {
  const {
    datalakeReports,
    selectDropdownOptionsByReportType,
    segments,
  } = useDatalake();

  const [conversionTypeOptions, setConversionTypeOptions] = useState([]);
  const [datasetOptions, setDatasetOptions] = useState([]);
  const [conversionType, setConversionType] = useState({});
  const [dataset, setDataset] = useState({});
  const [tableName, setTableName] = useState('');
  const [error, setError] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);

  const retrieveDatalakeDatasets = () => {
    const segmentOptions = getConversionTypes(segments);
    setConversionTypeOptions(segmentOptions);
  };

  const retrieveDatalakeReportOptions = () => {
    const reportRockerboxOptions = selectDropdownOptionsByReportType(ROCKERBOX_REPORT_TYPE);
    setDatasetOptions(reportRockerboxOptions);
    retrieveDatalakeDatasets();
  };

  const handleConversionTypeChange = (_, { value }) => setConversionType(value);
  const handleDatasetChange = (_, { value }) => setDataset(value);
  const handleTableNameChange = (_, { value }) => setTableName(value);

  const handleSubmit = () => {
    setError('');
    setDisableSubmit(true);
    return submitDatasetToSync(dataset.toString(), tableName, conversionType.toString())
      .then(() => {
        setDisableSubmit(false);
      })
      .catch(e => {
        const errorMessage = get(e, 'response.data.message');
        setError(errorMessage);
        setDisableSubmit(false);
      })
      .finally(() => {
        setDataset({});
        setConversionType({});
        setTableName('');
      });
  };

  useEffect(() => {
    if (datalakeReports.length) retrieveDatalakeReportOptions();
  }, [datalakeReports, dataset]);

  useEffect(() => {
    if (conversionType > 0 && dataset === 'compiled_mta') {
      segments.forEach(segment => {
        if (segment.action_id === conversionType && segment.action_name.includes('pinterest')) {
          setDisableSubmit(true);
          setError('This dataset is not available for conversions that include attribution data provided by Pinterest.');
        }
      });
    }
  }, [dataset, conversionType]);

  return (
    <>
      <Form>
        <Form.Group style={styles}>
          <Form.Field>
            <Form.Select
              fluid
              style={{ width }}
              label="Dataset"
              options={datasetOptions}
              value={dataset}
              onChange={handleDatasetChange}
              disabled={disableInputs}
            />
          </Form.Field>
          <Form.Field>
            <Form.Select
              fluid
              style={{ width }}
              label="Conversion"
              options={conversionTypeOptions}
              value={conversionType}
              onChange={handleConversionTypeChange}
              disabled={disableInputs}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              fluid
              label="Table Name"
              style={{ width }}
              placeholder="Enter a table name"
              value={tableName}
              onChange={handleTableNameChange}
              disabled={disableInputs}
            />
          </Form.Field>
          <Form.Field>
            <Form.Button
              primary
              size="medium"
              content="Sync this dataset"
              type="button"
              onClick={handleSubmit}
              disabled={disableInputs || disableSubmit}
              style={{ marginTop: 20 }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      {error && <ErrorMessage message={error} />}
    </>
  );
};

export default RockerboxDatasetSyncForm;

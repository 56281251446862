import React from 'react';
import { Dropdown } from 'semantic-ui-react';


export {default as FormWrap} from './FormWrap';
export {default as FieldWrap} from './FieldWrap';
export {default as DateModal} from './DateModal';
export {default as DateSelector} from './DateSelector';
export {default as DeprecationBanner} from './DeprecationBanner';


export const Aux = props => props.children;

export const InlineDropdown = (props) => {
  return (
    <Dropdown {...props} inline preventFluid={true}/>
  )
}

import React, { Component } from 'react';
import moment from 'moment';
import { Popup, Icon } from 'semantic-ui-react';
import { DateRangePicker, isInclusivelyAfterDay, isBefore, isAfter, DayPickerRangeController } from 'react-dates';
import { DateModal, DateSelector } from './stateless';
import 'react-dates/lib/css/_datepicker.css';
import '../react_dates_overrides.css';

export const yesterday = moment.utc().utcOffset(-5).startOf('day').subtract(24, 'hours').format('YYYY-MM-DD');
export const week = moment.utc().utcOffset(-5).startOf('day').subtract(7, 'days').format('YYYY-MM-DD');
export const month = moment.utc().utcOffset(-5).startOf('day').subtract(30, 'days').format('YYYY-MM-DD');

export const timeOptions = [
  {value: yesterday, text: 'Yesterday'},
  {value: week, text: 'Last 7 days'},
  {value: month, text: 'Last 30 days'},
  {value: 'custom', text: 'Custom'}
];

const dateKeys = ["startDate","endDate"]

const formatDate = (date) => {
  return moment(date).format('YYYY-MM-DD')
}

export const _timeRange = (superclass) => class timerange extends superclass {
  state = {
    focusedInput: null
  }

  setTimeRangeState = (obj) => {
    const {startDate, endDate} = obj;
    const invalidEndDate = endDate == 'Invalid date';

    if (!this.updatePath) {
      this.setState(obj)
      return
    }

    const dateUpdates = Object.keys(obj).reduce((p,c) => {
      if (dateKeys.includes(c)) p[c] = obj[c]
      return p
    },{})

    const stateUpdates = Object.keys(obj).reduce((p,c) => {
      if (!dateKeys.includes(c)) p[c] = obj[c]
      return p
    },{})

    if(invalidEndDate) {
      this.updatePath({startDate, endDate: startDate})
      this.setState(stateUpdates)
    }
    else {
      this.updatePath(dateUpdates)
      this.setState(stateUpdates)
    }
  }

  handleDateSelectionChange = (e, {value}) => {
    if(value === 'custom') {
      this.setTimeRangeState({dateModal: true})
    } else {
      this.setTimeRangeState({startDate: value, loading: true})
    }
  }

  handleModalClose = () => {
    this.setTimeRangeState({dateModal: false})
  }

  handleDateChange = (e, stateSlice) => {
    this.setTimeRangeState({[stateSlice]: moment(e).format('YYYY-MM-DD')});
  }

  handleRangeChange = ({start_date, end_date}) => {
    const { startDate, endDate } = this.getStartEndDate();

    this.setTimeRangeState({
      startDate: moment(start_date).format('YYYY-MM-DD') || startDate,
      endDate: moment(end_date).format('YYYY-MM-DD') || endDate
    });
  }

  getStartEndDate = () => {
    const startDate = this.props.match.params.startDate;
    const endDate = this.props.match.params.endDate;

    if (startDate && endDate) {
      return {
        startDate,
        endDate
      }
    }
    return {
      startDate: this.state.startDate,
      endDate: this.state.endDate
    }
  }

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput: focusedInput });
  }

  handleOutsideRange = (day) => {
    const { selected } = this.props;
    const firstReportingDate = selected.first_reporting_date;
    const yesterday = moment.utc().utcOffset(-5).subtract(1,'days').endOf('day');
    const minDate = firstReportingDate ? day.isBefore(moment(firstReportingDate)) : day.isBefore(moment.utc().utcOffset(-5).subtract(365,"days"));
    const maxDate = day.isAfter(yesterday);

    return minDate || maxDate
  }

  handleOutsideClick = () => {
    this.setState({focusedInput: null})
  }

  renderDateSelector() {

    const { startDate, endDate } = this.getStartEndDate()

    return (
      <DateSelector
        {...{ timeOptions, startDate, endDate }}
        handleDateChange={this.handleDateSelectionChange}
      />
    )
  }

  renderDateModal() {

    const { startDate, endDate } = this.getStartEndDate();

    return (
      <DateModal
        modalOpen={this.state.dateModal}
        handleClose={this.handleModalClose}
        startDate={startDate} endDate={endDate}
        handleChange={this.handleDateChange}
      />
    )
  }

  renderAirbnbDatepicker() {
    const { startDate, endDate } = this.getStartEndDate();
    const { focusedInput } = this.state;
    const { setTimeRangeState, onFocusChange, handleOutsideRange, handleOutsideClick } = this
    const airbnbProps = { setTimeRangeState, onFocusChange, handleOutsideRange, focusedInput, startDate, endDate, handleOutsideClick }

    return (
      <div key={0}>
        <ABBDatePicker key={0} {...airbnbProps} />
      </div>
    )
  }
}

class ABBDatePicker extends Component {

  render() {
    const { setTimeRangeState, onFocusChange, handleOutsideRange, handleOutsideClick } = this.props
    const { startDate, endDate, focusedInput } = this.props

    return (
      <React.Fragment>
        <div>
          <React.Fragment>
            <span onClick={() => onFocusChange("startDate")} style={{fontSize: 16}}>{ moment(startDate).format('MM/DD/YYYY') }</span>
            <Icon name="arrow right" style={{marginRight: 5, marginLeft: 5}}/>
            <span onClick={() => onFocusChange("endDate")} style={{fontSize: 16}}>{ moment(endDate).format('MM/DD/YYYY') }</span>
          </React.Fragment>
          <Popup
            on='click'
            pinned
            open={!!focusedInput}
            trigger={<span />}
            position="bottom center"
            style={{marginTop: 28}}
          >
            <DayPickerRangeController
              numberOfMonths={2}
              onDatesChange={(dateObj) => {
                const newFocusedInput = focusedInput === "endDate" ? null : "endDate"

                setTimeRangeState({
                  startDate: formatDate(dateObj.startDate),
                  endDate: formatDate(dateObj.endDate),
                  focusedInput: newFocusedInput
                })
              }}
              focusedInput={focusedInput || "startDate"}
              isOutsideRange={(day) => handleOutsideRange(day)}
              startDate={moment(startDate)}
              endDate={moment(endDate)}
              noBorder={true}
              minimumNights={0}
              hideKeyboardShortcutsPanel
              className="reports-datepicker"
              autoFocusEndDate={true}
              onOutsideClick={handleOutsideClick}
            />
          </Popup>
        </div>
      </React.Fragment>)
  }
}


const TimeRange = _timeRange(Component)

export default TimeRange;

import React, { useState, useReducer } from 'react';

import { rollupTableDataToCollapseRows } from './helpers';
import { useRows, useTableColumns, useTableData, useGroupedData } from './useTableParts';
import { StructuredGrid, SummaryBox, StyledDropdown, FilterWrapper } from '../../../../components';
import { MultiSelectIconButton } from '../../../../components/select/MultiSelectIconButton';
import { formatCurrency, numberFormatter } from '../../../../utils/valueFormatter';
import { CalendarIcon } from '../../parts/stateless';
import { useBudgetPeriod } from '../form/budget/useBudgetPeriod';
import { useProjectionPeriod } from '../form/projection/useProjectionPeriod';

const groupByOptions = [
  'Default',
  'Channel',
  'Platform',
  'Strategy',
].map(text => ({ text, value: text.toLowerCase() }));

const tableColumnOptions = [
  { value: 'baseline', icon: 'window minimize' },
  { value: 'delta', icon: 'percent' },
  { value: 'totalPercent', icon: 'chart pie' },
];
const Forecast = ({ isConversionKPI, isDirty, constraints, featureNameMap, featureStats, data, updateFieldValues }) => {
  const [groupBy, setGroupBy] = useState('default');
  const [columnGroups, setColumnGroups] = useReducer((prev, current) => {
    const hasCurrent = prev.includes(current);
    const newValues = hasCurrent ? prev.filter(item => item !== current) : [...prev, current];
    return [...newValues];
  }, ['baseline', 'delta']);
  const { projectionPeriod, projectionName } = useProjectionPeriod();
  const { budgetPeriod, budgetPeriodName } = useBudgetPeriod();
  const numDaysByPeriod = { projectionPeriod, budgetPeriod };
  const nameByPeriod = { projectionName, budgetPeriodName };

  const { forecastRows, baseline, summary } = data;
  const { zeroRows, constrainedRows, adjustedRows } = useRows({
    isDirty,
    constraints,
    forecastRows,
    summary,
  });

  const { tableData: adjustedTableData } = useTableData(adjustedRows, isConversionKPI, featureNameMap, numDaysByPeriod, 'Adjusted');
  const { tableData: constrainedTableData } = useTableData(constrainedRows, isConversionKPI, featureNameMap, numDaysByPeriod, 'Non Adjustable + Fixed Cost Marketing', true);
  const { tableData: zeroTableData } = useTableData(zeroRows, isConversionKPI, featureNameMap, numDaysByPeriod, 'Inactive Marketing', true);

  const { displayCols: adjustedCols } = useTableColumns(isConversionKPI, updateFieldValues, featureStats, columnGroups, nameByPeriod, 'Marketing Summary');

  const { groupedTableData: groupedAdjustedTableData } = useGroupedData(isConversionKPI, adjustedTableData, groupBy, numDaysByPeriod, projectionName);

  const hasBaseline = baseline > 0;
  const kpiText = isConversionKPI ? 'conversions' : 'revenue';
  const kpiFormatter = value => (isConversionKPI ? numberFormatter(value) : formatCurrency(value, 'USD', 0));
  const allRows = [...groupedAdjustedTableData, ...constrainedTableData, ...zeroTableData];
  const totals = rollupTableDataToCollapseRows(allRows, '', isConversionKPI);

  return (
    <>
      <FilterWrapper
        other={(
          <StyledDropdown
            label="Group By"
            value={groupBy}
            options={groupByOptions}
            onChange={(e, { value }) => setGroupBy(value)}
          />
        )}
        stickyRight={(
          <MultiSelectIconButton values={columnGroups} options={tableColumnOptions} onChange={setColumnGroups} />
        )}
      />
      <StructuredGrid
        sortable
        cols={adjustedCols}
        data={allRows}
        textAlign="right"
        summaryRow
        sticky
      />
      {hasBaseline && (
        <SummaryBox
          icon={<CalendarIcon />}
          headline={`Seasonal effects on ${kpiText}`}
          text={`We estimate your baseline ${kpiText} for this time period based on historical data and add that to your predicted marketing ${kpiText} to calculate your total predicted ${kpiText}.`}
          statCards={[
            { label: `Baseline ${kpiText}`, value: kpiFormatter(baseline) },
            { label: null, value: '+' },
            { label: `Predicted Marketing ${kpiText}`, value: kpiFormatter(totals.totalKPIAtTargetValue) },
            { label: null, value: '=' },
            { label: `Total Predicted ${kpiText}`, value: kpiFormatter(baseline + totals.totalKPIAtTargetValue) },
          ]}
        />
      )}
    </>
  );
};

export default Forecast;

import React, { useMemo } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { CursorClickIcon, ChartBarIcon } from '@heroicons/react/solid';

import PlatformStatusCard from './PlatformStatusCard';
import { useGlobalState } from '../../hooks/global';
import { buildPath } from '../../hooks/urlState';

const PlatformCardV2 = ({ platformsDist, integrations, colorMap, platform, apiSpend, batchFileStatus, syntheticData, conversionData, isConversionPanel, trackingParamAlerts }) => {
  const { account } = useGlobalState();
  const { tier } = account || {};
  const isFreeAccount = tier === 'lite';
  // only show conversion status if the advertiser has that type of conversions
  if (isConversionPanel) {
    const hasPlatformData = conversionData?.find(syncTime => syncTime.type === platform);
    if (!hasPlatformData) return null;
  }

  const platformObj = useMemo(() => {
    if (!integrations) return {};
    return integrations.find(x => x.platform_key === platform) || {};
  }, [integrations, platform]);

  const icon = platformObj.icon_path;
  const display = platformObj.platform;
  const hasPlatformPerformance = !!platformObj.has_platform_performance;
  const color = !colorMap ? undefined : colorMap[platform];
  const totalSpend = Number(platformsDist[platform] || 0);
  const analyticsLink = buildPath('platformPerformance', { view: 'platform', platformName: platform });
  const trackingParamsLink = buildPath('integrationTrackingParameters', { platformName: platform });
  const ordersAndConversionsLink = buildPath('conversionsOverview', { platformName: platform });
  const isBatchPlatform = !!platformObj.batch_platform;

  // don't display card for batch platforms we don't have data for
  if (isBatchPlatform && !batchFileStatus.find(item => item.platform === platform)) {
    return null;
  }

  const accounts = useMemo(() => {
    if (!integrations) return;
    return integrations.filter(x => x.platform_key === platform);
  }, [integrations, platform]);

  const trackingParamAlert = useMemo(
    // eslint-disable-next-line no-mixed-operators
    () => !!trackingParamAlerts && trackingParamAlerts[platform] || {},
    [trackingParamAlerts, platform],
  );
  const hasTrackingParamIssue = !!trackingParamAlert?.hasIssues;

  const footerLinks = useMemo(() => {
    if (isConversionPanel) {
      return [
        {
          HeroIcon: ChartBarIcon,
          text: 'Analytics',
          link: ordersAndConversionsLink,
        },
      ];
    }
    return [
      !isFreeAccount && {
        HeroIcon: CursorClickIcon,
        text: 'Tracking',
        link: trackingParamsLink,
      },
      !!hasPlatformPerformance && {
        HeroIcon: ChartBarIcon,
        text: 'Analytics',
        link: analyticsLink,
      },
    ].filter(Boolean);
  }, [tier, isConversionPanel]);

  return (
    <PlatformStatusCard
      key={platform}
      className="platform-cardv2"
      platform={platform}
      header={display}
      headerImage={icon}
      statistics={[{
        statisticLabel: <>
          Spend
          <span style={{ fontFamily: 'Poppins', opacity: 0.5 }}>Last 30D</span>
          {/* eslint-disable-next-line react/jsx-closing-tag-location */}
        </>,
        statisticValue: totalSpend,
        alignLabel: 'left',
        format: 'currency',
        maxDigits: 2,
        color,
      }]}
      footerLinks={footerLinks}
      accounts={accounts}
      spendData={apiSpend}
      batchFileStatus={batchFileStatus}
      syntheticData={syntheticData}
      conversionData={conversionData}
      isBatchPlatform={isBatchPlatform}
      hasTrackingParamIssue={hasTrackingParamIssue}
      isConversionPlatform={isConversionPanel}
    />
  );
};

export default PlatformCardV2;

import React from 'react';
import { Header, Grid, GridRow, Button } from 'semantic-ui-react';

const CancelSubscription = () => {
  const startIntercomChat = () => {
    if (window.Intercom) window.Intercom('showNewMessage');
  };

  return (
    <>
      <Header
        as="h2"
        content="Modify or Cancel Subscription"
        className="header"
      />
      <Grid>
        <GridRow className="top-row">
          Have questions about your plan or looking to cancel your subscription?
        </GridRow>
        <GridRow className="second-row">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Get in touch with us, and we'll be happy to help.
        </GridRow>
        <GridRow className="third-row">
          <Button
            size="big"
            className="button"
            color="purple"
            inverted={true}
            content="Contact Us"
            onClick={startIntercomChat}
          />
        </GridRow>
      </Grid>
    </>
  );
};

export default CancelSubscription;

import React from 'react'
import { Checkbox, Icon, Segment, Header } from 'semantic-ui-react';
import StepView from '../helpers/Step'
import { InviteUsers } from '../../../V3/components'

import { getSteps } from '../helpers/Step';


export const OnboardingProgressList = ({ completedOnboardingSteps, setActiveStep, tier }) => {
  const STEPS = getSteps(tier)
  return (
    <Segment
      basic
      textAlign='center'
    >
      <div className="onboarding-progress-list">
      {STEPS.map(s => {
        const isComplete = completedOnboardingSteps.includes(s.key);
        return (
          <div className='onboarding-progress-element'
            style={{color: isComplete ? 'black' : '#475ddc'}}
            onClick={() => setActiveStep(s.key)}
          >
            <Checkbox
              readOnly
              checked={isComplete}
            />
            <span>{s.title}</span>
            {!isComplete && <Icon name='arrow right' />}
          </div>
        )
      })}
      </div>
    </Segment>
  )
}

const OnboardingProgress = ({ completedOnboardingSteps, setActiveStep, allStepsComplete, tier }) => { 
  const headerText = tier === 'starter'? 'Rockerbox Starter Onboarding' : 'Rockerbox Onboarding'
  return (
    <Segment
      basic
      className='onboarding-progress-wrapper'
      textAlign='center'
    >
      <Header as='h1'>
       { allStepsComplete ? "You're all set!": headerText}
        <Header.Subheader>
          { allStepsComplete ? "Thanks for completing your account set up! Ready to start diving in?" : "Welcome back! You have a few more set up steps remaining." }
        </Header.Subheader>
      </Header>
      <OnboardingProgressList {...{completedOnboardingSteps, setActiveStep, tier}} />
    </Segment>
  )
}

export default OnboardingProgress

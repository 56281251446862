import React, { useEffect, useState } from 'react';
import { Header } from 'semantic-ui-react';
import { IndexGridTree, ContentCard, LineChart, AreaChartLoader } from '@rockerbox/styleguide';
import { useReportingData } from '../hooks/reportingData';
import useAuthedIntegrations from '../../../hooks/useAuthedIntegrations';
import { buildComparison } from './compare';
import { generateDateRange, getChartData } from '../../../utils/channelPerformance/helpers';
import { metrics } from '../../../utils/channelPerformance/platformMetrics';
import { TreeTableLoader } from '../../../components/loaders';
import {
  PopupCard, Drawer, ChartTooltip, NoDataCat, DataWarning, BreakdownsButton, CustomizeColumnsButton, FilterWrapper, StyledDropdown, LayoutToggles, DownloadCSVButton,
} from '../../../components';
import ComparisonSetup from './EditComparison';
import { NoComparisonSetup, FilterHeader } from './parts';
import { TrendCardsFromComparisonData } from './ComparisonTrendCards';
import Filter from './Filter';
import { track, time } from '../../../../utils/tracking';
import { channelPerformancePlatformsConfig } from '../../../constants/mappings';
import { useGlobalState } from '../../../hooks/global';

/* eslint-disable  react/jsx-props-no-spreading */

const GatedByComparisonSelection = props => {
  const { comparisonConfigsList } = props;
  const { segOptions, segsInfo, conversionOptions, platform, createConfigList, updateConfigList } = props;

  const platformSelection = useAuthedIntegrations(channelPerformancePlatformsConfig);
  const { onDropdownChange, dropdownOptions } = platformSelection;

  const [comparisonDrawerOpen, setComparisonDrawerOpen] = useState(false);

  const missingComparisonConfig = !comparisonConfigsList;
  const emptyComparisonConfig = missingComparisonConfig || comparisonConfigsList.length === 0;
  const closeComparisonDrawer = () => setComparisonDrawerOpen(false);

  const ComparisonDrawer = () => ( // eslint-disable-line react/no-unstable-nested-components
    <Drawer openDrawer={comparisonDrawerOpen} onDrawerClose={closeComparisonDrawer}>
      <ComparisonSetup {...{
        handleCloseComparisonSetup: closeComparisonDrawer,
        comparisonConfigsList: segOptions, segsInfo,
        conversionOptions, platform, createConfigList, updateConfigList,
        open: comparisonDrawerOpen,
      }}
      />
    </Drawer>
  );

  if (missingComparisonConfig) {
    return (
      <>
        {/* Loading... */}
        {/* <ComparisonDrawer /> */}
      </>
    );
  }

  if (emptyComparisonConfig) {
    return (
      <>
        <FilterWrapper
          {...{ collapseKey: platform }}
          other={(
            <StyledDropdown
              label="Ad Platform"
              value={platform}
              options={dropdownOptions}
              onChange={onDropdownChange}
            />
          )}
        />
        <NoComparisonSetup onClick={setComparisonDrawerOpen} {...{ platform }} />
        <ComparisonDrawer />
      </>
    );
  }

  return (
    <>
      <Comparison
        {...{ setComparisonDrawerOpen }}
        {...props}
      />
      <ComparisonDrawer />
    </>
  );
};

const Comparison = props => {
  const { setComparisonDrawerOpen } = props;
  const { windowValue, attributionWindowOptions, updateAttributionWindow } = props;

  const {
    headerText, platform, platformConfig,
    startDate, endDate, onDateChange, firstReportingDate,
    platformPerformanceData,
  } = props;  // dates and filters
  const { platformData, platformDates, integrations } = props; // data
  const { optionalMetric, segId, model } = props; // values
  const { segOptions, modelOptions } = props; // options
  const { updateSegmentAndModel, setModel } = props; // methods for RB data
  const { tiers, setTiers, allColumns, selectedColumns, setSelectedColumns, modelDisplayName } = props;
  const { optional_metrics, hasRevenue, account } = props; // other
  const { onOptionalMetricChange } = props; // trend card method
  const { currencyCode } = useGlobalState();
  const { convValue } = props;
  const [loading, setLoading] = useState(true);
  const [breakdownsDrawerOpen, setBreakdownsDrawerOpen] = useState(false);
  const [columnsDrawerOpen, setColumnsDrawerOpen] = useState(false);
  const [groupDatesBy, setGroupDatesBy] = useState('daily');

  useEffect(
    () => {
      const hasOptions = segOptions.length && attributionWindowOptions.length;
      const missingSegment = segId === 0;
      if (hasOptions && missingSegment) updateSegmentAndModel(segOptions[0].filter_id);
    },
    [segOptions, attributionWindowOptions, segId],
  );

  useEffect(() => {
    setLoading(true);
  }, [startDate, endDate, platform, segId]);

  const { tiersData, tiersDates, assisted, assistedWindow, ttcDates } = useReportingData(account);

  const dateRange = React.useMemo(() => generateDateRange(startDate, endDate), [startDate, endDate]);

  const comparisonArgs = [
    platformData, platformDates,
    tiersData, tiersDates,
    assisted, assistedWindow, ttcDates, dateRange,
    model,
  ];
  const { comparisonData, dailyComparisonData, missingDates } = React.useMemo(
    () => buildComparison(...comparisonArgs),
    comparisonArgs,
  );

  useEffect(() => {
    if (!comparisonData) return;
    setLoading(false);
  }, [comparisonData]);

  useEffect(() => {
    if (!platform || !startDate || !endDate) return;
    if (!!loading) {
      time('channels.digital_advertising.comparison.view');
      return;
    }
    track('channels.digital_advertising.comparison.view', {
      platform,
      start_date: startDate,
      end_date: endDate,
    });
  }, [platform, startDate, endDate, loading]);

  useEffect(() => {
    if (!segOptions.length > 0) return;

    const defaultAttributionWindow = platformConfig?.default_attribution_window;
    const hasExistingConversion = segOptions.find(c => c.platform_conversion === convValue);
    const hasExistingAttributionWindow = attributionWindowOptions.find(c => c.value === windowValue);

    if (!hasExistingConversion) {
      updateSegmentAndModel(segOptions[0].filter_id);
    }

    if (!hasExistingAttributionWindow) {
      updateAttributionWindow(defaultAttributionWindow);
    }
  }, [convValue, segOptions, attributionWindowOptions, platform]);

  const hasNoData = (missingDates && missingDates.length === 0 && comparisonData.length === 0);
  const hasMissingDates = (missingDates && missingDates.length > 0);

  if (hasNoData) {
    return (
      <div>
        <Filter {...{
          platform, loading, platformPerformanceData, platformConfig, // data
          startDate, endDate, onDateChange, firstReportingDate, // dates
          segOptions, segId, updateSegmentAndModel, setComparisonDrawerOpen, // conversion
          modelOptions, model, setModel, // model
          attributionWindowOptions, windowValue, updateAttributionWindow, // attribution window
        }}
        />
        <FilterHeader {...{ headerText }} />
        <NoDataCat message="No Data" height="200px" />
      </div>
    );
  }
  if (hasMissingDates) {
    return (
      <div>
        <Filter {...{
          platform, loading, platformPerformanceData, platformConfig, // data
          startDate, endDate, onDateChange, firstReportingDate, // dates
          segOptions, segId, updateSegmentAndModel, setComparisonDrawerOpen, // conversion
          modelOptions, model, setModel, // model
          attributionWindowOptions, windowValue, updateAttributionWindow, // attribution window
        }}
        />
        <FilterHeader {...{ headerText }} />
        <DataWarning
          header="Incomplete Data"
          details={`Unable to perform comparison due to missing underlying data due to invalid data for dates: ${missingDates.join(', ')}`}
        />
      </div>
    );
  }

  const dataUndefined = (dailyComparisonData === undefined);
  const missingDailyComparisonData = (dailyComparisonData && dailyComparisonData.length === 0);
  const selectedCardMetricText = optionalMetric === 'cpa' || optionalMetric === 'roas' ? optionalMetric.toUpperCase() : optionalMetric;
  const chartData = getChartData(dailyComparisonData, groupDatesBy, true);

  return (
    <div>
      <Filter {...{
        platform, loading, platformPerformanceData, platformConfig, // data
        startDate, endDate, onDateChange, firstReportingDate, // dates
        segOptions, segId, updateSegmentAndModel, setComparisonDrawerOpen, // conversion
        modelOptions, model, setModel, // model
        attributionWindowOptions, windowValue, updateAttributionWindow, // attribution window
      }}
      />

      <FilterHeader {...{ headerText }} />

      <LayoutToggles
        chartComponent={(
          <>
            <TrendCardsFromComparisonData {...{
              platformConfig, integrations, platform,
              optional_metrics, optionalMetric, selectedCardMetric: optionalMetric,
              dailyComparisonData, hasRevenue, model, onCardClick: onOptionalMetricChange,
              currencyCode,
            }}
            />

            <ContentCard style={{ borderRadius: 0, border: '1px solid #e6e6e6', zIndex: 0, position: 'static', boxShadow: 'none' }}>
              <Header as="h2">
                { `Attributed ${platformConfig.display_platform_name} ${metrics[optionalMetric]?.display_name} vs. Rockerbox ${modelDisplayName} `}
              </Header>
              { (loading || dataUndefined) ? <AreaChartLoader />
                : (missingDailyComparisonData) ? <NoDataCat message="No Data" height="200px" />
                  : (
                    <LineChart
                      height={200}
                      showTooltip
                      domainDefault="auto"
                      tooltipComponent={ChartTooltip}
                      data={chartData}
                    // customTooltip={customTooltip(platformConfig.display_platform_name)}
                      XAxisKey="date"
                      YAxisStyle={[
                        {
                          keyName: `${optionalMetric}_${model}_rb`,
                        },
                        {
                          color: '#FFA278',
                          keyName: optionalMetric || 'conversions',
                        },
                      ]}
                      nameMap={{
                        [`${optionalMetric}_${model}_rb`]: `Rockerbox ${selectedCardMetricText}`,
                        [optionalMetric || 'conversions']: `${platformConfig.display_platform_name} ${selectedCardMetricText || 'Conversions'}`,
                      }}
                      currencyOverride={currencyCode}
                      showXAxis
                      groupDatesToggle
                      dottedLineForInfinity
                      {...{ groupDatesBy, setGroupDatesBy }}
                    />
                  )}
            </ContentCard>
          </>
        )}
        tableComponent={(
          <ContentCard hasTable>
            { (loading || dataUndefined) ? <TreeTableLoader showSearch />
              : (missingDailyComparisonData) ? <NoDataCat message="No Data" height="200px" />
                : (
                  <IndexGridTree
                    cols={selectedColumns}
                    allCols={allColumns}
                    data={comparisonData}
                    title={`Attributed ${platformConfig.display_platform_name} Performance`}
                    summaryRow
                    showSearch
                    searchPlaceholder="Search"
                    rightContent={(
                      <div>
                        <BreakdownsButton onClick={() => setBreakdownsDrawerOpen(true)} />
                        <CustomizeColumnsButton onClick={() => setColumnsDrawerOpen(true)} />
                        <DownloadCSVButton
                          data={comparisonData}
                          selectedColumns={selectedColumns}
                          trackName="attribution.rockerboxperformance.download"
                        />
                      </div>
                    )}
                    tiersOrder={tiers}
                    orderBy="conversions"
                    orderDirection="descending"
                    sticky
                  />
                )}
          </ContentCard>
        )}
      />

      <Drawer openDrawer={breakdownsDrawerOpen} onDrawerClose={() => setBreakdownsDrawerOpen(false)}>
        <PopupCard
          title="Breakdowns"
          list={tiers}
          setOrder={setTiers}
          callbackFn={() => setBreakdownsDrawerOpen(false)}
          open={breakdownsDrawerOpen}
        />
      </Drawer>
      <Drawer openDrawer={columnsDrawerOpen} onDrawerClose={() => setColumnsDrawerOpen(false)}>
        <PopupCard
          title="Customize Columns"
          isCustom={true}
          customKey="display"
          list={selectedColumns}
          originalList={allColumns}
          setOrder={setSelectedColumns}
          icon="none"
          callbackFn={() => setColumnsDrawerOpen(false)}
          excludeFirst={1}
          open={columnsDrawerOpen}
        />
      </Drawer>
    </div>
  );
};

export default GatedByComparisonSelection;

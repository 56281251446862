const NAMESPACE = 'exports';

export function postExportRequest(params) {
  const url = `/${NAMESPACE}/report`;
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then(data => data);
}

export function getExportRequestsForAdvertiser() {
  const url = `/${NAMESPACE}/report?request_origin=ui`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(({ data }) => data);
}

export function getExportRequestStatus(report_request_id) {
  const url = `/${NAMESPACE}/report/status/${report_request_id}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => {
      if (!response.ok) {
        console.log(`${url} ${response.status} ${response.statusText}`);
        return null;
      }
      return response.json();
    })
    .then(data => (data ? data.response : data));
}

import React from 'react';
import { connect } from 'react-redux';
import { Header, Icon } from 'semantic-ui-react';
import { ContentCard } from '@rockerbox/styleguide';

export const Announcements = props => {
  if (props.announcements){
    return props.announcements.map(a => {
      if(a.major > 0) return (
        <ContentCard className='user-notification announcements'>
          <Header as='h3'>
            <Icon name='exclamation triangle' color={a.color || 'yellow'} />
            <Header.Content>
              {a.title}
              <Header.Subheader>{a.message}</Header.Subheader>
            </Header.Content>
          </Header>
        </ContentCard>
      )
    })
  }
  return null
}

function mapStateToProps(state, props) {
  return {
    announcements: state.app.announcements,
  };
}

const ConnectedAnnouncements = connect(mapStateToProps, null)(Announcements);

export default ConnectedAnnouncements;

import React, { useEffect } from 'react';
import moment from 'moment';
import { LineChart } from '@rockerbox/styleguide';
import { CustomLegend } from './parts';
import { COLORS, METRICS } from './constants';
import { calculateMinMax, groupAndCalculateMean, findMetricIndex } from './helpers';

// format x-axis ticks to show 3-digit month abbreviations, skipping repeats
let prevMonth = null;
const formatDate = tickItem => {
  const currentMonth = moment(tickItem).format('MMM');
  if (prevMonth === currentMonth) {
    return '';
  }
  prevMonth = currentMonth;
  return currentMonth;
};

const LineChartWithLegend = ({ data, loading, platform, topTab, setTopTab }) => {
  const activeMetrics = platform === 'facebook'
    ? METRICS
    : Object.keys(METRICS).slice(2, 5).reduce((acc, key) => ({ ...acc, [key]: METRICS[key] }), {});
  const metric = activeMetrics[topTab];

  useEffect(() => {
    prevMonth = null;
  }, []);

  useEffect(() => {
    const defaultTab = platform === 'facebook' ? 'cpa' : 'cpm';
    setTopTab(defaultTab);
  }, [platform]);

  return (
    !loading
    && (
    <div className="chart-container">
      <div className="legend-container">
        <CustomLegend activeIndex={topTab} onClick={setTopTab} data={data} activeMetrics={activeMetrics} />
      </div>
      <div className="chart-wrapper" style={{ width: '100%', height: 400 }}>
        <LineChart
          key={metric}
          height={400}
          data={groupAndCalculateMean(data, platform)}
          YAxisStyle={[
            {
              keyName: metric,
              color: COLORS[findMetricIndex(topTab, activeMetrics)],
            },
          ]}
          showTooltip
          showXAxis
          domainDefault={calculateMinMax(metric, groupAndCalculateMean(data, platform)).max}
          formatter={metric === 'roas' ? v => parseFloat(v.toFixed(2)) : metric === 'ctr' ? v => `${(v * 100).toFixed(2)}%` : v => `$${v.toFixed(2)}`}
          XAxisFormatter={formatDate}
          XAxisTickHeight={14}
          allCaps
          yAnchor={130}
          dottedLineForInfinity
        />
      </div>
    </div>
    )
  );
};
export default LineChartWithLegend;

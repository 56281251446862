import React from 'react';

import { Button } from 'semantic-ui-react';

export const MultiSelectIconButton = ({ values, options, onChange }) => {
  const isSelected = option => values.includes(option.value);

  return (
    <Button.Group>
      {options.map(option => (
        <Button active={isSelected(option)} icon={option.icon} onClick={() => onChange(option.value)} />
      ))}
    </Button.Group>
  );
};

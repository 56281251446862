import { rawBufferToArrowBufferIPC, arrowBufferIPCToArrowTable } from '../format/convertFormat';
import init from '../parquet';

const path = '/v3/static/js/arrow1_bg.wasm';
let arrowIsInit = false;  // eslint-disable-line no-unused-vars
init(path).then(resp => {
  arrowIsInit = resp;
});

/*
 * Functions for interacting with /data-reporting APIs
 */

const buildBase = dataset => `/data-reporting/${dataset}/cache`;
const buildDataURL = (date, identifier, dataset) => (
  dataset === 'spend-mta-limited'
    ? `${buildBase('spend-mta')}/limited_tiers?date=${date}&identifier=${identifier}`
    : `${buildBase(dataset)}?date=${date}&identifier=${identifier}`);

const buildMetaDataUrl = (startDate, endDate, identifier, dataset) => (
  dataset === 'spend-mta-limited'
    ? `${buildBase('spend-mta')}/hash?start_date=${startDate}&end_date=${endDate}&identifier=${identifier}`
    : `${buildBase(dataset)}/hash?start_date=${startDate}&end_date=${endDate}&identifier=${identifier}`);

/*
 * getMetaData is used to know which datasets need to be fetched
 * array of results are compared to what is in cache to determine what to fetch
 */
export const getMetaData = dataset => (identifier, startDate, endDate) => {
  const statsUrl = buildMetaDataUrl(startDate, endDate, identifier, dataset);
  return fetch(statsUrl, { credentials: 'include' })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(arr => arr.map(row => ({ ...row, dataset, identifier })))
    .catch(error => {
      console.error('Error fetching or processing cache data:', error);
    });
};

// Used to fetch the data
export const getData = dataset => async ({ date, identifier, id }) => {
  const url = buildDataURL(date, identifier, dataset);
  const promise = await fetch(url, { credentials: 'include' });
  const rawBuffer = await promise.arrayBuffer();
  const buffer = await rawBufferToArrowBufferIPC(rawBuffer);
  const data = await arrowBufferIPCToArrowTable(buffer);
  return { buffer, data, dataset, date, identifier, id };
};

import React from 'react';

import { Table, Header, Icon } from 'semantic-ui-react';

const defaultFormatter = x => x;

const DefaultCell = ({ item, col }) => {
  const formatter = col.formatter || defaultFormatter;
  return (
    <Table.Cell width={item[col.cellWidth]} className={`data-${col?.key}`}>
      <span>{formatter(item[col.key])}</span>
    </Table.Cell>
  );
};

export const writeCell = (item, col, index, handleRowExpand, isExpanded) => {
  const CellComponent = col.as || DefaultCell;

  return (
    <CellComponent {...{ col, item, handleRowExpand, isExpanded }} key={index} />
  );
};

export const ContentCardTableFooter = ({ children }) => (
  <Table.Footer>
    <Table.Row>
      <Table.HeaderCell colSpan="100">
        <div className="footer-content">{children}</div>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Footer>
);

export const TableHeading = ({ title, right }) => (
  (title || right) ? (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#f2f4fd', padding: '1em', margin: '1em 0' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {title && <h2 style={{ margin: 0, paddingRight: '1em', fontWeight: 300 }}>{title}</h2>}
      </div>
      {right && right}
    </div>
  ) : null
);

export const HeaderCells = ({ cols, sorting, column, direction, handleSort }) => cols.map(col => {
  const isSortable = sorting && col.sortable !== false;

  if (col.headerAs) {
    return col.headerAs(col);
  }

  if (isSortable) {
    return (
      <Table.HeaderCell
        key={col.key}
        style={({ borderLeft: 'none', ...col.headerStyle })}
        onClick={() => handleSort(col.key)}
        sorted={column === col.key ? direction : null}

      >
        {col.display}
      </Table.HeaderCell>
    );
  }

  return (
    <Table.HeaderCell
      key={col.key}
      style={({ borderLeft: 'none', ...col.headerStyle })}
    >
      {col.display}
    </Table.HeaderCell>
  );
});

export const EmptyMessage = ({ callToAction, emptyIcon = 'info', fallbackMsg = 'No data found' }) => (
  <Table.Body>
    <Table.Row>
      <Table.Cell
        textAlign="center"
        selectable={false}
        colSpan="100"
        className={callToAction ? undefined : 'empty'}
        style={{ borderTop: 'solid 1px #efefef' }}
      >
        <br />
        <Icon
          circular
          disabled
          name={emptyIcon}
          size="big"
          className="m-b-10"
        />
        <Header
          as="h3"
          disabled
          content={fallbackMsg}
          className="m-0"
        />
        {callToAction && <div style={{ marginTop: 8 }}>{callToAction}</div>}
        <br />
      </Table.Cell>
    </Table.Row>
  </Table.Body>
);

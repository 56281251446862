import React from 'react';
import CacheContext from '../../utils/CacheContext';
import { getSegments, getDirectMailSegments, updateDirectMailSegments } from '../../utils/api';
import { Loader, Button } from 'semantic-ui-react';
import { ContentCard } from '@rockerbox/styleguide';
import MultiselectTwoSides from "react-multiselect-two-sides";

const DirectMailIntegration = () => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const [directMailSegments, setDirectMailSegments] = React.useState(false)
  const segments = Context.getCache(`segments`, getSegments) || []
  const [availableSegments] = React.useMemo(() => {
    return [segments.filter( s => s.touchpoints != 1 && s.is_child != 1 )]
  }, [segments])
  
  React.useEffect( () => {
    getDirectMailSegments()
      .then( direct_mail_segments => {
        setDirectMailSegments(direct_mail_segments.map( x => x.action_id ) )
      })
  }, [])

  const submit = () => {
    const data = directMailSegments;
    updateDirectMailSegments(data)
      .then( direct_mail_segments => {
        setDirectMailSegments(direct_mail_segments.map( x => x.action_id ))
      })
  } 

  if ((!availableSegments && !availableSegments.length) || !directMailSegments) return <Loader active />  
  return (
     <ContentCard title="Apply Direct Mail to these segments">
       
      <MultiselectTwoSides
          options={availableSegments}
          value={directMailSegments}
          className="msts_theme_example"
          onChange={setDirectMailSegments}
          availableHeader="Segments Available"
          availableFooter={`Available: ${availableSegments.length - directMailSegments.length}`}
          selectedHeader="Apply Direct mail"
          selectedFooter={`Selected: ${directMailSegments.length}`}
          labelKey="action_name"
          valueKey="action_id"
          showControls
          searchable
      />
      <Button
        as="a"
        primary
        onClick={submit}
        style={{ marginTop: "10px" }}
      >
        Save
      </Button>

    </ContentCard>
  )
}

export default DirectMailIntegration

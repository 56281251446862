import { generateDateRange } from '../AttributionReport/helpers';
import { getPlatformPerformanceSummary, getPlatformPerformanceSyncStatus } from '../../api/platformPerformanceApi';
import { getSpendByPlatform } from '../../api/attribution';
import { getParquetAndReturnJson } from '../../utils/parquetToJson';

export const PLATFORM_PERFORMANCE_PLATFORMS = [
  'facebook',
  'pinterest',
  'bing',
  'tiktok',
  'linkedin',
  'adwords',
  'snapchat',
];

export const generateBlankPlatformData = (startDate, endDate, platforms) => {
  const blankObject = {};
  platforms.forEach(p => { blankObject[p] = 0; });
  const blankDates = generateDateRange(startDate, endDate)
    .map(date => ({ date, ...blankObject }));

  const dateObj = {};
  blankDates.forEach(d => { dateObj[d.date] = d; });

  return dateObj;
};

export const generateBlankShopifyData = (startDate, endDate) => {
  const blankObject = { revenue: 0, conversions: 0 };
  const blankDates = generateDateRange(startDate, endDate)
    .map(date => ({ date, ...blankObject }));

  const dateObj = {};
  blankDates.forEach(d => { dateObj[d.date] = d; });

  return dateObj;
};

export const getTimeOfDay = () => {
  const currentHour = new Date().getHours();

  const MORNING_START = 4;
  const AFTERNOON_START = 12;
  const EVENING_START = 18;

  if (currentHour >= MORNING_START && currentHour < AFTERNOON_START) {
    return 'morning';
  }
  if (currentHour >= AFTERNOON_START && currentHour < EVENING_START) {
    return 'afternoon';
  }
  return 'evening';
};

export const getShopifyOrderData = (start, end) => (
  getPlatformPerformanceSummary('shopify', start, end)
    .then(data => {
      const flatData = data.map(x => x.metrics).flat();

      const dataByDate = generateBlankShopifyData(start, end);

      flatData.forEach(x => {
        dataByDate[x?.date].revenue += (x?.summary?.subtotal_price || 0);
        dataByDate[x?.date].conversions = dataByDate[x?.date].conversions + (x?.summary?.first_order || 0) + (x?.summary?.returning_customer || 0);
      });

      return Object.keys(dataByDate).map(x => ({
        date: x,
        revenue: dataByDate[x]?.revenue,
        conversions: dataByDate[x]?.conversions,
      }));
    })
);

export const getPlatformSpendData = (authorizations, start, end) => {
  const requests = [];
  authorizations.forEach(x => {
    if (x.platform === 'shopify') return;
    if (PLATFORM_PERFORMANCE_PLATFORMS.includes(x.platform)) {
      requests.push(getPlatformPerformanceSummary(x.platform, start, end));
    } else {
      // eslint-disable-next-line array-callback-return
      x.authorizations.map(account => {
        const vPlatform = x.platform === 'adroll' ? 'adroll_v1' : x.platform;
        requests.push(getSpendByPlatform(vPlatform, account.account_id, start, end));
      });
    }
  });

  return Promise.all(requests)
    .then(data => {
      // eslint-disable-next-line no-prototype-builtins
      const flatData = data.flat().map(x => (x.hasOwnProperty('metrics') ? x.metrics : x.values)).flat();

      const dataObj = flatData.reduce((acc, x) => {
        // eslint-disable-next-line no-param-reassign
        if (!acc[x?.date]) acc[x?.date] = { spend: 0 };
        // eslint-disable-next-line no-prototype-builtins, no-param-reassign
        acc[x?.date].spend += (x.hasOwnProperty('summary') ? (x?.summary?.spend || 0) : (x?.spend || 0));
        return acc;
      }, {});

      return Object.keys(dataObj).map(x => ({
        date: x,
        spend: dataObj[x]?.spend,
      }));
    });
};

export const getPlatformSpendDataByPlatform = (authorizations, start, end) => {
  const acceptedPlatforms = authorizations.filter(p => p.platform !== 'shopify').map(p => (p.platform === 'adroll' ? 'adroll_v1' : p.platform));

  const requests = [];
  authorizations.forEach(x => {
    if (x.platform === 'shopify' || x.platform.includes('postie')) return;
    // conditionally hit /has/spend if non-PP platform
    if (PLATFORM_PERFORMANCE_PLATFORMS.includes(x.platform)) {
      requests.push(getPlatformPerformanceSummary(x.platform, start, end));
    } else {
      x.authorizations.forEach(account => {
        const vPlatform = x.platform === 'adroll' ? 'adroll_v1' : x.platform;
        requests.push(getSpendByPlatform(vPlatform, account.account_id, start, end));
      });
    }
  });

  return Promise.all(requests)
    .then(data => {
      const dataByDate = generateBlankPlatformData(start, end, acceptedPlatforms);

      data.forEach(platformData => {
        platformData.forEach(accountData => {
          const { metrics } = accountData;
          const spendData = metrics || accountData.values;
          const platform = metrics ? accountData.platform : spendData[0].platform;
          const totalAccountSpend = metrics ? metrics.reduce((a, b) => a + b.summary.spend, 0) : spendData.reduce((a, b) => a + b.spend, 0);
          spendData.forEach(obj => {
            const selectedObj = dataByDate[obj.date];
            // eslint-disable-next-line no-return-assign, no-prototype-builtins
            if (!selectedObj) return dataByDate[obj.date] = { date: obj.date, [platform]: obj.hasOwnProperty('summary') ? obj.summary.spend : obj.spend };
            // eslint-disable-next-line no-prototype-builtins
            selectedObj[platform] = obj.hasOwnProperty('summary') ? obj.summary.spend : obj.spend + (selectedObj[platform] || 0);
          });

          Object.assign(platform, { spend: Number(totalAccountSpend.toFixed(2)) });
        });
      });
      return dataByDate;
    });
};

export const getPlatformSyncStatusByPlatform = (platforms, start, end) => {
  const requests = platforms.map(p => getPlatformPerformanceSyncStatus(p, start, end));

  return Promise.all(requests)
    .then(responses => {
      const addPlatformAsKeys = platforms.reduce((p, c, i) => {
        // eslint-disable-next-line no-param-reassign
        p[c] = responses[i];
        return p;
      }, {});

      const syncingPlatformsArr = [];

      Object.entries(addPlatformAsKeys).forEach(([key, value]) => {
        syncingPlatformsArr.push({ text: key, value });
      });

      return syncingPlatformsArr;
    });
};

export const getSpend = (start, end) => {
  const url = `/data-reporting/advertiser-spend-by-platform/cache?start_date=${start}&end_date=${end}`;
  return getParquetAndReturnJson(url)
    .then(data => data)
      .catch(err => console.log(err)); // eslint-disable-line
};

export const formatAPIData = newApiData => {
  const result = {};
  const platforms = new Set();

  newApiData.forEach(({ date, platform, spend }) => {
    platforms.add(platform);

    if (!result[date]) {
      result[date] = { date };
    }

    result[date][platform] = spend;
  });

  Object.values(result).forEach(dayObj => {
    platforms.forEach(platform => {
      if (dayObj[platform] === undefined) {
        // eslint-disable-next-line no-param-reassign
        dayObj[platform] = 0;
      }
    });
  });

  return Object.values(result).sort((a, b) => new Date(a.date) - new Date(b.date));
};

import React, { Component } from "react";
import AvailReportingPlot from "../AvailReportingPlot";
import RedeliverReportModal from "./RedeliverReportModal";
import { Icon, Button } from "semantic-ui-react";
import { ContentCard } from "@rockerbox/styleguide";
import { Aux } from "../stateless";
import moment from 'moment';
import { Link } from "react-router-dom";
import * as routes from "../../routes";
import { getScheduledreports, getSegments } from '../../utils/api';

const DestinationReporting = (props) => {
  const dest = props.destination;

  const data = dest.files.map((x) => {
    return {
      data: props.dates.map((d, i) => [d, x["delivered"][i] || 0]),
      interval_s: 24 * 60 * 60,
      measure: x["filename"],
    };
  });

  return (
    <div>
      <h4>{dest.name}</h4>
      <AvailReportingPlot data={data} />
    </div>
  );
};

const ReportStatusWrapper = (props) => {
  return (
    <Aux>
      <ContentCard
        title={props.title ? `Status: ${props.title}` : "Status"}
        topRight={
          <Link to={routes.reports}>
            <Icon name="angle left" />
            Back to Exports
          </Link>
        }
      >
        {props.children}
      </ContentCard>
    </Aux>
  );
};

export default class ReportDeliveryStatus extends Component {
  state = {
    showModal: false,
    segment_id: undefined,
    saved_report: {},
    firstReportingDate: moment('2018-01-01'),
  };

  onModalClose = () => this.setState({ showModal: false });
  openModal = () => this.setState({ showModal: true });

  componentDidMount() {
    const { match: { params: { id } = {} } = {} } = this.props;
    const scheduled_report_id = parseInt(id);

    Promise.all([getScheduledreports(), getSegments()])
      .then(resp => {
        const reports = resp[0]
        const segments = resp[1]
        const report = reports.filter(report => report.id === scheduled_report_id)[0]
        const segment = segments.filter(segment => segment.filter_id === parseInt(report.filter_id))[0]

        if (report) {
          this.setState({ segment_id: parseInt(report.filter_id) })
        }

        if (segment){
          this.setState({ firstReportingDate: moment(segment.first_reporting_date) })
        }

      })
  }

  componentWillUnmount() {
    this.props.resetReportDeliveryStatus();
  }

  render() {
    const {
      reportDeliveryStatusData,
      report,
      dates,
      resolved,
      match: { params: { id } = {} } = {},
    } = this.props;
    const { destination: currentDestination } = report || {};
    const initialDate = dates[0];
    const deliveryStatusDataByDestination = reportDeliveryStatusData
      .filter((data) => data.name === currentDestination)
      .map((data) => {
        let latestFileName = "";
        let latestIndex = 0;

        const combineFiles = data.files.reduce((prev, current) => {
          if (!prev.delivered) {
            return current;
          }

          prev.delivered = prev.delivered.map((item, i) => {
            if (current.delivered[i] === 1 && i > latestIndex) {
              latestIndex = i;
              latestFileName = current.filename;
            }
            return prev.delivered[i] || current.delivered[i];
          });

          return prev;
        }, {});

        combineFiles.filename = latestFileName
          ? latestFileName
          : combineFiles.filename;
        data.files = [combineFiles];

        return data;
      });
    const { showModal } = this.state;

    return (
      <ReportStatusWrapper title={report ? report.name : ""} {...this.props}>
        {resolved ? (
          <div>
            {deliveryStatusDataByDestination.length > 0 ? (
              deliveryStatusDataByDestination.map((d) => {
                return <DestinationReporting destination={d} dates={dates} />;
              })
            ) : (
              <h3>No report delivery data to show</h3>
            )}
            <div style={{ paddingTop: "10px" }}>
              <Button primary onClick={this.openModal}>
                Redeliver Report
              </Button>
              <RedeliverReportModal
                close={this.onModalClose}
                open={showModal}
                {...{ report, id }}
                segment_id={this.state.segment_id}
                initialDate={initialDate}
                firstReportingDate={this.state.firstReportingDate}
              />
            </div>
          </div>
        ) : (
          <ContentCard.CardLoader />
        )}
      </ReportStatusWrapper>
    );
  }
}

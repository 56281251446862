import React from 'react';

import ContentLoader from 'react-content-loader';
import { Segment } from 'semantic-ui-react';

const ComboChartLoader = () => (
  <Segment style={{ overflow: 'hidden', padding: 0 }}>
    <ContentLoader
      viewBox="0 0 845 424"
      preserveAspectRatio="none"
      foregroundColor="#e5e5e5"
      height={424}
      width="100%"
    >
      <path d="M91.92,34.49H26.77c-2.21,0-4-1.79-4-4v-5.06c0-2.21,1.79-4,4-4h65.15c2.21,0,4,1.79,4,4v5.06 C95.92,32.7,94.13,34.49,91.92,34.49z" />
      <path d="M280.41,34.49H151.32c-2.21,0-4-1.79-4-4v-5.06c0-2.21,1.79-4,4-4h129.09c2.21,0,4,1.79,4,4v5.06 C284.41,32.7,282.62,34.49,280.41,34.49z" />
      <rect x="20.21" y="298.42" width="20.69" height="107.5" />
      <rect x="47.14" y="282.94" width="20.69" height="122.98" />
      <rect x="74.2" y="274.55" width="20.69" height="131.37" />
      <rect x="101.06" y="267.11" width="20.69" height="138.81" />
      <rect x="128.24" y="280.47" width="20.69" height="125.45" />
      <rect x="155.51" y="290.02" width="20.69" height="115.9" />
      <rect x="182.78" y="274.55" width="20.69" height="131.37" />
      <rect x="209.84" y="257.24" width="20.69" height="148.68" />
      <rect x="236.7" y="242.9" width="20.69" height="163.02" />
      <rect x="263.87" y="233.4" width="20.69" height="172.52" />
      <rect x="290.32" y="244.79" width="20.69" height="161.13" />
      <rect x="317.25" y="221.58" width="20.69" height="184.34" />
      <rect x="344.31" y="209.02" width="20.69" height="196.9" />
      <rect x="371.17" y="197.86" width="20.69" height="208.06" />
      <rect x="398.35" y="217.89" width="20.69" height="188.03" />
      <rect x="425.62" y="253.49" width="20.69" height="152.43" />
      <rect x="452.89" y="233.14" width="20.69" height="172.77" />
      <rect x="479.95" y="210.37" width="20.69" height="195.55" />
      <rect x="506.81" y="191.51" width="20.69" height="214.41" />
      <rect x="533.98" y="179.02" width="20.69" height="226.9" />
      <rect x="560.94" y="236.21" width="20.69" height="169.71" />
      <rect x="587.87" y="211.77" width="20.69" height="194.15" />
      <rect x="614.93" y="198.53" width="20.69" height="207.39" />
      <rect x="641.79" y="186.78" width="20.69" height="219.14" />
      <rect x="668.96" y="207.88" width="20.69" height="198.04" />
      <rect x="696.23" y="222.95" width="20.69" height="182.97" />
      <rect x="723.51" y="198.53" width="20.69" height="207.39" />
      <rect x="750.57" y="171.2" width="20.69" height="234.72" />
      <rect x="777.42" y="148.56" width="20.69" height="257.36" />
      <rect x="804.6" y="133.56" width="20.69" height="272.36" />
    </ContentLoader>
  </Segment>
);

export default ComboChartLoader;

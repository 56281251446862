import React from 'react'
import CacheContext from '../../utils/CacheContext'
import { getSegments } from '../../utils/api'
import moment from 'moment'
import PostlogLift from '../PostlogLift'
import { Loader } from 'semantic-ui-react'


const PostlogIntegration = () => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const segments = Context.getCache(`segments`, getSegments) || []

  const viewSegment = segments.find(row => row.touchpoints == 1)
  const id = viewSegment && viewSegment['action_id']

  if (!id) return <Loader active />

  const startDate = moment.utc().utcOffset(-5).subtract(30, 'days').format('YYYY-MM-DD')
  const endDate = moment.utc().utcOffset(-5).subtract(1, 'days').format('YYYY-MM-DD')

  return (
    <PostlogLift
      hideHeader
      history={{push: () => {}}}
      match={{
        params: { id, startDate, endDate },
        path: "",
        url: ""
      }}
    />
  )
}

export default PostlogIntegration

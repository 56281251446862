import React from 'react';
import { Form, Table, Button, Icon, Popup, Header } from 'semantic-ui-react'

import { getPlatformAccounts, postPlatformAccount } from '../../../utils/api';
import CacheContext from '../../../utils/CacheContext.js'

export const AccountCell = ({
  item, col,
  backgroundColor = "rgb(234, 153, 153)",
  Component = Table.Cell,
  forceShowButton = false
}) => {
  const account_id = item[col.key]
  const { id, platform } = item;

  if (account_id) return (<Table.Cell>{account_id}</Table.Cell>)

  const [state, setState, Context] = React.useContext(CacheContext);
  const [err, setErr] = React.useState(false)
  const [loadingAccounts, setLoadingAccounts] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const [accounts, setAccounts] = React.useState([])
  const [accountId, setAccountId] = React.useState(account_id)

  React.useEffect(() => {

    getPlatformAccounts(platform,id)
      .then(accounts => {

        const options = accounts.map((a, i) => {
          return {
            text: a.account_name,
            value: a.account_id || a.customer_id,
            content: (
            <Header content={a.account_name} subheader={a.account_id} style={{ fontFamily: "Lato" }} as='h4'/>
            ),
            is_manager: a.is_manager || false,
            currency_code: a.currency_code || "USD",
            key: i}
        }).sort((a, b) => a.text > b.text ? 1 : -1)
        setOptions( options );
        setAccounts( accounts );
        setLoadingAccounts(false)
      });
  }, [account_id])

  const account = accounts.find(row => (row.account_id || row.customer_id) == accountId);
  const reset = () => setState({"integrationFeatures": undefined, "authorizations": undefined});
  const submit = () => {
    setLoading(true)
    postPlatformAccount(platform, id, account).then((resp) => {
      setLoading(false)
      if (resp.error) {
        setErr(resp.error);
      } else reset();
    })
  }

  return <Component style={{backgroundColor}}>
      <Form>
        <Form.Group inline>
          <Form.Dropdown
            search
            selection
            loading={loadingAccounts}
            onChange={(_, {value}) => {
              setErr(false);
              setAccountId(value);
            }}
            placeholder={loadingAccounts ? "Loading accounts..." : "Choose an account"}
            {...{options, value: accountId}}
            disabled={loading}
            upward={false}
            className="account-dropdown"
          />
          {
            (accountId || forceShowButton) && <Form.Field>
              <Button
                as='a' color='green'
                onClick={submit}
                disabled={!accountId || loading || account.is_manager || err}
                loading={loading}
              >
                Save
              </Button>
              {account && account.is_manager && <ManagerAcctWarning account={account} />}
              {account && err && <AcctExistsWarning error={err} />}
              {}
            </Form.Field>
          }
        </Form.Group>
      </Form>
  </Component>
}

const ManagerAcctWarning = () => {
  return <Popup hoverable position='right center' content={
    <>We can't pull reporting with a Google Ads manager account. See our documentation <a href="http://bit.ly/AdWordsManagerAccounts" target="_blank">here</a>.</>
  } trigger={<Icon name='warning sign'/>} />
}

const AcctExistsWarning = ({ error }) => {
  return <Popup hoverable position='right center' content={
    <>{error}</>
  } trigger={<Icon name='warning sign'/>} />
}

export default { AccountCell }

import React, { useMemo } from 'react';

import moment from 'moment';
import {
  Label, AreaChart, Area, LineChart, BarChart, Line, Bar, XAxis, YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Button, Grid } from 'semantic-ui-react';

import { numberFormatter, currencyFormatter, percentFormatter } from '../../../utils/valueFormatter';

const colors = {
  spend: '#1C8ED1',
  revenue: 'rgb(43,160,43)',
  percentOfTotalSpend: 'rgb(255,203,15)', // rgb(255,203,15
  percentOfTotalRevenue: 'rgb(221,4,167)',
  roas: '#ff7300',
};

const formatCurrency = currencyFormatter('usd', 0);
const roundNumber = numberFormatter;

const TooltipRow = ({ color, label, value, final }) => (
  <p style={{
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: !final && 5,
    paddingTop: !!final && 5,
    borderTop: !!final && '1px solid #e6e6e6',
    color: color || '#4d4d4d',
    fontSize: 12,
  }}
  >
    <span style={{ marginRight: 20, fontWeight: 600 }}>{label}</span>
    <span style={{ fontFeatureSettings: '"kern" 1, "tnum" 1' }}>{value}</span>
  </p>
);

const buildCustomTooltip = (isConversionKPI, currentMetric, color) => ({ active, payload, label }) => {
  if (!active || !payload?.length) return null;

  const metrics = payload?.[0]?.payload;

  const {
    day,
    spend,
    revenue,
    roas,
    cpa,
    percentOfTotalRevenue,
    percentOfTotalSpend,
  } = metrics || {};

  const kpiLabel = isConversionKPI ? 'Conversions' : 'Revenue';
  const kpiRatioLabel = isConversionKPI ? 'CPA' : 'ROAS';

  return (
    <div style={{
      padding: 10,
      borderRadius: 4,
      background: '#fff',
      color: '#000',
      boxShadow: '0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15)',
    }}
    >
      <TooltipRow label="Date" value={day} />
      <TooltipRow color={currentMetric === 'spend' ? color : false} label="Spend" value={formatCurrency(spend)} />
      <TooltipRow color={currentMetric === 'revenue' ? color : false} label={kpiLabel} value={isConversionKPI ? roundNumber(revenue) : formatCurrency(revenue)} />
      <TooltipRow color={currentMetric === 'percentSpend' ? color : false} label="% of all Spend" value={percentFormatter(percentOfTotalSpend)} />
      <TooltipRow color={currentMetric === 'percentRevenue' ? color : false} label={`% of marketing ${kpiLabel}`} value={percentFormatter(percentOfTotalRevenue)} />
      <TooltipRow
        final
        color={currentMetric === 'ratio' ? color : false}
        label={kpiRatioLabel}
        value={isConversionKPI ? formatCurrency(cpa) : `${roundNumber(roas)}X`}
      />
    </div>
  );
};

export const SimplePlot = ({
  domain,
  tickFormatter,
  ticks,
  type,
  width,
  height,
  margin,
  data,
  dataKey,
  timeKey = 'day',
  color,
  showAxis = false,
  yAxisLabel,
  FormattedTooltip = null,
}) => {
  const [Chart, Item, props] = useMemo(() => {
    switch (type) {
      case 'bar':
        return [BarChart, Bar, { fill: color }];
      case 'line':
        return [LineChart, Line, { stroke: color, dot: false }];
      case 'area':
        return [AreaChart, Area, { fill: color, stroke: color, dot: false, type: 'monotone' }];
      default:
        return [BarChart, Bar, { fill: color }];
    }
  }, [type]);

  const _margin = margin || { top: 0, right: 10, left: 20, bottom: 0 };
  const _width = width || '100%';
  const _height = height || 120;

  return (
    <ResponsiveContainer width={_width} height={_height}>
      <Chart data={data} margin={_margin}>
        { FormattedTooltip
          && <Tooltip content={<FormattedTooltip />} />}
        <XAxis dataKey={timeKey} hide={!showAxis} ticks={ticks} tickFormatter={tickFormatter} />
        <YAxis stroke="#919191" domain={domain}>
          <Label
            value={yAxisLabel}
            position="insideLeft"
            style={{ textAnchor: 'middle', fill: '#919191', fontSize: '0.9em' }}
            angle={-90}
          />
        </YAxis>
        <Tooltip position={{ y: 0 }} />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Item dataKey={dataKey} {...props} />
      </Chart>
    </ResponsiveContainer>
  );
};

export default function DailyChart({ name, data, isConversionKPI = false }) {
  const firstDate = moment(data[0].day).format('MMM YYYY');
  const lastDate = moment(data[data.length - 1].day).format('MMM YYYY');

  const totalSpend = data.reduce((acc, x) => acc + x.spend, 0);
  const totalRevenue = data.reduce((acc, x) => acc + x.revenue, 0);
  const ROAS = totalRevenue / totalSpend;

  // const margin = { top: 0, right: 10, left: 10, bottom: 0 };
  const formatDate = tick => moment(tick).format('MMM YYYY');
  const ticks = data.map(x => x.day).filter(x => x.slice(-2) === '01');

  const totalSpendCrossChannel = data.reduce((acc, x) => acc + x.totalSpend, 0);
  const totalRevenueCrossChannel = data.reduce((acc, x) => acc + (x.totalRevenue || 0), 0);

  const percentSpend = totalSpendCrossChannel > 0 ? totalSpend / totalSpendCrossChannel : 0;
  const percentRevenue = totalRevenueCrossChannel > 0 ? totalRevenue / totalRevenueCrossChannel : 0;

  const kpiLabelName = isConversionKPI ? 'Conversions' : 'Revenue';
  const kpiRatioLabelName = isConversionKPI ? 'CPA' : 'ROAS';
  const kpiRatioKeyName = isConversionKPI ? 'cpa' : 'roas';

  const downloadCSVFromJSONData = () => {
    const jsonColumns = [
      'day', 'spend', 'revenue', 'percentOfTotalRevenue', 'percentOfTotalSpend',
    ];
    jsonColumns.push(kpiRatioKeyName);
    const csvColumnNames = [
      'Date', 'Spend', kpiLabelName, `% of Marketing ${kpiLabelName}`, '% of all Spend', kpiRatioLabelName,
    ];

    // Convert JSON data to CSV format
    const csvData = data.map(row => jsonColumns.map(col => row[col]).join(','));

    // Add the header row
    csvData.unshift(csvColumnNames.join(','));

    // Create a Blob from the CSV data
    const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv' });

    // Create a link element for downloading
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(csvBlob);
    downloadLink.download = `${name}.csv`;

    // Append the link to the document body and trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (

    <Grid style={{ padding: 10 }}>
      <Grid.Row>
        <Grid.Column width={13}>
          <h3>
            Daily Spend and Predictions (
            {firstDate}
            {' '}
            —
            {' '}
            {lastDate}
            )
          </h3>
        </Grid.Column>
        <Grid.Column />
        <Grid.Column width={2}>
          <Button basic color="blue" onClick={downloadCSVFromJSONData}>Download CSV</Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={13}>
          <div>
            <SimplePlot
              FormattedTooltip={buildCustomTooltip(isConversionKPI, 'spend', colors.spend)}
              type="bar" height={120} data={data}
              dataKey="spend" color={colors.spend} showAxis={false}
              yAxisLabel="Spend"
            />
            <SimplePlot
              FormattedTooltip={buildCustomTooltip(isConversionKPI, 'revenue', colors.revenue)}
              type="bar" height={120} data={data}
              dataKey="revenue" color={colors.revenue} showAxis={false}
              yAxisLabel={kpiLabelName}
            />
            <SimplePlot
              FormattedTooltip={buildCustomTooltip(isConversionKPI, 'percentSpend', colors.percentOfTotalSpend)}
              domain={[0, 1]} type="area" height={60}
              data={data} dataKey="percentOfTotalSpend" color={colors.percentOfTotalSpend}
              showAxis={false} yAxisLabel="% Spend"
            />
            <SimplePlot
              FormattedTooltip={buildCustomTooltip(isConversionKPI, 'percentRevenue', colors.percentOfTotalRevenue)}
              domain={[0, 1]} type="area" height={60}
              data={data} dataKey="percentOfTotalRevenue" color={colors.percentOfTotalRevenue}
              showAxis={false} yAxisLabel={`% ${kpiLabelName}`}
            />
            <SimplePlot
              FormattedTooltip={buildCustomTooltip(isConversionKPI, 'ratio', colors.roas)}
              tickFormatter={formatDate} ticks={ticks} type="line"
              height={80} data={data} dataKey={kpiRatioKeyName}
              color={colors.roas} showAxis={true} yAxisLabel={kpiRatioLabelName}
            />
          </div>
        </Grid.Column>
        <Grid.Column width={3}>
          <div style={{ height: 120, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', color: '#8884d8' }}>
            <div style={{ textAlign: 'center' }}>
              <br />
              <h3>{ currencyFormatter('usd', 0)(totalSpend) }</h3>
              Total Spend
            </div>
          </div>
          <div style={{ height: 120, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', color: colors.revenue }}>
            <div style={{ textAlign: 'center' }}>
              <br />
              <h3>
                { isConversionKPI
                  ? numberFormatter(totalRevenue, 0)
                  : currencyFormatter('usd', 0)(totalRevenue)}
              </h3>
              Predicted
              {' '}
              { kpiLabelName}
            </div>
          </div>
          <div style={{ height: 60, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', color: 'rgb(255,203,15)' }}>
            <div style={{ textAlign: 'center' }}>
              <h3 style={{ paddingTop: 20 }}>{ percentFormatter(percentSpend) }</h3>
              Percent of Spend
            </div>
          </div>
          <div style={{ height: 60, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', color: colors.percentOfTotalRevenue }}>
            <div style={{ textAlign: 'center' }}>
              <h3 style={{ paddingTop: 20 }}>{ percentFormatter(percentRevenue) }</h3>
              Percent of
              {' '}
              { kpiLabelName }
            </div>
          </div>
          <div style={{ height: 80, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', color: '#ff7300' }}>
            <div style={{ textAlign: 'center' }}>
              <h3 style={{ paddingTop: 20 }}>
                { isConversionKPI ? currencyFormatter('usd', 0)(1 / ROAS) : numberFormatter(ROAS, 1) }
                { isConversionKPI ? '' : 'X' }
              </h3>
              { kpiRatioLabelName }
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

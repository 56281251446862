import React, { Component } from 'react';
import {
  Grid,
  Segment,
  Header,
  Form,
  Input,
  Button,
  Container,
  Icon
} from 'semantic-ui-react';
import { GatewayWrap } from '@rockerbox/styleguide';

class PasswordSuccess extends Component {
  render() {
    return (
      <GatewayWrap>
        <Segment raised loading={false}>
          <div className="m-t-20">
            <Icon circular inverted color="green" name="check" size="huge" />
          </div>
          <Header as="h3" color="grey" className="m-b-20">
            Email Sent!
            <Header.Subheader>
              Check your inbox for an email with a link to reset your password.
            </Header.Subheader>
          </Header>
          <div className="m-t-40">
            <Button as="a" href="/login" primary fluid size="big">
              Back to Sign in
            </Button>
          </div>
        </Segment>
        <div className="white m-t-30">
          Need help?&nbsp;
          <a href="mailto:support@rockerbox.com" className="white underlined">
            Contact us
          </a>
        </div>
      </GatewayWrap>
    );
  }
}

export default PasswordSuccess;

import React from "react";
import { Step } from "semantic-ui-react";
import Setup from "./Setup";
import Status from "./Status";
import Impact from "../Impact";
import OttDataSource from "./OttDataSource";
import { getSegments } from "../../utils/api";
import CacheContext from "../../utils/CacheContext.js";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

export const StepsHeader = ({ steps, activeStep, setActiveStep }) => (
  <Step.Group widths={steps.length} size="mini">
    {steps.map((value) => {
      return (
        <Step
          active={activeStep == value}
          onClick={() => setActiveStep(value)}
          key={value}>
          <Step.Content>
            <Step.Title>{value}</Step.Title>
          </Step.Content>
        </Step>
      );
    })}
  </Step.Group>
);

const OTTView = (props) => {
  const { username } = props;
  const steps = ["Setup", "Status", "Data Source", "Impact"];
  const [activeStep, setActiveStep] = React.useState(steps[0]);
  const [state, setState, Context] = React.useContext(CacheContext);
  const segments = Context.getCache("segments", getSegments) || [];
  const segmentId =
    segments.length > 0
      ? segments.filter((item) => item.featured === 1)[0]["filter_id"]
      : null;

  const stepsProps = {
    steps,
    activeStep,
    setActiveStep,
  };

  const stepProps = {
    segmentId,
    username,
  };

  return (
    <React.Fragment>
      <StepsHeader {...stepsProps} />
      {activeStep == "Setup" && <Setup />}
      {activeStep == "Status" && <Status {...stepProps} />}
      {activeStep == "Data Source" && <OttDataSource />}
      {activeStep == "Impact" && (
        <Impact
          title="OTT"
          datasets={["ott_difference"]}
          getSegmentId={() => Promise.resolve(segmentId)}
        />
      )}
    </React.Fragment>
  );
};

function mapStateToProps(state, props) {
  const {
    username,
    user: { pixel_source_name },
  } = state.app;

  return { username, pixel_source_name };
}

export default connect(mapStateToProps)(withRouter(OTTView));

import { useState, useEffect, useMemo } from 'react';

import { InviteUsersRows, InviteStatus, RolesDropdown } from './parts';
import { getAccountUsers } from '../../api/account';
import { updateUserRBAC } from '../../api/rbacApi';
import { updateEmailSuppressions } from '../../utils/rbac';

export const useUsersData = () => {
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [users, setUsers] = useState([]);

  const userDataRefresh = () => getAccountUsers()
    .then(data => {
      setUsers(data);
      setLoadingUsers(false);
    });

  const handleManageUpdate = () => {
    setLoadingUsers(true);
    userDataRefresh();
  };

  useEffect(() => {
    userDataRefresh();
  }, []);

  return {
    loadingUsers,
    users,
    handleManageUpdate,
  };
};

export const useTableColumns = ({
  user: currentUser, handleManageUpdate,
  roleOptions, advertiserExistsRBAC, userToRoleMap, setUserToRoleMap,
  authorizedToAssignRoles,
}) => {
  const { username } = currentUser;
  const manage_params = {
    current_username: username,
    handleManageUpdate,
  };

  const defaultCols = [
    { display: 'Email', key: 'email' },
    { display: 'Status', key: 'has_invite', as: InviteStatus },
    { display: '', key: manage_params, as: InviteUsersRows, advertiserExistsRBAC: false, authorizedToAssignRoles },
  ];

  const handleRoleChange = (id, email, role) => {
    updateUserRBAC(id, role)
      .then(() => {
        const updatedRoles = {
          ...userToRoleMap,
          [id]: role,
        };
        updateEmailSuppressions(id, email);
        setUserToRoleMap(updatedRoles);
      })
      .catch(x => console.log(x));
  };

  const cols = useMemo(() => {
    if (!advertiserExistsRBAC) return defaultCols;
    const roleCol = {
      display: 'Role',
      key: 'role',
      as: RolesDropdown,
      roles: roleOptions,
      onChange: handleRoleChange,
      authorizedToAssignRoles,
    };
    const updatedCols = defaultCols.map(col => ({
      ...col,
      advertiserExistsRBAC,
    }));
    updatedCols.splice(1, 0, roleCol);
    return updatedCols;
  }, [defaultCols, advertiserExistsRBAC, roleOptions]);

  return {
    cols,
  };
};

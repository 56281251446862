import React, { useMemo, useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { TrashIcon } from '@heroicons/react/outline';
// eslint-disable-next-line import/no-extraneous-dependencies
import { PuzzleIcon } from '@heroicons/react/solid';
import _ from 'lodash';
import moment from 'moment';
import { Card, Table, Icon, Popup, Button, Image, Header } from 'semantic-ui-react';

import PlatformCard from './PlatformCard';
import PlatformCardV2 from './PlatformCardV2';
import { getDatasetStatus, getSpend } from '../../api/dataReporting';
import { useTrackingParamAlerts } from '../../hooks/trackingParamAlerts';
import { yesterday, monthAgo } from '../../utils/time';
import { generateDateRange } from '../AttributionReport/helpers';

export const PanelsGroup = ({ data, platformsDist, integrations, colorMap, apiSpend, batchFileStatus, syntheticData, conversionData, isTestAccount, isConversionPanel }) => {
  const platforms = useMemo(() => {
    if (!integrations) return;
    if (isConversionPanel) return ['shopify_conversions', 'webhook_conversions', 'pixel_conversions'];
    const platformKeys = integrations.map(x => x.platform_key);
    const uniquePlatformKeys = [...new Set(platformKeys)];
    return uniquePlatformKeys;
  }, [integrations, data]);

  const trackingParamAlerts = useTrackingParamAlerts();
  // TODO: remove this and set it statically to 3 once we remove gating
  const itemsPerRow = isTestAccount ? 3 : 4;
  return (
    <Card.Group itemsPerRow={itemsPerRow} style={{ padding: '1em 0 2em' }}>
      {/* TODO: remove this duplication once the feature is full rolled out */}
      {isTestAccount && platforms.map(platform => (
        <PlatformCardV2 {...{
          key: platform, platform, platformsDist, integrations, colorMap, apiSpend, batchFileStatus,
          syntheticData, conversionData, isConversionPanel, trackingParamAlerts,
        }}
        />
      ))}
      {!isTestAccount && platforms.map(platform => (
        <PlatformCard {...{ key: platform, platform, data, platformsDist, integrations, colorMap, isConversionPanel }} />
      ))}
    </Card.Group>
  );
};

export const StatusCell = handleDelete => ({ item, col }) => {
  const [open, setOpen] = useState(false);
  const last_sync = item[col.key];
  const { auth_status } = item;

  // hack for the status cell for batch integrations
  if (auth_status === null) {
    return (
      <Table.Cell>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '.5em' }}>
              <Icon name="check circle" color="green" />
            </div>
            <span>
              Using Batch Files
            </span>
          </div>
        </div>
      </Table.Cell>
    );
  }

  return (
    <Table.Cell>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '.5em' }}>
            { !auth_status
              ? <Icon name="warning sign" color="orange" />
              : <Icon name="check circle" color="green" />}
          </div>
          <span>
            { !auth_status
              ? (
                <span>
                  Unable to Connect
                  <Button
                    primary
                    content="Reconnect"
                    size="mini"
                    onClick={() => { window.location = `/auth/${item.platform_key}?account_id=${item.account_id}`; }}
                    style={{ padding: '5px 5px 4px 5px', marginLeft: '1em', position: 'relative', top: -1 }}
                  />
                </span>
              )
              : !!last_sync
                ? `Last Synced ${moment.utc(last_sync).fromNow()}`
                : 'Connected'}
          </span>
        </div>

        <Popup
          on="click"
          size="small"
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          position="top right"
          trigger={(
            <TrashIcon
              style={{
                height: 16,
                marginRight: 0,
                color: '#E76D8E',
                cursor: 'pointer',
              }}
              className="hover-opacity-75"
            />
          )}
          content={(
            <>
              <Header
                as="h4"
                content={`Are you sure you want to delete this connection to ${item.platform} account ${item.account_name}?`}
                subheader="This action cannot be undone and may disrupt scheduled reporting or other data syncs."
              />
              <Button.Group fluid>
                <Button
                  content="Nevermind"
                  onClick={() => setOpen(false)}
                />
                <Button.Or />
                <Button
                  negative
                  content="Confirm Delete"
                  onClick={() => handleDelete(item.platform_key, item.id)}
                />
              </Button.Group>
            </>
)}
        />

      </div>
    </Table.Cell>
  );
};

export const IconCell = integrations => ({ item, col }) => {
  const integration = integrations.find(c => c.platform === item.platform);

  return (
    <Table.Cell className="account-cell">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {!!integration?.icon_path
          ? <Image src={integration.icon_path} style={{ height: 20, marginRight: '1em' }} />
          : <PuzzleIcon style={{ height: 20, marginRight: '1em', marginLeft: 0, color: '#bbb' }} />}
        <div className="account-name">
          {item[col.key]}
        </div>
      </div>
    </Table.Cell>
  );
};

export const CurrencyCell = ({ item, col }) => {
  const amount = item[col.key];
  const dollars = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: col.currency || 'USD',
  });

  return (
    <Table.Cell collapsing style={{ fontFeatureSettings: '"kern" 1, "tnum" 1', textAlign: 'right' }}>
      { amount ? dollars.format(amount) : ''}
    </Table.Cell>
  );
};

export const transformBatchFileStatus = mockData => {
  const result = {
    files_received: [],
    files_processed: [],
  };

  const { files_received, files_processed } = mockData;
  const dateRange = generateDateRange(monthAgo, yesterday);

  dateRange.forEach(dateValue => {
    // For files_received
    const date = `${dateValue} 00:00:00`;

    if (files_received[date]) {
      const filesOnDate = files_received[date].map(fileId => ({
        fileId,
        status: 'received',
      }));

      result.files_received.push({
        date: dateValue,
        status: filesOnDate.length > 0 ? 'success' : 'no data',
        number: filesOnDate.length,
        files: filesOnDate,
      });
    } else {
      // If no files received on a given date
      result.files_received.push({
        date: dateValue,
        status: 'no data',
        number: 0,
        files: [],
      });
    }

    // For files_processed
    if (files_processed[date]) {
      const filesOnDate = Object.entries(files_processed[date]).map(([fileId, status]) => ({
        fileId,
        status,
      }));

      const numberEmpty = filesOnDate.filter(({ status }) => status === 'empty').length;
      const numberSuccess = filesOnDate.filter(({ status }) => status === 'success').length + numberEmpty;
      const numberFailure = filesOnDate.filter(({ status }) => status === 'failure').length;

      result.files_processed.push({
        date: dateValue,
        status: filesOnDate.length === 0 ? 'no data'
          : numberSuccess > 0 && numberFailure === 0 && numberEmpty === 0 ? 'success'
            : numberSuccess === 0 && numberFailure > 0 ? 'failure'
              : numberEmpty > 0 ? 'empty'
                : 'mixed',
        numberSuccess,
        numberFailure,
        files: filesOnDate,
      });
    } else {
      result.files_processed.push({
        date: dateValue,
        status: 'no data',
        numberSuccess: 0,
        numberFailure: 0,
        files: [],
      });
    }
  });

  return result;
};

const mapActionIdToName = segments => segments.reduce((acc, item) => {
  if (item.action_id && item.action_name) {
    return { ...acc, [item.action_id]: item.action_name };
  }
  return acc;
}, {});

export const transformConversionStatus = (conversionData, segments, conversionType) => {
/**
 * 1. Parse the input data to filter and process only pixel_conversions.
 * 2. Generate a date range for the given period.
 * 3. For each date in the range, compute the number of statuses (success, failure, empty).
 * 4. Determine the overall status for each day (success, failure, mixed, no data).
 * 5. Accumulate statuses by conversion_segment_id for each day.
 */
  const result = [];
  const dateRange = generateDateRange(monthAgo, yesterday);
  const filteredData = conversionData.filter(item => item.type === conversionType);
  const idsToNames = mapActionIdToName(segments);

  dateRange.forEach(date => {
    const dayResult = {
      date,
      numberSuccess: 0,
      numberFailure: 0,
      numberEmpty: 0,
      status: '',
      statusesBySegment: {},
    };

    filteredData.forEach(segment => {
      const statusesForDate = segment.statuses.filter(status => status.date.startsWith(date));
      statusesForDate.forEach(status => {
        if (status.status === 'success') dayResult.numberSuccess += 1;
        if (status.status === 'empty') dayResult.numberSuccess += 1;
        if (status.status === 'in_progress') dayResult.numberSuccess += 1;
        if (status.status === 'failure') dayResult.numberFailure += 1;
        dayResult.statusesBySegment[idsToNames[segment.conversion_segment_id]] = status.status;
      });
    });

    if (dayResult.numberSuccess > 0 && dayResult.numberFailure === 0) {
      dayResult.status = 'success';
    } else if (dayResult.numberFailure > 0 && dayResult.numberSuccess === 0) {
      dayResult.status = 'failure';
    } else if (dayResult.numberSuccess === 0 && dayResult.numberFailure === 0) {
      dayResult.status = 'no data';
    } else {
      dayResult.status = 'mixed';
    }
    result.push(dayResult);
  });

  return result;
};

export const isMissingData = apiResponse => {
  if (!apiResponse) return false;
  const hasFailures = apiResponse.failures && apiResponse.failures.length > 0;
  return hasFailures;
};

export const determineModalStatus = (spendStatus, conversionStatus, connectionStatus) => {
  if (spendStatus === undefined || conversionStatus === undefined || connectionStatus === undefined) return false;
  return spendStatus || conversionStatus || connectionStatus;
};

export const transformTableData = data => {
/**
 * Transforms account data into a structure grouped by platform, suitable for display in an expandable grid.
 * This function aggregates account data into platform-specific groups, accumulating details like account names,
 * IDs, auth statuses, and other relevant pieces for each platform.
 *
 * eg:
 *
 *  Input:
 * const accountsData = [
 *   { platform: 'Twitter', account_name: 'user1', account_id: '001', auth_status: 'verified', id: 'a1' },
 *   { platform: 'Twitter', account_name: 'user2', account_id: '002', auth_status: 'unverified', id: 'a2' },
 *   { platform: 'Facebook', account_name: 'userA', account_id: '101', auth_status: 'verified', id: 'b1' }
 * ];
 *
 *  Output:
 * [
 *   {
 *     platform: 'Twitter',
 *     account_names: ['user1', 'user2'],
 *     account_ids: ['001', '002'],
 *     auth_statuses: ['verified', 'unverified'],
 *     ids: ['a1', 'a2'],
 *     number_of_accounts: 2
 *   },
 *   {
 *     platform: 'Facebook',
 *     account_names: ['userA'],
 *     account_ids: ['101'],
 *     auth_statuses: ['verified'],
 *     ids: ['b1'],
 *     number_of_accounts: 1
 *   }
 * ]
 *
 * @param {Array} data - The array of account data objects to transform. Each object should include properties
 *                       like platform, account_name, account_id, auth_status, and id.
 * @returns {Array} An array of objects where each object represents a platform group with aggregated account
 *                  information and additional properties such as account_names, account_ids, auth_statuses,
 *                  ids, and number_of_accounts.
 */
  const groupedByPlatform = data.reduce((acc, item) => {
    const { platform, account_name, account_id, auth_status, id, platform_key, batch_platform } = item;
    const platformGroup = acc[platform] || {
      platform,
      platform_key,
      batch_platform,
      account_names: [],
      account_ids: [],
      auth_statuses: [],
      ids: [],
      number_of_accounts: 0,
    };

    platformGroup.account_names.push(account_name);
    platformGroup.account_ids.push(account_id);
    platformGroup.auth_statuses.push(auth_status);
    platformGroup.ids.push(id);
    platformGroup.number_of_accounts += 1;
    // eslint-disable-next-line no-param-reassign
    acc[platform] = platformGroup;

    return acc;
  }, {});

  return Object.values(groupedByPlatform);
};

export const getMostRecentConversionTimestamp = (platform, syncTimes) => {
  const relevantEntries = syncTimes.filter(entry => entry.type === platform);
  const maxTimestamp = relevantEntries.reduce((acc, entry) => {
    const maxInEntry = entry.statuses.reduce((innerAcc, status) => (status.sync_timestamp > innerAcc ? status.sync_timestamp : innerAcc), '');
    return maxInEntry > acc ? maxInEntry : acc;
  }, '');

  return maxTimestamp || null;
};

export const getMostRecentSyncTimestamp = (platform, syncTimes) => {
  if (['shopify_conversions', 'webhook_conversions', 'pixel_conversions'].includes(platform)) {
    return getMostRecentConversionTimestamp(platform, syncTimes);
  }
  const platformSyncTimes = syncTimes.find(p => p.platform === platform);
  if (!platformSyncTimes) {
    return null;
  }

  const successfulSyncs = platformSyncTimes.statuses
    .sort((a, b) => new Date(b.sync_timestamp) - new Date(a.sync_timestamp));

  return successfulSyncs.length > 0 ? successfulSyncs[0].sync_timestamp : null;
};

export const getMostRecentSuccessfulConversionDate = (platform, syncTimes) => {
  const relevantEntries = syncTimes.find(entry => entry.type === platform);
  if (!relevantEntries) {
    return null;
  }
  const successfulSpends = relevantEntries.statuses
    .filter(status => status.status === 'success')
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  return successfulSpends.length > 0
    ? `${successfulSpends[0].date.split(' ')[0]} 23:59:59`
    : null;
};

export const getMostRecentSuccessfulDate = (platform, syncTimes, conversionData) => {
  if (['shopify_conversions', 'webhook_conversions', 'pixel_conversions'].includes(platform)) {
    return getMostRecentSuccessfulConversionDate(platform, conversionData);
  }
  const platformSyncTimes = syncTimes.find(p => p.platform === platform);
  if (!platformSyncTimes) {
    return null;
  }

  const successfulSpends = platformSyncTimes.statuses
    .filter(status => ['success', 'empty'].includes(status.status))
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  return successfulSpends.length > 0
    ? `${successfulSpends[0].date.split(' ')[0]} 23:59:59`
    : null;
};

export const getRecentDataEntries = (data, fillInMissingDays) => {
/**
 * Processes and returns the most recent data entries.
 * If there are more than 30 entries, it slices the last 30 entries.
 * If there are less, it attempts to fill in the missing days.
 * If there are exactly 30, it returns them as is.
 *
 * @param {Array} data - The array of data entries to process.
 * @param {Function} fillInMissingDays - A function that fills in missing days for the data array.
 * @returns {Array} The processed array of data entries.
 */
  if (!data || data.length === 0) return [];

  const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));

  if (sortedData.length > 30) {
    return sortedData.slice(-30);
  } if (sortedData.length < 30) {
    return fillInMissingDays(sortedData);
  }
  return sortedData;
};

const transform = input => {
  const platformData = {};

  input.forEach(item => {
    const {
      id,
      updated_at,
      platform,
      date,
      spend_usd,
    } = item;

    if (!platformData[platform]) {
      platformData[platform] = {
        identifier: id,
        report: `platform_performance_${platform}`,
        platform,
        metrics: [],
      };
    }

    const lastSyncDate = updated_at ? new Date(updated_at).toISOString().replace(/T/, ' ').replace(/\..+/, '') : null;

    platformData[platform].metrics.push({
      date,
      summary: {
        impressions: null,
        clicks: null,
        spend: spend_usd,
      },
      last_sync: lastSyncDate,
    });
  });

  return Object.values(platformData);
};

const deduplicateByReport = arr => {
  const seenReports = new Set();
  const deduplicatedArr = [];
  arr.forEach(subArr => {
    if (subArr.length === 0) return;
    const item = subArr[0];
    if (!seenReports.has(item.report)) {
      seenReports.add(item.report);
      deduplicatedArr.push(subArr);
    }
  });
  return deduplicatedArr;
};

export const fetchData = async (featuredSegment, startDate) => {
  const spendPromise = getSpend(startDate, yesterday);
  const spendStatusPromise = getDatasetStatus('spend', startDate, yesterday);
  const conversionSyncPromise = featuredSegment === '0' ? null : getDatasetStatus('conversions', startDate, yesterday, featuredSegment);
  const syntheticStatusPromise = getDatasetStatus('synthetic_events', startDate, yesterday);
  const batchSpendStatusPromise = getDatasetStatus('spend_files', startDate, yesterday);

  return Promise.all([spendStatusPromise, conversionSyncPromise, syntheticStatusPromise, spendPromise, batchSpendStatusPromise]);
};

const processPlatformData = (platformData, { platformsDist, dataByDate, tableData }) => {
  platformData.forEach(accountData => {
    const { identifier, metrics, platform, values, error } = accountData;
    if (error) {
      // eslint-disable-next-line no-param-reassign
      platformsDist[platform] = 'error';
      return;
    }
    const dailyMetrics = metrics || values;
    const platformKey = platform || dailyMetrics?.[0]?.platform;

    const accountObj = tableData.find(obj => obj.account_id === identifier) || {};
    const totalAccountSpend = dailyMetrics.reduce((a, b) => a + (b?.summary?.spend || b.spend), 0);

    dailyMetrics.forEach(obj => {
      const daySpend = obj?.summary?.spend || obj.spend || 0;
      // eslint-disable-next-line no-param-reassign
      platformsDist[platformKey] = (daySpend || 0) + (platformsDist[platformKey] || 0);

      const selectedObj = dataByDate[obj.date];
      if (!selectedObj) {
        // eslint-disable-next-line no-param-reassign
        dataByDate[obj.date] = { date: obj.date, [platformKey]: daySpend };
        return;
      }
      selectedObj[platformKey] = daySpend + (selectedObj[platformKey] || 0);
    });

    Object.assign(accountObj, { spend: Number(totalAccountSpend.toFixed(2)) });
  });
};

export const processData = async (values, { connectedAdAccounts, authedAdPlatforms, setters }) => {
  const [spendStatusResponse, conversionSyncResponse, syntheticSyncResponse, spendResponse, batchSpendStatusResponse] = values;
  const {
    setSpendSyncStatus, setConversionSyncStatus, setSyntheticSyncStatus, setBatchSpendStatus, // data statuses
    setSyncTimeStatus, setConversionsSyncTimeStatus, // sync times
    setPlatformsSummary, setChartData, setAccountSummary, setLoading,
  } = setters;

  // set status data
  setSpendSyncStatus(spendStatusResponse.response);
  setConversionSyncStatus(conversionSyncResponse?.response);
  setSyntheticSyncStatus(syntheticSyncResponse.response);
  setBatchSpendStatus(batchSpendStatusResponse.response);

  // process platform data
  const spendData = transform(spendResponse);
  const tableData = _.cloneDeep(connectedAdAccounts);
  const dataByDate = {};
  const platformsDist = {};

  const summaries = deduplicateByReport(
    connectedAdAccounts.map(account => spendData.filter(item => item.platform === account.platform_key)).filter(s => !!s),
  );

  try {
    const data = await Promise.all(summaries.filter(summary => summary.length > 0));
    data.forEach(platformData => {
      processPlatformData(platformData, { platformsDist, dataByDate, tableData });
    });

    Object.keys(dataByDate).forEach(date => {
      authedAdPlatforms.forEach(platform => {
        if (!!dataByDate[date][platform]) return;
        dataByDate[date][platform] = 0;
      });
    });
    setPlatformsSummary(platformsDist);
    setChartData(Object.values(dataByDate));
    setAccountSummary(tableData);
  } catch (err) {
    console.log('Error:', err.message);
  } finally {
    setLoading(false);
  }
};

export const fillInMissingDays = sortedData => {
  const dateRange = generateDateRange(monthAgo, yesterday);
  return dateRange.map(dateString => {
    const existingData = sortedData.find(d => {
      const dataDate = d.date.split(' ')[0]; // Extract just the date part from 'YYYY-MM-DD HH:MM:SS'
      return dataDate === dateString;
    });
    const formattedDate = `${dateString} 00:00:00`;
    return existingData || { date: formattedDate, status: 'no_data' };
  });
};

export const getConversionIntegrations = authorizations => {
  const shopifyAuth = authorizations.find(item => item.platform === 'shopify');
  const activeShopifyAuth = shopifyAuth?.authorizations && shopifyAuth.authorizations.length > 0 && shopifyAuth.authorizations[0].status === 1;
  return [{
    platform_key: 'shopify_conversions', platform: 'Shopify',
    logo_path: 'https://images.rockerbox.com/integrations/integrations-logo-shopify.png',
    icon_path: 'https://images.rockerbox.com/integrations/integrations-icon-shopify.svg',
    auth_status: activeShopifyAuth,
  },
  {
    platform_key: 'pixel_conversions', platform: 'Onsite Pixels',
    logo_path: 'https://images.rockerbox.com/integrations/integrations-logo-rockerbox.png',
    icon_path: 'https://images.rockerbox.com/logo-purple-icon.svg',
  },
  {
    platform_key: 'webhook_conversions', platform: 'Webhooks',
    // TODO: Update image to use actual webhook image once we have it in images.rockerbox.com
    logo_path: 'https://images.rockerbox.com/integrations/integrations-logo-placeholder.png',
    icon_path: 'https://images.rockerbox.com/integrations/integrations-icon-placeholder.svg',
  }];
};

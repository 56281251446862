import React from 'react';

import { IndexGridNew } from '@rockerbox/styleguide';
import toast from 'react-hot-toast';
import { Table } from 'semantic-ui-react';

import { forgotPassword, deleteUser } from '../../api/account';
import { deleteUserRBAC } from '../../api/rbacApi';
import { RockerboxStyledDropdown } from '../StyledDropdown';

export const RolesDropdown = ({ item, col }) => (
  <Table.Cell>
    <RockerboxStyledDropdown
      selection
      options={col.roles}
      value={item[col.key]}
      onChange={(e, { value }) => col.onChange(item.id, item.username, value)}
      disabled={!col.authorizedToAssignRoles}
    />
  </Table.Cell>
);

export const InviteStatus = ({ item, col }) => <Table.Cell>{item[col.key] === 0 ? 'Active' : 'Invite Sent'}</Table.Cell>;

export const InviteUsersRows = ({ item, col }) => {
  const currentuser = col.key.current_username;
  const shouldShow = item.email !== currentuser;
  const { hasRoles, authorizedToAssignRoles } = col;

  const handleDelete = () => {
    const { username, id } = item;
    const deleteUserPromise = deleteUser({ username })
      .then(() => {
        if (hasRoles) {
          deleteUserRBAC(id).then(() => {
            col.key.handleManageUpdate();
          });
        } else {
          col.key.handleManageUpdate();
        }
      });
    toast.promise(deleteUserPromise, {
      loading: 'Removing',
      success: `${username} removed from account`,
      error: `Error removing ${username} from account`,
    });
  };

  const handleResend = () => {
    const { email } = item;
    const resendInvitePromise = forgotPassword({ email });
    toast.promise(resendInvitePromise, {
      loading: 'Sending',
      success: `Resent invite to ${email}`,
      error: `Error resending invite to ${email}`,
    });
  };

  return (
    <Table.Cell textAlign="right" style={{ position: 'relative', height: 47 }}>
      { shouldShow ? <IndexGridNew.SendButton item={item} send={handleResend} popup="Resend Invite" disabled={!authorizedToAssignRoles} /> : ''}
      { shouldShow ? <IndexGridNew.OverlayDeleteBtn onClick={handleDelete} popup="Remove User" disabled={!authorizedToAssignRoles} /> : ''}
    </Table.Cell>
  );
};

export default InviteUsersRows;

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { SplitLayout, ColumnLayout, ContentCard, StatCard, IndexGrid } from '@rockerbox/styleguide';
import { Grid, Header, Menu, Icon, Dropdown } from 'semantic-ui-react';
import * as d3 from 'rockerbox_d3_legacy_clone';
import plot from './plot';
import scatter from './scatter';

export const ConfusionMatrix = ({confusion_data}) => (
  <Grid columns="equal">
    <Grid.Row style={{fontWeight:"bold"}}>
      <Grid.Column> </Grid.Column> <Grid.Column> Predicted No </Grid.Column>
      <Grid.Column> Predicted Yes </Grid.Column>
      <Grid.Column> Total </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column style={{fontWeight:"bold"}}> Actual No </Grid.Column>
      <Grid.Column style={{color:"red",fontWeight:"bold"}}> { confusion_data['true negative'] } </Grid.Column>
      <Grid.Column> { confusion_data['false positive'] } </Grid.Column>
      <Grid.Column> { confusion_data['true negative'] + confusion_data['false positive']} </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column style={{fontWeight:"bold"}}> Actual Yes </Grid.Column>
      <Grid.Column> { confusion_data['false negative'] } </Grid.Column>
      <Grid.Column style={{color:"steelblue",fontWeight:"900"}}> { confusion_data['true positive'] } </Grid.Column>
      <Grid.Column> { confusion_data['false negative'] + confusion_data['true positive']} </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column style={{fontWeight:"bold"}}> Total </Grid.Column>
      <Grid.Column> { confusion_data['true negative'] + confusion_data['false negative']} </Grid.Column>
      <Grid.Column> { confusion_data['false positive'] + confusion_data['true positive']} </Grid.Column>
      <Grid.Column> { confusion_data['true negative'] + confusion_data['false negative'] + confusion_data['false positive'] + confusion_data['true positive'] } </Grid.Column>
    </Grid.Row>
  </Grid>
)

class ModelSummary extends Component {
  state = {
    threshold: .50,
    width: false
  }
  renderD3() {
    const { title, data } = this.props;
    const { threshold, width } = this.state;
    const target = ReactDOM.findDOMNode(this.refs.plot);
    const chart = plot(width)
      .title(title)
      .threshold(threshold);

    const plotData = data.map(x => { 
      const { predicted, actual, threshold } = x;
      return { predicted, actual, threshold }
    })

    d3
      .select(target)
      .selectAll('svg')
      .remove();

    d3
      .select(target)
      .datum(plotData)
      .call(chart);

    const buckets = d3.range(0,1.02,.02)
    const bucketedData = d3.nest()
      .key(x => buckets.filter(y => y > x.threshold)[0])
      .rollup()
      .entries(data)

    const positiveDist = bucketedData.map(x => {
      const truePositive = x.values.slice(-1)[0]['true positive'];
      const falsePositive = x.values.slice(-1)[0]['false positive'];
      const total = truePositive + falsePositive;
      const key = x.key;
      const values = x.values;
      const value = truePositive;
      return { key, truePositive, falsePositive, total, values, value }
    })

    const target2 = ReactDOM.findDOMNode(this.refs.conv);
    const chart2 = scatter(width)
      .title("% Classified Positive")
      .threshold(threshold)

    d3
      .select(target2)
      .selectAll('svg')
      .remove();

    d3
      .select(target2)
      .datum(positiveDist)
      .call(chart2);


    const negativeDist = bucketedData.map(x => {
      const trueNegative = x.values.slice(-1)[0]['true negative'];
      const falseNegative = x.values.slice(-1)[0]['false negative'];
      const total = trueNegative + falseNegative;
      const key = x.key;
      const values = x.values;
      const value = trueNegative;
      return { key, trueNegative, falseNegative, total, values, value }
    })

    const target3 = ReactDOM.findDOMNode(this.refs.nonconv);
    const chart3 = scatter(width)
      .title("% Classified Negative")
      .threshold(threshold)
      .color("red")
      .evalThreshold(function(x,y) { return x < y })

    d3
      .select(target3)
      .selectAll('svg')
      .remove();

    d3
      .select(target3)
      .datum(negativeDist)
      .call(chart3);

  }

  getDimensions() {
    const target = ReactDOM.findDOMNode(this.refs.plot);
    if (target) {
      const parent = target.parentNode
      const x = (this.state.width && (this.state.width === parent.offsetWidth)) ? false : this.setState({"width": parent.offsetWidth});
    }
  }

  componentDidMount() {
    this.getDimensions();
    this.renderD3();
  }
  componentDidUpdate(prevProps) {

    const { threshold } = this.props;
    if ( threshold && (threshold != prevProps.threshold)) {
      this._setThreshold( threshold )
    }
    this.renderD3();
  }

  setThreshold = (value) => {
    const threshold = value.currentTarget.value/100
    this._setThreshold(threshold)
  }

  _setThreshold = (threshold) => {
    this.setState({ threshold })
  }

  render() {

    const { data } = this.props;
    const { onSetThreshold } = this.props;
    const { threshold } = this.state;

    const confusion_data = data ? data
      .sort((p,c) => p.threshold - c.threshold)
      .filter(x => x.threshold > threshold)[0] : {}


    return (
      <ContentCard title="Model Summary">
        <SplitLayout
          leftWidth={8}
          rightWidth={8}
          leftContent={
            <React.Fragment>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <b>Probability Threshold: </b> { threshold.toPrecision(2) } <br />
                    <input type="range" value={threshold*100} orientation="vertical" min={0} max={100} step={0.01} onChange={onSetThreshold || this.setThreshold}  />
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              {confusion_data && <ConfusionMatrix confusion_data={confusion_data} /> }
              <br />
              <div ref="plot" />
            </React.Fragment>
          }
          rightContent={
            <div>
              <div ref="conv" /> 
              <div ref="nonconv" />
              
            </div>
          }
        />
      </ContentCard> 
    );
  }
}

export default ModelSummary;

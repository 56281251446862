import React, { useContext, useMemo, useEffect, useState } from 'react';
import { Button, Table, Popup, Header } from 'semantic-ui-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DownloadIcon } from '@heroicons/react/outline';
import json2csv from 'json2csv';

import { IndexGridNew } from '@rockerbox/styleguide';
import { CacheContext } from '../../../utils/CacheContext';
import { getSegments } from '../../api/attribution';
import { getCreativeData, getCreativeDataByTiers } from '../../api/hindsightChecks';
import { TreeTableLoader } from '../../components/loaders';
import { weekAgo, yesterday } from '../../utils/time';
import { track } from '../../../utils/tracking';

const PLATFORM_TIER = 'tier_one';
const MACRO_TIERS = ['tier_two', 'tier_three', 'tier_four', 'tier_five'];
const MACRO_DELIMITERS = /{{|}}|\$!{|\${|#{|{|}|\[\[|\]\]|\[|\]|\[%|%\]|%%|%|\$|!|:|__/g;

const PixelCountTable = ({ tier_one, tier_two, tier_three, tier_four, tier_five }) => {
  const [pixelCounts, setPixelCounts] = useState(null);

  useEffect(() => {
    if (!tier_one) return;
    getCreativeDataByTiers(tier_one, tier_two, tier_three, tier_four, tier_five, weekAgo, yesterday)
      .then(res => {
        const data = res.map(d => ({
          ...d,
          date: d?.timestamp.replace('00:00:00', '').trim(),
        }));
        setPixelCounts(data);
      });
  }, [tier_one, tier_two, tier_three, tier_four, tier_five]);

  if (!pixelCounts) return <TreeTableLoader />;

  return (
    <IndexGridNew
      celled
      data={pixelCounts}
      cols={[
        { display: 'Date (UTC)', key: 'date' },
        { display: 'Count Unique Pixel Fires', key: 'uid', tooltip: 'This value is deduplicated per unique user, so the same pixel firing multiple times for one person will count as 1' },
      ]}
      emptyIcon="info"
      fallBackMsg="No pixel fires found within the past 7 days"
    />
  );
};

const PixelCountCell = ({ item }) => {
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <Table.Cell collapsing>
      <Popup
        trigger={(
          <Button
            basic
            size="tiny"
            icon="chart bar"
            content="View Counts"
          />
        )}
        on="click"
        open={popupOpen}
        onOpen={() => setPopupOpen(true)}
        onClose={() => setPopupOpen(false)}
        position="top right"
        wide="very"
        content={!popupOpen ? null : (
          <>
            <Header as="h4" content="Past 7 Day Pixel Fires" />
            <pre>
              {`tier_one: ${item.tier_one}`}
              {!!item?.tier_two && `\ntier_two: ${item.tier_two}`}
              {!!item?.tier_three && `\ntier_three: ${item.tier_three}`}
              {!!item?.tier_four && `\ntier_four: ${item.tier_four}`}
              {!!item?.tier_five && `\ntier_five: ${item.tier_five}`}
            </pre>
            <div style={{ width: 524, height: 296 }}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <PixelCountTable {...item} />
            </div>
          </>
        )}
      />
    </Table.Cell>
  );
};

const ImpressionPixelData = ({ platform, impressionTracking }) => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState, Context] = useContext(CacheContext);
  const segments = Context.getCache('segments', getSegments) || null;

  const [impPixelData, setImpPixelData] = useState(null);

  // deconstruct pixel code to get meaning of query parameters
  const [platformValues, macroTierNames] = useMemo(() => {
    if (!impressionTracking?.length) return [null, null];
    const platformValuesResponse = impressionTracking.reduce((acc, pixel) => {
      const url = new URL(pixel.params);
      const qs = url.searchParams;
      const pValue = qs.get(PLATFORM_TIER);
      if (pValue) acc.push(pValue);
      return acc;
    }, []);
    const macroTierNamesResponse = MACRO_TIERS.reduce((acc, t) => {
      const url = new URL(impressionTracking[0].params);
      const qs = url.searchParams;
      const pValue = qs.get(t);
      // eslint-disable-next-line no-param-reassign
      if (!!pValue) acc[t] = pValue.replace(MACRO_DELIMITERS, ' ').trim();
      return acc;
    }, {});
    return [platformValuesResponse, macroTierNamesResponse];
  }, [impressionTracking]);

  const tableColumns = useMemo(() => {
    if (!platformValues || !macroTierNames) return null;
    return [
      { display: 'Pixel Type', key: PLATFORM_TIER, tooltip: PLATFORM_TIER, isSearchable: true },
      ...MACRO_TIERS.map(t => !!macroTierNames[t] && ({ display: macroTierNames[t], key: t, tooltip: t, isSearchable: true })).filter(Boolean),
      { display: 'Count', key: '_', as: PixelCountCell },
    ];
  }, [platformValues, macroTierNames]);

  // get creative pixel data and filter by platform tier
  const allPagesSegment = useMemo(() => {
    if (!segments) return null;
    return segments.find(s => s.action_type === 'segment');
  }, [segments]);

  useEffect(() => {
    if (!platformValues?.length || !allPagesSegment) return;
    getCreativeData(allPagesSegment, weekAgo, yesterday).then(data => {
      const filteredData = data.filter(d => platformValues.includes(d[PLATFORM_TIER]));
      setImpPixelData(filteredData);
    });
  }, [platformValues, allPagesSegment]);

  // csv data export
  const formatDataForExport = data => {
    if (!data) return [];
    return data.map(d => {
      const row = {};
      tableColumns.forEach(c => {
        if (!!d[c.key]) row[c.display] = d[c.key];
      });
      return row;
    });
  };

  const exportCSV = () => {
    const parser = new json2csv.Parser();
    const formattedData = formatDataForExport(impPixelData);
    const csv = parser.parse(formattedData);

    const csvContent = `data:text/csv;charset=utf-8,${csv}`;
    const fileName = `${platform} Impression Pixel Data (${weekAgo} - ${yesterday}).csv`;

    const link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    track('data.marketing.advertising_platforms.tracking.download_impression_pixel_data', { platform });
  };

  if (!impPixelData) return <TreeTableLoader />;

  return (
    <IndexGridNew
      celled
      data={impPixelData}
      cols={tableColumns}
      paginate
      itemsPerPage={10}
      sortable
      searchable
      alignSearchLeft
      placeholder="Search"
      emptyIcon="info"
      fallBackMsg="No Impression Pixel Data Found"
      topRight={!!impPixelData?.length
        && (
        <Button
          inverted
          size="large"
          color="purple"
          content={(
            <>
              <DownloadIcon style={{ display: 'inline', height: 19, margin: '-5px 3px -5px -3px' }} />
              {' Download as CSV'}
            </>
)}
          onClick={exportCSV}
          style={{ margin: 0 }}
          className="remove-box-shadow"
        />
        )}
    />
  );
};

export default ImpressionPixelData;

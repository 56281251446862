import React from 'react';
import { IndexGridTree } from '@rockerbox/styleguide';
import { metrics } from '../../../../utils/channelPerformance/platformMetrics';

const RecommendationsTooltipText = () => (
  <>
    <p>We base our recommendations on various factors; how you implement them is up to you, and results are not guaranteed.</p>
    <a href="https://help.rockerbox.com/article/wi5m1awn1v-optimizing-against-target-cpa-or-roas-goals#recommendation_overview" target="_blank" rel="noreferrer">Learn more...</a>
  </>
);

export const generateCols = ({ hasOptionSelected, platformConfig, currencyCode, goal, tier }) => { // eslint-disable-line import/prefer-default-export
  const { metric, type } = { ...goal };

  if (!platformConfig || !platformConfig.hierarchy) return [];

  const removeFirstItem = platformConfig.hierarchy.slice(1); // Grid should not include account name
  const updatedHierarchy = removeFirstItem.map(c => c.value);
  const tier1DisplayName = removeFirstItem.map(c => c.text.replace(' name', '')).join(' / ');

  const metricCols = !hasOptionSelected ? platformConfig.default_metrics
    : [...platformConfig.default_metrics, ...platformConfig.optional_metrics];

  const allMetricCols = [];
  metricCols.forEach(metricCol => {
    const attributes = metrics[metricCol];
    allMetricCols.push({
      currency: currencyCode,
      display: attributes.display_name,
      key: attributes.name,
      isSearchable: true,
      as: attributes.as,
      reducer: attributes.reducer,
      style: { textAlign: 'right' },
    });
  });

  let cols = [
    { display: tier1DisplayName, key: 'group', groupBy: updatedHierarchy, as: IndexGridTree.NameCell({}) },
    ...allMetricCols,
  ];

  if (metric && type) { // removed target key so can render when opening saved goal view
    const goalMetric = allMetricCols.find(c => c.key === metric);
    const filteredMetricCols = allMetricCols.filter(c => c.key !== metric);
    const goalCols = [
      {
        display: `${metric.toUpperCase()}  vs Goal`,
        key: 'goal',
        as: IndexGridTree.MetricVsGoalCell,
        style: { textAlign: 'right' },
      },
      {
        display: 'Recommendations',
        key: 'recommendations',
        as: IndexGridTree.RecommendationCell,
        tooltip: RecommendationsTooltipText,
        hoverable: true,
        sortable: 0,
      },
    ];
    const filteredGoalCols = (tier === 'lite' || tier === 'free') ? goalCols.filter(c => c.key !== 'recommendations') : goalCols;

    cols = [
      cols[0],
      goalMetric,
      ...filteredGoalCols,
      ...filteredMetricCols,
    ];
  }

  return cols;
};

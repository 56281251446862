import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Divider } from 'semantic-ui-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ChartBarIcon, CursorClickIcon, SpeakerphoneIcon } from '@heroicons/react/outline';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ShareIcon, DatabaseIcon } from '@heroicons/react/solid';

import * as aq from 'arquero';

import * as routes from '../../baseRoutes';
import { track, time } from '../../../utils/tracking';
import { DateRangeHooks, SavedReportsGrid, PlatformPerformanceSync, DividingHeader } from '../../components';
import { convertCurrencyByDate } from '../../utils/currency';

import { getSavedReports } from '../../api/attribution';
import { getCurrencyRate } from '../../api/currencyRate';
import { useGlobalState } from '../../hooks/global';
import { FREE_PLATFORMS } from './constants';
import { getPlatformSpendDataByPlatform } from './helpers';

import FreeInviteUsers from './FreeInviteUsers';
import StateOfSpend from './StateOfSpend';
import Blog from './Blog';
import PlatformMetrics from './PlatformMetrics';
import FreeConnectOrders from './FreeConnectOrders';
import PlatformSpendChart from './PlatformSpendChart';
import GetStartedIntegrations from './GetStartedIntegrations';

const FreeDashboard = ({ startDate, endDate, onDateChange, currencyCode, tier }) => {
  const { integrations, authorizations } = useGlobalState();

  const [savedReports, setSavedReports] = useState(null);
  const [loadingSpend, setLoadingSpend] = useState(true);
  const [loadingConversions, setLoadingConversions] = useState(true);

  const [stillBackfilling, setStillBackfilling] = useState(false);
  const [stillBackfillingShopify, setStillBackfillingShopify] = useState(false);
  const [hasConversionData, setHasConversionData] = useState(false);
  const [hasSpendData, setHasSpendData] = useState(false);
  const [spendData, setSpendData] = useState(false);

  const connectedPlatforms = useMemo(
    () => (!!authorizations ? authorizations.filter(a => a.authorizations.some(ac => ac.account_id !== '')).map(({ platform }) => platform) : []),
    [authorizations],
  );

  const platforms = useMemo(() => {
    if (!authorizations || !integrations) return [];
    return authorizations.map(x => {
      const platformInfo = integrations.find(y => y.platform === x.platform) || {};
      return {
        ...x,
        ...platformInfo,
        title: platformInfo.display_name || x.platform.replaceAll('_', ' ').replaceAll('-', ' '),
        icon: !!platformInfo.icon_white_img && `https://images.rockerbox.com/integrations/${platformInfo.icon_white_img}`,
        defaultIcon: platformInfo.category === 'advertising.digital'
          ? CursorClickIcon
          : platformInfo.category === 'advertising.offline'
            ? SpeakerphoneIcon
            : platformInfo.category === 'advertising.affiliate'
              ? ShareIcon
              : DatabaseIcon,
      };
    });
  }, [integrations, authorizations]);

  const colorMap = useMemo(() => {
    const colors = {};
    Object.keys(FREE_PLATFORMS).forEach(platform => {
      colors[platform] = FREE_PLATFORMS[platform].color;
    });
    return colors;
  }, []);

  const currencyFormatOptions = {
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: 'USD',
  };
  currencyFormatOptions.currency = currencyCode || 'USD';
  const currencyFormatter = new Intl.NumberFormat('en-US', currencyFormatOptions);

  // spend data for metrics charts
  useEffect(() => {
    if (!connectedPlatforms) return;
    if (!connectedPlatforms.length) return setHasSpendData(false);
    if (!(connectedPlatforms.filter(x => x !== 'shopify').length > 0)) return setHasSpendData(false);
    setHasSpendData(true);
  }, [connectedPlatforms]);

  // conversion data for metrics charts
  useEffect(() => {
    if (!connectedPlatforms) return;
    if (!connectedPlatforms.includes('shopify')) return setHasConversionData(false);
    setHasConversionData(true);
  }, [connectedPlatforms]);

  useEffect(() => {
    getSavedReports()
      .then(savedReportsResponse => {
        setSavedReports(savedReportsResponse);
      });
  }, []);

  useEffect(() => {
    if (!authorizations) return;
    if (currencyCode === undefined) return;
    if (!authorizations.length) return setSpendData([]);
    setLoadingSpend(true);
    getPlatformSpendDataByPlatform(authorizations, startDate, endDate)
      .then(data => {
        const columns = Object.keys(Object.values(data)[0]).filter(x => x !== 'date');
        getCurrencyRate(currencyCode, startDate, endDate).then(response => {
          const rates = response.data;
          const table = aq.from(Object.values(data));
          const updated = convertCurrencyByDate(table, rates, columns);
          columns.push('date');
          setSpendData(updated.objects({ columns }));
          setLoadingSpend(false);
        });
      });
  }, [authorizations, startDate, endDate, currencyCode]);

  useEffect(() => {
    if (!!loadingSpend || !!loadingConversions) {
      time('home.view');
      return;
    }
    track('home.view');
  }, [loadingSpend, loadingConversions]);

  return (
    <div className="free-dashboard">
      {stillBackfilling && connectedPlatforms.map(p => <PlatformPerformanceSync {...{ platform: p, setPercent: () => {}, onComplete: () => window.location.reload() }} />)}
      {stillBackfillingShopify
      && <PlatformPerformanceSync {...{ platform: 'shopify', setPercent: () => {}, onComplete: () => window.location.reload() }} />}
      <Grid style={{ marginBottom: 25 }}>
        <Grid.Row>
          <Grid.Column width={5}>
            <GetStartedIntegrations {...{ integrations }} />
          </Grid.Column>
          <Grid.Column width={11}>
            <StateOfSpend />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      { (hasConversionData || hasSpendData)
      && (
      <div className="datepicker">
        <DateRangeHooks
          transparent
          {...{
            startDate,
            endDate,
            firstReportingDate: false,
            onDateChange,
          }}
        />
      </div>
      )}
      { hasConversionData
      && (
      <PlatformMetrics {...{
        loading: loadingConversions,
        setLoading: setLoadingConversions,
        stillBackfilling: stillBackfilling || stillBackfillingShopify,
        setStillBackfillingShopify,
        hasConversionData,
        currencyFormatter,
        startDate,
        endDate,
        currencyCode,
        spendData,
        tier,
      }}
      />
      )}
      { hasSpendData
      && (
      <PlatformSpendChart {...{
        loading: loadingConversions || loadingSpend,
        setLoading: setLoadingSpend,
        stillBackfilling,
        setStillBackfilling,
        hasSpendData,
        platforms,
        startDate,
        endDate,
        colorMap,
        currencyFormatter,
        data: spendData,
      }}
      />
      )}

      <DividingHeader
        text="Saved Reports"
        icon={<ChartBarIcon />}
      />
      {(savedReports && savedReports.length > 0) ? <SavedReportsGrid columns={3} hideTitle />
        : (
          <Grid centered style={{ marginTop: -2, marginBottom: 0 }}>
            <Grid.Column width={8}>
              <p style={{ fontSize: '18px', textAlign: 'center' }}>
                After
                {' '}
                <Link to={routes.connectIntegration}>connecting your advertising platforms</Link>
                , you can adjust the filters within each report and save them for quick access later. Your Saved Reports are available here on your dashboard and within each report.
              </p>
            </Grid.Column>
          </Grid>
        )}

      {(savedReports && savedReports.length > 0) && <Divider style={{ marginBottom: 50 }} />}

      <Grid centered stretched style={{ marginBottom: 60 }}>
        <Grid.Column width={6}>
          <FreeInviteUsers showTitle={false} />
        </Grid.Column>
        { !connectedPlatforms.includes('shopify')
        && (
        <Grid.Column width={6}>
          <FreeConnectOrders />
        </Grid.Column>
        )}
      </Grid>

      <Blog />
    </div>
  );
};

export default FreeDashboard;

import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import * as routes from '../../../baseRoutes';

const CreateTableDoneButton = ({ disabled }) => (
  <div style={{ padding: '10px 0px' }}>
    <Button
      as={Link}
      to={routes.datawarehouse}
      primary
      content={(
        <span>
          I’m done, take me to the Data Warehouse overview page
          <Icon name="arrow alternate circle right" />
        </span>
)}
      disabled={disabled}
    />
  </div>
);

export default CreateTableDoneButton;

import React from 'react';

import { ComposedChart, Area, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Header } from 'semantic-ui-react';

import { CustomAxisTick } from './chartHelpers';
import { titleCase } from '../helpers';

const PredVsActualTest = ({ isConversionKPI, predVsActualTestData = undefined }) => {
  if (!predVsActualTestData || !predVsActualTestData.length) return null;
  const chartData = predVsActualTestData.map(row => ({
    date: row.date,
    confidence: [row.q05, row.q95],
    mean: row.q50 || row.mean,
    actual: row.actual,
  })).sort((a, b) => a.date - b.date);

  const chartFormatter = isConversionKPI
    ? new Intl.NumberFormat('en-US')
    : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  const kpiText = isConversionKPI ? 'Conversions' : 'Revenue';
  const formatting = isConversionKPI ? 'number' : 'currency';

  return (
    <>
      <Header as="h3">
        Test Period
      </Header>
      <Header as="h4" style={{ margin: '1rem' }}>
        Predicted vs Actual (5% to 95% quantile range)
      </Header>
      {!!chartData && !!chartData.length && (
        <div>
          <ResponsiveContainer width="100%" height={250}>
            <ComposedChart
              width={730}
              height={250}
              data={chartData}
              margin={{
                top: 20, right: 20, bottom: 20, left: 20,
              }}
            >
              <XAxis dataKey="date" />
              <YAxis
                type="number"
                tickLine={false}
                minTickGap={70}
                tick={CustomAxisTick('y', formatting)}
                label={{ value: kpiText, angle: -90, position: 'insideLeft', offset: 20 }}
              />
              <Area type="linear" dataKey="confidence" stroke={null} fill="#B7ECD7" strokeWidth={3} />
              <Line type="linear" dataKey="actual" stroke="#485CDC" strokeWidth={3} dot={false} />
              <Line type="linear" dataKey="mean" stroke="#6AD9B0" strokeWidth={3} dot={false} />
              <Tooltip
                formatter={(value, name) => {
                  if (name === 'confidence') {
                    return [
                      `${chartFormatter.format(value[0])} - ${chartFormatter.format(value[1])}`,
                      '90% Confidence Interval',
                    ];
                  }
                  return [chartFormatter.format(value), titleCase(name)];
                }}
                itemSorter={item => {
                  switch (item.dataKey) {
                    case 'actual':
                      return 0;
                    case 'mean':
                      return 1;
                    case 'confidence':
                      return 2;
                    default:
                      return 3;
                  }
                }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export default PredVsActualTest;

const OAUTH_PLATFORMS = [
  'adroll',
  'adwords',
  'amazon_selling_partner_api',
  'bing',
  'dbm',
  'facebook',
  'linkedin',
  'pinterest',
  'reddit',
  'snapchat',
  'twitter',
  'tiktok',
  'stripe',
]

export default OAUTH_PLATFORMS

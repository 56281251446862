import React, { useContext, useMemo, useState, useEffect } from 'react';
import { Modal, Button } from 'semantic-ui-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DocumentTextIcon } from '@heroicons/react/outline';

import { IndexGridNew } from '@rockerbox/styleguide';
import { CacheContext } from '../../../utils/CacheContext';
import { getAllIntegrations, getIntegrationFeatures } from '../../api/account';
import { getPixelCode } from '../../api/attributionPixels';
import { HelpDocs } from '../../components';
import { track } from '../../../utils/tracking';

const ParamInstructions = ({ platform, setImpressionTracking }) => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState, Context] = useContext(CacheContext);
  const allIntegrations = Context.getCache('allIntegrations', getAllIntegrations) || null;
  const integrationFeatures = Context.getCache('integrationFeatures', getIntegrationFeatures) || [];

  const [modalOpen, setModalOpen] = useState(false);
  const [clickParameters, setClickParameters] = useState([{ name: 'Loading...', params: 'Loading...' }]);
  const [impressionPixels, setImpressionPixels] = useState([]);
  const [hasImpressionTracking, setHasImpressionTracking] = useState(false);

  const platformDisplayName = useMemo(() => {
    if (!allIntegrations) return;
    const integrationObj = allIntegrations.find(platformObj => platformObj.platform === platform) || {};
    return integrationObj?.display_name || platform;
  }, [allIntegrations, platform]);

  const helpDoc = useMemo(() => {
    const spendFeature = integrationFeatures.find(x => x.platform === platform && x.feature_group === 'spend_platform' && !!x.help_doc) || {};
    return spendFeature.help_doc;
  }, [integrationFeatures, platform]);

  useEffect(() => {
    if (!platform || !platformDisplayName) return;
    getPixelCode().then(data => {
      const { creative_pixels, creative_pixels_enabled } = data;
      const platformRows = creative_pixels
        .filter(x => x.platform === platform && ['click', 'creative'].includes(x.type))
        .map(x => ({
          name: x.name,
          params: x.compiled,
          type: x.type,
        }));
      const platformClickParameters = platformRows.filter(x => x.type === 'click');
      const platformImpressionPixels = platformRows.filter(x => x.type === 'creative');
      setClickParameters(platformClickParameters);
      setImpressionPixels(platformImpressionPixels);
      setHasImpressionTracking(!!creative_pixels_enabled && !!platformImpressionPixels.length);
      setImpressionTracking(!!creative_pixels_enabled && !!platformImpressionPixels.length && platformImpressionPixels);
    });
  }, [platform, platformDisplayName]);

  // eslint-disable-next-line react/no-unstable-nested-components
  const ViewInstructionsButton = () => (
    <Button
      inverted
      size="large"
      color="purple"
      content={(
        <>
          <DocumentTextIcon style={{ display: 'inline', height: 19, margin: '-5px 3px -5px -3px' }} />
          {' View Instructions'}
        </>
)}
      onClick={() => setModalOpen(true) && track('data.marketing.advertising_platforms.tracking.view_instructions', { platform })}
      style={{ margin: 0 }}
      className="remove-box-shadow"
    />
  );

  return (
    <>
      <IndexGridNew
        celled
        title="Click Tracking Parameters"
        data={clickParameters}
        cols={[
          { display: 'Type', key: 'name', as: IndexGridNew.CollapsingCell },
          { display: 'URL Parameters', key: 'params', as: IndexGridNew.CopyCell },
        ]}
        topRight={<ViewInstructionsButton />}
      />
      {hasImpressionTracking && (
      <>
        <br />
        <IndexGridNew
          celled
          title="Impression Tracking Pixels"
          data={impressionPixels}
          cols={[
            { display: 'Type', key: 'name', as: IndexGridNew.CollapsingCell },
            { display: 'Pixel Code', key: 'params', as: IndexGridNew.CopyCell },
          ]}
          topRight={<ViewInstructionsButton />}
        />
      </>
      )}
      <Modal
        closeIcon
        dimmer="inverted"
        className="modal-v3"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Modal.Header>
          <div className="title">
            {`${platformDisplayName} Tracking Instructions`}
          </div>
        </Modal.Header>
        <Modal.Content scrolling>
          <HelpDocs slug={helpDoc} />
        </Modal.Content>
      </Modal>
    </>
  );
};

export default ParamInstructions;

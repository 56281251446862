import React, { useEffect, useState } from 'react';
import { Segment, Icon } from 'semantic-ui-react';
import { useAtom } from 'jotai';
import { tier1Atom, anyAtom, firstAtom, midAtom, lastAtom, saveIDAtom } from '../../atoms';
import { isJsonString } from '../../utils/valueFormatter';
import { track } from '../../../utils/tracking';

const FilterWrapper = ({
  loading, collapseKey, advancedFilters, advancedFiltersOpened,
  other, date, stickyRight, stickyStyles,
  compareDate,
  trackNameForAdvancedFilterClick,
}) => {
  const [tier_1] = useAtom(tier1Atom);
  const [any] = useAtom(anyAtom);
  const [first] = useAtom(firstAtom);
  const [mid] = useAtom(midAtom);
  const [last] = useAtom(lastAtom);
  const [saveID] = useAtom(saveIDAtom);
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    if (!collapseKey) return;
    setShowFilters(false);
  }, [collapseKey]);

  const openCloseFilters = () => {
    showFilters ? setShowFilters(false) : setShowFilters(true);
  };

  useEffect(() => {
    if (tier_1.length > 0 || isJsonString(any) || isJsonString(first) || isJsonString(mid) || isJsonString(last)) {
      setShowFilters(true);
    }
  }, [saveID]);

  return (
    <Segment className="filter-wrap-v3">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {other && other}
          {date && date}
          {compareDate && compareDate}
          {advancedFilters && (
            <div // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
              style={{ paddingLeft: '20px', color: '#475ddc', marginTop: '15px' }}
              onClick={() => {
                if (!loading) {
                  if (trackNameForAdvancedFilterClick) track(trackNameForAdvancedFilterClick);
                  openCloseFilters();
                }
              }}
              role="button"
            >
              <span style={{ cursor: 'pointer' }}>Advanced Filters</span>
              <Icon
                link
                name={showFilters ? 'angle up' : 'angle down'}
                style={{ float: 'right', color: '#475ddc' }}
              />
            </div>
          )}
        </div>
        {stickyRight && (
        <div style={stickyStyles || { display: 'flex', alignItems: 'flex-end' }}>
          {stickyRight}
        </div>
        )}
      </div>
      {showFilters && advancedFilters && (
        <div style={{ paddingTop: '10px' }}>
          {advancedFilters}
        </div>
      )}
      {advancedFiltersOpened && (
        <div style={{ paddingTop: '20px', display: 'flex' }}>
          {advancedFiltersOpened}
        </div>
      )}
    </Segment>
  );
};

export default FilterWrapper;

import React, { useEffect, useState, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Header, Dropdown, Form, Loader, Button } from 'semantic-ui-react';
import toast from 'react-hot-toast';

import { getConversionFunnelConfigs, postConversionFunnelConfig, putConversionFunnelConfig } from '../../api/attribution';
import { useGlobalState } from '../../hooks/global';
import { segmentOptions } from '../../utils/options';
import { track } from '../../../utils/tracking';
import { ViewAccessWrapper } from '../../components';

const ConfigForm = ({ funnelConfiguration, options, refresh }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const configEvents = [
    { name: 'conversion_event_id_1', label: 'Conversion Event 1', rules: { required: 'Minimum 2 Conversion Events Required' } },
    { name: 'conversion_event_id_2', label: 'Conversion Event 2', rules: { required: 'Minimum 2 Conversion Events Required' } },
    { name: 'conversion_event_id_3', label: 'Conversion Event 3' },
    { name: 'conversion_event_id_4', label: 'Conversion Event 4' },
    { name: 'conversion_event_id_5', label: 'Conversion Event 5' },
  ];

  const formDefaultValues = useMemo(() => {
    if (Object.keys(funnelConfiguration).length === 0) return {};
    const { name, conversion_event_id_1, conversion_event_id_2, conversion_event_id_3, conversion_event_id_4, conversion_event_id_5 } = funnelConfiguration;
    return {
      name,
      conversion_event_id_1,
      conversion_event_id_2,
      conversion_event_id_3,
      conversion_event_id_4,
      conversion_event_id_5,
    };
  }, [funnelConfiguration]);

  const { control, handleSubmit, formState: { errors }, setValue, trigger, reset } = useForm({
    defaultValues: formDefaultValues,
  });

  const onSave = data => {
    setIsSubmitting(true);
    const saveConfig = funnelConfiguration.id ? putConversionFunnelConfig(data, funnelConfiguration.id) : postConversionFunnelConfig(data);

    saveConfig
      // eslint-disable-next-line no-unused-vars
      .then(({ id, success }) => {
        track('Settings.ConversionFunnel.set.save');
        if (success) {
          toast.success('Updated Funnel Configuration');
          setIsSubmitting(false);
          refresh();
        }
      })
      .catch(err => {
        console.log('Save Funnel Config Error:', err); // eslint-disable-line no-console
        toast.error('Error updating funnel configuration');
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSave)} style={{ width: 500 }}>
      <Loader active={isSubmitting} />
      <Form.Field disabled={isSubmitting}>
        <label>Funnel Configuration Name</label>
        <Controller
          fluid
          name="name"
          control={control}
          render={({ field }) => (
            <Form.Input
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
              placeholder="Default"
              error={errors.name?.message}
            />
          )}
          rules={{ required: 'Missing Name' }}
        />
      </Form.Field>
      {configEvents.map(({ name, label, rules }) => (
        <Form.Field disabled={isSubmitting}>
          <label>{label}</label>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Dropdown
              // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                selection
                clearable
                options={options}
                // eslint-disable-next-line no-shadow
                onChange={(e, { name, value }) => {
                  const valueUpdated = value.length || value > 0 ? Number(value) : null;
                  setValue(name, valueUpdated);
                  trigger(name);
                }}
                placeholder="Select Conversion Event"
                error={errors[name]?.message}
              />
            )}
            rules={rules}
          />
          {errors[name]?.message && <div className="ui pointing above prompt label">{errors[name].message}</div>}
        </Form.Field>
      ))}
      <Button type="submit" primary style={{ marginRight: 10 }} disabled={isSubmitting}>Save changes</Button>
      <Button onClick={() => reset(formDefaultValues)} color="purple" inverted type="button" disabled={isSubmitting}>Cancel</Button>
    </Form>
  );
};

const ConversionFunnelConfig = () => {
  const { segments } = useGlobalState();
  const [funnelConfiguration, setFunnelConfiguration] = useState({});
  const [configLoaded, setConfigLoaded] = useState(false);
  const options = segmentOptions(segments);

  const handleGetFunnelConfigs = () => {
    getConversionFunnelConfigs()
      .then(data => {
        setFunnelConfiguration(data[0] || {});
        setConfigLoaded(true);
      })
      .catch(err => console.log(err)); // eslint-disable-line no-console
  };

  useEffect(() => {
    handleGetFunnelConfigs();
  }, []);

  return (
    <>
      <Header
        as="h1"
        content="Conversion Funnel"
      />
      {configLoaded && <ConfigForm {...{ funnelConfiguration, options, refresh: handleGetFunnelConfigs }} />}
    </>
  );
};

const ConversionFunnelConfigWrapper = () => (
  <ViewAccessWrapper
    viewName="settings_conversion_funnel"
    viewComponent={<ConversionFunnelConfig />}
  />
);

export default ConversionFunnelConfigWrapper;

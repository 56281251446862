import React from 'react';

import ContentLoader from 'react-content-loader';
import { Card } from 'semantic-ui-react';

const SimpleChartCardLoader = ({ animate = true }) => (
  <Card fluid>
    <ContentLoader
      viewBox="0 0 397 200"
      preserveAspectRatio="none"
      foregroundColor="#e5e5e5"
      height={200}
      width="100%"
      {...{ animate }}
    >
      <path d="M0,113.1c0,0,49.81,16.26,117.95-13.69c46.07-20.25,101.11-20.17,101.11-20.17s62.01,1.14,91.51-27.22
        C339.24,24.44,397,34.34,397,34.34v165.98L0,201.25V113.1z"
      />
    </ContentLoader>
  </Card>
);

export default SimpleChartCardLoader;

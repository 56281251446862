import React from "react";
import { tableTypeOptions } from "./constants";
import { Form } from "semantic-ui-react";
import { IndexGridNew } from "@rockerbox/styleguide";

const SyncCardExpandableDatasets = ({
  item: {
    datasets,
    filter_id,
    onDatasetDelete,
    onStatusChange,
    onSnapshotChange,
    onSnapshotScheduleChange
  }
}) => {
  return (
    <Form>
      {!!datasets &&
        datasets.map(dataset => {
          return (
            <Form.Group key={dataset["warehouse_table_name"]}>
              <Form.Field>
                <Form.Dropdown
                  width={4}
                  selection
                  disabled={true}
                  options={tableTypeOptions}
                  value={dataset["dataset"]}></Form.Dropdown>
              </Form.Field>
              <Form.Input
                value={dataset["warehouse_table_name"]}
                disabled={true}></Form.Input>
              <Form.Button
                primary
                onClick={onStatusChange}
                filter_id={filter_id}
                dataset_id={dataset["id"]}>
                Status
              </Form.Button>
              <Form.Button
                primary
                onClick={ (e) => {
                    e.preventDefault();
                    window.open('https://app.rockerbox.com/documentation/reports/' + dataset["report_type_id"], '_blank').focus();
                  }
                }>
                Schema
              </Form.Button>
              <Form.Button
                primary
                onClick={onSnapshotChange}
                filter_id={filter_id}
                dataset_id={dataset["id"]}>
                Snapshots
              </Form.Button>
              <Form.Button
                primary
                dataset_id={dataset["id"]}
                filter_id={filter_id}
                onClick={onSnapshotScheduleChange}>
                Schedule Snapshot
              </Form.Button>
              <IndexGridNew.DeleteButton
                datasetId={dataset["id"]}
                onDelete={onDatasetDelete}
                popupContent={`Delete Table ${dataset["warehouse_table_name"]}`}>
                Delete
              </IndexGridNew.DeleteButton>
            </Form.Group>
          );
        })}
    </Form>
  );
};

const ManageButtons = ({ item }) => {
  const callback = useCallback(() => deleteSnapshotScheduleById(item.id));
  return (
    <Table.Cell>
      <IndexGridNew.DeleteButton
        onDelete={callback}
        popupContent={`Delete Schedule`}>
        Delete
      </IndexGridNew.DeleteButton>
    </Table.Cell>
  );
};

export default SyncCardExpandableDatasets;

import React, { useState, useEffect, useContext } from 'react';
import { Grid, Loader, Icon, GridColumn } from 'semantic-ui-react';
import { Elements } from '@stripe/react-stripe-js';
import { getCustomerID, postStripeSubscription, getStripeSubscription, deleteStripeSubscription, getStripeInvoice, postSetupIntent, getDiscountCodeMetadata } from '../../api/account';
import PricingInputForm from './inputForm';
import ConfirmationPage from './confirmation';
import PricingGraph from './graph';
import CheckoutForm from './checkoutForm';
import SetupForm from './setupForm';
import { monthlyAdSpendToPrice, roundStripeUnits, calculateProratedCost, handleDiscount, formatAddress } from './helpers';
import { StripeContext } from '../../../utils/stripe';

const StarterPricing = ({ completeStep, completedOnboardingSteps, pricing, monthlyAdSpend, setMonthlyAdSpend, onboardingResponse, tax, setTax }) => {
  const { stripePromise } = useContext(StripeContext);

  const [customerId, setCustomerId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [clientSecretOptions, setClientSecretOptions] = useState(false);
  const [estimated, setEstimated] = useState(0);
  const [prorated, setProrated] = useState(0);
  const [hasSub, setHasSub] = useState(false);
  const [subId, setSubId] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditPromoCode, setIsEditPromoCode] = useState(false);
  const [promoCode, setPromoCode] = useState(null);
  const [codePending, setCodePending] = useState(false);
  const [promoValue, setPromoValue] = useState(null);
  const [promoPercent, setPromoPercent] = useState(null);
  const [promoTagline, setPromoTagline] = useState(null);
  const [discountType, setDiscountType] = useState(null);
  const [oneHundredPercentOff, setOneHundredPercentOff] = useState(false);
  const [setupIntentComplete, setSetupIntentComplete] = useState(false);
  const [pricePending, setPricePending] = useState(false);
  const [taxInfo, setTaxInfo] = useState({ zipCollected: false, address: false, readyForPayment: false, recalcTax: false });
  const [zip, setZip] = useState('');
  const [address, setAddress] = useState('');
  const [proratedTotal, setProratedTotal] = useState(0);

  const REQUIRED_STEPS = ['survey', 'store', 'platforms', 'tracking', 'cname', 'invite'];
  const price_id = window.location.href.includes('app.rockerbox') ? 'price_1LvMbFF27DVuTFSPEIJnZOvV' : 'price_1LvMRQF27DVuTFSPGc9B4V7Y';

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#475ddc',
      colorBackground: '#ffffff',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '4px',
      borderRadius: '8px',
    },
  };

  const options = {
    clientSecret: '',
    appearance,
  };

  // handle special case of 100% off code after the payment info has been provided
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const { confirm } = params;
  const promo = params.code;
  const { type } = params;
  if (confirm && customerId && !setupIntentComplete) {
    setLoading(true);
    const localDate = new Date();
    // check for active stripe sub first
    getStripeSubscription(customerId)
      .then(subscriptionResponse => {
        const subscriptionData = subscriptionResponse.response.data;
        const activeSubscription = subscriptionData.find(x => x.status === 'active');
        if (activeSubscription) {
          setOneHundredPercentOff(true);
          setHasSub(true);
          setLoading(false);
        } else {
          postStripeSubscription(customerId, price_id, roundStripeUnits(monthlyAdSpend), localDate.getTimezoneOffset(), promo, type);
        }
        setOneHundredPercentOff(true);
        setHasSub(true);
        setLoading(false);
      });
    setSetupIntentComplete(true);
  }

  useEffect(() => {
    if (taxInfo.zipCollected === 'has zip') return;
    const date = new Date();
    getCustomerID()
      .then(customerResponse => {
        const cid = customerResponse.response;
        setCustomerId(cid);
        getStripeSubscription(cid)
          .then(subscriptionResponse => {
            const subscriptionData = subscriptionResponse.response.data;
            const activeSubscription = subscriptionData.find(x => x.status === 'active');
            if (activeSubscription) {
              setHasSub(true);
              // retrieve the latest invoice to check if there is a one-time discount applied (this won't show up on the subscription object)
              getStripeInvoice(activeSubscription.latest_invoice)
                .then(invoiceResponse => {
                  setAddress(formatAddress(invoiceResponse.response.customer_address));
                  setTaxInfo(prevState => ({ ...prevState, address: formatAddress(invoiceResponse.response.customer_address) }));
                  setProratedTotal((invoiceResponse.response.amount_paid) / 100);
                  handleDiscount(activeSubscription, pricing, invoiceResponse.response, setEstimated, setProrated, setOneHundredPercentOff);
                  setPricePending(false);
                });
            } else {
              postStripeSubscription(cid, price_id, roundStripeUnits(onboardingResponse.annual_marketing_spend), date.getTimezoneOffset())
                .then(subscriptionPost => {
                  if ('error_message' in subscriptionPost.response) return;
                  setSubId(subscriptionPost.response.id);
                });
            }
            setLoading(false);
          });
      });
  }, []);

  useEffect(() => {
    const localDate = new Date();
    if (monthlyAdSpend < 1 || !pricing || !subId) return;
    setClientSecretOptions(null);
    deleteStripeSubscription(subId, price_id, roundStripeUnits(monthlyAdSpend))
      .then(() => {
        setClientSecretOptions(null);
        // handle special case of 100% promo code entered
        if (oneHundredPercentOff & !confirm) {
          setEstimated(monthlyAdSpendToPrice(monthlyAdSpend, pricing.response));
          setProrated(calculateProratedCost(monthlyAdSpendToPrice(monthlyAdSpend, pricing.response)));
          setPromoValue(null);
          getDiscountCodeMetadata(promoCode, discountType)
            .then(resp => {
              const tagline = discountType === 'promo_code' ? resp.response.coupon.metadata.text : resp.response.metadata.text;
              setPromoTagline(tagline);
            });
          setCodePending(false);
          setLoading(false);
          setCustomerId(customerId);
          postSetupIntent(customerId, ['card', 'us_bank_account'])
            .then(setupIntent => {
              options.clientSecret = setupIntent.response.client_secret;
              setClientSecretOptions(options);
            });
        } else {
          postStripeSubscription(customerId, price_id, roundStripeUnits(monthlyAdSpend), localDate.getTimezoneOffset(), promoCode, discountType)
            .then(subscriptionPost => {
              if (subscriptionPost.response.discount == null && subscriptionPost.response.latest_invoice.discount == null) {
                // no promo code
                setEstimated(monthlyAdSpendToPrice(monthlyAdSpend, pricing.response));
                setProrated(calculateProratedCost(monthlyAdSpendToPrice(monthlyAdSpend, pricing.response)));
                setPricePending(false);
                setPromoValue(null);
              } else {
                // valid promo code entered, do the promo code logic
                const couponObj = { couponData: {} };
                if (subscriptionPost.response.latest_invoice.discount && subscriptionPost.response.discount == null) {
                  couponObj.couponData = subscriptionPost.response.latest_invoice.discount.coupon;
                } else {
                  couponObj.couponData = subscriptionPost.response.discount.coupon;
                }
                if (couponObj.couponData.percent_off === null) {
                  setPromoValue(couponObj.couponData.amount_off / 100);
                  setEstimated(monthlyAdSpendToPrice(monthlyAdSpend, pricing.response) - couponObj.couponData.amount_off / 100);
                  setProrated(calculateProratedCost(monthlyAdSpendToPrice(monthlyAdSpend, pricing.response) - couponObj.couponData.amount_off / 100));
                } else {
                  setPromoPercent(couponObj.couponData.percent_off);
                  setEstimated(monthlyAdSpendToPrice(monthlyAdSpend, pricing.response) * (1 - couponObj.couponData.percent_off / 100));
                  setProrated(calculateProratedCost(monthlyAdSpendToPrice(monthlyAdSpend, pricing.response)) * (1 - couponObj.couponData.percent_off / 100));
                }
                setPricePending(false);
                setPromoTagline(couponObj.couponData.metadata.text);
              }
              options.clientSecret = subscriptionPost.response.latest_invoice.payment_intent.client_secret;
              setClientSecretOptions(options);
              setCodePending(false);
            });
        }
      });
  }, [monthlyAdSpend, pricing, subId, promoCode, taxInfo]);

  if (hasSub && !loading) {
    return <ConfirmationPage {...{ estimated, completeStep, oneHundredPercentOff, proratedTotal, address }} />;
  }

  const completedPriorSteps = REQUIRED_STEPS.every(s => completedOnboardingSteps.includes(s));
  const pricesLoading = !(estimated > 0);
  return (
    <div className="starter-pricing-view">
      <Grid className="grid">
        <Grid.Column width={5}>
          {/* eslint-disable-next-line max-len */}
          {!loading && !pricesLoading && <PricingInputForm {...{ estimated, prorated, isEdit, setIsEdit, isEditPromoCode, setIsEditPromoCode, monthlyAdSpend, setMonthlyAdSpend, promoCode, setPromoCode, codePending, setCodePending, promoValue, setPromoValue, promoTagline, promoPercent, setPromoPercent, discountType, setDiscountType, clientSecretOptions, oneHundredPercentOff, setOneHundredPercentOff, pricePending, setPricePending, taxInfo, setTaxInfo, address, setAddress }} />}
        </Grid.Column>
        {!loading && !pricesLoading && (
        <Grid.Column width={7} className="graph">
          {' '}
          <div>
            <h3 className="header">Starter Plan Pricing Curve</h3>
            <PricingGraph pricing={pricing} givenSpendLevel={monthlyAdSpend} minHeight={310} heightPercent={1.0} YAxisLabel="Cost Per $1K in Ad Spend" />
          </div>
        </Grid.Column>
        )}
      </Grid>
      <Grid className="starter-pricing-view-grid">
        {!clientSecretOptions && (loading || pricesLoading)
          && <Loader active />}
        {clientSecretOptions && !isEdit && completedPriorSteps && !oneHundredPercentOff
        && (
        <GridColumn width={12}>
          <Grid>
            <GridColumn width={16}>
              <Elements key={monthlyAdSpend} stripe={stripePromise} appearance={appearance} options={clientSecretOptions}>
                <CheckoutForm
                  estimated={estimated}
                  prorated={prorated}
                  customerId={customerId}
                  zip={zip}
                  setZip={setZip}
                  tax={tax}
                  setTax={setTax}
                  monthlyAdSpend={monthlyAdSpend}
                  promoCode={promoCode}
                  promoValue={promoValue}
                  promoPercent={promoPercent}
                  address={address}
                  setAddress={setAddress}
                  proratedTotal={proratedTotal}
                  setProratedTotal={setProratedTotal}
                  taxInfo={taxInfo}
                  setTaxInfo={setTaxInfo}
                />
              </Elements>
            </GridColumn>
          </Grid>
        </GridColumn>
        )}
        {clientSecretOptions && !isEdit && completedPriorSteps && oneHundredPercentOff
        && (
        <GridColumn width={12} className="onboarding-payment-active-view">
          <Grid>
            <GridColumn width={16} className="onboarding-payment-box">
              <Elements key={monthlyAdSpend} stripe={stripePromise} appearance={appearance} options={clientSecretOptions}>
                <SetupForm {...{ promoCode, discountType }} />
              </Elements>
            </GridColumn>
          </Grid>
        </GridColumn>
        )}
      </Grid>
      <Grid className="billing-info">
        <Grid.Column>
          <div>
            {!loading && !completedPriorSteps
        && (
        <div className="onboarding-payment-blocked-view">
          <Icon inverted color="grey" name="info circle" size="huge" className="icon" />
          <p className="text">Finish your previous setup steps before proceeding with billing</p>
        </div>
        )}
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
};
export default StarterPricing;

import React from 'react';
import ContentLoader from 'react-content-loader';
import { Grid, Segment } from 'semantic-ui-react';

export const TrendChartLoader = () => (
  <ContentLoader
    viewBox="0 0 1232 350"
    preserveAspectRatio="none"
    foregroundColor="#e5e5e5"
    height={350}
    width="100%"
  >
    <path d="M7.3,59.8h18v248.1h-18L7.3,59.8z" />
    <path d="M30.9,82.5h18v225.3h-18L30.9,82.5z" />
    <path d="M54.3,115.8h18v192.1h-18V115.8z" />
    <path d="M77.8,83.3h18v224.6h-18V83.3z" />
    <path d="M101.3,107.8h18v200h-18V107.8z" />
    <path d="M124.8,52h18v255.9h-18L124.8,52z" />
    <path d="M148.4,87.4h18v220.5h-18V87.4z" />
    <path d="M171.9,106.7h18v201.1h-18V106.7z" />
    <path d="M195.4,88.8h18v219.1h-18V88.8z" />
    <path d="M218.9,74.7h18v233.2h-18V74.7z" />
    <path d="M242.4,111.3h18v196.6h-18V111.3z" />
    <path d="M265.9,97.6h18v210.2h-18V97.6z" />
    <path d="M289.4,77.5h18v230.3h-18V77.5z" />
    <path d="M312.9,21.9h18v286h-18V21.9z" />
    <path d="M336.4,39.7h18v268.2h-18V39.7z" />
    <path d="M359.9,5h18v302.9h-18V5z" />
    <path d="M383.4,44h18v263.9h-18V44z" />
    <path d="M406.9,76.1h18v231.8h-18V76.1z" />
    <path d="M430.4,98.1h18v209.8h-18V98.1z" />
    <path d="M453.9,80.4h18v227.5h-18V80.4z" />
    <path d="M477.4,94.1h18v213.7h-18V94.1z" />
    <path d="M500.9,76.1h18v231.8h-18L500.9,76.1z" />
    <path d="M524.3,51.7h18v256.2h-18V51.7z" />
    <path d="M547.8,71.4h18v236.5h-18V71.4z" />
    <path d="M571.3,34.3h18v273.6h-18V34.3z" />
    <path d="M594.8,48.7h18v259.2h-18V48.7z" />
    <path d="M618.3,94.9h18v213h-18V94.9z" />
    <path d="M641.8,105.1h18v202.8h-18V105.1z" />
    <path d="M665.3,92.4h18v215.5h-18V92.4z" />
    <path d="M688.8,80.4h18v227.5h-18V80.4z" />
    <path d="M712.3,54.4h18v253.5h-18V54.4z" />
    <path d="M735.8,86.6h18v221.3h-18V86.6z" />
    <path d="M759.3,105.1h18v202.7h-18V105.1z" />
    <path d="M782.8,21.4h18v286.4h-18V21.4z" />
    <path d="M806.3,46.3h18v261.6h-18V46.3z" />
    <path d="M829.8,70.8h18v237.1h-18V70.8z" />
    <path d="M853.3,49h18v258.9h-18V49z" />
    <path d="M876.8,34.3h18v273.6h-18V34.3z" />
    <path d="M900.3,74.7h18v233.2h-18V74.7z" />
    <path d="M923.8,98.4h18v209.5h-18V98.4z" />
    <path d="M947.3,86.6h18v221.3h-18V86.6z" />
    <path d="M970.8,127.2h18v180.7h-18V127.2z" />
    <path d="M994.3,115.8h18v192.1h-18V115.8z" />
    <path d="M1017.8,91.5h18v216.4h-18V91.5z" />
    <path d="M1041.3,107.5h18v200.3h-18V107.5z" />
    <path d="M1064.8,122.8h18v185h-18V122.8z" />
    <path d="M1088.3,63h18v244.8h-18V63z" />
    <path d="M1111.8,21.9h18v286h-18V21.9z" />
    <path d="M1135.3,34.3h18v273.6h-18V34.3z" />
    <path d="M1158.8,57.8h18v250.1h-18V57.8z" />
    <path d="M1182.3,74.7h18v233.2h-18V74.7z" />
    <path d="M1205.8,44h18v263.9h-18V44z" />
  </ContentLoader>
);

const HighlightsIconLoader = ({ animate = true }) => (
  <ContentLoader
    viewBox="0 0 30 30"
    foregroundColor="#e5e5e5"
    height={30}
    width={30}
    {...{ animate }}
  >
    <circle cx="15" cy="15" r="15" />
  </ContentLoader>
);

export const HighlightsTextLoader = ({ animate = true }) => (
  <ContentLoader
    viewBox="0 0 325 51"
    preserveAspectRatio="none"
    foregroundColor="#e5e5e5"
    height={51}
    width="100%"
    {...{ animate }}
  >
    <rect x="0" y="3.9" width="297.96" height="9.06" rx="4" ry="4" />
    <rect x="0" y="20.97" width="246.99" height="9.06" rx="4" ry="4" />
    <rect x="0" y="38.04" width="132.17" height="9.06" rx="4" ry="4" />
  </ContentLoader>
);

export const HighlightsLoader = ({ animate = true }) => (
  <div
    style={{
      display: 'flex',
      width: '30%',
      minWidth: 370,
    }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <HighlightsIconLoader {...{ animate }} />
    </div>
    <div style={{ paddingLeft: 15 }}>
      <HighlightsTextLoader {...{ animate }} />
    </div>
  </div>
);

const ChannelStatLoader = () => (
  <ContentLoader
    viewBox="0 0 233 137"
    foregroundColor="#e5e5e5"
    height={137}
    width={233}
  >
    <rect x="76.17" y="46.28" width="80.66" height="28.09" rx="4" ry="4" />
    <rect x="20.63" y="82.52" width="191.73" height="8.28" rx="4" ry="4" />
  </ContentLoader>
);

export const ChannelTrendsLoader = () => (
  <>
    <Grid>
      <Grid.Row>
        <Grid.Column stretched width={11} style={{ paddingTop: 15, flexGrow: 1 }}>
          <TrendChartLoader />
        </Grid.Column>
        <Grid.Column
          stretched
          verticalAlign="middle"
          style={{ width: 245, height: '75%', paddingLeft: 0 }}
        >
          <ChannelStatLoader />
          <ChannelStatLoader />
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <br />
    <div style={{ position: 'relative', height: 150 }} />
  </>
);

export const ChannelRecommendationsLoader = ({ animate }) => (
  <Grid>
    <Grid.Row columns={4} stretched>
      {[...Array(4)].map((_, i) => (
        <Grid.Column key={i}>
          <Segment
            style={{
              height: 85,
              boxShadow: 'none',
              border: '2px solid #eeeeee',
            }}
          >
            <ContentLoader
              {...{ animate }}
              viewBox="0 0 78 57"
              foregroundColor="#e5e5e5"
              height={58}
              width={78}
              style={{
                marginLeft: 0,
                marginRight: 'auto',
              }}
            >
              <circle cx="11.53" cy="18.04" r="11.44" />
              <rect x=".9" y="40.64" width="75" height="11.57" rx="4" ry="4" />
            </ContentLoader>
            <ContentLoader
              {...{ animate }}
              viewBox="0 0 50 50"
              foregroundColor="#e5e5e5"
              height={50}
              width={50}
              style={{
                position: 'absolute',
                top: 15,
                right: 15,
              }}
            >
              <path cx="25" cy="25" d="M 25,9.5 A 15.5,15.5,0,1,1,24.999729473965957,9.500000002360785 L 24.99983419372107,15.500000001446933 A 9.5,9.5,0,1,0,25,15.5 Z" />
            </ContentLoader>
          </Segment>
        </Grid.Column>
      ))}
    </Grid.Row>
  </Grid>
);

/* eslint-disable max-len */
import React, { useEffect } from 'react';

import { useAtom } from 'jotai';

import AttributionModelSelector from './AttributionModelSelector';
import CreditRedistributionModal from './CreditRedistributionModal';
import { track } from '../../../utils/tracking/track';
import { analysisSearchAtom } from '../../atoms';
import { FilterWrapper, DateRangeHooks, StyledDropdown, DerivedTiersFilter, RollupFilter, DebouncedSearch, SaveReportButton, ResetFilterButton } from '../../components';
import CompareDateRangePicker from '../../components/CompareDateRangePicker';
import { NTF_OPTIONS } from '../../constants/options';
import { segmentOptions } from '../../utils/options';

// LIST OF ACCOUNTS that will not have derived tiers feature until their tier mappings are corrected
const EXCLUDE_DERIVED_TIERS_ACCOUNTS = ['hukgear', '3zbrands', 'chubbies_shorts', 'rad_power_bikes', 'travis_mathew', 'trupanion', 'ucare', 'weight_watchers_subscription', 'tula', 'stockx', 'babylist', 'loews', 'burton', 'living_proof', 'c2fo', 'murad', 'carewell', 'bistromd', 'hourglass'];

const Filter = ({
  loading, tiersData, rawData, // data
  segments, id, setId, // conversion segments
  startDate, endDate, onDateChange, firstReportingDate, compareStartDate, compareEndDate, compareStart, compareEnd, // dates
  compareRange, setCompareRange, compare, setCompare, onCompareDateChange, compareOptions, numDays, setCompareStartDate, setCompareEndDate, // comparison
  hasNtf, ntfFilter, setNtfFilter, // customer type
  attributionMethod, setAttributionMethod, attributionOptions, noData, standardAttributionOptions, redistributionOptions, // model
  filters, resetFilters, search, setSearch, // jotai
  tierOneSummaryRaw, setCreditRedistributionId, selectedRedistribution, getAndSetRedistributions, // modal data
  account,
}) => {
  const [analysisSearch] = useAtom(analysisSearchAtom);
  const segmentOptionsDropdown = segmentOptions(segments);
  const { pixel_source_name } = account;
  const excludeRBClassification = EXCLUDE_DERIVED_TIERS_ACCOUNTS.includes(pixel_source_name);
  const allowExtendedDateRanges = true;

  useEffect(() => {
    if (tiersData.length && !hasNtf) {
      setNtfFilter('all');
    }
  }, [hasNtf]);

  const openModal = () => {
    track('Attribution.AttributionReport.CustomModel.set.click');
  };

  const trackModelChange = value => {
    const presets = ['normalized', 'even', 'first_touch', 'last_touch'];
    if (!presets.includes(value)) {
      track('Attribution.AttributionReport.CustomModel.set.toggle');
    }
  };

  return (
    <FilterWrapper
      {...{ loading }}
      other={(
        <>
          <StyledDropdown
            label="Conversion Type"
            options={segmentOptionsDropdown}
            value={parseInt(id)}
            onChange={(e, { value }) => setId(value)}
            fluid={false}
          />
          <StyledDropdown
            label="Customer Type"
            disabled={!hasNtf || noData}
            options={NTF_OPTIONS}
            value={ntfFilter}
            onChange={(e, { value }) => setNtfFilter(value)}
          />
          <AttributionModelSelector
            label="Attribution Model"
            {...{ standardAttributionOptions, redistributionOptions, attributionOptions }}
            value={selectedRedistribution.id || attributionMethod}
            onChange={(e, data) => {
              setAttributionMethod(data);
              trackModelChange(data.value);
            }}
            disabled={noData}
            rightBtn={(
              <div
                onClick={openModal}
                tabIndex={0}
                role="button"
                onKeyDown={openModal}
              >
                <CreditRedistributionModal {...{
                  attributionMethod, data: tierOneSummaryRaw,
                  setCreditRedistributionId, loading, selectedRedistribution,
                  getAndSetRedistributions, attributionOptions, ntfFilter,
                }}
                />
              </div>
            )}
          />
          {excludeRBClassification && (
            <div style={{ paddingRight: 12 }}>
              <DebouncedSearch
                onChange={(evt, { value }) => {
                  setSearch(value.toLowerCase());
                  resetFilters();
                }}
                value={search}
                disabled={filters.tier_1.length > 0}
                resetFilters={resetFilters}
              />
            </div>
          )}
        </>
      )}
      date={
        <DateRangeHooks {...{ startDate, endDate, onDateChange, firstReportingDate, compare, allowExtendedDateRanges }} />
      }
      compareDate={(
        <CompareDateRangePicker {...{
          compareStart,
          compareEnd,
          compareStartDate,
          setCompareStartDate,
          compareEndDate,
          setCompareEndDate,
          compareOptions,
          compareRange,
          setCompareRange,
          numDays,
          onCompareDateChange,
        }}
        />
      )}
      stickyRight={(
        <>
          <SaveReportButton
            {...{ loading }}
            options={{
              'Attribution Model': attributionMethod,
              'Compare Range': compareOptions,
            }}
          />
          {/* TODO: pass in a prop setCompare */}
          <ResetFilterButton setCompare={setCompare} />
        </>
      )}
      advancedFilters={excludeRBClassification && (<RollupFilter rawData={tiersData} />)}
      advancedFiltersOpened={!excludeRBClassification && (
        <>
          <div style={{ paddingRight: 12 }}>
            <DebouncedSearch
              onChange={(evt, { value }) => {
                setSearch(value.toLowerCase());
                resetFilters();
              }}
              value={!!analysisSearch.length ? '' : search}
              disabled={filters.tier_1.length > 0}
              resetFilters={resetFilters}
              label={false}
              placeholder="Search"
            />
          </div>
          <DerivedTiersFilter rawData={rawData} tiersData={tiersData} />
        </>
      )}
    />
  );
};

export default Filter;

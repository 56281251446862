import React from 'react';

import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid } from 'recharts';

import ChartTooltip from '../ChartTooltip';
import { defaultChartDimensions as defaults } from '../constants';
import { formatXAxisTickLabel, toolTipLabelFormatter } from '../helpers';
import { sortData } from '../hooks/sortData';
import { GroupByDropdown } from '../parts';

export const HatchableBar = ({ fill, x, y, width, height, hatched }) => (
  <g>
    {/* Draw the bar with its original fill color */}
    <rect x={x} y={y} width={width} height={height} fill={fill} />
    {/* Conditionally overlay the diagonal hatch pattern if hatched is true */}
    {hatched && <rect x={x} y={y} width={width} height={height} fill="url(#diagonalHatch)" />}
  </g>
);

const StackedBarChart = ({
  data, tierColorMap,
  groupDatesToggle = false, groupDatesBy, setGroupDatesBy,
  defaultGroupBy, // add this prop to sum up data rollups
  height, showTooltip, hideKeys = [], formatter, showXAxis, showTotalInToolTip,
  failureDates, showDataStatus = false, isTestAccount,
}) => {
  const toolTipValueFormatter = formatter?.format ? formatter.format
    : formatter || (v => v);

  const { renderData } = sortData(data, groupDatesBy, defaultGroupBy);
  const isBadDate = date => (date ? failureDates.has(date) : false);

  return (
    <>
      {groupDatesToggle && <GroupByDropdown {...{ groupDatesBy, setGroupDatesBy }} />}
      <ResponsiveContainer width="100%" height={height || defaults.height}>
        <BarChart data={renderData} barCategoryGap="20%">
          <defs>
            <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
              <path d="M 0,0 l 4,4" stroke="black" strokeWidth="1" />
            </pattern>
          </defs>
          <XAxis
            xAxisId="0"
            hide={!showXAxis}
            dataKey="date"
            tickFormatter={(date, i) => formatXAxisTickLabel(date, i, renderData)}
            tickLine={false}
            interval={0}
            height={12}
            padding={{ left: 5, right: 5 }}
          />
          <YAxis
            hide
            domain={[0, dataMax => dataMax]}
            yAxisId="bar"
          />
          {isTestAccount && Object.keys(renderData[0])
            .filter(k => k !== 'date' && !hideKeys.includes(k))
            .map(k => (
              <Bar
                key={k}
                dataKey={k}
                stackId="a"
                yAxisId="bar"
                fill={tierColorMap[k]}
                shape={props => { // eslint-disable-line react/no-unstable-nested-components
                  const { date } = props.payload;
                  const hatched = isBadDate(date);
                  return <HatchableBar {...props} hatched={hatched} />; // eslint-disable-line react/jsx-props-no-spreading
                }}
              />
            ))}
          {!isTestAccount && Object.keys(renderData[0])
            .filter(k => k !== 'date')
            .map(k => (
              <Bar key={k} dataKey={k} stackId="a" fill={tierColorMap[k]} yAxisId="bar" />
            ))}
          {showTooltip
            && (
            <Tooltip
              cursor={false}
              formatter={toolTipValueFormatter}
              labelFormatter={v => toolTipLabelFormatter(v, renderData)}
              content={<ChartTooltip {...{ showTotalInToolTip, hideKeys }} />}
              wrapperStyle={{ zIndex: 3 }}
              showDataStatus={showDataStatus}
              isBadDate={isBadDate}
            />
            )}
          <CartesianGrid horizontal={false} vertical={false} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default StackedBarChart;

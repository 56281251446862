import React, { useEffect, useMemo, useCallback } from "react";
import { ContentCard, IndexGridNew } from "@rockerbox/styleguide";
import {
  datasetAccountingStore,
  warehouseStore,
  syncCardStore
} from "./zustandStore";
import { withRouter } from "react-router-dom";
import { Table, Dropdown, Icon, Card } from "semantic-ui-react";
import { connect } from "react-redux";
import SummarySelector from "../CreativeDataSource/SummarySelector";
import TimeSeriesChart from "./TimeSeriesChart";
import MetaTable from "./MetaTable";
import { SYNC_TAB_STATS, SYNC_TAB_LOGS } from "./constants";

const datasetAccountingSelector = state => [
  state.fetchDatasetMeta,
  state.datasetId,
  state.snapshotId,
  state.setDatasetId,
  state.meta,
  state.snapshotMeta,
  state.fetchSnapshotMeta,
  state.metaAccounting,
  state.syncAccounting
];
const warehouseSelector = state => [state.warehouses, state.activeWarehouseId];
const syncSelector = state => [
  state.syncStatusActiveTab,
  state.setActiveTab,
  state.setCardLoading
];
const LinkButton = ({ item }) => {
  return (
    <Table.Cell>
      <a
        target="_blank"
        href={`http://wq.internal.getrockerbox.com/v2/workqueue_status/job/${item.job_id}`}>
        <Icon name="question circle outline" size="large" />
      </a>
    </Table.Cell>
  );
};

const TitleDropdown = ({ datasets, datasetId, onChangeCallback }) => (
  <Dropdown
    style={{ marginBottom: "15px" }}
    options={datasets}
    value={datasetId}
    selection
    onChange={onChangeCallback}
  />
);

const SyncStatusTable = props => {
  const { platform, datasets: filterDatasets, segment, username } = props;
  const [warehouses, activeWarehouseId] = warehouseStore(warehouseSelector);
  const [syncStatusActiveTab, setActiveTab, setCardLoading] = syncCardStore(
    syncSelector
  );

  const [
    fetchDatasetMeta,
    datasetId,
    snapshotId,
    setDatasetId,
    meta,
    snapshotMeta,
    fetchSnapshotMeta,
    metaAccounting,
    syncAccounting
  ] = datasetAccountingStore(datasetAccountingSelector);

  const datasets = useMemo(() => {
    const targetWarehouse = warehouses.filter(
      warehouse => warehouse.id === activeWarehouseId
    ).length
      ? warehouses.filter(warehouse => warehouse.id === activeWarehouseId)[0]
      : null;
    const datasets =
      targetWarehouse && targetWarehouse["datasets"]
        ? targetWarehouse["datasets"]
            .filter(d => d.filter_id === segment)
            .map(item =>
              Object.assign({}, item, {
                warehouse_table_name: item["warehouse_table_name"] + "_live"
              })
            )
        : [];

    if (datasets && datasets.length && !datasetId) {
      setDatasetId(datasets[0]["id"]);
    }

    return datasets.map(dataset => ({
      key: dataset["id"],
      value: dataset["id"],
      text: `${dataset["warehouse_table_name"]}`
    }));
  }, [warehouses, activeWarehouseId, segment]);

  const onChangeCallback = useCallback((e, data) => {
    setDatasetId(data.value);
  });

  useEffect(() => {
    if (datasetId) {
      setCardLoading(true);
      Promise.all([fetchDatasetMeta(datasetId)]).finally(() =>
        setCardLoading(false)
      );
    }
  }, [datasetId]);

  useEffect(() => {
    if (snapshotId) {
      setCardLoading(true);
      fetchSnapshotMeta(datasetId, snapshotId).finally(() =>
        setCardLoading(false)
      );
    }
  }, [datasetId, snapshotId]);

  const changeStats = useCallback((e, d) => {
    setActiveTab(e);
  });
  const changeLogs = useCallback((e, d) => setActiveTab(SYNC_TAB_LOGS));

  const summaryCardList = [
    {
      value: null,
      label: "Sync Health Stats",
      type: SYNC_TAB_STATS,
      onClick: changeStats,
      text: true
    },
    {
      value: null,
      label: "Sync Log",
      type: SYNC_TAB_LOGS,
      onClick: changeLogs,
      text: true
    },
    {
      value: snapshotId ? snapshotMeta["num_rows"] : meta["num_rows"] || 0,
      label: "Total Rows",
      type: SYNC_TAB_STATS,
      onClick: changeStats
    }
  ];

  const isRBUser = username && username.trim().slice(-14) === "@rockerbox.com";

  const tableCols = [
    { key: "start_date", display: "Start Date" },
    { key: "end_date", display: "End Date" },
    { key: "status", display: "Finish Type" },
    { key: "updated_at", display: "Updated At" }
  ];

  if (isRBUser) {
    tableCols.push({ key: "job_id", display: "Workqueue Job", as: LinkButton });
  }

  return (
    <React.Fragment>
      {snapshotId ? (
        <Card fluid>
          <Card.Content
            header={`Snapshot Name: ${snapshotMeta["snapshot_name"]}`}
          />
          <Card.Content
            description={`Description: ${snapshotMeta["description"]}`}
          />
        </Card>
      ) : (
        <TitleDropdown
          datasetId={datasetId}
          datasets={datasets}
          onChangeCallback={onChangeCallback}
        />
      )}
      <SummarySelector
        datasetList={summaryCardList}
        onClick={changeStats}
        activeView={syncStatusActiveTab}
      />

      {syncStatusActiveTab === SYNC_TAB_STATS ? (
        <ContentCard title="Meta Accounting">
          <TimeSeriesChart data={metaAccounting} />
          <MetaTable meta={metaAccounting} />
        </ContentCard>
      ) : (
        <ContentCard title={`Dataset Sync Accounting`} hasTable>
          <IndexGridNew
            data={syncAccounting}
            cols={tableCols}
            sortable
            sortKey="date"
            paginate
            itemsPerPage={10}
          />
        </ContentCard>
      )}
    </React.Fragment>
  );
};

function mapStateToProps(state, props) {
  const { username } = state.app;

  return { username };
}

export default connect(mapStateToProps)(withRouter(SyncStatusTable));

import React, { Component } from 'react';
import { Icon, Button, Input, Grid, Search, Table, Popup, Dropdown, Loader, Dimmer } from 'semantic-ui-react';
import { PagedIndexGrid, ContentCard, ScrollingPopup } from '@rockerbox/styleguide';
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/tomorrow';

const SearchBox = ({ handleSearchChange, value }) => {
  return (
    <Search
      style={{display: 'inline', marginRight: 15}}
      onSearchChange={e => handleSearchChange(e.target.value)}
      value={value}
      open={false}
    />
  );
};

export const Config = (props) => {
  const { handleTextChange, handleSave, displayName, action, configValue, pixelSourceName, templateOptions, handleTemplateChange, selectedTemplate, templateData } = props;
  return (
    <ContentCard>
      <Grid>
        <Grid.Column width={10}>
          <Grid>
            <Grid.Row style={{paddingBottom: 0}}>
              <Grid.Column width={5} style={{paddingTop: 10, fontWeight: 'bold'}}>
                <span>Display Name:&nbsp;</span>
              </Grid.Column>
              <Grid.Column width={11} style={{paddingLeft: 0}}>
                <Input value={displayName} icon="edit" onChange={(e,data) => handleTextChange(data.value, "displayName")} placeholder="Add Display Name" fluid/>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{paddingBottom: 10}}>
              <Grid.Column width={5} style={{paddingTop: 10, fontWeight: 'bold'}}>
                <span>Webhook URL:&nbsp;</span>
              </Grid.Column>
              <Grid.Column width={11} style={{paddingLeft: 0, marginTop: 10, display: 'flex', justifyContent: 'space-between'}}>
                <WebhookUrl {...{action, pixelSourceName}} />
              </Grid.Column>
            </Grid.Row>
            {templateData && templateData.length > 0 && <Grid.Row>
              <Grid.Column width={5} style={{fontWeight: 'bold'}}>
                <span>Template:&nbsp;</span>
              </Grid.Column>
              <Grid.Column width={11} style={{paddingLeft: 0}}>
                <Dropdown select fluid clearable
                  placeholder="Select a template"
                  options={templateOptions}
                  value={selectedTemplate}
                  onChange={handleTemplateChange}
                />
              </Grid.Column>
            </Grid.Row>}
          </Grid>
        </Grid.Column>
      </Grid>
      <AceEditor
        mode="json"
        theme="tomorrow"
        setOptions={{
          highlightGutterLine: false,
          showPrintMargin: false,
          showGutter: true,
          highlightActiveLine: false,
          fontSize: 12,
          wrapEnabled: true
        }}
        width={'100%'}
        height={'676px'}
        value={configValue}
        onChange={(obj) => handleTextChange(obj,"configValue")}
        style={{marginTop: 15}}
      />
    </ContentCard>
  )
}

const FindCell = ({item, col, handleRowClick}) => {
  return (
    <Table.Cell>
      <Button onClick={() => handleRowClick(item)} content="use" size="tiny"/>
    </Table.Cell>
  )
}

const PreviewCell = ({item, col}) => {
  const jsonPreview = JSON.parse(item.body);
  return (
    <Table.Cell>
      <ScrollingPopup
        trigger={
          <p>
            {item[col.key]}
          </p>
        }
        position="bottom left"
        content={
          <Popup.Content>
            <pre style={{width: 700}}>
              {JSON.stringify(jsonPreview,0,2)}
            </pre>
          </Popup.Content>
        }
        on="click"
        flowing
        maxWidth="400px"
      />
    </Table.Cell>
  )
}

export const EventsTable = (props) => {
  const { filteredData, handleSearchChange, searchVal, handleRowClick, loading } = props;
  const cols = [
    {display: 'raw message ID', key: 'raw_message_id', as: PreviewCell},
    {display: '', as: (props) => <FindCell handleRowClick={handleRowClick} {...props} />}
  ]

  return (
    <React.Fragment>
      <div style={{marginTop: 15}}>
        <h4 style={{display: 'inline'}}>Search:&nbsp;</h4>
        <SearchBox handleSearchChange={handleSearchChange} value={searchVal}/>
      </div>
      {!!loading && 
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      }
      {filteredData.length > 0 &&
        (
          <PagedIndexGrid data={filteredData} cols={cols} itemsPerPage={8} style={{marginTop: 10}}  compact={true}/>
        )
      }
      {filteredData.length == 0 && <p>No data available.</p>}
    </React.Fragment>
  )
}

export const TestEvents = (props) => {
  const { filteredData, eventBody, handleTextChange, handleTest } = props;

  return (
    <React.Fragment>
      {filteredData.length > 0 &&
        (<React.Fragment>
          <div style={{textAlign: 'left'}}>
            <Button
              primary
              compact
              size="small"
              style={{marginTop: 10}}
              onClick={handleTest}
              >
                Test
              </Button>
          </div>
          <AceEditor
            mode="json"
            theme="tomorrow"
            setOptions={{
              highlightGutterLine: false,
              showPrintMargin: false,
              showGutter: true,
              highlightActiveLine: false,
              fontSize: 12,
              wrapEnabled: true
            }}
            width={'100%'}
            height={'582px'}
            value={eventBody}
            onChange={(obj) => handleTextChange(obj,"eventBody")}
            style={{marginTop: 15}}
          />
        </React.Fragment>)
      }
    </React.Fragment>
  )
}

export const Output = ( props ) => {
  const { output } = props;

  return(
    <React.Fragment>
      {output == 'error' && <p>There was an error processing your request.  If this is a message you're trying to capture please update your config.</p>}
      {output !== 'error' && <AceEditor
        mode="json"
        theme="tomorrow"
        setOptions={{
          highlightGutterLine: false,
          showPrintMargin: false,
          showGutter: false,
          highlightActiveLine: false,
          fontSize: 12,
          wrapEnabled: true,
        }}
        readOnly={true}
        width={'100%'}
        height={'620px'}
        value={output}
        style={{marginTop: 15}}
      />}
    </React.Fragment>
  )
}

export class WebhookUrl extends Component {
  handleCopy = (action, pixelSourceName) => {

    const el = document.createElement('textarea');

    el.value = `https://webhooks.getrockerbox.com/${action}?advertiser=${pixelSourceName}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  render(){
    const { action, pixelSourceName } = this.props

    return (
      <>
        <div style={{overflowX: 'auto'}}>https://webhooks.getrockerbox.com/{action}?advertiser={pixelSourceName}</div>
        <Icon name="copy outline" style={{cursor: 'pointer', marginLeft: 5}}onClick={() => this.handleCopy(action, pixelSourceName)}/>
      </>
    )
  }
}

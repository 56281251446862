export function getBatchInboxes() {
  const url = '/batch/inbox';
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getBatchReports() {
  const url = '/batch/report';
  return fetch(url, { credentials: 'include' })
    .then(response => response.json())
    .then(data => {
      const batchData = data.response.map(row => {
        const newRow = { ...row };
        newRow.text = `${row.report_name}`;
        newRow.value = row.id;
        return newRow;
      });
      return batchData;
    });
}

import React, { Component } from "react";
import { ContentCard, ColumnLayout } from "@rockerbox/styleguide";
import { Table } from "semantic-ui-react";
import RunButton from "../parts/RunButton";
import { getOutboxById, getOutboxWithoutDetails } from "../../utils/api";
import { Link } from "react-router-dom";
import { Icon, Dropdown, Message } from "semantic-ui-react";

class OutboxTestCard extends Component {
  state = {
    success: false,
    error: false,
    job_id: null
  };

  updateState(job_id) {
    this.setState({ error: true, job_id });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.id !== prevProps.id) {
      this.setState({
        success: false,
        error: false,
        job_id: null
      });
    }
  }

  render() {
    const { loading, id, outbox_type } = this.props;
    const { error, job_id, success } = this.state;

    const ErrorMessage = error ? (
      <Message
        error
        header="We were unable to upload a test file to your outbox"
        content={`Please check the instructions again and make sure you have everything set up accordingly. If you need any further help, please reach out to your account manager with the following error code: ${job_id}`}
      />
    ) : null;

    const SuccessMessage = success ? (
      <Message
        info
        header="Test file uploaded successfully"
        content="You’re good to go! You can now set up scheduled exports to be sent to your outbox."
      />
    ) : null;

    return (
      <ContentCard title="Test Outbox Setup" loading={loading}>
        <h4>
          1. Follow the setup instructions on the left to configure your [s3
          bucket/gcp bucket/sftp server] to grant Rockerbox access.
        </h4>
        <h4>
          2. Once you have updated your configuration, click the “Test Outbox”
          button below.
        </h4>
        <h4>
          3. The test will upload a “Rockerbox_setup_test.txt” file in your root
          folder. If you can view and access the file, then your outbox is ready
          to go!
        </h4>
        {ErrorMessage}
        {SuccessMessage}
        <RunButton
          jobTypeKey={`test_outbox_credentials${Date.now()}`}
          path="test/report"
          data={{ id, outbox_type }}
          onSuccess={() => this.setState({ success: true })}
          onError={job_id => this.updateState(job_id)}
          content={"Test Outbox"}
          needJobId={true}
        />
      </ContentCard>
    );
  }
}

const GCDetails = props => {
  const {
    config: { bucket_name, root_directory, email },
    outbox_type,
    loading
  } = props;

  return (
    <React.Fragment>
      <ContentCard title="Outbox Details" hasTable loading={loading}>
        <Table definition>
          <Table.Row>
            <Table.Cell>Outbox Type</Table.Cell>
            <Table.Cell>{outbox_type}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Bucket Name</Table.Cell>
            <Table.Cell>{bucket_name}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Root Directory</Table.Cell>
            <Table.Cell>{root_directory}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Google Cloud Service Account</Table.Cell>
            <Table.Cell>{email}</Table.Cell>
          </Table.Row>
        </Table>
      </ContentCard>
    </React.Fragment>
  );
};

const SftpDetails = props => {
  const {
    config: { hostname, root_directory, username },
    outbox_type,
    loading
  } = props;

  return (
    <React.Fragment>
      <ContentCard title="Outbox Details" hasTable loading={loading}>
        <Table definition>
          <Table.Row>
            <Table.Cell>Outbox Type</Table.Cell>
            <Table.Cell>{outbox_type}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Host</Table.Cell>
            <Table.Cell>{props.config.hostname}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Folder</Table.Cell>
            <Table.Cell>{props.config.root_directory}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Username</Table.Cell>
            <Table.Cell>{props.config.username}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Port</Table.Cell>
            <Table.Cell>22</Table.Cell>
          </Table.Row>
        </Table>
      </ContentCard>
    </React.Fragment>
  );
};

const GoogleCloudSetup = props => {
  const {
    config: { bucket_name, root_directory, email },
    outbox_type,
    loading
  } = props;
  return (
    <ColumnLayout
      leftWidth={4}
      rightWidth={5}
      centerWidth={5}
      leftContent={<GCDetails {...props} />}
      rightContent={<OutboxTestCard {...props} />}
    >
      <ContentCard title="Setup Instructions">
        <h4>
          1. Create Google Cloud outbox using button on the left, provide your
          Google Cloud Storage bucket name.
        </h4>
        <h4>
          2. Go to google cloud storage and{" "}
          <a
            href="https://cloud.google.com/storage/docs/access-control/using-iam-permissions"
            target="_blank"
          >
            edit your bucket permissions
          </a>
          . Add rockerbox service account as a member and assign an "Storage
          Object Admin" role to it.
        </h4>
        <p>Supported File types:</p>
        <ul>
          <li>csv</li>
          <li>tsv</li>
          <li>xlsx</li>
        </ul>
      </ContentCard>
    </ColumnLayout>
  );
};

export const S3Setup = props => {
  const {
    outbox_type,
    iam_user,
    config: { bucket_name, root_directory } = {}
  } = props;
  const rockerboxObj = `
  {
    "Statement": {
      "Action": [
        "s3:ListBucket",
        "s3:GetObject",
        "s3:GetObjectAcl",
        "s3:PutObject",
        "s3:PutObjectAcl",
        "s3:DeleteObject",
        "s3:AbortMultipartUpload",
        "s3:ListMultipartUploadParts"
      ],
      "Principal": {
        "AWS": [
          "${iam_user}"
        ]
      },
      "Resource": [
        "arn:aws:s3:::${bucket_name}",
        "arn:aws:s3:::${bucket_name}/*"
      ],
      "Effect": "Allow",
      "Sid": "rockerbox-access"
    }
  }
  `;
  const bucket = `s3://${bucket_name}/`;
  return (
    <ColumnLayout
      leftWidth={4}
      rightWidth={5}
      centerWidth={5}
      leftContent={
        <React.Fragment>
          <ContentCard title="Outbox Details" hasTable>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Outbox Type</Table.Cell>
                  <Table.Cell>{outbox_type}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Bucket Name</Table.Cell>
                  <Table.Cell>{bucket_name}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Root Directory</Table.Cell>
                  <Table.Cell>{root_directory}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Authorized ARN</Table.Cell>
                  <Table.Cell>{iam_user}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </ContentCard>
        </React.Fragment>
      }
      rightContent={<OutboxTestCard {...props} />}
    >
      <ContentCard title="Setup Instructions">
        <h4>
          1. Create S3 outbox using button on the left, provide your AWS S3
          bucket name.
        </h4>
        <h4>
          Please add the following statement to the inline or managed
          permissions policy associated with your bucket. This statement will
          give our user permissions to put files into your bucket:
        </h4>
        <pre>{rockerboxObj}</pre>
      </ContentCard>
    </ColumnLayout>
  );
};

export const SftpSetup = props => {
  const sftpAddress = `  sftp ${props.username}@sftp.getrockerbox.com`;
  const success = `
  Connected to sftp.getrockerbox.com.
  sftp> ls
  /files
  `;
  return (
    <ColumnLayout
      leftWidth={4}
      centerWidth={5}
      rightWidth={5}
      leftContent={<SftpDetails {...props} />}
      rightContent={<OutboxTestCard {...props} />}
    >
      <ContentCard title="Setup Instructions">
        <h4>
          1. Create SFTP Outbox using button on the left, provide username,
          password, hostname and root directory path.
        </h4>

        <h4>2. We will deliver reports to the sftp server root directory.</h4>

        <p>Supported File types:</p>
        <ul>
          <li>csv</li>
          <li>tsv</li>
          <li>xlsx</li>
        </ul>
      </ContentCard>
    </ColumnLayout>
  );
};

class OutboxSetup extends Component {
  state = {
    loading: true,
    error: false,
    outbox: {}
  };

  componentDidMount() {
    const { match: { params: { category, id } = {} } = {} } = this.props;
    getOutboxById(id)
      .then(res => {
        this.setState({ outbox: res[0] || {}, loading: false });
        return getOutboxWithoutDetails();
      })
      .then(outboxes => {
        this.setState({
          outboxes: outboxes.filter(
            outbox =>
              outbox.outbox_type !== "email" &&
              outbox.outbox_type !== "do_spaces"
          )
        });
      });
  }

  render() {
    const {
      loading,
      outbox: { name, outbox_type } = {},
      outboxes
    } = this.state;
    const outboxTypeMapping = {
      amazon_s3: S3Setup,
      ftp: SftpSetup,
      sftp: SftpSetup,
      googlecloud_storage: GoogleCloudSetup
    };
    const SetupComponent =
      outbox_type && outboxTypeMapping[outbox_type]
        ? outboxTypeMapping[outbox_type]
        : React.Fragment;

    const returnButton = (
      <Link to="/v2/settings/destination/view/outbox">
        <Icon name="angle left" />
        Back to Destination
      </Link>
    );

    const DropdownItem = props => {
      const { name, id } = props;
      const handleClick = () => {
        props.onItemClick(id);
      };
      return <Dropdown.Item text={name} onClick={handleClick} />;
    };

    const ContentCardHeader = props => {
      const { outboxes, name } = props;
      if (outboxes && outboxes.length > 0) {
        const options = outboxes.map(outbox => {
          return (
            <DropdownItem
              name={outbox.name}
              id={outbox.id}
              onItemClick={() => {
                this.props.history.push(
                  `/v2/settings/destination/view/outbox/id/${outbox.id}`
                );
                this.setState({
                  outbox
                });
              }}
            />
          );
        });
        return (
          <Dropdown text={`Outbox: ${name}`} inline>
            <Dropdown.Menu>{options}</Dropdown.Menu>
          </Dropdown>
        );
      }

      return `Outbox: ${name}`;
    };

    return (
      <React.Fragment>
        <ContentCard
          title={<ContentCardHeader name={name} outboxes={outboxes} />}
          loading={loading}
          topRight={returnButton}
          disableHeaderEllipse
          noContent
        ></ContentCard>
        <SetupComponent {...this.state.outbox} loading={loading} />
      </React.Fragment>
    );
  }
}
export default OutboxSetup;

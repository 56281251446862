import React from 'react';
import { Header, Segment } from 'semantic-ui-react';

export const SplitLayout = ({ header, leftContent, rightContent }) => (
  <>
    <h3>{header}</h3>
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 2 }}>{leftContent}</div>
      <div style={{ flex: 5 }}>{rightContent}</div>
    </div>
  </>
);

export const FormWrapSegment = ({ children }) => (
  <Segment style={{ marginTop: '1rem', paddingTop: 0, paddingBottom: '1rem' }}>
    <Segment style={{ background: '#f0f3ff', margin: '-1rem' }}>{children}</Segment>
  </Segment>
);

export const FormDescription = ({ children }) => (
  <Header as="h4" style={{ margin: 10, marginTop: 0, fontWeight: 400 }}>
    {children}
  </Header>
);

import { colorHash } from '../../../utils/colorHash';
/**
 * Function to inverse the values in the given chart data.
 *
 * @param {Array} data - The chart data with an x axis of date to be inverted.
 * @returns {Array} - The inverted chart data.
 */
export const inverseChartData = data => data.map(x => {
  const keys = Object.keys(x).filter(y => y !== 'date');
  const inverse = { ...x };
  keys.forEach(k => {
    inverse[k] = 1 / x[k];
  });
  return inverse;
});

/**
 * Function: changeDisplayName
 *
 * Description: This function takes in an array of data and a mapping object, and returns a new array of data with the display names changed according to the mapping.
 *
 * Parameters:
 * - data (Array): An array of data objects.
 * - mapping (Object): An object that maps the current display names to the desired display names.
 *
 * Returns:
 * - result (Array): A new array of data objects with the display names changed according to the mapping.
 */
export const changeDisplayName = (data, mapping) => data.map(row => {
  const keys = Object.keys(row);
  return keys.reduce((accu, curr) => {
    if (mapping[curr]) {
      accu[mapping[curr]] = row[curr];
      return accu;
    }
    accu[curr] = row[curr];
    return accu;
  }, {});
});

/**
 * Creates a tier color map based on the given data, tier colors, and feature name map.
 *
 * @param {Array} data - The data to create the tier color map from.
 * @param {Object} tierColors - The tier colors object.
 * @param {Object} featureNameMap - The feature name map object.
 * @returns {Object} The tier color map object.
 */
export const createTierColorMap = (data, tierColors, featureNameMap) => data.reduce((accu, curr) => {
  const { channel } = curr;
  const name = featureNameMap[channel] || channel;
  accu[name] = tierColors[channel] || colorHash(channel);
  accu.color = curr[channel];
  return accu;
}, {});

export const convertToX = value => `${Math.ceil((Math.abs(value * 100))) / 100}`;
export const convertToPercent = value => `${Math.ceil((Math.abs(value * 100)))}`;

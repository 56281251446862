import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import { UserCircleIcon, UserGroupIcon, GlobeAltIcon, ColorSwatchIcon, CreditCardIcon, FilterIcon, MailIcon } from '@heroicons/react/solid';

import Billing from './Billing';
import ConversionFunnelConfig from './ConversionFunnelConfig';
import Domains from './Domains';
import EmailSubscriptions from './EmailSubscriptions';
import ManageUsers from './ManageUsers';
import MyAccount from './MyAccount';
import TierColors from './TierColors';
import * as routes from '../../baseRoutes';

export const SETTINGS_MENU = [
  {
    name: 'My Account',
    icon: <UserCircleIcon />,
    route: routes.myAccount,
    component: MyAccount,
  },
  {
    name: 'Subscriptions',
    icon: <MailIcon />,
    route: routes.emailSubscriptions,
    component: EmailSubscriptions,
  },
  {
    name: 'Team Users',
    icon: <UserGroupIcon />,
    route: routes.manageUsers,
    component: ManageUsers,
  },
  {
    name: 'Domains',
    icon: <GlobeAltIcon />,
    route: routes.domains,
    component: Domains,
  },
  {
    name: 'Colors',
    icon: <ColorSwatchIcon />,
    route: routes.colors,
    component: TierColors,
  },
  {
    name: 'Billing',
    icon: <CreditCardIcon />,
    route: routes.billing,
    component: Billing,
    lockedTiers: ['lite', 'scale'],
  },
  {
    name: 'Conversion Funnel',
    icon: <FilterIcon />,
    route: routes.conversionFunnelConfig,
    component: ConversionFunnelConfig,
  },
];

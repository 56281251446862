import moment from 'moment';

// >>>>>> CONSTANTS <<<<<<<<
export const PRESET_PROJECTION_RANGES = [
  { text: 'Daily', numDays: 1 },
  { text: 'Weekly', numDays: 7 },
  { text: 'Monthly', numDays: 30 },
  { text: 'Quarterly', numDays: 90 },
];

// >>>>>> DATES <<<<<<<<<
export const today = moment.utc().utcOffset(-5).format('YYYY-MM-DD');
export const tomorrow = moment.utc().utcOffset(-5).add(1, 'days').format('YYYY-MM-DD');
export const weekFromToday = moment.utc().utcOffset(-5).add(7, 'days').format('YYYY-MM-DD');
export const monthFromToday = moment.utc().utcOffset(-5).add(31, 'days').format('YYYY-MM-DD');
export const quarterFromToday = moment.utc().utcOffset(-5).add(90, 'days').format('YYYY-MM-DD');

// // >>>>>>> REFORMAT <<<<<<<<<<
export const reformatDateMMDDYYYY = date => moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
export const reformatDateYYYYMMDD = date => moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD');

// >>>>>>> HELPERS <<<<<<<<<
export const isAfterTomorrow = date => moment(date).isAfter(today);
export const daysAfter = num => moment.utc().utcOffset(-5).add(num + 1, 'days').format('YYYY-MM-DD');
export const calculateDifferenceInDays = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, 'days');
};
export const numDaysLater = (startDate = tomorrow, endDate = monthFromToday, preset = false) => {
  const diff = calculateDifferenceInDays(startDate, endDate);
  const preset_ranges = [1, 7, 14, 21, 30, 90];

  if (preset) {
    if (startDate === tomorrow && preset_ranges.includes(diff)) return diff;
    if (endDate !== tomorrow || preset_ranges.includes(diff)) return 'custom';
  }
  return diff;
};
export const getPeriodName = (numDays, startDate, endDate) => {
  const isStartDateTomorrow = startDate === tomorrow;
  if (isStartDateTomorrow && numDays === 1) return 'Daily';
  if (isStartDateTomorrow && numDays === 7) return 'Weekly';
  if (isStartDateTomorrow && numDays === 30) return 'Monthly';
  if (isStartDateTomorrow && numDays === 90) return 'Quarterly';
  return `${reformatDateMMDDYYYY(startDate)} to ${reformatDateMMDDYYYY(endDate)}`;
};
export const dateIsBetween = (date, startDate, endDate) => moment(date).isBetween(moment(startDate), moment(endDate), undefined, '[]');

export const generateDateRangesByType = (dates, type = 'month') => {
  const ranges = [];
  dates.sort((a, b) => moment(a) - moment(b));

  for (let i = 0; i < dates.length; i++) {
    const current = moment(dates[i]);
    const text = type === 'quarter' ? `${current.format('YYYY')} Q${current.quarter()}` : current.format('MMMM YYYY');
    if (ranges.some(range => range.text === text)) {
      // eslint-disable-next-line no-continue
      continue;
    }
    ranges.push({
      text,
      startDate: current.startOf(type).format('YYYY-MM-DD'),
      endDate: current.endOf(type).format('YYYY-MM-DD'),
      type,
    });
  }
  return ranges;
};

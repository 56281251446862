import React from 'react';

import ContentLoader from 'react-content-loader';

const AreaChartLoader = ({ style }) => (
  <ContentLoader
    viewBox="0 0 1216 200"
    preserveAspectRatio="none"
    foregroundColor="#e5e5e5"
    height={200}
    width="100%"
    {...{ style }}
  >
    <path d="M1211.4,24.5V195c-13.9,0-27.7,0-41.6,0s-27.7,0-41.6,0s-27.7,0-41.6,0s-27.7,0-41.6,0s-27.7,0-41.6,0s-27.7,0-41.6,0
      c-13.9,0-27.7,0-41.6,0s-27.7,0-41.6,0s-27.7,0-41.6,0s-27.7,0-41.6,0s-27.7,0-41.6,0c-13.9,0-27.7,0-41.6,0s-27.7,0-41.6,0
      c-13.9,0-27.7,0-41.6,0s-27.7,0-41.6,0s-27.7,0-41.6,0c-13.9,0-27.7,0-41.6,0s-27.7,0-41.6,0s-27.7,0-41.6,0s-27.7,0-41.6,0
      s-27.7,0-41.6,0s-27.7,0-41.6,0s-27.7,0-41.6,0s-27.7,0-41.6,0c-13.9,0-27.7,0-41.6,0c-13.9,0-27.7,0-41.6,0s-27.7,0-41.6,0
      s-27.7,0-41.6,0s-27.7,0-41.6,0L5,84.8c180.3,51.5,186.4-14.8,281.6-9.9c248.1,12.8,212.8-44.7,439.5-24.4
      C944.5,70,977.5,19.9,1037.1,14.1C1050.5,12.8,1144,0,1211.4,24.5L1211.4,24.5z"
    />
  </ContentLoader>
);

export default AreaChartLoader;

import React from "react";
import CrossDeviceIndex from "../CrossDevice/index";
import { SplitLayout } from "@rockerbox/styleguide";
import GenericMessage from "../GenericMessage";
import OttAnalysis from "./OttAnalysis";

const Setup = (props) => {
  const MainContent = (
    <React.Fragment>
      <CrossDeviceIndex />
      <OttAnalysis {...props} />
    </React.Fragment>
  );
  return (
    <SplitLayout
      leftWidth={13}
      rightWidth={3}
      leftContent={MainContent}
      rightContent={<GenericMessage message={"ott_index"} />}></SplitLayout>
  );
};

export default Setup;

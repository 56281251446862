import * as d3 from 'rockerbox_d3_legacy_clone';

import IndexGrid from '../IndexGrid';

const removeNull = obj => Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null && v !== ''));

export const buildColumnOptions = (data, skipColumns, calculatedColumns = {}) => {
  const example = (data || []).reduce((p, c) => Object.assign(p, removeNull(c)), {});
  return [...Object.keys(example), ...Object.keys(calculatedColumns)]
    .filter(key => !skipColumns.includes(key))
    .map(key => {
      const type = key.includes('date') ? 'datetime' : example[key] || example[key] === 0 ? typeof (example[key]) : 'value';
      return { key, type, value: key, text: key };
    });
};

const processHeader = (obj, depth, resultObj) => {
  resultObj[depth] = resultObj[depth] || []; // eslint-disable-line no-param-reassign
  return Object.entries(obj).reduce((p, [k, v]) => {
    const multiplier = (Object.keys(v).length) ? processHeader(v, depth + 1, resultObj) : 1;
    resultObj[depth].push({ display: k, key: k, colSpan: multiplier });
    return p + multiplier;
  }, 0) || (depth === 0 ? resultObj : 1);
};

export const parseHeaderRowsFromColumnSet = columnSet => {
  const headerStructure = Array.from(columnSet)
    .sort(d3.ascending)
    .reduce((p, col) => {
      col.split('|').reduce((accu, r) => {
        accu[r] = accu[r] || {};
        return accu[r];
      }, p);
      return p;
    }, {});

  const tmp = {};
  processHeader(headerStructure, 0, tmp);
  return Object.entries(tmp).map(([, v]) => v).slice(0, -1);
};

const parseDisplay = col => col.display.split('|').slice(-1)[0];

export const buildGridColumns = (rows, columnSet, valueFormatter, defaultFormatTypeAs, singleValue, showTotal, columnNameFormatter, summary, tooltips, columnCells = {}) => {
  const headerRows = parseHeaderRowsFromColumnSet(columnSet);
  const colToValuePos = Array.from(columnSet)
    .reduce((accu, c) => {
      const split = c.split('|');
      accu[c] = split.slice(0, -1).join('|') + (summary || []).indexOf(split.slice(-1)[0]);
      return accu;
    }, {});
  const sortedColumnSet = Array.from(columnSet).sort((p, c) => d3.ascending(colToValuePos[p], colToValuePos[c]));

  const rowCols = IndexGrid.arrayToColumns(rows)
    .map(row => {
      const as = columnCells[row.key];
      return Object.assign(row, { as, display: columnNameFormatter(row.display) });
    });

  const valueCols = IndexGrid.arrayToColumns(sortedColumnSet)
    .map((col, i) => {
      const display = columnNameFormatter(singleValue ? headerRows[headerRows.length - 1][i].display : parseDisplay(col));

      return Object.assign(col, { display });
    });

  valueCols.map(c => {
    const copy = { ...c };
    if (tooltips[copy.display]) {
      copy.tooltip = tooltips[copy.display];
    }
    return copy;
  });

  const totalCols = showTotal ? IndexGrid.arrayToColumns(['value'])
    .map(row => Object.assign(row, { display: 'Total', as: defaultFormatTypeAs.sum })) : [];

  if (valueFormatter) valueCols.map(row => Object.assign(row, { as: valueFormatter(row.key) }));

  const gridColumns = [...rowCols, ...valueCols, ...totalCols];

  return { gridColumns, headerRows: singleValue ? headerRows.slice(0, -1) : headerRows };
};

export const getColumnsByType = (columns, formatterFuncs, columnNameFormatter, skipFormatter) => {
  const textCols = columns.filter(row => row.type !== 'number' && row.type !== 'value')
    .map(row => Object.assign(row, { text: columnNameFormatter(row.text) }));

  const numberCols = columns.filter(row => row.type === 'number')
    .map(row => Object.assign(row, { text: columnNameFormatter(row.text) }));

  const valueCols = [...numberCols.reduce((p, c) => {
    const { number } = formatterFuncs;
    const items = Object.keys(number)
      .filter(key => !skipFormatter.includes(key))
      .map(key => {
        const value = `${c.value}:${key}`;
        const text = columnNameFormatter(value);
        return { ...c, value, text };
      });

    return [...p, ...items];
  }, []),
    // ...columns.filter(row => row.type == "value")
    // .map(row => Object.assign(row, {text: columnNameFormatter(row.text)}))

  ];

  return { textCols, numberCols, valueCols };
};

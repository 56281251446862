import React, { useState, useEffect, useMemo } from 'react';
import { Segment, Message, Icon, Button } from 'semantic-ui-react';
import { DataTable } from '@rockerbox/styleguide';
import { DateRangeByType } from '../../../components';

const ButtonSmallBlue = ({ disabled, onClick, content }) => (
  <Button {...{ disabled, onClick, content }} inline color="blue" size="small" />
);

const BulkSpendByDate = ({
  data, rangeType, columns, issues,
  addRow, onUpdate, onRemove, updateAllNames, onRemoveAndCopy,
}) => {
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [filterType, setFilterType] = useState(false);

  const hasIssues = !(issues && issues.length);

  const onDateUpdate = (_, { startDate: startDateUpdate, endDate: endDateUpdate }) => {
    setStartDate(startDateUpdate);
    setEndDate(endDateUpdate);
  };

  const onDataUpdate = dataUpdate => {
    if (startDate && endDate) {
      dataUpdate.map(row => Object.assign(row, { startDate, endDate }));
      onUpdate(dataUpdate);
    }
  };

  const filteredData = useMemo(() => {
    if (filterType === 'not approved') return data.filter(row => row.state !== 'Approved');
    if (filterType === 'approved') return data.filter(row => row.state === 'Approved');
    return data;
  }, [filterType, data]);

  useEffect(() => {
    if (filteredData.length === 0) setFilterType(false);
  }, [filteredData]);

  return (
    <div>
      <Segment>
        <h5>
          {`Choose the ${rangeType} against which you want to add spend for multiple sponsorships.`}
        </h5>
        <DateRangeByType {...{ rangeType, onChange: onDateUpdate }} />
      </Segment>
      {startDate && endDate && (
        <Segment>
          <h5>
            Add the Sponsorship name and the cost you want to assign for the above date.
          </h5>
          <DataTable
            {...{
              columns: columns.filter(c => ['state', 'entity_name', 'cost'].includes(c.key)),
              data,
              onUpdate: onDataUpdate,
              onRemove,
              rowProps: { updateAllNames },
            }}
          />
          <br />
          <Button size="mini" icon="plus" onClick={addRow} />
          <br />
        </Segment>
      )}
      {issues.length > 0 && (
        <Segment>
          <Message
            content={(
              <>
                <Icon name="warning" color="orange" />
                Issues have been detected with the campaigns entered above. You must resolve all issues before uploading.
              </>
            )}
            negative
          />
          <div>
            <Button.Group size="small">
              <Button active={filterType === 'not approved'} onClick={() => setFilterType('not approved')}>
                Show Issues (
                {issues.length}
                )
              </Button>
            </Button.Group>
            &nbsp; &nbsp; &nbsp;
            {filterType === 'not approved' && (
              <>
                <ButtonSmallBlue
                  disabled={hasIssues}
                  onClick={() => onRemoveAndCopy(false)}
                  content="Remove issues"
                />
                <ButtonSmallBlue
                  disabled={hasIssues}
                  onClick={() => onRemoveAndCopy(true)}
                  content="Remove + copy"
                />
                <ButtonSmallBlue
                  disabled={hasIssues}
                  onClick={() => onRemoveAndCopy(true, 'Missing Entity')}
                  content="Remove missing entities + copy"
                />
              </>
            )}
            <br />
            <br />
            {filterType === 'not approved' && (
              <DataTable
                {...{
                  columns: columns.filter(c => ['state', 'entity_name', 'cost'].includes(c.key)),
                  data: filteredData,
                  onUpdate,
                  onRemove,
                  rowProps: { updateAllNames },
                }}
              />
            )}
          </div>
        </Segment>
      )}
    </div>
  );
};

export default BulkSpendByDate;

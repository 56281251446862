import React, { useMemo } from 'react';

import { PropTypes } from 'prop-types';
import { ResponsiveContainer, AreaChart, XAxis, YAxis, Area, Tooltip, ReferenceLine, Label } from 'recharts';

import ChartTooltip from '../ChartTooltip';
import { toolTipLabelFormatter, findColor } from '../helpers';

const renderCustomizedLabel = ({ viewBox, heading, subHeading1, subHeading2 }) => {
  const { x, y, width } = viewBox;
  return (
    <g>
      <foreignObject x={x} y={y} width={width} height={500}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          width: '100%',
          padding: '0 25px',
          justifyContent: 'center',
          textAlign: 'left',
        }}
        >
          <div>{heading}</div>
          <div>{subHeading1}</div>
          {!!subHeading2 && <div>{subHeading2}</div>}
        </div>
      </foreignObject>
    </g>
  );
};

const FunnelAreaChart = ({
  data, tierColorMap,
  XAxisKey, width = '100%', height = 300, showXAxis, strokeWidth = 2,
  fillOpacity = 0.9,
  hideKeys, formatter, showTooltip, showTotalInToolTip, reverseTooltipOrder,
  summaryContent,
}) => {
  const keysToRender = useMemo(() => {
    if (!data.length) return [];

    return Object.keys(data[0] || {})
      .filter(k => k !== 'event')
      .sort((a, b) => data[0][a] - data[0][b]);
  }, [data]);

  const xAxisKeys = useMemo(() => data.map(point => point[XAxisKey]), [data]);

  const domainMax = useMemo(() => {
    if (!summaryContent) return false;

    const dataReduced = data.map(obj => {
      const sum = Object.keys(obj)
        .filter(key => key !== XAxisKey)
        .reduce((acc, key) => acc + (obj[key] === Infinity ? 0 : obj[key]), 0);
      return sum;
    });
    const dataMax = dataReduced.length ? Math.max(...dataReduced) : 0;

    return dataMax * 1.5;
  }, [data, summaryContent]);

  const defaultFormatter = v => v.toLocaleString('en-US', { maximumFractionDigits: 0 });
  const tooltipValueFormatter = formatter || defaultFormatter;

  return (
    <ResponsiveContainer width={width} height={height}>
      <AreaChart data={data}>
        <XAxis
          hide={!showXAxis}
          dataKey={XAxisKey}
          tickLine={false}
          interval={0}
          height={12}
        />
        <YAxis
          hide
          yAxisId="Left"
          domain={[0, domainMax || 'dataMax']}
        />
        {xAxisKeys.map(x => (
          <ReferenceLine
            x={x}
            stroke="#F0F0F0"
            strokeWidth={3}
            yAxisId="Left"
            isFront={true}
          />
        ))}
        {summaryContent && summaryContent.length && xAxisKeys.map((x, i) => {
          if (i === xAxisKeys.length - 1) return null;
          const segment = [
            { x, y: domainMax },
            { x: xAxisKeys[i + 1], y: domainMax },
          ];
          const summary = summaryContent.find(c => c[XAxisKey] === x);
          const { heading, subHeading1, subHeading2 } = summary;

          return (
            <ReferenceLine
              stroke="white"
              segment={segment}
              yAxisId="Left"
            >
              <Label
                content={renderCustomizedLabel}
                {...{ heading, subHeading1, subHeading2 }}
              />
            </ReferenceLine>
          );
        })}

        {keysToRender.map(k => {
          const color = findColor(k, [], tierColorMap);
          return (
            <Area
              key={`area_${k}`}
              yAxisId="Left"
              dataKey={k}
              stackId="1"
              type="linear"
              stroke={color}
              fillOpacity={fillOpacity}
              fill={color}
              strokeWidth={strokeWidth}
            />
          );
        })}
        {showTooltip
            && (
            <Tooltip
              cursor={false}
              formatter={tooltipValueFormatter}
              labelFormatter={v => toolTipLabelFormatter(v, data)}
              content={(
                <ChartTooltip
                  {...{
                    showTotalInToolTip,
                    hideKeys,
                  }}
                  reverseOrder={reverseTooltipOrder}
                />
              )}
              wrapperStyle={{ zIndex: 3 }}
            />
            )}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default FunnelAreaChart;

FunnelAreaChart.propTypes = {
  as: PropTypes.element,
  data: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line react/forbid-prop-types
  XAxisKey: PropTypes.string.isRequired,
};

import React, { Component } from 'react';
import { ContentCard, PagedIndexGrid } from '@rockerbox/styleguide';
import { Input, Button, Message } from 'semantic-ui-react';
import { getExcludedDomains, postExcludedDomains } from '../../utils/api';

class ExcludedDomains extends Component {
  state = {
    domain: '',
    loading: false,
    error: false,
    domains: []
  }

  componentDidMount() {
    getExcludedDomains().then(data => this.setState({domains: data}));
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.domains !== prevState.domains) {
      this.setState({domains: this.state.domains})
    }
  }

  handleChange = (event, data) => {
    const { value } = data;
    this.setState({ domain: value})
  }

  handleSubmit = () => {
    const postObj = {
      domain: this.state.domain.trim(),
    };

    this.setState({loading: true})
    postExcludedDomains(postObj)
      .then(response => {
        this.setState({loading: false, domain: '', error: false })
        getExcludedDomains().then(data => this.setState({domains: data}))
      })
      .catch(error => {
        this.setState({error: true, loading: false })
      })

  }

  render() {
    const { domain, loading, error, domains } = this.state;
    const allowAdd = domains.length < 10
    const cols = [
      {display: 'Domains', key: 'domain'},
    ];

    return (
      <ContentCard title="Excluded Domains">
        {allowAdd &&
          <div style={{marginBottom: 14}}>
            <p>Add up to 10 domain exclusions</p>
            <Input
              placeholder="yourDomain.com"
              value={domain}
              action
              onChange={this.handleChange}
              style={{width: 300}}
            >
              <input />
              <Button
                primary
                type="submit"
                onClick={this.handleSubmit}
                disabled={domain === ''}
                loading={loading}
              >Add</Button>
            </Input>
            { error && (
              <Message negative>
                <p>This domain may have already been added</p>
              </Message>
            )}
          </div>
        }
        {!allowAdd &&
          <div>
            <Message negative>
              <p>Maximum number of excluded domains reached</p>
            </Message>
          </div>
        }
        <PagedIndexGrid data={domains} cols={cols} itemsPerPage={10} collapsed/>
      </ContentCard>
    )
  }
}

export default ExcludedDomains;

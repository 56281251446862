import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import * as routes from "../../routes";

export const AddColumnButton = ({ setNewColumn }) => (
  <Button
    color="blue"
    content="Add Column"
    icon="plus"
    labelPosition="left"
    onClick={() => setNewColumn({})}></Button>
);

export const SaveSchemaButton = ({ appliedData, sendUpdateSavedReport }) => (
  <Button
    color="green"
    icon="save"
    labelPosition="left"
    floated="right"
    content="Save Schema"
    disabled={appliedData && appliedData.length === 0}
    onClick={() => sendUpdateSavedReport()}
  />
);

export const ApplySchemaButton = ({
  sendReportTransformRequest,
  setHasError,
}) => (
  <Button
    primary
    size="tiny"
    content="Apply Custom Schemas"
    onClick={() => {
      sendReportTransformRequest();
      setHasError(null);
    }}></Button>
);

export const BackButton = (props) => (
  <Link to={routes.reports}>
    <Icon name="angle left" />
    Back to Exports
  </Link>
);

export const RemoveRowButton = ({ removeSchemaByOrder, schema }) => (
  <Button
    icon="times"
    color="red"
    onClick={() => removeSchemaByOrder(schema.order)}
  />
);

// index.js
export const arrayToColumns = arr => arr.map(key => ({ key, display: key }));

// IndexGrid.js
export const columnsToOptions = arr => arr.map(({ key }) => ({ key, value: key, text: key }));

export const pagerLength = (rows, itemsPerPage) => {
  const numberOfPagers = Math.ceil(rows.length / itemsPerPage);

  return numberOfPagers;
};

export const isFalsy = obj => !obj || Object.getOwnPropertyNames(obj).length === 0;

import React, { useState, useEffect } from 'react';
import {
  Container,
  Header,
  Card,
  Image,
  Loader,
} from 'semantic-ui-react';
import { dataWarehouseHeroBanner } from '@rockerbox/styleguide';
import { BasicViewHeader, ViewAccessWrapper } from '../../components';

import { getAccount, getAllIntegrations } from '../../api/account';
import { getArtifacts } from '../../api/attribution';
import { generateWarehouseCardList } from './utils';

import { DatalakeProvider } from './DatalakeContext/context';
import { useDatalake } from './DatalakeContext/hooks';

import { WarehouseCard, GCPDataUnavailable } from './components';
import ReportSync from './ReportSync/ReportSync';

const DataWarehouse = () => {
  const [integrations, setIntegrations] = useState([]);
  const [artifacts, setArtifacts] = useState([]);
  const [accountInfo, setAccountInfo] = useState({});

  const { connectionLoaded, datalakeConnection } = useDatalake();

  useEffect(() => {
    getArtifacts().then(setArtifacts);
    getAllIntegrations().then(setIntegrations);
    getAccount()
      .then(data => {
        setAccountInfo(data);
      });
  }, []);

  const gcpUnavailable = (datalakeConnection?.platform === 'gcp') && (!accountInfo?.features?.includes('data_warehouse_gcp_enabled'));
  const cardsList = generateWarehouseCardList(artifacts, integrations, accountInfo);

  if (!datalakeConnection?.active) {
    return (
      <>
        <BasicViewHeader
          header="Data Warehouse Sync"
          subheader="Sync log-level and aggregate data to your warehouse"
        />
        {!connectionLoaded
          ? (
            <Loader
              active
              inline
              style={{
                display: 'flex',
                width: '100%',
                margin: '150px 0',
              }}
            />
          )
          : (
            <Container style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
              <Image src={dataWarehouseHeroBanner} size="big" centered style={{ marginTop: 50 }} />
              <Container textAlign="center">
                <Header
                  as="h1"
                  content="Connect your Data Warehouse"
                  subheader="Select your provider below to get started"
                  style={{ lineHeight: '2em' }}
                />
              </Container>
              <Card.Group centered>
                {cardsList.map(WarehouseCard)}
              </Card.Group>
            </Container>
          )}
      </>
    );
  }

  if (gcpUnavailable) {
    return (
      <>
        <BasicViewHeader
          subheader="Data Exports"
          header="Data Warehousing"
        />
        <GCPDataUnavailable />
      </>
    );
  }

  return <ReportSync />;
};

const DataWarehouseApp = () => (
  <DatalakeProvider>
    <DataWarehouse />
  </DatalakeProvider>
);

const DataWarehouseAppWrapper = () => (
  <ViewAccessWrapper
    viewName="data_warehouse"
    viewComponent={<DataWarehouseApp />}
  />
);

export default DataWarehouseAppWrapper;

import styled from 'styled-components';

const GrayLabel = styled.div`
color: rgba(0, 0, 0, 0.4);
font-weight: 700;
font-family: 'Lato', 'Helvetica Neue', Arial;
font-size: 12px;
`;

export default GrayLabel;

import React, { useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { Input, Message } from 'semantic-ui-react';
import { useStepsDisplay, useStepsState } from '../StepsSectionContext';
import Button from '../../../../components/parts/Button';

const SetupClientShare = () => {
  // eslint-disable-next-line no-unused-vars
  const [stateData, setStateData] = useStepsState();
  // eslint-disable-next-line no-unused-vars
  const [stepsData, setStepsData] = useStepsDisplay();
  const [currentCopiedText, setCurrentCopiedText] = useState('');
  const onCopy = text => {
    setCurrentCopiedText(text);
    navigator.clipboard.writeText(text);
  };
  // eslint-disable-next-line react/no-unstable-nested-components
  const CopyBlock = props => (
    <div>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      <Button primary disabled={stepsData['1'] === false} style={{ width: '157px' }} onClick={() => props.onCopyCallback(props.text)}>
        { currentCopiedText === '' ? 'Copy To Clipboard' : 'Copied!' }
      </Button>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      <Message size="small" content={props.text} />
    </div>
  );
  const renderDatabaseCreateStatment = () => {
    const text = Object.keys(stateData).length > 0
      ? `CREATE DATABASE rockerbox FROM SHARE IDENTIFIER('${stateData.details.rockerbox_account_id.toUpperCase()}."${stateData.details.share.toUpperCase()}"')`
      : 'CREATE DATABASE rockerbox FROM SHARE IDENTIFIER(\'rbx_account."share_name"\')';
    return <CopyBlock text={text} onCopyCallback={onCopy} />;
  };
  return (
    <div>
      <div>
        {renderDatabaseCreateStatment()}
      </div>
    </div>
  );
};
export default SetupClientShare;

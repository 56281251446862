import React, { useEffect } from 'react'
import { Segment, Button, Grid, Icon, Input, Form, Header } from 'semantic-ui-react';

import { STARTER_ADVERTISING_PLATFORMS } from '../helpers/constants';

const Item = ({ platform, integrations, authorizations }) => {
  const integration = integrations.find(x => x.platform == platform);
  const platformAuthorizations = authorizations.find(x => x.platform == platform)?.authorizations || [];
  const activeAuths = platformAuthorizations.filter(x => x.account_id != '');

  return (
    <div className='item'>
        <div className='connect-row'>
          <img
            src={`https://images.rockerbox.com/integrations/${integration.logo_img}`}
            className='logo'
          />
          <Button primary onClick={() => window.location = `${integration.path}`}>Connect</Button>
        </div>
        {
          activeAuths.length > 0 &&
          <div>
            <hr className='divider' />
            <div className='accounts-list'>
              {
                activeAuths.map(a => {
                  return (
                  <div className='auth-account'>
                    <Icon circular inverted color='green' name='check' size='tiny' />
                    <span className='text'>{a.account_name} <span style={{color: '#bbbbc4'}}>({a.account_id})</span></span>
                  </div>
                  )
                })
              }
            </div>
          </div>
        }
    </div>
  )
}


export const PlatformsList = ({ integrations, authorizations, tier }) => {
  if (integrations.length < 1) return null;
  const available_platforms = tier == 'starter' ? STARTER_ADVERTISING_PLATFORMS : [...STARTER_ADVERTISING_PLATFORMS, 'adroll'];

  return (
    <div>
      <div className='platforms-list'>
        {available_platforms.map(i => {
          return <Item platform={i} {...{ integrations, authorizations }} />
        })}
      </div>
    </div>
  )
}

import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';

import { UnavailableContent, UpgradeContent } from './parts';
import { useGlobalState } from '../../hooks/global';

const LockedModal = ({ open, onClose, item }) => {
  const { state, user } = useGlobalState();
  const { account } = state;
  const { tier, is_shopify_hosted } = account || {};
  const { username } = user;

  const { name, icon, section, route, locked } = item || {};
  const requiresUpgrade = !!locked;

  const [error, setError] = useState(false);
  const [jobRunning, setJobRunning] = useState(false);

  const closeModal = () => {
    // prevent modal close if job is running
    if (!jobRunning) onClose();
  };

  const startIntercomChat = jobId => {
    const chatMessage = `Help! There was an error setting up my account. Error Code: job ID generated from signup ${jobId}`;
    if (window.Intercom) window.Intercom('showNewMessage', chatMessage);
  };

  return (
    <Modal
      {...{ open, onClose: closeModal }}
      closeIcon={!jobRunning}
      size="tiny"
      dimmer="inverted"
      className="modal-v3"
    >
      <Modal.Header>
        <div className={`icon ${section}`}>{icon}</div>
        <div className="title">
          {`${requiresUpgrade ? 'Upgrade to access ' : ''}${name}`}
        </div>
      </Modal.Header>
      {requiresUpgrade
        ? <UpgradeContent {...{ username, tier, is_shopify_hosted, error, setError, startIntercomChat, jobRunning, setJobRunning }} />
        : <UnavailableContent {...{ route }} />}
    </Modal>
  );
};

export default LockedModal;

import React, { useEffect, useState } from 'react';
import { ContentCard, IndexGrid } from '@rockerbox/styleguide';
import { Table, Button } from 'semantic-ui-react';
import { getAggregationGroupings, updateAggregationgroupings, postAggregationGroupings } from '../../utils/api';
import AggregationsModal from './AggregationsModal';


const Aggregations = () => {
  const [data, setData] = useState([]);
  const [modifiedData, setModifiedData] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);
  const [selected, setSelected] = useState({});
  const [stateNumber, setStateNumber] = useState(1);

  useEffect(() => {
    getAggregationGroupings()
      .then(data => {
        const modifiedData = data.map(c => {
          const groups = c.group_by;
          return {...groups, suffix: c.suffix, id: c.id }
        })
        setModifiedData(modifiedData);
        setData(data);
      })
  }, [stateNumber]);

  const handleSubmit = (postObj) => {
    const send = selected.id ? updateAggregationgroupings : postAggregationGroupings;

    send(postObj, selected.id)
    .then(data => {
      setStateNumber(stateNumber+1)
      handleModalClose();
    })
  }

  const handleModal = (item) => {
    setSelected(item);
    setModalStatus(true);
  }

  const handleModalClose = () => {
    setModalStatus(false);
    setSelected({});
  }

  const ManageCell = ({ item, col }) => {
    return (
      <Table.Cell>
        <IndexGrid.EditButton onClick={() => handleModal(item)}/>
      </Table.Cell>
    );
  };

  const cols = [
    {display: 'Suffix', key: 'suffix'},
    {display: 'Tier 1', key: 'tier_1'},
    {display: 'Tier 2', key: 'tier_2'},
    {display: 'Tier 3', key: 'tier_3'},
    {display: 'Tier 4', key: 'tier_4'},
    {display: 'Edit', as: ManageCell}
  ]

  return (
    <React.Fragment>
      <ContentCard hasTable title="Aggregations"
        topRight={<Button
          primary
          compact
          content="Aggregation"
          icon="plus"
          onClick={() => handleModal({})}
        />}
        >
          <IndexGrid data={modifiedData} cols={cols} fallbackMsg="No aggregations found"/>
        </ContentCard>
        <AggregationsModal handleSubmit={handleSubmit} modalStatus={modalStatus} handleModalClose={handleModalClose} selected={selected}/>
    </React.Fragment>
  )
}

export default Aggregations;

import React, { Component } from 'react';
import styled from 'styled-components';
import { Button, Loader } from 'semantic-ui-react';

export const BlueGradientBackground = styled.div`
    background: linear-gradient(90deg, #026cac 0%, #05a5d6 100%);
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
`;

export const CloseButtonStyles = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px !important;
  margin: 0 !important;
`

export const Row = styled.div.attrs({"className":"rowContainer"})`
  margin-top:${props => props.marginTop || '15px' };
  margin-bottom:${props => props.marginBottom || '20px' };
  display: flex;
  flex-direction: ${props => props.direction || 'row' };
  flex: 1;
`


export const MinLoader = (props) => (
  <Row marginBottom="100px" marginTop="100px">
    <Loader active inline='centered' {...props} />
  </Row>
)


export const Error = (props) => (
  <Row marginBottom="100px" marginTop="100px" style={{justifyContent:"center"}}>
    { props.children }
  </Row>
)

import React from 'react';

import { Segment, Icon, Image, Button } from 'semantic-ui-react';

const KanbanCard = ({ item, provided, snapshot, viewDetailsText, onViewDetails }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Segment secondary raised={snapshot.isDragging} className="kanban-card" {...provided.draggableProps} {...provided.dragHandleProps}>
    <div>
      <Icon name="ellipsis vertical" />
      {item.value}
    </div>
    <div className="kanban-card-details">
      { item.image && <Image size="mini" src={item.image} /> }
      <Button
        size="mini"
        content={viewDetailsText || 'view details'}
        onClick={onViewDetails}
      />
    </div>
  </Segment>
);

export default KanbanCard;

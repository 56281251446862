import { useEffect, useMemo } from 'react';
import moment from 'moment';

import { useGlobalState } from '../../hooks/global';
import useAppSettings from '../../hooks/useAppSettings';
import { segmentOptions } from '../../utils/options';

// helpers
const checkIfNoSegment = segId => !segId || segId === 0 || segId === '' || segId === '0';
const checkIfSegmentsEmpty = segments => segments.length === 0;
const getFeaturedSegment = segments => segments.find(c => c.featured === 1);
const getSegmentViaId = (segments, segId) => segments.find(c => c.filter_id === parseInt(segId) || c.filter_id === String(segId));

// custom hook for global settings overlap needs
export const useSettings = () => {
  const { segments } = useGlobalState();
  const segOptions = useMemo(() => segmentOptions(segments), [segments]);

  const settings = useAppSettings();
  const { tierColors } = settings;

  return { tierColors, segments, segOptions };
};

export const useSegmentOptions = (segId, segments) => useMemo(() => {
  if (checkIfSegmentsEmpty(segments)) return false;
  if (checkIfNoSegment(segId)) return false;

  const selectedSegment = getSegmentViaId(segments, segId);

  if (!selectedSegment) return false;

  return {
    hasNtf: selectedSegment.has_new_to_file,
    hasRevenue: selectedSegment.include_revenue,
  };
}, [segments, segId]);

export const useFirstReportingDate = (segId, segOptions, defaultDate) => useMemo(() => {
  if (checkIfSegmentsEmpty(segOptions)) return defaultDate;
  if (checkIfNoSegment(segId)) return defaultDate;

  const selectedSegment = getSegmentViaId(segOptions, segId);

  if (!selectedSegment) return defaultDate;

  const { first_reporting_date } = selectedSegment;
  const reportingDateBeforePpDate = moment(first_reporting_date).isBefore(defaultDate);
  const checkedDate = reportingDateBeforePpDate ? defaultDate : first_reporting_date;

  return checkedDate;
}, [segOptions, segId]);

export const useInitDefaultSegment = (segments, segId, setSegId) => {
  useEffect(() => {
    const hasSegments = !checkIfSegmentsEmpty(segments);
    const missingSelectedSegment = checkIfNoSegment(segId);

    if (hasSegments && missingSelectedSegment) {
      const { filter_id } = getFeaturedSegment(segments);
      setSegId(filter_id);
    }
  }, [segments]);
};

import React from 'react';

import { ContentCard, PagedIndexGrid } from '@rockerbox/styleguide';

import { useUsersData, useTableColumns } from './hooks';
import InviteUsersForm from './InviteUsersForm';
import { RBAC_ASSIGN_ROLES } from '../../constants/rbac';
import { useGlobalState } from '../../hooks/global';
import { useRBACUsers, useRBACAdvertiser, useRBACRoles, useRBACPermissions } from '../../hooks/useRBAC';

const InviteUsers = ({ hideTitle, showFooter = false }) => {
  const { user } = useGlobalState();
  const { loadingUsers, users, handleManageUpdate } = useUsersData();
  const { advertiserExistsRBAC } = useRBACAdvertiser();
  const { roleOptions } = useRBACRoles();
  const { usersWithRoles, userToRoleMap, setUserToRoleMap } = useRBACUsers(users);
  const { authorizedPermissions } = useRBACPermissions();
  const authorizedToAssignRoles = authorizedPermissions.includes(RBAC_ASSIGN_ROLES);

  const { cols } = useTableColumns({
    user, handleManageUpdate,
    roleOptions, advertiserExistsRBAC, userToRoleMap, setUserToRoleMap,
    authorizedToAssignRoles,
  });

  return (
    <ContentCard title={!hideTitle && 'Invite Users'} loading={loadingUsers}>
      <InviteUsersForm {...{ handleManageUpdate }} />
      {
        !loadingUsers
        && (
          <PagedIndexGrid
            className="invite-users-table"
            data={usersWithRoles}
            cols={cols}
            itemsPerPage={10}
            collapsed
            showFooter={showFooter}
          />
        )
      }
    </ContentCard>
  );
};

export default InviteUsers;

import React, { useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { TrashIcon } from '@heroicons/react/outline';
// eslint-disable-next-line import/no-extraneous-dependencies
import { PuzzleIcon, ChartBarIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { Label, Header, Icon, Grid, Table, Image, Button, Popup } from 'semantic-ui-react';

import { GREEN, RED, GREY, PURPLE, BLUE, WHITE, AUTO_PARAMS_PLATFORMS, SUCCESS_STATUSES } from './constants';
import { deletePlatformAccount } from '../../api/account';
import { buildPath } from '../../hooks/urlState';
import { formatDateToLongString } from '../../utils/dateFormatters';

export const StatusLabel = ({ status }) => {
  if (!['success', 'failure'].includes(status)) return null;
  return (
    <Label
      color={status === 'success' ? 'green' : 'red'}
      className="status-label"
    >
      {status === 'success' ? 'Success' : 'Fail'}
    </Label>
  );
};

export const PlatformStatus = ({ tier, hasTrackingParamIssue, hasAutoParams }) => (
  <div className="platform-status">
    {tier !== 'lite' && (
    <div className="section-header" style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
      <Header
        as="h4"
        content={(
          <span style={{ whiteSpace: 'nowrap' }}>
            <Icon
              name={hasTrackingParamIssue ? 'exclamation triangle' : 'check circle outline'}
              color={hasTrackingParamIssue ? 'orange' : 'green'}
            />
            {' '}
            Tracking Params QA status
            {!!hasAutoParams && (
            <>
              <span style={{ margin: '0 20px' }} />
              <Icon
                name={hasTrackingParamIssue ? 'exclamation triangle' : 'check circle outline'}
                color={hasTrackingParamIssue ? 'orange' : 'green'}
              />
              {' '}
              Auto Params status
            </>
            )}
          </span>
            )}
      />
    </div>
    )}
  </div>
);

export const IntegrationStatus = ({ tier, hasTrackingParamIssue, isConversionPlatform, accounts }) => {
  const isShopify = accounts.some(account => account.platform === 'Shopify');
  const hasAutoParams = accounts.some(account => AUTO_PARAMS_PLATFORMS.includes(account.platform_key));
  if (isShopify) {
    return (
      <div className="platform-status">
        {tier !== 'lite' && (
        <div className="section-header" style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          <Header
            as="h4"
            content={(
              <span style={{ whiteSpace: 'nowrap' }}>
                <Icon
                  name={hasTrackingParamIssue ? 'exclamation triangle' : 'check circle outline'}
                  color={hasTrackingParamIssue ? 'orange' : 'green'}
                />
                {' '}
                Authentication status
              </span>
                )}
          />
        </div>
        )}
      </div>
    );
  }
  if (isConversionPlatform) {
    return null;
  }
  return (
    <PlatformStatus tier={tier} hasTrackingParamIssue={hasTrackingParamIssue} hasAutoParams={hasAutoParams} />
  );
};

const generateTooltipContent = (dayData, type) => {
  const { date, files, status } = dayData;
  const dateFormatted = formatDateToLongString(date);
  if (type === 'File Received') {
    const numberOfFiles = files.length;
    const numberContent = numberOfFiles > 1 ? ` x${numberOfFiles}` : '';
    const tooltipContent = `${dateFormatted}: ${status.charAt(0).toUpperCase()}${status.slice(1)}${numberContent}`;
    return tooltipContent;
  }
  if (type === 'File Processed') {
    if (status === 'no data') {
      return `${dateFormatted}: No data`;
    }
    if (status === 'empty') {
      return `${dateFormatted}: Empty`;
    }
    const numberProcesssed = dayData.numberSuccess + dayData.numberFailure;
    const successContent = numberProcesssed > 1 && dayData.numberSuccess > 0 ? `Success x${dayData.numberSuccess} ` : dayData.numberSuccess > 0 ? 'Success' : '';
    const failureContent = numberProcesssed > 1 && dayData.numberFailure > 0 ? `Failure x${dayData.numberFailure}` : dayData.numberFailure > 0 ? 'Failure' : '';
    const statusContent = successContent + failureContent;
    const tooltipContent = `${dateFormatted}: ${statusContent}`;
    return tooltipContent;
  }
  if (type === 'Conversion Data') {
    if (status === 'no data') {
      return `${dateFormatted}: No data`;
    }
    const tooltipContent = [
      dateFormatted,
    ];
    Object.entries(dayData.statusesBySegment).forEach(([segmentId, segmentStatus]) => {
      tooltipContent.push(`${segmentId}: ${segmentStatus.charAt(0).toUpperCase()}${segmentStatus.slice(1)}`);
    });
    return tooltipContent.join('\n');
  }
};

const TooltipContent = ({ dayData, label }) => {
  const tooltipClassName = label === 'Conversion Data' ? 'conversion-status-tooltip-text' : 'batch-status-tooltip-text';
  return (
    <span className={tooltipClassName}>
      <div className="tooltip-header">Sync Status:</div>
      {generateTooltipContent(dayData, label)}
    </span>
  );
};

export const BatchGrid = ({ data, label }) => {
  if (!data) return null;
  const recentData = data.length > 30 ? data.slice(-30) : data;
  const gridContent = label === 'File Received'
    ? recentData.map(dayData => (
      <div
        className="batch-status-tooltip batch-grid-icon"
        style={{
          backgroundColor: SUCCESS_STATUSES.includes(dayData.status) ? PURPLE : GREY,
        }}
      >
        {SUCCESS_STATUSES.includes(dayData.status) && dayData.number > 1 && <span>{dayData.number}</span>}
        <TooltipContent dayData={dayData} label={label} />
      </div>
    )) : recentData.map(dayData => (dayData.status === 'mixed' ? (
      <div className="batch-status-tooltip mixed-status">
        <div className="grid-icon-mixed-status" style={{ backgroundColor: GREEN, paddingTop: '1px' }}>
          <span>{dayData.numberSuccess}</span>
        </div>
        <div className="grid-icon-mixed-status" style={{ backgroundColor: RED }}>
          <span>{dayData.numberFailure}</span>
        </div>
        <TooltipContent dayData={dayData} label={label} />
      </div>
    ) : (
      <div
        className="batch-status-tooltip batch-grid-icon"
        style={{
          backgroundColor: SUCCESS_STATUSES.includes(dayData.status) ? GREEN : GREY,
        }}
      >
        {SUCCESS_STATUSES.includes(dayData.status) && dayData.numberSuccess > 1 && <span>{dayData.numberSuccess}</span>}
        {dayData.status === 'failure' && dayData.numberFailure > 1 && <span>{dayData.numberFailure}</span>}
        <TooltipContent dayData={dayData} label={label} />
      </div>
    )));

  return (
    <div className="status-grid-container">
      <div className="status-grid-label">
        {label}
        :
      </div>
      <div className="status-grid">{gridContent}</div>
    </div>
  );
};

export const StackedConversionGrid = ({ data, label }) => {
  if (!data) return null;
  const recentData = data.length > 30 ? data.slice(-30) : data;
  const gridContent = recentData.map(dayData => {
    if (dayData.status === 'mixed') {
      return (
        <div className="batch-status-tooltip conversions-mixed-status" key={dayData.date}>
          {(dayData.numberSuccess + dayData.numberEmpty > 0) && (
            <div className="grid-icon-mixed-status-conversions-success" style={{ backgroundColor: GREEN, marginBottom: '2px' }}>
              <span>{dayData.numberSuccess + dayData.numberEmpty}</span>
            </div>
          )}
          {dayData.numberFailure > 0 && (
            <div className="grid-icon-mixed-status-conversions" style={{ backgroundColor: RED }}>
              <span>{dayData.numberFailure}</span>
            </div>
          )}
          <TooltipContent dayData={dayData} label={label} />
        </div>
      );
    }
    return (
      <div
        className="batch-status-tooltip conversions-grid-icon"
        style={{
          backgroundColor: dayData.status === 'success' ? GREEN : dayData.status === 'failure' ? RED : WHITE,
        }}
        key={dayData.date}
      >
        <span>
          {dayData.status === 'success'
            ? dayData.numberSuccess + dayData.numberEmpty
            : dayData[`number${dayData.status.charAt(0).toUpperCase()}${dayData.status.slice(1)}`]}
        </span>
        <TooltipContent dayData={dayData} label={label} />
      </div>
    );
  });

  return (
    <div className="status-grid-container">
      <div className="status-grid-label">
        {label}
        :
      </div>
      <div className="status-grid">
        {gridContent}
      </div>
    </div>
  );
};

export const StatusIndicator = ({ hasIssue, text }) => (
  <Grid.Column width={3} className="status-column">
    <Icon
      name={hasIssue ? 'exclamation triangle' : 'check circle'}
      color={hasIssue ? 'orange' : 'green'}
      size="large"
      className="modal-icon"
    />
    <span className="modal-text">{text}</span>
  </Grid.Column>
);

export const ActionCell = integrations => ({ item, handleRowExpand, isExpanded }) => {
  const { platform, number_of_accounts, has_platform_performance: hasPlatformPerformance, platform_key: platformKey } = item;
  const integration = integrations.find(c => c.platform === item.platform);
  const analyticsLink = buildPath('platformPerformance', { view: 'platform', platformName: item.platform });
  return (
    <Table.Cell>
      <div className="account-details">
        <div className="header">
          {!!integration?.icon_path
            ? <Image src={integration.icon_path} style={{ height: 40, marginRight: '1em', marginLeft: '2em' }} />
            : <PuzzleIcon style={{ height: 40, marginRight: '1em', marginLeft: '2em', color: '#bbb' }} />}
          <div className="platform-name">
            {platform}
          </div>
          <div className="number-of-accounts">
            {number_of_accounts}
          </div>
          <Icon name={isExpanded ? 'chevron down' : 'chevron right'} onClick={() => handleRowExpand(platformKey)} style={{ margin: '0 0 3px 10px' }} />
        </div>
        <div className="analytics">
          {!!hasPlatformPerformance
            && (
            <Link key={analyticsLink} to={analyticsLink}>
              <div className="icon">
                <ChartBarIcon style={{ marginRight: '8px', height: '28' }} />
                View Analytics
              </div>
            </Link>
            )}
        </div>
      </div>
    </Table.Cell>
  );
};

export const ExpandedComponent = ({ item }) => {
  const [openPopups, setOpenPopups] = useState({});

  const handleOpenPopup = index => {
    setOpenPopups({ ...openPopups, [index]: true });
  };

  const handleClosePopup = index => {
    setOpenPopups({ ...openPopups, [index]: false });
  };

  const handleDelete = async (key, id) => {
    await deletePlatformAccount(key, id);
    window.location.reload();
  };

  const renderAccountRows = (account, index) => {
    const authStatus = item.batch_platform ? 'Using Batch Files' : item.auth_statuses[index] ? 'Connected' : 'Not Connected';
    return (
      <div key={index} className="expanded-element">
        <div className="top-row">
          <span className="account-name">
            Account Name:
            &nbsp;
            {account}
          </span>
          <div className="account-auth-status">
            <div className="status-content">
              <span className="status-indicator">
                {!item.batch_platform && !item.auth_statuses[index]
                  ? <Icon name="warning sign" color="orange" />
                  : <Icon name="check circle" color="green" />}
              </span>
              <span style={{ whiteSpace: 'nowrap' }}>{authStatus}</span>
              {!item.auth_statuses[index] && !item.batch_platform && (
              <Button
                primary
                content="Reconnect"
                size="mini"
                onClick={() => { window.location = `/auth/${item.platform_key}?account_id=${item.account_ids[index]}`; }}
                style={{ padding: '5px 5px 4px 5px', marginLeft: '1em', position: 'relative', top: -1 }}
              />
              )}
            </div>
            { !item.batch_platform
              && (
              <Popup
                on="click"
                size="small"
                open={openPopups[index] || false}
                onClose={() => handleClosePopup(index)}
                onOpen={() => handleOpenPopup(index)}
                position="top right"
                trigger={(
                  <TrashIcon
                    style={{ height: 22, color: BLUE, cursor: 'pointer', marginRight: '20px' }}
                    className="hover-opacity-75"
                  />
                )}
                content={(
                  <>
                    <Header
                      as="h4"
                      content={`Are you sure you want to delete this connection to ${item.platform} account ${account}?`}
                      subheader="This action cannot be undone and may disrupt scheduled reporting or other data syncs."
                    />
                    <Button.Group fluid>
                      <Button
                        content="Nevermind"
                        onClick={() => handleClosePopup(index)}
                      />
                      <Button.Or />
                      <Button
                        negative
                        content="Confirm Delete"
                        onClick={() => {
                          handleDelete(item.platform_key, item.ids[index]);
                          handleClosePopup(index);
                        }}
                      />
                    </Button.Group>
                  </>
                  )}
              />
              )}
          </div>
        </div>
        <div className="bottom-row">
          <span className="text">
            Account ID:
            {' '}
            {item.account_ids[index]}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div>
      {item.account_names.map(renderAccountRows)}
    </div>
  );
};

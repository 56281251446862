import React, { useMemo } from 'react';

import { MiniChartCard, MiniChartCardLoader } from '@rockerbox/styleguide';
import { Grid } from 'semantic-ui-react';

const numberFormatter = numDecimals => number => {
  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: numDecimals,
  });
  return numberFormat.format(number);
};

const MiniChartCards = ({ loading, data, hasRevenue, featuredSegmentName, currencyFormatter, spendStatusRollup, conversionStatusRollup, syntheticStatusRollup, isTestAccount }) => {
  const [roas, cpa] = useMemo(() => {
    if (!data) return [0, 0];
    const totalRevenue = data.reduce((acc, x) => acc + x.revenue, 0);
    const totalSpend = data.reduce((acc, x) => acc + x.spend_total, 0);
    const totalConversions = data.reduce((acc, x) => acc + x.conversions, 0);
    // eslint-disable-next-line no-shadow
    const roas = totalRevenue / totalSpend;
    // eslint-disable-next-line no-shadow
    const cpa = totalSpend / totalConversions;
    return [roas, cpa];
  }, [data]);
  const hasFailures = response => {
    if (!response || response.length === 0) {
      return null;
    }
    return response.failures.some(entry => entry.dates.length > 0);
  };

  // TODO: remove test account stipulation
  const hasBadSpendData = hasFailures(spendStatusRollup) && isTestAccount;
  const hasBadConversionData = (hasFailures(conversionStatusRollup) || hasFailures(syntheticStatusRollup)) && isTestAccount;
  const hasBadData = (hasBadSpendData || hasBadConversionData) && isTestAccount;

  return (
    <Grid.Row style={{ height: 150, marginBottom: 1 }}>
      <Grid.Column>
        {!!loading ? <MiniChartCardLoader />
          : (
            <MiniChartCard
              data={data}
              title={!!hasRevenue ? 'Blended ROAS' : 'Blended CPA'}
              formatter={!!hasRevenue ? numberFormatter(2) : currencyFormatter(2)}
              dataKey={!!hasRevenue ? 'roas' : 'cpa'}
              summaryType="custom"
              customTotal={!!hasRevenue ? roas : cpa}
              color={!!hasRevenue ? '#ffa64d' : '#E76D8E'}
              hasBadData={hasBadData}
            />
          )}
      </Grid.Column>
      <Grid.Column>
        {!!loading ? <MiniChartCardLoader />
          : (
            <MiniChartCard
              data={data}
              title={!!hasRevenue ? 'Blended CPA' : featuredSegmentName}
              formatter={!!hasRevenue ? currencyFormatter(2) : numberFormatter(0)}
              dataKey={!!hasRevenue ? 'cpa' : 'conversions'}
              summaryType={!!hasRevenue ? 'custom' : 'sum'}
              customTotal={!!hasRevenue ? cpa : null}
              color={!!hasRevenue ? '#E76D8E' : '#4987BF'}
              hasBadData={hasBadData}
            />
          )}
      </Grid.Column>
      <Grid.Column>
        {!!loading ? <MiniChartCardLoader />
          : (
            <MiniChartCard
              data={data}
              title={!!hasRevenue ? `Revenue (${featuredSegmentName})` : `Spend (${featuredSegmentName})`}
              formatter={currencyFormatter(0)}
              dataKey={!!hasRevenue ? 'revenue' : 'spend_total'}
              summaryType="sum"
              color={!!hasRevenue ? '#66D9AE' : '#5B57C8'}
              hasBadData={!!hasRevenue ? hasBadConversionData : hasBadSpendData}
            />
          )}
      </Grid.Column>
    </Grid.Row>
  );
};

export default MiniChartCards;

import React, { useMemo } from 'react';

import { useGlobalState } from '../../hooks/global';
import { switchAccount } from '../../api/account';
import NavbarV3 from './component';
import { LOGOUT, LOGIN } from './constants';

const Navbar = ({ history }) => {
  const { state, artifacts, user, permissions, announcements } = useGlobalState();
  const { account } = state;

  const allowedAccounts = permissions.accounts || [];
  const accounts = useMemo(
    () => allowedAccounts.map(({ advertiser_name, pixel_source_name }) => Object({
      text: advertiser_name,
      key: pixel_source_name,
      value: pixel_source_name,
    })),
    [allowedAccounts],
  );

  const notifications = useMemo(() => announcements.filter(a => !a.major), [announcements]);

  const parsedArtifacts = useMemo(() => {
    if (!artifacts) return {};
    const parsed = {};
    Object.entries(artifacts).forEach(x => {
      const firstItem = x[0];
      const secondItem = x[1];
      try {
        parsed[firstItem] = JSON.parse(secondItem);
      } catch (e) {
        parsed[firstItem] = secondItem;
      }
    });
    return parsed;
  }, [artifacts]);

  const handleAccountChange = value => {
    switchAccount(value)
      .then(() => document.location.reload(true));
  };

  const handleLogout = () => {
    fetch(
      LOGOUT,
      { headers: { accept: 'application/json' } },
    ).then(() => {
      if (!!window.gapi) {
        try {
          const auth2 = window.gapi.auth2.getAuthInstance();
          auth2.signOut().then(() => console.info('Google user signed out'));
        } catch (e) {
          console.error(e);
        }
      }
      window.location = LOGIN;
    });
  };

  const navigate = route => history.push(route);

  return (
    <NavbarV3
      {...{ navigate, account, accounts, user, handleAccountChange, notifications, handleLogout, artifacts: parsedArtifacts }}
    />
  );
};

export default Navbar;

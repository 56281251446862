import React, { useEffect, useState } from "react";
import Container from "../parts/Container";
import { Button, Modal, Form, Message } from "semantic-ui-react";
import {
  ScheduleEmailReportModal,
  ScheduleNewReportModal
} from "./ScheduleNewReportModal";
import { findMatchedReportTypeOptions } from "./helpers";
import { getReportTypeLimitBySegment } from '../../utils/api';
import { CloseButtonStyles } from "../styled";

const NewSavedReportModal = props => {
  const {
    report_type_id,
    report_name,
    filter_id,
    onFieldChange,
    segments,
    reportTypes,
    loading,
    error,
    successPage,
    onCreateNewSavedReport,
    isRBUser
  } = props;

  const [reportTypeLimits, setReportTypeLimit] = useState([])

  useEffect(() => {
    if (filter_id) {
      getReportTypeLimitBySegment(filter_id).then((data) => setReportTypeLimit(data['response']))
    }
  }, [filter_id])

  const segmentsOptions = segments
    ? segments.map((o, i) => {
        return { text: o.action_name, value: o.filter_id, key: i };
      })
    : [];

  let matchedReportTypes = findMatchedReportTypeOptions(
    filter_id,
    segments,
    reportTypes,
    reportTypeLimits
  );

  const RBUserReportTypeOptions = matchedReportTypes
    ? matchedReportTypes.map((o, i) => {
        return { text: o.name, value: o.id, key: i };
      })
    : [];
  const NonRBUserReportTypeOptions = matchedReportTypes
    ? matchedReportTypes
        .filter(type => type.is_public === 1)

        .map((o, i) => {
          return { text: o.name, value: o.id, key: i };
        })
    : [];

  const reportTypeOptions = isRBUser()
    ? RBUserReportTypeOptions
    : NonRBUserReportTypeOptions;

  const noAvailableItemInListError =
    filter_id && reportTypeOptions.length === 0
      ? {
          content:
            "There are no available report types for the segment you selected. For any custom report requests, please contact support@rockerbox.com.",
          pointing: "below"
        }
      : false;

  const validate = () => report_type_id && report_name && filter_id;

  const formList = (
    <React.Fragment>
      <Form.Group>
        <Form.Field>
          <label>Report Name</label>
          <Form.Input
            value={report_name}
            onChange={onFieldChange("report_name")}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <label>Segment Name</label>
          <Form.Dropdown
            selection
            options={segmentsOptions}
            value={filter_id}
            onChange={(e, data) => {
              onFieldChange("filter_id")(e, data);
              onFieldChange("report_type_id")(e, { value: null });
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <label>Report Type</label>
          <Form.Dropdown
            selection
            options={reportTypeOptions}
            error={noAvailableItemInListError}
            value={report_type_id}
            onChange={onFieldChange("report_type_id")}
          />
        </Form.Field>
      </Form.Group>
      <Button type="submit" disabled={!validate()}>
        Create new report
      </Button>
    </React.Fragment>
  );

  const SuccessContent = (
    <Container center column>
      <h5>
        New saved report created! You can now download this report anytime, or
        set up a scheduled export to receive a file on a recurring basis.
      </h5>
      <Container>
        <Button onClick={props.close}>Done</Button>
        <Button
          primary
          onClick={() =>
            onFieldChange("showNewScheduledReportModal")({}, { value: true })
          }
        >
          Schedule Report
        </Button>
      </Container>
    </Container>
  );

  return (
    <Container>
      <Form onSubmit={onCreateNewSavedReport} loading={loading} error={error}>
        <Message
          error
          header="Failed to create new report"
          content="Please sure all fields are filled properly or contact us for help."
        />
        {successPage ? SuccessContent : formList}
      </Form>
    </Container>
  );
};

export default props => {
  const { outboxes, outboxId, onFieldChange } = props;
  const currentOutbox = outboxes.filter(outbox => outbox.id === outboxId)[0];
  const onLoadingChange = flag => onFieldChange("loading")({}, { value: flag });
  const onErrorChange = flag => onFieldChange("error")({}, { value: flag });
  const ModalComponent = props.showNewScheduledReportModal
    ? currentOutbox && currentOutbox.outbox_type === "do_spaces"
      ? ScheduleEmailReportModal
      : ScheduleNewReportModal
    : NewSavedReportModal;

  return (
    <Modal
      size="small"
      open={props.open}
      onClose={props.close}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        <CloseButtonStyles onClick={props.close}>x</CloseButtonStyles>
        {props.showNewScheduledReportModal
          ? "New Scheduled Report"
          : props.title}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <ModalComponent
            done={props.close}
            {...props}
            onLoadingChange={onLoadingChange}
            onErrorChange={onErrorChange}
          />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

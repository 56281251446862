import React, { useState, useEffect } from 'react';
import { Header, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import { saveIDAtom } from '../atoms';
import { useSavedReports } from '../views/SavedReports/_hooks/savedReports';
import { Drawer, EditSavedReport } from '.';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const EditSavedReportButton = ({ setEditSavedReportDrawerOpen }) => {
  const handleEditButtonClick = () => setEditSavedReportDrawerOpen(true);
  return (
    <div
      role="button"
      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      onClick={handleEditButtonClick}
      onKeyDown={handleEditButtonClick}
      tabIndex={0}
    >
      <Icon name="purple edit" size="tiny" />
    </div>
  );
};

const BasicViewHeader = ({
  header, subheader, // Text
  topRight, // other components
  headerOnTop = true,
}) => {
  const { savedReports, handleGetSavedReports, loading } = useSavedReports();

  const [saveID] = useAtom(saveIDAtom);
  const [savedReport, setSavedReport] = useState({});
  const [heading, setHeading] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const [editSavedReportDrawerOpen, setEditSavedReportDrawerOpen] = useState(false);

  useEffect(() => {
    handleGetSavedReports();
  }, [saveID]);

  useEffect(() => {
    if (saveID && savedReports.length) {
      const foundView = savedReports.find(({ id }) => id === saveID);
      if (foundView) setSavedReport(foundView);
      return;
    }
    setSavedReport({});
  }, [saveID, savedReports]);

  useEffect(() => {
    if (loading) return;
    let setName = header;
    let setDescription = subheader;
    if (savedReport.id === saveID) {
      const { name, description } = savedReport;
      if (name && name.length) setName = name;
      if (description && description.length) setDescription = description;
    }
    setHeading(setName);
    setSubHeading(setDescription);
  }, [savedReport, loading]);

  const saveIdValidToEdit = savedReports.map(x => x.id).includes(Number(saveID));

  return (
    <Wrapper className="generic-header">
      <div>
        <Header as="h1" style={{ fontWeight: 'bold', color: '#333' }}>
          {!headerOnTop && <Header.Subheader style={{ paddingTop: 5 }}>{subHeading}</Header.Subheader>}
          <div
            className="header"
            style={{
              fontWeight: 600,
              padding: !!headerOnTop ? '5px 0' : '5px 0 0',
              display: 'flex',
              alignItems: 'center',
              columnGap: 20,
            }}
          >
            <h1>
              {heading}
            </h1>
            {!!saveID && !loading && saveIdValidToEdit && <EditSavedReportButton {...{ setEditSavedReportDrawerOpen }} />}
          </div>
          {!!headerOnTop && <Header.Subheader>{subHeading}</Header.Subheader>}
        </Header>
      </div>
      <div>
        <div style={{ display: 'inline-flex', alignItems: 'flex-end' }}>
          {topRight && topRight}
        </div>
      </div>
      <Drawer openDrawer={editSavedReportDrawerOpen} onDrawerClose={() => setEditSavedReportDrawerOpen(false)}>
        <EditSavedReport
          savedReport={savedReport}
          onClose={() => setEditSavedReportDrawerOpen(false)}
          open={editSavedReportDrawerOpen}
          refresh={handleGetSavedReports}
        />
      </Drawer>
    </Wrapper>
  );
};

export default BasicViewHeader;

import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';


export const Wrapper = styled.div`
  flex:1;
  width:100%;
`
export const Row = styled.div.attrs({"className":"gridRow"})`
  padding-top:1px;
  flex: ${props => props.flex ? props.flex : 1};
  display: flex;
  flex-direction: row;
  ${props => props.grey ?  "background:#f6f6f6;" : undefined }
  ${props => props.pointer ? "cursor:pointer;" : undefined }
  & > .gridCol {
    ${props => props.light ? "opacity:.6;" : undefined }
    ${props => props.grey && props.light ?  "opacity:.75;" : undefined }
  }
  :hover {
    ${props => props.hover ?  "background:#f0f0f0;" : undefined }
    ${props => props.light ? "opacity:.85;" : undefined }
  }
`
export const Column = styled.div.attrs({"className":"gridCol"})`
  flex: ${props => props.flex || "1" };
  display: flex;
  flex-direction: column;
  padding-left: ${props => props.padded || "0" }px;
  margin-right: -${props => props.padded || "0" }px;
  ${props => props.bold ?  "font-weight:bold;opacity:1 !important;" : undefined }
`
export const CollapsibleWrapper = styled.div`
  flex:1;
  display: flex;
  flex-direction: column; 
  margin-bottom:1px;

  & > .gridRow:first-of-type {
    ${props => props.open ? "background:#fbfbfb;" : "" }
  }
  & .children {
    ${props => props.useBorder ? "border-left:15px solid #ddd;" : "padding-left:15px;"}
    & > .gridRow > .gridCol:nth-of-type(2) {
      /*margin-left:-15px;*/
    }
    & > .gridRow > .gridCol:not(:first-of-type) {
      text-align:right;
    }

  }
  & .total{
    & > .gridRow > .gridRow > .gridCol:nth-of-type(2) {
      margin-left:-15px;
    }
    & > .gridRow > .gridCol:not(:first-of-type) {
      text-align:right;
    }
  }
`
const VerticalAlign = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
`
export class Collapsible extends Component {

  state = { open: false }

  componentDidMount() {
    const { open } = this.props;
    if (open != undefined) this.setState({ open })
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props
    if (this.state.open !== open && open != prevProps.open) this.setState({ open })
  }
  
  render() {
    const { open } = this.state;
    const { title, totalRow, children, style, useBorder, hideTotalOnOpen, alignOnOpen } = this.props;

    if (open) {
      const titleRow = (
        <Row pointer onClick={() => this.setState({ open: false})} style={{fontWeight:"bold"}}>
          <VerticalAlign>
            <Icon name='down chevron' size='tiny' style={{paddingLeft:"10px"}}/>
          </VerticalAlign>
          { title }
        </Row>
      )
      return (
        <CollapsibleWrapper open style={style} useBorder={useBorder}>
          { alignOnOpen ? <div className="total">{ titleRow }</div> : titleRow }
          <div className="children" > { children } </div>
          { 
            totalRow && hideTotalOnOpen !== true && 
            <div className="total">
              <Row style={{fontWeight:"bold",borderTop:"1px solid grey",marginTop:"3px",paddingTop:"3px",marginBottom:"5px"}}>
                <Column padded="15"> Total { title }</Column>
                { totalRow }
              </Row>
            </div>
          }
        </CollapsibleWrapper>
      )
    }
    return (
      <CollapsibleWrapper style={style} useBorder={useBorder}>
        <div className="total">
          <Row pointer onClick={() => this.setState({ open: true})} >
            <VerticalAlign>
              <Icon name='right chevron' size='tiny' style={{paddingLeft:"10px"}}/>
            </VerticalAlign>
            { title }
            { totalRow }
          </Row>
        </div>
      </CollapsibleWrapper>
    )
  }
}


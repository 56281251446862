import * as d3 from 'rockerbox_d3_legacy_clone';
import { IndexGridTree } from '@rockerbox/styleguide';
import { spendFormatter } from '../valueFormatter';

const d3sum = (arr, acc) => arr.reduce((p, c) => p + acc(c), 0);
const f = values => values.filter(x => !x.depth);
const sum_reducer = (values, col) => d3.sum(f(values), x => x[col]);

export const metrics = {
  spend: {
    name: 'spend',
    is_default: true,
    display_name: 'Spend',
    format: 'currency',
    reducer: values => d3.sum(f(values), x => x.spend),
    as: values => IndexGridTree.SpendCell(spendFormatter(values.col.currency))({ item: values.item, col: values.col, label: values.label }),
    trendReversed: false,
  },
  impressions: {
    name: 'impressions',
    is_default: true,
    display_name: 'Impressions',
    format: 'decimal',
    reducer: values => d3.sum(f(values), x => x.impressions),
    as: IndexGridTree.NumberCell,
    trendReversed: false,
  },
  clicks: {
    name: 'clicks',
    is_default: true,
    display_name: 'Clicks',
    format: 'decimal',
    reducer: values => d3.sum(f(values), x => x.clicks),
    as: IndexGridTree.NumberCell,
    trendReversed: false,
  },
  ctr: {
    name: 'ctr',
    is_default: true,
    display_name: 'CTR',
    format: 'percent',
    reducer: values => (d3sum(f(values), x => x.impressions) ? d3sum(f(values), x => x.clicks) / d3sum(f(values), x => x.impressions) : 0),
    as: IndexGridTree.NumberCell, // TODO: Pecentage cell OR lable
    trendReversed: false,
    formula: {
      type: 'devide',
      params: ['clicks', 'impressions'],
      func: (clicks, impressions) => clicks / impressions,
    },

  },
  cpc: {
    name: 'cpc',
    is_default: true,
    display_name: 'CPC',
    format: 'currency',
    reducer: values => (d3sum(f(values), x => x.spend) ? d3sum(f(values), x => x.spend) / d3sum(f(values), x => x.clicks) : 0),
    as: values => IndexGridTree.SpendCell(spendFormatter(values.col.currency))({ item: values.item, col: values.col, label: values.label }),
    trendReversed: true,
    formula: {
      type: 'devide',
      params: ['spend', 'clicks'],
      func: (spend, clicks) => spend / clicks,
    },
  },
  cpm: {
    name: 'cpm',
    is_default: true,
    display_name: 'CPM',
    format: 'currency',
    reducer: values => (d3sum(f(values), x => x.impressions) ? (1000 * d3sum(f(values), x => x.spend)) / d3sum(f(values), x => x.impressions) : 0),
    as: values => IndexGridTree.SpendCell(spendFormatter(values.col.currency))({ item: values.item, col: values.col, label: values.label }),
    trendReversed: true,
    formula: {
      type: 'devide',
      params: ['spend', 'impressions'],
      func: (spend, impressions) => (spend / impressions) * 1000,
    },
  },
  conversions: {
    name: 'conversions',
    is_default: false,
    display_name: 'Conversions',
    format: 'decimal',
    reducer: values => d3.sum(f(values), x => x.conversions),
    as: IndexGridTree.NumberCell, // TODO: fix to IndexGridTree.NumberCell,
    trendReversed: false,
  },
  conversions_even_rb: {
    name: 'conversions_even_rb',
    display_name: ' Even Conversions',
    is_default: true,
    format: 'decimal',
    reducer: values => d3.sum(f(values), x => x.conversions_even_rb),
    as: IndexGridTree.NumberCell,
    trendReversed: false,
  },
  conversions_first_touch_rb: {
    name: 'conversions_first_touch_rb',
    display_name: 'First Touch Conversions',
    is_default: true,
    format: 'decimal',
    reducer: values => d3.sum(f(values), x => x.conversions_first_touch_rb),
    as: IndexGridTree.NumberCell,
    trendReversed: false,
  },
  conversions_last_touch_rb: {
    name: 'conversions_last_touch_rb',
    display_name: 'Last Touch Conversions',
    is_default: true,
    format: 'decimal',
    // "reducer": (values) => d3.sum(f(values), x => x.rb_last_touch),
    reducer: values => sum_reducer(values, 'conversions_last_touch_rb'),
    as: IndexGridTree.NumberCell,
    trendReversed: false,
  },
  conversions_assisted_rb: {
    name: 'conversions_assisted_rb',
    display_name: 'Full Credit Conversions',
    is_default: true,
    format: 'decimal',
    reducer: values => d3.sum(f(values), x => x.conversions_assisted_rb),
    as: IndexGridTree.NumberCell,
    trendReversed: false,
  },
  conversions_assisted_att_win_rb: {
    name: 'conversions_assisted_att_win_rb',
    display_name: 'Full Credit Conversions 7d',
    is_default: true,
    format: 'decimal',
    reducer: values => d3.sum(f(values), x => x.conversions_assisted_att_win_rb),
    as: IndexGridTree.NumberCell,
    trendReversed: false,
  },
  cpa_assisted_att_win_rb: {
    name: 'cpa_assisted_att_win_rb',
    display_name: 'Full Credit CPA 7d',
    is_default: true,
    format: 'decimal',
    reducer: values => d3.sum(f(values), x => x.spend) / d3.sum(f(values), x => x.conversions_assisted_att_win_rb),
    as: IndexGridTree.NumberCell,
    trendReversed: false,
  },
  conversions_normalized_rb: {
    name: 'conversions_normalized_rb',
    display_name: 'Normalized Conversions',
    is_default: true,
    format: 'decimal',
    reducer: values => d3.sum(f(values), x => x.conversions_normalized_rb),
    as: IndexGridTree.NumberCell,
    trendReversed: false,
  },
  cpa: {
    name: 'cpa',
    is_default: false,
    display_name: 'CPA',
    format: 'currency',
    reducer: values => (d3sum(f(values), x => x.conversions) ? d3sum(f(values), x => x.spend) / d3sum(f(values), x => x.conversions) : Infinity),
    as: values => IndexGridTree.SpendCell(spendFormatter(values.col.currency))({ item: values.item, col: values.col, label: values.label }),
    trendReversed: true,
    formula: {
      type: 'devide',
      params: ['spend', 'conversions'],
      func: (spend, conversions) => spend / conversions,
    },
  },
  attributed_cpa: {
    name: 'attributed_cpa',
    is_default: false,
    custom_name: 'CPA',
    format: 'currency',
    buildReducer: (spend, conversions) => values => (d3sum(f(values), x => x[conversions] || 0)
      ? d3sum(f(values), x => x[spend]) / d3sum(f(values), x => x[conversions] || 0)
      : Infinity),
    as: values => IndexGridTree.SpendCell(spendFormatter(values.col.currency))({ item: values.item, col: values.col, label: values.label }),
    trendReversed: true,
    formula: {
      type: 'devide',
      params: ['spend', 'conversions'],
      func: (spend, conversions) => spend / conversions,
    },
  },
  revenue: {
    name: 'revenue',
    is_default: false,
    display_name: 'Revenue',
    format: 'currency',
    reducer: values => d3.sum(f(values), x => x.revenue),
    as: values => IndexGridTree.SpendCell(spendFormatter(values.col.currency))({ item: values.item, col: values.col, label: values.label }),
    trendReversed: false,
  },
  revenue_even_rb: {
    name: 'revenue_even_rb',
    display_name: 'Even Revenue',
    is_default: true,
    format: 'currency',
    reducer: values => d3.sum(f(values), x => x.revenue_even_rb),
    as: values => IndexGridTree.SpendCell(spendFormatter(values.col.currency))({ item: values.item, col: values.col, label: values.label }),
    trendReversed: false,
  },
  revenue_first_touch_rb: {
    name: 'revenue_first_touch_rb',
    display_name: ' First Touch Revenue',
    is_default: true,
    format: 'currency',
    reducer: values => d3.sum(f(values), x => x.revenue_first_touch_rb),
    as: values => IndexGridTree.SpendCell(spendFormatter(values.col.currency))({ item: values.item, col: values.col, label: values.label }),
    trendReversed: false,
  },
  revenue_last_touch_rb: {
    name: 'revenue_last_touch_rb',
    display_name: 'Last Touch Revenue',
    is_default: true,
    format: 'currency',
    reducer: values => d3.sum(f(values), x => x.revenue_last_touch_rb),
    as: values => IndexGridTree.SpendCell(spendFormatter(values.col.currency))({ item: values.item, col: values.col, label: values.label }),
    trendReversed: false,
  },
  revenue_normalized_rb: {
    name: 'revenue_normalized_rb',
    display_name: 'Normalized Revenue',
    is_default: true,
    format: 'currency',
    reducer: values => d3.sum(f(values), x => x.revenue_normalized_rb),
    as: values => IndexGridTree.SpendCell(spendFormatter(values.col.currency))({ item: values.item, col: values.col, label: values.label }),
    trendReversed: false,
  },
  revenue_assisted_rb: {
    name: 'revenue_assisted_rb',
    display_name: 'Full Credit Revenue',
    is_default: true,
    format: 'currency',
    reducer: values => d3.sum(f(values), x => x.revenue_assisted_rb),
    as: values => IndexGridTree.SpendCell(spendFormatter(values.col.currency))({ item: values.item, col: values.col, label: values.label }),
    trendReversed: false,
  },
  revenue_assisted_att_win_rb: {
    name: 'revenue_assisted_att_win_rb',
    display_name: 'Full Credit Revenue 7D',
    is_default: true,
    format: 'currency',
    reducer: values => d3.sum(f(values), x => x.revenue_assisted_att_win_rb),
    as: values => IndexGridTree.SpendCell(spendFormatter(values.col.currency))({ item: values.item, col: values.col, label: values.label }),
    trendReversed: false,
  },
  roas: {
    name: 'roas',
    is_default: false,
    display_name: 'ROAS',
    format: 'decimal',
    reducer: values => (d3sum(f(values), x => x.revenue) ? d3sum(f(values), x => x.revenue) / d3sum(f(values), x => x.spend) : Infinity),
    as: IndexGridTree.NumberCell,
    trendReversed: false,
    formula: {
      type: 'devide',
      params: ['revenue', 'spend'],
      func: (revenue, spend) => revenue / spend,
    },
  },
  attributed_roas: {
    name: 'attributed_roas',
    is_default: false,
    custom_name: 'ROAS',
    format: 'decimal',
    buildReducer: (revenue, spend) => values => (d3sum(f(values), x => x[revenue] || 0) ? d3sum(f(values), x => x[revenue] || 0) / d3sum(f(values), x => x[spend]) : Infinity),
    // "reducer": (values) => d3sum(f(values), x => x.revenue) ? d3sum(f(values), x => x.revenue) / d3sum(f(values), x => x.spend) : Infinity,
    as: IndexGridTree.NumberCell,
    trendReversed: false,
    formula: {
      type: 'devide',
      params: ['revenue', 'spend'],
      func: (revenue, spend) => revenue / spend,
    },
  },
};

export const FACEBOOK_STANDARD_CONVERSIONS = {
  'offsite_conversion.fb_pixel_view_content': 'View Content (CAPI)',
  'offsite_conversion.fb_pixel_purchase': 'Purchase (CAPI)',
  'offsite_conversion.fb_pixel_add_payment_info': 'Add Payment Info (CAPI)',
  'offsite_conversion.fb_pixel_add_to_cart': 'Add To Cart (CAPI)',
  'offsite_conversion.fb_pixel_complete_registration': 'Complete Registration (CAPI)',
  'offsite_conversion.fb_pixel_initiate_checkout': 'Initiate Checkout (CAPI)',
  'onsite_conversion.purchase': 'Facebook Shop Purchase',
};

export const OVERRIDE_WINDOW_NAMES = {
  allConversions: 'All Conversions by Marketing Date',
  allConversionsByConversionDate: 'All Conversions by Conversion Date',
};

import React, { Component } from 'react';
import { ContentCard, IndexGrid } from '@rockerbox/styleguide'
import { Dropdown } from 'semantic-ui-react';

class ModelRuns extends Component {

  render() {
    const { featureData, featureHeaders, modelHeaders, modelData } = this.props
    const fallbackMsg = "No model settings available"

    return (
      <ContentCard hasTable borderless disableHeaderEllipse title="Data Summary" >
        { modelData && <IndexGrid data={modelData} cols={modelHeaders} as={ContentCard.Table} {...{fallbackMsg}} />}
      </ContentCard>
    )
  }
}
export default ModelRuns;

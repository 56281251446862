import { useMemo, useState, useEffect } from 'react';
import { useParamsWithDefaults } from '../urlParamHooks';

import { getFacebookConversions } from '../../../../api/attribution';
import { FACEBOOK_STANDARD_CONVERSIONS } from '../../../../utils/channelPerformance/platformMetrics';
import { useGlobalState } from '../../../../hooks/global';

export const usePlatformNames = () => { // eslint-disable-line import/prefer-default-export
  const { platform } = useParamsWithDefaults();
  const selectedPlatform = platform;

  const { authorizations } = useGlobalState();
  const [fbNameMap, setFbNameMap] = useState({});

  useEffect(() => {
    if (!authorizations) return;
    // get facebook auths
    const fbAuth = authorizations.find(c => c.platform === 'facebook') || {};
    const fbAuthz = fbAuth.authorizations || [];
    if (!fbAuthz.length) return;
    // get facebook names
    const reqs = fbAuthz.map(auth => getFacebookConversions(auth.id));
    Promise.all(reqs).then(responses => {
      const customConversions = responses.flat();
      const nameMap = FACEBOOK_STANDARD_CONVERSIONS;
      customConversions.map(x => nameMap[`offsite_conversion.custom.${x.id}`] = `${x.name} (${x.id})`); // eslint-disable-line no-return-assign
      setFbNameMap(nameMap);
    });
  }, [authorizations]);

  const allPlatformNames = {
    facebook: fbNameMap,
  };

  const conversionNames = useMemo(
    () => allPlatformNames[selectedPlatform] || {},
    [fbNameMap, selectedPlatform],
  );

  return {
    platformNameMap: allPlatformNames,
    selectedPlatform,
    conversionNames,
  };
};

import React from 'react';
import { Card, Header, Image } from 'semantic-ui-react';
import { ExternalLinkIcon } from '@heroicons/react/outline'; // eslint-disable-line import/no-extraneous-dependencies
import { gsheets } from '@rockerbox/styleguide';
import styled from 'styled-components';
import { BasicViewHeader } from '../../../components';

export const GrayHeader = styled.h2`
  color: rgba(0, 0, 0, 0.5);
  margin-top: 0px;
`;

// disable dropdown if no authorizations?  Also show message below image to connect accounts?
export const MainHeader = () => (
  <BasicViewHeader
    header="Platform Performance"
    subheader="Gauge your marketing performance based on platform-reported metrics"
  />
);

export const PlatformImage = ({ integrations, platform }) => (
  platform && platform !== '0' && integrations
    ? <Image size="tiny" src={integrations[platform].alt} style={{ marginRight: 5, width: 20, height: 20 }} />
    : (
      <div style={{
        height: 20, width: 20,
        borderRadius: '100%', border: '1px dotted rgba(255, 255, 255, 0.6)',
        marginRight: 5, marginTop: -4,
      }}
      />
    )
);

export const GoogleSheetsCard = ({ gSheetsPluginLink }) => (
  <Card
    link
    onClick={() => window.open(gSheetsPluginLink, '_blank')}
    style={{ width: 210 }}
  >
    <Card.Content style={{ padding: '5px 10px' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Image src={gsheets} style={{ height: 30 }} />
        <Header as="h4" style={{ margin: 0, fontSize: 18, fontWeight: 500 }}>
          <Header.Subheader style={{ margin: '2px 0 -2px', fontSize: 11 }}>
            Open data in
          </Header.Subheader>
          Google Sheets
        </Header>
        <ExternalLinkIcon style={{ height: 16, margin: 0, color: '#475ddc' }} />
      </div>
    </Card.Content>
  </Card>
);

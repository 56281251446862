import React, { Component } from 'react';
import { connect } from 'react-redux';
import AccountDetails from './AccountDetails';
import { getSegments } from '../../utils/api';
import CacheContext from '../../utils/CacheContext';

class AccountPage extends Component {
  static contextType = CacheContext;

  state = {
    segments: []
  }

  componentDidMount() {
    getSegments()
      .then( segments => this.setState({segments}))
  }

  render() {
    const { segments } = this.state;
    const { account, advertiser_name, signup_source, username } = this.props;
    const [contextState, setContextState, Context] = this.context

    return (
      <React.Fragment>
        <AccountDetails account={account} segments={segments} signup_source={signup_source} username={username} params={this.props.match.params} history={this.props.history}/>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    account: state.app.user,
    username: state.app.username,
    signup_source: state.app.account.signup_source,
  };
}

// AccountPage.contextType = CacheContext


export default connect(mapStateToProps)(AccountPage);

import React, { useState, useEffect } from "react";
import { Button, Form, Dropdown, Message } from "semantic-ui-react";
import { updateOrCreateOttOffset } from "../../utils/api";
import SummarySelector from "./SummarySelector";

const SettingForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [optionList, setOptionList] = useState();
  const {
    options,
    setOffset,
    currentOffset,
    offset,
    firstMenu,
    offsetType,
    areaKey,
    chartData,
    offsetItem,
    total,
    percentage,
  } = props;

  useEffect(() => {
    setOptionList(options);
  }, [options && options.length]);

  useEffect(() => {
    if (loading) {
      updateOrCreateOttOffset(
        `${offsetType}_offset`,
        areaKey,
        parseInt(currentOffset)
      ).then((data) => setLoading(false));
    }
  }, [loading]);

  const handleChange = (e, data) => {
    const updatedOffset = Object.assign({}, offset[`${offsetType}_offset`], {
      [areaKey]: data.value,
    });
    const newOffset = Object.assign({}, offset, {
      [`${offsetType}_offset`]: updatedOffset,
    });
    setOffset(newOffset);
  };

  const handleAddition = (e, { value }) => {
    const total = chartData[chartData.length - 1][areaKey][1];
    const current = chartData.find((item) => item["offset"] === value);

    if (!current) {
      return;
    }
    setOptionList([
      { text: `${value} mins`, value, pcg: current[areaKey][1] / total },
      ...optionList.map((item) => Object.assign({}, item)),
    ]);
  };

  const type = areaKey.charAt(0).toUpperCase() + areaKey.slice(1);

  const datasetList = offsetItem
    ? [
        {
          value: total,
          label: `Total ${type} events`,
          type: "total",
        },

        {
          value: currentOffset,
          label: "Time window",
          type: "offset",
        },
        {
          value:
            (offsetItem && offsetItem[areaKey] && offsetItem[areaKey][1]) || 0,
          label: `Eligible ${type} events`,
          type: areaKey,
        },
        {
          value: percentage,
          label: "% Classified",
          type: "percentage",
        },
      ]
    : [];

  return (
    <Form
      style={{ marginRight: "10px", marginTop: "10px", marginLeft: "20px" }}
      onSubmit={() => setLoading(true)}
      loading={loading}>
      <Message info>
        <Message.Header>
          Set Time-Window Threshold for {type} events
        </Message.Header>
        <Message.Content>
          The threshold corresponds to the time-window that an IP address will
          be used to link site visitors back to seeing a commercial. Minimum
          threshold interval is 5 min
        </Message.Content>
      </Message>
      <Form.Field>
        <Dropdown
          placeholder="Select offset"
          fluid
          search
          selection
          allowAdditions
          onAddItem={handleAddition}
          options={optionList}
          value={currentOffset}
          onChange={handleChange}
        />
      </Form.Field>
      {offsetItem && (
        <SummarySelector datasetList={datasetList} onClick={() => {}} />
      )}
      <Form.Field style={{ marginTop: "15px" }}>
        <Button primary type="submit">
          Save
        </Button>
      </Form.Field>
    </Form>
  );
};

export default SettingForm;

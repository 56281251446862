import React, { useEffect, useState } from 'react';
import { ContentCard } from '@rockerbox/styleguide';
import { Table, Popup, Button, Icon, Checkbox, Header, Loader, Message } from 'semantic-ui-react';
import { getSubscriptionsStatus, getNotificationSubscriptions, postUnsubscribe, deleteUnsubscribe } from '../../utils/api';
import * as d3 from 'rockerbox_d3_legacy_clone';
import _ from 'lodash';

const CheckboxEval = (props) => {
    const { channel, channelsValue, notificationId, handleCheckbox } = props
    const channelRecord = channelsValue.find(item => channel == item.channel)

    if (!channelRecord) {
        return <Table.Cell />
    }
    return (
        <Table.Cell key={channelRecord.subscription_id}>
            <Checkbox
                defaultChecked={channelRecord.is_subscribed}
                onChange={(e, data) => handleCheckbox(data.checked, channelRecord, notificationId)}
            />
        </Table.Cell>
    )
}

const ConstructRows = (props) => {
    const { channels, data, handleCheckbox } = props
    const channelsValue = data.channel_subscription
    const notificationId = data.label
    return (
        <Table.Row key={notificationId}>
            <Table.Cell collapsing>
                <b>{data.name}</b>
            </Table.Cell>
            <Table.Cell>
                <Popup
                    flowing
                    content={<p>{data.description}</p>}
                    trigger={<Icon name="question circle" style={{ color: "#CACBCD" }} />}
                />
            </Table.Cell>
            {channels.map(obj =>
                <CheckboxEval
                    channel={obj}
                    channelsValue={channelsValue}
                    notificationId={notificationId}
                    handleCheckbox={handleCheckbox}
                />)}
        </Table.Row>)
}

const CreateStatusView = (props) => {
    const { channels, data, handleCheckbox } = props
    return (
        <Table basic='very'>
            <Table.Header>
                <Table.HeaderCell key={'name'} />
                <Table.HeaderCell key={'description'} />
                {channels ? channels.map(col => <Table.HeaderCell key={col} > {col.replace(/(^|-|\s)./g, str => str.toUpperCase())} </Table.HeaderCell>) : []}
            </Table.Header>
            <Table.Body>
                {data.map(row =>
                    <ConstructRows
                        channels={channels}
                        data={row}
                        handleCheckbox={handleCheckbox}
                    />)}
            </Table.Body>
        </Table>
    )
}

const EmptyMessage = () => (
    <div style={{ textAlign: 'center' }}>
        <Icon
            circular
            disabled
            name='info'
            size='big'
            className='m-b-10' />
        <Header
            as='h3'
            disabled
            content='No subscriptions found'
            className='m-0' />
    </div>
)

const Notifications = () => {

    const [subscriptionsData, setSubscriptionsData] = useState(undefined)
    const [advNotificationData, setAdvNotificationData] = useState(undefined)
    const [userSubscriptionData, setUserSubscriptionData] = useState(undefined)
    const [message, setMessage] = useState(undefined)
    const errorMsg = "Error: Some subscriptions were not updated"

    useEffect(() => {
        getSubscriptionsStatus()
            .then(data => setSubscriptionsData(data))
        getNotificationSubscriptions()
            .then(data => setAdvNotificationData(data))
    }, [])
    const availChannels = !advNotificationData ?
        [] : [...new Set(advNotificationData.map(obj => obj.channel))]

    //TODO: Remove d3 use
    useEffect(() => {
        if (!subscriptionsData || !advNotificationData) return
        if (!subscriptionsData.length || !advNotificationData.length) { setUserSubscriptionData([]); return }

        const aggedData = d3.nest()
            .key(row => row.notification_type.id)
            .entries(advNotificationData)
            .flatMap(({ key, values }) => {
                const label = key
                const name = values[0]['notification_type']['name']
                const description = values[0]['notification_type']['description']
                const channel_subscription = values.map(obj => {
                    const unsubscribed = subscriptionsData.find(subObj => subObj.id == obj.subscription_id).unsubscribed.length
                    return {
                        "channel": obj.channel,
                        "subscription_id": obj.subscription_id,
                        "is_subscribed": !unsubscribed,
                        "is_subscribed_origin": !unsubscribed
                    }
                })
                return ({ label, name, description, channel_subscription })
            })
        setUserSubscriptionData(aggedData)
    }, [subscriptionsData, advNotificationData])

    const handleCheckbox = (checked, channelRecord, notificationId) => {
        const data = _.cloneDeep(userSubscriptionData)
        data.find(obj => obj.label == notificationId).channel_subscription
            .find(subObj => subObj.subscription_id == channelRecord.subscription_id).is_subscribed = checked
        setUserSubscriptionData(data)
    }

    const updateSubscription = (subsObj) => {
        if (subsObj.is_subscribed) {
            const unsubscribedId = subscriptionsData.find(obj => obj.id == subsObj.subscription_id).unsubscribed[0].unsubscribed_id
            return deleteUnsubscribe(unsubscribedId)
        }
        return postUnsubscribe(subsObj.subscription_id)
    }

    const handleSave = (userSubscriptionData) => {
        const apiCalls = []
        userSubscriptionData.map(obj => obj.channel_subscription
            .filter(subsObj => {
                (subsObj.is_subscribed != subsObj.is_subscribed_origin) ?
                    apiCalls.push(updateSubscription(subsObj)) : []
            })
        )

        Promise.all(apiCalls).then(responses => {
            const rps = responses.filter(r => "error" in r)
            rps.length > 0 ? setMessage(errorMsg) : setMessage("Saved successfully")

            getSubscriptionsStatus()
                .then(data => setSubscriptionsData(data))
            getNotificationSubscriptions()
                .then(data => setAdvNotificationData(data))
        })

    }

    return (
        <ContentCard title='My Notification Settings' >
            {(!userSubscriptionData) ?
                <Loader active inline='centered' /> : (!userSubscriptionData.length) ?
                    <EmptyMessage /> :
                    <>
                        <CreateStatusView
                            channels={availChannels}
                            data={userSubscriptionData}
                            handleCheckbox={handleCheckbox}
                        />
                        <div vertical style={{ textAlign: 'right' }}>
                            {(!message) ?
                                [] : <Message
                                    compact
                                    color={message == errorMsg ? 'red' : 'green'}
                                    size='large' content={message}
                                    style={{ margin: '5px' }}
                                />
                            }
                            <Button
                                primary
                                size='medium'
                                onClick={() => handleSave(userSubscriptionData)}
                                content='Save'
                            />
                        </div>
                    </>}
        </ContentCard>
    )
}

export default Notifications;
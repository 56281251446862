import React, { useEffect } from 'react';

import { useAtom } from 'jotai';

import Chart from './Chart';
import Filter from './Filter';
import { generateTicks } from './helpers';
import { useDatasets } from './hooks';
import { MainHeader, DailyLabelFormatter, HourlyLabelFormatter } from './parts';
import SummaryTable from './SummaryTable';
import { track, time } from '../../../utils/tracking';
import { eventTypeAtom, relativeDateAtom } from '../../atoms';
import { ViewAccessWrapper } from '../../components';
import { useGlobalState } from '../../hooks/global';
import { numberFormatter } from '../../utils/valueFormatter';

const EventStatus = () => {
  const { account } = useGlobalState();
  const [eventType, setEventType] = useAtom(eventTypeAtom);
  const [date, setDate] = useAtom(relativeDateAtom);
  const {
    chartData, paramsSummary, paramsHourly, // raw data
    updatedChartData, updatedEventStatusData, filteredKeys, // processed data
    tierColorMap, loading,
    groupBy, setGroupBy, // chart groupby
    handleSearchChange,
    handleToggleAll, handleCheckboxChange, selectAllChecked, checkedItems, // checkbox handlers and state for chart
  } = useDatasets(eventType, date);
  const LabelFormatter = groupBy === 'daily' ? DailyLabelFormatter : HourlyLabelFormatter;

  useEffect(() => {
    if (!eventType || !date) return;
    if (!!loading) {
      time('data.event_status.view');
      return;
    }

    track('data.event_status.view', {
      advertiser: account.pixel_source_name,
    });
  }, [eventType, date, loading]);
  return (
    <>
      <MainHeader />
      <Filter {...{ eventType, setEventType, date, setDate }} />
      <Chart {...{
        chartData, tierColorMap, labelFormatter: LabelFormatter, formatDate: generateTicks, formatter: numberFormatter,
        loading, groupBy, setGroupBy,
        handleSearchChange,
        filteredKeys, updatedChartData,
        handleToggleAll, handleCheckboxChange, selectAllChecked, checkedItems,
      }}
      />
      <SummaryTable {...{ updatedEventStatusData, paramsSummary, paramsHourly, tierColorMap, account, loading }} />
    </>
  );
};

const EventStatusAccess = () => (
  <ViewAccessWrapper
    viewName="event_status"
    viewComponent={<EventStatus />}
  />
);

export default EventStatusAccess;

import React, { useCallback, useState, useEffect } from "react";
import { Button, Icon, Form } from "semantic-ui-react";
import {
  checkJob,
  checkJobsCache,
  trackJob
} from "../../utils/job_api";
import { submitSnowflakeDataWarehouseSetup } from "../../utils/api";
import { statusStore, warehouseStore } from "./zustandStore";
import { STATUS_FINISHED, STATUS_FAILED } from "./constants";

const backfillTypeMap = {
  "Setup Snowflake": "snowflakeSetupStatus"
};

const warehouseSelector = state => [state.fetchWarehouse];
const statusSelector = state => [state.setStatus, state.changeSetupFormLoading];

const BackfillCell = props => {
  const [jobStatus, setJobStatus] = useState("unknown");
  const [jobUrl, setJobUrl] = useState(null);
  const {
    accountType,
    clientUrl,
    text,
    filter_id,
    username,
    platform,
    style,
    disabled
  } = props;
  const item = {
    account_type: accountType,
    client_url: accountType === "reader_account" ? null : clientUrl,
    username
  };

  const [setStatus, changeSetupFormLoading] = statusStore(statusSelector);
  const [fetchWarehouse] = warehouseStore(warehouseSelector);

  useEffect(() => {
    if (jobStatus === "error") {
      changeSetupFormLoading(false);
      setStatus(STATUS_FAILED);
    }
    if (jobStatus === "success") {
      fetchWarehouse()
        .then(() => setStatus(STATUS_FINISHED))
        .finally(() => changeSetupFormLoading(false));
    }
  }, [jobStatus]);

  const getJobs = jobKey => id => {
    checkJobsCache(id, jobKey).then(jobStatus => {
      const promises = Object.keys(jobStatus)
        .filter(key => {
          const started = jobStatus[key] == "started";
          const queued = jobStatus[key] == "queued";
          const untracked = jobStatus[key] == "unknown";
          return started || queued || untracked;
        })
        .map(key => checkJob(JSON.parse(key), id, jobKey));

      if (promises.length > 0)
        Promise.all(promises).then(() => {
          setTimeout(() => getJobs(jobKey)(id), 5000);
        });

      setJobStatus(Object.values(jobStatus)[0]);
    });
  };

  const runSnowflakeSetup = item => {
    changeSetupFormLoading(true);
    return submitSnowflakeDataWarehouseSetup(item).then(resp => {
      const job_id = resp["json"]["job_id"];
      trackJob(item, filter_id, "snowflakeSetupStatus", job_id);
      getJobs("snowflakeSetupStatus")(filter_id);
      const jobUrl = `http://wq.internal.getrockerbox.com/v2/workqueue_status/job/${job_id}`;
      setJobUrl(jobUrl);
    });
  };

  const onButtonClick = useCallback(() => runSnowflakeSetup(item));

  return (
    <Form.Field collapsing style={style}>
      <Button color="green" onClick={onButtonClick} disabled={disabled}>
        {text}
      </Button>

      {jobStatus["snowflakeSetupStatus"] == "error" ? (
        <a href={jobUrl} target="_blank">
          <Icon name="warning sign" />
        </a>
      ) : jobStatus["snowflakeSetupStatus"] == "success" ? (
        <a href={jobUrl} target="_blank">
          <Icon name="check circle" />
        </a>
      ) : jobStatus["snowflakeSetupStatus"] != undefined ? (
        <a href={jobUrl} target="_blank">
          <Icon loading name="circle notch" />
        </a>
      ) : null}
    </Form.Field>
  );
};

export default BackfillCell;

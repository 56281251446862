import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { CacheContext } from '../../../../utils/CacheContext';
import { getSegments, getPlatformComparisonConfigs } from '../../../api/attribution';

export const usePlatformComparisonConfig = () => {
  const [comparisonConfigsList, setComparisonConfigsList] = useState(undefined);

  const { platformName } = useParams();
  const [state, setState, Context] = React.useContext(CacheContext); // eslint-disable-line no-unused-vars
  const segments = (Context.getCache('segments', getSegments) || undefined);
  const comparisonConfigs = (Context.getCache('comparisons', getPlatformComparisonConfigs) || undefined);

  const filterConfigsList = (configs, segs) => {
    const platformConfigs = configs?.filter(config => config.platform === platformName);

    const filtered = segs.flatMap(segment => {
      const config = platformConfigs.find(platformConfig => platformConfig.filter_id === segment.action_id);
      if (!config) return [];
      return { text: segment.action_name, value: segment.filter_id, first_reporting_date: segment.first_reporting_date, ...config };
    });

    return filtered;
  };

  const createConfigList = () => {
    if (!segments) return;
    if (!comparisonConfigs) return;

    const filtered = filterConfigsList(comparisonConfigs, segments);
    setComparisonConfigsList(filtered);
  };

  const updateConfigList = () => {
    if (!segments) return;
    getPlatformComparisonConfigs()
      .then(data => {
        const filtered = filterConfigsList(data, segments);
        setComparisonConfigsList(filtered);
      });
  };

  useEffect(() => {
    if (platformName === '0') return;
    createConfigList();
  }, [platformName, segments, comparisonConfigs]);

  return {
    comparisonConfigsList,
    createConfigList,
    updateConfigList,
    segsInfo: segments,
  };
};

export const ConfigWithDefaults = (comparisonConfigsList, platformConfig, segsInfo) => {
  const getDefaultPlatformConfig = () => {
    const conversionEvent = platformConfig.default_conv;
    const attributionWindow = platformConfig.default_attribution_window;
    const defaultMetric = platformConfig.default_metric;
    const optionalMetric = defaultMetric ? platformConfig.optional_metrics[0] : '';
    const segmentId = segsInfo.find(segment => segment.featured)?.filter_id || 0;

    return {
      conversionEvent,
      attributionWindow,
      defaultMetric,
      optionalMetric,
      segmentId,
    };
  };

  const getFirstComparisonConfig = () => {
    const defaultConfig = getDefaultPlatformConfig();
    const config = comparisonConfigsList[0] || {};

    const conversionEvent = config.platform_conversion || defaultConfig.conversionEvent;
    const attributionWindow = platformConfig.default_attribution_window || defaultConfig.attributionWindow;
    const defaultMetric = platformConfig.default_metric || defaultConfig.defaultMetric;
    const optionalMetric = (defaultMetric && platformConfig.optional_metrics[0]) || defaultConfig.optionalMetric;
    const segmentId = config.filter_id;

    return {
      conversionEvent,
      attributionWindow,
      defaultMetric,
      optionalMetric,
      segmentId,
    };
  };

  const getConfigBySegmentId = segId => {
    const defaultConfig = getDefaultPlatformConfig();
    const config = comparisonConfigsList.find(({ filter_id }) => filter_id === segId) || getFirstComparisonConfig();

    const conversionEvent = config.platform_conversion || defaultConfig.conversionEvent;
    const attributionWindow = platformConfig.default_attribution_window || defaultConfig.attributionWindow;
    const defaultMetric = platformConfig.default_metric || defaultConfig.defaultMetric;
    const optionalMetric = (defaultMetric && platformConfig.optional_metrics[0]) || defaultConfig.optionalMetric;
    const segmentId = config.filter_id;

    return {
      conversionEvent,
      attributionWindow,
      defaultMetric,
      optionalMetric,
      segmentId,
    };
  };

  return {
    getFirstComparisonConfig,
    getConfigBySegmentId,
    getDefaultPlatformConfig,
  };
};

import React, { useState } from 'react';
import shallow from 'zustand/shallow';
import _ from 'lodash';
import { IndexGridNew } from '@rockerbox/styleguide';
import { Table, Loader, Label, Icon } from 'semantic-ui-react';
import { updateEntityData, deleteEntityData } from '../../../api/attribution';
import { entityStore } from '../hooks/entityStore';

export const ManageCell = ({ item }) => (
  <Table.Cell>
    <IndexGridNew.EditButton size="tiny" url={`/v3/data/marketing/promo_codes/edit/${item.id}`} />
    <IndexGridNew.DeleteButton
      size="tiny"
      onDelete={() => {
        deleteEntityData(item.id)
          .then(() => {
            window.location.reload();
          });
      }}
    />
  </Table.Cell>
);

export const PromoCodeCell = ({ item }) => (
  <Table.Cell>
    {item.promo_codes.length > 0 && item.promo_codes.map(c => c.name).join(', ')}
  </Table.Cell>
);

export const HasCell = ({ item, col }) => (
  <Table.Cell>
    {' '}
    { item[col.key].length > 0 ? <Icon name="check" /> : '' }
    {' '}
  </Table.Cell>
);

export const DisplayCell = ({ item, col }) => {
  const [loading, setLoading] = useState(false);
  const [set] = entityStore(store => [store.set], shallow);

  const onClick = () => {
    const { program } = col;
    const entity = _.cloneDeep(item);
    const { id } = entity;
    const { tier_1, tier_2, tier_3, tier_4, tier_5 } = program;
    const display = { tier_1, tier_2, tier_3, tier_4, tier_5 };
    entity.display = [display];

    setLoading(true);
    updateEntityData(entity, id)
      .then(data => {
        set({ entityConfigs: data });
        setLoading(false);
      });
  };

  return (
    <Table.Cell>
      { loading ? <div style={{ width: '14px', height: '14px', display: 'inline-block', position: 'relative' }}><Loader active size="mini" /></div>
        : item[col.key] ? <Icon name="check" />
          : <Label {...{ onClick }} style={{ cursor: 'pointer' }} size="tiny"> Apply Program Display Rule </Label>}
    </Table.Cell>
  );
};

import { useState, useEffect } from 'react';
import { getSavedReports, deleteSavedReport, postSavedReport, putSavedReport } from '../../../api/attribution';

export const useSavedReports = () => {
  const [savedReports, setSavedReports] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleGetSavedReports = () => (
    getSavedReports()
      .then(saved => setSavedReports([...saved]))
  );

  useEffect(() => {
    setLoading(true);
    handleGetSavedReports()
      .then(() => setLoading(false));
  }, []);

  const deleteReport = id => (
    deleteSavedReport(id)
      .then(() => {
        handleGetSavedReports();
      })
  );

  const postReport = data => (
    postSavedReport(data)
      .then(x => {
        handleGetSavedReports();
        return x;
      })
  );

  const updateReport = (data, id) => (
    putSavedReport(data, id)
      .then(x => {
        handleGetSavedReports();
        return x;
      })
  );

  return {
    loading,
    savedReports, setSavedReports, handleGetSavedReports,
    deleteSavedReport: deleteReport,
    postSavedReport: postReport,
    updateSavedReport: updateReport,
  };
};

export const buildUpdateFieldValues = (fieldValues, setFieldValues) => (field, key, value) => {
  const current = fieldValues[field];
  const update = { [key]: value };

  if (key === 'value' && current.selection !== 'custom') {
    // don't allow value to be set unless is custom
    delete update.value;
  }
  if (key === 'value' && current.selection === 'custom') {
    // don't allow value to be set unless is custom
    update[key] = parseInt(value);
  }
  if (key === 'selection' && value !== 'custom') {
    // set to selections default value
    update.value = current[value];
  }

  const future = { [field]: { ...current, ...update } };

  setFieldValues(future);
};

import { atom } from 'jotai';

import { tomorrow, monthFromToday } from '../parts/time';

// SCENARIO PLANNER
export const startDateProjectionAtom = atom(tomorrow);
export const endDateProjectionAtom = atom(monthFromToday);
export const startDateBudgetAtom = atom(tomorrow);
export const endDateBudgetAtom = atom(monthFromToday);
export const startDateBaselineAtom = atom(false);
export const endDateBaselineAtom = atom(false);
export const optimizationAtom = atom(false);
export const budgetAtom = atom(false);

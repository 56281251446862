import React, { useMemo, useEffect, useState } from 'react';
import { Form, Dropdown, Button, Loader, Label } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { useAtom } from 'jotai';
import { tier1Atom, tier2Atom, tier3Atom, tier4Atom, tier5Atom } from '../../../atoms';
import { readableDateFromPreset } from '../../../utils/time';
import { LowBudgetMessage, NoEmaMessage } from './parts';
import { FormSummaryTable } from '../../../components';

const metricOptionsList = [{ text: 'CPA', value: 'cpa', key: 'cpa' }, { text: 'ROAS', value: 'roas', key: 'roas' }];
const targetOptions = [
  { text: '7 Day Exponential Moving Average', value: 'rolling', key: 'rolling' },
  { text: 'Fixed Target', value: 'fixed', key: 'fixed' },
];

const GoalsForm = props => {
  const {
    conversionOptions, convValue,
    attributionWindowOptions, windowValue,
    startDate, endDate,
    platformDisplayName,
    selectedColumns, setSelectedColumns, setGoal,
    onOptionalMetricChange,
    filledPerformance, onClose,
    open, optional_metrics,
    totalSpend,
  } = props;

  // can't create cpa rolling average for zero conversion day
  const zeroConversions = useMemo(() => filledPerformance?.find(c => c.conversions === 0), [filledPerformance]);

  // local state
  const [goalTargetOptions, setGoalTargetOptions] = useState(targetOptions);
  const [metricOptions, setMetricOptions] = useState(metricOptionsList);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // hooks
  const readableDate = readableDateFromPreset(startDate, endDate);

  // jotai
  const [tier_1] = useAtom(tier1Atom);
  const [tier_2] = useAtom(tier2Atom);
  const [tier_3] = useAtom(tier3Atom);
  const [tier_4] = useAtom(tier4Atom);
  const [tier_5] = useAtom(tier5Atom);

  // form values
  const { control, handleSubmit, formState: { errors }, setValue, trigger, reset, watch } = useForm({
    defaultValues: {
      metric: 'cpa',
      type: 'rolling',
      target: '',
      name: '',
      shared: 0,
      budget: '',
    },
  });

  const typeWatcher = watch('type');
  const metricWatcher = watch('metric');
  const budgetWatcher = watch('budget');

  useEffect(() => {
    if (!optional_metrics.find(e => e === 'roas')) {
      setMetricOptions([{ text: 'CPA', value: 'cpa', key: 'cpa' }]);
    }

    if (zeroConversions && metricWatcher === 'cpa') {
      setGoalTargetOptions([{ text: 'Fixed Target', value: 'fixed', key: 'fixed' }]);
      setValue('type', 'fixed');
    } else {
      setGoalTargetOptions(targetOptions);
      setValue('type', 'rolling');
    }
  }, [zeroConversions, metricWatcher, optional_metrics]);

  const lessThan5PercentBudget = useMemo(() => {
    const lessThan5Percent = (totalSpend * 0.05) > budgetWatcher && budgetWatcher !== '';
    return lessThan5Percent;
  }, [budgetWatcher]);

  const onSubmit = data => {
    setIsSubmitting(true);
    const { metric, type, target, budget } = data;
    const goal = {
      target: type === 'rolling' ? null : target,
      metric,
      type,
      budget: budget === '' ? null : budget,
    };

    const goalsAndSelectedCols = [
      selectedColumns[0],
      metric,
      'goal',
      'recommendations',
      ...selectedColumns
        .slice(1)
        .filter(({ key }) => key !== metric)];

    onOptionalMetricChange(undefined, { value: metric });
    setGoal(goal);
    setSelectedColumns(goalsAndSelectedCols);
    onClose();
    setIsSubmitting(false);
  };

  const resetFormAndClose = () => {
    reset({
      metric: 'cpa',
      type: zeroConversions ? 'fixed' : 'rolling',
      target: '',
      name: '',
      shared: 0,
      budget: '',
    });
  };

  useEffect(() => {
    if (!open) resetFormAndClose();
  }, [open]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Loader active={isSubmitting} />
      <h2>Set a Goal</h2>
      <Form.Field disabled={isSubmitting}>
        <label htmlFor="goal-metric">Goal Metric</label>
        <Controller
          name="metric"
          control={control}
          render={({ field }) => (
            <Dropdown
              {...field} // eslint-disable-line react/jsx-props-no-spreading
              selection
              options={metricOptions}
              onChange={(e, { name, value }) => {
                setValue(name, value);
                trigger(name);
              }}
              placeholder="Select"
              fluid
              error={errors.metric?.message}
              id="goal-metric"
            />
          )}
          rules={{ required: 'Missing Goal Metric' }}
        />
        {errors.metric?.message && <div className="ui pointing above prompt label">{errors.metric.message}</div>}
      </Form.Field>

      <Form.Field disabled={isSubmitting}>
        <label htmlFor="goal-target">Goal Target</label>
        <p>
          The default target is the 7 Day Exponential Moving Average over the specified date range, but you can also set a fixed goal as well.
        </p>
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <Dropdown
              {...field}  // eslint-disable-line react/jsx-props-no-spreading
              selection
              options={goalTargetOptions}
              onChange={(e, { name, value }) => {
                setValue(name, value);
                trigger(name);
              }}
              placeholder="Select"
              fluid
              error={errors.type?.message}
              id="goal-target"
            />
          )}
          rules={{ required: 'Missing Target Type' }}
        />
        {errors.type?.message && <div className="ui pointing above prompt label">{errors.type.message}</div>}
      </Form.Field>

      {(typeWatcher === 'fixed') && (
        <Form.Field disabled={isSubmitting}>
          <Controller
            fluid
            name="target"
            control={control}
            render={({ field }) => (
              <Form.Input
                {...field}  // eslint-disable-line react/jsx-props-no-spreading
                fluid
                error={errors.target?.message}
                type="number"
                placeholder={metricWatcher === 'cpa' ? '30.00' : '1.2'}
              />
            )}
            rules={{ required: 'Missing target value', min: { value: 0.1, message: 'Minimum target value is .1' } }}
          />
        </Form.Field>
      )}
      {(zeroConversions && metricWatcher === 'cpa') && <NoEmaMessage optionalMetrics={optional_metrics} />}

      <Form.Field disabled={isSubmitting}>
        <label htmlFor="optional-budget">
          Budget (optional)
          <Label size="mini" style={{ marginLeft: 5 }}>
            Beta
          </Label>
        </label>
        <Controller
          fluid
          name="budget"
          control={control}
          render={({ field }) => (
            <Form.Input
              {...field}  // eslint-disable-line react/jsx-props-no-spreading
              fluid
              error={errors.budget?.message}
              type="number"
              placeholder={1000}
              id="optional-budget"
            />
          )}
          rules={{ min: { value: 1, message: 'Minimum budget value is 1' } }}
        />
      </Form.Field>
      {(lessThan5PercentBudget) && <LowBudgetMessage />}

      <FormSummaryTable
        tableHeading="Summary"
        summary={[
          { key: 'Ad Platform', text: 'Ad Platform', value: platformDisplayName },
          { key: 'Conversion', text: 'Conversion', value: conversionOptions.find(c => c.value === convValue).text },
          { key: 'Attribution Window', text: 'Attribution Window', value: attributionWindowOptions.find(c => c.value === windowValue).text, collapsing: true }, // eslint-disable-line max-len
          { key: 'Date Range', text: 'Date Range', value: readableDate },
          { key: 'Tier Filters', text: 'Tier Filters', value: { tier_1, tier_2, tier_3, tier_4, tier_5 } },
          { key: 'Metrics', text: 'Metrics', value: selectedColumns.map(c => c.display).join(', ') },
        ]}
      />

      <Button type="submit" primary style={{ marginRight: 10 }} disabled={isSubmitting}>Apply Goal</Button>
      <Button onClick={() => onClose()} color="purple" inverted type="button" disabled={isSubmitting}>Cancel</Button>
    </Form>
  );
};

export default GoalsForm;

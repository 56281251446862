import React from 'react';
import { Statistic } from 'semantic-ui-react';
import { CURRENCY_GREEN } from './constants';
import { formatNumber } from '../AttributionReport/helpers';

const SpendCard = ({ statisticLabel, alignLabel = '', statisticValue, labelTop = true, fontWeight = 300, format, maxDigits, icon, iconBg, currencyCode }) => {
  const color = CURRENCY_GREEN;
  const item = { format, maxDigits };
  return (
    <div className="statistics-card">
      <Statistic vertical size="tiny" className="chart-card-statistic">
        {labelTop && <Statistic.Label style={{ textAlign: alignLabel, marginBottom: 5, opacity: 0.8 }}>{statisticLabel}</Statistic.Label>}
        <Statistic.Value style={{ color, fontWeight }}>
          {formatNumber(item, statisticValue, currencyCode)}
        </Statistic.Value>
        {!labelTop && <Statistic.Label style={{ textAlign: 'left' }}>{statisticLabel}</Statistic.Label>}
        {!!icon && (
          <div className="bubble-icon" style={{ background: iconBg }}>
            {icon}
          </div>
        )}
      </Statistic>
    </div>
  );
};

export default SpendCard;

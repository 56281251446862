import React from 'react';
import { Table } from 'semantic-ui-react';
import { writeCell } from './parts';

export const GridRow = ({ item, cols, expandKey, setExpandKey, idKey, expandedComponent }) => {
  const isExpanded = expandKey === item[idKey];
  const ExpandedSection = expandedComponent;
  const handleRowExpand = () => {
    setExpandKey(isExpanded ? '' : item[idKey]);
  };

  return (
    <>
      <Table.Row>
        {cols.map((col, index) => writeCell(item, col, index, handleRowExpand, isExpanded))}
      </Table.Row>
      {(expandKey === item[idKey]) && (
        <Table.Row>
          <Table.Cell colSpan="100" style={{ borderTop: 'none' }}>
            <ExpandedSection {...{ item, cols }} />
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
};

export default GridRow;

import React, { useMemo, useState, useEffect } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { TrendingUpIcon } from '@heroicons/react/outline';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ChartBarIcon } from '@heroicons/react/solid';
import { ComboChartLoader } from '@rockerbox/styleguide';
import { ResponsiveContainer, ComposedChart, XAxis, YAxis, Tooltip, Line, Bar } from 'recharts';
import { Segment, Dropdown, Icon } from 'semantic-ui-react';

import { composeHatchableBar, generateColors, transformData, containsDottedValues } from './helpers';
import { extractAndDeduplicateDates } from '../../../utils/dateFormatters';
import { reformatDateMMDDYYYY } from '../../../utils/time';

const BAR_CHART_OPTIONS = [
  {
    text: 'Spend',
    value: 'spend',
    key: 'spend',
  },
  {
    text: 'Revenue',
    value: 'revenue',
    key: 'revenue',
  },
  {
    text: 'Conversions',
    value: 'conversions',
    key: 'conversions',
  },
];

const LINE_CHART_OPTIONS = [
  {
    text: 'Blended ROAS',
    value: 'roas',
    key: 'roas',
  },
  {
    text: 'Blended CPA',
    value: 'cpa',
    key: 'cpa',
  },
];

const formatNumber = number => {
  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
  });
  return numberFormat.format(number);
};

const ToolTipRow = ({ name, value, color, payload, currencyFormatter, barChartMetric, i, showTotal, isLastRow }) => {
  const { spend_total } = payload[i].payload;
  const nonCurrencyStats = ['conversions', 'roas'];
  const formatter = nonCurrencyStats.includes(name) ? formatNumber : currencyFormatter(2);
  const displayName = name.replaceAll('_', ' ').replaceAll('-', ' ')
    .replace('cpa', 'CPA')
    .replace('roas', 'ROAS');
  const suffix = barChartMetric === 'spend' && !['roas', 'roas_undotted', 'cpa', 'cpa_undotted'].includes(name) ? ' Spend' : '';

  return (
    <>
      <p
        key={name}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: isLastRow ? 0 : 10,
          textTransform: 'capitalize',
        }}
      >
        <span style={{ marginRight: 20 }}>
          <Icon name="square" style={{ color }} />
          {`${displayName}${suffix}: `}
        </span>
        <span style={{ fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
          {formatter(value)}
        </span>
      </p>
      {showTotal && (
        <p
          key="total"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            paddingTop: '10px',
            borderTop: '1px solid grey',
            fontWeight: 'bold',
          }}
        >
          <span style={{ marginRight: 20 }}>Total</span>
          <span style={{ fontFeatureSettings: '"kern" 1, "tnum" 1' }}>{formatter(spend_total)}</span>
        </p>
      )}
    </>
  );
};

const ComboChart = ({ loading, data, platforms, hasRevenue, currencyFormatter, spendStatusRollup, conversionStatusRollup, syntheticStatusRollup, isTestAccount }) => {
  const [barChartMetric, setBarChartMetric] = useState('spend');
  const [lineChartMetric, setLineChartMetric] = useState('roas');
  const [transformedData, setTransformedData] = useState(data);

  const failureDates = useMemo(() => {
    const spendDates = extractAndDeduplicateDates(spendStatusRollup);
    const conversionDates = extractAndDeduplicateDates(conversionStatusRollup);
    const syntheticDates = extractAndDeduplicateDates(syntheticStatusRollup);
    return [...new Set([...spendDates, ...conversionDates, ...syntheticDates])];
  }, [spendStatusRollup, conversionStatusRollup, syntheticStatusRollup]);

  const barChartFailureDates = useMemo(
    () => (barChartMetric === 'spend'
      ? extractAndDeduplicateDates(spendStatusRollup)
      : extractAndDeduplicateDates(conversionStatusRollup).concat(extractAndDeduplicateDates(syntheticStatusRollup))),
    [barChartMetric, spendStatusRollup, conversionStatusRollup, syntheticStatusRollup],
  );

  const HatchedBar = composeHatchableBar(barChartFailureDates);

  const keysToRender = [lineChartMetric];
  const keysDottedToRender = failureDates.length > 0 ? [lineChartMetric] : [];

  useEffect(() => {
    if (!data) return;
    // in order to indicate the status of the data powering the line chart, we need to maintain two datasets, one 'dotted', the other 'undotted'
    // we set days with bad data to be dotted, otherwise undotted, and then lay these lines on top of eachother so its continuous
    const statusIndicatedData = transformData(data, failureDates);
    setTransformedData(statusIndicatedData);
  }, [data, failureDates]);

  const [barChartOptions, lineChartOptions] = useMemo(() => {
    if (!hasRevenue) {
      setLineChartMetric('cpa');
      return [
        BAR_CHART_OPTIONS.filter(x => x.value !== 'revenue'),
        LINE_CHART_OPTIONS.filter(x => x.value !== 'roas'),
      ];
    }
    setLineChartMetric('roas');
    return [BAR_CHART_OPTIONS, LINE_CHART_OPTIONS];
  }, [hasRevenue]);

  const colors = useMemo(
    () => !!platforms && generateColors(platforms.length),
    [platforms],
  );

  const toolTipLabelFormatter = v => {
    const hasDate = Object.keys(data[0]).includes('date');
    if (hasDate) {
      return reformatDateMMDDYYYY(v);
    }
    return v;
  };

  const findPlatformsByFailureDate = (failureData, dateString) => {
    if (!failureData) return;
    const targetDate = `${dateString} 00:00:00`;
    const platformsWithFailures = failureData.failures.filter(failure => failure.dates.includes(targetDate)).map(failure => failure.platform.charAt(0).toUpperCase() + failure.platform.slice(1));

    return platformsWithFailures;
  };

  const platformsWithIssuesThatDay = (date, isDotted) => {
    if (!isDotted || !spendStatusRollup || barChartMetric !== 'spend') {
      return [];
    }
    const filteredData = findPlatformsByFailureDate(spendStatusRollup, date);
    return filteredData;
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const ChartTooltip = ({ active, payload, label, showSpendTotal }) => {
    if (active && payload && payload.length) {
      const isDotted = containsDottedValues(payload);
      const filteredPayload = payload.filter(({ name }) => !['roas_undotted', 'cpa_undotted'].includes(name));
      const problemPlatforms = platformsWithIssuesThatDay(label, isDotted);
      return (
        <div style={{
          borderRadius: 4,
          background: '#fff',
          color: '#000',
          boxShadow: '0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15)',
          paddingBottom: isDotted ? '0' : '5px',
        }}
        >
          <p
            style={{
              fontSize: '14px',
              color: '#4d4d4d',
              padding: '10px 10px 0 10px',
            }}
          >
            {toolTipLabelFormatter(label)}
          </p>
          {filteredPayload.map(({ name, value, color }, i) => (
            <div style={{ padding: '0 10px' }}>
              <ToolTipRow
                key={name}
                {...{
                  name,
                  value,
                  color,
                  payload: filteredPayload,
                  currencyFormatter,
                  barChartMetric,
                  i,
                  showTotal: i === filteredPayload.length - 2 && showSpendTotal,
                  isLastRow: i === filteredPayload.length - 1,
                }}
              />
            </div>
          ))}
          {isDotted && problemPlatforms.length > 0 && (
            <div style={{
              width: '100%',
              textAlign: 'center',
              backgroundColor: '#e9eaf1',
              padding: '4px 0 0 0',
              borderBottomLeftRadius: '4px',
              borderBottomRightRadius: '4px',
            }}
            >
              Incomplete day:
              <ul style={{ listStyle: 'none', padding: 0, marginTop: '-3px' }}>
                {problemPlatforms.map(platform => (
                  <li key={platform} style={{ fontSize: '12px', marginTop: '-3px' }}>
                    {platform}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  if (!!loading) return <ComboChartLoader />;

  return (
    <Segment>
      <div className="card-metric-selectors-wrap">
        <div className={`card-metric-selector ${barChartMetric === 'spend' ? 'purple' : barChartMetric === 'revenue' ? 'green' : 'blue'}`}>
          <ChartBarIcon className="icon" />
          <Dropdown
            inline
            selection
            selectOnBlur={false}
            options={barChartOptions}
            value={barChartMetric}
            onChange={(_, { value }) => setBarChartMetric(value)}
          />
        </div>
        <div className={`card-metric-selector ${lineChartMetric === 'roas' ? 'orange' : 'pink'}`}>
          <TrendingUpIcon className="icon" />
          <Dropdown
            inline
            selection
            selectOnBlur={false}
            options={lineChartOptions}
            value={lineChartMetric}
            onChange={(_, { value }) => setLineChartMetric(value)}
          />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={360}>
        <ComposedChart data={transformedData} barCategoryGap={2}>
          <defs>
            <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
              <path d="M 0,0 l 4,4" stroke="black" strokeWidth="1" />
            </pattern>
          </defs>
          <XAxis hide dataKey="date" />
          <YAxis hide yAxisId="bar" />
          <YAxis hide yAxisId="line" />
          {/* we draw a stacked bar chart for spend, hatching to incidate bad data */}
          {platforms.map((platform, i) => isTestAccount && barChartMetric === 'spend' && (
            <Bar key={platform} dataKey={platform} yAxisId="bar" stackId="spend" shape={<HatchedBar />} fill={colors[i]} />
          ))}
          {!isTestAccount && barChartMetric === 'spend' && platforms.map((platform, i) => (
            <Bar key={platform} dataKey={platform} yAxisId="bar" stackId="spend" fill={colors[i]} stroke="#fff" strokeWidth={3} />
          ))}
          {/* we draw a simple bar chart for non-spend, hatching to incidate bad data */}
          {((isTestAccount && barChartMetric !== 'spend') || (!isTestAccount && barChartMetric !== 'spend')) && (
            <Bar
              key={barChartMetric}
              dataKey={barChartMetric}
              yAxisId="bar"
              shape={isTestAccount ? <HatchedBar /> : undefined}
              fill={barChartMetric === 'revenue' ? '#66D9AE' : '#4987BF'}
              stroke={!isTestAccount ? '#fff' : undefined}
              strokeWidth={!isTestAccount ? 3 : undefined}
            />
          )}
          {/* we draw two lines, one dotted, one solid, and lay them on top of one another to indicate when there's bad data */}
          {isTestAccount && keysDottedToRender && (
          <Line
            key={`${lineChartMetric}`}
            dataKey={`${lineChartMetric}`}
            yAxisId="line"
            type="linear"
            stroke={lineChartMetric === 'roas' ? '#ffa64d' : '#E76D8E'}
            strokeWidth={3}
            dot={false}
            activeDot={false}
            strokeDasharray="3 3"
            connectNulls={false}
          />
          )}
          {isTestAccount && keysToRender && (
            <Line
              key={`${lineChartMetric}_undotted`}
              dataKey={`${lineChartMetric}_undotted`}
              yAxisId="line"
              type="linear"
              stroke={lineChartMetric === 'roas' ? '#ffa64d' : '#E76D8E'}
              strokeWidth={3}
              dot={false}
              activeDot={false}
              connectNulls={false}
            />
          )}
          {!isTestAccount && (
            <Line
              key={lineChartMetric}
              dataKey={lineChartMetric}
              yAxisId="line"
              type="monotone"
              stroke={lineChartMetric === 'roas' ? '#ffa64d' : '#E76D8E'}
              strokeWidth={3}
              dot={false}
            />
          )}
          <Tooltip
            cursor={{ stroke: '#efefef', strokeWidth: 2, strokeDasharray: '5 5' }}
            content={<ChartTooltip />}
            position={{ y: 0 }}
            showSpendTotal={barChartMetric === 'spend'}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Segment>
  );
};

export default ComboChart;

export const eventOptions = [{ text: 'On-Site Pixels', value: 'pixel-events-qa', key: 'pixel-events-qa' }];

export const dateOptions = [
  {
    text: 'Today',
    key: 'today',
    value: 'today',
  },
  {
    text: 'Yesterday',
    key: 'yesterday',
    value: 'yesterday',
  },
  {
    text: 'Last 7 Days',
    key: 'last7',
    value: 'last7',
  },
  {
    text: 'Last 14 Days',
    key: 'last14',
    value: 'last14',
  },
];

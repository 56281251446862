import React, { useState, useEffect } from 'react';
import { Form, Dropdown, Button, Input } from 'semantic-ui-react';
import { get } from 'lodash';
import { map } from 'lodash/fp';

import { useDatalake } from '../DatalakeContext/hooks';
import { useStepsDisplay, useStepsState } from '../StepsSectionContext';
import { postGCPSetup } from '../../../api/datalake';
import { BIGQUERY_REGIONS, GCP_STORAGE_MULTI_REGIONS } from '../constants';
import ErrorMessage from '../components/ErrorMessage';

const getRegionOptions = map(region => {
  if (GCP_STORAGE_MULTI_REGIONS.includes(region)) {
    return { text: `${region} (multi-region)`, value: region };
  }
  return { text: region, value: region };
});

const SetupShare = () => {
  const { datalakeConnection } = useDatalake();
  const [stepDisplay, setStepDisplay] = useStepsDisplay();
  // eslint-disable-next-line no-unused-vars
  const [_, setStepsStateData] = useStepsState();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [region, setRegion] = useState('');
  const [projectId, setProjectId] = useState('');
  const [datasetId, setDatasetId] = useState('');

  const regions = getRegionOptions(BIGQUERY_REGIONS);

  // eslint-disable-next-line no-shadow
  const handleSelectRegion = (_, { value }) => setRegion(value);
  // eslint-disable-next-line no-shadow
  const handleProjectIdInput = (_, { value }) => setProjectId(value);
  // eslint-disable-next-line no-shadow
  const handleDatasetIdInput = (_, { value }) => setDatasetId(value);

  useEffect(() => {
    if (datalakeConnection?.details) {
      const {
        details: {
          data_location,
          project_id,
          dataset_id,
        },
        platform_metadata: { service_account_email },
      } = datalakeConnection;
      setStepDisplay(1, true);
      setRegion(data_location);
      setProjectId(project_id);
      setDatasetId(dataset_id);
      setStepsStateData({
        serviceAccountEmail: service_account_email,
      });
    }
  }, [datalakeConnection]);

  const handleSetupShare = () => {
    setLoading(true);
    return postGCPSetup({ region, projectId, datasetId })
      .then(res => {
        if (res.data.status === 'success') {
          setLoading(false);
          const serviceAccountEmail = get(res.data, 'platform_metadata.service_account_email');
          setStepsStateData({
            serviceAccountEmail,
          });
          setStepDisplay(1, true);
        }
      })
      .catch(e => {
        setLoading(false);
        setError(e.response.data.message);
      });
  };

  return (
    <Form>
      <Form.Field width={4}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>Select region</label>
        <Dropdown
          placeholder="Select region"
          selection
          options={regions}
          value={region}
          onChange={handleSelectRegion}
          disabled={stepDisplay[1]}
        />
      </Form.Field>
      <Form.Field width={4}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>Project ID</label>
        <Input
          placeholder="Enter project id"
          value={projectId}
          onChange={handleProjectIdInput}
          disabled={stepDisplay[1]}
        />
      </Form.Field>
      <Form.Field width={4}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>Dataset ID</label>
        <Input
          placeholder="Enter dataset id"
          value={datasetId}
          onChange={handleDatasetIdInput}
          disabled={stepDisplay[1]}
        />
      </Form.Field>
      <Button
        primary
        onClick={handleSetupShare}
        content="Set up BigQuery"
        disabled={!region || loading || stepDisplay[1]}
        {...{ loading }}
      />
      {error && <ErrorMessage message={error} />}
    </Form>
  );
};

export default SetupShare;

import React from 'react';

import { Segment, Table, Dropdown, Grid, Input, Button } from 'semantic-ui-react';

import { CardLoader } from '../ContentCard/ContentCard';
// eslint-disable-next-line import/no-cycle
import SortableIndexGrid from '../SortableIndexGrid';

const columnsToOptions = arr => arr.map(({ key }) => ({ key, value: key, text: key }));

const SearchableIndexGrid = props => {
  const { cols, data } = props;
  const keys = cols.filter(row => !row.as).map(({ key }) => key);
  const filterableColumns = cols.filter(row => !!row.isFilterable);

  const [searchValue, setSearchValue] = React.useState(data);
  const [filteredData, setFilteredData] = React.useState(data);
  const [filters, setFilters] = React.useState([]);
  const [filterOptions, setFilterOptions] = React.useState({});

  React.useEffect(() => {
    // eslint-disable-next-line array-callback-return
    data.map(row => {
      // eslint-disable-next-line no-param-reassign
      row.searchField = keys.map(key => row[key]).join('_').toLowerCase();
    });

    // eslint-disable-next-line no-shadow
    const filterOptions = filterableColumns.reduce((p, c) => {
      // eslint-disable-next-line no-param-reassign, no-shadow
      p[c.key] = Object.keys(data.reduce((p, row) => {
        // eslint-disable-next-line no-param-reassign
        p[row[c.key]] = 1;
        return p;
      }, {}))
        .map(text => ({ text, value: text, key: text }));

      return p;
    }, {});

    setFilterOptions(filterOptions);
    setFilteredData(data);
  }, [data]);

  React.useEffect(() => {
    const filtered = data
      .filter(row => row.searchField.indexOf(searchValue) > -1)
      .filter(row => filters
        .reduce((p, c) => p && (c.value ? row[c.field] === c.value : true), true));

    setFilteredData(filtered);
  }, [searchValue, filters, data]);

  const search = (evt, { value }) => setSearchValue(value);

  const addFilter = () => setFilters([...filters, {}]);
  const removeFilter = i => () => {
    const subset = [...filters];
    subset.splice(i, 1);
    setFilters(subset);
  };

  const updateFilter = (pos, field) => (evt, { value }) => {
    // eslint-disable-next-line no-undef
    const filterCopy = _.cloneDeep(filters);
    filterCopy[pos][field] = value;
    setFilters(filterCopy);
  };

  const headerRows = (
    <tr>
      <td colSpan={100}>
        <Grid columns="equal">
          <Grid.Column>
            {
          filters.map((filter, i) => (
            <Segment secondary style={{ fontSize: '.8em' }}>
              <span style={{ fontSize: '1.25em' }}>
                { i === 0 ? 'Where' : 'and where'}
&nbsp;
              </span>
              <Dropdown size="mini" selection options={columnsToOptions(filterableColumns)} onChange={updateFilter(i, 'field')} />
              <span style={{ fontSize: '1.25em' }}>&nbsp; contains &nbsp;</span>
              <Dropdown size="mini" selection multi options={filterOptions[filter.field]} onChange={updateFilter(i, 'value')} />
              <Button icon="remove" size="mini" color="red" onClick={removeFilter(i)} />
            </Segment>
          ))
        }
            <Button size="mini" icon="filter" onClick={addFilter} />
          </Grid.Column>
          <Grid.Column style={{ textAlign: 'right' }}>
            <Input value={searchValue} size="mini" icon="search" onChange={search} />
          </Grid.Column>
        </Grid>
      </td>
    </tr>
  );

  if (filteredData.length === 0) {
    return (
      <Table>
        <Table.Header>{ headerRows }</Table.Header>
        <CardLoader />
      </Table>
    );
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <SortableIndexGrid {...props} {...{ headerRows, cols }} data={filteredData} />;
};

export default SearchableIndexGrid;

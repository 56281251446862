import React from 'react';
import moment from 'moment';
import { sumRecursive, TreeRow } from '../TransformTables/TreeTableData';

export const FieldPreview = ({ data, field, responseScaffold, ButtonComponent = false, RowComponent = false }) => {

  const responseParents = !responseScaffold ? false :
    responseScaffold.reduce((obj, c) => {
      const { key } = c
      obj[key] = c
      return obj
    }, {})
   
  const values = d3.nest()
    .key(row => row.base_id )
    .rollup(values => {
      const parent = values.find(row => responseParents[row[field]])
      const child = values.find(row => !responseParents[row[field]])
      return { response : parent, followup: child, count: values.length }
    })
    .entries(data.sort((p,c) => moment(c.timestamp) - moment(p.timestamp)))
    .filter(row => {
      return !row.values.response && !!row.values.followup
    })
    .reduce((obj,c) => {
      if (c.values.followup[field]) obj[c.values.followup[field]] = (obj[c.values.followup[field]] || 0) + 1
      return obj
    },{})

  const listValues = Object.entries(values)
    .sort((p,c) => c[1] - p[1])

  const Row = RowComponent || DefaultRow

  return <>
    { 
      listValues
        .map(([key, value]) => {
          return <Row>
            <div style={{flex:2}}>{ key }</div>
            <div style={{flex:1}}>{ value }</div>
            { 
              ButtonComponent && <div style={{flex:1}}><ButtonComponent value={key} /></div> 
            }
          </Row>
        }) 
    }
  </>
}



export const NestedFieldPreview = ({ data, fields, responseScaffold, rollup, controlColumn, defaultOpen = 0 }) => {

  const rollupFunc = rollup || ((values) => values.map(obj => { return {row: obj, count: 1 }} ))

  const responseParents = !responseScaffold ? false :
    responseScaffold.reduce((obj, c) => {
      const { key } = c
      obj[key] = c
      return obj
    }, {})
   
  const values = React.useMemo(() => {
    const values = fields.reduce((p,c) => p.key(row => row[c]), d3.nest())
      .rollup(rollupFunc)
      .entries(data.sort((p,c) => moment(c.timestamp) - moment(p.timestamp)))
      .filter(row => responseParents ? responseParents[row.key] : true )

    values.map(sumRecursive)
    return values.sort((p,c) => c.count - p.count)
  }, [fields, data.length])

  return values.map(row => (<TreeRow {...{defaultOpen, row, controlColumn}} />))
}

import React, {
  createContext,
  // eslint-disable-next-line no-unused-vars
  useContext,
  useState,
  useEffect,
} from 'react';
import { get } from 'lodash';

import {
  getDatalakeConnectionByAdvertiser,
  getDatalakeTables,
  getDatalakeReports,
} from '../../../api/datalake';
import { getSegments } from '../../../api/attribution';

import { transformDatalakeTablesResponse } from './selectors';

const DatalakeContext = createContext({});
const DatalakeProvider = ({ children }) => {
  // Convert this to useReducer
  // Unmodified API storage
  const [datalakeConnection, setDatalakeConnection] = useState({});
  const [segments, setSegments] = useState([]);
  const [datalakeTables, setDatalakeTables] = useState([]);
  const [datalakeReports, setDatalakeReports] = useState([]); // aka schemas

  // Loaded
  const [connectionLoaded, setConnectionLoaded] = useState(false);
  const [datalakeLoaded, setDatalakeLoaded] = useState(false);
  const [segmentsLoaded, setSegmentsLoaded] = useState(false);
  const [tablesLoaded, setTablesLoaded] = useState(false);
  const [reportsLoaded, setReportsLoaded] = useState(false);

  // Modified
  const [transformedTables, setTransformedTables] = useState([]); // aka schemas

  const refreshDatalakeConnection = () => {
    setConnectionLoaded(false);
    getDatalakeConnectionByAdvertiser()
      .then(data => {
        setDatalakeConnection(get(data, 'data'));
        setConnectionLoaded(true);
      })
      .catch(e => e.message);
  };

  const refreshDatalakeTables = () => {
    setTablesLoaded(false);
    getDatalakeTables().then(data => {
      setDatalakeTables(get(data, 'data.advertiser_tables'));
      setTablesLoaded(true);
    });
  };

  useEffect(() => {
    getDatalakeConnectionByAdvertiser()
      .then(data => {
        setDatalakeConnection(get(data, 'data'));
        setConnectionLoaded(true);
      })
      .catch(e => e.message);
  }, []);

  // get the goods
  useEffect(() => {
    if (datalakeConnection?.active) {
      getSegments().then(data => {
        setSegments(data);
        setSegmentsLoaded(true);
      }).catch(e => e.message);
      getDatalakeTables().then(data => {
        setDatalakeTables(get(data, 'data.advertiser_tables'));
        setTablesLoaded(true);
      }).catch(e => e.message);

      getDatalakeReports().then(data => {
        // reports are also schemas in this context
        // slightly confusing.. perhaps have same name as data name
        setDatalakeReports(get(data, 'data.schemas'));
        setReportsLoaded(true);
      }).catch(e => e.message);
    }
  }, [datalakeConnection]);

  useEffect(() => {
    setTransformedTables(transformDatalakeTablesResponse(datalakeTables, segments));
  }, [datalakeTables, segments]);

  useEffect(() => {
    if ([segmentsLoaded, tablesLoaded, reportsLoaded].every(x => x === true)) {
      setDatalakeLoaded(true);
    } else {
      setDatalakeLoaded(false);
    }
  }, [connectionLoaded, datalakeLoaded, segmentsLoaded, tablesLoaded, reportsLoaded]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    connectionLoaded,
    datalakeLoaded,
    segments,
    datalakeTables,
    transformedTables,
    datalakeReports,
    datalakeConnection,
    refreshDatalakeConnection,
    refreshDatalakeTables,
  };

  return (
    <DatalakeContext.Provider value={value}>
      {children}
    </DatalakeContext.Provider>
  );
};

export { DatalakeContext, DatalakeProvider };

import React, { useState } from 'react';
import { ContentCard, IndexGridTree, FunnelAreaChart } from '@rockerbox/styleguide';

// shared v3
import { BreakdownsButton, CustomizeColumnsButton, Drawer, PopupCard, NoDataCat, LayoutToggles } from '../../components';
import { BarChartLoader, TreeTableLoader } from '../../components/loaders';

const FunnelChartAndTable = ({
  loading, noData, firstReportingDate,
  chartData, chartSummaryComponents, selectedChartMetric,
  tierColorMap, tooltipValueFormatter,
  tiersSummary, orderBy,
  selectedColumns, allColumns, setColumns,
  tiers, setTiers,
}) => {
  const [breakdownsDrawerOpen, setBreakdownsDrawerOpen] = useState(false);
  const [columnsDrawerOpen, setColumnsDrawerOpen] = useState(false);

  if (loading) {
    return (
      <LayoutToggles
        chartComponent={(<BarChartLoader />)}
        tableComponent={(<TreeTableLoader showSearch />)}
      />
    );
  }

  if (noData) {
    return (
      <NoDataCat
        message={`There is no data available in the date range. Please make sure your reporting date is set to on or after ${firstReportingDate}`}
        imgSize="small"
      />
    );
  }

  return (
    <>
      <LayoutToggles
        chartComponent={(
          <FunnelAreaChart
            data={chartData}
            height={400}
            tierColorMap={tierColorMap}
            XAxisKey="event"
            showTooltip
            showTotalInToolTip={selectedChartMetric !== 'cpa'}
            formatter={tooltipValueFormatter}
            reverseTooltipOrder
            hideKeys={['Unmapped Events']}
            summaryContent={chartSummaryComponents}
          />
        )}
        tableComponent={(
          <ContentCard style={{ marginTop: 20 }} hasTable>
            <IndexGridTree
              cols={selectedColumns}
              allCols={allColumns}
              data={tiersSummary}
              orderBy={orderBy}
              orderDirection="descending"
              summaryRow
              title="Channel Breakdown"
              rightContent={(
                <div>
                  <BreakdownsButton onClick={() => setBreakdownsDrawerOpen(true)} />
                  <CustomizeColumnsButton onClick={() => setColumnsDrawerOpen(true)} />
                </div>
              )}
              tiersOrder={tiers}
              hideKeys={['Unmapped Events']}
              sticky
            />
          </ContentCard>
        )}
      />
      <Drawer openDrawer={breakdownsDrawerOpen} onDrawerClose={() => setBreakdownsDrawerOpen(false)}>
        <PopupCard
          title="Breakdowns"
          list={tiers}
          setOrder={setTiers}
          callbackFn={() => setBreakdownsDrawerOpen(false)}
          open={breakdownsDrawerOpen}
          trackName="Journeys.ConversionFunnel.breakdown.set.edit"
        />
      </Drawer>
      <Drawer openDrawer={columnsDrawerOpen} onDrawerClose={() => setColumnsDrawerOpen(false)}>
        <PopupCard
          title="Customize Columns"
          isCustom={true}
          customKey="display"
          list={selectedColumns}
          originalList={allColumns}
          setOrder={setColumns}
          icon="none"
          callbackFn={() => setColumnsDrawerOpen(false)}
          excludeFirst={1}
          open={columnsDrawerOpen}
          trackName="Journeys.ConversionFunnel.columns.set.edit"
        />
      </Drawer>
    </>
  );
};

export default FunnelChartAndTable;

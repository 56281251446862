import React, { Component } from 'react';

export const ClusterSizeOverview = ({ currentSummary }) => {
  return (
    <div>
      <br/ >
      <b>Num clusters:</b> {currentSummary.values.length} <br/>
      <b>Avg cluster size:</b> {currentSummary.avg_count.toPrecision(4)} <br/>
      <b>Avg cluster correlation:</b> {currentSummary.avg_correlation.toPrecision(4)} <br/>
    </div>
  )
}

export const ClusterDetails = ({ summaryGroup }) => {
  return (
    <div>
      <br/ >
      <h3>Cluster Details</h3>
      <b> Num features:</b> { summaryGroup.unique.size} <br />
      <b> Cluster correlation:</b> { summaryGroup.avg_correlation.toPrecision(4) } <br />
      <b> Tier 1s:</b> { Array.from(summaryGroup.tier_1).join(", ")} <br />
    </div>
  )
}

export const CorrelationSummary = ({ correlationSummary }) => {
  return (
    <div>
      <br/ >
      <h3>Correlation Details</h3>
      <b>Correlation:</b> { correlationSummary.correlation.toPrecision(4) }
      <div style={{display:"flex"}}>
        <div style={{flex:1, overflow:"hidden",textOverflow:"ellipsis"}}>
          <b>Feature A</b>
          {
            correlationSummary.x.map(x => { 
              return <div style={{overflow:"hidden",textOverflow:"ellipsis"}}>{ x }</div> 
            })
          }
        </div>
        <div style={{flex:1, overflow:"hidden",textOverflow:"ellipsis"}}>
          <b>Feature B</b>
          {
            correlationSummary.y.map(x => { 
              return <div style={{overflow:"hidden",textOverflow:"ellipsis"}}>{ x }</div> 
            })
          }
        </div>
      </div>
    </div>
  )
}

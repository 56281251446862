import React from 'react';

import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

const SplitLayout = ({ stretched, leftWidth, leftContent, rightWidth, rightStyle, rightContent }) => (
  <Grid fluid="true">
    <Grid.Row stretched={stretched}>
      <Grid.Column width={leftWidth}>
        {leftContent}
      </Grid.Column>
      <Grid.Column width={rightWidth} style={rightStyle}>
        {rightContent}
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

SplitLayout.propTypes = {
  /** 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 (sum of all widths cannot exceed 16) */
  leftWidth: PropTypes.number.isRequired,
  /** 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 (sum of all widths cannot exceed 16) */
  rightWidth: PropTypes.number.isRequired,
  leftContent: PropTypes.element,
  rightContent: PropTypes.element,
  /** stretch contents to take up the entire grid height */
  stretched: PropTypes.bool,

};

export default SplitLayout;

import { timeFormatter, discountFormatter, deviceAttributeFormatter, timeToConversionFormatter } from './helpers';
import { productsFormatter } from './productsList';
import { formatCurrency } from '../../utils/valueFormatter';

export const INFO_FIELDS = [
  {
    key: 'order_number',
    label: 'Order Number',
  },
  {
    key: 'order_id',
    label: 'Order ID',
  },
  {
    key: 'timestamp_conv',
    label: 'Conversion Time',
    format: timeFormatter,
  },
  {
    key: 'conversion_hash_id',
    label: 'Conversion ID',
  },
];

export const REVENUE_FIELDS = [
  {
    key: 'revenue',
    label: 'Revenue',
    format: formatCurrency,
    type: 'currency',
  },
  {
    key: 'total',
    label: 'Order Total',
    format: formatCurrency,
    type: 'currency',
    tooltip: 'The total amount charged to the customer, including shipping and taxes',
  },
  {
    key: 'discount',
    label: 'Discount',
    format: discountFormatter,
    type: 'discount',
  },

  {
    key: 'coupon',
    label: 'Coupon',
  },
];

export const SHOPIFY_FIELDS = [
  {
    key: 'products',
    label: 'Products',
    format: productsFormatter,
    validate: x => {
      if (!x || x === '0' || x === '0.0') return false;
      try {
        JSON.parse(x.replace(/\\\\\\/g, '&%&%#@').replace(/\\/g, '').replaceAll('&%&%#@', '\\'));
      } catch {
        return false;
      }
      return true;
    },
  },
  {
    key: 'order_source',
    label: 'Order Source',
  },
  {
    key: 'shop',
    label: 'Shopify Shop ID',
  },
];

export const CONVERSION_INFO_FIELDS = [
  ...INFO_FIELDS,
  ...REVENUE_FIELDS,
  ...SHOPIFY_FIELDS,
];

export const USER_ID_FIELDS = [
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'external_id',
    label: 'Customer ID',
  },
  {
    key: 'phone_number',
    label: 'Phone Number',
  },
  {
    key: 'mobile_device_id',
    label: 'Mobile Device ID',
  },
  {
    key: 'uid',
    label: 'Rockerbox User ID',
  },
  {
    key: 'segment_anonymous_id',
    label: 'Segment User ID',
  },
];

export const USER_TYPE_FIELDS = [
  {
    key: 'new_to_file',
    label: 'Customer Type',
    format: x => (!!x ? 'New' : 'Repeat'),
    validate: x => x !== undefined,
  },
  {
    key: 'orders_count',
    label: 'Num Lifetime Orders',
  },
  {
    key: 'timestamp_conv',
    label: 'Time to Conversion',
    format: timeToConversionFormatter,
    validate: (x, conversionData) => x !== conversionData?.events[0]?.timestamp,
  },
  {
    key: 'events',
    label: 'First Seen',
    format: x => timeFormatter(x[0]?.timestamp),
    validate: x => !!x?.length && x[0]?.timestamp,
  },
];

export const USER_INFO_FIELDS = [
  ...USER_ID_FIELDS,
  ...USER_TYPE_FIELDS,
];

export const ADDRESS_FIELDS = [
  {
    key: 'address1',
    label: 'Address',
    format: (x, conversionData) => `${conversionData.address1} ${conversionData.address2}`,
  },
  {
    key: 'city',
    label: 'City',
  },
  {
    key: 'state',
    label: 'State',
  },
  {
    key: 'zip',
    label: 'Zip Code',
  },
  {
    key: 'country',
    label: 'Country',
  },
  {
    key: 'dma_id',
    label: 'DMA ID',
  },
];

export const DEVICE_FIELDS = [
  {
    key: 'user_agent',
    label: 'Device',
    format: deviceAttributeFormatter('device'),
  },
  {
    key: 'user_agent',
    label: 'OS',
    format: deviceAttributeFormatter('os'),
  },
  {
    key: 'user_agent',
    label: 'Browser',
    format: deviceAttributeFormatter('browser'),
  },
  {
    key: 'ip',
    label: 'IP Address',
  },
];

export const PRODUCT_FIELDS = [
  {
    key: 'name',
    label: 'Product Name',
  },
  {
    key: 'sku',
    label: 'SKU',
  },
  {
    key: 'product_id',
    label: 'Product ID',
  },
  {
    key: 'variant_id',
    label: 'Variant ID',
  },
  {
    key: 'quantity',
    label: 'Quantity',
  },
  {
    key: 'price',
    label: 'List Price',
  },
  {
    key: 'pre_tax_price',
    label: 'Customer Price',
  },
  {
    key: 'product',
    label: 'Product Name',
  },
  {
    key: 'amount',
    label: 'Amount',
  },
  {
    key: 'ltv',
    label: 'LTV',
  },
  {
    key: 'commit',
    label: 'Commit',
  },
];

export const EXCLUDED_DETAIL_FIELDS = [
  'rb_source',
  'index',
  'script_version',
  'source',
  'request_referrer',
  'uid',
  'hash_ip',
  'timestamp',
  'is_conversion',
  'date',
  'is_view',
  'url',
  'referrer',
  'sessionId',
  'user_agent',
  'action',
  'client_source',
  'variants',
];

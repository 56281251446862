import React, { useState, useEffect } from 'react';

import _ from 'lodash';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { Table, Form, Button } from 'semantic-ui-react';

import { addBudget } from './budgetHelpers';
import { filterEmptyTiers, filterUsingGroupBy, addValuesToAllRows } from './helpers';
import { TreeTableLoader } from './loader';
import { EmptyMessage, TableHeaders, TableRow, SummaryRow } from './parts';

export const DEFAULT_TIERS = ['tier_1', 'tier_2', 'tier_3', 'tier_4', 'tier_5'];

const nestBy = (data, tier, groupby, mapOfReducers, mapOfCompareReducers) => {
  const pos = groupby.indexOf(tier) + 1;
  const makeKey = add => row => groupby.slice(0, pos + add).map(k => row[k]).join('=');
  const getLast = row => groupby.slice(pos - 1, pos).map(k => row[k]).join('');
  const isLastTier = (groupby.length === pos);

  const nested = d3.nest()
    .key(makeKey(0))
    .rollup(values => {
      const first = values[0];
      const _tiers = groupby.slice(0, pos).reduce((accu, c) => {
        accu[c] = first[c];
        return accu;
      }, {});

      const reducedValues = Object.entries(mapOfReducers)
        .reduce((accu, [key, func]) => {
          accu[key] = func(values);
          return accu;
        }, {});

      const reducedCompareValues = Object.entries(mapOfCompareReducers)
        .reduce((accu, [key, func]) => {
          accu[key] = func(values);
          return accu;
        }, {});

      const current = makeKey(0)(first);
      const childKeys = isLastTier ? [] : Array(...(new Set(values.map(makeKey(1))))).filter(key => key.slice(0, -1) !== current);
      const group = getLast(first);

      return { depth: pos - 1, group, count: values.length, childKeys, ..._tiers, ...reducedValues, ...reducedCompareValues };
    })
    .map(data);

  return nested;
};

const nestWithChildren = (data, groupBy, mapOfReducers, mapOfCompareReducers, orderByFunc, additionalDataObj) => {
  const totalSpend = data.reduce((p, c) => p + c.spend, 0);

  const parentNest = groupBy.slice().reverse().reduce((childrenMap, c) => {
    const parentMap = nestBy(data, c, groupBy, mapOfReducers, mapOfCompareReducers);

    Object.keys(parentMap).forEach(key => {
      const obj = parentMap[key];

      if (additionalDataObj && Object.keys(additionalDataObj).length > 0) addValuesToAllRows(obj, additionalDataObj, totalSpend);

      const { childKeys } = obj;
      obj.values = childKeys.map(childKey => ({ key: childKey, ...childrenMap[childKey] }));
    });

    return parentMap;
  }, {});

  return Object.entries(parentNest)
    .map(([key, values]) => ({ key, ...values }))
    .sort(orderByFunc);
};

const IndexGridTree = props => {
  const {
    data, cols, allCols = false, hideKeys = [],
    orderBy, orderDirection, summaryRow, showSearch, searchPlaceholder,
    tiersOrder, rightContent, title, sticky, additionalDataObj = {}, compare = false,
    compareStart, compareEnd, useColorGradient,
  } = props;

  // find what to groupby
  const groups = cols.reduce((p, c) => {
    if (c.groupBy) return c.groupBy;
    return p;
  }, []);

  const groupBy = tiersOrder || (groups.length > 0 ? groups : DEFAULT_TIERS);

  // find and define the reducers
  const columns = allCols || cols;
  const mapOfReducers = columns.reduce((p, c) => {
    if (!c.reducer) return p;

    const currentPair = { [c.key]: c.reducer };
    return Object.assign(p, currentPair);
  }, {});

  const mapOfCompareReducers = columns.reduce((p, c) => {
    if (c.compareReducer === undefined) return p;

    const currentPair = { [`${c.key}_compare`]: c.compareReducer };
    return Object.assign(p, currentPair);
  }, {});

  // data
  const [_orderBy, setOrderBy] = useState(orderBy);
  const [_orderDirection, setOrderDirection] = useState(orderDirection || 'descending');
  const [treeData, setTreeData] = useState({});
  const [selectedData, setSelectedData] = useState(undefined);
  const [selectedRowKeys, setSelectedRowKeys] = useState({});
  const [searchVal, setSearchVal] = useState('');
  const [searchableData, setSearchableData] = useState(data || []);
  const [showingSearchResults, setShowingSearchResults] = useState(false);

  useEffect(() => {
    setOrderBy(orderBy);
  }, [orderBy]);

  useEffect(() => {
    setOrderDirection(orderDirection);
  }, [orderDirection]);

  const orderByFunc = !_orderBy ? () => { }
    : (p, c) => (_orderDirection === 'ascending'
      ? d3.ascending(p[_orderBy], c[_orderBy])
      : d3.descending(p[_orderBy], c[_orderBy]));

  useEffect(() => {
    setSearchableData(data);
  }, [data, cols]);
  // TODO: this is an expensive rendering - improve multiple calls when both searchable and cols changing
  useEffect(() => {
    const filteredEmptyTiers = filterEmptyTiers(searchableData); // extra loop for advertisers with bad data
    let outer = nestWithChildren(filteredEmptyTiers, groupBy, mapOfReducers, mapOfCompareReducers, orderByFunc, additionalDataObj);

    if (additionalDataObj.goal?.budget) {
      outer = addBudget(outer, additionalDataObj.goal);
    }

    setTreeData(outer);
  }, [searchableData, cols, tiersOrder, _orderBy, _orderDirection]);

  const extractAndFlatten = (nodeList, matches, arr) => {
    nodeList.forEach(node => {
      const { key } = node;
      arr.push(node);
      const allowedChildren = matches[key];
      if (allowedChildren) {
        const sortedValues = node.values.sort(orderByFunc);
        extractAndFlatten(sortedValues, matches, arr);
      }
    });
    return arr;
  };

  const selectRow = row => {
    const copySelectedRowKeys = _.cloneDeep(selectedRowKeys);
    const shouldRemove = !!copySelectedRowKeys[row.key];
    if (shouldRemove) delete copySelectedRowKeys[row.key];
    else {
      copySelectedRowKeys[row.key] = row.childKeys;
    }

    setSelectedRowKeys(copySelectedRowKeys);
  };

  useEffect(() => {
    if (Object.keys(treeData).length === 0) return;
    const flatTree = extractAndFlatten(treeData, selectedRowKeys, []);
    setSelectedData(flatTree);
  }, [treeData, selectedRowKeys]);

  const filterData = ({ reset = false }) => {
    const filtered = data.filter(c => filterUsingGroupBy(groupBy, c, searchVal));

    if (reset || searchVal.length === 0) {
      setSearchableData(data);
      setSelectedRowKeys({});
      setShowingSearchResults(false);
      return;
    }

    if (filtered.length === 0) {
      window.alert('No results found');
      return;
    }

    const expandKeys = filtered.reduce((accu, c) => {
      groupBy.reduce((rolledKey, key, i) => {
        // if the search value is not in the remaining items, exclude it from adding an expand key
        const remaining = groupBy.slice(i + 1).map(k => c[k]).join('----');
        if (!remaining.toLowerCase().includes(searchVal)) return '';

        const newKey = rolledKey === '' ? c[key] : `${rolledKey}=${c[key]}`;
        accu[newKey] = (accu[newKey] || 0) + 1;
        return newKey;
      }, '');
      return accu;
    }, {});

    setSelectedRowKeys(expandKeys);
    setSearchableData(filtered);
    setShowingSearchResults(true);
  };

  const resetSearch = () => {
    setSearchVal('');
    filterData({ reset: true });
  };

  return (
    <div style={{ overflowX: 'auto' }}>
      {(showSearch || rightContent || title) && (
      <div style={sticky ? ({ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#f2f4fd', padding: '1em', position: 'sticky', left: 0 })
        : { display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#f2f4fd', padding: '1em', position: 'sticky', left: 0 }}
      >
        {(showSearch || title)
          && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {title && <h2 style={{ margin: 0, paddingRight: '1em', fontWeight: 300 }}>{title}</h2>}
            {showSearch && (
            <div className="table-filter-search">
              <Form onSubmit={filterData} size="small">
                <Form.Group inline>
                  <Form.Input
                    icon="search"
                    iconPosition="left"
                    placeholder={searchPlaceholder}
                    value={searchVal}
                    onChange={(e, { value }) => setSearchVal(value)}
                    action={!!searchVal && { content: 'Search', color: 'purple', size: 'small' }}
                    style={{ width: 396 }}
                  />
                </Form.Group>
              </Form>
              {!!showingSearchResults
                && (
                <Button
                  content="Reset"
                  onClick={resetSearch}
                  size="small"
                  className="reset-button"
                />
                )}
            </div>
            )}
          </div>
          )}
        {rightContent && rightContent}
      </div>
      )}
      <Table className={`tree-grid ${sticky ? 'sticky-table' : ''}`}>
        {!selectedData && <TreeTableLoader />}
        {selectedData && selectedData.length > 0
          && (
          <>
            <TableHeaders
              cols={cols}
              onClick={
                (evt, { value }) => (value === _orderBy
                  ? setOrderDirection(_orderDirection === 'ascending' ? 'descending' : 'ascending')
                  : setOrderBy(value))
              }
              orderBy={_orderBy}
              orderDirection={_orderDirection}
            />
            <Table.Body>
              {selectedData.map((item, idx) => {
                if (hideKeys.includes(item.key)) return;
                /* eslint-disable react/jsx-props-no-spreading */
                return (
                  <TableRow {...props} {...{ idx }} item={item} onClick={(evt, { value }) => selectRow(value)} data={selectedData} />
                );
                /* eslint-enable react/jsx-props-no-spreading */
              })}
            </Table.Body>
            {summaryRow && (
            <SummaryRow
              cols={cols}
              allCols={allCols}
              data={selectedData}
              compare={compare}
              compareStart={compareStart}
              compareEnd={compareEnd}
              useColorGradient={useColorGradient}
            />
            )}
          </>
          )}
        {selectedData && selectedData.length === 0 && <EmptyMessage />}
      </Table>
    </div>

  );
};

// IndexGridV2.propTypes = {
//   /** @deprecated instead, wrap `IndexGrid` component with [ContentCard](#contentcard) and include the `hasTable` prop
//    */
//   as: PropTypes.element,
//   /** Array of grid row objects */
//   data: PropTypes.arrayOf(PropTypes.object).isRequired,
//   /** Array of column objects */
//   cols: PropTypes.arrayOf(PropTypes.shape({
//     key: PropTypes.string.isRequired,
//     display: PropTypes.string,
//     as: PropTypes.element
//    })).isRequired,
//   /** Optional fallback message if no data is available */
//   fallBackMsg: PropTypes.string,
//   UTCOffset: PropTypes.number,
//   /** Number of rows per page */
//   itemsPerPage: PropTypes.number.isRequired,
//   expandable: PropTypes.bool,
//   /** Component that will display when table row is clicked */
//   expandedComponent: PropTypes.element,
//   /** Key from a data object used to determine which row has been clicked and should be expanded. They selected key must be unique across all objects (ideally some sort of id) */
//   idKey: PropTypes.string,
// }

export default IndexGridTree;

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Sticky } from 'semantic-ui-react';

const ColumnLayout = props => (
  <Grid fluid="true" style={props.style}>
    <Grid.Row stretched={props.stretched}>
      {props.leftWidth &&
        <Grid.Column width={props.leftWidth}>
          {props.leftContent}
        </Grid.Column> || null
      }
      {props.centerWidth &&
        <Grid.Column width={props.centerWidth}>
          {props.children}
        </Grid.Column> || null
      }
      {props.rightWidth &&
        <Grid.Column width={props.rightWidth}>
          {props.rightContent}
        </Grid.Column> || null
      }
    </Grid.Row>
  </Grid>
)

ColumnLayout.propTypes = {
  /** 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 (sum of all widths cannot exceed 16) */
  leftWidth: PropTypes.number.isRequired,
  /** 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 (sum of all widths cannot exceed 16)*/
  righWidth: PropTypes.number.isRequired,
  /** 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 (sum of all widths cannot exceed 16)*/
  centerWidth: PropTypes.number.isRequired,
  leftContent: PropTypes.element,
  rightContent: PropTypes.element,
  /** stretch contents to take up the entire grid height */
  stretched: PropTypes.bool,

}

export default ColumnLayout

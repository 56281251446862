import React from 'react';
import { Form, Button } from 'semantic-ui-react';


export const S3 = (props) => {
  return (
    <React.Fragment>
      <Form.Input label="Name:" value={props.s3.name}
        onChange={(e,d) => props.handleFormUpdate("name", d.value)}
      />
      <Form.Input label="User ARN:" value={props.s3.userArn}
        onChange={(e,d) => props.handleFormUpdate("userArn", d.value)}
      />
      <Form.Input label="Folder:" value={props.s3.folder}
        onChange={(e,d) => props.handleFormUpdate("folder", d.value)}
      />
      <Button primary type="submit" content="Create"/>
    </React.Fragment>
  )
}

export const Sftp = (props) => {
  return (
    <React.Fragment>
      <Form.Input
        label="Name:" value={props.sftp.name}
        onChange={(e,d) => props.handleFormUpdate("name", d.value)}
      />
      <Form.Input
        label="Vendor:" value={props.sftp.vendor}
        onChange={(e,d) => props.handleFormUpdate("vendor", d.value)}
      />
      <Button primary type="submit" content="Create"/>
    </React.Fragment>
  )
}

export const Email = (props) => {
  return (
    <React.Fragment>
      <Form.Input label="Name:" value={props.email.name}
        onChange={(e,d) => props.handleFormUpdate("name", d.value)}
      />
      <Button primary type="submit" content="Create"/>
    </React.Fragment>
  )
}

import React from 'react'
import { Button, Table, Checkbox, Icon, Header, Grid, Modal, Dropdown } from 'semantic-ui-react';
import { ContentCard, IndexGrid, StatCard } from '@rockerbox/styleguide'


export const RedCell = ({ item, col }) => <Table.Cell style={{color: 'red'}}>{item[col.key]}</Table.Cell>

export const WrapCell = ({ item, col }) => <Table.Cell style={{wordBreak: "break-all"}}>{item[col.key]}</Table.Cell>

export const AdAccountSelector = ({ accounts, account, badAds, onSelect, onToggle, onUpdateImps, disabled }) => (
  <ContentCard>
    <div style={{minHeight: '50px', padding: '5px'}}>
      <strong>Select Account: </strong>
      <Dropdown
        onChange={onSelect}
        value={account.id}
        options={accounts}
        disabled={disabled}
        selection
      />
      <div style={{float: 'right', padding: '10px'}}>
        <strong style={{padding: '10px'}}>Enable auto-update: </strong>
        <Checkbox
          toggle
          style={{ marginBottom: '-5px' }}
          checked={!!account.auto_update}
          disabled={disabled || true}
          onClick={() => onToggle()}
        />
      </div>
    </div>
  </ContentCard>
)

export const FacebookAdsModal = ({ open, onClose, badAds, updateExcludedAdIds, onSubmit }) => {

  return(
    <Modal
      size="small"
      open={open}
      onClose={onClose}
    >
      <Modal.Header>
        Are you sure?
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          { updateExcludedAdIds.length > 0 &&
            <div>
              <h4>The following ads will be excluded from having their parameters automatically updated:</h4>
              <ul style={{height: "100px", overflow: "hidden", overflowY: "scroll"}}>
                {
                  badAds.filter(a => updateExcludedAdIds.includes(a.ad_id)).map(ad => {
                    return (<li>{ad.ad_name}</li>)
                  })
                }
              </ul>
            </div>
          }
          <br />
          <div style={{float: 'right', padding: '10px'}}>
            <div style={{display: "inline"}}>
              <Button
                content="Cancel"
                onClick={onClose}
              />
            </div>
            <div style={{display: "inline"}}>
              <Button
                primary
                content="Confirm"
                onClick={onSubmit}
              />
            </div>
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export const ViewSelector = ({ onClick, activeView, badAds, adsData, excludedAdIds, excludedAds, adsAboveThreshold, correctAds }) => (
  <Grid fluid='true'>
    <Grid.Row stretched>
      <Grid.Column style={{width: "20%"}}>
        <StatCard
          segmentColor={activeView == "missing" && "blue"}
          onClick={() => onClick("missing")}
          value={badAds.length}
          label='MISSING'
          size='small'
        />
      </Grid.Column>
      <Grid.Column style={{width: "20%"}}>
        <StatCard
          segmentColor={activeView == "above_threshold" && "blue"}
          onClick={() => onClick("above_threshold")}
          value={adsAboveThreshold.length}
          label='ABOVE THRESHOLD'
          size='small'
        />
      </Grid.Column>
      <Grid.Column style={{width: "20%"}}>
        <StatCard
          segmentColor={activeView == "excluded" && "blue"}
          onClick={() => onClick("excluded")}
          value={excludedAds.length}
          label='EXCLUDED'
          size='small'
        />
      </Grid.Column>
      <Grid.Column style={{width: "20%"}}>
        <StatCard
          segmentColor={activeView == "correct" && "blue"}
          onClick={() => onClick("correct")}
          value={correctAds.reduce((p,c) => p + c.numAds, 0)}
          label='CORRECT'
          size='small'
        />
      </Grid.Column>
      <Grid.Column style={{width: "20%"}}>
        <StatCard
          segmentColor={activeView == "total" && "blue"}
          onClick={() => onClick("total")}
          value={adsData.length}
          label='TOTAL ACTIVE'
          size='small'
        />
      </Grid.Column>
    </Grid.Row>
  </Grid>
)


export const AdsTable = (props) => {

  const adsTableData = props.item.ads;

  const cols = [
    {display: 'Adset', key: 'adset_name', headerWidth: 2},
    {display: 'Ad', key: 'ad_name', headerWidth: 2},
    {display: 'Ad ID', key: 'ad_id', headerWidth: 2},
    {display: 'Creative', key: 'creative_name', headerWidth: 2},
    {display: 'Impressions', key: 'impressions', headerWidth: 1},
    {display: 'Clicks', key: 'clicks', headerWidth: 1},
    {display: 'Link(s)', key: 'link', as: IndexGrid.EllipsedCell, headerWidth: 1},
    {display: 'URL Tags', key: 'url_tags', as: WrapCell, headerWidth: 2}
  ];

  return(
    <IndexGrid attached data={adsTableData} cols={cols} />
  )
}

export const ErrorView = ({ message }) => {
  return (
    <ContentCard style={{ 'textAlign': 'center' }}>
      <br />
        <Icon
          circular
          disabled
          name='warning'
          size='big'
          className='m-b-10'
          style={{color:'red'}} />
        <Header
          as='h2'
          disabled
          content={message}
          className='m-0' />
        <br />
    </ContentCard>
  )
}


export const AdsAccountingTable = ({ data }) => {

  const cols = [
    {display: 'Account ID', key: 'account_id', as: WrapCell, headerWidth: 1},
    {display: 'Campaign ID', key: 'campaign_id', as: WrapCell, headerWidth: 1},
    {display: 'Adset ID', key: 'adset_id', as: WrapCell, headerWidth: 1},
    {display: 'Ad ID', key: 'ad_id', as: WrapCell, headerWidth: 1},
    {display: 'Old Creative ID', key: 'old_creative_id', as: WrapCell, headerWidth: 1},
    {display: 'New Creative ID', key: 'new_creative_id', as: WrapCell, headerWidth: 1},
    {display: 'Status', key: 'finish_type', as: WrapCell, headerWidth: 1},
    {display: 'Message', key: 'msg', as: IndexGrid.EllipsedCell, headerWidth: 1},
  ]

  return (
    <IndexGrid
      style={{ fontSize: "11px"}}
      data={data}
      cols={cols}
      fallBackMsg="No ad updates to display"
    />
  )
}

export const AdsErrorAccountingTable = (props) => {

  const data = props.item.errors;

  const cols = [
    {display: 'Message', key: 'error_msg', headerWidth: 8},
    {display: 'Campaign ID', key: 'campaign_id', headerWidth: 2},
    {display: 'Ad ID', key: 'ad_id', headerWidth: 2}
  ]

  return (
    <IndexGrid style={{ fontSize: "11px"}} data={data} cols={cols}
    />
  )
}



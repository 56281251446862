import React, { useState, useEffect } from 'react';

import { ContentCard, SplitLayout } from '@rockerbox/styleguide';
import { useAtom } from 'jotai';
import { atomWithHash } from 'jotai/utils';
import moment from 'moment';

import Filter from './Filter';
import { useCustomerPaths, useTierColors } from './hooks';
import KeyInsights from './keyInsights';
import { KeyInsightsLoader, SummaryStatsLoader } from './loaders';
import MainHeader from './MainHeader';
import PathToConversion from './PathToConversion';
import SummaryStatsCard from './SummaryStats';
import { track, time } from '../../../utils/tracking';
import { anyAtom, firstAtom, lastAtom, customerTypeAtom, andOrAtom } from '../../atoms';
import { ViewAccessWrapper } from '../../components';
import { TreeTableLoader } from '../../components/loaders';
import { useGlobalState } from '../../hooks/global';
import { useURLStateAndSetDefault } from '../../hooks/urlState';
import { weekAgo, yesterday } from '../../utils/time';

const minPathLenAtom = atomWithHash('minPathLen', '1');

const MarketingPaths = () => {
  // router params
  const [id, setId] = useURLStateAndSetDefault('id', '0', true);
  const [startDate, setStartDate] = useURLStateAndSetDefault('startDate', weekAgo, true);
  const [endDate, setEndDate] = useURLStateAndSetDefault('endDate', yesterday, true);
  const [tier, setTier] = useURLStateAndSetDefault('tier', '1', true);

  // filters
  const [customerType, setCustomerType] = useAtom(customerTypeAtom);
  const [minPathLen, setMinPathLen] = useAtom(minPathLenAtom);
  const [any, setAny] = useAtom(anyAtom);
  const [first, setFirst] = useAtom(firstAtom);
  const [last, setLast] = useAtom(lastAtom);
  const [andOr, setAndOr] = useAtom(andOrAtom);
  const [hasNtf, setHasNtf] = useState(true);
  const [hasRevenue, setHasRevenue] = useState(true);

  const filtersAndDates = { id, startDate, endDate, customerType, hasNtf, hasRevenue, tier };
  const filterTouchpoints = { minPathLen, any, first, last, andOr };
  const filterTouchpointsMethods = { setMinPathLen, setAny, setFirst, setLast, setAndOr };

  // hooks
  const { segments } = useGlobalState();
  const {
    resolved,
    customerPaths,
    newCustomers,
    summaryStats,
    hasData,
    tiers,
    firstTiers,
    lastTiers,
  } = useCustomerPaths(filtersAndDates, filterTouchpoints, filterTouchpointsMethods);
  const { tierColors } = useTierColors();
  const { overallSummary, filteredSummary, percentOfTotal } = summaryStats;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    if ((!hasData && !hasData.length > 0) || !tierColors) return;

    setLoading(false);
  }, [tierColors, hasData]);

  useEffect(() => {
    if (!id || segments.length === 0) return;
    const selected = segments.find(x => {
      if (id === '0') return x.featured === 1;
      return x.filter_id === Number(id);
    });

    const { filter_id, include_revenue, has_new_to_file } = selected || {};

    if (id === '0') {
      setId(filter_id);
    }

    setHasRevenue(!!include_revenue);
    setHasNtf(!!has_new_to_file);
  }, [id, segments]);

  // mixpanel tracking
  useEffect(() => {
    if (!id || id === '0' || !startDate || !endDate) return;
    if (!!loading) {
      time('funnel.marketing_paths.view');
      return;
    }
    track('funnel.marketing_paths.view', {
      segment_id: id,
      tier,
      customer_type: customerType,
      start_date: startDate,
      end_date: endDate,
    });
  }, [id, tier, customerType, startDate, endDate, loading]);

  const onDateChange = ({ startDate, endDate }) => { // eslint-disable-line no-shadow
    const formatStartDate = moment(startDate).format('YYYY-MM-DD');
    const formatEndDate = moment(endDate).format('YYYY-MM-DD');

    setStartDate(formatStartDate);
    setEndDate(formatEndDate);
  };

  return (
    <>
      <MainHeader />
      <Filter {...{
        loading, tiers, firstTiers, lastTiers, // data
        tier, setTier, // tier groupby filter
        segments, id, setId, // conversion segment
        startDate, endDate, onDateChange,  // dates
        hasNtf, customerType, setCustomerType, // customer type
        filterTouchpoints, filterTouchpointsMethods, // advanced filters
      }}
      />
      <SplitLayout
        leftWidth={10}
        rightWidth={6}
        leftContent={loading
          ? <KeyInsightsLoader />
          : (
            <KeyInsights {...{
              filtersAndDates, customerPaths, summaryStats, newCustomers, tierColors,
            }}
            />
          )}
        rightContent={loading ? <SummaryStatsLoader /> : <SummaryStatsCard {...{ overallSummary, filteredSummary, percentOfTotal }} />}
        rightStyle={{ paddingLeft: 0 }}
        stretched
      />
      <ContentCard hasTable>
        {loading ? <TreeTableLoader /> : (
          <PathToConversion
            customerPath={customerPaths}
            resolved={resolved}
            tierColors={tierColors}
          />
        )}
      </ContentCard>
    </>
  );
};

const MarketingPathsAccess = () => (
  <ViewAccessWrapper
    viewName="marketing_paths"
    viewComponent={<MarketingPaths />}
  />
);

export default MarketingPathsAccess;

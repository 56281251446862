import React from 'react';
import { Dropdown, Message, Table, Input } from 'semantic-ui-react';
import { getDataset, getDatasetSchema } from './../../utils/api';
import { PagedIndexGrid, ExpandableIndexGrid, SplitLayout, IndexGrid } from '@rockerbox/styleguide';
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/tomorrow';

const COLS = IndexGrid.arrayToColumns(["key","size"]);

const CHILD_SEGMENT_TEMPLATE = JSON.stringify({
  "meta": {
    "action": {
      "from": "<<FILL IN FIELD>>",
      "case": [
        {"contains": "X", "value": "new_action_nameX"},
        {"contains": "Y", "value": "new_action_nameY"}
      ]
    }
  }
}, false, 2)

const TRANSFORM_FIELD_TEMPLATE = JSON.stringify({
  "meta": {
    "<<FIELD TO OVERRIDE. e.g, order_id>>": {
      "from": "<<FIELD WITH VALUE WE WANT. e.g., order_name>>"
    }
  }
}, false, 2)

const TEMPLATES = [
  { text: "create child segments from a parent", value: CHILD_SEGMENT_TEMPLATE },
  { text: "override a field with a different key", value: TRANSFORM_FIELD_TEMPLATE }
]

const DataTransformations = (props) => {
  const { id, isChild } = props;
  const [schema, setSchema] = React.useState("");
  const [dataset, setDataset] = React.useState([]);
  const [summary, setSummary] = React.useState([]);

  React.useEffect(() => {
    getDatasetSchema("conversion_data", id)
      .then((data) => {
        const { current : { schema }} = data.response
        if (schema) setSchema(JSON.stringify(schema[0]['data_schema'], false, 2))
      })
      .catch((err) => {
        console.log(`no schema available ${err}`)
      })
  }, [id])

  React.useEffect(() => {
    getDataset("conversion_data", id)
      .then((data) => {
        const { response } = data
        const { conversion_data } = response
        const keys = Object.keys(conversion_data[0])

        const transposed = conversion_data.reduce((obj,row) => {
          keys.map(k => {
            obj[k] = obj[k] || []
            obj[k].push(row[k])
          })
          return obj
        }, {})

        const summary = Object.keys(transposed)
          .map(key => {
            const raw = transposed[key]
            const set = new Set(raw)
            const size = set.size
            return { key, raw, set, size }
          })
          .sort((p,c) => c.size - p.size)
          .filter(({ key }) => !["date","conversion_hash_id","timestamp","sessionId","uid","base_id","user_agent","script_version","rb_source","hash_ip"].includes(key) )

        setDataset(conversion_data)
        setSummary(summary)
      })
      .catch((err) => {
        console.log(`no data available ${err}`)
      })
  }, [id])

  React.useEffect(() => {
    const key = isChild ? "conversion_data_excluded" : "conversion_data";

    getDatasetSchema(key, id)
      .then((data) => {
        const { current : { schema }} = data.response
        if (schema) setSchema(JSON.stringify(schema[0]['data_schema'], false, 2))
      })
      .catch((err) => {
        console.log(`no schema available ${err}`)
      })

    getDataset(key, id)
      .then((data) => {
        const { response } = data
        const conversion_data = response[key]
        const keys = Object.keys(conversion_data[0])

        const transposed = conversion_data.reduce((obj,row) => {
          keys.map(k => {
            obj[k] = obj[k] || []
            obj[k].push(row[k])
          })
          return obj
        }, {})

        const summary = Object.keys(transposed)
          .map(key => {
            const raw = transposed[key]
            const set = new Set(raw)
            const size = set.size
            return { key, raw, set, size }
          })
          .sort((p,c) => c.size - p.size)
          .filter(({ key }) => !["date","conversion_hash_id","timestamp","sessionId","uid","base_id","user_agent","script_version","rb_source","hash_ip"].includes(key) )

        setDataset(conversion_data)
        setSummary(summary)
      })
      .catch((err) => {
        console.log(`no data available ${err}`)
      })

  }, [isChild])


  const Expanded = ({item}) => {

    const [search, setSearch] = React.useState("");
    const [values, setValues] = React.useState([]);

    React.useEffect(() => {
      const values = Array.from(item.set)
        .map((item) => { return {"value":item}})
        .filter(({ value }) => !!value && value.toLowerCase().includes(search.toLowerCase()))

      setValues(values)

    },[item.set, search])

    const SearchHeader = () => {
      return <Table.HeaderCell>
        <Input label="Find Values" float="right" onChange={(_, {value}) => setSearch(value)} value={search} />
        <b> ({values.length}) </b>
      </Table.HeaderCell>
    }


    const COLS = [
      {"display":"", key: "value", as: IndexGrid.EllipsedCell, headerAs: SearchHeader}
    ]

    return (
      <>
        <PagedIndexGrid
          itemsPerPage={10}
          cols={COLS}
          data={values}
        />
      </>
    )
  }


  const cols = COLS.concat([ { "display": "", "key": "expand", "as": IndexGrid.ExpandContractCell } ])


  return (
    <div>
      <SplitLayout
        leftWidth={6}
        leftContent={
          <>
            <Message
              size="tiny"
              header="Explore Data"
              content="Below is a summary describing the cardinality of data associated with unique conversion events. You can search through this to help build a configuration."
            />
            <ExpandableIndexGrid
              expandCell={true}
              expandable={true}
              idKey="key"
              expandedComponent={Expanded}
              data={summary}
              cols={cols}
            />
          </>
        }
        rightWidth={10}
        rightContent={
          <>
            <Message
              size="tiny"
              header="Build Transformation Configuration"
              content="Setup a configuration to modify the contents of an existing or create a new column that can be used by child segments or to improve reporting"
            />
            { props.transformation.length < 3 ? <>
                I want to...
                <Dropdown selection options={TEMPLATES} onChange={(_, { value }) => props.handleChange({ value }, "transformation")} />
              </>
              :
              <AceEditor
                mode="json"
                theme="tomorrow"
                setOptions={{
                  highlightGutterLine: false,
                  showPrintMargin: false,
                  showGutter: true,
                  highlightActiveLine: false,
                  fontSize: 12,
                  wrapEnabled: true
                }}
                width={'100%'}
                height={'676px'}
                value={props.transformation}
                onChange={(value) => {
                  props.handleChange({ value }, "transformation")
                }}
                style={{marginTop: 15}}
              />
            }
          </>
        }
      />
    </div>
  )
}

export default DataTransformations;

import React from 'react';
import { Button, Form } from 'semantic-ui-react';
import { DownloadIcon } from '@heroicons/react/outline'; // eslint-disable-line import/no-extraneous-dependencies

const FormattedButton = ({ onClick }) => (
  <Button
    inverted
    size="large"
    color="purple"
    content={(
      <>
        <DownloadIcon style={{ display: 'inline', height: 19, margin: '-5px 3px -5px -3px' }} />
        {' Download Worksheet (CSV)'}
      </>
    )}
    onClick={onClick}
    className="remove-box-shadow"
    style={{
      margin: 0,
      marginLeft: 'auto',
    }}
  />
)
export default FormattedButton;
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Container } from 'semantic-ui-react';
import styled from 'styled-components';
// eslint-disable-next-line no-unused-vars
import { useStepsDisplay } from '../StepsSectionContext';
// eslint-disable-next-line no-unused-vars
import { CreateTableDoneButton } from '.';
// eslint-disable-next-line no-unused-vars

const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 1rem 0;
`;

const StyledContainer = styled.div`
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const GCPDataUnavailable = ({ button }) => (
  <StyledWrapper>
    <StyledContainer>
      <h3>Next Steps</h3>
      <div style={{ textAlign: 'left', maxWidth: '600px' }}>
        {/* eslint-disable-next-line max-len */}
        After creating the database, you can configure your platform and Rockerbox dataset shares. Some of your data will be available almost immediately after creating your shares, but it may take up to 24 hours to sync your complete dataset.
      </div>
      {button}
    </StyledContainer>
  </StyledWrapper>
);

export default GCPDataUnavailable;

export const initialLogic = {
  field: "",
  value: "",
}

export const initialGroup = {
  logic: []
}

export const initialTransform = {
  num_mta_key_fields: 1,
  num_transform_key_fields: 1,
  //mta_key: "",
  //transform_key: "",
  transform_key_1: "",
  transform_key_2: "",
  transform_key_3: "",
  transform_key_4: "",
  transform_key_5: "",
  transform_tier_1: null,
  transform_tier_2: null,
  transform_tier_3: null,
  transform_tier_4: null,
  transform_tier_5: null,
  transform_table_id: false,
  transformColumns: []
}

export const initialState = Object.assign({}, initialTransform, {
  name: "",
  rewrite_tier_1: "",
  rewrite_tier_2: "",
  rewrite_tier_3: "",
  rewrite_tier_4: "",
  rewrite_tier_5: "",
  order: 100,
  group: false,
  popupOpen: false,
})

import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { IndexGrid } from '@rockerbox/styleguide';
import { getSegments, getTransformTableData, getDataset } from '../../utils/api';

const DebugTransformTableJoin = ({ name, event_id, transform_table_id, mta_key, transform_key }) => {

  const [show, setShow] = React.useState(false)
  const [events, setEvents] = React.useState([])
  const [table, setTable] = React.useState([])
  const [featuredSegment, setFeaturedSegment] = React.useState(false)

  React.useEffect(() => {
    getSegments()
      .then(resp => {
        const seg = resp.find(row => row.featured)
        setFeaturedSegment(seg)
      })
  }, [])

  React.useEffect(() => {
    if (transform_table_id) getTransformTableData(transform_table_id)
      .then(setTable)
  }, [transform_table_id])


  React.useEffect(() => {
    if (name && featuredSegment && events.length == 0) {
      getDataset("events_tiers", featuredSegment.action_id, 1, 'data')
        .then(resp => {
          const tiers_array = resp['response']["events_tiers"]

          if (!tiers_array || tiers_array.length == 0) return;

          const rows = tiers_array.filter(row => {
            if (!row.match_ids || row.match_ids.length < 1) return false;
            return row.match_ids.slice(-1)[0] == event_id;
          })
          setEvents(rows)
        })
    }
  },[name, featuredSegment])

  const colEvent = `Event Key – ${mta_key}`
  const colTable = `Table Key – ${transform_key}`

  const { keyMappings, unmappedKeys } = React.useMemo(() => {
    if (!(mta_key && transform_key && !!table.length && !!events.length)) return {};

    const mtaKeys = mta_key.replace("}{",",").slice(1,-1).split(",")
    const transformKeys = transform_key.replace("}{",",").slice(1,-1).split(",").map( k => `{${k}}`)

    const eventKeys = new Set(events.map(row => mtaKeys.map(k => String(row[k])).join(",") ))
    const tableKeys = new Set(table.map(row => transformKeys.map(k => String(row[k])).join(",") ))

    const unmappedKeys = [...eventKeys].filter(k => !tableKeys.has(k))

    const keyMappings = [...eventKeys]
      .map(eventKey => {
        return { [colEvent]: eventKey, [colTable]: tableKeys.has(eventKey) ? eventKey : ""}
      })
      .sort((p,c) => c.inTable - p.inTable)

    return { keyMappings, unmappedKeys }

  }, [events, table, mta_key, transform_key])

  if (!show) return <>
    <Checkbox label="Show Key Debug" onClick={() => setShow(true)} />
  </>

  if (!event_id) return null


  return <>
    <IndexGrid data={keyMappings} cols={IndexGrid.arrayToColumns([colEvent, colTable])} />
  </>
}

export default DebugTransformTableJoin;

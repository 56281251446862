import React from 'react';
import * as d3 from 'rockerbox_d3_legacy_clone';
import _ from 'lodash'
import { postEntityData, updateEntityData } from '../../utils/api';

import { SelectCheckbox } from '@rockerbox/styleguide';
import { Form, Message, Menu, Button, Segment, Modal, Dropdown } from 'semantic-ui-react';

const DEFAULTS = {
  "events": { name:"", tier_1:"", tier_2:"", tier_3:"", tier_4:"", tier_5:"" },
  "promo_codes": { name:"", conversion_field_name:"", conversion_field_value:"", fixed_unit_cost:"", rev_share_percent:"", fixed_discount:"", percent_discount: "" },
  "costs": { name:"", start_date:"", end_date:"", distribution:"", cost:"" },
  "display": { name:"", start_date:"", end_date:"", distribution:"", cost:"" }
}

const DEFAULT_CODE = {
    "rev_share_percent":0,
    "conversion_field_value":"",
    "percent_discount":0,
    "fixed_unit_cost":0,
    "fixed_discount":0,
    "conversion_field_name":"coupon",
    "name":""
}

const PromoModal = (props) => {

  const { onClose, open, selectedItem, entityData, entitySegment, entityPrograms } = props;
  const [ selectedEntity, setSelectedEntity ] = React.useState(false)
  const [ search, setSearch ] = React.useState(false)
  const [ selectedMenu, setSelectedMenu ] = React.useState("assign")
  const [ sponsorName, setSponsorName] = React.useState(false)
  const [ sponsorType, setSponsorType] = React.useState(false)
  const [ marketingType, setMarketingType] = React.useState("sponsorship")
  const [ marketingChannel, setMarketingChannel] = React.useState(false)
  const [ isSending, setIsSending] = React.useState(false)

  const inputRef = React.useRef();
  const submitRef = React.useRef();

  React.useEffect(() => {
    setSelectedEntity(false)
    setSearch(false)
  }, [open])


  if (!selectedItem) return null


  const saveChanges = () => {

    const isSponsorship = marketingType == "sponsorship"
    const isAssignment = selectedMenu == "assign"

    const defaultObj = {
      name: isSponsorship ? sponsorName : marketingChannel,
      entity_type: isSponsorship ? sponsorType : marketingChannel,
      costs: [],
      events: [],
      promo_codes: [],
      display: [],
      segments: entitySegment
    }

    Object.keys(DEFAULTS).map(key => {
      defaultObj[key] = defaultObj[key].map(row => Object.assign({}, DEFAULTS[key], row))
    })

    if (entityPrograms && isSponsorship) {
      const currentProgram = entityPrograms.find(row => row.entity_type == sponsorType) || {}
      const { tier_1, tier_2, tier_3, tier_4, tier_5, fixed_unit_cost, rev_share_percent } = currentProgram
      const display = { tier_1, tier_2, tier_3, tier_4, tier_5 }
      const costs = { fixed_unit_cost, rev_share_percent }

      defaultObj['display'] = [display]
      defaultObj['costs'] = [costs]
    }

    
    const obj = isAssignment && isSponsorship ? entityData.find(row => row.name == selectedEntity) : 
      !isSponsorship ? entityData.find(row => row.name == marketingChannel) || defaultObj :
      defaultObj
    const { id } = obj 
    const send = id ? updateEntityData : postEntityData;

    const postObj = _.cloneDeep(obj)

    const { field, discount_amount, discount_type, promoCode} = selectedItem
    const fieldValue = {conversion_field_name: field, conversion_field_value: promoCode, name: promoCode}
    const discountValue = discount_amount.replace("$","").replace("%","").replace("-","")
    const discountDetails = discount_type == "percentage" ?  {percent_discount : discountValue} : 
      {fixed_discount: discountValue}

    const promoCodeRow = Object.assign({}, DEFAULT_CODE, fieldValue, discountDetails)

    postObj['promo_codes'].push(promoCodeRow)

    setIsSending(true)
    send(postObj, id)
      .then(data => {
        const item = data.find(row => row.name == postObj.name)
        setIsSending(false)
        onClose(id ? { update: item } : { add: item }) 
      })
  }

  const options = entityData
    .map(row => Object.assign({value: row.name, text: `${row.name} (${row.entity_type})`}))
    .sort((p,c) => d3.ascending(p.value, c.value))

  const programOptions = entityPrograms.length == 0 ? 
    [{text:"Podcast",value:"podcast"},{text:"Influencer",value:"influencer"},{text:"Site Sponsorship",value:"site_sponsorship"}] :
    entityPrograms.map(row => Object.assign({value: row.entity_type, text: row.entity_type}))
  
  // const Entity = <Segment>
  //   <Form >
  //     <Form.Field>
  //       <label>Marketing Channel</label>
  //       <Dropdown 
  //         value={marketingChannel}
  //         selection
  //         placeholder="What type of marketing is this?" 
  //         fluid 
  //         options={defaultOptions}
  //         onChange={(evt, { value }) => setMarketingChannel(value)}
  //       /> 
  //     </Form.Field>
  //     <br />
  //     <br />
  //     <Button loading={isSending} onClick={saveChanges} type="submit" color="blue" floated="right" style={{margin:"10px"}} >Save</Button>
  //   </Form>
  // </Segment>

  const Sponsorships = <Segment>
    <Menu tabular>
      <Menu.Item name='Assign to Existing' active={selectedMenu == "assign"} onClick={() => setSelectedMenu("assign")} />
      {/* <Menu.Item name='Create New' active={selectedMenu == "create"} onClick={() => setSelectedMenu("create")} /> */}
    </Menu>
    { selectedMenu == "assign" && <Form >
        <Form.Field>
          <label>Sponsorship Name</label>
          <Dropdown 
            fluid
            clearable
            onChange={(evt, { value }) => {
              if (value.length > 0) {
                setSearch(false)
                setSelectedEntity(value)
              } else {
                setSelectedEntity(false)
              }
            }}
            closeOnChange
            onSearchChange={(x, { searchQuery }) => setSearch(searchQuery)}
            options={options}
            placeholder='Search for an existing sponsor'
            search
            searchQuery={search || undefined}
            selection
            value={selectedEntity || undefined}
          />
        </Form.Field>
        <br />
        <br />
        <Button loading={isSending} onClick={saveChanges} type="submit" color="blue" floated="right" style={{margin:"10px"}} >Save</Button>
      </Form>
    }
    {/* { selectedMenu == "create" && <Form>
        <Form.Field>
          <label>Sponsorship Name</label>
          <Input 
            onChange={(evt, { value }) => setSponsorName(value)}
            placeholder="What is name of the Sponsorship?" fluid 
          /> 
        </Form.Field>
        <Form.Field>
          <label>{ entityPrograms.length > 0 ? "Sponsorship Program" : "Sponsorship Type" }</label>
          <Dropdown 
            selection
            placeholder={ entityPrograms.length > 0 ? "What Sponsorship Program does this belong to?" : "What type of sponsorship is this this?" }
            fluid 
            options={programOptions}
            onChange={(evt, { value }) => setSponsorType(value)}
          /> 
        </Form.Field>
        <Button loading={isSending} onClick={saveChanges} type="submit" color="blue" floated="right" style={{margin:"10px"}}>Save</Button>
      </Form>
    } */}
  </Segment>

  const associateOptions = [
    {text: "Sponsorship", value: "sponsorship", ShowSelected: Sponsorships  }, 
    // {text: "Other", value: "other", ShowSelected: Entity }
  ]

  return <Modal {...{onClose, open}}>
    <Segment>
      <h3 >Associate the <b>{ selectedItem.promoCode }</b> with marketing</h3>
      <Message>
        By associating a promo-code to marketing, you will be able to include the promo-code as a marketing touch point and associated a cost to any promotional campaigns that distributed the promo-code.
      </Message>
      <Segment secondary>
        <SelectCheckbox
          showBorder={false}
          // description={"Is this promocode associated with a sponsorship or other type of marketing"}
          options={associateOptions}
          onChange={(evt, { value }) => setMarketingType(value) }
          value={marketingType}
        />
      </Segment>
    </Segment>
  </Modal>
}

export default PromoModal;

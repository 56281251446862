import React from 'react';
import { Button, Input, Form } from 'semantic-ui-react';
import ExternalMarketingInput from './ExternalMarketing';

const InputValue = ({marketType, url, referrer, handleUrlChange, handleReferrerChange, handleSubmit, placeholder, buttonText}) => {
  return (
    <Form>
      <Form.Field>
        <label>{marketType === 'pixel' ? 'Pixel URL' : 'URL'}</label>
        <Input placeholder={placeholder} value={url} onChange={handleUrlChange} />
      </Form.Field>
      {marketType === 'url' ? (
        <Form.Field>
          <label>Referrer URL</label>
          <Input placeholder="http://mysite.com" onChange={handleReferrerChange} value={referrer} />
        </Form.Field>
      ) : ''}
      <Button type="submit" primary onClick={handleSubmit} disabled={url === '' && referrer === ''} >
        {buttonText}
      </Button>
    </Form>
  );
};

const TestInput = (props) => {
  const advertiser = props.advertiser !== undefined ? props.advertiser.pixel_source_name : '';
  const testInput = props.marketType === 'url' ? (
    <InputValue
      {...props}
      placeholder={'http://mywebsite.com/samplepage?examplequery=value&utm_source=samplesource'}
      buttonText={'Check URL'}
    />
  ) : props.marketType === 'pixel' ? (
    <InputValue
      {...props}
      placeholder={`https://metrics.getrockerbox.com/track/v5?source=${advertiser}&tier_two=[campaign_id_value]&tier_three=[adgroup_id_value]&tier_four=[creative_id_value]&tier_five=[placement_id_value]`}
      buttonText={'Check Pixel'}
    />
  ) : props.marketType === 'external' || props.marketType === 'externalJSON' ? (
    <ExternalMarketingInput {...props} />
  ) : <></>
  return testInput;
};

export default TestInput;

import React, { useEffect } from 'react';
import { Grid, Button, Icon } from 'semantic-ui-react';
import { useGlobalState } from '../../../hooks/global';
import { Status } from '../../../views/AdhocExports';
import { createExportRequestDetailsFieldsArray } from './selectors';
import { track } from '../../../../utils/tracking';

const GridColumnHeader = ({ label }) => (
  <Grid.Column width={6} style={{ fontWeight: 'bold', fontSize: 12 }}>{label}</Grid.Column>
);

const GridColumnInfo = ({ info }) => (
  <Grid.Column width={10} style={{ fontSize: 12 }}>{info}</Grid.Column>
);

const EmptyReportMessage = () => (
  <div className="ui negative message">
    <div className="header">No Data</div>
    <p>There is no data for the chosen export parameters.</p>
  </div>
);

const ExportDetails = ({ itemInfo }) => {
  const { segments } = useGlobalState();
  const isExpired = !itemInfo.expiration_status || itemInfo.expiration_status === 'EXPIRED';
  const exportRequestDetailsFields = createExportRequestDetailsFieldsArray(itemInfo, segments);

  const trackingMetaData = {
    pixel_source_name: itemInfo.pixel_source_name,
    report_request_id: itemInfo.id,
  };

  useEffect(() => {
    track('data.exports.ad_hoc_exports.view_details', trackingMetaData);
  }, []);

  const handleDownloadTracking = () => {
    track('data.exports.ad_hoc_exports.download', trackingMetaData);
  };

  return (
    <>
      <h1>Export Details</h1>
      <div style={{ backgroundColor: '#fbfbfe', borderRadius: 4 }}>
        <Grid columns={2} style={{ marginTop: 20, marginLeft: 5, marginRight: 5 }}>
          {exportRequestDetailsFields.map(field => {
            if (field.label === 'Status') {
              return (
                <Grid.Row style={{ padding: '0.5rem 0' }}>
                  <GridColumnHeader label={field.label} />
                  <Status item={itemInfo} />
                </Grid.Row>
              );
            }
            return (
              <Grid.Row style={{ padding: '0.5rem 0' }}>
                <GridColumnHeader label={field.label} />
                <GridColumnInfo info={field.info} />
              </Grid.Row>
            );
          })}
        </Grid>
      </div>
      <div style={{ marginTop: 20 }}>
        <Button icon inverted size="large" disabled={!!isExpired} onClick={handleDownloadTracking}>
          <a href={itemInfo.presigned_url_filtered} download target="_blank" rel="noreferrer">
            <Icon name="download" />
            {' '}
            Download
          </a>
        </Button>
      </div>
      {itemInfo.report_size === 0 && <EmptyReportMessage />}
    </>
  );
};

export default ExportDetails;

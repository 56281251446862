import React from 'react';
import { Segment, Header, Grid, Input } from 'semantic-ui-react';
import ContentLoader from 'react-content-loader';

import { savedReportsHero } from '@rockerbox/styleguide';

export const SavedReportsGridHeader = ({ value, onChange, hideTitle, hideSearch, headerStyles }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#f2f4fd',
      padding: !!hideTitle ? 18 : 12,
      marginBottom: 12,
      ...headerStyles,
    }}
  >
    {!hideTitle
      && (
      <Header
        as="h2"
        style={{ margin: 0, paddingRight: '1em', fontWeight: 300 }}
        content="Saved Reports"
      />
      )}
    <div className="table-filter-search" style={{ flexGrow: 1 }}>
      <Input
        {...{ value, onChange }}
        style={{
          width: !!hideTitle ? '100%' : 400,
          visibility: !!hideSearch ? 'hidden' : 'visible',
        }}
        placeholder="Search"
        icon="search"
        iconPosition="left"
        primary
      />
    </div>
  </div>
);

export const SavedReportsGridLoader = ({ columns }) => (
  <Grid {...{ columns }} className={`saved-views columns-${columns}`}>
    <Grid.Row>
      {[...Array(columns * 3)].map((_, i) => (
        <Grid.Column key={i}>
          <Segment className="saved-item" style={{ padding: 0 }}>
            <ContentLoader
              viewBox="0 0 220 59"
              foregroundColor="#e5e5e5"
              height={59}
              width={220}
              style={{ marginLeft: 0 }}
            >
              <path d="M37.7,42.5h-17c-2.2,0-4-1.8-4-4v-17c0-2.2,1.8-4,4-4h17c2.2,0,4,1.8,4,4v17C41.7,40.7,39.9,42.5,37.7,42.5z" />
              <path d="M210.6,25.4H59.5c-2.2,0-4-1.8-4-4v-3c0-2.2,1.8-4,4-4h151.1c2.2,0,4,1.8,4,4v3C214.6,23.6,212.8,25.4,210.6,25.4z" />
              <path d="M115.3,42.1H77.5c-2.2,0-4-1.8-4-4v-1c0-2.2,1.8-4,4-4h37.8c2.2,0,4,1.8,4,4v1C119.3,40.3,117.5,42.1,115.3,42.1z" />
              <circle cx="62.6" cy="37.6" r="4.5" />
            </ContentLoader>
          </Segment>
        </Grid.Column>
      ))}
    </Grid.Row>
  </Grid>
);

export const EmptyGridSavedReports = ({ emptyText }) => (
  <div className="saved-views-empty-view">
    <img src={savedReportsHero} alt="no saved reports" />
    <Header
      as="h3"
      color="grey"
      content={emptyText}
    />
  </div>
);

export const capitalize = ([first, ...rest]) => {
  if (!first) return '';
  return first.toUpperCase() + rest.join('').toLowerCase();
};

export const getUTMDisplayName = utm => {
  const utmName = utm.replace('utm_', '');
  const utmNameCapitalized = capitalize(utmName);
  return `UTM ${utmNameCapitalized}`;
};

import moment from 'moment';

import { DATA_STATUS_MINIMUM_DATE } from '../constants/dataStatusReporting';
import { getParquetAndReturnJson } from '../utils/parquetToJson';

export function getBenchmarkData(platform) {
  const url = `/data-reporting/platform-performance-benchmarks/${platform}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .catch(error => console.error(error.message)); // eslint-disable-line no-console
}

export function getDatasetStatus(category, startDate, endDate, segmentId) {
  const startDateWithMinimum = moment(startDate).isBefore(moment(DATA_STATUS_MINIMUM_DATE)) ? DATA_STATUS_MINIMUM_DATE : startDate;
  const baseUrl = `/data-reporting/dataset-status/${category}/any-success?start_date=${startDateWithMinimum}&end_date=${endDate}`;
  const url = segmentId ? `${baseUrl}&conversion_segment_id=${segmentId}` : baseUrl;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .catch(error => console.error(error.message)); // eslint-disable-line no-console
}

export function getDatasetStatusRollup(category, startDate, endDate, segmentId) {
  const startDateWithMinimum = moment(startDate).isBefore(moment(DATA_STATUS_MINIMUM_DATE)) ? DATA_STATUS_MINIMUM_DATE : startDate;
  const baseUrl = `/data-reporting/dataset-status/${category}/any-success-rollup?start_date=${startDateWithMinimum}&end_date=${endDate}`;
  const url = segmentId ? `${baseUrl}&conversion_segment_id=${segmentId}` : baseUrl;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .catch(error => console.error(error.message)); // eslint-disable-line no-console
}

export function getLastSyncTime(category, startDate, endDate, segmentId) {
  const startDateWithMinimum = moment(startDate).isBefore(moment(DATA_STATUS_MINIMUM_DATE)) ? DATA_STATUS_MINIMUM_DATE : startDate;
  const baseUrl = `/data-reporting/dataset-status/${category}/last?start_date=${startDateWithMinimum}&end_date=${endDate}`;
  const url = segmentId ? `${baseUrl}&conversion_segment_id=${segmentId}` : baseUrl;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .catch(error => console.error(error.message)); // eslint-disable-line no-console
}

export function getSpend(startDate, endDate) {
  const url = `/data-reporting/advertiser-spend-by-platform/cache?start_date=${startDate}&end_date=${endDate}`;
  return getParquetAndReturnJson(url)
    .then(data => data)
    .catch(err => console.log(err)); // eslint-disable-line no-console
}

export function getEventStatusHourlyData(eventType, startDate, endDate) {
  const url = `/data-reporting/${eventType}/hourly-stats?start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .catch(error => console.error(error.message)); // eslint-disable-line no-console
}

export function getEventStatusSummaryData(eventType, startDate, endDate) {
  const url = `/data-reporting/${eventType}/summary-stats?start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .catch(error => console.error(error.message)); // eslint-disable-line no-console
}

export function getParametersSummary(eventType, startDate, endDate) {
  const url = `/data-reporting/${eventType}/summary-parameters?start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .catch(error => console.error(error.message)); // eslint-disable-line no-console
}

export function getParametersHourly(eventType, startDate, endDate) {
  const url = `/data-reporting/${eventType}/hourly-parameters?start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .catch(error => console.error(error.message)); // eslint-disable-line no-console
}

export function getEventReportStatus(eventType, advertiser, startDate, endDate, action) {
  const url = `/data-reporting/${eventType}-report/download/check?advertiser=${advertiser}&start_date=${startDate}&end_date=${endDate}&action=${action}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .catch(error => console.error(error.message)); // eslint-disable-line no-console
}

export function getEventStatusFiles(batchJobId, eventType, action) {
  const url = `/data-reporting/${eventType}-report/download/filenames?batch_job_id=${batchJobId}&action=${action}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .catch(error => console.error(error.message)); // eslint-disable-line no-console
}

import * as aq from 'arquero';

const DEFAULT_COLUMNS = ['even', 'first_touch', 'last_touch'];
const MODEL_COLUMNS = ['normalized'];
const EXCLUDE_RENAMING_COLUMNS = ['date', 'searchable', 'tier_1', 'tier_2', 'tier_3', 'tier_4', 'tier_5'];

const getColumnsViaDatasetProperties = hasModel => (hasModel ? [...DEFAULT_COLUMNS, ...MODEL_COLUMNS] : [...DEFAULT_COLUMNS]);

const hasColumn = (table, column) => table
  .columnNames()
  .includes(column);

const constructSumRollups = columns => columns.reduce((accu, col) => {
  accu[`${col}`] = `d => op.sum(op.number(d['${col}']))`;

  const revenueColumn = `revenue_${col}`;
  accu[`${revenueColumn}`] = `d => op.sum(op.number(d['${revenueColumn}']))`;
  return accu;
}, {});

const arrayToObject = (array, prefix, method = false) => array.reduce((accu, item) => {
  if (method) {
    accu[item] = method;
  } else {
    accu[item] = prefix ? `${prefix}_${item}` : [];
  }
  return accu;
}, {});

export const filterTiers = (raw, filters) => {
  const { tier_1, tier_2, tier_3, tier_4, tier_5 } = filters;
  const tiers = { tier_1, tier_2, tier_3, tier_4, tier_5 };

  Object.keys(tiers).forEach(t => {
    if (tiers[t] === undefined || tiers[t].length === 0) delete tiers[t];
  });

  const toFilter = raw.params(tiers);

  const filtered = Object.entries(tiers)
    .reduce((data, [key, values]) => {
      if (values.length === 0) return data;
      return data.filter(`(d, $) => op.includes($['${key}'], d['${key}'])`); // enable if using regular rollup in advanced
    }, toFilter);

  return filtered;
};

export const filterSearch = (raw, search, maxGroupby) => {
  if (!search || search.length === 0) return raw;
  raw.params({ search });
  const addSearchableCol = {
    searchable: d => op.lower(d.tier_1 + d.tier_2 + d.tier_3 + d.tier_4), // eslint-disable-line no-undef
  };
  if (maxGroupby.includes('tier_5')) {
    addSearchableCol.searchable = d => op.lower(d.tier_1 + d.tier_2 + d.tier_3 + d.tier_4 + d.tier_5); // eslint-disable-line no-undef
  }
  const filtered = raw
    // made new column "searchable" -- lower case and add all tiers together
    .derive(addSearchableCol)
    .filter((d, $) => op.match(d.searchable, $.search)); // eslint-disable-line no-undef
  return filtered;
};

const getTableSummary = (groupby, table) => {
  const hasModel = hasColumn(table, 'normalized');
  const columns = getColumnsViaDatasetProperties(hasModel);
  const hasSpend = hasColumn(table, 'spend');

  const spend = {};

  if (hasSpend) {
    spend.spend = 'd => op.sum(op.number(d.spend))';
  }

  const specific = constructSumRollups(columns);
  const rollup = { ...spend, ...specific };

  const summary = table
    .groupby(groupby)
    .rollup(rollup);
  return summary;
};

export const getStatsByTable = (groupby, tiersTablesRaw) => {
  aq.addFunction('number', Number);
  const logString = groupby.join(', ');

  if (!tiersTablesRaw || !tiersTablesRaw.length) return [];
  console.time(`new - ${logString}`); // eslint-disable-line no-console

  const tiersData = tiersTablesRaw.map(table => getTableSummary(groupby, table));

  return tiersData;
};

export const getChartSummary = (groupby, table) => {
  if (!table) return [];
  const columns = table
    .columnNames()
    .filter(col => !groupby.includes(col));

  const rollup = columns.reduce((accu, col) => {
    accu[`${col}`] = `d => op.sum(op.number(d['${col}']))`;
    return accu;
  }, {});

  const summary = table
    .groupby(groupby)
    .rollup(rollup);
  return summary;
};

export const mergeTables = (tiersDataBySegment, funnelConfigurationByName) => {
  const renamedTablesBySegment = tiersDataBySegment.map((table, i) => {
    const columnsToRename = table
      .columnNames()
      .filter(col => !EXCLUDE_RENAMING_COLUMNS.includes(col));
    const renameCols = arrayToObject(columnsToRename, funnelConfigurationByName[i]);
    return table.rename(renameCols);
  });

  const allSegmentColumns = [...new Set(renamedTablesBySegment.flatMap(table => table.columnNames()))];

  const allSegmentColumnsAsObj = arrayToObject(allSegmentColumns);

  const imputeColumns = allSegmentColumns
    .filter(col => !EXCLUDE_RENAMING_COLUMNS.includes(col));
  const imputeColumnsAsObj = arrayToObject(imputeColumns, false, () => 0);

  const merged = aq.table(allSegmentColumnsAsObj)
    .concat(...renamedTablesBySegment)
    .impute(imputeColumnsAsObj);

  return merged;
};

export const getTotals = table => {
  const columns = table.columns();
  const numericColumns = Object.keys(columns)
    .filter(col => typeof columns[col].data[0] === 'number')
    .reduce((accu, col) => {
      accu[col] = `d => op.sum(op.number(d['${col}']))`;
      return accu;
    }, {});

  return table.rollup(numericColumns);
};

export const loadProfitwell = (token) => {
  (function(i, s, o, g, r, a, m) {
    i.ProfitWellObject = r;
    (i[r] =
      i[r] ||
      function() {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    'script',
    'https://public.profitwell.com/js/profitwell.js',
    'profitwell',
  );
  window.profitwell('auth_token', token);
}

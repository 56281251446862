import React from 'react';

import { Header } from 'semantic-ui-react';

import { useBaselinePeriod } from './useBaselinePeriod';
import { formatCurrency } from '../../../../../utils/valueFormatter';
import MMMDateRangeHooks from '../../../parts/MMMDateRangeHooks';
import { SplitLayout } from '../Layout';
import { useProjectionPeriod } from '../projection/useProjectionPeriod';

const BaselinePeriod = ({ trainingData, baselineTotal, activeFeatures, inactiveFeatures }) => {
  const { projectionPeriod } = useProjectionPeriod();
  const { loading, startDate, endDate, onDateChange, dateRange, formattedPeriod, formattedOptions } = useBaselinePeriod({ trainingData, projectionPeriod });

  return (
    <SplitLayout
      header="3. Establish Baseline"
      leftContent={!loading && (
        <MMMDateRangeHooks
          {...{ startDate, endDate, onDateChange, dateRange }}
          localStorageKey={false}
          options={formattedOptions}
        />
      )}
      rightContent={(
        <Header as="h4" style={{ margin: 10, marginTop: 0, fontWeight: 400 }}>
          The baseline is the starting point for planning. It establishes the current state of your marketing mix.
          <div style={{ height: '0.8rem' }} />
          In
          {' '}
          <b>
            {formattedPeriod}
            {' '}
          </b>
          {`(your current selected baseline), you spent on average ${formatCurrency(baselineTotal, 'USD', 0)} per day across ${activeFeatures.length} channels. `}
          {`During this time period, you also chose not to spend on ${inactiveFeatures.length} channels.`}
        </Header>
      )}
    />
  );
};

export default BaselinePeriod;

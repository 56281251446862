import moment from 'moment';
import momentTimezone from 'moment-timezone';
import parser from 'ua-parser-js';

import { formatCurrency } from '../../utils/valueFormatter';

export const timeFormatter = x => {
  const time = moment(x * 1000).format('MMM D, YYYY h:mm a');
  const timezone = momentTimezone.tz.guess();
  const zoneAbbr = momentTimezone.tz.zone(timezone).abbr(x * 1000);
  return `${time} (${zoneAbbr})`;
};

export const percentFormatter = x => {
  const formatter = Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  const value = x > 1 ? x / 100 : x;
  return formatter.format(value);
};

export const discountFormatter = (x, conversionData, currencyCode) => {
  const { discount_type } = conversionData;
  if (discount_type === 'percentage') return percentFormatter(x);
  if (discount_type === 'fixed_amount') return formatCurrency(x, currencyCode);
  return x;
};

export const deviceAttributeFormatter = attribute => x => {
  const value = x.replaceAll('\\', '');
  const res = parser(value);
  const { device, os, browser } = res;
  if (attribute === 'device') return `${device.vendor || 'Unknown'} ${device.model || ''}`;
  if (attribute === 'os') return `${os.name || 'Unknown'} ${os.version || ''}`;
  if (attribute === 'browser') return `${browser.name || 'Unknown'} ${browser.version || ''}`;
  return value;
};

export const timeToConversionFormatter = (x, conversionData) => {
  const endTime = moment(x * 1000);
  const startTime = moment((conversionData?.events[0]?.timestamp ?? 0) * 1000);
  const duration = moment.duration(endTime.diff(startTime));
  return duration.humanize();
};

export const timestampFormatter = (x, formatStr = 'MMM D, YYYY h:mm a', includeTz = true) => {
  const timezone = momentTimezone.tz.guess();
  const time = moment.utc(x).tz(timezone);
  const timeStr = time.format(formatStr);
  if (!includeTz) return timeStr;
  const zoneAbbr = momentTimezone.tz.zone(timezone).abbr(time);
  return `${timeStr} (${zoneAbbr})`;
};

export const timestampDiffFormatter = (x, y) => {
  const startTime = moment(x);
  const endTime = moment(y);
  const duration = moment.duration(endTime.diff(startTime));
  return duration.humanize();
};

export const formatFieldValues = (item, originalValue, conversionData, currencyCode) => {
  const { format, type } = item;
  if (!format) return originalValue;
  if (type === 'currency') return format(originalValue, currencyCode);
  if (type === 'discount') return format(originalValue, conversionData, currencyCode);
  return format(originalValue, conversionData);
};

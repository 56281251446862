import React from 'react';

import { ContentCard, LineChart, AreaChartLoader } from '@rockerbox/styleguide';
import { Grid, GridRow, GridColumn, List, ListItem, Checkbox, Input } from 'semantic-ui-react';

import { NoDataCat, StyledDropdown } from '../../components';

const containerStyles = {
  height: 335,
  overflowY: 'auto',
  position: 'relative',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
};

const stickyBarStyles = {
  position: 'sticky',
  top: 0,
  backgroundColor:
  'white',
  zIndex: 1,
  paddingBottom: 10,
};

const Chart = ({
  chartData, updatedChartData, filteredKeys, // data
  groupBy, setGroupBy,
  handleSearchChange,
  handleToggleAll, handleCheckboxChange, selectAllChecked, checkedItems,
  labelFormatter, formatDate, formatter,
  tierColorMap, loading,
}) => {
  if (!loading && (!chartData || chartData.length === 0)) {
    return (
      <ContentCard>
        <NoDataCat message="No data found" height={300} />
      </ContentCard>
    );
  }

  return (
    <ContentCard>
      {loading ? (
        <AreaChartLoader />
      ) : (
        <Grid columns={2} divided>
          <GridRow>
            <GridColumn width={4}>
              <div style={containerStyles}>
                <div style={stickyBarStyles}>
                  <Input icon="search" iconPosition="left" onChange={handleSearchChange} style={{ marginRight: 10 }} />
                  <Checkbox
                    label="Select All"
                    onClick={handleToggleAll}
                    style={{ margin: '10px 0' }}
                    checked={selectAllChecked}
                  />
                </div>
                <List>
                  {filteredKeys.filter(c => c !== 'date').map(chartKey => (
                    <ListItem key={chartKey}>
                      <Checkbox
                        label={chartKey}
                        name={chartKey}
                        checked={checkedItems.includes(chartKey)}
                        onChange={handleCheckboxChange}
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
            </GridColumn>
            <GridColumn width={12}>
              <StyledDropdown
                options={[{ text: 'Hourly', value: 'hourly' }, { text: 'Daily', value: 'daily' }]}
                value={groupBy}
                style={{ width: 100 }}
                onChange={(e, { value }) => setGroupBy(value)}
              />
              <LineChart
                height={300}
                data={updatedChartData}
                labelFormatter={labelFormatter}
                formatter={formatter}
                showTooltip
                showTotalInToolTip
                showXAxis
                XAxisFormatter={formatDate}
                dottedLineForInfinity
                tierColorMap={tierColorMap}
                sortKey="value"
              />
            </GridColumn>
          </GridRow>
        </Grid>
      )}
    </ContentCard>
  );
};

export default Chart;

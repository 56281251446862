import React from 'react';
import { Statistic } from 'semantic-ui-react';
import { formatCurrency } from '../../../utils/valueFormatter';

const PlatformSummary = ({ platformMetricsObj, hasRevenue }) => {
  const convTotal = platformMetricsObj?.total_count || 0;
  const convOverlap = platformMetricsObj?.overlap_count || 0;

  const revTotal = platformMetricsObj?.total_revenue || 0;

  let displayMetrics = {
    'TOTAL CONVERSIONS': convTotal.toLocaleString(),
    OVERLAP: convOverlap.toLocaleString(),
  };

  if (hasRevenue) {
    const revMetrics = {
      REVENUE: formatCurrency(revTotal),
      'AVG REVENUE': formatCurrency(revTotal / convTotal),
    };
    // Insert keys in the middle of the obj for right display order
    const extendedMetrics = [...Object.entries(displayMetrics), ...Object.entries(revMetrics)];
    displayMetrics = Object.fromEntries(extendedMetrics);
  }

  return (
    <div style={{ padding: '20px 10px', fontSize: '14px', display: 'flex', alignItems: 'center' }}>
      <Statistic.Group color="purple" size="tiny" style={{ flexBasis: '100%', justifyContent: 'space-evenly', margin: 0, paddingLeft: '10px', paddingRight: '10px' }}>
        {Object.entries(displayMetrics).map((obj, i, arr) => (
          <Statistic key={obj[0]} style={{ flex: 1, marginRight: i === arr.length - 1 ? 0 : 'auto', marginLeft: 'auto', marginBottom: 0 }}>
            <h2 style={{ fontSize: '14px', fontWeight: 'normal', textAlign: 'center' }}>{obj[0]}</h2>
            <Statistic.Value style={{ fontSize: '20px !important', fontWeight: '300', textAlign: 'center' }}>
              {obj[1]}
            </Statistic.Value>
          </Statistic>
        ))}
      </Statistic.Group>
    </div>
  );
};

export default PlatformSummary;

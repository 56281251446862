import React, { useEffect, useCallback } from "react";
import { Form } from "semantic-ui-react";
import { ContentCard } from "@rockerbox/styleguide";
import { redshiftStore, warehouseStore, statusStore } from "./zustandStore";
import { STATUS_FAILED } from "./constants";

const redshiftSelect = state => [
  state.regions,
  state.fetchAwsRegions,
  state.region,
  state.selectRegion,
  state.initSetup,
  state.fetchClusterName
];
const warehouseSelector = state => [state.warehouses, state.fetchWarehouse];
const statusSelector = state => [
  state.setStatus,
  state.setupFormLoading,
  state.changeSetupFormLoading
];

const RedshiftInit = props => {
  const [warehouses, fetchWarehouse] = warehouseStore(warehouseSelector);
  const [setStatus, setupFormLoading, changeSetupFormLoading] = statusStore(
    statusSelector
  );
  const [
    regions,
    fetchAwsRegions,
    region,
    selectRegion,
    initSetup,
    fetchClusterName
  ] = redshiftStore(redshiftSelect);
  const { platform } = props;

  useEffect(() => {
    fetchAwsRegions();
  }, []);

  useEffect(() => {
    const awsWarehouses = warehouses.filter(
      warehouse => warehouse.platform === platform
    );
    const connectionId =
      awsWarehouses.length > 0 ? awsWarehouses[0]["id"] : null;
    if (connectionId) {
      fetchClusterName(connectionId);
    }
  }, [warehouses.length]);

  const onRegionChange = useCallback((e, data) => selectRegion(data.value));
  const onSubmit = () => {
    changeSetupFormLoading(true);
    const requestBody = { region };
    initSetup(requestBody)
      .then(() => fetchWarehouse())
      .catch(() => setStatus(STATUS_FAILED))
      .finally(() => changeSetupFormLoading(false));
  };
  return (
    <ContentCard title="Continue Setup">
      <Form onSubmit={onSubmit} loading={setupFormLoading}>
        <Form.Group>
          <Form.Field width={5}>
            <Form.Dropdown
              label="Cluster Region"
              options={regions}
              selection
              fluid
              value={region}
              onChange={onRegionChange}
            />
          </Form.Field>
        </Form.Group>
        <Form.Button primary>Initialize</Form.Button>
      </Form>
    </ContentCard>
  );
};

export default RedshiftInit;

import React from 'react';
import { Button, Form, Label, Icon, Input } from 'semantic-ui-react';
import { ContentCard } from '@rockerbox/styleguide';
import parseLucene from "./luceneFilter";

const SEARCH_BY_DEFAULTS = [
  "total_spend","total_incremental_cost","total_sponsorship_cost",
  "total_assisted","total_assisted_revenue","total_attributed", "total_attributed_revenue",
  "entity_type", "entity_name"
]

const ALIAS_DEFAULTS = {
  "spend":"total_spend",
  "incremental_cost":"total_incremental_cost",
  "sponsorship_cost":"total_sponsorship_cost",
  "assisted":"total_assisted", 
  "assisted_revenue":"total_assisted_revenue", 
  "attributed":"total_attributed", 
  "attributed_revenue":"total_attributed_revenue", 
  "type":"entity_type", 
  "name":"entity_name"
}

const AdvancedSearch = (props) => {

  const { 
    data, 
    onChange,
    searchBy = SEARCH_BY_DEFAULTS, 
    searchAliases = ALIAS_DEFAULTS 
  } = props

  const [ searchBuffer, setSearchBuffer] = React.useState("")
  const [ searchValue, setSearchValue] = React.useState("")


  const filteredData = React.useMemo(() => parseLucene(
      data, searchValue.toLowerCase(), 
      (term, field) => {
        return (!term && ["spend"].includes(field)) ? 
          0 : (term && typeof(term) == "string") ? 
          term.toLowerCase() : term 
      },
      searchBy, 
      searchAliases
    ), 
    [searchValue, data]
  )

  React.useEffect(() => {
    onChange(false, { value: filteredData})
  },[filteredData])

  const clearFilters = () => {
    setSearchBuffer("")
    setSearchValue("")
  }
  const onSubmit = () => {
    setSearchValue(searchBuffer)
  }

  return (
    <>
        <ContentCard>
          <Form {...{onSubmit}} style={{marginBottom:"4px"}}>
            <Input
              // label={<Dropdown defaultValue='filter' options={[]} />}
              fluid icon="Search" action={<Button type="submit"> Search </Button>}
              onChange={(x, {value}) => setSearchBuffer(value.replace(/: /g,":"))}
            />
          </Form>
          {(searchBuffer && searchBuffer == searchValue) ?
            <>Current Filters: <Label>{searchBuffer}<Icon name="delete" onClick={clearFilters} /></Label></>:
            <div style={{opacity:.5,textAlign:"center"}}>hit enter to apply filter</div>
          }
        </ContentCard>

    </>
  )
}

export default AdvancedSearch;

import React from 'react';

import { IndexGridNew } from '@rockerbox/styleguide';

import { baseCols, facebookCols } from './constants';
import { groupByMonth, groupAndCalculateMean } from './helpers';

const Table = ({ data, platform }) => {
  const metrics = data ? groupByMonth(groupAndCalculateMean(data, platform), platform) : data;
  const cols = platform === 'facebook' ? [...baseCols, ...facebookCols] : baseCols;
  const displayName = platform === 'google' ? 'Adwords' : platform[0].toUpperCase() + platform.slice(1);
  const titleText = `${displayName} Monthly Performance Metrics`;

  if (!metrics || !metrics.length) return null;
  return metrics.length > 0 && (
    <IndexGridNew title={titleText} data={metrics} cols={cols} summaryRow fallbackMsg="No data found" />
  );
};
export default Table;

import React, { useEffect } from "react";
import moment from "moment";
import AceEditor from "react-ace";
import "brace/mode/mysql";
import "brace/theme/tomorrow";
import { Button, Icon } from "semantic-ui-react";
import { ContentCard } from "@rockerbox/styleguide";
import { snowflakeAccountNameMap } from "./constants";
import { snowflakeFormStore } from "./zustandStore";

const selector = state => [state.conversionNum, state.fetchConversionNum];

export const CopyButton = props => {
  return (
    <Button
      primary
      onClick={() => {
        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
          navigator.clipboard.writeText(props.path);
        } else {
          console.log(
            "Current browser doesn't support navigator.clipboard.writeText()"
          );
        }
      }}>
      <Icon name="copy outline" />
      Copy
    </Button>
  );
};

const SnowflakeSuccessInstruction = ({
  platform,
  filter_id,
  datasets,
  region = "us-east-1",
  pixel_source_name = "linksoul"
}) => {
  const [conversionNum, fetchConversionNum] = snowflakeFormStore(selector);

  const yesterday = moment
    .utc()
    .utcOffset(-5)
    .subtract(1, "days")
    .format("YYYY-MM-DD");

  useEffect(() => {
    fetchConversionNum(filter_id);
  }, [filter_id]);

  const tableName = React.useMemo(() => {
    const dataset = datasets.filter(d => d.dataset === 'compiled_mta_tiers')[0]
    if (dataset) {
      return dataset['warehouse_table_name']
    } else {
      return "tiers"
    }
  }, [datasets])

  return (
    <ContentCard title="Congratulations!">
      <h3>
        <b>
          You're just about there. All that is left to do is setup the database
          table in you Snowflake instance.
        </b>
      </h3>

      <h4>
        To complete your setup (with a user who has ACCOUNTADMIN permissions)
        run the following command in the Snowflake SQL Editor
      </h4>
      <AceEditor
        mode="mysql"
        theme="tomorrow"
        readOnly={true}
        fontSize={14}
        showPrintMargin={false}
        showGutter={false}
        highlightActiveLine={false}
        value={`CREATE DATABASE rockerbox FROM SHARE ${snowflakeAccountNameMap[region]}.rockerbox_${pixel_source_name};`}
        setOptions={{
          showLineNumbers: true,
          highlightGutterLine: false
        }}
        wrapEnabled={true}
        width={"100%"}
        height={"50px"}
      />
      <CopyButton
        path={`CREATE DATABASE rockerbox FROM SHARE ${snowflakeAccountNameMap[region]}.rockerbox_${pixel_source_name};`}
      />
      <h4>
        To test that everything is setup correctly, run the following query to
        check the number of conversions recorded in Rockerbox
      </h4>
      <AceEditor
        mode="mysql"
        theme="tomorrow"
        readOnly={true}
        fontSize={14}
        showGutter={false}
        highlightActiveLine={false}
        value={`SELECT sum(even) FROM rockerbox.public.${tableName}_live WHERE date = '${yesterday}';`}
        setOptions={{
          showLineNumbers: true,
          highlightGutterLine: false
        }}
        wrapEnabled={true}
        width={"100%"}
        height={"50px"}
      />
      <CopyButton
        path={`SELECT sum(even) FROM rockerbox.public.tiers_live WHERE date = '${yesterday}';`}
      />
      <h4>{`Conversion number of ${yesterday} is `}<b>{`${conversionNum}`}</b></h4>
    </ContentCard>
  );
};

export default SnowflakeSuccessInstruction;

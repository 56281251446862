import React, { useEffect } from 'react'
import { Segment, Button, Grid, Icon, Input, Form, Header, Loader } from 'semantic-ui-react';
import { CacheContext } from '../../../utils/CacheContext'
import { getAuthorizations, saveMappingTemplate, getAllIntegrations, getIntegrations } from '../../../V3/api/account'
import { submitAttributionRunJob } from '../../../V3/api/attributionRun'
import { PlatformsList } from './parts'
import { InviteUsersWidget } from '../parts/Invite';

// import { DEFAULT_HISTORY, FEATURES_TO_ENABLE, FEATURE_FLAGS } from '../helpers/constants'
// import { ALLOWED_PLATFORMS_MAPPING } from '../../../V3/constants/mappings'

const Platforms = ({ completeStep, advertiser, tier }) => {
  // TODO: unit test me
  const getAuths = () => (
    getAuthorizations().then(data =>
      data.map(auths => {
        const { authorizations, platform } = auths
        authorizations.map(a => a['platform'] = platform)
        auths['authorizations'] = authorizations
        return auths
      })
      .filter(a => !a.authorizations[0].batch_platform && a.platform != 'shopify')
    )
  )

  const [state, setState, Context] = React.useContext(CacheContext);
  const integrations = Context.getCache(`integrations`, getAllIntegrations) || [];
  const authorizations = Context.getCache(`authorizations`, getAuths) || [];
  const [hasAuth, setHasAuth] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    setLoading(true);
    if (!authorizations) return;
    let x = false;
    authorizations.map(p => {
      p.authorizations.map(a => {
        if (a.account_id != '') x = true;
      })
    });
    setHasAuth(x);
    setLoading(false);
  }, [authorizations])

  return (
    <div className='onboarding-platforms-view'>
      <Grid columns={1}>
        <Grid.Row>
          <Grid.Column>
            <Segment
              basic
              textAlign='center'
            >
              <Header as='h1'>
                Connect Advertising Platforms
                {tier === 'starter' && 
                <Header.Subheader>
                  Where are you spending your marketing dollars? <br/>Add your accounts to Rockerbox so we can help you track their perfomance.
                </Header.Subheader>}
                {tier != 'starter' && 
                <Header.Subheader>
                  Authenticate your core Advertising Platforms to enable Rockerbox to begin tracking spend against these channels. <br/><br/>
                  For any platforms not listed here, your onboarding team will provide specific instructions once you complete our initial onboarding flow!
                </Header.Subheader>}
              </Header>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column></Grid.Column>
          <Grid.Column>
            {loading ? <Loader active />
              :
              <PlatformsList {...{ integrations, authorizations, tier }} />
            }
          </Grid.Column>
          <Grid.Column>
            <InviteUsersWidget
              header="Don't have access to all your accounts?"
              subheader='Invite someone else on your team who has access to help set it up'
              advertiser={advertiser}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {hasAuth &&
        <Button
          primary
          size="huge"
          onClick={completeStep}
          style={{
            position: 'fixed',
            right: 25,
            bottom: 85,
            width: 'calc((100vw - 380px)/3)',
            height: 50,
          }}
        >
          Next
          <Icon name='right arrow' />
        </Button>
      }
    </div>
  );
}

export default Platforms;


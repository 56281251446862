import _ from "lodash";

const randomKey = () => {
  return (
    Math.random().toString(36).substring(2, 16) +
    Math.random().toString(36).substring(2, 16)
  ).toUpperCase();
};

export const keysMap = {
  served: [
    "served_ott_device_events",
    "served_web_events",
    "served_in_app_events",
  ],
  batch: ["batch_ott_device_events", "batch_web_events", "batch_in_app_events"],
  tatari: ["tatari_events"],
};

const converStrKeyToObject = (tierMap) => {
  return Object.entries(tierMap)
    .map(([key, count]) => {
      const tiers = key.split(";");
      return {
        key: randomKey(),
        num_days: count,
        tier_one: tiers[0],
        tier_two: tiers[1],
        tier_three: tiers[2],
        tier_four: tiers.length > 5 ? tiers.slice(3, -1).join(";") : tiers[3],
        tier_five: tiers[tiers.length - 1],
      };
    })
    .sort((a, b) => b.num_days - a.num_days);
};

const createCountMap = (summary, type) => {
  const keys = keysMap[type];
  const countsMap = keys.reduce((prev, key) => {
    prev[key] = summary.reduce((sum, cur) => sum + cur[key]["count"], 0);
    return prev;
  }, {});

  countsMap["total"] = Object.values(countsMap).reduce(
    (prev, cur) => prev + cur,
    0
  );
  return countsMap;
};

const createTierMap = (summary, type) => {
  const keys = keysMap[type];
  return keys.reduce((prev, key) => {
    prev[key] = summary
      .map((obj) => obj[key]["tiers_count"])
      .filter((count) => count.length > 0);
    return prev;
  }, {});
};

const groupByTier = (dateArray) => {
  const tierMap = {};
  for (let dateData of dateArray) {
    for (let tier of dateData) {
      const { tier_one, tier_two, tier_three, tier_four, tier_five } = tier;
      const mapKey = `${tier_one};${tier_two};${tier_three};${tier_four};${tier_five}`;
      if (tierMap[mapKey]) {
        tierMap[mapKey] += 1;
      } else {
        tierMap[mapKey] = 1;
      }
    }
  }

  return converStrKeyToObject(tierMap);
};

const calculateTotal = (dateMap) => {
  const totalTiers = Object.values(dateMap)
    .reduce((prev, cur) => prev.concat(cur))
    .sort((a, b) => b.num_days - a.num_days);

  dateMap["total"] = _.uniqWith(totalTiers, (a, b) => {
    return (
      a.tier_one === b.tier_one &&
      a.tier_two === b.tier_two &&
      a.tier_three === b.tier_three &&
      a.tier_four === b.tier_four &&
      a.tier_five === b.tier_five
    );
  });

  return dateMap;
};

const contructDict = (summary, dates, type) => {
  const keys = keysMap[type];
  dates = dates.sort(
    (a, b) => parseInt(a.replace(/\-/g, "")) - parseInt(b.replace(/\-/g, ""))
  );
  const tierAndTypeMap = {};
  for (let i = 0; i < dates.length; i++) {
    const date = dates[i];
    const data = summary[i];
    keys.forEach((key) => {
      data &&
        data[key]["tiers_count"].map((record) => {
          const {
            tier_one,
            tier_two,
            tier_three,
            tier_four,
            tier_five,
            count,
          } = record;
          const newKey = `${tier_one};${tier_two};${tier_three};${tier_four};${tier_five}`;
          if (tierAndTypeMap[newKey]) {
            tierAndTypeMap[newKey].push({ date, count, key });
          } else {
            tierAndTypeMap[newKey] = [{ date, count, key }];
          }
        });
    });
  }

  return tierAndTypeMap;
};

export const aggregateSummary = (data, type) => {
  const { summary, dates } = data;
  const categoryMap = createTierMap(summary, type);
  const countsMap = createCountMap(summary, type);

  const dateMap = {};
  Object.entries(categoryMap).forEach(([key, value]) => {
    dateMap[key] = groupByTier(value);
  });

  const tierAndTypeMap = contructDict(summary, dates, type);

  return {
    tiers: calculateTotal(dateMap),
    counts: countsMap,
    detailDict: tierAndTypeMap,
  };
};

export const prepareTatariChartData = (offsets, counts, type) => {
  const { summary: data } = offsets;
  const tatariCounts = counts["tatari_events"];
  const dedupData = data.reduce((prev, cur) => {
    if (prev && prev[cur.offset]) {
      prev[cur.offset] = prev[cur.offset] + cur.tatari;
    } else {
      prev[cur.offset] = cur.tatari;
    }
    return prev;
  }, {});

  const chartData = Object.entries(dedupData)
    .map(([key, value]) => ({
      offset: key,
      tatari: [0, value],
    }))
    .sort((a, b) => a.offset - b.offset);

  return chartData;
};

export const prepareChartData = (offsets, counts, type) => {
  const { summary: data } = offsets;
  const deviceCount = counts[`${type}_ott_device_events`];
  const sumData = data.reduce((prev, cur) => {
    if (prev[cur.offset]) {
      prev[cur.offset] = {
        web: prev[cur.offset]["web"] + cur.web,
        mobile: prev[cur.offset]["mobile"] + cur.mobile,
        device:
          cur.device || cur.device === 0
            ? prev[cur.offset]["device"] + cur.device
            : deviceCount,
      };
    } else {
      prev[cur.offset] = {
        web: cur.web,
        mobile: cur.mobile,
        device: cur.device || cur.device === 0 ? cur.device : deviceCount,
      };
    }
    return prev;
  }, {});

  const chartData = Object.entries(sumData).map(([key, value]) => {
    return {
      offset: key,
      device: [0, value["device"]],
      web: [0, value["web"]],
      mobile: [0, value["mobile"]],
    };
  });
  return chartData;
};

export const getOffsetOptions = (chartData, key) => {
  if (chartData.length <= 0) {
    return [];
  }
  const total =
    chartData[chartData.length - 1][key] &&
    chartData[chartData.length - 1][key][1];
  const options = [
    { pcg: 0.25, key: 1, text: "25% or least" },
    { pcg: 0.5, key: 2, text: "50%" },
    { pcg: 0.75, key: 3, text: "75%" },
  ];
  for (let option of options) {
    const item = chartData.find(
      (item) => item[key] && item[key][1] > Math.floor(total * option.pcg)
    );
    option["value"] = item ? parseInt(item["offset"]) : 5;
  }

  return options.filter((option, i, arr) => {
    if (i > 0 && arr[i - 1].value == option.value) {
      return false;
    }
    return true;
  });
};

export const groupByDate = (list) => {
  if (!list) {
    return [];
  }
  const map = list.reduce((prev, cur) => {
    if (prev[cur["date"]]) {
      prev[cur["date"]] = prev[cur["date"]] + cur.count;
    } else {
      prev[cur["date"]] = cur.count;
    }
    return prev;
  }, {});

  return Object.entries(map).map(([key, value]) => {
    return { date: key, count: value, key: "total" };
  });
};

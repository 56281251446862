import React from 'react';
import { StatCard } from '@rockerbox/styleguide';
import * as d3 from 'rockerbox_d3_legacy_clone';
import CacheContext from '../../utils/CacheContext';

const ViewStats = (props) => {
  const [{currency_code}] = React.useContext(CacheContext);

  return (
    <React.Fragment>
      <StatCard
        segmentColor={props.plotVariable == "conversions" && "blue"}
        onClick={props.handlePlotVariable("conversions")}
        value={ d3.sum(props.data.values, x => x.conversions ) }
        label="Conversions" explainer='Past 30 Days' size='small'/>
      <StatCard
        segmentColor={props.plotVariable == "revenue" && "blue"}
        onClick={props.handlePlotVariable("revenue")}
        value={ d3.sum(props.data.values, x => x.revenue) }
        label="Revenue" explainer='Past 30 Days' size='small'/>
      <StatCard
        segmentColor={props.plotVariable == "spend" && "blue"}
        onClick={props.handlePlotVariable("spend")}
        value={ d3.sum(props.data.values, x => x.spend) }
        label='Spend' type='currency' currencyCode={currency_code} explainer='Past 30 Days' size='small'/>
    </React.Fragment>
  )
}
export default ViewStats

import React from 'react';
import { Redirect } from 'react-router-dom';

const createNewRoute = (currentPath, currentHash, newPath) => {
  const oldPathArr = currentPath.split('/');
  const newPathArr = newPath.split('/');
  const pathUpdate = oldPathArr.map((val, i) => (newPathArr[i] ? newPathArr[i] : val)).join('/');
  return `${pathUpdate}${currentHash}`;
};

export const withRedirect = (WrappedComponent, redirect) => {
  const redirectExists = !!redirect.newRoute;
  const currentPath = window.location.pathname;
  const currentHash = window.location.hash;
  const newRoute = redirectExists ? createNewRoute(currentPath, currentHash, redirect.newRoute) : false;
  return props => {
    if (newRoute) { // redirect condition
      return <Redirect to={newRoute} />;
    }
    return <WrappedComponent props={props} />;
  };
};

import { useState, useEffect, useMemo } from 'react';

import { useGlobalState } from './global';
import { getAllUsersRBAC, getRolesInRBAC, getAdvertiserRBAC, getUserAuthorizationActions, getAllPermissionsInRBAC } from '../api/rbacApi';
import { isDefaultAdmin } from '../utils/rbac';

export const useRBACAdvertiser = () => {
  const { account } = useGlobalState();
  const [advertiserExistsRBAC, setAdvertiserExistsRBAC] = useState(false);

  useEffect(() => {
    if (!account) return;
    getAdvertiserRBAC(account.pixel_source_name).then(advertiserInRBAC => {
      if (advertiserInRBAC.length) {
        setAdvertiserExistsRBAC(true);
      }
    });
  }, [account]);

  return {
    advertiserExistsRBAC,
  };
};

export const useRBACUsers = users => {
  const [userToRoleMap, setUserToRoleMap] = useState(false);

  useEffect(() => {
    getAllUsersRBAC().then(usersRBAC => {
      if (!usersRBAC?.length) return;
      const mapUserIdToRole = usersRBAC.reduce((accu, { args }) => {
        const userId = args[0].id;
        // For custom roles, this will be a type 'Role' and the role id will include the pixel source name before the '___'
        const roleId = args[1].type === 'Role' ? args[1].id.split('___')[1] : args[1].id;
        accu[userId] = roleId;
        return accu;
      }, {});
      setUserToRoleMap(mapUserIdToRole);
    });
  }, [users]);

  const usersWithRoles = useMemo(() => {
    if (!userToRoleMap) return users;
    return users.map(user => ({
      ...user,
      role: userToRoleMap[user.id],
    }));
  }, [userToRoleMap, users]);

  return {
    usersWithRoles,
    userToRoleMap,
    setUserToRoleMap,
  };
};

export const useRBACRoles = () => {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    getRolesInRBAC().then(rolesInRBAC => setRoles(rolesInRBAC));
  }, []);

  const roleOptions = useMemo(() => {
    if (!roles || !roles.length) return [];
    return roles.map(role => ({
      text: role.charAt(0).toUpperCase() + role.slice(1),
      value: role,
      key: role,
    }));
  }, [roles]);

  return {
    roles,
    roleOptions,
  };
};

export const useRBACPermissions = () => {
  const { user } = useGlobalState();
  const { advertiserExistsRBAC } = useRBACAdvertiser();
  const [allPermissions, setAllPermissions] = useState([]);
  const [authorizedPermissionsRBAC, setAuthorizedPermissionsRBAC] = useState([]);

  useEffect(() => {
    getAllPermissionsInRBAC().then(permissions => setAllPermissions(permissions));
  }, []);

  useEffect(() => {
    if (!user) return;
    getUserAuthorizationActions(user.id).then(actions => setAuthorizedPermissionsRBAC(actions));
  }, [user]);

  const isRBACAdmin = useMemo(() => {
    if (!user) return false;
    return isDefaultAdmin(user.email, advertiserExistsRBAC);
  }, [user, advertiserExistsRBAC]);

  const authorizedPermissions = useMemo(() => {
    if (isRBACAdmin) return allPermissions;
    return authorizedPermissionsRBAC;
  }, [isRBACAdmin, allPermissions, authorizedPermissionsRBAC]);

  return {
    authorizedPermissions,
  };
};

import React from 'react';

import { useUrlStateWithDefaults } from './urlParamHooks';
import useAppSettings from '../../hooks/useAppSettings';

import GatedByPlatformSelection from './GatedByPlatformSelection';

const GatedByGlobalSetup = () => {
  const settings = useAppSettings();
  const { defaultModel } = settings;

  const urlState = useUrlStateWithDefaults({ defaultView: 'platform', defaultModel });

  return (
    <GatedByPlatformSelection
      {...{ settings, urlState }}
    />
  );
};

export default GatedByGlobalSetup;

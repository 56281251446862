import React from 'react';

import { Header } from 'semantic-ui-react';

import { FILTER_FEATURES_BY, HEADER_DESCRIPTION } from './constants';
import { buildExportCSVFunction } from './export/export';
import ExportButton from './export/ExportButton';
import { useBaselineRecommendation as useBaselineData } from './form/baseline/useBaselineData';
import { useBaselinePeriod } from './form/baseline/useBaselinePeriod';
import { useConstraints } from './form/constraints/useConstaints';
import Form from './form/Form';
import { useOptimization } from './form/optimize/useOptimization';
import { useProjectionPeriod } from './form/projection/useProjectionPeriod';
import { getPlanNames, useFilteredData } from './hooks';
import ForecastTable from './table/ForecastTable';
import { TreeTableLoader } from '../../../components/loaders';
import { useGlobalState } from '../../../hooks/global';
import { useForecast } from '../forecast/useForecast';
import { useResponseCurves } from '../hooks/useResponseCurves';
import { ModelSectionHeader } from '../parts/stateless';

const BudgetForecast = ({
  isConversionKPI, features: featuresRaw, featureNameMap,
  responseCurves: responseCurvesRaw,
  trainingData: trainingDataRaw, trainingStats: trainingStatsRaw,
  seasonality,
}) => {
  const { account } = useGlobalState();
  const { advertiser_name } = account;
  const { features, responseCurves, trainingData, trainingStats } = useFilteredData(featuresRaw, responseCurvesRaw, trainingDataRaw, trainingStatsRaw, FILTER_FEATURES_BY);
  const { projectionPeriod } = useProjectionPeriod();
  const { featureStats } = useBaselinePeriod({ trainingData, projectionPeriod });
  const { constraints, setConstraints, setConstraintPercentage } = useConstraints({ featureStats });
  const { planNames } = getPlanNames(isConversionKPI);
  const { responseCurvesArr } = useResponseCurves({ responseCurves });
  const { baseline, baselineTotal, activeFeatures, inactiveFeatures } = useBaselineData({
    responseCurves,
    trainingStats,
    featureStats,
    constraints,
  });

  const { isDirty, fieldValues, updateFieldValues, submitForecast, optimization } = useOptimization({
    baseline,
    responseCurves: responseCurvesArr,
    constraints,
    isConversionKPI,
  });

  const { data } = useForecast({
    fieldValues,
    responseCurves: responseCurvesArr,
    features,
    featureStats,
    seasonality,
  });

  const exportCSV = buildExportCSVFunction(data, featureNameMap, featureStats, advertiser_name, projectionPeriod);
  const loading = !responseCurvesArr?.length;
  const scenarioName = !isDirty ? `${planNames[optimization]} Scenario` : 'Submit Scenario to generate forecast';

  return (
    <>
      <ModelSectionHeader text="Marketing Scenario Planner" />
      <Header as="h4" content={HEADER_DESCRIPTION} style={{ marginBottom: 2, fontWeight: 400 }} />
      {loading && <TreeTableLoader />}
      {!loading && (
        <Form
          {...{
            isConversionKPI,
            trainingData,
            baselineTotal,
            activeFeatures,
            inactiveFeatures,
            featureNameMap,
            constraints,
            setConstraints,
            setConstraintPercentage,
            submitForecast,
          }}
        />
      )}
      {!loading && !isDirty && optimization && (
        <>
          <ModelSectionHeader text={scenarioName}>
            <ExportButton onClick={exportCSV} />
          </ModelSectionHeader>
          <ForecastTable {...{ isConversionKPI, isDirty, constraints, featureNameMap, featureStats, data, updateFieldValues }} />
        </>
      )}
    </>
  );
};
export default BudgetForecast;

import React from 'react';
import { BucketsPath } from '@rockerbox/styleguide';
import { useGlobalState } from '../../../hooks/global';

const Path = ({ item, keyInsight, tierColors }) => {
  const { labelKey, labelName, formatter = v => v } = keyInsight;
  const { labelKey2, labelName2, formatter2 = v => v } = keyInsight;
  const { currencyCode } = useGlobalState();
  const metric = (x, formatter) => formatter(item[x], currencyCode); // eslint-disable-line no-shadow

  const newArr = [];
  item.path.forEach(path => {
    newArr.push({ buckets: path });
  });

  return (
    <div style={{ paddingBottom: 20, paddingRight: 10 }}>
      <div>
        <h3 style={{ color: '#666666', fontSize: 14, paddingBottom: 3 }}>
          {`${metric(labelKey, formatter)}${labelName ? ` ${labelName}` : ''}`}
          {labelKey2 && `, ${metric(labelKey2, formatter2)} ${labelName2 || ''}`}
        </h3>
      </div>
      <div>
        <BucketsPath steps={newArr} colorMap={tierColors} />
      </div>
    </div>
  );
};

export default Path;

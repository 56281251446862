import React, { useEffect, useCallback } from "react";
import { IndexGridNew } from "@rockerbox/styleguide";
import { Button, Table, Icon } from "semantic-ui-react";
import { datasetAccountingStore, syncCardStore } from "./zustandStore";

const datasetSelector = state => [
  state.datasetId,
  state.snapshots,
  state.snapshotId,
  state.setSnapshotId,
  state.fetchSnapshots,
  state.fetchSnapshotMeta
];
const syncSelector = state => [
  state.cardLoading,
  state.setCardLoading,
  state.updateCardStatus
];

const SnapshotIndex = props => {
  const [
    datasetId,
    snapshots,
    snapshotId,
    setSnapshotId,
    fetchSnapshots,
    fetchSnapshotMeta
  ] = datasetAccountingStore(datasetSelector);
  const [cardLoading, setCardLoading, updateCardStatus] = syncCardStore(
    syncSelector
  );

  const LinkButton = props => {
    const { item } = props;
    const onStatusClick = useCallback((e, data) => {
      setSnapshotId(item.id);
      updateCardStatus("snapshot_status");
    });

    return (
      <Table.Cell>
        <Button primary onClick={onStatusClick}>
          Status
        </Button>
      </Table.Cell>
    );
  };

  const cols = [
    { key: "id", display: "Snapshot Id" },
    { key: "snapshot_name", display: "Snapshot Name" },
    { key: "warehouse_table_name", display: "Table Name" },
    { key: "description", display: "Description" },
    { key: "updated_at", display: "Date Complete" },
    { key: "id", dispaly: "Status", as: LinkButton }
  ];

  useEffect(() => {
    if (datasetId) {
      setCardLoading(true);
      fetchSnapshots(datasetId).finally(() => setCardLoading(false));
    }
  }, [datasetId]);

  const onCreation = useCallback(() => {
    updateCardStatus("snapshot_creation");
  });

  return (
    <React.Fragment>
      <IndexGridNew data={snapshots} cols={cols} paginate itemsPerPage={10} />
      <Button onClick={onCreation}>Create</Button>
    </React.Fragment>
  );
};

export default SnapshotIndex;

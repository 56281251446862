import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import { FormWrap, FieldWrap, SplitLayout, ColumnLayout, ContentCard, IndexGrid } from '@rockerbox/styleguide';
import { Input, Grid, Form, Header, Menu, Icon, Dropdown } from 'semantic-ui-react';

import * as routes from '../../routes';
import { getProjects, getModelRunData, getModelRuns, postModel } from '../../utils/api';
import { track } from '../../utils/tracking';

import SelectConversionType from './Segment';
import ModelSummary from './ModelSummary';
import ModelCompare from './Compare';
import ModelSetup from './ModelSetup';
import ModelNav, { SelectView, SelectModel } from './ModelNav';
import BuildModel from './BuildModel';
import ModelRuns from './ModelRuns';
import FeatureSummary from './FeatureSummary';
import Features from './Features';
import CDFPlot from './CDFPlot';
import CorrelationPlot from './CorrelationPlot';
import ROCPlot from './ROCPlot';
import PRPlot from './PRPlot';

import css from './charts.css';

const DefaultNoModel = () => (
  <ContentCard title="No model runs">
    <div style={{textAlign:"center"}}>
      Hey there, it looks like no completed model runs have completed for this segment.
    </div>
  </ContentCard>
)

const projectToOption = (resp) => {
  return { text: resp.name, value: resp.id, key: resp.id }
}

class ModelCharts extends Component {

  state = {
    globalThreshold: 0.5,
    modelSubmission: {},
    newModelBuild: {},
    projects: [],
    rockerboxUser: false
  }

  getComparisonData(run_id) {
    if (run_id) {
      this.setState({ loadingCompare: true })
      getModelRunData(run_id).then(data => {
        this.setState({
          loadingCompare: false,
          compare_roc: data.response.data.roc,
          compare_confusion_data: data.response.data.confusion_data
        });
      });
    }
  }

  getProjects() {
    getProjects()
      .then( resp => {
        const projects = resp.response.map(projectToOption)
        this.setState({ projects })
      })

  }

  getData(run_id) {
    if (run_id) {
      this.setState({loadingData: true})
      getModelRunData(run_id).then(data => {
        data.response.data.touchpoints.map(obj => {
          try { obj['weight'] = data.response.data.weights.touchpoint[obj.touchpoint] } catch(e) {}
          try { obj['f1_gain'] = data.response.data.weights.f1_gain[obj.touchpoint] } catch(e) {}
          obj['conversion_rate'] = parseInt(obj['num_converters']/obj['num_users']*1000)/10 + "%"
          obj['multi_touch_rate'] = parseInt(obj['num_multi_touch_users']/obj['num_users']*1000)/10 + "%"
          obj.touchpoint.split("|").map((k,i) => obj["tier_" + (i+1)] = k)
        })

        const { submission, start_date, end_date } = data.response;
        const { timebucket_vectors } = submission;

        this.setState({
          loadingDate: false,
          modelRun: data.response,
          modelSubmission: submission,
          newModelBuild: { start_date, end_date, timebucket_vectors },
          modelRunData: data.response.data,
          roc: data.response.data.roc,
          pr: data.response.data.pr,
          touchpoints: data.response.data.touchpoints,
          correlations: data.response.data.correlations,
          confusion_data: data.response.data.confusion_data
        });
      });
    }
  }

  componentDidMount() {
    const { id, run_id, comparisonId, view } = this.props.match.params;

    this.getData(run_id)
    this.getProjects()
    if (comparisonId) this.getComparisonData(comparisonId)


    getModelRuns().then(modelRuns => {
      const filteredModelRuns = modelRuns.filter(c => c.conversion_id == id);
      if ((filteredModelRuns.length > 0) && !run_id) this.setRun(modelRuns[0].value);
      if (view == "compare" && (filteredModelRuns.length > 1) && !comparisonId) this.setCompare(modelRuns[1].value);
      this.setState({ modelRuns: filteredModelRuns })

      const { username } = this.props;
      const isRockerboxUser = username && username.includes('@rockerbox.com');
      isRockerboxUser ? this.setState({rockerboxUser: true}) : this.setState({rockerboxUser: false});
    })
  }

  setModelRunByDefault = (runs) => {
    const { id, view, run_id, comparisonId } = this.props.match.params;
    const { modelRuns } = this.state
    const filteredRuns = (runs || modelRuns).filter(x => x.conversion_id == id)

    const isComparison = ((view == "compare") && !comparisonId)
    const hasRuns = filteredRuns.length > 0
    const hasTwoRuns = filteredRuns.length > 1

    if (!hasRuns) this.setRun(false)
    if (hasRuns) this.setRun(filteredRuns[0].id)
    if (isComparison && hasTwoRuns) this.setCompare(filteredRuns[1].id)
  }

  filterModelRuns = (modelRuns) => {
    const { id } = this.props.match.params
    return modelRuns.filter(c => c.conversion_id == id);
  }

  componentDidUpdate(prevProps, prevState) {
    const { id, view, run_id, comparisonId } = this.props.match.params;
    const { modelRuns } = this.state;
    const prevId = prevProps.match.params.id;
    const prevRunId = prevProps.match.params.run_id;
    const prevComparisonId = prevProps.match.params.comparisonId;

    const { username } = this.props;
    if (run_id !== prevRunId) this.getData(run_id)
    if (comparisonId && comparisonId !== prevComparisonId) this.getComparisonData(comparisonId)

    if (id && (id !== prevId) ) {
      getModelRuns().then(modelRuns => {
        const filteredRuns = this.filterModelRuns(modelRuns)
        this.setModelRunByDefault(filteredRuns)
        this.setState({ modelRuns: filteredRuns })
      })
    }

    if(prevProps.username !== username) {
      const isRockerboxUser = username.includes('@rockerbox.com');
      isRockerboxUser ? this.setState({rockerboxUser: true}) : this.setState({rockerboxUser: false});
    }

  }

  setRun = (runId) => {
    const { run_id, id, comparisonId } = this.props.match.params;
    const path = routes.modelsView + "/" + id + "/" + runId + (comparisonId ? `/compare/${comparisonId}` : "/setup")
    const { push } = this.props.history
    push(path)
    if (run_id != runId) {
      this.setState({
        loadingCompare: false,
        roc: false,
        confusion_data: false
      });
    }
  }
  setCompare = (comparisonId) => {
    const { id, run_id } = this.props.match.params;
    const path = routes.modelsView + "/" + id + "/" + run_id + "/compare/" + comparisonId
    const { push } = this.props.history
    push(path)

    this.setState({
      loadingCompare: false,
      compare_roc: false,
      compare_confusion_data: false
    });
  }

  setView = (view) => {
    const { id, run_id } = this.props.match.params;
    if (id && run_id) {
      const path = routes.modelsView + "/" + id + "/" + run_id + "/" + view
      const { push } = this.props.history
      push(path)
      this.setState({
        loadingCompare: false,
        compare_roc: undefined,
        compare_confusion_data: undefined
      });
    }
  }

  setGlobalThreshold = (event) => {
   const globalThreshold = event.currentTarget.value/100
   console.log(globalThreshold)
   this.setState({ globalThreshold: event.currentTarget.value/100 })
  }

  updateBuild = (field) => (evt, value) => {
    const _newModelBuild = this.state.newModelBuild;
    const newModelBuild = _.cloneDeep(_newModelBuild);
    newModelBuild[field] = value.value;
    this.setState({ newModelBuild })
  }
  submitModel = () => {
    const { newModelBuild } = this.state;
    const { id, run_id } = this.props.match.params;
    newModelBuild['filter_id'] = id;

    const DEFAULTS = {
      "start_date":moment.utc().utcOffset(-5).format("YYYY-MM-DD"),
      "end_date":moment.utc().utcOffset(-5).format("YYYY-MM-DD"),
      "binary": false,
      "model": 'logistic',
      "attr": 'multi',
      "score": 'f1',
      "file_location": "/tmp",
      "include_direct": true,
      "datasets": ["onsite"]
    }

    const toPost = Object.assign({},DEFAULTS,newModelBuild)
    postModel(toPost)
      .then(x => {
        track('model-build', toPost)
      })
  }


  render() {
    const { projects } = this.state;
    const { confusion_data, correlations, newModelBuild, modelSubmission, modelRuns, modelRun, roc, pr, touchpoints, rockerboxUser } = this.state;
    const { globalThreshold, compare_confusion_data, compare_roc } = this.state;
    const { view, id, run_id, comparisonId } = this.props.match.params;
    const runs = modelRuns ? modelRuns && modelRuns.filter(x => x.conversion_id == id) : []



    const modelHeaders = [{display:"Summary",key:"key"}, {display:"",key:"value"}]
    const modelData = modelRun && touchpoints && [
      {"key":"Features", "value":touchpoints && touchpoints.length},
      {"key":"Datapoints", "value":modelRun.datapoints },
      {"key":"Excluded Datapoints", "value":modelRun.excluded_datapoints },
      {"key":"Users", "value":modelRun.total_users},
      {"key":"Converters", "value":modelRun.converters},
      {"key":"Multi-touch %", "value":modelRun.multi_touch_users/modelRun.total_users},
      {"key":"Multi-touch conversion %", "value":modelRun.multi_touch_converters/modelRun.converters},
    ]

    const startDate = moment((modelRun && modelRun.start_date) || new Date());
    const endDate = moment((modelRun && modelRun.end_date) || new Date());

    const featureHeaders = [{display:"Setup",key:"key"}, {display:"",key:"value"}]
    const featureData = modelRun && [
      {"key":"Start Date", "value":modelRun.start_date},
      {"key":"End Date", "value":modelRun.end_date},
      {"key":"", "value":""},
      {"key":"Vector Tier Construction", "value":modelSubmission.tiers || "Standard"},
      {"key":"Time-bucketed Vectors", "value":modelSubmission.timebucket_vectors || "None"},
      {"key":"First Sequence Vectors", "value":modelSubmission.first_vectors || "None"},
      {"key":"Last Sequence Vectors", "value":modelSubmission.last_vectors || "None"},
      {"key":"Excluded Vectors", "value":modelRun.exclusion_vectors || "None"},
      {"key":"Forced Vectors", "value":modelRun.forced_vectors || "None"},
      {"key":"~ Max Num Vectors", "value":modelRun.max_features|| "100"},
      {"key":"Min Touchpoints per vector", "value":modelRun.min_touchpoints || "None"},
      {"key":"", "value":""},
      {"key":"Cross Validation Folds", "value":"5"},
      {"key":"CV Fold Selection", "value":"Stratified"},
      {"key":"Regularization Strategy", "value":"L2"},
      {"key":"Scoring", "value":modelSubmission.score},
      {"key":"Regularization lambda", "value":"5"},
      {"key":"Git Hash", "value":modelRun.git_hash.slice(0,10)}
    ]

    const isSetup = (view == "setup" && modelRuns)
    const isDataSummary = (view == "data_summary" && modelRuns)
    const isFeatureSummary = (view == "feature_summary" && touchpoints && touchpoints.length > 0 )
    const isFeatureData = (view == "feature_data" && touchpoints && touchpoints.length > 0)
    const isCorrelation = (view == "feature_correlation" && correlations)
    const isModelSummary = (view == "model_summary" && confusion_data)
    const isModelROC = (view == "model_roc" && roc && confusion_data)
    const isModelPR = (view == "model_pr" && pr && confusion_data)
    const isModelCompare = (view == "compare" )
    const hasNoModel = (modelRuns && modelRuns.length == 0)

    const { setView, setRun, setCompare, updateBuild, submitModel, setGlobalThreshold } = this;


    return (
      <div style={{padding:"5px"}}>
        <ContentCard noContent borderless disableHeaderEllipse
          title={
            <SelectConversionType
              {...this.props}
              modelRuns={runs}
              runId={run_id} setRun={(x) => this.setRun(x)}
            />
          }
          topRight={
            <SelectView {...{view, run_id, modelRuns, setRun, setView, comparisonId}} />
          }
        />
        {
          isModelCompare &&
          <ModelCompare
            {...{id, run_id, comparisonId}}
            {...{modelRuns, globalThreshold, confusion_data, roc, compare_confusion_data, compare_roc}}
            {...{setRun, setCompare, setGlobalThreshold}}
            />
        }
        { !isModelCompare && runs.length == 0 &&
          <ColumnLayout stretched leftWidth={3} rightWidth={3} centerWidth={10}
            leftContent={null}
            rightContent={null}
          >
            { hasNoModel && <DefaultNoModel /> }
            { rockerboxUser ? <BuildModel {...{projects, model: newModelBuild, touchpoints, updateBuild, submitModel}} /> : null}
          </ColumnLayout>

        }
        { !isModelCompare && runs.length != 0 &&
          <ContentCard
            noContent borderless disableHeaderEllipse
            title={<SelectModel id={run_id} onSelect={setRun} {...{modelRuns}}/>}
          />
        }
        { !isModelCompare && runs.length != 0 &&
          <ColumnLayout stretched leftWidth={3} rightWidth={3} centerWidth={10}
            leftContent={<ModelNav {...{view, run_id, modelRuns, setRun, setView}} /> }
            rightContent={rockerboxUser ? <BuildModel {...{projects, model: newModelBuild, touchpoints, updateBuild, submitModel}} /> : null}
          >
            { hasNoModel && <DefaultNoModel /> }
            { isSetup && <ModelSetup {...{featureHeaders,featureData}} /> }
            { isDataSummary && <ModelRuns {...{modelHeaders, modelData, run_id}} /> }
            { isFeatureSummary && <FeatureSummary {...{touchpoints}} /> }
            { isFeatureData && <Features {...{touchpoints}} /> }
            { isCorrelation && <CorrelationPlot data={correlations} title="Percent Classified Per Threshold" /> }
            { isModelSummary && <ModelSummary data={confusion_data} /> }
            { isModelROC && <ROCPlot cfData={confusion_data} data={roc} title="Receiver operating characteristic" /> }
            { isModelPR && <PRPlot cfData={confusion_data} data={pr} title="Recall/Precision Curve" /> }
          </ColumnLayout>
        }
      </div>
    );
  }
}

function mapStateToProps (state, props) {
  const { username } = state.app

  return { username }
}

export default withRouter(connect(mapStateToProps)(ModelCharts));

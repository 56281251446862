import React from 'react';
import { BasicViewHeader, ViewAccessWrapper } from '../../components';
import Steps from './Steps';

const Surveys = () => (
  <>
    <BasicViewHeader
      header="Surveys"
      subheader="Use post-purchase surveys to uncover the marketing channels that drive customers to your brand"
    />
    <Steps />
  </>
);

const SurveysWrapper = () => (
  <ViewAccessWrapper
    viewName="surveys"
    viewComponent={<Surveys />}
  />
);

export default SurveysWrapper;

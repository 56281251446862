import React from 'react';

import { Segment, Table, TableHeader, TableHeaderCell, TableBody, TableRow, TableCell } from 'semantic-ui-react';

const DataTable = ({ metrics, formatter }) => (
  <Table celled className="mmm-custom-data-table">
    <TableHeader>
      <TableRow style={{ fontWeight: 600 }}>
        <TableHeaderCell>Channel</TableHeaderCell>
        <TableHeaderCell textAlign="right">Minimum Date</TableHeaderCell>
        <TableHeaderCell textAlign="right">Maximum Date</TableHeaderCell>
        <TableHeaderCell textAlign="right">Minimum</TableHeaderCell>
        <TableHeaderCell textAlign="right">Maximum</TableHeaderCell>
        <TableHeaderCell textAlign="right">Total</TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      { Object.keys(metrics).map(channel => {
        if (channel === 'date') return;
        const { min, max, total, minDate, maxDate } = metrics[channel];
        return (
          <TableRow>
            <TableCell>
              {channel}
            </TableCell>
            <TableCell textAlign="right">
              { minDate }
            </TableCell>
            <TableCell textAlign="right">
              { maxDate }
            </TableCell>
            <TableCell textAlign="right">
              { formatter.format(min) }
            </TableCell>
            <TableCell textAlign="right">
              { formatter.format(max) }
            </TableCell>
            <TableCell textAlign="right">
              { formatter.format(total) }
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
);

export const DataSection = ({ headerText, subheaderText = undefined, metrics = {}, formatter }) => (
  <>
    <Segment className="header">
      <div>
        {headerText}
      </div>
      { subheaderText
      && (
        <div className="subheader">
          {subheaderText}
        </div>
      )}
    </Segment>
    {Object.keys(metrics).length > 0 && <DataTable {...{ metrics, formatter }} />}
  </>
);

/* eslint-disable max-len */
export const REVENUE_SUBHEADING = 'Revenue data represents the total value generated from product or service sales across all your sales channels.';
export const MARKETING_SPEND_SUBHEADING = 'Total expenditure on marketing activities across different channels such as TV, radio, online advertising, and print.';
export const IMP_SUBHEADING = 'Number of impressions generated through channels such as email sends, SMS sends, or press releases. Represents the reach or exposure of marketing efforts that is not otherwise directly represented by spend.';
export const EXTRA_SUBHEADING = 'Additional factors influencing sales or performance, including economic indicators, promotional dates, seasonality, and other relevant variables beyond direct marketing spend and impressions.';
/* eslint-enable max-len */

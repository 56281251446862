import React from 'react';
import { GenericMessage } from '../../../components';

export const SetupMessage = () => <GenericMessage message="survey_setup" />;
export const SetupJoinMessage = () => <GenericMessage message="survey_setup_join" />;
export const ResponseMessage = () => <GenericMessage {...{ message: 'survey_response' }} />;
export const DefaultResponseMessage = () => <GenericMessage {...{ message: 'survey_default_response' }} />;
export const ExclusionResponseMessage = () => <GenericMessage {...{ message: 'survey_exclusion_response' }} />;
export const RemappedResponseMessage = () => <GenericMessage {...{ message: 'survey_remapped_response' }} />;
export const EventMessage = () => <GenericMessage {...{ message: 'survey_marketing_event' }} />;
export const ViewEventsMessage = () => <GenericMessage {...{ message: 'survey_view_marketing_event' }} />;
export const JoinMessage = () => <GenericMessage {...{ message: 'survey_add_events' }} />;

import React from 'react';

import ColorHash from 'color-hash';
import { Label, Popup } from 'semantic-ui-react';
import tinycolor from 'tinycolor2';

const angleStyles = {
  fontSize: 12,
  position: 'relative',
  top: -4,
  marginRight: 2,
};

const BucketLabel = ({ bucket, color, level, condensed, zIndex }) => (
  <Label
    tag
    style={{
      backgroundColor: tinycolor(color).lighten(level * 5).toString(),
      color: tinycolor(color).lighten(level * 10).getBrightness() > 185 ? '#000' : '#fff',
      zIndex,
    }}
    className={`bucket ${condensed && 'condensed'}`}
    title={bucket}
  >
    <div className="ellipsed">
      {level > 0 && <span style={angleStyles}>&#x221F;</span>}
      {bucket || <span>&nbsp;</span>}
    </div>
  </Label>
);

const BucketsPath = ({ steps, colorMap, condensed }) => {
  const numLevels = Math.max.apply(null, steps.map(step => step.buckets.length));
  const colorHash = new ColorHash({
    hue: { min: 50, max: 355 },
    saturation: 0.55,
    lightness: [0.4, 0.75, 0.5],
  });

  return (
    <div className="buckets-path">
      {
        steps.map((step, i) => {
          const topLevelBucket = step.buckets[0];
          const tierColor = (colorMap && colorMap[topLevelBucket]) || ((!topLevelBucket || topLevelBucket === 'Other') ? '#4A4A4A' : colorHash.hex(topLevelBucket.split('').reverse().join('')));
          const zIndex = steps.length - i;

          const labelComponent = (
            <div
              className={`buckets-step-wrap ${!condensed && 'expanded'} levels-${numLevels}`}
              style={{ borderColor: tierColor }}
              key={step.buckets.map(bucket => bucket).join('') + i}
            >
              {condensed
                ? (
                  <BucketLabel
                    key={step.buckets.map(bucket => bucket).join('') + zIndex}
                    color={tierColor}
                    level={0}
                    condensed
                    zIndex={zIndex}
                  />
                )
                : step.buckets.map((bucket, index) => (
                  <BucketLabel
                    key={bucket + tierColor + zIndex + index}
                    bucket={bucket}
                    color={tierColor}
                    level={index}
                    zIndex={zIndex}
                  />
                ))}
            </div>
          );

          if (condensed) {
            return (
              <Popup
                inverted position="top center" size="tiny"
                trigger={labelComponent}
                content={step.buckets.map(bucket => bucket).join(' › ')}
              />
            );
          }

          return labelComponent;
        })
      }
      {/* {!condensed &&
        <Label
          tag
          className='bucket finish'
          title='Conversion'
          style={{height: BUCKET_LABEL_HEIGHT*numLevels}}
        >
          <div className='finish-icon'>
            <Icon name='check' />
          </div>
        </Label>
      } */}
    </div>
  );
};

export default BucketsPath;

import React, { Component } from 'react';
import * as Router from "react-router-dom";
import _ from 'lodash';

export const customHistory = (props) => {

  const { match } = props
  const { path, url, params, forcePath } = match
  const custom = React.useMemo(() => {
    const history = Router.useHistory()

    const updatePath = (obj) => {
      const objKeys = Object.keys(obj)

      const keys = path.split("/")
      const values = url.split("/")

      const pathArray = keys.reduce((p,c,i) => {

        const k = c.includes(":") && c.replace(":","").replace("?","")

        if (k && objKeys.indexOf(k) > -1) p.push(obj[k])
        else if (values.length > i && values[i] !== undefined && values[i].length) {
          p.push(values[i])
        }
        return p
      }, [])

      const computedPath = "/" + pathArray.filter(x => x).join("/")
      history.push(forcePath || computedPath)

    }
    
    return Object.assign({}, history, { updatePath, match })
  }, [match])

  return custom
}

export const _pushPath = (superclass) => class pushpath extends superclass {

  updateStateField = (key) => (event, { value }) => {
    this.setState({[key]: value })
  }

  paramsChanged = (prevParams, _paramsList=false) => {
    const params = this.props.match.params
    const paramsList = _paramsList || Object.keys(params)

    return paramsList.filter(key => {
      return params[key] != prevParams[key]
    })
  }

  updatePath = (obj) => {
    const objKeys = Object.keys(obj)

    const { history: { push } } = this.props;
    const { path, url } = this.props.match;
    const keys = path.split("/")
    const values = url.split("/")

    const pathArray = keys.reduce((p,c,i) => {

      const k = c.includes(":") && c.replace(":","").replace("?","")

      if (k && objKeys.indexOf(k) > -1) p.push(obj[k])
      else if (values.length > i && values[i] !== undefined && values[i].length) {
        p.push(values[i])
      }
      return p
    }, [])

    push("/" + pathArray.filter(x => x).join("/"))

  }

  updateStateArrayRowField = (stateSlice, field, idKey = "id", newIdKey = "new_id") => (item) => (e, {value}) => {
    const stateCopy = _.cloneDeep(this.state);
    const selectedObj = item.new_id ?
        stateCopy[stateSlice].find(c => c[newIdKey] == item[newIdKey]) :
        stateCopy[stateSlice].find(c => c[idKey] == item[idKey]);
    selectedObj[field] = value;

    this.setState(stateCopy);
  }

}

const PushPath = _pushPath(Component)

export default PushPath;

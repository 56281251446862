import React from 'react';

import { IndexGridNew } from '@rockerbox/styleguide';
import { ReferenceArea } from 'recharts';
import { Header } from 'semantic-ui-react';

import { useProjectionComparisonData } from './comparePeriods';
import { ComparisonForm, useFormData } from './ComparisonForm';
import { HEADER_DESCRIPTION, CustomizedLabel } from './parts';
import { getHeaders, getSummaryHeaders } from './tableHelpers';
import { useQuarterlyData } from './useQuarterlyData';
import { WaterfallChart } from './WaterfallChart';
import { numberFormatter } from '../../../utils/valueFormatter';
import { useResponseCurves } from '../hooks/useResponseCurves';
import { ModelSectionHeader } from '../parts/stateless';

const WaterfallComparison = ({ isConversionKPI, featureNameMap, modelData, trainingData, responseCurves }) => {
  const { responseCurvesArr } = useResponseCurves({ responseCurves });
  const { fiscalQuarters, baselineFiscalQuarters } = useQuarterlyData({ modelData, trainingData });
  const { period1, period2, setPeriod1, setPeriod2 } = useFormData();
  const headers = getHeaders(period1, period2, isConversionKPI);
  const summaryHeaders = getSummaryHeaders(period1, period2, isConversionKPI);

  const { deltaSummary, deltas, waterfallData } = useProjectionComparisonData({ period1, period2, fiscalQuarters, baselineFiscalQuarters, responseCurvesArr, featureNameMap });

  // const minYAxis = waterfallData.reduce((acc, row) => Math.max(acc, Math.abs(row.value)), 0) / 2;
  const hasData = waterfallData.length > 0;
  const startX1 = hasData && waterfallData[0].name;
  const startX2 = hasData && waterfallData[1].name;
  const lastX1 = hasData && waterfallData.slice(-2)[0].name;
  const lastX2 = hasData && waterfallData.slice(-2)[1].name;
  const firstPeriodTotal = waterfallData.slice(0, 2).reduce((acc, row) => acc + row.originalValue, 0);
  const lastPeriodTotal = waterfallData.slice(-2).reduce((acc, row) => acc + row.originalValue, 0);

  const deltaX1 = hasData && waterfallData[2].name;
  const deltaX2 = hasData && waterfallData.slice(-3)[0].name;

  const offsetMagnitude = 35; // firstPeriodTotal > lastPeriodTotal ? -15 : 15;

  const minFunc = () => 0;

  const showData = period1 && period2;

  return (
    <div>
      <ModelSectionHeader text="Compare Performance" />

      <div style={{ maxWidth: '1200px' }}>

        <Header as="h4" content={HEADER_DESCRIPTION} style={{ marginBottom: 2, fontWeight: 400 }} />
        {trainingData && <ComparisonForm fiscalQuarters={baselineFiscalQuarters} {...{ setPeriod1, setPeriod2 }} />}
      </div>
      {showData && (
      <>
        <h3>Waterfall Chart</h3>
        <div style={{ maxWidth: '98%' }}>
          <WaterfallChart data={waterfallData} minFunc={minFunc} isConversionKPI={isConversionKPI}>
            <defs>
              <pattern id="stripes1" width="4" height="4" patternUnits="userSpaceOnUse">
                <path d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2" stroke="#fff" strokeWidth="1" />
              </pattern>
              <pattern id="color_grey" width="1" height="1" patternUnits="userSpaceOnUse">
                <rect fill="#ddd" width="1" height="1" />
              </pattern>
              <pattern id="combined_grey" width="4" height="4" patternUnits="userSpaceOnUse">
                <rect fill="url(#color_grey)" width="4" height="4" />
                <rect fill="url(#stripes1)" width="4" height="4" />
              </pattern>
            </defs>
            <ReferenceArea
              x1={deltaX1}
              x2={deltaX2}
              y1={firstPeriodTotal}
              y2={lastPeriodTotal}
              stroke="#ddd"
              fill="url(#combined_grey)"
              label={(
                <CustomizedLabel
                  justifyPosition="start"
                  yOffset="middle"
                  numItems={waterfallData.length}
                  text={`Contributors to change in sales: ${numberFormatter(Math.abs(firstPeriodTotal - lastPeriodTotal))}`}
                />
              )}
            />
            <ReferenceArea
              x1={startX1}
              x2={startX2}
              y1={0}
              y2={firstPeriodTotal}
              stroke="#ccc"
              label={(
                <CustomizedLabel
                  justifyPosition="start"
                  yOffset={offsetMagnitude}
                  numItems={waterfallData.length}
                  text={`Total sales ${period1}`}
                />
              )}
            />
            <ReferenceArea
              x1={lastX1}
              x2={lastX2}
              y1={0}
              y2={lastPeriodTotal}
              stroke="#ccc"
              label={(
                <CustomizedLabel
                  yOffset={offsetMagnitude}
                  numItems={waterfallData.length}
                  text={`Total sales ${period2}`}
                />
              )}
            />
          </WaterfallChart>
        </div>

        <h3>Macro Summary</h3>
        <IndexGridNew data={[deltaSummary]} cols={summaryHeaders} />

        <h3>Details</h3>
        <IndexGridNew data={deltas} cols={headers} summaryRow />
      </>
      )}
    </div>
  );
};

export default WaterfallComparison;

import React from 'react';
import { getBatchTriggerData} from '../../utils/api';
import { Table, Checkbox } from 'semantic-ui-react';
import { ContentCard, IndexGrid, IndexGridNew } from '@rockerbox/styleguide';
import _ from 'lodash';
import Sankey from './Sankey';
import { checkJobStatus } from '../../utils/job_api';

const TriggeredColumn = (props) => {
  const { item } = props;

  return <Table.Cell>
    <Checkbox checked={item.triggered} disabled={item.triggered == 0} onClick={() => console.log('Clicked')} />
  </Table.Cell>
}

const TriggerJobFinished = ({ item, jobFinishStatus }) => {

  if (item.target === 'group_backfill') {
    return (
      <Table.Cell>
        <Checkbox checked={Object.values(jobFinishStatus).length && !Object.values(jobFinishStatus).includes("error")} />
      </Table.Cell>
    )
  } else {
    return (
      <Table.Cell></Table.Cell>
    )
  }
}

const COLS = [
  {display: "Date", key: "upload_id"},
  {display: "Reports Processed", key: "reportsProcessed"},
  {display: "Triggered", key: "triggered", as: TriggeredColumn}
]

const COLS_UPLOAD = [
  {display: "Job Id", key: "job_id"},
  {display: "Target", key: "target"},
  {display: "Jobs Finished", key: "finished", as: TriggerJobFinished}
]

const Expansion = (groupId) => ({ item }) => {
  const uploadId = item.upload_id;
  const [uploadReporting, setUploadReporting] = React.useState({});
  const [jobFinishStatus, setjobFinishStatus] = React.useState({});
  const [failedBackfillJobs, setFailedBackfillJobs] = React.useState(new Set());

  React.useEffect(() => {
    if (uploadId) getBatchTriggerData(groupId, uploadId)
      .then(resp => {
        resp.nodes.map((row, i) => {
          row.name = row.target;
          row.id = i
        })
        resp.links.map((row, i) => {
          row.value = 10
        })
        setUploadReporting(resp)

        for(let node of resp.nodes) {
          if(node.target === 'group_backfill') {
            const jobId = node.job_id
            const allJobs = jobId.split(",")
            for(let job of allJobs) {
              checkJobStatus(job)
              .then(res => {
                setjobFinishStatus(prevState => ({...prevState, [job]: res.finish_type}))
                if(res.finish_type === "error") {
                  setFailedBackfillJobs(prevState => new Set(prevState.add(job)))
                }
              })
              .catch(err => {
                console.log(err)
              })
            }
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
  }, [uploadId])


  return <div style={{display:"flex"}}>
    { Object.keys(uploadReporting).length == 0 && <div style={{flex:1,textAlign:"center"}}><ContentCard.CardLoader /></div> }
    { Object.keys(uploadReporting).length > 0 && <React.Fragment>
        <div style={{flex:1}}>
          <Sankey data={uploadReporting} />
          { failedBackfillJobs.size > 0 && <div>
              <h5>Failed Backfill Jobs:</h5>
              {[...failedBackfillJobs].map(job => <li key={`${job}`}><a href={`http://wq.internal.getrockerbox.com/v2/workqueue_status/job/${job}`}>{job}</a></li>)}
            </div>
          }
        </div>
        <div style={{flex:1}}>
          <IndexGrid cols={COLS_UPLOAD} data={uploadReporting.nodes} jobFinishStatus={jobFinishStatus} />
        </div>
      </React.Fragment>
    }

  </div>
}

const Reporting = ({ groupId }) => {
  const [uploadId, setUploadId] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [triggerReporting, setTriggerReporting] = React.useState([]);

  React.useEffect(() => {
    setLoading(true)
    setTriggerReporting([]);
    getBatchTriggerData(groupId)
      .then(resp => {
        resp.map(row => row.reportsProcessed = row.reports.length)
        setTriggerReporting(resp.sort((p,c) => d3.ascending(c.upload_id, p.upload_id)));
      })
      .finally(() => setLoading(false))
  }, [groupId])


  const onClick = (row) => {
    const { upload_id } = row;
    setUploadId(upload_id);
  }

  const Component = _.memoize(Expansion)(groupId)

  return (
    <ContentCard hasTable>
      { triggerReporting && triggerReporting.length > 0 || loading
            ? <IndexGridNew idKey="upload_id" groupId={groupId} cols={COLS} data={triggerReporting} expandedComponent={Component} expandable/>
            : <Table><IndexGridNew.EmptyMessage /></Table>
      }
    </ContentCard>
  )
}

export default Reporting;

// Potential tier names for supported platforms
export const PLATFORM_NAMES = {
  Facebook: ['facebook', 'instagram', 'fb\/ig', 'fb-ig', 'igstories'], // eslint-disable-line no-useless-escape
  AdWords: ['google', 'gdn', 'dcm', 'adwords', 'pmax'],
  YouTube: ['youtube'],
  Tatari: ['tatari'],
  'The Trade Desk': ['ttt', 'the trade desk'],
  Pinterest: ['pinterest'],
  Snapchat: ['snapchat'],
  TikTok: ['tiktok'],
  Bing: ['bing'],
  LinkedIn: ['linkedin'],
  ImpactRadius: ['impactradius', 'impact radius', 'impact'],
  Pepperjam: ['pepperjam'],
  'Linear TV': ['linear'],
  Verizon: ['verizon'],
};

export const STRATEGY_OPTIONS = [
  { text: 'Prospecting', values: ['prospect', 'acqu'] },
  { text: 'Retargeting', values: ['retarget', 'remarket'] },
  { text: 'Retention', values: ['retention'] },
  { text: 'Non Brand', values: ['non brand'] },
  { text: 'Brand', values: ['brand'] },
  { text: 'Affiliate', values: ['affiliate', 'coupon', 'impactradius', 'impact radius', 'pepperjam', 'promo code'] },
];

export const EXCLUDED_TIERS = [
  'unmapped.events',
  'referring.domain',
  'www',
  '\.com', // eslint-disable-line no-useless-escape
  // 'referral',
  'organic',
  // 'email',
  // 'sms',
  // 'newsletter',
  // 'influencer',
  'customer experience',
  'cx',
  // 'survey',
  // 'promo.code',
  // 'coupon',
  // 'direct',
];

import React from 'react';

const BulletChart = ({ data }) => {
  const chartWidth = 600;
  const chartHeight = data.length * 70 + 50; // Adjusted for space above bars for tooltips
  const maxRange = data.reduce((acc, cur) => cur.range[cur.range.length - 1], 0); // Assuming the maximum value in the range is 100

  return (
    <svg width={chartWidth} height={chartHeight}>
      {data.map((item, index) => {
        const yPosition = index * 70 + 50;

        // Calculate widths for range and actual bars
        const rangeWidths = item.range.map(range => (range / maxRange) * chartWidth);
        const actualWidth = (item.actual / maxRange) * chartWidth;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <g key={index}>
            {/* Draw range */}
            <rect x={rangeWidths[0]} y={yPosition - 10} width={rangeWidths[1] - rangeWidths[0]} height={40} fill="#ddd" />
            <rect x={rangeWidths[1]} y={yPosition - 10} width={rangeWidths[2] - rangeWidths[1]} height={40} fill="#aaaaaa" />
            <rect x={rangeWidths[2]} y={yPosition - 10} width={rangeWidths[3] - rangeWidths[2]} height={40} fill="#aaaaaa" />
            <rect x={rangeWidths[2] - 1} y={yPosition - 10} width={2} height={40} fill="#999999" />
            <rect x={rangeWidths[3]} y={yPosition - 10} width={rangeWidths[4] - rangeWidths[3]} height={40} fill="#ddd" />
            {/* Draw actual value */}
            <rect x={0} y={yPosition} width={actualWidth} height={20} fill="#8884d8" />
            {/* Tooltip labels */}
            <text x={rangeWidths[1]} y={yPosition - 15} fill="#000000" fontSize="10" textAnchor="middle">{item.range[1]}</text>
            <text x={rangeWidths[2]} y={yPosition - 15} fill="#000000" fontSize="10" textAnchor="middle">{item.range[2]}</text>
            <text x={rangeWidths[3]} y={yPosition - 15} fill="#000000" fontSize="10" textAnchor="middle">{item.range[3]}</text>
          </g>
        );
      })}
    </svg>
  );
};

export default BulletChart;

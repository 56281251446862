/* eslint-disable import/prefer-default-export */

export function handleCacheResponse(raw) {
  const { details: { keys } = {}, response, error } = raw;
  if (!!error) return [];
  if (keys.length === 1) {
    return response[keys[0]];
  }
  return raw.response;
}

import React, { useEffect, useState } from 'react';

import { Input, List, Header, Icon, Loader, Container, Image } from 'semantic-ui-react';

import { noDataCat } from '../../../images/no-data-cat.png';

const CustomList = ({
  options,
  headerKey = 'text',
  subheaderKey = 'value',
  search,
  onChange,
  noDataMsg,
}) => {
  const [originalData, setOriginalData] = useState(options);
  const [filteredData, setFilteredData] = useState([]);
  const [active, setActive] = useState('');
  const [searchVal, setSearchVal] = useState('');
  const hasData = filteredData && filteredData.length === 0;

  useEffect(() => {
    setOriginalData(options);
    setFilteredData(options);
  }, [options]);

  useEffect(() => {
    onChange(false, { value: active });
  }, [active, onChange]);

  const filterSearch = value => {
    const filtered = Array.isArray(originalData)
      ? originalData.filter(item => (
        Object.prototype.hasOwnProperty.call(item, headerKey)
          && Object.prototype.hasOwnProperty.call(item, subheaderKey)
          ? item[headerKey].toLowerCase().includes(value.toLowerCase())
              || String(item[subheaderKey]).toLowerCase().includes(value.toLowerCase())
          : item[headerKey].toLowerCase().includes(value.toLowerCase())
      ))
      : [];
    setFilteredData(filtered);
    if (filtered.length === 0) setActive('');
    setSearchVal(value);
  };

  return (
    <>
      {search && (
        <Input
          fluid
          icon="search"
          iconPosition="left"
          style={{ marginTop: 12 }}
          value={searchVal}
          onChange={(e, { value }) => filterSearch(value)}
        />
      )}
      {!filteredData ? (
        <Loader active inline="centered" size="small" style={{ margin: '20px auto' }} />
      ) : hasData ? (
        <Container style={{ margin: '25px auto' }}>
          <Image src={noDataCat} size="tiny" centered />
          <h3 style={{ marginTop: 5, textAlign: 'center' }}>{noDataMsg || 'No Data Found'}</h3>
        </Container>
      ) : (
        <List divided className="custom-list">
          {filteredData.map(item => (
            <List.Item
              onClick={() => setActive(item.value)}
              className={`${active === item.value ? 'active-list-item' : ''} list-item-wrapper`}
            >
              <List.Content>
                <Header as="h3">
                  {item[headerKey]}
                  {subheaderKey && (
                    <Header.Subheader>
                      {item[subheaderKey]}
                    </Header.Subheader>
                  )}
                </Header>
              </List.Content>
              {active === item.value && (
                <List.Content>
                  <Icon name="check" size="large" />
                </List.Content>
              )}
            </List.Item>
          ))}
        </List>
      )}
    </>
  );
};

export default CustomList;

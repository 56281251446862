import { find, get, compose, map, filter, join } from 'lodash/fp';
import {
  formatDimensions,
  formatReportSize,
} from './utils';
import { METRIC_COLUMNS,
  CUSTOMER_TYPE_OPTIONS,
  ATTRIBUTION_MODEL_OPTIONS } from '../constants';

const selectAttributionModel = (options, attributionMethod) => compose(
  get('text'),
  find(option => option.value === attributionMethod),
)(options);

const selectCustomerType = (options, customerType) => compose(
  get('text'),
  find(option => option.value === customerType),
)(options);

const selectSegmentName = (segments, segmentId) => compose(
  get('action_name'),
  find(segment => segment.identifier === segmentId),
)(segments);

const selectMetricColumnsDisplay = (metricColumns, baseColumns) => compose(
  join(', '),
  map(metric => metric.text),
  filter(metric => metricColumns.includes(metric.value)),
)(baseColumns);

export const createExportRequestDetailsFieldsArray = (exportRequest, segments) => {
  // TODO: refactor this selector to gracefully exit if export request fails
  if (!exportRequest.request_body) return [];

  const {
    report_name,
    created_at,
    report_size,
    request_body: {
      report_request: {
        identifier,
        start_date,
        end_date,
        metadata,
        group_by_date,
        metrics: {
          attr_method,
          ntf_filter,
          columns,
        },
      },
      delivery_options: {
        email_delivery_location,
      },
    },
  } = exportRequest;

  const reportName = report_name;
  const segmentName = selectSegmentName(segments, identifier);
  const customerType = selectCustomerType(CUSTOMER_TYPE_OPTIONS, ntf_filter);
  const attributionModel = selectAttributionModel(ATTRIBUTION_MODEL_OPTIONS, attr_method);
  const dateRange = `${start_date} - ${end_date}`;
  const groupByTimePeriod = `${group_by_date[0].toUpperCase()}${group_by_date.slice(1)}`;
  const groupByDimensions = formatDimensions(metadata);
  const metrics = selectMetricColumnsDisplay(columns, METRIC_COLUMNS);
  const deliveryEmail = Array.isArray(email_delivery_location) ? email_delivery_location.map(email => email.deliveryEmail).join(', ') : email_delivery_location;
  const reportSizeString = formatReportSize(report_size);
  const requestTime = created_at;

  const fields = [
    {
      label: 'Export Name',
      info: reportName,
    },
    {
      label: 'Status',
      info: '',
    },
    {
      label: 'Conversion',
      info: segmentName,
    },
    {
      label: 'Customer Type',
      info: customerType,
    },
    {
      label: 'Attribution Model',
      info: attributionModel,
    },
    {
      label: 'Date Range',
      info: dateRange,
    },
    {
      label: 'Group By Time Period',
      info: groupByTimePeriod,
    },
    {
      label: 'Group By Dimensions',
      info: groupByDimensions,
    },
    {
      label: 'Metrics',
      info: metrics,
    },
    {
      label: 'Delivery Email',
      info: deliveryEmail,
    },
    {
      label: 'File Size',
      info: reportSizeString,
    },
    {
      label: 'Request Time',
      info: requestTime,
    },
  ];

  return fields;
};

import React, { useEffect } from 'react';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { StyledDropdown } from '../../index';
import TiersRollupFilter from '../RollupFilter';
import { analysisTypeAtom, analysisSearchAtom, platformStrategySearchAtom } from '../../../atoms';
import { useIndexedMTA } from './hooks';
import { useTiers } from '../RollupFilter/hooks';
import { track } from '../../../../utils/tracking';

const analysisOptions = [
  'Tiers',
  'Channel',
  'Platform',
  'Strategy',
].map(text => ({ text, value: text.toLowerCase() }));

const AnalysisDropdown = ({ analysisType, options, searchValue, onChange, rawData, hierarchy, clearAnalysis }) => {
  const { clearFilters } = useTiers();
  const { channelOptions, platformOptions, strategyOptions } = options;
  if (analysisType === 'channel') {
    clearFilters();
    return <StyledDropdown value={searchValue} onChange={onChange} options={channelOptions} multiple clearable />;
  }
  if (analysisType === 'platform') {
    clearFilters();
    return <StyledDropdown value={searchValue} onChange={onChange} options={platformOptions} multiple clearable />;
  }
  if (analysisType === 'strategy') {
    clearFilters();
    return <StyledDropdown value={searchValue} onChange={onChange} options={strategyOptions} multiple clearable />;
  }
  if (analysisType === 'tiers') {
    clearAnalysis();
    return <TiersRollupFilter {...{ rawData, hierarchy }} />;
  }
  return null;
};

const PlatformStrategyDropdown = ({ options, platformStrategySearchValue, onChange }) => (
  <div style={{ display: 'flex' }}>
    <StyledDropdown value="strategy" options={[{ text: 'Strategy', value: 'strategy' }]} />
    <StyledDropdown value="is" options={[{ text: 'is', value: 'is' }]} disabled />
    <div style={{ flex: 0.8 }}>
      <StyledDropdown value={platformStrategySearchValue} onChange={onChange} options={options} multiple clearable />
    </div>
  </div>
);

const DerivedTiersFilter = ({ rawData, tiersData, hierarchy }) => {
  const [analysisType, setAnalysisType] = useAtom(analysisTypeAtom);
  const [searchValue, setSearchValue] = useAtom(analysisSearchAtom);
  const [platformStrategySearchValue, setPlatformStrategySearchValue] = useAtom(platformStrategySearchAtom);
  const resetPlatformStrategy = useResetAtom(platformStrategySearchAtom);

  const handleAnalysis = (evt, { value }) => {
    setAnalysisType(value);
    setSearchValue('');
  };

  const clearAnalysis = () => {
    setSearchValue('');
    setPlatformStrategySearchValue('');
  };

  const handleSearch = (evt, { value }) => {
    track(`crosschannel.filter.adv.${analysisType}`);
    setSearchValue(value);
  };

  const handlePlatformStrategySearch = (evt, { value }) => {
    track('crosschannel.filter.adv.platformstrategy');
    setPlatformStrategySearchValue(value);
  };

  const { channelOptions, platformOptions, strategyOptions, platformStrategyOptions } = useIndexedMTA(rawData, analysisType, searchValue);

  useEffect(() => {
    if (!platformStrategySearchValue || !platformStrategySearchValue.length || !platformStrategyOptions.length) return;

    const platformStrategyValueOptions = platformStrategyOptions.map(({ value }) => value);
    const allPlatformStrategyExists = platformStrategySearchValue.every(val => platformStrategyValueOptions.includes(val));
    if (!allPlatformStrategyExists) {
      const filterPlatStrategyValues = platformStrategySearchValue.filter(val => platformStrategyValueOptions.includes(val));
      setPlatformStrategySearchValue(filterPlatStrategyValues);
    }
  }, [platformStrategyOptions]);

  useEffect(() => {
    if (analysisType !== 'platform') resetPlatformStrategy();
  }, [analysisType]);

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', paddingBottom: 10 }}>
        <StyledDropdown value={analysisType} options={analysisOptions} onChange={handleAnalysis} />
        <StyledDropdown value="is" options={[{ text: 'is', value: 'is' }]} disabled />
        <div style={{ flex: 0.8 }}>
          <AnalysisDropdown
            {...{
              analysisType,
              options: { channelOptions, platformOptions, strategyOptions },
              searchValue,
              onChange: handleSearch,
              rawData: tiersData, hierarchy,
              clearAnalysis,
            }}
          />
        </div>
      </div>
      {analysisType === 'platform' && searchValue !== '' && (
        <PlatformStrategyDropdown
          {...{
            options: platformStrategyOptions,
            platformStrategySearchValue,
            onChange: handlePlatformStrategySearch,
          }}
        />
      )}
    </div>
  );
};

export default DerivedTiersFilter;

import React from 'react';
import { Image } from 'semantic-ui-react';
import { generateTrendCards } from './helpers';
import { formatNumber } from '../../../utils/channelPerformance/helpers';

import TrendCards from '../../../components/TrendCards';

/* eslint-disable react/jsx-props-no-spreading */

const Statistic = ({ option, integrations, platform, activeMetric, currencyCode }) => (
  <>
    {(integrations && platform && activeMetric) && <Image size="tiny" src={integrations[platform][`${'icon'}`]} style={{ margin: '-4px 5px 0 0', width: 20, height: 20 }} />}
    {formatNumber(option, option.mainNumber, currencyCode)}
  </>
);

export const TrendCardsFromPerformanceData = props => {
  const { splitPerformanceData, metrics_group, integrations, platform, activeMetric, currencyCode } = props;
  const [testObj, baselineObj, testDays, baselineDays] = splitPerformanceData || [{}, {}, {}, {}, {}, {}];
  const cardParams = { testObj, baselineObj, testDays, baselineDays, currencyCode };

  const cards = React.useMemo(() => generateTrendCards({ ...cardParams, metrics_group })
    .map(option => {
      // pre-format values to display in card, this map can be done elsewhere

      const updated = { ...option };
      updated.heading = updated.text;
      updated.statistic = <Statistic {...{ option: updated, integrations, platform, activeMetric, currencyCode }} />;
      return updated;
    }), [cardParams]);

  return (
    <TrendCards
      {...props}
      defaultCard={TrendCards.TrendingCard}
      options={cards}
      loading={!splitPerformanceData}
    />
  );
};

export default TrendCardsFromPerformanceData;

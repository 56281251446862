import React from "react";
import {
  ContentCard,
  IndexGrid
} from "@rockerbox/styleguide";
import {
  getExternalDataTimeseries,
  getConversionSegments
} from "../../utils/api";
import TimeRange, { yesterday, week } from "../timerange";

class ExternalDataSource extends TimeRange {
  state = {
    startDate: week,
    endDate: yesterday,
    featuredFilterId: false,
    customerData: [],
    loading: true
  };

  componentDidMount() {
    getConversionSegments().then(data => {
      const featuredFilterId = data
        .filter(segment => segment.featured == 1)
        .map(seg => {
          return { id: seg.action_id };
        })[0]["id"];
      this.setState({ featuredFilterId });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate, featuredFilterId } = this.state;
    if (
      startDate != prevState.startDate ||
      prevState.featuredFilterId != featuredFilterId
    ) {
      getExternalDataTimeseries(featuredFilterId, startDate, endDate).then(
        customerData => {
          this.setState({ customerData, loading: false });
        }
      );
    }
  }

  render() {
    const { customerData, startDate, endDate, loading } = this.state;

    if (customerData.length == 0) return null;

    const firstItem = customerData[0];
    const keys = Object.keys(firstItem)
      .filter(k => k != "date")
      .filter(k => k != "view")
      .map(key => {
        return { display: key, key };
      });

    const cols = [{ display: "Date", key: "date" }].concat(keys);

    return (
      <ContentCard
        hasTable
        title="External Data"
        explainer=""
        topRight={this.renderDateSelector()}
      >
        {loading && <ContentCard.CardLoader />}
        {!loading && (
          <IndexGrid
            data={customerData}
            cols={cols}
            as={ContentCard.Table}
            idKey="date"
            expandedComponent={this.expandedSection}
            fallbackMsg="No customer data"
          />
        )}
        {this.renderDateModal()}
      </ContentCard>
    );
  }
}

export default ExternalDataSource;

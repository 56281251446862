import moment from 'moment';
import { REPORT_TYPE_OPTIONS_NEW_VISITORS, REPORT_TYPE_OPTIONS_FUNNEL_POSITION, DERIVED_TIERS } from '../../constants/options';
import { CHANNEL_MAPPING, PLATFORM_MAPPING, STRATEGY_MAPPING } from '../filters/DerivedTiersFilter/constants';

export const capitalizeText = text => {
  if (!text) return 'All';
  return text
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const compareObjects = (obj1, obj2, skipKey) => {
  const keys1 = Object.keys(obj1).filter(x => x !== skipKey);
  const keys2 = Object.keys(obj2).filter(x => x !== skipKey);

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every(key => {
    if (!keys2.includes(key)) {
      return false;
    }

    return obj1[key] === obj2[key];
  });
};

export const existingFilterMatchFound = (postParams, paramsArray) => {
  for (let i = 0; i < paramsArray.length; i++) {
    const currentParams = paramsArray[i];
    if (compareObjects(currentParams.hash, postParams.hash, 'saveID') && compareObjects(currentParams.route, postParams.route, 'saveID')) {
      return true;
    }
  }
  return false;
};

export const findOptionText = (optionsList, value) => (optionsList?.find(c => c.value === value)?.text);

export const reportOptions = routeName => {
  if (routeName === 'newVisitors') return REPORT_TYPE_OPTIONS_NEW_VISITORS;
  if (routeName === 'funnelPosition') return REPORT_TYPE_OPTIONS_FUNNEL_POSITION;
  return [];
};

/**
 * Returns the corresponding text value from the mapping object based on the analysis and value parameters.
 *
 * @param {string} analysis - The type of analysis, which can be 'channel', 'platform', or 'strategy'.
 * @param {string} value - The value to be analyzed.
 * @returns {string} - The corresponding text value from the mapping object.
 *
 * @example
 * const analysis = 'channel';
 * const value = 'affiliate';
 * const result = formatRBClassificationValue(analysis, value);
 * console.log(result); // Output: 'Affiliate'
 */
export const formatRBClassificationValue = (analysis, value) => {
  if (analysis === 'channel') {
    return CHANNEL_MAPPING[value]?.text || '';
  }
  if (analysis === 'platform') {
    return PLATFORM_MAPPING[value]?.text || '';
  }
  if (analysis === 'strategy') {
    return STRATEGY_MAPPING[value]?.text || '';
  }
  return '';
};

/**
 * Returns the RB classification text based on the provided analysis and values.
 *
 * @param {string} analysis - The type of analysis, which can be 'channel', 'platform', or 'strategy'.
 * @param {string} analysisValue - The value associated with the analysis.
 * @param {array} platformStrategyValue - An array of values associated with the platform and strategy.
 * @returns {string} - The RB classification text.
 *
 * @example
 * const analysis = 'channel';
 * const analysisValue = 'value1';
 * const platformStrategyValue = [];
 *
 * const rbClassificationText = getRBClassificationText(analysis, analysisValue, platformStrategyValue);
 * console.log(rbClassificationText);
 * // Output: "Channel - Channel Text"
 */
export const getRBClassificationText = (analysis, analysisValue, platformStrategyValue) => {
  const platformStrategyText = (analysis === 'platform' && platformStrategyValue.length > 0 && formatRBClassificationValue('strategy', platformStrategyValue).length > 0)
    ? `, Strategy - ${formatRBClassificationValue('strategy', platformStrategyValue)}`
    : '';

  const formattedAnalysis = findOptionText(DERIVED_TIERS, analysis) && `${findOptionText(DERIVED_TIERS, analysis)} - `;

  if (!formattedAnalysis) return '';

  return `${formattedAnalysis}${formatRBClassificationValue(analysis, analysisValue)}${platformStrategyText}`;
};

export const convertToMacro = x => {
  const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
  const isDate = dateRegex.test(x);
  if (isDate) {
    const today = moment.utc().utcOffset(-5);
    const date = moment.utc(x, 'YYYY-MM-DD');
    const numDaysAgo = today.diff(date, 'days');
    return `{{ ${numDaysAgo}d }}`;
  }
  return x;
};

export const formattedHash = hash => {
  if (hash[0] !== '#') return {};
  const hashSplit = hash.substr(1).split('&');
  const saveHash = hashSplit.reduce((acc, v) => {
    const [key, val] = v.split('=');
    if (key === 'saveID') {
      return acc;
    }
    return { ...acc, [key]: val };
  }, {});
  return saveHash;
};

export const formattedParams = params => {
  if (!params) return {};
  return Object.keys(params)
    .reduce((accu, key) => {
      const value = convertToMacro(params[key]);
      accu[key] = value;
      return accu;
    }, {});
};

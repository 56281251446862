import { SET_ARTIFACTS, SET_ANNOUNCEMENTS, SET_ACCOUNT, SET_COMMUNICATION, SET_NOTIFICATION } from './constants';
import { BAD_USER, CONFIGURE_USER } from '../../constants';

const initialState = {
  artifacts: {},
  user: {},
  accounts: [],
  username: undefined,
  permissions: [],
  announcements: undefined,
  account: {},
  communications: undefined,
  notifications: undefined
}


export default function AppReducer (state = initialState, action) {
  switch (action.type) {
    case BAD_USER:
      return {
        ...state,
        username: false
      }
    case CONFIGURE_USER:
      return {
        ...state,
        ...action.data
      }
    case SET_ARTIFACTS:
      return {
        ...state,
        artifacts: action.data.response
      }
    case SET_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: action.data.response
      }
    case SET_ACCOUNT:
      return {
        ...state,
        account: action.data.response
      }
    case SET_COMMUNICATION:
      return {
        ...state,
        communications: action.data.response
      }
    case SET_NOTIFICATION:
      return {
        ...state,
        notifications: action.data.response
      }
    default:
      return state;
  }
}

import SyncDatasetManageTable from "./SyncDatasetManageTable";
import SyncStatusTable from "./SyncStatusTable";
import SnapshotIndex from "./SnapshotIndex";
import SnapshotCreationForm from "./SnapshotCreationForm";
import SnapshotSchedule, { SnapshotScheduleCreation } from "./SnapshotSchedule";

export const STATUS_PENDING = "pending";
export const STATUS_FINISHED = "finished";
export const STATUS_UNSET = "unset";
export const STATUS_FAILED = "failed";
export const STATUS_INCOMPLETE = "incomplete";

export const DEFAULT_PLATFORM = "gcp";

export const SNOTFLAKE_ACCOUNT_REGION = [
  { display: "us-west-2 (Oregon)", key: "us-west-2" },
  { display: "us-east-1 (N. Virginia)", key: "us-east-1" },
  { display: "us-east-2 (Ohio)", key: "us-east-2" },
  { display: "eu-west-1 (Ireland)", key: "eu-west-1" },
  { display: "ap-southeast-2 (Sydney)", key: "ap-southeast-2" }
];

export const GCP_REGION = [
  {
    text: "NORTHAMERICA-NORTHEAST1",
    key: "NORTHAMERICA-NORTHEAST1",
    value: "NORTHAMERICA-NORTHEAST1"
  },
  { text: "US-CENTRAL1", key: "US-CENTRAL1", value: "US-CENTRAL1" },
  { text: "US-EAST1", key: "US-EAST1", value: "US-EAST1" },
  { text: "US-EAST4", key: "US-EAST4", value: "US-EAST4" },
  { text: "US-WEST1", key: "US-WEST1", value: "US-WEST1" },
  { text: "US-WEST2", key: "US-WEST2", value: "US-WEST2" },
  { text: "US-WEST3", key: "US-WEST3", value: "US-WEST3" },
  { text: "US-WEST4", key: "US-WEST4", value: "US-WEST4" },
  {
    text: "SOUTHAMERICA-EAST1",
    key: "SOUTHAMERICA-EAST1",
    value: "SOUTHAMERICA-EAST1"
  },
  { text: "EUROPE-WEST1", key: "EUROPE-WEST1", value: "EUROPE-WEST1" },
  { text: "EUROPE-WEST2", key: "EUROPE-WEST2", value: "EUROPE-WEST2" },
  { text: "EUROPE-WEST3", key: "EUROPE-WEST3", value: "EUROPE-WEST3" },
  { text: "EUROPE-WEST4", key: "EUROPE-WEST4", value: "EUROPE-WEST4" },
  { text: "EUROPE-WEST6", key: "EUROPE-WEST6", value: "EUROPE-WEST6" },
  { text: "ASIA-EAST1", key: "ASIA-EAST1", value: "ASIA-EAST1" },
  { text: "ASIA-EAST2", key: "ASIA-EAST2", value: "ASIA-EAST2" },
  { text: "ASIA-NORTHEAST1", key: "ASIA-NORTHEAST1", value: "ASIA-NORTHEAST1" },
  { text: "ASIA-NORTHEAST2", key: "ASIA-NORTHEAST2", value: "ASIA-NORTHEAST2" },
  { text: "ASIA-NORTHEAST3", key: "ASIA-NORTHEAST3", value: "ASIA-NORTHEAST3" },
  { text: "ASIA-SOUTH1", key: "ASIA-SOUTH1", value: "ASIA-SOUTH1" },
  { text: "ASIA-SOUTHEAST1", key: "ASIA-SOUTHEAST1", value: "ASIA-SOUTHEAST1" },
  { text: "ASIA-SOUTHEAST2", key: "ASIA-SOUTHEAST2", value: "ASIA-SOUTHEAST2" },
  {
    text: "AUSTRALIA-SOUTHEAST1",
    key: "AUSTRALIA-SOUTHEAST1",
    value: "AUSTRALIA-SOUTHEAST1"
  },
  { text: "ASIA1 (Duel Region)", key: "asia1", value: "asia1" },
  { text: "EUR4 (Duel Region)", key: "eur4", value: "eur4" },
  { text: "NAM4 (Duel Region)", key: "nam4", value: "nam4" },
  { text: "EUR (Multi Region)", key: "eur", value: "eur" },
  { text: "ASIA (Multi Region)", key: "asia", value: "asia" },
  { text: "US (Multi Region)", key: "us", value: "us" }
];

export const accountTypeOptions = [
  {
    text: "Snowflake accoount",
    key: "snowflake_account",
    value: "snowflake_account"
  },
  {
    text: "Reader Account",
    key: "reader_account",
    value: "reader_account"
  }
];

export const steps = ["Setup", "Sync"];

export const snowflakeAccountNameMap = {
  "us-east-1": "mra88988",
  "us-east-2": "tm56698",
  "us-west-2": "mha39826",
  "east-us-2": "wz14259",
  "west-us-2": "yf56395",
  "us-central1": "jz09292"
};

export const tableTypeOptions = [
  {
    key: "mta",
    value: "compiled_mta",
    text: "MTA"
  },
  {
    key: "tiers",
    value: "compiled_mta_tiers",
    text: "TIERS"
  }
];

export const SYNC_CARD_DEFAULT = "show";
export const SYNC_CARD_EDIT = "edit";
export const SYNC_CARD_ADD = "add";
export const SYNC_CARD_STATUS = "status";
export const SYNC_CARD_SNAPSHOT = "snapshot";
export const SYNC_CARD_SNAPSHOT_STATUS = "snapshot_status";
export const SYNC_CARD_SNAPSHOT_CREATION = "snapshot_creation";
export const SYNC_CARD_SNAPSHOT_SCHEDULE = "snapshot_schedule";
export const SYNC_CARD_SNAPSHOT_SCHEDULE_ADD = "snapshot_schedule_add";

export const syncTitleMap = {
  [SYNC_CARD_EDIT]: "Edit Dataset",
  [SYNC_CARD_ADD]: "Create New Dataset",
  [SYNC_CARD_STATUS]: "Dataset Status",
  [SYNC_CARD_SNAPSHOT]: "Snapshots",
  [SYNC_CARD_SNAPSHOT_STATUS]: "Snapshot Status",
  [SYNC_CARD_SNAPSHOT_CREATION]: "Create New Snapshot",
  [SYNC_CARD_SNAPSHOT_SCHEDULE]: "Scheduled Snapshot",
  [SYNC_CARD_SNAPSHOT_SCHEDULE_ADD]: "Add new scheduled snapshot"
};

export const sycnComponentMap = {
  [SYNC_CARD_EDIT]: SyncDatasetManageTable,
  [SYNC_CARD_ADD]: SyncDatasetManageTable,
  [SYNC_CARD_STATUS]: SyncStatusTable,
  [SYNC_CARD_SNAPSHOT]: SnapshotIndex,
  [SYNC_CARD_SNAPSHOT_STATUS]: SyncStatusTable,
  [SYNC_CARD_SNAPSHOT_CREATION]: SnapshotCreationForm,
  [SYNC_CARD_SNAPSHOT_SCHEDULE]: SnapshotSchedule,
  [SYNC_CARD_SNAPSHOT_SCHEDULE_ADD]: SnapshotScheduleCreation
};

export const SYNC_TAB_STATS = "stats";
export const SYNC_TAB_LOGS = "logs";

export const scheduleTypeOptions = [
  { key: "weekly", text: "Week", value: "weekly" },
  { key: "monthly", text: "Month", value: "monthly" },
  { key: "quarterly", text: "Quarter", value: "quarterly" }
];

import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { Button, Popup, Dropdown } from 'semantic-ui-react';
import * as Router from 'react-router-dom';
import DateRangePicker from './DateRangePicker';
import {
  PRESET_DATE_RANGES_V3, yesterday, startOfMonth, startOfPreviousMonth, endOfPreviousMonth, firstReportingDate as firstReportDate,
  daysAgo, numDaysAgo, reformatDateMMDDYYYY, reformatDateYYYYMMDD, startOfPreviousQuarter, endOfPreviousQuarter,
} from '../../utils/time';

const updatePath = (path, current, updates) => {
  const pathParts = path.split('/');
  const updateObj = Object.assign(current, updates);
  const updateObjKeys = Object.keys(updateObj);
  const { hash } = document.location;

  const pathArray = pathParts.reduce((p, split) => {
    const keyName = split.includes(':') && split.replace(':', '').replace('?', '');
    const hasKey = updateObjKeys.indexOf(keyName) > -1;

    if (hasKey && updateObj[keyName] !== undefined) p.push(updateObj[keyName]);
    if (!keyName) p.push(split);

    return p;
  }, []);

  return pathArray.join('/') + hash;
};

const Label = styled.div`
  font-family: 'Poppins' !important;
  color: #333333;
`;

const DateWrap = styled.div`
  .DateInput_input {
    font-size: 14px;
    text-align: center;
    font-family: 'Inter', 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }

  .DateRangePickerInput_arrow {
    margin: 0 30px;
  }

  .DateInput_input__focused {
    border: none;
    background: rgba(71, 93, 220, 0.10);
    border-radius: 4px;
  }

  .DateRangePicker_picker {
    right: 0px !important;
  }

  .CalendarMonth_caption,
  .DayPicker_weekHeader_li {
    font-family: 'Poppins', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }

  .CalendarDay {
    font-family: 'Inter', 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-feature-settings: "kern" 1, "tnum" 1;
  }
`;

const CalendarButton = styled(Button)`
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border: ${props => (props.invert ? '1px solid rgba(255,255,255,.15) !important' : '1px solid rgba(34,36,38,.15) !important')};
  border-left: none !important;
  box-shadow: none !important;
  color: ${props => (props.invert ? '#FFF !important' : '#475ddc !important')};
  background:  ${props => (props.transparent ? 'transparent !important' : 'white !important')};

  &:hover {
    border-color: transparent !important;
    background-color: ${props => (props.invert ? 'rgba(255,255,255,.15) !important' : '#475ddc !important')};
    color: white !important;
  }
`;

const CalendarDropdown = styled(Dropdown)`
  border-radius: 4px 0 0 4px;
  line-height: 1em;
  white-space: normal;
  min-height: 36px;
  display: inline-block;
  padding: 11px 14px;
  border: ${props => (props.invert ? '1px solid rgba(255,255,255,.15)' : '1px solid rgba(34,36,38,.15)')};
  outline: 0;
  font-family: Poppins;
  background:  ${props => (props.transparent ? 'transparent !important' : 'white !important')};

  &:hover {
    border-color: ${props => (props.invert ? 'rgba(255,255,255,0) !important' : '#475ddc !important')};
    background-color: ${props => (props.invert ? 'rgba(255,255,255,.15) !important' : 'transparent !important')};
  }
`;

const DateRangeHooks = ({
  startDate = yesterday, endDate = yesterday, onDateChange = () => { }, firstReportingDate = firstReportDate.format('YYYY-MM-DD'), maxSelectableDate, // dates
  marginTop, marginBottom, invert = false, transparent = false, // styling
  hidePresetDates = false, compare = false, allowExtendedDateRanges = false,
}) => {
  const [showCustom, setShowCustom] = useState(false);
  const match = Router.useRouteMatch();
  const { push } = Router.useHistory();
  const { path, params } = match;
  const presetOptions = useMemo(() => PRESET_DATE_RANGES_V3.map(({ text, numDays }) => ({ key: text, text, value: numDays })), [PRESET_DATE_RANGES_V3]);

  const [_start, _setStart] = useState(startDate);
  const [_end, _setEnd] = useState(endDate);
  const noURLStartDate = !path.includes(':startDate');
  const noURLEndDate = !path.includes(':endDate');
  const [presetValue, setPresetValue] = useState(undefined);
  const [activeText, setActiveText] = useState('');
  const [recentDatesOptions, setRecentDatesOptions] = useState([]);

  const setStart = noURLStartDate ? _setStart : startDate => push(updatePath(path, params, { startDate })); // eslint-disable-line no-shadow
  const setEnd = noURLEndDate ? _setEnd : endDate => push(updatePath(path, params, { endDate })); // eslint-disable-line no-shadow
  const start = noURLStartDate ? _start : params.startDate;
  const end = noURLEndDate ? _end : params.endDate;

  const checkForCustomDates = (startDateCheck, endDateCheck) => {
    const checkDaysAgo = numDaysAgo(startDateCheck, endDateCheck, true);
    if (checkDaysAgo !== 'custom') {
      setPresetValue(checkDaysAgo);
      const findActiveText = PRESET_DATE_RANGES_V3.find(({ numDays }) => numDays === checkDaysAgo);
      setActiveText(findActiveText.text);
      return false;
    }
    const customDateValue = `${reformatDateMMDDYYYY(startDateCheck)} - ${reformatDateMMDDYYYY(endDateCheck)}`;
    setPresetValue(customDateValue);
    setActiveText(customDateValue);
    return customDateValue;
  };

  useEffect(() => {
    const dates = JSON.parse(localStorage.getItem('dates')) || [];
    dates.map(row => Object.assign(row, { key: row.value }));
    setRecentDatesOptions([...dates]);
    checkForCustomDates(startDate, endDate);
  }, []);

  useEffect(() => {
    if ((!start && startDate) || (!end && endDate)) {
      const newPath = updatePath(path, params, { startDate: start || startDate, endDate: end || endDate });
      push(newPath);
    }
  }, [startDate, endDate, start, end]);

  useEffect(() => {
    if (start && end) {
      const startMomentDate = moment(start);
      const endMomentDate = moment(end);
      const firstReportingMomentDate = moment(firstReportingDate);

      if (endMomentDate.isBefore(firstReportingMomentDate)) {
        onDateChange({ startDate: firstReportingDate, endDate: firstReportingDate });
        return;
      }

      const checkStartVsFirstReportingDate = startMomentDate.isBefore(firstReportingMomentDate) ? firstReportingDate : start;
      const finalStart = endMomentDate.isBefore(moment(checkStartVsFirstReportingDate)) ? end : checkStartVsFirstReportingDate;

      onDateChange({ startDate: finalStart, endDate: end });
    }
    checkForCustomDates(start, end);
  }, [start, end, firstReportingDate]);

  useEffect(() => {
    if (showCustom) setShowCustom(false);
  }, [endDate, activeText]);

  useEffect(() => {
    if (presetValue === 'custom') {
      const customDateValue = checkForCustomDates(start, end);
      if (!customDateValue) return;

      const dates = JSON.parse(localStorage.getItem('dates')) || [];
      const foundInDates = dates.find(({ value }) => value === customDateValue);
      if (!foundInDates) {
        const customObj = {
          key: customDateValue,
          text: customDateValue,
          value: customDateValue,
        };

        const last4Dates = dates.length > 4 ? dates.slice(0, 4) : dates;
        localStorage.setItem('dates', JSON.stringify([customObj, ...last4Dates]));
        setRecentDatesOptions([customObj, ...last4Dates]);
      }
    }
  }, [presetValue, start, end]);

  const onPresetDatesChange = (e, { text, value }) => {
    setPresetValue(value);
    setActiveText(text);
    if (value === 'prev_month') {
      setStart(startOfPreviousMonth);
      setEnd(endOfPreviousMonth);
      return;
    }
    if (value === 'current_month') {
      setStart(startOfMonth);
      setEnd(yesterday);
      return;
    }
    if (value === 'prev_quarter') {
      setStart(startOfPreviousQuarter);
      setEnd(endOfPreviousQuarter);
      return;
    }
    if (typeof value === 'number') {
      setStart(daysAgo(value));
      setEnd(yesterday);
      return;
    }
    const [customStart, customEnd] = value.split(' - ');
    setStart(reformatDateYYYYMMDD(customStart));
    setEnd(reformatDateYYYYMMDD(customEnd));
  };

  const setStartEnd = ({ startDate: startUpdate, endDate: endUpdate }) => {
    setPresetValue('custom');
    setStart(startUpdate.format('YYYY-MM-DD'));
    setEnd(endUpdate.format('YYYY-MM-DD'));
  };

  return (
    <div>
      {!transparent && <Label>Date Range</Label>}
      <div style={{ marginTop, marginBottom, display: 'flex', height: 'auto', minWidth: transparent ? 300 : 'auto' }}>
        { compare ? (
          <Popup
            content="Clear your Compare Range to update Date Range"
            position="top center"
            trigger={(
              <div style={{ display: 'flex' }}>
                <CalendarDropdown
                  fluid
                  disabled
                  text={activeText}
                  scrolling={false}
                  invert={invert}
                  className={invert ? 'date-selected' : ''}
                  transparent={transparent}
                />
                <CalendarButton
                  disabled
                  icon="calendar alternate"
                  active={false}
                  invert={invert}
                  transparent={transparent}
                />
              </div>
            )}
          />
        )
          : (
            <>
              <CalendarDropdown
                fluid
                text={activeText}
                scrolling={false}
                invert={invert}
                className={invert ? 'date-selected' : ''}
                transparent={transparent}
              >
                <Dropdown.Menu
                  scrolling={false}
                  style={{
                    minWidth: '100%',
                    backgroundColor: invert ? '#697AE5' : 'none',
                    fontFamily: 'Poppins',
                  }}
                >
                  {!hidePresetDates && presetOptions.map(({ key, text, value }) => (
                    <Dropdown.Item
                      {...{ key, text, value }}
                      onClick={onPresetDatesChange}
                      active={value === presetValue}
                      selected={value === presetValue}
                      style={{ color: invert ? '#fff' : 'none' }}
                    />
                  ))}
                  {recentDatesOptions.length > 0 && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Header content="Recent date ranges" />
                  </>
                  )}
                  {recentDatesOptions.map(({ key, text, value }) => (
                    <Dropdown.Item
                      {...{ key, text, value }}
                      onClick={onPresetDatesChange}
                      active={value === presetValue}
                      selected={value === presetValue}
                      style={{ color: invert ? '#fff' : 'none' }}
                    />
                  ))}
                </Dropdown.Menu>
              </CalendarDropdown>
              <Popup
                wide="very"
                trigger={(
                  <CalendarButton
                    icon="calendar alternate"
                    onClick={() => setShowCustom(true)}
                    active={showCustom}
                    invert={invert}
                    transparent={transparent}
                  />
              )}
                content={(
                  <DateWrap>
                    <DateRangePicker
                      startDate={moment(start)}
                      endDate={moment(end)}
                      onChange={setStartEnd}
                      {...{ firstReportingDate, maxSelectableDate }}
                      allowExtendedDateRanges={allowExtendedDateRanges}
                    />
                  </DateWrap>
                )}
                on="click"
                open={showCustom}
                onClose={() => setShowCustom(false)}
                onOpen={() => setShowCustom(true)}
                position="bottom right"
                pinned
                positionFixed
                flowing
                basic
                style={{
                  height: 396,
                  width: 346,
                }}
              />
            </>
          )}
      </div>
    </div>
  );
};

DateRangeHooks.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onDateChange: PropTypes.func,
  marginTop: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  marginBottom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  // fluid: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  firstReportingDate: PropTypes.object, // moment
  // showDates: PropTypes.bool,
  // inline: PropTypes.bool,
  // label: PropTypes.string,
};

export default DateRangeHooks;

/*
>>>>>>>> EXAMPLE <<<<<<<<<<
<DateRangeHooks
  {...{startDate, endDate, onDateChange, marginTop: '0', marginBottom: '0',   fluid: true, firstReportingDate}}
/>
*/

import React, { useState, useEffect, useMemo } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { PlusCircleIcon } from '@heroicons/react/solid';
import { ContentCard, IndexGridNew, ChartCardLoader } from '@rockerbox/styleguide';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { PLATFORMS } from './constants';
import { PanelsGroup, StatusCell, IconCell, transformTableData, fetchData, processData, getConversionIntegrations } from './helpers';
import { ActionCell, ExpandedComponent } from './parts';
import SyncingData from './SyncingData';
import { track } from '../../../utils/tracking';
import { deletePlatformAccount } from '../../api/account';
import { getPlatformPerformanceSyncStatus } from '../../api/platformPerformanceApi';
import * as routes from '../../baseRoutes';
import { BasicViewHeader, NoConnections, ViewAccessWrapper, ExpandableGrid, DataStatusModal } from '../../components';
import { TreeTableLoader } from '../../components/loaders';
import { useGlobalState } from '../../hooks/global';
import { findFeaturedActionId } from '../../utils/options';
import { yesterday, monthAgo, twoMonthsAgo } from '../../utils/time';

const Integrations = () => {
  const { authorizations, integrations: allIntegrations, segments } = useGlobalState();
  const [platformsSummary, setPlatformsSummary] = useState(undefined);
  const [chartData, setChartData] = useState(undefined);
  const [accountSummary, setAccountSummary] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [showSync, setShowSync] = useState(false);
  const [platformsToSync, setPlatformsToSync] = useState([]);
  const [spendSyncStatus, setSpendSyncStatus] = useState([]);
  const [conversionSyncStatus, setConversionSyncStatus] = useState([]);
  const [syntheticSyncStatus, setSyntheticSyncStatus] = useState([]);
  const [batchSpendStatus, setBatchSpendStatus] = useState([]);

  // TODO: remove this once we remove gating
  const isTestAccount = true;
  const ITEMS_PER_ROW = isTestAccount ? 3 : 4;

  const featuredSegmentId = findFeaturedActionId(segments);

  const handleDelete = async (key, id) => {
    setLoading(true);
    await deletePlatformAccount(key, id);
    window.location.reload();
  };

  const startDate = useMemo(() => {
    const featuredSegment = segments.find(segment => segment.action_id === featuredSegmentId);
    const firstReportingDate = featuredSegment?.first_reporting_date || null;
    return new Date(firstReportingDate) > new Date(monthAgo) ? firstReportingDate : monthAgo;
  }, [segments, featuredSegmentId, monthAgo]);

  const colorMap = useMemo(() => {
    const colors = {};
    // TODO: colors for all platforms
    Object.keys(PLATFORMS).forEach(platform => {
      colors[platform] = PLATFORMS[platform].color;
    });
    return colors;
  }, []);

  const advertisingIntegrations = useMemo(() => {
    if (!allIntegrations?.length) return [];
    const _list = allIntegrations
      .filter(integration => integration?.category?.includes('advertising'))
      .map(integration => integration.platform);
    return [...new Set(_list)];
  }, [allIntegrations]);

  // create a flat list of all auths with integration info included
  const connectedAdAccounts = useMemo(() => {
    if (!allIntegrations?.length || !advertisingIntegrations?.length || !authorizations?.length || !batchSpendStatus) return undefined;

    return authorizations.flatMap(obj => {
      const { platform } = obj;
      const integrationObj = allIntegrations.find(platformObj => platformObj.platform === platform);
      // TODO: remove this eslint disable once we remove gating which is causing the shadowing
      // eslint-disable-next-line no-shadow
      const accounts = obj.authorizations.flatMap(account => {
        if (!advertisingIntegrations.includes(platform)) {
          return [];
        }
        return {
          platform_key: platform,
          platform: integrationObj.display_name,
          account_name: (!account.batch_platform && !!account.account_name) ? account.account_name : integrationObj.display_name,
          account_id: account.account_id,
          auth_status: !account.batch_platform ? !!account.status : null,
          batch_platform: !!account.batch_platform,
          has_platform_performance: Object.keys(PLATFORMS).includes(platform),
          logo_path: `https://images.rockerbox.com/integrations/${integrationObj.logo_img}`,
          icon_path: integrationObj.icon_img && `https://images.rockerbox.com/integrations/${integrationObj.icon_img}`,
          id: account.id,
        };
      });

      return [...accounts];
    });
  }, [allIntegrations, advertisingIntegrations, authorizations]);

  const conversionIntegrations = authorizations ? getConversionIntegrations(authorizations) : [];

  const authedAdAccounts = useMemo(() => {
    if (!advertisingIntegrations?.length || !authorizations) return [];
    return authorizations
      .filter(c => (
        advertisingIntegrations.includes(c.platform) && c.authorizations.some(a => !a.batch_platform && !!a.account_id)
      ));
  }, [advertisingIntegrations, authorizations]);

  const authedAdPlatforms = useMemo(() => {
    if (!authedAdAccounts?.length) return [];
    return authedAdAccounts.map(c => c.platform);
  }, [authedAdAccounts]);

  const authedPlatPerfAccounts = useMemo(() => {
    if (!authedAdAccounts?.length) return [];
    return authedAdAccounts.filter(c => Object.keys(PLATFORMS).includes(c.platform_key));
  }, [authedAdAccounts]);

  useEffect(() => {
    if (!connectedAdAccounts || !authedAdPlatforms) return;

    const setters = {
      setSpendSyncStatus,
      setConversionSyncStatus,
      setSyntheticSyncStatus,
      setPlatformsSummary,
      setChartData,
      setAccountSummary,
      setLoading,
      setBatchSpendStatus,
    };

    fetchData(featuredSegmentId, startDate)
      .then(values => processData(values, { connectedAdAccounts, authedAdPlatforms, setters }))
      .catch(err => console.log('Error:', err.message));
  }, [authedAdPlatforms, connectedAdAccounts, startDate, yesterday]);

  // combine account info with spend & auth status
  const tableData = useMemo(() => {
    if (!connectedAdAccounts?.length || !accountSummary?.length) return [];
    return accountSummary.map(obj => {
      const selectedObj = connectedAdAccounts.find(c => Number(c.account_id) === Number(obj.account_id));
      return { ...obj, ...selectedObj };
    });
  }, [connectedAdAccounts, accountSummary]);

  const transformedData = transformTableData(tableData);

  // TODO: remove the ternary once we remove gating
  const tableColumns = useMemo(() => (isTestAccount ? [
    { display: 'Connected Accounts', key: 'account_name', as: connectedAdAccounts && ActionCell(connectedAdAccounts, handleDelete) },
  ] : [
    { display: 'Account', key: 'account_name', isSearchable: true, as: connectedAdAccounts && IconCell(connectedAdAccounts) },
    { display: 'Account ID', key: 'account_id', isSearchable: true },
    { display: 'Platform', key: 'platform', isSearchable: true },
    { display: 'Status', key: 'last_sync', as: StatusCell(handleDelete), headerWidth: 4 },
  ]), [connectedAdAccounts]);

  // sync status checks
  useEffect(() => {
    if (!authedPlatPerfAccounts?.length) return;

    const selectedPlatformsByUpdatedKey = authedPlatPerfAccounts.reduce((p, curr) => {
      const filteredAuthsList = curr.authorizations.filter(c => {
        const updated = moment(c.updated);
        const isValid = updated.isValid();
        const diffInMinutes = moment().diff(updated, 'minutes');

        return !!(isValid && diffInMinutes < 60);
      });

      if (filteredAuthsList.length > 0) {
        p.push(curr.platform);
      }

      return p;
    }, []);

    Promise.all(selectedPlatformsByUpdatedKey.map(platform => getPlatformPerformanceSyncStatus(platform, twoMonthsAgo, yesterday)))
      .then(responses => {
        const addPlatformAsKeys = selectedPlatformsByUpdatedKey.reduce((p, c, i) => ({ ...p, [c]: responses[i] }), {});

        const syncingPlatformsArr = [];

        Object.entries(addPlatformAsKeys).forEach((k, v) => {
          if (addPlatformAsKeys[k].length < 30) {
            syncingPlatformsArr.push({ text: k, value: v });
          }
        });
        setShowSync(true);
        setPlatformsToSync(syncingPlatformsArr);
      });
  }, [authedPlatPerfAccounts]);

  useEffect(() => {
    track('data.marketing.advertising_platforms.main.view');
  }, []);

  if (!!authorizations && !authedAdPlatforms?.length) return <NoConnections />;
  const conversionsSummary = { shopify: 0, pixel: 0, webhooks: 0 };

  // TODO: remove check for batch once we add those in
  const displayConversionsHeader = conversionSyncStatus?.length > 0
    && Number(conversionSyncStatus[0].conversion_segment_id) === featuredSegmentId
    && conversionSyncStatus[0].type !== 'batch_file_conversions';

  return (
    <>
      {/* TODO: remove check for test account once we rollout the new version */}
      <DataStatusModal
        isTestAccount={isTestAccount}
        startDate={startDate}
        endDate={yesterday}
        connections={accountSummary}
      />
      <BasicViewHeader
        header="Advertising Platforms"
        subheader="Connect your advertising platforms and centralize your spend"
        topRight={(
          <Button
            primary
            as={Link}
            to={routes.connectIntegration}
          >
            Connect New Account
          </Button>
          )}
      />
      {/* TODO: Enable charts once summary data hooked and compiled */}
      {showSync && <SyncingData {...{ integrations: connectedAdAccounts, platformsToSync }} /> }

      {!!loading && <ChartCardLoader itemsPerRow={ITEMS_PER_ROW} padding="1em 0" />}
      {!loading && !!chartData?.length
          && (
          <PanelsGroup
            data={chartData}
            platformsDist={platformsSummary}
            integrations={connectedAdAccounts}
            colorMap={colorMap}
            apiSpend={spendSyncStatus}
            batchFileStatus={batchSpendStatus}
            syntheticData={syntheticSyncStatus}
            isTestAccount={isTestAccount}
          />
          )}
      {!!isTestAccount && displayConversionsHeader && (
        <BasicViewHeader
          header="Orders and Conversions"
          subheader="See your customer data for all your conversions"
        />
      )}
      {!!loading && <ChartCardLoader itemsPerRow={ITEMS_PER_ROW} padding="1em 0" />}
      {!loading
        && (
        <PanelsGroup
          platformsDist={conversionsSummary}
          integrations={conversionIntegrations}
          colorMap={colorMap}
          apiSpend={spendSyncStatus}
          batchFileStatus={batchSpendStatus}
          syntheticData={syntheticSyncStatus}
          conversionData={conversionSyncStatus}
          isTestAccount={isTestAccount}
          isConversionPanel
        />
        )}
      {/* TODO: remove gating */}
      {!!isTestAccount && tableData.length > 0
      && (
        <div style={{ position: 'relative' }}>
          <Button
            color="purple"
            content="Connect New Account"
            as={Link}
            to={routes.connectIntegration}
            style={{
              position: 'absolute',
              top: 3,
              right: 5,
              zIndex: 10,
            }}
            className="remove-box-shadow"
          />
          <ExpandableGrid
            data={transformedData}
            cols={tableColumns}
            idKey="platform"
            expandedComponent={ExpandedComponent}
          />
        </div>
      )}
      {/* TODO: remove old component */}
      {loading
        ? (!isTestAccount
          && (
          <ContentCard hasTable>
            <TreeTableLoader />
          </ContentCard>
          )
        )
        : (!isTestAccount
          && (
          <ContentCard hasTable>
            <IndexGridNew
              title="Connected Accounts"
              celled
              data={tableData}
              cols={tableColumns}
              sortable
              defaultSortCol="platform"
              defaultSortOrder="ascending"
              placeholder="Search Accounts"
              searchable
              alignSearchLeft
              topRight={(
                <Button
                  inverted
                  color="purple"
                  content={(
                    <>
                      <PlusCircleIcon style={{ display: 'inline', height: 19, margin: '-5px 3px -5px -3px' }} />
                      {' Connect Account'}
                    </>
                  )}
                  as={Link}
                  to={routes.connectIntegration}
                  style={{ margin: 0 }}
                  className="remove-box-shadow"
                />
                )}
              fallBackMsg="No Matching Accounts Found"
            />
          </ContentCard>
          )
        )}
    </>
  );
};

const IntegrationsAccess = () => (
  <ViewAccessWrapper
    viewName="integrations"
    viewComponent={<Integrations />}
  />
);

export default IntegrationsAccess;

import React from 'react';

import PropTypes from 'prop-types';
import { Header, Grid, Icon, Segment } from 'semantic-ui-react';

export const EmptyMessage = () => (
  <Segment textAlign="center">
    <br />
    <Icon
      circular
      disabled
      name="info"
      size="big"
    />
    <Header
      as="h3"
      disabled
      content="No data found"
      className="m-0"
    />
    <br />
  </Segment>
);

export const Headers = props => {
  const { label1, label2 } = props;

  return (
    <>
      <br />
      <Grid fluid columns={3} verticalAlign="right">
        <Grid.Row style={{ paddingTop: 0 }}>
          <Grid.Column width={8} />
          <Grid.Column width={4}>
            <Header as="h5" textAlign="center" size="small" style={{ color: '#1026AF', fontWeight: 'bold' }}>{label1}</Header>
          </Grid.Column>
          <Grid.Column width={4}>
            <Header as="h5" textAlign="center" size="small" style={{ color: '#1026AF', fontWeight: 'bold' }}>{label2}</Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

Headers.propTypes = {
  /** metric name */
  label1: PropTypes.string,
  label2: PropTypes.string,
};

import React, { useState, useEffect } from 'react';
import { Message, Form } from 'semantic-ui-react';
import { ColumnLayout, ContentCard, SelectButtons } from '@rockerbox/styleguide';
import { getConversionDataset } from '../../../api/attributionCache';
import useOptions from '../hooks/useOptions';

const FormDropdown = ({ options, label, value, onChange, loading }) => (
  <Form.Dropdown
    {...{ options, label, value, onChange, loading }}
    select
    selection
  />
);

const GeneralStep = ({
  state, updateStateField, updateStateArrayRowField,
  entityType, typeOptions, showName, setIsDisabled,
}) => {
  const { entity_type, name, segments } = state;
  const { segmentOptionsDropdown } = useOptions();

  const [fieldOptions, setFieldOptions] = useState([]);

  const { segment_id } = segments[0] || {};

  useEffect(() => {
    if (segment_id && segment_id.length === 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
    if (segment_id) {
      getConversionDataset(segment_id)
        .then(response => response.response.conversion_data)
        .then(data => {
          if (!data || data.length === 0) return;

          const fields = Object.keys(data[0])
            .filter(key => ![
              '1', 'source_name', 'ip', 'request_referrer', 'rb_source',
              'event_id', 'hash_ip', 'raw_message_id', 'timestamp', 'date',
              'landing_url', 'user_agent', 'conversion_hash_id', 'origin', 'url',
            ].includes(key))
            .map(value => ({ text: value, value }));

          setFieldOptions(fields);
        });
    }
  }, [segment_id]);

  return (
    <ColumnLayout leftWidth={4} centerWidth={8} rightWidth={4}>
      <ContentCard>
        { false && !entityType && (
        <>
          <Message
            header="What type of marketing are you trying to track?"
            content="We have a few hard to track channels listed here. The goal is to appropriately bucket the entity so we know how to atribute this data as it gets added to the model." //eslint-disable-line
          />
          {/* <Form.Dropdown selection value={entity_type} onChange={updateStateField("entity_type")} options={typeOptions} /> */}
          <SelectButtons value={entity_type} onChange={updateStateField('entity_type')} options={typeOptions} large />
        </>
        )}
        {showName && (
        <>
          <Message header={`What is the name of this ${entity_type}?`} content={`How do you refer to this particular ${entity_type}`} />
          <Form.Input value={name} onChange={updateStateField('name')} />
        </>
        )}

        {
          showName && segments.map(row => {
            const { segment_id = '', promo_field } = row; // eslint-disable-line no-shadow
            return (
              <>
                <Message
                  header="Associate a segment"
                  content="Since you are using 'promo-codes' or information collected at the time of conversion to attribute marketing, it is necessary to choose a conversion segment from which to capture the marketing information. To do this, we need to know the conversion segment as well as the field which contains the promo-code information." // eslint-disable-line
                />
                <FormDropdown
                  onChange={(e, { value }) => updateStateArrayRowField('segments', 'segment_id')(row)(false, { value })}
                  label="Segment"
                  value={parseInt(segment_id)}
                  options={segmentOptionsDropdown}
                />
                <FormDropdown
                  value={promo_field}
                  onChange={updateStateArrayRowField('segments', 'promo_field')(row)}
                  options={fieldOptions}
                  search
                  label="Promo-code Field"
                />

              </>
            );
          })
        }

      </ContentCard>
    </ColumnLayout>
  );
};

export default GeneralStep;

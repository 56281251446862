import React, { useState, useRef } from 'react'
import { Grid, Header, Card, Button, Image, Label, Icon, Loader, Popup, Input } from 'semantic-ui-react'
import CacheContext from '../../utils/CacheContext.js'
import { getAllIntegrations, addPlatformFeature, getAccount } from '../../utils/api.js'
import { AccountCell } from '../Integrations/Authorizations/OAuth'
import { IntegrationDisplayName } from './parts'
import { track } from '../../utils/tracking'


export const IntegrationLogo = ({ platform, style, wrapClassName }) => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const integrations = (Context.getCache(`integrations`, getAllIntegrations) || [])
  const integrationObject = integrations.find(row => row.platform == platform) || {}
  const { logo_img } = integrationObject
  const image = logo_img || 'integrations-placeholder.png'
  const src = `https://images.rockerbox.com/integrations/${image}`

  return (
    <div className={wrapClassName || "flex-centered"}>
      <Image centered size='medium' ui={false} {...{src, style}} />
    </div>
  )
}


const IntegrationCard = ({ platformObj, enabled, authRow, isAllowed, updatePath, isClickable, showAuthedAccounts }) => {
  const cardRef = useRef()
  
  const platform = platformObj.key

  const getNumAuths = (authorizations) => {
    if (!authorizations) return 0
    const accountIds = authorizations

      .filter(({ account_id }) => !!account_id)
      .map(({ account_id }) => account_id)
    const uniqueAccounts = new Set(accountIds)
    return uniqueAccounts.size
  }

  const { authorizations } = authRow || {}
  const numAuths = getNumAuths(authorizations)
  const authorization = !!authorizations && authorizations[0]
  const hasAuth = !!authorization
  const isAuthComplete = authorization && authorization.account_id != ""

  const backgroundColor = "none"
  const platformValues = platformObj.values[0]
  const displayName = <IntegrationDisplayName {...{platform}} />
  const description = platformValues.description || <>Connect {displayName} to Rockerbox</>
  const authPath = platformValues.path
  const { auth_required, premium } = platformValues

  const col = {key: "account_id"}
  const id = authorization && authorization.id
  const item = {id, platform: platform, backgroundColor}

  const [loading, setLoading] = React.useState(false)
  const enableIntegration = ({ platform, id }) => () => {
    setLoading(true)
    track("integration.installed", { platform, id })
    addPlatformFeature(platform, id).then(() => window.location = `/v2/settings/integrations/view/${platform}`)
  }
  const oauthPlatform = ({ platform, id, path }) => () => {
    track("integration.installed", { platform, id })
    window.location = path
  }
  const nonOauthPlatform = ({ platform, id }) => () => {
    track("integration.installed", { platform, id })
    updatePath({ platform, feature: 'Accounts' })
  }
  const startIntercomChat = ({ display_name, platform }) => () => {
    const name = platform.length < 4 || display_name.replaceAll(' ','').toLowerCase().includes(platform.replaceAll('_',''))
      ? display_name
      : `${display_name} (${platform.charAt(0).toUpperCase()}${platform.slice(1)})`
    const chatMessage = `Hi! I'm interested in upgrading to access the ${name} integration.`
    if (window.Intercom) window.Intercom('showNewMessage', chatMessage)
    track("integration.upgrade_contact", { platform, id })
  }
  const onClick = !loading && (
    !isAllowed
      ? startIntercomChat(platformValues)
      : auth_required
        ? !!authPath
          ? oauthPlatform(platformValues)
          : nonOauthPlatform(platformValues)
        : enableIntegration(platformValues)
  )
  const goToPlatform = () => updatePath({ platform, feature: null })

  return (
    <Card className='integration-card' onClick={isClickable && goToPlatform} ref={cardRef}>
      {!!premium && !enabled &&
        <Label ribbon='left' color='orange'>
          Premium
        </Label>
      }
      <IntegrationLogo {...{platform}} />
      <Card.Content>
        <Card.Header>{displayName}</Card.Header>
        <Card.Meta>
          {description}
        </Card.Meta>
        {!isClickable &&
        <Card.Description className={hasAuth && !isAuthComplete && 'flex-centered'}>
          {!hasAuth && (
            (!auth_required && enabled) ?
              <Button
                color='green'
                animated='vertical'
                className='full-width'
                onClick={goToPlatform}
              >
                <Button.Content hidden>View</Button.Content>
                <Button.Content visible>
                  <div>
                    <Icon name='check' />
                    &nbsp;Enabled
                  </div>
                </Button.Content>
              </Button>
            :
              <Button
                color={!isAllowed ? 'teal' : 'blue'}
                icon={!isAllowed && 'lock'}
                content={!!isAllowed ? (!!auth_required ? "Connect" : "Enable") : "Contact Us to Upgrade"}
                className='full-width'
                loading={loading}
                {...{onClick}}
              />
          )}
          {hasAuth && isAuthComplete &&
            (showAuthedAccounts ?
              <>
                <Label
                  color='green'
                  icon='check'
                  content="Connected"
                />
                <div className='account-info'>
                  <Icon name='user circle' />&nbsp;
                  {numAuths > 1
                    ? `${numAuths} Accounts`
                    : `${authorization.account_name} (${authorization.account_id})`
                  }
                </div>
              </>
            :
              <Button
                color='green'
                animated='vertical'
                className='full-width'
                onClick={goToPlatform}
              >
                <Button.Content hidden>View</Button.Content>
                <Button.Content visible>
                  <div>
                    <Icon name='check' />
                    &nbsp;{numAuths} Account{numAuths > 1 && 's'} Connected
                  </div>
                </Button.Content>
              </Button>
            )
          }
          { hasAuth && !isAuthComplete &&
            <Popup
              open inverted wide position='top center' className='callout-orange'
              content={<>Please select the {displayName} account you would like to connect</>}
              popperModifiers={[{ boundary: cardRef }]}
              trigger={
                <AccountCell {...{backgroundColor, item, col}} forceShowButton />
              }
            />
          }
        </Card.Description>
        }
      </Card.Content>
    </Card>
  )
}


const IntegrationGroup = ({ section, platformsToDisplay, allowedPlatforms, enabledFeatures, galleryHideCategoryHeaders, updatePath, galleryNumColumns, galleryCentered, galleryShowAuthedAccounts, clickableCards, searchValue }) => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const account = (Context.getCache(`account`, getAccount) || {})
  const ADMIN_ADVERTISER_ID = 769315
  const [accountIsAdmin, setAccountIsAdmin] = useState(account.external_advertiser_id === ADMIN_ADVERTISER_ID)
  const { platformTypes, authorizations } = section

  const showAllPlatforms = platformsToDisplay.length == 0
  const platformType = platformTypes.find(type => type.key == section.platformType) || {}
  const { values } = platformType || {}
  const platforms = values ? (values.filter(({ key, values }) => showAllPlatforms ? values.map(x => accountIsAdmin ? x.deprecated : x.deprecated || x.is_admin).some(x => !x) : platformsToDisplay.includes(key))) : []

  const getAuthorization = (platform) => (authorizations||[]).find(type => type.platform == platform) || false
  const isEnabled = (platform) => !!section.integrations.find(i => i.key == platform)

  const [filteredPlatforms] = React.useMemo(() => {
    if (!searchValue) return [platforms]

    const filteredPlatforms = platforms.filter(x =>
      x.values.map(x =>
        x.display_name.toLowerCase().includes(searchValue) || (!!x.description && x.description.toLowerCase().includes(searchValue))
      ).some(x => !!x)
    )
    return [filteredPlatforms]
  }, [searchValue, platforms])

  const sortedPlatforms = filteredPlatforms.sort((a, b) => a.values[0]['platform'] < b.values[0]['platform'] ? -1 : 1)

  if (platforms.length == 0) return null

  return <Grid.Row>
    <Grid.Column>
      {!galleryHideCategoryHeaders &&
        <Header dividing as='h3' content={section.title} />
      }
      <Card.Group centered={galleryCentered} itemsPerRow={galleryNumColumns || 4}>
        {sortedPlatforms.map(platformObj =>
          <IntegrationCard
            key={platformObj.key}
            enabled={isEnabled(platformObj.key)}
            authRow={getAuthorization(platformObj.key)}
            isAllowed={
              (!platformObj.values[0].premium || enabledFeatures.indexOf(platformObj.key) > -1)
              && (!allowedPlatforms.length || allowedPlatforms.indexOf(platformObj.key) > -1)
            }
            isClickable={!!clickableCards}
            showAuthedAccounts={galleryShowAuthedAccounts}
            {...{platformObj, updatePath}}
          />
        )}
      </Card.Group>
    </Grid.Column>
  </Grid.Row>
}


const GalleryLoader = () => (
  <div className='flex-centered'>
    <Loader active />
  </div>
)


const IntegrationGallery = ({ galleryHeader, galleryHideCategoryHeaders, galleryNumColumns, galleryCentered, galleryShowAuthedAccounts, clickableCards, platformsToDisplay, allowedPlatforms, enabledFeatures, integrations, updatePath }) => {
  if (!integrations) return null

  const loadedIntegrations = integrations.reduce((acc, curr) => acc + (curr.platformTypes ? curr.platformTypes.length : 0), 0)
  if (!loadedIntegrations) return <GalleryLoader />

  const [searchValue, setSearchValue] = React.useState("")

  return (
    <>
      <Grid fluid="true" columns="equal">
        <Grid.Row>
          <Grid.Column>
            {!!galleryHeader &&
              <Grid fluid="true">
                <Grid.Row>
                  <Grid.Column width={10}>
                    <Header
                      as='h2'
                      content={galleryHeader.header}
                      subheader={galleryHeader.subheader}
                    />
                  </Grid.Column>
                  <Grid.Column width={6} textAlign='right'>
                    <Input
                      icon='search'
                      iconPosition='left'
                      placeholder='Search'
                      onChange={(evt, { value }) => setSearchValue(value.toLowerCase())}
                      className={!!searchValue && 'active'}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            }
          </Grid.Column>
        </Grid.Row>
        { integrations.map(section => <IntegrationGroup {...{ section, platformsToDisplay, allowedPlatforms, enabledFeatures, galleryHideCategoryHeaders, galleryNumColumns, galleryCentered, galleryShowAuthedAccounts, clickableCards, updatePath, searchValue, key: section.platformType }} />) }
      </Grid>
    </>
  )
}

export default IntegrationGallery

import React, { Component } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import IndexGrid from '../IndexGrid';

class SortableIndexGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      direction: null,
      data: [],
      sortKey: undefined,
    };
  }

  componentDidMount() {
    const { data, sortKey } = this.props;
    this.setState({ data, sortKey });
  }

  componentDidUpdate(prevProps) {
    const { data, sortKey } = this.props;
    if (data !== prevProps.data) {
      this.setState({ data, sortKey });
    }
  }

  handleSort = (clickedColumn, col) => () => {
    const { column, data, direction: directionFromState, sortKey: sortKeyFromState } = this.state;
    const sortKey = col.sortKey || sortKeyFromState || clickedColumn;

    const sortBy = col.subKey ? o => o[clickedColumn][col.subKey] : o => o[sortKey];
    const direction = directionFromState === 'ascending' ? 'descending' : 'ascending';

    const undefinedData = data.filter(row => sortBy(row) === undefined);
    const definedData = data.filter(row => sortBy(row) !== undefined);

    const sortedData = _.sortBy(definedData, [sortBy]);
    if (direction === 'descending') sortedData.reverse();

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: [...sortedData, ...undefinedData],
        direction,
      });
      return;
    }

    const arr = [...sortedData, ...undefinedData];

    this.setState({
      column: clickedColumn,
      data: arr,
      direction,
    });
  };

  render() {
    const { cols } = this.props;
    const { column, data, direction } = this.state;

    const Headers = cols.map(col => {
      if (col.sortable === false) {
        return <Table.HeaderCell width={col.headerWidth}>{col.display}</Table.HeaderCell>;
      }
      return <Table.HeaderCell width={col.headerWidth} sorted={column === col.key ? direction : null} onClick={this.handleSort(col.key, col)}>{col.display}</Table.HeaderCell>;
    });

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <IndexGrid sortedHeaders={Headers} {...this.props} data={data} sortable />
    );
  }
}

SortableIndexGrid.propTypes = {
  /** @deprecated instead, wrap `IndexGrid` component with [ContentCard](#contentcard) and include the `hasTable` prop
   */
  as: PropTypes.element,
  /** Array of grid row objects */
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  /** Array of column objects */
  cols: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    display: PropTypes.string,
    as: PropTypes.element,
    sortable: PropTypes.bool,
  })).isRequired,
  /** Optional fallback message if no data is available */
  fallBackMsg: PropTypes.string,
  UTCOffset: PropTypes.number,
};

export default SortableIndexGrid;

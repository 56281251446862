import React, { useState, useEffect } from "react";
import { Table, Ref, Dropdown, Input, Button, Icon } from "semantic-ui-react";
import { RemoveRowButton } from "./Buttons";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { IndexGrid } from "@rockerbox/styleguide";
import {
  ItemTypes,
  typeOptions,
  datetimeFormatOptions,
  floatFormatOptions,
} from "./constants";

const TableHeader = (props) => {
  const columns = [
    "Field",
    "Description",
    "Type",
    "Format",
    "Default Value",
    "",
  ];

  return (
    <Table.Header>
      <Table.Row>
        {columns.map((col, i) => (
          <Table.HeaderCell
            key={col}
            style={i === 0 ? { paddingLeft: "44px" } : {}}>
            {col}
          </Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
  );
};

const RowDropTarget = (props) => {
  const {
    schema: { order },
    moveRowOrder,
  } = props;
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.ORDER,
    drop: (item) => moveRowOrder(item.id, order),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.ORDER, id: order },
    collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
  });

  return (
    <Ref innerRef={drop} style={{ width: "100%" }}>
      <Table.Row key={order} ref={drag}>
        <Ref innerRef={drag}>{props.children}</Ref>
      </Table.Row>
    </Ref>
  );
};

const RowDragContent = ({
  schema,
  updateSchemaByOrder,
  optionKeys,
  datetimeOptions,
  floatOptions,
  removeSchemaByOrder,
  handleDatetimeFormatAddition,
  handleFloatFormatAddition,
  validationMap,
}) => {
  const isDefinedCol = validationMap[schema.name]
    ? validationMap[schema.name]
    : false;
  return (
    <React.Fragment style={{ className: "draggable" }}>
      <Table.Cell style={{ cursor: "grab" }}>
        <Icon name="list" size="large" style={{ paddingRight: "30px" }}></Icon>
        {schema.name}
      </Table.Cell>
      <IndexGrid.EllipsedCell
        item={schema}
        col={{ key: "description" }}
        style={{ minWidth: "300px" }}>
        {schema.description}
      </IndexGrid.EllipsedCell>
      <Table.Cell>
        {validationMap[schema.name] ? (
          `${schema.type}`
        ) : (
          <Dropdown
            fluid
            value={schema.type}
            options={typeOptions}
            onChange={(e, data) =>
              updateSchemaByOrder(schema.order, "type", data.value)
            }
          />
        )}
      </Table.Cell>
      <Table.Cell>
        {schema.type === "datetime" || schema.type === "float" ? (
          <Dropdown
            fluid
            search
            allowAdditions
            value={schema.format}
            options={
              schema.type === "datetime" ? datetimeOptions : floatOptions
            }
            onAddItem={
              schema.type === "datetime"
                ? handleDatetimeFormatAddition
                : handleFloatFormatAddition
            }
            onChange={(e, data) =>
              updateSchemaByOrder(schema.order, "format", data.value)
            }
          />
        ) : (
          <Input value={""} transparent placeholder="N/A" />
        )}
      </Table.Cell>
      <Table.Cell>
        <Input
          value={schema.default}
          transparent
          placeholder="Optional"
          onChange={(e, data) =>
            updateSchemaByOrder(schema.order, "default", data.value)
          }
        />
      </Table.Cell>
      <Table.Cell>
        <RemoveRowButton
          removeSchemaByOrder={removeSchemaByOrder}
          schema={schema}
        />
      </Table.Cell>
    </React.Fragment>
  );
};

const NewTableRow = (props) => {
  const {
    updateColumnField,
    newColumn,
    optionKeys,
    datetimeOptions,
    floatOptions,
    handleDatetimeFormatAddition,
    handleFloatFormatAddition,
    createNewField,
    schemas,
    fieldOptions,
  } = props;

  return (
    <Table.Row>
      <Table.Cell>
        <Dropdown
          selection
          search
          options={fieldOptions}
          value={newColumn["name"]}
          onChange={(e, data) => updateColumnField("name", data.value)}
        />
        <Button
          inverted
          icon="check"
          color="green"
          onClick={() => createNewField()}
        />
      </Table.Cell>
      <Table.Cell>{newColumn.description}</Table.Cell>
      <Table.Cell>
        <Dropdown
          fluid
          value={newColumn.type}
          options={typeOptions}
          onChange={(e, data) => updateColumnField("type", data.value)}
        />
      </Table.Cell>
      <Table.Cell>
        {newColumn.type === "datetime" || newColumn.type === "float" ? (
          <Dropdown
            fluid
            search
            allowAdditions
            value={newColumn.format}
            options={
              newColumn.type === "datetime" ? datetimeOptions : floatOptions
            }
            onAddItem={
              newColumn.type === "datetime"
                ? handleDatetimeFormatAddition
                : handleFloatFormatAddition
            }
            onChange={(e, data) => updateColumnField("format", data.value)}
          />
        ) : null}
      </Table.Cell>
      <Table.Cell>
        <Input
          value={newColumn.default}
          transparent
          placeholder="Optional"
          onChange={(e, data) => updateColumnField("default", data.value)}
        />
      </Table.Cell>
      <Table.Cell></Table.Cell>
    </Table.Row>
  );
};

const DnDReportTable = (props) => {
  const {
    schemas,
    moveRowOrder,
    updateSchemaByOrder,
    removeSchemaByOrder,
    optionKeys,
    newColumn,
    validationMap,
  } = props;

  const [datetimeOptions, setDatetimeOptions] = useState(datetimeFormatOptions);
  const [floatOptions, setFloatOptions] = useState(floatFormatOptions);

  const handleDatetimeFormatAddition = (e, { value }) => {
    setDatetimeOptions((prevState, curProps) => {
      const newState = prevState.map((item) => Object.assign({}, item));
      newState.push({ key: value, text: value, value });
      return newState;
    });
  };

  const handleFloatFormatAddition = (e, { value }) => {
    setFloatOptions((prevState, curProps) => {
      const newState = prevState.map((item) => Object.assign({}, item));
      newState.push({ key: value, text: value, value });
      return newState;
    });
  };

  const formatOptionsProps = {
    datetimeOptions,
    floatOptions,
    handleDatetimeFormatAddition,
    handleFloatFormatAddition,
  };

  const newRow = newColumn ? (
    <NewTableRow {...props} {...formatOptionsProps} optionKeys={optionKeys} />
  ) : null;
  const dndRows = schemas.map((schema) => (
    <RowDropTarget
      schema={schema}
      moveRowOrder={moveRowOrder}
      key={schema.name}>
      <RowDragContent
        schema={schema}
        updateSchemaByOrder={updateSchemaByOrder}
        removeSchemaByOrder={removeSchemaByOrder}
        validationMap={validationMap}
        optionKeys={optionKeys}
        {...formatOptionsProps}
      />
    </RowDropTarget>
  ));

  return (
    <DndProvider backend={HTML5Backend}>
      <Table celled>
        <TableHeader />
        <Table.Body>
          {dndRows}
          {newRow}
        </Table.Body>
      </Table>
    </DndProvider>
  );
};

export default DnDReportTable;

import { useMemo } from 'react';
import { getChartSummary, getStatsByTable, mergeTables, getTotals, filterTiers, filterSearch } from './arqueroHelpers';

const useMemoWithLog = (func, watching) => useMemo(
  (...args) => {
    const res = func(...args);
    return res;
  },
  watching,
);

const s = arr => JSON.stringify(arr);

const nRowsGreaterThanCountValue = (tables, rowCount) => {
  const tablesRowCount = tables.map(({ _nrows }) => _nrows);
  return tablesRowCount.some(rows => rows > rowCount);
};

export const useFilteredDataArq = (tiersDataRaw, funnelConfiguration, search, filters, conversionKey) => {
  const { tier_1, tier_2, tier_3, tier_4, tier_5 } = filters;

  const maxGroupby = (tiersDataRaw && nRowsGreaterThanCountValue(tiersDataRaw, 50000)) ? ['date', 'tier_1', 'tier_2', 'tier_3', 'tier_4'] : ['date', 'tier_1', 'tier_2', 'tier_3', 'tier_4', 'tier_5'];

  const tiersDataReduced = useMemoWithLog(() => {
    if (!!tiersDataRaw && tiersDataRaw.length) return getStatsByTable(maxGroupby, tiersDataRaw);
    return false;
  }, [tiersDataRaw]);

  const tiersData = useMemoWithLog(() => {
    if (!!tiersDataReduced && tiersDataReduced.length) return tiersDataReduced.map(table => filterSearch(table, search, maxGroupby));
    return false;
  }, [tiersDataReduced, search]);

  // FOR TABLE DATA
  const tiersSummaryRaw = useMemoWithLog(() => {
    if (tiersData && tiersData.length) return getStatsByTable(maxGroupby.slice(1), tiersData);
    return false;
  }, [tiersData]);

  const tiersSummaryRawMerged = useMemoWithLog(() => {
    if (!!tiersSummaryRaw && tiersSummaryRaw.length) return mergeTables(tiersSummaryRaw, funnelConfiguration);
    return false;
  }, [tiersSummaryRaw, funnelConfiguration]);

  const filteredTiersSummary = useMemoWithLog(() => (
    !!tiersSummaryRawMerged ? filterTiers(tiersSummaryRawMerged, filters) : false
  ), [tiersSummaryRawMerged, s(tier_1), s(tier_2), s(tier_3), s(tier_4), s(tier_5)]);

  const tiersSummaryTable = useMemoWithLog(() => {
    if (!filteredTiersSummary) return [];
    return filteredTiersSummary.objects();
  }, [filteredTiersSummary, conversionKey]);

  // FOR CHART DATA
  const filteredTiers = useMemoWithLog(() => {
    if (!!tiersData || tiersData.length === 0) {
      return tiersData.map(table => filterTiers(table, filters));
    }
    return false;
  }, [tiersData, s(tier_1), s(tier_2), s(tier_3), s(tier_4), s(tier_5)]);

  const tierDataGroupedByTier1 = useMemoWithLog(() => {
    if (filteredTiers && filteredTiers.length) return getStatsByTable(['tier_1'], filteredTiers);
    return false;
  }, [filteredTiers]);

  const tier1DataMerged = useMemoWithLog(() => {
    if (!!tierDataGroupedByTier1 && tierDataGroupedByTier1.length) {
      return mergeTables(tierDataGroupedByTier1, funnelConfiguration);
    }
    return false;
  }, [tierDataGroupedByTier1, funnelConfiguration]);

  const tier1SummaryByEvent = useMemoWithLog(() => {
    if (!tier1DataMerged) return false;
    return getChartSummary(['tier_1'], tier1DataMerged).objects();
  }, [tier1DataMerged]);

  // TOTALS
  const tiersSummaryRawTotals = useMemoWithLog(() => (
    tiersSummaryRawMerged ? getTotals(tiersSummaryRawMerged) : false
  ), [tiersSummaryRawMerged]);

  const tiersSummaryTotals = useMemoWithLog(() => {
    if (filteredTiersSummary) {
      return getTotals(filteredTiersSummary);
    }
    return false;
  }, [filteredTiersSummary]);

  // base case
  if (!tiersData || tiersData.length === 0) {
    return {
      tier1SummaryByEvent: [],
      tiersSummary: [],
      tiersSummaryRaw: [],
      tiersSummaryRawTotals: {},
      tierSummaryTotals: {},
    };
  }

  return {
    tier1SummaryByEvent,
    tiersSummary: tiersSummaryTable,
    tiersSummaryRawMerged: tiersSummaryRawMerged.objects(),
    tiersSummaryRawTotals: tiersSummaryRawTotals.objects()[0],
    tiersSummaryTotals: tiersSummaryTotals.objects()[0],
  };
};

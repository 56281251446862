import React from "react";
import { Form, Button } from "semantic-ui-react";

const tiers = ["one", "two", "three", "four", "five"];

const TiersInput = ({ onExternalTierChange }) => {
  const [showCurrent, setShowCurrent] = React.useState('one');
  const currentFields = tiers.slice(0, tiers.indexOf(showCurrent) + 1);

  const handleAddorMinusField = (index, operation) => {
    const nextIndex = operation === 'plus' ? tiers.indexOf(index) + 1 : tiers.indexOf(index) - 1;
    setShowCurrent(tiers[nextIndex]);
  };


  return (
    <Form.Group>
      {currentFields.map((num) => {
      const key = "tier_" + num;
        return (
            <Form.Input
            placeholder={key}
            name={num}
            //   value={(tiers_filter[index] && tiers_filter[index][key]) || ""}
            onChange={onExternalTierChange}
            />
        );
      })}
      <Button
      icon="plus"
      onClick={() => handleAddorMinusField(showCurrent, 'plus')}
      style={{ display: showCurrent == "five" ? "none" : "inline-block" }}
      as="span"
      />
      <Button
      icon="minus"
      onClick={() => handleAddorMinusField(showCurrent, 'minus')}
      style={{ display: showCurrent == "one" ? "none" : "inline-block" }}
      as="span"
      />
  </Form.Group>
  );
};
  
export default TiersInput
import React, { useContext, useEffect } from 'react';
import { getMessages, getAccountCurrency } from '../../utils/api';
import CacheContext from '../../utils/CacheContext';

const getCurrency = () => {
  return getAccountCurrency()
  .then(data => {
    return data.length > 0 ? data[0].currency_code : 'USD';
  })
}

const GlobalApplicationData = () => {
  const [state, setState, Context] = useContext(CacheContext);
  const currency = (Context.getCache(`currency_code`, getCurrency));
  const messages = Context.getCache(`messages`, getMessages) || []

  useEffect(() => {
    const format = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: state.currency_code || "USD",
    }).format

    const formatCurrency = (x) => x ? format(x) : '-';

    setState({ formatCurrency })

  },[currency])

  return null;
}

export default GlobalApplicationData;

/**
 * Returns a subset of data based on the selected page number and items per page.
 *
 * @param {Array} data - The array of data to be paginated.
 * @param {number} itemsPerPage - The number of items to be displayed per page.
 * @param {number} selectedPageNumber - The selected page number.
 * @returns {Array} - The subset of data for the selected page.
 */
export const selectData = (data, itemsPerPage, selectedPageNumber) => (data || []).slice(
  (selectedPageNumber - 1) * itemsPerPage,
  selectedPageNumber * itemsPerPage,
);

/**
 * Calculate the length of the pager.
 *
 * @param {number} selectedPageNumber - The selected page number.
 * @returns {Array} - The subset of data for the selected page.
 */
export const calculatePagerLength = (rows, itemsPerPage) => {
  const numRows = rows.length === 0 ? 1 : rows.length;
  const numberOfPagers = Math.ceil(numRows / itemsPerPage);
  return numberOfPagers;
};

/**
 * Calculates the starting record number based on the selected page number and items per page.
 *
 * @param {number} selectedPageNumber - The selected page number.
 * @param {number} itemsPerPage - The number of items per page.
 * @returns {number} - The starting record number.
 */
export function recordsFrom(selectedPageNumber, itemsPerPage) {
  const fromRecord = (selectedPageNumber - 1) * itemsPerPage + 1;

  if (selectedPageNumber === 1) {
    return 1;
  }
  return new Intl.NumberFormat().format(fromRecord);
}

/**
 * Calculates the ending record number for the given page number and pagination settings.
 *
 * @param {number} selectedPageNumber - The selected page number.
 * @param {number} itemsPerPage - The number of items per page.
 * @param {number} totalItems - The total number of items.
 * @returns {string} - The formatted ending record number.
 */
export function recordsTo(selectedPageNumber, itemsPerPage, totalItems) {
  const toRecord = itemsPerPage * selectedPageNumber;

  if (toRecord > totalItems) {
    return new Intl.NumberFormat().format(totalItems);
  }
  return new Intl.NumberFormat().format(toRecord);
}

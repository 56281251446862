import React, { useState } from 'react';

const CacheContext = React.createContext([{}, () => {}, () => {}]);
const LOADING = {}
let state = {}

const CacheContextProvider = (props) => {
  const [update, setUpdate] = useState({});
  const setState = (stateUpdates) => {
    //state = {...state, ...stateUpdates}
    state = Object.assign(state, stateUpdates)
    setUpdate(stateUpdates)
  }

  const getCache = (key, func, ...args) => {
    if (state[key] || LOADING[key]) return state[key];

    if (func && typeof func === "function" && !LOADING[key]) {
      LOADING[key] = true;
      func(...args)
        .then(resp => {
          LOADING[key] = false;
          setState({ [key]: resp}) 
        })
      return false
    }

    if (func) {
      setState({ [key]: func })
      return func
    }

    throw "must supply getCache with atleast two arguments"

  }
  const isLoading = (key) => {
    return LOADING[key]
  }
  return (
    <CacheContext.Provider value={[state, setState, { getCache, isLoading }]}>
      {props.children}
    </CacheContext.Provider>
  );
}

const CacheContextHook = (name, getter, defaultValue, ...args) => {

  const [state, setState, Context] = React.useContext(CacheContext);
  const resp = Context.getCache(name, getter, ...args)

  return React.useMemo(() => resp || defaultValue, [resp])
}

export { CacheContext, CacheContextProvider, CacheContextHook };
export default CacheContext;

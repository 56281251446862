import React, { useEffect } from 'react'
import { Segment, Button, Grid, Icon, Input, Form, Header, Loader, Dimmer } from 'semantic-ui-react';
import { CacheContext } from '../../../utils/CacheContext'
import { getAuthorizations, getIntegrationFeatures, saveMappingTemplate, getAllIntegrations, getIntegrations } from '../../../V3/api/account'
import { getArtifacts } from '../../../V3/api/attribution'
import { submitAttributionRunJob } from '../../../V3/api/attributionRun'
import IncompleteStep from '../parts/IncompleteStep';
import { TrackingPlatformsList, AUTO_PARAMS_PLATFORMS } from './parts';

// import { DEFAULT_HISTORY, FEATURES_TO_ENABLE, FEATURE_FLAGS } from '../helpers/constants'
// import { ALLOWED_PLATFORMS_MAPPING } from '../../../V3/constants/mappings'

const Tracking = ({ completeStep, advertiser, setActiveStep, completedOnboardingSteps, tier }) => {
  const getAuths = () => (
    getAuthorizations().then(data =>
      data.map(auths => {
        const { authorizations, platform } = auths
        authorizations.map(a => a['platform'] = platform)
        auths['authorizations'] = authorizations
        return auths
      })
      .filter(a => !a.authorizations[0].batch_platform && a.platform != 'shopify')
    )
  )

  const [state, setState, Context] = React.useContext(CacheContext);
  const { auto_params_help_docs } = Context.getCache(`artifacts`, getArtifacts) || {}
  const authorizations = Context.getCache(`authorizations`, getAuths);
  const integrations = Context.getCache(`integrations`, getAllIntegrations) || [];
  const integrationFeatures = Context.getCache(`integrationFeatures`, getIntegrationFeatures) || [];
  const [loading, setLoading] = React.useState(true);
  const [authorizedPlatforms, setAuthorizedPlatforms] = React.useState([]);
  const [platformsComplete, setPlatformsComplete] = React.useState(AUTO_PARAMS_PLATFORMS);

  useEffect(() => {
    setLoading(true);
    if (!authorizations) return;
    const platforms = []
    authorizations.map(p => {
      if(p.authorizations.some(a => a.account_id != '') && p.platform != 'shopify') {
        platforms.push(p.platform);
      }
    });
    setLoading(false);
    setAuthorizedPlatforms(platforms);
    if (completedOnboardingSteps.includes('tracking')) setPlatformsComplete(platforms);
  }, [authorizations]);

  const markPlatformComplete = (platform) => setPlatformsComplete([...platformsComplete, platform]);
  const helpDocsObj = auto_params_help_docs ? JSON.parse(auto_params_help_docs) : {};

  return (
    <div className='onboarding-tracking-view'>
      <Grid
        columns={1}
      >
        <Grid.Row>
          <Grid.Column width={15}>
            <Segment
              basic
              textAlign='center'
            >
              <Header as='h1'>
                Configure Tracking Parameters
                {tier === 'starter' &&
                <Header.Subheader>
                  In order for us to measure your ads, you'll need to add parameters to your ad URLs
                </Header.Subheader>}
                {tier != 'starter' &&
                <Header.Subheader>
                  Add tracking to your core Advertising Platforms to enable Rockerbox to begin tracking marketing touchpoints against these channels. 
                  <br/>
                  <br/>
                  For any platforms not listed here, your onboarding team will provide specific instructions once you complete our initial onboarding flow! 
                </Header.Subheader>}

              </Header>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={12} style={{marginLeft: 'auto', marginRight: 'auto'}}>
            { loading ? <Loader active />
              :
              authorizedPlatforms.length > 0 ?
              <>
                <TrackingPlatformsList {...{ authorizedPlatforms, platformsComplete, integrations, integrationFeatures, markPlatformComplete, advertiser, auto_params_help_docs: helpDocsObj }}/>
                <div style={{textAlign: 'center'}}>
                  <Button
                    primary
                    className='next'
                    onClick={completeStep}
                    disabled={!authorizedPlatforms.every(p => platformsComplete.includes(p))}
                  >
                    Next <Icon name='arrow right' />
                  </Button>
                </div>
              </>
              :
              <IncompleteStep
                message="You haven't connected any ad platforms"
                className='platforms-incomplete'
                icon='puzzle piece'
                buttonText='Connect Advertising Platforms'
                prevStep='platforms'
                setActiveStep={setActiveStep}
              />
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default Tracking;


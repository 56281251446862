import { useState, useEffect, useMemo } from 'react';

import { parseIssues, parseMissingParamsData, parseUrlsAndQaData } from './hooksHelpers';
import { getFacebookAutoParamsErrors } from '../../api/attribution';
import { getPixelTrackingResolutions } from '../../api/attributionPixels';
import { getOnsiteDataset } from '../../api/attributionReporting';
import { useGlobalState } from '../../hooks/global';
import { yesterdayTrackingQa } from '../../utils/time';
import { parseFbErrors } from '../../utils/trackingQa';

export const useMissingParams = platform => {
  const { segments, authorizations } = useGlobalState();
  const [missingParamsData, setMissingParamsData] = useState(null);
  const [missingParamsSummary, setMissingParamsSummary] = useState(undefined);

  const allPagesSegment = useMemo(() => {
    if (!segments) return null;
    return segments.find(s => s.action_type === 'segment');
  }, [segments]);

  useEffect(() => {
    if (!platform || !allPagesSegment || !authorizations) return;
    if (platform === 'facebook' && authorizations) {
      const fbAuths = authorizations.find(x => x.platform === 'facebook')?.authorizations;

      if (!fbAuths) return;

      const fbAccountIds = fbAuths.map(x => x.account_id);
      const fbPromises = fbAccountIds.map(accountId => getFacebookAutoParamsErrors(accountId));

      Promise.all(fbPromises)
        .then(responses => parseFbErrors(responses))
        .then(allFbErrors => {
          const returnObj = {
            facebook: {
              hasIssues: allFbErrors.length > 0,
              errors: allFbErrors,
            },
          };
          setMissingParamsSummary(returnObj);
          setMissingParamsData([]);
        });
      return;
    }

    Promise.all([
      getPixelTrackingResolutions(platform),
      getOnsiteDataset(allPagesSegment.action_id, 'tracking_qa_results', yesterdayTrackingQa, yesterdayTrackingQa),
    ]).then(([resolutions, data]) => {
      const { combinedTruncatedUrlAndReferralUrls, platformQAData } = parseUrlsAndQaData(data, platform);
      const missingParamsDataRaw = parseMissingParamsData(combinedTruncatedUrlAndReferralUrls, resolutions);
      const { hasIssues, missingParamsTableData } = parseIssues(platformQAData, missingParamsDataRaw);

      setMissingParamsSummary({ [platform]: { hasIssues } });
      setMissingParamsData(missingParamsTableData);
    })
      .catch(err => {
        console.error(err);
        setMissingParamsData([]);
        setMissingParamsSummary({});
      });
  }, [platform, allPagesSegment, authorizations]);

  return {
    missingParamsData,
    setMissingParamsData,
    missingParamsSummary,
  };
};

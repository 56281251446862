import React from 'react';
import * as Styles from './FormBodyStyles';
import Container from '../parts/Container';
import { Accordion, Header, Dropdown } from 'semantic-ui-react';
import DebugTransformTableJoin from './DebugTransformTableJoin';

const INDICES = ["1","2","3","4","5"];
const BUCKETS_HEADER = "Buckets"
const BUCKETS_HEADER_TEXT = "Enter the buckets, starting with the broadest first, that you would use to categorize these events."
const EVENT_TYPE_OPTIONS = [
  {key:"rename",value:"rename",text:"Rename"},
  {key:"transform",value:"transform",text:"Transform"}
]

const DropdownSelection = (props) => <Dropdown selection {...props} />

const TierField = (props) => {

  const prefix = props.prefix || "rewrite_tier"
  const fieldHeader = props.fieldHeader || "Bucket"

  const FIELD = `${prefix}_${props.index}`;
  const { isBound, onFieldChange, t1, t2, index } = props;
  const hast1 = t1 !== undefined && index == 1;
  const hast2 = t2 !== undefined && index == 2;
  const t1Val = hast1 ? t1 : undefined;
  const t2Val = hast2 ? t2 : undefined;
  const InputComponent = props.as ? props.as : Styles.SourceInput

  return (
    <Styles.Field>
      <Styles.SourceLabel>{fieldHeader} {index}</Styles.SourceLabel>
      <InputComponent required={index == 1 || index == 2} boundValue={isBound(FIELD)} value={t1Val || t2Val || props[FIELD]} onChange={onFieldChange(FIELD)} disabled={hast1 || hast2} options={props.options} />
    </Styles.Field>
  )
}

const SECTION = {
  define: {
    content: "1. Define Event Key",
    subheader: "Build your event key using variables extracted from the querystring.",
    fieldHeader: "Bucket",
    prefix: "rewrite_tier",
    incrementField: "num_mta_key_fields"
  },
  choose: {
    content: "2. Setup Transform Table",
    subheader: "This is the table that will be used to specify your events values",
    fieldHeader: "Bucket",
    prefix: "transform_key",
    incrementField: "num_transform_key_fields",
    as: DropdownSelection
  },
  specify: {
    content: "3. Specify Event",
    subheader: "Using the columns from the transform table, constants and expressions, specify how you want to classify these events.",
    fieldHeader: "Bucket",
    prefix: "transform_tier",
    as: DropdownSelection
  }
}

const HeaderFieldSection = (props) => {
  const { showSelectors, children, after, grey} = props;
  const { tierRowProps, options, setupProps, onFieldChange } = props;
  const { content, subheader, incrementField } = setupProps
  const { as, fieldHeader, prefix } = setupProps

  const numFields = props[incrementField] || (options && options.length + 1) 
  const showButton = numFields < 5
  const onClick = () => onFieldChange(incrementField)(false, {"value":numFields+1}) 

  return <Styles.Flex grey={grey}>
    <Header as="h4" {...{content, subheader}} style={{marginTop:25,marginBottom:10}} />
      { children }
      { showSelectors && INDICES.slice(0,numFields).map(i => <TierField {...{as, prefix, options, fieldHeader}} {...props} {...tierRowProps} index={i} />) }
      <div>
        { showButton && <Styles.ControlButton {...{onClick, as: "a"}} style={{width:"45px",float:"right",marginRight:".75em"}} >+</Styles.ControlButton> }
      </div>
      { after }
  </Styles.Flex>
}


const TierRenameSection = (props) => {

  const { name, id, artifacts, onFieldChange, isBoundValue, t1, t2, event_type } = props
  const { transform_key, mta_key, transform_table_id, transformColumns } = props
  const { transform } = props
  const copy = artifacts.attributable_source_copy;
  const tip = artifacts.attributable_source_tip;
  const tierRowProps = { isBound: isBoundValue, copy, onFieldChange }

  const showEventDetails = transform_table_id && !props.transforms.find(row => row.id == transform_table_id).platform


  return (
  <React.Fragment>
    <Styles.Field>
      <Styles.SourceLabel>Event Type</Styles.SourceLabel>
      <Dropdown options={EVENT_TYPE_OPTIONS} selection value={event_type} onChange={onFieldChange("event_type")} />
    </Styles.Field>
    { event_type == "rename" && 
      <React.Fragment>
        <Header as="h4" content={BUCKETS_HEADER} subheader={BUCKETS_HEADER_TEXT} style={{marginTop:25,marginBottom:10}} />
        <Container fill> { INDICES.map(i => <TierField {...props} {...tierRowProps} index={i} />) } </Container>
        <Container align="center">
          <Styles.ProTip><Styles.LightBulb name='lightbulb-o'/>{tip}</Styles.ProTip>
        </Container>
      </React.Fragment>
    }
    { event_type == "transform" && 
      <Container fill> 
        <HeaderFieldSection 
          showSelectors {...props} {...{ tierRowProps, setupProps: SECTION["define"]}} 
          after={
            <Accordion 
              panels={[ {
                key: "Advanced", 
                title: "Advanced Key Setup", 
                content: {
                  content: <Styles.SourceInput value={mta_key} onChange={onFieldChange("mta_key")} />
                }
              }]}
            />
          } 
        />
        <HeaderFieldSection setupProps={SECTION["choose"]} {...props} 
          {...{ tierRowProps, showSelectors: transform_table_id, grey: true, options: transformColumns }} 
          after={
            <Accordion 
              panels={[ {
                key: "Advanced", 
                title: "Advanced Key Setup", 
                content: {
                  content: <Styles.SourceInput value={transform_key} onChange={onFieldChange("transform_key")} />
                }
              }]}
            />
          }
          >
          <Styles.Field>
            <Styles.SourceLabel>Choose table</Styles.SourceLabel>
            <Dropdown options={props.transforms} defaultValue={transform_table_id} onChange={onFieldChange("transform_table_id")} selection />
          </Styles.Field>
        </HeaderFieldSection>
        { 
          showEventDetails ? 
            <HeaderFieldSection showSelectors {...props} {...{ tierRowProps, setupProps: SECTION["specify"], options: transformColumns}} />: 
            <Styles.Flex /> 
        }
      </Container>
    }
    { event_type == "transform" && 
      <Container fill> 
        <Styles.Flex flex={2}>
          <DebugTransformTableJoin {... {name, event_id: id, transform_table_id, mta_key, transform_key}} />
        </Styles.Flex>
        <Styles.Flex />
      </Container> 
    }

  </React.Fragment>
  )
}

export default TierRenameSection;

import React from 'react';
import * as Styles from './FormBodyStyles';
import Container from '../parts/Container';
import EventRow from './EventRow';
import { Header } from 'semantic-ui-react';

const RULES_HEADER = "Rules"
const RULES_HEADER_TEXT = "Define the rules, using properties of URLs collected by the Rockerbox Pixel, to set which events should be captured."

const RuleSection = (props) => {

  const { onLogicChange, addLogic, removeLogic } = props;
  const { segmentFields, name, group } = props;

  return (
    <React.Fragment>
      <Header as="h4" content={RULES_HEADER} subheader={RULES_HEADER_TEXT} style={{marginBottom: 0}} />
      { group && group.map((innerGroup,i) =>
        <Container column fill style={{position: 'relative'}}>
          <Styles.Connector column fill width="1" />
          {
            innerGroup.logic.map((logic,j) => {
              const operator = j > 0 ? 'Or' : i > 0 ? 'And' : 'Where';
              return <EventRow
                exemplar={j === 0 && i === 0}
                isSub={j > 0}
                operator={operator}
                count={innerGroup.logic.length - 1}
                index={j}
                logic={logic}
                {...props}
                segmentFields={segmentFields}
                onPatternChange={onLogicChange(i)(j,"value")}
                onCustomChange={onLogicChange(i)(j,"field")}
                onParamChange={onLogicChange(i)(j,"field")}
                addLogic={() => addLogic(i)}
                removeLogic={() => removeLogic(i, j)} />
            })
          }
        </Container>
      )
      }
    </React.Fragment>
  )
}

export default RuleSection;

import React from 'react';
import { Input, Menu } from 'semantic-ui-react';
import TransformTableRaw from './TransformTableRaw';
import TransformTableTest from './TransformTableTest';
import TransformTableCurrent from './TransformTableCurrent';

const NAMES = {
  raw: "Raw Dataset",
  transformed: "Transformed Dataset (from saved settings)"
}

const TransformTableResults = ({ transformTable }) => {

  const transformTableId = transformTable.id
  const [searchValue, setSearchValue] = React.useState("");
  const [showType, setShowType] = React.useState(!transformTable.id ? "raw" : "current");

  const onChange = (_, evt) => setSearchValue(evt.value)

  return (
    <React.Fragment>
      <div>
        <Input icon='search'  type="text" placeholder="Find a record" value={searchValue} {...{onChange}} />
        <br />
      </div>
      <div style={{display:"flex",alignContent:"stretch"}}>
        { transformTable.acetl_report_id &&
          <div style={{flex:1, maxWidth:"50%", overflowX:"scroll"}}>
            <Menu pointing secondary>
              <Menu.Item name={NAMES.raw} active={showType === 'raw'} onClick={() => setShowType("raw")} />
              { transformTableId && 
                <Menu.Item name={NAMES.transformed} active={showType === 'current'} onClick={() => setShowType("current")} />
              }
            </Menu>
            { showType == "current" && <TransformTableCurrent {...{transformTableId, searchValue}} /> }
            { showType == "raw" && <TransformTableRaw {...{ transformTable, searchValue}} /> }
          </div>
        }
        <div style={{flex:1, maxWidth:"50%"}}>
          <Menu pointing secondary>
            <Menu.Item name='Preview Changes' active />
          </Menu>
          <TransformTableTest {...{transformTable, searchValue}} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default TransformTableResults;

import React, { useState, useEffect, useMemo } from 'react';
import shallow from 'zustand/shallow';
import _ from 'lodash';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { Segment, Form, Message, Button } from 'semantic-ui-react';
import { ColumnLayout, ContentCard, DataTable } from '@rockerbox/styleguide';
import { getConversionDataset } from '../../../api/attributionCache';
import { postEntityData, updateEntityData } from '../../../api/attribution';
import { entityStore } from '../hooks/entityStore';
import FindPromoCode from './FindPromoCode';
import { TIERS } from '../../../constants/tiers';

/* eslint-disable no-shadow, no-param-reassign */

const cleanNumberField = (key, row) => (
  !['fixed_discount', 'percent_discount', 'fixed_unit_cost', 'rev_share_percent'].includes(key) || !row[key]
    ? row[key]
    : String(row[key]).replace('$', '').replace('%', '').replace(',', '')
);

const DEFAULT_CREATE = {
  display: [{}],
  segments: [{ segment_id: '', promo_field: '' }],
  promo_codes: [],
};

const PROMO_FIELDS = ['conversion_field_value', 'entity_type', 'fixed_discount', 'percent_discount', 'fixed_unit_cost', 'rev_share_percent'];

export const tierMacroColumn = ({ cell, row }) => {
  const { value } = cell;

  if (value === '{conversion_field_value}') {
    if (!!row.conversion_field_value) return row.conversion_field_value;
    return '{ Promo Code }';
  }

  if (value === '{name}') {
    if (!!row.name) return row.name;
    return '{ Name }';
  }

  return value;
};

const states = [
  {
    key: 'Approved',
    content: 'This will create a new entity and promo-code',
    icon: 'check circle',
    color: 'green',
  },
  {
    key: 'Same Name',
    content: 'This will add a new promo code to the existing entity',
    icon: 'warning circle',
    color: 'green',
  },
  {
    key: 'Same Code',
    content: 'The promo code is already associated with another entity',
    icon: 'warning sign',
    color: 'orange',
  },
  {
    key: 'Missing Code',
    content: 'You must enter a promo code to create a new entry',
    icon: 'warning sign',
    color: 'red',
  },
  {
    key: 'Missing Name',
    content: 'You must enter a name to create a new entry',
    icon: 'warning sign',
    color: 'red',
  },
];

export const COLUMNS = [
  {
    style: { width: '1.9em' },
    display: '',
    key: 'state',
    type: 'text',
    asEdit: ({ value }) => value,
    asView: DataTable.StatusCell({ states }),
  },
  { display: 'Name', key: 'name', type: 'text' },
  { display: 'Promo Code', key: 'conversion_field_value', type: 'text' },
  { display: 'Type', key: 'entity_type', type: 'text' },
  { display: 'Tier 1', key: 'tier_1', type: 'text', asView: tierMacroColumn },
  { display: 'Tier 2', key: 'tier_2', type: 'text', asView: tierMacroColumn },
  { display: 'Tier 3', key: 'tier_3', type: 'text', asView: tierMacroColumn },
  { display: 'Tier 4', key: 'tier_4', type: 'text', asView: tierMacroColumn },
  { display: 'Tier 5', key: 'tier_5', type: 'text', asView: tierMacroColumn },
  { display: 'Discount ($)', key: 'fixed_discount', type: 'currency' },
  { display: 'Discount (%)', key: 'percent_discount', type: 'percent' },
  { display: 'Fixed Payout ($)', key: 'fixed_unit_cost', type: 'currency' },
  { display: 'Rev Share (%)', key: 'rev_share_percent', type: 'percent' },
];

const READ_ONLY_COST = ['fixed_unit_cost', 'rev_share_percent'];
const SKIP_PROMO_COST = ['fixed_discount', 'percent_discount', 'fixed_unit_cost', 'rev_share_percent'];

const makeReadOnly = readOnlyColumns => row => {
  const readOnly = readOnlyColumns.includes(row.key);
  return { readOnly, ...row };
};

const CreateViaPromo = ({ program, entityConfigs, entityData, onReset }) => {
  const { entity_type } = program;
  const { tier_1, tier_2, tier_3, tier_4, tier_5, fixed_unit_cost, rev_share_percent } = program || {};
  const [data, setData] = useState([]);
  const [segment_id, setSegment] = useState(false);
  const [conversionData, setConversionData] = useState(false);
  const [totalCount, setTotalCount] = useState(false);
  const [currentCount, setCurrentCount] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [promo_field, setPromoField] = useState('coupon');
  const [loading, setLoading] = useState(undefined);
  const [fieldOptions, setFieldOptions] = useState([]);

  const [featuredSegmentId, segmentOptions, getSegments] = entityStore(
    ({ featuredSegmentId, segmentOptions, getSegments }) => [featuredSegmentId, segmentOptions, getSegments],
    shallow,
  );

  const hasDisplay = !!(tier_1 || tier_2 || tier_3 || tier_4 || tier_5);
  const hasCosts = !!(fixed_unit_cost || rev_share_percent);

  const _columns = useMemo(() => {
    const options = entityData.map(({ name }) => ({ name, text: name, value: name }));
    const columns = COLUMNS;
    const entityColumn = columns.find(row => row.key === 'name');
    entityColumn.asEdit = DataTable.SearchCell(options);

    return columns;
  }, [entityData]);

  useEffect(() => {
    getSegments();
  }, []);
  useEffect(() => {
    const hasEntityData = !!entityData && entityData.length > 0;
    const _segmentId = hasEntityData ? entityData[0].segments[0].segment_id : featuredSegmentId || null;

    if (!segment_id && _segmentId) setSegment(_segmentId);
  }, [featuredSegmentId]);
  useEffect(() => {
    if (loading === false) onReset();
  }, [loading]);

  useEffect(() => {
    if (segment_id) {
      getConversionDataset(segment_id)
        .then(response => response.response.conversion_data)
        .then(convData => {
          setConversionData(convData);
          const fields = Object.keys(convData.slice(0, 10).reduce((p, c) => Object.assign(p, c), {}))
            .filter(
              key => ![
                '1',
                'source_name',
                'ip',
                'request_referrer',
                'rb_source',
                'event_id',
                'hash_ip',
                'raw_message_id',
                'timestamp',
                'date',
                'landing_url',
                'user_agent',
                'conversion_hash_id',
                'origin',
                'url',
              ].includes(key),
            )
            .map(value => ({ text: value, value }));

          setFieldOptions(fields);
        });
    }
  }, [segment_id]);

  const { existingCode, existingName } = useMemo(() => {
    const existingCodeMapped = entityData.reduce((accu, c) => {
      c.promo_codes.forEach(code => {
        accu[code.conversion_field_value] = c;
      });
      return accu;
    }, {});
    const existingNameMapped = d3
      .nest()
      .key(row => row.name)
      .map(entityData);
    return {
      existingCode: existingCodeMapped,
      existingName: existingNameMapped,
    };
  }, [entityData]);

  useEffect(() => {
    d3.nest()
      .key(row => row.name)
      .key(row => row.conversion_field_value)
      .map(data);
  }, [data]);

  const onUpdate = d => {
    d.forEach(row => {
      const sameName = !!existingName[row.name];
      const sameCode = !!existingCode[row.conversion_field_value];
      row.state = sameCode
        ? 'Same Code' : sameName
          ? 'Same Name' : !row.conversion_field_value
            ? 'Missing Code' : !row.name
              ? 'Missing Name' : 'Approved';
      Object.assign(row, {
        tier_1, tier_2, tier_3, tier_4, tier_5,
        fixed_unit_cost, rev_share_percent, entity_type,
      });
    });
    setData(d);
  };
  const onRemove = row => setData(data.filter(r => r !== row));
  const addRow = (row = {}) => setData(data.concat([row]));

  const onAdd = (evt, { value }) => {
    const { promoCode } = value;
    const conversion_field_value = promoCode;

    setShowSearch(false);

    const newData = data.concat([{
      conversion_field_value,
      tier_1, tier_2, tier_3, tier_4, tier_5,
      fixed_unit_cost, rev_share_percent, entity_type,
    }]);

    setData(newData);
  };

  const onSave = () => {
    setLoading(true);

    const newEntities = data.filter(row => row.state === 'Approved');

    const newToPost = newEntities.reduce((accu, c) => {
      accu[c.name] = accu[c.name] || _.cloneDeep(DEFAULT_CREATE);

      const asCode = PROMO_FIELDS.reduce((accu1, key) => {
        accu1[key] = cleanNumberField(key, c);
        return accu1;
      }, { conversion_field_name: promo_field, name: c.conversion_field_value });

      accu[c.name].promo_codes.push(asCode);
      accu[c.name].name = c.name;
      accu[c.name].entity_type = c.entity_type;

      const display = accu[c.name].display[0];
      const segments = accu[c.name].segments[0];

      segments.segment_id = segment_id;
      segments.promo_field = promo_field;

      ['tier_1', 'tier_2', 'tier_3', 'tier_4', 'tier_5'].forEach(tier => {
        display[tier] = c[tier] === c.name ? '{name}'
          : c[tier] === c.conversion_field_value ? '{conversion_field_value}' : c[tier];
      });

      return accu;
    }, {});

    const existingEntites = data.filter(row => row.state === 'Same Name');
    const toUpdate = existingEntites.reduce((accu, c) => {
      const { name } = c;
      accu[name] = accu[name] || _.cloneDeep(existingName[name])[0];

      const asCode = PROMO_FIELDS.reduce((accu1, key) => {
        accu1[key] = cleanNumberField(key, c);
        return accu1;
      }, { conversion_field_name: promo_field, name: c.conversion_field_value });

      accu[c.name].promo_codes.push(asCode);

      return accu;
    }, {});

    // TO HANDLE QUEUEING OF REQUESTS
    const requestsQueue = [];

    Object.values(newToPost).forEach(obj => {
      const { id } = obj;
      const send = id ? updateEntityData : postEntityData;
      requestsQueue.push({ send, obj, id });
    });
    Object.values(toUpdate).forEach(obj => {
      const { id } = obj;
      const send = id ? updateEntityData : postEntityData;
      requestsQueue.push({ send, obj, id });
    });

    setTotalCount(requestsQueue.length);

    const runFromQueue = () => {
      const item = requestsQueue.pop();
      if (requestsQueue.length % 10) setCurrentCount(requestsQueue.length);

      if (!item) {
        setLoading(false);
        onReset();
      }

      if (item) {
        const { send, obj, id } = item;
        send(obj, id).then(runFromQueue);
      }
    };

    requestsQueue.reverse();
    runFromQueue();
  };

  const removeErrors = () => {
    const valid = data.filter(row => row.state === 'Approved' || row.state === 'Same Name');
    setData(valid);
  };

  const toRemove = data.filter(row => row.state !== 'Approved' && row.state !== 'Same Name');

  const readOnlyColumns = program && hasDisplay && hasCosts
    ? [...TIERS, ...TIERS]
    : program && hasDisplay
      ? TIERS
      : program && hasCosts
        ? READ_ONLY_COST : [];

  const skipColumns = program && !hasCosts ? ['entity_type', ...SKIP_PROMO_COST] : [];

  const columns = program ? _columns.filter(row => !skipColumns.includes(row.key)).map(makeReadOnly(readOnlyColumns)) : _columns;

  return (
    <ContentCard title="Create Multiple Sponsorships via Promo Codes">
      <ColumnLayout
        leftWidth={12}
        rightWidth={4}
        leftContent={(
          <Form>
            <Form.Dropdown
              required
              onChange={(x, { value }) => setSegment(value)}
              as={Form.Dropdown}
              options={segmentOptions}
              value={segment_id}
              label="Segment"
              select
              selection
            />
            <Form.Dropdown
              value={promo_field}
              onChange={(x, { value }) => setPromoField(value)}
              options={fieldOptions}
              search
              label="Promo-code Field"
              loading={segment_id && fieldOptions.length === 0}
              select
              selection
            />
            <br />
          </Form>
        )}
        rightContent={(
          <Message
            header="Associate a segment"
            content="Since you are using 'promo-codes' or information collected at the time of conversion to attribute marketing, it is necessary to choose a conversion segment from which to capture the marketing information. To do this, we need to know the conversion segment as well as the field which contains the promo-code information." // eslint-disable-line
          />
        )}
      />
      <Segment {...{ loading }}>
        <DataTable
          {...{
            columns,
            data,
            onUpdate,
            onRemove,
          }}
        />
        <Button size="mini" icon="plus" onClick={addRow} />
        {!showSearch && (
          <div style={{ textAlign: 'center', marginTop: '15px' }}>
            <Button
              basic
              size="huge"
              content="Find Promo Code to associate to create a Sponsorship"
              icon="search"
              onClick={() => setShowSearch(true)}
            />
          </div>
        )}
        {showSearch && <FindPromoCode {...{ conversionData, promo_field, entityConfigs, entityData, onAdd }} />}
        {totalCount && (
          <>
            Saving
            {' '}
            {totalCount - currentCount}
            /
            {totalCount}
            ...
          </>
        )}
        <br />
        {toRemove.length > 0 && (
          <Message
            content={(
              <>
                The records below either have insufficent data or are already associated with other sponsorships. Either remove or correct the errors before submitting.
                <Button style={{ marginLeft: '5px' }} color="orange" compact size="tiny" onClick={removeErrors}>
                  Remove Errors
                </Button>
              </>
            )}
          />
        )}
        <br />
        {data.length > 0 && toRemove.length === 0 && (
          <Button
            disabled={loading || data.length === 0 || !segment_id}
            loading={loading}
            floated="right"
            color="green"
            onClick={onSave}
            submit
          >
            Add Entities & Promo Codes
          </Button>
        )}
        <br />
        <br />
      </Segment>
    </ContentCard>
  );
};

export default CreateViaPromo;

import React from 'react';
import { ContentCard } from '@rockerbox/styleguide';
import { Table, Button, Loader } from 'semantic-ui-react';
import CacheContext from '../../../utils/CacheContext';
import { ColorPicker, ViewAccessWrapper } from '../../components';
import { getFeaturedSegment } from '../../api/attribution';
import { getTiersAsOptions } from '../../api/attributionCache';
import { getTierColors, saveTierColor, deleteTierColor } from '../../api/account';
import { colorHash } from '../../utils/colorHash';
import { track } from '../../../utils/tracking';

const TierColorRow = ({ tier_1, color }) => {
  const [loading, setLoading] = React.useState(false);
  const [tierColor, setTierColor] = React.useState(color);
  const [saveTimeout, setSaveTimeout] = React.useState(null);

  const handleChangeColor = ({ hex }) => {
    setTierColor(hex);

    if (!!saveTimeout) window.clearTimeout(saveTimeout);

    // eslint-disable-next-line no-use-before-define
    const saveTimeoutId = window.setTimeout(() => saveChangedColor(tier_1, hex), 3000);
    setSaveTimeout(saveTimeoutId);
  };

  // eslint-disable-next-line no-shadow
  const saveChangedColor = (tier_1, color) => {
    setLoading(true);
    saveTierColor({ tier_1, color })
      .then(() => setLoading(false));
    track('settings.tier_color.edited', { tier_1, color });
  };

  const resetTierColor = () => {
    setLoading(true);
    setTierColor(undefined);
    if (!!saveTimeout) window.clearTimeout(saveTimeout);
    setSaveTimeout(null);
    deleteTierColor({ tier_1 })
      .then(() => setLoading(false));
    track('settings.tier_color.reset', { tier_1 });
  };

  return (
    <Table.Row>
      <Table.Cell>
        <ColorPicker
          color={tierColor || colorHash(tier_1)}
          onChange={handleChangeColor}
        />
      </Table.Cell>
      <Table.Cell>
        <strong>
          {tier_1}
        </strong>
        {loading && (
        <>
          &nbsp;&nbsp;&nbsp;
          <Loader active inline size="tiny" />
        </>
        )}
      </Table.Cell>
      <Table.Cell>
        <Button
          icon="undo"
          disabled={!tierColor}
          onClick={resetTierColor}
        />
      </Table.Cell>
    </Table.Row>
  );
};

const TierColors = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState, Context] = React.useContext(CacheContext);
  const featuredSegment = (Context.getCache('featuredSegment', getFeaturedSegment) || false);
  const tiersOptions = featuredSegment ? (Context.getCache(`tiers${featuredSegment}`, getTiersAsOptions, featuredSegment) || false) : false;
  const sortedTiers = tiersOptions ? tiersOptions.map(({ key }) => key).sort() : false;

  const [tierColors, setTierColors] = React.useState(false);

  React.useEffect(() => {
    getTierColors()
      // eslint-disable-next-line no-shadow
      .then(tierColors => setTierColors(tierColors));
  }, []);

  const loading = !sortedTiers || !tierColors;

  return (
    <ContentCard
      hasTable
      title="Marketing Channel Colors"
      explainer="Customize the colors used to display your marketing channels in the Rockerbox Dashboard"
    >
      {loading && <ContentCard.CardLoader />}
      {!loading
        && (
        <Table basic="very" celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell collapsing>Color</Table.HeaderCell>
              <Table.HeaderCell>Tier 1</Table.HeaderCell>
              <Table.HeaderCell collapsing>Reset to Default</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sortedTiers.map(tier_1 => (
              <TierColorRow
                tier_1={tier_1}
                color={tierColors[tier_1]}
              />
            ))}
          </Table.Body>
        </Table>
        )}
    </ContentCard>
  );
};

const TierColorsWrapper = () => (
  <ViewAccessWrapper
    viewName="settings_colors"
    viewComponent={<TierColors />}
  />
);

export default TierColorsWrapper;

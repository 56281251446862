import React from 'react';
import { ContentCard, IndexGridNew } from '@rockerbox/styleguide';
import { Button } from 'semantic-ui-react';
import { DatasetDocumentationCell, DatasetLastSyncedCell } from '../ReportSync/ReportSyncPlatformTable';
import { useDatalake } from '../DatalakeContext/hooks';
import { ROCKERBOX_REPORT_TYPE } from '../constants';

const RockerboxDataTable = ({ showLastSyncd, loading, emptyGridMessage, callbackFn }) => {
  const { selectDatasetsByReportType } = useDatalake();
  const rockerboxDatasets = selectDatasetsByReportType(ROCKERBOX_REPORT_TYPE);
  const filteredRockerboxDatasets = rockerboxDatasets.filter(t => t.conversionName);

  const tableColumns = [
    { display: 'Conversion Name', key: 'conversionName' },
    { display: 'Conversion ID', key: 'identifier' },
    { display: 'Dataset', key: '', as: filteredRockerboxDatasets && DatasetDocumentationCell(filteredRockerboxDatasets) },
    { display: 'Table Name', key: 'table_name' },
  ];

  if (!!showLastSyncd) { tableColumns.push({ display: 'Last Synced', key: 'last_sync', as: filteredRockerboxDatasets && DatasetLastSyncedCell(filteredRockerboxDatasets) }); }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!loading
        ? (
          <IndexGridNew
            title="Rockerbox Data"
            topRight={(
              <Button
                inverted
                color="purple"
                onClick={() => callbackFn()}
                disabled={loading}
                loading={loading}
                content="Add a conversion share"
                className="remove-box-shadow"
              />
          )}
            celled
            data={filteredRockerboxDatasets}
            cols={tableColumns}
            placeholder="Rockerbox Datasets"
            fallBackMsg={emptyGridMessage || 'No Matching Datasets Found'}
          />
        )
        : <ContentCard.CardLoader />}
    </>
  );
};

export default RockerboxDataTable;

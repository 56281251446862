import moment from 'moment';
import * as d3 from 'rockerbox_d3_legacy_clone';

export default function cdfChart(w) {
  var margin = {
    top: 60, // top margin includes title and legend
    right: 20, // right margin should provide space for last horz. axis title
    bottom: 30,
    left: 10 // left margin should provide space for y axis titles
  };

  var _w = w || 840
  // height & width
  var width = (_w - margin.left - margin.right);
  var height = (_w/2 - margin.top - margin.bottom);
  var legendWidth = _w -240;
  var legendHeight = 300;

  // cosmetics
  var color = "steelblue";
  var title = '';

  // global div for tooltip


  var formatCount = d3.format(",.0f");

  function chart(selection) {
    selection.each(function drawGraph(touchpoints) {

      const values = touchpoints

      var max = d3.max(values, x => x.key);
      var min = 0;
      var x = d3.scale.linear()
        .domain([min, max])
        .range([margin.left, margin.left + width - margin.right]);


      var ymax = d3.max(values, x => x.total);
      var ymin = 0;
      var y = d3.scale.sqrt()
        .domain([ymin, ymax])
        .range([height, margin.top]);

      var tpmax = d3.max(values, x => x.value/x.total);
      var tpmin = 0;
      var tp = d3.scale.sqrt()
        .domain([tpmin, tpmax])
        .range([.4, 1]);

      var d3this = d3.select(this);

      var w = width;
      var h = height;
      
      var dataset = values
      
      var svg = d3this
      	.append("svg")
      	.attr("width", width + margin.left + margin.right - 1)
      	.attr("height", height + margin.top + margin.bottom);

      var barWidth = width/values.length/2

      svg.append("g")
         .selectAll("circle")
         .data(dataset)
         .enter()
         .append("circle")
         .attr("cx", function(d) { return x(d.key); })
         .attr("cy", function(d) { return y(d.total); })
         .attr("r", function(d) { return barWidth})
         .attr("fill", function(d) { return evalThreshold(d.key, threshold) ? color : "grey"})
         .attr("opacity", function(d) { return  tp(d.value/d.total); })
         .on("mouseover", function(d) { console.log(d) })


      svg.append("text")
        .attr("x", width / 2)
        .attr("y", margin.top-30)
        .attr("text-anchor", "middle")
        .text(title)

      var xAxis = d3.svg.axis()
          .scale(x)
          .orient("bottom");

      var X = svg.append("g")
          .attr("class", "x axis")
          .attr("transform", "translate(0," + height + ")")
          .call(xAxis);

      //svg.append("text")
      //  .attr("x", width / 2)
      //  .attr("y", height)
      //  .attr("text-anchor", "middle")
      //  .text("Non Converters")


    });
  }

  chart.width = function(_) {
    if (!arguments.length) return width;
    width = _;
    return chart;
  };

  chart.height = function(_) {
    if (!arguments.length) return height;
    height = _;
    return chart;
  };

  chart.title = function(_) {
    if (!arguments.length) return title;
    title = _;
    return chart;
  };

  var radius = 5;
  chart.radius = function(_) {
    if (!arguments.length) return radius;
    radius = _;
    return chart;
  };

  chart.color = function(_) {
    if (!arguments.length) return color;
    color = _;
    return chart;
  };

  var evalThreshold = function(x,y) { return x > y };
  chart.evalThreshold = function(_) {
    if (!arguments.length) return evalThreshold;
    evalThreshold = _;
    return chart;
  };

  var threshold = 0.5;
  chart.threshold = function(_) {
    if (!arguments.length) return threshold;
    threshold = _;
    return chart;
  };

  return chart;
}

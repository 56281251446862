export const createPresignedURL = (method, bucket, filename, contentType) => {
  const url = '/aws/s3/presign/';
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': contentType,
    },
    body: JSON.stringify({ filename, bucket, method }),
  })
    .then(response => response.json());
};

export const uploadUsingPresignedURL = (url, data, contentType) => (
  fetch(url, {
    method: 'PUT',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Referrer-Policy': 'no-referrer',
      'Content-Type': contentType,
    },
    body: data,
  })
    .then(response => console.log(response))
);

export const submitMMMConfigureAndBuildJob = advertiser => {
  const url = '/aws/batch/submit';
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: JSON.stringify({ script_name: 'configure-and-submit-mmm-build', advertiser }),
  })
    .then(response => response.json());
};

export const submitAwsBatch = postObj => {
  const url = '/aws/batch/submit';
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postObj),
  })
    .then(response => response.json());
};

export const getBatchStatus = batchJobId => {
  const url = `/aws/batch/check_status/${batchJobId}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(data => data.response)
    .catch(error => console.error(error.message)); // eslint-disable-line no-console
};

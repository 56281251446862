// also many functions are duplicated in styleguide/components/charts/hooks/sortData.js
import moment from 'moment';

const skip = ['date', 'target', 'absoluteAdjustedDiff', 'formattedValue', 'backgroundColor', 'onTarget', 'delta', 'colorStyles'];

const findAllUniqueKeys = data => {
  if (!data) return [];
  const dataMapped = data
    .flatMap(x => Object.keys(x))
    .filter(x => !skip.includes(x));
  const uniqueMapped = [...new Set(dataMapped)];
  return uniqueMapped;
};

const findMonths = data => {
  const dataMapped = data.map(({ date }) => moment(date).month());
  return [...new Set(dataMapped)];
};

const aggregateData = (accu, item, chunkIndex, keys) => {
  if (!accu[chunkIndex]) {
    accu[chunkIndex] = { date: item.date, target: item.target };
  }
  keys.forEach(key => {
    const itemValue = item[key];
    if (!accu[chunkIndex][key]) {
      accu[chunkIndex][key] = itemValue;
    } else {
      accu[chunkIndex][key] += itemValue;
    }
  });
  return accu;
};

const divideData = (numeratorData, denominatorData) => {
  const divided = [];
  numeratorData.forEach((numerator, i) => {
    const keys = findAllUniqueKeys([numerator]);
    const dividedObj = { date: numerator.date };
    keys.forEach(key => {
      if (numerator[key] === undefined || denominatorData[i][key] === 0) {
        dividedObj[key] = 0;
      } else {
        dividedObj[key] = numerator[key] / denominatorData[i][key];
      }
    });
    divided.push(dividedObj);
  });
  return divided;
};

const groupDataWeekly = (data, keys) => {
  const perChunk = 7;

  const result = data.reduce((acc, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);
    return aggregateData(acc, item, chunkIndex, keys);
  }, []);

  return result;
};

const groupDataMonthly = (data, keys) => {
  const months = findMonths(data);

  const result = data.reduce((acc, item) => {
    const chunkIndex = months.findIndex(month => month === moment(item.date).month());
    return aggregateData(acc, item, chunkIndex, keys);
  }, []);

  return result;
};

export const groupChartData = (data, groupDatesBy, specificKeys = []) => {
  const uniqueKeys = specificKeys.length === 0 ? findAllUniqueKeys(data) : specificKeys;
  if (groupDatesBy === 'weekly') {
    return groupDataWeekly(data, uniqueKeys);
  }
  if (groupDatesBy === 'monthly') {
    return groupDataMonthly(data, uniqueKeys);
  }
};

export const groupDivideChartData = (numeratorData, denominatorData, groupDatesBy) => {
  const numeratorKeys = findAllUniqueKeys(numeratorData);
  const denominatorKeys = findAllUniqueKeys(denominatorData);
  if (groupDatesBy === 'weekly') {
    const numeratorDataGrouped = groupDataWeekly(numeratorData, numeratorKeys);
    const denominatorDataGrouped = groupDataWeekly(denominatorData, denominatorKeys);
    const dividedData = divideData(numeratorDataGrouped, denominatorDataGrouped);
    return dividedData;
  }
  if (groupDatesBy === 'monthly') {
    const numeratorDataGrouped = groupDataMonthly(numeratorData, numeratorKeys);
    const denominatorDataGrouped = groupDataMonthly(denominatorData, denominatorKeys);
    const dividedData = divideData(numeratorDataGrouped, denominatorDataGrouped);
    return dividedData;
  }
};

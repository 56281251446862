import React from "react";
import { ContentCard } from "@rockerbox/styleguide";
import { Message } from "semantic-ui-react";

const ErrorPage = props => (
  <ContentCard hasTable title="OTT" explainer="" topRight={null}>
    <Message
      info
      header="We couldn't find your data."
      content="Your OTT data is currently unavailable or hasn't been setup yet. Please contact Rockerbox service to resolve this issue."
    />
  </ContentCard>
);

export default ErrorPage;

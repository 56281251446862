import React, { Component } from "react";
import { optionConfig } from "./DeliveryOptionIndex";
import { DropdownGroup } from "./DropdownMenu";
import { Menu, Icon } from "semantic-ui-react";
import NewOutboxModal from "./NewOutboxModal";

export default class DeliveryOptionMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      outbox_type: undefined,
      loading: false,
      error: false
    };
    this.showModal = this.showModal.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
  }

  onModalClose() {
    return this.setState({
      showModal: false,
      outbox_type: undefined,
      loading: false,
      error: false
    });
  }

  showModal(type) {
    return this.setState({
      showModal: true,
      outbox_type: type,
      loading: false,
      error: false
    });
  }

  onLoadingChange(flag) {
    this.setState({ loading: flag });
  }

  onErrorChange(flag) {
    this.setState({ error: flag });
  }

  OptionItems() {
    const {
      category,
      optionType,
      outboxes,
      handleTypeMenuHeaderClick
    } = this.props;

    const groupOutboxByKey = outboxes.reduce((prev, current) => {
      if (prev[current.outbox_type]) {
        prev[current.outbox_type].push(current);
      } else {
        prev[current.outbox_type] = [current];
      }
      return prev;
    }, {});

    const options = Object.keys(groupOutboxByKey).filter(
      type => type !== "do_spaces"
    );
    options.unshift("do_spaces");

    return options.map(type => (
      <Menu.Item
        active={optionType === type}
        onClick={() => handleTypeMenuHeaderClick(type)}
      >
        <Menu.Header>
          {optionConfig[type]["name"]}
          {optionType === type && (
            <Icon name="chevron right" style={{ float: "right", margin: 0 }} />
          )}
        </Menu.Header>
      </Menu.Item>
    ));
  }

  render() {
    const { showModal, outbox_type, loading, error } = this.state;
    const { setOutboxes } = this.props;
    return (
      <React.Fragment>
        <Menu vertical fluid>
          {this.OptionItems()}
        </Menu>
        <DropdownGroup showModal={this.showModal} />
        <NewOutboxModal
          close={this.onModalClose}
          open={showModal}
          loading={loading}
          error={error}
          onErrorChange={this.onErrorChange.bind(this)}
          onLoadingChange={this.onLoadingChange.bind(this)}
          title={
            optionConfig[outbox_type] && optionConfig[outbox_type]["title"]
          }
          fields={
            optionConfig[outbox_type] && optionConfig[outbox_type]["fields"]
          }
          outbox_type={outbox_type}
          setOutboxes={setOutboxes}
        />
      </React.Fragment>
    );
  }
}

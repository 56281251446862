import React, { Component } from 'react';
import { SplitLayout, ContentCard, IndexGrid } from '@rockerbox/styleguide';
import { Message, Button, Table, Popup, Label, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import ButterToast from 'butter-toast';
import _ from 'lodash';
import { ToastStyles, CloseButtonStyles } from '../Toast';
import * as routes from '../../routes';
import UrlButton from '../parts/UrlButton';
import { getConversionSegments, updateConversion, deleteSegment } from '../../utils/api';
import * as baseRoutes from '../../V3/baseRoutes';

const LinkTo = ({ item: { action_id, action_name } }) => (
  <Table.Cell width={6}>
    <Popup
      inverted
      content="View Report"
      position="right center"
      size="small"
      trigger={(
        <Link
          to={`${baseRoutes.crossChannelReport}/${action_id}`}
        >
          {action_name}
        </Link>
      )}
    />
  </Table.Cell>
);

const ConversionKey = ({ item }) => (
  <Table.Cell>
    {item.conversion_key_name === 0 ? <Label>Default</Label> : item.conversion_key_name}
  </Table.Cell>
);

const Featured = ({ item, setFeaturedSegment }) => {
  if (item.featured === 1) {
    return (
      <Table.Cell style={{ width: 25 }}>
        <Popup trigger={<Icon name="star" color="yellow" />} content="featured segment" />
      </Table.Cell>
    );
  }
  return (
    <Table.Cell style={{ width: 25, cursor: 'pointer' }}>
      <Icon name="star outline" color="grey" onClick={() => setFeaturedSegment(item)} />
    </Table.Cell>
  );
};

export const defaultCols = [
  { display: 'Name', key: 'action_name', as: LinkTo },
  { display: 'Conversion Key', key: 'conversion_key_name', as: ConversionKey },
];

export default class ConversionEvents extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    segments: [],
  };

  componentDidMount() {
    this.getSegments();
  }

  getSegments = () => {
    getConversionSegments()
      .then(data => {
        const conversions = data
          .filter(segment => segment.action_type === 'conversion')
          .filter(segment => !segment.is_child);

        this.setState({ segments: conversions });
      });
  };

  deleteEvent = id => deleteSegment(id).then(() => {
    this.getSegments();

    ButterToast.raise({
      dismissOnClick: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      content: () => (
        <ToastStyles>
          <CloseButtonStyles>x</CloseButtonStyles>
          Your conversion event was successfully deleted.  So long!
        </ToastStyles>
      ),
      toastTimeout: 5000,
    });
  });

  setFeaturedSegment = segment => {
    const featuredSegment = _.cloneDeep(segment);
    featuredSegment.featured = 1;
    // eslint-disable-next-line react/destructuring-assignment
    featuredSegment.advertiser = this.props.advertiser;

    updateConversion(featuredSegment)
      .then(() => {
        this.getSegments();
      });
  };

  render() {
    const { permissions } = this.props;
    const { segments } = this.state;

    // eslint-disable-next-line react/no-unstable-nested-components
    const ManageCell = ({ item }) => (
      <Table.Cell collapsing disabled={permissions.readOnly}>
        <IndexGrid.EditButton url={`${routes.editConversionEvent}/${item.action_id}`} />
        <Popup
          inverted
          content="View Conversion Event"
          trigger={
            <UrlButton url={`${routes.viewConversionEvent}/${item.action_id}`} icon="eye" />
            }
        />
        <IndexGrid.DeleteButton onDelete={() => {
          // eslint-disable-next-line react/no-this-in-sfc
          this.deleteEvent(item.action_id);
        }}
        />
      </Table.Cell>
    );

    const attributableCols = [
      // eslint-disable-next-line react/no-unstable-nested-components, react/jsx-props-no-spreading
      { display: '', as: props => (<Featured setFeaturedSegment={this.setFeaturedSegment} {...props} />) },
      ...defaultCols,
      { display: 'Manage', key: 'manage', as: ManageCell },
    ];

    return (
      <SplitLayout
        leftWidth={13}
        rightWidth={3}
        leftContent={(
          <ContentCard
            title="Conversion Events"
            hasTable
            // eslint-disable-next-line max-len
            explainer="A Conversion Event identifies when your customer takes an important action. You can specify one (e.g. Purchased Product) or many (e.g. Request Demo, Created Account, Signed Contract)."
            topRight={(
              <>
                <Button
                  primary
                  compact
                  size="mini"
                  icon="plus"
                  content="New Conversion"
                  as={Link}
                  to={`${routes.createConversionEvent}`}
                  disabled={permissions.readOnly}
                />
                <Button
                  primary
                  compact
                  size="mini"
                  icon="database"
                  content="Manage Data"
                  as={Link}
                  to={`${routes.conversionEventBackfill}`}
                  disabled={permissions.readOnly}
                />
              </>
              )}
          >

            { !!segments.length ? <IndexGrid data={segments} cols={attributableCols} as={ContentCard.Table} fallbackMsg="No conversions" /> : <ContentCard.CardLoader /> }
          </ContentCard>
          )}
        rightContent={(
          <Message
            header="About Conversion Segments"
            content={(
              <>
                <p>A conversion segment is a specific action associated with your users that occurs on your property. It is an action against marketing activity will be evaluated.</p>
                <p>
                  A conversion segment is constructed by explicitly passing a piece of tracking information to Rockerbox via an onsite pixel,
                  server-side webhook or batch file that contains information about conversions.
                </p>
                <p>Examples of conversion events include a customer adding a product to their cart, making a purchase or completing a post purchase survey.</p>
              </>
)}
          />
          )}
      />
    );
  }
}

// ConversionEvents.propTypes = {
//   editEvent: Proptypes.func.isRequired,
//   segments: Proptypes.array.isRequired
// }

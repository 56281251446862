import React from 'react';
import { BasicViewHeader, ViewAccessWrapper } from '../../components';
import { platformPerformanceFirstReportingDate } from '../../utils/time';

import { useUrlStateWithDefaults } from './urlParamsHooks';
import { useSettings, useSegmentOptions, useFirstReportingDate, useInitDefaultSegment } from './hooks';
import Overlap from './Overlap';

const ChannelOverlap = () => {
  const { tierColors, segments, segOptions } = useSettings();

  // urlState
  const urlState = useUrlStateWithDefaults();
  const { platform, startDate, endDate, segId, ntfFilter } = urlState;   // properties
  const { setStartDate, setEndDate, setSegId, setNtfFilter } = urlState; // methods

  // segment: derived properties
  const { hasNtf, hasRevenue } = useSegmentOptions(segId, segments);

  // firstReportingDate: dervied from segment
  const firstReportingDate = useFirstReportingDate(
    segId,
    segOptions,                              // watched
    platformPerformanceFirstReportingDate,           // defaults
  );

  // Initialization hook: set default segment
  useInitDefaultSegment(
    segments,           // watched
    segId,
    setSegId,     // used
  );

  const onDateChange = date => {
    const { startDate: newStartDate, endDate: newEndDate } = date;
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  return (
    <>
      <BasicViewHeader
        header="Marketing Overlap"
        subheader="Understand how your marketing works together by analyzing the overlap of channels"
      />
      <Overlap
        {...{ headerText: 'Gain insight into the overlap with your marketing', platform }} // Basic params
        {...{ startDate, endDate, onDateChange, firstReportingDate }} // Dates
        {...{ segOptions, segId, setSegId }} // Conversion params
        {...{ ntfFilter, setNtfFilter, hasNtf }} // NTF
        {...{ tierColors, hasRevenue }} // Other
      />
    </>
  );
};

const ChannelOverlapAccess = () => (
  <ViewAccessWrapper
    viewName="channel_overlap"
    viewComponent={<ChannelOverlap />}
  />
);

export default ChannelOverlapAccess;

import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { IndexGrid, ContentCard, SplitLayout } from '@rockerbox/styleguide';

const DATASET_COLUMNS = [
  {display: "udf", key: "udf"},
  {display: "Filter ID", key: "filter_id"},
  {display: "Dataset", key: "dataset"}
]

const ALLOWED_DATASETS = [
  'sessions',
  'compiled_mta'
]

const Datasets = ({ groupId, datasets, multiday }) => {
  /*
   * Do something here to get segments + datasets
   */
  return <React.Fragment>
    <ContentCard title={
      <SplitLayout leftWidth="12" rightWdith="4"
        leftContent="Wait until we aggregate:"
        rightContent={ multiday == 1 ? <Checkbox checked={multiday} label="multiday" /> : "" }
      />
      } 
      hasTable
    >
      <IndexGrid data={datasets} cols={DATASET_COLUMNS} />
    </ContentCard>
  </React.Fragment>
}

export default Datasets;

import React, { useState } from 'react';

import { IndexGridNew } from '@rockerbox/styleguide';
import { Table, Label, Input, Icon } from 'semantic-ui-react';

import { Tooltip } from '../../../components';
import { formatCurrency } from '../../../utils/valueFormatter';
import { roundNumber } from '../helpers';
import { SparkChart } from '../MarketingPerformance/charts';

const labelStyles = {
  fontSize: 12,
  fontWeight: 500,
  padding: 5,
  marginLeft: 7,
};

export const AddXCell = ({ item, col }) => (
  <Table.Cell>
    <p>
      {`${item[col.key]}${item[col.key] === 'N/A' ? '' : 'X'}`}
    </p>
  </Table.Cell>
);

export const ChannelCell = ({ item, col }) => (
  <Table.Cell style={{ textAlign: 'left' }}>
    {item.expandButton && item.expandButton}
    <div style={{ width: 250 }}>
      {item.color && <Icon name="circle" style={{ color: item.color }} size="large" />}
      <span>
        {item[col.key]}
      </span>
      {item.tooltip && <Tooltip content={item.tooltip.content} icon={item.tooltip.icon} color="#666" style={{ float: 'right' }} />}
    </div>
  </Table.Cell>
);

export const CPACell = ({ item, col }) => (
  <Table.Cell>
    <p>
      {formatCurrency(1 / item[col.key])}
    </p>
  </Table.Cell>
);

export const CurrencyCell = ({ item, col }) => (
  <Table.Cell>
    <p>{formatCurrency(item[col.key], 'USD', col.sigfigs || 0)}</p>
  </Table.Cell>
);

export const KPIComparisonCell = ({ item, col }) => {
  const value = isNaN(item[col.key]) ? 0 : item[col.key];
  const noDifference = value === 0 || isNaN(value);
  const positive = value > 0;
  const positiveLabel = positive ? '+' : '';

  const content = `${positiveLabel}${roundNumber(value * 100, 1)}%`;
  const color = noDifference ? '' : positive ? 'green' : 'red';

  return (
    <Table.Cell>
      <p>
        <Label
          content={content}
          color={color}
          style={labelStyles}
        />
      </p>
    </Table.Cell>
  );
};

export const MetricComparisonCell = isConversionKPI => ({ item, col }) => {
  const { formatter } = col;
  const noDifference = item[col.key] === 0 || isNaN(item[col.key]);
  const positive = isConversionKPI ? item[col.key] < 0 : item[col.key] > 0;
  const positiveLabel = isConversionKPI
    ? positive ? '' : '+'
    : positive ? '+' : '';
  return (
    <Table.Cell>
      <Label
        content={`${positiveLabel}${formatter(item[col.key])}`}
        color={noDifference ? '' : positive ? 'green' : 'red'}
        style={labelStyles}
      />
    </Table.Cell>
  );
};

export const NumberCell = ({ item, col }) => {
  try {
    const formatted = item[col.key].toLocaleString('en-US', {
      maximumFractionDigits: col.sigfigs || 0,
    });

    return (
      <Table.Cell>
        <p>
          {formatted}
        </p>
      </Table.Cell>
    );
  } catch (e) {
    return (
      <Table.Cell>
        <p>{item[col.key]}</p>
      </Table.Cell>
    );
  }
};

export const PercentCell = ({ item, col }) => {
  const { formatter } = col;
  return (
    <Table.Cell content={`${formatter(item[col.key])}%`} />
  );
};

export const SpendEffectCell = ({ item, col }) => (
  <Table.Cell style={{ padding: 1 }}>
    <SparkChart data={[item]} maxVal={col.maxValue} />
  </Table.Cell>
);

export const SpendInputCell = ({ item, col }) => {
  const [showCustom, setShowCustom] = useState(false);
  const { key, allowCustomInput } = item;
  const { updateFunction, handleInput, featureStats } = col;

  if (!key) {
    return (
      <Table.Cell>
        <p>{formatCurrency(item[col.key], 'USD', 0)}</p>
      </Table.Cell>
    );
  }

  return (
    <Table.Cell>
      <div style={{ display: 'inline-block', width: 100 }}>
        <Input
          compact
          type="number"
          step={50}
          min={0}
          max={featureStats?.[key].historicalSpendMax}
          icon="dollar"
          iconPosition="left"
          disabled={!allowCustomInput}
          value={item[col.key]}
          onChange={(e, { value }) => value >= 0 && updateFunction(key, 'value', handleInput(value, featureStats?.[key].maxSpend))}
          className={`align-right slim-label ${!allowCustomInput && 'disabled-simple'}`}
          style={{ width: showCustom ? 89 : 67 }}
        />
        {!showCustom
        && (
        <Icon
          color="grey"
          name="pencil" onClick={() => {
            updateFunction(key, 'selection', 'custom');
            setShowCustom(true);
          }}
          style={{ cursor: 'pointer', marginTop: 8, marginLeft: 5 }}
        />
        )}
      </div>
    </Table.Cell>
  );
};

export const SpendTableHeader = ({ metricText, budgetPeriodName }) => {
  const TOOLTIP = `Our recommended ${budgetPeriodName} Spend values are based on our predictions to reach optimal ${metricText} efficiency, 
  but you can also keep your current spend or enter a custom value that achieves your goals.`;
  return (
    <>
      Proposed &nbsp;
      <Tooltip content={TOOLTIP} />
    </>
  );
};

export const TargetComparisonCell = ({ item }) => {
  const { targetValue, currentValue } = item;
  const percent = currentValue > 0 ? (targetValue - currentValue) / currentValue : 0;
  const noDifference = targetValue === currentValue || isNaN(percent);
  const positive = percent > 0;
  const positiveLabel = positive ? '+' : '';

  const content = `${positiveLabel}${roundNumber(percent * 100, 1)}%`;
  const color = noDifference ? '' : positive ? 'green' : 'red';

  return (
    <Table.Cell>
      <p>
        <Label
          content={content}
          color={color}
          style={labelStyles}
        />
      </p>
    </Table.Cell>
  );
};

export const GroupedChannelTooltipContent = ({ data, isConversionKPI, projectionName }) => {
  const kpiText = isConversionKPI ? 'Conversions' : 'Revenue';
  const columns = [
    {
      display: 'Channel / Tactic',
      key: 'display',
      headerStyle: { textAlign: 'left' },
      as: ChannelCell,
    },
    {
      display: `Projected ${projectionName} Budget`,
      key: 'estimatedTotalValue',
      as: CurrencyCell,
    },
    {
      display: `Projected ${projectionName} ${kpiText}`,
      key: 'totalKPIAtTargetValue',
      as: isConversionKPI ? NumberCell : CurrencyCell,
    },
  ];
  return (
    <IndexGridNew cols={columns} data={data} />
  );
};

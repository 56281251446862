import React from 'react';
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/tomorrow';

export const OnsitePixelEditor = ({unparsedJson, parsedJson, activeTab, toggleActive}) => (
	<React.Fragment>
    {/*
    <Menu
        fluid
        widths={2}
        attached="top"
        secondary
        pointing
    >
        <Menu.Item
        name="Raw JSON"
        active={!activeTab}
        onClick={() => toggleActive(0)}
        />
        <Menu.Item
        name="Parsed JSON"
        active={activeTab}
        onClick={() => toggleActive(1)}
        />
    </Menu>
    */}
		<AceEditor
			mode="json"
			theme="tomorrow"
			fontSize={12}
			highlightActiveLine={false}
			value={activeTab ? parsedJson : unparsedJson}
			setOptions={{
				showLineNumbers: true,
				highlightGutterLine: false,
				showPrintMargin: false,
				readOnly: true
			}}
			wrapEnabled={false}
			width={'100%'}
			height={'520px'}
		/>
  </React.Fragment>
)

import moment from 'moment';
import * as d3 from 'rockerbox_d3_legacy_clone';

export default function cdfChart(w) {
  var margin = {
    top: 60, // top margin includes title and legend
    right: 20, // right margin should provide space for last horz. axis title
    bottom: 30,
    left: 10 // left margin should provide space for y axis titles
  };

  var _w = w || 840
  // height & width
  var width = (_w - margin.left - margin.right);
  var height = (_w/5*3 - margin.top - margin.bottom);
  var legendWidth = _w -240;
  var legendHeight = 300;

  // cosmetics
  var color = "steelblue";
  var title = '';

  // global div for tooltip


  var formatCount = d3.format(",.0f");

  function chart(selection) {
    selection.each(function drawGraph(touchpoints) {

      const values = touchpoints

      var max = d3.max(values, x => x.threshold);
      var min = 0;
      var x = d3.scale.linear()
        .domain([min, max])
        .range([0, width]);


      var pmax = d3.max(values, x => x.predicted);
      var pmin = 0;
      var px = d3.scale.sqrt()
        .domain([pmin, pmax])
        .range([0, .9]);

      var amax = d3.max(values, x => x.actual);
      var amin = 0;
      var ax = d3.scale.sqrt()
        .domain([amin, amax])
        .range([0, .9]);


      var d3this = d3.select(this);

      var w = width;
      var h = height;
      
      var dataset = values
      
      var svg = d3this
      	.append("svg")
      	.attr("width", width)
      	.attr("height", height);

      svg.append("g")
         .selectAll("circle")
         .data(dataset)
         .enter()
         .append("circle")
         .attr("cx", function(d) { return x(d.threshold); })
         .attr("cy", margin.top+radius/2)
         .attr("r", function(d) { return Math.sqrt(ax(d.actual)*radius*radius); })
         .attr("fill", function(d) { return d.threshold > threshold ? "steelblue" : "grey"; })
         .attr("opacity", function(d) { return  ax(d.actual); })


      svg.append("g")
         .selectAll("circle")
         .data(dataset)
         .enter()
         .append("circle")
         .attr("cx", function(d) { return x(d.threshold); })
         .attr("cy", height-radius-radius)
         .attr("r", function(d) { return Math.sqrt(px(d.predicted)*radius*radius); })
         .attr("fill", function(d) { return d.threshold < threshold ? "red" : "grey"; })
         .attr("opacity", function(d) { return  px(d.predicted); })
      

      svg.append("text")
        .attr("x", width / 2)
        .attr("y", margin.top-radius)
        .attr("text-anchor", "middle")
        .text("Converters")

      svg.append("text")
        .attr("x", width / 2)
        .attr("y", height)
        .attr("text-anchor", "middle")
        .text("Non Converters")


    });
  }

  chart.width = function(_) {
    if (!arguments.length) return width;
    width = _;
    return chart;
  };

  chart.height = function(_) {
    if (!arguments.length) return height;
    height = _;
    return chart;
  };

  chart.title = function(_) {
    if (!arguments.length) return title;
    title = _;
    return chart;
  };

  var radius = 20;
  chart.radius = function(_) {
    if (!arguments.length) return radius;
    radius = _;
    return chart;
  };

  var threshold = 0.5;
  chart.threshold = function(_) {
    if (!arguments.length) return threshold;
    threshold = _;
    return chart;
  };

  return chart;
}

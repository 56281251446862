// import { useEffect } from "react"

// eslint-disable-next-line import/no-extraneous-dependencies
import Papa from 'papaparse';

export const usePapaParseConfig = () => {
  const onComplete = (results, file) => {
    console.log('Parsing complete:', results, file);
  };

  return {
    delimiter: '', // auto-detect
    newline: '', // auto-detect
    quoteChar: '"',
    escapeChar: '"',
    header: true,
    transformHeader: h => (h ? h.trim() : h), // strip whitespace from headers
    dynamicTyping: false,
    preview: 0,
    encoding: '',
    worker: false,
    comments: false,
    step: undefined,
    complete: onComplete,
    error: undefined,
    download: false,
    downloadRequestHeaders: undefined,
    downloadRequestBody: undefined,
    skipEmptyLines: true,
    chunk: undefined,
    chunkSize: undefined,
    fastMode: undefined,
    beforeFirstChunk: undefined,
    withCredentials: undefined,
    transform: undefined,
    delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP],
    skipFirstNLines: 0,
  };
};

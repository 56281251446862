import React, { Component } from 'react'
import rdmd from '@readme/markdown'
import { Header } from 'semantic-ui-react'
import { ContentCard } from '@rockerbox/styleguide'
import { postFormattedHelpDoc } from '../../utils/api'
import { MarkdownViewer } from './markdownViewer'

import '@readme/markdown/dist/main.css'


class HelpDoc extends Component {
  state = {
    doc: null,
    loading: true,
  }

  componentDidMount(){
    const { slug, context } = this.props
    postFormattedHelpDoc(slug, context)
      .then(doc => this.setState({doc, loading: false}))
  }

  componentDidUpdate(prevProps) {
    const oldContext = prevProps.context
    const { slug, context } = this.props

    if (JSON.stringify(context) !== JSON.stringify(oldContext)){
      postFormattedHelpDoc(slug, context)
        .then(doc => this.setState({doc, loading: false}))
    }
  }

  render() {
    const { doc, loading } = this.state
    const { includeTitle } = this.props

    if (!doc || loading) return <ContentCard.CardLoader />

    const { title, updatedAt, markdown } = doc

    return (<>
      {includeTitle &&
        <Header
          as='h1'
          content={title}
          subheader={updatedAt}
        />
      }
      <MarkdownViewer className='readme-docs'>
        {rdmd(markdown)}
      </MarkdownViewer>
    </>)
  }
}

export default HelpDoc

import React, { useState, useEffect, useMemo } from 'react';
import { Card } from 'semantic-ui-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { GlobeIcon, LightningBoltIcon, CashIcon } from '@heroicons/react/outline';

import * as aq from 'arquero';

import { ChartCard, AreaChartViz, SimpleChartCardLoader } from '@rockerbox/styleguide';
import { Link } from 'react-router-dom';
import { useGlobalState } from '../../hooks/global';
import { getCurrencyRate } from '../../api/currencyRate';
import { getShopifyOrderData, getPlatformSyncStatusByPlatform } from './helpers';
import { convertCurrencyByDate } from '../../utils/currency';
import { yesterday, twoMonthsAgo } from '../../utils/time';
import * as baseRoutes from '../../baseRoutes';

import EmptyChartCard from './EmptyChartCard';

const NAME_MAP = { cpa: 'CPA', roas: 'ROAS' };

const PlatformMetrics = ({ startDate, endDate, loading, setLoading, stillBackfilling, setStillBackfillingShopify, hasConversionData, currencyFormatter, currencyCode, spendData, tier }) => {
  const { authorizations } = useGlobalState();
  const [conversionData, setConversionData] = useState(null);
  const [chartData, setChartData] = useState(null);

  const ConnectText = tier === 'starter'
    ? <Link to={`${baseRoutes.integrationLastStep}/shopify`}>{'Connect your order data to view\nconversions and revenue'}</Link>
    : 'Connect your order data to view\nconversions and revenue';

  const currencyFormatFunction = x => (currencyFormatter ? currencyFormatter.format(x) : x);

  useEffect(() => {
    setLoading(true);
    getShopifyOrderData(startDate, endDate)
      .then(data => {
        setConversionData(data);
        setLoading(false);
      });
  }, [startDate, endDate]);

  useEffect(() => {
    if (!hasConversionData) return;
    getPlatformSyncStatusByPlatform(['shopify'], twoMonthsAgo, yesterday)
      .then(data => {
        let isBackfilling = false;
        data.forEach(obj => {
          if (obj.value.length < 30) {
            isBackfilling = true;
          }
        });
        setStillBackfillingShopify(isBackfilling);
      });
  }, [hasConversionData, startDate, endDate]);

  const totalRevenue = useMemo(() => {
    if (!conversionData) return 0;
    // eslint-disable-next-line no-unsafe-optional-chaining
    return conversionData.reduce((acc, d) => acc + d?.revenue, 0);
  }, [conversionData]);

  const totalConversions = useMemo(() => {
    if (!conversionData) return 0;
    // eslint-disable-next-line no-unsafe-optional-chaining
    return conversionData.reduce((acc, d) => acc + d?.conversions, 0);
  }, [conversionData]);

  const displayRevenue = useMemo(() => totalRevenue > 0, [totalRevenue]);

  useEffect(() => {
    if (!spendData) return;
    if (currencyCode === undefined) return;
    if (!authorizations.length) return setChartData([]);

    const spendDataSums = [];
    // eslint-disable-next-line array-callback-return
    spendData.map(x => {
      // eslint-disable-next-line no-return-assign, no-param-reassign
      const s = Object.keys(x).reduce((a, c) => a += (c === 'date' ? 0 : x[c]), 0);
      spendDataSums.push({ date: x.date, spend: s });
    });

    getCurrencyRate(currencyCode, startDate, endDate).then(response => {
      const rates = response.data;
      const table = aq.from(Object.values(spendDataSums));
      const updated = convertCurrencyByDate(table, rates);
      setChartData(updated.objects());
    });
  }, [spendData]);

  const totalSpend = useMemo(() => {
    if (!chartData) return 0;
    return chartData.reduce((acc, d) => acc + d.spend, 0);
  }, [chartData]);

  // roas and cpa data
  const performanceData = useMemo(() => {
    if (!conversionData || !chartData) return null;
    if (!conversionData.length || !chartData.length) return [];
    return chartData.map(({ date, spend }) => {
      const { revenue, conversions } = conversionData.find(d => d.date === date) || { revenue: 0, conversions: 0 };
      return {
        date,
        roas: spend ? revenue / spend : 0,
        cpa: conversions ? spend / conversions : 0,
      };
    });
  }, [conversionData, chartData]);

  const avgROAS = useMemo(() => {
    if (!performanceData?.length) return 0;
    return performanceData.reduce((acc, { roas }) => acc + roas, 0) / performanceData.length;
  }, [performanceData]);

  const avgCPA = useMemo(() => {
    if (!performanceData?.length) return 0;
    return performanceData.reduce((acc, { cpa }) => acc + cpa, 0) / performanceData.length;
  }, [performanceData]);

  return (
    <div className="platform-metrics">
      <Card.Group itemsPerRow={3}>
        {(!!stillBackfilling || !!loading) ? <SimpleChartCardLoader animate={!stillBackfilling} />
          : !totalConversions ? <EmptyChartCard icon={<GlobeIcon />} text={ConnectText} />
            : (
              <ChartCard
                key={displayRevenue ? 'revenue' : 'conversions'}
                chart={(
                  <AreaChartViz
                    solidChart={true}
                    data={conversionData}
                    XAxisKey="date"
                    YAxisStyle={[{
                      keyName: displayRevenue ? 'revenue' : 'conversions',
                      color: '#36f29b80',
                    }]}
                    nameMap={NAME_MAP}
                    formatter={currencyFormatFunction}
                    strokeWidth={0}
                  />
                )}
                statistics={[{
                  statisticLabel: displayRevenue ? 'Revenue' : 'Orders',
                  statisticValue: displayRevenue ? totalRevenue : totalConversions,
                  alignLabel: 'left',
                  format: displayRevenue && 'currency',
                  currencyCode,
                  maxDigits: 0,
                  color: '#000000',
                  ix: 'free',
                }]}
              />
            )}

        {(!!stillBackfilling || !!loading || performanceData === null) ? <SimpleChartCardLoader animate={!stillBackfilling} />
          : !avgCPA
            ? <EmptyChartCard icon={<LightningBoltIcon />} text={<Link to={baseRoutes.connectIntegration}>{'Connect your orders & advertising\nplatforms to view ROAS and CPA'}</Link>} />
            : (
              <ChartCard
                key={displayRevenue ? 'roas' : 'cpa'}
                chart={(
                  <AreaChartViz
                    solidChart={true}
                    data={performanceData}
                    XAxisKey="date"
                    YAxisStyle={[{
                      keyName: displayRevenue ? 'roas' : 'cpa',
                      color: '#dc474980',
                    }]}
                    nameMap={NAME_MAP}
                    formatter={x => x.toFixed(2)}
                    strokeWidth={0}
                  />
                )}
                statistics={[{
                  statisticLabel: `Blended ${displayRevenue ? 'ROAS' : 'CPA'}`,
                  statisticValue: displayRevenue ? avgROAS : avgCPA,
                  alignLabel: 'left',
                  format: !displayRevenue && 'currency',
                  currencyCode,
                  maxDigits: 2,
                  color: '#000000',
                  ix: 'free',
                }]}
              />
            )}

        {(!!stillBackfilling || !!loading || chartData === null) ? <SimpleChartCardLoader animate={!stillBackfilling} />
          : !totalSpend ? <EmptyChartCard icon={<CashIcon />} text={<Link to={baseRoutes.connectIntegration}>{'Connect your advertising platforms\nto view performance and spend'}</Link>} />
            : (
              <ChartCard
                key="spend"
                chart={(
                  <AreaChartViz
                    solidChart={true}
                    data={chartData}
                    XAxisKey="date"
                    YAxisStyle={[{
                      keyName: 'spend',
                      color: '#6847dc80',
                    }]}
                    nameMap={NAME_MAP}
                    formatter={currencyFormatFunction}
                    strokeWidth={0}
                  />
                )}
                statistics={[{
                  statisticLabel: 'Spend',
                  statisticValue: totalSpend,
                  alignLabel: 'left',
                  format: 'currency',
                  currencyCode,
                  maxDigits: 0,
                  color: '#000000',
                  ix: 'free',
                }]}
              />
            )}
      </Card.Group>

      {/* {!!stillBackfilling && <BackfillingMessage />} */}
    </div>
  );
};

export default PlatformMetrics;

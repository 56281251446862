import { openDB } from 'idb';

const upgrade = upgradeDb => {
  if (!upgradeDb.objectStoreNames.contains('request_cache_id')) upgradeDb.createObjectStore('request_cache_id', { keyPath: 'request' });
  if (!upgradeDb.objectStoreNames.contains('cache_id_data')) upgradeDb.createObjectStore('cache_id_data', { keyPath: 'cache_id' });
};

const dbs = {};

export const getDB = (name, version) => {
  if (!dbs[name]) dbs[name] = openDB(name, version, { upgrade });
  return dbs[name];
};

export const initDb = async (dataset, version) => {
  console.time(`open-db-${dataset}`); // eslint-disable-line no-console
  const db = await getDB(dataset, version);
  console.timeEnd(`open-db-${dataset}`); // eslint-disable-line no-console
  return db;
};

export const resetDBs = () => {
  Object.keys(dbs).forEach(k => delete dbs[k]);
};

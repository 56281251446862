import { combineReducers } from 'redux';

import segment from '../components/SelectSegment/reducer';
import app from '../components/SceneApp/reducer';
import reports from '../components/ReportIndex/reducer';
import reportDeliveryStatus from '../components/ReportDeliveryStatus/reducer';

const rootReducer = combineReducers({
  segment,
  app,
  reports,
  reportDeliveryStatus
});

export default rootReducer;

import React, { useState, useEffect } from 'react';

import { faFrownOpen, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Icon } from 'semantic-ui-react';

import { getDatasetStatusRollup } from '../api/dataReporting';
import { useGlobalState } from '../hooks/global';
import { formatDateToLongString } from '../utils/dateFormatters';
import { findFeaturedActionId } from '../utils/options';

export const isMissingData = apiResponse => {
  if (!apiResponse) return false;
  const hasFailures = apiResponse.failures && apiResponse.failures.length > 0;
  return hasFailures;
};

export const determineModalStatus = (spendStatus, conversionStatus, syntheticStatus, connectionStatus) => {
  if (spendStatus === undefined || conversionStatus === undefined || syntheticStatus === undefined || connectionStatus === undefined) return false;
  return spendStatus || conversionStatus || connectionStatus || syntheticStatus;
};

const checkAuthStatuses = platforms => {
  if (!platforms) {
    return undefined;
  }
  const hasInactiveStatus = platforms.some(platform => platform.authorizations.some(auth => auth.status === 0));
  return hasInactiveStatus;
};

// eslint-disable-next-line no-unused-vars
const getDaysWithIssues = (spendStatus, conversionStatus, syntheticStatus) => {
  const extractDates = status => status.failures.reduce((acc, item) => {
    item.dates.forEach(date => acc.add(date.split(' ')[0]));
    return acc;
  }, new Set());

  const allDates = [...extractDates(spendStatus), ...extractDates(conversionStatus), ...extractDates(syntheticStatus)];

  return Array.from(new Set(allDates));
};

const generateModalText = (spendStatus, conversionStatus, syntheticStatus, connectionStatus, issueDetected) => {
  if (!spendStatus || !conversionStatus || !syntheticStatus || !connectionStatus) return;

  if (!issueDetected) return 'All your account connections are working and all ads are being tracked.';

  const spendFailures = spendStatus.failures.length;
  const conversionFailures = conversionStatus.failures.length;
  const syntheticFailures = syntheticStatus.failures.length;
  const badConnections = connectionStatus.filter(platform => platform.authorizations.some(auth => auth.status === 0));
  const connectionIssues = badConnections.length;
  const numberOfIssueTypes = [spendFailures, conversionFailures, syntheticFailures, connectionIssues].filter(issue => issue > 0).length;
  if (connectionIssues > 0) {
    const platformsDisconnected = badConnections.map(platform => platform.platform);
    const platformName = platformsDisconnected[0];
    const integrations = '/v3/data/marketing/integrations/main';
    return (
      connectionIssues > 1
        ? (
          <span>
            Your marketing accounts have been disconnected from Rockerbox. To ensure your data is accurate, please
            {' '}
            <a href={integrations}>reconnect</a>
            {' '}
            your accounts as soon as possible.
          </span>
        )
        : (
          <span>
            Your
            {' '}
            {platformName}
            {' '}
            account(s) have been disconnected from Rockerbox. To ensure your data is connected, please
            {' '}
            <a href={integrations}>reconnect</a>
            {' '}
            your
            {' '}
            {platformName}
            {' '}
            accounts as soon as possible.
          </span>
        )
    );
  }
  const daysWithIssues = getDaysWithIssues(spendStatus, conversionStatus, syntheticStatus);
  const numberOfDaysWithIssues = daysWithIssues.length;
  const formattedDate = formatDateToLongString(daysWithIssues[0]);
  if (numberOfIssueTypes > 1) {
    return numberOfDaysWithIssues > 1
      ? `Rockerbox has detected issues with your data for ${numberOfDaysWithIssues} days.
      There may be a delay in processing some of your data for the days impacted. We are working to resolve this as soon as possible.`
      : `Rockerbox has detected an issue with your data for ${formattedDate}.
       There may be a delay in processing some of your data for the day impacted. We are working to resolve this as soon as possible.`;
  }
  if (spendFailures > 0 || syntheticFailures > 0) {
    const type = spendFailures > 0 ? 'spend' : 'synthetic';
    const platformsWithIssues = (type === 'spend' ? spendStatus : syntheticStatus).failures.map(failure => failure.platform);

    return platformsWithIssues.length > 1
      ? `Rockerbox has detected issues with your data for ${platformsWithIssues.length} platforms. 
      There may be a delay in processing some of your data for the days impacted. We are working to resolve this as soon as possible.`
      : `Rockerbox has detected issues with your ${platformsWithIssues[0]} data for ${numberOfDaysWithIssues > 1 ? `${numberOfDaysWithIssues} days`
        : formattedDate}. There may be a delay in processing some of your data for the days impacted. We are working to resolve this as soon as possible.`;
  }
  if (conversionFailures > 0) {
    return numberOfDaysWithIssues > 1
      ? `Rockerbox has detected an issue with your conversion data for ${numberOfDaysWithIssues} days. 
      There may be a delay in processing some of your data for the days impacted. We are working to resolve this as soon as possible.`
      : `Rockerbox has detected issues with your conversion data for ${formattedDate}. 
      There may be a delay in processing some of your data for the days impacted. We are working to resolve this as soon as possible.`;
  }
};

const StatusIndicator = React.memo(({ hasIssue, text }) => (
  <Grid.Column width={2} className="custom-width status-column">
    <Icon
      name={hasIssue ? 'exclamation triangle' : 'check circle'}
      color={hasIssue ? 'orange' : 'green'}
      size="large"
      className="modal-icon"
    />
    <span className="modal-text">{text}</span>
  </Grid.Column>
));

const DataStatusModal = ({ isTestAccount, connections, startDate, endDate }) => {
  if (!connections) return null;
  const { segments, authorizations, dataStatusPlatforms } = useGlobalState();
  const [showModal, setShowModal] = useState(true);
  const [spendRollupStatus, setSpendRollupStatus] = useState(null);
  const [conversionRollupStatus, setConversionRollupStatus] = useState(null);
  const [syntheticRollupStatus, setSyntheticRollupStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  const dismissTextBox = () => setShowModal(false);

  const featuredSegment = findFeaturedActionId(segments);

  useEffect(() => {
    if (!featuredSegment || !dataStatusPlatforms) return;
    const fetchData = async () => {
      try {
        const spendRollupPromise = getDatasetStatusRollup('spend', startDate, endDate);
        const conversionRollupPromise = featuredSegment === '0' ? null : getDatasetStatusRollup('conversions', startDate, endDate, featuredSegment);
        const syntheticRollupPromise = getDatasetStatusRollup('synthetic_events', startDate, endDate);

        const [conversionRollupResponse, spendRollupResponse, syntheticRollupResponse] = await Promise.all([
          conversionRollupPromise,
          spendRollupPromise,
          syntheticRollupPromise,
        ]);
        const filteredSpend = {
          ...spendRollupResponse.response,
          failures: spendRollupResponse.response.failures.filter(failure => dataStatusPlatforms.includes(failure.platform)),
        };
        setSpendRollupStatus(filteredSpend);
        setConversionRollupStatus(conversionRollupResponse?.response);
        // TODO: remove filtering of Tiktok synthetic once the data is reliable
        const filteredData = {
          ...syntheticRollupResponse.response,
          failures: syntheticRollupResponse.response.failures.filter(failure => failure.platform !== 'tiktok'),
        };
        setSyntheticRollupStatus(filteredData);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    };

    fetchData();
  }, [startDate, endDate, featuredSegment, dataStatusPlatforms]);

  const hasMissingSpend = isMissingData(spendRollupStatus);
  const hasMissingConversions = isMissingData(conversionRollupStatus);
  const hasBrokenConnection = checkAuthStatuses(authorizations);
  const hasMissingSynthetic = isMissingData(syntheticRollupStatus);
  const issueDetected = determineModalStatus(hasMissingSpend, hasMissingConversions, hasMissingSynthetic, hasBrokenConnection);

  const issueText = generateModalText(spendRollupStatus, conversionRollupStatus, syntheticRollupStatus, authorizations, issueDetected);
  const icon = issueDetected ? { icon: faFrownOpen, color: 'orange' } : { icon: faThumbsUp, color: 'green' };
  const header = issueDetected ? 'Data Processing Delay' : 'Everything looks good!';
  return (
    !loading && showModal && isTestAccount && issueText ? (
      <div className="dismissable-text-box">
        <Button
          icon="close"
          circular
          className="dismiss-button"
          onClick={dismissTextBox}
        />
        <Grid>
          <Grid.Column width={6} style={{ marginRight: '25px' }}>
            <div className="summary-text">
              <FontAwesomeIcon
                as="i"
                icon={icon.icon}
                color={icon.color}
                style={{ marginRight: '10px', height: '40' }}
              />
              <div style={{ marginLeft: '10px' }}>
                <h3 style={{ margin: '0 5px 5px 0' }}>{header}</h3>
                <p style={{ margin: 0, minHeight: '34px' }}>
                  {issueText}
                </p>
              </div>
            </div>
          </Grid.Column>
          <StatusIndicator hasIssue={hasBrokenConnection} text="Account Connections" />
          <StatusIndicator hasIssue={hasMissingConversions} text="Conversion Data" />
          <StatusIndicator hasIssue={hasMissingSpend} text="Marketing Spend Data" />
          <StatusIndicator hasIssue={hasMissingSynthetic} text="Synthetic Events" />
        </Grid>
      </div>
    ) : (
      <div />
    )
  );
};

export default DataStatusModal;

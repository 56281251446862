import React, { useState } from 'react';
import _ from 'lodash';
import { ContentCard } from '@rockerbox/styleguide';
import { Checkbox, Segment, Grid, Dropdown, Button, Form } from 'semantic-ui-react';

import CurrentResponses from './CurrentResponses';
import { ResponseMessage, ExclusionResponseMessage, RemappedResponseMessage } from './Messages';

const ResponseCard = ({
  response, response_values, response_display,
  updateStateArrayRowField, remove, pos,
}) => {
  const valueChange = updateStateArrayRowField('responses', 'response_values')(response);
  const displayChange = updateStateArrayRowField('responses', 'response_display')(response);

  const valueSplit = (response_values || '').split('|');
  const valueOptions = valueSplit.map(text => Object({ text, value: text }));
  const hasMultiple = valueSplit.length > 1 && valueSplit.slice(-1)[0].length > 0;

  const dropdownValueChange = values => valueChange(false, { value: values.join('|') });

  return (
    <Segment secondary>
      <Button as="a" floated="right" icon="remove" onClick={() => remove(pos)} />
      <Form.Group>
        { !hasMultiple && <Form.Input label="If response contains" value={response_values} onChange={valueChange} /> }
        { hasMultiple && (
        <Form.Field>
          <label>If response contains</label>
          <Dropdown
            search
            selection
            multiple
            allowAdditions
            value={valueSplit}
            options={valueOptions}
            onChange={(evt, { value }) => dropdownValueChange(value)}
          />
        </Form.Field>
        )}
        <Form.Input label="Then show" value={response_display} onChange={displayChange} />
      </Form.Group>
    </Segment>
  );
};

const ResponseSetup = props => {
  const { setResponseValues, handleChange, updateStateArrayRowField, addAdvanced, removeAdvanced } = props;
  const { survey, conversion, surveyResponseKey, excluded, defaultResponse, responses, ruleValues } = props;
  const [showExclusions, setShowExclusions] = useState(excluded && excluded.length > 0);
  const [showMappings, setShowMappings] = useState(true);

  const remove = removeAdvanced('responses');
  const handleExcluded = (e, d) => handleChange(d.value, 'excluded');

  const createEntry = (value, display = false) => {
    const missingValue = responses.filter(({ response_values }) => response_values === value).length === 0;
    if (missingValue) addAdvanced('response', { response_display: display || value, response_values: value });
  };

  const editEntry = (currentDisplay, value) => {
    const existing = responses.find(row => row.response_display === currentDisplay.response_display);
    const displayChange = updateStateArrayRowField('responses', 'response_display')(existing);
    displayChange(false, { value });
  };

  const removeEntry = currentDisplay => {
    const responsesCopy = _.cloneDeep(responses);
    const newResponses = responsesCopy.filter(row => row.response_display !== currentDisplay);
    setResponseValues(newResponses);
  };

  const removePattern = _value => {
    const value = _value.trim().toLowerCase();
    const responsesCopy = _.cloneDeep(responses);

    responsesCopy.forEach(row => {
      row.response_values = row.response_values  // eslint-disable-line no-param-reassign
        .split('|')
        .filter(v => v.toLowerCase().trim() !== value)
        .sort()
        .join('|');
    });

    setResponseValues(responsesCopy);
  };

  const appendEntry = (currentDisplay, _value) => {
    const value = _value.trim();

    const responsesCopy = _.cloneDeep(responses);
    responsesCopy.forEach(responseObj => {
      const obj = responseObj.response_values
        .split('|')
        .map(s => s.trim())
        .reduce((p, c) => Object.assign(p, { [c]: 1 }), {});
      responseObj.response_values = Object.keys(obj).join('|'); // eslint-disable-line no-param-reassign
    });

    const currentItem = responsesCopy.find(row => row.response_display === currentDisplay);

    if (!currentItem) return createEntry(value, currentDisplay);

    const filteredVal = responsesCopy.filter(row => row.response_values.toLowerCase().includes(value.toLowerCase()));

    filteredVal.forEach(row => {
      row.response_values = row.response_values // eslint-disable-line no-param-reassign
        .split('|')
        .filter(v => !v.toLowerCase().includes(value.toLowerCase()))
        .sort()
        .join('|');
    });

    const newResponseValue = currentItem.response_values.length > 0 ? `${currentItem.response_values}|${value}` : value;
    currentItem.response_values = newResponseValue;

    setResponseValues(responsesCopy);
  };

  const options = Object.keys(responses
    .reduce((accu, c) => {
      accu[c.response_display] = 1;
      return accu;
    }, {}))
    .map(text => ({ text, value: text }));
  // .filter(({ text }) => text)

  return (
    <Grid columns="equal">

      <Grid.Row>
        <Grid.Column>
          <RemappedResponseMessage />
        </Grid.Column>
        { false && (
        <Grid.Column>
          <ContentCard maxHeight={400} title="Organize Responses">
            <h5>Organize your responses</h5>
          </ContentCard>
        </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <CurrentResponses
            {...{
              ruleValues, options,
              editEntry, appendEntry, createEntry, removeEntry,
              removePattern, defaultResponse, responseRules: responses,
              surveyResponseKey, survey, id: conversion,
            }}
          />
        </Grid.Column>
      </Grid.Row>
      { false
      && (
      <Grid.Row>
        <Grid.Column>
          <ContentCard title="Organize Survey Responses">
            <ResponseMessage />
            <Checkbox toggle label="Response Exclusions" checked={showExclusions} onChange={() => setShowExclusions(true)} />
            {
              showExclusions && (
              <>
                <ExclusionResponseMessage />
                <Form.Input label="Excluded responses:" value={excluded} onChange={handleExcluded} />
              </>
              )
            }
            <br />
            <Checkbox toggle label="Response Mappings" checked={showMappings} onChange={() => setShowMappings(true)} />
            { showMappings && (
            <>
              {responses && responses.length > 0 && responses.map((response, pos) => (
                <ResponseCard {...{ response, updateStateArrayRowField, pos, remove }} />
              ))}
              <div style={{ height: '24px' }}>
                <Button floated="right" primary onClick={() => addAdvanced('response')} content="response" icon="plus" size="mini" as="span" />
              </div>
            </>
            )}
            <Segment secondary>
              <Form.Group>
                <Form.Input label="Otherwise show" value={defaultResponse} onChange={(e, d) => handleChange(d.value, 'defaultResponse')} />
              </Form.Group>
            </Segment>
          </ContentCard>
        </Grid.Column>
      </Grid.Row>
      )}
    </Grid>
  );
};

export default ResponseSetup;

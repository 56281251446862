import React from 'react';
import GatedByGlobalSetup from './GatedByGlobalSetup';
import { ViewAccessWrapper } from '../../components';

const PlatformPerformanceAccess = () => (
  <ViewAccessWrapper
    viewName="platform_performance"
    viewComponent={<GatedByGlobalSetup />}
  />
);

export default PlatformPerformanceAccess;

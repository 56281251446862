import React from 'react';

import { Header, Table } from 'semantic-ui-react';
import styled from 'styled-components';
import tinycolor from 'tinycolor2'; // eslint-disable-line import/no-extraneous-dependencies

import { Tooltip as InfoTooltip } from '../../../components';
import { roundNumber } from '../helpers';

const HeaderCell = styled(Table.HeaderCell)`
  font-weight:bold;
`;

const TableCell = styled(Table.Cell)`
  ${({ color }) => {
    if (color < 0) {
      const negativeColor = '#E61939';
      const bgColor = tinycolor(negativeColor).lighten(10).darken(-color * 30).toHexString();
      return `background-color:${bgColor}`;
    }
    const positiveColor = '#5fa8f6';
    const bgColor = tinycolor(positiveColor).lighten(10).darken(color * 30).toHexString();
    return `background-color:${bgColor}`;
  }}
`;

const CorrelationChart = ({ isConversionKPI, correlationData = [], features }) => {
  // Create a map for easy access to the values
  const correlation_map = correlationData.reduce((accu, { media_col_from, media_col_to, value }) => {
    accu[`${media_col_from}-${media_col_to}`] = value;
    return accu;
  }, {});
  const kpiText = isConversionKPI ? 'conversions' : 'revenue';

  return (
    <>
      <Header
        as="h4"
        content={(
          <>
            Feature Correlation
            <InfoTooltip
              position="right center"
              content={`See how your features are correlated with each other and ${kpiText}.`}
              style={{ fontSize: 14, marginLeft: 5, marginBottom: 5 }}
            />
          </>
        )}
      />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <HeaderCell />
            {features.map((column, index) => (
              <HeaderCell key={index}>{index + 1}</HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {features.map((row, i) => (
            <Table.Row key={i}>
              <Table.HeaderCell style={{ paddingLeft: '5px' }}>
                {i + 1}
                .
                {' '}
                {row.name === 'target' ? 'Revenue' : row.name}
              </Table.HeaderCell>
              {features.map((column, j) => {
                const cellValue = correlation_map[`${row.value}-${column.value}`] || correlation_map[`${column.value}-${row.value}`] || 0;
                return (
                  <TableCell key={j} color={cellValue}>
                    {roundNumber(cellValue, 2)}
                  </TableCell>
                );
              })}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default CorrelationChart;

export const ItemTypes = {
  ORDER: "order",
};

export const typeOptions = [
  { key: "int", text: "Integer", value: "int" },
  { key: "float", text: "Float", value: "float" },
  { key: "datetime", text: "Date Time", value: "datetime" },
  { key: "str", text: "String", value: "str" },
  { key: "object", text: "JSON", value: "json" },
];

export const datetimeFormatOptions = [
  { key: "%Y-%m-%d", text: "%Y-%m-%d", value: "%Y-%m-%d" },
  {
    key: "%Y-%m-%d %H:%M:%S",
    text: "%Y-%m-%d %H:%M:%S",
    value: "%Y-%m-%d %H:%M:%S",
  },
  {
    key: "%Y-%m-%dT%H:%M:%S+00:00",
    text: "%Y-%m-%dT%H:%M:%S+00:00",
    value: "%Y-%m-%dT%H:%M:%S+00:00",
  },
  {
    key: "%d\\/%b\\/%Y:%H:%M:%S",
    text: "%d\\/%b\\/%Y:%H:%M:%S",
    value: "%d\\/%b\\/%Y:%H:%M:%S",
  },
  {
    key: "%Y-%m-%dT%H:%M:%SZ",
    text: "%Y-%m-%dT%H:%M:%SZ",
    value: "%Y-%m-%dT%H:%M:%SZ",
  },
];

export const floatFormatOptions = [
  { key: "{:.2f}", text: "{:.2f}", value: "{:.2f}" },
  { key: "{:.3f}", text: "{:.3f}", value: "{:.3f}" },
  { key: "{:.4f}", text: "{:.4f}", value: "{:.4f}" },
];

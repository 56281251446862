import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from './component';

import Fetch from '../parts/GenericFetch';
import { rootAction } from '../../rootAction';

export const SET_CONVERSION_SEGMENTS = 'SET_CONVERSION_SEGMENTS'
export const SET_CONVERSION_SEGMENT = 'SET_CONVERSION_SEGMENT'

export const CONV_ENDPOINT = '/segments?format=json';

const err = () => console.log("error")

function mapStateToProps (state, props) {

  const hasSelectedSegmentId = props.selectedSegmentId !== undefined
  const selectedSegmentId = hasSelectedSegmentId ? props.selectedSegmentId : props.match.params.id

  return {
    segments: props.segments || state.segment.segments,
    defaultSelection: state.segment.segments.find(x => x.featured),
    selected: state.segment.segments.find(x => x.action_id == selectedSegmentId)
  }
}

function mapDispatchToProps(dispatch, props) {

  const pushFunc = typeof(props.endpoint) == "function" ? props.endpoint : (x) => props.endpoint + x
  const { onChange, selectedSegmentId } = props;

  return {
    setDefault: (defaultSelection) => {

      if (!defaultSelection) return;

      if (props.skipDefaultSelection) return;

      const hasHistory = !!props.history
      const hasMatch = !!props.match
      const hasSegmentSelectionViaProps = selectedSegmentId == ''
      const hasOnChangeFunc = !!onChange

      if (!hasHistory && hasSegmentSelectionViaProps && hasOnChangeFunc) {
        onChange(defaultSelection.action_id);
        return
      }

      if (hasHistory && hasMatch)  {
        const { match, history: { push } } = props;
        if (!match.params.id && onChange) {
          onChange(defaultSelection.action_id)
          return
        }
        if (!match.params.id && !onChange) {
          push(pushFunc(defaultSelection.action_id))
          return
        }
      }


    },
    setConversionSegments: (data) => {
      const conversions = data.response
        .filter(segment => segment.action_type == "conversion")

      dispatch(rootAction(SET_CONVERSION_SEGMENTS, conversions))
    },
    setConversionSegment: (data) => {

      const hasHistory = props.history

      if (!hasHistory && !!onChange) {
        onChange(data.action_id)
        return
      }
      if (hasHistory) {
        const { push } = props.history
        if (onChange) onChange(data.action_id)
        else push(pushFunc(data.action_id))
        dispatch(rootAction(SET_CONVERSION_SEGMENT, true))
      }

    }
  }
}

const Component = (props) => [
  <Fetch error={err} endpoint={CONV_ENDPOINT} preventFetch={!!props.segments.length} success={props.setConversionSegments} />,
  <Select {...props} />
]

const SelectConversion = connect(mapStateToProps, mapDispatchToProps)(withRouter(Component))

export default SelectConversion;

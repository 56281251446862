import React from "react";
import * as routes from "../../routes";
import { Link } from "react-router-dom";
import { Table, Button } from "semantic-ui-react";
import { IndexGrid, ContentCard } from "@rockerbox/styleguide";

const NextTriggerCell = ({ item }) => {
  const { next_group_id } = item;
  if (!next_group_id) {
    return null;
  }
  return (
    <Table.Cell>
      <Link to={`${routes.triggerIndex}/view/${next_group_id}`}>
        <Button icon="arrow right" />
      </Link>
    </Table.Cell>
  );
};

const findGroupedTriggers = (triggerGroups, groupId) => {
  groupId = parseInt(groupId);
  let result = null;
  if (!triggerGroups || !groupId) {
    return result;
  }
  triggerGroups.forEach((triggerGroup, i) => {
    const nodes = triggerGroup["nodes"];
    const targetIndex = nodes.findIndex(
      trigger => trigger.group_id === groupId
    );
    if (targetIndex !== -1) {
      const nextIndex = targetIndex + 1;
      result = {
        currentNode: nodes[targetIndex],
        nextNode: nodes[nextIndex] || null
      };
    }
  });

  return result;
};

const LINK_COLUMNS = [
  { display: "Current Agg", key: "current_name" },
  { display: "Next Agg", key: "next_name" },
  { display: "Next Trigger", as: NextTriggerCell }
];

const Links = ({ groupId, triggers }) => {
  const groups = findGroupedTriggers(triggers, groupId);
  if (!groups) {
    return null;
  }
  const { currentNode, nextNode } = groups;
  const data = [
    {
      current_name: currentNode && currentNode.name,
      next_name: (nextNode && nextNode.name) || "None",
      next_group_id: nextNode && nextNode.group_id
    }
  ];

  return (
    <ContentCard title={"Following aggregations:"} hasTable>
      <IndexGrid data={data} cols={LINK_COLUMNS} />
    </ContentCard>
  );
};

export default Links;

import React, { useState } from "react";
import Container from "../parts/Container";
import { postScheduledReport } from "./api";
import { track } from "../../utils/tracking";
import { hourFromLocalToEST, currentTimeZone } from "./timezoneConversion";
import { invalidRootFolder } from "./helpers"
import {
  Button,
  Modal,
  Form,
  Message,
  Dropdown,
  Checkbox,
} from "semantic-ui-react";
import { CloseButtonStyles } from "../styled";

const ShareFormFields = ({
  filetype,
  setFiletype,
  days,
  setDays,
  hour,
  setHours,
  sendOnFinish,
  setSendOnFinish,
  isHourOutOfRange
}) => {
  const filetypeOptions = [
    { text: "csv", value: "csv", key: "csv" },
    { text: "tsv", value: "tsv", key: "tsv" },
    { text: "xlsx", value: "xlsx", key: "xlsx" },
  ];

  const daysOptions = [
    { text: "Mon", value: "Mon", key: "Mon" },
    { text: "Tues", value: "Tues", key: "Tues" },
    { text: "Wed", value: "Wed", key: "Wed" },
    { text: "Thurs", value: "Thurs", key: "Thurs" },
    { text: "Fri", value: "Fri", key: "Fri" },
    { text: "Sat", value: "Sat", key: "Sat" },
    { text: "Sun", value: "Sun", key: "Sun" },
  ];

  return (
    <React.Fragment>
      <Form.Group key="filetype">
        <Form.Field>
          <label>{"File Type"}</label>
          <Dropdown
            placeholder="csv"
            selection
            value={filetype}
            options={filetypeOptions}
            onChange={(e, data) => setFiletype(data.value)}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group key="days">
        <Form.Field>
          <label>{"Scheduled Days"}</label>
          <Dropdown
            placeholder="Mon"
            multiple
            selection
            value={days}
            options={daysOptions}
            onChange={(e, data) => setDays(data.value)}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group key="send_on_pipeline_finish">
        <Form.Field>
          <Checkbox
            label="Send when aggregation finish (Recommend)"
            defaultChecked={true}
            value={sendOnFinish}
            onChange={(e, data) => {
              setSendOnFinish(data.checked);
              setHours(12);
            }}
          />
        </Form.Field>
      </Form.Group>
      {sendOnFinish ? null : (
        <Form.Group key={"hour"}>
          <Form.Field inline>
            <label>{`Hour (${currentTimeZone.zoneAbbr()})`}</label>
            <Form.Input
              value={hour}
              error={!!isHourOutOfRange && {"content": "Current hour is not available, please use send when aggregation finish checkbox above.", "pointing": "left"}}
              onChange={(e, data) => setHours(data.value)}
            />
          </Form.Field>
        </Form.Group>
      )}
    </React.Fragment>
  );
};

export const ScheduleEmailReportModal = (props) => {
  const [days, setDays] = useState([
    "Mon",
    "Tues",
    "Wed",
    "Thurs",
    "Fri",
    "Sat",
    "Sun",
  ]);
  const [hour, setHours] = useState(12);
  const [filetype, setFiletype] = useState("csv");
  const [sendOnFinish, setSendOnFinish] = useState(true);
  const {
    outboxId,
    savedReportId,
    outboxes,
    contact,
    onFieldChange,
    savedReports,
    loading,
    onLoadingChange,
    error,
    onErrorChange,
    onOutboxIdChange,
  } = props;
  const reportOptions = savedReports.map((r, i) => {
    return { text: r.name, value: r.id, key: i };
  });
  const onSubmit = () => {
    const requestBody = {
      saved_report_id: savedReportId,
      outbox_id: outboxes[0]["id"],
      days: days.join(","),
      time: hourFromLocalToEST(parseInt(hour)),
      filetype: filetype,
      destination: contact,
      contact,
      send_on_pipeline_finish: sendOnFinish,
    };

    onLoadingChange(true);
    track("create-scheduled-report", requestBody);
    postScheduledReport(requestBody)
      .then(() => props.setScheduledReports())
      .then(() => props.close())
      .catch((err) => {
        onLoadingChange(false);
        onErrorChange(true);
      });
  };

  const validate = () => {
    const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailList = contact.split(",");
    const validateContact = emailList.every((email) =>
      emailReg.test(email.trim())
    );
    return outboxes[0] && outboxes[0]["id"] && savedReportId && validateContact;
  };

  const outboxOptions = outboxes.map((o, i) => {
    return { text: o.name, value: o.id, key: i };
  });

  const isHourOutOfRange = hourFromLocalToEST(parseInt(hour)) >= 19;

  return (
    <Container>
      <Form onSubmit={() => onSubmit()} loading={loading} error={error}>
        <Message
          error
          header="Failed to create new scheduled report"
          content="Please contact us for help"
        />
        <Form.Group>
          <Form.Field>
            <label>Outbox</label>
            <Form.Dropdown
              selection
              options={outboxOptions}
              value={outboxId}
              onChange={onOutboxIdChange()}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>Saved Report</label>
            <Form.Dropdown
              selection
              options={reportOptions}
              value={savedReportId}
              onChange={onFieldChange("savedReportId")}
            />
          </Form.Field>
        </Form.Group>
        <ShareFormFields
          filetype={filetype}
          setFiletype={setFiletype}
          days={days}
          setDays={setDays}
          hour={hour}
          setHours={setHours}
          sendOnFinish={sendOnFinish}
          setSendOnFinish={setSendOnFinish}
          isHourOutOfRange={isHourOutOfRange}
        />
        <Form.Group>
          <Form.Field>
            <label>Email</label>
            <Form.Input value={contact} onChange={onFieldChange("contact")} />
          </Form.Field>
        </Form.Group>
        <Button type="submit" disabled={!validate()}>
          Schedule Report
        </Button>
      </Form>
    </Container>
  );
};

export const ScheduleNewReportModal = (props) => {
  const [days, setDays] = useState([
    "Mon",
    "Tues",
    "Wed",
    "Thurs",
    "Fri",
    "Sat",
    "Sun",
  ]);
  const [hour, setHours] = useState(12);
  const [filetype, setFiletype] = useState("csv");
  const [sendOnFinish, setSendOnFinish] = useState(true);

  const {
    onLoadingChange,
    onErrorChange,
    outboxId,
    savedReportId,
    rootFolder,
    outboxes,
    contact,
    onFieldChange,
    onOutboxIdChange,
    savedReports,
    loading,
    error,
  } = props;

  const onSubmit = () => {
    const folder = rootFolder.startsWith("/")
      ? rootFolder.substring(1)
      : rootFolder;

    const obj = {
      saved_report_id: savedReportId,
      outbox_id: outboxId,
      days: days.join(","),
      time: hourFromLocalToEST(parseInt(hour)),
      filetype: filetype,
      destination: `${folder}/{report_type_name}/{segment_name}/{date}/data.${filetype}`,
      contact,
      send_on_pipeline_finish: sendOnFinish,
    };

    onLoadingChange(true);
    track("create-scheduled-report", obj);
    postScheduledReport(obj)
      .then(() => props.setScheduledReports())
      .then(() => props.close())
      .catch((err) => {
        onLoadingChange(false);
        onErrorChange(true);
      });
  };

  const validate = () => {
    const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { outboxId, savedReportId, rootFolder, contact } = props;
    const emailList = contact.split(",");
    const validateContact = emailList.every((email) =>
      emailReg.test(email.trim())
    );
    const badRootFolder = invalidRootFolder(rootFolder)
    return outboxId && savedReportId && rootFolder != "" && !badRootFolder && validateContact;
  };

  const outboxOptions = React.useMemo(
    () => outboxes.map((o, i) => ({ text: o.name, value: o.id, key: i })),
    [outboxes]
  );

  const reportOptions = React.useMemo(
    () => savedReports.map((r, i) => ({ text: r.name, value: r.id, key: i })),
    [savedReports]
  );

  // 5 hour offset between est and utc
  const isHourOutOfRange = hourFromLocalToEST(parseInt(hour)) >= 19;
  return (
    <Container>
      <Form onSubmit={() => onSubmit()} loading={loading} error={error}>
        <Message
          error
          header="Failed to create new scheduled report"
          content="Please contact us for help"
        />
        <Form.Group>
          <Form.Field>
            <label>Outbox</label>
            {outboxOptions && outboxOptions.length === 0 ? (
              <Message negative>
                <p>No available outbox</p>
              </Message>
            ) : (
              <Form.Dropdown
                selection
                options={outboxOptions}
                value={outboxId}
                onChange={onOutboxIdChange()}
              />
            )}
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>Saved Report</label>
            <Form.Dropdown
              selection
              options={reportOptions}
              value={savedReportId}
              onChange={onFieldChange("savedReportId")}
            />
          </Form.Field>
        </Form.Group>
        <ShareFormFields
          filetype={filetype}
          setFiletype={setFiletype}
          days={days}
          setDays={setDays}
          hour={hour}
          setHours={setHours}
          sendOnFinish={sendOnFinish}
          setSendOnFinish={setSendOnFinish}
          isHourOutOfRange={isHourOutOfRange}
        />
        <Form.Group>
          <Form.Field>
            <label>Root Folder</label>
            <Form.Input
              placeholder="/"
              value={rootFolder}
              onChange={onFieldChange("rootFolder")}
              error={invalidRootFolder(rootFolder) && {
                content: 'Please remove the leading slash in Path',
                pointing: 'below',
              }} 
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>Contact</label>
            <Form.Input
              value={contact}
              placeholder="me@example.com”"
              onChange={onFieldChange("contact")}
            />
          </Form.Field>
        </Form.Group>
        <Button type="submit" disabled={!validate()}>
          Schedule Report
        </Button>
      </Form>
    </Container>
  );
};

export default (props) => (
  <Modal
    size="small"
    open={props.open}
    onClose={props.close}
    closeOnDimmerClick={false}>
    <Modal.Header>
      <CloseButtonStyles onClick={props.close}>x</CloseButtonStyles>Schedule New
      Delivery
    </Modal.Header>
    <Modal.Content>
      <Modal.Description>
        {props.outbox_type.indexOf("do_spaces") !== -1 ? (
          <ScheduleEmailReportModal done={props.close} {...props} />
        ) : (
          <ScheduleNewReportModal done={props.close} {...props} />
        )}
      </Modal.Description>
    </Modal.Content>
  </Modal>
);

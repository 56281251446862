import { generateBlankData } from '../../AttributionReport/helpers';

export const computeChartData = data => {
  /**
   * Computes chart data from the given input data.
   *
   * This function organizes the input data into a format suitable for chart rendering, grouped by date.
   *
   * @param {Array<Object>} data - An array of data entries, where each entry represents a data point for a single date/dimension combo.
   * @returns {Object} An object containing chart data and dimensions suitable for rendering.
   *
   * @example
   * // Usage:
   * const data = [
   *   { date: '2023-01-15', dimension: 'A', value: 100 },
   *   { date: '2023-01-16', dimension: 'B', value: 150 },
   *   { date: '2023-01-17', dimension: 'A', value: 200 },
   * ];
   *
   * const result = computeChartData(data);
   * // Result:
   * // {
   * //   chartData: [
   * //     { date: '2023-01-15', A: 100, B: undefined },
   * //     { date: '2023-01-16', A: undefined, B: 150 },
   * //     { date: '2023-01-17', A: 200, B: undefined }
   * //   ],
   * //   dimensions: ['A', 'B']
   * // }
   */
  if (!data || data.length < 1) {
    return {
      chartData: [],
      dimensions: [],
    };
  }

  const dimensions = new Set([]);
  const dates = data.map(d => d.date);
  dates.sort();

  const minDate = dates[0];
  const maxDate = dates[dates.length - 1];

  const blanks = generateBlankData(minDate, maxDate).map(d => Object({ date: d.date }));

  const dataByDate = {};

  blanks.forEach(d => {
    dataByDate[d.date] = {};
  });

  data.forEach(d => {
    const { date, dimension, value } = d;
    dimensions.add(dimension);
    dataByDate[date][dimension] = value;
  });

  data.forEach(d => {
    dimensions.forEach(dim => {
      if (!dataByDate[d.date][dim]) {
        dataByDate[d.date][dim] = 0;
      }
    });
  });

  const chartData = Object.keys(dataByDate).map(d => ({ date: d, ...dataByDate[d] }));

  return {
    chartData,
    dimensions: Array.from(dimensions),
  };
};

export const computeMMMFeedMetrics = data => {
  /**
   * Computes and aggregates Media Mix Modeling (MMM) feed metrics from a given data array.
   *
   * This function calculates minimum, maximum, and total values for each metric type in the data.
   *
   * @param {Array<Object>} data - An array of data entries, where each entry represents metrics.
   * @returns {Object} An object containing aggregated metrics (minimum, maximum, and total) for each metric type.
   *
   * @example
   * // Usage:
   * const data = [
   *   {
   *     date: '2022-11-23', dimension: 'small_big_R', value: 66.69447665
   *   }
   *   {
   *     date: '2022-11-23', dimension: 'small_big_S', value: 45.34534
   *   }
   * ];
   *
   * const result = computeMMMFeedMetrics(data);
   * // Result:
   * // {
   * //   Facebook_Propspecting_S: { min: 1000, max: 1200, total: 2200 },
   * //   Facebook_Retargeting_S: { min: 150, max: 180, total: 330 },
   * //   Adwords_Prospecting_S: { min: 500.75, max: 600.50, total: 1101.25 }
   * // }
   */
  const result = data.reduce((accu, entry) => {
    const { date, dimension, value } = entry;

    // Initialize the key in the result object if it doesn't exist
    if (!accu[dimension]) {
      accu[dimension] = {
        min: undefined,
        max: undefined,
        total: 0,
        minDate: undefined,
        maxDate: undefined,
      };
    }

    // Calculate minimum value
    if (accu[dimension].min === undefined || value < accu[dimension].min) {
      accu[dimension].min = value;
    }

    // Calculate maximum value
    if (accu[dimension].max === undefined || value > accu[dimension].max) {
      accu[dimension].max = value;
    }

    // Calculate minimum date value
    if (accu[dimension].minDate === undefined || date < accu[dimension].minDate) {
      accu[dimension].minDate = date;
    }

    // Calculate maximum date value
    if (accu[dimension].maxDate === undefined || date > accu[dimension].maxDate) {
      accu[dimension].maxDate = date;
    }

    // Calculate total value
    accu[dimension].total += value;

    return accu;
  }, {});
  return result;
};

export const computeMinMaxDates = metrics => {
  /**
   * Computes the minimum and maximum dates from a metrics object and returns them in a formatted representation.
   *
   * @param {object} metrics - The metrics object containing date information in a `date` key.
   * @returns {object} An object with two properties: minDate and maxDate, representing the minimum and maximum dates in MM/DD/YYYY format.
   *
   * @example
   * // Usage:
   * const metrics = {
   *   date: {
   *     min: '2023-01-15',
   *     max: '2023-12-31'
   *   };
   * const result = computeMinMaxDates(metrics);
   * // Result:
   * // {
   * //   minDate: '01/15/2023',
   * //   maxDate: '12/31/2023'
   * // }
   */
  if (!metrics.date) return {};
  const { min, max } = metrics.date;
  const minParts = min.split('-');
  const minDateFormatted = `${minParts[1]}/${minParts[2]}/${minParts[0]}`;
  const maxParts = max.split('-');
  const maxDateFormatted = `${maxParts[1]}/${maxParts[2]}/${maxParts[0]}`;
  return {
    minDate: minDateFormatted,
    maxDate: maxDateFormatted,
  };
};

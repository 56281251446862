import {
  getDataValidInCache,
  clearInvalidCache,
  getDataFromCache,
  setCacheData,
} from './dbOperations';

export const db_checkRequiredDatasets = async (db, requiredDatasets, isLimitedTiers = false) => {
  const checkDb = getDataValidInCache(db);
  const promises = isLimitedTiers ? requiredDatasets
    .map(({ date, identifier, id }) => checkDb(date, 'spend-mta-limited', identifier, id)) : requiredDatasets
    .map(({ date, dataset, identifier, id }) => checkDb(date, dataset, identifier, id));

  const resolved = await Promise.all(promises);
  return resolved;
};

export const db_clearInvalidCache = async (db, invalidCache) => {
  const clearDb = clearInvalidCache(db);
  const promises = invalidCache.map(clearDb);
  const resolved = await Promise.all(promises);
  return resolved;
};

export const db_getDataFromCache = async (db, hasCache) => {
  const getDb = getDataFromCache(db);
  const promises = hasCache.map(getDb);
  const resolved = await Promise.all(promises);
  return resolved;
};

export const db_setCacheData = async (db, dataFromRemote) => {
  const setDb = setCacheData(db);
  const promises = dataFromRemote.map(setDb);
  const resolved = await Promise.all(promises);
  return resolved;
};

export const db_checkCacheState = async (db, requiredDatasets) => {
  const isLimitedTiers = db.name === 'compiled_mta_tiers_limited';
  const cacheState = await db_checkRequiredDatasets(db, requiredDatasets, isLimitedTiers);
  const hasCache = cacheState.filter(row => row.hasCache);
  const missedCache = cacheState.filter(row => !row.hasCache);
  const invalidCache = missedCache.filter(row => row.cache_id);
  console.log(`hasCache: ${hasCache.length}`); // eslint-disable-line no-console
  console.log(`missedCache: ${missedCache.length}`); // eslint-disable-line no-console
  console.log(`invalidCache: ${invalidCache.length}`); // eslint-disable-line no-console
  return { hasCache, missedCache, invalidCache };
};

export const SET_SHOULD_REFETCH_JOBS = "SET_SHOULD_REFETCH_JOBS";
export const DELETE_REPORT = "DELETE_REPORT";
export const REFETCH_JOBS = "REFETCH_JOBS";
export const SET_SCHEDULED_REPORTS = "SET_SCHEDULED_REPORTS";
export const SET_SAVED_REPORTS = "SET_SAVED_REPORTS";
export const FORCE_SEND_ONCE = "FORCE_SEND_ONCE";
export const SET_REPORT_TYPES = "SET_REPORT_TYPES";
export const SCHEDULE_REPORT = "SCHEDULE_REPORT";
export const REPORT_SCHEDULED = "REPORT_SCHEDULED";
export const RESET_SCHEDULE_FORM = "RESET_SCHEDULE_FORM";

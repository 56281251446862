import React from 'react';

import { Input, Form } from 'semantic-ui-react';
import styled from 'styled-components';

import Ace from './Ace';
import Template from './Template';

const FieldWrap = styled.div`
  flex: 0 0 20% !important;
`;

const inputValueToType = (inputValue, selectOptions = []) => {
  if (!inputValue) return 'input';
  if (inputValue.includes('else')) return 'template';
  if (inputValue.startsWith('{{') && inputValue.endsWith('}}')) return 'code';
  if (inputValue.startsWith('{') && inputValue.endsWith('}') && selectOptions.length > 0) return 'select';
  return 'input';
};

const formatValueByType = (value, type) => {
  switch (type) {
    case 'input': return value;
    case 'select': return `{${value}}`;
    case 'code': return `{{${value}}}`;
    case 'template': return `{{${value}}}`;
    default: return value;
  }
};

const TYPE_OPTIONS = [
  { text: 'as text', value: 'input' },
  { text: 'via column', value: 'select' },
  { text: 'via template', value: 'template' },
  { text: 'via code', value: 'code' },
];

const MultiInput = React.memo(({
  disabled = false, id = '', value = '', invalid = false, label = '',
  selectOptions = [], typeOptions = TYPE_OPTIONS, onChange = (() => { }), onBlur = (() => { }),
}) => {
  const [selectType, setSelectType] = React.useState('input');

  const setSelection = (_, evt) => setSelectType(evt.value);
  const setInput = (_, evt) => onChange(false, { value: formatValueByType(evt.value, selectType) });
  const testInput = (_, evt) => onBlur(false, { value: formatValueByType(evt.value, selectType) });

  React.useEffect(() => {
    setSelectType(inputValueToType(value || '', selectOptions));
  }, [id, selectOptions.length]);

  const currentType = React.useMemo(() => inputValueToType(value, selectOptions), [value, selectOptions.length]);
  const selectValue = React.useMemo(() => (value && value.length > 2 ? value.slice(1, -1) : ''), [value]);
  const codeValue = React.useMemo(() => (currentType === 'code' || currentType === 'template' ? value.slice(2, -2) : ''), [value]);

  return (
    <FieldWrap className="field">
      <div style={{ display: 'flex' }}>
        <label style={{ fontWeight: 'bold' }}>{label}</label>
        <div style={{ fontSize: '.8em', color: 'grey', marginLeft: '5px' }}>
          <Form.Dropdown compact value={selectType} options={typeOptions} onChange={setSelection} />
        </div>
      </div>
      {selectType === 'template' && <Template value={codeValue} {...{ selectOptions }} onChange={setInput} />}
      {selectType === 'input' && (
        <Input style={{ border: `1px solid ${invalid ? 'rgb(252, 13, 27)' : 'rgb(221,221,221)'}` }} value={value} onChange={setInput} onBlur={val => testInput(false, { value: val })} />
      )}
      {selectType === 'select' && <Form.Select options={selectOptions || []} defaultValue={selectValue} onChange={setInput} disabled={disabled} />}
      {selectType === 'code' && (
        <Ace.Input
          style={{ border: `1px solid ${invalid ? 'rgb(252, 13, 27)' : 'rgb(221,221,221)'}` }}
          value={codeValue} onChange={val => setInput(false, { value: val })}
          onBlur={val => testInput(false, { value: val })}
        />
      )}
    </FieldWrap>
  );
});

export default Object.assign(MultiInput, {});

import React, { useState, useMemo } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';
import { Table, Pagination } from 'semantic-ui-react';

import GridRow from './GridRow';
import { ContentCardTableFooter, TableHeading, EmptyMessage, HeaderCells } from './parts';
import { selectData, calculatePagerLength, recordsFrom, recordsTo } from '../../utils/pagination';

const ExpandableGrid = ({
  data, cols, className, idKey, expandedComponent, right,
  sorting = false, title = false, itemsPerPage = 10, paginate = false, // Grid Defaults
  emptyIcon, fallbackMsg, // Empty Message Defaults
}) => {
  const [expandKey, setExpandKey] = useState('');
  const [selectedPageNumber, setSelectedPageNumber] = useState(1);
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);

  const sortedData = useMemo(() => {
    if (!column) return data;
    return _.orderBy(data, [column], [direction === 'ascending' ? 'asc' : 'desc']);
  }, [column, direction, data]);

  const selectedData = useMemo(() => (paginate
    ? selectData(sortedData, itemsPerPage, selectedPageNumber)
    : sortedData), [sortedData, paginate, itemsPerPage, selectedPageNumber]);

  const handleSort = clickedColumn => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setDirection('ascending');
      return;
    }
    setDirection(direction === 'ascending' ? 'descending' : 'ascending');
  };

  const pageNumbers = useMemo(() => (calculatePagerLength(sortedData, itemsPerPage)
  ), [sortedData.length, itemsPerPage]);

  const { startingRecord, endingRecord, totalRecords } = useMemo(() => {
    const from = recordsFrom(selectedPageNumber, itemsPerPage);
    const to = recordsTo(selectedPageNumber, itemsPerPage, data.length);
    const all = new Intl.NumberFormat().format(data.length);

    return { startingRecord: from, endingRecord: to, totalRecords: all };
  }, [selectedData, selectedPageNumber]);

  return (
    <>
      <TableHeading {...{ title, right }} />
      <Table className={className} sortable={sorting}>
        <Table.Header>
          <Table.Row>
            <HeaderCells {...{ cols, sorting, column, direction, handleSort }} />
          </Table.Row>
        </Table.Header>
        { selectedData.length > 0 ? (
          <Table.Body>
            {selectedData.map(item => <GridRow {...{ item, cols, expandKey, setExpandKey, idKey, expandedComponent }} />)}
          </Table.Body>
        ) : <EmptyMessage {...{ fallbackMsg, emptyIcon }} />}
        {
          (paginate && selectedData.length > 0) && (
            <ContentCardTableFooter>
              <div>
                {`Showing ${startingRecord}-${endingRecord} out of ${totalRecords} records`}
              </div>
              <Pagination
                onPageChange={(e, { activePage }) => setSelectedPageNumber(activePage)}
                totalPages={pageNumbers}
                firstItem={false}
                lastItem={false}
                ellipsisItem={pageNumbers > 7 ? undefined : null}
                activePage={selectedPageNumber}
              />
            </ContentCardTableFooter>
          )
        }
      </Table>
    </>
  );
};

ExpandableGrid.propTypes = {
  // `data` is an array of objects that the grid will display.
  data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  // `cols` is an array of objects defining the columns of the grid.
  // Each object in the array can have properties like 'headerAs', 'display', and 'headerStyle'.
  cols: PropTypes.arrayOf(PropTypes.shape({
    headerAs: PropTypes.func,
    display: PropTypes.string,
    headerStyle: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  })).isRequired,
  // `className` is a string to apply custom CSS classes to the table.
  className: PropTypes.string,
  // `idKey` is a string that represents the unique key of each data object.
  idKey: PropTypes.string.isRequired,
  // `expandedComponent` is a React component that will be used to render the expanded view.
  expandedComponent: PropTypes.elementType,
  // `itemsPerPage` is a number that determines how many items will be shown per page.
  itemsPerPage: PropTypes.number,
  // `paginate` is a boolean that indicates whether pagination should be used.
  paginate: PropTypes.bool,
};

ExpandableGrid.defaultProps = {
  className: '',
  expandedComponent: null,
  itemsPerPage: 10,
  paginate: false,
};

export default ExpandableGrid;

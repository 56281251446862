import React, { useEffect, useState } from "react";
import moment from "moment";
import ReportCustomizeLayout from "./Layout";
import {
  getDataset,
  getSavedReportSchema,
  sendReportSchema,
} from "../../utils/api";
import {
  getReportType,
  getSavedreports,
  updateSavedReport,
} from "../ReportIndex/api";

const castDefinitionToMap = (definition) => {
  return Object.entries(definition).reduce((prev, [key, value]) => {
    if (value["type"] === "integer") {
      prev[key] = "int";
      return prev;
    }

    if (value["format"]) {
      prev[key] = value["format"].replace("-", "");
      return prev;
    }
    if (value["type"] === "string") {
      prev[key] = "str";
      return prev;
    }

    prev[key] = "json";
    return prev;
  }, {});
};

const ReportCustomize = (props) => {
  const { match: { params: { id: savedReportId } = {} } = {} } = props;

  const [schemas, setSchemas] = useState([]);
  const [report, setReport] = useState({});
  const [optionKeys, setOptionKeys] = useState([]);
  const [newColumn, setNewColumn] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [appliedData, setAppliedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [validationMap, setValidationMap] = useState({});
  const [validationErrors, setValidationErrors] = useState([]);

  let defaultSchema;

  useEffect(() => {
    Promise.all([
      getReportType(),
      getSavedreports(),
      getSavedReportSchema(savedReportId),
    ])
      .then((data) => {
        const [reportTypes, savedReports, reportSchema] = data;
        const savedReport = savedReports.filter(
          (item) => item.id === parseInt(savedReportId)
        )[0];
        const datasets = reportTypes
          .filter((item) => item.id === savedReport.report_type_id)[0]
          ["keys"].split(",");
        const filter_id = savedReport["filter_id"];
        const defaultSchemaStr = reportSchema["info"]["default_schema"];

        const customSchema = reportSchema["info"]["custom_schema"]
          ? reportSchema["info"]["custom_schema"]
          : defaultSchemaStr;
        const definition = Object.values(reportSchema["definitions"])[0][
          "properties"
        ];

        const customSchemaJson = JSON.parse(customSchema);
        let customSchemaToList = Object.entries(customSchemaJson).reduce(
          (prev, cur) => {
            const [key, value] = cur;
            const index = value["order"];
            prev[index] = Object.assign({}, value, {
              name: key,
              description:
                (definition[key] && definition[key]["description"]) || "",
            });
            return prev;
          },
          []
        );

        const defaultSchemaJson = JSON.parse(defaultSchemaStr);
        defaultSchema = Object.entries(defaultSchemaJson).reduce(
          (prev, cur) => {
            const [key, value] = cur;
            const index = value["order"];
            prev[index] = Object.assign({}, value, {
              name: key,
              description:
                (definition[key] && definition[key]["description"]) || "",
            });
            return prev;
          },
          []
        );

        // Handle old custom schema with gap in order, reassign the order
        if (customSchemaToList.length > Object.keys(customSchemaJson).length) {
          customSchemaToList = customSchemaToList
            .filter((item) => item)
            .sort((a, b) => a["order"] - b["order"])
            .map((item, index) => Object.assign({}, item, { order: index }));
        }

        setValidationMap(castDefinitionToMap(definition));
        setSchemas(customSchemaToList);
        setReport(savedReport);
        return Promise.all(
          datasets.map((dataset) => getDataset(dataset, filter_id, 1, 'data'))
        );
      })
      .then((datasets) => {
        let datasetToUse = null;
        for (let dataset of datasets) {
          const [key, value] = Object.entries(dataset["response"])[0];
          if (value && value.length > 0) {
            datasetToUse = key;
            const schemaKeys = Object.keys(value[0]);
            const defaultSchemaKeys = defaultSchema.map(d => d.name);
            const finalKeys = [...schemaKeys, ...defaultSchemaKeys.filter((k) => schemaKeys.indexOf(k) === -1)]
            setOptionKeys(finalKeys);
            break;
          }
        }
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, []);

  useEffect(() => {
    setSchemas((prevState, curProps) => {
      const newState = prevState
        .filter((schema) => optionKeys.indexOf(schema.name) !== -1)
        .map((item, i) => Object.assign({}, item, { order: i }));
      return newState;
    });
  }, [optionKeys]);

  useEffect(() => {
    const errors = [];
    schemas.forEach((schema) => {
      if (
        validationMap[schema.name] &&
        validationMap[schema.name] !== schema.type
      ) {
        errors.push({
          message: `${schema.name} should have type ${
            validationMap[schema.name]
          }`,
        });
      }
    });

    setValidationErrors(errors);
  }, [schemas]);

  const moveRowOrder = (targetOrder, currentOrder) => {
    setSchemas((prevState, curProps) => {
      const temp = prevState[targetOrder];
      const newSchemas = prevState.map((item) => Object.assign({}, item));
      newSchemas[targetOrder] = newSchemas[currentOrder];
      newSchemas[currentOrder] = temp;
      newSchemas[currentOrder]["order"] = currentOrder;
      newSchemas[targetOrder]["order"] = targetOrder;

      return newSchemas;
    });
  };

  const updateSchemaByOrder = (order, field, value) => {
    const newSchemas = schemas.map((item) => Object.assign({}, item));
    newSchemas[order][field] = value;
    setSchemas(newSchemas);
  };

  const updateColumnField = (field, value) => {
    setNewColumn((prevState, currentProps) =>
      Object.assign({}, prevState, { [field]: value })
    );
  };

  const removeSchemaByOrder = (order) => {
    const newSchemas = schemas.map((item) => Object.assign({}, item));

    newSchemas.splice(order, 1);
    for (let i = order; i < newSchemas.length; i++) {
      newSchemas[i]["order"] = i;
    }
    setSchemas(newSchemas);
  };

  const submitNewColumn = () => {
    const index = schemas.length;
    const newObj = Object.assign({}, newColumn, { order: index, new: true });
    const newSchemas = schemas.map((item) => Object.assign({}, item));
    newSchemas.push(newObj);
    setSchemas(newSchemas);
    const newOptionKeys = optionKeys.map((item) => item);
    newOptionKeys.push(newColumn.name);
    setOptionKeys(newOptionKeys);
  };

  const schemaFormat = (schemas) =>
    schemas.reduce((prev, cur) => {
      const key = cur["name"];
      const keys = ["from", "type", "format", "default", "order"];
      if (optionKeys.indexOf(key) === -1) {
        return prev;
      }
      prev[key] = Object.assign({}, cur);
      if (!prev[key]["from"]) {
        prev[key]["from"] = key;
      }
      Object.keys(prev[key]).forEach((fieldKey) => {
        if (keys.indexOf(fieldKey) === -1) {
          delete prev[key][fieldKey];
        }
      });
      return prev;
    }, {});

  const sendReportTransformRequest = () => {
    setLoading(true);
    const yesterday = moment.utc()
      .utcOffset(-5)
      .subtract(1, "days")
      .endOf("day")
      .format("YYYY-MM-DD");

    const body = {
      email: "support@rockerbox.com",
      file_type: "csv",
      outbox_id: null,
      saved_report_id: savedReportId,
      start_date: yesterday,
      end_date: yesterday,
      schema: schemaFormat(schemas),
    };

    return sendReportSchema(body)
      .then((data) => {
        setAppliedData(data["response"]);
        setLoading(false);
      })
      .catch((e) => {
        setHasError(e);
        setLoading(false);
      });
  };

  const sendUpdateSavedReport = () => {
    setLoading(true);

    const body = {
      custom_schema: JSON.stringify(schemaFormat(schemas)),
      inbound_transform_schema: JSON.stringify(schemaFormat(schemas)),
      transform_func: null,
      name: report["name"],
      report_type_id: report["report_type_id"],
      num_days: report["num_days"],
    };

    body["filters"] = report["filters"]
      ? JSON.stringify(report["filters"])
      : "";

    updateSavedReport(savedReportId, body)
      .then((resp) => setLoading(false))
      .catch((e) => {
        setLoading(false);
        setHasError(e);
      });
  };

  const createNewField = () => {
    setSchemas((prevState, curProps) => {
      const newState = prevState.map((item) => Object.assign({}, item));
      const newField = Object.assign({}, newColumn, {
        order: prevState.length,
      });
      newState.push(newField);
      return newState;
    });
    setNewColumn(null);
  };

  const childProps = {
    schemas,
    moveRowOrder,
    updateSchemaByOrder,
    removeSchemaByOrder,
    optionKeys,
    openModal,
    setOpenModal,
    newColumn,
    setNewColumn,
    updateColumnField,
    submitNewColumn,
    sendReportTransformRequest,
    sendUpdateSavedReport,
    appliedData,
    loading,
    setLoading,
    hasError,
    setHasError,
    report,
    createNewField,
    validationMap,
    validationErrors,
  };

  return <ReportCustomizeLayout {...childProps} />;
};

export default ReportCustomize;

import React, { useState } from 'react'
import {Grid, Segment, Header, Button } from 'semantic-ui-react'

import { PixelCnameDomain, AllowedDomains, ExcludedDomains } from '../../../V3/components'
import { InviteUsersWidget } from './Invite';


const PixelCNameView = ({ setNextStep, username, advertiser, completeStep }) => {
  const [cnameComplete, setCnameComplete] = useState(false)


  return (
    <div className='onboarding-cname-view'>
      <Grid
        columns={1}
      >
        <Grid.Row>
          <Grid.Column>
            <Segment
              basic
              textAlign='center'
            >
              <Header as='h1'>
                First-Party Tracking Domain
                <Header.Subheader>
                  Set up your custom tracking domain to improve data reliability and identity resolution.<br/>Rockerbox trackers and pixels will be loaded from a subdomain of your website.
                </Header.Subheader>
              </Header>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={8}>
            <PixelCnameDomain cnameAdded={setCnameComplete} />
            <Button
              size='large'
              fluid
              color='purple'
              className='m-t-25 m-b-30'
              disabled={!cnameComplete}
              onClick={completeStep}
            >
              Next
            </Button>
            <InviteUsersWidget 
              header="Don't have access to your DNS settings?"
              subheader='Invite someone else on your team who has access to help set it up'
              advertiser={advertiser}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        </Grid.Row>
      </Grid>
    </div>
  );

};

export default PixelCNameView

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'd3';
import * as venn from 'venn.js';
import tinycolor from 'tinycolor2'; // eslint-disable-line import/no-extraneous-dependencies

/* eslint-disable no-shadow, no-param-reassign */

/*
>>>>> what data prop needs to look like <<<<<<<<<<<<
  const fakeVennData = [
    { sets: ["Paid_Social"], size: 1000, label: "Paid Social", tooltip: ToolTip, color: 'blue' },
    { sets: ["Email"], size: 600, label: "Email", tooltip: ToolTip, color: 'red', onClick: setBubbleClicked },
    { sets: ["Organic_search"], size: 500, label: "Organic Search", tooltip: ToolTip, color: 'green', onClick: setBubbleClicked },
    { sets: ["A"], size: 100, label: "A", tooltip: ToolTip, color: 'orange', onClick: setBubbleClicked },
    { sets: ["B"], size: 300, label: "B", tooltip: ToolTip, color: 'purple', onClick: setBubbleClicked },
    { sets: ["Paid_Social", "Email"], size: 200, tooltip: ToolTip },
    { sets: ["Paid_Social", "Organic_search"], size: 250, tooltip: ToolTip },
    { sets: ["Paid_Social", "A"], size: 10, tooltip: ToolTip },
    { sets: ["Paid_Social", "B"], size: 10, tooltip: ToolTip },
  ]

  const ToolTip = ({ label, size }) => {
  return (
    <div>
      <p><b>{size}</b> users who intreacted with <b>{label}</b></p>
    </div>
  )
}
*/

export const VennDiagram = ({ id = 'default', data, clicked, width = 500, height = 500, fillOpacity = 0.25, toolTipOnHover = false, noLabel = false }) => {
  const vennDivId = `venn-${id}`;

  useEffect(() => {
    // draw venn diagram
    const div = d3.select(`#${vennDivId}`);
    div.datum(data).call(venn.VennDiagram().width(width).height(height));

    div.select('svg')
      .style('overflow', 'visible');

    // center text in each circle
    d3.selectAll(`#${vennDivId} .venn-circle`)
      .each(function (d) {
        const element = d3.select(this).node();
        const { x, y, width, height } = element.getBoundingClientRect();
        d.x = x;
        d.y = y;
        d.width = width;
        d.height = height;
      });

    // >>>> WIP (need to fix x,y coordinates) <<<<<<icon for each label
    data.forEach(set => {
      if (set.icon) {
        d3.selectAll(`#${vennDivId} .venn-circle`)
          .filter(() => d3.select(this).attr('data-venn-sets') === set.sets.join('_'))
          .select('text')
          .remove();

        d3.selectAll(`#${vennDivId} .venn-circle`)
          .filter(() => d3.select(this).attr('data-venn-sets') === set.sets.join('_'))
          .append('svg:image')
          .attr('xlink:href', set.icon)
          .attr('height', '100px')
          .attr('width', '100px')
          .attr('text-anchor', 'middle');
      }
    });

    // onClick of Venn
    div.selectAll('g')
      .on('click', d => {
        if (d.onClick) {
          d.onClick(d.sets);
        }
      });

    // add a tooltip
    if (toolTipOnHover) {
      const tooltip = d3.select(`#${vennDivId}`).append('div')
        .attr('class', 'venntooltip')
        .attr('id', 'venn-tooltip-component');

      d3.selectAll('.venntooltip')
        .style('position', 'absolute')
        .style('text-align', 'center')
        .style('width', '130px')
        .style('height', '130px')
        .style('background', 'white')
        .style('padding', '2px')
        .style('margin', '0 auto')
        .style('border', '0 px')
        .style('border-radius', '8px')
        .style('opacity', '1');

      // add listeners to all the groups to display tooltip on mouseover
      div.selectAll('g')
        .on('mouseover', d => {
        // sort all the areas relative to the current item
          venn.sortAreas(div, d);

          // Display a tooltip with the current size
          tooltip.transition().duration(400).style('opacity', 0.9);
          ReactDOM.render(React.createElement(d.tooltip, { label: d.sets, size: d.size }), document.getElementById('venn-tooltip-component'));

          div.selectAll('path')
            .style('stroke-opacity', 0)
            .style('stroke', '#fff')
            .style('stroke-width', 3);

          // highlight the current path
          const selection = d3.select(this).transition('tooltip').duration(400);
          selection.select('path')
            .style('stroke-width', 3)
            .style('fill-opacity', d.sets.length === 1 ? 0.4 : 0.1)
            .style('stroke-opacity', 1);
        })

        .on('mousemove', () => {
          tooltip.style('left', `${d3.event.pageX}px`)
            .style('top', `${d3.event.pageY - 28}px`);
        })

        .on('mouseout', () => {
          tooltip.transition().duration(400).style('opacity', 0);
          const selection = d3.select(this).transition('tooltip').duration(400);
          selection.select('.venn-circle  path')
            .style('fill-opacity', fillOpacity)
            .style('stroke-opacity', 0);
        });
    }
  }, [data]);

  useEffect(() => {
    const div = d3.select(`#${vennDivId}`);
    const selected = clicked?.join('_');

    // colors
    d3.selectAll(`#${vennDivId} .venn-circle path`)
      .style('fill', d => d.color)
      .style('fill-opacity', fillOpacity);

    d3.selectAll(`#${vennDivId} .venn-circle text`)
      .style('fill', d => tinycolor(d.color).darken(15).toString())
      .style('font-size', '16px')
      .style('font-weight', '500')
      .style('font-family', "'Poppins',sans-serif")
      .style('cursor', 'pointer')
      .each(function (d) {
        const element = d3.select(this).node();
        if (element.hasAttribute('transform')) return;
        const { x, y, width, height } = element.getBoundingClientRect();
        const xTransform = (d.width / 2) - (x + (width / 2) - d.x);
        const yTransform = (d.height / 2) - (y + (height / 2) - d.y);
        element.setAttribute('transform', `translate(${xTransform}, ${yTransform})`);
      });

    if (noLabel) {
      d3.selectAll(`#${vennDivId} .venn-circle text`)
        .remove();
    }

    div.selectAll('path')
      .style('stroke-opacity', 0)
      .style('stroke', '#fff')
      .style('stroke-width', 3)
      .style('cursor', 'pointer');

    // highlight selected circle
    d3.selectAll(`#${vennDivId} .venn-circle`)
      .filter(function () {
        return d3.select(this).attr('data-venn-sets') === selected;
      })
      .select('path')
      .style('stroke-width', 5)
      .style('stroke', d => d.color)
      .style('stroke-opacity', 0.7)
      .style('fill-opacity', 0.4);

    // highlight selected text
    d3.selectAll(`#${vennDivId} .venn-circle`)
      .filter(function () {
        return d3.select(this).attr('data-venn-sets') === selected;
      })
      .select('text')
      .style('fill', d => tinycolor(d.color).darken(25).toString());
  }, [clicked, data]);

  return (
    <div id={vennDivId} style={{ textAlign: 'center' }} />
  );
};

export default VennDiagram;

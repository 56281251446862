import { useEffect, useState, useMemo } from 'react';

import { IndexGridTree } from '@rockerbox/styleguide';
import { useAtom } from 'jotai';
import * as d3 from 'rockerbox_d3_legacy_clone';

import { columnsAtom } from '../../../atoms';
import { customerKeys, touchPointColors } from '../constants';
import { arrayMatches } from '../helpers';

const f = values => values.filter(x => !x.depth);

export const getTreeGridColumns = (tierColorMap, reportType, customerType, tooltips) => {
  const cellAsForPercentage = reportType === 'mix'
    ? IndexGridTree.PercentageCellAvg
    : IndexGridTree.PercentageCellAvgAcrossForFunnelPosition(
      [customerKeys[customerType][0], customerKeys[customerType][2], customerKeys[customerType][4]],
    );

  return [
    {
      display: 'Channel',
      key: 'group',
      groupBy: ['tier_1', 'tier_2', 'tier_3', 'tier_4', 'tier_5'],
      as: IndexGridTree.NameCell(tierColorMap),
    },
    {
      display: '% First Touch',
      key: customerKeys[customerType][0],
      as: cellAsForPercentage,
      reducer: values => d3.sum(f(values), x => x[customerKeys[customerType][0]]),
      style: { textAlign: 'right' },
      sortable: reportType === 'mix',
    },
    {
      display: 'First Touch',
      key: customerKeys[customerType][1],
      as: IndexGridTree.NumberCell,
      reducer: values => d3.sum(f(values), x => x[customerKeys[customerType][1]]),
      tooltip: tooltips[reportType]?.first,
      style: { textAlign: 'right' },
    },
    {
      display: '% Middle Touch',
      key: customerKeys[customerType][2],
      as: cellAsForPercentage,
      reducer: values => d3.sum(f(values), x => x[customerKeys[customerType][2]]),
      style: { textAlign: 'right' },
      sortable: reportType === 'mix',
    },
    {
      display: 'Middle Touch',
      key: customerKeys[customerType][3],
      as: IndexGridTree.NumberCell,
      reducer: values => d3.sum(f(values), x => x[customerKeys[customerType][3]]),
      tooltip: tooltips[reportType]?.mid,
      style: { textAlign: 'right' },
    },
    {
      display: '% Last Touch',
      key: customerKeys[customerType][4],
      as: cellAsForPercentage,
      reducer: values => d3.sum(f(values), x => x[customerKeys[customerType][4]]),
      style: { textAlign: 'right' },
      sortable: reportType === 'mix',
    },
    {
      display: 'Last Touch',
      key: customerKeys[customerType][5],
      as: IndexGridTree.NumberCell,
      reducer: values => d3.sum(f(values), x => x[customerKeys[customerType][5]]),
      tooltip: tooltips[reportType]?.last,
      style: { textAlign: 'right' },
    },
  ];
};

const getProgressBarColumn = (tooltips, customerType) => {
  const distTooltip = tooltips?.individual_dist;
  const customerDist = [customerKeys[customerType][1], customerKeys[customerType][3], customerKeys[customerType][5]];
  return [
    {
      display: 'Distribution',
      key: 'distribution_progress_bar',
      as: IndexGridTree.ProgressBar(touchPointColors, customerDist),
      headerWidth: 5,
      tooltip: distTooltip,
      sortable: 0,
    },
  ];
};

export const useTreeColumns = (customerType, reportType, tooltips, tierColorMap) => {
  const [colsUrl, setColsUrl] = useAtom(columnsAtom);
  const [customerTypeMappingIndex, setCustomerTypeMappingIndex] = useState([]);
  const [_reportType, setReportType] = useState(reportType); // for switching report type and resetting columns

  const findCustomerKeyIndex = arr => arr.map(v => (
    customerKeys[customerType].findIndex(x => x === v)
  ));

  const updateColsUrl = arr => {
    if (!customerTypeMappingIndex.length) return arr;
    return arr.map((key, i) => {
      if (customerTypeMappingIndex[i] === -1) return key;
      return customerKeys[customerType][customerTypeMappingIndex[i]];
    });
  };

  const setSelectedColumns = selected => {
    const columnNames = selected.map(({ key }) => key);
    const findMappingIndex = findCustomerKeyIndex(columnNames);
    setColsUrl(columnNames);
    setCustomerTypeMappingIndex(findMappingIndex);
  };

  const allColumns = useMemo(() => {
    if (reportType === 'mix') {
      return getTreeGridColumns(tierColorMap, reportType, customerType, tooltips);
    }
    if (reportType === 'individual') {
      const progressColumn = getProgressBarColumn(tooltips, customerType);
      const columns = getTreeGridColumns(tierColorMap, reportType, customerType, tooltips);
      return [...columns, ...progressColumn];
    }
  }, [customerType, reportType, tooltips, tierColorMap]);

  const selectedColumns = useMemo(() => {
    if (!allColumns) return [];
    if (_reportType !== reportType) return allColumns;

    if (colsUrl.length === 0) return allColumns;

    const findSelectedColumns = updateColsUrl(colsUrl).map(key => allColumns.find(col => key === col.key));
    return findSelectedColumns;
  }, [allColumns]);

  useEffect(() => {
    if (!colsUrl.length) return;
    const updatedColsUrl = updateColsUrl(colsUrl);
    setColsUrl(updatedColsUrl);
  }, [customerType]);

  useEffect(() => {
    if (!colsUrl.length) return;
    const findMappingIndex = findCustomerKeyIndex(colsUrl);
    setCustomerTypeMappingIndex(findMappingIndex);
  }, [colsUrl]);

  useEffect(() => {
    if (selectedColumns.some(x => x === undefined)) return;
    const columnNames = selectedColumns.map(({ key }) => key);
    if (arrayMatches(colsUrl, columnNames) || colsUrl.length === 0) return;

    const newColsUrl = colsUrl.map((key, i) => {
      if (customerTypeMappingIndex[i] === -1) return key;
      return customerKeys[customerType][customerTypeMappingIndex[i]];
    });
    if (arrayMatches(newColsUrl, columnNames)) {
      setColsUrl(newColsUrl);
      return;
    }

    setColsUrl([]);
  }, [selectedColumns]);

  useEffect(() => {
    setReportType(reportType);
  }, [reportType]);

  return {
    allColumns,
    selectedColumns,
    setSelectedColumns,
  };
};

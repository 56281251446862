import React from 'react';
import { StructuredGrid } from '../../../components';

const RedistributionTable = ({ focusedCell, setFocusedCell, inputError, data, cols }) => (
  <div className="credit-redistribution-modal">
    <StructuredGrid {...{ focusedCell, setFocusedCell, inputError, data, cols }} className="floating-first-column-grid" />
    {/* <IndexGridNew {...{ focusedCell, setFocusedCell, inputError, data, cols }} className="floating-first-column-grid" /> */}
  </div>
);

export default RedistributionTable;

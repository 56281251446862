import React, { useCallback, useEffect, useState } from 'react';

import { getAccountUsers } from '../../api/account';
import InviteUsersForm from '../../components/InviteUsers/InviteUsersForm';

const FreeInviteUsers = ({ showTitle }) => {
  // eslint-disable-next-line no-unused-vars
  const title = showTitle === undefined ? true : showTitle;
  // eslint-disable-next-line no-unused-vars
  const [loadingUsers, setLoadingUsers] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getAccountUsers().then(data => {
      setUsers(data);
      setLoadingUsers(false);
    });
  }, []);

  const handleManageUpdate = useCallback(() => getAccountUsers().then(data => setUsers(data)));
  return (
    <div className="free-invite-users">
      <div style={{ padding: '25px 5px' }}>
        <h2>Invite your teammates to Rockerbox</h2>
        <InviteUsersForm {...{ handleManageUpdate }} />
      </div>
    </div>
  );
};

export default FreeInviteUsers;

import React, { useEffect } from 'react';
import { Card } from 'semantic-ui-react';
import X2JS from 'x2js';
import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NewspaperIcon } from '@heroicons/react/outline';

import { DividingHeader } from '../../components';

const BLOG_RSS_FEED = 'https://www.rockerbox.com/blog/rss.xml';

const NUM_POSTS = 4;

const Blog = () => {
  const [blogPosts, setBlogPosts] = React.useState([]);

  const x2js = new X2JS();

  useEffect(() => {
    fetch(BLOG_RSS_FEED)
      .then(res => res.text())
      .then(text => x2js.xml2js(text))
      .then(feed => feed?.rss?.channel?.item || [])
      .then(posts => posts.map(p => {
        const { title, link, category, description } = p;
        const timestamp = p?.date?.__text;
        const author = p?.creator?.__text;
        const descriptionParts = description.split('</div>');
        const descriptionP1 = descriptionParts[0];
        const descriptionP2 = descriptionParts[1];
        const imageMatch = descriptionP1.match(/<img[^>]+src="([^">]+)"/);
        const image = imageMatch?.[1];
        const descriptionText = descriptionP2.replaceAll(/<[^>]*>/g, '').trim();
        return {
          title,
          link,
          category,
          preview: `${descriptionText.slice(0, 220)}...`,
          timestamp: moment(timestamp).format('MMMM D, YYYY'),
          author,
          image,
        };
      }))
      .then(posts => posts.slice(0, NUM_POSTS))
      .then(x => setBlogPosts(x));
  }, []);

  return (
    <>
      <DividingHeader
        text="The Rockerbox Blog"
        icon={<NewspaperIcon />}
      />
      <Card.Group itemsPerRow={NUM_POSTS} className="blog-cards">
        {blogPosts.map(post => (
          <Card
            key={post.link}
            image={post.image}
            header={post.title}
            meta={post.timestamp}
            description={post.preview}
            link
            onClick={() => window.open(post.link, '_blank')}
          />
        ))}
      </Card.Group>
    </>
  );
};

export default Blog;

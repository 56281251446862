import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Segment, Icon, Progress, Image } from 'semantic-ui-react';
import * as routes from '../../baseRoutes';
import { PlatformPerformanceSync } from '../../components';

const ProgressBar = props => {
  const { integrations, platformObj, index } = props;
  const [percent, setPercent] = useState(((platformObj.value.length / 30) * 100));
  const onComplete = () => window.location.reload();

  return (
    <>
      <PlatformPerformanceSync {...{ platform: platformObj.text, setPercent, onComplete }} />
      <Segment style={{ margin: '0 0 0 4em', padding: '8px 1em', display: 'flex', alignItems: 'center', zIndex: `${100 - index}` }}>
        <Image src={integrations.find(c => c.platform_key === platformObj.text).icon_path} style={{ height: 15, marginRight: '1em' }} />
        <Progress percent={percent} active color="green" size="small" style={{ flexGrow: 1, margin: 0 }} />
      </Segment>
    </>
  );
};

const SyncingData = props => {
  const { integrations, platformsToSync } = props;

  return ((platformsToSync && platformsToSync.length > 0) && (
  <div style={{ paddingBottom: '1em' }}>
    <Segment style={{ marginBottom: 0, zIndex: 101, borderBottomRightRadius: 0 }}>
      <h3 style={{ marginTop: 4 }}>
        <Icon loading name="spinner" color="purple" size="large" style={{ marginRight: '.5em', marginTop: -2 }} />
        We’re currently syncing data from your advertising platforms. Have other accounts you would like to connect?
        <Link as="a" to={routes.connectIntegration} style={{ cursor: 'pointer', marginLeft: 5, fontFamily: 'Poppins' }}>
          Connect more accounts here
        </Link>
      </h3>
    </Segment>
    <div className="stacked-progress-bars">
      {
        platformsToSync.map((platform, index) => (
          <ProgressBar {...{ platformObj: platform, integrations, index }} key={index} />
        ))
      }
    </div>
  </div>
  ));
};

export default SyncingData;

import React, { useState, useEffect } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { Dropdown } from 'semantic-ui-react';

import { BasicGrid } from '../../../components';

const calculateMean = arr => _.mean(arr);
const calculateStd = arr => {
  const mean = calculateMean(arr);
  const variance = _.mean(arr.map(num => (num - mean) ** 2));
  return Math.sqrt(variance);
};
const calculateMedian = arr => {
  const sortedArr = arr.slice().sort((a, b) => a - b);
  const middle = Math.floor(sortedArr.length / 2);
  if (sortedArr.length % 2 === 0) {
    return (sortedArr[middle - 1] + sortedArr[middle]) / 2;
  }
  return sortedArr[middle];
};
const calculateQuartiles = arr => {
  const sortedArr = arr.slice().sort((a, b) => a - b);
  const q1 = sortedArr[Math.floor(sortedArr.length / 4)];
  const q3 = sortedArr[Math.floor(sortedArr.length * 3 / 4)]; // eslint-disable-line no-mixed-operators
  return [q1, q3];
};

const calculateStatistics = data => {
  const spends = data.map(d => d.spend);
  const mean = calculateMean(spends);
  const std = calculateStd(spends);
  const median = calculateMedian(spends);
  const [q25, q75] = calculateQuartiles(spends);
  return {
    mean,
    std,
    median,
    q25,
    q75,
  };
};

const TimeSeriesTable = ({ data }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [days, setDays] = useState(30);

  useEffect(() => {
    const endDate = moment(data[data.length - 1].day);
    const startDate = moment(endDate).subtract(days, 'days');
    const recentData = data.filter(d => moment(d.day).isBetween(startDate, endDate, undefined, '[]'));
    setFilteredData(recentData);
  }, [days, data]);

  const overallStats = calculateStatistics(data);
  const recentStats = calculateStatistics(filteredData);

  const rows = [
    { key: 'mean', display: 'Mean', overall: overallStats.mean.toFixed(2), recent: recentStats.mean.toFixed(2) },
    { key: 'std', display: 'Standard Deviation', overall: overallStats.std.toFixed(2), recent: recentStats.std.toFixed(2) },
    { key: 'median', display: 'Median', overall: overallStats.median.toFixed(2), recent: recentStats.median.toFixed(2) },
    { key: 'q25', display: '25% Quartile', overall: overallStats.q25.toFixed(2), recent: recentStats.q25 && recentStats.q25.toFixed(2) },
    { key: 'q75', display: '75% Quartile', overall: overallStats.q75.toFixed(2), recent: recentStats.q75 && recentStats.q75.toFixed(2) },
  ];

  const options = [
    { key: 7, text: '7 days', value: 7 },
    { key: 30, text: '30 days', value: 30 },
    { key: 60, text: '60 days', value: 60 },
    { key: 90, text: '90 days', value: 90 },
  ];

  const cols = [
    { key: 'display', display: 'Statistic', cellWidth: 1 },
    { key: 'overall', display: 'Overall', cellWidth: 1 },
    {
      key: 'recent', display: (
        <>
          Last
          {' '}
          <Dropdown
            options={options}
            value={days}
            onChange={(e, { value }) => setDays(value)}
          />
        </>
      ), cellWidth: 1,
    },
  ];

  return (
    <div>
      <BasicGrid data={rows} cols={cols} />
    </div>
  );
};

export default TimeSeriesTable;

export const formatDateWithType = (date, formatType) => {
  const d = new Date(date);
  const month = d.getMonth() + 1;
  const day = d.getDate().toString();
  const year = d.getFullYear();

  if (formatType === 'DD/MM/YYYY') {
    return `${day}/${month}/${year}`;
  }
  if (formatType === 'MM/DD/YYYY') {
    return `${month}/${day}/${year}`;
  }
  if (formatType === 'MM/DD/YYYY HH:MM AM UTC') {
    const hours = d.getHours() % 12 || 12;
    const minutes = d.getMinutes().toString().padStart(2, '0');
    const ampm = d.getHours() >= 12 ? 'PM' : 'AM';

    return `${month}/${day}/${year} ${hours}:${minutes} ${ampm} UTC`;
  }
};

export const extractAndDeduplicateDates = rollup => {
  if (!rollup || !rollup.failures) return [];
  return Array.from(new Set(
    rollup.failures.flatMap(failure => failure.dates.map(date => date.split(' ')[0])),
  ));
};

export const formatDateToLongString = date => {
  const dateString = new Date(`${date}T00:00:00Z`);
  const formatter = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
  });
  return formatter.format(dateString);
};

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { loginAxios, loginGoogle } from '../../../utils/api';
import axios from 'axios';
import {
  Grid,
  Segment,
  Header,
  Form,
  Container,
  Input,
  Button,
  Message
} from 'semantic-ui-react';
import { GatewayWrap, ContentCard } from '@rockerbox/styleguide';
import css from '../button.css';

class Login extends Component {
  state = {
    email: '',
    password: '',
    hasError: false,
    errorMsg: '',
    googleLogin: false
  };

  handleInputChange = event => {
    const target = event.target;
    const name = target.name;
    const value = event.target.value;

    this.setState({
      [name]: value
    });
  };

  handleSubmit = () => {
    const submitObj = {
      username: this.state.email,
      password: this.state.password
    };

    loginAxios(submitObj)
      .then(data => {
        this.setState({ hasError: false, errorMsg: '' });
        this.redirectAfterLogin();
      })
      .catch(e => {
        const error = this.getErrorMessage(e);
        this.setState({ hasError: true, errorMsg: error });
      });
  };

  getErrorMessage = (e) => {
    try {
      return e.response.data.response.error || "There was an error logging you in";
    } catch(e) {
      return "There was an error logging you in"
    }
  }

  redirectAfterLogin = () => {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const next = params.get("next");
    if (next) {
      window.location.href = next;
    } else {
      window.location.href = "/v3";
    }
  }

  componentDidMount() {
    if(window.google) {
      window.google.accounts.id.initialize({
        client_id: '665449023953-ckh1eop8o8uiabn88ai7mkb30fa9h5kv.apps.googleusercontent.com',
        callback: this.onGoogleSignIn,
      })

      window.google.accounts.id.renderButton(document.getElementById("googleLogin"), {
        theme: "filled_blue",
        text: "signin_with",
      })
    }
  }

  showGoogleLogin = () => {
    this.setState({ googleLogin: true, loading: false });
  }

  onGoogleSignIn = (response) => {
    this.setState({ loading: true });
    const token = response.credential;
    const data = {
      'token': token
    };
    loginGoogle(data).then((d) => {
      return d.response;
    }).then((resp) => {
      if (resp.error) this.setState({ hasError: true, errorMsg: resp.error, loading: false });
      else this.redirectAfterLogin();
    });
  }

  render() {
    return (
      <GatewayWrap>
        <Segment raised loading={false}>
          <Header as="h3" color="grey" className="m-b-20">
            Sign in to your Rockerbox account
          </Header>
          {this.state.hasError && (
            <Message negative>
              <Message.Header>{this.state.errorMsg}</Message.Header>
            </Message>
          )}
          {this.state.loading ?
            <ContentCard.CardLoader />
            :
            this.state.googleLogin ? null :
            <Form onSubmit={this.handleSubmit}>
              <Container textAlign="left">
                <Form.Field>
                  <label>Email</label>
                  <Input
                    type="email"
                    fluid
                    icon="envelope open outline"
                    iconPosition="left"
                    placeholder="sally@sample.com"
                    value={this.state.email}
                    name="email"
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    <Grid>
                      <Grid.Column width={5} floated="left">
                        Password
                      </Grid.Column>
                      <Grid.Column width={5} floated="right" textAlign="right">
                        <a href="/password-forgot" tabIndex={-1}>Forgot password?</a>
                      </Grid.Column>
                    </Grid>
                  </label>
                  <Input
                    type="password"
                    fluid
                    icon="shield"
                    iconPosition="left"
                    placeholder="••••••••"
                    value={this.state.password}
                    name="password"
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
              </Container>
              <div className="m-t-20">
                <Button primary fluid size="big" type="submit">
                  Sign in
                </Button>
              </div>
            </Form>
          }
          <button id="googleLogin" disabled={this.state.loading} style={{ display: `${this.state.googleLogin ? "block" : "none"}` }}></button>
          { this.state.googleLogin ?
            <React.Fragment>
              <div style={{marginTop: "15px"}}>
                SSO not enabled for your account?&nbsp;
                <a style={{cursor: "pointer"}} onClick={() => this.setState({ googleLogin: false })}>Back to log in</a>
              </div>
            </React.Fragment>
            :
            <div style={{marginTop: "15px"}}>
                Have Google Sign-In enabled for your account?&nbsp;
                <a style={{cursor: "pointer"}} onClick={() => this.showGoogleLogin()}>Log in here</a>
            </div>
          }
        </Segment>
        <div className="white m-t-30">
          Need help?&nbsp;
          <a href="mailto:support@rockerbox.com" className="white underlined">
            Contact us
          </a>
        </div>
      </GatewayWrap>
    );
  }
}

export default withRouter(Login);

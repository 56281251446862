import React, { useEffect, useMemo, useState } from 'react';

import { Form, Button, Icon, Message } from 'semantic-ui-react';

import { DEFAULT_STRATEGIES } from './constants';
import { getMMMCustomChannels, getMMMCustomSubchannels, postMMMCustomChannel, postMMMCustomSubchannel } from '../../../api/attributionModel';

const ErrorMsg = ({ errors }) => (
  <Message negative style={{ marginRight: 20 }}>
    <Message.Header>We had some issues parsing your file. Please review your data and try uploading again.</Message.Header>
    <ul>
      {
        errors.map(e => (<li>{e}</li>))
      }
    </ul>
  </Message>
);

const Map = ({ onFormSubmit, onCancel, headers, channel, setChannel, strategy, setStrategy, dateColumn, setDateColumn, uploadType, KPIColumn, setKPIColumn, errors }) => {
  const [channels, setChannels] = useState([]);
  const [subchannels, setSubchannels] = useState([]);
  const [showChannelForm, setShowChannelForm] = useState(false);
  const [showSubchannelForm, setShowSubchannelForm] = useState(false);

  const [customChannel, setCustomChannel] = useState('');
  const [customSubchannel, setCustomSubchannel] = useState('');

  const [tinyLoading, setTinyLoading] = useState(false);

  useEffect(() => {
    getMMMCustomChannels().then(setChannels);
    getMMMCustomSubchannels().then(setSubchannels);
  }, []);

  const columnOptions = useMemo(() => (
    headers.filter(h => h !== dateColumn).map(x => ({ text: x, value: x }))
  ), [headers, dateColumn]);

  const channelOptions = useMemo(() => [...channels.map(x => x.name), 'Add New'].map(x => ({ text: x, value: x, key: `${x}_channel` })), [channels]);
  const subchannelOptions = useMemo(() => [...subchannels.map(x => x.name), ...DEFAULT_STRATEGIES].map(x => ({ text: x, value: x, key: `${x}_subchannel` })), [subchannels]);

  const handleChange = (setState, fieldName) => (e, { value }) => {
    if (value === 'Add New') {
      const f = fieldName === 'channel' ? setShowChannelForm : setShowSubchannelForm;
      return f(true);
    }
    setState(value);
  };

  const submitNew = fieldName => value => {
    const post = fieldName === 'channel' ? postMMMCustomChannel : postMMMCustomSubchannel;
    const setter = fieldName === 'channel' ? setChannel : setStrategy;
    const optionsSetter = fieldName === 'channel' ? setChannels : setSubchannels;
    const setForm = fieldName === 'channel' ? setShowChannelForm : setShowSubchannelForm;
    const existing = fieldName === 'channel' ? channels : subchannels;

    const data = {
      name: value,
    };

    setTinyLoading(true);

    post(data).then(() => {
      optionsSetter([...existing, { name: value }]);
      setter(value);
      setTinyLoading(false);
      setForm(false);
    });
  };

  const uploadTypeDisplay = uploadType === 'revenue' ? 'revenue' : 'spend';

  return (
    <>
      { errors.length > 0 && <ErrorMsg {...{ errors }} /> }
      <Form className="mmm-upload-form">
        <Form.Select
          fluid
          label={(
            <label>
              Which column contains the
              {' '}
              <strong>Date</strong>
              ?
            </label>
          )}
          value={dateColumn}
          options={headers.map(x => ({ text: x, value: x }))}
          onChange={(e, { value }) => setDateColumn(value)}
        />
        {dateColumn
          && (
          <Form.Select
            value={KPIColumn}
            options={columnOptions}
            fluid
            onChange={(e, { value }) => setKPIColumn(value)}
            label={(
              <label>
                Which column contains
                {' '}
                <strong>{uploadTypeDisplay}</strong>
                ?
              </label>
            )}
          />
          )}
        {KPIColumn
          && (
          <>
            <label>
              Select or type the channel you&apos;d like to set up:
            </label>
            { showChannelForm
              ? (
                <div className="tiny-buttons">
                  <Form.Input onChange={(e, { value }) => setCustomChannel(value)} />
                  <Button
                    primary
                    size="tiny"
                    disabled={!customChannel || tinyLoading}
                    onClick={() => submitNew('channel')(customChannel)}
                  >
                    <Icon inverted name="check" />
                  </Button>
                  <Button size="tiny" onClick={() => setShowChannelForm(false)}><Icon inverted name="x" /></Button>
                </div>
              )
              : (
                <Form.Select
                  clearable
                  value={channel}
                  options={channelOptions}
                  onChange={handleChange(setChannel, 'channel')}
                />
              )}
            <label>
              Identify a strategy (optional):
            </label>
            { showSubchannelForm
              ? (
                <div className="tiny-buttons">
                  <Form.Input onChange={(e, { value }) => setCustomSubchannel(value)} />
                  <Button
                    size="tiny"
                    primary
                    onClick={() => submitNew('subchannel')(customSubchannel)}
                    disabled={!customSubchannel || tinyLoading}
                  >
                    <Icon inverted name="check" />
                  </Button>
                  <Button size="tiny" onClick={() => setShowSubchannelForm(false)}><Icon inverted name="x" /></Button>
                </div>
              )
              : (
                <Form.Select
                  fluid
                  clearable
                  value={strategy}
                  options={subchannelOptions}
                  onChange={handleChange(setStrategy, 'subchannel')}
                />
              )}
          </>
          )}
        <div>
          <Button
            onClick={onFormSubmit}
            primary
            disabled={!channel || !dateColumn || !KPIColumn}
            style={{ margin: '10 10 10 0' }}
          >
            Validate
          </Button>
          <Button
            onClick={() => onCancel()}
            inverted
            style={{ margin: 10 }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Map;

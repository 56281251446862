import React, { useMemo } from 'react';

import { Header, Icon, Button } from 'semantic-ui-react';

const BetaEnrollment = () => {
  const enrolled = useMemo(() => document.cookie.indexOf('beta=true') > -1, []);

  const toggleEnrollment = () => {
    document.cookie = `beta=${enrolled ? 'false' : 'true'}; path=/;`;
    window.location.reload();
  };

  return (
    <>
      <Header
        as="h3"
        content="Beta Program"
      />
      <p>
        Given the nature of beta products, you may see features added, removed, and edited on a regular basis.
      </p>
      <p>
        {enrolled
        && (
        <Icon
          name="check circle"
          color="green"
        />
        )}
        {'You are currently '}
        <strong>{enrolled ? 'enrolled' : 'not enrolled'}</strong>
        {' in our beta program. '}
        Get early access to new features and help us improve the platform by participating!
        You may opt in or out at any time below.
      </p>
      <Button
        primary={enrolled}
        icon={!enrolled && 'lab'}
        content={enrolled ? 'Leave Beta' : 'Join Beta'}
        onClick={toggleEnrollment}
      />
    </>
  );
};

export default BetaEnrollment;

import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { useDelayUnmount } from './hooks';

/*
TODO:
Deprecate this DrawerFlashing component when components/Drawer is able to use react-dates calendar.
*/

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

const DrawerFlashing = ({ children, openDrawer, onDrawerClose, header, noDelay }) => {
  const [isMounted, setIsMounted] = useState(openDrawer);
  const shouldRenderChild = noDelay ? isMounted : useDelayUnmount(isMounted, 200);

  useEffect(() => {
    openDrawer ? setIsMounted(true) : setIsMounted(false);
  }, [openDrawer]);

  useEffect(() => {
    const close = e => {
      if (e.key === 'Escape') {
        onDrawerClose();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  return shouldRenderChild ? ReactDom.createPortal(
    <>
      <div className={`drawer-overlay ${isMounted ? 'opening' : 'closing'}`} onClick={onDrawerClose} />
      <div className={`drawer-panel ${isMounted ? 'opening' : 'closing'}`}>
        <div style={{ textAlign: 'right', marginBottom: 10, marginRight: -12 }}>
          <Icon name="close" size="large" color="grey" onClick={onDrawerClose} link />
        </div>
        { header && <h2 style={{ marginTop: 0 }}>{header}</h2> }
        <div style={{ flexGrow: 1, overflowY: 'auto' }}>
          {children}
        </div>
      </div>
    </>,
    document.getElementById('drawer-root'),
  ) : null;
};

DrawerFlashing.propTypes = {
  openDrawer: PropTypes.func.isRequired,
  onDrawerClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  header: PropTypes.string,
};

export default DrawerFlashing;

import React from "react";
import { Table } from "semantic-ui-react";
import { IndexGrid, ContentCard } from "@rockerbox/styleguide";

const COLUMN_NUM = 500;

const Tables = ({ displayData }) =>
  Object.keys(displayData).map((key) => {
    const data = displayData[key];
    if (data.length === 0) {
      return null;
    }

    const columns = Object.keys(data[0]);
    const tableNum = parseInt(columns.length / COLUMN_NUM) + 1;
    const resultCols = [];

    for (let i = 0; i < tableNum; i++) {
      const newList = columns.splice(0, Math.min(columns.length, COLUMN_NUM));
      if (newList.length > 0) {
        resultCols.push(newList);
      }
    }

    const body = data.slice(0, 5);

    const tables = resultCols.map((partialCols) => {
      const cols = partialCols.map((column) => {
        return {
          display: column,
          key: column,
          as: IndexGrid.EllipsedCell,
        };
      });

      return <IndexGrid columns={cols.length} data={body} cols={cols} />;
    });

    return (
      <ContentCard
        title={key[0].toUpperCase() + key.slice(1).replace(/\_/g, " ")}>
        {tables}
      </ContentCard>
    );
  });

const DisplayTable = (props) => (
  <Table celled>
    <Tables {...props} />
  </Table>
);

export default DisplayTable;

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { forgotPassword } from '../../../utils/api';
import {
  Grid,
  Segment,
  Header,
  Form,
  Input,
  Button,
  Container
} from 'semantic-ui-react';
import { GatewayWrap } from '@rockerbox/styleguide';

class ForgotPassword extends Component {
  state = {
    email: '',
    loading: false
  };

  handleInputChange = event => {
    this.setState({ email: event.target.value });
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    const submitObj = { email: this.state.email };
    forgotPassword(submitObj).then(data => {
      this.setState({ loading: false });
      this.props.history.push('/password-success')
    });
  };

  render() {
    return (
      <GatewayWrap>
        <Segment raised loading={false}>
          <Header as="h3" color="grey" className="m-b-20">
            Forgot your password?
            <Header.Subheader>
              Enter your email address below and we’ll help you reset your
              password.
            </Header.Subheader>
          </Header>
          <Form onSubmit={this.handleSubmit}>
            <Container textAlign="left">
              <Form.Field>
                <label>Email</label>
                <Input
                  type="email"
                  fluid
                  icon="envelope open outline"
                  iconPosition="left"
                  placeholder="forgetful.frank@example.com"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                />
              </Form.Field>
            </Container>
            <div className="m-t-20">
              <Button primary fluid size="big" disabled={this.state.loading}>
                Request Reset Link
              </Button>
            </div>
          </Form>
        </Segment>
        <div className="white m-t-30">
          <a href="/login" className="white underlined">
            Back to Sign in
          </a>
        </div>
      </GatewayWrap>
    );
  }
}
export default withRouter(ForgotPassword);

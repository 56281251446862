import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import CacheContext from '../../utils/CacheContext'
import { getSegments, getAllIntegrations } from  '../../utils/api'
import { Grid, Header, Icon, Button, Label, Segment } from 'semantic-ui-react'
import { ContentCard } from '@rockerbox/styleguide'


export const Communications = ({ communications }) => {
  if (!communications) return null

  return communications.map((notification) => (
    <CommunicationBanner {...notification} />
  ))
}

const CommunicationBanner = ({ notification_title, display_feature_name, notification_message, notification_link_text, notification_link, dates, filter_ids, sub_type, color, icon }) => {
  const history = useHistory()
  const [state, setState, Context] = React.useContext(CacheContext)
  const segments = Context.getCache(`segments`, getSegments) || []
  const integrations = Context.getCache(`integrations`, getAllIntegrations) || []
  const integrationObject = integrations.find(({ platform }) => platform == sub_type) || {}
  const { display_name } = integrationObject

  const [expanded, setExpanded] = React.useState(false)
  const toggleExpand = () => setExpanded(!expanded)

  // date formatting
  const DATE_FORMAT = 'YYYY-MM-DD'
  const dateRange = dates.map(d => moment(d))
  const minDate = moment.min(dateRange).format(DATE_FORMAT)
  const maxDate = moment.max(dateRange).format(DATE_FORMAT)

  // generate list of segments to display
  const affectedSegments = segments
    .filter(({ action_id }) => filter_ids.includes(action_id))
    .map(({ action_name }) => action_name)
    .join(", ")

  return(
    <ContentCard className='user-notification communications'>
      <Grid fluid columns='equal'>
        <Grid.Row>
          <Grid.Column>
            <Header as="h3">
              <Icon
                name={icon || "exclamation triangle"}
                color={color || "yellow"}
              />
              <Header.Content>
                {notification_title}
                {!!display_name && <>: {display_name}</>}
                <Header.Subheader style={{ marginTop: 3 }}>
                  {notification_message}
                  <Label
                    as='a'
                    size='mini'
                    icon={`chevron ${expanded ? "up" : "down"}`}
                    content={expanded ? "Hide Info" : "More Info"}
                    onClick={toggleExpand}
                  />
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
          {notification_link &&
            <Grid.Column width={3} textAlign='right' verticalAlign='middle'>
              <Button
                primary
                content={notification_link_text}
                onClick={() => history.push(notification_link)}
              />
            </Grid.Column>
          }
        </Grid.Row>
      </Grid>
      {expanded &&
        <Segment color='yellow'>
          <p>
            <Icon name='calendar alternate outline' />
            <strong>Date{dates.length > 1 && 's'}:&nbsp;</strong>
            {minDate}{dates.length > 1 && ` to ${maxDate}`}
          </p>
          <p>
            <Icon name='info circle' />
            <strong>Affected Conversion{affectedSegments.length != 1 && 's'}:&nbsp;</strong>
            {!!affectedSegments.length ? affectedSegments : "All"}
          </p>
        </Segment>
      }
    </ContentCard>
  )
}

function mapStateToProps(state, props) {
  return {
    communications: state.app.communications
  };
}

const ConnectedCommunications = connect(
  mapStateToProps,
  null
)(Communications);

export default ConnectedCommunications;

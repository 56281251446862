/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react';

import PropTypes from 'prop-types';
import { Table, Icon, Header } from 'semantic-ui-react';

import { CardLoader } from '../ContentCard/ContentCard';

const Wrap = props => props.children;

const writeCell = (item, col, otherProps) => {
  // eslint-disable-next-line no-shadow
  const DefaultCell = ({ item, col }) => (
    <Table.Cell width={item[col.cellWidth]}>
      <span>{item[col.key]}</span>
    </Table.Cell>
  );
  const CellComponent = col.as || DefaultCell;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <CellComponent {...{ col, item, ...otherProps }} />
  );
};

const GridRow = ({
  isTotal,
  item,
  warning,
  onRowClick,
  positive,
  selectedRow,
  negative,
  cols,
}) => {
  const style = (isTotal || item.isTotal) ? { fontWeight: 'bold' } : {};

  const warningValue = item[warning];

  return (
    <Table.Row
      onClick={onRowClick ? () => onRowClick(item) : undefined}
      positive={positive || item.positive}
      style={{
        backgroundColor: (selectedRow === item) ? '#f2f2f2' : undefined,
        cursor: onRowClick ? 'pointer' : undefined,
        ...style,
      }}
      warning={warningValue}
      negative={negative === true || item[negative]}
    >
      {cols.map(col => writeCell(item, col, { isTotal, onRowClick, positive, selectedRow, negative }))}
      {' '}
    </Table.Row>
  );
};

const Grid = props => {
  const { sortedHeaders: headers, customHeader = false } = props;
  const TableHeader = (
    <Table.Header>
      { props.headerRows }
      <Table.Row>
        { headers && headers.length > 0 ? headers : props.cols.map(col => (
          col.headerAs
            ? col.headerAs(col)
            : <Table.HeaderCell width={col.headerWidth} style={col.headerStyles}>{col.display}</Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
  );

  return (
    <>
      { customHeader || TableHeader }
      <Table.Body>
        {props.data.map(item => (
          <GridRow {...props} item={item} />
        ))}
      </Table.Body>
      <Wrap>
        {props.footer || null}
      </Wrap>
    </>
  );
};

export const IndexGrid = props => {
  const { cols, data, fallBackMsg, ...tableProps } = props;

  return (
    <Table {...tableProps}>
      { !data && <CardLoader /> }
      { data && data.length > 0 && <Grid cols={cols} {...props} /> }
      { data && data.length === 0 && <EmptyMessage fallBackMsg={fallBackMsg} />}
    </Table>
  );
};

export const EmptyMessage = ({ fallBackMsg = 'No data found' }) => (
  <Table.Body>
    <Table.Row>
      <Table.Cell
        textAlign="center"
        selectable={false}
        className="empty"
      >
        <br />
        <Icon
          circular
          disabled
          name="info"
          size="big"
          className="m-b-10"
        />
        <Header
          as="h3"
          disabled
          content={fallBackMsg}
          className="m-0"
        />
        <br />
      </Table.Cell>
    </Table.Row>
  </Table.Body>
);

IndexGrid.propTypes = {
  /** @deprecated instead, wrap `IndexGrid` component with [ContentCard](#contentcard) and include the `hasTable` prop
   */
  as: PropTypes.element,
  /** Array of grid row objects */
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Array of column objects */
  cols: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    display: PropTypes.string,
    as: PropTypes.element,
  })).isRequired,
  /** Optional fallback message if no data is available */
  fallBackMsg: PropTypes.string,
  UTCOffset: PropTypes.number,
  onRowClick: PropTypes.func,
};

export default IndexGrid;

import React, { useEffect, useMemo, useState } from 'react';
import { useAtom } from 'jotai';
import { Segment } from 'semantic-ui-react';
import { SplitLayout, IndexGridNew } from '@rockerbox/styleguide';
import { searchAtom, analysisAtom, overlapAtom } from '../../../atoms';
import { VennDiagram, NoDataCat, StyledDropdown, DebouncedSearch } from '../../../components';
import PlatformSummary from './PlatformSummary';
import { Loading, cols, revCols, SimpleSubHeader } from '../parts';
import { generateTopInsights, generateVennItem, processMetricsData, selectCustomerTypeMetrics } from './helpers';
import Filter from './Filter';
import { useIndexedPaths, useGetPathsRaw } from './hooks';
import { track, time } from '../../../../utils/tracking';

const getColorMapFromTierColors = tierColors => Object.keys(tierColors || {}).reduce((accu, key) => {
  accu[key.toLowerCase()] = tierColors[key];
  return accu;
}, []);

const useDisplayCols = (hasRevenue, columns, revColumns) => useMemo(() => {
  if (!hasRevenue) return [...columns];
  return [...columns.slice(0, 2), ...revColumns];
}, [hasRevenue]);

const Overlap = props => {
  const { startDate, endDate, onDateChange, firstReportingDate } = props; // Dates
  const { segOptions, segId, setSegId } = props; // // Conversions params
  const { ntfFilter, setNtfFilter, hasNtf } = props; // NTF
  const { tierColors, hasRevenue } = props; // Other

  const platform = 'DEPRECATED';
  const colorMap = getColorMapFromTierColors(tierColors);

  const customerTypeSuffix = ntfFilter === 'all' ? '' : `_${ntfFilter}`;
  const mainMetric = hasRevenue ? 'revenue' : 'count';
  const totalMetric = `total_${mainMetric}`;

  const displayCols = useDisplayCols(
    hasRevenue,   // watch
    cols,
    revCols, // use
  );

  // DATA
  const pathsRaw = useGetPathsRaw(segId, startDate, endDate);
  const { channelOptions, platformOptions, strategyOptions, findMatchingPaths } = useIndexedPaths(pathsRaw);

  // State: Analysis setup
  const [searchValue, setSearchValue] = useAtom(searchAtom);
  const platformDisplayName = searchValue;
  const [analysisType, setAnalysisType] = useAtom(analysisAtom);
  const [overlapType, setOverlapType] = useAtom(overlapAtom);

  // State: Analysis result
  const [paths, setPaths] = useState(false);
  const [overlapChannels, setOverlapChannels] = useState(false);
  const [matchedTokens, setMatchedTokens] = useState(false); // TODO: remove as is unnecessary outside of debugging

  //
  const [overlapMetricsData, setOverlapMetricsData] = useState(undefined);
  const [highLevel, setHighLevel] = useState(undefined);
  const [keyInsightsMetricsData, setKeyInsightsMetricsData] = useState(undefined);

  const [bubbleClicked, setBubbleClicked] = useState(undefined);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [carouselChange, setCarouselChange] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, [startDate, endDate, segId]);

  useEffect(() => {
    if (pathsRaw) setLoading(false);
  }, [pathsRaw]);

  // PROCESS DISPLAY DATA
  useEffect(() => {
    if (!overlapChannels || !paths || !platformDisplayName || !ntfFilter) return;
    if (overlapChannels.length === 0) {
      setLoading(false);
      return;
    }

    // Platform data
    const platformObj = selectCustomerTypeMetrics(paths.platform, customerTypeSuffix);
    const platformVennObj = generateVennItem([platform], platformObj[totalMetric], `${platformDisplayName}`, colorMap);
    platformVennObj.size *= 2; // adjust platformVennObj to scale 100% larger to avoid unintentional overlap amongst channels

    // Overlap data
    const { highLevelOverlaps, keyInsights } = generateTopInsights(
      overlapChannels,
      paths,
      customerTypeSuffix,
      mainMetric, // for sorting channels
      platformObj,
      platform,
      colorMap,
      setBubbleClicked, // for Venn data
    );

    const metricsData = overlapChannels.flatMap(channelKey => {
      const customerTypeObj = selectCustomerTypeMetrics(paths[channelKey], customerTypeSuffix);
      if (customerTypeObj?.overlap_count === 0 || !customerTypeObj?.overlap_count) return []; // Exclude 0 overlaps by customer types
      return processMetricsData(channelKey, platform, customerTypeObj, platformObj, colorMap);
    });

    const keyInsightsMetrics = keyInsights.map(channel => metricsData.find(x => x.key === channel));

    setOverlapMetricsData(metricsData);
    setHighLevel([platformVennObj, ...highLevelOverlaps]);
    setKeyInsightsMetricsData(keyInsightsMetrics);
    setLoading(false);
  }, [paths, overlapChannels, ntfFilter, platformDisplayName]);

  useEffect(() => {
    if (!keyInsightsMetricsData) return;
    const clickedIndex = keyInsightsMetricsData.findIndex(element => element.key === bubbleClicked[0]);
    if (clickedIndex !== carouselIndex) {
      setCarouselIndex(clickedIndex);
    }
  }, [bubbleClicked, keyInsightsMetricsData]);

  useEffect(() => {
    if (!keyInsightsMetricsData) return;
    if (carouselChange) {
      setBubbleClicked([keyInsightsMetricsData[carouselIndex].key]);
      setCarouselChange(false);
    }
  }, [carouselIndex, carouselChange, keyInsightsMetricsData]);

  useEffect(() => {
    if (!platform || !startDate || !endDate) return;
    if (!!loading) {
      time('channels.digital_advertising.overlap.view');
      return;
    }
    track('channels.digital_advertising.overlap.view', {
      platform,
      start_date: startDate,
      end_date: endDate,
    });
  }, [platform, startDate, endDate, loading]);

  useEffect(() => {
    if (searchValue.length < 3) {
      setMatchedTokens(false);
      setPaths(false);
      setOverlapChannels(false);
      return;
    }
    if (!pathsRaw) return;

    const result = findMatchingPaths(searchValue, analysisType, overlapType);
    const {
      matchedTokens: newMatchedTokens,
      paths: newPaths,
      overlapChannels: newOverlapChannels,
    } = result;

    setMatchedTokens(newMatchedTokens);
    setPaths(newPaths);
    setOverlapChannels(newOverlapChannels);
  }, [pathsRaw, searchValue, analysisType, overlapType]);

  const handleSearch = (evt, { value }) => setSearchValue(value);
  const handleAnalysis = (evt, { value }) => {
    setAnalysisType(value);
    setSearchValue('');
    setMatchedTokens(false);
    setPaths(false);
    setOverlapChannels(false);
  };
  const handleOverlapType = (evt, { value }) => setOverlapType(value);

  const analysisOptions = [
    'Channel',
    'Platform',
    'Strategy',
    'Tier 1',
    'Custom Search',
  ].map(text => ({ text, value: text.toLowerCase() }));

  const overlapOptions = [
    'Channel',
    'Platform',
    'Strategy',
    'Tiers',
    'Inspect (Channel, Platform, Strategy)',
  ].map(text => ({ text, value: text.toLowerCase().split(' ')[0] }));

  const hasData = overlapChannels && overlapChannels.length !== 0 && overlapMetricsData && overlapMetricsData.length > 0 && highLevel && !loading;

  return (
    <div>
      <Filter
        {...{
          loading,
          platform, // data
          startDate,
          endDate,
          onDateChange,
          firstReportingDate, // dates
          ntfFilter,
          setNtfFilter,
          hasNtf, // ntf
          segOptions,
          segId,
          setSegId, // segments
        }}
      />
      <SplitLayout
        leftWidth={10}
        leftContent={(
          <>
            {/* <SimpleHeader {...{ headerText: "Setup Analysis" }} /> */}
            <SimpleSubHeader {...{ headerText: 'Choose the marketing you want to inspect for overlap and how you want to see overlap to be broken down' }} />
            <Segment className="filter-wrap-v3">
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
                <div style={{ display: 'flex', flex: '1' }}>
                  <StyledDropdown label="Inspect Marketing by" value={analysisType} options={analysisOptions} onChange={handleAnalysis} />
                  {analysisType === 'channel' ? (
                    <StyledDropdown label="Channel" value={searchValue} onChange={handleSearch} options={channelOptions} />
                  ) : analysisType === 'platform' ? (
                    <StyledDropdown label="Platform" value={searchValue} onChange={handleSearch} options={platformOptions} />
                  ) : analysisType === 'strategy' ? (
                    <StyledDropdown label="Strategy" value={searchValue} onChange={handleSearch} options={strategyOptions} />
                  ) : (
                    <DebouncedSearch value={searchValue} onChange={handleSearch} placeholder="e.g. retarget" />
                  )}
                </div>
                <div style={{ display: 'flex', flex: '1' }}>
                  <StyledDropdown label="Analyze Overlap by" value={overlapType} options={overlapOptions} onChange={handleOverlapType} />
                </div>
              </div>
            </Segment>
            {searchValue.length === 0 && pathsRaw ? (
              <div style={{ width: '50%' }}>
                <center>You must select marketing you want to analyze above</center>
              </div>
            ) : null}
            {searchValue && pathsRaw && (!overlapChannels || overlapChannels.length === 0) ? <NoDataCat message="No data matches your selection." /> : null}
            {searchValue && overlapChannels && overlapChannels.length !== 0 ? (
              <>
                <SimpleSubHeader {...{ headerText: 'Selection Summary' }} />
                <PlatformSummary
                  {...{
                    platformIcon: false,
                    platformDisplayName,
                    platformMetricsObj: selectCustomerTypeMetrics(paths?.platform, customerTypeSuffix),
                    hasRevenue,
                  }}
                />
                {
                  // TODO: have option to inspect paths
                  false && matchedTokens && JSON.stringify(matchedTokens.map(path => path.join('___')))
                }
              </>
            ) : null}

            {hasData && (
              <>
                <SimpleSubHeader {...{ headerText: 'Overlap Analysis' }} />
                <br />
                <IndexGridNew
                  sortable
                  cols={displayCols}
                  data={overlapMetricsData}
                  topRight
                  style={{ marginTop: 0 }}
                  textAlign="right"
                  defaultSortCol="count_overlap"
                />
              </>
            )}
          </>
        )}
        rightWidth={6}
        rightContent={
          hasData && (
            <>
              <br />
              <br />
              <br />
              <br />
              <VennDiagram
                id={platform}
                data={highLevel}
                clicked={bubbleClicked}
                height={340}
              />
            </>
          )
        }
      />
      <div>
        {!overlapChannels && !loading ? (
          <NoDataCat
            message={
              searchValue === '' ? (
                <>Choose a piece of marketing to analyze to get started</>
              ) : (
                <>
                  No Data
                  <p style={{ fontSize: '16px' }}>No overlap was found between the platform and other marketing channels</p>
                </>
              )
            }
          />
        ) : null}
        {loading && <Loading />}
      </div>
    </div>
  );
};

export default Overlap;

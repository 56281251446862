import React from 'react';

import CacheContext from '../../utils/CacheContext';
import { getBatchReports, getBatchTriggers, updateBatchTrigger } from '../../utils/api';
import { Form, Button } from 'semantic-ui-react';
import { IndexGrid, ContentCard } from '@rockerbox/styleguide';
import Cadence from './Cadence';

const RULE_COLUMNS = [
  {display: "Inbox", key: "inbox_name"},
  {display: "Report", key: "report_name"}
]

const Rules = ({ groupId, rules }) => {

  const [processedReportId, setProcessedReportId] = React.useState(false)
  const [inboxId, setInboxId] = React.useState(false)
  const [showForm, setShowForm] = React.useState(false)
  const [triggerCadence, setTriggerCadence] = React.useState('No')

  const [state, setState, Context] = React.useContext(CacheContext);
  const reportOptions = Context.getCache('reportOptions', getBatchReports) || [];

  React.useEffect(()=> {
    getBatchTriggers(groupId)
      .then(resp => {
        const value =  resp[0] || {};
        const jobs = value.jobs[0] || {};
        const cadenceValue = jobs.days_delivery_cadence || 'No';
        setTriggerCadence(cadenceValue);})
  }, [groupId])



  const inboxOptions = React.useMemo(() => {
    return Object.values(reportOptions.reduce((p,row) => {
      p[row.inbox_id] = {value: row.inbox_id, text: row.inbox_name}
      return p
    },{}))
  }, [reportOptions.length])

  const filteredReportOptions = React.useMemo(() => {
    return reportOptions.filter(row => row.inbox_id == inboxId)
  }, [inboxId])

  const submit = () => {
    const newReports = rules.map(row => { return { processed_report_id: row.report_id }})
    newReports.push({processed_report_id: processedReportId})
    updateBatchTrigger(groupId, {reports: newReports})
      .then(() => {
        setShowForm(false)
        setProcessedReportId(false)
        setInboxId(false)
        setState({triggers: undefined})
      })
  }

   const updateCadence = () => {
    updateBatchTrigger(groupId, {days_delivery_cadence: triggerCadence})
      .then((data) => {
        console.log(data)
      })
      .catch(err => {
        console.log(err)
      })
   }

  const options = React.useMemo(()=>{
    const cadenceOptions = [
      {
        key: triggerCadence,
        text: `${triggerCadence} day cadence`,
        value: triggerCadence,
      }
    ];

    for (let i of [1, 7, 30]) {
      const isExist = cadenceOptions.find(item => item.key === i);

      if (!isExist) {
        cadenceOptions.push({
          key: i,
          text: `${i} day cadence`,
          value: i
        })
      }
    }

    return cadenceOptions;
  }, [triggerCadence])

  return <React.Fragment>
     <ContentCard title={"Wait until we receive:"} hasTable topRight={<Cadence optionsABC={options} triggerValue={triggerCadence} setTriggerCadence={setTriggerCadence} updateCadence={updateCadence} groupId = {groupId}/>}>
      <IndexGrid data={rules} cols={RULE_COLUMNS} />
    </ContentCard>
    { !showForm && <Button icon="plus" onClick={() => setShowForm(true)} /> }
    { showForm && <Form>
        <Form.Group>
          <label>From</label>
          <Form.Dropdown selection options={inboxOptions} value={inboxId} onChange={(_, event) => setInboxId(event.value)} />
          <label>Report</label>
          <Form.Dropdown selection options={filteredReportOptions} value={processedReportId} onChange={(_, event) => setProcessedReportId(event.value)} />
        </Form.Group>
        <Button onClick={submit}>Add</Button>
      </Form>
    }
  </React.Fragment>
}

export default Rules;

/* eslint max-len: 0 */
import { useMemo } from 'react';
import { useAtom } from 'jotai';

import * as d3 from 'd3';

import { IndexGridTree } from '@rockerbox/styleguide';

import { spendFormatter } from '../../../utils/valueFormatter';
import { tiersAtom, columnsAtom, analysisTypeAtom } from '../../../atoms';
import { TIERS, DERIVED_TIERS } from '../../../constants/tiers';

const f = values => values.filter(x => !x.depth);

export const getTreeGridColumns = (tierColorMap, conversionKey, currencyCode, revenueKey, tiersSummaryRawTotals = {}, comparisonSummaryRawTotals = {}, compare = false) => {
  const compareConversionKey = `${conversionKey}_compare`;
  const compareRevenueKey = `${revenueKey}_compare`;
  return [
    {
      id: 'group',
      display: 'Channel',
      key: 'group',
      groupBy: [...TIERS], // Add Derived Tiers?
      as: compare ? IndexGridTree.CompareNameCell(tierColorMap) : IndexGridTree.NameCell(tierColorMap),
    },
    {
      id: 'conversion_key',
      display: 'Conversions',
      key: conversionKey,
      compareKey: compareConversionKey,
      as: compare ? IndexGridTree.CompareNumberCellTwoDecimals : IndexGridTree.NumberCellTwoDecimals,
      reducer: values => d3.sum(f(values), x => x[conversionKey]),
      compareReducer: values => d3.sum(f(values), x => x[compareConversionKey]),
      summaryLabel: 'Total',
      style: { textAlign: 'right' },
    },
    {
      id: 'conversion_percent_key',
      display: '% of Conversions',
      key: conversionKey,
      compareKey: compareConversionKey,
      as: compare ? IndexGridTree.ComparePercentageCellOfTotal(tiersSummaryRawTotals[conversionKey], comparisonSummaryRawTotals[conversionKey]) : IndexGridTree.PercentageCellOfTotal(tiersSummaryRawTotals[conversionKey]),
      reducer: values => d3.sum(f(values), x => x[conversionKey]),
      compareReducer: values => d3.sum(f(values), x => x[compareConversionKey]),
      style: { textAlign: 'right' },
    },
    {
      id: 'revenue_key',
      display: 'Revenue',
      key: revenueKey,
      compareKey: compareRevenueKey,
      as: compare ? IndexGridTree.CompareSpendCell(spendFormatter(currencyCode)) : IndexGridTree.SpendCell(spendFormatter(currencyCode)),
      reducer: values => d3.sum(f(values), x => x[revenueKey]),
      compareReducer: values => d3.sum(f(values), x => x[compareRevenueKey]),
      summaryLabel: 'Total',
      style: { textAlign: 'right' },
    },
    {
      id: 'revenue_percent_key',
      display: '% of Revenue',
      key: revenueKey,
      compareKey: compareRevenueKey,
      as: compare ? IndexGridTree.ComparePercentageCellOfTotal(tiersSummaryRawTotals[revenueKey], comparisonSummaryRawTotals[revenueKey]) : IndexGridTree.PercentageCellOfTotal(tiersSummaryRawTotals[revenueKey]),
      reducer: values => d3.sum(f(values), x => x[revenueKey]),
      compareReducer: values => d3.sum(f(values), x => x[compareRevenueKey]),
      style: { textAlign: 'right' },
    },
    {
      id: 'spend',
      display: 'Spend',
      key: 'spend',
      compareKey: 'spend_compare',
      as: compare ? IndexGridTree.CompareSpendCell(spendFormatter(currencyCode)) : IndexGridTree.SpendCell(spendFormatter(currencyCode)),
      reducer: values => d3.sum(f(values), x => x.spend),
      compareReducer: values => d3.sum(f(values), x => x.spend_compare),
      summaryLabel: 'Total',
      style: { textAlign: 'right' },
    },
    {
      id: 'spend_percent',
      display: '% of Spend',
      key: 'spend',
      compareKey: 'spend_compare',
      as: compare ? IndexGridTree.ComparePercentageCellOfTotal(tiersSummaryRawTotals.spend, comparisonSummaryRawTotals.spend) : IndexGridTree.PercentageCellOfTotal(tiersSummaryRawTotals.spend),
      reducer: values => d3.sum(f(values), x => x.spend),
      compareReducer: values => d3.sum(f(values), x => x.spend_compare),
      style: { textAlign: 'right' },
    },
    {
      id: 'cpa',
      display: 'CPA',
      key: 'cpa',
      compareKey: 'cpa_compare',
      as: compare ? IndexGridTree.CompareCpaCell(conversionKey, spendFormatter(currencyCode)) : IndexGridTree.CpaCell(conversionKey, spendFormatter(currencyCode)),
      reducer: values => (d3.sum(f(values), x => x.spend) ? d3.sum(f(values), x => x.spend) / d3.sum(f(values), x => x[conversionKey]) : 0),
      style: { textAlign: 'right' },
      arqueroCalc: {
        cpa: `d => d.spend / d['${conversionKey}']`,
        cpa_compare: `d => d.spend_compare / d['${compareConversionKey}']`,
      },
    },
    {
      id: 'roas',
      display: 'ROAS',
      key: 'roas',
      compareKey: 'roas_compare',
      as: compare ? IndexGridTree.CompareRoasCell(revenueKey, spendFormatter(currencyCode)) : IndexGridTree.RoasCell(revenueKey, spendFormatter(currencyCode)),
      reducer: values => (d3.sum(f(values), x => x.spend) ? d3.sum(f(values), x => x[revenueKey]) / d3.sum(f(values), x => x.spend) : 0),
      style: { textAlign: 'right' },
      arqueroCalc: {
        roas: `d =>  d['${revenueKey}'] / d.spend`,
        roas_compare: `d =>  d['${compareRevenueKey}'] / d.spend_compare`,
      },
    },
  ];
};

const getOptionalTreeGridColumns = (conversionKey, currencyCode, revenueKey, compare = false) => {
  const compareConversionKey = `${conversionKey}_compare`;
  const compareRevenueKey = `${revenueKey}_compare`;
  return [
    {
      id: 'revenue_per_conversions',
      display: 'Revenue Per Conversions',
      key: 'revenue_per_conversions',
      compareKey: 'revenue_per_conversions_compare',
      as: compare ? IndexGridTree.CompareRevenuePerConversionsCell(spendFormatter(currencyCode), revenueKey, compareRevenueKey, conversionKey, compareConversionKey) : IndexGridTree.RevenuePerConversionsCell(spendFormatter(currencyCode), revenueKey, conversionKey),
      reducer: values => (d3.sum(f(values), x => x[conversionKey]) ? d3.sum(f(values), x => x[revenueKey]) / d3.sum(f(values), x => x[conversionKey]) : 0),
      style: { textAlign: 'right' },
      arqueroCalc: {
        revenue_per_conversions: `d =>  d['${revenueKey}'] / d['${conversionKey}']`,
        revenue_per_conversions_compare: `d =>  d['${compareRevenueKey}'] / d['${compareConversionKey}']`,
      },
    },
  ];
};

export const useTreeColumns = (tiersData, tierColorMap, conversionKey, currencyCode, revenueKey, tiersSummaryRawTotals, comparisonSummaryRawTotals = {}, compare = false) => {
  const [savedColumnIds, setSavedColumnIds] = useAtom(columnsAtom);
  const [tiers, setTiers] = useAtom(tiersAtom);
  const [analysisType] = useAtom(analysisTypeAtom);

  const allColumns = useMemo(() => (
    getTreeGridColumns(tierColorMap, conversionKey, currencyCode, revenueKey, tiersSummaryRawTotals, comparisonSummaryRawTotals, compare)
  ), [tierColorMap, conversionKey, currencyCode, revenueKey, comparisonSummaryRawTotals, compare]);

  const optionalColumns = useMemo(() => (
    getOptionalTreeGridColumns(conversionKey, currencyCode, revenueKey, compare)
  ), [conversionKey, currencyCode, revenueKey, compare]);

  const selectedColumns = useMemo(() => {
    if (!tiersData || tiersData.length === 0) return []; // if no data, return empty array
    if (savedColumnIds && savedColumnIds.length === 0) return allColumns;
    const allCols = [...allColumns, ...optionalColumns];
    // since order matters... use the ordered that is saved
    const cols = savedColumnIds
      .flatMap(id => allCols.find(c => c.id == id) || []); // eslint-disable-line

    // if there are no selected columns, return all columns
    if (cols.length === 0) return allColumns;

    return cols;
  }, [savedColumnIds, allColumns, optionalColumns, conversionKey, tiersData]);

  const setColumns = selectedCols => {
    const keys = selectedCols.map(({ id }) => id);
    setSavedColumnIds(keys);
  };

  const addDerivedTiers = DERIVED_TIERS.includes(analysisType) ? DERIVED_TIERS : [];

  return {
    tiers: tiers.length ? tiers : [...TIERS, ...addDerivedTiers],
    setTiers,
    allColumns: [...allColumns, ...optionalColumns],
    selectedColumns,
    setColumns,
  };
};

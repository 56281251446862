export function exportCSV(data, name = "data.csv") {

  const columns = Object.keys(data[0])
  const rows = data.map(row => columns.map(c => row[c]))
  const csvContent = "data:text/csv;charset=utf-8," 
    + columns.join(",") + "\n" 
    + rows.map(e => e.join(",")).join("\n");

  const link = document.createElement("a");
  link.setAttribute("href", csvContent);
  link.setAttribute("download", name);
  document.body.appendChild(link); // Required for FF

  link.click();

}

import React from 'react';

// parts
import FirstPartyAttributedPerformance from './FirstPartyAttributedPerformance';
import { MainHeader } from './stateless';
import { FilterWrapper, StyledDropdown, SelectAPlatform, NoConnections } from '../../components';

// state
import useAuthedIntegrations from '../../hooks/useAuthedIntegrations';
import { channelPerformancePlatformsConfig } from '../../constants/mappings';

const GatedByPlatformSelection = props => {
  const platformSelection = useAuthedIntegrations(channelPerformancePlatformsConfig);
  const { onDropdownChange, dropdownOptions, dropdownOptionsLoading } = platformSelection;
  const { urlState } = props;
  const { platform } = urlState;

  // Force a integration before viewing page
  if (!!dropdownOptions && !dropdownOptions.length) return <NoConnections />;

  // Force the selection of a platform
  if (!platform || platform === '0' || platform === '') {
    return (
      <>
        <div>
          <MainHeader />
        </div>
        {dropdownOptions?.length === 0 ? null
          : (
            <FilterWrapper
              {...{ loading: dropdownOptionsLoading }}
              other={(
                <StyledDropdown
                  label="Ad Platform"
                  defaultOpen={true}
                  value={platform}
                  options={dropdownOptions}
                  onChange={onDropdownChange}
                />
          )}
            />
          )}
        <SelectAPlatform {...{ dropdownOptionsLoading, dropdownOptions }} />
      </>
    );
  }

  return (
    <>
      <MainHeader />
      <FirstPartyAttributedPerformance {...{ platformSelection, urlState }} />
    </>
  );
};

export default GatedByPlatformSelection;

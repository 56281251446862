import React, { useState, useEffect } from 'react';
import { StatCard, SplitLayout, PagedIndexGrid, IndexGrid, ContentCard } from '@rockerbox/styleguide';
import { Grid, Statistic } from 'semantic-ui-react';

import { SetupJoinMessage, JoinMessage } from './Messages';
import { FormDropdown } from './parts';
import useOptions from '../hooks';

const buildOptions = data => (data ? data.map(({ key }) => ({ key, text: key, value: key })) : []);

const EventSetup = ({
  handleChange,
  survey, surveyKey,
  conversion, conversionKey,
  setupOnly,
}) => {
  const { segmentOptionsDropdown } = useOptions();

  const [keyJoin, setKeyJoin] = useState([]);
  const [joinSummary, setJoinSummary] = useState({});
  const { surveyData, conversionData } = useOptions(survey, conversion);

  const summaryOptions = buildOptions(surveyData.summary).filter(({ text }) => !text.includes('_followup'));
  const conversionOptions = buildOptions(conversionData.summary).filter(({ text }) => !text.includes('_followup'));

  useEffect(() => {
    if (surveyData.conversion_data && conversionData.conversion_data) {
      const right = surveyData.conversion_data.reduce((accu, c) => {
        accu[c[surveyKey]] = c;
        return accu;
      }, {});

      const surveySet = new Set(Object.keys(right));
      const conversionSet = new Set();

      const keyJoinFiltered = conversionData.conversion_data.map(row => {
        const conversion_key = row[conversionKey];
        conversionSet.add(conversion_key);
        const survey_key = (right[conversion_key] || {})[surveyKey];
        return { conversion_key, survey_key };
      });

      const overlap = keyJoinFiltered.filter(({ conversion_key, survey_key }) => conversion_key === survey_key);

      const joinSummaryUpdate = {
        surveys: surveySet.size,
        conversions: conversionSet.size,
        overlap: overlap.length,
      };

      setKeyJoin(keyJoinFiltered);
      setJoinSummary(joinSummaryUpdate);
    }
  }, [surveyData, conversionData, conversionKey, surveyKey]);

  if (setupOnly) {
    return (
      <>
        <SetupJoinMessage />
        <FormDropdown
          onChange={(e, { value }) => handleChange(value, 'conversion')}
          label="Conversion Segment"
          value={parseInt(conversion)}
          options={segmentOptionsDropdown}
        />
        <SplitLayout
          leftWidth={8}
          rightWidth={8}
          leftContent={(
            <FormDropdown
              loading={!conversionOptions.length}
              options={conversionOptions}
              label="Conversion Key:"
              value={conversionKey}
              onChange={(e, d) => handleChange(d.value, 'conversionKey')}
            />
          )}
          rightContent={(
            <FormDropdown
              loading={!summaryOptions.length}
              options={summaryOptions}
              label="Survey Key:"
              value={surveyKey}
              onChange={(e, d) => handleChange(d.value, 'surveyKey')}
            />
          )}
        />

        { !!conversionOptions.length && !!summaryOptions.length && conversionKey && surveyKey
        && (
        <Statistic.Group size="mini" style={{ 'justify-content': 'space-between', margin: 1 }}>
          <StatCard style={{ flex: 1, margin: 5 }} value={joinSummary.conversions} label="Conversions" />
          <StatCard style={{ flex: 1, margin: 5 }} value={joinSummary.surveys} label="Responses" />
          <StatCard style={{ flex: 1, margin: 5 }} value={joinSummary.overlap} label="Overlap" />
          <StatCard style={{ flex: 1, margin: 5 }} value={joinSummary.overlap / joinSummary.conversions} label="Response Rate" type="percent" />
        </Statistic.Group>
        )}
      </>
    );
  }

  return (
    <Grid columns="equal">
      <Grid.Column>
        <ContentCard title="Associate Survey Responses to Conversion">
          <JoinMessage />
          <FormDropdown
            onChange={(e, { value }) => handleChange(value, 'conversion')}
            label="Conversion Segment"
            value={parseInt(conversion)}
            options={segmentOptionsDropdown}
          />
          <SplitLayout
            leftWidth={8}
            rightWidth={8}
            leftContent={(
              <FormDropdown
                options={conversionOptions}
                label="Conversion Key:"
                value={conversionKey}
                onChange={(e, d) => handleChange(d.value, 'conversionKey')}
              />
            )}
            rightContent={(
              <FormDropdown
                options={summaryOptions}
                label="Survey Key:"
                value={surveyKey}
                onChange={(e, d) => handleChange(d.value, 'surveyKey')}
              />
            )}
          />
        </ContentCard>

      </Grid.Column>
      <Grid.Column>
        <ContentCard title="Results of Conversion / Survey Association" noContent />
        <Statistic.Group size="mini" style={{ 'justify-content': 'space-between', margin: 1 }}>
          <StatCard style={{ flex: 1, margin: 5 }} value={joinSummary.conversions} label="Conversions" />
          <StatCard style={{ flex: 1, margin: 5 }} value={joinSummary.surveys} label="Surveys" />
          <StatCard style={{ flex: 1, margin: 5 }} value={joinSummary.overlap} label="Overlap" />
          <StatCard style={{ flex: 1, margin: 5 }} value={joinSummary.overlap / joinSummary.conversions} label="Response Rate" type="percent" />
        </Statistic.Group>
        <br />
        <ContentCard hasTable>
          <PagedIndexGrid itemsPerPage={10} cols={IndexGrid.arrayToColumns(['survey_key', 'conversion_key'])} data={keyJoin} />
        </ContentCard>
      </Grid.Column>
    </Grid>
  );
};

export default EventSetup;

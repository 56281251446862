import React, { useMemo, useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { CheckCircleIcon } from '@heroicons/react/outline';
import { IndexGridNew } from '@rockerbox/styleguide';
import toast from 'react-hot-toast';
import { Checkbox, Table, Button } from 'semantic-ui-react';

import { getUTMDisplayName } from './helpers';
import { PagesCell, FeedbackCell } from './parts';
import { track } from '../../../utils/tracking';
import { postPixelTrackingResolution } from '../../api/attributionPixels';
import { TreeTableLoader } from '../../components/loaders';

const STANDARD_UTMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
];

const FixedWidthCell = ({ item, col }) => (
  <Table.Cell>
    <div style={{ maxWidth: 150, overflowWrap: 'break-word' }}>{item[col.key]}</div>
  </Table.Cell>
);

const MissingParams = ({ missingParamsData, setMissingParamsData, platform }) => {
  const [showResolved, setShowResolved] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const filteredParamsData = useMemo(() => {
    if (!missingParamsData) return null;
    if (showResolved) return missingParamsData;

    return missingParamsData.filter(p => !(p.utm_medium === 'organic' || p.resolution !== undefined));
  }, [missingParamsData, showResolved]);

  const hasIssues = useMemo(() => {
    if (!missingParamsData) return false;
    return missingParamsData.some(p => !p.resolution);
  }, [missingParamsData]);

  const PlatformFeedbackCell = useMemo(() => FeedbackCell(platform), [platform]);

  const columns = useMemo(() => {
    if (!missingParamsData) return;
    const utmsInData = missingParamsData.reduce((acc, curr) => {
      Object.keys(curr).forEach(k => {
        if (k.startsWith('utm_') && !acc.includes(k)) acc.push(k);
      });
      return acc;
    }, []);

    const utmCols = STANDARD_UTMS.reduce((acc, utm) => {
      if (utmsInData.includes(utm)) {
        const utmRow = {
          display: getUTMDisplayName(utm),
          key: utm,
          isSearchable: true,
          as: FixedWidthCell,
        };
        acc.push(utmRow);
      }
      return acc;
    }, []);

    utmsInData.filter(utm => !STANDARD_UTMS.includes(utm)).forEach(utm => {
      const utmRow = {
        display: getUTMDisplayName(utm),
        key: utm,
        isSearchable: true,
        // as: FixedWidthCell,
      };
      utmCols.push(utmRow);
    });

    return [
      ...utmCols,
      { display: 'Referrer Url', key: 'referrer', isSearchable: true },
      { display: 'Pages', key: 'pages', isSearchable: true, as: PagesCell },
      { display: 'Clicks', key: 'count', textAlign: 'right', tooltip: 'Clicks tracked yesterday', as: IndexGridNew.NumberCell },
      // eslint-disable-next-line max-len
      { display: 'Resolution', key: 'resolution', textAlign: 'center', sortable: false, tooltip: 'Mark whether you\'ve resolved each issue or you want to silence the alert', as: PlatformFeedbackCell },
    ];
  }, [missingParamsData]);

  const resolveAllIssues = () => {
    const unresolvedRows = missingParamsData.filter(p => !p.resolution);
    if (!unresolvedRows.length) return;

    setSubmitting(true);
    Promise.all(unresolvedRows.map(x => postPixelTrackingResolution({
      platform,
      resolution: 'fixed',
      hash: x.rowHash,
      params: x.params,
      url: x.url,
    })))
      .then(res => {
        const updatedData = missingParamsData.map(x => {
          if (!!x.resolution) return x;
          return {
            ...x,
            resolution: 'fixed',
          };
        });
        setMissingParamsData(updatedData);

        const successCount = res.filter(x => !!x?.success)?.length;
        toast.success(`${successCount} issues marked resolved`);
        setSubmitting(false);
        track('data.marketing.advertising_platforms.tracking.resolve', { platform, all: true, resolution: 'fixed' });
      })
      .catch(err => {
        console.error(err);
        toast.error('Error submitting resolution');
        setSubmitting(false);
      });
  };

  if (!missingParamsData) return <TreeTableLoader />;

  return (
    <IndexGridNew
      celled
      data={filteredParamsData}
      cols={columns}
      sortable
      defaultSortCol="count"
      searchable
      alignSearchLeft
      placeholder="Search"
      emptyIcon="check"
      fallBackMsg="No Issues Found"
      topRight={!!missingParamsData?.length
        && (
          <Checkbox
            inverted
            color="purple"
            label="Show Resolved"
            className="header-checkbox"
            checked={showResolved}
            onChange={() => setShowResolved(!showResolved)}
          />
        )}
      footer={hasIssues
        && (
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan={columns.length - 1} />
              <Table.HeaderCell textAlign="center">
                <Button
                  inverted
                  size="small"
                  color="purple"
                  content={(
                    <>
                      <CheckCircleIcon style={{ display: 'inline', height: 19, margin: '-5px 3px -5px -3px' }} />
                      {' Mark All Fixed'}
                    </>
                  )}
                  onClick={resolveAllIssues}
                  loading={submitting}
                  disabled={submitting}
                  className="remove-box-shadow"
                  style={{ margin: 0 }}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        )}
    />
  );
};

export default MissingParams;

import { PLATFORM_MAPPING } from './constants';

/**
 * Generates dropdown options based on the given event index and mapping. Alphabetize dropdown list and put other at the end.
 *
 * @param {Object} eventIndex - The event index object.
 * @param {Object} mapping - The mapping object.
 * @returns {Array} - An array of dropdown options.
 */
export const createDropdownOptions = (eventIndex, mapping) => {
  if (!eventIndex) return [];
  const options = Object.keys(eventIndex);
  const includesOther = options.includes('other');
  const sortedOptions = includesOther ? [...options.filter(x => x !== 'other').sort(), 'other'] : options.sort();

  return sortedOptions
    .map(value => ({ key: value, value, text: mapping[value].text }));
};

/**
 * Generates platform integration matches based on the given platform integrations.
 *
 * @param {Array} platformIntegrations - An array of platform integrations.
 * @returns {Object} - An object containing platform integration matches.
 */
export const generatePlatformIntegrationMatches = platformIntegrations => {
  if (!platformIntegrations) return {};
  return (
    platformIntegrations.reduce((accu, curr) => {
      const { platform, display_name } = curr;
      const matches = PLATFORM_MAPPING[platform] ? PLATFORM_MAPPING[platform].matches : [];
      const platformPattern = {
        matches: [platform, ...matches],
        text: display_name,
        exclude: ['organic'],
      };
      return {
        ...accu,
        [platform]: platformPattern,
      };
    }, {})
  );
};

/**
 * Generates a mapping of platform integrations that are not included in the given list of platform integrations.
 *
 * @param {Array} platformIntegrations - An array of platform integrations, each containing a 'platform' property.
 * @returns {Object} - A mapping of platform integrations that are not included in the given list of platform integrations.
 */
export const generateOtherPlatformMatches = platformIntegrations => {
  const platformsMapped = platformIntegrations.map(({ platform }) => platform);
  return (
    Object.keys(PLATFORM_MAPPING)
      .filter(platform => !platformsMapped.includes(platform))
      .reduce((accu, curr) => {
        const mapping = PLATFORM_MAPPING[curr];
        return { ...accu, [curr]: mapping };
      }, {})
  );
};

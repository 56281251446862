import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import Fetch from '../parts/GenericFetch';
import { rootAction } from '../../rootAction';

import { CONFIGURE_USER, BAD_USER } from '../../constants';
import { SET_ARTIFACTS, SET_ANNOUNCEMENTS, SET_ACCOUNT, SET_COMMUNICATION, SET_NOTIFICATION, USER_LOGGED_OUT_FROM_GOOGLE } from './constants';

const ARTIFACTS_ENDPOINT = '/artifacts';
const CURRENT_CLIENT_ENDPOINT = '/user/permissions';
const ANNOUNCEMENTS_ENDPOINT = '/announcements';
const ACCOUNT_ENDPOINT = '/account';
const COMMUNICATION_ENDPOINT = '/account/communications'
const NOTIFICATION_ENDPOINT = '/account/notifications'

const AppData = (props) => {
  return (
    <div>
      <Fetch endpoint={ACCOUNT_ENDPOINT} success={props.setAccount} />
      <Fetch key={1} endpoint={CURRENT_CLIENT_ENDPOINT} success={props.setUser} error={props.setNoAuth} />
      <Fetch key={2} endpoint={ARTIFACTS_ENDPOINT} success={props.setArtifacts} />
      <Fetch endpoint={ANNOUNCEMENTS_ENDPOINT} success={props.setAnnouncements} />
      <Fetch endpoint={COMMUNICATION_ENDPOINT} success={props.setCommunications} />
      <Fetch endpoint={NOTIFICATION_ENDPOINT} success={props.setNotifications} />
      { props.account.signup_source === 'shopify' && props.account.requires_setup === 5 && (
        <Route path="/" render={() => <Redirect to="/onboarding" />}/>
      )}
      { props.username === false && <Route path="/" render={() => <Redirect to="/login"/>} /> }
      { props.username && props.account && props.children }
      <SetTrackingIdentities {...props} />
    </div>
  )
}

const configurePermissions = feature_permissions => {
  const permissionsList = feature_permissions.map(p => p.name)

  return {
    readOnly: permissionsList.includes('read_only')
  }
}

const logUserOut = () => {
  fetch('/user/logout', {'headers': {'accept': 'application/json'}}).then(d => {
    window.location = '/login';
  })
}

function jwtDecode(t) {
  let token = {};
  token.raw = t;
  token.header = JSON.parse(window.atob(t.split('.')[0]));
  token.payload = JSON.parse(window.atob(t.split('.')[1]));
  return token
}

const validateCorrectGoogleLogin = (response, rockerboxUsername) => {
  const jwtToken = response.credential
  const userInfo = jwtDecode(jwtToken);
  const userEmail = userInfo?.payload?.email;

  console.log(`User's current google login: ${userEmail}, User's rockerbox email: ${rockerboxUsername}`);
  if(!userEmail || userEmail.toLowerCase() !== rockerboxUsername.toLowerCase()){
    logUserOut();
  } else {
    document.cookie=`google_login=${userEmail}; max-age=3600; path=/`
  }
}

export const checkGoogleLogin = (account, user, isRockerboxer) => {
  if (isRockerboxer) return;

  if (!window.google) {
    setTimeout(() => checkGoogleLogin(account, user, isRockerboxer), 500);
  } else {
    if (!!account.enable_google_sso) {
      window.google.accounts.id.initialize({
        client_id: '665449023953-ckh1eop8o8uiabn88ai7mkb30fa9h5kv.apps.googleusercontent.com',
        prompt_parent_id: 'g_id_onload',
        auto_select: 'true',
        callback: function(response) {
          validateCorrectGoogleLogin(response, user);
        }
      });

      window.google.accounts.id.prompt((notification) => {
        if(notification.isNotDisplayed()){
          const notificationMsg = notification.getNotDisplayedReason();

          if(notificationMsg == USER_LOGGED_OUT_FROM_GOOGLE) {
            console.log("User is not logged into google");
            logUserOut()
          }
        }
      });
    }
  }
}

const SetTrackingIdentities = ({ username, account }) => {
  if (username && account && account.hasOwnProperty('advertiser_name')) {
    const isRockerboxer = username.includes("@rockerbox.com")
    document.cookie = `is_rockerboxer=${isRockerboxer}; domain=.rockerbox.com; SameSite=None; Secure`
    checkGoogleLogin(account, username, isRockerboxer);
  }
  return null
}

function mapStateToProps (state, props) {
  const { username, announcements, account } = state.app

  return { username, announcements, account }
}

function mapDispatchToProps(dispatch) {
  return {
    setUser: data => dispatch(rootAction(CONFIGURE_USER, {
      username: document.cookie.split("user_name=\"")[1].split("\";")[0],
      user: data.response.accounts.filter(x => x.selected)[0],
      accounts: data.response.accounts,
      permissions: configurePermissions(data.response.feature_permissions)
    })),
    setNoAuth: data => dispatch(rootAction(BAD_USER, false)),
    setArtifacts: data => dispatch(rootAction(SET_ARTIFACTS, data)),
    setAnnouncements: data => dispatch(rootAction(SET_ANNOUNCEMENTS, data)),
    setCommunications: data => dispatch(rootAction(SET_COMMUNICATION, data)),
    setNotifications: data => dispatch(rootAction(SET_NOTIFICATION, data)),
    setAccount: data => dispatch(rootAction(SET_ACCOUNT, data))
  };
}

const ConnectedData = connect(mapStateToProps, mapDispatchToProps)(AppData);

export default ConnectedData;

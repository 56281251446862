import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ChartBarIcon, CursorClickIcon, SpeakerphoneIcon } from '@heroicons/react/outline';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ShareIcon, DatabaseIcon } from '@heroicons/react/solid';

import * as routes from '../../baseRoutes';
import { track, time } from '../../../utils/tracking';
import { DateRangeHooks, SavedReportsGrid, PlatformPerformanceSync, DividingHeader, DemoVideoWrapper } from '../../components';
import { useGlobalState } from '../../hooks/global';
import { FREE_PLATFORMS } from './constants';

import { getSavedReports } from '../../api/attribution';

import { getSpend, formatAPIData } from './helpers';

import { colorHash } from '../../utils/colorHash';

import PlatformMetrics from './PlatformMetrics';
import PlatformSpendChart from './PlatformSpendChart';

const StarterDashboard = ({ startDate, endDate, onDateChange, currencyCode, tier }) => {
  const { authorizations, integrations } = useGlobalState();

  const [savedReports, setSavedReports] = useState(null);
  const [loadingSpend, setLoadingSpend] = useState(true);
  const [loadingConversions, setLoadingConversions] = useState(true);
  const [stillBackfilling, setStillBackfilling] = useState(false);
  const [stillBackfillingShopify, setStillBackfillingShopify] = useState(false);
  const [hasConversionData, setHasConversionData] = useState(true);
  const [hasSpendData, setHasSpendData] = useState(true);
  const [spendData, setSpendData] = useState(undefined);

  const connectedPlatforms = useMemo(
    () => (!!authorizations ? authorizations.filter(a => a.authorizations.some(b => b.account_id !== '')).map(({ platform }) => platform) : []),
    [authorizations],
  );

  const platforms = useMemo(() => {
    if (!authorizations || !integrations) return [];
    return authorizations.map(x => {
      const platformInfo = integrations.find(y => y.platform === x.platform) || {};
      return {
        ...x,
        ...platformInfo,
        title: platformInfo.display_name || x.platform.replaceAll('_', ' ').replaceAll('-', ' '),
        icon: !!platformInfo.icon_white_img && `https://images.rockerbox.com/integrations/${platformInfo.icon_white_img}`,
        defaultIcon: platformInfo.category === 'advertising.digital'
          ? CursorClickIcon
          : platformInfo.category === 'advertising.offline'
            ? SpeakerphoneIcon
            : platformInfo.category === 'advertising.affiliate'
              ? ShareIcon
              : DatabaseIcon,
      };
    });
  }, [integrations, authorizations]);

  const colorMap = useMemo(() => {
    if (!authorizations) return {};
    const colors = {};
    authorizations.forEach(x => { colors[x.platform] = FREE_PLATFORMS[x.platform]?.color || colorHash(x.platform); });
    return colors;
  }, [authorizations]);

  useEffect(() => {
    if (!authorizations) return;
    if (!authorizations.length) return setSpendData([]);
    setLoadingSpend(true);
    getSpend(startDate, endDate)
      .then(data => {
        const updated = formatAPIData(data);
        setSpendData(updated);
        setLoadingSpend(false);
      });
  }, [authorizations, startDate, endDate, currencyCode]);

  const currencyFormatOptions = {
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: 'USD',
  };
  currencyFormatOptions.currency = currencyCode || 'USD';
  const currencyFormatter = new Intl.NumberFormat('en-US', currencyFormatOptions);

  // spend data for metrics charts
  useEffect(() => {
    if (!connectedPlatforms) return;
    if (!connectedPlatforms.length) return setHasSpendData(false);
    if (!(connectedPlatforms.filter(x => x !== 'shopify').length > 0)) return setHasSpendData(false);
    setHasSpendData(true);
  }, [connectedPlatforms]);

  // conversion data for metrics charts
  useEffect(() => {
    if (!connectedPlatforms) return;
    if (!connectedPlatforms.includes('shopify')) return setHasConversionData(false);
    setHasConversionData(true);
  }, [connectedPlatforms]);

  useEffect(() => {
    getSavedReports()
      .then(savedReportsResponse => {
        setSavedReports(savedReportsResponse);
      });
  }, []);

  useEffect(() => {
    if (!!loadingSpend || !!loadingConversions) {
      time('home.view');
      return;
    }
    track('home.view');
  }, [loadingSpend, loadingConversions]);

  return (
    <div className="starter-dashboard">
      <DemoVideoWrapper />
      {stillBackfilling && connectedPlatforms.map(p => <PlatformPerformanceSync {...{ platform: p, setPercent: () => {}, onComplete: () => window.location.reload() }} />)}
      {stillBackfillingShopify
        && <PlatformPerformanceSync {...{ platform: 'shopify', setPercent: () => {}, onComplete: () => window.location.reload() }} />}
      <div className="datepicker">
        <DateRangeHooks
          transparent
          {...{
            startDate,
            endDate,
            firstReportingDate: false,
            onDateChange,
          }}
        />
      </div>

      <PlatformMetrics {...{
        loading: loadingConversions || loadingSpend,
        setLoading: setLoadingConversions,
        stillBackfilling: stillBackfilling || stillBackfillingShopify,
        setStillBackfillingShopify,
        hasConversionData,
        currencyFormatter,
        startDate,
        endDate,
        currencyCode,
        spendData,
        tier,
      }}
      />

      <PlatformSpendChart {...{
        loading: loadingConversions || loadingSpend,
        setLoading: setLoadingSpend,
        stillBackfilling,
        setStillBackfilling,
        hasSpendData,
        platforms,
        startDate,
        endDate,
        colorMap,
        currencyFormatter,
        data: spendData,
      }}
      />

      <DividingHeader
        text="Saved Reports"
        icon={<ChartBarIcon />}
      />
      {(savedReports && savedReports.length > 0) ? <SavedReportsGrid columns={3} hideTitle />
        : (
          <Grid centered style={{ marginTop: -2, marginBottom: 0 }}>
            <Grid.Column width={8}>
              <p style={{ fontSize: '18px', textAlign: 'center' }}>
                After
                {' '}
                <Link to={routes.connectIntegration}>connecting your advertising platforms</Link>
                , you can adjust the filters within each report and save them for quick access later. Your Saved Reports are available here on your dashboard and within each report.
              </p>
            </Grid.Column>
          </Grid>
        )}
    </div>
  );
};

export default StarterDashboard;

import React from 'react';
import * as Router from "react-router-dom";
import * as routes from '../../routes';

import { Button, Table } from 'semantic-ui-react'
import { IndexGrid, PagedIndexGrid, ContentCard } from '@rockerbox/styleguide';

import { getEventTags } from '../../utils/api';
import CacheContext from '../../utils/CacheContext';

const LengthCell = ({ item, col }) => {
  return <Table.Cell>
    { item[col.key].length }
  </Table.Cell>
}

const NumCell = ({ item, col }) => {
  if (item[col.key] == null ) {
    return <Table.Cell></Table.Cell>
  }
  return <Table.Cell>
    { item[col.key] }
  </Table.Cell>
}

const COLS = [
  {display:"Name", key:"name"},
  {display:"Tier 1", key:"tier_1"},
  {display:"Tier 2", key:"tier_2"},
  {display:"Tier 3", key:"tier_3"},
  {display:"Tier 4", key:"tier_4"},
  {display:"Tier 5", key:"tier_5"},
  {display:"Event Exclusions", key:"event_exclusions", as: LengthCell},
  {display:"Spend Exclusions", key:"spend_exclusions", as: LengthCell},
  {display:"Attribution Window (days)", key: "num_days", as: NumCell}
]

const EventTagGrid = (props) => {

  const [state, setState, Context] = React.useContext(CacheContext);
  const eventTags = (Context.getCache(`eventTags`, getEventTags) || [])

  const EventTagRow = ({item}) => (
    <Table.Cell collapsing>
      <IndexGrid.EditButton url={routes.eventTagsIndex + '/view/' + item.id} />
      {/* <IndexGrid.DeleteButton onDelete={() => deleteEvent(item.id) }/> */}
    </Table.Cell>
  )

  const cols = COLS.concat([{key:"id", as: EventTagRow}])

  return <ContentCard title={"Event Tags"} hasTable topRight={<Button as={Router.Link} to={`${routes.eventTagsIndex}/create`} size="mini">Create</Button>}>
    <PagedIndexGrid itemsPerPage={20} data={eventTags} cols={cols} />
  </ContentCard>
}

export default EventTagGrid;

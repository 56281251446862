import React, { Component } from 'react';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { StatCard, SplitLayout, ContentCard } from '@rockerbox/styleguide'
import { getBucketsTimeseries, getCreativeBucketsTimeseries } from '../../utils/api';
import moment from 'moment';

import SummaryBar from '../SummaryBar';

export default class UnmappedEvents extends Component {

  state = {
    timeseriesData: [],
    mapped: 0,
    unmapped: 0
  }

  handleDateChange = (row, pos) => {
    const { date } = row;
    const { setDate } = this.props;
    setDate(date);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.id !== this.props.id) {
      this.getData();
    }
  }

  getData = () => {
    const start_date = moment.utc().utcOffset(-5).subtract(30, 'days').format("YYYY-MM-DD");
    const end_date = moment.utc().utcOffset(-5).subtract(1, 'days').format("YYYY-MM-DD");
    const apiCalls = [
      getBucketsTimeseries(this.props.id, start_date, end_date),
      getCreativeBucketsTimeseries(this.props.id, start_date, end_date).catch( (e) => [])
    ]
    Promise.all(apiCalls)
      .then( timeseriesData => {
        timeseriesData = timeseriesData.reduce( (x,y) => x.concat(y) );
        timeseriesData = d3.nest()
              .key( (d) => d.date )
              .rollup( (values) => { return {unmapped: d3.sum(values, (x) => x.unmapped), mapped: d3.sum(values, (x) => x.mapped)}} )
              .entries(timeseriesData)
              .map( x => { return {date: x.key, unmapped: x.values.unmapped, mapped: x.values.mapped} });
        const mapped = d3.sum(timeseriesData, x => x.mapped)
        const unmapped = d3.sum(timeseriesData, x => x.unmapped)
        this.setState({ timeseriesData, mapped, unmapped });
      });
  }

  render() {
    const { timeseriesData, unmapped, mapped } = this.state;

    return (
      <SplitLayout
        rightContent={
          <ContentCard title={"Event Mappings by Day"} hasTable >
            <SummaryBar data={timeseriesData} exclude={["cache_from"]} onClick={this.handleDateChange}/>
          </ContentCard>
        }
        leftWidth={3}
        leftContent={
          <React.Fragment>
            <StatCard value={mapped} label='Mapped Events' explainer='Past 30 Days' size='small' />
            <StatCard value={unmapped} label='Unmapped Events' explainer='Past 30 Days' size='small' />
            <StatCard value={mapped > 0 ? unmapped/(mapped+unmapped) : 0 } label='% Unmapped' type='percent' explainer='Past 30 Days' size='small' />
          </React.Fragment>
        }
        rightWidth={13}
        />
    )

  }
}

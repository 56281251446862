import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Button } from 'semantic-ui-react';
import { ContentCard } from '@rockerbox/styleguide';
import RockerboxDataTable from '../components/RockerboxDataTable';

const ReportSyncRockerboxTable = ({ loading, callbackFn }) => (
  <div>
    <ContentCard hasTable>
      <RockerboxDataTable
        callbackFn={callbackFn}
        loading={loading}
        showLastSyncd={true}
        emptyGridMessage="No Datasets Synced"
      />
    </ContentCard>
  </div>
);

export default ReportSyncRockerboxTable;

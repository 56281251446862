import React, { useState, useEffect, useCallback } from 'react';
import { Segment, Form, Grid, Button, Message } from 'semantic-ui-react';
import { attributableNames, tierKeys as tierKeysFromConstants } from './constants';
import { createWords } from './filterDataMain';

const FilterOptions = ({ pathIdsByTiers, tierKeys, name, updateFilterObj, setUpdateFilterObj, minLen, setMinLen, setFilterSelected }) => {
  const filterObj = updateFilterObj[name] || {};

  const findOptions = node => {
    const options = [];
    if (node.children) {
      Object.keys(node.children).forEach(key => options.push({ key, text: key, value: key }));
      return options;
    }
  };

  return tierKeys.map((key, i) => {
    const prevFilterExists = filterObj[tierKeys[i - 1]] && filterObj[tierKeys[i - 1]].length > 0;
    if (i !== 0 && !prevFilterExists) return null;

    const prevFilters = {};
    const filterOptions = [];
    if (i === 0) {
      filterOptions.push(...findOptions(pathIdsByTiers.root));
    } else {
      let lastFilter = i - 1;
      while (lastFilter >= 0) {
        prevFilters[tierKeys[lastFilter]] = filterObj[tierKeys[lastFilter]];
        lastFilter -= 1;
      }
      const combos = createWords(prevFilters);
      combos.forEach(filter => {
        const findNode = pathIdsByTiers.search(filter);
        if (findNode) {
          filterOptions.push(...findOptions(findNode));
        }
      });
    }

    filterOptions.sort((a, b) => {
      const nameA = a.text.toUpperCase();
      const nameB = b.text.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    const onSelectTier = (event, x) => {
      const { value, name: nameX } = x;
      // eslint-disable-next-line no-param-reassign
      if (!updateFilterObj[nameX]) updateFilterObj[nameX] = {};
      if (nameX === 'mid' && minLen === 2) setMinLen(3);

      const updatedTiers = updateFilterObj[nameX];
      updatedTiers[tierKeys[i]] = value;
      tierKeys.forEach((k, index) => {
        if (updatedTiers[k] && updatedTiers[k].length === 0) {
          for (let j = index + 1; j < tierKeys.length; j += 1) {
            delete updatedTiers[tierKeys[j]];
          }
        }
      });

      setUpdateFilterObj(prev => ({
        ...prev,
        [nameX]: updatedTiers,
      }));
      setFilterSelected(true);
    };

    return (
      <Form.Select
        search
        multiple
        name={name}
        value={filterObj[key] || ''}
        onChange={onSelectTier}
        placeholder={`${attributableNames[key]} contains`}
        options={filterOptions}
      />
    );
  });
};

const TouchPointOptions = ({ pathIdsByTiers, tierKeys, name, updateFilterObj, setUpdateFilterObj, minLen, setMinLen, setFilterSelected }) => {
  const touchLabels = ['First Touchpoint', 'Middle Touchpoint', 'Last Touchpoint'];

  return (
    <Form>
      <Grid columns={3}>
        {touchLabels.map((touch, i) => (
          <Grid.Column>
            <label><b>{touch}</b></label>
            <FilterOptions
              {...{ pathIdsByTiers, tierKeys, updateFilterObj, setUpdateFilterObj, minLen, setMinLen, setFilterSelected }}
              name={name[i]}
            />
          </Grid.Column>
        ))}
      </Grid>
    </Form>
  );
};

const FilterTouchPoint = ({
  filterObj, pathIdsByTiers, minPathLen, setMinPathLen,
  setAny, setFirst, setMid, setLast,
  applyFilters, setApplyFilters, setLoading,
}) => {
  const [minLen, setMinLen] = useState(2);
  const [updateFilterObj, setUpdateFilterObj] = useState({});
  const [filterSelected, setFilterSelected] = useState(false);

  useEffect(() => {
    setMinLen(minPathLen);
    setUpdateFilterObj(filterObj);
    Object.keys(filterObj).forEach(key => {
      if (filterObj[key] && filterObj[key].tier_1.length > 0) {
        setFilterSelected(true);
      }
    });
  }, []);

  const clearFilters = useCallback(() => {
    setFilterSelected(false);
    setLoading(true);
    setAny('any');
    setFirst('first');
    setMid('mid');
    setLast('last');
    setMinPathLen(2);
    setApplyFilters(true);
    setUpdateFilterObj({});
  });

  const handleSubmit = useCallback(() => {
    setMinPathLen(minLen);
    setApplyFilters(true);
    setLoading(true);
    setAny(updateFilterObj.any ? JSON.stringify(updateFilterObj.any) : 'any');
    setFirst(updateFilterObj.first ? JSON.stringify(updateFilterObj.first) : 'first');
    setMid(updateFilterObj.mid ? JSON.stringify(updateFilterObj.mid) : 'mid');
    setLast(updateFilterObj.last ? JSON.stringify(updateFilterObj.last) : 'last');
  });

  const handleMinSteps = useCallback(evt => {
    const selectedSteps = evt.target.value;
    if (!(updateFilterObj.mid && updateFilterObj.mid.tier_1.length > 0 && selectedSteps < 3)) {
      setMinLen(selectedSteps);
      setFilterSelected(true);
    }
  });

  return (
    <Segment style={{ backgroundColor: 'transparent' }}>
      <Grid columns={3} style={{ marginTop: '0px', paddingTop: '10px', paddingBottom: '10px' }} relaxed divided>
        <Grid.Column width={3}>
          <Form>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label><b>Anywhere in Path</b></label>
            <FilterOptions
              {...{ pathIdsByTiers, tierKeys: tierKeysFromConstants, updateFilterObj, setUpdateFilterObj, setFilterSelected }}
              name="any"
            />
          </Form>
        </Grid.Column>
        <Grid.Column width={10}>
          <TouchPointOptions
            {...{ pathIdsByTiers, tierKeys: tierKeysFromConstants, updateFilterObj, setUpdateFilterObj, minLen, setMinLen, setFilterSelected }}
            name={['first', 'mid', 'last']}
          />
        </Grid.Column>
        <Grid.Column width={3} textAlign="center">
          <div><b>{`Minimum Number of Touchpoints: ${minLen}`}</b></div>
          <br />
          <input
            type="range"
            min={2}
            max={5}
            value={minLen}
            onChange={handleMinSteps}
          />
        </Grid.Column>
      </Grid>
      <Form.Field style={{ minHeight: '30px' }}>
        <Message compact info>
          <p>
            The data excludes all conversions with fewer than 2 touchpoints, including Direct.
            If you include a Middle Touch filter, then the minimum number of touchpoints is 3.
          </p>
          <p>
            Each path must meet the conditions of
            {' '}
            <b>any</b>
            {' '}
            of the buckets within a selected filter. It must also meet the condition of
            {' '}
            <b>all</b>
            {' '}
            populated filters.
          </p>
        </Message>
        <Button style={{ float: 'right', cursor: 'pointer' }} onClick={() => clearFilters()}>
          Clear All Filters
        </Button>
        <Button
          primary
          style={{ float: 'right', cursor: 'pointer' }}
          onClick={() => handleSubmit()}
          loading={applyFilters === true}
          disabled={!filterSelected}
        >
          Submit
        </Button>
      </Form.Field>
    </Segment>
  );
};

export default FilterTouchPoint;

import React from 'react'
import CacheContext from '../../../utils/CacheContext'
import { getUser, getAccount, getTransformTableTemplates, getAttributableEventTemplates } from '../../../utils/api'

const CacheData = () => {
  // caching api data
  const [state, setState, Context] = React.useContext(CacheContext)
  const user = (Context.getCache(`user`, getUser) || undefined)
  const account = (Context.getCache(`account`, getAccount) || undefined)
  const transformTableTemplates = (Context.getCache(`transformTableTemplates`, getTransformTableTemplates) || [])
  const attributableEventTemplates = (Context.getCache(`attributableEventTemplates`, getAttributableEventTemplates) || [])

  return null
}

export default CacheData

import { useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import { useAtom } from 'jotai';

import { attributionMethodAtom } from '../../../atoms';
import { getCache } from '../../../utils/data';
import { methodOptions } from '../../../constants/options';
import { findCurrentSegments } from '../../../utils/options';

export const getFirstReportingDate = currentSegments => {
  const first_reporting_dates = currentSegments.map(({ first_reporting_date }) => moment(first_reporting_date));
  const firstReportingDate = moment.max(first_reporting_dates);
  return firstReportingDate.format('MM/DD/YYYY');
};

const arraysAreEqual = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }

  return true;
};

export const useDatasets = (segments, startDate, endDate, funnelConfiguration, account) => {
  const dataset = 'compiled_mta_tiers';
  const [_attributionMethod, _setAttributionMethod] = useAtom(attributionMethodAtom);
  const [firstReportingDate, setFirstReportingDate] = useState(undefined);
  const [tiersDataRaw, setTiersDataRaw] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(false);
  const [currentResponse, setCurrentResponse] = useState(false);

  const invalidConfiguration = (!funnelConfiguration || funnelConfiguration.length === 0);
  const badDate = !startDate || !endDate;
  const missingSegments = !segments || (segments.length < 1);

  const currentSegments = !missingSegments && funnelConfiguration && findCurrentSegments(segments, funnelConfiguration);

  const hasMissingParameters = (invalidConfiguration || badDate || missingSegments || !currentSegments || !account);

  const hasModel = useMemo(() => {
    if (!currentSegments || !currentSegments.length) return false;
    return currentSegments.every(({ use_model }) => !!use_model);
  }, [currentSegments]);

  const attributionOptions = useMemo(() => {
    if (hasModel) return methodOptions;
    return methodOptions.filter(o => o.value !== 'normalized');
  }, [hasModel]);

  const setAttributionMethod = value => {
    if (!value) return;
    _setAttributionMethod(value);
  };

  useEffect(() => {
    // Check assertions - Don't attempt to run anything if we dont have everything
    if (hasMissingParameters) return;

    const requestHashById = funnelConfiguration.map(id => `${id}${startDate}${endDate}`);
    setCurrentRequest(requestHashById);

    const firstReportingDateFromSegments = getFirstReportingDate(currentSegments);

    if (moment(endDate).isBefore(moment(firstReportingDateFromSegments))) {
      // no need to execute request if date range selection is bad
      setCurrentResponse(requestHashById);
      setFirstReportingDate(firstReportingDateFromSegments);
      setTiersDataRaw(false);
      return;
    }

    const hasDataReportingApiFlag = account.features.find(c => c === 'data_reporting_api');

    const getViaCache = hasDataReportingApiFlag
      ? getCache('compiled_mta_tiers', 'data-services-v1')
      : getCache('compiled_mta_tiers', 'legacy');

    const requests = funnelConfiguration.map(id => getViaCache(startDate, endDate, dataset, id));

    Promise.all(requests)
      .then(_data => {
        setCurrentResponse(requestHashById);
        setFirstReportingDate(firstReportingDateFromSegments);
        setTiersDataRaw(_data);
      });
  }, [funnelConfiguration, startDate, endDate, segments]);

  const dataIsCurrent = arraysAreEqual(currentRequest, currentResponse);
  const loading = !currentRequest || (!hasMissingParameters && !dataIsCurrent);
  const noData = currentRequest && dataIsCurrent && tiersDataRaw.length !== funnelConfiguration.length;

  const attributionMethod = useMemo(() => {
    const defaultMethod = hasModel ? 'normalized' : 'even';
    const noMethod = !_attributionMethod || _attributionMethod === '';
    const invalidMethod = _attributionMethod === 'normalized' && !hasModel;
    const standardAttributionMethod = (noMethod || invalidMethod) ? defaultMethod : _attributionMethod;

    return standardAttributionMethod;
  }, [_attributionMethod, hasModel]);

  return {
    loading,
    noData,
    hasModel,
    attributionMethod,
    attributionOptions,
    setAttributionMethod,
    tiersDataRaw,
    currentSegments,
    firstReportingDate,
  };
};

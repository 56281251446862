import { TIERS } from '../../../constants/tiers';
import { fillDailyPerformanceMissingDates } from '../../../utils/channelPerformance/helpers';
import {
  sumColumnsByGroup,
  sumNumericColumnsByGroup,
  validateJoinColumns,
  deriveNumberFromBigIntColumns,
  deriveRatiosFromRatioObject,
} from './arquero_helpers';
import {
  JOIN_KEYS,
  PLATFORM_TO_TIERS,
  TIERS_TO_ASSISTED,
  TIERS_TO_ASSISTED_WINDOW,
  RENAME_PLATFORM_TIERS,
  TIERS_DATA_COLS,
  RATIO_NAMES,
} from './constants';

export const validateDates = (mainArray, ...comparedArrays) => {
  const results = comparedArrays.flatMap(datesArr => {
    const evalSet = new Set(datesArr);
    return [...mainArray].filter(date => !evalSet.has(date));
  });
  const missingDates = [...new Set(results)].sort();
  return missingDates;
};

const joinPlatformTierAssistedWindow = (platformData, groupedTiersData, assisted, assistedWindow) => {
  validateJoinColumns(platformData, groupedTiersData, ...PLATFORM_TO_TIERS);
  validateJoinColumns(groupedTiersData, assisted, ...TIERS_TO_ASSISTED);
  validateJoinColumns(groupedTiersData, assistedWindow, ...TIERS_TO_ASSISTED_WINDOW);

  return platformData // spend_key
    .join_left(groupedTiersData, PLATFORM_TO_TIERS, undefined, { suffix: ['_platform', '_spend_mta'] })
    .rename(RENAME_PLATFORM_TIERS)
    .join_left(assisted, TIERS_TO_ASSISTED, undefined, { suffix: ['_platform', '_ttc_assisted'] })
    .rename(RENAME_PLATFORM_TIERS)
    .join_left(assistedWindow, TIERS_TO_ASSISTED_WINDOW, undefined, { suffix: ['_platform', '_ttc_assisted_window'] })
    .rename(RENAME_PLATFORM_TIERS);
};

export const buildComparison = (platformData, platformDates, tiersData, tiersDates, assisted, assistedWindow, ttcDates, expectedDateRange, model) => {
  const hasData = platformData?.size && tiersData?.size && assisted?.size && assistedWindow?.size;
  const hasDates = platformDates && tiersDates && ttcDates;
  const hasDateRange = (expectedDateRange && expectedDateRange.length > 0);

  if (!hasDateRange) return {};

  const missingDates = hasDates && validateDates(expectedDateRange); // historically was validating against platformDates, tiersDates, ttcDates

  if (!hasData || !hasDates) return {};
  if (hasData && hasDates && missingDates.length > 0) return { missingDates };

  // fix platform data with empty spend_key
  const platformDataFixed = platformData
    .derive({ spend_key: d => (d.spend_key === '' ? 'BAD_SPEND_KEY' : d.spend_key) });

  // GROUP BY, SUM and JOIN datasets
  const grouped = sumColumnsByGroup(tiersData, TIERS_DATA_COLS, JOIN_KEYS);
  const fullJoin = joinPlatformTierAssistedWindow(platformDataFixed, grouped, assisted, assistedWindow);
  const groupedJoin = sumNumericColumnsByGroup(fullJoin, TIERS);
  const dailySums = sumNumericColumnsByGroup(fullJoin, ['date']);

  // Convert bigint to number
  const deriveNumericConversion = deriveNumberFromBigIntColumns(dailySums);
  const deriveRatios = deriveRatiosFromRatioObject(dailySums, RATIO_NAMES);

  const daily = dailySums
    .derive(deriveNumericConversion) // first convert bigints to numbers before deriving ratios
    .derive(deriveRatios);

  const dailyComparisonData = fillDailyPerformanceMissingDates(expectedDateRange, daily.objects());

  const comparisonData = groupedJoin
    .derive(deriveNumericConversion)
    .derive({
      attributed_cpa: `(d) => (d.spend || 0)/(d['conversions_${model}_rb'])`,
      attributed_roas: `(d) => (d['revenue_${model}_rb']/(d.spend || 0))`,
    })
    .objects();

  return {
    missingDates,
    comparisonData,
    dailyComparisonData,
  };
};

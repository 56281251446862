import React from 'react';

import { eventOptions, dateOptions } from './constants';
import { FilterWrapper, StyledDropdown } from '../../components';

const Filter = ({ eventType, setEventType, date, setDate }) => (
  <FilterWrapper
    other={(
      <>
        <StyledDropdown
          label="Event Type"
          options={eventOptions}
          value={eventType}
          onChange={(e, { value }) => setEventType(value)}
          disabled
        />
        <StyledDropdown
          label="Date Range (UTC)"
          options={dateOptions}
          value={date}
          onChange={(e, { value }) => setDate(value)}
        />
      </>
    )}
  />
);

export default Filter;

import React from 'react';
import { BasicViewHeader } from '../../components';

const MainHeader = () => (
  <BasicViewHeader
    header="Marketing Paths"
    subheader="View your overall marketing performance using various marketing paths"
  />
);

export default MainHeader;

import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { FilterWrapper, StyledDropdown } from '../../components';
import { getBenchmarkData } from '../../api/dataReporting';
import { formatText, formatSpendText, sortDropdownOptions, getIndustryNames, getSpendLevels, getAdTypes, filterData } from './helpers';
import { PLATFORMS, FILTER_TYPES } from './constants';
import SaveReportButton from '../../components/SavedReports/SaveReportButton';
import ResetFilterButton from '../../components/ResetFilterButton';

// eslint-disable-next-line max-len
const Filter = ({ platform, setPlatform, filterType, setFilterType, setLoading, loading, setData, industry, setIndustry, spendLevel, setSpendLevel, spendLevelOptions, setSpendLevelOptions, adType, setAdType, data, trendCategories }) => {
  const [industryOptions, setIndustryOptions] = useState([]);
  const [adTypeOptions, setAdTypeOptions] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState(false);

  const activePlatform = (
    <span>
      {/* eslint-disable-next-line max-len */}
      <img size="tiny" src={`https://images.rockerbox.com/integrations/integrations-icon-${platform}.svg`} style={{ marginRight: 5, width: 15, display: 'inline', marginBottom: '-3px' }} alt="platform icon" />
      {formatText(platform)}
    </span>
  );

  const reset = (newPlatform, currentPlatform) => {
    if (newPlatform === currentPlatform) return;
    if (newPlatform === 'platform') return;
    setLoading(true);
    setPlatform(newPlatform);
    setIndustry('All industries');
    setFilterType('Industry');
    setAdType('All ad types');
    setSpendLevel('all_spend');
    setSpendLevelOptions([]);
    setIndustryOptions([]);
    setAdTypeOptions([]);
  };

  const resetAdType = newAdType => {
    setAdType(newAdType);
    setSpendLevel('all_spend');
    setIndustry('All industries');
  };

  useEffect(() => {
    getBenchmarkData(platform).then(newData => {
      setData(filterData(newData.data, industry, 'industry', platform, adType));
      setUnfilteredData(newData.data);
      setLoading(false);
    });
  }, [platform]);

  useEffect(() => {
    if (!unfilteredData || !trendCategories) return;
    if (platform === 'google') setAdTypeOptions(getAdTypes(unfilteredData));
    setIndustryOptions(sortDropdownOptions(getIndustryNames(unfilteredData, adType, trendCategories.industries)));
    setSpendLevelOptions(getSpendLevels(unfilteredData, adType));
  }, [unfilteredData, filterType, adType, trendCategories]);

  useEffect(() => {
    if (!unfilteredData) return;
    if (filterType === 'Industry') {
      setData(filterData(unfilteredData, industry, 'industry', platform, adType));
    } else if (filterType === 'Spend Level') {
      setData(filterData(unfilteredData, spendLevel, 'spend_level', platform, adType));
    } else setData(data);
  }, [industry, spendLevel, adType, filterType]);

  return (
    <div className="filter">
      <FilterWrapper
        other={(
          <>
            <StyledDropdown
              label="Ad Platform"
              value={platform}
              placeholder="Loading..."
              text={activePlatform}
              style={{ width: '125px' }}
              advancedDropdown={(
                <Dropdown.Menu>
                  {PLATFORMS.map(x => (
                    <Dropdown.Item
                      key={x}
                      text={x.charAt(0).toUpperCase() + x.slice(1)}
                      value={x}
                      image={`https://images.rockerbox.com/integrations/integrations-icon-${x}.svg`}
                      onClick={() => reset(x, platform)}
                      active={platform === x}
                    />
                  ))}
                </Dropdown.Menu>
            )}
            />
            {platform === 'google' && (
              <StyledDropdown
                label="Ad Type"
                value={adType}
                placeholder="Loading..."
                text={formatText(adType)}
                advancedDropdown={(
                  <Dropdown.Menu>
                    {adTypeOptions.map(x => (
                      <Dropdown.Item
                        key={x}
                        text={formatText(x)}
                        value={x}
                        onClick={() => resetAdType(x)}
                        active={adType === x}
                      />
                    ))}
                  </Dropdown.Menu>
            )}
              />
            )}
            <StyledDropdown
              label="Filter By"
              value={filterType}
              placeholder="Loading..."
              text={filterType}
              advancedDropdown={(
                <Dropdown.Menu>
                  {FILTER_TYPES.map(x => (
                    <Dropdown.Item
                      key={x}
                      text={x}
                      value={x}
                      onClick={() => setFilterType(x)}
                      active={filterType === x}
                    />
                  ))}
                </Dropdown.Menu>
            )}
            />
            {filterType === 'Industry' && (
              <StyledDropdown
                label="Industry"
                value={industry}
                placeholder="Loading..."
                text={formatText(industry)}
                advancedDropdown={(
                  <Dropdown.Menu>
                    {industryOptions.map(x => (
                      <Dropdown.Item
                        key={x.value}
                        text={x.text}
                        value={x.value}
                        onClick={() => setIndustry(x.value)}
                        active={industry === x.value}
                      />
                    ))}
                  </Dropdown.Menu>
          )}
              />
            )}
            {filterType === 'Spend Level' && (
              <StyledDropdown
                label="Monthly Ad Spend"
                value={spendLevel}
                placeholder="Loading..."
                text={formatSpendText(spendLevel)}
                advancedDropdown={(
                  <Dropdown.Menu>
                    {spendLevelOptions.map(x => (
                      <Dropdown.Item
                        key={x.value}
                        text={formatSpendText(x.value)}
                        value={x.value}
                        onClick={() => setSpendLevel(x.value)}
                        active={spendLevel === x.value}
                      />
                    ))}
                  </Dropdown.Menu>
          )}
              />
            )}

          </>
      )}
        stickyRight={(
          <>
            <SaveReportButton {...{ loading }} />
            <ResetFilterButton />
          </>
)}
      />
    </div>
  );
};
export default Filter;

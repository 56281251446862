import React, { useState } from 'react';

// helpers
import { useGlobalState } from '../../hooks/global';

// state
import { useTiers } from './_hooks/tierFilters';
import { usePlatformPerformanceConfig } from './_hooks/platformConfig';
import { usePerformanceDataWithSelection } from './_hooks/performanceData';
import { useTreeColumns } from './_hooks/treeColumns';

// views
import PlatformPerformance from './PlatformPerformance';
import { platformPerformanceFirstReportingDate } from '../../utils/time';

const DigitalAdvertising = props => {
  const { account, currencyCode } = useGlobalState();
  const { platformSelection, urlState } = props;
  const { integrations } = platformSelection;
  const { tier } = account;

  const {
    view, // what page is shown
    platform, // what platform
    startDate, setStartDate, endDate, setEndDate, // what date range
  } = urlState;

  const performanceConfig = usePlatformPerformanceConfig();
  const { platformConfig } = performanceConfig;

  // read / reset tiers on url
  const { tier_1, tierFilterMap } = useTiers(view, platformConfig);
  const [firstReportingDate, setFirstReportingDate] = useState(platformPerformanceFirstReportingDate); // eslint-disable-line no-unused-vars

  // performance data configuration / selection
  const {
    defaultMetric, setDefaultMetric, // card selection metric
    optionalMetric, setOptionalMetric, // card selection metric
    conversionOptions,
    convValue, platformConversionEvent, updatePlatformConversionEvent,
    attributionWindowOptions,
    windowValue, attributionWindow, updateAttributionWindow,
    hasOptionSelected,
    dates,
  } = performanceConfig;

  const { revenue_formatter } = platformConfig;
  const { optional_metrics, default_metrics } = platformConfig;

  const { allTiers, tiers, setTiers, allColumns, selectedColumns, setSelectedColumns, groupBy } = useTreeColumns(
    platform,
    integrations,
    currencyCode,
    tier,
  );

  // get performance data using configuration
  const {
    platformPerformanceData,
    dailyPerformance,
    totalPerformance,
  } = usePerformanceDataWithSelection(
    { groupBy, attributionWindow, platformConversionEvent, revenue_formatter, tierFilterMap },
  );

  // State update handlers
  const onDefaultMetricChange = (e, { value }) => setDefaultMetric(value);
  const onOptionalMetricChange = (e, { value }) => setOptionalMetric(value);

  const onDateChange = ({ startDate, endDate }) => { // eslint-disable-line no-shadow
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const { display_platform_name } = platformConfig;
  const headerText = `What does my advertising performance look like according to ${display_platform_name}’s default tracking?`;

  return (
    <PlatformPerformance
      {...{
        convValue, windowValue,
        conversionOptions, attributionWindowOptions,
        updatePlatformConversionEvent, updateAttributionWindow,
      }} // values/methods for conversions metrics dropdown
      {...{ headerText, tier_1, platform, platformConfig, startDate, endDate, onDateChange, firstReportingDate }}  // Basic params and filters
      {...{
        platformPerformanceData,
        integrations, dates,
        dailyPerformance, totalPlatformPerformance: totalPerformance,
      }} // Data
      {...{ optionalMetric, defaultMetric }} // initial trend card values
      {...{ onOptionalMetricChange, onDefaultMetricChange }} // methods for trend cards
      {...{ allTiers, tiers, setTiers, allColumns, selectedColumns, setSelectedColumns }} // for customize cols and edit breakdowns
      {...{ optional_metrics, hasOptionSelected, hasAvailConvMetrics: true, default_metrics, account }}
    />
  );
};

export default DigitalAdvertising;

import { SET_REPORT_DELIVERY_STATUS, RESET_REPORT_DELIVERY_STATUS } from './constants';

const initialState = {
  report: {},
  dates: [],
  reportDeliveryStatusData: [],
  resolved: false
};

export default function(state=initialState, action) {
  switch (action.type) {
    case SET_REPORT_DELIVERY_STATUS:
      const response = action.data.response;
      return {
        ...state,
        dates: response.dates,
        reportDeliveryStatusData: response.report_status,
        report: response.report,
        resolved: true
      };
    case RESET_REPORT_DELIVERY_STATUS:
      return {
        ...state,
        report: {},
        dates: [],
        reportDeliveryStatusData: [],
        resolved: false
      }
    default:
      return state;
  }
}


/* eslint-disable import/prefer-default-export */
import { useContext, useMemo } from 'react';

import { CacheContext } from '../../utils/CacheContext';
import { getTierColors, getAccount, getAccountCurrency, getAdvertiserAccessibleFeatures, getUser, getAccountUsers, getAuthorizations, getAllIntegrations, getUserPermissions } from '../api/account';
import { getArtifacts, getSegments, getAnnouncements, getSavedReports } from '../api/attribution';

export const useGlobalState = () => {
  const [state, setState, Context] = useContext(CacheContext);
  const accessibleFeatures = Context.getCache('accessibleFeatures', getAdvertiserAccessibleFeatures) || null;
  const account = Context.getCache('account', getAccount) || {};
  const announcements = Context.getCache('announcements', getAnnouncements) || [];
  const artifacts = Context.getCache('artifacts', getArtifacts) || {};
  const authorizations = Context.getCache('authorizations', getAuthorizations) || null;
  const currencyCode = Context.getCache('currency_code', getAccountCurrency) || 'USD';
  const integrations = Context.getCache('allIntegrations', getAllIntegrations) || [];
  const permissions = Context.getCache('permissions', getUserPermissions) || {};
  const savedReports = Context.getCache('savedReports', getSavedReports) || [];
  const segments = Context.getCache('segments', getSegments) || [];
  const tierColors = Context.getCache('tierColors', getTierColors) || undefined;
  const user = Context.getCache('user', getUser) || {};
  const users = Context.getCache('users', getAccountUsers) || null;

  const getCommonPlatforms = () => {
    const authorizationPlatforms = authorizations.map(auth => auth.platform);
    const integrationPlatforms = integrations.map(integration => integration.platform);
    const commonPlatforms = authorizationPlatforms.filter(platform => integrationPlatforms.includes(platform));
    return commonPlatforms;
  };

  const dataStatusPlatforms = useMemo(() => {
    if (!authorizations || !integrations) return [];
    const commonPlatforms = getCommonPlatforms(authorizations, integrations);
    return commonPlatforms;
  }, [authorizations, integrations]);

  return {
    state, setState, Context,
    accessibleFeatures,
    account,
    announcements,
    artifacts,
    authorizations,
    currencyCode,
    dataStatusPlatforms,
    integrations,
    permissions,
    savedReports,
    segments,
    tierColors,
    user,
    users,
  };
};

import json2csv from 'json2csv';

import { track } from '../../../../../utils/tracking';
import { roundNumber } from '../../helpers';

export const formatDataForExport = (rawData, featureNameMap, featureStats, numDays) => {
  if (!rawData) return [];
  const { forecastRows, baseline, summary } = rawData;
  const formattedData = forecastRows.map(row => {
    const { key, targetValue, revenueAtTargetValue, revenueAtCurrentValue } = row;
    const totalSpend = targetValue * numDays;
    const predictedRevenue = revenueAtTargetValue * numDays;
    const currentRevenue = revenueAtCurrentValue * numDays;
    const predictedRoas = predictedRevenue / totalSpend;
    const currentRoas = currentRevenue / totalSpend;
    return {
      'Channel / Tactic': featureNameMap[key],
      'Current Daily Spend': roundNumber(featureStats[key].currentSpend),
      'Target Daily Spend': roundNumber(targetValue),
      'Total Spend': roundNumber(totalSpend),
      'Predicted Revenue': roundNumber(predictedRevenue),
      'Revenue vs Current': roundNumber(predictedRevenue - currentRevenue),
      'Predicted ROAS': isNaN(predictedRoas) ? 0 : roundNumber(predictedRoas, 2),
      'ROAS vs Current': isNaN(currentRoas) ? 0 : isNaN(predictedRoas) ? 0 : roundNumber(predictedRoas - currentRoas, 2),
    };
  });
  formattedData.push({
    'Channel / Tactic': 'Marketing Summary',
    'Current Daily Spend': roundNumber(summary.dailySpend),
    'Target Daily Spend': roundNumber(summary.targetDailySpend),
    'Total Spend': roundNumber(summary.totalSpend),
    'Predicted Revenue': roundNumber(summary.predictedRevenue),
    'Revenue vs Current': roundNumber(summary.revenueDiff),
    'Predicted ROAS': roundNumber(summary.predictedRoas, 2),
    'ROAS vs Current': roundNumber(summary.roasDiff, 2),
  });
  if (baseline > 0) {
    formattedData.push({
      'Channel / Tactic': 'Seasonality Adjustment',
      'Predicted Revenue': roundNumber(baseline),
    });
    formattedData.push({
      'Channel / Tactic': 'Total Summary',
      'Predicted Revenue': roundNumber(summary.predictedRevenue + baseline),
    });
  }
  return formattedData;
};

export const buildExportCSVFunction = (data, featureNameMap, featureStats, advertiser_name, numDays) => () => {
  const parser = new json2csv.Parser();
  const formattedData = formatDataForExport(data, featureNameMap, featureStats, numDays);
  const csv = parser.parse(formattedData);

  const csvContent = `data:text/csv;charset=utf-8,${csv}`;
  const fileName = `${advertiser_name} Budget Worksheet (${numDays}).csv`;

  const link = document.createElement('a');
  link.setAttribute('href', csvContent);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  track('modeling.mmm.budget_worksheet.download');
};

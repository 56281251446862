import { prettyDisplayNames } from '../../../utils/channelPerformance/helpers';

export const arrayToOptions = arr => arr.map(c => ({ text: c, value: c }));

export const keyValObjectToNestedOptionsArray = (obj, platformNameMap) => Object.entries(obj).map(([text, values]) => {
  const options = arrayToOptions(values).map(c => {
    const updated = { ...c };
    updated.text = prettyDisplayNames(updated.text);
    return updated;
  });
  const formattedText = platformNameMap[text] || prettyDisplayNames(text);

  return { text: formattedText, value: text, options };
});

export const invertKeysAndValues = obj => {
  const keys = Object.keys(obj);
  const invertedObj = {};

  keys.forEach(key => {
    const values = obj[key];
    values.forEach(val => {
      const currentKeysAssociatedWithValue = invertedObj[val] || [];
      invertedObj[val] = [...currentKeysAssociatedWithValue, key];
    });
  });

  return invertedObj;
};

const textNotBlank = row => row.text !== '';

export const buildConversionMetricsDropdown = (data, platformNameMap) => {
  if (!data || !data.hasOwnProperty('nested_columns')) return []; // eslint-disable-line no-prototype-builtins

  const { nested_columns } = data;
  const attributionCol = invertKeysAndValues(nested_columns);
  const conversionsList = keyValObjectToNestedOptionsArray(attributionCol, platformNameMap);

  return conversionsList.filter(textNotBlank);
};

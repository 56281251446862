import { useState, useEffect, useMemo } from 'react';
import { yesterday, monthAgo } from '../../../utils/time';
import { useURLStateAndSetDefault } from '../../../hooks/urlState';
import { getConversionFunnelConfigs } from '../../../api/attribution';

const checkIsValidSegment = (id, segments) => !!segments.find(x => x.filter_id === Number(id));

export const usePageParams = segments => {
  const [funnelConfigId, setFunnelConfigId] = useURLStateAndSetDefault('conversionFunnelConfigId', '0', true);
  const [startDate, setStartDate] = useURLStateAndSetDefault('startDate', monthAgo, true);
  const [endDate, setEndDate] = useURLStateAndSetDefault('endDate', yesterday, true);
  const [funnelConfiguration, setFunnelConfiguration] = useState(false);
  const [funnelConfigName, setFunnelConfigName] = useState('');

  const hasSegments = segments && segments.length > 0;

  const resetFunnelConfigState = () => {
    setFunnelConfigId(0);
    setFunnelConfiguration([]);
    setFunnelConfigName('');
  };

  useEffect(() => {
    getConversionFunnelConfigs()
      .then(data => {
        if (!data || !data.length) {
          resetFunnelConfigState();
          return;
        }
        const { id, name, conversion_event_id_1, conversion_event_id_2, conversion_event_id_3, conversion_event_id_4, conversion_event_id_5 } = data[0];
        const funnelSegments = [conversion_event_id_1, conversion_event_id_2, conversion_event_id_3, conversion_event_id_4, conversion_event_id_5].filter(e => e);
        setFunnelConfigId(id);
        setFunnelConfiguration(funnelSegments);
        setFunnelConfigName(name);
      })
      .catch(err => console.log(err)); // eslint-disable-line no-console
  }, []);

  const hasInvalidSegments = useMemo(() => {
    if (!hasSegments || !funnelConfigId || !funnelConfiguration.length) return false;

    return !funnelConfiguration.every(e => checkIsValidSegment(e, segments));
  }, [funnelConfiguration, segments]);

  return {
    startDate, setStartDate,
    endDate, setEndDate,
    funnelConfigId: Number(funnelConfigId), setFunnelConfigId, funnelConfigName,
    funnelConfiguration, setFunnelConfiguration,
    hasInvalidSegments,  // TODO: warning in UI if segment is invalid and no featured?
  };
};

import create from "zustand";
import moment from "moment";
import {
  getDataWarehouseConnections,
  submitGCPDataWarehouseSetup,
  fetchAwsRegions,
  runAwsRedshiftInit,
  fetchClusterName,
  fetchRedshiftSetupScript,
  fetchDatasetMeta,
  fetchSnapshotMeta,
  insertNewSnapshot,
  getConversionDataSummary,
  createNewWarehouseDataset,
  deletewWarehouseDataset,
  fetchDatasetMetaAccounting,
  fetchSnapshotSchedule,
  insertNewSnapshotSchedule,
  deleteSnapshotSchedule
} from "../../utils/api";
import { trackJob } from "../../utils/job_api";
import { getJobs } from "./helper";
import {
  STATUS_PENDING,
  STATUS_INCOMPLETE,
  steps,
  SYNC_CARD_DEFAULT
} from "./constants";

export const warehouseStore = create(set => ({
  fetchWarehouse: async () => {
    const warehouses = await getDataWarehouseConnections();
    set({ warehouses });
    return warehouses;
  },
  warehouses: null,
  activeWarehouseId: null,
  setActiveWarehouseId: id => set({ activeWarehouseId: id })
}));

export const statusStore = create(set => ({
  status: STATUS_PENDING,
  setStatus: status => set({ status }),
  activeStep: steps[0],
  setActiveStep: step => set({ activeStep: step }),
  setupFormLoading: false,
  changeSetupFormLoading: loading => set({ setupFormLoading: loading }),
  snapshotLoading: false,
  changeSnapshotLoading: loading => set({ snapshotLoading: loading }),
  datasetCardActive: false,
  setDatasetCardStatus: status => set({ datasetCardActive: status }),
  syncStatus: "stats",
  setSyncStatus: status => set({ syncStatus: status })
}));

export const googleCloudBucketStore = create(set => ({
  bucketName: null,
  region: null,
  filterId: null,
  account: null,
  accountType: null,
  onFieldChange: field => (e, data) => set({ [field]: data.value }),
  submitSetup: async body => {
    const job_id = await submitGCPDataWarehouseSetup(body);
    return job_id;
  }
}));

export const snowflakeFormStore = create(set => ({
  accountType: null,
  clientUrl: null,
  setAccountType: type => set({ accountType: type }),
  setClientUrl: url => set({ clientUrl: url }),
  conversionNum: null,
  fetchConversionNum: async filter_id => {
    const summary = await getConversionDataSummary(filter_id, false);
    set({
      conversionNum:
        // conversion_data_original is the original conversion_data
        // conversion_data in summary is rekeyed
        (summary["conversion_data_original"] &&
          summary["conversion_data_original"].length) ||
        null
    });
  }
}));

export const redshiftStore = create(set => ({
  regions: null,
  region: "us-east-1",
  // cluster name will decide the status of setup
  // when cluster is none, setup is not begin.
  // when cluster_name is 'incomplete', setup is pending for client to run script
  // when cluster_name exists, setup is finished
  clusterName: null,
  setupScript: "",
  fetchAwsRegions: async () => {
    const regions = await fetchAwsRegions();
    const regionOptions = regions
      ? Object.entries(regions).map(([key, value]) => ({
          key: value,
          text: key,
          value: value
        }))
      : [];
    set({ regions: regionOptions });
  },
  fetchRedshiftSetupScript: async () => {
    const script = await fetchRedshiftSetupScript();
    set({ setupScript: script });
  },
  fetchClusterName: async id => {
    const connection = await fetchClusterName(id);
    const clusterName = connection["cluster_name"] || STATUS_INCOMPLETE;
    set({ clusterName });
  },
  selectRegion: value => set({ region: value }),
  initSetup: async obj => {
    const response = await runAwsRedshiftInit(obj);
    return response;
  }
}));

export const datasetAccountingStore = create((set, get) => ({
  fetchDatasetMeta: async id => {
    const { data, meta } = await fetchDatasetMeta(id);

    if (data && data[0]) {
      for (let field of [
        "warehouse_table_name",
        "dataset",
        "filter_id",
        "id"
      ]) {
        meta[field] = data[0][field];

        if (field === "warehouse_table_name") {
          meta[field] += "_live";
        }
      }

      const {
        sync_accounting: syncAccounting = [],
        meta_accounting: metaAccounting = []
      } = data[0];
      syncAccounting &&
        syncAccounting.sort((a, b) => moment(b.end_date) - moment(a.end_date));
      set({
        meta,
        syncAccounting: syncAccounting || [],
        metaAccounting: metaAccounting || []
      });
    } else {
      set({ meta });
    }
  },
  fetchSnapshots: async datasetId => {
    const snapshots = await fetchSnapshotMeta(datasetId);
    const updateTableNameSnapshots = snapshots.map(snapshot =>
      Object.assign({}, snapshot, {
        warehouse_table_name: snapshot["warehouse_table_name"] + "_snapshot"
      })
    );
    set({ snapshots: updateTableNameSnapshots });
    return;
  },
  fetchSnapshotMeta: async (datasetId, snapshotId) => {
    const resp = await fetchSnapshotMeta(datasetId, snapshotId);
    const snapshot = resp[0];
    if (!snapshot) {
      return;
    }
    if (snapshot && snapshot["warehouse_table_name"]) {
      snapshot["warehouse_table_name"] =
        snapshot["warehouse_table_name"] + "_snapshot";
    }
    const metaAccounting = snapshot["meta"];
    delete snapshot["meta"];
    set({ metaAccounting, snapshotMeta: snapshot });
  },
  createNewSnapshot: async (datasetId, newSnapshot) => {
    const setStatus = get().setSnapshotJobStatus;
    set({ snapshotJobUrl: null, snapshotJobStatus: null });
    let resp = null;
    const requestBody = Object.assign({}, newSnapshot, {
      advertiser_warehouse_dataset_id: datasetId
    });
    try {
      resp = await insertNewSnapshot(requestBody);
    } catch (e) {
      setStatus("failed");
      return;
    }

    const { id, meta, job_id, sync_status } = resp["response"][0];
    trackJob(requestBody, datasetId, "snapshotCreation", job_id);
    getJobs(setStatus)("snapshotCreation")(datasetId);
    const jobUrl =
      process.env.NODE_ENV === "development"
        ? `http://wq.staging.internal.getrockerbox.com/v2/workqueue_status/job/${job_id}`
        : `http://wq.internal.getrockerbox.com/v2/workqueue_status/job/${job_id}`;
    set({
      snapshotId: id,
      snapshotJobUrl: jobUrl
    });
  },
  createNewDataset: async body => {
    const resp = await createNewWarehouseDataset(body);
    const newDatasetId = resp[0]["id"];
    set({ datasetId: newDatasetId });
  },
  deleteDataset: async id => {
    const resp = await deletewWarehouseDataset(id);
    console.log(resp);
  },
  datasetId: null,
  setDatasetId: id => set({ datasetId: id }),
  newDataset: {},
  setNewDataset: (field, value) =>
    set({ newDataset: { ...get().newDataset, [field]: value } }),
  datasetMetadataHistry: [],
  fetchDatasetMetadataHistry: async date => {
    const history = await fetchDatasetMetaAccounting(get().datasetId, date);
    set({ datasetMetadataHistry: history });
  },
  meta: {},
  snapshots: [],
  snapshotId: null,
  snapshotJobUrl: null,
  snapshotJobStatus: null,
  setSnapshotJobStatus: status => set({ snapshotJobStatus: status }),
  setSnapshotId: id => set({ snapshotId: id }),
  snapshotMeta: {},
  newSnapshot: {},
  setNewSnapshot: (field, value) =>
    set({ newSnapshot: { ...get().newSnapshot, [field]: value } }),
  syncAccounting: [],
  metaAccounting: [],
  resetSnapshot: () =>
    set({
      snapshotId: null,
      snapshotJobUrl: null,
      snapshotJobStatus: null,
      Meta: {},
      metaAccounting: []
    }),
  snapshotSchedules: [],
  newSnapshotSchedule: {},
  setNewSnapshotSchedule: (field, value) =>
    set({
      newSnapshotSchedule: { ...get().newSnapshotSchedule, [field]: value }
    }),
  fetchSnapshotSchedule: async snapshotId => {
    const schedules = await fetchSnapshotSchedule(snapshotId);
    set({ snapshotSchedules: schedules });
  },
  createNewSnapshotSchedule: async body => {
    const datasetId = get().datasetId;
    const newSchedules = await insertNewSnapshotSchedule(body);
    const schedules = newSchedules.filter(
      schedule => schedule.dataset_id === datasetId
    );

    set({ snapshotSchedules: schedules });
  },
  deleteSnapshotScheduleById: async scheduleId => {
    const datasetId = get().datasetId;
    const newSchedules = await deleteSnapshotSchedule(scheduleId);
    const schedules = newSchedules.filter(
      schedule => schedule.dataset_id === datasetId
    );
    set({ snapshotSchedules: schedules });
  }
}));

export const syncCardStore = create((set, get) => ({
  activeSegment: null,
  selectSegment: segment => set({ activeSegment: segment }), // this is for add action, need to select from segment lists
  cardStatus: SYNC_CARD_DEFAULT,
  cardStatusHistory: [],
  updateCardStatus: status =>
    set({
      cardStatus: status,
      cardStatusHistory: [...get().cardStatusHistory, status]
    }),
  cardStatusBack: () => {
    const history = get().cardStatusHistory;
    const newHistory = [...history];
    newHistory.pop();
    if (newHistory.length === 0) {
      set({ cardStatus: SYNC_CARD_DEFAULT, cardStatusHistory: newHistory });
    } else {
      set({
        cardStatus: newHistory[newHistory.length - 1],
        cardStatusHistory: newHistory
      });
    }
  },
  newDataset: null, // object that contains new dataset info for active segment
  setNewDataset: (field, value) =>
    set({
      newDataset: Object.assign({}, get().newDataset, { [field]: value })
    }),
  removeNewDataset: () => set({ newDataset: null }),
  deleteDataset: id => {}, // api call to delete dataset
  cardLoading: false,
  setCardLoading: loading => set({ cardLoading: !!loading }),
  syncStatusActiveTab: "stats",
  setActiveTab: status => set({ syncStatusActiveTab: status })
}));
// debug
if (process.env.NODE_ENV === "development") {
  // warehouseStore.subscribe(console.log);
  // statusStore.subscribe(console.log);
  // googleCloudBucketStore.subscribe(console.log);
  // syncCardStore.subscribe(console.log);
  // redshiftStore.subscribe(console.log);
  datasetAccountingStore.subscribe(console.log);
}

import {
  fieldValuesToStartingPosition,
  calcTotalSpendFromFieldValues,
  evalChannelConstraints,
  findHighestROIChannel,
  findLowestROIChannel,
  startingFieldValuesFromCurrent,
} from './optimizeHelpers';
import { getCurrentRoas } from '../../../forecast/forecastData';

const INCREMENT = 50;

/**
 * Optimizes the field values to meet the target spend by incrementing the recommended values of the highest ROI channel.
 * Optimization start at the *minimum value* for each channel and budget is allocated to the highest ROI channel until:
 * - the target spend is met or
 * - all channel level constraints are met.
 *
 * @param {number} targetSpend - The target spend to optimize towards.
 * @param {object} constraints - The constraints for each channel.
 * @param {object} fieldValues - The current field values.
 * @param {object} responseCurves - The response curves for each channel.
 * @returns {object} - The optimized field values.
 */
export const optimizeToBudget = (targetSpend, constraints, fieldValues, responseCurves) => {
  const newFieldValues = fieldValuesToStartingPosition(fieldValues, constraints, 'min_value');
  let totalCurrentSpend = calcTotalSpendFromFieldValues(newFieldValues);

  while (totalCurrentSpend < targetSpend) {
    try {
      const highestROIChannel = findHighestROIChannel(newFieldValues, constraints, responseCurves);
      newFieldValues[highestROIChannel].recommended += INCREMENT;
      newFieldValues[highestROIChannel].value = newFieldValues[highestROIChannel].recommended;
      totalCurrentSpend += INCREMENT;
    } catch (e) {
      break;
    }
  }
  return newFieldValues;
};
/**
 * Optimizes the budget by expanding the spend on the highest ROI channel until the target spend is reached.
 * Optimization start at the *current value* for each channel and budget is allocated to the highest ROI channel until:
 * - the target spend is met or
 * - all channel level constraints are met.
 *
 * @param {number} targetSpend - The target spend to reach.
 * @param {object} constraints - The constraints for each channel.
 * @param {object} fieldValues - The current field values.
 * @param {object} responseCurves - The response curves for each channel.
 * @returns {object} - The new field values after optimization.
 */
export const optimizeExpandBudget = (targetSpend, constraints, fieldValues, responseCurves) => {
  const newFieldValues = startingFieldValuesFromCurrent(fieldValues, constraints);
  let totalCurrentSpend = calcTotalSpendFromFieldValues(newFieldValues);

  while (totalCurrentSpend < targetSpend) {
    try {
      const highestROIChannel = findHighestROIChannel(newFieldValues, constraints, responseCurves);
      newFieldValues[highestROIChannel].recommended += INCREMENT;
      newFieldValues[highestROIChannel].value = newFieldValues[highestROIChannel].recommended;
      totalCurrentSpend += INCREMENT;
    } catch (e) {
      break;
    }
  }
  return newFieldValues;
};

/**
 * Optimizes the budget by reducing spend on channels with the lowest ROI until the target spend is reached.
 * Optimization start at the *current value* for each channel and budget is reduced from the lowest ROI channel until:
 * - the target spend is met or
 * - all channel level constraints are met.
 *
 * @param {number} targetSpend - The target spend amount.
 * @param {object} constraints - The constraints for each channel.
 * @param {object} fieldValues - The current field values.
 * @param {object} responseCurves - The response curves for each channel.
 * @returns {object} - The new field values after optimization.
 */
export const optimizeReduceBudget = (targetSpend, constraints, fieldValues, responseCurves) => {
  const newFieldValues = startingFieldValuesFromCurrent(fieldValues, constraints);
  let totalCurrentSpend = calcTotalSpendFromFieldValues(newFieldValues);

  while (totalCurrentSpend > targetSpend) {
    try {
      const lowestROIChannel = findLowestROIChannel(newFieldValues, constraints, responseCurves);
      newFieldValues[lowestROIChannel].recommended -= INCREMENT;
      newFieldValues[lowestROIChannel].value = newFieldValues[lowestROIChannel].recommended;
      totalCurrentSpend -= INCREMENT;
    } catch (e) {
      break;
    }
  }
  return newFieldValues;
};

/**
 * Optimizes the Return on Advertising Spend (ROAS) by adjusting spend values for different channels.
 * Optimization start at the *minimum value* for each channel.
 *
 * Channels with ROAS below the target are decreased until:
 * - the channel is at the minimum spend
 * - the ROAS is above the target
 *
 * Channels with ROAS above the target are increased until:
 * - the channel is at the max spend
 * - the channel's ROAS is below the target
 *
 * @param {number} targetROAS - The target ROAS value.
 * @param {object} constraints - The constraints for each channel.
 * @param {object} fieldValues - The current spend values for each channel.
 * @param {object} responseCurves - The response curves for each channel.
 * @returns {object} - The optimized spend values for each channel.
 */
export const optimizeROAS = (targetROAS, constraints, fieldValues, responseCurves) => {
  const minFieldValues = fieldValuesToStartingPosition(fieldValues, constraints, 'min_value');
  const newFieldValues = minFieldValues;
  const adjustableFieldValues = Object.entries(newFieldValues).filter(([, v]) => !v.fixed);

  // for channels where ROAS is below the target, decrease spend until
  // - the channel is at the minimum spend
  // - the ROAS is above the target
  while (true) { // eslint-disable-line no-constant-condition
    try {
      const eligible = adjustableFieldValues
        .map(([k, v]) => [k, Object.assign(v, { currentROAS: getCurrentRoas(responseCurves, k, v.recommended) })])
        .filter(([k, v]) => evalChannelConstraints(k, v, constraints, 'decrease'));

      const channels = eligible.filter(([, v]) => v.currentROAS < targetROAS).sort((a, b) => a.currentROAS - b.currentROAS);

      const channel = channels[0];
      const channelKey = channel[0];
      const operation = a => a - INCREMENT;

      newFieldValues[channelKey].recommended = operation(newFieldValues[channelKey].recommended);
      newFieldValues[channelKey].value = newFieldValues[channelKey].recommended;
    } catch (e) {
      break;
    }
  }

  // for channels where ROAS is above the target, increase spend until
  // - the channel is at the max spend
  // - the channel's ROAS is below the target
  while (true) { // eslint-disable-line no-constant-condition
    try {
      const eligible = adjustableFieldValues
        .map(([k, v]) => [k, Object.assign(v, { currentROAS: getCurrentRoas(responseCurves, k, v.recommended, 'increase') })])
        .filter(([k, v]) => evalChannelConstraints(k, v, constraints, 'increase'));

      const channels = eligible.filter(([, v]) => v.currentROAS > targetROAS).sort((a, b) => b.currentROAS - a.currentROAS);

      const channel = channels[0];
      const channelKey = channel[0];
      const operation = a => a + INCREMENT;

      newFieldValues[channelKey].recommended = operation(newFieldValues[channelKey].recommended);
      newFieldValues[channelKey].value = newFieldValues[channelKey].recommended;
    } catch (e) {
      break;
    }
  }

  return newFieldValues;
};

import { useState, useEffect, useMemo } from 'react';

import { debounce } from 'lodash';

import { getStartAndEndDate, filterObjectBySelectedKeys, sumByDate, filterArrayBySelectedKeys } from './helpers';
import { getEventStatusHourlyData, getEventStatusSummaryData, getParametersSummary, getParametersHourly } from '../../api/dataReporting';
import { colorHash } from '../../utils/colorHash';

export const useDatasets = (eventType, date) => {
  const [eventStatusData, setEventStatusData] = useState([]);
  const [paramsSummary, setparamsSummary] = useState([]);
  const [paramsHourly, setparamsHourly] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [groupBy, setGroupBy] = useState('hourly');

  const [searchTerm, setSearchTerm] = useState('');
  const [originalChartKeys, setOriginalChartKeys] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [updatedChartData, setUpdatedChartData] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [updatedEventStatusData, setUpdatedEventStatusData] = useState([]);

  useEffect(() => {
    setLoading(true);
    const { startDate, endDate } = getStartAndEndDate(date);

    const apiCalls = [
      getEventStatusHourlyData(eventType, startDate, endDate),
      getEventStatusSummaryData(eventType, startDate, endDate),
      getParametersSummary(eventType, startDate, endDate),
      getParametersHourly(eventType, startDate, endDate),
    ];

    Promise.all(apiCalls).then(responses => {
      const [chart, summaryData, parametersSummary, parametersHourly] = responses;
      const keys = Object.keys(chart[0]);

      summaryData.sort((a, b) => a.action.toLowerCase().localeCompare(b.action.toLowerCase())); // match order of keys in chart

      setChartData(chart);
      setEventStatusData(summaryData);
      setparamsSummary(parametersSummary);
      setparamsHourly(parametersHourly);
      setGroupBy('hourly');
      setLoading(false);
      setSelectAllChecked(true);
      setOriginalChartKeys(keys);
      setCheckedItems(keys);
      setSearchTerm('');
    });
  }, [eventType, date]);

  const tierColorMap = useMemo(() => {
    if (!chartData) return {};
    return chartData.reduce((accu, { date: currentDate, ...rest }) => {
      Object.keys(rest).forEach(key => {
        if (!accu[key]) {
          accu[key] = colorHash(key);
        }
      });
      return accu;
    }, {});
  }, [chartData]);

  const filteredKeys = useMemo(() => (
    searchTerm === '' ? originalChartKeys : originalChartKeys.filter(c => c.toLowerCase().includes(searchTerm.toLowerCase()))
  ), [searchTerm, originalChartKeys]);

  useEffect(() => {
    const grouped = groupBy === 'daily' ? sumByDate(chartData) : chartData;
    const selectedKeysSet = new Set(filteredKeys.filter(key => checkedItems.includes(key)));

    // Ensure date key is always included in updatedChartData so x-axis doesn't break on search
    const filteredObjects = grouped.map(obj => ({
      date: obj.date,
      ...filterObjectBySelectedKeys(obj, selectedKeysSet),
    }));

    const filteredEventStatusData = filterArrayBySelectedKeys(eventStatusData, selectedKeysSet);

    setUpdatedChartData(filteredObjects);
    setUpdatedEventStatusData(filteredEventStatusData);

    const allFilteredChecked = filteredKeys.filter(key => key !== 'date').every(key => checkedItems.includes(key));
    setSelectAllChecked(allFilteredChecked);
  }, [checkedItems, chartData, filteredKeys, groupBy, eventStatusData, searchTerm]);

  const handleCheckboxChange = (e, { name }) => {
    if (checkedItems.includes(name)) {
      setCheckedItems(checkedItems.filter(item => item !== name));
    } else {
      setCheckedItems([...checkedItems, name]);
    }
  };

  const handleToggleAll = () => {
    const itemsToDeselect = checkedItems.filter(key => !filteredKeys.includes(key) || key === 'date');
    const itemsToSelect = [...new Set([...checkedItems, ...filteredKeys.filter(key => key !== 'date')])];

    if (selectAllChecked) {
      setCheckedItems(itemsToDeselect);
    } else {
      setCheckedItems(itemsToSelect);
    }

    setSelectAllChecked(!selectAllChecked);
  };

  const debouncedSearch = debounce(value => {
    setSearchTerm(value);
  }, 300);

  const handleSearchChange = (e, { value }) => {
    debouncedSearch(value);
  };

  return {
    chartData,
    paramsSummary,
    paramsHourly,
    tierColorMap,
    loading,
    groupBy,
    setGroupBy,
    handleSearchChange,
    filteredKeys,
    updatedChartData,
    handleToggleAll,
    handleCheckboxChange,
    selectAllChecked,
    checkedItems,
    updatedEventStatusData,
  };
};

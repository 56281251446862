import React, { Component } from 'react';
import { ContentCard, IndexGrid, SortableIndexGrid } from '@rockerbox/styleguide'
import { Form, Grid, Input, Button, Container, Message, Table } from 'semantic-ui-react';



const touchpointCols = [
  {
    "display": "tier_1",
    "key": "tier_1",
    as: IndexGrid.EllipsedCell, headerWidth: 3
  },
  {
    "display": "tier_2",
    "key": "tier_2",
    as: IndexGrid.EllipsedCell, headerWidth: 3
  },
  {
    "display": "tier_3",
    "key": "tier_3",
    as: IndexGrid.EllipsedCell, headerWidth: 3
  },
  {
    "display": "tier_4",
    "key": "tier_4",
    as: IndexGrid.EllipsedCell, headerWidth: 3
  },
  {
    "display": "tier_5",
    "key": "tier_5",
    as: IndexGrid.EllipsedCell, headerWidth: 3
  },
  {
    "display": "users",
    "key": "num_users"
  },
  {
    "display": "first touches",
    "key": "is_first_touch"
  },
  {
    "display": "multi-touch users",
    "key": "num_multi_touch_users"
    ,"tag": "corr"
  },
  {
    "display": "multi-touch rate",
    "key": "multi_touch_rate"
    ,"tag": "corr"
  },
  {
    "display": "related touches",
    "key": "num_related"
    ,"tag": "corr"
  },
  {
    "display": "unique combos",
    "key": "num_groups"
    ,"tag": "corr"
  },
  {
    "display": "conv",
    "key": "num_converters"
  },
  {
    "display": "conv rate",
    "key": "conversion_rate",
    as: IndexGrid.Percent
  },
  {
    "display": "weight",
    "key": "weight"
  },
  {
    "display": "f1_gain",
    "key": "f1_gain"
  },
  {
    "display": "explore",
    "key": "explore",
  }
]

const FeatureDrillIn = ({name, selectedFeature, allFeatures}) => {

  const { related_touches } = selectedFeature;
  const keys = related_touches;
  const nameOnly = name.split("|")[0];

  const touches = (name.indexOf("||||") > -1) ?
    keys.filter(x => x.touchpoint.indexOf(nameOnly) == -1) :
    keys.filter(x => x.touchpoint != `${nameOnly}||||`);

  const columns = [
    { "display": "tier_1", "key": "tier_1", as: IndexGrid.EllipsedCell, headerWidth: 2 },
    { "display": "tier_2", "key": "tier_2", as: IndexGrid.EllipsedCell, headerWidth: 2 },
    { "display": "tier_3", "key": "tier_3", as: IndexGrid.EllipsedCell, headerWidth: 2 },
    { "display": "tier_4", "key": "tier_4", as: IndexGrid.EllipsedCell, headerWidth: 2 },
    { "display": "tier_5", "key": "tier_5", as: IndexGrid.EllipsedCell, headerWidth: 2 },
    { "display": "overlapping touches", "key": "related_value" },
    { "display": "percent overlap (related)", "key": "related_overlap", as: Percent, "total": "related_total" },
    { "display": "percent overlap (feature)", "key": "overlap", as: Percent, "total": "total" },
    { "display": "correlation", "key": "correlation" }
  ]

  const kvs = touches
    .map(o => {
      const original = {
        related_value: o.num_touches,
        related_total: allFeatures[o.touchpoint],
        related_overlap: o.num_touches/allFeatures[o.touchpoint],
        total: selectedFeature.num_users,
        overlap: o.num_touches/selectedFeature.num_users,
        correlation: o.correlation
      }

      return o.touchpoint.split("|").reduce((p,c,i) => {p[`tier_${i+1}`] = c; return p}, original)
    })
    .sort((p,c) => c.correlation - p.correlation)

    return (
      <IndexGrid
        as={ContentCard.Table}
        cols={columns}
        data={kvs}
      />
    )
}

const Percent = props => (
  <Table.Cell>
    { props.item[props.col.key].toLocaleString("en", {style: "percent", minimumFractionDigits: 2}) }
    &nbsp;
    ({ props.item[props.col.total] })
  </Table.Cell>
);

const OnClick = (onClick) => props => (
  <Table.Cell width={6} onClick={() => onClick(props.item) }>
    Explore
  </Table.Cell>
);

const Title = ({ onClick, title}) => (
  <span>
    { onClick && <span><a onClick={onClick}>Back </a> | Touches relate to: </span>  }
    { title }
  </span>
)

class Features extends React.Component {
  state = {
    selectedTouchpointName: false,
    excludeTag: "corr"
  }

  onSelectFeature = (row, i) => {
    this.setState({ selectedTouchpointName: row.touchpoint })
  }

  resetSelectFeature = () => {
    this.setState({ selectedTouchpointName: false })
  }

  toggleColumns = () => {
    this.setState({ excludeTag: this.state.excludeTag == "corr" ? "" : "corr"})
  }

  render() {

    const { touchpoints } = this.props;
    const { excludeTag, selectedTouchpointName } = this.state;

    touchpointCols[touchpointCols.length -1]['as'] = OnClick(this.onSelectFeature)

    const selectedFeature = (selectedTouchpointName !== false) ?
      touchpoints.filter(x => x.touchpoint == selectedTouchpointName)[0] :
      false;

    const allFeatures = touchpoints.reduce((p,c) => {
      p[c.touchpoint] = c.num_users
      return p
    }, {})



    return (
      <div style={{height:"800px",overflowY:"scroll"}}>
        <ContentCard hasTable title={
            <Title
              onClick={selectedFeature && this.resetSelectFeature}
              title={selectedFeature ? selectedFeature.touchpoint.split("|").join(" > ") : "Feature Data"}
            />
          }
          topRight={
            <Button
              onClick={this.toggleColumns}
              size='tiny'
              compact>{ excludeTag == "corr" ? "Show" : "Hide"} Correlation Data</Button>
          }
        >
          {
            selectedFeature === false && <SortableIndexGrid
              as={ContentCard.Table}
              cols={touchpointCols.filter(x => x.tag != excludeTag)}
              data={touchpoints.sort((p,c) => c.num_users - p.num_users)}
            />
          }
          {
            selectedFeature !== false &&
            <FeatureDrillIn
              name={selectedTouchpointName}
              selectedFeature={selectedFeature}
              allFeatures={allFeatures}
            />
          }
        </ContentCard>
      </div>
    )
  }
}
export default Features;

export const markActiveRoutes = (routes, path, isLiteUser, enabledFeatures) => {
  const filteredRoutes = routes.filter(x => 
    !!x.feature
      ? enabledFeatures.indexOf(x.feature) > -1
      : !isLiteUser || !!x.lite
  )

  filteredRoutes.map(x => {
    const isRoute = path.indexOf(x.route) > -1;
    const hasValue = ["index","edit","inspect","schema","view","copy","create", "edit", "scheduled", "saved", "outbox", "delivered", "live"].filter(x => path.indexOf(x) > -1).length > 0;

    const supported = isRoute && hasValue;

    x.active = (path === x.route) || supported
  })

  const nested = filteredRoutes.reduce((p,c) => {
    if (!c.parent) {
      const children = (p[c.route] || { children: []}).children
      const activeChildren = children.filter(x => x.active)
      const active = activeChildren.length || c.active

      c.children = children;
      c.active = active;
      p[c.route] = c;

    } else {
      p[c.parent] = p[c.parent] || { children: []};
      p[c.parent].active = p[c.parent].active || c.active
      p[c.parent].children.push(c)
    }
    return p

  }, {})

  const topLevel = Object.values(nested)
  const topActive = topLevel.filter(x => x.active)

  if (!topActive.length) return {
    topLevel: topLevel,
    secondLevel:[],
    topLevelActiveIndex:0,
    secondLevelActiveIndex:0,
    forceRoute: "/v2/settings"
  }

  const secondLevel = topActive.length && topActive[0].children
  const secondActive = secondLevel.filter(x => x.active)

  topLevel.map((x,i) => {x.index = i})
  secondLevel.map((x,i) => {x.index = i})

  const topLevelActiveIndex = topActive.length && topActive[0].index
  const secondLevelActiveIndex = secondActive.length && secondActive[0].index

  let forceRoute = false;
  if (!secondActive.length) forceRoute = secondLevel[0].route

  return { topLevel, secondLevel, topLevelActiveIndex, secondLevelActiveIndex, forceRoute }
}


export const toMenuItem = (route) => {
  return {
    name: route.name,
    displayName: route.displayName,
    iconName: route.iconName,
    active: route.active,
    route: route.route
  }
}

export const toPane = (route) => {
  return {
    menuItem: route.name,
    route: route.route
  }
}

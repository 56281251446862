import React, { Component } from "react";
import AceEditor from "react-ace";
import { Menu, Icon, Button, Form } from "semantic-ui-react";
import { ContentCard, ColumnLayout } from "@rockerbox/styleguide";
import { findMatchedReportTypeOptions } from "./helpers";

import "brace/mode/json";
import "brace/mode/python";
import "brace/theme/tomorrow";
import {
  getReportType,
  getSavedreports,
  updateSavedReport,
  getAllSegments
} from "./api";
import { formatTiersValue } from "../../utils/format_helpers";
import { withRouter, Link } from "react-router-dom";
import * as routes from '../../routes';

class ReportEditor extends Component {
  state = {
    name: "",
    reportTypeId: null,
    reportTypes: [],
    id: null,
    num_days: null,
    configTab: "inbound_transform_schema",
    savedReport: {},
    loading: true,
    segments: []
  };

  componentDidMount() {
    const { match: { params: { id } = {} } = {} } = this.props;
    return Promise.all([
      getReportType().then(data => this.setState({ reportTypes: data })),
      getAllSegments().then(data => this.setState({ segments: data })),
      getSavedreports().then(data => {
        const savedReport =
          data.length > 0 && data.filter(report => report.id == id)[0];
        if (savedReport) {
          savedReport.filters = JSON.stringify(savedReport.filters, null, "\t");
          savedReport.custom_schema = this.formatJsonString(
            savedReport.custom_schema
          );
          savedReport.inbound_transform_schema = this.formatJsonString(
            savedReport.inbound_transform_schema
          );
          savedReport.transform_func = savedReport.transform_func || "";
        }

        this.setState({
          savedReport: savedReport || {},
          id,
          loading: false,
          reportTypeId: savedReport.report_type_id,
          num_days: savedReport.num_days,
          name: savedReport.name
        });
      })
    ]);
  }

  formatJsonString(inputStr) {
    return (inputStr && JSON.stringify(JSON.parse(inputStr), null, "\t")) || "";
  }

  handleTextChange(data, field) {
    return this.setState({ [field]: data });
  }

  handleScriptChange(data, field) {
    return this.setState({
      savedReport: Object.assign(this.state.savedReport, { [field]: data })
    });
  }

  toggleActiveTab(tab) {
    return this.setState({ configTab: tab });
  }

  handleSubmit() {
    this.setState({ loading: true, error: false });
    const {
      savedReport: {
        custom_schema: outbound_schema_content,
        inbound_transform_schema: inbound_schema_content,
        filters: filters_content,
        transform_func
      },
      num_days,
      name,
      reportTypeId,
      id
    } = this.state;

    const body = {
      custom_schema: outbound_schema_content,
      inbound_transform_schema: inbound_schema_content,
      filters: JSON.stringify(formatTiersValue(JSON.parse(filters_content))),
      transform_func,
      num_days,
      name,
      report_type_id: reportTypeId
    };

    if (!this.validate(body)) {
      return this.setState({ loading: false });
    }

    return updateSavedReport(id, body).then(() =>
      this.setState({ loading: false })
    );
  }

  validate(body) {
    const jsonFields = ["custom_schema", "inbound_transform_schema", "filters"];
    try {
      jsonFields.forEach(field => {
        body[field] && JSON.parse(body[field]);
      });
    } catch (e) {
      return false;
    }
    const otherFields = ["num_days", "name", "report_type_id"];
    return otherFields.every(field => body[field]) && body["num_days"] > 0;
  }

  render() {
    const {
      name,
      reportTypeId,
      id,
      reportTypes,
      num_days,
      configTab,
      savedReport,
      loading,
      segments
    } = this.state;

    const filter_id = parseInt(savedReport.filter_id);
    let typeOptions = findMatchedReportTypeOptions(
      filter_id,
      segments,
      reportTypes
    );
    typeOptions = typeOptions.map(type => {
      return {
        key: type.name,
        text: type.name,
        value: type.id
      };
    });

    const FiltersEditor = (
      <React.Fragment>
        <Menu attached="top" secondary pointing fluid>
          <Menu.Item name="Filters" active={true} />
        </Menu>
        <AceEditor
          mode="json"
          theme="tomorrow"
          setOptions={{
            highlightGutterLine: false,
            showPrintMargin: false,
            showGutter: true,
            highlightActiveLine: false,
            fontSize: 14,
            wrapEnabled: true
          }}
          width={"100%"}
          height={"530px"}
          value={savedReport["filters"]}
          defaultValue={savedReport["filters"]}
          style={{ marginTop: 15 }}
          onChange={obj => this.handleScriptChange(obj, "filters")}
        />
      </React.Fragment>
    );
    return (
      <ContentCard
        title="Edit Saved Report"
        topRight={
          <Link to={routes.reports}>
            <Icon name="angle left" />
            Back to Exports
          </Link>
        }
      >
        <Form loading={loading} onSubmit={this.handleSubmit.bind(this)}>
          <Form.Group inline>
            <Form.Field>
              <Form.Input
                label="Report name:"
                value={name}
                icon="edit"
                placeholder="Add report name"
                onChange={(e, data) =>
                  this.handleTextChange(data.value, "name")
                }
                inline
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                label="Number of days"
                error={
                  num_days <= 0 && {
                    content: "Please enter value larger than 0",
                    pointing: "left"
                  }
                }
                value={num_days}
                icon="edit"
                placeholder="Add Number of days"
                onChange={(e, data) =>
                  this.handleTextChange(data.value, "num_days")
                }
                inline
              />
            </Form.Field>
            <Form.Field inline>
              <Form.Select
                label="Report Type:"
                options={typeOptions}
                value={reportTypeId}
                onChange={(e, data) =>
                  this.handleTextChange(data.value, "reportTypeId")
                }
                clearable
              />
            </Form.Field>
          </Form.Group>
          <ColumnLayout
            leftWidth={6}
            rightWidth={9}
            leftContent={FiltersEditor}
            rightContent={null}
          ></ColumnLayout>

          <Button primary type="submit">
            Submit
          </Button>
        </Form>
      </ContentCard>
    );
  }
}

const ReportEditorContainer = withRouter(ReportEditor);

export default ReportEditorContainer;

export const DEFAULT_HISTORY = {
  params: { },
  path: "",
  url: "",
  forcePath: "/onboarding/platforms"
}

export const FEATURES_TO_ENABLE = ["auto_params", "ads_management", "events", "comparison"]

export const FEATURE_FLAGS = ["platform_comparison", "new_visitors"]

export const DEFAULTS_INTEGRATIONS = { platformTypes: [], platformFeatures: false, integrations: [] }


export const SURVEY_OPTIONS = {
  role: [
    {value: "founder",          text: "I'm a founder "},
    {value: "solo_marketer",    text: "I am the only marketer at my company"},
    {value: "channel_manager",  text: "I manage specific marketing channels"},
    {value: "marketing_lead",   text: "I lead a team of marketers"},
    {value: "marketing_exec",   text: "CMO / VP of Marketing"},
    {value: "finance",          text: "Finance / Accounting"},
    {value: "product",          text: "Product"},
    {value: "other",            text: "Other"},
  ],
  industry: [
    {value: "clothing_fashion",     text: "Clothing & Fashion"},
    {value: "health_beauty",        text: "Health & Beauty"},
    {value: "jewelry_accessories",  text: "Jewelry & Accessories"},
    {value: "food_drink",           text: "Food & Drink"},
    {value: "art_photography",      text: "Art & Photography"},
    {value: "electronics",          text: "Electronics"},
    {value: "home_garden",          text: "Home & Garden"},
    {value: "furniture",            text: "Furniture"},
    {value: "sports_recreation",    text: "Sports & Recreation"},
    {value: "toys_games",           text: "Toys & Games"},
    {value: "crafts_books",         text: "Crafts & Books"},
    {value: "services",             text: "Services"},
    {value: "other",                text: "Other"},
  ],
  company_size: [
    {value: "solo",       text: "Just me"},
    {value: "1_to_5",     text: "1-5"},
    {value: "6_to_20",    text: "6-20"},
    {value: "21_to_100",  text: "21-100"},
    {value: "over_100",   text: "100+"},
  ],
}

export const STARTER_ADVERTISING_PLATFORMS = ['facebook','adwords','pinterest','snapchat','tiktok','bing','linkedin','criteo']

import React, { useMemo } from 'react';
import { Segment, Header, Button } from 'semantic-ui-react';
import { ExclamationIcon, CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/solid'; // eslint-disable-line import/no-extraneous-dependencies

const Alert = ({ type, hideIcon, title, description, action, style }) => {
  const [Icon, color] = useMemo(() => {
    switch (type) {
      case 'warning':
        return [ExclamationIcon, 'orange'];
      case 'success':
        return [CheckCircleIcon, 'green'];
      case 'info':
        return [InformationCircleIcon, 'blue'];
      default:
        return [InformationCircleIcon, 'blue'];
    }
  }, [type]);

  return (
    <Segment className={`alert ${type}`} {...{ style }}>
      {!hideIcon && <Icon className="icon" />}
      <Header
        as="h3"
        content={title}
        subheader={description}
      />
      {!!action
        && (
        <Button
          content={action.content}
          as={action.as}
          to={action.to}
          onClick={action.onClick}
          {...{ color }}
        />
        )}
    </Segment>
  );
};

export default Alert;

import React, { useState, useMemo } from 'react';

import { BellIcon, CheckCircleIcon } from '@heroicons/react/outline'; // eslint-disable-line import/no-extraneous-dependencies
import { ExclamationCircleIcon, ExclamationIcon, InformationCircleIcon } from '@heroicons/react/solid'; // eslint-disable-line import/no-extraneous-dependencies
import { Link } from 'react-router-dom';
import { Header, Menu, Popup, Button } from 'semantic-ui-react';

import { useGlobalState } from '../../hooks/global';
import { useMissingParams } from '../../hooks/missingParamsAlert';
import { buildPath } from '../../hooks/urlState';

const NotificationsMenu = () => {
  const { announcements, authorizations, integrations: allIntegrations } = useGlobalState();

  const [opened, setOpened] = useState(false);

  const { missingParamsSummary } = useMissingParams();

  const trackingParamNotifications = useMemo(() => {
    if (!missingParamsSummary) return [];
    return Object.entries(missingParamsSummary)
      .filter(([, val]) => val.hasIssues)
      .map(([k, v]) => ({
        ...v,
        platform: k,
        link: buildPath('integrationTrackingParameters', { platformName: k }),
        displayName: allIntegrations.find(x => x.platform === k)?.display_name || k,
      }));
  }, [missingParamsSummary, allIntegrations]);

  const lapsedAuths = useMemo(() => {
    if (!authorizations) return [];

    const getReauthLink = authRecord => {
      if (authRecord.platform === 'shopify') {
        return `/auth/shopify?shop=${authRecord.account_id}.myshopify.com`;
      }

      return `/auth/${authRecord.platform}?account_id=${authRecord.account_id}`;
    };

    const auths = authorizations.flatMap(x => x.authorizations);
    return auths
      .filter(auth => !!auth.account_id && !auth.batch_platform && !auth.status)
      .map(auth => ({
        ...auth,
        link: getReauthLink(auth),
        displayName: allIntegrations.find(x => x.platform === auth.platform)?.display_name || auth.platform,
      }));
  }, [authorizations, allIntegrations]);

  const numNotifications = useMemo(() => (
    announcements?.length || 0) + (lapsedAuths?.length || 0) + (trackingParamNotifications?.length || 0
  ), [announcements, lapsedAuths, trackingParamNotifications]);

  return (
    <Popup
      transition={{ animation: 'fade down', duration: 500 }}
      trigger={(
        <Menu.Item
          className="icon-button"
          content={(
            <>
              <BellIcon />
              {!!numNotifications && <span className="notification-count">{numNotifications}</span>}
            </>
          )}
          active={!!opened}
        />
      )}
      basic
      on="click"
      position="bottom right"
      open={!!opened}
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      className="nav-dropdown-menu notifications-menu"
      content={(
        <>
          <Header as="h3" content="Notifications" />
          <div className={`content ${!numNotifications && 'empty'}`}>
            {!numNotifications && (
              <>
                <CheckCircleIcon className="icon" />
                <p>You have no notifications</p>
              </>
            )}
            {!!announcements.length
              && announcements.map(x => (
                <div key={x.title} className="notification">
                  <div className="notification-content">
                    <Header
                      as="h5"
                      content={(
                        <>
                          <ExclamationCircleIcon className="icon" />
                          {x.title}
                        </>
                      )}
                      subheader={x.message}
                    />
                  </div>
                </div>
              ))}
            {!!lapsedAuths?.length && (
              <div className="section-heading action-required">
                <ExclamationIcon className="icon" />
                <div className="text">Action Required</div>
              </div>
            )}
            {lapsedAuths.map(x => (
              <div key={`auth-${x.displayName}`} className="notification">
                <div className="notification-content">
                  <Header
                    as="h5"
                    content={`${x.displayName} Re-Authorization Required`}
                    subheader={`Your connection to ${x.account_name} (Account ${x.account_id}) needs to be renewed.`}
                    className="capitalize"
                  />
                </div>
                <div className="notification-action">
                  <Button
                    fluid
                    primary
                    size="small"
                    content={`Reconnect ${x.displayName} Account`}
                    onClick={() => { window.location = x.link; }}
                  />
                </div>
              </div>
            ))}
            {!!trackingParamNotifications?.length && (
              <div className="section-heading needs-review">
                <InformationCircleIcon className="icon" />
                <div className="text">Needs Review</div>
              </div>
            )}
            {trackingParamNotifications.map(x => (
              <div key={`params-${x.displayName}`} className="notification">
                <div className="notification-content">
                  <Header
                    as="h5"
                    content={`${x.displayName} Ads Untracked`}
                    subheader={`We've detected clicks from ${x.displayName} ads that are missing Rockerbox parameters.`}
                    className="capitalize"
                  />
                </div>
                <div className="notification-action">
                  <Button as={Link} to={x.link} fluid primary size="small" content={`Fix ${x.displayName} Tracking Parameters`} />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    />
  );
};

export default NotificationsMenu;

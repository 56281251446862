/* eslint-disable max-len */
import { sponsorshipsChannelsImage,
  collect2, collect3, collect4, collect5,
  export1, export2, export3, export4,
  forecast1, forecast2, forecast3, forecast4,
  journey1, journey2, journey3, journey4,
  optimize1, optimize2, optimize3, optimize4,
  track2, track3, track4, track5 } from '@rockerbox/styleguide';

export const suppressionGroupIds = {
  'Weekly Insights': 185788,
  'Tracking Parameter Alerts': 172241,
  'Missing Pixel Activity': 172243,
  'Failed Platform Authorization': 180099,
  'Data Processing Delay Alerts': 236227,
};

export const ALLOWED_PLATFORMS_MAPPING = {
  lite: ['facebook', 'adwords', 'pinterest', 'snapchat', 'tiktok', 'bing', 'linkedin'],
  starter: ['facebook', 'adwords', 'pinterest', 'snapchat', 'tiktok', 'bing', 'linkedin', 'criteo'],
};

export const colorNames = {
  green: '#66D9AE',
  orange: '#FFA278',
  purple: '#7C78DC',
  red: '#E76D8E',
  blue: '#0489c1',
  yellow: '#FFDF78',
  'dark-green': '#42C493',
  'dark-orange': '#DE794C',
  'dark-purple': '#5B57C8',
  'dark-red': '#C94568',
  'dark-blue': '#03638D',
  'dark-yellow': '#DEBC4C',
  'light-green': '#92EBC9',
  'light-orange': '#FFBC9E',
  'light-purple': '#A3A0EC',
  'light-red': '#F095AD',
  'light-blue': '#1298D2',
  'light-yellow': '#FFE89E',
};

export const donutColors = {
  filtered: '#6F7FE0',
  missing: '#BFC7F9',
};

export const channelPerformancePlatformsConfig = {
  adwords: {
    hierarchy: [{ text: 'Account name', value: 'account_name' }, { text: 'Campaign name', value: 'campaignName' }, { text: 'Ad Group name', value: 'adGroupName' }],
    // "hierarchy": {"account_name": "Account Name", "campaignName": "Campaign Name", "adGroupName": "Ad Group Name"},
    object_columns: ['identifier', 'campaignName', 'campaignId', 'adGroupName', 'adGroupId'], // netwrok?
    base_metrics_columns: ['spend', 'impressions', 'clicks'], // blue cards - seems like they have specnd by origin
    default_metric: 'spend',
    default_conv: '',
    revenue_suffix: 'Value',
    revenue_formatter: attributionWindow => `${attributionWindow.replace('allConversionsValue', 'allConversions').replace('allConversions', 'allConversionsValue')}`, // NEEDS THE DOUBLE REPLACE TO REMOVE THE OPTION FROM LIST
    exclude_windows: ['clickThroughLookbackWindowDays', 'viewThroughConversions', 'viewThroughLookbackWindowDays'],
    default_attribution_window: 'allConversionsByConversionDate',
    default_metrics: ['spend', 'impressions', 'clicks', 'ctr', 'cpc', 'cpm'],
    optional_metrics: ['conversions', 'cpa', 'revenue', 'roas'],
    display_platform_name: 'Adwords',
  },
  bing: {
    hierarchy: [{ text: 'Account name', value: 'account_name' }, { text: 'Campaign name', value: 'CampaignName' }, { text: 'Ad Group name', value: 'AdGroupName' }, { text: 'Ad title', value: 'AdTitle' }],
    // "hierarchy": {"account_name": "Account Name", "CampaignName": "Campaign Name", "AdGroupName": "Ad Group Name", "AdTitle": "Ad Title"},
    object_columns: ['identifier', 'CampaignName', 'CampaignId', 'AdGroupName', 'AdGroupId', 'AdTitle', 'AdId'],
    base_metrics_columns: ['spend', 'impressions', 'clicks'],
    default_metric: 'spend',
    default_conv: 'AllConversionsQualified',
    default_attribution_window: 'conversion_analytics',
    default_metrics: ['spend', 'impressions', 'clicks', 'ctr', 'cpc', 'cpm'],
    optional_metrics: ['conversions', 'cpa'],
    display_platform_name: 'Bing',
  },
  facebook: {
    hierarchy: [{ text: 'Account name', value: 'account_name' }, { text: 'Campaign name', value: 'campaign_name' }, { text: 'Adset name', value: 'adset_name' }, { text: 'Ad name', value: 'ad_name' }],
    object_columns: ['identifier', 'campaign_id', 'campaign_name', 'adset_id', 'adset_name', 'ad_id', 'ad_name'],
    base_metrics_columns: ['spend', 'impressions', 'clicks'],
    default_metric: 'spend',
    default_conv: 'offsite_conversion.fb_pixel_purchase',
    revenue_suffix: '_value',
    revenue_formatter: attributionWindow => `${`${attributionWindow.replace('_value', '')}_value`}`, // NEEDS THE DOUBLE REPLACE TO REMOVE THE OPTION FROM LIST
    exclude_windows: ['1d_click_original_currency_value', '1d_view_original_currency_value', '7d_click_original_currency_value', '28d_view', '28d_click', '7d_view'],
    default_attribution_window: '7d_click_1d_view',
    default_metrics: ['spend', 'impressions', 'clicks', 'ctr', 'cpc', 'cpm'],
    optional_metrics: ['conversions', 'cpa', 'revenue', 'roas'],
    display_platform_name: 'Facebook',
  },
  linkedin: {
    hierarchy: [{ text: 'Account name', value: 'account_name' }, { text: 'Campaign Group name', value: 'campaignGroupName' }, { text: 'Campaign name', value: 'campaignName' }, { text: 'Campaign type', value: 'campaignType' }],
    // "hierarchy": {"account_name":"Account Name", "campaignGroupName": "Campaign Group Name", "campaignName": "Campaign Name", "campaignType": "Campaign Type"}, //creativeType / creativeId
    object_columns: ['identifier', 'campaignGroupName', 'campaignGroupId', 'campaignName', 'campaignId', 'campaignType', 'creativeType', 'creativeId'],
    base_metrics_columns: ['spend', 'impressions', 'clicks'],
    default_metric: 'spend',
    default_conv: '', // unknown
    default_attribution_window: 'externalWebsiteConversions',
    exclude_windows: ['conversionValue', 'postClickAttributionWindowSize', 'viewThroughAttributionWindowSize'],
    default_metrics: ['spend', 'impressions', 'clicks', 'ctr', 'cpc', 'cpm'],
    optional_metrics: ['conversions', 'cpa'],
    display_platform_name: 'LinkedIn',
  },
  pinterest: {
    hierarchy: [{ text: 'Account name', value: 'account_name' }, { text: 'Campaign name', value: 'CAMPAIGN_NAME' }, { text: 'Ad Group name', value: 'AD_GROUP_NAME' }, { text: 'Ad name', value: 'PIN_PROMOTION_NAME' }],
    // "hierarchy": {"account_name":"Account Name", "CAMPAIGN_NAME": "Campaign Name", "AD_GROUP_NAME": "Ad Group Name", "AD_ID": "Ad ID"},
    object_columns: ['identifier', 'CAMPAIGN_NAME', 'CAMPAIGN_ID', 'AD_GROUP_NAME', 'AD_GROUP_ID', 'PIN_PROMOTION_NAME', 'AD_ID'],
    base_metrics_columns: ['spend', 'impressions', 'clicks'],
    default_metric: 'spend',
    default_conv: 'TOTAL_CHECKOUT',
    default_attribution_window: '1D_VIEW',
    default_metrics: ['spend', 'impressions', 'clicks', 'ctr', 'cpc', 'cpm'],
    optional_metrics: ['conversions', 'cpa'],
    display_platform_name: 'Pinterest',
  },
  snapchat: {
    hierarchy: [{ text: 'Account name', value: 'account_name' }, { text: 'Campaign name', value: 'campaign_name' }, { text: 'Ad Squad name', value: 'ad_squad_name' }, { text: 'Ad name', value: 'ad_name' }],
    object_columns: ['identifier', 'campaign_name', 'campaign_id', 'ad_squad_name', 'ad_squad_id', 'ad_name', 'ad_id'],
    base_metrics_columns: ['spend', 'impressions', 'clicks'],
    default_metric: 'spend',
    default_conv: 'purchases',
    revenue_suffix: '_value',
    revenue_formatter: attributionWindow => `${`${attributionWindow.replace('_value', '')}_value`}`,
    default_attribution_window: 'swipe_28_day',
    default_metrics: ['spend', 'impressions', 'clicks', 'ctr', 'cpc', 'cpm'],
    optional_metrics: ['conversions', 'cpa', 'revenue', 'roas'],
    display_platform_name: 'Snapchat',
  },
  tiktok: {
    hierarchy: [{ text: 'Account name', value: 'account_name' }, { text: 'Campaign name', value: 'campaign_name' }, { text: 'Ad Group name', value: 'adgroup_name' }, { text: 'Ad name', value: 'ad_name' }],
    // "hierarchy": {"account_name": "Account Name", "campaign_name": "Campaign Name", "adgroup_name": "Ad Group Name", "ad_name": "Ad Name"},
    object_columns: ['identifier', 'campaign_id', 'campaign_name', 'adgroup_id', 'adgroup_name', 'ad_id', 'ad_name'],
    base_metrics_columns: ['spend', 'impressions', 'clicks'],
    default_metric: 'spend',
    default_conv: 'conversion',
    default_attribution_window: 'conversion_analytics', // conversion_analytics
    default_metrics: ['spend', 'impressions', 'clicks', 'ctr', 'cpc', 'cpm'],
    optional_metrics: ['conversions', 'cpa'],
    display_platform_name: 'TikTok',
  },
};

/* eslint-disable quotes */
export const productLinesToUpsellMapping = {
  collect: {
    trackItem: 'upsell_contact.collect',
    chatMessage: "Hi! I'm interested in upgrading to access Collect Product Line.",
    productLineDisplay: 'Collect',
    purpose: {
      title: 'Centralize your marketing data with ',
      contentTitle: 'Access a clean, complete dataset of performance + ecommerce data with less manual work.',
      content: 'With Rockerbox Collect, we help you centralize performance data from API platforms like Google, Facebook, and TikTok, and non-API platforms like Tatari and Reddit, and revenue data from ecommerce platforms to create a complete, accurate dataset to power your reporting',
      image: collect5,
    },
    highlights: {
      title: 'The marketing data you need, without the headaches.',
      content: [
        {
          heading: 'A Clean, Accurate Dataset',
          subheading: 'With all your marketing performance data in one place, your team is empowered to better understand the impact your marketing campaigns are having on revenue.',
          image: collect2,
        },
        {
          heading: 'Reduce the Load on Engineering',
          subheading: 'Too often, centralizing your marketing data is a messy, error-prone process. Collect helps to automate the manual work and free up technical resources.',
          image: collect3,
        },
        {
          heading: 'Key Platform Integrations',
          subheading: 'Collect helps you gather data from platforms you already use—from social to search to OTT.',
          image: collect4,
        },
      ],
    },
    action: {
      title: 'Collect—All of your data, all in one place.',
      description: 'We do the work of collecting pivotal marketing performance/ecommerce data and standardizing that data to provide a foundation for the reports you need to run to understand and optimize your marketing.',
      logosOfPlatforms: true,
      productLine: 'collect',
    },
  },
  track: {
    trackItem: 'upsell_contact.track',
    chatMessage: "Hi! I'm interested in upgrading to access Track Product Line.",
    productLineDisplay: 'Track',
    purpose: {
      title: 'Build a full picture of the buyer journey with ',
      contentTitle: 'We integrate with your website and ecommerce platform to build a granular first-party dataset to guide marketing reporting.',
      content: 'With Rockerbox Track, your organization can access a clean first-party dataset powered by your own website and ecommerce platform data. This dataset helps you uncover the link between marketing touchpoints and conversions so you can gain a better understanding of which marketing activities are driving revenue.',
      image: track5,
    },
    highlights: {
      title: 'Privacy-conscious first-party data to power MTA',
      content: [
        {
          heading: 'A Clean, Accurate Dataset',
          subheading: 'The creation of an actionable first-party dataset is no simple task, from technical complexities to privacy restrictions to shifting analytics tools. Rockerbox Track simplifies the process and provides a foundation for accurate attribution reporting.',
          image: track2,
        },
        {
          heading: 'Unbiased Performance Evaluation',
          subheading: 'Individual marketing platforms often inflate the picture of their own value. Rockerbox uses first-party data tracking to give marketers full visibility into every touchpoint that impacted a sale and an unbiased view of credit.',
          image: track3,
        },
        {
          heading: 'Analyze Data in Rockerbox and Beyond',
          subheading: "Data collected through Track can be analyzed further with Rockerbox Journey, Rockerbox’s MTA reporting offering, or with Rockerbox Export—our solution for transferring data to your data warehouse, Google Sheets or elsewhere.",
          image: track4,
        },
      ],
    },
    action: {
      title: 'Track—The data you need to unlock the conversion funnel.',
      description: 'Without reliable first-party data, the true impact of your marketing channels is a mystery. Rockerbox Track simplifies the complex process of unifying first-party data from multiple sources into a dataset that can be leveraged to uncover real optimization opportunities for your brand.',
      logosOfPlatforms: false,
    },
  },
  journey: {
    trackItem: 'upsell_contact.journey',
    chatMessage: "Hi! I'm interested in upgrading to access Journey Product Line.",
    productLineDisplay: 'Journey',
    purpose: {
      title: 'Visualize the customer path to purchase with ',
      contentTitle: 'Insight into the impact of every touchpoint, powered by first-party data.',
      content: "Rockerbox Journey leverages data gathered by Track and Collect to construct a granular map of your customers' interactions with your marketing channels and how each channel impacted the eventual purchase. It's the information you need to truly understand what's working and where to spend.",
      image: journey4,
    },
    highlights: {
      title: "Understand the buyer journey. Find out what's working and what's not.",
      content: [
        {
          heading: 'Channel Overlap Analysis',
          subheading: 'How are your channels working together? Are any of your marketing efforts redundant? Journey includes views that help you understand the interplay of your marketing channels.',
          image: journey1,
        },
        {
          heading: 'Customer Journey Insights',
          subheading: 'MTA at its most accessible and actionable. Collect lets you analyze the impact of every facet of your marketing: from social to search, linear TV to OTT.',
          image: journey2,
        },
        {
          heading: 'Marketing Funnel Visibility',
          subheading: 'Journey helps you explore every portion of your funnel and understand which channels play a role at different times.',
          image: journey3,
        },
      ],
    },
    action: {
      title: 'Pull in marketing touchpoint and spend data for the channels you already use.',
      description: 'We offer integrations with leading platforms for social, search, video, audio, offline, and more.',
      logosOfPlatforms: false,
    },
  },
  optimize: {
    trackItem: 'upsell_contact.optimize',
    chatMessage: "Hi! I'm interested in upgrading to access Optimize Product Line.",
    productLineDisplay: 'Optimize',
    purpose: {
      title: 'Set Goals, Track Progress, See Success with ',
      contentTitle: 'Find out what’s working and what to do next.',
      content: 'Rockerbox Optimize helps you keep your campaign performance strong with concrete spend recommendations based on CPA and ROAS goals you set.',
      image: optimize4,
    },
    highlights: {
      title: 'Regular optimization guidance for your marketing campaigns.',
      content: [
        {
          heading: 'Set CPA or ROAS Goals',
          subheading: 'With Optimize, you can set targets for CPA and ROAS either based on a fixed number that you decide or based on a dynamic moving average that helps you understand both how your campaigns are performing overall and where there are optimization opportunities.',
          image: optimize1,
        },
        {
          heading: 'Monitor Performance and Adjust',
          subheading: "Track your progress toward your goals in our UI with suggestions on where to spend more, less, or where you’re right on track.",
          image: optimize2,
        },
        {
          heading: 'Make the Most of Your Budget',
          subheading: "Many platforms tell you how you’re performing, but it’s not as easy to figure out where and how to make adjustments to improve your performance. With Rockerbox Optimize, we help you take action in a smart, data-driven way.",
          image: optimize3,
        },
      ],
    },
    action: {
      title: 'Marketing Recommendations, Backed by Data',
      description: 'With Rockerbox Optimize, you can understand not only how you’re performing but how you can adjust your spend for even better results.',
      logosOfPlatforms: false,
    },
  },
  forecast: {
    trackItem: 'upsell_contact.forecast',
    chatMessage: "Hi! I'm interested in upgrading to access the MMM Product Line.",
    productLineDisplay: 'MMM',
    purpose: {
      title: 'Build a better marketing budget with ',
      contentTitle: 'Use AI-driven Marketing Mix Modeling to make data-backed decisions.',
      content: 'Rockerbox Marketing Mix Modeling (MMM) helps brands explore the relationship between ad channel spend and revenue based on their historical data. This statistical method allows brands to make confident budget decisions for the best possible revenue outcome.',
      image: forecast1,
    },
    highlights: {
      title: 'Uncover the channels that are actually impacting your bottom line. Make measured adjustments.',
      content: [
        {
          heading: 'Data-Backed Budgets',
          subheading: 'MMM is a statistical method built to help uncover the impact of advertising without needing user-level identifiers. For hard-to-measure channels, MMM can help you understand the lift a channel generates and how to adjust spend for even better performance and ROAS.',
          image: forecast2,
        },
        {
          heading: 'High-Level Results',
          subheading: 'Explore the efficiency of all your marketing channels through insights into overall spend investments alongside revenue contributions.',
          image: forecast3,
        },
        {
          heading: 'Visibility into the Model',
          subheading: "MMM uses your own spend and revenue data to make predictions about how changes you make to your ad spend across channels affect your revenue in the future. Even better, we offer transparency into the model and how well it’s predicting performance.",
          image: forecast4,
        },
      ],
    },
    action: {
      title: 'Evaluate the revenue impact of top channels you already use.',
      description: '',
      logosOfPlatforms: true,
      productLine: 'forecast',

    },
  },
  export: {
    trackItem: 'upsell_contact.export',
    chatMessage: "Hi! I'm interested in upgrading to access Export Product Line.",
    productLineDisplay: 'Export',
    purpose: {
      title: 'Get your data where you need it to go with ',
      contentTitle: 'Easily transfer your data to your data warehouse, Google Sheets, and beyond.',
      content: 'Rockerbox Export offers a clean, analytics-ready data set with integrations to help brands move data to their data warehouse where it can be further analyzed in BI tools, to Google Sheets, or download it for some other purpose. Our seamless integrations and features in this product help reduce the manual labor and complexity usually associated with data exports, and help marketers and analysts get to the insights they need faster.',
      image: export4,
    },
    highlights: {
      title: 'No more data hassles. Just high-quality data in the right place.',
      content: [
        {
          heading: 'Connect your Data Warehouse',
          subheading: 'This integration allows first-party data and ad platform numbers to flow directly into your data warehouse, freeing up your entire team to focus on discovering the customer and marketing insights that will help you grow.',
          image: export1,
        },
        {
          heading: 'Transfer Data to Google Sheets',
          subheading: 'Make quick work of your regular reporting with a plugin that syncs data directly into Google Sheets and prebuilt templates to streamline analysis.',
          image: export2,
        },
        {
          heading: 'Automated or As-Needed Exports',
          subheading: 'Exports allows you to quickly pull pivotal marketing from Rockerbox whenever you need it, at any level of granularity. Even large date ranges take just seconds to download.',
          image: export3,
        },
      ],
    },
    action: {
      title: 'Streamline your data export processes with Export.',
      description: '',
      logosOfPlatforms: true,
      productLine: 'export',
    },
  },
  newVisitors: {
    trackItem: 'upsell_contact.new_visitors',
    buttonText: 'Click here to be notified for Updates to New Visitors!',
    chatMessage: "Hi! I'm interested to be notified for updates to New Visitors!",
    productLineDisplay: '',
    purpose: {
      title: 'New Visitors is being sunsetted as we work on a new and improved way to report on traffic data in',
      contentTitle: 'The New Visitors view is  being sunsetted as we look to improve the data and consolidate the information this view provided into other views in the future.',
      content: '',
      image: sponsorshipsChannelsImage,
    },
    highlights: {
      title: 'Check out some of the other pages in the Journey Product Line!',
      content: [
        {
          heading: 'Channel Overlap Analysis',
          subheading: 'How are your channels working together? Are any of your marketing efforts redundant? Journey includes views that help you understand the interplay of your marketing channels.',
          image: journey1,
        },
        {
          heading: 'Customer Journey Insights',
          subheading: 'MTA at its most accessible and actionable. Collect lets you analyze the impact of every facet of your marketing: from social to search, linear TV to OTT.',
          image: journey2,
        },
        {
          heading: 'Marketing Funnel Visibility',
          subheading: 'Journey helps you explore every portion of your funnel and understand which channels play a role at different times.',
          image: journey3,
        },
      ],
    },
    action: {
      title: '',
      description: '',
      logosOfPlatforms: false,
    },
  },
  missing: {
    trackItem: 'upsell_contact.missing',
    chatMessage: "Hi! I'm interested in upgrading to access Product Line.",
    productLineDisplay: '',
    purpose: {
      title: '',
      contentTitle: '',
      content: '',
      image: sponsorshipsChannelsImage,
    },
    highlights: {
      title: '',
      content: [],
    },
    action: {
      title: '',
      description: '',
      logosOfPlatforms: false,
    },
  },
};
/* eslint-enable quotes */

export const productLineToViewMapping = {
  collect: [
    'home',
    'integrations',
    'platform_performance',
    'google_sheets_plugin',
    'platform_performance_benchmarks',
    'spend_trends',
    'saved_reports',
    'settings_my_account',
    'settings_team_users',
    'settings_colors',
  ],
  track: [
    'integrations',
    'event_status',
    'promo_codes',
    'surveys',
    'google_sheets_plugin',
    'orders_and_conversions_summary',
    'orders_and_conversions_details',
    'orders_and_conversions_user_detail',
    'ad_hoc_exports',
    'cross_channel_report',
    'rockerbox_performance',
    'platform_performance_benchmarks',
    'spend_trends',
    'saved_reports',
    'settings_my_account',
    'settings_team_users',
    'settings_domains',
    'settings_colors',
  ],
  optimize: [
    'goals_and_recs',
    'google_sheets_plugin',
    'platform_performance_benchmarks',
    'spend_trends',
    'saved_reports',
    'settings_my_account',
    'settings_team_users',
    'settings_colors',
  ],
  journey: [
    'google_sheets_plugin',
    'marketing_paths',
    'funnel_position',
    'channel_overlap',
    'conversion_funnel',
    'platform_performance_benchmarks',
    'spend_trends',
    'saved_reports',
    'settings_my_account',
    'settings_team_users',
    'settings_colors',
    'settings_conversion_funnel',
  ],
  forecast: [
    'marketing_mix_modeling',
    'google_sheets_plugin',
    'platform_performance_benchmarks',
    'spend_trends',
    'saved_reports',
    'settings_my_account',
    'settings_team_users',
    'settings_colors',
  ],
  export: [
    'data_warehouse',
    'platform_performance_benchmarks',
    'spend_trends',
    'settings_my_account',
    'settings_team_users',
    'settings_colors',
  ],
  newVisitors: [
    'new_visitors',
  ],
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';


export default class GenericFetch extends Component {

  fetchData() {
    const { endpoint, success, error } = this.props;
    const onSuccess = success || function(){}; 
    const onError = error || function(){}; 

    axios.get(endpoint, { credentials: 'include', headers: { "Accept": "application/json" }  })
      .then(resp => {
        if (resp.request.responseURL.indexOf("login") > -1) throw "login failed"
        return resp
      })
      .then((resp) => resp.data)
      .then(onSuccess)
      .catch(onError)
  }

  componentDidMount() {
    const { endpoint, preventFetch, onStart } = this.props;
    if (preventFetch) return;
    onStart && onStart(endpoint);
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const { endpoint, refetch, preventFetch, onStart } = this.props;
    if (preventFetch) return;

    if (
         (prevProps.refetch !== refetch && refetch) ||
         (prevProps.endpoint !== endpoint) ||
         (prevProps.preventFetch !== preventFetch && refetch)
    ) {
      onStart && onStart(endpoint);
      this.fetchData();
    }
  }

  render() {
    return null;
  }
}

GenericFetch.propTypes = {
  refetch: PropTypes.bool,
  preventFetch: PropTypes.bool,
  endpoint: PropTypes.string.isRequired,
  success: PropTypes.func,
  error: PropTypes.func,
}

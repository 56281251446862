import React from 'react';
import { useHistory } from 'react-router-dom'
import { Message, Button } from 'semantic-ui-react';
import { ContentCard, IndexGridNew } from '@rockerbox/styleguide';
import TableOutput from './TableOutput';

const OutputHeader = ({ urlData, checkUrlData }) => {
  const checkExternal = checkUrlData.original_url;
  if (checkExternal.slice(0, 12) === 'fakeexternal') {
    const externalTiers = checkExternal.slice(17).split('&');
    const tiersMapped = externalTiers.reduce((acc, tier) => {
      const tierNum  = tier.substr(0, tier.indexOf('='));
      const value = tier.substr(tier.indexOf('=') + 1);
      const tierValue = value === 'undefined' ? '' : value;
      return { ...acc, [tierNum]: tierValue }
    }, {});

    const cols = [
      { display: 'tier_one', key: 'tier_one' },
      { display: 'tier_two', key: 'tier_two' },
      { display: 'tier_three', key: 'tier_three' },
      { display: 'tier_four', key: 'tier_four' },
      { display: 'tier_five', key: 'tier_five' },
    ];

    return (
      <div>
        <Message.Header>Your Marketing Touchpoint</Message.Header>
        <p>External Marketing: </p>
        <IndexGridNew borderless cols={cols} data={[tiersMapped]} />
        <br />
      </div>
    )
  } else {
    return (
      <div>
        <Message.Header>Your Marketing Touchpoint</Message.Header>
        <p>URL: <a href={urlData.original_url} target="_blank">{urlData.original_url}</a></p>
        <p>Referrer: <a href={urlData.request_referrer} target="_blank">{urlData.request_referrer}</a></p>
        <br />
      </div>
    )
  }
}

const TestOutput = ({ checkUrlData, attributableEvents, handleClickClear }) => {
  const [matches, setMatches] = React.useState([]);
  const [selectedRule, setSelectedRule] = React.useState({name: ''});
  const history = useHistory();

  React.useEffect(() => {
    const match_ids = checkUrlData[0].match_ids;
    const selected_id = checkUrlData[0].selected_rule_id;
    const data = match_ids.map((id) => {
      let matched = attributableEvents.find((event) => event.id === id);
      return matched;
    })
    const selectedRule = data.find((e) => e.id === selected_id) || {name: ''};
    const newMatches = [data, ...matches];
    setMatches(newMatches);
    setSelectedRule(selectedRule);
  }, [checkUrlData]);
  
  return (
    <ContentCard
      title="Matching Rules"
      explainer="Your Marketing Touchpoint entered is currently mapped in the following tables."
      topRight={<Button onClick={handleClickClear}>Clear Results</Button>}
    >
      {checkUrlData.map((urlData, index) => {
        if (urlData.match_ids.length === 0) {
          return (
            <Message>
              <OutputHeader urlData={urlData} checkUrlData={checkUrlData[index]} />
              <h4> No matching rules. </h4>
              <Button type="submit" onClick={() => history.push('/v2/channels/mappings/create')}>
                Create New Rule
              </Button>
            </Message>
          );
        } else {
          return (
            <Message>
              <OutputHeader urlData={urlData} checkUrlData={checkUrlData[index]} />
              {matches[index] ? (
                <TableOutput urlData={urlData} matches={matches[index]} selectedRuleName={selectedRule.name} />
              ) : ''}
            </Message>
          );
        }
      })
      }
    </ContentCard>
  );
};;

export default TestOutput;

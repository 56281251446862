import React, { useState, useEffect, useMemo } from 'react';

import { Menu, Dropdown } from 'semantic-ui-react';

export const useChannelTactics = (channels, features) => {
  const [selectedChannel, setSelectedChannel] = useState();
  const [selectedTactic, setSelectedTactic] = useState();

  // default to first channel
  useEffect(() => {
    if (!channels?.length) return;
    setSelectedChannel(channels[0]);
  }, [channels, channels.length]);

  // filter tactics by selected channel
  const availableTactics = useMemo(() => {
    if (!features?.length || !selectedChannel) return [];
    return features.filter(f => f.channel === selectedChannel);
  }, [features, selectedChannel]);

  // default to first tactic
  useEffect(() => {
    if (!availableTactics?.length) return;
    setSelectedTactic(availableTactics[0].tactic);
  }, [availableTactics]);

  // get model feature from selected channel and tactic
  const selectedFeature = useMemo(() => {
    if (!features?.length || !selectedChannel || !selectedTactic) return;
    return features.find(f => f.channel === selectedChannel && f.tactic === selectedTactic);
  }, [features, selectedChannel, selectedTactic]);

  const subchannelOptions = useMemo(() => (
    availableTactics.map(x => ({
      key: x.tactic,
      value: x.tactic,
      text: x.tactic,
    }))
  ), [availableTactics]);

  const ChannelSelector = () => (
    <Menu
      tabular
      attached="top"
      className="mmmm-channel-menu-overrides"
    >
      {channels.map(channel => (
        <Menu.Item
          key={channel}
          name={channel}
          content={channel}
          active={selectedChannel === channel}
          onClick={(e, { name }) => setSelectedChannel(name)}
        />
      ))}
    </Menu>
  );

  const TacticSelector = () => (availableTactics?.length > 1 ? (
    <div style={{ display: 'flex' }}>
      <Dropdown
        style={{ width: '100%', flex: 'right' }}
        selection
        value={selectedTactic}
        onChange={(e, { value }) => setSelectedTactic(value)}
        options={subchannelOptions}
      />
    </div>
  ) : null);

  return {
    selectedChannel,
    selectedTactic,
    setSelectedChannel,
    setSelectedTactic,
    availableTactics,
    selectedFeature,
    ChannelSelector,
    TacticSelector,
  };
};

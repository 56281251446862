import React from 'react';
import { Message, Table } from 'semantic-ui-react';
import { IndexGridNew } from '@rockerbox/styleguide';

const TableOutput = ({ urlData, matches, selectedRuleName }) => {
  matches.sort((a, b) => b.order - a.order);
  const ruleIdCell = ({ item }) => (
    <Table.Cell style={{ cursor: "pointer" }}>
      <a href={`https://app.rockerbox.com/v2/channels/mappings/edit/${item.id}`} target="_blank" >{item.id}</a>
    </Table.Cell>
  )

  const ruleTypeCell = ({ item, col }) => (
      <Table.Cell>
        {!item[col.key] ? 'rename' : 'transform'}
      </Table.Cell>
  )
  const cols = [
    { display: 'Tier 1', key: 'tier_1' },
    { display: 'Tier 2', key: 'tier_2' },
    { display: 'Tier 3', key: 'tier_3' },
    { display: 'Tier 4', key: 'tier_4' },
    { display: 'Tier 5', key: 'tier_5' },
  ];

  const colsAllRules = [
    { display: 'Rule ID', key: 'id', as: ruleIdCell },
    { display: 'Rule Name', key: 'name' },
    { display: 'Rule Type', key: 'transform', tooltip: 'If the Rule Type is Rename, then the marketing output will use the Tier 1 through Tier 5 values. If the Rule Type is Transform, then the output will instead use the Transform Table results. ', as: ruleTypeCell },
    { display: 'Priority', key: 'order', tooltip: 'When a marketing touchpoint matches multiple rules, the highest priority rule will be used.' },
    { display: 'Tier 1', key: 'rewrite_tier_1', tooltip: 'The Tier 1 through Tier 5 results are the output if the rule were used. Text enclosed with { } are macros and will be replaced in reports by the corresponding parameters from the marketing touchpoint.' },
    { display: 'Tier 2', key: 'rewrite_tier_2' },
    { display: 'Tier 3', key: 'rewrite_tier_3' },
    { display: 'Tier 4', key: 'rewrite_tier_4' },
    { display: 'Tier 5', key: 'rewrite_tier_5' },
  ];

  return (
    <div>
      <Message.Header>Selected Rule: {`${urlData.selected_rule_id} ${selectedRuleName}`}</Message.Header>
      <IndexGridNew cols={cols} data={[urlData]} />

      <Message.Header>All Matching Rules</Message.Header>
      <IndexGridNew cols={colsAllRules} data={matches} sortable />
    </div>
  );
};

export default TableOutput;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header, Icon, Segment, Grid, Popup, Table as SemanticTable, Loader, Label } from 'semantic-ui-react';

class ContentCard extends Component {
  constructor(props){
    super(props)
    this.state = { collapsed: !!props.defaultCollapsed }
  }

  toggleCollapse = () => this.setState({ collapsed: !this.state.collapsed })


  render(){
    const { collapsed } = this.state
    const props = this.props
    const { interiorHeight } = props;

    let classNames = ''
    classNames += props.hasTable ? 'p-0 ' : ''
    classNames += props.borderless ? 'borderless ' : ''
    classNames += props.noBackground ? 'no-background ': ''
    classNames += props.scrollY ? 'add-scroll-y ' : ''
    classNames += props.className
    

    let headerClassNames = ''
    headerClassNames += props.noBackground ? 'no-background ': ''
    headerClassNames += props.centeredTitle ? 'centered-title ': ''

    let groupClassNames = ''
    groupClassNames += props.noBackground ? 'no-background ': ''
    groupClassNames += props.fullHeight ? 'full-height ': ''

    return(
      <Segment.Group key={props.key} className={groupClassNames} style={props.style}>
        {props.title &&
          <CardHeader
            classNames={headerClassNames}
            disableHeaderEllipse={props.disableHeaderEllipse}
            title={props.title}
            subtitle={props.subtitle}
            icon={props.icon}
            explainer={props.explainer}
            collapsed={collapsed}
            onClick={props.collapseOnHeaderClick && this.toggleCollapse}
            noTopRight={!props.topRight}
            titleLeftWidth={props.titleLeftWidth}
            titleRightWidth={!!props.titleRightWidth ? props.titleRightWidth : props.topRight ? 8 : props.collapsing ? 3 : 0}
            headerTextStyles={props.headerTextStyles}
            disableTitleOverflow={props.disableTitleOverflow}
          >
            {props.topRight}
            {props.collapsing &&
              <Icon
                link inverted size='small' color='grey'
                name={collapsed ? 'plus' : 'minus'}
                onClick={this.toggleCollapse}
                className='collapse-link'
              />
            }
          </CardHeader>
        }
        {!props.noContent &&
          <Segment
            className={classNames}
            textAlign={props.textAlign}
            loading={props.loading && props.children}
            style={collapsed ? {display: 'none', maxHeight: interiorHeight, overflow: interiorHeight ? "hidden" : undefined} : { maxHeight: interiorHeight, overflow: interiorHeight ? "hidden" : undefined}}
          >
            {props.forceLoading || (props.loading && !props.children) ?
              <CardLoader />
            :
              props.children
            }
          </Segment>
        }
        {
          props.footer
        }
      </Segment.Group>
    )
  }
}

ContentCard.propTypes = {
  /** Removes padding around child element */
  hasTable: PropTypes.bool,
  borderless: PropTypes.bool,
  noBackground: PropTypes.bool,
  /** Adds content in the top right of the header bar, title prop is required */
  topRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  /** Adds content in the top left of the header bar*/
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  /** Height is 100% of parent */
  fullHeight: PropTypes.bool,
  /** Centers text inside of card header */
  centeredTitle: PropTypes.bool,
  disableHeaderEllipse: PropTypes.bool,
  /** Shows header bar only (`title` and `topRight`), recommended to use with self closing tag */
  noContent: PropTypes.bool
}

export default ContentCard

const CardHeader = props => (
  <Segment
    className={`${props.classNames} ${props.collapsed && 'force-rounded-corners'} ${props.onClick && 'clickable'}`}
    onClick={props.onClick}
  >
    <Grid fluid="true" columns="equal">
      <Grid.Row className="segment-header">
        <Grid.Column
          className={props.disableHeaderEllipse ? '' : 'ellipsed'}
          style={{overflow: props.disableTitleOverflow ? 'hidden' : 'visible'}}
          width={props.titleLeftWidth}
          textAlign="left"
        >
          <Header as="h3" className={props.explainer || 'full-width'} style={props.headerTextStyles}>
            {props.icon && <Icon name={props.icon} color='grey' />}
            {props.title}
            {props.subtitle && <Header.Subheader>{props.subtitle}</Header.Subheader>}
          </Header>
          {props.explainer ?
            <Popup
              hoverable inverted position='right center' wide='very'
              trigger={<Icon circular size='small' color='grey' name='question' className='explainer' />}
              className='multiline'
            >
              <Popup.Content>
                {props.explainer}
              </Popup.Content>
            </Popup>
          : null}
        </Grid.Column>
        {props.children &&
          <Grid.Column textAlign="right" width={props.titleRightWidth}>
            {props.children}
          </Grid.Column>
        }
      </Grid.Row>
    </Grid>
  </Segment>
)

export const CardLoader = () => (
  <div>
    <br /><br />
    <Loader active inline="centered" />
    <br /><br />
  </div>
)

export const LoadingView = CardLoader;

export const EmptyView = props => (
  <SemanticTable basic='very'>
    <SemanticTable.Body>
      <SemanticTable.Row>
        <SemanticTable.Cell
          textAlign='center'
          selectable={false}
          className='empty'
        >
          <br />
          <Icon
            circular
            disabled
            name={props.icon || 'info'}
            size='big'
            className='m-b-10' />
          <Header
            as='h3'
            disabled
            content={props.fallBackMsg || 'No data found'}
            subheader={props.description}
            className='m-0' />
          <br />
        </SemanticTable.Cell>
      </SemanticTable.Row>
    </SemanticTable.Body>
  </SemanticTable>
)

export const Table = props => (
  <SemanticTable
    selectable
    attached='bottom'
    className='segment-table'
    {...props}>
    {props.children}
  </SemanticTable>
)

export const TableFooter = props => (
  <SemanticTable.Footer>
    <SemanticTable.Row>
      <SemanticTable.HeaderCell colSpan='100'>
        <div className='footer-content'>
          {props.children}
        </div>
      </SemanticTable.HeaderCell>
    </SemanticTable.Row>
  </SemanticTable.Footer>
)

export const TableExplainer = props => (
  <Popup hoverable inverted position='top center' size='tiny'
         trigger={<Icon circular size='tiny' color='grey' name='question' className='tableExplainer' />}
         content={props.content} />
)

export const TableHeaderCellNoHover = ({ children, ...componentProps}) => (
  <SemanticTable.HeaderCell className='no-hover' {...componentProps}>
    {children}
  </SemanticTable.HeaderCell>
)

export const TableEllipsedCell = props => (
  <SemanticTable.Cell className='ellipsed'>
    <Popup
      trigger={<span>{props.children}</span>}
      content={props.children}
      inverted
      hoverable
    />
  </SemanticTable.Cell>
)

export const TableTwoValuesCell = ({ as, mainValue, subValue, ...componentProps }) => {
  const CellComponent = as || SemanticTable.Cell

  return(
    <CellComponent {...componentProps}>
      <Header
        as='h5'
        content={mainValue}
        subheader={subValue}
      />
    </CellComponent>
  )
}

export const PercentChangeLabel = props => (
  <Label
    circular
    color={props.colorFunc ?
      props.colorFunc(props.value) :
      props.value > 0 ? 'green' :
      props.value ? 'red' : ''
    }
    size='tiny'
  >
    {(props.value > 0 || props.value < 0) &&
      <Icon
        name={`angle ${props.value > 0 ? 'up' : 'down'}`}
      />
    }
    {props.value ? props.value.toLocaleString("en", {style: "percent"}) : "—"}
  </Label>
)

export const SimpleSection = props => (
  <Segment className='simple'>
    {props.children}
  </Segment>
)

import React from 'react';

import { GridColumn, Checkbox, GridRow } from 'semantic-ui-react';

export const SubscriptionRow = ({ name, description, checked, onChange, disabled }) => (
  <GridRow>
    <GridColumn width={1} className="subscriptions-checkbox">
      <Checkbox checked={checked} onChange={onChange} disabled={disabled} />
    </GridColumn>
    <GridColumn width={15} className="subscriptions-text">
      <div className="name">{name}</div>
      <div>{description}</div>
    </GridColumn>
  </GridRow>
);

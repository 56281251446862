import React, { useState } from 'react';

import { ContentCard, SplitLayout, IndexGrid } from '@rockerbox/styleguide';
import _ from 'lodash';
import * as Router from 'react-router-dom';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { Message, Menu, Icon, Form, Button } from 'semantic-ui-react';

import PreviewAll from './PreviewAll';
import TransformTableDetails from './TransformTableDetails';
import TransformTableResults from './TransformTableResults';
import * as routes from '../../routes';
import { updateTransformTables, getAllowTransformTableTemplates, getTransformTableTemplates, getTransformTables, getIntegrationFeatures } from '../../utils/api';
import CacheContext from '../../utils/CacheContext';

const VerticalMenu = ({ transformTables, selected, selectTable, getDeprecationStatus }) => (
  <Menu vertical fluid>
    <Menu.Item>
      <Menu.Header style={{ color: 'black' }}>Edit Transform Tables</Menu.Header>
    </Menu.Item>
    {transformTables.map(row => {
      const isDeprecated = getDeprecationStatus(row.platform);
      const modifiedRowName = row.name?.replace('Platform: ', '').replace(' transform', '');

      return (
        <Menu.Item onClick={() => selectTable(row.id, isDeprecated)} key={row.id}>
          {row.name && modifiedRowName}
          {!(row.id === Number(selected)) && isDeprecated && <Icon name="exclamation triangle" style={{ float: 'right', color: 'red', margin: 0 }} />}
          {row.id === Number(selected) && <Icon name="chevron right" style={{ float: 'right', margin: 0 }} />}
        </Menu.Item>
      );
    })}
  </Menu>
);

const TransformTableTemplates = () => {
  const history = Router.useHistory();
  const [strategy, setStrategy] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [templates, setTemplates] = React.useState([]);
  const [, , Context] = React.useContext(CacheContext);
  const transformTables = Context.getCache('transformTables', getTransformTables) || [];

  React.useEffect(() => {
    getTransformTableTemplates()
      .then(transformTableTemplates => {
        const nested = d3.nest()
          .key(({ strategy: strategyKey }) => strategyKey)
          .entries(transformTableTemplates)
          .map(({ key, values }) => ({ key, value: values, text: key }));

        setTemplates(nested);
      });
  }, []);

  const platforms = !strategy ? {}
    : strategy
      .reduce((accu, c) => {
        const { platform, version } = c;
        const key = (version) ? `${platform}_${version}` : platform;
        accu[key] = c;
        return accu;
      }, {});

  const impacted = transformTables
    .filter(({ platform }) => platforms[platform] !== undefined)
    .map(({ name, platform, id }) => ({ name, platform, id }));

  const onSubmit = () => {
    const impactedTables = transformTables
      .filter(({ platform }) => platforms[platform] !== undefined)
      .map(({ name, platform, version, id }) => {
        const key = (version) ? `${platform}_${version}` : platform;
        const body = JSON.parse(platforms[key].rule);
        Object.assign(body, { id, name });

        return body;
      });

    setLoading(true);

    const promises = impactedTables.map(body => updateTransformTables(body, body.id));

    Promise.all(promises)
      .then(() => {
        history.push(`${routes.transformTablesIndex}/view/all`);
        setLoading(false);
      });
  };

  return (
    <ContentCard title="Apply Templates for Transform Tables">
      {/* eslint-disable-next-line max-len */}
      <Message content="Use the dropdown below to quickly apply templates for an advertiser. Note that if you choose to use a template, it will override the contents and the existing values in a transform table will be lost." />
      <Form.Dropdown
        options={templates} select label="Organize Marketing by "
        selection
        onChange={(e, { value }) => setStrategy(value)}
      />
      {loading
        ? <ContentCard.CardLoader />
        : (
          <IndexGrid
            fallBackMsg="No changes will be made with selected template"
            cols={IndexGrid.arrayToColumns(['platform', 'name', 'id'])}
            data={impacted}
          />
        )}
      <div style={{ height: 35 }}>
        {!!strategy && <Button floated="right" content="Apply" as="a" onClick={onSubmit} />}
      </div>
    </ContentCard>
  );
};

const TransformTablesIndex = () => {
  const history = Router.useHistory();
  const [state, setState, Context] = React.useContext(CacheContext);
  const transformTables = Context.getCache('transformTables', getTransformTables) || [];
  const templates = Context.getCache('transformTablesTemplatesEnabled', getAllowTransformTableTemplates) || {};
  const allowTemplates = templates.response && templates.response.length > 0;
  const routedData = Router.useRouteMatch(`${routes.transformTablesIndex}/view/:selected?`) || {};
  const { params: { selected } = {} } = routedData;
  const setSelected = value => history.push(`${routes.transformTablesIndex}/view/${value}`);
  const [integrations, setIntegrations] = useState([]);
  const [deprecated, setDeprecated] = useState(false);
  const [deprecationAlert, setDeprecationAlert] = useState('');

  React.useEffect(() => {
    getIntegrationFeatures()
      .then(data => {
        setIntegrations(data.integrations);
      });
  }, []);

  React.useEffect(() => {
    const transformTable = (selected === 'new') ? {} : _.cloneDeep(transformTables.find(row => row.id === Number(selected)));
    setState({ transformTable });

    if (selected === undefined) setSelected('all');
  }, [selected, transformTables.length]);

  const { transformTable } = state;
  const selectTable = (name, isDeprecated) => {
    setSelected(name);
    setDeprecated(isDeprecated);
    setDeprecationAlert('Your account is using a deprecated version of this integration. Please contact us for help upgrading to the latest version.');
  };

  const getDeprecationStatus = platform => {
    const platformName = platform?.replace(/_v\d+$/, '');
    return integrations.some(i => i.values.some(item => item.values[0].platform === platformName && item.values[0].feature_deprecated === 1));
  };

  return (
    <SplitLayout
      leftWidth={2}
      rightWidth={14}
      leftContent={(
        <>
          <Menu vertical fluid>
            <Menu.Item>
              <Menu.Header style={{ color: 'black' }}>
                Explore Tables
              </Menu.Header>
            </Menu.Item>
            <Menu.Item active={selected === 'all'} onClick={() => selectTable('all')}>
              View All
              {' '}
              {selected === 'all' && <Icon name="chevron right" style={{ float: 'right', margin: 0 }} />}
            </Menu.Item>
            {
              allowTemplates
              && (
                <Menu.Item active={selected === 'templates'} onClick={() => selectTable('templates')}>
                  Apply Templates
                  {' '}
                  {selected === 'templates' && <Icon name="chevron right" style={{ float: 'right', margin: 0 }} />}
                </Menu.Item>
              )
            }
          </Menu>
          <VerticalMenu
            transformTables={transformTables}
            selected={selected}
            selectTable={selectTable}
            getDeprecationStatus={getDeprecationStatus}
          />
          <Button onClick={() => selectTable('new')}>New</Button>
        </>
      )}
      rightContent={(
        <>
          {deprecated
            && <div className="ui negative message">{deprecationAlert}</div>}
          {selected === 'all' && <PreviewAll />}
          {selected === 'templates' && <TransformTableTemplates />}
          {transformTable && selected !== 'all' && (
            <>
              <TransformTableDetails {...{ transformTable }} />
              <h3 style={{ fontWeight: '500' }}>Explore Raw Data and Resulting Transform Table</h3>
              <TransformTableResults {...{ transformTable }} />
            </>
          )}
        </>
      )}
    />
  );
};

export default TransformTablesIndex;

import moment from 'moment';
import * as d3 from 'rockerbox_d3_legacy_clone';

export default function cdfChart(w) {
  // define chart layout
  var margin = {
    // top margin includes title and legend
    top: 70,

    // right margin should provide space for last horz. axis title
    right: 40,

    bottom: 70,

    // left margin should provide space for y axis titles
    left: 100
  };

  var _w = w || 840
  // height & width
  var width = (_w - margin.left - margin.right);
  var height = (_w - margin.top - margin.bottom);
  var legendWidth = _w -240;
  var legendHeight = 300;


  // cosmetics
  var color = "steelblue";
  var title = '';
  var colors = ['orange', 'green', 'yellow', 'lightblue', 'purple']

  // global div for tooltip
  var div = d3.select('body')
    .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);


  function chart(selection) {
    selection.each(function drawGraph(dataset) {

      var lines = [];
      var aucs = [];
      var data, tpr, fpr;
      var mean, mean_tpr, mean_fpr, tprs_upper, tprs_lower, area_data;

      for (var i=0; i < 5; i++) {
        data = dataset[i.toString()]
        fpr = data['fpr'];
        tpr = data['tpr'];

        aucs.push(data['auc']);

        lines.push(fpr.map((f, i) => {
          return {fpr: f, tpr: tpr[i]}
        }));
      }

      mean = dataset['mean'];

      mean_tpr = mean['tpr'];
      mean_fpr = mean['fpr'];
      tprs_lower = mean['tprs_lower'];
      tprs_upper = mean['tprs_upper'];

      area_data = mean_fpr.map((fpr, i) => {
        return {
          fpr: fpr,
          tpr_lower: tprs_lower[i],
          tpr_upper: tprs_upper[i]
        };
      });

      var meanline = mean_fpr.map((f, i) => {
        return {fpr: f, tpr: mean_tpr[i]};
      });

      var lowerline = mean_fpr.map((f, i) => {
        return {fpr: f, tpr: tprs_lower[i]};
      });

      var upperline = mean_fpr.map((f, i) => {
        return {fpr: f, tpr: tprs_upper[i]};
      });

      // Set the ranges
      var x = d3.scale.linear()
        .range([0, width])
        .domain([0,1]);

      var y = d3.scale.linear()
        .range([height, 0])
        .domain([0,1]);

      var xAxis = d3.svg.axis().scale(x)
          .orient("bottom").ticks(5);

      var yAxis = d3.svg.axis().scale(y)
          .orient("left").ticks(5);

      var plotline = d3.svg.line()
          .x(function(d) { return x(d.fpr); })
          .y(function(d) { return y(d.tpr); });

      var area = d3.svg.area()
          .x(function(d) { return x(d.fpr); })
          .y0(function(d) { return y(d.tpr_lower); })
          .y1(function(d) { return y(d.tpr_upper); });

      // create SVG element
      var svg = d3.select(this).append('svg')
          .attr('width', width + margin.left + margin.right)
          .attr('height', height + margin.top + margin.bottom)
        .append('g')
          .attr('width', width)
          .attr('height', height)
          .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      // plot each roc fold
      lines.forEach((line, i) =>{
        svg.append("path")
            .attr("class", "line")
            .style("stroke", colors[i])
            .attr("d", plotline(line));
      });

      // plot means
      svg.append("path")
          .attr("class", "line")
          .attr("class", "mean")
          .attr("d", plotline(meanline));

      svg.append("path")
          .attr("class", "line")
          .attr("class", "lower")
          .attr("d", plotline(lowerline));

      svg.append("path")
          .attr("class", "line")
          .attr("class", "upper")
          .attr("d", plotline(upperline));

      // shade between upper and lower tprs
      svg.append("path")
          .datum(area_data)
          .attr("class", "area")
          .attr("class", "between")
          .attr("d", area);

      // plot an x=y line
      svg.append("path")
          .attr("class", "line")
          .attr("class", "luck")
          .attr("d", plotline([{fpr:0.0, tpr:0.0},{fpr:1.0, tpr:1.0}]))
          .style("stroke-dasharray", ("3, 3"));

      //axes
      svg.append("g")
          .attr("class", "x axis")
          .attr("transform", "translate(0," + height + ")")
        .call(xAxis);

      svg.append("g")
          .attr("class", "y axis")
        .call(yAxis);

      // extra axes
      svg.append("g")
          .attr("class", "x axis")
        .call(xAxis.ticks(0));

      svg.append("g")
          .attr("class", "y axis")
          .attr('transform', 'translate(' + width + ',0)')
        .call(yAxis.ticks(0));

      // title and axis labels
      svg.append("text")
          .attr("class", "x label")
          .attr("text-anchor", "end")
          .attr("x", (width / 2))
          .attr("y", height + (margin.bottom / 2))
          .style("font-size", "14px")
        .text("False Positive Rate");

      svg.append("text")
          .attr("class", "y label")
          .attr("text-anchor", "end")
          .attr("y", 0 - (margin.left / 2))
          .attr("x", 0 - (height / 2))
          .attr("transform", "rotate(-90)")
          .style("font-size", "14px")
        .text("True Positive Rate");

      svg.append("text")
          .attr("x", (width / 2))
          .attr("y", 0 - (margin.top / 2))
          .attr("text-anchor", "middle")
          .style("font-size", "16px")
        .text(title);

      // legend
      var auc;
      for (var i=0; i < 5; i++) {
        auc = aucs[i];
        svg.append("text")
            .attr("y", height - (legendHeight/2)+i*25)
            .attr("x", (width / 2) + 0 + 30)
            .attr("class", "legend")
            .text(`ROC fold ${i}: (AUC = ${auc.toFixed(2)})`)

        svg.append('rect')
            .attr("y", height - (legendHeight/2)+i*25 - 10)
            .attr("x", (width / 2) + 0 + 15 )
            .attr("height", 10)
            .attr("width", 10)
            .attr('fill', colors[i]);
      }

      auc = mean['auc'];
      svg.append("text")
          .attr("y", height - (legendHeight/2)+i*25)
          .attr("x", (width / 2) + 0 + 30)
          .attr("class", "legend")
          .html(`Mean ROC: (AUC = ${auc.toFixed(2)} &plusmn; ${mean['std_auc'].toFixed(2)})`)

      svg.append('rect')
          .attr("y", height - (legendHeight/2)+i*25 - 10)
          .attr("x", (width / 2) + 0 + 15)
          .attr("height", 10)
          .attr("width", 10)
          .attr('fill', colors[i]);


      svg.append("circle")
        .attr("cx", function(d) { return x(marker.x); })
        .attr("cy", function(d) { return y(marker.y); })
        .attr("r", function(d) { return 5 })
        .attr("fill", color)
    });
  }

  chart.width = function(_) {
    if (!arguments.length) return width;
    width = _;
    return chart;
  };

  var marker = {}
  chart.marker = function(_) {
    if (!arguments.length) return marker;
    marker = _;
    return chart;
  };

  chart.height = function(_) {
    if (!arguments.length) return height;
    height = _;
    return chart;
  };

  chart.title = function(_) {
    if (!arguments.length) return title;
    title = _;
    return chart;
  };

  return chart;
}

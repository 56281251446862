import {
  get,
  // eslint-disable-next-line no-unused-vars
  curry,
  compose,
  map,
  filter,
  find,
  // eslint-disable-next-line no-unused-vars
  head,
} from 'lodash/fp';

import { REPORT_SCHEMA_HELP_DOC_MAPPINGS } from '../constants';

export const transformDatalakeTablesResponse = (data, segments) => {
  const getConversionName = identifier => compose(
    get('action_name'),
    find(s => s.action_id === Number(identifier)),
  )(segments);

  return compose(
    map(table => ({
      ...table,
      ...(table.identifier && { conversionName: getConversionName(table.identifier) }),
      helpDoc: get(table.report)(REPORT_SCHEMA_HELP_DOC_MAPPINGS),
    })),
  )(data);
};

// used by options selectors
const createReportDropdownOptions = map(({ report, display_name }) => ({
  key: report,
  text: display_name,
  value: report,
  content: display_name,
}));

export const selectSchemasByReportType = (data, reportType) => compose(
  filter(s => s.report_type === reportType),
)(data);

const filteredTables = schemas => advertiserTables => {
  const filteredSchemas = map(({ report }) => report)(schemas);
  return filter(table => filteredSchemas.includes(table.report))(advertiserTables);
};

const buildSyncedDatasetsViewObject = schemas => advertiserTables => map(table => ({
  ...table,
  ...find(s => s.report === table.report)(schemas),
}))(advertiserTables);

export const selectDropdownOptionsByReportType = (datalakeTables, datalakeReports) => reportType => {
  const schemas = selectSchemasByReportType(datalakeReports, reportType);
  return createReportDropdownOptions(schemas);
};

export const selectDatasetsByReportType = (datalakeTables, datalakeReports) => reportType => {
  const filteredSchemas = selectSchemasByReportType(datalakeReports, reportType);
  return compose(
    buildSyncedDatasetsViewObject(filteredSchemas),
    filteredTables(filteredSchemas),
  )(datalakeTables);
};

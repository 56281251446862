import React, { useState, useEffect } from 'react';
import { Grid, GridColumn } from 'semantic-ui-react';
import { useAtom } from 'jotai';
import { BasicViewHeader, ViewAccessWrapper, LayoutToggles } from '../../components';
import { platformAtom, industryAtom, spendLevelAtom, adTypeAtom, benchmarksTopTapAtom } from '../../atoms';
import { getTrendCategories } from '../../api/attribution';
import LineChartWithLegend from './Chart';
import Insights from './Insights';
import Filter from './Filter';
import Table from './Table';
import CallToAction from './CallToAction';

const PlatformBenchmarks = () => {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [platform, setPlatform] = useAtom(platformAtom);
  const [filterType, setFilterType] = useState('Industry');
  const [industry, setIndustry] = useAtom(industryAtom);
  const [topTab, setTopTab] = useAtom(benchmarksTopTapAtom);
  const [spendLevelOptions, setSpendLevelOptions] = useState([]);
  const [spendLevel, setSpendLevel] = useAtom(spendLevelAtom);
  const [adType, setAdType] = useAtom(adTypeAtom);
  const [trendCategories, setTrendCategories] = useState(false);

  useEffect(() => {
    getTrendCategories().then(newData => {
      newData.industries.push({ value: 'All industries', text: 'All industries' });
      setTrendCategories(newData);
    });
  }, []);

  return (
    <div className="benchmarks">
      <BasicViewHeader
        header="Advertising Performance Benchmarks"
        subheader="View trends and benchmarks for advertising KPIs on popular digital platforms"
      />
      <Filter {...{
        platform, setPlatform, filterType, setFilterType, setLoading, loading, setData,
        industry, setIndustry, spendLevel, setSpendLevel, spendLevelOptions, setSpendLevelOptions,
        adType, setAdType, data, trendCategories,
      }}
      />
      <LayoutToggles
        chartComponent={(
          <Grid>
            <GridColumn width={5} className="insights">
              <Insights {...{ data, platform, filterType, industry, spendLevel, adType, spendLevelOptions }} />
            </GridColumn>
            <GridColumn width={11} className="chart">
              <LineChartWithLegend {...{ data, loading, platform, topTab, setTopTab }} />
            </GridColumn>
          </Grid>
        )}
        tableComponent={(
          <Grid>
            <GridColumn width={11} className="table">
              <Table {...{ data, platform }} />
            </GridColumn>
            <GridColumn width={5} className="cta">
              {data.length > 0 && <CallToAction {...{ platform }} />}
            </GridColumn>
          </Grid>
        )}
      />
    </div>
  );
};

const PlatformBenchmarksWrapper = () => (
  <ViewAccessWrapper
    viewName="platform_performance_benchmarks"
    viewComponent={<PlatformBenchmarks />}
  />
);

export default PlatformBenchmarksWrapper;

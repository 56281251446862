import React, { useState, useEffect } from 'react';
import { SplitLayout, ContentCard, IndexGridNew, channelGroupsIcon } from '@rockerbox/styleguide';
import { Segment, Loader, Icon, Header, Button, Table, Image, Message } from 'semantic-ui-react';
import { getChannelGroups, getSegments, deleteChannelGroup } from '../../utils/api';
import ChannelGroupsForm from './ChannelGroupsForm';
import { openIntercomMessenger } from '../Navigation/helpSection';

const EmptyMessage = props => (
  <ContentCard>
    <div style={{textAlign: 'center'}}>
      <Icon
        circular
        disabled
        name='info'
        size='big'
        className='m-b-10' />
      <Header
        as='h3'
        disabled
        content={props.fallBackMsg || 'No data found'}
        className='m-0' />
        { props.children }
    </div>
  </ContentCard>
)

const tierCols = [
  {display: "Tier 1", key: "tier_1"},
  {display: "Tier 2", key: "tier_2"},
  {display: "Tier 3", key: "tier_3"},
  {display: "Tier 4", key: "tier_4"},
  {display: "Tier 5", key: "tier_5"},
]

const Expanded = ({item}) => <IndexGridNew data={item.tiers} cols={tierCols}/>

const ChannelGroups = () => {
  const [featuredSegment, setFeaturedSegment] = useState({});
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [viewState, setViewState] = useState("");
  const [selectedGroup, setSelectedGroup] = useState({});
  const [saveStatus, setSaveStatus] = useState("");

  const getData = () => {
    getChannelGroups()
    .then(data => {
      setData(data);
      setViewState("index");
      setLoading(false);
    });
  }
 
  useEffect(()=> {
    getData();

    getSegments()
    .then(data => {
      const featured = data.find(c => c.featured == 1);
      featured.segment_id = featured.filter_id
      
      setFeaturedSegment(featured);
    })
  }, [])

  useEffect(() => {
    if(viewState == "index") {
      setLoading(true);
      getData();
    }
  }, [viewState])

  const handleEdit = (item) => {
    setViewState("edit")
    setSelectedGroup(item);
  }

  const handleDelete = (item) => {
    setLoading(true);
    
    deleteChannelGroup(item.id)
    .then(data => {
      getData();
    })
  }

  const SavedSuccessfully = () => <Message positive onDismiss={() => setSaveStatus("")}>
    <Message.Header><Icon name="check circle"/>Channel Group Saved</Message.Header>
    <p>Note: these changes will only be applied to your reports <b>going forward</b>.<br/>If you would like these changes to be applied retroactively to historical data, please <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={openIntercomMessenger}>contact us</span>.</p>
  </Message>

  const SaveError = () => <Message negative onDismiss={() => setSaveStatus("")}>
    <Message.Header><Icon name="warning sign"/> Error creating Channel Group</Message.Header>
    <p>The Display Name conflicts with a previously created Channel Group. Please use a unique name that has not been used before.</p>
  </Message>

  const ManageCell = ({item, col}) => (
    <Table.Cell>
      <Button icon="pencil" onClick={() => handleEdit(item)}/>
      <IndexGridNew.DeleteButton onDelete={() => handleDelete(item, col)} popupContent="Are you sure you want to delete this Channel Group?"/>
    </Table.Cell>
  )

  const cols = [
    { display: "Name", key: "name" },
    { display: "Column Prefix", key: "prefix" },
    { display: "Manage", as: ManageCell}
  ]

  const handleCreate = () => {
    setSelectedGroup({});
    setViewState("create")
  }

  return (
    <SplitLayout
    leftWidth={12}
    leftContent={<ContentCard title="Channel Groups" topRight={(viewState == "create" || viewState == "edit") ? <a onClick={() => setViewState("index")} style={{cursor: 'pointer'}}><Icon name="angle left"/>Back</a> : <Button primary size="mini" content="Create" onClick={() => handleCreate()}/> }>
      { loading && <Segment style={{height: 380}}><Loader active={loading} size='medium'>Loading</Loader></Segment> }
      { (!loading && saveStatus.length > 0) && ((saveStatus == "saved") ? <SavedSuccessfully/> : <SaveError/>)}
      { (!loading && viewState == "index") && ((data && data.length > 0) ? <IndexGridNew data={data} cols={cols} expandable expandedComponent={Expanded} idKey="prefix"/> : <EmptyMessage fallBackMsg="No Groups Created"><Button primary content="Create Channel Group" onClick={() => handleCreate()} style={{marginTop: 20}}/></EmptyMessage>)}
      { (!loading && (viewState == "create" || viewState == "edit")) && <ChannelGroupsForm {...{featuredSegment, viewState, selectedGroup, setViewState, setSaveStatus}}/>}
    </ContentCard>}
    rightWidth={4}
    rightContent={
      <ContentCard>
        <Header as='h3' style={{fontWeight: "bold"}}>
          <Image size="small" src={channelGroupsIcon} /> What are Channel Groups?
        </Header>
        <p>View the impact of your marketing by only giving credit to the channels that matter to you.</p>
        <p>Select a series of marketing tiers to be associated with a group, and then filter your attribution reports to only count those channels.</p>
        <p>For example, if you want to see how your CPA & ROAS change when you only give credit to paid marketing channels, create a Channel Group and select all the tiers associated with the channels you are spending on.
          After setting up, you will be able to filter your data in the Reports view by this Channel Group.
        </p>
      </ContentCard>
    }
  />
  )
}

export default ChannelGroups
import React from 'react';
import * as Router from "react-router-dom";
import * as routes from '../../routes';
import { Link } from "react-router-dom"

import AdvancedSearch from '../helpers/AdvancedSearch';
import { PivotGrid, PagedIndexGrid, ContentCard } from '@rockerbox/styleguide';
import { Icon, Dropdown, Button } from 'semantic-ui-react';
import _ from 'lodash';
import CacheContext from '../../utils/CacheContext';
import { getProcessedReports } from '../../utils/api';

const ForceColumnToValue = (props) => {
  const { data, options, onChange } = props

  const [value, setValue] = React.useState([])

  const onUpdate = () => {
    
    const forcedData = data.map(row => {
      const forced = value.reduce((p,c) => {
        p[c] = parseFloat(row[c])
        return p
      }, {})
      return Object.assign({}, row, forced)
    })
    onChange(false, { value: forcedData}) 

  }

  React.useEffect(() => {
    onUpdate()
  }, [data, value])

  return <Dropdown {...{options, onChange: (_, { value }) => { setValue(value) }}} selection search multiple />
  
}

const DatasetsView = (props) => {

  const [state, setState, Context] = React.useContext(CacheContext);
  const listType = Context.getCache("listType", "processed")

  const [data, setData] = React.useState([]);
  const [originalData, setOriginalData] = React.useState([]);
  const [searchColumns, setSearchColumns] = React.useState([]);


  const [columns, setColumns] = React.useState([]);
  const [viewBy, setViewBy] = React.useState("list");
  const history = Router.useHistory();
  const routedData = Router.useRouteMatch(`${routes.datasetsIndex}/view/:selectedType?/:selectedReport?/:selectedFile?`) || {};
  const { params: { selectedType, selectedReport, selectedFile } = {} } = routedData

  const options = originalData.length == 0 ? [] : Object.keys(originalData[0])
    .map(text => Object.assign({text, value: text}))

  React.useEffect(() => {
    setData([])
    if (selectedFile) {
      setData(false)
      getProcessedReports(selectedReport, listType, "data", selectedFile)
        .then(data => {
          data.map(obj => {
            Object.assign(obj, Object.entries(obj).reduce((p,c) => {
              p[c[0]] = c[1] == "nan" ? 0 : c[1]
              return p
            }, {}))
          })
          setData(data)
          setOriginalData(data)
          if (data.length == 0) return

          const cols = Object.keys(data[0]);
          const columns = cols.map(key => {
            return {key, display: key }
          })

          setColumns(columns)
        })
    }
  }, [selectedFile])

  const updateData = (_, { value }) => {
    debugger
    setData(value)
  }

  return <ContentCard 
    title={<Link to={`${routes.datasetsIndex}/view/${routedData.params.selectedType}/${routedData.params.selectedReport}`}>
      <Icon name="chevron left"/>Back
    </Link>
    } 
    hasTable style={{overflowX:"scroll"}}
    topRight={<Button.Group size="mini">
      <Button active={viewBy == "list"} onClick={() => setViewBy("list")}>List</Button>
      <Button active={viewBy == "pivot"} onClick={() => setViewBy("pivot")}>Pivot</Button>
    </Button.Group>
    }
  > 
    <div style={{padding:10}}>
    { viewBy == "list" && <PagedIndexGrid itemsPerPage={20} cols={columns} data={data} /> }
    { viewBy == "pivot" && <>
        <div>Force column to be metric: <ForceColumnToValue {...{options, data: originalData, onChange: updateData}} /></div>
        <div>Allow search by: <Dropdown {...{options, onChange: (_, { value }) => setSearchColumns(value) }} selection search multiple  /></div>

        { searchColumns.length >0 && <AdvancedSearch 
          data={originalData} 
          searchBy={searchColumns} 
          onChange={updateData}
        /> }
        <PivotGrid data={data} hasRawData={data.length > 0} defaultCols={[]} defaultRows={[]} defaultSummary={[]} showOptions={true} selectColumnWidth={3} /> 
      </>
    }

    </div>
  </ContentCard>

}
export default DatasetsView;

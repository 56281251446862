import React, { Component } from 'react';

import { SplitLayout, ContentCard } from '@rockerbox/styleguide';
import ModelSummary from './ModelSummary';
import { SelectModel } from './ModelNav';
import ROCPlot from './ROCPlot';

import css from './charts.css';


const NoCompare = () => (
  <ContentCard>
    <div style={{textAlign:"center"}}>
      Hey there! Use the dropdown above to compare you existing model against another run.
    </div>
  </ContentCard>
)

class ModelCompare extends Component {


  render() {
    const { id, run_id, comparisonId } = this.props;
    const { modelRuns, globalThreshold, confusion_data, roc, compare_confusion_data, compare_roc  } = this.props;


    const { setRun, setCompare, setGlobalThreshold } = this.props;

    const threshold = globalThreshold
    const onSetThreshold = setGlobalThreshold
    const title = "Receiver operating characteristic"
     
    const show_left = !!confusion_data
    const show_right = !!compare_confusion_data

    return (
      <SplitLayout 
        leftContent={
          <React.Fragment>
            <ContentCard 
              noContent borderless disableHeaderEllipse
              title={<SelectModel id={run_id} onSelect={setRun} {...{modelRuns}}/>} 
            />
            { show_left && 
              <React.Fragment>
                <ModelSummary {...{threshold, onSetThreshold}} data={confusion_data} /> 
                <ROCPlot cfData={confusion_data} {...{title, threshold, onSetThreshold}} data={roc} />
              </React.Fragment>
            }
          </React.Fragment>
        }
        leftWidth={8}
        rightContent={
          <React.Fragment>
            <ContentCard 
              noContent borderless disableHeaderEllipse
              title={<SelectModel id={comparisonId} onSelect={setCompare} {...{modelRuns}}/>} 
            />
            { show_right && 
              <React.Fragment>
                <ModelSummary {...{threshold, onSetThreshold}} data={compare_confusion_data} /> 
                <ROCPlot {...{title, threshold, onSetThreshold}} cfData={compare_confusion_data} data={compare_roc} />
              </React.Fragment>
            }
            { !show_right && <NoCompare /> }
          </React.Fragment>
        }
        rightWidth={8}
      />
    );
  }
}

export default ModelCompare;

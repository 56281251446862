import React, { useState, useEffect } from 'react';

import { Menu } from 'semantic-ui-react';

export const useMenu = (items, defaultValue, initialState, setState, vertical) => {
  const updateState = setState || useState;
  const [selectedItem, setSelectedItem] = updateState(initialState || defaultValue);

  useEffect(() => {
    if (selectedItem === '') setSelectedItem(defaultValue);
  }, [selectedItem]);

  const handleItemClick = (e, { name }) => {
    setSelectedItem(name);
  };

  const Component = () => (
    <Menu tabular vertical={vertical} className="mmmm-channel-menu-overrides">
      {items.map(item => (
        <Menu.Item key={item} name={item} content={item} active={selectedItem === item} onClick={handleItemClick} />
      ))}
    </Menu>
  );

  return {
    Component,
    selectedItem,
  };
};

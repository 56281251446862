import _ from 'lodash'

/*
 * Generated by PEG.js 0.9.0.
 *
 * http://pegjs.org/
 */

function peg$subclass(child, parent) {
  function ctor() { this.constructor = child; }
  ctor.prototype = parent.prototype;
  child.prototype = new ctor();
}

function peg$SyntaxError(message, expected, found, location) {
  this.message  = message;
  this.expected = expected;
  this.found    = found;
  this.location = location;
  this.name     = "SyntaxError";

  if (typeof Error.captureStackTrace === "function") {
    Error.captureStackTrace(this, peg$SyntaxError);
  }
}

peg$subclass(peg$SyntaxError, Error);

function peg$parse(input) {
  var options = arguments.length > 1 ? arguments[1] : {},
      parser  = this,

      peg$FAILED = {},

      peg$startRuleFunctions = { start: peg$parsestart },
      peg$startRuleFunction  = peg$parsestart,

      peg$c0 = function(node) { 
              return node[0]; 
          },
      peg$c1 = function() { 
              return {};
          },
      peg$c2 = function(operator) { 
              return { 
                  'operator': operator 
                  }; 
          },
      peg$c3 = function(operator, right) { 
              return right; 
          },
      peg$c4 = function(left, operator, right) { 
              var node= { 
                  'left':left 
                  };
              
              var right = 
                      right.length == 0 
                      ? null 
                      : right[0]['right'] == null 
                          ? right[0]['left'] 
                          : right[0];
                          
              if (right != null) 
              { 
                  node['operator'] = operator==''? '<implicit>' : operator[0];
                  node['right'] = right;
              } 
              
              return node;
          },
      peg$c5 = function(field_exp) { 
              return field_exp; 
          },
      peg$c6 = "(",
      peg$c7 = { type: "literal", value: "(", description: "\"(\"" },
      peg$c8 = ")",
      peg$c9 = { type: "literal", value: ")", description: "\")\"" },
      peg$c10 = function(op, fieldname, range) { 
              range['field'] = 
                  fieldname == '' 
                      ? "<implicit>"
                      : fieldname; 
                  
              if ( '' != op ) {
                  range['prefix'] = op;
                  }
              return range; 
          },
      peg$c11 = function(op, fieldname, node) { 
              node['field']= fieldname; 
              if ( '' != op ) {
                  node['prefix'] = op;
              }
              return node; 
          },
      peg$c12 = function(op, fieldname, term) { 
              var fieldexp = {
                  'field': 
                      fieldname == '' 
                          ? "<implicit>"
                          : fieldname           
                  }; 
              if ( '' != op ) {
              	fieldexp['prefix'] = op;
                  }
              for(var key in term)
                  fieldexp[key] = term[key];
                  
              return fieldexp; 
          },
      peg$c13 = /^[:]/,
      peg$c14 = { type: "class", value: "[:]", description: "[:]" },
      peg$c15 = function(fieldname) { 
              return fieldname; 
          },
      peg$c16 = function(term) {
              var result = { 'term': term };
              
              return result;
          },
      peg$c17 = function(term) {
              var result = { 'term': term };
              return result;
          },
      peg$c18 = function(term) { 
              return term.join('');
          },
      peg$c19 = ".",
      peg$c20 = { type: "literal", value: ".", description: "\".\"" },
      peg$c21 = /^[^: \t\r\n\f{}()"+-\/[\]]/,
      peg$c22 = { type: "class", value: "[^: \\t\\r\\n\\f\\{\\}()\"+-/\\[\\]]", description: "[^: \\t\\r\\n\\f\\{\\}()\"+-/\\[\\]]" },
      peg$c23 = /^[^: \t\r\n\f{}()"+-\/\^~]/,
      peg$c24 = { type: "class", value: "[^: \\t\\r\\n\\f\\{\\}()\"+-/^~]", description: "[^: \\t\\r\\n\\f\\{\\}()\"+-/^~]" },
      peg$c25 = "\"",
      peg$c26 = { type: "literal", value: "\"", description: "\"\\\"\"" },
      peg$c27 = /^[^"]/,
      peg$c28 = { type: "class", value: "[^\"]", description: "[^\"]" },
      peg$c29 = function(term) {
              return term.join('');
          },
      peg$c30 = "~",
      peg$c31 = { type: "literal", value: "~", description: "\"~\"" },
      peg$c32 = function(proximity) {        
              return proximity;
          },
      peg$c33 = "^",
      peg$c34 = { type: "literal", value: "^", description: "\"^\"" },
      peg$c35 = function(boost) {        
              return boost;
          },
      peg$c36 = function(fuzziness) {        
              return fuzziness == '' ? 0.5 : fuzziness;
          },
      peg$c37 = "0.",
      peg$c38 = { type: "literal", value: "0.", description: "\"0.\"" },
      peg$c39 = /^[0-9]/,
      peg$c40 = { type: "class", value: "[0-9]", description: "[0-9]" },
      peg$c41 = function(val) { 
              return parseFloat("0." + val.join(''));
          },
      peg$c42 = function(val) {        
              return parseInt(val.join(''));
          },
      peg$c43 = "[",
      peg$c44 = { type: "literal", value: "[", description: "\"[\"" },
      peg$c45 = "TO",
      peg$c46 = { type: "literal", value: "TO", description: "\"TO\"" },
      peg$c47 = "]",
      peg$c48 = { type: "literal", value: "]", description: "\"]\"" },
      peg$c49 = function(term_min, term_max) { 
              return {
                  'term_min': term_min, 
                  'term_max': term_max,
                  'inclusive': true
              };
          },
      peg$c50 = "{",
      peg$c51 = { type: "literal", value: "{", description: "\"{\"" },
      peg$c52 = "}",
      peg$c53 = { type: "literal", value: "}", description: "\"}\"" },
      peg$c54 = function(term_min, term_max) { 
              return {
                  'term_min': term_min, 
                  'term_max': term_max, 
                  'inclusive': false
              };
          },
      peg$c55 = function(operator) { 
              return operator; 
          },
      peg$c56 = "OR",
      peg$c57 = { type: "literal", value: "OR", description: "\"OR\"" },
      peg$c58 = "AND",
      peg$c59 = { type: "literal", value: "AND", description: "\"AND\"" },
      peg$c60 = "NOT",
      peg$c61 = { type: "literal", value: "NOT", description: "\"NOT\"" },
      peg$c62 = "||",
      peg$c63 = { type: "literal", value: "||", description: "\"||\"" },
      peg$c64 = function() { return 'OR'; },
      peg$c65 = "&&",
      peg$c66 = { type: "literal", value: "&&", description: "\"&&\"" },
      peg$c67 = function() { return 'AND'; },
      peg$c68 = "+",
      peg$c69 = { type: "literal", value: "+", description: "\"+\"" },
      peg$c70 = "-",
      peg$c71 = { type: "literal", value: "-", description: "\"-\"" },
      peg$c72 = { type: "other", description: "whitespace" },
      peg$c73 = /^[ \t\r\n\f]/,
      peg$c74 = { type: "class", value: "[ \\t\\r\\n\\f]", description: "[ \\t\\r\\n\\f]" },
      peg$c75 = { type: "any", description: "any character" },

      peg$currPos          = 0,
      peg$savedPos         = 0,
      peg$posDetailsCache  = [{ line: 1, column: 1, seenCR: false }],
      peg$maxFailPos       = 0,
      peg$maxFailExpected  = [],
      peg$silentFails      = 0,

      peg$result;

  if ("startRule" in options) {
    if (!(options.startRule in peg$startRuleFunctions)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
  }

  function text() {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function location() {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description) {
    throw peg$buildException(
      null,
      [{ type: "other", description: description }],
      input.substring(peg$savedPos, peg$currPos),
      peg$computeLocation(peg$savedPos, peg$currPos)
    );
  }

  function error(message) {
    throw peg$buildException(
      message,
      null,
      input.substring(peg$savedPos, peg$currPos),
      peg$computeLocation(peg$savedPos, peg$currPos)
    );
  }

  function peg$computePosDetails(pos) {
    var details = peg$posDetailsCache[pos],
        p, ch;

    if (details) {
      return details;
    } else {
      p = pos - 1;
      while (!peg$posDetailsCache[p]) {
        p--;
      }

      details = peg$posDetailsCache[p];
      details = {
        line:   details.line,
        column: details.column,
        seenCR: details.seenCR
      };

      while (p < pos) {
        ch = input.charAt(p);
        if (ch === "\n") {
          if (!details.seenCR) { details.line++; }
          details.column = 1;
          details.seenCR = false;
        } else if (ch === "\r" || ch === "\u2028" || ch === "\u2029") {
          details.line++;
          details.column = 1;
          details.seenCR = true;
        } else {
          details.column++;
          details.seenCR = false;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;
      return details;
    }
  }

  function peg$computeLocation(startPos, endPos) {
    var startPosDetails = peg$computePosDetails(startPos),
        endPosDetails   = peg$computePosDetails(endPos);

    return {
      start: {
        offset: startPos,
        line:   startPosDetails.line,
        column: startPosDetails.column
      },
      end: {
        offset: endPos,
        line:   endPosDetails.line,
        column: endPosDetails.column
      }
    };
  }

  function peg$fail(expected) {
    if (peg$currPos < peg$maxFailPos) { return; }

    if (peg$currPos > peg$maxFailPos) {
      peg$maxFailPos = peg$currPos;
      peg$maxFailExpected = [];
    }

    peg$maxFailExpected.push(expected);
  }

  function peg$buildException(message, expected, found, location) {
    function cleanupExpected(expected) {
      var i = 1;

      expected.sort(function(a, b) {
        if (a.description < b.description) {
          return -1;
        } else if (a.description > b.description) {
          return 1;
        } else {
          return 0;
        }
      });

      while (i < expected.length) {
        if (expected[i - 1] === expected[i]) {
          expected.splice(i, 1);
        } else {
          i++;
        }
      }
    }

    function buildMessage(expected, found) {
      function stringEscape(s) {
        function hex(ch) { return ch.charCodeAt(0).toString(16).toUpperCase(); }

        return s
          .replace(/\\/g,   '\\\\')
          .replace(/"/g,    '\\"')
          .replace(/\x08/g, '\\b')
          .replace(/\t/g,   '\\t')
          .replace(/\n/g,   '\\n')
          .replace(/\f/g,   '\\f')
          .replace(/\r/g,   '\\r')
          .replace(/[\x00-\x07\x0B\x0E\x0F]/g, function(ch) { return '\\x0' + hex(ch); })
          .replace(/[\x10-\x1F\x80-\xFF]/g,    function(ch) { return '\\x'  + hex(ch); })
          .replace(/[\u0100-\u0FFF]/g,         function(ch) { return '\\u0' + hex(ch); })
          .replace(/[\u1000-\uFFFF]/g,         function(ch) { return '\\u'  + hex(ch); });
      }

      var expectedDescs = new Array(expected.length),
          expectedDesc, foundDesc, i;

      for (i = 0; i < expected.length; i++) {
        expectedDescs[i] = expected[i].description;
      }

      expectedDesc = expected.length > 1
        ? expectedDescs.slice(0, -1).join(", ")
            + " or "
            + expectedDescs[expected.length - 1]
        : expectedDescs[0];

      foundDesc = found ? "\"" + stringEscape(found) + "\"" : "end of input";

      return "Expected " + expectedDesc + " but " + foundDesc + " found.";
    }

    if (expected !== null) {
      cleanupExpected(expected);
    }

    return new peg$SyntaxError(
      message !== null ? message : buildMessage(expected, found),
      expected,
      found,
      location
    );
  }

  function peg$parsestart() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parse_();
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$parse_();
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parsenode();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parsenode();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c0(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parse_();
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parse_();
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1();
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseEOF();
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1();
        }
        s0 = s1;
      }
    }

    return s0;
  }

  function peg$parsenode() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parseoperator_exp();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseEOF();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c2(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseoperator_exp();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsenode();
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c3(s1, s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parsegroup_exp();
        if (s1 !== peg$FAILED) {
          s2 = [];
          s3 = peg$parseoperator_exp();
          while (s3 !== peg$FAILED) {
            s2.push(s3);
            s3 = peg$parseoperator_exp();
          }
          if (s2 !== peg$FAILED) {
            s3 = [];
            s4 = peg$parsenode();
            while (s4 !== peg$FAILED) {
              s3.push(s4);
              s4 = peg$parsenode();
            }
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c4(s1, s2, s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    return s0;
  }

  function peg$parsegroup_exp() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parsefield_exp();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parse_();
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parse_();
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c5(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$parseparen_exp();
    }

    return s0;
  }

  function peg$parseparen_exp() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 40) {
      s1 = peg$c6;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c7); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parsenode();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parsenode();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 41) {
          s3 = peg$c8;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c9); }
        }
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$parse_();
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$parse_();
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c0(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsefield_exp() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseprefix_operator_exp();
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsefieldname();
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parserange_operator_exp();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c10(s1, s2, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseprefix_operator_exp();
      if (s1 === peg$FAILED) {
        s1 = null;
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parsefieldname();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseparen_exp();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c11(s1, s2, s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseprefix_operator_exp();
        if (s1 === peg$FAILED) {
          s1 = null;
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parsefieldname();
          if (s2 === peg$FAILED) {
            s2 = null;
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parseterm();
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c12(s1, s2, s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    return s0;
  }

  function peg$parsefieldname() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseunquoted_field();
    if (s1 !== peg$FAILED) {
      if (peg$c13.test(input.charAt(peg$currPos))) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c14); }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c15(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseterm() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parsequoted_term();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parse_();
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parse_();
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c16(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseunquoted_term();
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$parse_();
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parse_();
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c17(s1);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseunquoted_term() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseterm_char();
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parseterm_char();
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c18(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseterm_char() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 46) {
      s0 = peg$c19;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c20); }
    }
    if (s0 === peg$FAILED) {
      if (peg$c21.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c22); }
      }
    }

    return s0;
  }

  function peg$parseunquoted_field() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parsefield_char();
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parsefield_char();
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c18(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parsefield_char() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 46) {
      s0 = peg$c19;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c20); }
    }
    if (s0 === peg$FAILED) {
      if (peg$c23.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c24); }
      }
    }

    return s0;
  }

  function peg$parsequoted_term() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 34) {
      s1 = peg$c25;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c26); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      if (peg$c27.test(input.charAt(peg$currPos))) {
        s3 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c28); }
      }
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          if (peg$c27.test(input.charAt(peg$currPos))) {
            s3 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c28); }
          }
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 34) {
          s3 = peg$c25;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c26); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c29(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseproximity_modifier() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 126) {
      s1 = peg$c30;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c31); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseint_exp();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c32(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseboost_modifier() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 94) {
      s1 = peg$c33;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c34); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsedecimal_or_int_exp();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c35(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsefuzzy_modifier() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 126) {
      s1 = peg$c30;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c31); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsedecimal_exp();
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c36(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsedecimal_or_int_exp() {
    var s0;

    s0 = peg$parsedecimal_exp();
    if (s0 === peg$FAILED) {
      s0 = peg$parseint_exp();
    }

    return s0;
  }

  function peg$parsedecimal_exp() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c37) {
      s1 = peg$c37;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c38); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      if (peg$c39.test(input.charAt(peg$currPos))) {
        s3 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c40); }
      }
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          if (peg$c39.test(input.charAt(peg$currPos))) {
            s3 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c40); }
          }
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c41(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseint_exp() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    if (peg$c39.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c40); }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        if (peg$c39.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c40); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c42(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parserange_operator_exp() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 91) {
      s1 = peg$c43;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c44); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseunquoted_term();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$parse_();
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$parse_();
        }
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c45) {
            s4 = peg$c45;
            peg$currPos += 2;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c46); }
          }
          if (s4 !== peg$FAILED) {
            s5 = [];
            s6 = peg$parse_();
            if (s6 !== peg$FAILED) {
              while (s6 !== peg$FAILED) {
                s5.push(s6);
                s6 = peg$parse_();
              }
            } else {
              s5 = peg$FAILED;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseunquoted_term();
              if (s6 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 93) {
                  s7 = peg$c47;
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c48); }
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c49(s2, s6);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 123) {
        s1 = peg$c50;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c51); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parseunquoted_term();
        if (s2 !== peg$FAILED) {
          s3 = [];
          s4 = peg$parse_();
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$parse_();
          }
          if (s3 !== peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c45) {
              s4 = peg$c45;
              peg$currPos += 2;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c46); }
            }
            if (s4 !== peg$FAILED) {
              s5 = [];
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                while (s6 !== peg$FAILED) {
                  s5.push(s6);
                  s6 = peg$parse_();
                }
              } else {
                s5 = peg$FAILED;
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parseunquoted_term();
                if (s6 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 125) {
                    s7 = peg$c52;
                    peg$currPos++;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c53); }
                  }
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c54(s2, s6);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseoperator_exp() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parse_();
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$parse_();
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseoperator();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$parse_();
        if (s4 !== peg$FAILED) {
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$parse_();
          }
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c55(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parse_();
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parse_();
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parseoperator();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseEOF();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c55(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseoperator() {
    var s0, s1;

    if (input.substr(peg$currPos, 2) === peg$c56) {
      s0 = peg$c56;
      peg$currPos += 2;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c57); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c58) {
        s0 = peg$c58;
        peg$currPos += 3;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c59); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c60) {
          s0 = peg$c60;
          peg$currPos += 3;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c61); }
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 2) === peg$c62) {
            s1 = peg$c62;
            peg$currPos += 2;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c63); }
          }
          if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c64();
          }
          s0 = s1;
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 2) === peg$c65) {
              s1 = peg$c65;
              peg$currPos += 2;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c66); }
            }
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c67();
            }
            s0 = s1;
          }
        }
      }
    }

    return s0;
  }

  function peg$parseprefix_operator_exp() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parse_();
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$parse_();
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseprefix_operator();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c55(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseprefix_operator() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 43) {
      s0 = peg$c68;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c69); }
    }
    if (s0 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 45) {
        s0 = peg$c70;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c71); }
      }
    }

    return s0;
  }

  function peg$parse_() {
    var s0, s1;

    peg$silentFails++;
    s0 = [];
    if (peg$c73.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c74); }
    }
    if (s1 !== peg$FAILED) {
      while (s1 !== peg$FAILED) {
        s0.push(s1);
        if (peg$c73.test(input.charAt(peg$currPos))) {
          s1 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c74); }
        }
      }
    } else {
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c72); }
    }

    return s0;
  }

  function peg$parseEOF() {
    var s0, s1;

    s0 = peg$currPos;
    peg$silentFails++;
    if (input.length > peg$currPos) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c75); }
    }
    peg$silentFails--;
    if (s1 === peg$FAILED) {
      s0 = void 0;
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  peg$result = peg$startRuleFunction();

  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$fail({ type: "end", description: "end of input" });
    }

    throw peg$buildException(
      null,
      peg$maxFailExpected,
      peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
      peg$maxFailPos < input.length
        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
    );
  }
}

const parser = peg$parse

/* https://raw.githubusercontent.com/peebles/json-lucene-like-query/master/index.js */
/*
  Added a JSON.query() function that given an object or an array of objects
  and a "lucene-like" expression, returns an array of the objects that match
  the expression.

  * Supported features:
  * - conjunction operators (AND, OR, ||, &&, NOT)
  * - prefix operators (+, -) (on the field, like Kibana)
  * - quoted values ("foo bar")
  * - named fields (foo:bar)
  * - range expressions (foo:[bar TO baz], foo:{bar TO baz})
  * - parentheses grouping ( (foo OR bar) AND baz ) 
  * - field groups ( foo:(bar OR baz) )
  * - fields that refer to arrays ( foo.bar[0]:baz )
  * - test for missing and existing fields:
  *     foo.bar:_missing_
  *     foo.bar:_exists_

*/

export const parseLucene = function( arr, expression, transformValue = false, fields = [], aliases = {} ) {

    function handleTerm( o, term ) {
	if ( term.field && term.field != '<implicit>' )
	    var compare = _.get( o, aliases[term.field] || term.field );
	else
	    var compare = JSON.stringify( o );

        compare = transformValue ? transformValue(compare, term.field) : compare;
        //console.log(term, compare, (!compare && (compare !== 0)))

	if ( term.field && term.term == '_missing_' ) return ( compare == undefined );
	if ( term.field && term.term == '_exists_' ) return ( compare != undefined );

	if ( !compare && (compare !== 0)) return false;
        //if (compare == 0) debugger

	if ( term.field && term.term_min && term.term_max ) {
	    // range check
	    if ( compare.toString().match(/^\d+$/) ) {
		if ( Number( compare ) >= Number( term.term_min ) &&
		     Number( compare ) <= Number( term.term_max ) ) return true;
		else return false;
	    }
	    else {
		if ( compare >= term.term_min && compare <= term.term_max ) return true;
		else return false;
	    }
	}

	if ( term.field && term.term.match( /^([\>\<\=]{1}[=]*)(\d+)$/ ) ) {
	    var m = term.term.match( /^([\>\<\=]{1}[=]*)(\d+)$/ );
	    var T = Number( compare );
	    var M = Number( m[2] );
	    switch( m[1] ) {
	    case '<':  return ( T < M ); break;
	    case '<=': return ( T <= M ); break;
	    case '=': return ( T == M ); break;
	    case '>':  return ( T > M ); break;
	    case '>=': return ( T >= M ); break;
	    }
	}

	var regex = new RegExp( term.term );
	var match = ( compare.toString().match( regex ) ? true : false );
	if ( term.prefix && term.prefix == '-' )
	    match = ! match;
	return match;
    }

    function handleExpr( o, exp ) {
	if ( _.has( exp, 'left.operator' ) )
	    var left = handleExpr( o, exp.left );
	else if ( _.has( exp, 'left.term' ) || _.has( exp, 'left.term_min' ) )
	    var left = handleTerm( o, exp.left );
	else
	    var left = false;

	if ( _.has( exp, 'right.operator' ) )
	    var right = handleExpr( o, exp.right );
	else if ( _.has( exp, 'right.term' ) || _.has( exp, 'right.term_min' ) )
	    var right = handleTerm( o, exp.right );
	else
	    var right = true;


	//if ( _.has( exp, 'left.term' ) &&  _.has( exp, 'right.term' )) debugger
	if ( exp.operator == 'AND' ) return ( left && right );
	else if ( exp.operator == 'OR' ) return ( left || right );
	else return left;
    }
    
    if (!expression) return arr;
    var exp = parser( expression );

    if ( ! ( arr instanceof Array ) ) {
	var a = [];
	a.push( arr );
	arr = a;
    }

    var matches = _.filter( arr, function( o ) {
        const obj = (fields.length) ? fields.reduce((p,c) => Object.assign(p, {[c]: o[c]}), {}) : o
	return handleExpr( obj, exp );
    });
    return matches;
}

export default parseLucene

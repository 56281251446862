export const NTF_OPTIONS = [
  {
    text: 'All Customers',
    value: 'all',
    key: 'all',
  },
  {
    text: 'New Customers',
    value: 'new',
    key: 'new',
  },
  {
    text: 'Repeat Customers',
    value: 'repeat',
    key: 'repeat',
  },
];

export const REPORT_TYPE_OPTIONS_NEW_VISITORS = [
  {
    text: 'Most New Visitors',
    value: 'visitors',
    key: 'visitors',
  },
  {
    text: 'Most Efficient Cost',
    value: 'cpa',
    key: 'cpa',
  },
  {
    text: 'Most Pageviews',
    value: 'engagement',
    key: 'engagement',
  },
];

export const REPORT_TYPE_OPTIONS_FUNNEL_POSITION = [
  {
    text: 'Channel mix at each stage of the funnel',
    value: 'mix',
    key: 'mix',
  },
  {
    text: 'Role of each channel across the funnel',
    value: 'individual',
    key: 'individual',
  },
];

export const methodOptions = [
  {
    text: 'Modeled Multi-Touch',
    value: 'normalized',
    key: 'normalized',
  },
  {
    text: 'Even Weight',
    value: 'even',
    key: 'even',
  },
  {
    text: 'First-Touch',
    value: 'first_touch',
    key: 'first_touch',
  },
  {
    text: 'Last-Touch',
    value: 'last_touch',
    key: 'last_touch',
  },
];

export const ATTRIBUTABLE_MODELS = [
  { key: 1, text: 'Modeled Multi-Touch', value: 'normalized' },
  { key: 2, text: 'Even Weight', value: 'even' },
  { key: 3, text: 'Last Touch', value: 'last_touch' },
  { key: 4, text: 'First Touch', value: 'first_touch' },
  { key: 5, text: 'Full Credit', value: 'assisted' },
  { key: 6, text: 'Full Credit (7D Window)', value: 'assisted_att_win' },
];

export const sharedOptions = [
  {
    text: 'Just me',
    value: 0,
    key: 0,
  },
  {
    text: 'Everyone in my organization',
    value: 1,
    key: 1,
  },
];

export const DERIVED_TIERS = [
  { key: 1, text: 'Channel', value: 'channel' },
  { key: 2, text: 'Platform', value: 'platform' },
  { key: 3, text: 'Strategy', value: 'strategy' },
  { key: 4, text: 'Tiers', value: 'tiers' },
];

export const MAX_DAYS = 100;
export const MAX_DAYS_EXTENDED = 184;

export function getSubscriptions(email) {
  const url = `/sendgrid?user_email=${email}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json());
}

export function postSubscriptions(userEmail, subscriptionsToSuppress) {
  const obj = { user_email: userEmail, subscriptions_to_suppress: subscriptionsToSuppress };
  const url = '/sendgrid';
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  })
    .then(response => response.json());
}

export function deleteSubscriptions(userEmail, suppressionsToDelete) {
  const obj = { user_email: userEmail, suppressions_to_delete: suppressionsToDelete };
  const url = '/sendgrid';
  return fetch(url, {
    credentials: 'include',
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  })
    .then(response => response.json());
}

import React, { useMemo } from 'react';
import { useGlobalState } from '../hooks/global';
import { Upsell } from '.';
import { productLineToViewMapping } from '../constants/mappings';
import { track } from '../../utils/tracking';

const ViewAccessWrapper = ({ viewName, viewComponent }) => {
  const { accessibleFeatures } = useGlobalState();

  const hasAccess = useMemo(() => {
    if (accessibleFeatures === null) return;
    return accessibleFeatures.includes(viewName);
  }, [accessibleFeatures]);

  const findProductLine = (data, target) => {
    const productLine = Object.keys(data).find(line => data[line].includes(target));
    return productLine || 'missing';
  };

  if (hasAccess === undefined) return null;

  if (hasAccess === false) {
    const productLine = findProductLine(productLineToViewMapping, viewName);
    track(`${viewName}.${productLine}.upsell`);
    return (
      <Upsell productLine={productLine} />
    );
  }

  return viewComponent;
};

export default ViewAccessWrapper;

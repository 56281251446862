/* eslint-disable no-param-reassign */
export const formatText = text => {
  if (text === 'All ad types') return 'All Ad Types';
  return text
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const firstCharToLower = str => str.charAt(0).toLowerCase() + str.slice(1);

export const formatNumberWithCommas = number => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatSpendText = value => {
  if (value === 'all_spend') return 'All Spend Levels';
  const formatted = formatText(value);
  const words = formatted.split(' ');

  if (words[0] === 'Above' || words[0] === 'Below') {
    const multipliedValue = parseInt(words[1]) * 1000;
    return `${(words[0])} $${formatNumberWithCommas(multipliedValue)}`;
  }

  const [lower, upper] = value.split('_to_');
  const multipliedLower = parseInt(lower) * 1000;
  const multipliedUpper = parseInt(upper) * 1000;
  return `$${formatNumberWithCommas(multipliedLower)} to $${formatNumberWithCommas(multipliedUpper)}`;
};

const compareValues = (a, b) => {
  const [lowerA, upperA] = a.split('_to_');
  const [lowerB, upperB] = b.split('_to_');
  return parseInt(lowerA) - parseInt(lowerB) || parseInt(upperA) - parseInt(upperB);
};

export const processSpendOptions = input => {
  const all = [];
  const below = [];
  const range = [];
  const above = [];

  input.forEach(element => {
    if (element.startsWith('below_')) {
      below.push(element);
    } else if (element.startsWith('above_')) {
      above.push(element);
    } else if (element === 'all_spend') {
      all.push(element);
    } else {
      range.push(element);
    }
  });

  below.sort((a, b) => parseInt(a.split('_')[1]) - parseInt(b.split('_')[1]));
  range.sort(compareValues);
  above.sort((a, b) => parseInt(a.split('_')[1]) - parseInt(b.split('_')[1]));

  const sortedArray = all.concat(below, range, above);

  return sortedArray.map((element, index) => ({
    value: element,
    text: `$${formatText(element)}`,
    order: index + 1,
  }));
};

export const sortDropdownOptions = industries => industries.sort((a, b) => a.text.toUpperCase().localeCompare(b.text.toUpperCase()));

export const getIndustryNames = (data, adType = 'All ad types', trendCategories = []) => {
  const trendCategoriesSet = new Set(trendCategories.map(item => item.value));

  const filteredData = adType !== 'All ad types'
    ? data.filter(item => item.ad_type === adType)
    : data;

  const industrySet = new Set(
    filteredData
      .filter(item => item.industry && trendCategoriesSet.has(item.industry))
      .map(item => item.industry),
  );

  return Array.from(industrySet).map(industry => ({
    value: industry,
    text: formatText(industry),
  }));
};

export const getSpendLevels = (data, adType = 'All ad types') => {
  const filteredData = adType !== 'All ad types' ? data.filter(item => item.ad_type === adType) : data;
  const spendLevels = new Set(
    filteredData
      .filter(item => item.spend_level !== null)
      .map(item => item.spend_level),
  );
  return processSpendOptions(Array.from(spendLevels));
};

export const getAdTypes = data => {
  const adTypes = new Set();
  data.forEach(item => {
    if (item.ad_type !== null) {
      adTypes.add(item.ad_type);
    }
    adTypes.add('All ad types');
  });
  const sortedAdTypes = Array.from(adTypes).sort((a, b) => a.localeCompare(b));
  return sortedAdTypes;
};

export const filterData = (data, filterValue, filterKey, platform = 'default', ad_type = 'All ad types') => {
  if (filterValue === 'All' && ad_type === 'All ad types') return data;

  return data.filter(item => {
    if (platform === 'google') {
      if (filterKey === 'industry' || filterKey === 'spend_level') {
        return (item[filterKey] === filterValue) && (ad_type === 'All ad types' ? true : item.ad_type === ad_type);
      }
      return item.ad_type === ad_type;
    }
    return item[filterKey] === filterValue;
  });
};

export const groupByMonth = (data, platform) => {
  const groupedData = data.reduce((acc, item) => {
    const date = new Date(item.date);
    const yearMonth = date.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (!acc[yearMonth]) {
      acc[yearMonth] = { count: 0, cpm: 0, cpc: 0, ctr: 0, date: yearMonth, roas: 0, cpa: 0 };
    }

    const currentMonth = acc[yearMonth];
    currentMonth.count += 1;
    currentMonth.cpm += item.cpm;
    currentMonth.cpc += item.cpc;
    currentMonth.ctr += item.ctr;

    if (platform === 'facebook') {
      currentMonth.roas += item.roas;
      currentMonth.cpa += item.cpa;
    }

    return acc;
  }, {});

  return Object.values(groupedData).map(({ count, roas, cpa, ...rest }) => {
    const monthAvg = {
      ...rest,
      cpm: (rest.cpm / count),
      cpc: (rest.cpc / count),
      ctr: (rest.ctr / count),
    };

    if (platform === 'facebook') {
      monthAvg.roas = (roas / count);
      monthAvg.cpa = (cpa / count);
    }

    return monthAvg;
  });
};

export const calculateChange = (data, metric) => {
  const lowerCaseMetric = metric.toLowerCase();
  const first = data[0][lowerCaseMetric];
  const last = data[data.length - 1][lowerCaseMetric];
  const change = ((last - first) / first) * 100;
  return change;
};

export const firstElementMonthAndYear = data => {
  if (data.length === 0) return;
  const date = new Date(data[0].date);
  return {
    year: date.getUTCFullYear(),
    month: new Intl.DateTimeFormat('en-US', { month: 'long', timeZone: 'UTC' }).format(date),
  };
};

export const insightBuilder = (platform, filterType, filterValue, insightData, formattedPlatform) => {
  const isIndustry = filterType === 'Industry' && filterValue !== 'All industries';
  const isSpendLevel = filterType === 'Spend Level' && filterValue !== 'All' && filterValue.length !== 0;
  const industryText = isIndustry ? ` in the ${formatText(filterValue)} Industry` : '';
  const spendText = isSpendLevel ? ` spending ${firstCharToLower(filterValue)} per month` : '';
  const metricsText = platform === 'facebook' ? ` ${insightData.firstMetric} and ${insightData.secondArticle} ${insightData.secondDirection} in ${insightData.secondMetric}` : ' click-through-rate';
  const word = isSpendLevel && filterValue !== 'All' ? 'on' : 'using';

  return `Advertisers${spendText} ${word} ${formattedPlatform}${industryText} have seen ${insightData.firstArticle} ${insightData.firstDirection} in${metricsText}.`;
};

export const generateInsightData = (data, platform, filterType, industry, formattedSpendLevel, startMonth, startYear, formattedPlatform) => {
  if (!data) return {};

  const insightData = {};
  const weeklyData = data;

  insightData.firstMetric = platform === 'facebook' ? 'ROAS' : 'CTR';
  const firstChange = calculateChange(weeklyData, insightData.firstMetric);
  insightData.firstArticle = firstChange > 0 ? 'an' : 'a';
  insightData.firstDirection = firstChange > 0 ? 'increase' : 'decrease';
  insightData.firstMagnitude = `${Math.abs(firstChange).toFixed(2)}%`;

  insightData.secondMetric = platform === 'facebook' ? 'CPA' : 'CTR';
  const secondChange = calculateChange(weeklyData, insightData.secondMetric);
  insightData.secondArticle = secondChange > 0 ? 'an' : 'a';
  insightData.secondDirection = secondChange > 0 ? 'increase' : 'decrease';
  insightData.secondMagnitude = `${Math.abs(secondChange).toFixed(2)}%`;

  insightData.firstLine = filterType === 'Industry'
    ? insightBuilder(platform, filterType, industry, insightData, formattedPlatform)
    : insightBuilder(platform, filterType, formattedSpendLevel, insightData, formattedPlatform);

  insightData.secondLine = platform === 'facebook'
    // eslint-disable-next-line max-len
    ? `Since ${startMonth} ${startYear}, ${formattedPlatform} ${insightData.firstMetric} has ${insightData.firstDirection}d by ${insightData.firstMagnitude}. ${insightData.secondMetric} saw ${insightData.secondArticle} ${insightData.secondMagnitude} ${insightData.secondDirection} during the same period.`
    : `Since ${startMonth} ${startYear}, ${formattedPlatform} ${insightData.firstMetric} has ${insightData.firstDirection}d by ${insightData.firstMagnitude}.`;

  insightData.thirdLine = platform === 'facebook'
    ? `Check out your own ${formattedPlatform} ${insightData.firstMetric} and ${insightData.secondMetric} to see how you compare!`
    : `Check out your own ${formattedPlatform} click-through-rate to see how you compare!`;

  return insightData;
};

export const calculateMinMax = (metric, data) => {
  let min = Number.MAX_VALUE;
  let max = Number.MIN_VALUE;
  data.forEach(obj => {
    if (obj[metric] < min) {
      min = obj[metric];
    }
    if (obj[metric] > max) {
      max = obj[metric];
    }
  });
  return { min: min * 0.75, max: max * 1.35 };
};

const mean = arr => arr.reduce((sum, value) => sum + value, 0) / arr.length;

// Function to group data by date and calculate mean metrics
export const groupAndCalculateMean = (data, platform) => {
  const groupedData = {};

  // Group data by date
  data.forEach(item => {
    const { date } = item;
    if (!groupedData[date]) {
      groupedData[date] = [];
    }
    groupedData[date].push(item);
  });

  // Calculate mean metrics for each group
  const calculateMean = (items, property) => mean(items.map(item => item[property]));

  const result = Object.entries(groupedData).map(([date, items]) => {
    const baseObj = {
      date,
      cpm: calculateMean(items, 'cpm'),
      cpc: calculateMean(items, 'cpc'),
      ctr: calculateMean(items, 'ctr'),
    };
    // add the facebook-specific metrics if the platform is facebook
    return platform === 'facebook'
      ? {
        ...baseObj,
        cpa: calculateMean(items, 'cpa'),
        roas: calculateMean(items, 'roas'),
      }
      : baseObj;
  });

  return result;
};

export const findMetricIndex = (metric, metricsObj) => Object.keys(metricsObj).indexOf(metric);

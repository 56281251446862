import React, { useEffect, useContext, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Sidebar, Icon } from 'semantic-ui-react';
import ReactDom from 'react-dom';
import styled from 'styled-components';
import { DimmerContext } from '../../context/DimmerContext';

const StyledSidebar = styled(Sidebar)`
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  background-color: #fff;
  color: #000;
  overflow-x: hidden;
  min-width: 500px;
`;

// TODO: Refactor child to rerender automatically when drawer open and close
// TODO: Drawer component to accept react-dates create portal
const Drawer = ({ children, openDrawer, onDrawerClose, header }) => {
  const { setDimmed } = useContext(DimmerContext);

  useEffect(() => {
    const close = e => {
      if (e.key === 'Escape') {
        onDrawerClose();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  useEffect(() => {
    openDrawer ? setDimmed(true) : setDimmed(false);
  }, [openDrawer]);

  return ReactDom.createPortal(
    <StyledSidebar
      animation="overlay"
      onHide={() => onDrawerClose()}
      visible={openDrawer}
      direction="right"
    >
      <div style={{ textAlign: 'right', marginBottom: 10, marginRight: -12 }}>
        <Icon name="close" size="large" color="grey" onClick={onDrawerClose} link />
      </div>

      { header && <h2 style={{ marginTop: 0 }}>{header}</h2> }

      <div style={{ flexGrow: 1, overflowY: 'auto' }}>
        { cloneElement(children, { openDrawer, onDrawerClose }) }
      </div>

    </StyledSidebar>,
    document.getElementById('v3-custom-sidebar'),
  );
};

Drawer.propTypes = {
  openDrawer: PropTypes.func.isRequired,
  onDrawerClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  header: PropTypes.string,
};
export default Drawer;

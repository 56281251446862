import React, { useState, useEffect } from 'react';
import { Table, Checkbox, Icon, Button, Label } from 'semantic-ui-react';
import { IndexGridNew } from '@rockerbox/styleguide';
import { useGlobalState } from '../../hooks/global';
import { getExportRequestsForAdvertiser, getExportRequestStatus } from '../../api/datalakeAdhocExports';
import { Drawer, BasicViewHeader, ViewAccessWrapper } from '../../components';
import DrawerFlashing from './DrawerFlashing/index';
import { AdhocExportsForm, ExportDetails } from '../../components/AdhocExports';
import { track } from '../../../utils/tracking';

export const ReportType = () => (
  <Table.Cell>
    Attributed Performance Report
  </Table.Cell>
);

export const SegmentName = segments => ({ item }) => {
  const segment = segments?.find((({ identifier }) => identifier === item?.request_body.report_request?.identifier))?.action_name;

  return (
    <Table.Cell>
      <p>{segment}</p>
    </Table.Cell>
  );
};

export const DateRange = ({ item }) => (
  <Table.Cell>
    {item.request_body.report_request.start_date}
    {' '}
    -
    {item.request_body.report_request.end_date}
  </Table.Cell>
);

export const Status = ({ item }) => {
  const statusMapping = {
    SUCCESS: {
      color: 'green',
      icon: 'checkmark box',
      display: 'Ready',
    },
    ERROR: {
      color: 'red',
      icon: 'exclamation triangle box',
      display: 'Error',
    },
    EXPIRED: {
      color: 'red',
      icon: 'calendar times',
      display: 'Expired',
    },
    IN_PROGRESS: {
      color: 'teal',
      icon: 'refresh times',
      display: 'In Progress',
    },
    EMPTY: {
      color: 'gray',
      icon: 'dont',
      display: 'No Data',
    },
  };

  const statusColor = statusMapping[item.status]?.color;
  const statusIcon = statusMapping[item.status]?.icon;
  const statusDisplay = statusMapping[item.status]?.display;

  return (
    <Table.Cell>
      <Icon style={{ marginLeft: 8 }} name={statusIcon} color={statusColor} size="large" />
      {' '}
      {statusDisplay}
    </Table.Cell>
  );
};

export const AdditionalButtons = handleExportDetailOpen => ({ item }) => {
  const isExpired = !item.expiration_status || item.expiration_status === 'EXPIRED';

  const handleDownloadTracking = () => {
    const trackingMetaData = {
      pixel_source_name: item.pixel_source_name,
      report_request_id: item.id,
    };

    track('data.exports.ad_hoc_exports.download', trackingMetaData);
  };

  return (
    <Table.Cell>
      <Button icon inverted size="large" disabled={!!isExpired}>
        <a href={item.presigned_url_filtered} download onClick={handleDownloadTracking} target="_blank" rel="noreferrer">
          <Icon name="download" />
        </a>
      </Button>
      <Button icon inverted size="large" onClick={() => handleExportDetailOpen(item)}>
        <Icon name="search" color="purple" />
      </Button>
    </Table.Cell>
  );
};

const AdhocExportsHistory = () => {
  const { user, segments, account } = useGlobalState();
  const [exportsHistory, setExportsHistory] = useState([]);
  const [exportsDisplay, setExportsDisplay] = useState([]);
  const [checked, setChecked] = useState(false);

  const [exportDetailsOpen, setExportDetailsOpen] = useState(false);
  const [adhocExportsFormOpen, setAdhocExportsFormOpen] = useState(false);
  const [exportDetailInfo, setExportDetailInfo] = useState({});

  const refreshExportRequestsList = () => {
    getExportRequestsForAdvertiser()
      .then(data => {
        setExportsHistory(data);
        setExportsDisplay(data);
      })
      .catch(err => console.log(err));
  };

  const handleExportRequestClose = (refresh = false) => {
    setAdhocExportsFormOpen(false);
    if (refresh) {
      refreshExportRequestsList();
    }
  };

  useEffect(() => {
    refreshExportRequestsList();
  }, []);

  useEffect(() => {
    const inProgressExports = exportsHistory.filter(exportRecord => exportRecord.status === 'IN_PROGRESS');

    if (inProgressExports && inProgressExports.length) {
      const timeoutId = setTimeout(() => {
        inProgressExports.forEach(request => {
          getExportRequestStatus(request.id)
            .catch(err => console.log(err))
            .finally(() => {
              refreshExportRequestsList();
            });
        });
      }, 10000);

      return () => clearTimeout(timeoutId);
    }
  }, [exportsHistory]);

  const handleExportDetailOpen = itemInfo => {
    setExportDetailInfo(itemInfo);
    setExportDetailsOpen(true);
  };

  const filterDatasets = () => {
    const filteredExports = exportsHistory.filter(arr => arr.requester === user.email);
    // if (checked) {
    //   setExportsDisplay(exportsHistory);
    // } else {
    //   setExportsDisplay(filteredExports);
    // }
    checked ? setExportsDisplay(exportsHistory) : setExportsDisplay(filteredExports);
    setChecked(!checked);
  };

  const tableColumns = [
    { display: 'Export Name', key: 'report_name', isSearchable: true },
    { display: 'Status', key: '', as: Status },
    { display: 'Report Type', key: 'report_type', as: ReportType },
    { display: 'Conversion', key: '', as: SegmentName(segments) },
    { display: 'Date Range', key: '', as: DateRange },
    { display: 'Requested At', key: 'created_at' },
    { display: 'Requested By', key: 'requester', isSearchable: true },
    { display: '', key: '', as: AdditionalButtons(handleExportDetailOpen) },
  ];

  const trackingMetaData = {
    pixel_source_name: account.pixel_source_name,
  };

  useEffect(() => {
    track('data.exports.ad_hoc_exports.view', trackingMetaData);
  }, []);

  return (
    <>
      <BasicViewHeader
        header={(
          <>
            Ad Hoc Exports
            <Label
              size="mini"
              color="green"
              content="beta"
            />
          </>
        )}
        subheader="Run ad hoc exports of your Rockerbox data as spreadsheets"
        topRight={(
          <Button primary onClick={() => setAdhocExportsFormOpen(true)}>
            New Export
          </Button>
        )}
      />
      <IndexGridNew
        topRight={(
          <Checkbox
            inverted
            color="purple"
            label="Show only my exports"
            className="header-checkbox"
            checked={checked}
            onChange={() => filterDatasets()}
          />
        )}
        celled
        data={exportsDisplay}
        cols={tableColumns}
        searchable
        fallBackMsg="No Exports Found"
      />
      <Drawer openDrawer={exportDetailsOpen} onDrawerClose={() => setExportDetailsOpen(false)}>
        <ExportDetails itemInfo={exportDetailInfo} />
      </Drawer>
      <DrawerFlashing openDrawer={adhocExportsFormOpen} onDrawerClose={() => setAdhocExportsFormOpen(false)}>
        <AdhocExportsForm onFormClose={handleExportRequestClose} open={adhocExportsFormOpen} />
      </DrawerFlashing>
    </>
  );
};

const AdhocExportsAccess = () => (
  <ViewAccessWrapper
    viewName="ad_hoc_exports"
    viewComponent={<AdhocExportsHistory />}
  />
);

export default AdhocExportsAccess;

import React, { useState, useEffect } from 'react';
import shallow from 'zustand/shallow';
import { Message, Segment, Modal, Button, Form, Input } from 'semantic-ui-react';
import { SelectCheckbox } from '@rockerbox/styleguide';
import { updateEntityProgram, postEntityProgram } from '../../../api/attribution';
import { SetupDisplaySelector } from '../ManualSetup/SetupDisplay';
import { entityStore } from '../hooks/entityStore';

const CreateEntityProgram = ({
  programOptions, onClose, onSubmit, open, id, hasLegacySpend,
  entityType: entityTypeFromProps,
  description: descriptionFromProps,
  fixedCost: fixedCostFromProps,
  percentCost: percentCostFromProps,
  shouldHaveSponsorshipCost: shouldHaveSponsorshipCostFromProps,
  display: displayFromProps,
}) => {
  const [set] = entityStore(({ set }) => [set], shallow); // eslint-disable-line no-shadow

  const [entityType, setEntityType] = useState(entityTypeFromProps || '');
  const [description, setDescription] = useState(descriptionFromProps || false);
  const [fixedCost, setFixedCost] = useState(fixedCostFromProps || false);
  const [percentCost, setPercentCost] = useState(percentCostFromProps || false);
  const [shouldHaveSponsorshipCost, setShouldHaveSponsorshipCost] = useState(shouldHaveSponsorshipCostFromProps || false);
  const [display, setDisplay] = useState(displayFromProps || false);
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState(false);

  const resetForm = () => {
    setEntityType('');
    setDescription(false);
    setFixedCost(false);
    setPercentCost(false);
    setShouldHaveSponsorshipCost(false);
    setDisplay(false);
  };

  const saveChanges = () => {
    const entity_type = entityType;
    const entity_description = description || entity_type;
    const fixed_unit_cost = fixedCost;
    const rev_share_percent = percentCost;
    const has_campaign_costs = shouldHaveSponsorshipCost;

    const postObj = { entity_type, description: entity_description, fixed_unit_cost, rev_share_percent, has_campaign_costs, ...display };

    const send = id ? updateEntityProgram : postEntityProgram;

    setLoading(true);
    send(postObj, id)
      .then(data => {
        set({ entityProgramConfigs: data.response });
        if (!!onSubmit) onSubmit({ value: postObj });
        if (!!onClose) onClose();
        setLoading(false);
        resetForm();
      });
  };

  useEffect(() => {
    const foundValue = id
      ? programOptions.find(row => (row.text === entityType && row.text !== entityTypeFromProps))
      : programOptions.find(row => row.text === entityType);
    if (!foundValue) {
      setNameError(false);
    } else {
      setNameError({
        content: 'Sponsorship Program Name already exists. Please enter unique sponsorship name.',
        pointing: 'below',
      });
    }
  }, [entityType]);

  const FixedPayout = (
    <Segment>
      <Form.Field>
        <label>Amount paid to sponsor per purchase</label>
        <Input
          label="USD $"
          value={fixedCost || ''}
          placeholder=""
          onChange={(evt, { value }) => setFixedCost(value)}
        />
      </Form.Field>
    </Segment>
  );

  const PercentPayout = (
    <Segment>
      <Form.Field>
        <label>Percent of revenue paid to sponsor per purchase</label>
        <Input
          label="%"
          labelPosition="right"
          value={percentCost || ''}
          placeholder=""
          onChange={(evt, { value }) => setPercentCost(value)}
        />
      </Form.Field>
    </Segment>
  );

  const SponsorshipPayment = (
    <Message>
      You will be asked to enter this information for each sponsor that is added to the program to account for the appropriate costs
    </Message>
  );

  const paymentValues = [];
  if (fixedCost) paymentValues.push('fixed_payout');
  if (percentCost) paymentValues.push('percent_payout');
  if (shouldHaveSponsorshipCost) paymentValues.push('sponsorship');

  const PAYMENT_TERMS = [
    { text: 'A fixed amount per purchase', value: 'fixed_payout', ShowSelected: FixedPayout },
    { text: 'A percent of revenue driven', value: 'percent_payout', ShowSelected: PercentPayout },
    { text: 'A fee for a sponsorship / campaign run', value: 'sponsorship', ShowSelected: SponsorshipPayment },
  ];

  const updateSponsorshipCosts = (evt, { value }) => (value.includes('sponsorship')
    ? setShouldHaveSponsorshipCost(true)
    : setShouldHaveSponsorshipCost(false));

  return (
    <Modal {...{ onClose, open }}>
      <Modal.Header>
        { id ? 'Edit' : 'Create'}
        {' '}
        Sponsorship Program
      </Modal.Header>
      <Modal.Content>
        { !id && (
        <Message
          active
          header="What is a sponsorship program?"
          content="A program is a set of rules that define how promo codes are respresented in your attribution data."
        />
        )}
        <Form>
          <Form.Input
            label="Program Name"
            value={entityType}
            placeholder="ex: Influencer, Podcast, Site Sponsorship"
            onChange={(evt, { value }) => {
              setEntityType(value);
            }}
            error={nameError}
          />
          <Form.Field>
            <label>Description</label>
            <Form.Input
              value={description !== '0' ? description || '' : ''}
              placeholder="Brief description about the program for you to reference"
              onChange={(evt, { value }) => setDescription(value)}
            />
          </Form.Field>
          {!!hasLegacySpend && (
          <>
            <h5>Payment Structure</h5>
            <Segment secondary>
              <SelectCheckbox
                disabled={!!id}
                showBorder={false}
                value={paymentValues}
                description="Participants in this program are paid:"
                multiple
                options={PAYMENT_TERMS}
                onChange={updateSponsorshipCosts}
              />
            </Segment>
          </>
          )}
          <h5>Reporting Structure</h5>
          <Segment secondary>
            <div style={{ fontWeight: 'bold', paddingBottom: '10px' }}>
              Setup the standard structure underwhich you want marketing associated with this program to appear:
            </div>
            <div style={{ padding: '10px' }}>
              <SetupDisplaySelector value={display} onChange={(evt, { value }) => setDisplay(value)} />
            </div>
          </Segment>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={saveChanges} type="submit" primary disabled={nameError} {...{ loading }}>Save</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CreateEntityProgram;

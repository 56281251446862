import React from 'react';
import { BasicViewHeader, ViewAccessWrapper } from '../../components';
import Steps from './Steps/index';

const PromoCodes = () => (
  <>
    <BasicViewHeader
      header="Promo Codes"
      subheader="Associate your promo codes with marketing efforts to understand their impact on your business"
    />
    <br />
    <Steps />
  </>
);

const PromoCodesWrapper = () => (
  <ViewAccessWrapper
    viewName="promo_codes"
    viewComponent={<PromoCodes />}
  />
);

export default PromoCodesWrapper;

export const isValidJSONArray = value => {
  try {
    const parsedValue = JSON.parse(value);
    return Array.isArray(parsedValue);
  } catch (error) {
    return false;
  }
};

export const isDecimal = value => ArrayBuffer.isView(value);

export const isBigInt = value => Object.prototype.toString.call(value).slice(8, -1) === 'BigInt';

export const isDate = value => {
  if (typeof value !== 'object' || ArrayBuffer.isView(value)) {
    return false;
  }
  const timestamp = Date.parse(value);
  return !Number.isNaN(timestamp);
};

export const checkUrlParamValue = param => !(param === 0 || param === undefined);

import React, { useState } from 'react';
import { Table, Input, Popup } from 'semantic-ui-react';

const SliderCell = (onModifierChange, weights, setInputError) => ({ item, inputError, focusedCell, setFocusedCell }) => {
  const weight = weights?.find(c => c.tier_1 === item.tier_1) || {};
  const { modifier } = weight;
  const { tier_1 } = item;
  const [value, setValue] = useState(modifier);
  const [validateErr, setValidateErr] = useState(false);

  const handleSliderMouseUp = e => {
    onModifierChange(e.target.value, tier_1);
  };

  const handleInputOnChange = inputValue => {
    const prepped = (inputValue.slice(-1)[0] === '.') ? `${inputValue}0` : inputValue;

    if (prepped !== inputValue || inputValue === '') {
      setValue(String(inputValue));
      return;
    }

    const number = parseFloat(prepped);
    const isValid = !Number.isNaN(number) && number >= 0 && number <= 10 && (parseInt(number * 100) === Math.floor(number * 100)) && (prepped.length < 5);

    if (isValid) {
      setValidateErr(false);
      setInputError(false);
      onModifierChange(inputValue, tier_1);
    } else {
      setInputError(true);
      setValidateErr(true);
    }
  };

  return (
    <Table.Cell style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRight: 'none', borderLeft: 'none', borderBottom: 'none' }}>
      <div style={{ marginRight: 10, marginLeft: 10 }}>
        0x
      </div>
      <input
        type="range"
        min={0}
        max={10}
        step={0.25}
        value={value}
        onChange={e => setValue(e.target.value)}
        onMouseUp={e => handleSliderMouseUp(e)}
      />
      <div style={{ marginLeft: 10 }}>
        10x
      </div>
      <div style={{ width: 65, marginRight: 10, marginLeft: 10 }}>
        <Popup
          open={validateErr}
          trigger={(
            <Input
              autoFocus={focusedCell === item.tier_1}
              type="text"
              value={value}
              onChange={(e, { value }) => { // eslint-disable-line no-shadow
                handleInputOnChange(value);
                setFocusedCell(item.tier_1);
              }}
              style={{ textAlign: 'center' }}
              fluid
              disabled={inputError && !validateErr}
            />
          )}
          position="right center"
        >
          <Popup.Content>
            <ul style={{ margin: 0, paddingLeft: 10 }}>
              <li>max value 10</li>
              <li>max decimal places 2</li>
            </ul>
          </Popup.Content>
        </Popup>
      </div>
    </Table.Cell>
  );
};

export default SliderCell;

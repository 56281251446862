import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { FilterWrapper, RollupFilter, DateRangeHooks, StyledDropdown, SaveReportButton, ResetFilterButton } from '../../../components';
import useAuthedIntegrations from '../../../hooks/useAuthedIntegrations';
import { channelPerformancePlatformsConfig } from '../../../constants/mappings';

const Filter = ({
  platform, loading, platformPerformanceData, platformConfig, // data
  startDate, endDate, onDateChange, firstReportingDate, // dates
  segOptions, segId, updateSegmentAndModel, setComparisonDrawerOpen, // conversion
  modelOptions, model, setModel, // model
  attributionWindowOptions, windowValue, updateAttributionWindow, // attribution window
}) => {
  const platformSelection = useAuthedIntegrations(channelPerformancePlatformsConfig);
  const { onDropdownChange, dropdownOptions } = platformSelection;

  const platformDisplayName = platformConfig?.display_platform_name || 'Platform';

  return (
    <FilterWrapper
      {...{ loading, collapseKey: platform }}
      other={(
        <>
          <StyledDropdown
            label="Ad Platform"
            value={platform}
            options={dropdownOptions}
            onChange={onDropdownChange}
          />
          <StyledDropdown
            label="Conversion" // do we need to add Platform + Conversion Metrics?
            placeholder="Rockerbox Conversion"
            options={segOptions}
            value={parseInt(segId)}
            advancedDropdown={(
              <Dropdown.Menu>
                {
                segOptions.map(c => (
                  <Dropdown.Item
                    text={c.text}
                    key={c.value}
                    value={c.value}
                    active={c.value === segId}
                    onClick={(e, { value }) => updateSegmentAndModel(value)}
                  />
                ))
              }
                <Dropdown.Divider />
                <Dropdown.Item icon="edit" text="Edit Comparisons" onClick={() => setComparisonDrawerOpen(true)} />
              </Dropdown.Menu>
            )}
          />
          <StyledDropdown
            label="Rockerbox Attribution"
            placeholder="Rockerbox Attribution"
            options={modelOptions}
            value={model}
            onChange={(e, { value }) => setModel(value)}
          />
          <StyledDropdown
            label={`${platformDisplayName} Attribution`}
            placeholder="Select Attribution Window"
            options={attributionWindowOptions}
            value={windowValue}
            onChange={(e, { value }) => updateAttributionWindow(value)}
            disabled={!attributionWindowOptions?.length}
          />
        </>
      )}
      date={<DateRangeHooks {...{ startDate, endDate, onDateChange, firstReportingDate }} />}
      stickyRight={(
        <>
          <SaveReportButton
            {...{ loading }}
            options={{
              'Platform Attribution': attributionWindowOptions,
            }}
          />
          <ResetFilterButton />
        </>
      )}
      advancedFilters={!!platform && platform !== 0 && (
      <RollupFilter
        rawData={platformPerformanceData}
        rollupHeadingLabel="Marketing Account Objects"
      />
      )}
    />
  );
};

export default Filter;

import React, { useState, useEffect, useMemo } from 'react';

import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';

import ChartTooltip from '../ChartTooltip';
import { defaultChartDimensions as defaults } from '../constants';
import { formatXAxisTickLabel, toolTipLabelFormatter, getMaxValueWithoutInfinity, findKeysWithInfinityValues } from '../helpers';
import { sortGoalData } from '../hooks/sortData';
import { GroupByDropdown } from '../parts';

const GoalLineChart = ({
  data, goal, setGoal, tierColorMap,
  groupDatesToggle = false, groupDatesBy, setGroupDatesBy,
  defaultGroupBy, // add this prop to sum up data rollups
  height = 200, showTooltip, formatter, dot = false, showXAxis, YAxisStyle = [], goalAxisStyle, domainDefault = false,
  nameMap = {}, currencyOverride, tooltipComponent,
  dottedLineForInfinity = false,
}) => {
  const { renderData } = sortGoalData(data, groupDatesBy, defaultGroupBy, goal, setGoal);
  const [filteredData, setFilteredData] = useState(renderData);
  const toolTipValueFormatter = formatter?.format ? formatter.format
    : formatter || (v => v);
  const CTooltip = tooltipComponent || ChartTooltip;
  const lineType = dottedLineForInfinity ? 'linear' : 'monotone';

  const maxWithoutInfinityLeft = useMemo(() => {
    const leftKey = YAxisStyle.filter((yaxis => yaxis.side !== 'Right')).map(({ keyName }) => keyName);
    return getMaxValueWithoutInfinity(renderData, leftKey);
  }, [renderData]);

  const maxWithoutInfinityRight = useMemo(() => {
    const rightKey = YAxisStyle.filter((yaxis => yaxis.side === 'Right')).map(({ keyName }) => keyName);
    return getMaxValueWithoutInfinity(renderData, rightKey);
  }, [renderData]);

  const keysToRender = useMemo(() => {
    if (!renderData.length) return [];
    if (YAxisStyle.length) {
      return YAxisStyle.map(k => k.keyName);
    }
    return Object.keys(renderData[0] || {}).filter(k => k !== 'date');
  }, [YAxisStyle]);

  const keysDottedToRender = useMemo(() => {
    if (!dottedLineForInfinity) return [];
    return findKeysWithInfinityValues(renderData).filter(key => keysToRender.includes(key));
  }, [keysToRender]);

  useEffect(() => {
    if (!dottedLineForInfinity) {
      setFilteredData(renderData);
      return;
    }
    const keysWithInfinityValues = findKeysWithInfinityValues(renderData);
    const dataReplaced = renderData.map(entry => {
      const replaceInfinityValuesWithNull = keysWithInfinityValues.reduce((acc, curr) => ({
        [`${curr}`]: Number.isFinite(entry[curr]) ? entry[curr] : null,
        [`${curr}_dotted`]: Number.isFinite(entry[curr]) ? entry[curr] : null,
        ...acc,
      }), {});
      return {
        ...entry,
        ...replaceInfinityValuesWithNull,
      };
    });
    setFilteredData(dataReplaced);
  }, [renderData]);

  const findColor = key => {
    if (YAxisStyle.length) {
      const findObj = YAxisStyle.find(entry => entry.keyName === key);
      return findObj ? findObj.color : '#026CAC';
    }
    return tierColorMap[key];
  };

  const findYaxisSide = key => {
    if (YAxisStyle.length) {
      const findObj = YAxisStyle.find(entry => entry.keyName === key);
      return findObj && findObj.side ? findObj.side : 'Left';
    }
    return 'Left';
  };

  return (
    <>
      {groupDatesToggle && <GroupByDropdown {...{ groupDatesBy, setGroupDatesBy }} />}
      <ResponsiveContainer width="100%" height={height || defaults.height}>
        <LineChart data={filteredData}>
          <XAxis
            xAxisId="0"
            hide={!showXAxis}
            dataKey="date"
            tickFormatter={(date, i) => formatXAxisTickLabel(date, i, renderData)}
            tickLine={false}
            interval={0}
            height={12}
            padding={{ left: 20, right: 20 }}
          />
          <YAxis
            hide
            domain={[0, domainDefault || maxWithoutInfinityLeft || 'dataMax']}
            yAxisId="Left"
          />
          <YAxis
            hide
            domain={[0, domainDefault || maxWithoutInfinityRight || 'dataMax']}
            yAxisId="Right"
          />
          {keysDottedToRender
            .map(k => {
              const color = findColor(k);
              const yAxisSide = findYaxisSide(k);
              return (
                <Line
                  key={`${k}_dotted`}
                  dataKey={`${k}_dotted`}
                  dot={dot}
                  fill={color}
                  stroke={color}
                  type={lineType}
                  yAxisId={yAxisSide}
                  strokeWidth={2}
                  strokeDasharray="3 3"
                  connectNulls
                />
              );
            })}
          {keysToRender
            .map((k, i) => {
              const color = YAxisStyle.length ? (YAxisStyle[i].color || '#026CAC') : tierColorMap[k];
              const yAxisSide = YAxisStyle.length ? (YAxisStyle[i].side || 'Left') : 'Left';
              return (
                <Line
                  key={k}
                  dataKey={k}
                  dot={dot}
                  fill={color}
                  stroke={color}
                  type={lineType}
                  yAxisId={yAxisSide}
                  strokeWidth={2}
                  connectNulls={!dottedLineForInfinity}
                />
              );
            })}
          {goalAxisStyle.map((goalObj, i) => {
            const color = goalAxisStyle[i].color || '#026CAC';
            const yAxisSide = goalAxisStyle[i].side || 'Left';
            const k = goalObj.keyName;
            return (
              <Line
                key={k}
                dataKey={k}
                dot={dot}
                fill={color}
                stroke={color}
                type={lineType}
                yAxisId={yAxisSide}
                strokeWidth={2}
                connectNulls={!dottedLineForInfinity}
              />
            );
          })}
          {showTooltip
            && (
            <Tooltip
              cursor={{
                stroke: '#efefef',
                strokeWidth: 2,
                strokeDasharray: '5 5',
              }}
              position={{ y: 0 }}
              labelFormatter={v => toolTipLabelFormatter(v, renderData)}
              formatter={toolTipValueFormatter}
              content={<CTooltip {...{ nameMap, currencyOverride, formatter, goal }} />}
              wrapperStyle={{ zIndex: 3 }}
            />
            )}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default GoalLineChart;

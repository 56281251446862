/* eslint no-param-reassign: 0 */

import { useMemo } from 'react';

const arqZeroType = (arch, column) => {
  const columnData = arch._data[column]?.data;
  const first = columnData ? columnData[0] : '';

  if (typeof (first) === 'bigint') return 'n';
  return '';
};

const arqBuildImputeObject = (table, column) => {
  const zero = arqZeroType(table, column);
  return { [column]: `() => 0${zero}` };
};

export const cleanData = (table, hasNTF, hasModel, hasSpend) => {
  if (table.columnNames().includes('even')) {
    table = table.impute(arqBuildImputeObject(table, 'even'));
    table = table.impute(arqBuildImputeObject(table, 'first_touch'));
    table = table.impute(arqBuildImputeObject(table, 'last_touch'));
    table = table.impute(arqBuildImputeObject(table, 'revenue_even'));
    table = table.impute(arqBuildImputeObject(table, 'revenue_first_touch'));
    table = table.impute(arqBuildImputeObject(table, 'revenue_last_touch'));
  }

  if (hasSpend) {
    table = table.impute(arqBuildImputeObject(table, 'spend'));
  }

  if (hasNTF) {
    table = table.impute(arqBuildImputeObject(table, 'ntf_even'));
    table = table.impute(arqBuildImputeObject(table, 'ntf_first_touch'));
    table = table.impute(arqBuildImputeObject(table, 'ntf_last_touch'));
    table = table.impute(arqBuildImputeObject(table, 'ntf_revenue_even'));
    table = table.impute(arqBuildImputeObject(table, 'ntf_revenue_first_touch'));
    table = table.impute(arqBuildImputeObject(table, 'ntf_revenue_last_touch'));
  }

  if (hasModel) {
    table = table.impute(arqBuildImputeObject(table, 'normalized'));
    table = table.impute(arqBuildImputeObject(table, 'revenue_normalized'));
  }

  if (hasNTF && hasModel) {
    table = table.impute(arqBuildImputeObject(table, 'ntf_normalized'));
    table = table.impute(arqBuildImputeObject(table, 'ntf_revenue_normalized'));
  }

  return table;
};

export const useMemoWithLog = (func, watching) => useMemo((...args) => {
  // console.log("here", watching, String(func))
  // console.time("func")
  const res = func(...args);
  // console.timeEnd("func")
  return res;
}, watching);

export const s = arr => JSON.stringify(arr);

export const applyModifiersArquero = (table, weights, convKey, revKey, showOriginalCols = false, tableCalc = false) => {
  let updatedWeights;

  if (tableCalc) {
    const allTier1 = table.objects().map(c => c.tier_1);
    const remainingModifiers = allTier1.reduce((accu, currentValue) => {
      if (!weights.some(obj => obj.tier_1 === currentValue)) {
        accu.push({ modifier: 1, tier_1: currentValue });
      }
      return accu;
    }, []);

    updatedWeights = [...weights, ...remainingModifiers];
  } else {
    updatedWeights = weights;
  }

  const hasSpend = table.columnNames().includes('spend');
  const hasRevKey = table.columnNames().includes(revKey);
  const hasConvKey = table.columnNames().includes(convKey);

  if (!hasRevKey || !hasConvKey) {
    return table;
  }

  const weightsObj = updatedWeights.reduce((accu, row) => {
    accu[row.tier_1] = row.modifier;
    return accu;
  }, {});

  const deriveMultiplied = {
    multipliedConversions: `(d, $) => $.weightsObj[d['tier_1']] * d['${convKey}'] `,
    multipliedRevenue: `(d, $) => $.weightsObj[d['tier_1']] * d['${revKey}'] `,
  };

  const rollupSummary = {
    multipliedConversions: 'd => op.sum(d[\'multipliedConversions\'])',
    multipliedRevenue: 'd => op.sum(d[\'multipliedRevenue\'])',
    [convKey]: `d => op.sum(d['${convKey}'])`,
    [revKey]: `d => op.sum(d['${revKey}'])`,
  };

  const calculatedColumns = hasSpend ? {
    cpa: `d => d['spend']/d['${convKey}']`,
    roas: `d => d['${revKey}']/d['spend']`,
  }
    : {};

  if (hasSpend) {
    rollupSummary.spend = 'd => op.sum(d[\'spend\'])';
  }

  const originalCalculatedCols = (showOriginalCols && hasSpend) ? {
    originalCpa: 'd => d[\'spend\']/d[\'originalConversions\']',
    originalRoas: 'd => d[\'originalRevenue\']/d[\'spend\']',
  } : {};

  const allCalculatedCols = Object.assign(calculatedColumns, originalCalculatedCols);

  const derive = {
    normalizedConversions: `(d, $) => d['multipliedConversions'] * $.totalObj['${convKey}'] / $.totalObj['multipliedConversions']`,
    normalizedRevenue: `(d, $) => d['multipliedRevenue'] * $.totalObj['${revKey}'] / $.totalObj['multipliedRevenue']`,
  };

  const renameKeys = {
    normalizedConversions: convKey,
    normalizedRevenue: revKey,
  };

  const originalCols = showOriginalCols ? {
    [convKey]: 'originalConversions',
    [revKey]: 'originalRevenue',
  } : {};

  const rename = Object.assign(renameKeys, originalCols);

  const multiplied = (hasRevKey && hasConvKey) ? table
    .params({ weightsObj })
    .derive(deriveMultiplied)
    : table;

  const [totalObj] = (hasRevKey && hasConvKey) ? multiplied
    .rollup(rollupSummary)
    : multiplied;

  const results = multiplied
    .params({ totalObj })
    .derive(derive)
    .rename(rename)
    .derive(allCalculatedCols);

  return results;
};

import * as aq from 'arquero';

import { TIERS, DEFAULT_TIERS } from '../../constants/tiers';

export const getTotals = table => {
/**
 * Calculates the sum of numeric values in each column of a given table.
 *
 * @param {object} table - The table object containing the data.
 * @returns {object} - Table containing the totals for each column of the table.
 */
  aq.addFunction('number', Number);
  const columns = table.columns();
  const numericColumns = Object.keys(columns)
    .filter(col => typeof columns[col].data[0] === 'number')
    .reduce((accu, col) => {
      accu[col] = `d => op.sum(op.number(d['${col}']))`;
      return accu;
    }, {});
  return table.rollup(numericColumns);
};

export const columnNamesMapped = (columns, addColumns) => {
  /**
   * Creates a mapping object that maps column names to their corresponding display names.
   * Handles the addition of suffixes and dates to the column names.
   *
   * @param {Array.<Object>} columns - An array of objects representing the columns.
   * Each object should have a `key` property representing the column key and a `display` property representing the column display name.
   * @param {Object} addColumns - An object containing additional configuration options for adding suffixes and dates to the column names.
   * It can have the following properties:
   *   - `columnKeyName` (string): The key name to use when looking for additional column keys in the `columns` array.
   *   - `appendSuffix` (string): The suffix to append to the column names.
   *   - `appendDateToAddedCols` (string): The date to append to the column names that have the suffix added.
   *   - `appendDate` (string): The date to append to the column names that do not have the suffix added.
   *
   * @returns {Object} - A mapping object where the keys are the column names and the values are the corresponding display names.
   */
  const mapping = columns.reduce((accu, col) => {
    const includesPercent = col.display.includes('%');
    if (addColumns.columnKeyName && col[addColumns.columnKeyName]) {
      const addColumnsKey = addColumns.columnKeyName;
      const suffix = addColumns.appendSuffix;
      const addKey = col[addColumnsKey];
      if (includesPercent) {
        accu[`${col.key}_percent${suffix}`] = `${col.display}${suffix}`;
      } else {
        accu[addKey] = `${col.display}${suffix}`;
      }
    }
    if (includesPercent) {
      accu[`${col.key}_percent`] = col.display;
      return accu;
    }
    accu[col.key] = col.display;
    return accu;
  }, {});

  if (addColumns.appendDateToAddedCols) {
    const suffix = addColumns.appendSuffix;
    Object.keys(mapping).forEach(key => {
      if (mapping[key].includes(suffix)) {
        mapping[key] = `${mapping[key]} ${addColumns.appendDateToAddedCols}`;
      }
    });
  }

  if (addColumns.appendDate) {
    const suffix = addColumns.appendSuffix;
    Object.keys(mapping).forEach(key => {
      if (!mapping[key].includes(suffix)) {
        mapping[key] = `${mapping[key]} ${addColumns.appendDate}`;
      }
    });
  }

  return mapping;
};

/**
 * Returns an object containing derived column calculations based on the input columns and table totals.
 *
 * @param {Array.<Object>} columns - An array of objects representing the columns. Each object has a `key` property that represents the column key, an `arqueroCalc`
 * property that contains the column calculation, and a `display` property that represents the column display format.
 * @param {Object} tableTotals - An object that contains the total values for each column.
 * @param {string} suffix - A string that represents the suffix to be appended to the derived column keys.
 * @returns {Object} - An object that contains the derived columns and their corresponding calculations.
 * The keys of the object are the derived column keys, and the values are the calculations in string format.
 */
export const getDerivedColumns = (columns, tableTotals, suffix) => columns.reduce((accu, col) => {
  if (col.arqueroCalc) {
    accu[col.key] = col.arqueroCalc[col.key];
    if (suffix) {
      const addKey = `${col.key}${suffix}`;
      accu[addKey] = col.arqueroCalc[addKey];
    }
  }
  if (col.display.includes('%')) {
    const totalColValue = tableTotals[col.key];
    accu[`${col.key}_percent`] = `d => d['${col.key}'] / ${totalColValue} * 100`;
    if (suffix) {
      const totalAddColValue = tableTotals[`${col.key}${suffix}`];
      accu[`${col.key}_percent${suffix}`] = `d => d['${col.key}${suffix}'] / ${totalAddColValue} * 100`;
    }
  }
  return accu;
}, {});

/**
 * Generates derived columns for calculating deltas between two columns in a table.
 *
 * @param {boolean} addColumnDeltas - A flag indicating whether to add delta columns.
 * @param {Array} columnNames - An array of column names.
 * @param {string} addSuffix - A suffix to be added to the column names.
 * @returns {Object} - An object containing derived columns for calculating deltas between two columns in a table.
 *  The keys of the object are the delta column names, and the values are strings representing functions that calculate the deltas.
 */
export const getDerivedDeltaColumns = (addColumnDeltas, columnNames, addSuffix) => {
  if (!addColumnDeltas) return {};
  const columnsWithDeltas = columnNames
    .filter(col => col.includes(addSuffix))
    .map(col => [col.replace(addSuffix, ''), col]);

  const derivedColumns = columnsWithDeltas.reduce((accu, col) => {
    if (col[0].includes('_percent')) {
      const deltaColName = `${col[1]}_delta`;
      accu[deltaColName] = `d => d['${col[0]}'] - d['${col[1]}']`;
    } else {
      const deltaColName = `${col[1]}_delta_%`;
      accu[deltaColName] = `d => (d['${col[0]}'] - d['${col[1]}']) / d['${col[1]}'] * 100`;
    }
    return accu;
  }, {});
  return derivedColumns;
};

export const relocateDeltaColumns = (table, columns, suffix) => {
  /**
   * Rearranges the columns in a table by moving the delta columns to their respective positions based on a suffix.
   *
   * @param {object} table - The input table that needs to be rearranged.
   * @param {array} columns - The list of column names in the original order.
   * @param {string} suffix - The suffix used to identify the delta columns.
   * @returns {object} - A new table with the delta columns moved to their respective positions based on the suffix.
  */
  if (!suffix) return table;
  const allCols = table.columnNames();
  const deltaCols = allCols.filter(col => !columns.includes(col));
  const colsMapAfter = deltaCols.reduce((accu, col) => {
    const [mapTo] = col.split(suffix);
    accu[col] = mapTo;
    return accu;
  }, {});
  let tableReoder = table;
  deltaCols.forEach(col => {
    tableReoder = tableReoder.relocate(col, { after: colsMapAfter[col] });
  });
  return tableReoder;
};

/**
 * Generates a mapping of column names to their corresponding display names for delta columns.
 * @param {Array.<Object>} columns - An array of objects representing the columns, where each object has a `key` and `display` property.
 * @param {Array} deltaColumns - An array of strings representing the delta columns.
 * @param {Object} addColumns - An object with `addDeltas` and `appendSuffix` properties.
 * @returns {Object} - An object mapping delta column names to their corresponding display names.
 */
export const getDeltaDisplayMapping = (columns, deltaColumns, addColumns) => {
  if (!addColumns.addDeltas) return {};
  const suffix = addColumns.appendSuffix;
  const deltaDisplayMap = deltaColumns.reduce((accu, col) => {
    const [keyName, delta] = col.split(suffix);
    const deltaDisplay = delta === '_delta_%' ? 'Delta (%)' : 'Delta';
    if (keyName.includes('_percent')) {
      const findKey = keyName.replace('_percent', '');
      const displayName = columns.find(({ key }) => key === findKey).display;
      accu[col] = `% of ${displayName}${suffix} ${deltaDisplay}`;
    } else {
      const displayName = columns.find(({ key }) => key === keyName).display;
      accu[col] = `${displayName}${suffix} ${deltaDisplay}`;
    }
    return accu;
  }, {});
  return deltaDisplayMap;
};

/* eslint-disable quotes */
const replaceSpecialCharacters = columnNames => {
  if (!TIERS.some(tier => columnNames.includes(tier))) return {};
  return ({
    tier_1: `(d, $) => op.match(d.tier_1, /#/) ? op.replace(d.tier_1, /#/g, '') : d.tier_1`,
    tier_2: `(d, $) => op.match(d.tier_2, /#/) ? op.replace(d.tier_2, /#/g, '') : d.tier_2`,
    tier_3: `(d, $) => op.match(d.tier_3, /#/) ? op.replace(d.tier_3, /#/g, '') : d.tier_3`,
    tier_4: `(d, $) => op.match(d.tier_4, /#/) ? op.replace(d.tier_4, /#/g, '') : d.tier_4`,
    tier_5: `(d, $) => op.match(d.tier_5, /#/) ? op.replace(d.tier_5, /#/g, '') : d.tier_5`,
  });
};
/* eslint-enable quotes */

export const formatCSVData = (data, columns, addColumns, editBreakdowns) => {
  const aqData = aq.from(data);
  const columnsWithDisplayText = columns.map(col => {
    const displayTextCol = { ...col };
    if (col.displayText) {
      displayTextCol.display = col.displayText;
    }
    return displayTextCol;
  });
  const columnNamesMap = columnNamesMapped(columnsWithDisplayText, addColumns);
  const selectTierColumns = editBreakdowns && editBreakdowns.length ? editBreakdowns : TIERS;
  const columnNames = Object.keys(columnNamesMap).flatMap(col => (col === 'group' ? selectTierColumns : col));
  const tableTotals = getTotals(aqData).objects()[0];

  const addSuffix = addColumns ? addColumns.appendSuffix : false;
  const addColumnDeltas = addColumns ? addColumns.addDeltas : false;

  const deriveColumns = getDerivedColumns(columnsWithDisplayText, tableTotals, addSuffix);
  const deriveSpecialChars = replaceSpecialCharacters(columnNames);
  const deriveDeltas = getDerivedDeltaColumns(addColumnDeltas, columnNames, addSuffix);
  const deltaColumns = Object.keys(deriveDeltas);
  const derivedTable = aqData
    .derive(deriveColumns)
    .derive(deriveSpecialChars)
    .select(columnNames)
    .derive(deriveDeltas);

  const reorderTable = relocateDeltaColumns(derivedTable, columnNames, addSuffix);
  const deltaDisplayMap = getDeltaDisplayMapping(columnsWithDisplayText, deltaColumns, addColumns);
  const columnsRenameMap = reorderTable
    .columnNames()
    .reduce((accu, col) => {
      if (DEFAULT_TIERS[col]) {
        accu[col] = DEFAULT_TIERS[col];
        return accu;
      }
      accu[col] = columnNamesMap[col] ? columnNamesMap[col] : deltaDisplayMap[col] ? deltaDisplayMap[col] : col;
      return accu;
    }, {});

  return reorderTable.rename(columnsRenameMap).objects();
};

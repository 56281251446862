import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { ContentCard, IndexGrid, ExpandableIndexGrid } from '@rockerbox/styleguide'
import { withRouter } from 'react-router-dom';
import * as d3 from 'rockerbox_d3_legacy_clone';

import { AccountStats, AdGroupTable } from './GoogleAdsReportingParts.js';

import { getAdwordsReportingData, getGoogleAdsAccounts, getSegments } from '../../utils/api';
import { _timeRange, yesterday, week } from '../timerange';
import { _pushPath } from '../pushpath';

class GoogleAdsReportingView extends _pushPath(_timeRange(Component)){

  state = {
    account: undefined,
    stats: undefined,
    adsData: [],
    loading: false
  }


  pushToHistory(sdate, edate) {
    const { startDate, endDate } = this.props.match.params;
    const start_date = sdate || startDate || week;
    const end_date = edate || endDate || yesterday;

    this.updatePath({ startDate: start_date, endDate: end_date });
  }

  componentDidMount() {
    const { startDate, endDate } = this.props.match.params;
    this.setState({ loading: true });

    Promise.all([getSegments(), getGoogleAdsAccounts()]).then(([segments,accts]) => {
      const allPagesId = segments.find(s => s.action_type == 'segment').action_id;

      const accounts = accts.map(row => {
        row.text = row.account_name
        row.value = row.id
        return row
      });
      const account = accounts[0];

      this.setState({ account, accounts, allPagesId });
      if (startDate === undefined || endDate === undefined) {
        this.pushToHistory();
      } else {
        this.getAdsData(startDate, endDate);
      }
    })

  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate } = this.props.match.params;
    const start = prevProps.match.params.startDate !== startDate;
    const end = prevProps.match.params.endDate !== endDate;

    if ((start && startDate !== undefined) || (end && endDate !== undefined)) {
      return this.getAdsData(startDate, endDate)
    }
  }

  getAdsData(sDate, eDate) {
    const { account, allPagesId } = this.state;
    const { account_id } = account;

    const startDate = sDate || this.props.match.params.startDate;
    const endDate = eDate || this.props.match.params.endDate;

    return getAdwordsReportingData(startDate, endDate, allPagesId, account_id).then(data => {
      const adsData = !!data ? data[`adwords_reporting_${account_id}`] : [];
      return this.processAdsData(adsData);
    });


  }

  processAdsData(adsData) {
    const { account } = this.state;
    const { account_id } = account;

    if (adsData.length < 1) {
      this.setState({ stats: {}, campaignData: [] });
      return;
    }

    const mapper = d3.nest().key(d => d.campaign_id).rollup(d => d[0]).entries(adsData);
    const campaignDataMap = mapper.reduce((p,c) =>  {p[c.key] = c.values; return p}, {});

    const group_mapper = d3.nest().key(d => d.ad_group_id).rollup(d => d[0]).entries(adsData);
    const adgroupDataMap = group_mapper.reduce((p,c) =>  {p[c.key] = c.values; return p}, {});

    const stats = d3.nest().key(d => 'key')  // bogus key to roll everything into one
      .rollup((v) => {
        return {
            imps: d3.sum(v, d => d.imps),
            clicks: d3.sum(v, d => d.clicks),
            spend: d3.sum(v, d => d.spend),
            conversions: d3.sum(v, d => d.conversions),
            all_conversions: d3.sum(v, d => d.all_conversions),
        }
      }).entries(adsData)[0].values;

    const formatAdGroups = (values) => {
      const adGroupStats = d3.nest().key(d => d.ad_group_id)
        .rollup((v) => {
          return {
              imps: d3.sum(v, d => d.imps),
              clicks: d3.sum(v, d => d.clicks),
              spend: d3.sum(v, d => d.spend).toFixed(2),
              conversions: d3.sum(v, d => d.conversions).toFixed(2),
              all_conversions: d3.sum(v, d => d.all_conversions).toFixed(2),
          }
        }).entries(values).map(d => {
          const { imps, clicks, spend, conversions, all_conversions } = d['values'];
          return {
            ad_group_id: d['key'],
            ad_group: adgroupDataMap[d['key']]['ad_group'],
            device: adgroupDataMap[d['key']]['device'],
            network: adgroupDataMap[d['key']]['network'],
            imps, clicks, spend, conversions, all_conversions
          }
        });
      return adGroupStats
    }

    const campaignData = d3.nest().key(d => d.campaign_id)
      .entries(adsData).map(o => {
        return {
          account_id,
          name: campaignDataMap[o['key']]['campaign'],
          id: o['key'],
          device: campaignDataMap[o['key']]['device'],
          network: campaignDataMap[o['key']]['network'],
          adGroups: formatAdGroups(o['values']),
          numAdGroups: o['values'].length,
          imps: d3.sum(o['values'], d => d.imps),
          clicks: d3.sum(o['values'], d => d.clicks),
          spend: d3.sum(o['values'], d => d.spend).toFixed(2),
          conversions: d3.sum(o['values'], d => d.conversions).toFixed(2),
          all_conversions: d3.sum(o['values'], d => d.all_conversions).toFixed(2)
        }
      });

    this.setState({ stats, campaignData, loading: false });
  }

  onAccountSelection = (e, d) => {
    const { startDate, endDate } = this.props.match.params;
    const { accounts } = this.state;
    const accountId = d.value;
    const account = accounts.filter(a => a.id == accountId)[0];
    const excludedAdIds = account.excluded_ads || [];

    this.setState({ account, excludedAdIds, loading: true }, () => this.getAdsData(startDate, endDate));
  }

  render () {

    const cols = [
      // {display: 'Account ID', key: 'account_id'},
      {display: 'Campaign ID', key: 'id'},
      {display: 'Campaign', key: 'name'},
      {display: 'Device', key: 'device'},
      {display: 'Network', key: 'network'},
      {display: 'Impressions', key: 'imps', headerWidth: 1},
      {display: 'Clicks', key: 'clicks', headerWidth: 1},
      {display: 'Cost', key: 'spend', headerWidth: 1},
      {display: 'Conversions', key: 'conversions', headerWidth: 1},
      {display: 'Conversions (all)', key: 'all_conversions', headerWidth: 2},
      {display: "", key: "expand", as: IndexGrid.ExpandContractCell}
    ]

    const { adsData, campaignData, account, accounts, stats, loading } = this.state;


    const AdAccountSelector = ({ account, accounts }) => (
      <ContentCard>
        <div style={{minHeight: '50px', padding: '5px'}}>
          <strong>Select Account: </strong>
          <Dropdown
            onChange={this.onAccountSelection}
            value={account.id}
            options={accounts}
            disabled={loading}
            selection
          />
          <div style={{float: 'right', padding: '10px'}}>
            {this.renderDateSelector()}
          </div>
        </div>
      </ContentCard>
    )

    if (loading) return (<div><ContentCard.CardLoader /></div>);

    return (
      <div>
        <React.Fragment>
          { accounts && accounts.length > 0 &&
            <AdAccountSelector {...{ account, accounts }}/>
          }
          { adsData && stats &&
            <AccountStats {...{ adsData, stats }} />
          }
          { this.renderDateModal() }
        </React.Fragment>
        { campaignData && campaignData.length > 0 &&
          <ExpandableIndexGrid
            style={{ fontSize: "11px"}}
            idKey="id"
            data={campaignData}
            cols={cols}
            expandCell={true}
            expandedComponent={AdGroupTable}
            fallBackMsg={"No adgroup data"}
          />
        }
      </div>
    )
  }
}

export default withRouter(GoogleAdsReportingView);

import React from "react";
import { Table, Icon } from "semantic-ui-react";
import { IndexGrid, ContentCard } from "@rockerbox/styleguide";

const IconHeader = ({ item }) => {
  return (
    <Table.Cell style={{ cursor: "pointer" }}>
      <Icon name="edit outline" /> {item.type}
    </Table.Cell>
  );
};

const SetupSummaryTable = (props) => {
  const { allTypeProps, header, setActiveArea } = props;
  const areaKeys = ["device", "web", "mobile"];
  const cols = [
    { key: "type", display: "Event Type", as: IconHeader },
    { key: "currentOffset", display: "Threshold Value (min)" },
  ];

  const data = Object.keys(allTypeProps).map((key) => ({
    currentOffset: allTypeProps[key]["currentOffset"],
    type: key,
  }));

  return (
    <ContentCard title={"Setup Summary"} hasTable topRight={header}>
      <IndexGrid
        data={data}
        cols={cols}
        idKey="key"
        fallbackMsg="No customer data"
        onRowClick={(item) => setActiveArea(item.type)}
      />
    </ContentCard>
  );
};

export default SetupSummaryTable;

/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Table, Icon, Header } from 'semantic-ui-react';

import { CardLoader } from '../ContentCard/ContentCard';

const ExpandedWrapper = ({ colSpan, children }) => (
  <Table.Row className="expandable-row">
    <Table.Cell colSpan={colSpan} style={{ borderTop: 'none' }}>
      {children}
    </Table.Cell>
  </Table.Row>
);

const Wrap = props => props.children;

const writeCell = (item, col, otherProps) => {
  // eslint-disable-next-line no-shadow
  const DefaultCell = ({ item, col }) => (
    <Table.Cell width={item[col.cellWidth]}>
      <span>{item[col.key]}</span>
    </Table.Cell>
  );
  const CellComponent = col.as || DefaultCell;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <CellComponent {...{ col, item, ...otherProps }} />
  );
};

const GridRow = props => {
  const ExpandedSection = props.expandedComponent;
  const { item, idKey, onRowClick } = props;
  const handleClick = props.expandCell
    ? false
    : () => {
      props.handleExpand(item[idKey]);
      if (onRowClick) {
        onRowClick({ id: item[idKey] });
      }
    };

  return (
    <>
      <Table.Row positive={props.positive || props.item.positive} negative={props.negative} onClick={handleClick} style={props.expandCell ? null : { cursor: 'pointer' }}>
        { props.cols.map(col => writeCell(props.item, col, props)) }
      </Table.Row>
      { props.expand === item[idKey] && <ExpandedWrapper colSpan={Object.keys(props.cols).length}><ExpandedSection item={item} /></ExpandedWrapper>}
    </>
  );
};

const Grid = props => {
  const headers = props.sortedHeaders;

  return (
    <>
      <Table.Header>
        <Table.Row>
          { headers && headers.length > 0 ? headers : props.cols.map(col => (
            <Table.HeaderCell width={col.headerWidth}>{col.display}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {props.data.map(item => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <GridRow {...props} item={item} />
        ))}
      </Table.Body>
      <Wrap>
        {props.footer || null}
      </Wrap>
    </>
  );
};

const EmptyMessage = props => (
  <Table.Body>
    <Table.Row>
      <Table.Cell
        textAlign="center"
        selectable={false}
        className="empty"
      >
        <br />
        <Icon
          circular
          disabled
          name="info"
          size="big"
          className="m-b-10"
        />
        <Header
          as="h3"
          disabled
          content={props.fallBackMsg || 'No data found'}
          className="m-0"
        />
        <br />
      </Table.Cell>
    </Table.Row>
  </Table.Body>
);

class ExpandableIndexGrid extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    expand: '',
  };

  componentDidMount() {
    const expand = this.props.defaultExpand;
    if (expand) this.setState({ expand });
  }

  componentDidUpdate(prevProps) {
    const expand = this.props.forceExpand;
    if (expand && prevProps.forceExpand !== expand) this.setState({ expand });
  }

  handleExpand = item => {
    this.setState({ expand: item });
  };

  handleContract = () => {
    this.setState({ expand: '' });
  };

  render() {
    const { expandedComponent, idKey, cols, data, fallBackMsg, ...tableProps } = this.props;
    const { handleExpand, handleContract } = this;

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Table {...tableProps}>
        { !data && <CardLoader /> }
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        { data && data.length > 0 && <Grid cols={cols} {...this.props} {...{ handleExpand, handleContract }} expand={this.state.expand} /> }
        { data && data.length === 0 && <EmptyMessage fallBackMsg={fallBackMsg} />}
      </Table>
    );
  }
}

ExpandableIndexGrid.propTypes = {
  /** @deprecated instead, wrap `IndexGrid` component with [ContentCard](#contentcard) and include the `hasTable` prop
   */
  /** Array of column objects */
  cols: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    display: PropTypes.string,
    as: PropTypes.element,
  })).isRequired,
  /** Key from a `data` object used to determine which row has been clicked and should be expanded.  The selected key must be unique across all objects (ideally some sort of id) */
  idKey: PropTypes.string.isRequired,
  /** Component that will display when table row is clicked */
  expandedComponent: PropTypes.element.isRequired,
  /** Optional fallback message if no data is available */
  fallBackMsg: PropTypes.string,
};

export default ExpandableIndexGrid;

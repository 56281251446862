import React, { useState } from 'react';
import { Modal, Form, Button, Divider, Icon, Popup } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { postChannelRedistribution, putChannelRedistribution, deleteChannelRedistribution } from '../../../api/attribution';
import RedistributionTable from './RedistributionTable';
import { StyledDropdown } from '../../../components';
import { recommendedOptions } from './constants';
import { track } from '../../../../utils/tracking';
import { useConversionAndRevenueKey, useDefaultWeights, useGridData, useSelectedRedistribution, useRecommended, useCreditRedistributionId, useCols, watchModifiersSetRecommended, watchDataSetOptionsList } from './hooks'; // eslint-disable-line max-len

const FieldWrapper = styled.div`
  width: 175px;
`;

const CreditRedistributionModal = props => {
  const {
    data, setCreditRedistributionId,
    loading, selectedRedistribution, getAndSetRedistributions,
    attributionOptions, ntfFilter,
  } = props;

  const { startDate, endDate, id, creditRedistributionId } = useParams();
  const [name, setName] = useState('Custom Model');
  const [modifiers, setModifiers] = useState([]);
  const [model, setModel] = useState('even');
  const [open, setOpen] = useState(false);
  const [recommended, setRecommended] = useState(Number(creditRedistributionId) === 0 ? 'default' : '');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [focusedCell, setFocusedCell] = useState(false);
  const [optionsList, setOptionsList] = useState([]);

  const hasSelectedRedistribution = Object.keys(selectedRedistribution).length > 0;
  const options = attributionOptions.filter(row => !row.model);
  const showOriginalCols = true;

  // useMemo/useEffect hooks
  const { conversionKey, revenueKey } = useConversionAndRevenueKey(model, ntfFilter);
  const defaultWeights = useDefaultWeights(data);
  const gridData = useGridData(data, modifiers, conversionKey, revenueKey, showOriginalCols);

  useSelectedRedistribution(
    defaultWeights,
    selectedRedistribution, // watched
    data,
    hasSelectedRedistribution,
    setModifiers,
    setModel,
    setName, // methods
  );

  useRecommended(
    recommended, // watched
    modifiers,
    gridData,
    track,
    setModifiers, // methods
  );

  useCreditRedistributionId(
    creditRedistributionId, // watched
    setRecommended, // methods
  );

  watchDataSetOptionsList(
    data,
    recommendedOptions,
    setOptionsList,
  );

  watchModifiersSetRecommended(
    modifiers,
    setRecommended,
    gridData,
  );

  const onModifierChange = (modifier, tier_1) => {
    track('Attribution.AttributionReport.CustomModel.set.slide');
    const updatedWeights = modifiers.map(c => {
      if (c.tier_1 === tier_1) {
        return { ...c, modifier: Number(modifier) };
      }
      return c;
    });

    setModifiers(updatedWeights);
  };

  const resetModifiers = () => {
    if (hasSelectedRedistribution) {
      const { weights, model } = selectedRedistribution;  // eslint-disable-line no-shadow
      weights.sort((a, b) => a.tier_1.localeCompare(b.tier_1));
      setModifiers(weights);
      setModel(model);
    } else {
      setModifiers(defaultWeights);
    }
  };

  const cols = useCols(
    model,
    modifiers, // watched
    conversionKey,
    revenueKey,
    onModifierChange,
    resetModifiers,
    setInputError,
    selectedRedistribution,
    defaultWeights,
  );

  const closeAndReset = () => {
    track('Attribution.AttributionReport.CustomModel.set.cancel');
    setRecommended('');
    setModel('even');
    setOpenDeleteModal(false);
    if (hasSelectedRedistribution) {
      const { weights } = selectedRedistribution;
      weights.sort((a, b) => a.tier_1.localeCompare(b.tier_1));
      setModifiers(weights);
    } else {
      setModifiers(defaultWeights);
    }
    setOpen(false);
  };

  const onSubmit = () => {
    track('Attribution.AttributionReport.CustomModel.set.save');
    const redistributionId = selectedRedistribution.id;
    const send = redistributionId ? putChannelRedistribution : postChannelRedistribution;
    const postObj = {
      name,
      weights: modifiers,
      start_date: startDate,
      end_date: endDate,
      filter_id: Number(id),
      model,
    };

    send(postObj, redistributionId)
      .then(response => {
        const { id } = response; // eslint-disable-line no-shadow
        setCreditRedistributionId(id);
        getAndSetRedistributions();
        closeAndReset();
      })
      .catch(err => console.log(err));
  };

  const onDelete = () => {
    track('Attribution.AttributionReport.CustomModel.set.delete');
    const { id } = selectedRedistribution; // eslint-disable-line no-shadow

    deleteChannelRedistribution(id)
      .then(x => { // eslint-disable-line no-unused-vars
        setCreditRedistributionId(0);
        getAndSetRedistributions();
        setOpenDeleteModal(false);
        setOpen(false);
      });
  };

  return (
    <Modal
      onClose={closeAndReset}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Icon name="edit" color="purple" style={{ cursor: 'pointer', margin: 0 }} disabled={loading} />}
      size="fullscreen"
    >
      <Modal.Content>
        <div style={{ textAlign: 'right' }}>
          <Icon name="close" size="large" color="grey" onClick={closeAndReset} style={{ cursor: 'pointer' }} />
        </div>
        {!hasSelectedRedistribution && (
          <>
            <h2>Create Custom Credit Allocation</h2>
            <h3>Select an existing attribution model as a starting point, then cutsomize each channel’s credit allocation to suit your needs.</h3>
          </>
        )}
        {hasSelectedRedistribution && (
          <div style={{ display: 'flex', position: 'relative' }}>
            <h2 style={{ margin: 0 }}>{`${selectedRedistribution.name}`}</h2>
            <Popup
              on="click"
              open={openDeleteModal}
              onClose={() => setOpenDeleteModal(false)}
              onOpen={() => setOpenDeleteModal(true)}
              position="right center"
              content={(
                <div>
                  <span style={{ textAlign: 'center' }}>Are you sure?</span>
                  <div>
                    <Button negative content="Yes, delete" size="mini" type="button" onClick={onDelete} />
                    <Button content="Cancel" size="mini" type="button" onClick={() => setOpenDeleteModal(false)} />
                  </div>
                </div>
              )}
              trigger={(
                <Button
                  type="button"
                  icon="trash alternate outline"
                  style={{ padding: 0, backgroundColor: 'transparent', color: '#475ddc', marginLeft: 10, fontSize: 16, cursor: 'pointer' }}
                />
              )}
            />
          </div>
        )}
        <Divider />
        <Form onSubmit={onSubmit}>
          <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', width: 975, marginBottom: 10 }}>
            <FieldWrapper>
              <div style={{ fontWeight: 500 }}>
                Base Attribution Model
              </div>
              <div style={{ display: 'flex' }}>
                <StyledDropdown
                  value={model}
                  options={options}
                  onChange={(e, { value }) => setModel(value)}
                  containerStyles={{ flexGrow: 1, padding: 0 }}
                  noPadding
                />
              </div>
            </FieldWrapper>
            <FieldWrapper>
              <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                Preconfigured Settings
                {/* <Popup basic inverted content="tool tip content" trigger={<Icon name="question circle" color="purple" />} /> */}
              </div>
              <div style={{ display: 'flex' }}>
                <StyledDropdown
                  value={recommended}
                  options={optionsList}
                  onChange={(e, { value }) => setRecommended(value)}
                  containerStyles={{ flexGrow: 1, padding: 0 }}
                  noPadding
                />
              </div>
            </FieldWrapper>
            <div style={{ width: 350 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                Custom Model Name
                {/* <Popup basic inverted content="tool tip content" trigger={<Icon name='question circle' color="purple" />} /> */}
              </div>
              <div>
                <Form.Input value={name} onChange={(e, { value }) => setName(value)} />
              </div>
            </div>

            <div>
              <Button content="Save Model" type="submit" primary disabled={inputError} style={{ height: 36 }} />
            </div>
            <div>
              <Button content="Cancel" onClick={closeAndReset} inverted color="purple" style={{ height: 36 }} />
            </div>
          </div>

          <RedistributionTable {...{ data: gridData, cols, inputError, focusedCell, setFocusedCell }} />
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default CreditRedistributionModal;

import { suppressionGroupIds } from '../../../constants/mappings';

/**
 * Get the new suppressions and deletions for email subscriptions.
 * @param {Object} values - An object where the keys are subscription names and the values are booleans indicating if the subscription is active.
 * @returns {Object} An object containing two arrays: `newSuppressions` and `newDeletions`.
 */
export function getEmailSuppressionsAndDeletions(values) {
  const newSuppressions = [];
  const newDeletions = [];
  Object.entries(values).forEach(([key, value]) => {
    const suppressionId = suppressionGroupIds[key];
    if (!suppressionId) return; // Skip if suppressionId is undefined

    if (value) {
      newDeletions.push(suppressionId);
    } else {
      newSuppressions.push(suppressionId);
    }
  });
  return { newSuppressions, newDeletions };
}

/**
 * Merge an array of objects into a single object, ensuring unique values for each key.
 * @param {Array<Object>} array - The array of objects to be merged.
 * @returns {Object} The merged object with unique values for each key.
 */
export const mergeObjectsArray = array => array.reduce((accu, obj) => {
  Object.entries(obj).forEach(([key, value]) => {
    if (!accu[key]) {
      accu[key] = [];
    }
    accu[key] = [...new Set([...accu[key], ...value])];
  });
  return accu;
}, {});

import React, { useMemo } from 'react';
import { Table, Button } from 'semantic-ui-react';
import _ from 'lodash';
import SliderCell from './SliderCell';
import { formatCurrency, numberToTwoDecimals } from '../../../utils/valueFormatter';

const CreditAllocationHeader = (resetModifiers, selectedRedistribution, modifiers, defaultWeights) => (col, index) => {
  const { headerStyles, headerWidth } = col;
  const reset = () => {
    resetModifiers();
  };

  const weightsMatchModifiers = useMemo(() => {
    const { weights } = selectedRedistribution;
    const weightEqualsModifier = _.isEqual(weights, modifiers);
    const defaultEqualsModifier = _.isEqual(defaultWeights, modifiers);
    const disableScenarios = weightEqualsModifier || defaultEqualsModifier;

    return disableScenarios;
  }, [modifiers]);

  return (
    <Table.HeaderCell style={headerStyles} width={headerWidth} key={index} rowSpan="2">
      Credit Allocation
      <Button
        onClick={reset}
        disabled={weightsMatchModifiers}
        icon="refresh"
        type="button"
        color="purple"
        style={{ padding: 0, backgroundColor: 'transparent', color: '#475ddc', marginLeft: 10, fontSize: 14 }}
      />
    </Table.HeaderCell>
  );
};

export const generateCols = (conversionKey, revenueKey, onModifierChange, modifiers, resetModifiers, setInputError, selectedRedistribution, defaultWeights) => {
  const firstCol = {
    id: 'channel',
    display: 'Channel',
    key: 'tier_1',
    headerStyle: { textAlign: 'left' },
    cellStyle: { textAlign: 'left' },
    rowSpan: 2,
    primaryColumn: 'Channel',
  };

  const modelSelectionCol = {
    headerAs: CreditAllocationHeader(resetModifiers, selectedRedistribution, modifiers, defaultWeights),
    id: 'custom',
    key: 'custom',
    as: SliderCell(onModifierChange, modifiers, setInputError),
    headerStyles: { textAlign: 'center' },
    rowSpan: 2,
  };

  const restCols = [
    {
      id: 'spend',
      display: 'Spend',
      key: 'spend',
      formatter: formatCurrency,
      textAlign: 'right',
      rowSpan: 2,
      primaryColumn: 'Spend',
    },
    {
      id: 'originalConversions',
      display: 'Original',
      key: 'originalConversions',
      formatter: numberToTwoDecimals,
      textAlign: 'right',
      colSpan: 2,
      primaryColumn: 'Conversions',
    },
    {
      id: 'conversion_key',
      display: 'Modified',
      key: conversionKey,
      formatter: numberToTwoDecimals,
      textAlign: 'right',
      colSpan: 2,
      primaryColumn: 'Conversions',
      cellStyle: { backgroundColor: '#F5F7FF' },
    },
    {
      id: 'originalRevenue',
      display: 'Original',
      key: 'originalRevenue',
      formatter: formatCurrency,
      textAlign: 'right',
      colSpan: 2,
      primaryColumn: 'Revenue',
    },
    {
      id: 'revenue_key',
      display: 'Modified',
      key: revenueKey,
      formatter: formatCurrency,
      textAlign: 'right',
      colSpan: 2,
      primaryColumn: 'Revenue',
      cellStyle: { backgroundColor: '#F5F7FF' },
    },
    {
      id: 'originalCpa',
      display: 'Original',
      key: 'originalCpa',
      formatter: formatCurrency,
      textAlign: 'right',
      colSpan: 2,
      primaryColumn: 'CPA',
    },
    {
      id: 'cpa',
      display: 'Modified',
      key: 'cpa',
      formatter: formatCurrency,
      textAlign: 'right',
      colSpan: 2,
      primaryColumn: 'CPA',
      cellStyle: { backgroundColor: '#F5F7FF' },
    },
    {
      id: 'originalRoas',
      display: 'Original',
      key: 'originalRoas',
      formatter: numberToTwoDecimals,
      textAlign: 'right',
      colSpan: 2,
      primaryColumn: 'ROAS',
    },
    {
      id: 'roas',
      display: 'Modified',
      key: 'roas',
      formatter: numberToTwoDecimals,
      textAlign: 'right',
      colSpan: 2,
      primaryColumn: 'ROAS',
      cellStyle: { backgroundColor: '#F5F7FF' },
    },
  ];

  const finalCols = [firstCol, modelSelectionCol, ...restCols];

  return finalCols;
};

export const removeDirect = modifiers => modifiers.map(c => {
  const updatedObj = { ...c };
  if (updatedObj.tier_1 === 'Direct') {
    updatedObj.modifier = 0;
  } else {
    updatedObj.modifier = 1;
  }
  return updatedObj;
});

export const removeSpend = (modifiers, gridData) => modifiers.map(item1 => {
  const matchingItem2 = gridData.find(item2 => item2.tier_1 === item1.tier_1 && item2.spend === 0);
  if (matchingItem2) {
    return { ...item1, modifier: 0 };
  }
  return { ...item1, modifier: 1 };
});

export const calculateDefaults = modifiers => modifiers.map(c => {
  const updatedObj = { ...c };
  updatedObj.modifier = 1;
  return updatedObj;
});

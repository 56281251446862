import React, { Component } from 'react';
import ButterToast from 'butter-toast';
import { ToastStyles, CloseButtonStyles } from '../Toast';
import { Table } from 'semantic-ui-react';
import { IndexGrid } from '@rockerbox/styleguide';
import { forgotPassword, deleteUser } from '../../utils/api';


export class InviteUsersRows extends Component { 
  handleDelete = () => {
    const { username }  = this.props.item
    deleteUser({ username })
      .then( () => {
        this.props.col.key.handleManageUpdate();
      })
      .then( () => {
        ButterToast.raise({
          dismissOnClick: true,
          content: ({toastId, dismiss}) => (
            <ToastStyles>
              <CloseButtonStyles>x</CloseButtonStyles>
              {username} removed from account
            </ToastStyles>
          ),
          toastTimeout: 5000
        })
      })
  }

  handleResend = () => {
    const { email } = this.props.item;
    forgotPassword({ email: email})
      .then( () => {
        ButterToast.raise({
          dismissOnClick: true,
          content: ({toastId, dismiss}) => (
            <ToastStyles>
              <CloseButtonStyles>x</CloseButtonStyles>
              Account info resent to: {email}
            </ToastStyles>
          ),
          toastTimeout: 5000
        })
      })
  }

  render() {
    const username = this.props.col.key.current_username;
    const shouldShow =
      username && username.trim().slice(-14) === "@rockerbox.com";
 
    return (
      <Table.Cell textAlign={"right"}> 
        { shouldShow ? <IndexGrid.SendButton item={this.props.item} send={ this.handleResend } popup={"Resend Login"}/> : ""}
        { shouldShow ? <IndexGrid.DeleteButton onDelete={ this.handleDelete } popup={"Remove User"}/> : ""}
      </Table.Cell>
    )
  }
}



import React from 'react';

export const useConversionEntityNameMemo = (conversionData, entityConfigs, codeField=false) => {

  return React.useMemo(() => {

    if ((entityConfigs === false) || !conversionData) return [];

    const fieldValueName = entityConfigs.reduce((codes, config) => {
      config.promo_codes.map(code => {
        codes[code.conversion_field_name] = codes[code.conversion_field_name] || {}
        codes[code.conversion_field_name][code.conversion_field_value] = {
          name: config.name,
          id: code.entity_id
        }
      })
      return codes
    }, {})

    const conversionsWithEntityName = conversionData.map(conversion => {
      const {field, promoCode, entityName, entityId} = Object.entries(fieldValueName).reduce((previous, [_field, values]) => {
        const field = codeField || _field
        const promoCode = conversion[field]
        const {name: entityName, id: entityId} = values[promoCode] || {}
        return {field, promoCode, entityName, entityId}
      }, {})

      if (field) return Object.assign(conversion, {field, promoCode, entityName, entityId})
      if (codeField) return Object.assign(conversion, {field: codeField, promoCode: conversion[codeField]})

      return conversion
    })

    return conversionsWithEntityName.filter(({ promoCode }) => promoCode)

  }, [conversionData, entityConfigs, codeField])
}
    
export const useMissingAssignedCodesMemo = (conversionsWithEntityName, discountField= "discount") => {

  return React.useMemo(() => {
    if (!conversionsWithEntityName.length) return {}

    const byEntityName = d3.nest()
      .key(row => row.entityName || "Unclassified")
      .key(row => row.promoCode)
      .rollup(values => {
        let discount = values[0][discountField]
        const { field, revenue, discount_type = "fixed_amount" } = values[0]

        discount = (discount && discount.length > 15) ? JSON.parse(discount).amount : discount
        const original = parseFloat(revenue) + parseFloat(discount)
        const discount_amount = getDiscountAmount(discount, discount_type, original)

        return {values: values, count: values.length, discount_amount, discount_type, field}
      })
      .map(conversionsWithEntityName)

    const missingPromoCodes = Object.entries(byEntityName['Unclassified'])
      .map(([promoCode,value]) => Object.assign({ promoCode}, value) )
      .sort((p,c) => d3.ascending(p.promoCode, c.promoCode))
      .sort((p,c) => c.count - p.count)

    delete byEntityName['Unclassified']
    const assignedPromoCodes = Object.entries(byEntityName).reduce((codes,[entityName, byPromoCode]) => {
      const newCodes = Object.entries(byPromoCode).map(([promoCode, _values]) => {
        const { values, count } = _values
        const { revenue, discount_type, field } = values[0]
        const discount = values[0][discountField]
        const original = revenue + parseFloat(discount)
        const discount_amount = getDiscountAmount(discount, discount_type, original)
        return { entityName, promoCode, values, count, discount_amount, discount_type, field }
      })
      return [...codes, ...newCodes]
    }, [])

    return { missingPromoCodes, assignedPromoCodes }
  }, [conversionsWithEntityName, discountField])
}

const getDiscountAmount = (discount, discount_type, original) => (
  !!discount
    ? discount_type == "fixed_amount"
      ? `$${discount}`
      : `${Math.round(discount/(original)*100)}%`
    : "-"
)
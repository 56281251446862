import axios from "axios";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import { openDB } from "idb";
import * as d3 from 'rockerbox_d3_legacy_clone';
const yesterday = moment.utc().utcOffset(-5).subtract(1, "days").format("YYYY-MM-DD")

export const mock = (response) => () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve({ response }), 1);
  });
};

export function switchAccount(value) {
  const url = `/account/switch`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ advertiser_name: value })
  }).then(data => {
    return data;
  });
}

export function getUser() {
  const url = `/user`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(({ response }) => response);
}

export function getUserPermissions() {
  const url = `/user/permissions`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(({ response }) => response);
}

export function getAccount() {
  const url = `/account`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(({ response }) => response);
}

export function getSubscriptions() {
  const url = `/account/subscriptions`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(({ response }) => response);
}

export function createCheckoutSession(obj) {
  const url = `/account/billing/checkout`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(({ response }) => response);
}

export function createPortalSession() {
  const url = `/account/billing/portal`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(({ response }) => response);
}

export function getMessages() {
  const url = `/account/help/messages`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(({ response }) => response);
}

export function getAnnouncements() {
  const url = `/announcements`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(({ response }) => response);
}

export function getDataset(
  datasetName,
  id,
  daysAgo = false,
  host = "cache",
  start = false,
  end = false,
  udf = "attribution_events"
) {
  const [startDate, endDate] = daysAgo
    ? [
        moment
          .utc()
          .utcOffset(-5)
          .subtract(daysAgo, "days")
          .format("YYYY-MM-DD"),
        yesterday
      ]
    : start && end
    ? [start, end]
    : [yesterday, yesterday];

  let url = `/${host}/v3/${udf}?keys=${datasetName}&identifier=${id}`;
  url +=
    daysAgo || (start && end)
      ? `&start_date=${startDate}&end_date=${endDate}`
      : `&date=${yesterday}`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(response => response.json());
}

export function getTiersAsOptions(id, days = false) {
  return getDataset("compiled_mta_tiers", id, days)
    .then(({ response: { compiled_mta_tiers } }) => compiled_mta_tiers)
    .then(tiers => {
      const tt = ["tier_1", "tier_2", "tier_3", "tier_4", "tier_5"];
      const rolled = tt
        .reduce((nest, c) => nest.key(row => row[c]), d3.nest())
        .rollup(values => values.length)
        .entries(tiers);

      const toOptions = ({ key, values }) => {
        if (typeof values == "number") return { key, text: key, value: key };
        return { key, text: key, value: key, values: values.map(toOptions) };
      };

      return rolled.map(toOptions);
    });
}

export const getConversionDataset = async (id, daysAgo = false) => {
  const data = await getDataset(
    "conversion_data_excluded,conversion_data_ntf,conversion_data",
    id,
    daysAgo
  );

  const { response } = data;
  const {
    conversion_data_excluded,
    conversion_data_ntf,
    conversion_data
  } = response;
  const conversion_dataset =
    !!conversion_data_excluded && !!conversion_data_excluded.length
      ? conversion_data_excluded
      : !!conversion_data_ntf && !!conversion_data_ntf.length
      ? conversion_data_ntf
      : conversion_data;

  const use_filter_for_original = conversion_dataset.length > 0 && Object.keys(conversion_dataset[0]).indexOf("is_valid") !== -1;
  const conversion_data_original = use_filter_for_original ? conversion_dataset.filter(({ is_valid }) => !!is_valid) : conversion_dataset;

  const ordered = conversion_data_original
    .map(row => {
      return Object.assign({}, row, { mtimestamp: moment(row.timestamp) });
    })
    .sort((p, c) => p.mtimestamp - c.timestamp)
    .reduce((p, c) => {
      const current = p[c.base_id];
      if (!current) {
        p[c.base_id] = c;
        return p;
      }
      const currentKeys = Object.keys(current);
      const rekeyedC = Object.keys(c)
        .filter(key => key.includes("answer") || key.includes("response"))
        .reduce((p, key) => {
          p[`${key}_followup`] = c[key];
          return p;
        }, {});

      Object.assign(p[c.base_id], rekeyedC);

      return p;
    }, {});

  return {
    response: {
      conversion_data: Object.values(ordered),
      conversion_data_original
    }
  };
};

export const getConversionDataSummary = (id, daysAgo = 3, skipped = false) => {
  const SKIPPED = skipped || [
    "date",
    "conversion_hash_id",
    "timestamp",
    "sessionId",
    "base_id",
    "user_agent",
    "script_version",
    "rb_source",
    "hash_ip",
    "segment_anonymous_id",
    "segmentmessageId"
  ];

  return getConversionDataset(id, daysAgo).then(data => {
    const { response } = data;
    const { conversion_data, conversion_data_original } = response;

    const transposed = conversion_data.reduce((obj, row) => {
      Object.keys(row).map(k => {
        obj[k] = obj[k] || [];
        obj[k].push(row[k]);
      });
      return obj;
    }, {});

    const summary = Object.keys(transposed)
      .map(key => {
        const raw = transposed[key];
        const set = new Set(raw);
        const size = set.size;
        return { key, raw, set, size };
      })
      .sort((p, c) => c.size - p.size)
      .filter(({ key }) => !SKIPPED.includes(key));

    return {
      conversion_data_original,
      conversion_data,
      summary
    };
  });
};

export function getDatasetSchemaStartEnd(
  datasetName,
  id,
  udf = "attribution_events",
  startDate = yesterday,
  endDate = yesterday
) {
  const url = `/data/v3/stats/${udf}?keys=${datasetName}&start_date=${startDate}&end_date=${endDate}&identifier=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(response => response.json());
}

export function getDatasetSchema(
  datasetName,
  id,
  udf = "attribution_events",
  date = yesterday
) {
  const url = `/data/v3/stats/${udf}?keys=${datasetName}&date=${date}&identifier=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(response => response.json());
}

export function getAllValidations() {
  const url = `/account/available/validations`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(resp => {
      return {
        response: resp.response.map(row => {
          return { text: row.script_name, value: row.script_name };
        })
      };
    });
}
export function getValidations() {
  const url = `/account/validations`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(response => response.json());
}

export function saveValidations(obj) {
  const url = `/account/validations`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => response.json());
}

export function getAttributableEventTemplates() {
  const url = `/account/settings/attributable_event/templates`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getTransformTableTemplates() {
  const url = `/account/settings/transform_table/templates`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getAllowTransformTableTemplates() {
  const url = `/account/settings/transform_table/templates/allow`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(response => response.json());
}

export function saveAllowTransformTableTemplates(obj) {
  const url = `/account/settings/transform_table/templates/allow`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => response.json());
}

export function getMappingTemplate() {
  const url = `/account/settings/mapping_template`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function saveMappingTemplate(obj) {
  const url = `/account/settings/mapping_template`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getOnboardingStep(obj) {
  const url = `/account/onboarding_step`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function saveOnboardingStep(obj) {
  const url = `/account/onboarding_step`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function saveOnboardingSurvey(obj) {
  const url = `/account/onboarding_survey`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function postRequiresSetup(obj) {
  const url = `/account/requires_setup`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getEventTables() {
  const url = `/account/event_tables`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(response => response.json());
}

export function saveEventTables(obj) {
  const url = `/account/event_tables`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => response.json());
}

export function deleteUser(obj) {
  const url = `/user/delete`;
  return fetch(url, {
    credentials: "include",
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(data => {
    return data;
  });
}
export function activateAccount(obj) {
  const url = `/user/password/set`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => {
      return data;
    });
}

export function activateGoogleAccount(obj) {
  const url = `/user/login/google/activate`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => response.json());
}

export function forgotPassword(obj) {
  const url = `/user/password/forgot`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => {
      return data;
    });
}

export function login(obj) {
  const url = `/user/login`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  });
}

export function loginAxios(obj) {
  return axios.post("/user/login", obj);
}

export function loginGoogle(obj) {
  const url = `/user/login/google`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => response.json());
}

export function changePassword(obj) {
  const url = `/user/password/change`
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
  .then(response => response.json())
  .then(({ response }) => response)
}

export function getCheckUrls() {
  return fetch("/method/names", { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function postSelectedEvent(obj) {
  const url = `/events/client_data`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

export function checkUrl(obj) {
  const url = `/events/check_url`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

export function getCustomerPath(id, tier, startDate, endDate) {
  const url = `/cache/v3/attribution_events?identifier=${id}&start_date=${startDate}&end_date=${endDate}&keys=path_${tier}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => data["response"])
    .then(data => {
      const d = data[`path_${tier}`];
      return d;
    });
}

export function getAccountDataSources(id) {
  const url = `/account/data_sources`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function postAccountDataSources(obj) {
  const url = `/account/data_sources`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}
export function getDataSources(id) {
  const url = `/data_sources`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function addNewUser(obj) {
  const url = `/user/signup`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

export function getAccountUsers() {
  const url = `/account/users`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getComparisonData(id, startDate, endDate) {
  const url = `/data/attribution_events?keys=attribution&identifier=${id}&start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data;
    });
}

export function getGoogleAnalyticsDataSource() {
  const url = `/auth/ga/credentials`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getGoogleProperties() {
  const url = `/auth/ga/property`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data;
    });
}

export function getGoogleAnalyticsProfiles(account_id, property_id) {
  const url = `/auth/ga/profile?account_id=${account_id}&property_id=${property_id}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data;
    });
}

export function postGooglePropertySelection(obj) {
  const url = `/auth/ga/credentials`;

  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

export function postDomains(obj) {
  return axios.post("/account/domains", obj);
}

export function getDomains() {
  const url = `/account/domains`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function postExcludedDomains(obj) {
  return axios.post("/account/excluded/domains", obj);
}

export function getExcludedDomains() {
  const url = `/account/excluded/domains`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getPlatformAccounts(platform, id) {
  const url = `/auth/${platform}/account/${id}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.accounts;
    });
}

export function postPlatformAccount(platform, id, obj) {
  const url = `/auth/${platform}/account/${id}`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

export function deletePlatformAccount(platform, id) {
  const url = `/auth/${platform}/account/${id}`;
  return fetch(url, {
    credentials: "include",
    method: "DELETE"
  })
    .then(response => response.json())
    .then(data => {
      return data.success;
    });
}

export function getShopifyDataSource() {
  const url = `/auth/shopify/credentials`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getShopifySetupStatus() {
  const url = `/auth/shopify/setup`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getShopifyInfo() {
  return getShopifySetupStatus().then(res => {
    const data = !!res && !!res.length ? res[0] : {};
    const { segments, historical_orders } = data;
    data["segments"] = !!segments ? JSON.parse(segments) : {};
    data["view_product"] = data["segments"]["view_product"];
    data["add_to_cart"] = data["segments"]["add_to_cart"];
    data["purchase"] =
      data["segments"]["purchase_batch"] || data["segments"]["purchase_pixel"];
    data["historical_orders"] = !!historical_orders
      ? JSON.parse(historical_orders)
      : {};
    return data;
  });
}

export function postDBMCredentials(_id, obj) {
  const url = `/auth/dbm/account/${_id}`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => {
    return response.json();
  });
}

export function postTaboolaCredentials(obj) {
  const url = `/auth/taboola/account`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => {
    return response.json();
  });
}

export function putTaboolaCredentials(obj) {
  const url = `/auth/taboola/account/${obj["id"]}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => {
    return response.json();
  });
}

export function postCriteoCredentials(obj) {
  const url = `/auth/criteo/account`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => {
    return response.json();
  });
}

export function putCriteoCredentials(obj) {
  const url = `/auth/criteo/account/${obj["id"]}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => {
    return response.json();
  });
}

export function postOutbrainCredentials(obj) {
  const url = `/auth/outbrain/account`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => {
    return response.json();
  });
}

export function putOutbrainCredentials(obj) {
  const url = `/auth/outbrain/account/${obj["id"]}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => {
    return response.json();
  });
}

export function putRedditCredentials(obj) {
  const url = `/auth/reddit/account`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => {
    return response.json();
  });
}

export function postCommissionJunctionCredentials(obj) {
  const url = `/auth/commissionjunction/account`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => {
    return response.json();
  });
}

export function getSpendAssignments() {
  const url = `/spend/assignments`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function postSpend(obj) {
  const url = `/spend/assignments`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      if (data.response) return data.response;
      throw data.error;
    });
}

export function updateSpend(obj, _id) {
  const url = `/spend/assignments?id=${_id}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      if (data.response) return data.response;
      throw data.error;
    });
}

export function getSpendPlatform() {
  const url = `/has/spend/platform`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getSpendPlatforms() {
  const url = `/spend/platforms`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getSpendPlatformTiers(platform) {
  const url = `/has/spend/platform/tiers?platform=${platform}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getStandardDatasets() {
  const url = `/batch/report`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      data.response.map(row => {
        row.text = row.report_name;
        row.value = row.id;
      });
      return data.response;
    });
}

export function getProcessedReports(
  id,
  stage = "processed",
  show = "list",
  file_id = false
) {
  const url = file_id
    ? `/data/internal/${stage}/${show}?id=${id}&file_id=${file_id}`
    : `/data/internal/${stage}/${show}?id=${id}`;

  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getSpendFields(platform) {
  const url = `/spend/fields?platform=${platform}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      data.response.map(row => {
        row.text = row.display_name;
        row.value = row.cassandra_name;
      });
      return data.response;
    });
}

export function getModelRunData(id) {
  const url = `/model_runs?id=${id}`;
  return fetch(url, { credentials: "include" }).then(response =>
    response.json()
  );
}

export function getModelRuns() {
  const url = `/model_runs`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(response => {
      return response.response
        .map(x => {
          const name = x.run_name || x.id;
          x.key = x.id;
          x.text =
            name +
            " (" +
            moment(new Date(x.finished_at)).format("YYYY-MM-DD") +
            ")";
          x.value = x.id;
          return x;
        })
        .sort((p, c) => c.id - p.id)
        .filter(x => x.finished_at > 1540228281000);
    });
}
export function postModel(obj) {
  const url = `/model/submit`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}
export function getLTV(
  id,
  how,
  numMonths,
  bucketBy,
  tier_1,
  tier_group,
  raw,
  key,
  cohort_start,
  cohort_end
) {
  let url = `/ltv?how=${how}&length=${numMonths}&identifier=${id}`;

  if (bucketBy && bucketBy != "-" && bucketBy != "")
    url += "&bucket_by=" + bucketBy;
  if (cohort_start && cohort_start != "-" && cohort_start != "")
    url += "&cohort_start=" + cohort_start;
  if (cohort_end && cohort_end != "-" && cohort_end != "")
    url += "&cohort_end=" + cohort_end;

  if (tier_1 && tier_1 != "-" && tier_1 != "") url += "&tier_1=" + tier_1;
  if (tier_group && tier_group != "-" && tier_group != "")
    url += "&tier_group=" + tier_group;
  if (raw) url += "&raw=true";
  if (key) url += `&new_to_file_key=${key}`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}
export function getTiers() {
  let url = `/ltv/tiers`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

export function getPixelCode() {
  let url = `/pixels/code`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}
export function getEventTags() {
  let url = `/event/tags`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}
export function updateEventTag(data) {
  let url = `/event/tags?id=${data.id}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    body: JSON.stringify(data)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}
export function createEventTag(data) {
  let url = `/event/tags`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(data)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}
export function getLookupData(filter_id) {
  const date = moment
    .utc()
    .utcOffset(-5)
    .subtract(1, "d")
    .format("YYYY-MM-DD");
  let url = `/cache/v3/attribution_events?identifier=${filter_id}&keys=uid_lookups_counts&date=${date}`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(handleCacheResponse);
}
export function testLookup(uids, lookup, lookups) {
  let url = `/has/lookup`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify({ uids, lookup, lookups })
  }).then(response => {
    return response.json();
  });
}
export function getMissingSpend(filter_id) {
  let url = `/cache/v3/attribution_events?identifier=${filter_id}&keys=unmapped_spend_rows`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(handleCacheResponse);
}

export function getSegments() {
  let url = `/segments`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

export function deleteSegment(id) {
  let url = `/segments?id=${id}`;
  return fetch(url, {
    credentials: 'include',
    method: 'DELETE',
  });
}

export function getFeaturedSegment() {
  const url = `/segments`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(({ response }) => response.find(segments => !!segments.featured))
    .then(({ action_id }) => action_id);
}

export function getSegmentCompare() {
  let url = `/segment_compare`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

export function postSegmentCompare(data) {
  let url = `/segment_compare`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(data)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

export function getDirectMailSegments() {
  let url = `/direct_mail/config`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

export function updateDirectMailSegments(data) {
  let url = `/direct_mail/config`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    body: JSON.stringify(data)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

function handleCacheResponse(raw) {
  const { details: { keys } = {}, response, error } = raw;
  if (!!error) return []
  if (keys.length === 1) {
    return response[keys[0]];
  }
  return raw["response"];
}

export function getMissingBuckets(filter_id, endDate) {
  let url = `/data/v3/onsite_events?identifier=${filter_id}&keys=events_unmapped&date=${endDate}`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(handleCacheResponse);
}
export function getBucketsTimeseries(filter_id, startDate, endDate) {
  let url = `/data/v3/onsite_events?identifier=${filter_id}&keys=events_mapping_count&start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(handleCacheResponse);
}
export function getBucketsTree(filter_id, endDate) {
  let url = `/data/v3/onsite_events?identifier=${filter_id}&keys=events_mapped&date=${endDate}`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(handleCacheResponse);
}
export function getBucketsRuleTree(filter_id, endDate) {
  let url = `/data/v3/onsite_events?identifier=${filter_id}&keys=events_mapped&date=${endDate}`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(handleCacheResponse);
}
export function getCreativeMissingBuckets(filter_id, endDate) {
  let url = `/data/v3/onsite_events?identifier=${filter_id}&keys=creative_unmapped&date=${endDate}`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(handleCacheResponse);
}
export function getMissingTransformBuckets(filter_id, endDate) {
  let url = `/data/v3/onsite_events?identifier=${filter_id}&keys=events_transform_unmapped&date=${endDate}`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(handleCacheResponse);
}

export function getCreativeBucketsTimeseries(filter_id, startDate, endDate) {
  let url = `/data/v3/onsite_events?identifier=${filter_id}&keys=creative_mapping_count&start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(handleCacheResponse);
}
export function getCreativeBucketsTree(filter_id, endDate) {
  let url = `/data/v3/onsite_events?identifier=${filter_id}&keys=creative_tiers_count&date=${endDate}`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(handleCacheResponse);
}
export function getCreativeBucketsRuleTree(filter_id, endDate) {
  let url = `/data/v3/onsite_events?identifier=${filter_id}&keys=creative_rules_count&date=${endDate}`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(handleCacheResponse);
}
export function getEventIssues(filter_id, endDate) {
  let url = `/data/v3/onsite_events?identifier=${filter_id}&keys=events_issues&date=${endDate}`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(handleCacheResponse);
}
export function getBucketsExcludedRuleTree(filter_id, endDate) {
  let url = `/data/v3/onsite_events?identifier=${filter_id}&keys=events_excluded&date=${endDate}`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(handleCacheResponse);
}

export function getAttributableEvents() {
  let url = `/transform/events`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

export function createAttributableEvent(data) {
  let url = `/transform/events`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(data)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}
export function updateAttributableEvent(data) {
  let url = `/transform/events?id=${data.id}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    body: JSON.stringify(data)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

export function deleteAttributableEvent(id) {
  const url = `/transform/events?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    });
}

export function getSavedBucketUrls(id) {
  return new Promise(function(resolve, reject) {
    resolve(JSON.parse(window.localStorage.getItem("RB.savedEvents." + id)));
  });
}
export function setSavedBucketUrls(data) {
  let toSave = [];
  if (data.urls) {
    const longestList =
      data.urls.length > data.referrers.length ? data.urls : data.referrers;
    toSave = longestList.map((v, i) => {
      return { url: data.urls[i], referrer: data.referrers[i] };
    });
  } else {
    toSave = data;
  }
  const numUrls = parseInt(
    window.localStorage.getItem("RB.savedEventsCount") || 0
  );
  const incrementedUrls = numUrls + 1;
  window.localStorage.setItem("RB.savedEventsCount", incrementedUrls);
  window.localStorage.setItem(
    "RB.savedEvents." + incrementedUrls,
    JSON.stringify(toSave)
  );
  return new Promise(function(resolve, reject) {
    resolve(incrementedUrls);
  });
}

export function getConversionSegments() {
  let url = `/segments?format=json`;
  return fetch(url, { credentials: "include" })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

export function getSegmentNames(version = "v1") {
  const url = `/has/segment/names?type=${version}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export const attachChildConversion = data =>
  fetch("/segments/children", {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  }).then(data => {
    return data;
  });

export const createConversion = data =>
  fetch("/segments", {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  }).then(data => {
    return data.json();
  });

export const updateConversion = data => {
  return fetch("/segments", {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  }).then(data => {
    return data.json();
  });
};

export function getOboardingProgress() {
  const url = `/account/progress`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getConversionData(id, startDate, endDate) {
  const url = `/cache/v3/attribution_events?keys=conversion_data_path&identifier=${id}&start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(handleCacheResponse);
}

export function getCompiledMta(id, startDate = yesterday, endDate = yesterday) {
  const url = `/data/v3/attribution_events?keys=compiled_mta&identifier=${id}&start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(handleCacheResponse);
}

export function getPlatformComparison(keys, id, startDate, endDate) {
  const url = `/data/v3/attribution_events?keys=${keys}&identifier=${id}&start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(handleCacheResponse);
}

export function getGAConversionData(startDate, endDate, allPagesId) {
  const url = `/data/attribution_events?keys=ga_conversion_summary&identifier=${allPagesId}&start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.ga_conversion_summary;
    });
}

export function getConversionTimeseries(id, startDate, endDate) {
  const url = `/data/attribution_events?keys=summary&identifier=${id}&start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.summary;
    });
}

export function getSpendByPlatform(
  platform,
  accountId,
  assignmentId,
  startDate,
  endDate
) {
  let url = `/has/spend?platform=${platform}&start_date=${startDate}&end_date=${endDate}`;
  if (accountId) url += `&account_id=${accountId}`;
  if (assignmentId) url += `&assignment_id=${assignmentId}`;

  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getOnsitePixel(uid) {
  const url = `/has/onsite/pixel/${uid}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function submitSpendBackfill(data) {
  const url = `/run/sync/spend`;

  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  }).then(data => {
    return data;
  });
}

export function submitBatchSpendPurge(data) {
  const url = `/run/purge/batch_spend`;

  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  }).then(data => {
    return data;
  });
}

export function submitSpendAgg(data) {
  const url = `/run/agg/spend`;

  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(data => {
      return data.json();
    })
    .then(data => {
      return data.response;
    });
}

export function submitPipelineAgg(data) {
  const url = `/run/agg/pipeline`;

  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(data => {
      return data.json();
    })
    .then(data => {
      return data.response;
    });
}

export function submitOttAgg(data) {
  const url = `/run/agg/ott`;

  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(data => {
      return data.json();
    })
    .then(data => {
      return data.response;
    });
}

export function submitGCPDataWarehouseSetup(data) {
  const url = `/run/setup/data_warehouse/gcp`;

  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(data => {
      return data.json();
    })
    .then(data => {
      return data.response;
    });
}

export function submitSnowflakeDataWarehouseSetup(data) {
  const url = `/run/setup/data_warehouse/snowflake`;

  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(data => {
      return data.json();
    })
    .then(data => {
      return data.response;
    });
}

export function submitEventsAgg(data) {
  const url = `/run/agg/events`;

  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(data => {
      return data.json();
    })
    .then(data => {
      return data.response;
    });
}

export function getAuthorizations() {
  const url = `/account/authorizations`;

  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getAuths() {
  return getAuthorizations().then(data => {
    return data
      .map(auths => {
        const { authorizations, platform } = auths;
        authorizations.map(a => (a["platform"] = auths.platform));
        auths["authorizations"] = authorizations;
        return auths;
      })
      .filter(a => !a.authorizations[0].batch_platform);
  });
}

export function getPinterestSegment() {
  const url = `/pinterest_event`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => data.response);
}

export function getFacebookConversions(id) {
  const url = `/access/facebook/${id}/customconversions?fields=name`;

  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => data.response);
}
export function getFacebookSyntheticConfigs(id) {
  const url = `/synthetic_events/facebook`;

  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}
export function updateFacebookSyntheticConfigs(id, data) {
  const url = `/synthetic_events/facebook?id=${id}`;

  return fetch(url, {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(data => {
      return data.json();
    })
    .then(data => {
      return data.response;
    });
}
export function createFacebookSyntheticConfigs(data) {
  const url = `/synthetic_events/facebook`;

  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(data => {
      return data.json();
    })
    .then(data => {
      return data.response;
    });
}

export function getACETLMetaData(
  filter_id,
  dataset = "facebook_conversions_device"
) {
  return new Promise((resolutionFunc, rejectionFunc) => {
    getBatchReports().then(resp => {
      const reportType = resp.find(
        ({ report_type_name }) => report_type_name == dataset
      );

      const { id } = reportType;
      const url = `/data/internal/processed/list?id=${id}`;

      fetch(url, { credentials: "include" })
        .then(response => response.json())
        .then(data => {
          data.response.map(row => (row.date = row.upload_id));
          resolutionFunc({ current: data.response });
        });
    });
  });
}

export function getMetaData(filter_id, dataset = "fb_synthetic_summary") {
  const endDate = moment
    .utc()
    .utcOffset(-5)
    .subtract(1, "d")
    .format("YYYY-MM-DD");
  const startDate = moment
    .utc()
    .utcOffset(-5)
    .subtract(31, "d")
    .format("YYYY-MM-DD");

  const url = `/data/v3/stats/attribution_events?start_date=${startDate}&end_date=${endDate}&identifier=${filter_id}&keys=${dataset}`;

  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getFacebookAdAccounts() {
  const url = `/ads_data/facebook`;

  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function createFacebookAdsConfig(data) {
  const url = `/ads_data/facebook`;

  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(data => {
      return data.json();
    })
    .then(data => {
      return data.response;
    });
}

export function updateFacebookAdsConfig(_id, data) {
  const url = `/ads_data/facebook?id=${_id}`;

  return fetch(url, {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(data => {
      return data.json();
    })
    .then(data => {
      return data.response;
    });
}

export function getFacebookAdsAccounting(accountId) {
  const url = `/ads_data/facebook/accounting?account_id=${accountId}`;

  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getFacebookAdsErrorAccounting(accountId) {
  const url = `/ads_data/facebook/accounting/errors?account_id=${accountId}`;

  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getFacebookAdsData(date, filter_id, account_id) {
  const url = `/cache/v3/facebook_data?start_date=${date}&end_date=${date}&identifier=${filter_id}&keys=ads_data_${account_id},bad_ads_data_${account_id}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(response => response.response);
}

export function getGoogleAdsAccounts() {
  const url = `/account/authorizations`;

  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return (
        data.response.find(a => a.platform == "adwords")["authorizations"] || []
      );
    });
}

export function getAdwordsReportingData(
  startDate,
  endDate,
  filter_id,
  account_id
) {
  const url = `/cache/v3/adwords_data?start_date=${startDate}&end_date=${endDate}&identifier=${filter_id}&keys=adwords_reporting_${account_id}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(response => response.response);
}

export function getCreativeTiersTimeseriesData(
  startDate,
  endDate,
  tier_one,
  tier_two,
  tier_three,
  tier_four,
  tier_five
) {
  const url = `/has/creative/data?start_date=${startDate}&end_date=${endDate}&tier_one=${tier_one}&tier_two=${tier_two}&tier_three=${tier_three}&tier_four=${tier_four}&tier_five=${tier_five}`;

  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => data.response);
}

function fetchWithTimeout(url, options, timeout = 7000) {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error("timeout")), timeout)
    )
  ]);
}

export function getCreativeTiersData(id, startDate, endDate) {
  const url = `/has/creative/data?start_date=${startDate}&end_date=${endDate}&identifier=${id}&keys=response`;

  return fetchWithTimeout(url, { credentials: "include" }, 10000)
    .then(response => response.json())
    .then(data => {
      data.response.map(row => {
        row.key =
          row.tier_one +
          row.tier_two +
          row.tier_three +
          row.tier_four +
          row.tier_five;
        row.num_days = row.date.length;
      });
      return data.response.sort((p, c) => c.num_days - p.num_days);
    });
}

export function getTemporalTiersData(id, startDate, endDate) {
  const url = `/has/temporal/data?start_date=${startDate}&end_date=${endDate}&identifier=${id}&keys=response`;

  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response.sort((p, c) => c.date - p.date);
    });
}

export function getClickDataTimeseries(id, startDate, endDate) {
  const url = `/cache/v3/click_data?start_date=${startDate}&end_date=${endDate}&identifier=${id}&keys=response`;

  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(handleCacheResponse);
}

export function getCustomerDataTimeseries(
  id,
  newToFileKey,
  startDate,
  endDate
) {
  const url = `/has/customer/data?start_date=${startDate}&end_date=${endDate}&identifier=${id}&new_to_file_key=${newToFileKey}&keys=response`;

  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getAddressDataTimeseries(id, startDate, endDate) {
  const url = `/has/address/data?start_date=${startDate}&end_date=${endDate}&identifier=${id}&keys=response`;

  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getPixelDataTimeseries(id, startDate, endDate) {
  const url = `/has/pixel/data?start_date=${startDate}&end_date=${endDate}&identifier=${id}&keys=response`;

  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getExternalDataTimeseries(id, startDate, endDate) {
  const url = `/has/external_event/data?start_date=${startDate}&end_date=${endDate}&identifier=${id}&keys=response`;

  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

const getDB = () => openDB("api_cache", 3, {upgrade(upgradeDb) {
  if (!upgradeDb.objectStoreNames.contains('url_cache_id')) upgradeDb.createObjectStore('url_cache_id', {keyPath: "url"});
  if (!upgradeDb.objectStoreNames.contains('cache_id_data')) upgradeDb.createObjectStore('cache_id_data', {keyPath: "cache_id"});
}})

export function getConversionSegmentReportingData(id, startDate, endDate) {
  const statsUrl = `/data/v3/stats/attribution_events?start_date=${startDate}&end_date=${endDate}&identifier=${id}&keys=compiled_mta_tiers`;

  const promise = (url) => fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then((raw, request) => {
      const { details: { keys, cache_id } = {}, response } = raw;

      if (keys.length === 1) {
        const key = keys[0];
        const _cache_id = Object.values(cache_id[key])[0]
        const data = response[key];

        Object.entries(cache_id[key]).map(([date, id]) => {
          getDB().then(function(db) {
            const tx = db.transaction(['url_cache_id', 'cache_id_data'], 'readwrite');
            const url_cache = tx.objectStore('url_cache_id');
            const cache_data = tx.objectStore('cache_id_data');
            url_cache.put({ url, cache_id: _cache_id })
            cache_data.put({ cache_id: _cache_id, data })
            return tx.complete;
          })
        })

        return data;
      }
      return raw["response"];
    })

  return getDB()
    .then(db => {
      return new Promise((resolve, reject) => {


        fetch(statsUrl, { credentials: "include"})
          .then(response => response.json())
          .then(({ response: { current }}) => current.reduce((p, { date, id }) => Object.assign(p, {[date]: id}), {}) )
          .then(dateObj => {
            console.log(new Date())
            return Object.entries(dateObj).map(([k,v]) => {
              const url = `/cache/v3/attribution_events?start_date=${k}&end_date=${k}&identifier=${id}&keys=compiled_mta_tiers`

              return new Promise((resolve, reject) => {

                getDB()
                  .then(function(db) {
                    const tx = db.transaction(['url_cache_id','cache_id_data'], 'readwrite');
                    const url_cache = tx.objectStore('url_cache_id');
                    const cache_data = tx.objectStore('cache_id_data');
                    url_cache.get(url)
                      .then(response => {
                        const { cache_id = false } = response || {}

                        if (cache_id == v) {
                          cache_data.get(cache_id)
                            .then(({ data }) => resolve(data))
                          return true
                        }
                        if (cache_id && cache_id != v) {
                          cache_data.delete(cache_id)
                          url_cache.delete(url)
                        }
                        promise(url)
                          .then(resolve)
                      })
                  })
              })
            })
          })
          .then(promises => {
            console.log("created all promises", new Date())
            Promise.all(promises)
              .then(dates => {
                console.log("resolved all indexdb/gets", new Date())
                resolve(Object.values(dates))
              })
          })
      })
  })
}

export function getSummaryData(id, startDate, endDate) {
  const url = `/cache/v3/attribution_events?start_date=${startDate}&end_date=${endDate}&identifier=${id}&keys=summary`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(handleCacheResponse);
    // MORE STUFF HERE
}

export function getDailySpendTiersData(id, date) {
  const url = `/data/v3/attribution_events?date=${date}&identifier=${id}&keys=spend_tiers`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(handleCacheResponse)
    .then(data => data.map(d => { return { ...d, date } }))
}

export function getSpendTiersData(id, startDate, endDate) {
  const dateRange = Array.from(moment.range(startDate, endDate).by('day')).map(x => x.format('YYYY-MM-DD'));
  const requests = dateRange.map(date => getDailySpendTiersData(id, date));
  return Promise.all(requests)
    .then(r => r.flat());
}

export function getPostlogLift(id, startDate, endDate) {
  const url = `/data/v3/onsite_events?start_date=${startDate}&end_date=${endDate}&identifier=${id}&keys=postlog_lift`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(handleCacheResponse);
}

export function getSessions(id, startDate, endDate) {
  const url = `/data/v3/onsite_events?start_date=${startDate}&end_date=${endDate}&identifier=${id}&keys=minute_sessions`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      data["response"]["minute_sessions"].map(row => {
        Object.keys(row).map(k => {
          row[k] = parseInt(row[k]);
        });
        row.date = new Date(row.session_minutes);
      });
      return data["response"]["minute_sessions"];
    });
}

export function getSegmentFields() {
  const url = `/has/segment/fields`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getTierPreviews() {
  const url = `/tier_previews`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getUserNotifications(id) {
  const url = `/user/notifications?id=${id}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getAccountNotifications(id) {
  const url = `/account/notifications`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getNotificationTypes() {
  const url = `/user/notifications/types`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function setUserNotifications(id, obj) {
  const url = `/user/notifications?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(data => {
    return data;
  });
}

export function deleteNotifications(id) {
  const url = `/user/notifications?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    });
}

export function getBatchConfigData() {
  const url = `/batch/report_config`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getBatchConfigById(id) {
  const url = `/batch/report_config?id=${id}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function updateBatchTrigger(group_id, data) {
  const url = `/batch/group?group_id=${group_id}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function createBatchTrigger(data) {
  const url = "/batch/group";
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getBatchTriggers(group_id) {
  const url = group_id ? `/batch/group?group_id=${group_id}` : "/batch/group";
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getBatchTriggerLinks() {
  const url = "/batch/group/link";
  return fetch(url, { credentials: "include" })
    .then(res => res.json())
    .then(data => data.response);
}

export function getBatchTriggerData(group_id, upload_id) {
  const url = upload_id
    ? `/batch/group/accounting?group_id=${group_id}&upload_id=${upload_id}`
    : `/batch/group/accounting?group_id=${group_id}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getBatchTemplates() {
  const url = `/batch/templates`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getBatchInboxMessages(id) {
  const url = `/batch/inbox?id=${id}&metadata=True`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response[0];
    });
}

export function postBatchNotifyReplay(id) {
  const url = `/batch/notify_replay?notify_id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: '{}'
  })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}
export function getBatchInboxMessageHistory(inbox_id, message_id) {
  const url = `/batch/inbox/message/${inbox_id}/${message_id}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function updateBatchInboxUploadId(inbox_id, inbox_type, toggle) {
  const url = `/batch/inbox?id=${inbox_id}&inbox_type=${inbox_type}&toggle=${toggle}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({})
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

export function hasAction(startDate, endDate) {
  let url = `/has/action/data?start_date=${startDate}&end_date=${endDate}&keys=response`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getWebhooksData() {
  const url = `/webhook/transforms`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}
export function getEventData(id) {
  let url = `/has/event/data?event_id=${id}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function updateWebhook(id, obj) {
  const url = `/webhook/transforms?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

export function createWebhook(obj) {
  const url = `/webhook/transforms`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

export function getWebhookEvents(endpoint, day, utcHour) {
  const url = `/has/webhook?date=${day} ${utcHour}&endpoint=${endpoint}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function createWebhookTest(obj) {
  const url = `/webhook/test`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => response.json());
}

export function getLookupTables() {
  const url = `/uid_lookup_tables`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getFacebookData(type, startDate, endDate, id) {
  const url = `/cache/v3/attribution_events?start_date=${startDate}&end_date=${endDate}&identifier=${id}&keys=facebook_${type}s`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(handleCacheResponse);
}
export function getParsedEventData(id) {
  let url = `/has/event/data?event_id=${id}&event_type=parsed`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getWebhookDaily(endpoint, date) {
  const url = `/has/webhook/daily?endpoint=${endpoint}&date=${date}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getBatchInbox() {
  const url = `/batch/inbox`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getBatchReports() {
  const url = `/batch/report`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      data.response.map(row => {
        row.text = `${row.report_name}`;
        row.value = row.id;
      });
      return data.response;
    });
}

export function getBatchReportType() {
  const url = `/batch/report_types`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function updateBatchConfig(obj, id) {
  const url = `/batch/report_config?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

export function postBatchConfig(obj) {
  const url = `/batch/report_config`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(data => {
    return data;
  });
}

export function getSavedReportSchema(id) {
  const url = `/reporting/saved/schema?id=${id}`;
  return fetch(url, { credentials: "include" }).then(response =>
    response.json()
  );
}

export function modelTransforms() {
  const url = `/model/vector_transforms`;
  return fetch(url, { credentials: "include" }).then(response =>
    response.json()
  );
}

export function getInboxMessages(id) {
  const url = `/batch/inbox?id=${id}&metadata=True`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function postBatchReportTest(obj) {
  const url = `/batch/report_test`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => {
      return data;
    });
}

export function getReportSchema(id) {
  const url = `/reporting/docs${id ? `?id=${id}` : ""}`;
  return fetch(url, { credentials: "include" }).then(response =>
    response.json()
  );
}

export function sendReporting(obj) {
  const url = `/reporting/send`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => {
      return data;
    });
}

export function sendScheduledReporting(obj) {
  const url = `/reporting/send/scheduled`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => {
      return data;
    });
}

export function getRechargeAccount() {
  const url = `/auth/recharge/account`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function submitAttributionRunJob(path, data) {
  const url = `/run/${path}`;

  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(data => {
      return data.json();
    })
    .then(data => {
      return data.response;
    });
}
export function getUserData(filter_id, base_id) {
  const url = `/has/user?base_id=${base_id}&filter_id=${filter_id}`;
  return fetch(url, { credentials: "include" }).then(response =>
    response.json()
  );
}

export function getCrossDevice(id, startDate, endDate) {
  const url = `/cache/v3/attribution_events?start_date=${startDate}&end_date=${endDate}&identifier=${id}&keys=cross_device_by_tier`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(handleCacheResponse);
}

export function getTimeToConversion(id, startDate, endDate) {
  const url = `/cache/v3/attribution_events?start_date=${startDate}&end_date=${endDate}&identifier=${id}&keys=time_to_conversion`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(handleCacheResponse);
}

export function getSurveyConfigs() {
  const url = `/segments/survey`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data["response"];
    });
}

export function getSurveyResults(id, startDate, endDate) {
  const url = `/cache/v3/attribution_events?start_date=${startDate}&end_date=${endDate}&identifier=${id}&keys=survey_results,survey_stats`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data["response"];
    });
}

export function getProjects() {
  const url = `/model/project`;
  return fetch(url, { credentials: "include" }).then(response =>
    response.json()
  );
}
export function createProject(name) {
  const url = `/model/project`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify({
      name
    })
  }).then(response => response.json());
}
export function getProjectObject(project_id, obj_type) {
  const url = `/model/project/${project_id}/${obj_type}`;
  return fetch(url, { credentials: "include" }).then(response =>
    response.json()
  );
}
export function createProjectObject(project_id, obj_type, body) {
  const url = `/model/project/${project_id}/${obj_type}`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify({
      project_id,
      body
    })
  }).then(response => response.json());
}

export function interactiveStatus() {
  const url = `/interactive/status`;
  return fetch(url, { credentials: "include" }).then(response =>
    response.json()
  );
}
export function interactiveLaunch() {
  const url = `/interactive/launch`;
  return fetch(url, { credentials: "include" }).then(response =>
    response.json()
  );
}

export function interactiveLogin(token) {
  const url = `/interactive/kernel/login`;
  const formData = new FormData();
  formData.append("password", token);

  return axios({
    url: url,
    credentials: "include",
    method: "POST",
    data: formData
  });
}

export function interactiveGetKernel(notebook) {
  const url = `/interactive/kernel/api/sessions`;
  const body = {
    path: notebook,
    type: "notebook",
    name: "",
    kernel: { id: null, name: "python2" }
  };
  return fetch(url, {
    body: JSON.stringify(body),
    headers: {
      Accept: "application/json, text/javascript, */*; q=0.01",
      "Content-Type": "application/json"
    },
    method: "POST"
  });
}

export function postInvocaCredentials(obj) {
  const url = `/auth/invoca/account`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => response.json());
}

export function putInvocaCredentials(obj) {
  const url = `/auth/invoca/account/${obj["id"]}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => response.json());
}

export function postPodsightsCredentials(obj) {
  const url = `/auth/podsights/account`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => response.json());
}

export function putPodsightsCredentials(obj) {
  const url = `/auth/podsights/account/${obj["id"]}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => response.json());
}

export function postTheTradeDeskCredentials(obj) {
  const url = `/auth/the_trade_desk/account`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => response.json());
}

export function putTheTradeDeskCredentials(obj) {
  const url = `/auth/the_trade_desk/account`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => response.json());
}

export function getExcludedIdentifiers() {
  const url = "/account/identifer_exclusions";

  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(response => response.json());
}

export function postExcludeIdentifier(body) {
  const url = "/account/identifer_exclusions";
  return axios.post(url, body).then(response => {
    if (!response.data || !response.data.response) {
      throw new Error(`Received invalid response from ${url}`);
    }
    return response.data.response;
  });
}

export function deleteExcludeIdentifier(id) {
  const url = `/account/identifer_exclusions?id=${id}`;
  return axios.delete(url).then(response => {
    if (!response.data || !response.data.response) {
      throw new Error(`Received invalid response from ${url}`);
    }
    return response.data.response;
  });
}

export function createInbox(obj) {
  const url = `/batch/inbox`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(obj)
  }).then(response => response.json());
}

export function getWebhookTemplates() {
  const url = `/webhook/templates`;

  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getPatternUids(pattern) {
  const now = moment(new Date()).format("YYYY-MM-DD 00:00:00");
  const url = `/has/pattern/uid?action=${pattern}&date=${now}`;
  return fetch(url, {
    credentials: "include",
    method: "GET"
  }).then(response => response.json());
}

export function getUIDAction(uid, pattern) {
  const url = `/has/uid_action/data?action=${pattern}&uid=${uid}`;
  return fetch(url, {
    credentials: "include",
    method: "GET"
  }).then(response => response.json());
}

export function getTransformTables() {
  const url = `/transform/table`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getRawTransformTableDataById(id) {
  const url = `/data/transform_table/raw?id=${id}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getRawTransformTableData(id) {
  const url = `/data/transform_table/raw?acetl_report_id=${id}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getTransformTableData(id, _with) {
  let url = `/data/transform_table?id=${id}`;
  url += _with ? `&with=${_with}` : "";
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function testTransformTableData(data, id) {
  let url = `/data/transform_table?acetl_report_id=${id}`;

  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function testTransformTableSyntax(data) {
  let url = `/transform/table/syntax`;

  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function createTransformTable(data) {
  let url = `/transform/table`;

  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function updateTransformTables(data, id) {
  let url = `/transform/table?id=${id}`;

  return fetch(url, {
    credentials: "include",
    method: "PUT",
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function deleteEntityData(id) {
  const url = `/entity?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "DELETE"
  }).then(response => response.json());
}

export function postEntityData(obj) {
  const url = `/entity`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}
export function postEntityProgram(obj) {
  const url = `/entity/program`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(obj)
  }).then(response => response.json())
    .catch(err => console.log(`Error posting entity: ${err}`));
}

export function updateEntityData(data, id) {
  let url = `/entity?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function updateEntityProgram(data, id) {
  let url = `/entity/program?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    body: JSON.stringify(data)
  }).then(response => response.json());
}

export function getEntityResults(
  id,
  startDate = yesterday,
  endDate = yesterday
) {
  const url = `/cache/v3/attribution_events?start_date=${startDate}&end_date=${endDate}&identifier=${id}&keys=entity_stats`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(handleCacheResponse);
}

export function getEntityProgramConfigs() {
  const url = `/entity/program`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}
export function getEntityConfigs() {
  const url = `/entity`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}
export function getTransformTableColumns(id) {
  const url = `/data/lookup_transform?lookup_transform_id=${id}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getSurveyData() {
  const url = `/segments/survey`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function postSurveyData(obj) {
  const url = `/segments/survey`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(obj)
  }).then(response => response.json());
}

export function updateSurveyData(data, id) {
  let url = `/segments/survey?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getPlatformFeatures() {
  const url = `/account/integrations/features`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function addPlatformFeature(platform, id) {
  const url = `/account/integrations/features`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify({ platform, id })
  })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getAllIntegrations() {
  const url = `/account/integrations/all`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getIntegrations() {
  const url = `/account/integrations`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export const getIntegrationFeatures = () => {
  return Promise.all([
    getIntegrations(),
    getPlatformFeatures(),
    getAllIntegrations()
  ]).then(([data, features, platforms]) => {
    //if (data.length < 1) return { platformTypes: [], platformFeatures: {}, integrations: [] }
    const platformFeatures = d3
      .nest()
      .key(x => x.platform)
      .map(features);

    const platformTypes = d3
      .nest()
      .key(x => x.category)
      .key(x => x.platform)
      .entries(platforms)
      .map(({ key, values }) => {
        const platforms = values
          .map(({ key }) => {
            return { key, values: platformFeatures[key] };
          })
          .filter(({ values }) => !!values);
        return { key, values: platforms };
      });

    const integrations = d3
      .nest()
      .key(x => x.platform)
      .key(x => x.display_name)
      .entries(data)
      .map(platformRow => {
        const { key, values } = platformRow;
        const allFeatures = platformFeatures[key]
          .filter(f => !f["deprecated"])
          .map(k => k.display_name);
        const usedFeatures = values.map(({ key }) => key);
        const options = allFeatures
          .filter(k => !usedFeatures.includes(k))
          .map(key => {
            return { key, text: key, value: key };
          });
        const first = values[0].values[0];
        const { platform_type, display_name } = first;
        const { category } =
          platforms.find(({ platform }) => platform == key) || {};
        return { key, platform_type, values, options, display_name, category };
      });

    return { platformTypes, platformFeatures, integrations };
  });
};

export function getReportTypes() {
  const url = `/reporting/types`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function sendReportingData(obj) {
  const url = `/reporting/send`;
  return fetch(url, {
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify(obj)
  }).then(response => response.json());
}

export function submitCustomSchema(body, id) {
  const url = `/reporting/saved/schema?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(body)
  }).then(response => response.json());
}

export function getOutboxes() {
  return axios.get("/account/outbox/data").then(d => d.data.response);
}

export function getOutboxById(id) {
  return axios.get(`/account/outbox?id=${id}`).then(d => d.data.response);
}

export function getOutboxWithoutDetails() {
  return axios.get(`/account/outbox`).then(d => d.data.response);
}

export function postNewOutbox(obj) {
  return axios
    .post("/account/outbox", obj)
    .then(() => axios.get("/account/outbox"))
    .then(d => d.data);
}

export function getCrossDeviceConfig() {
  const url = `/cross_device/config`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function createCrossDeviceConfig(body) {
  const url = `/cross_device/config`;

  return axios.post(url, body).then(data => {
    return data.response;
  });
}

export function updateCrossDeviceConfig(body) {
  const url = `/cross_device/config`;

  return axios.put(url, body).then(data => {
    return data.response;
  });
}

export function getPixelCnameDomain(force_run_test) {
  const url = `/pixels/cname${force_run_test ? "?force_run_test=true" : ""}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => data.response);
}

export function postPixelCnameDomain(obj) {
  const url = `/pixels/cname`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getAmazonAdsAccount() {
  const url = `/auth/amazon_ads/account`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => data.response);
}

export function getAmazonMWSAccount() {
  const url = `/auth/amazon_mws/credentials`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => data.response);
}

export function postAmazonMWSCredentials(obj) {
  const url = `/auth/amazon_mws/credentials`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getTransformEnableStatus() {
  const url = `/account/settings/transform_table`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => data.response);
}

export function postEnableTransformStatus(obj) {
  const url = `/account/settings/transform_table`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function updateEnableTransformStatus(data) {
  let url = `/account/settings/transform_table`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    body: JSON.stringify(data)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

export function fetchCreativeTierDateCount(
  keys,
  startDate,
  endDate,
  filter_id
) {
  const url = `/data/v3/creative/onsite_events?keys=${keys}&start_date=${startDate}&end_date=${endDate}&identifier=${filter_id}`;

  return fetch(url, {
    credentials: "include"
  }).then(response => response.json());
}

export function fetchServedSummary(key, startDate, endDate, filter_id) {
  const url = `/data/v3/onsite_events?keys=${key}&start_date=${startDate}&end_date=${endDate}&identifier=${filter_id}`;

  return fetch(url, {
    credentials: "include"
  })
    .then(response => response.json())
    .then(data => {
      const summary = data["response"][key];
      const details = data["details"]["cache_id"][key];
      const dates = Object.keys(details).map(
        datetime => datetime.split(" ")[0]
      );

      return {
        summary,
        dates
      };
    });
}

export function fetchServedOffsetData(key, startDate, endDate, filter_id) {
  const url = `/data/v3/onsite_events?keys=${key}&start_date=${startDate}&end_date=${endDate}&identifier=${filter_id}`;

  return fetch(url, {
    credentials: "include"
  })
    .then(response => response.json())
    .then(data => {
      const summary = data["response"][key];
      const details = data["details"]["cache_id"][key];
      const dates = Object.keys(details).map(
        datetime => datetime.split(" ")[0]
      );

      return {
        summary,
        dates
      };
    });
}

export function getOttOffset() {
  const url = "/ott/offset";
  return fetch(url, {
    credentials: "include"
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getOttTypes() {
  const url = "/ott/offset/types";
  return fetch(url, {
    credentials: "include"
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function updateOrCreateOttOffset(type, key, offset) {
  const url = "/ott/offset";
  const body = {
    type,
    offset: {
      [key]: offset
    }
  };

  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getAccountCurrency() {
  const url = "/account/currency/reporting";
  return fetch(url, {
    credentials: "include"
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function updateAccountCurrency(data) {
  let url = `/account/currency/reporting`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    body: JSON.stringify(data)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

export function postAccountCurrency(data) {
  const url = `/account/currency/reporting`;

  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getAggregationGroupings() {
  const url = "/account/aggregation/groupings";
  return fetch(url, {
    credentials: "include"
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function postAggregationGroupings(data) {
  const url = `/account/aggregation/groupings`;

  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function updateAggregationgroupings(data, id) {
  let url = `/account/aggregation/groupings?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    body: JSON.stringify(data)
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.response;
    });
}

export function checkJobWithToken(token) {
  const url = `/run/check/job/token?token=${token}`;
  return fetch(url)
    .then(response => response.json())
    .then(data => data.response);
}

export function postFormattedHelpDoc(slug, context) {
  const url = `/help_docs?slug=${slug}`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(context),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getValidationQuery() {
  const url = "/account/validation_query";
  return fetch(url, {
    credentials: "include"
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getRunningReportJobs(date) {
  const url = `/reporting/running?date=${date}`;
  return fetch(url, {
    credentials: "include"
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getDeliveredFile(id) {
  return axios
    .get(`/reporting/delivered/files?id=${id}`)
    .then(d => d.data.response);
}

export function updateScheduledReport(obj) {
  return axios.put("/reporting/scheduled", obj);
}

export function getSavedreports() {
  return axios.get("/reporting/saved").then(d => d.data.response);
}

export function sendSavedReport(obj) {
  return axios.post("/run/send/report/", obj).then(d => d.data);
}

export function sendReportTransform(obj) {
  const url = "/run/send/report/transform";
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      if (data["error"]) {
        throw new Error(data["error"]);
      } else {
        return data["response"];
      }
    });
}

export function sendReportSchema(obj) {
  const url = "/run/send/report/transform/schema";
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      if (data["error"]) {
        throw new Error(data["error"]);
      } else {
        return data["response"];
      }
    });
}

export function getReportTypeLimitBySegment(id) {
  return axios.get(`/reporting/types/limit?filter_id=${id}`).then(d => d.data);
}

export function getErrorMessage(job_id) {
  return axios
    .get(`/reporting/error/message?job_id=${job_id}`)
    .then(d => d.data);
}

export function getTierColors() {
  const url = `/account/settings/tier_colors`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(({ response }) =>
      response.reduce((acc, { tier_1, color }) => {
        acc[tier_1] = color;
        return acc;
      }, {})
    );
}

export function saveTierColor(obj) {
  const url = `/account/settings/tier_colors`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function deleteTierColor(obj) {
  const url = `/account/settings/tier_colors`;
  return fetch(url, {
    credentials: "include",
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getPixelValidateData(platform) {
  const url = `/pixels/validate?platform=${platform}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => data.response);
}
export function getArtifacts() {
  const url = `/artifacts`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => data.response);
}

export function getDataWarehouse() {
  const url = `/data_warehouse`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => data.response);
}

export function getDataWarehouseConnections() {
  const url = `/data_warehouse/connections`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => data.response);
}

export function getFeatureWaitlist(feature) {
  const url = `/account/feature_waitlist?feature=${feature}`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function postFeatureWaitlist(obj) {
  const url = `/account/feature_waitlist`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function fetchAwsRegions() {
  const url = "/data_warehouse/aws/regions";
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(response => response.json());
}

export function fetchClusterName(id) {
  const url = `/data_warehouse/connections?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(response => response.json());
}

export function runAwsRedshiftInit(obj) {
  const url = "/run/setup/data_warehouse/aws/init";
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => {
    if (response.status > 299 || response.status < 200) {
      throw new Error(response.json());
    }
    return response.json();
  });
}

export function fetchRedshiftSetupScript() {
  const url = `/data_warehouse/aws/setup_script`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(response => response.response);
}

export function fetchDatasetMeta(id) {
  const url = `/data_warehouse/datasets?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(response => response.response);
}

export function fetchDatasetMetaAccounting(dataset_id, date) {
  const url = `/data_warehouse/datasets/accounting?dataset_id=${dataset_id}&date=${date}`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(response => response.response);
}

export function createNewWarehouseDataset(body) {
  const url = `/data_warehouse/datasets`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => {
      if (response.status > 299 || response.status < 200) {
        throw new Error(response.json());
      }
      return response.json();
    })
    .then(response => response.response);
}

export function deletewWarehouseDataset(id) {
  const url = `/data_warehouse/datasets?dataset_id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => {
      if (response.status > 299 || response.status < 200) {
        throw new Error(response.json());
      }
      return response.json();
    })
    .then(response => response.response);
}

export function fetchSnapshotMeta(datasetId, snapshotId = null) {
  const url = snapshotId
    ? `/data_warehouse/snapshots?dataset_id=${datasetId}&id=${snapshotId}`
    : `/data_warehouse/snapshots?dataset_id=${datasetId}`;

  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(response => response.response);
}

export function fetchSnapshotSchedule(datasetId) {
  const url = `/data_warehouse/snapshot/schedule?dataset_id=${datasetId}`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(response => response.response);
}

export function insertNewSnapshotSchedule(data) {
  const url = `/data_warehouse/snapshot/schedule`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(response => {
      if (response.status > 299 || response.status < 200) {
        throw new Error(response.json());
      }
      return response.json();
    })
    .then(response => response.response);
}

export function deleteSnapshotSchedule(scheduleId) {
  const url = `/data_warehouse/snapshot/schedule?schedule_id=${scheduleId}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ active: false })
  })
    .then(response => {
      if (response.status > 299 || response.status < 200) {
        throw new Error(response.json());
      }
      return response.json();
    })
    .then(response => response.response);
}

export function insertNewSnapshot(data) {
  const url = "/data_warehouse/snapshots";
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  }).then(response => {
    if (response.status > 299 || response.status < 200) {
      throw new Error(response.json());
    }
    return response.json();
  });
}

export function crossDeviceDelete(id) {
  const url = `/cross_device/config?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(response => {
    if (response.status > 299 || response.status < 200) {
      throw new Error(response.json());
    }
    return response.json();
  });
}

export function getPixelIntegrations() {
  const url = `/pixels/integration`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function postPixelIntegrations(obj) {
  const url = `/pixels/integration`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getChannelGroups() {
  const url = `/channel_group`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function postChannelGroup(obj) {
  const url = "/channel_group";
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(data => {
    return data;
  });
}

export function updateChannelGroup(obj, id) {
  const url = `/channel_group?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => {
    return response.json();
  });
}

export function deleteChannelGroup(id) {
  const url = `/channel_group?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "DELETE"
  })
    .then(response => response.json())
    .then(data => {
      return data.success;
    });
}

export function getExperiments() {
  const url = `/experiments`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response
          .map(platform => Object.assign(platform, {'platform': platform.platform ? platform.platform.split("_")[0] : ''}));
    });
}

export function postExperiment(obj) {
  const url = "/experiments";
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => {
    return response.json();
  });
}

export function updateExperiment(obj, id) {
  const url = `/experiments/${id}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => {
    return response.json();
  });
}

export function getEnabledExperimentPlatforms() {
  const url = `/account/integrations`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      const integrations = data.response;
      return integrations
        .filter(platform => !!platform.platform && platform.deleted==0 && platform.active==1 && platform.experiments_enabled)
        .map(platform => Object.assign(platform, {'platform': platform.platform ? platform.platform.split("_")[0] : ''}))
        .map(i => ({text: i.display_name, key: i.platform, value: i.platform, version: i.version}));
    });
}

export function getEventTypeResults(id, startDate, endDate) {
  const url = `/event_types?identifier=${id}&start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, { credentials: "include" })
      .then(response => response.json());
}

export function getPlatformComparisonConfigs() {
  const url = `/platform_comparison`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(({ response }) => response)
}


export function updatePlatformComparisonConfigs(obj, id) {
  const url = `/platform_comparison?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(response => {
    return response.json();
  });
}

export function postPlatformComparisonConfigs(obj) {
  const url = '/platform_comparison';
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
  .then(data => {
    return data;
  });
}

export function deletePlatformComparisonConfigs(id) {
  const url = `/platform_comparison?id=${id}`;
  return fetch(url, {
      credentials: "include",
      method: "DELETE"
    })
    .then(response => response.json())
    .then(data => {
      return data.success;
    });
}

export function getFirstVisitsTiers(filterId, startDate, endDate) {
  const url = `/cache/v3/onsite_events?identifier=${filterId}&keys=first_visits_tiers&start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
  .then(response => response.json())
  .then(({ response }) => response)
}

export function getVendorNofications() {
  const url = `/vendor_notification_links`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(response => response.response)
}

export function deleteVendorNofications(id) {
  const url = `/vendor_notification_links/${id}`;
  return fetch(url, {
    credentials: "include",
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(response => response.response)
}


export function removeNotification(id) {
  const url = `/account/notifications/${id}`;

  return fetch(url, {
      credentials: "include",
      method: "DELETE"
    })
    .then(response => response.json())
    .then(data => data.response);
}

export function getDataFeatures() {
  const url = `/features`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function snoozeIntegrationNotification(id) {
  const url = `/account/integrations/features/${id}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    body: JSON.stringify({ notification_active: 0 })
  })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getSubscriptionsStatus() {
  const url = `/subscriptions`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(response => response.response)
}

export function getNotificationSubscriptions() {
  const url = `/advertiser_notification_subscription`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(response => response.response)
}

export function postUnsubscribe(id) {
  const url = `/subscription/unsubscribe`;
  const obj = {"subscription_id":id}
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(response => response)
}

export function deleteUnsubscribe(id) {
  const url = `/subscription/unsubscribe?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "DELETE",
  })
    .then(response => response.json())
    .then(response => response)
}

export function getScheduledreports() {
  return axios.get("/reporting/scheduled").then(d => d.data.response);
}

export function getPathsWDetails(id, tier, start, end) {
  const path_key = `path_t${tier}`
  const url = `/cache/v3/attribution_events?identifier=${id}&start_date=${start}&end_date=${end}&keys=${path_key}`;
    return fetch(url, { credentials: "include" })
    .then(response => response.json())
      .then(response => {
        return {'data': response.response[path_key],
                  'details': response.details.cache_id[path_key]}
      })
    .catch((error) => console.error(error.message))
}

export function getPlatformPerfomanceData(platform, startDate, endDate) {
  const url = `/report_cache/data/platform_performance_${platform}?start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"}
  })
    .then(response =>  response.json())
    .catch((error) => console.error(error.message))
}

export function getPlatformPerfomanceMeta(platform) {
  const url = `/report_cache/meta/platform_performance_${platform}`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
  }})
    .then(response =>  response.json() )
        .catch((error) => console.error(error.message))
}

export function getPlatformPerfomanceSyncStatus(platform, startDate, endDate) {
  const url = `/report_cache/sync_status/platform_performance_${platform}?start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
  }})
    .then(response =>  response.json() )
    .then(response =>  response.response )
    .catch((error) => console.error(error.message))
}

export function getPlatformPerformanceSummary(platform, startDate, endDate) {
  const url = `/report_cache/summary/platform_performance_${platform}?start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
  }})
    .then(response =>  response.json() )
    .then(response =>  response.data )
        .catch((error) => console.error(error.message))
}

export function postSignup(obj) {
  const url = `/account/signup`;

  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(response => response.response)
}

export function postUserLoginToken(token) {
  const url = `/user/login/token`;

  const postObj = { token }
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(postObj)
  })
  .then(response => response.json())
  .then(response => response.response)
}

export function getChannelTrends() {
  const url = `/trends/channels`
  return fetch(url, {
    credentials: 'include'
  })
    .then(response => response.json())
    .then(data => data.response)
}

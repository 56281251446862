import React from 'react';

const MCCWarning = () => (
  <div style={{ paddingTop: '10px' }}>
    <p>Unfortuantely, due to limitations with Google Ads, we are unable to pull metrics from a Manager account.</p>
    <p>
      Please select a sub-account to connect with Rockerbox. If you have multiple accounts, you will need to authenticate each sub-account separately.
    </p>
    <p>
      For more info or instructions on how to grant sub-account access, see
      <a href="http://bit.ly/AdWordsManagerAccounts" target="_blank" rel="noreferrer">bit.ly/AdWordsManagerAccounts</a>
      .
    </p>
    <p>If you need to, you can come back and refresh this page after you&apos;ve updated your account access permissions.</p>
  </div>
);

export default MCCWarning;

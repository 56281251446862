import React from 'react';

import { groupBy } from 'lodash';
import toast from 'react-hot-toast';
import { Table } from 'semantic-ui-react';

import { formatCurrency, numberFormatter } from '../../../../utils/valueFormatter';
import { roundNumber } from '../../helpers';
import { MetricComparisonCell, ChannelCell, CurrencyCell, NumberCell, TargetComparisonCell, KPIComparisonCell, PercentCell, SpendTableHeader, SpendInputCell } from '../../parts/tableCells';

/**
 * Creates an array of column objects for a table.
 *
 * @param {boolean} isConversionKPI - Indicates whether the KPI is conversion or revenue.
 * @param {object} featureStats - Statistics related to the features.
 * @param {function} updateFieldValues - Function to update field values.
 * @param {array} columnGroups - Array of optional column groups to include.
 * @param {string} summaryText - Text to be displayed in the summary label.
 * @returns {Array} - Array of column objects.
 */

export const createColumns = (isConversionKPI, featureStats, updateFieldValues, columnGroups, nameByPeriod, summaryText) => {
  const { projectionName, budgetPeriodName } = nameByPeriod;
  const kpiText = isConversionKPI ? 'Conversions' : 'Revenue';
  const metricText = isConversionKPI ? 'CPA' : 'ROAS';
  const kpiFormatter = value => (isConversionKPI ? numberFormatter(value) : formatCurrency(value, 'USD', 0));
  const metricFormatter = value => (isConversionKPI ? formatCurrency(value, 'USD', 2) : roundNumber(value, 2));
  const handleInput = (input, max) => {
    if (input < 0) return 0;
    if (input > max) {
      toast('The spend you entered is outside the bounds of our predictive model', { icon: 'ℹ️', id: 'budget-forecast-input' });
      return roundNumber(max);
    }
    return input;
  };
  const includeGroups = ['default', ...columnGroups];

  const allColumns = [
    {
      display: 'Channel / Tactic',
      key: 'display',
      headerStyle: { textAlign: 'left' },
      groupBy: ['forecastType', 'key'],
      as: ChannelCell,
      colSpan: 1,
      summaryLabel: <p style={{ textAlign: 'left', width: 250 }}>{summaryText}</p>,
      columnType: 'default',
    },
    {
      colSpan: 4,
      primaryColumn: `${budgetPeriodName} Budget`,
      display: 'Baseline',
      key: 'currentValue',
      as: CurrencyCell,
      reducer: data => data.reduce((acc, item) => acc + item.currentValue, 0),
      columnType: 'baseline',
    },
    {
      colSpan: 4,
      primaryColumn: `${budgetPeriodName} Budget`,
      display: <SpendTableHeader metricText={metricText} budgetPeriodName={budgetPeriodName} />,
      key: 'targetValue',
      as: SpendInputCell,
      updateFunction: updateFieldValues,
      handleInput,
      featureStats,
      reducer: data => data.reduce((acc, item) => acc + item.targetValue, 0),
      columnType: 'default',
    },
    {
      colSpan: 4,
      primaryColumn: `${budgetPeriodName} Budget`,
      display: '% Δ',
      key: 'targetPercentDiff',
      as: TargetComparisonCell,
      reducer: () => 0,
      formatter: kpiFormatter,
      columnType: 'delta',
    },
    {
      colSpan: 4,
      primaryColumn: `${budgetPeriodName} Budget`,
      display: '% of Total',
      key: 'spendShare',
      as: PercentCell,
      formatter: x => parseInt(roundNumber(x, 3) * 1000) / 10,
      columnType: 'totalPercent',
    },
    {
      colSpan: 1,
      primaryColumn: '',
      display: '',
      key: '',
      as: () => <Table.Cell style={{ backgroundColor: '#F9FAFB' }} />,
      columnType: 'default',
    },
    {
      colSpan: 1,
      primaryRowSpan: 2,
      primaryColumn: `Projected ${projectionName} Budget`,
      key: 'estimatedTotalValue',
      as: CurrencyCell,
      reducer: data => data.reduce((acc, item) => acc + item.estimatedTotalValue, 0),
      columnType: 'default',
    },
    {
      colSpan: 1,
      primaryColumn: ' ',
      display: '',
      key: '',
      as: () => <Table.Cell style={{ backgroundColor: '#F9FAFB' }} />,
      columnType: 'default',
    },
    {
      colSpan: 4,
      primaryColumn: `Projected ${projectionName} ${kpiText}`,
      display: 'Baseline',
      key: 'totalKPIAtCurrentValue',
      as: isConversionKPI ? NumberCell : CurrencyCell,
      reducer: data => data.reduce((acc, item) => acc + item.totalKPIAtCurrentValue, 0),
      columnType: 'baseline',
    },
    {
      colSpan: 4,
      primaryColumn: `Projected ${projectionName} ${kpiText}`,
      display: 'Proposed',
      key: 'totalKPIAtTargetValue',
      as: isConversionKPI ? NumberCell : CurrencyCell,
      reducer: data => data.reduce((acc, item) => acc + item.totalKPIAtTargetValue, 0),
      columnType: 'default',
    },
    {
      colSpan: 4,
      primaryColumn: `Projected ${projectionName} ${kpiText}`,
      display: '% Δ',
      key: 'kpiPercentDiff',
      as: KPIComparisonCell,
      reducer: data => {
        const totalKPIAtTargetValue = data.reduce((acc, item) => acc + item.totalKPIAtTargetValue, 0);
        const totalKPIAtCurrentValue = data.reduce((acc, item) => acc + item.totalKPIAtCurrentValue, 0);
        return (totalKPIAtTargetValue - totalKPIAtCurrentValue) / totalKPIAtCurrentValue;
      },
      formatter: kpiFormatter,
      columnType: 'delta',
    },
    {
      colSpan: 4,
      primaryColumn: `Projected ${projectionName} ${kpiText}`,
      display: '% of Total',
      key: 'effectShare',
      as: PercentCell,
      formatter: x => parseInt(roundNumber(x, 3) * 1000) / 10,
      columnType: 'totalPercent',
    },
    {
      colSpan: 1,
      primaryColumn: '  ',
      display: '',
      key: '',
      as: () => <Table.Cell style={{ backgroundColor: '#F9FAFB' }} />,
      columnType: 'default',
    },
    {
      colSpan: 3,
      primaryColumn: `${metricText}`,
      display: 'Baseline',
      key: 'metricAtCurrentValue',
      as: isConversionKPI ? CurrencyCell : NumberCell,
      reducer: data => {
        const spendOverKPI = data.reduce((acc, item) => acc + item.estimatedTotalValue, 0) / data.reduce((acc, item) => acc + item.totalKPIAtCurrentValue, 0);
        return isConversionKPI ? spendOverKPI : 1 / spendOverKPI;
      },
      sigfigs: 2,
      columnType: 'baseline',
    },
    {
      colSpan: 3,
      primaryColumn: `${metricText}`,
      display: 'Proposed',
      key: 'metricAtTargetValue',
      as: isConversionKPI ? CurrencyCell : NumberCell,
      reducer: data => {
        const spendOverKPI = data.reduce((acc, item) => acc + item.estimatedTotalValue, 0) / data.reduce((acc, item) => acc + item.totalKPIAtTargetValue, 0);
        return isConversionKPI ? spendOverKPI : 1 / spendOverKPI;
      },
      sigfigs: 2,
      columnType: 'default',
    },
    {
      colSpan: 4,
      primaryColumn: `${metricText}`,
      display: 'Overall Δ',
      key: 'metricDiff',
      as: MetricComparisonCell(isConversionKPI),
      reducer: data => {
        const spendOverKPITargetMetric = data.reduce((acc, item) => acc + item.estimatedTotalValue, 0) / data.reduce((acc, item) => acc + item.totalKPIAtTargetValue, 0);
        const spendOverKPICurrentMetric = data.reduce((acc, item) => acc + item.estimatedTotalValue, 0) / data.reduce((acc, item) => acc + item.totalKPIAtCurrentValue, 0);
        return isConversionKPI ? spendOverKPITargetMetric - spendOverKPICurrentMetric : 1 / spendOverKPITargetMetric - 1 / spendOverKPICurrentMetric;
      },
      formatter: metricFormatter,
      columnType: 'delta',
    },
    {
      colSpan: 3,
      primaryColumn: `${metricText}`,
      display: 'Marginal / Incremental',
      key: 'incrementalMetric',
      as: isConversionKPI ? CurrencyCell : NumberCell,
      sigfigs: 2,
      columnType: 'default',
    },
    /*
    {
      display: 'Spend Share',
      key: 'spendShare',
      as: PercentCell,
      formatter: x => parseInt(roundNumber(x, 3) * 1000) / 10,
    },
    {
      display: 'Effect Share',
      key: 'effectShare',
      as: PercentCell,
      formatter: x => parseInt(roundNumber(x, 3) * 1000) / 10,
    }, */
  ];

  const includedColumns = allColumns.filter(col => includeGroups.includes(col.columnType));

  const primaryGroups = groupBy(includedColumns, 'primaryColumn');

  includedColumns.forEach(col => {
    // eslint-disable-next-line no-param-reassign
    col.colSpan = primaryGroups[col.primaryColumn].length;
  });

  return includedColumns;
};

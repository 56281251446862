import React from 'react';

import PropTypes from 'prop-types';
import { Segment, Statistic, Popup } from 'semantic-ui-react';

const StatCard = props => {
  const { type, value, size, label, text, color, style, explainer, segmentColor, maxDigits, currencyCode, onClick } = props;
  const numberFormatOptions = {
    style: type || 'decimal',
    maximumFractionDigits: maxDigits || 0,
  };
  if (type === 'currency') numberFormatOptions.currency = currencyCode || 'USD';
  if (type === 'percent') numberFormatOptions.style = 'percent';

  const numberFormatter = new Intl.NumberFormat('en-US', numberFormatOptions);
  const formattedValue = value ? !text ? numberFormatter.format(value) : '-' : value;

  return (
    <Segment color={segmentColor} textAlign="center" className="stat" onClick={onClick} style={{ cursor: onClick ? 'pointer' : undefined, ...style }}>
      <Statistic color={color || 'blue'} size={size}>
        <Statistic.Value text={!!text}>{formattedValue }</Statistic.Value>
        <Statistic.Label>
          {explainer
            ? (
              <Popup
                hoverable inverted position="right center"
                trigger={<span>{label}</span>}
              >
                <Popup.Content>
                  {explainer}
                </Popup.Content>
              </Popup>
            )
            : label}
        </Statistic.Label>
      </Statistic>
    </Segment>
  );
};

StatCard.propTypes = {
  /** decimal is the default, optional: currency, percent  */
  type: PropTypes.string,
  /** Value content inside of stat card */
  value: PropTypes.number,
  /** mini, tiny, small, large, huge */
  size: PropTypes.string,
  /** Stat value color -- red, orange, yellow, olive, green, teal, blue, violet, purple, pink, brown, grey, black */
  color: PropTypes.string,
  /** Smaller text under value */
  label: PropTypes.string,
  /** Label hover text */
  explainer: PropTypes.string,
  /** top border of the stat card -- red, orange, yellow, olive, green, teal, blue, violet, purple, pink, brown, grey, black */
  segmentColor: PropTypes.string,
};

export default StatCard;

export const getLocalJSON = key => JSON.parse(localStorage.getItem(key) || '{}');

export const setLocalJSON = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export function checkJobStatus(submissionId) {
  const url = `/run/check/job?job_id=${submissionId}`;
  return fetch(url, { credentials: 'include' })
    .then(response => response.json())
    .then(data => data.response);
}

export function generateResponseBucketData(modelData) {
  return modelData.raw.response_curves.reduce((obj, x) => {
    Object.assign(obj, { [x.feature_name]: obj[x.feature_name] || [] });
    obj[x.feature_name].push(x.spend_input);
    return obj;
  }, {});
}

function createEmptyFeatureCountObj(modelData) {
  return modelData.features.reduce((p, c) => Object.assign(p, { [c]: {} }), {});
}

export function computeFeatureHistograms(modelData) {
  const featureNames = modelData.features;
  const responseBuckets = generateResponseBucketData(modelData);
  const featureSpendCounts = createEmptyFeatureCountObj(modelData);

  modelData.trainingData.forEach(day => {
    featureNames.forEach(feature => {
      const featureSpendOnDay = day[feature];
      const bucket = responseBuckets[feature].filter(threshold => threshold < featureSpendOnDay).slice(-1)[0];
      featureSpendCounts[feature][bucket] = featureSpendCounts[feature][bucket] || 0;
      featureSpendCounts[feature][bucket] += 1;
    });
  });

  return featureSpendCounts;
}

import React from 'react'
import { Modal, Button, Loader, Dimmer } from 'semantic-ui-react'
import { CloseButtonStyles } from '../../styled'

export const ConfirmDeleteModal = ({ title, open, close, loading, deleteAccount, activePlatform, activeAccountRowId }) => (
  <Modal size="small" open={open} onClose={close} closeOnDimmerClick={true}>
    <Modal.Header><CloseButtonStyles onClick={close}>x</CloseButtonStyles>{ title }</Modal.Header>
    <Modal.Content>

      <Modal.Description style={{textAlign: 'center', fontSize: '14px'}}>
        { loading &&
          <div>
            <Dimmer active inverted>
              <Loader active inline='centered' />
            </Dimmer>
          </div>
        }
        <div>Deleting this account could cause a disruption in data ingestion for your Rockerbox account.</div>
        <div>Are you sure you'd like to delete this account?</div>
        <div style={{marginTop: '10px'}}>
          <Button onClick={close}>Cancel</Button>
          <Button negative onClick={() => deleteAccount(activePlatform, activeAccountRowId)}>Delete</Button>
        </div>
      </Modal.Description>
    </Modal.Content>
  </Modal>
)

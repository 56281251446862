import React from "react";
import { Message } from "semantic-ui-react";

import { ColumnLayout } from "@rockerbox/styleguide";
import DisplayTable from "./DisplayTable";
import EditorWindow from "./EditorWindow";

const Preview = (props) => {
  const {
    inboundSchema,
    setInboundSchema,
    displayData,
    editorError,
    setEditorError,
  } = props;

  const hasError = editorError && editorError.length > 0;

  const ErrorMsg = (
    <Message
      error
      header="There is error in Inbound Schema, Please fix it before continue"
      list={editorError.map(
        (item) => `${item.text} in row ${item.row}`
      )}></Message>
  );
  const InfoMsg = (
    <Message
      info
      header="This is auto generated inbound schema based on report data"
      content="Please review it every field in inbound schema json object and make sure all types are correct. Type value must be one of (str, int, float, datetime). Incorrect type value will cause transform test in next step failed."
    />
  );

  const editorView = (
    <React.Fragment>
      {hasError ? ErrorMsg : InfoMsg}
      <EditorWindow
        nane="inboundSchema"
        value={inboundSchema}
        onChange={(obj) => setInboundSchema((prevState, curProps) => obj)}
        onValidate={(obj) => setEditorError((prevState, curProps) => obj)}
      />
    </React.Fragment>
  );

  const displayTable = (
    <React.Fragment>
      <Message content="This is a preview of raw report data" info />
      <div style={{ width: "100%", overflow: "scroll" }}>
        <DisplayTable displayData={displayData} />
      </div>
    </React.Fragment>
  );

  return (
    <ColumnLayout
      leftWidth={9}
      rightWidth={6}
      leftContent={displayTable}
      rightContent={editorView}></ColumnLayout>
  );
};

export default Preview;

export const maintenance = '/maintenance';

const V3Base = '/v3';
export const home = `${V3Base}/home`;

/* SAVED REPORTS */
const savedReportsBase = `${V3Base}/savedreports`;
export const savedReports = `${savedReportsBase}/all`;

/* DATA */
const dataBase = `${V3Base}/data`;

// DATA -> Collect Marketing Data
const marketingBase = `${dataBase}/marketing`;
const integrationsBase = `${marketingBase}/integrations`;
export const integrationsPlatforms = `${integrationsBase}/main`;
export const connectIntegration = `${integrationsBase}/connect`;
export const integrationLastStep = `${integrationsBase}/auth`;
export const integrationTrackingParameters = `${integrationsBase}/tracking`;
export const shopifyCheckoutExtensions = `${integrationsBase}/shopify_checkout_extensions`;

const promoCodesBase = `${marketingBase}/promo_codes`;
export const promoCodes = `${promoCodesBase}/main`;
export const promoCodesCreate = `${promoCodesBase}/create`;
export const promoCodesEdit = `${promoCodesBase}/edit`;

const surveysBase = `${marketingBase}/surveys`;
export const surveys = `${surveysBase}/main`;
export const surveyCreate = `${surveysBase}/create`;
export const surveyEdit = `${surveysBase}/edit`;

// export const syncHistory = `${marketingBase}/sync_history`;

// DATA -> Track Customer Data
const customersBase = `${dataBase}/customers`;
const conversionsBase = `${customersBase}/conversions`;
export const conversionsOverview = `${conversionsBase}/main`;
export const conversionsDetail = `${conversionsBase}/detail`;
export const conversionsUserProfile = `${conversionsBase}/user`;
export const conversionsConnect = `${conversionsBase}/connect`;
export const eventStatus = `${customersBase}/event_status`;

// DATA -> Data exports
const exportsBase = `${dataBase}/exports`;
export const adHocExportsHistory = `${exportsBase}/ad_hoc_exports`;
export const datawarehouse = `${exportsBase}/data_warehouse`;
export const datawarehouseSetup = `${datawarehouse}/setup`;

/* ATTRIBUTION */
const attributionBase = `${V3Base}/attribution`;
export const crossChannelReport = `${attributionBase}/cross_channel_report`;
export const platformPerformance = `${attributionBase}/platform_performance`;
export const rockerboxPerformance = `${attributionBase}/rockerbox_performance`;

/* JOURNEYS */
const journeysBase = `${V3Base}/journeys`;
export const marketingPaths = `${journeysBase}/marketing_paths`;
export const funnelPosition = `${journeysBase}/funnel_position`;
export const conversionFunnel = `${journeysBase}/conversion_funnel`;
export const channelOverlap = `${journeysBase}/channel_overlap`;
export const newVisitors = `${journeysBase}/new_visitors`;

/* MMM */
const mmmBase = `${V3Base}`;
export const marketingMixModeling = `${mmmBase}/mmm`;

/* BENCHMARKS */
const benchmarksBase = `${V3Base}/benchmarks`;
export const spendTrends = `${benchmarksBase}/spend_trends`;
export const platformBenchmarks = `${benchmarksBase}/platform_benchmarks`;

/* SETTINGS */
export const settingsIndex = '/v3/settings';
export const myAccount = `${settingsIndex}/account`;
export const emailSubscriptions = `${settingsIndex}/subscriptions`;
export const manageUsers = `${settingsIndex}/users`;
export const domains = `${settingsIndex}/domains`;
export const colors = `${settingsIndex}/colors`;
export const billing = `${settingsIndex}/billing`;
export const conversionFunnelConfig = `${settingsIndex}/conversion_funnel_config`;

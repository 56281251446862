import {
  compose,
  filter,
  map,
  keyBy,
  get,
  includes,
  curry,
} from 'lodash/fp';

// Selectors
export const selectWarehouseIntegrationLogos = compose(
  keyBy('platform'),
  map(({ platform, logo_img }) => ({ platform, logo_img })),
  filter(({ category }) => category === 'export.warehousing'),
);

export const selectWarehouseArtifacts = compose(
  curry(s => s && JSON.parse(s)),
  get('data_warehouse_welcome_cards'),
);

export const selectWarehouseFeatureEnabled = compose(
  includes('data_warehouse'),
  get('features'),
);

// Utils
export const generateWarehouseCardList = (artifacts, integrations, features) => {
  const warehouseItems = selectWarehouseArtifacts(artifacts);
  const logos = selectWarehouseIntegrationLogos(integrations);
  const featureEnabled = selectWarehouseFeatureEnabled(features);
  return map(item => ({
    ...item,
    logo: get(item.logo)(logos),
    featureEnabled,
  }))(warehouseItems);
};

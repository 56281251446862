import React, { Component } from 'react';
import { getBatchInboxMessages, getBatchInboxMessageHistory, postBatchNotifyReplay } from '../../../utils/api';
import { Table, Icon } from 'semantic-ui-react';
import { SortablePagedIndexGrid, PagedIndexGrid, IndexGrid, ContentCard } from '@rockerbox/styleguide';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

const TimestampCell = ({item, col}) => {
  const time = moment.utc(item[col.key]).local().format('MMM D, YYYY - h:mm a');
  return (
    <Table.Cell>{time}</Table.Cell>
  )
}

const ReplayCell =  ({item, col}) => {
  console.log(item);
  const notify_id = item[col.key]
  const username = item['username'] || ""
  const is_admin = username.trim().slice(-14) === "@rockerbox.com"

  return (
    <Table.Cell>
    {is_admin
      ? <div><button class="ui button" icon labelPosition='left' onClick={() => postBatchNotifyReplay(notify_id)}>
          <Icon name='redo'/>
          {notify_id}
        </button></div>
      : notify_id
    }
    </Table.Cell>
  )
}

const CacheCell = ({item, col}) => {
  const cache_id = item[col.key]
  const username = item['username'] || ""
  const is_admin = username.trim().slice(-14) === "@rockerbox.com"

  return (
    <Table.Cell>
    {is_admin
      ? <a href={`http://workqueueacetl.internaldcos.getrockerbox.com/v2/workqueue_status/job/${cache_id}`} target="_blank">{cache_id}</a>
      : cache_id
    }
    </Table.Cell>
  )
}

const DownloadFile = ({item, col}) => {
  const { id, inbox_id } = item;
  return (
    <Table.Cell>
      <IndexGrid.ButtonWithHover
        popupContent="Download"
        icon="download"
        as={Link}
        to={`/batch/inbox/message/download?id=${id}&inbox_id=${inbox_id}`}
        target="_blank"
      />
    </Table.Cell>
  )
}

const NumRows = ({ item={} }) => {
  const { num_rows } = item;
  return (
      <Table.Cell>{ num_rows ? num_rows : "" }</Table.Cell>
  )
}

class BatchInboxView extends Component {
  state = {
    messages: [],
    extract_accounting: []
  }

  componentDidMount () {
    const { inboxId } = this.props.match.params;
    getBatchInboxMessages(inboxId) 
      .then(resp => {
        const { messages } = resp;
        const { username } = this.props;
        const extract_accounting = resp['extract_accounting'] ? resp['extract_accounting'].map( row => {
          row.username = username
          return row
        }) : [];

        this.setState({messages, extract_accounting})
      })
  }

  getMessageHistory = item => {
    const { id, inbox_id } = item;
    const { username } = this.props; 
    getBatchInboxMessageHistory(inbox_id, id)
      .then( data => {
        const expandedData = data.map( row => {
          row.username = username
          return row
        })
        this.setState({ expandedData });
    })
  }

  messageHistory = ({item}) => {
    const { expandedData, expandedKey } = this.state;
    if (expandedKey != item.id) {
      this.setState({ expandedData: [], expandedKey: item.id }, () =>
        this.getMessageHistory(item)
      );
    }

    const cols=[
      {display: 'id', key: 'id', as: ReplayCell},
      {display: 'Report ID', key: 'report_id'},
      {display: 'File ID', key: 'file_id'},
      {display: 'Action', key: 'action'},
      {display: 'Target', key: 'target'},
      {display: 'Timestamp', key: 'created_at', as: TimestampCell},
      {display: 'Job Status', key: 'finish_type'},
      {display: 'Cache ID', key: 'job_id', as: CacheCell}
    ]

    return (
      <IndexGrid cols={cols} data={expandedData} fallBackMsg="Loading" />
    )
  }

  render() {
    const { messages, extract_accounting } = this.state;
    const message_cols = [
      {display: 'Date', key: 'date'},
      {display: 'Upload ID', key: 'upload_id'},
      {display: 'File Name', key: 'filename'},
      {display: 'Start Date', key: 'start_date'},
      {display: 'End Date', key: 'end_date'},
      {display: 'Download', key: 'id', as: DownloadFile },
      {display: 'Num Rows', key:'num_rows', as: NumRows }
    ]

    const accounting_cols = [
      {display: 'Timestamp', key: 'created_at', as: TimestampCell},
      {display: 'File Path', key: 'file_path'},
      {display: 'Status', key: 'status'},
      {display: 'Cache ID', key: 'job_id', as: CacheCell}
    ]

    return (
      <React.Fragment>
        <ContentCard title='Notify Accounting' hasTable>
          <PagedIndexGrid cols={message_cols} data={messages} idKey="id" itemsPerPage={10} expandable={true} expandedComponent={this.messageHistory} />
        </ContentCard>

        <ContentCard title='Extract Accounting' hasTable>
          <SortablePagedIndexGrid cols={accounting_cols} data={extract_accounting} idKey="id" itemsPerPage={10} />
        </ContentCard>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    account: state.app.user,
    username: state.app.username,
    signup_source: state.app.account.signup_source,
  };
}

export default connect(mapStateToProps)(BatchInboxView);

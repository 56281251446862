import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import { Form, Menu, Input, Icon, Dropdown, Message, Button, Segment, Checkbox } from 'semantic-ui-react';
import { ColumnLayout, ContentCard, DataTable } from '@rockerbox/styleguide';
import FindMarketingEvent from '../Bulk/FindMarketingEvent';
import { addValueToOptionsIfMissing } from '../helpers';
import useOptions from '../hooks/useOptions';

const SetupTracking = ({ state, setState, trackingOptions, updateStateField, addItemToList, removeItemFromList, hasLegacySpend }) => {
  const promoColumns = useMemo(() => (
    [
      { display: 'Promo Code Value', key: 'conversion_field_value', type: 'text', editProps: { allowAdditions: true } },
      { display: 'Description', key: 'name', type: 'text' },
      !!hasLegacySpend && { display: 'Discount ($)', key: 'fixed_discount', type: 'currency' },
      !!hasLegacySpend && { display: 'Discount (%)', key: 'percent_discount', type: 'percent' },
    ].filter(Boolean)
  ), [hasLegacySpend]);

  const [eventSelectionType, setEventSelectionType] = useState('find');

  const { hasPromoCodes, hasUTMParams } = state;
  const { segments, events, promo_codes, name } = state;
  const { segment_id } = segments[0] || {};
  const { tiersOptions } = useOptions(segment_id);

  const updateEventRow = row => {
    const index = events.map(evt => evt.id || evt.new_id).indexOf(row.id || row.new_id);
    const newEvents = _.cloneDeep(events);
    newEvents.splice(index, 1, row);
    setState({ events: newEvents });
  };

  const updatePromoCodes = promoCodesUpdate => {
    const promoCodes = promoCodesUpdate.map((row, i) => {
      const { fixed_discount, percent_discount } = row;
      const rowMapped = { ...row };
      if (!state.promo_codes[i] || state.promo_codes[i].fixed_discount !== fixed_discount) rowMapped.percent_discount = '';
      if (!state.promo_codes[i] || state.promo_codes[i].percent_discount !== percent_discount) rowMapped.fixed_discount = '';
      return rowMapped;
    });
    setState({ promo_codes: promoCodes });
  };

  const addEventRowByTierValues = values => {
    const tiers = ['tier_1', 'tier_2', 'tier_3', 'tier_4', 'tier_5'];

    const newObject = values.reduce((accu, c, i) => {
      const tier = tiers[i];
      accu[tier] = c;
      return accu;
    }, {});

    const shouldReplace = events.filter(row => !row.tier_1).length;
    const replaceRow = shouldReplace ? events.filter(row => !row.tier_1)[0] : false;

    Object.assign(replaceRow, newObject);

    if (shouldReplace) {
      updateEventRow(replaceRow); // TODO: figure out how this works
    } else {
      addItemToList('events', newObject);
    }
  };

  const addEventViaTree = treeNode => {
    const keys = treeNode.parents.concat([treeNode.key]);
    addEventRowByTierValues(keys);
    return false;
  };

  return (
    <ColumnLayout leftWidth={4} centerWidth={8} rightWidth={4}>
      <ContentCard>
        <Message
          header={`How do you track marketing for ${name}?`}
          content="Choose all of the marketing tracking options that apply."
        />
        <Segment secondary>
          {trackingOptions.map(row => {
            const { text, field } = row;
            const value = state[field];
            return (
              <>
                <Checkbox label={text} checked={value} onClick={() => updateStateField(field)(false, { value: !value })} />
                <br />
              </>
            );
          })}
        </Segment>
      </ContentCard>

      {hasPromoCodes && (
        <ContentCard title="Promo Code Tracking">
          <Message
            header="Associated Promo-codes"
            content={`Enter the promo-codes that are associated with ${name}. These promo codes will be used to give marketing credit to ${name} when warranted.`} // eslint-disable-line
          />
          <div style={{ width: '100%' }}>
            <DataTable
              {...{
                columns: promoColumns,
                data: promo_codes,
                onUpdate: updatePromoCodes,
                onRemove: row => removeItemFromList('promo_codes')(row),
              }}
            />
            <br />
            <Button primary onClick={() => addItemToList('promo_codes')} content="Promo code" icon="plus" size="mini" as="span" floated="right" />
            <br />
            <br />
          </div>
        </ContentCard>
      )}
      {hasUTMParams && (
        <ContentCard title="URL Tracking">
          <Message
            header="Associate Click Tracking"
            content={`Click tracking (or landing page tracking) can be setup using the channel rules in our platform to organize your marketing data. We can associated a specific slice of this marketing back to ${name} so that we can explicitly track it and assign a cost.`} // eslint-disable-line
          />
          {events.filter(row => row.tier_1).length > 0 && (
            <h5>
              <b>Associated Marketing Events</b>
            </h5>
          )}
          {events
            && events
              .filter(row => row.tier_1)
              .map(eventRow => (
                <EventSelectionRow
                  readOnly
                  {...{
                    tiersOptions,
                    eventRow,
                    onUpdate: updateEventRow,
                    onRemove: row => removeItemFromList('events')(row),
                  }}
                />
              ))}
          <>
            {events && events.filter(row => !row.tier_1).length === 0 && (
              <div style={{ textAlign: 'center', marginTop: '15px' }}>
                <Button
                  basic
                  size="huge"
                  content="Add an Event"
                  icon="plus"
                  onClick={() => addItemToList('events')}
                />
              </div>
            )}
            <br />
            <br />
          </>

          {events && events.filter(row => !row.tier_1).length > 0 && (
            <Menu tabular>
              <Menu.Item active={eventSelectionType === 'find'} onClick={() => setEventSelectionType('find')}>
                Find Event
              </Menu.Item>
              <Menu.Item active={eventSelectionType === 'select'} onClick={() => setEventSelectionType('select')}>
                Create Event
              </Menu.Item>
            </Menu>
          )}

          {events && events.filter(row => !row.tier_1).length > 0 && eventSelectionType === 'find' && (
            <FindMarketingEvent
              segment={segments[0]}
              onChange={(e, { value }) => addEventViaTree(value)}
            />
          )}
          {events && events.filter(row => !row.tier_1).length > 0 && eventSelectionType === 'select' && (
            <CreateEventRow
              onChange={(e, { value }) => addEventRowByTierValues(value)}
            />
          )}
          <br />
          <br />
        </ContentCard>
      )}
    </ColumnLayout>
  );
};

const CreateEventRow = props => {
  const { onChange } = props;

  const [tier_1, setT1] = useState('');
  const [tier_2, setT2] = useState('');
  const [tier_3, setT3] = useState('');
  const [tier_4, setT4] = useState('');
  const [tier_5, setT5] = useState('');

  const triggerUpdate = evt => {
    onChange(evt, { value: [tier_1, tier_2, tier_3, tier_4, tier_5] });
    evt.preventDefault();
    return false;
  };

  const fields = [
    { name: 'Tier 1', onChange: (e, { value }) => setT1(value) },
    { name: 'Tier 2', onChange: (e, { value }) => setT2(value) },
    { name: 'Tier 3', onChange: (e, { value }) => setT3(value) },
    { name: 'Tier 4', onChange: (e, { value }) => setT4(value) },
    { name: 'Tier 5', onChange: (e, { value }) => setT5(value) },
  ];

  return (
    <>
      {fields.map(({ name, key, onChange: onChangeFields }) => (
        <Form.Field {...{ key }}>
          <Input fluid {...{ onChange: onChangeFields, label: name }} />
        </Form.Field>
      ))}
      <Form.Button fluid onClick={triggerUpdate}>
        Add
      </Form.Button>
    </>
  );
};

export const EventSelectionRow = ({ tiersOptions, eventRow, onUpdate, onRemove, WrapperComponent = false, readOnly = false }) => {
  const tiers = ['tier_1', 'tier_2', 'tier_3', 'tier_4', 'tier_5'];

  const tiersVisible = useMemo(() => {
    const filledTier = tiers.reduce((p, c) => (eventRow[c] && eventRow[c].length ? c : p), '');
    const index = tiers.indexOf(filledTier) + 1;
    return index < tiers.length ? tiers[index] : 'tier_5';
  }, [...Object.values(eventRow)]);

  const selectedTiers = useMemo(() => {
    const index = tiers.indexOf(tiersVisible);
    return tiers.slice(0, index + 1);
  }, [tiersVisible]);

  const Wrapper = WrapperComponent || (props => <Segment style={{ fontSize: '.95rem' }} {...props} />); // eslint-disable-line react/jsx-props-no-spreading

  const optionsByTier = {
    tier_1: tiersOptions,
  };

  const updateTier = (row, tier) => (evt, { value }) => {
    const index = tiers.indexOf(tier);
    const clearUpdates = tiers.slice(index, tiers.length).reduce((accu, c) => {
      accu[c] = '';
      return accu;
    }, {});

    onUpdate({ ...row, ...clearUpdates, [tier]: value });
  };

  return (
    <Wrapper secondary>
      {!readOnly && <Icon name="remove" onClick={() => onRemove(eventRow)} floated="right" />}
      {selectedTiers.map((tier, i) => {
        const nextTier = selectedTiers[i + 1];
        const value = eventRow[tier];
        let options = optionsByTier[tier] || [];
        if (value) {
          const nextOptions = (options.find(row => row.value === value) || {}).values || [];
          optionsByTier[nextTier] = nextOptions.filter(({ value }) => value.trim().length > 0); // eslint-disable-line no-shadow
        }

        const hasValue = !!value;
        const hasOptions = options.length > 0;

        // check if the value is present in options. If not, we'll append it to options so the dropdown renders properly
        options = addValueToOptionsIfMissing(value, options);

        return hasOptions && !readOnly ? (
          <Dropdown placeholder={tier} selection {...{ options, value }} onChange={updateTier(eventRow, tier)} />
        ) : readOnly && hasValue ? (
          <div style={{ display: 'inline-block' }}>
            {i > 0 && (
              <div style={{ display: 'inline-block', padding: '10px' }}>
                <Icon name="right arrow" />
              </div>
            )}
            <div style={{ display: 'inline-block' }}>
              <b>
                Tier
                {i + 1}
              </b>
              <br />
              <Input disabled {...{ value }} />
            </div>
          </div>
        ) : null;
      })}
      {readOnly && (
        <div style={{ float: 'right', marginTop: '15px' }}>
          <Icon name="remove" onClick={() => onRemove(eventRow)} style={{ cursor: 'pointer' }} />
        </div>
      )}
    </Wrapper>
  );
};

export default SetupTracking;

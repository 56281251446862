import React, { createContext } from 'react'
import { loadStripe } from '@stripe/stripe-js'

const STRIPE_PUB_KEYS = {
  test: 'pk_test_515qVwhF27DVuTFSPSTsHmUKav9KVsp6XeBUilYsssOMYlYKj3qpJKeLwomMObmy0ZaADkGqrYINYBL0vvveA4NE200zfV1M2Mc',
  prod: 'pk_live_515qVwhF27DVuTFSPGTGZjwqHN744WLsUDNyMO6FCq7ZdoKRpYGSlk4ixc8ROJpgeAt7dkASIQLn3q7S9hjj9goTO00gHAcMv5i',
}

export const StripeContext = createContext()
const isProductionApp = window.location.hostname === 'app.rockerbox.com'
const env = isProductionApp ? 'prod' : 'test'
const stripePubKey = STRIPE_PUB_KEYS[env]
const stripePromise = loadStripe(stripePubKey)

export const StripeProvider = ({ children }) => {
  return (
    <StripeContext.Provider value={{ stripePromise }}>
      {children}
    </StripeContext.Provider>
  )
}

export const tierKeys = ['tier_1', 'tier_2', 'tier_3', 'tier_4', 'tier_5'];

export const positionIndex = { first: 0, mid: 1, last: 2 };

export const attributableNames = {
  tier_1: 'Tier 1',
  tier_2: 'Tier 2',
  tier_3: 'Tier 3',
  tier_4: 'Tier 4',
  tier_5: 'Tier 5',
  tier_1_first: 'Tier 1',
  tier_2_first: 'Tier 2',
  tier_3_first: 'Tier 3',
  tier_4_first: 'Tier 4',
  tier_5_first: 'Tier 5',
  tier_1_mid: 'Tier 1',
  tier_2_mid: 'Tier 2',
  tier_3_mid: 'Tier 3',
  tier_4_mid: 'Tier 4',
  tier_5_mid: 'Tier 5',
  tier_1_last: 'Tier 1',
  tier_2_last: 'Tier 2',
  tier_3_last: 'Tier 3',
  tier_4_last: 'Tier 4',
  tier_5_last: 'Tier 5',
};

export const customerKeys = {
  all: [
    'count_first_all_percent_col',
    'count_first_all',
    'count_mid_all_percent_col',
    'count_mid_all',
    'count_last_all_percent_col',
    'count_last_all',
  ],
  new: [
    'count_first_ntf_percent_col',
    'count_first_ntf',
    'count_mid_ntf_percent_col',
    'count_mid_ntf',
    'count_last_ntf_percent_col',
    'count_last_ntf',
  ],
  repeat: [
    'count_first_rpt_percent_col',
    'count_first_rpt',
    'count_mid_rpt_percent_col',
    'count_mid_rpt',
    'count_last_rpt_percent_col',
    'count_last_rpt',
  ],
};

export const touchPointColors = {
  count_first_all: '#66D9AE',
  count_mid_all: '#FFDF78',
  count_last_all: '#E76D8E',
  count_first_ntf: '#66D9AE',
  count_mid_ntf: '#FFDF78',
  count_last_ntf: '#E76D8E',
  count_first_rpt: '#66D9AE',
  count_mid_rpt: '#FFDF78',
  count_last_rpt: '#E76D8E',
};

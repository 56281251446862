import React from 'react';

import { Menu, Icon } from 'semantic-ui-react';

const STYLE = { color: 'black', fontWeight: 'bold' };

const MenuSelect = ({ style, title, value, options, onChange }) => {
  const [selectedValue, setSelectedValue] = React.useState(value);

  React.useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  React.useEffect(() => {
    if (value !== selectedValue) onChange(false, { value: selectedValue });
  }, [selectedValue]);

  return (
    <Menu vertical fluid {...{ style }}>
      <Menu.Item header content={title} style={STYLE} />
      { options.map(row => {
        const active = row.value === selectedValue;
        const onClick = () => setSelectedValue(row.value);
        const chevron = active && <Icon name="chevron right" style={{ float: 'right', margin: 0 }} />;
        return (
          <Menu.Item {...{ active, onClick }}>
            { row.text }
            {' '}
            {chevron}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default MenuSelect;

import { useState, useMemo } from 'react';

import { extractAndDeduplicateDates } from '../../../utils/dateFormatters';

export const useDataStatus = selectedMetric => {
  const [spendStatusRollup, setSpendStatusRollup] = useState([]);
  const [conversionStatusRollup, setConversionStatusRollup] = useState([]);
  const [syntheticStatusRollup, setSyntheticStatusRollup] = useState([]);

  const failureDates = useMemo(() => {
    const spendDates = extractAndDeduplicateDates(spendStatusRollup);
    const conversionDates = extractAndDeduplicateDates(conversionStatusRollup);
    const syntheticDates = extractAndDeduplicateDates(syntheticStatusRollup);

    switch (selectedMetric) {
      case 'spend':
        return new Set(spendDates);
      case 'conversions':
      case 'revenue':
      case 'rpc':
        return new Set([...syntheticDates, ...conversionDates]);
      default:
        return new Set([...spendDates, ...conversionDates, ...syntheticDates]);
    }
  }, [spendStatusRollup, conversionStatusRollup, syntheticStatusRollup, selectedMetric]);

  return {
    setSpendStatusRollup, setConversionStatusRollup, setSyntheticStatusRollup,
    failureDates,
  };
};

import _ from 'lodash';

const defaultString = s => (s || '');

export const objectWithDefault = obj => Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, defaultString(value)]));

export const getDisplayString = obj => {
  const { tier_1, tier_2, tier_3, tier_4, tier_5 } = obj || {};
  const display = objectWithDefault({ tier_1, tier_2, tier_3, tier_4, tier_5 });
  return JSON.stringify(display);
};

export const isNumeric = value => !_.isNaN(parseFloat(value)) && _.isFinite(parseFloat(value));

export const addValueToOptionsIfMissing = (value, options) => {
  const obj = options.find(o => o.key === value);
  if (obj === undefined) {
    options.push({ key: value, text: value, value });
  }
  return options;
};

import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import { IndexGridNew } from '@rockerbox/styleguide';

export const NoPrograms = ({ setOpen }) => (
  <Table>
    <IndexGridNew.EmptyMessage
      fallbackMsg="Get started by creating a sponsorship program"
      callToAction={<Button color="blue" content="Create Program" onClick={() => setOpen(true)} />}
    />
  </Table>
);

export const NoSelection = () => (
  <Table>
    <IndexGridNew.EmptyMessage
      fallbackMsg="Select a program on the left to view your promo codes"
    />
  </Table>
);

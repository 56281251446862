/* eslint import/prefer-default-export: 0 */

import { useMemo, useState, useEffect } from 'react';

import * as aq from 'arquero';
import { useAtom } from 'jotai';
import moment from 'moment';

import { cleanData } from './helpers';
import { getChannelRedistributions } from '../../../api/attribution';
import { attributionMethodAtom } from '../../../atoms';
import { marketingEventToChannel, marketingEventToPlatform, marketingEventToStrategy } from '../../../components/filters/DerivedTiersFilter/eventTransforms';
import { usePlatformMappings } from '../../../components/filters/DerivedTiersFilter/hooks';
import { methodOptions } from '../../../constants/options';
import { getCache } from '../../../utils/data';
import { generateDateRange } from '../helpers';

/* eslint-disable no-shadow */

const findCurrentSegment = (segments, id) => segments.find(c => c.filter_id === Number(id)) || false;

const getFirstReportingDate = currentSegment => {
  const { first_reporting_date } = currentSegment;
  const firstReportingDate = moment(first_reporting_date);
  return firstReportingDate.format('MM/DD/YYYY');
};

const hasColumn = (table, column) => table
  .columnNames()
  .includes(column);

const DEFAULT_COLUMNS = ['even', 'first_touch', 'last_touch'];
const MODEL_COLUMNS = ['normalized'];
const FILTER_TIER_1 = []; // add to this if we want to filter raw data like Unmapped Events

const deriveRepeatColumns = table => {
  const hasNTF = hasColumn(table, 'ntf_even');
  const hasModel = hasColumn(table, 'normalized');
  const hasSpend = hasColumn(table, 'spend');

  // front end check on missing values for numeric columns until API makes update to do check
  const cleanedData = cleanData(table, hasNTF, hasModel, hasSpend);

  if (!hasNTF) return cleanedData;

  const columns = hasModel ? [...DEFAULT_COLUMNS, ...MODEL_COLUMNS] : DEFAULT_COLUMNS;

  const repeat = columns.reduce((accu, col) => {
    accu[`repeat_${col}`] = `d => d['${col}'] - d['ntf_${col}']`;
    accu[`repeat_revenue_${col}`] = `d => d['revenue_${col}'] - d['ntf_revenue_${col}']`;
    return accu;
  }, {});

  const derived = cleanedData.derive(repeat);
  return derived;
};

const deriveRBClassifications = (table, platformMappings) => table.derive({
  searchable: 'd => op.lower(d.tier_1 + "___" + d.tier_2 + "___" + d.tier_3)',
})
  .derive({
    channel: aq.escape(d => marketingEventToChannel(d.searchable)),
    platform: aq.escape(d => marketingEventToPlatform(d.searchable, platformMappings)),
    strategy: aq.escape(d => marketingEventToStrategy(d.searchable)),
  });

const deriveFinalRawTable = (table, platformMappings) => {
  const filteredTable = FILTER_TIER_1.length ? table.filter(`(d, $) => !op.includes(['${FILTER_TIER_1}'], d.tier_1)`) : table;
  const tableDerviedRepeatColumns = deriveRepeatColumns(filteredTable);
  return deriveRBClassifications(tableDerviedRepeatColumns, platformMappings);
};

export const useDatasets = (segments, startDate, endDate, id, account, creditRedistributionId, setCreditRedistributionId) => {
  const { platformMappings } = usePlatformMappings();
  const dataset = 'compiled_mta_tiers';
  const [_attributionMethod, _setAttributionMethod] = useAtom(attributionMethodAtom);
  const [firstReportingDate, setFirstReportingDate] = useState(undefined);
  const [tiersData, setTiersData] = useState([]);
  const [tiersDataRaw, setTiersDataRaw] = useState(false);
  const [tiersDataRawLimited, setTiersDataRawLimited] = useState(false);
  const [dates, setDates] = useState([]);
  const [currentRequest, setCurrentRequest] = useState(false);
  const [currentResponse, setCurrentResponse] = useState(false);
  const [allRedistributions, setAllRedistributions] = useState([]);

  const invalidId = (!id || id === 0);
  const badDate = !startDate || !endDate;
  const missingSegments = !segments || (segments.length < 1);
  const currentSegment = !missingSegments && findCurrentSegment(segments, id);
  const hasMissingParameters = (invalidId || badDate || missingSegments || !currentSegment || !account || !Object.keys(platformMappings).length);
  const hasModel = useMemo(() => !!currentSegment.use_model, [currentSegment]);
  const hasNtf = useMemo(() => !!currentSegment.has_new_to_file, [currentSegment]);

  const { attributionOptions, standardAttributionOptions, redistributionOptions } = useMemo(() => {
    const standardAttributionOptions = hasModel ? methodOptions : methodOptions.filter(o => o.value !== 'normalized');
    const redistributionOptions = allRedistributions
      .filter(row => row.model !== 'normalized' || (row.model === 'normalized' && hasModel))
      .map(c => ({ text: c.name, value: c.id, key: c.id, type: 'redistribution', model: c.model }));
    const attributionOptions = [...standardAttributionOptions, ...redistributionOptions];
    return { attributionOptions, standardAttributionOptions, redistributionOptions };
  }, [hasModel, allRedistributions]);

  const setAttributionMethod = data => {
    const { value } = data;
    if (!value) return;

    const selectedOption = attributionOptions.find(row => row.value === value);
    const { type, model } = selectedOption;

    if (type) {
      setCreditRedistributionId(value);
      _setAttributionMethod(model);
      return;
    }

    setCreditRedistributionId(0);
    _setAttributionMethod(value);
  };

  useEffect(() => {
    // Check assertions - Don't attempt to run anything if we dont have everything
    if (hasMissingParameters) return;

    const requestHash = `${id}${startDate}${endDate}`;
    setCurrentRequest(requestHash);

    const firstReportingDateFromSegment = getFirstReportingDate(currentSegment);

    if (moment(endDate).isBefore(moment(firstReportingDateFromSegment)) || moment(startDate).isBefore(moment(firstReportingDateFromSegment))) {
      // no need to execute request if date range selection is bad
      setCurrentResponse(requestHash);
      setFirstReportingDate(firstReportingDateFromSegment);
      setTiersData([]);
      setTiersDataRaw(false);
      setDates([]);
      return;
    }

    const getViaCacheLimited = getCache('compiled_mta_tiers_limited', 'data-services-v1-limited');

    getViaCacheLimited(startDate, endDate, 'compiled_mta_tiers_limited', id)
      .then(_data => {
        setCurrentResponse(requestHash);
        setFirstReportingDate(firstReportingDateFromSegment);

        if (_data._names.length === 0) {
          setTiersData([]);
          setTiersDataRaw(false);
          return;
        }

        console.time('from cache: raw repeat');
        const raw = deriveFinalRawTable(_data, platformMappings);
        console.timeEnd('from cache: raw repeat');
        setTiersDataRawLimited(raw);
        setDates(generateDateRange(startDate, endDate));
      });

    const hasDataReportingApiFlag = account.features.find(c => c === 'data_reporting_api');

    const getViaCache = hasDataReportingApiFlag
      ? getCache('compiled_mta_tiers', 'data-services-v1')
      : getCache('compiled_mta_tiers', 'legacy');

    getViaCache(startDate, endDate, dataset, id)
      .then(_data => {
        setCurrentResponse(requestHash);
        setFirstReportingDate(firstReportingDateFromSegment);

        if (_data._names.length === 0) {
          setTiersData([]);
          setTiersDataRaw(false);
          return;
        }

        console.time('from cache: raw repeat');
        const raw = deriveFinalRawTable(_data, platformMappings);
        console.timeEnd('from cache: raw repeat');

        setTiersDataRaw(raw);
        setTiersData([1, 2, 3]); // i dont understand why this is still needed
        setDates(generateDateRange(startDate, endDate));
      });
  }, [id, startDate, endDate, segments]);

  const getAndSetRedistributions = () => {
    getChannelRedistributions().then(redistributions => {
      setAllRedistributions(redistributions);
    });
  };
  useEffect(() => {
    getAndSetRedistributions();
  }, []);

  // const selectedRedistribution = useMemo(() => {
  //   if(!creditRedistributionId || creditRedistributionId == 0 || !allRedistributions.length) return {};
  //   const selected = allRedistributions.find(c => c.id === Number(creditRedistributionId))
  //   return selected
  // }, [creditRedistributionId, allRedistributions])

  const dataIsCurrent = (currentRequest == currentResponse); // eslint-disable-line
  const loading = !currentRequest || (!hasMissingParameters && !dataIsCurrent);
  const noData = currentRequest && dataIsCurrent && (tiersData.length === 0 && tiersDataRaw.length === 0);

  const { attributionMethod, selectedRedistribution } = useMemo(() => {
    const defaultMethod = hasModel ? 'normalized' : 'even';
    const noMethod = !_attributionMethod || _attributionMethod === '';
    const invalidMethod = _attributionMethod === 'normalized' && !hasModel;
    const standardAttributionMethod = (noMethod || invalidMethod) ? defaultMethod : _attributionMethod;

    const hasRedistribution = (creditRedistributionId && creditRedistributionId != 0 && allRedistributions.length); // eslint-disable-line eqeqeq
    const selectedRedistribution = allRedistributions.find(c => {
      const idMatches = c.id === Number(creditRedistributionId);
      const modelEligible = c.model !== 'normalized' || hasModel;
      return hasRedistribution && idMatches && modelEligible;
    }) || {};

    const attributionMethod = selectedRedistribution.model || standardAttributionMethod;

    return {
      attributionMethod,
      selectedRedistribution,
    };
  }, [_attributionMethod, hasModel, creditRedistributionId, allRedistributions]);

  return {
    loading,
    noData,
    hasModel, hasNtf,
    attributionMethod,
    attributionOptions,
    standardAttributionOptions,
    redistributionOptions,
    setAttributionMethod,
    // tiersData,
    tiersDataRaw,
    tiersDataRawLimited,
    dates,
    firstReportingDate,
    selectedRedistribution, allRedistributions,
    getAndSetRedistributions,
  };
};

export const useComparisonDatasets = (segments, startDate, endDate, id, account, creditRedistributionId, setCreditRedistributionId, compare) => {
  const { platformMappings } = usePlatformMappings();
  const dataset = 'compiled_mta_tiers';
  const [_attributionMethod, _setAttributionMethod] = useAtom(attributionMethodAtom);
  const [firstReportingDate, setFirstReportingDate] = useState(undefined);
  const [tiersData, setTiersData] = useState([]);
  const [tiersDataRaw, setTiersDataRaw] = useState(false);
  const [tiersDataRawLimited, setTiersDataRawLimited] = useState(false);
  const [dates, setDates] = useState([]);
  const [currentRequest, setCurrentRequest] = useState(false);
  const [currentResponse, setCurrentResponse] = useState(false);
  const [allRedistributions, setAllRedistributions] = useState([]);

  const invalidId = (!id || id === 0);
  const badDate = !startDate || !endDate;
  const missingSegments = !segments || (segments.length < 1);
  const currentSegment = !missingSegments && findCurrentSegment(segments, id);
  const hasMissingParameters = (invalidId || badDate || missingSegments || !currentSegment || !account || !compare || !Object.keys(platformMappings).length);
  const hasModel = useMemo(() => !!currentSegment.use_model, [currentSegment]);
  const hasNtf = useMemo(() => !!currentSegment.has_new_to_file, [currentSegment]);

  const { attributionOptions, standardAttributionOptions, redistributionOptions } = useMemo(() => {
    const standardAttributionOptions = hasModel ? methodOptions : methodOptions.filter(o => o.value !== 'normalized');
    const redistributionOptions = allRedistributions
      .filter(row => row.model !== 'normalized' || (row.model === 'normalized' && hasModel))
      .map(c => ({ text: c.name, value: c.id, key: c.id, type: 'redistribution', model: c.model }));
    const attributionOptions = [...standardAttributionOptions, ...redistributionOptions];
    return { attributionOptions, standardAttributionOptions, redistributionOptions };
  }, [hasModel, allRedistributions]);

  const setAttributionMethod = data => {
    const { value } = data;
    if (!value) return;

    const selectedOption = attributionOptions.find(row => row.value === value);
    const { type, model } = selectedOption;

    if (type) {
      setCreditRedistributionId(value);
      _setAttributionMethod(model);
      return;
    }

    setCreditRedistributionId(0);
    _setAttributionMethod(value);
  };

  useEffect(() => {
    // Check assertions - Don't attempt to run anything if we dont have everything
    if (hasMissingParameters) return;

    const requestHash = `${id}${startDate}${endDate}`;
    setCurrentRequest(requestHash);

    const firstReportingDateFromSegment = getFirstReportingDate(currentSegment);

    if (moment(endDate).isBefore(moment(firstReportingDateFromSegment))) {
      // no need to execute request if date range selection is bad
      setCurrentResponse(requestHash);
      setFirstReportingDate(firstReportingDateFromSegment);
      setTiersData([]);
      setTiersDataRaw(false);
      setDates([]);
      return;
    }

    const getViaCacheLimited = getCache('compiled_mta_tiers_limited', 'data-services-v1-limited');

    getViaCacheLimited(startDate, endDate, 'compiled_mta_tiers_limited', id)
      .then(_data => {
        setCurrentResponse(requestHash);
        setFirstReportingDate(firstReportingDateFromSegment);

        if (_data._names.length === 0) {
          setTiersData([]);
          setTiersDataRaw(false);
          return;
        }

        console.time('from cache: raw repeat');
        const raw = deriveFinalRawTable(_data, platformMappings);
        console.timeEnd('from cache: raw repeat');
        setTiersDataRawLimited(raw);
        setDates(generateDateRange(startDate, endDate));
      });

    const hasDataReportingApiFlag = account.features.find(c => c === 'data_reporting_api');

    const getViaCache = hasDataReportingApiFlag
      ? getCache('compiled_mta_tiers', 'data-services-v1')
      : getCache('compiled_mta_tiers', 'legacy');

    getViaCache(startDate, endDate, dataset, id)
      .then(_data => {
        setCurrentResponse(requestHash);
        setFirstReportingDate(firstReportingDateFromSegment);

        if (_data._names.length === 0) {
          setTiersData([]);
          setTiersDataRaw(false);
          return;
        }

        console.time('from cache: raw repeat');
        const raw = deriveFinalRawTable(_data, platformMappings);
        console.timeEnd('from cache: raw repeat');

        setTiersDataRaw(raw);
        setTiersData([1, 2, 3]); // i dont understand why this is still needed
        setDates(generateDateRange(startDate, endDate));
      });
  }, [id, startDate, endDate, segments]);

  const getAndSetRedistributions = () => {
    getChannelRedistributions().then(redistributions => {
      setAllRedistributions(redistributions);
    });
  };
  useEffect(() => {
    getAndSetRedistributions();
  }, []);

  // const selectedRedistribution = useMemo(() => {
  //   if(!creditRedistributionId || creditRedistributionId == 0 || !allRedistributions.length) return {};
  //   const selected = allRedistributions.find(c => c.id === Number(creditRedistributionId))
  //   return selected
  // }, [creditRedistributionId, allRedistributions])

  const dataIsCurrent = (currentRequest == currentResponse); // eslint-disable-line
  const loading = !currentRequest || (!hasMissingParameters && !dataIsCurrent);
  const noData = currentRequest && dataIsCurrent && (tiersData.length === 0 && tiersDataRaw.length === 0);

  const { attributionMethod, selectedRedistribution } = useMemo(() => {
    const defaultMethod = hasModel ? 'normalized' : 'even';
    const noMethod = !_attributionMethod || _attributionMethod === '';
    const invalidMethod = _attributionMethod === 'normalized' && !hasModel;
    const standardAttributionMethod = (noMethod || invalidMethod) ? defaultMethod : _attributionMethod;

    const hasRedistribution = (creditRedistributionId && creditRedistributionId != 0 && allRedistributions.length); // eslint-disable-line eqeqeq
    const selectedRedistribution = allRedistributions.find(c => {
      const idMatches = c.id === Number(creditRedistributionId);
      const modelEligible = c.model !== 'normalized' || hasModel;
      return hasRedistribution && idMatches && modelEligible;
    }) || {};

    const attributionMethod = selectedRedistribution.model || standardAttributionMethod;

    return {
      attributionMethod,
      selectedRedistribution,
    };
  }, [_attributionMethod, hasModel, creditRedistributionId, allRedistributions]);

  return {
    loading,
    noData,
    hasModel, hasNtf,
    attributionMethod,
    attributionOptions,
    standardAttributionOptions,
    redistributionOptions,
    setAttributionMethod,
    // tiersData,
    tiersDataRaw,
    tiersDataRawLimited,
    dates,
    firstReportingDate,
    selectedRedistribution, allRedistributions,
    getAndSetRedistributions,
  };
};

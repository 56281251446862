import _ from 'lodash';
import d3 from 'rockerbox_d3_legacy_clone';

import { roundNumber, findClosestPoint } from '../helpers';

const getSpendStats = (trainingStats, featureKey, curve) => {
  const featureTrainingStats = trainingStats.find(x => x.feature === featureKey) || {};
  const historicalSpendMin = featureTrainingStats?.spend_min || 0;
  const historicalSpendMax = featureTrainingStats?.spend_max || 0;
  const meanSpend = featureTrainingStats?.spend_mean || 0;

  const averageSpend = findClosestPoint(curve, 'spend_input', meanSpend);
  return { averageSpend, historicalSpendMin, historicalSpendMax };
};

const getRecommendationFromCurve = (currentRoas, currentIndex, curve, currentSpend, pointClosestToOne, constraints) => {
  const shouldIncrease = currentRoas > 1;
  const directionalIndex = shouldIncrease
    ? d3.max([currentIndex - 1, 0])
    : d3.min([currentIndex + 1, curve.length - 1]);

  const directionalChange = curve[directionalIndex].spend_input;

  const { min_value, max_value } = constraints || {};

  const maxRecommendedChange = shouldIncrease
    ? d3.max([currentSpend * 1.25, directionalChange])
    : d3.min([currentSpend * 0.75, directionalChange]);

  const recommendation = currentSpend === 0 ? 0
    : shouldIncrease
      ? d3.min([pointClosestToOne.spend_input || Infinity, maxRecommendedChange])
      : d3.max([pointClosestToOne.spend_input || -Infinity, maxRecommendedChange]);

  if (recommendation > max_value) return max_value;
  if (recommendation < min_value) return min_value;
  return recommendation;
};

const getMinMaxRoasFromCurve = curve => {
  const [min, max] = d3.extent(curve.map(({ predicted_roas }) => predicted_roas));
  const minRoas = roundNumber(min, 4) || 0.1;
  const maxRoas = roundNumber(max, 4) || 0.1;
  return { minRoas, maxRoas };
};

export const buildRecommendation = (responseCurves, trainingStats, constraints = { CONNEXITY_S: { min: 200, max: 200 } }) => {
  // TODO: this code requires a cleanup in a more significant way...
  // it is doing something funky with average spend which is changing the value of currentSpend based on the optimization strategy chosen

  if (!responseCurves || !trainingStats?.length) return;
  return Object.keys(responseCurves).reduce((accu, featureKey) => {
    // response curve
    const curve = responseCurves[featureKey];
    if (!curve?.length) return accu;

    const currentConstraints = constraints[featureKey] || {};
    const { originalSpend, min_value, max_value } = currentConstraints;

    // calculate min/max roas
    const { minRoas, maxRoas } = getMinMaxRoasFromCurve(curve);
    const { averageSpend, historicalSpendMin, historicalSpendMax } = getSpendStats(trainingStats, featureKey, curve);
    const isFixed = min_value && (min_value === max_value);

    const currentSpend = isFixed
      ? min_value : originalSpend || averageSpend;

    const currentRoas = curve.filter(row => row.spend_input === averageSpend)[0]?.predicted_roas || 0;
    const currentIndex = curve.findIndex(row => row.spend_input === averageSpend) || 0;

    const pointClosestToOne = curve.filter(row => row.predicted_roas === findClosestPoint(curve, 'predicted_roas', 1))[0] || {};

    const recommendation = getRecommendationFromCurve(currentRoas, currentIndex, curve, averageSpend, pointClosestToOne, currentConstraints);

    const recommendedSpend = isFixed ? min_value : findClosestPoint(curve, 'spend_input', recommendation);
    const recommendedPoint = curve.slice(1).find(x => x.spend_input === recommendedSpend); // don't recommend going all the way down
    const recommendedRevenue = recommendedPoint?.marginal_response || 0;
    const recommendedRoas = findClosestPoint(curve, 'predicted_roas', recommendedRevenue / recommendedSpend);

    const optimalSpend = recommendedSpend;
    const optimalRevenue = recommendedRevenue;
    const optimalRoas = recommendedRoas;

    // min and max spend on response curve
    const minSpend = _.min(curve, 'spend_input').spend_input;
    const maxSpend = _.max(curve, 'spend_input').spend_input;

    accu[featureKey] = {
      minRoas,
      maxRoas,
      minSpend,
      maxSpend,
      current: currentSpend,
      currentSpend,
      optimalSpend,
      optimalRevenue,
      optimalRoas,
      recommended: recommendedSpend,
      recommendedSpend,
      recommendedRevenue,
      recommendedRoas,
      historicalSpendMin,
      historicalSpendMax,
    };
    return accu;
  }, {});
};

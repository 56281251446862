import React, { useEffect, useMemo } from 'react';
import moment from 'moment';

// local
import Filter from './Filter';
import FunnelChartAndTable from './FunnelChartAndTable';
import EmptyFunnel from './EmptyFunnel';
import { getFirstReportingDate, useDatasets } from './hooks/datasets';
import { getColorsFromHash } from './helpers';

// state
import { usePageParams } from './hooks/pageParams';
import { useDerivedKeys } from './hooks/derivedKeys';
import { useFilteredDataArq } from './hooks/filteredData';
import { getChartData } from './hooks/getChartData';
import { useTreeColumns } from './hooks/treeColumns';

// shared v3
import { useGlobalState } from '../../hooks/global';
import { useTierFilters } from '../../hooks/useTierFilters';
import { track } from '../../../utils/tracking';
import { BasicViewHeader, ViewAccessWrapper } from '../../components';

const ConversionFunnel = () => {
  // GLOBAL / ACCOUNT
  const { account, currencyCode, segments, tierColors } = useGlobalState();

  // API INPUT AND CONTROL
  const {
    startDate, setStartDate, endDate, setEndDate,
    funnelConfigId, funnelConfigName, funnelConfiguration, hasInvalidSegments,
  } = usePageParams(segments);

  // GET DATA: from global state and url params
  const datasets = useDatasets(segments, startDate, endDate, funnelConfiguration, account);
  const { tiersDataRaw, loading, noData, currentSegments, attributionMethod, setAttributionMethod, attributionOptions, firstReportingDate } = datasets;

  // DISPLAY Settings
  const derivedKeys = useDerivedKeys(attributionMethod, funnelConfiguration);
  const { conversionKey, revenueKey, orderBy } = derivedKeys;

  // FILTER
  const { filters, search, setSearch, resetFilters } = useTierFilters();
  const filteredDataArq = useFilteredDataArq(tiersDataRaw, funnelConfiguration, search, filters, conversionKey);
  const { tier1SummaryByEvent, tiersSummary, tiersSummaryRawMerged, tiersSummaryTotals } = filteredDataArq;

  // CHART data
  const chartDataFormatted = getChartData(tier1SummaryByEvent, tiersSummaryTotals, funnelConfiguration, conversionKey, currentSegments, currencyCode);
  const { chartData, tooltipValueFormatter, chartSummaryComponents, selectedChartMetric } = chartDataFormatted;

  // TREE GRID settings based on display settings
  const tierColorMap = useMemo(() => getColorsFromHash(tiersDataRaw, tierColors), [tiersDataRaw, tierColors]);
  const treeColumns = useTreeColumns(tiersSummaryRawMerged, tiersSummaryTotals, tierColorMap, conversionKey, currencyCode, revenueKey, currentSegments);
  const { tiers, setTiers, allColumns, selectedColumns, setColumns } = treeColumns;

  const onDateChange = ({ startDate: startChange, endDate: endChange }) => {
    const formatStartDate = moment(startChange).format('YYYY-MM-DD');
    const formatEndDate = moment(endChange).format('YYYY-MM-DD');

    setStartDate(formatStartDate);
    setEndDate(formatEndDate);
  };

  const validFunnel = (!!funnelConfigId && !hasInvalidSegments);
  const firstReportingDateFromAllSegments = segments && getFirstReportingDate(segments);
  const rbDataAvailable = firstReportingDateFromAllSegments ? moment(firstReportingDateFromAllSegments).isBefore(moment().subtract(2, 'weeks')) : false;

  useEffect(() => {
    track('Journeys.ConversionFunnel.Click.set.view');
  }, []);

  return (
    <>
      <BasicViewHeader
        header="Conversion Funnel"
        subheader="Gain insight into your marketing performance at different phases of the conversion funnel"
      />
      <br />
      <Filter
        {...{
          loading, tiersData: tiersSummaryRawMerged, // data
          attributionMethod, attributionOptions, setAttributionMethod, // model
          startDate, endDate, onDateChange, firstReportingDate, // dates
          filters, resetFilters, // jotai
          search, setSearch,
          funnelConfigName, currentSegments,
        }}
      />
      {(validFunnel && rbDataAvailable) ? (
        <FunnelChartAndTable {...{
          loading, noData, firstReportingDate,
          chartData, chartSummaryComponents, selectedChartMetric,
          tierColorMap, tooltipValueFormatter,
          tiersSummary, orderBy,
          selectedColumns, allColumns, setColumns,
          tiers, setTiers,
        }}
        />
      ) : (
        <EmptyFunnel {...{
          funnelConfiguration, hasInvalidSegments,
          rbDataAvailable, firstReportingDateFromAllSegments,
        }}
        />
      )}
    </>
  );
};

const ConversionFunnelAccess = () => (
  <ViewAccessWrapper
    viewName="conversion_funnel"
    viewComponent={<ConversionFunnel />}
  />
);

export default ConversionFunnelAccess;

const shouldChannelAllowChange = key => {
  const lkey = key.toLowerCase();
  if (lkey.includes('email')) return true;
  if (lkey.includes('cj')) return true;
  if (lkey.includes('sms')) return true;
  if (lkey.includes('dm')) return true;
  if (lkey.includes('connexity')) return true;
};

export const computeInitialConstraints = (featureStats, percentage = 0.3) => Object
  .entries(featureStats)
  .reduce((obj, [k, v]) => Object.assign(obj, {
    [k]: {
      originalSpend: v?.currentSpend,
      min_value: shouldChannelAllowChange(k) ? v?.currentSpend : parseInt(parseInt(v?.currentSpend * (1 - percentage)) / 100) * 100, // eslint-disable-line
      max_value: shouldChannelAllowChange(k) ? v?.currentSpend : parseInt(parseInt(v?.currentSpend * (1 + percentage)) / 100) * 100, // eslint-disable-line
    },
  }), {});

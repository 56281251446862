import _ from 'lodash';

import { DEFAULT_TIERS } from './IndexGridTree';

// index.js
export const arrayToColumns = arr => arr.map(key => ({ key, display: key }));

// IndexGridTree.js
export const pagerLength = (rows, itemsPerPage) => {
  const numberOfPagers = Math.ceil(rows.length / itemsPerPage);

  return numberOfPagers;
};

export const selectData = (data, itemsPerPage, selectedPageNumber) => (data || []).slice(
  (selectedPageNumber - 1) * itemsPerPage,
  selectedPageNumber * itemsPerPage,
);

export const filterUsingGroupBy = (groupBy, obj, searchVal) => {
  const loweredSearchVal = searchVal.toLowerCase();
  const objCopy = groupBy.reduce((accu, key) => {
    accu[key] = obj[key].toLowerCase();
    return accu;
  }, {});

  const hasVal = groupBy.some(c => objCopy[c].includes(loweredSearchVal));

  return hasVal;
};

/*
  function needed due to bad data (sola wood flowers) passing a single space as a tier value
  results in empty table rows.
*/
export const filterEmptyTiers = data => {
  const clone = _.cloneDeep(data);

  const correctedData = clone.map(c => {
    const copy = { ...c };
    DEFAULT_TIERS.forEach(tier => {
      if (copy[tier] === ' ') {
        copy[tier] = '';
      }
    });
    return copy;
  });

  return correctedData;
};

export const addValuesToAllRows = (obj, additionalDataObj, totalSpend) => {
  const updatedObj = { ...obj };

  if ('goal' in additionalDataObj) {
    updatedObj.goal = updatedObj[additionalDataObj.goal.metric] - additionalDataObj.goal.target;
    updatedObj.totalSpend = totalSpend;
    updatedObj.jotai_goal = additionalDataObj.goal;
  }

  return updatedObj;
};

import { useMemo } from 'react';

import { forecastData } from '../forecast/forecastData';
import { fieldValuesToCurrentSpend } from '../forecast/forecastHelpers';

export const quarterlyDataToFieldValues = quarterlyData => {
  const fieldValues = Object.keys(quarterlyData).reduce((accu, key) => {
    const spend = quarterlyData[key];

    const current = spend;
    const currentSpend = spend;
    const value = spend;

    accu[key] = {
      current, currentSpend, value,
    };

    return accu;
  }, {});

  return fieldValues;
};

export const forecastQuarterlyData = (responseCurvesArr, spend) => {
  // divide spend by 90
  const spendPerDay = Object.entries(spend).reduce((accu, [key, row]) => {
    const { value } = row;
    accu[key] = { value: value / 90 };
    return accu;
  }, {});
  const forecasted = forecastData(responseCurvesArr, spendPerDay);

  // multiply forecasted by 90
  const forecastedTotal = Object.entries(forecasted).reduce((accu, [key, value]) => {
    accu[key] = value * 90;
    return accu;
  }, {});

  return forecastedTotal;
};

export const constructComparison = (period1, fiscalQuarters, period2, responseCurvesArr, featureNameMap = {}) => {
  const prediction1 = period1 ? fiscalQuarters[period1].pred : null;
  const prediction2 = period2 ? fiscalQuarters[period2].pred : null;

  const period1FieldValues = period1 ? quarterlyDataToFieldValues(fiscalQuarters[period1].features) : [];
  const period2FieldValues = period2 ? quarterlyDataToFieldValues(fiscalQuarters[period2].features) : [];

  const spend1 = fieldValuesToCurrentSpend(period1FieldValues);
  const spend2 = fieldValuesToCurrentSpend(period2FieldValues);

  const forecast1 = period1 ? forecastQuarterlyData(responseCurvesArr, spend1) : false;
  const forecast2 = period2 ? forecastQuarterlyData(responseCurvesArr, spend2) : false;

  const deltas = Object.entries(forecast1).reduce((accu, [key, baseline]) => {
    const prevSpend1 = spend1[key]?.value;
    const prevSpend2 = spend2[key]?.value;
    const spendDelta = prevSpend2 - prevSpend1;
    const spendDeltaPct = spendDelta / prevSpend1;

    const current = forecast2[key];
    const delta = current - baseline;
    const deltaPct = delta / baseline;

    const deltaPercentOfBaselineTotal = delta / prediction1.pred;
    const obj = {
      key: featureNameMap[key] || key,
      baseline, current, delta, deltaPct, deltaPercentOfBaselineTotal,
      prevSpend1, prevSpend2, spendDelta, spendDeltaPct,
    };
    return [...accu, obj];
  }, []).sort((a, b) => b.deltaPercentOfBaselineTotal - a.deltaPercentOfBaselineTotal);

  const deltaSummary = deltas.reduce((accu, row) => {
    accu.delta += row.delta;
    accu.period1 += row.baseline;
    accu.period2 += row.current;
    return accu;
  }, {
    delta: 0,
    period1: 0,
    period2: 0,
  });

  deltaSummary.actual1 = prediction1?.actual || 0;
  deltaSummary.actual2 = prediction2?.actual || 0;
  deltaSummary.period1Baseline = (prediction1?.actual || 0) - deltaSummary.period1;
  deltaSummary.period2Baseline = (prediction2?.actual || 0) - deltaSummary.period2;
  deltaSummary.macroDelta = deltaSummary.period2Baseline - deltaSummary.period1Baseline;
  return { deltaSummary, deltas };
};

export const useProjectionComparisonData = ({ period1, period2, fiscalQuarters, baselineFiscalQuarters, responseCurvesArr, featureNameMap }) => {
  const { deltaSummary, deltas } = useMemo(() => {
    if (!period1 || !period2) return { deltaSummary: {}, deltas: [] };
    return constructComparison(period1, fiscalQuarters, period2, responseCurvesArr, featureNameMap);
  }, [period1, fiscalQuarters, period2, responseCurvesArr]);

  const { period1Baseline, period2Baseline } = useMemo(() => {
    if (!period1 || !period2) return { period1Baseline: 0, period2Baseline: 0 };
    return {
      period1Baseline: baselineFiscalQuarters[period1].sum,
      period2Baseline: baselineFiscalQuarters[period2].sum,
    };
  }, [period1, period2, baselineFiscalQuarters]);

  const waterfallData = useMemo(() => {
    const first = deltaSummary.actual1 - period1Baseline;
    const macro = deltaSummary.macroDelta;
    const last = deltaSummary.actual2 - period2Baseline;

    const data = [
      { name: `Baseline ${period1} sales`, value: period1Baseline, originalValue: period1Baseline, isBaseline: true, period1: period1Baseline, period2: 0 },
      { name: `Marketing ${period1} sales`, value: first, originalValue: first, isFirst: true, period1: 0, period2: 0 },
    ];

    if (macro > 0) data.push({ name: 'Trend / Seasonal Adjustment', value: macro, originalValue: macro });

    deltas.forEach(row => {
      if (row.delta !== 0) {
        data.push({
          isValue: true,
          name: featureNameMap[row.key] || row.key,
          value: row.delta,
          percent: row.deltaPercentOfBaselineTotal,
          originalValue: row.delta,
          period1: row.baseline,
          period2: row.current,
          period1Name: period1,
          period2Name: period2,
        });
      }
    });

    if (macro < 0) data.push({ name: 'Trend / Seasonal Adjustment', value: macro, originalValue: macro });

    data.push({ name: `Marketing ${period2} sales`, value: -last, originalValue: last, isLast: true, period1: 0, period2: 0 });
    data.push({ name: `Baseline ${period2} sales`, value: -period2Baseline, originalValue: period2Baseline, isBaseline: true, period1: 0, period2: period2Baseline });
    return data;
  }, [deltaSummary, deltas]);

  return { deltaSummary, deltas, waterfallData };
};

// *** CHANNELS ***
export const channelGroups = "/v2/channels/groups";
export const copyMappedEvent = "/v2/channels/mappings/copy";
export const createMappedEvent = "/v2/channels/mappings/create";
export const createExcludedEvent = "/v2/channels/exclusions/create";
export const editExcludedEvent = "/v2/channels/exclusions/edit";
export const editMappedEvent = "/v2/channels/mappings/edit";
export const eventTagsIndex = "/v2/channels/event_tags";
export const excludedEventIndex = "/v2/channels/exclusions";
export const mappedEventIndex = "/v2/channels/mappings";
export const mappedEventOverview = "/v2/channels/overview/view";
export const mappingChecker = "/v2/admin/mappingChecker";
export const promoResults = "/v2/channels/promo_codes";
export const transformTablesIndex = "/v2/channels/transform/tables";

// *** MODELS ***
export const modelsCompare = "/v2/models/compare";
export const modelsValidation = "/v2/models/validation/inspect";
export const modelsView = "/v2/models/view";

// *** SETTINGS ***
export const account = "/v2/settings/account";
export const authorizationsIndex = "/v2/settings/authorizations/view";
export const createConversionEvent = "/v2/settings/conversions/create";
export const conversionEventIndex = "/v2/settings/conversions";
export const conversionEventBackfill = "/v2/settings/conversions/inspect/backfill";
export const crossDeviceConfigIndex = "/v2/settings/data_sources/view";
export const datasetsIndex = "/v2/settings/datasets";
export const dataWarehouse = "/v2/settings/data_warehouse/view";
export const destinationIndex = "/v2/settings/destination/view/outbox";
export const editConversionEvent = "/v2/settings/conversions/edit";
export const inspectTouchpointEvent = "/v2/settings/mappings/inspect";
export const integrationsIndex = "/v2/settings/integrations/view";
export const ottIndex = "/v2/settings/ott";
export const postlog = "/v2/settings/postlog/view";
export const outboxSetup = "/v2/settings/reports/view/outbox/id";
export const reportCustomize = "/v2/settings/reports/view/customize/id";
export const reportInteractive = "/v2/settings/reports/view/edit/interactive/id";
export const reports = "/v2/settings/reports/view";
export const reportSchema = "/v2/settings/reports/view/schema/id";
export const reportStatus = "/v2/settings/reports/view/inspect/id";
export const savedReportEditor = "/v2/settings/reports/view/edit/id";
export const viewConversionEvent = "/v2/settings/conversions/view";

// *** ADMIN ***
export const batchConfigCreate = "/v2/admin/batch/create";
export const batchConfigEdit = "/v2/admin/batch/edit";
export const batchConfigIndex = "/v2/admin/batch";
export const batchConfigView = "/v2/admin/batch/view";
export const batchInboxView = "/v2/admin/batch/inbox/view";
export const dataSources = "/v2/admin/data_sources";
export const pixelLive = "/v2/pixel/live";
export const pixelView = "/v2/settings/pixels";
export const triggerIndex = "/v2/admin/triggers";
export const webhooksCreate = "/v2/admin/webhooks/create";
export const webhooksEdit = "/v2/admin/webhooks/edit";
export const webhooksIndex = "/v2/admin/webhooks";

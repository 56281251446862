import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { postRedshiftGrantPermission } from '../../../api/datalake';
import { useStepsState, useStepsDisplay } from '../StepsSectionContext';
import ErrorMessage from '../components/ErrorMessage';
import {useDatalake} from "../DatalakeContext/hooks";

const formStyles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  height: 'auto',
};

const RedshiftGrantPermission = () => {
  const { datalakeConnection, refreshDatalakeConnection } = useDatalake();
  const [stepDisplay, setStepDisplay] = useStepsDisplay();
  // eslint-disable-next-line no-unused-vars
  const [_, setStepsStateData] = useStepsState();
  // eslint-disable-next-line no-unused-vars
  const [stateData, setStateData] = useStepsState();

  const [clientRoleArn, setClientRoleArn] = useState('');
  const [error, setError] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    if (datalakeConnection?.details && stepDisplay['1'] === true && stepDisplay['2'] === false) {
      const {
        details: {
          connection_details: {
            client_iam_role
          },
          redshift_objects: {
            inline_policy,
            create_external_schema
          }
        }
      } = datalakeConnection;
      setClientRoleArn(client_iam_role)
      setStepDisplay(2, true);
      setDisableSubmit(true);
      setStepsStateData({
        platform_metadata: {
          inline_policy: inline_policy,
          create_external_schema: create_external_schema
        }
      });
    }
  }, [datalakeConnection, stepDisplay]);

  const handleSubmitArn = () => {
    setError('');
    setDisableSubmit(true);
    postRedshiftGrantPermission(clientRoleArn)
      .then(data => {
        // eslint-disable-next-line no-param-reassign
        data = data.data;
        setStateData(data);
        if (data.status === 'success') {
          setStepDisplay(2, true);
          refreshDatalakeConnection()
        }
        setDisableSubmit(false);
      })
      .catch(e => {
        setError(e.response.data.message);
        setDisableSubmit(false);
      });
  };

  const handleInputChange = (_, { value }) => setClientRoleArn(value);

  return (
    <Form>
      <Form.Group style={formStyles}>
        <Form.Field>
          <Form.Input
            label="ARN"
            placeholder="arn:aws:iam::99912..."
            value={clientRoleArn}
            onChange={handleInputChange}
            style={{ width: 500 }}
          />
        </Form.Field>
        <Form.Field>
          <Form.Button
            primary
            size="small"
            content="Submit ARN"
            type="button"
            onClick={handleSubmitArn}
            style={{ fontSize: 13 }}
            disabled={disableSubmit}
          />
        </Form.Field>
      </Form.Group>
      {error && <ErrorMessage message={error} />}
    </Form>
  );
};

export default RedshiftGrantPermission;

import React from 'react'
import { Message, Checkbox, Accordion, Divider, Loader, Header, Icon, Segment } from 'semantic-ui-react'
import * as d3 from 'rockerbox_d3_legacy_clone'
import { ContentCard, IndexGridNew } from '@rockerbox/styleguide'

import ToastNotification from '../../parts/ToastNotification'
import { getBatchConfigData, getProcessedReports, getPixelIntegrations, postPixelIntegrations } from '../../../utils/api'

const FACEBOOK_PIXEL_INTEGRATION = "fbq"
const FACEBOOK_REPORT_TYPE = "facebook_conversions_hourly"
const FACEBOOK_VIEW_PIXEL = "offsite_conversion.fb_pixel_view_content"
const FACEBOOK_CONV_PIXEL = "offsite_conversion.fb_pixel_purchase"


const PixelSetting = () => {
  const [fbPixelEnabled, setFbPixelEnabled] = React.useState(null)
  const [fbPixelRecommended, setFbPixelRecommended] = React.useState(null)
  const [fbPixelData, setFbPixelData] = React.useState([])
  const [forceShowSetting, setForceShowSetting] = React.useState(false)

  React.useEffect(() => {
    getFacebookPixelSetting()
    getFacebookData()
  }, [])

  const getFacebookPixelSetting = async () => {
    const res = await getPixelIntegrations()
    const pixelIntegrations = res.map(({ integration }) => integration)
    const isFbPixelEnabled = pixelIntegrations.indexOf(FACEBOOK_PIXEL_INTEGRATION) > -1
    setFbPixelEnabled(isFbPixelEnabled)
  }

  const getFacebookData = async () => {
    const batchConfigs = await getBatchConfigData()
    const fbReport = batchConfigs.find(row => row.report_type_name == FACEBOOK_REPORT_TYPE) || {}
    const { report_id } = fbReport
    const fbProcessedData = await getProcessedReports(report_id, "processed", "data")
    generateFbPixelRecommendation(fbProcessedData)
  }

  const generateFbPixelRecommendation = (data) => {
    const nested = d3.nest()
      .key(({ action_type }) => action_type)
      .rollup((v) => d3.sum(v, (d) => d['1d_view']))
      .entries(data)
    
    const _fbPixelData = nested.filter(({ key }) => !!key)
    setFbPixelData(_fbPixelData)

    const fbPixelMap = Object.assign({}, ..._fbPixelData.map(({key, values}) => ({[key]: values})))
    const viewPixelCount = fbPixelMap[FACEBOOK_VIEW_PIXEL] || 0
    const purchasePixelCount = fbPixelMap[FACEBOOK_CONV_PIXEL] || 0

    // check if ViewContent pixel count is less than 5x of Purchase pixel count
    const _fbPixelRecommended = !purchasePixelCount || ((viewPixelCount * 5) < purchasePixelCount)
    setFbPixelRecommended(_fbPixelRecommended)
  }

  const msg = (
    <Segment inverted color='green' style={{width: 'calc(100vw - 10px)'}}>
      Green
    </Segment>
  )
  const msg2 = (
    <Message
      success
      header='Your user registration was successful'
      content='You may now log-in with the username you have chosen'
      style={{width: 'calc(100vw - 10px)'}}
    />
  )

  const changeFbPixelSetting = () => {
    const fbPixelSetting = !fbPixelEnabled

    postPixelIntegrations({
      integration: FACEBOOK_PIXEL_INTEGRATION,
      active: fbPixelSetting
    })
      .then(() => {
        setFbPixelEnabled(!fbPixelEnabled)
        ToastNotification({
          type: "success",
          timeout: 10000,
          content: fbPixelSetting
            ? "Your Facebook ViewContent pixels will now be managed by Rockerbox."
            : "Rockerbox will no longer fire Facebook ViewContent pixels for you. Please ensure you have them implemented correctly."
        })
      })
      .catch((err) => {
        console.error('Error changing', FACEBOOK_PIXEL_INTEGRATION, 'pixel integration', err)
        ToastNotification({
          type: "error",
          content: "Sorry, there was a server error when changing your Facebook Pixel Setting. Please try again."
        })
      })
  }

  const cols = [
    {display: 'Facebook Pixel Name', key: 'key'},
    {display: 'Number of Pixel Fires Yesterday', key: 'values'},
  ]

  const panels = [
    {
      key: 'fbPixelData',
      title: 'Facebook Pixel Data',
      content: {
        content: (
          !!fbPixelData.length
            ? <IndexGridNew sortable data={fbPixelData} cols={cols} fallbackMsg="No Facebook Pixel data found"/>
            : <Loader active inline='centered' size='small'>Loading Facebook Pixel Data</Loader>
        ),
      },
    },
  ]

  const [messageIcon, messageTitle, messageDescription] = React.useMemo(() => {
    if (!!fbPixelRecommended) 
      return [
        "info circle",
        "Low Volume for Facebook ViewContent Pixel",
        <>
          We detected that the Facebook <strong>ViewContent</strong> pixel is not firing as frequently as expected.
          In order for Facebook Synthetic Events to work accurately, this needs to be resolved.<br />
          Enable the setting below to have Rockerbox manage this and fire the Facebook pixel for you.
        </>
      ]
    if (fbPixelRecommended === false)
      return [
        "check",
        "Facebook Pixels are working correctly",
        <>
          Your Facebook <strong>ViewContent</strong> and <strong>Purchase</strong> pixels are firing correctly.<br />
          We recommend keeping your Facebook Pixel Settings setting as-is.&nbsp;
          <a onClick={() => setForceShowSetting(true)} style={{cursor: 'pointer'}}>
            Still want to change it?
          </a>
        </>
      ]
    return [null, null, null]
  }, [fbPixelRecommended])

  return (
    <ContentCard title="Facebook Pixel Settings" loading={fbPixelRecommended === null}>
      {!!messageTitle && !!messageDescription &&
        <Message
          info
          header={
            <Header>
              <Icon fitted name={messageIcon} style={{ paddingBottom: 20, fontSize: 16 }} />&nbsp;
              {messageTitle}
            </Header>
          }
          content={messageDescription}
        />
      }
      {(!!fbPixelRecommended || !!forceShowSetting) && <>
        <Checkbox
          toggle
          label={<label>
            Allow Rockerbox to manage my Facebook&nbsp;
            <code><strong>ViewContent</strong></code>
            &nbsp;pixels
          </label>}
          checked={fbPixelEnabled}
          disabled={fbPixelEnabled === null}
          onChange={changeFbPixelSetting}
        />
        <Divider />
      </>}
      <Accordion panels={panels} />
    </ContentCard>
  )
}

export default PixelSetting

import React, { Component } from 'react';
import { getBatchConfigData, getBatchInbox, updateBatchInboxUploadId } from '../../utils/api';
import { SplitLayout } from '@rockerbox/styleguide';
import { Button } from 'semantic-ui-react';
import _ from 'lodash';

import * as routes from '../../routes';
import { withRouter } from 'react-router-dom';
import InboxCreateModal from './InboxCreateModal';
import InboxSetup from './setup/InboxSetup';
import InboxMenu from './setup/InboxMenu';
import ConfigSetupTable from './setup/ConfigSetupTable';

const toMap = (key) => (p,c) => {
  p[c[key]] = (p[c[key]] || []).concat([c])
  return p
}

class BatchConfigIndex extends Component {
  state = {
    configs: [],
    modalOpen: false,
    displayName: '',
    name: '',
    disableButton: false,
    inboxByInboxId: {},
    keys: [],
    modalOpen: false,
    selectedInboxId: '',
    configByInboxId: {}
  }

  componentDidMount() {
    getBatchInbox()
      .then(inboxes => {
        const { inboxId } = this.props.match.params;
        inboxes.map(row => row.mapper = row.mapper[0])

        const inboxByInboxId = inboxes.reduce(toMap("inbox_id"), {})
        const keys = Object.keys(inboxByInboxId);
        const selectedInboxId = inboxId ? inboxId : keys[0];
        this.setState({inboxByInboxId, keys, selectedInboxId})
      })

    getBatchConfigData()
      .then(configs => {
        const configByInboxId = configs.reduce(toMap("inbox_id"), {})
        this.setState({configs, configByInboxId})
      })
  }

  componentDidUpdate(prevProps) {
    const { inboxId } = this.props.match.params;
    const prevInboxId = prevProps.match.params.inboxId;
    const diffInboxId = inboxId !== prevInboxId;

    const { keys } = this.state;
    if(diffInboxId) {
      this.setState({selectedInboxId: inboxId});
    }

    if(diffInboxId && inboxId == undefined) {
      this.props.history.push(`${routes.batchConfigIndex}/index/${keys[0]}`)
    }
  }

  handleInboxSwitch = (id) => this.props.history.push(`${routes.batchConfigIndex}/index/${id}`)
  handleTextChange = (val, key) => this.setState({[key]: val})

  toggleModal = () => this.state.modalOpen ? this.setState({modalOpen: false}) : this.setState({modalOpen: true});

  toggleUploadId = (inbox_id, inbox_type, toggle) => {
    const cloned_state = _.cloneDeep(this.state);
    const targetMapper = cloned_state.inboxByInboxId[inbox_id][0].mapper;

    updateBatchInboxUploadId(inbox_id, inbox_type, toggle)
      .then(resp => {

          const { 
            date_as_upload_id, 
            upload_id_from_filename 
          } = resp;

          targetMapper.upload_id_from_filename = (toggle == "filename") ? upload_id_from_filename : 0;
          targetMapper.date_as_upload_id = (toggle == "date") ? date_as_upload_id : 0;

          this.setState(cloned_state);
      })
  }

  render() {
    const { inboxByInboxId, keys, modalOpen, selectedInboxId, configByInboxId } = this.state;

    const selected = inboxByInboxId[selectedInboxId]
    const selectedInbox = selected ? selected[0] : {};
    const { name, inbox_type, mapper, inbox_id } = selectedInbox;
    const hasMapper = !!mapper 
    const { toggleUploadId, handleInboxSwitch } = this

    const configs = configByInboxId[selectedInboxId]
    const hasConfig = configs && !!configs.length
    
    return(
      <React.Fragment>
        <SplitLayout
          leftWidth={4} rightWidth={12}
          leftContent={
            <React.Fragment>
              <InboxMenu {...{keys, inboxByInboxId, selectedInboxId, handleInboxSwitch}} />
              <Button primary content="Inbox" icon="plus" onClick={this.toggleModal}/>
            </React.Fragment>
          }
          rightContent={selectedInbox ?  <>
              <ConfigSetupTable {...{ hasConfig, selected, selectedInbox, inbox_id, configs}} />
              <InboxSetup {...{ inbox_type, mapper, name, toggleUploadId }} />
            </> : null
          }
        />
        <InboxCreateModal modalOpen={modalOpen} handleClose={this.toggleModal}/>
      </React.Fragment>
    )
  }
}

export default withRouter(BatchConfigIndex);

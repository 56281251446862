import React, { Component } from 'react';
import { Button, Checkbox, Icon } from 'semantic-ui-react';
import { ContentCard, IndexGrid, IndexGridNew } from '@rockerbox/styleguide'
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import * as d3 from 'rockerbox_d3_legacy_clone';

import { AdAccountSelector, ViewSelector, AdsTable, AdsAccountingTable, AdsErrorAccountingTable, ErrorView, RedCell, WrapCell  }  from './AdsManagementParts.js';

import RunButton from '../parts/RunButton';

import {
  getFacebookAdsData,
  getFacebookAdAccounts,
  updateFacebookAdsConfig,
  getFacebookAdsAccounting,
  getFacebookAdsErrorAccounting
} from '../../utils/api';


class FacebookAdsView extends Component {

  state = {
    activeView: 'missing',
    date: undefined,
    ads: undefined,
    adsData: undefined,
    correctAds: undefined,
    badAds: undefined,
    badAdsData: undefined,
    adsAccountingData: undefined,
    adsErrorAccountingData: undefined,
    account: undefined,
    accounts: undefined,
    excludedAdIds: undefined,
    excludedAds: undefined,
    synced: false,
    loading: false,
    updatingAds: false,
    showModal: false,
    errorMsg: undefined,
    disableAccountSwitcher: false,
    forceUpdateAdIds: []
  }

  componentDidMount() {
    this.setState({ loading: true })
    const { id } = this.props.match.params
    const date = moment.utc().utcOffset(-5).subtract(1, 'days').format('YYYY-MM-DD');
    getFacebookAdAccounts().then(accounts => {
      accounts = accounts.map(row => {
        row.text = row.account_name
        row.value = row.id
        return row
      })
      const account = accounts[0];
      this.getAdsAccountingData(account);
      const excludedAdIds = account.excluded_ads || [];
      this.setState({ account, accounts, date, excludedAdIds }, () => this.getAdsData());
    });
  }

  getAdsData() {
    const { account, date } = this.state;
    const { account_id, allpages_id } = account;

    return getFacebookAdsData(date, allpages_id, account_id).then(data => {
      const adsData = data[`ads_data_${account_id}`];
      const badAdsData = data[`bad_ads_data_${account_id}`];
      this.setState({ adsData, badAdsData }, () => {
        this.classifyAdsData();
        this.getAdsAccountingData(account);
      });
    });
  }

  getAdsAccountingData(account) {
    Promise.all([getFacebookAdsAccounting(account.account_id),getFacebookAdsErrorAccounting(account.account_id)]).then((data) => {
      const { badAdsData } = this.state;
      const badAdIds = badAdsData.map(a => a.ad_id);
      const errors = data[1].filter(d => badAdIds.includes(d['ad_id']));
      const adsErrorAccountingData = d3.nest()
        .key(e => e.error_title)
        .entries(errors)
        .map((e) => {
          return {title: e.key, errors: e.values, count: e.values.length};
        });

      console.log(adsErrorAccountingData);
      this.setState({ adsAccountingData: data[0], adsErrorAccountingData });
    });
  }

  classifyAdsData() {
    const { account, adsData, badAdsData, excludedAdIds } = this.state;
    const { account_id } = account;

    const mapper = d3.nest().key(d => d.campaign_id).rollup(d => d[0].campaign_name).entries(adsData);
    const campaignNameMap = mapper.reduce((p,c) =>  {p[c.key] = c.values; return p}, {});

    const ads = d3.nest().key(d => d.campaign_id).entries(adsData).map(o => {
      return {account_id: account_id, name: campaignNameMap[o['key']], id: o['key'], ads: o['values'], numAds: o['values'].length}
    });

    const correctAdsData = adsData.filter(a => (!badAdsData.map(b => b.ad_id).includes(a.ad_id) && !excludedAdIds.includes(a.ad_id)));
    const correctAds = d3.nest().key(d => d.campaign_id).entries(correctAdsData).map(o => {
      return {account_id: account_id, name: campaignNameMap[o['key']], id: o['key'], ads: o['values'], numAds: o['values'].length}
    });

    const excludedAds = adsData.filter(a => excludedAdIds.includes(a.ad_id))

    const adsAboveThreshold = badAdsData.filter(b => (b.impressions >= account.max_imps) && !excludedAdIds.includes(b.ad_id))
    const badAds = badAdsData.filter(b => !excludedAdIds.includes(b.ad_id) && (b.impressions < account.max_imps));

    this.setState({ ads, correctAds, badAds, excludedAds, adsAboveThreshold, loading: false });
  }

  reloadAdsData() {
    this.setState({ loading: true });
    this.getAdsData().then(
      this.setState({ synced: true })
    );
  }

  addExcludedAd(adId) {
    const { excludedAdIds, account } = this.state;

    excludedAdIds.push(adId);
    account.excluded_ads = excludedAdIds;

    updateFacebookAdsConfig(account.id, account).then(d => {
      this.setState({ excludedAdIds, account }, () => this.classifyAdsData());
    });
  }

  removeExcludedAd(adId) {
    const { excludedAdIds, account } = this.state;

    const i = excludedAdIds.indexOf(adId);
    excludedAdIds.splice(i,1);
    account.excluded_ads = excludedAdIds;

    updateFacebookAdsConfig(account.id, account).then(d => {
      this.setState({ excludedAdIds, account }, () => this.classifyAdsData());
    });
  }

  toggleForceUpdateAd(adId) {
    const { forceUpdateAdIds, account } = this.state;

    const i = forceUpdateAdIds.indexOf(adId);
    if (i > -1) forceUpdateAdIds.splice(i,1);
    else forceUpdateAdIds.push(adId);

    this.setState({ forceUpdateAdIds });
  }

  toggleAllForceUpdateAds() {
    const { forceUpdateAdIds, adsAboveThreshold } = this.state;
    const adIds = (forceUpdateAdIds.length < adsAboveThreshold.length) ? adsAboveThreshold.map(a => a.ad_id) : [];
    this.setState({ forceUpdateAdIds: adIds });
  }

  onAccountSelection = (e, d) => {
    const { accounts } = this.state;
    const accountId = d.value;
    const account = accounts.filter(a => a.id == accountId)[0];
    const excludedAdIds = account.excluded_ads || [];

    this.setState({ account, excludedAdIds, loading: true }, () => this.getAdsData());
  }

  toggleAutoUpdate = () => {
    const { account } = this.state;
    const autoUpdate = account.auto_update == 1 ? 0 : 1;

    this.setState({ disableAccountSwitcher: true });

    account.auto_update = autoUpdate;

    updateFacebookAdsConfig(account.id, account).then(d => {
      this.setState({ disableAccountSwitcher: false, account })
    });
  }

  updateAccountMaxImpressions = (e, d) => {
    const { account } = this.state;

    this.setState({ disableAccountSwitcher: true });

    account.max_imps = d.value;

    updateFacebookAdsConfig(account.id, account).then(d => {
      this.setState({ disableAccountSwitcher: false, account }, () => this.classifyAdsData());
    });
  }

  setActiveView = (activeView) => {
    this.setState({ activeView });
  }

  handleModalSubmit() {
    this.setState({ showModal: false });
  }

  onRunError = (errorMsg) => {
    this.setState({ errorMsg, loading: false })
  }


  render() {

    const { activeView, ads, adsData, correctAds, badAds, account, accounts, excludedAdIds, adsAboveThreshold, excludedAds, forceUpdateAdIds } = this.state;
    const { loading, synced, showModal, disableAccountSwitcher, date, errorMsg, adsAccountingData, adsErrorAccountingData } = this.state;

    const CheckboxCell = ({item}) => {
      return (
        <div style={{textAlign: 'center', paddingTop: '50%'}}>
          <Checkbox
            checked={forceUpdateAdIds.includes(item.ad_id)}
            onClick={() => this.toggleForceUpdateAd(item.ad_id)}
          />
        </div>
      );
    }

    const ExcludeButtonCell = ({item}) => {
      return (
        <div style={{textAlign: 'center', paddingTop: '50%'}}>
          <Button
            size={'tiny'}
            onClick={() => this.addExcludedAd(item.ad_id)}
            content={'X'}
            style={{ backgroundColor: 'red', color: 'white', fontWeight: 'bolder' }}
          />
        </div>
      );
    }

    const IncludeButtonCell = ({item}) => {
      return (
        <div style={{textAlign: 'center', paddingTop: '50%'}}>
          <Button
            size={'tiny'}
            onClick={() => this.removeExcludedAd(item.ad_id)}
            content={<Icon name='check' />}
            style={{ backgroundColor: 'green', color: 'white', fontWeight: 'bolder' }}
          />
        </div>
      );
    }


    const badAdCols = [
      {display: 'Campaign', key: 'campaign_name', as: WrapCell, headerWidth: 2},
      {display: 'Adset', key: 'adset_name', as: WrapCell, headerWidth: 2},
      {display: 'Ad', key: 'ad_name', as: WrapCell, headerWidth: 2},
      {display: 'Creative', key: 'creative_name', as: WrapCell, headerWidth: 2},
      {display: 'Impressions', key: 'impressions', headerWidth: 1},
      {display: 'Clicks', key: 'clicks', headerWidth: 1},
      {display: 'Link(s)', key: 'link', as: IndexGrid.EllipsedCell, headerWidth: 1},
      {display: 'URL Tags', key: 'url_tags', as: WrapCell, headerWidth: 1},
      {display: 'Missing Params', key: 'missing_tags', as: RedCell, headerWidth: 1},
      {display: 'Exclude', as: ExcludeButtonCell, headerWidth: 1}
    ]

    const aboveThresholdCols = [{display: 'Force Update?', as: CheckboxCell, headerWidth: 1}].concat([...badAdCols])
    aboveThresholdCols.pop()

    const excludedAdCols = [...badAdCols];
    excludedAdCols.pop()
    excludedAdCols.push({display: 'Include', as: IncludeButtonCell, headerWidth: 1})


    const adCols = [
      {display: 'Account ID', key: 'account_id'},
      {display: 'Campaign', key: 'name'},
      {display: 'Campaign ID', key: 'id'},
      {display: 'Number of Ads', key: 'numAds'},
      {display: "", key: "expand", as: IndexGrid.ExpandContractCell}
    ]


    if (ads == undefined) return <ContentCard.CardLoader />;

    return (
      <div>
      { loading ? <ContentCard.CardLoader />
        :
          <React.Fragment>
            <AdAccountSelector
              onSelect={this.onAccountSelection}
              onToggle={this.toggleAutoUpdate}
              disabled={disableAccountSwitcher}
              onUpdateImps={this.updateAccountMaxImpressions}
              {...{ account, accounts, badAds }}
            />
            <ViewSelector
              onClick={this.setActiveView}
              {...{ activeView, badAds, adsData, excludedAdIds, adsAboveThreshold, correctAds, excludedAds }}
            />
            { activeView == "missing" &&
              <React.Fragment>
                { errorMsg ?
                  <ErrorView message={errorMsg} />
                  :
                  badAds &&
                  <React.Fragment>
                    { badAds.length > 0 &&
                      <ContentCard>
                        <RunButton
                          jobTypeKey={`update_facebook_ads_${new Date().getTime()}`}
                          path="facebook_ads/update"
                          data={{account_id: account.account_id}}
                          onSuccess={() => {
                            this.setState({ forceUpdateAdIds: [] }, () => this.reloadAdsData());
                          }}
                          onError={() => this.onRunError("There was an error updating your ads")}
                          content={"Update All"}
                        />
                      </ContentCard>
                    }
                    <IndexGrid
                      style={{ fontSize: "11px"}}
                      data={badAds}
                      cols={badAdCols}
                      fallBackMsg={
                        ((badAds && badAds.length < 1) || synced) ? "No ads with missing parameters" : "Run sync to see ads with missing parameters"
                      }
                    />
                  </React.Fragment>
                }
                <div style={{minHeight: '50px', padding: '5px'}}>
                { adsData.length < 1 && !synced && !errorMsg &&
                  <RunButton
                    style={{float: 'right'}}
                    jobTypeKey={`sync_facebook_ads_${date}_${account.account_id}`}
                    path="facebook_ads/sync"
                    data={{account_id: account.account_id}}
                    onSuccess={() => this.reloadAdsData()}
                    onError={() => this.onRunError("There was an error syncing your ads")}
                    content="Sync Ads Data"
                  />
                }
                </div>
              </React.Fragment>
            }
            {activeView == "above_threshold" &&
              <React.Fragment>
                { adsAboveThreshold && adsAboveThreshold.length > 0 &&
                  <ContentCard>
                    <div style={{float: 'left', padding: '10px'}}>
                      <Checkbox
                        checked={forceUpdateAdIds.length == adsAboveThreshold.length}
                        onClick={() => this.toggleAllForceUpdateAds()}
                      />
                      <label style={{fontSize: '14px', padding: '10px', marginTop: '-5px'}}>Select All</label>
                    </div>
                    <RunButton
                      disabled={forceUpdateAdIds.length < 1}
                      jobTypeKey={`force_update_facebook_ads_${new Date().getTime()}`}
                      path="facebook_ads/force_update"
                      data={{account_id: account.account_id, ad_ids: forceUpdateAdIds}}
                      onSuccess={() => this.reloadAdsData()}
                      onError={() => this.onRunError("There was an error updating your ads")}
                      content={"Force Run Update"}
                    />
                  </ContentCard>
                }
                <IndexGrid
                  style={{ fontSize: "11px"}}
                  idKey="id"
                  data={adsAboveThreshold}
                  cols={aboveThresholdCols}
                  fallBackMsg={"No ads above threshold"}
                />
              </React.Fragment>
            }
            {activeView == "excluded" &&
              <IndexGrid
                style={{ fontSize: "11px"}}
                data={excludedAds}
                cols={excludedAdCols}
                fallBackMsg={"No excluded ads"}
              />
            }
            {activeView == "correct" &&
              <IndexGridNew
                style={{ fontSize: "11px"}}
                idKey="id"
                data={correctAds}
                cols={adCols}
                expandable={true}
                expandCell={true}
                expandedComponent={AdsTable}
                fallBackMsg={synced ? "No active ads" : "No ads data"}
              />
            }
            {activeView == "total" &&
              <React.Fragment>
                <IndexGridNew
                  style={{ fontSize: "11px"}}
                  idKey="id"
                  data={ads}
                  cols={adCols}
                  expandable={true}
                  expandCell={true}
                  expandedComponent={AdsTable}
                  fallBackMsg={synced ? "No active ads" : "No ads data"}
                />
                <ContentCard
                  collapsing={true}
                  defaultCollapsed={true}
                  title="Ad Update Accounting"
                >
                  { adsAccountingData && <AdsAccountingTable data={adsAccountingData} /> }
                </ContentCard>
                <ContentCard
                  collapsing={true}
                  defaultCollapsed={true}
                  title="Ad Update Errors"
                >
                  { adsErrorAccountingData &&
                    <IndexGridNew
                      style={{ fontSize: "11px"}}
                      idKey="title"
                      data={adsErrorAccountingData}
                      cols={[{display:'Error',key:'title'},{display:'Count',key:'count'},{display: "", key: "expand", as: IndexGrid.ExpandContractCell}]}
                      expandable={true}
                      expandCell={true}
                      expandedComponent={AdsErrorAccountingTable}
                      fallBackMsg={synced ? "No active ads" : "No ads data"}
                    />
                  }
                </ContentCard>
              </React.Fragment>
            }
          {/*<FacebookAdsModal
            open={showModal}
            onClose={() => this.setState({ showModal: false })}
            onSubmit={() => this.handleModalSubmit()}
            {...{ badAds }}
          />*/}
        </React.Fragment>
      }
      </div>
    )
  }
}

export default withRouter(FacebookAdsView);

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { activateAccount, activateGoogleAccount } from '../../../utils/api';
import {
  Grid,
  Form,
  Input,
  Button,
  Segment,
  Header,
  Container,
  Message
} from 'semantic-ui-react';
import { GatewayWrap } from '@rockerbox/styleguide';
import css from '../button.css';

class Activate extends Component {
  state = {
    password: '',
    confirmPassword: '',
    disabledStatus: true,
    nonce: '',
    username: '',
    advertiser: '',
    googleSSOEnabled: false,
    hasError: false,
    errorMsg: ''
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const nonce = urlParams.get('nonce');
    const username = urlParams.get('username');
    const advertiser = urlParams.get('advertiser');
    const googleSSOEnabled = urlParams.get('google_sso');

    if (googleSSOEnabled) {
      window.google.accounts.id.initialize({
        client_id: '665449023953-ckh1eop8o8uiabn88ai7mkb30fa9h5kv.apps.googleusercontent.com',
        callback: this.onGoogleSignIn,
      })

      window.google.accounts.id.renderButton(document.getElementById("googleLogin"), {
        theme: "filled_blue",
        text: "signin_with",
      })

      window.google.accounts.id.prompt();
    }

    this.setState({ nonce, username, advertiser, googleSSOEnabled });
  }

  handleInputChange = event => {
    const target = event.target;
    const name = target.name;
    const value = event.target.value;

    this.setState(
      {
        [name]: value
      },
      () => this.validatePassword()
    );
  };

  validatePassword = () => {
    const { password, confirmPassword } = this.state;
    if (
      password.length >= 8 &&
      confirmPassword.length >= 8 &&
      password === confirmPassword
    ) {
      this.setState({ disabledStatus: false });
    } else {
      this.setState({ disabledStatus: true });
    }
  };

  handleSubmit = () => {
    const submitObj = {
      password: this.state.password,
      nonce: this.state.nonce
    };

    activateAccount(submitObj).then(data => window.location = '/');
  };

  onGoogleSignIn = (response) => {
    const { nonce } = this.state;
    const token = response.credential;
    const data = {
      'token': token,
      'nonce': nonce
    };

    activateGoogleAccount(data).then((d) => {
      return d.response;
    }).then((resp) => {
      if (resp.error) this.setState({ hasError: true, errorMsg: resp.error, loading: false });
      else window.location = '/v3';
    })
  }

  render() {
    const { password, confirmPassword, disabledStatus, googleSSOEnabled, hasError, errorMsg } = this.state;

    return (
      <GatewayWrap>
        <Segment raised loading={false}>
          <Header as="h3" color="grey" className="m-b-20">
            You've been invited to join {this.state.advertiser}!
            <Header.Subheader>
            { googleSSOEnabled ?
              "Sign in with Google to get started."
              :
              "Set your account password to get started."
            }
            </Header.Subheader>
          </Header>
          <Form onSubmit={this.handleSubmit}>
            {hasError && (
              <Message negative>
                <Message.Header>{errorMsg}</Message.Header>
              </Message>
            )}
            <Container textAlign="left">
              <Form.Field>
                <label>Email</label>
                <Input
                  type="email"
                  fluid
                  icon="envelope open outline"
                  iconPosition="left"
                  placeholder={this.state.username}
                  disabled={true}
                />
              </Form.Field>
              { googleSSOEnabled ?
                <button id="googleLogin"></button>
                :
                <React.Fragment>
                  <Form.Field>
                    <label>Password</label>
                    <Input
                      type="password"
                      fluid
                      icon="shield"
                      iconPosition="left"
                      placeholder="Use at least 8 characters"
                      value={this.state.password}
                      name="password"
                      onChange={this.handleInputChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Confirm Password</label>
                    <Input
                      type="password"
                      fluid
                      icon="shield"
                      iconPosition="left"
                      placeholder="One more time, just to make sure!"
                      value={this.state.confirmPassword}
                      name="confirmPassword"
                      onChange={this.handleInputChange}
                    />
                  </Form.Field>
                </React.Fragment>
            }
            </Container>
            { !googleSSOEnabled &&
              <div className="m-t-20">
                <Button
                  primary
                  fluid
                  size="big"
                  disabled={this.state.disabledStatus}
                type="submit">
                  Activate Account
                </Button>
              </div>
            }
          </Form>
        </Segment>
        <div className="white m-t-30">
          Need help?&nbsp;
          <a href="mailto:support@rockerbox.com" className="white underlined">
            Contact us
          </a>
        </div>
      </GatewayWrap>
    );
  }
}

export default withRouter(Activate);

import CacheContext from './CacheContext';
import React, { useContext } from 'react';

export const formatPercent = x => x ? x.toLocaleString("en", {style: "percent"}) : "-";

export const formatWithCommas = x => {
  if (!x) return '-'
  const formatter = Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 0,
  })
  return formatter.format(x)
}

export const buildCurrency = (amount) => {
  return <Currency amount={amount} />
}

export const Currency = ({ amount }) => {
  const [ contextState ] = useContext(CacheContext);

  return contextState.formatCurrency(amount)
}

export const formatTiersValue = tiers => {
  if (!tiers) {
    return {}
  }

  Object.keys(tiers).forEach(key => {
    const shouldSplit = tiers[key] && tiers[key].indexOf(",") !== -1;
    tiers[key] = shouldSplit ? tiers[key].split(",") : tiers[key];
  })

  return tiers;
}
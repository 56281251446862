import hash from 'object-hash';

import { determineHasIssues } from '../../utils/trackingQa';

/**
 * Parses the issues from the platform QA data and missing parameters data.
 *
 * @param {Object} platformQAData - The platform QA data.
 * @param {Object} missingParamsDataRaw - The raw missing parameters data.
 * @returns {Object} - The parsed issues data.
 */
export const parseIssues = (platformQAData, missingParamsDataRaw) => {
  const has_missing_keys = platformQAData?.all?.has_missing_keys;
  const platform_total = platformQAData?.all?.platform_total;

  const percentMissing = platform_total ? has_missing_keys / platform_total : undefined;
  const hasIssues = determineHasIssues(percentMissing);
  const missingParamsTableData = Object.values(missingParamsDataRaw || {});

  return {
    hasIssues,
    missingParamsTableData,
  };
};

/**
 * Processes a list of URLs and their referral URLs to extract and hash UTM parameters,
 * and aggregates data based on the unique hash of these parameters.
 *
 * @param {Array} combinedTruncatedUrlAndReferralUrls - An array of objects, each containing a 'truncated_url'
 *   and a 'referral_url'. The 'truncated_url' is the main URL to process, and 'referral_url' is its source.
 * @param {Array} resolutions - An array of resolution objects that might contain a 'hash' key which
 *   corresponds to the hash of UTM parameters for resolving specific issues.
 *
 * @returns {Object} - An object where each key is a unique hash of UTM parameters, and each value is an object
 *   containing the count of occurrences, the referrer, the list of pages, and any specific resolution associated
 *   with these parameters.
 *
 * Each object in the return value includes:
 * - `count`: Number of times this set of parameters appears.
 * - `referrer`: The normalized referrer URL or 'NA' if not applicable.
 * - `pages`: Array of page objects derived from the URL, each with a 'url' and a 'path'.
 * - `resolution`: Any resolution associated with this parameter set, if found in the resolutions array.
 */
export const parseMissingParamsData = (combinedTruncatedUrlAndReferralUrls, resolutions) => combinedTruncatedUrlAndReferralUrls
  .map(({ truncated_url, referral_url }) => {
    let referralUrl;
    // ensure that referral_url is a valid url, otherwise page breaks
    if (referral_url === 'NA') {
      referralUrl = referral_url;
    } else {
      const referralUrlObj = new URL(referral_url);
      referralUrl = `${referralUrlObj.protocol}//${referralUrlObj.host}/`;
    }

    const urlObj = new URL(truncated_url);
    const params = {};
    urlObj.searchParams.forEach((v, k) => {
      if (k.startsWith('utm_')) {
        params[k] = v;
      }
    });

    const rowHash = hash(params);
    return {
      ...params,
      rowHash,
      params,
      referrer: referralUrl,
      pages: [{
        url: `${urlObj.protocol}//${urlObj.host}${urlObj.pathname}`,
        path: urlObj.pathname === '/' ? 'home page' : urlObj.pathname,
      }],
      resolution: resolutions.find(r => r.hash === rowHash)?.resolution,
    };
  })
  .reduce((acc, curr) => {
    if (acc[curr.rowHash]) {
      // eslint-disable-next-line no-param-reassign
      acc[curr.rowHash].count += 1;
      if (!acc[curr.rowHash].pages.find(x => x.path === curr.pages[0].path)) acc[curr.rowHash].pages.push(...curr.pages);
    } else {
      // eslint-disable-next-line no-param-reassign
      acc[curr.rowHash] = curr;
      // eslint-disable-next-line no-param-reassign
      acc[curr.rowHash].count = 1;
    }
    return acc;
  }, {});

/**
 * Parses the URLs and QA data to extract the combined truncated URL and referral URL data.
 *
 * @param {Object} data - The data object containing the tracking QA results.
 * @param {string} platform - The platform to parse the data for.
 * @returns {Object} - An object containing the combined truncated URL and referral URL data, as well as the platform QA data.
 *
 * The returned object includes:
 * - `combinedTruncatedUrlAndReferralUrls`: An array of objects, each containing a 'truncated_url' and a 'referral_url'.
 * - `platformQAData`: The platform QA data object.
 */
export const parseUrlsAndQaData = (data, platform) => {
  const trackingQAData = data?.tracking_qa_results?.[0] || {};
  const platformQAData = trackingQAData[platform] || {};

  const missingParams = platformQAData?.all?.['dataset:has_missing_keys']?.truncated_url || {};
  const referralUrls = platformQAData?.all?.['dataset:has_missing_keys']?.request_referrer || {};
  const combinedTruncatedUrlAndReferralUrls = [];

  Object.keys(missingParams).forEach(key => {
    if (referralUrls[key]) {
      combinedTruncatedUrlAndReferralUrls.push({
        truncated_url: missingParams[key],
        referral_url: referralUrls[key],
      });
    }
  });
  return { combinedTruncatedUrlAndReferralUrls, platformQAData };
};

export const TIERS = ['tier_1', 'tier_2', 'tier_3', 'tier_4', 'tier_5'];
export const TIERS_LIMITED = ['tier_1', 'tier_2', 'tier_3'];
export const DERIVED_TIERS = ['channel', 'platform', 'strategy'];
export const DEFAULT_DERIVED_TIERS = {
  channel: 'Channel',
  platform: 'Platform',
  strategy: 'Strategy',
};

export const DEFAULT_TIERS = {
  tier_1: 'Tier 1',
  tier_2: 'Tier 2',
  tier_3: 'Tier 3',
  tier_4: 'Tier 4',
  tier_5: 'Tier 5',
};

export const DEFAULT_TIERS_ARR = [
  { text: 'tier 1', value: 'tier_1' },
  { text: 'tier 2', value: 'tier_2' },
  { text: 'tier 3', value: 'tier_3' },
  { text: 'tier 4', value: 'tier_4' },
  { text: 'tier 5', value: 'tier_5' },
];

export const ATTRIBUTABLE_NAMES = {
  tier_1: 'Bucket 1',
  tier_2: 'Bucket 2',
  tier_3: 'Bucket 3',
  tier_4: 'Bucket 4',
  tier_5: 'Bucket 5',
};

export const AND_OR_OPTIONS = [
  { text: 'AND', value: 'and' },
  { text: 'OR', value: 'or' },
];

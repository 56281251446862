import React, { Component } from 'react';
import { FormWrap, FieldWrap, SplitLayout, ColumnLayout, ContentCard, IndexGrid } from '@rockerbox/styleguide';
import { Button, Input, Grid, Form, Header, Menu, Icon, Dropdown } from 'semantic-ui-react';

const links = [
  {"display_name":"Model Details", "view":"setup"},
  {"display_name":"Data Summary", "view":"data_summary"},
  {"display_name":"Features", "view":"feature_summary"},
  {"display_name":" - Feature Data", "view":"feature_data"},
  {"display_name":" - Feature Correlation", "view":"feature_correlation"},
  {"display_name":"Model Results", "view":"model_summary"},
  {"display_name":" - ROC", "view":"model_roc"},
  {"display_name":" - Precision/Recall", "view":"model_pr"},
]

export const SelectModel =  ({view, id, modelRuns, onSelect}) => {

  const runs = (modelRuns || [])
  const _selectedModel = runs.filter(x => x.id == id)
  const selectedModel = _selectedModel.length > 0 ? _selectedModel[0] : {}

  return (
    <Dropdown scrolling inline text={selectedModel.run_name || id} value={id} options={modelRuns}
      onChange={(_, x) => onSelect(x.value) }
    />
  )

}


export const SelectView = ({view, run_id, comparisonId, modelRuns, setRun, setCompare, setView}) => {

  return(
    <div style={{flex:0}}>
      <Button.Group size='mini'>
        <Button onClick={() => setView("setup")} positive={view != "compare"}>Analyze</Button>
        <Button.Or />
        <Button onClick={() => setView("compare")} positive={view == "compare"}>Compare</Button>
      </Button.Group>
    </div>
  )
}

const ModelNav = ({view, run_id, modelRuns, setRun, setView}) => {

  const runs = (modelRuns || [])
  const _selectedModel = runs.filter(x => x.id == run_id)
  const selectedModel = _selectedModel.length > 0 ? _selectedModel[0] : {}

  return(
    <ContentCard disableHeaderEllipse borderless>
      { links.map(obj => (
            <div style={{fontWeight:obj.view == view ? "bold": undefined}}>
              <a onClick={() => setView(obj.view)} href="#">{obj.display_name}</a>
            </div>
          )
        )
      }
    </ContentCard>
  )
}

export default ModelNav;

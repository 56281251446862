import React, { useMemo, useState, useEffect } from 'react';

import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChartTooltip, PieChartLoader } from '@rockerbox/styleguide';
import { Link } from 'react-router-dom';
import { ResponsiveContainer, Tooltip, PieChart, Pie, Cell } from 'recharts';
import { Button, Header } from 'semantic-ui-react';

import { getChannelTrends } from '../../api/attribution';
import * as routes from '../../baseRoutes';
import { CHANNELS, CHANNEL_MAP, CHANNEL_NAME_MAP } from '../SpendTrends/constants';
import { formatPercent, getChannelBreakdownSummary, getYearlyChanges, getChannelsAndBreakdown, getChannelMixHighlights } from '../SpendTrends/helpers';
import { HighlightsTextLoader } from '../SpendTrends/loaders';

const Highlights = ({ loading, data }) => {
  const { largest, mostStable } = data || {};

  // eslint-disable-next-line react/no-unstable-nested-components
  const Loader = () => (
    <>
      <p>
        <HighlightsTextLoader />
      </p>
      <p>
        <HighlightsTextLoader />
      </p>
    </>
  );

  return (
    <div className="highlights">
      <Header as="h2">Rockerbox Report: State of Spend</Header>
      {!!loading ? <Loader /> : (
        <>
          <p>
            Marketers are spending more on
            {' '}
            <strong>{CHANNEL_MAP[largest.channel].name}</strong>
            {' '}
            than any other channel.&nbsp;
            {`${largest.yearlyChange < 0 ? 'However, over' : 'Over'}`}
            {' '}
            the past year, its average share of budget has
            {' '}
            <strong>
              {`${largest.yearlyChange > 0 ? 'in' : 'de'}creased`}
              {' '}
              from
              {' '}
              {formatPercent(largest.prevYearValue)}
              {' '}
              to
              {' '}
              {formatPercent(largest.prevMonthValue)}
            </strong>
            .
          </p>
          <p>
            <strong>{CHANNEL_MAP[mostStable.channel].name}</strong>
            {' '}
            has remained the most consistent, with an average
            {' '}
            <strong>
              {formatPercent(Math.abs(mostStable.yearlyPctChange))}
              {' '}
              change
            </strong>
            {' '}
            in share of budget compared to last year.
          </p>
        </>
      )}
    </div>
  );
};

const StateOfSpend = () => {
  const [loading, setLoading] = useState(true);
  const [trendData, setTrendData] = React.useState([]);

  useEffect(() => {
    setLoading(true);
    getChannelTrends()
      .then(data => {
        setTrendData(data);
        setLoading(false);
      });
  }, []);

  const [channels, channelBreakdown] = useMemo(() => getChannelsAndBreakdown(trendData), [trendData]);

  const channelBreakdownSummary = useMemo(() => getChannelBreakdownSummary(channelBreakdown, channels, 0.005), [channelBreakdown, channels]);

  const yearlyChanges = useMemo(() => getYearlyChanges(channelBreakdown, channels), [channelBreakdown, channels]);

  const channelMixHighlights = useMemo(() => getChannelMixHighlights(yearlyChanges, channelBreakdownSummary), [yearlyChanges]);

  // eslint-disable-next-line react/no-unstable-nested-components
  const PieChartContainer = () => (
    <>
      <div className="center-text">
        <span>
          Average Mix of
          <br />
          Advertising Spend
        </span>
      </div>
      <ResponsiveContainer
        id="channelMixPieChart"
        width={300}
        height={280}
      >
        <PieChart>
          <Pie
            data={channelBreakdownSummary}
            dataKey="value"
            nameKey="channel"
            startAngle={90}
            endAngle={-270}
            innerRadius="80%"
            outerRadius="95%"
            paddingAngle={1}
          >
            {channelBreakdownSummary.map(({ channel }) => (
              <Cell
                key={channel}
                fill={CHANNELS.find(x => x.key === channel)?.color}
              />
            ))}
          </Pie>
          <Tooltip
            content={<ChartTooltip nameMap={CHANNEL_NAME_MAP} />}
            isAnimationActive={false}
            formatter={formatPercent}
          />
        </PieChart>
      </ResponsiveContainer>
    </>
  );

  return (
    <div className="state-of-spend">
      <div className="left-wrap">
        <Highlights {...{ loading, data: channelMixHighlights }} />
        {!loading
            && (
            <div className="button-wrap">
              <Button
                primary
                as={Link}
                to={routes.spendTrends}
              >
                Discover more trends
                <FontAwesomeIcon
                  as="i"
                  icon={faArrowCircleRight}
                  style={{ marginLeft: 10, color: 'white' }}
                />
              </Button>
            </div>
            )}
      </div>
      <div className="pie-chart-wrap">
        {!!loading ? <PieChartLoader height={280} hideCenterText /> : <PieChartContainer />}
      </div>
    </div>
  );
};

export default StateOfSpend;

import React, { useState, useEffect } from 'react'
import { Grid, Button } from 'semantic-ui-react';
import { postRequiresSetup } from '../../../V3/api/account'
import OnboardingProgress from './OnboardingProgress'

import { heroAttributionImage, logoColorVector } from '@rockerbox/styleguide'

import { getSteps } from '../helpers/Step';

const Finish = ({ completedOnboardingSteps, setActiveStep, finishOnboarding, loading, tier }) => {

  const STEPS = getSteps(tier)

  const allStepsComplete = STEPS.every(s => completedOnboardingSteps.includes(s.key));

  return (
    <>
      <div style={{ position: 'absolute', left: '25px', top: '20px', zindex: 10000 }}>
        <img src={logoColorVector} width="205" />
      </div>
      <Grid
          centered
          columns={3}
          verticalAlign='middle'
          padded='vertically'
          className='onboarding-finish-view fluid-height'
        >
        <Grid.Row>
          <Grid.Column></Grid.Column>
          <Grid.Column textAlign='center'>
            <OnboardingProgress {...{completedOnboardingSteps, allStepsComplete, setActiveStep, tier }} />
            {allStepsComplete &&
              <Button
                size='huge'
                color='purple'
                content='View Dashboard'
                loading={loading}
                disabled={loading}
                onClick={() => finishOnboarding()}
            />
          }
          </Grid.Column>
          <Grid.Column></Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default Finish

import { useEffect, useReducer } from 'react';

import { computeInitialConstraints } from './constraintHelpers';

export const useConstraints = ({ featureStats }) => {
  const [constraints, setConstraints] = useReducer((state, newState) => {
    const updatedSlices = Object.keys(newState).reduce((accu, key) => {
      const newSlice = newState[key];
      const currentSlice = state[key] || {};
      const updatedSlice = { ...currentSlice, ...newSlice };
      accu[key] = updatedSlice;
      return accu;
    }, {});
    return { ...state, ...updatedSlices };
  }, {});

  useEffect(() => {
    if (!featureStats) return;
    const computedConstraints = computeInitialConstraints(featureStats);
    setConstraints(computedConstraints);
  }, [featureStats]);

  const setConstraintPercentage = percentage => {
    const computedConstraints = computeInitialConstraints(featureStats, percentage);
    setConstraints(computedConstraints);
  };

  return {
    constraints,
    setConstraints,
    setConstraintPercentage,
  };
};

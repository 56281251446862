import moment from "moment";

const estOffset = -300;

export const currentTimeZone = new moment.tz(moment.tz.guess());

export const hourFromLocalToEST = localHour => {
  const currentOffset = currentTimeZone._offset;

  let estHour = localHour + (estOffset - currentOffset) / 60;

  while (estHour < 0) {
    estHour = estHour + 24;
  }

  while (estHour > 24) {
    estHour = estHour - 24;
  }

  return estHour;
};

export const hourFromESTToLocal = estHour => {
  const currentOffset = currentTimeZone._offset;

  let localHour = estHour + (currentOffset - estOffset) / 60;

  while (localHour < 0) {
    localHour = localHour + 24;
  }

  while (localHour > 24) {
    localHour = localHour - 24;
  }

  return localHour;
};

import React, { useState, useEffect } from 'react';
import * as aq from 'arquero';

import { getCache } from '../../../utils/data';
import { useParamsWithDefaults } from '../urlParamHooks';
import { useGlobalState } from '../../../hooks/global';
import { isMetaDataCurrent } from './platformConfig/helpers';
import { TIERS } from '../../../constants/tiers';
import { getCurrencyRate } from '../../../api/currencyRate';

/* eslint-disable no-console */

// TODO: eliminate this using better things...
const nestedSum = cols => aq.escape(data => {
  const agg = {};
  cols.forEach(col => {
    const subObj = data[col];
    Object.entries(subObj).forEach(([key, value]) => {
      if (!Object.prototype.hasOwnProperty.call(agg, key)) agg[key] = 0;
      agg[key] += value;
    });
  });
  return agg;
});

export const usePerformanceData = () => {
  // Get data from the the IndexedDB cache or the API
  const { startDate, endDate, platform } = useParamsWithDefaults();
  const { currencyCode } = useGlobalState();
  const [data, setData] = useState(undefined);
  const [current, setCurrent] = useState({});
  const hasMissingParameters = (!platform || !startDate || !endDate);

  useEffect(() => {
    setCurrent({});
    setData(undefined);
    if (hasMissingParameters) return; // Don't attempt to run anything if we dont have everything
    if (!currencyCode) return; // Don't proceed until we have the currency code

    const getPerformanceDataViaCache = getCache('platform_performance', 'legacy');

    getPerformanceDataViaCache(startDate, endDate, `platform_performance_${platform}`)
      .then(arrow => {
        if (arrow.size === 0) {
          setData(arrow);
          setCurrent({ platform, startDate, endDate });
        } else {
          getCurrencyRate(currencyCode, startDate, endDate)
            .then(response => {
              console.time('arrow-join-currency');
              const rates = response.data;
              const ratesObj = rates.reduce(
                (obj, { date, exchange_rate }) => Object.assign(obj, { [date]: exchange_rate }),
                {},
              );
              const derive = {
                currencyConvertedSpend: (d, $) => d.spend / $.rates[d.date],
              };

              const derived = arrow
                .params({ rates: ratesObj })
                .derive(derive);

              const renameMap = {
                spend: 'unconvertedSpend',
                currencyConvertedSpend: 'spend',
              };

              if (derived.columnNames().includes('swipes')) {
                renameMap.swipes = 'clicks';
              }

              const updated = derived.rename(renameMap);

              console.timeEnd('arrow-join-currency');
              setData(updated);
              setCurrent({ platform, startDate, endDate });
            });
        }
      })
      .catch(error => {
        console.error(error);
        setCurrent({});
        setData(undefined);
      });
  }, [platform, startDate, endDate, currencyCode, hasMissingParameters]);

  // if current != params, dont return meta and derived objects
  if (!isMetaDataCurrent(current, { startDate, endDate, platform })) {
    return {
      current,
      loading: !hasMissingParameters,
      platformPerformanceData: false,
    };
  }

  return {
    current,
    loading: false,
    platformPerformanceData: data,
  };
};

export const usePerformanceDataWithSelection = selection => {
  const { startDate, endDate, platform } = useParamsWithDefaults();
  const {
    groupBy, attributionWindow, platformConversionEvent,
    revenue_formatter, tierFilterMap,
  } = selection;

  const data = usePerformanceData();

  const revenueColumn = revenue_formatter ? revenue_formatter(attributionWindow) : false;

  const rollup = {
    impressions: '(d) => op.sum(d.impressions || 0)',
    clicks: '(d) => op.sum(d.clicks || 0)',
    spend: '(d) => op.sum(d.spend || 0)',
    conversions: `(d) => op.sum(d['${attributionWindow}']['${platformConversionEvent}'] || 0)`,
    cpa: `(d) => op.sum(d.spend || 0)/op.sum(d['${attributionWindow}']['${platformConversionEvent}'] || 0)`,
  };

  const simpleRollup = {
    impressions: '(d) => op.sum(d.impressions || 0)',
    clicks: '(d) => op.sum(d.clicks || 0)',
    spend: '(d) => op.sum(d.spend || 0)',
    conversions: '(d) => op.sum(d.conversions)',
    cpa: '(d) => op.sum(d.spend || 0)/op.sum(d.conversions)',
    ctr: '(d) => op.sum(d.clicks || 0)/op.sum(d.impressions)',
    cpc: '(d) => op.sum(d.spend || 0)/op.sum(d.clicks)',
    cpm: '(d) => op.sum(d.spend || 0)/op.sum(d.impressions)*1000',
  };

  const hasResponse = data.platformPerformanceData;
  const hasData = hasResponse && data.platformPerformanceData.size;
  const hasSelection = attributionWindow && platformConversionEvent;
  const isCurrent = isMetaDataCurrent(data.current || {}, { startDate, endDate, platform });
  const shouldAgg = isCurrent && hasSelection && hasData;

  const groupByDimensions = {
    date: ['date'],
    groupBy,
    tiers: TIERS,
    spend_key: ['spend_key'],
  };

  const hasTiers = data.platformPerformanceData
    && data.platformPerformanceData
      .columnNames()
      .includes(TIERS[0]);

  if (!hasTiers) groupByDimensions.tiers = [];

  const preFilteredPlatformPerformance = React.useMemo(
    () => {
      if (!shouldAgg) return false;
      console.time('arrow-init-filter');

      // NOTE: this is a very ugly place to put this
      const derive = {};
      if (platform === 'facebook') {
        Object.assign(derive, {
          '7d_click_1d_view': nestedSum(['7d_click', '1d_view']),
          '7d_click_1d_view_value': nestedSum(['7d_click_value', '1d_view_value']),
          '7d_click_1d_view_original_currency_value': nestedSum(['7d_click_value', '1d_view_value']),
        });
      }

      const filtered = data.platformPerformanceData
        .derive(derive);

      console.timeEnd('arrow-init-filter');

      if (filtered.size > 0) return filtered;
      return data.platformPerformanceData
        .derive(derive);
    },
    [shouldAgg, platformConversionEvent],
  );

  const hasRevenue = preFilteredPlatformPerformance
    && preFilteredPlatformPerformance
      .columnNames()
      .includes(revenueColumn);

  if (hasRevenue) {
    if (platform === 'facebook') {
      rollup.revenue = `(d, $) => op.sum((d['${revenueColumn}']['${platformConversionEvent}']/$['rates'][d['date']]) || 0)`;
      rollup.roas = `(d, $) => op.sum((d['${revenueColumn}']['${platformConversionEvent}']/$['rates'][d['date']]) || 0)/op.sum(d.spend || 0)`;
      simpleRollup.revenue = '(d) => op.sum(d.revenue)';
      simpleRollup.roas = '(d) => op.sum(d.revenue || 0)/op.sum(d.spend || 0)';
    } else {
      rollup.revenue = `(d) => op.sum((d['${revenueColumn}']['${platformConversionEvent}']) || 0)`;
      rollup.roas = `(d) => op.sum((d['${revenueColumn}']['${platformConversionEvent}']) || 0)/op.sum(d.spend || 0)`;
      simpleRollup.revenue = '(d) => op.sum(d.revenue)';
      simpleRollup.roas = '(d) => op.sum(d.revenue || 0)/op.sum(d.spend || 0)';
    }
  }

  const platformPerformanceData = React.useMemo(
    () => {
      if (!shouldAgg) return false;
      console.time('arrow-agg-raw');

      const { tiers, groupBy, date, spend_key } = groupByDimensions; // eslint-disable-line no-shadow

      const agg = preFilteredPlatformPerformance
        .groupby([...groupBy, ...date, ...tiers, ...spend_key])
        .rollup(rollup);

      console.timeEnd('arrow-agg-raw');
      return agg;
    },
    [groupBy, attributionWindow, platformConversionEvent, shouldAgg],
  );

  const filteredPlatformPerformance = React.useMemo(
    () => {
      if (!shouldAgg) return false;
      console.time('arrow-filter');
      const toFilter = platformPerformanceData
        .params(tierFilterMap);

      const filtered = Object.entries(tierFilterMap)
        .reduce((accu, [key, values]) => {
          if (values.length === 0) return accu;
          return accu
            .filter(`(d, $) => op.includes($['${key}'], d['${key}'])`);
        }, toFilter);

      console.timeEnd('arrow-filter');
      return filtered;
    },
    [platformPerformanceData, tierFilterMap],
  );

  const filteredPlatformPerformanceTiers = React.useMemo(
    () => {
      if (!shouldAgg || !hasTiers) return false;
      console.time('arrow-agg-tiers');

      const agg = filteredPlatformPerformance
        .groupby([...TIERS, 'date', 'spend_key'])
        .rollup(simpleRollup);

      console.timeEnd('arrow-agg-tiers');
      return agg;
    },
    [platformPerformanceData, tierFilterMap],
  );

  const dailyPerformance = React.useMemo(
    () => {
      if (!shouldAgg) return false;
      console.time('arrow-agg-daily');
      const agg = filteredPlatformPerformance
        .groupby(['date'])
        .rollup(simpleRollup);

      console.timeEnd('arrow-agg-daily');
      return agg;
    },
    [filteredPlatformPerformance],
  );

  const totalPerformance = React.useMemo(
    () => {
      if (!shouldAgg) return false;
      console.time('arrow-agg-total');

      const agg = filteredPlatformPerformance
        .groupby([...groupBy])
        .rollup(simpleRollup);

      console.timeEnd('arrow-agg-total');
      return agg;
    },
    [filteredPlatformPerformance],
  );

  if (!shouldAgg && hasResponse) {
    return {
      platformPerformanceData: [],
      dailyPerformance: [],
      totalPerformance: [],
    };
  }
  if (!shouldAgg) return { platformPerformanceData: undefined };

  const { loading } = data;
  return {
    loading,
    _platformPerformanceData: platformPerformanceData,
    platformPerformanceData: platformPerformanceData.objects(),
    filteredPlatformPerformanceTiers,
    filteredPlatformPerformance,
    filteredPlatformPerformanceObjects: filteredPlatformPerformance.objects(),
    dailyPerformance: dailyPerformance.objects(),
    totalPerformance: totalPerformance.objects(),
  };
};
